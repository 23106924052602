import {
    INCORPORATED_NUMBERS as PREFIX
} from "../const";

import {
    INCORPORATED_GROUP_LIST_API,
    INCORPORATED_NUMBER_LIST_API,
    INCORPORATED_SUPPLIER_LIST_API,
} from "../const/apiMethods";

import {createAction} from './defaults';
import {api} from "../api/loginRoutes";

const setGroups = createAction("SET_GROUPS_" + PREFIX);
const setGroupsLoading = createAction("SET_GROUPS_LOADING_" + PREFIX);
const setSuppliers = createAction("SET_SUPPLIERS_" + PREFIX);
const setNumberList = createAction("SET_NUMBER_LIST_" + PREFIX);
const setNumberListLoading = createAction("SET_NUMBER_LIST_LOADING_" + PREFIX);
export const clearIncorporatedNumberList = createAction("CLEAR_NUMBER_LIST_" + PREFIX);


export const getIncorporatedNumbersGroups = (filter = {}) => (dispatch) => {
    dispatch( setGroupsLoading(true) );

    api(INCORPORATED_GROUP_LIST_API, {filter: {
        ...filter,
        actual_price: filter.actual_price ? false : null
    }})
        .then((response) => {
            if (response !== undefined) {
                dispatch( setGroups({
                    groupList: response.incorporated_group_list || [],
                }) );
            }
        })
        .finally(() => {
            dispatch( setGroupsLoading(false) );
        })
};


export const getIncorporatedNumbersList = (id, filter, page = 1, per_page = 15) => (dispatch) => {

    if (!id) {
        return;
    }

    dispatch( setNumberListLoading(true) );

    api(INCORPORATED_NUMBER_LIST_API, {ing_key: id, filter, page, per_page})
        .then((response) => {
            if (response !== undefined) {
                dispatch( setNumberList({
                    numberList: response.incorporated_number_list || [],
                    numberCount: response.incorporated_number_count || 0,
                }) );
            }
        })
        .finally(() => {
            dispatch( setNumberListLoading(false) );
        })
};


export const getIncorporatedNumbersSuppliers = () => (dispatch) => {

    api(INCORPORATED_SUPPLIER_LIST_API)
        .then((response) => {
            if (response !== undefined) {
                dispatch( setSuppliers({
                    supplierList: response.supplier_list || [],
                }) );
            }
        })
};