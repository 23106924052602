import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { SelectPicker } from 'rsuite';
import CustomField from '../Form/CustomField';

const ServicePlansComponent = ({excluded = [], service_plan_group_list, ...props}) => {

    const data = useMemo(() => {
        return service_plan_group_list.filter(item => {
            return item.otp_type || item.otp_type === 0;
        });
    }, [service_plan_group_list, excluded]);

    return <CustomField
        block
        data={data}
        accepter={SelectPicker}
        labelKey = "name"
        valueKey = "spg_key"
        placeholder="Service plan group"
        name="spg_key"
        searchable={false}
        classPrefix="allocated"
        {...props}
        virtualized={false}
    />
};

const mapState = ( {references} )=> ({
    service_plan_group_list: references.service_plan_group_list
});


const ServicePlans = connect( mapState, {})(ServicePlansComponent);

export default ServicePlans;