import {MANAGER_ACCOUNT_USER as PREFIX} from '../const';

const initialState = {
    list: [],
    loading: true,
    dropdown_list: [],
    dropdown_loading: true,
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {list}}) => ({...state, list: list, loading: false}),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    ['SET_DROPDOWN_ITEMS_' + PREFIX]: (state, {payload: {list}}) => ({...state, dropdown_list: list, loading: false}),
    ['SET_DROPDOWN_LOADING_' + PREFIX]: (state, {payload}) => ({...state, dropdown_loading: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}