import React, {useState} from 'react'
import {SelectPicker, Uploader, Icon, FormControl} from "rsuite";
import * as S from "./styled";
import InputNumber from 'rsuite/es/InputNumber'


export const FieldInput = ({name, label,  ...props}) => {
    return (<S.Group>
        {label &&
        <S.LabelWrapper>
            <S.Label>{label}</S.Label>
        </S.LabelWrapper>
        }
        <S.Field
            name={name}
            {...props}
        />
    </S.Group>)
};
export const FieldNumber = ({name, label, type, ...props}) => {
    return (<S.Group>
        {label &&
        <S.LabelWrapper>
            <S.Label>{label}</S.Label>
        </S.LabelWrapper>
        }
        <S.FieldNumber
            accepter={InputNumber}
            name={name}
            {...props}
        />
    </S.Group>)
}

export const FieldSelect = ({name, label, type, data, valueKey, labelKey, ...props}) => (
    <S.Group>
        {label &&
            <S.LabelWrapper>
                <S.Label>{label}</S.Label>
            </S.LabelWrapper>
        }
        <S.Field
            name={name}
            accepter={SelectPicker}
            cleanable={false}
            data={data}
            valueKey={valueKey}
            labelKey={labelKey}
            {...props}
        />
    </S.Group>
);


export const FieldSelectComment = ({name, label, type, data, valueKey, labelKey, ...props}) => {
    const [otherMessage, onSetOtherMessage] = useState(null);
    const [showOtherMessage, onShowOtherMessage] = useState(false);
    return (<S.GroupExtendedComment>
        {label &&
        <S.LabelWrapper>
            <S.Label>{label}</S.Label>
        </S.LabelWrapper>
        }
        {!showOtherMessage ? <S.Field
            name={name}
            accepter={SelectPicker}
            cleanable={false}
            data={data}
            valueKey={valueKey}
            labelKey={labelKey}
            onSelect={(value) => {
                if (value === 'Other') {
                    onShowOtherMessage(true)
                } else {
                    onShowOtherMessage(false)
                }
            }}
            {...props}
        /> : <S.CommentIcon icon="close" onClick={() => {onShowOtherMessage(false)}}/>}
        {showOtherMessage && <div className="extended">
            <S.Field
                name={name}
                formValue={otherMessage}
                onChange={(value) => {
                    onSetOtherMessage(value)
                }}
                {...props}
            />
        </div>}
    </S.GroupExtendedComment>)
};

export default FieldInput