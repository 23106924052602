import React from "react";
import {Whisper, Tooltip} from "rsuite";
import Modal from "../../../components/Modal";
import {useWindowWidth} from "../../../hooks";
import Table from "../../../components/Table";
import {downloadDataAsFile} from "../../../utils";
import {ButtonPrimary} from "../../../components/base/BaseButton";

export default ({
    show,
    onClose,
    maxWidth = 700,
    data = []
}) => {

    const resizedWidth = useWindowWidth();

    const columns = [
        {
            id: "name",
            label: "Name",
            value: ({name, account_manager_name}) =>{
                const result = `${name} / ${account_manager_name}`;
                
                return <Whisper
                    trigger="hover"
                    placement="bottomStart"
                    speaker={
                        <Tooltip>{result}</Tooltip>
                    }
                >
                    <span>{result}</span>
                </Whisper>
            },
            flexGrow: 3,
            minWidth: 150,
            align: "center"
        },
        {
            id: "email",
            label: "Email",
            value: ({contact_email_list}) => contact_email_list && contact_email_list.length
                ? contact_email_list[0]
                : "",
            flexGrow: 3,
            minWidth: 150,
            align: "center"
        },
        {
            id: "amount",
            label: "Paid",
            value: ({amount}) => amount || 0,
            flexGrow: 2,
            minWidth: 80,
            align: "center"
        },
        {
            id: "currency",
            label: "Currency",
            dataKey: "currency",
            flexGrow: 2,
            minWidth: 80,
            align: "center"
        },
    ];

    let amountUsd = 0;
    let amountEur = 0;
    for (const account of data) {
        if (!account.amount)
            continue;
            
        switch (account.currency) {
            case "EUR":
                amountEur += account.amount;
                continue;
            default:
                amountUsd += account.amount;
        }
    }

    const savePaymentsReport = () => {
        const txt = [`Name;Email;Paid;Currency`];
        for (const item of data) {
            const email = item.contact_email_list && item.contact_email_list.length
                ? item.contact_email_list[0]
                : "";
            txt.push(`${item.name}/${item.account_manager_name};${email};${item.amount || 0};${item.currency}`);
        }
        downloadDataAsFile(txt.join("\n"), "payments.csv");
    };

    return (
        <Modal
            {...{show, onClose}}
            onClose={() => {
                onClose(false);
            }}
            title="Request Hawala Payments"
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            footer={false}
            successText="Confirm"
        >
            <p 
                style={{
                    paddingBottom: "20px",
                    textAlign: "center"
                }}
            >
                <b>{data.length}</b> {`payments to Hawala on amount ${amountUsd.toFixed(2)} USD and ${amountEur.toFixed(2)} EUR were created`}
            </p>

            <Table
                extraHeight={250}
                data={data}
                columns={columns}
            />

            <div style={{textAlign: "center", paddingTop: "20px"}}>
                <ButtonPrimary
                    onClick={savePaymentsReport}
                >
                    Download
                </ButtonPrimary>
            </div>
        </Modal>
    )
}