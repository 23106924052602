import React, {useState, useEffect} from 'react';
import ModalSendApi from '../../../../../components/Modal/ModalSendApi';
import {Alert, Checkbox, DatePicker, Schema} from 'rsuite';
import ApiRequest from '../../../../../components/ApiRequest';
import {toStartDayUTCTime, getServiceSmsPrefix} from 'utils';
import FormControl from 'rsuite/es/FormControl';
import ControlLabel from 'rsuite/es/ControlLabel';
import FormGroup from 'rsuite/es/FormGroup';
import {MIN_RATE_VALUE} from 'const';
import MyForm from '../../../../../components/MyForm';
import {useWindowWidth} from "../../../../../hooks";

const {NumberType, DateType} = Schema.Types;

export default ({
    service=true,
    initialValues,
    activeTrunk,
    getData,
    target,
    targetPrice,
    update,
    sp_key,
    account_name,
    subDestinationsList,
    ...props
}) => {

    const [canSend, setSend] = React.useState([]);
    const [rate, setRate] = React.useState(0);
    const [endDate, setEndDate] = useState(null);
    const [withDate, setWithDate] = useState(false);
    const windowWidth = useWindowWidth();
    const data = getData();

    const changeRateModel = {
        rate: NumberType()
            .min(MIN_RATE_VALUE, `The minimum value of this field is ${MIN_RATE_VALUE}`)
            .max(10, `The minimum value of this field is 10`),
        // end_date: DateType()
    };


    const changeRateMethod =  getServiceSmsPrefix(service, "trunk_number__filter:change_rate");

    return (
        <ModalSendApi
            {...props}
            title="Change Rate"
            successText="Change"
            update={update}
            extraDisabled={!rate || withDate && (!endDate || !rate)}
        >

            {!activeTrunk.incorporated && subDestinationsList.length
                ? subDestinationsList.map(item => {
                    return (
                        <ApiRequest
                            key={item.sde_key}
                            target={targetPrice}
                            method="account_price:create"
                            checkResultKey='account_price'
                            canSend={canSend.includes(item.sde_key)}
                            data={{
                                sp_key: sp_key,
                                sde_key: item.sde_key,
                                rate: rate
                            }}
                            update = { () => {
                                Alert.success(`Default rate ${rate} for ${item.sde_name} was set`);
                            }}
                        >
                            <Checkbox
                                value={item.sde_key}
                                onChange={(value, checked) => {
                                    if (checked) {
                                        setSend([...canSend, item.sde_key]);
                                        return;
                                    }
                                    setSend( canSend.filter(stateItem => stateItem !== item.sde_key) );
                                }}
                                className="mb-3"
                            >
                                Set this  rate as default for {item.sde_name} for {account_name} (current: <b>{item.rate}</b>)
                            </Checkbox>
                        </ApiRequest>
                    )})
                    : <></>
                }

            <MyForm
                formDefaultValue={initialValues}
                model={changeRateModel}
                target={target}
                method={changeRateMethod}
                checkResultKey = 'trunk_numbers'
                update = { ({trunk_numbers}) => {
                    Alert.success(`Change Rate for ${trunk_numbers} numbers`)
                }}
                addData={{
                    rate: rate,
                    ...(endDate ? {end_date: endDate} : {})
                }}
                className={`d-flex flex-column ${windowWidth >= 600 ? 'align-items-center' : null}`}

                // life huck: disabled do not work. need to be refactored
                deepCloned={false}
            >
                <FormGroup className="mb-3 mr-0">
                    <ControlLabel className="mr-3 mb-0">New Rate</ControlLabel>
                    <FormControl name="rate"
                                 label="New Rate"
                                 style={{width: 140}}
                                 placeholder="0.0001"
                                 onChange={value => setRate(+value)}
                                 errorPlacement="topStart"
                    />
                </FormGroup>
                <FormGroup className="mb-0 mr-0">
                    <Checkbox
                        inline
                        className="mr-4 ml-0"
                        onChange={(v, checked) => {
                            setWithDate(checked);

                            if (!checked) {
                                setEndDate(null);
                            }
                        }}
                    >
                        From date
                    </Checkbox>

                        <DatePicker
                            name="end_date"
                            placeholder="Enter Date"
                            disabled={!withDate}
                            onChange={(date) => {
                                date && setEndDate(toStartDayUTCTime(date));
                            }}
                            ranges={[
                                {
                                    label: 'Today',
                                    value: new Date()
                                },
                                {
                                    label: 'Tomorrow',
                                    value: new Date(new Date().setDate(new Date().getDate() + 1))
                                }
                            ]}

                            disabledDate={(date) => {
                                const today = new Date();
                                today.setHours(0, 0, 0, 0);
                                return date < today;
                            }}

                            // mobile
                            inline={windowWidth < 600 && withDate}
                            oneTap={windowWidth < 600 && withDate}
                        />

                </FormGroup>
            </MyForm>
        </ModalSendApi>
    );
};