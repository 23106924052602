import React, {useRef, useMemo, useState, useEffect, useCallback} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import styled from "styled-components";
import {Icon} from "rsuite";

import LiveCallsTable from "./LiveCallsTable";
import LiveCallsFilters from "./LiveCallsFilters";
import LiveCallsApiFilters from "./LiveCallsApiFilters";
import {
    TWO_STAGE_DEFAULT_PER_PAGE_DIALER,
    LOCAL_STORAGE_LIVE_CALLS_COLUMNS_DIALER,
    LOCAL_STORAGE_LIVE_CALLS_FILTERS_DIALER,
    LOCAL_STORAGE_LIVE_CALLS_PER_PAGE_DIALER,
    DEFAULT_PER_PAGE_MEDIUM,
    MIN_PER_PAGE_DIALER
} from "const";
import PageHeader from "components/base/PageHeader";
import {FlexGridItem, FlexGrid} from "components/base/FlexGrid";
import { Spacer } from "components/base/Spacer";
import {ButtonPrimary} from "components/base/BaseButton";
import m from "definedMessages";
import {LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE} from "const/localStorageKeys";
import LiveCallsAutoUpdateForm from "pages/LiveCalls/LiveCallsAutoUpdateForm";
import Page from "components/base/Page";
import { withTranslation } from "hoc/WithTranslation";
import { useWindowWidth } from "hooks";



const LiveCalls = ({
    data,
    loading,

    page,
    perPage,

    getLiveCalls,

    formatMessage
}) => {
    const updateDataTimer = useRef(null);

    const defaultFilter = {number_destination: ""};
    const defaultApiFilter = {test_calls: false};

    const userInfoSavedData = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};
    const autoUpdateSavedData = localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE);

    const perPageSavedData = localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_PER_PAGE_DIALER) 
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_PER_PAGE_DIALER))
        : DEFAULT_PER_PAGE_MEDIUM;

    const tableColumnsSavedData = localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_COLUMNS_DIALER)
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_COLUMNS_DIALER))
        : [];


    const isTest = useMemo(() => {
        return Object.keys(userInfoSavedData).length ? userInfoSavedData.session.is_test : false;
    }, [userInfoSavedData]);

  
    const timerValue =  useMemo(() => {
        return autoUpdateSavedData && !isNaN(autoUpdateSavedData) && parseFloat(autoUpdateSavedData) <= 100000
            ? autoUpdateSavedData
            : null;
    }, [autoUpdateSavedData]);


    const [filter, setFilter] = useState(defaultFilter);
    const [apiFilter, setApiFilter] = useState(defaultApiFilter);

    const [curPage, setCurPage] = useState(null);
    const [curPerPage, setCurPerPage] = useState(null);

    const [searchLoading, setSearchLoading] = useState(false);

    const [paginatedData, setPaginatedData] = useState([]);
    const [numbersCount, setNumbersCount] = useState(0);

    const [autoUpdateFormValue, setAutoUpdateFormValue] = useState({
        timer: timerValue || null
    });


    const resizedWidth = useWindowWidth();


    const columns = [
        {
            id: "a_number", 
            label: formatMessage(m.aNumber),
            dataKey: "a_number", 
            flexGrow: 2,
            minWidth: 120,
            align: "center",
            value: ({a_number, a_subdestination_name}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">{a_number}</div>
                        <div className="table-two-staged__cell-second">{a_subdestination_name}</div>
                    </div>
                )
            }
        },
        {
            id: "b_number", 
            label: formatMessage(m.bNumber),
            dataKey: "b_number", 
            flexGrow: 2,
            minWidth: 120,
            align: "center",
            value: ({b_number, b_subdestination_name}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">{b_number}</div>
                        <div className="table-two-staged__cell-second">{b_subdestination_name}</div>
                    </div>
                )
            }
        },
        {
            id: "range",
            label: formatMessage(m.range),
            dataKey: "range",
            flexGrow: 4,
            minWidth: 200,
            align: "center",
            value: ({range, rate_term}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">{range}</div>
                        <div className="table-two-staged__cell-second">Rate: {rate_term}</div>
                    </div>
                )
            }
        },
        {
            id: "term",
            label: `${resizedWidth > 1366 ? formatMessage(m.terminationPoint) : formatMessage(m.termPoint)}`,
            flexGrow: 2,
            minWidth: 120,
            align: "center",
            value: ({ip_term, port_term})=> {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-single">{`${ip_term}:${port_term}`}</div>
                    </div>
                )
            },
        },
        {
            id: "duration",
            label: formatMessage(m.duration),
            dataKey: "duration",
            flexGrow: 1,
            minWidth: 80,
            align: "center",
            value: ({duration})=> {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-single">{duration}</div>
                    </div>
                )
            },
        },
    ];

    const getPaginatedData = (_data) => {
        const _page = curPage || page;
        const _perPage = curPerPage || perPageSavedData || TWO_STAGE_DEFAULT_PER_PAGE_DIALER || perPage;
        
        const tableFilterSavedData = localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_FILTERS_DIALER) 
            ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_FILTERS_DIALER)) 
            : {};

        const filterValue = filter && filter.number_destination !== null
            ? filter.number_destination
            : tableFilterSavedData && !!tableFilterSavedData.number_destination
                ? tableFilterSavedData.number_destination
                : "";
            
        const dataValue = _data.filter((value) => {
            return value && (
                (value.a_number && value.a_number.includes(filterValue))
                || (value.b_number && value.b_number.includes(filterValue))
                || (value.a_subdestination_name && value.a_subdestination_name.includes(filterValue))
                || (value.b_subdestination_name && value.b_subdestination_name.includes(filterValue))
                || (value.termination_point && value.termination_point.includes(filterValue))
            )
        });

        setNumbersCount(dataValue.length);

        return dataValue ? dataValue.slice(_page * _perPage - _perPage, _page * _perPage) : [];
    };


    useEffect(() => {
        setCurPerPage(perPageSavedData);
        getLiveCalls();
        
        return () => {
            if (updateDataTimer.current) {
                clearTimeout(updateDataTimer.current);
                updateDataTimer.current = null;
            }
        }
    }, []);

    useEffect(() => {
        setPaginatedData(getPaginatedData(data));
    }, [data, filter, curPage, curPerPage]);

    const onChangeApiFilters = (value) => {
        setCurPage(1);
        setApiFilter(value);

        getLiveCalls(!!value.test_calls);
        // setPaginatedData(getPaginatedData(data));
    };

    const onChangeFilters = (value) => {
        if (!searchLoading) setSearchLoading(true);

        const intervalTimer = () => {
            setTimeout(() => {
                setSearchLoading(false);
            }, 500);
        };

        setFilter(value);

        localStorage.setItem(LOCAL_STORAGE_LIVE_CALLS_FILTERS_DIALER, JSON.stringify(value));

        intervalTimer();
    };

    const onChangePerPage = (value) => {
        if (!searchLoading) setSearchLoading(true);

        const intervalTimer = () => {
            setTimeout(() => {
                setSearchLoading(false);
            }, 500);
        };

        const _perPage = parseInt(value) || curPerPage;

        if (_perPage <= 0) {
            setCurPerPage(MIN_PER_PAGE_DIALER);
        } else if (_perPage > 100) {
            setCurPerPage(100);
        } else {
            setCurPerPage(_perPage);
        }

        localStorage.setItem(LOCAL_STORAGE_LIVE_CALLS_PER_PAGE_DIALER, JSON.stringify(_perPage));

        intervalTimer();
    };

    const onChangePage = (value) => {
        if (!searchLoading) setSearchLoading(true);

        const intervalTimer = () => {
            setTimeout(() => {
                setSearchLoading(false);
            }, 1000);
        };
 
        setCurPage(value);

        intervalTimer();
    };

    const onSaveColumnsTable = (idListToSave) => {
        localStorage.setItem(LOCAL_STORAGE_LIVE_CALLS_COLUMNS_DIALER, JSON.stringify(idListToSave));
    };

    const onUpdateData = () => {
        getLiveCalls(apiFilter.test_calls || false);
    };

    const onTableDataUpdate = () => {
        if (updateDataTimer.current) {
            clearTimeout(updateDataTimer.current);
        }

        if (!autoUpdateFormValue.timer) {
            return;
        }

        updateDataTimer.current = setTimeout(() => {
            onUpdateData();
        }, autoUpdateFormValue.timer * 1000);
    };

    const onChangeAutoUpdateValue = (value) => {
        const timerTime = isNaN(value.timer) || !parseFloat(value.timer) ? "" : value.timer;

        localStorage.setItem(LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE, timerTime);

        setAutoUpdateFormValue(() => {
            onTableDataUpdate();

            return {
                timer: timerTime
            }
        });
    };
        

            
    const tableFilterSavedData = localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_FILTERS_DIALER) 
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_FILTERS_DIALER)) 
        : {};


    return (
        <Page>
            <PageHeader showService={false} title={<FormattedMessage id="liveCalls.liveCalls.header" defaultMessage="LIVE CALLS" />}/>

            <FlexGrid justify="space-between">
                <FlexGridItem>
                    <FlexGrid>
                        <FlexGridItem>
                            <LiveCallsFilters
                                onChangeFilters={onChangeFilters}
                                loading={loading || searchLoading}
                                {...{
                                    savedTableFilter: tableFilterSavedData
                                }}
                            />
                        </FlexGridItem>
                        {!isTest &&
                            <FlexGridItem>
                                <LiveCallsApiFilters
                                    onChangeFilters={onChangeApiFilters}
                                />
                            </FlexGridItem>
                        }
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <StyledUpdateBlock>
                        <LiveCallsAutoUpdateForm
                            formDefaultValue={autoUpdateFormValue}
                            onChange={onChangeAutoUpdateValue}
                        />
                        <ButtonPrimary
                            onClick={onUpdateData}
                            loading={loading}
                        >
                            <StyledActionButtonIcon icon="refresh"/>
                            {formatMessage(m.updateData)}
                        </ButtonPrimary>
                    </StyledUpdateBlock>
                </FlexGridItem>
            </FlexGrid>
            
            <Spacer/>

            <LiveCallsTable
                count={numbersCount || 0}
                data={paginatedData.length ? paginatedData : []}
                page={curPage || 1}
                per_page={curPerPage || perPageSavedData || TWO_STAGE_DEFAULT_PER_PAGE_DIALER}
                loading={loading || searchLoading}
                pickedColumns={columns}
                rowHeight={46}
                twoStaged={true}
                onDataUpdated={onTableDataUpdate}
                {...{
                    onChangePage,
                    onChangePerPage,
                    onSaveColumnsTable,
                    savedTableColumns: tableColumnsSavedData,
                    savedPerPage: perPageSavedData
                }}
            />
        </Page>
    )
};

export default withTranslation(LiveCalls)


const StyledActionButtonIcon = styled(Icon)`
    margin-right: 5px;
    
    .rtl & {
        margin-left: 5px;
        margin-right: 0;
    }
`;

const StyledUpdateBlock = styled.div`
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: -10px;
    
    & > * {
        margin-bottom: 10px;
    }
    
    .rs-form {
        margin-right: 20px;
    }
    
    @media (min-width: 768px) {
        text-align: right;
    }
`;