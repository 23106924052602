import React, {useEffect, useState} from 'react';
import {SelectPicker, ControlLabel, Form} from "rsuite";
import CustomField from "../../components/Form/CustomField";
import {connect} from "react-redux";
import {getReferencesForReports} from "actions/references";
import FormHOC from "hoc/FilltersForm";
import {removeNilValues} from "utils";
import styled from 'styled-components';

const SIMTrafficRangesFilters = ({onSubmit, defaultFilters, loading, ...props}) => {
    const [filters, setFilters] = useState(defaultFilters);

    const handleChange = (formValues) => {
        setFilters({...formValues});
        onSubmit(removeNilValues(formValues));
    };

    useEffect(() => {
        props.getReferencesForReports();
    }, []);

    return (
        <>
            <StyledForm
                layout="inline"
                onChange={handleChange}
                formDefaultValue={defaultFilters}
                formValue={filters}
            >
                <ControlLabel className={'d-block mb-2'}>Filters</ControlLabel>
                <CustomField
                    accepter={SelectPicker}
                    width={200}
                    data={props.worldzoneList}
                    labelKey="name"
                    valueKey="wz_key"
                    placeholder="Zone"
                    name="wz_key"
                />
                <CustomField
                    accepter={SelectPicker}
                    width={200}
                    data={props.destinationList}
                    labelKey="name"
                    valueKey="de_key"
                    placeholder="Destination"
                    name="de_key"
                />
                <CustomField
                    accepter={SelectPicker}
                    width={200}
                    data={props.subdestinationList}
                    labelKey="name"
                    valueKey="sde_key"
                    placeholder="Subdestination"
                    name="sde_key"
                />
            </StyledForm>
        </>
    );
};

const mapStateToProps = ({references, sim_traffic_ranges}) => ({
    loading: sim_traffic_ranges.loading,
    subdestinationList: references.subdestination_list,
    destinationList: references.destination_list,
    worldzoneList: references.worldzone_list
});

const mapDispatchToProps = {
    getReferencesForReports
};

export default connect(mapStateToProps, mapDispatchToProps)(FormHOC(SIMTrafficRangesFilters));


const StyledForm = styled(Form)`
  & .rs-form-control-wrapper {
    margin-left: 0 !important;
  }
`;