import {REFERENCES_DIALER_TRUNK} from '../const/';
import {REFERENCES_ALL_SET_LISTS, REFERENCES_SET_LISTS, REFERENCES_SET_LOADING, REFERENCES_REPORT_SET_LOADING} from './actionTypes';

import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import {
    REFERENCES_FOR_REPORTS_METHOD,
    REFERENCES_ADMIN,
    REFERENCES_CLIENT,
    REFERENCES_ALL_METHOD
} from '../const/apiMethods';
import {APP_TYPE_ADMIN, SET_INITED_REFERENCFES} from "../const";


const setLoading = createAction(REFERENCES_SET_LOADING);
const setReportLoading = createAction(REFERENCES_REPORT_SET_LOADING);
const setReferences = createAction(REFERENCES_SET_LISTS);
const setAllReferences = createAction(REFERENCES_ALL_SET_LISTS);
export const setInited = createAction(SET_INITED_REFERENCFES);


// GET APP REFERENCES
export const getReferences = (appType = APP_TYPE_ADMIN, service, accountList = []) => (dispatch, getStore) => {
    dispatch(setLoading(true));
    
    const currentService = service;

    const method = appType === APP_TYPE_ADMIN ? REFERENCES_ADMIN : REFERENCES_CLIENT;
    const store = getStore();
    const accountJoinList = store?.auth_dialer?.accountJoinList || accountList;
    
    api(method)
        .then((references) => {
            if (references && Object.keys(references).length) {
                dispatch(setReferences({...references, service: currentService, appType, accountJoinList}));
            }
        })
        .finally(() => dispatch(setLoading(false)));
};



export const getReferencesForReports = () => (dispatch) => {
    dispatch(setReportLoading(true));

    api(REFERENCES_FOR_REPORTS_METHOD)
        .then((references) => {
            dispatch(setReferences(references));
        })
        .finally(() => dispatch(setReportLoading(false)));
};

const setDialerTrunk = createAction(REFERENCES_DIALER_TRUNK);

export const getDialerTrunk = (service, extraFilter = {}) => (dispatch) => {
    const method = service ? 'trunk_list_for_accounts' : 'sms.trunk_list_for_accounts';

    api(method, {filter: {gan: false, ...extraFilter}})
        .then(({trunk_list = []}) => {
            dispatch(setDialerTrunk(trunk_list.map(
                x =>({
                    ...x,
                    trunk_id: x.trunk_id,
                    _name:`${x.acc_name}\\${x.tr_name}\\${x.sp_name}`
                })
            )));
        })
};

export const getAllReferences = () => (dispatch) => {
    api(REFERENCES_ALL_METHOD).then((response) => {
            dispatch(setAllReferences(response));
            dispatch(setLoading(false));

    })
};

export const setInitedReferences = (value) => (dispatch) => {
    dispatch(setInited(value));
};