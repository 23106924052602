import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import {BLOCKED_DESTINATIONS_SET_LIST, BLOCKED_DESTINATIONS_SET_LOADING} from './actionTypes';
import {
    BLOCKED_DESTINATIONS_GET_LIST_API, BLOCKED_DESTINATIONS_CREATE_API, BLOCKED_DESTINATIONS_MODIFY_API,
    BLOCKED_DESTINATIONS_REMOVE_API, BLOCKED_DESTINATIONS_MODIFY_LIST_API,
    SMS_BLOCKED_DESTINATIONS_GET_LIST_API, SMS_BLOCKED_DESTINATIONS_CREATE_API,
    SMS_BLOCKED_DESTINATIONS_MODIFY_API, SMS_BLOCKED_DESTINATIONS_MODIFY_LIST_API,
    SMS_BLOCKED_DESTINATIONS_REMOVE_API
} from '../const/apiMethods';

const setLoading = createAction(BLOCKED_DESTINATIONS_SET_LOADING);
const setBlockedDestinationsList = createAction(BLOCKED_DESTINATIONS_SET_LIST);

export const getBlockedDestinationsList = (filter, service) => async (dispatch) => {
    dispatch(setLoading(true));

    const method = service ? BLOCKED_DESTINATIONS_GET_LIST_API : SMS_BLOCKED_DESTINATIONS_GET_LIST_API;

    return await api(method, {filter})
        .then((response) => {
            dispatch(setBlockedDestinationsList({...response}));
        })
        .finally(() => dispatch(setLoading(false)));
};

export const onAddBlockDestinationsItem = (data, service) => async (dispatch) => {
    const method = service ? BLOCKED_DESTINATIONS_CREATE_API : SMS_BLOCKED_DESTINATIONS_CREATE_API;
    return await api(method, {...data})
};

export const onEditBlockDestinationsItem = (ab_key, data, service) => async (dispatch) => {
    const method = service ? BLOCKED_DESTINATIONS_MODIFY_API : SMS_BLOCKED_DESTINATIONS_MODIFY_API;
    return await api(method, {target: {ab_key}, ...data})
};
export const onEditBlockDestinationsItemList = (ab_key_list, data, service) => async (dispatch) => {
    const method = service ? BLOCKED_DESTINATIONS_MODIFY_LIST_API : SMS_BLOCKED_DESTINATIONS_MODIFY_LIST_API;
    return await api(method, {ab_key_list, ...data})
};
export const onRemoveBlockDestinationsItem = (ab_key, service) => async (dispatch) => {
    const method = service ? BLOCKED_DESTINATIONS_REMOVE_API : SMS_BLOCKED_DESTINATIONS_REMOVE_API;
    return await api(method, {target: {ab_key}})
};