import React from "react";
import {Form, FormControl, Icon, InputGroup, FormGroup} from 'rsuite';
import styled from 'styled-components';
import CheckPicker from 'rsuite/es/CheckPicker';
import {debounce} from "../../../utils";


export default ({
    filters,
    referencesLoading,

    onChangeFilters,
    
    dialerList = []
}) => {
    return (
        <StyledForm
            formDefaultValue={filters}
            onChange={debounce((value) => {
                onChangeFilters(value);
            }, 500)}
        >
            <StyledFormGroup>
                <InputGroup inside>
                    <StyledFormControl
                        accepter={CheckPicker}
                        placeholder="Dialer"
                        data={dialerList}
                        labelKey="name"
                        valueKey="id"
                        name="dialer_id_list"
                        label="Dialers"
                        errorPlacement="topRight"
                        disabled={referencesLoading}
                    />
                    {referencesLoading ? <StyledAddon>
                        <Icon icon="spinner" pulse/>
                    </StyledAddon> : <></>}
                </InputGroup>
            </StyledFormGroup>
        </StyledForm>
    )
}
const StyledForm = styled(Form)`
    && {
        width: 100%;
        display: flex;
    }
    .rs-form-control-wrapper {
        width: auto;
        margin-right: 20px;
    }
`;

const StyledAddon = styled(InputGroup.Addon)`
    &&&& {
        position: relative;
        padding: 0;
    }
`;

const StyledFormControl = styled(FormControl)`
    && {
        width: 100%;
        min-width: 300px;
        max-width: 320px;
        overflow: hidden;
    }
`;

const StyledFormGroup = styled(FormGroup)`
    && {
        display: flex;
        align-items: center;

        .rs-control-label {
            margin-right: 10px;
            margin-bottom: 0px;
        }
    }
`;