import React from "react";
import styled from "styled-components";
import {useWindowWidth} from "hooks";

import {CustomModal} from "../../components/base";
import {ButtonContent, ButtonPrimary} from "../../components/base/BaseButton";
import { getServiceSmsPrefix } from "../../utils";


export default (
    {
        data,
        service,
        maxWidth = 600,
        onClose,
        transactionKey,
        onDownloadLog,
        ...props
    }
) => {

    const resizedWidth = useWindowWidth();

    return (
        <>
            <StyledModal
                showFooter={true}
                title={'Upload numbers result'}
                width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
                customFooter={() => {
                    return data[transactionKey] && <>
                        <ButtonPrimary onClick={onDownloadLog}>Download revoking log</ButtonPrimary>
                        <ButtonContent onClick={onClose}>Close</ButtonContent>
                    </>
                }}
                onClose={onClose}
                {...props}
            >

                <p><b>{data[transactionKey]?.numbers || 0}</b> numbers from <b>{data[transactionKey]?.accounts || 0}</b> dialers were revoked</p>

            </StyledModal>
        </>
    )
};


const StyledModal = styled(CustomModal)`
    .rs-modal-body {
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;