import {connect} from "react-redux";
import {getLiveCalls} from "actions/client/liveCalls";
import LiveCalls from "./LiveCalls";

const mapState = ({auth_dialer, client_live_calls}) => ({
    service: auth_dialer.serviceDialer,
    loading: client_live_calls.loading,
    data: client_live_calls.live_calls_list,
    page: client_live_calls.page,
    perPage: client_live_calls.per_page,
    count: client_live_calls.count,
});

export default connect(mapState, {getLiveCalls})(LiveCalls);
