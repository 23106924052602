import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {Icon} from 'rsuite';
import {Link} from 'react-router-dom';

import {newAccount} from '../../routes';
import {useDebounce, usePrevious, useWindowWidth} from "../../hooks";
import {checkPermissionsFor} from "../../store/storeHelpers";
import {ACCOUNT_CREATE_METHOD, SMS_ACCOUNT_CREATE_METHOD} from "../../const/apiMethods";
import {toEndDayUTCTime, toStartDayUTCTime, getManagedServices, compareObjects, servicePick} from "../../utils";

import PanelLayout from '../../components/base/PanelLayout';
import {ButtonPrimary} from "../../components/base/BaseButton";

import * as S from "./styled";
import AccountsTable from './AccountsTable';
import AccountsFilters from './AccountsFilters';
import { transformed_service_reverse_data } from 'const';


export default (
    {
        data,
        service,
        loading,
        authInfo,
        paymentTermsList,
        account_manager_list,
        countryList,
        howFindList,
        getAccounts,
        servicePlanList,
        onSetRedirectTab,
        getAccountManagersDropdownList,
    }
) => {

    const cancelToken = useRef(axios.CancelToken.source());
    const windowWidth = useWindowWidth();

    const prevData = usePrevious(data);

    const [moreFiltersShow, setMoreFiltersShow] = useState(false);
    const [filters, setFilters] = useState({str: "", only_with_traffic: true, service: transformed_service_reverse_data[service] || undefined});
    const prevFilters = usePrevious(filters);
    const [localLoading, setLocalLoading] = useState(false);

    const deferredFilter = useDebounce(filters, 500);

    const accountManangedServiceList = getManagedServices(authInfo);

    // get managers list
    useEffect(() => {
          
        const currentServiceTransformed = transformed_service_reverse_data[service];

        if (accountManangedServiceList.includes(currentServiceTransformed)) {
            getAccountManagersDropdownList();
        }
    }, [service, authInfo]);

    useEffect(() => {
        cancelToken.current.cancel("Operation canceled due to new request.");
    }, [service]);

    useEffect(() => {
        if (filters !== prevFilters) {
            setLocalLoading(true);
        } else {
            setLocalLoading(false);
        }
    }, [filters, prevFilters]);

    useEffect(() => {
        if (!compareObjects(data, prevData)) {
            setLocalLoading(false);
        }
    }, [data, prevData]);


    useEffect(() => {

        const params = {
            ...deferredFilter,
            cntr_code: deferredFilter.cntr_code || undefined,
            hf_key: deferredFilter.hf_key || undefined,
            acc_manager_id_list: deferredFilter.acc_manager_id_list
                ? deferredFilter.acc_manager_id_list.length ? filters.acc_manager_id_list : null
                : undefined,
            sp_key_list: deferredFilter.sp_key_list
                ? deferredFilter.sp_key_list.length ? deferredFilter.sp_key_list : null
                : undefined
        };
        if (params.created_time) {
            if (params.created_time.length === 2) {
                params.created_start_time = toStartDayUTCTime(params.created_time[0]);
                params.created_stop_time = toEndDayUTCTime(params.created_time[1]);
            }
            delete params.created_time;
        }
  
        const currentServiceTransformed = transformed_service_reverse_data[service];
        
        if (accountManangedServiceList.includes(currentServiceTransformed)) {
            _getAccounts(params);
        }
    }, [deferredFilter, accountManangedServiceList]);

    const _getAccounts = (filters) => {
        if (typeof cancelToken.current !== typeof undefined) {
            cancelToken.current.cancel("Operation canceled due to new request.");
        }
        cancelToken.current = axios.CancelToken.source(); 
        getAccounts(null, {...filters, cancelToken: cancelToken.current})    
    };


    const handleChangeFilters = (params) => {
        setFilters(prev => {
            const showWithoutTrunksValue = !prev.sp_key_list?.includes(0) && params?.sp_key_list?.includes(0) && !prev.show_without_trunks
                ? true
                : params.show_without_trunks;

            const showClosedAccount = !prev.sp_key_list?.includes(0) && params?.sp_key_list?.includes(0) && !prev.show_closed_account
                ? true
                : params.show_closed_account;

            const onlyWithTraffic = !prev.sp_key_list?.includes(0) && params?.sp_key_list?.includes(0) && prev.only_with_traffic
                ? false
                : params.only_with_traffic;
              
            return {
                ...params,
                show_without_trunks: showWithoutTrunksValue,
                show_closed_account: showClosedAccount,
                only_with_traffic: onlyWithTraffic,
            }
        })
    }

    const accountsSplitedData = (items) => {

        return items.reduce((result, nextValue, nextIndex) => {
            const subAccountList = nextValue?.account_list || [];

            const splitedSubAccounts = subAccountList.length ? {
                voiceAccount: subAccountList.find(acc => acc?.service === "voice") || {},
                smsAccount: subAccountList.find(acc => acc?.service === "sms") || {}
            } : {};

            result[nextIndex] = {
                ...splitedSubAccounts,
                ...nextValue
            };
            return result;
        }, []);
    };
    const accountsSplitedList = accountsSplitedData(data);

    const isMobile = windowWidth < 1200;

    return (
        <PanelLayout>
            <S.Grid
                noWrap={!isMobile}
                align={!isMobile ? "top" : "middle"}
            >

                <S.GridItem
                    isFullWidth={windowWidth < 420}
                    style={{
                        marginLeft: 'auto',
                        order: windowWidth >= 420 ? 1 : 0,
                        textAlign: isMobile ? 'right' : 'inherit'
                    }}
                >
                    <ButtonAddAccount service={service} isMobile={isMobile}/>
                </S.GridItem>

                <S.GridItem style={{flexGrow: 1}}>
                    <AccountsFilters
                        {...{
                            moreFiltersShow,
                            setMoreFiltersShow,
                            account_manager_list,
                            paymentTermsList,
                            servicePlanList,
                            countryList,
                            howFindList,
                            isMobile,
                            service,
                        }}
                        filter={filters}
                        setFilter={handleChangeFilters}
                        setLoading={setLocalLoading}
                    />
                </S.GridItem>

            </S.Grid>

            <AccountsTable
                data={accountsSplitedList}
                loading={loading || localLoading}
                defaultSortColumn="name"
                isMobile={isMobile}
                {...{
                    service,
                    authInfo,
                    onSetRedirectTab
                }}
            />
        </PanelLayout>
    )
}


const ButtonAddAccount = ({children, isMobile = false, service, ...props}) => (
    checkPermissionsFor(servicePick(service, ACCOUNT_CREATE_METHOD, SMS_ACCOUNT_CREATE_METHOD)) &&
        <ButtonPrimary
            componentClass={Link}
            to={newAccount}
            isMobile={isMobile}
            {...props}
        >
            {isMobile ?
                <Icon icon="plus"/>
                : "+Add account"
            }
        </ButtonPrimary>
);

