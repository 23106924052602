import {connect} from "react-redux";
import PriceRangeQuarantineSetting from "./PriceRangeQuarantineSetting";
import {
    createPriceRangeQuarantineSetting,
    getPriceRangeQuarantineSettingList,
    modifyPriceRangeQuarantineSetting,
    removePriceRangeQuarantineSetting
} from "../../actions/special_limit_terms";

const mapStateToProps = ({special_limit_terms, references}) => ({
    data: special_limit_terms.list,
    loading: special_limit_terms.loading,
    worldzoneList: references.worldzone_list,
    destinationList: references.destination_list,
    subdestinationList: references.subdestination_list,
    globalSettings: references.global_setting,
});

export default connect(mapStateToProps, {
    getPriceRangeQuarantineSettingList,
    createPriceRangeQuarantineSetting,
    modifyPriceRangeQuarantineSetting,
    removePriceRangeQuarantineSetting
})(PriceRangeQuarantineSetting);