import React from 'react';
import {getFileResponse} from '../../../api/loginRoutes';
import {downloadDataAsFile} from '../../../utils/helpers';
import {ButtonSideIcon} from "../../../components/base/BaseButton";
import {ACCOUNT_PRICE_GET_LIST_API, SMS_SUPPLIER_PRICE_GET_LIST_METHOD} from '../../../const/apiMethods';
import styled, {css} from "styled-components";
import {ButtonToolbar, Dropdown, Icon} from 'rsuite';
import {Loader} from 'rsuite/es';


export default (
    {
        filter = {},
        disabled = false,
        account_id, 
        isSupplier,
        pickedSupplierPlan
    }
) => {

    
    const [loading, setLoading] = React.useState(false);
    
    
    const exportFile = async (_, withId = false) => {
        setLoading(true);

        const params = isSupplier ? {
            target: {
                'sms.supplier_plan_id': pickedSupplierPlan?.id
            },
            filter
        } 
        : {
            target: {
                account_id
            },
            filter
        };

        const method = isSupplier ? SMS_SUPPLIER_PRICE_GET_LIST_METHOD : ACCOUNT_PRICE_GET_LIST_API;

        if (withId) {
            params.add = {id: true};
        }

        const result = await getFileResponse(method, params);
        
        if (result) {
            downloadDataAsFile(result, 'prices.csv');
        }

        setLoading(false);
    };

    
    const exportFileWithId = (_) => {
        exportFile(_, true);
    };
    
    
    return (
        <>
            <StyledButtonToolbar>
                <StyledDropdown
                    size="sm"
                    title={<>{loading ? <StyledLoader/> : <><Icon icon="upload2"/>Export</>}</>}
                    appearance="default"
                    disabled={loading || disabled}
                    placement="bottomEnd"
                >
                    <Dropdown.Item onClick={exportFile}>Export price</Dropdown.Item>
                    {!isSupplier ? <Dropdown.Item onClick={exportFileWithId}>Export price with id</Dropdown.Item> : <></>}
                </StyledDropdown>
            </StyledButtonToolbar>
        </>
    )
};



const StyledButtonToolbar = styled(ButtonToolbar)`
    display: inline-block; 
    ${props => props.width && css`
        width: ${props.width};
    `}
`;


const StyledDropdown = styled(Dropdown)`
    height: 30px;
    & .rs-btn {
        padding-left: 10px !important;
    }
    & .rs-loader-spin {
        margin-top: 0 !important;
    }
    & > a {
        padding-top: 5px !important;
        top: 0;
        height: 30px;
        width: 100%;
    }
    & .rs-dropdown-toggle-caret {
        top: 5px;
        margin-top: 0 !important;
    }
    &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
        background-color: #d6d6db !important;
    }
`;

const StyledLoader = styled(Loader)`
    & span.rs-loader-spin {
        margin-top: 3px;
        margin-right: 5px;
    }
`;