import React from 'react';
import {Table} from 'rsuite';
import {isFunction, getServiceBySearchParams} from '../../utils';
import {LOCAL_STORAGE_TABLE_RESIZE} from "../../const";
import styled from "styled-components";
// import {useLocation} from 'react-router';

const { Column, HeaderCell, Cell } = Table;

const dateOptions = {day: '2-digit', month: '2-digit', year: 'numeric'};
const timeOptions = {hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit'};

const dataFormatters = {
    'date': (date) => date ? new Date(date).toLocaleDateString() : null,
    'datetime': (date) => date ? new Date(date).toLocaleString("en-US") : null,
    'datetime_en': (date) => {
        const dateObj = new Date(date);
        return date
            ? dateObj.toLocaleDateString('en', dateOptions) + " " + dateObj.toLocaleTimeString('en', timeOptions)
            : null
    },
};

const convertDateToYesterday = (date) => {
    return date ? new Date(new Date(new Date(date).setSeconds(new Date(date).getSeconds() - 1)).setHours(23, 59, 59, 999)) : null
};

export default (columns, cutDate, isMobile, resizable=false) => {
    return columns.map(({
        label,
        flexGrow, 
        dataKey, 
        title, 
        formatData, 
        headerProps = {}, 
        value, 
        ...restProps
    }, i)  => {
        const location = window.location;
        const pathName = location.pathname.replace("/", "");
        const currentServiceBySearchParams = getServiceBySearchParams(location.search)

        const resizedKey = currentServiceBySearchParams 
            ? `${LOCAL_STORAGE_TABLE_RESIZE}_${pathName}_${currentServiceBySearchParams}` 
            : `${LOCAL_STORAGE_TABLE_RESIZE}_${pathName}`;

        let resizedWidthData = JSON.parse(localStorage.getItem(resizedKey));


        resizedWidthData = resizedWidthData !== null ? resizedWidthData : {};
        const resizedWidth = resizedWidthData[dataKey];

        const props = {...restProps};

        if (!props.width && !resizable) { // !resizedWidth (flexgrow bug first column doesn't move)
            props.flexGrow = flexGrow || 1;
        }

        if (resizable && resizedWidth) {
            props.width = parseInt(resizedWidth);
        }

        if (isMobile) {
            if (props.minWidthMobile)
                props.minWidth = props.minWidthMobile;
        }

        return (
            <Column {...props} key={i} resizable={resizable} onResize={(width, resizedDataKey) => {
                const currentResizedWidthData = JSON.parse(localStorage.getItem(resizedKey)) || {};
                localStorage.setItem(resizedKey, JSON.stringify({
                    ...currentResizedWidthData,
                    [resizedDataKey]: width
                }));
            }}>
                <HeaderCell {...headerProps}>{label}</HeaderCell>
                {!value ? <Cell dataKey={dataKey}>
                    {(row) => {
                        const dataFormatter = isFunction(formatData) ? formatData : dataFormatters[formatData];
                        const date = cutDate && cutDate.hasOwnProperty(dataKey) && cutDate[dataKey] ? convertDateToYesterday(row[dataKey]) : row[dataKey];
                        const data = dataFormatter ? dataFormatter(date, dataKey, row) : date;
                        return title
                            ? <span title={data}>{data}</span>
                            : <span>{data}</span>
                    }}

                </Cell>
                : <Cell dataKey={dataKey}>{value}</Cell>}
            </Column>
        )
    })
}

const StyledColumn = styled(Column)`

    &&&&&&&&&& .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner {
        background-color: var(--color-green-light);
    }
    &&&&&&&&&& .rs-table-column-resize-spanner::before {
        border-color: transparent var(--color-green-light) transparent transparent;
    }

    &&&&&&&&&& .rs-table-column-resize-spanner::after {
        border-color: transparent var(--color-green-light) transparent transparent;
    }
    

`;

