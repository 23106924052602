import React, {useContext, useEffect, useRef, useState} from "react";
import {AppTypeContext, RtlContext} from "App";
import {APP_TYPE_ADMIN} from "const";
import {Button, Message} from "rsuite";
import {Spacer} from "./base/Spacer";
import {connect} from "react-redux";
import {checkPermissionsFor} from "../store/storeHelpers";
import {getCountDialer, getNewsDialer} from "../actions/news_management";
import {api} from "../api/loginRoutes";
import {NEWS_SET_READ_API, NEWS_LIST_DIALER_API} from "../const/apiMethods";
import styled, {css} from "styled-components";
import {useIntl} from "react-intl";
import m from "../definedMessages";


const LINE_HEIGHT = 20;
const MAX_LINES = 3;

const MAX_HEIGHT = LINE_HEIGHT * MAX_LINES;

const NewsWidget = (
    {
        post: _post,
        permissions,
        getCountDialer,
    }
) => {
    
    const {formatMessage} = useIntl();
    const textRef = useRef(null);
    const [excerpted, setExcerpted] = useState(false);
    const appType = useContext(AppTypeContext);
    const rtl = useContext(RtlContext);
    const [post, setPost] = useState(_post);


    useEffect(() => {
        setPost(_post);
    }, [_post]);


    useEffect(() => {
        if (!textRef?.current) {
            return;
        }

        const height = textRef.current.offsetHeight;
        if (height > MAX_HEIGHT) {
            setExcerpted(true);
            return;
        }

        setExcerpted(false);
    }, [post]);

    
    useEffect(() => {
        if (checkPermissionsFor(NEWS_LIST_DIALER_API)) {
            getCountDialer();
        }
    }, [permissions]);


    if (appType === APP_TYPE_ADMIN || !post) {
        return null;
    }


    const handleClose = () => {
        api(NEWS_SET_READ_API, {target: {news_id: post.id}})
            .then(r => {
                if (!r) {
                    return;
                }
                setPost(null);
                getCountDialer();
            });
    };


    return (
        <>
            <StyledMessage
                closable
                type="success"
                onClose={handleClose}
                description={
                    <>
                        <StyledText withReadMore={excerpted}>
                            <div
                                ref={textRef}
                                dangerouslySetInnerHTML={{__html: rtl ? post.text_arabic || post.text : post.text}}
                            />
                        </StyledText>
                        {excerpted &&
                            <>
                                <Spacer size={6}/>
                                <StyledReadMore>
                                    <Button
                                        onClick={() => setExcerpted(false)}
                                        appearance="link"
                                        size="xs"
                                    >
                                        {formatMessage(m.readMore)}
                                    </Button>
                                </StyledReadMore>
                            </>
                        }
                    </>
                }
            />
            <Spacer/>
        </>
    )
};


const mapState = ( {auth, news_management} )=> ({
    post: news_management.widgetPost,
    loading: news_management.loading,
    permissions: auth.permissions
});


export default connect(mapState, {
    getNewsDialer,
    getCountDialer
})(NewsWidget);



const StyledMessage = styled(Message)`
    && {        
        .rs-message-btn-close {
            color: var(--color-error) !important;

            .rtl & {
                left: 0;
                right: auto;
            }
        }
    }
`;


const StyledText = styled.div`
    ${props => props.withReadMore && css`
        max-height: ${MAX_HEIGHT}px; 
        overflow: hidden;
    `}
`;


const StyledReadMore = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: linear-gradient(to bottom, transparent, #edfae1 60%);
    padding-bottom: 3px;
    padding-top: 20px;
    left: 0;
    
    .rs-btn {
        color: #4caf50 !important;
    }
`;