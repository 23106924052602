import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import {E164_SET_LOADING, E164_SET_LIST} from './actionTypes';
import {
    E164_GET_LIST_API
} from '../const/apiMethods';
import {E164 as PREFIX} from "../const";

const setLoading = createAction(E164_SET_LOADING);
const setSettingList = createAction(E164_SET_LIST);

export const getE164List = (filter, page, perPage, sort) => async (dispatch) => {
    dispatch(setLoading(true));

    return await api(E164_GET_LIST_API, {filter, page, per_page: perPage, sort})
        .then((response) => {
            localStorage.setItem(PREFIX + '_per_page', perPage);

            dispatch(setSettingList({
                list: response.e164_list,
                count: response.e164_count,
                page,
                perPage
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};