import {CLI_DIALER as PREFIX} from '../../const';
import {
    CLI_LIST_DIALER_API, CLI_LIST_OTP_DIALER_API,
    SMS_CLI_LIST_DIALER_API, SMS_CLI_LIST_OTP_DIALER_API,
    SMS_SEARCH_GET_LIST, SMS_MDR_LIST_DIALER_API,
    SMS_MDR_SHORT_CLI_LOOKUP_METHOD
} from '../../const/apiMethods';
import {api} from '../../api/loginRoutes';
import {_setValue} from './defaults';

const setCLIList = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);

export const getCLIList = (service = true, filter = {}, isAuth = false) => (dispatch) => {
    dispatch(setLoading(true));

    // sms.mdr_full:get_list
    // sms.otp_rule:get_list
    const request = service 
        ? (isAuth ? CLI_LIST_OTP_DIALER_API : CLI_LIST_DIALER_API)
        // : (isAuth ? SMS_CLI_LIST_OTP_DIALER_API : SMS_CLI_LIST_DIALER_API);
        //: (isAuth ? SMS_SEARCH_GET_LIST : SMS_MDR_LIST_DIALER_API);
        : (isAuth ? SMS_CLI_LIST_OTP_DIALER_API : SMS_MDR_SHORT_CLI_LOOKUP_METHOD);

    const dataKey = service ? 'cli_lookup' : 'sms.cli_lookup';
    const currentFilter = service ? filter : {
        ...filter,
        otp: isAuth
    }

    api(request, {filter: currentFilter, add: {}}).then((response) => {
        if (response !== undefined) {
            dispatch(setCLIList({
                cli_lookup: response[dataKey] || []
            }));
        }
    });
};

export const loadingData = (load) => (dispatch) => {
    dispatch(setLoading(load));
};
