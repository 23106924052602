
import {defineMessages} from "react-intl";

export default defineMessages({
    myNumbers: {id: "menu.myNumbers", defaultMessage: "My Numbers", description: "Menu: My Numbers"},
    testCalls: {id: "menu.testCalls", defaultMessage: "Test calls", description: "Menu: Test calls"},
    liveCalls: {id: "menu.liveCalls", defaultMessage: "Live calls", description: "Menu: Live calls"},
    report: {id: "menu.report", defaultMessage: "Report", description: "Menu: Report"},
    ratesAndNumbers: {id: "menu.ratesAndNumbers", defaultMessage: "Ratecard", description: "Menu: Ratecard"},
    accessList: {id: "menu.accessList", defaultMessage: "Access List", description: "Menu: Access List"},
    accesses: {id: "menu.accesses", defaultMessage: "Accesses", description: "Menu: Accesses"},
    cliLookup: {id: "menu.cliLookup", defaultMessage: "CLI Lookup", description: "Menu: CLI Lookup"},
    editProfile: {id: "menu.editProfile", defaultMessage: "Edit profile", description: "Menu: Edit profile"},
    ivr: {id: "menu.ivr", defaultMessage: "IVR", description: "Menu: IVR"},
    logout: {id: "menu.logout", defaultMessage: "Logout", description: "Menu: Logout"},
    unlogin: {id: "menu.unlogin", defaultMessage: "Log Out", description: "Menu: Log Out"},
    menu: {id: "menu.menu", defaultMessage: "Menu", description: "common: Menu"},
    yourAccountManager: {id: "menu.yourAccountManager", defaultMessage: "Your account manager", description: "Menu: Your account manager"},
    payments: {id: "menu.payments", defaultMessage: "Payments", description: "Common: Payments"},
    gaNumbers: {id: "menu.gaNumbers", defaultMessage: "GA Numbers", description: "Menu: GA Numbers"},
    hotAccessList: {id: "menu.hotAccessList", defaultMessage: "Hot Access List", description: "Menu: Hot Access List"},
    otpNumbers: {id: "menu.otpNumbers", defaultMessage: "OTP Numbers", description: "Menu: OTP Numbers"},
    other: {id: "menu.other", defaultMessage: "Other", description: "Menu: Other"}
});
