import React from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {SCREEN_MEDIA} from "../../../const";
import m from "../../../definedMessages";


const {sm} = SCREEN_MEDIA;

const PaginationTotal = (
    {
        total,
        ...props
    }
) => {
    const {formatMessage} = useIntl();

    return <span className={"pagination-total"} {...props}>{formatMessage(m.total) + ':'} {total}</span>
}

export default styled(PaginationTotal)`
    align-self: flex-start;
    display: block;
    flex-shrink: 0;
    margin-left: auto;
    opacity: 0.6;
`;