import React, {useEffect, useState, useRef} from "react";
import {FlexGrid, FlexGridItem} from "../../../components/base/FlexGrid";
import NumbersListFilter from "./NumbersListFilter";
import NumbersListTable from "./NumbersListTable";
import NumbersListActions from "./NumbersListActions";
import { Spacer } from "components/base/Spacer";
import { DEFAULT_PER_PAGE_SMALL } from "../../../const";
import { servicePick } from "../../../utils";
import { usePrevious } from "hooks";


export default (
    {
        data,
        count,
        rowKey,
        loading,
        filter,
        service,
        setFilter,
        permissions,
        groupsFilter,
        currentGroup,
        currentGroupData,
        destinationList,
        subdestinationList,
        getSupplierGroups,
        getSupplierNumbers,
        getIncorporatedNumbersGroups,
        getIncorporatedNumbersList,
        showReasonHandler,
    }
) => {

    const tableRef = useRef(null);

    const [selected, setSelected] = useState({all: false, list: []});
    const [selectedNumbersInfo, setSelectedNumbersInfo] = useState([]);
    const [page, setPage] = useState(1);
    const [pageChanged, setPageChanged] = useState(false);
    const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE_SMALL);

    const prevService = usePrevious(service);

    const numberRowKey = servicePick(service, "inn_key", "key");

    useEffect(() => {
        if (service === prevService && currentGroupData) {
            tableRef.current.clear();
            setPage(1);
            fetchData(currentGroup, filter, 1, perPage);
            setSelected({all: false, list: []});
        }
    }, [currentGroup, currentGroupData, filter]);

    const fetchData = (currentGroup, filter, page, perPage) => {
        if (service) {
            getIncorporatedNumbersList(currentGroup, filter, page, perPage);
        } else {
            if (currentGroup) {
                getSupplierNumbers(currentGroup, filter, page, perPage, undefined, service);
            }
        }
    }


    const handleSelectItems = (selected) => {
        const filteredNumbers = selectedNumbersInfo.filter(item => selected.list.includes(item[numberRowKey]));
        const currentPageNumbers = data.filter(item => selected.list.includes(item[numberRowKey]));
        const numbersUpdated = currentPageNumbers.reduce((summ, item) => {
            if (summ.find(_item => _item[numberRowKey]=== item[numberRowKey])) {
                return summ;
            }
            return [...summ, item];
        }, [...filteredNumbers]);

        setSelected({...selected});
        setSelectedNumbersInfo([...numbersUpdated]);
    };


    const handleSuccess = () => {
        tableRef.current.clear();
        if (service) {
            getIncorporatedNumbersGroups(groupsFilter);
            getIncorporatedNumbersList(currentGroup, filter, page, perPage);
        } else {
            getSupplierGroups(null, null, groupsFilter);
            getSupplierNumbers(currentGroup, filter, 1, DEFAULT_PER_PAGE_SMALL, undefined, service);
        }
    };


    const handleChangePagination = (page = 1, perPage = DEFAULT_PER_PAGE_SMALL) => {
        setPage(page);
        setPerPage(perPage);
        fetchData(currentGroup, filter, page, perPage);
    };

    const extraParams = service ? {
        ...(selected.all ? {ing_key: currentGroup} : {}),
        inn_key_list: !selected.all ? selected.list : undefined,
        number_list:  !selected.all ? selected.list.map(key => data.find(item => item?.inn_key == key)?.number) : [],
        filter: selected.all ? filter : undefined,
    } : {
        target: {
            "sms.supplier_gan_group_id": currentGroup
        },
        "sms.supplier_gan_key_list": !selected.all ? selected.list : undefined,
        number_list:  !selected.all ? selected.list.map(key => data.find(item => item?.key == key)?.number) : [],
        ...(selected.all ? {numbers: null} : {}),
        filter: selected.all ? filter : undefined,
    };


    const hasBlocked = selectedNumbersInfo && selectedNumbersInfo.some(number => number.blocked);
    const hasSelected = (selected.list && selected.list.length) || selected.all;

    return (
        <>

            <FlexGrid>

                <FlexGridItem style={{marginLeft: 'auto'}}>
                    <NumbersListActions
                        onSuccess={handleSuccess}
                        {...{
                            selected,
                            count,
                            service,
                            extraParams,
                            currentGroupData,
                            hasSelected,
                            hasBlocked,
                            handleSuccess,
                            permissions,
                            showReasonHandler,
                        }}
                    />
                </FlexGridItem>
            </FlexGrid>

            <Spacer />
            
            <FlexGrid>
                <FlexGridItem>
                    <NumbersListFilter
                        service={service}
                        filter={filter}
                        onChangeFilter={setFilter}
                        {...{
                            destinationList,
                            subdestinationList
                        }}
                    />
                </FlexGridItem>

            </FlexGrid>


            <NumbersListTable
                {...{
                    data,
                    count,
                    loading,
                    service,
                    page,
                }}

                isselected
                isSelectedAll
                ispagination

                ref={tableRef}
                row_key={numberRowKey}
                per_page={perPage}
                extraHeight={40 + perPage * 30}
                getItems={handleChangePagination}
                setSelected={handleSelectItems}
            />

        </>
    )
};