import React, {useState, useEffect, useMemo} from 'react';
import Form from 'rsuite/es/Form';
import FormHOC from '../../../../hoc/FilltersForm';
import {FormGroup, DateRangePicker} from 'rsuite';
import CustomField from '../../../../components/Form/CustomField';
import { removeNilValues } from "../../../../utils";
import { ButtonPrimary } from "../../../../components/base/BaseButton";
import {useIntl} from "react-intl";
import m from "../../../../definedMessages";

const { allowedRange } = DateRangePicker;


const PostbackFilter = ({onApplyFilters, defaultFilters, globalSettings = {}, loading, ...props}) => {
    const {formatMessage} = useIntl();

    const defaultFiltersValues = {
        ...defaultFilters,
        supplier_id: null,
        senderid: null,
        mcc_mnc: null,
        sender_sde_key: null,
        phone_sde_key: null,
        phone: null,
        reason_list: []
    };

    const allowedRanges = useMemo(() => {
        const date = new Date();
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const intervalMonth = globalSettings.cdr_full__interval_month || 0;
        const dateRange = [
            new Date(startDate.setMonth(startDate.getMonth() - intervalMonth)),
            new Date(endDate.setMonth(endDate.getMonth() + 12))
        ];

        return [
            `${dateRange[0].getFullYear()}-${dateRange[0].getMonth() + 1}-${dateRange[0].getDate()}`,
            `${dateRange[1].getFullYear()}-${dateRange[1].getMonth() + 1}-${dateRange[1].getDate()}`,
        ]
    }, [globalSettings]);

    const [filters, setFilters] = useState(defaultFilters);

    useEffect(() => {
        setFilters(defaultFiltersValues);
    }, []);

    const handleChange = (formValues) => {
        setFilters(formValues);
    };

    const handleApply = () => { onApplyFilters(removeNilValues(filters)); };

    return (
        <>
            <Form
                layout="inline"
                onChange={handleChange}
                formDefaultValue={defaultFilters}
                formValue={filters}
            >
                <CustomField
                    accepter={DateRangePicker}
                    disabledDate={allowedRange(...allowedRanges)}
                    cleanable={false}
                    placeholder="Select Date Range"
                    name="range"
                    width={300}
                />

                <FormGroup>
                    <ButtonPrimary
                        style={{ padding: '8px 34px', marginLeft: 12 }}
                        onClick={handleApply}
                        disabled={loading}
                    >
                        {formatMessage(m.applyFilter)}
                    </ButtonPrimary>
                </FormGroup>
            </Form>
        </>
    );
};
export default FormHOC(PostbackFilter);