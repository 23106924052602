import React, {useEffect, useLayoutEffect, useState} from "react"
import {
    Icon,
    Panel,
    Header,
    Navbar,
    Content,
    Container,
    FlexboxGrid,
} from "rsuite";
import styled, {keyframes} from "styled-components";
import {DEFAULT_AUTH_PATH_ADMIN} from "../../const";
import {useLocation} from "react-router";
import history from "../../config/history";
import {api} from "../../api/loginRoutes";
import {LOCAL_STORAGE_DELAYED_SERVICE_CHANGE} from "../../const/localStorageKeys";
import {ACCOUNT_USER_LOGIN_MASTER_API, SUBACC_LOGIN_USER_METHOD} from "../../const/apiMethods";
import { logoutUser } from "../../actions/auth";

export default () => {
    const location = useLocation();
    const [title, setTitle] = useState("Redirecting to client...");
    const [icon, setIcon] = useState("refresh2");

    useLayoutEffect(() => {
        const customerId = new URLSearchParams(location.search).get("customer-id");
        const subCustomerId = new URLSearchParams(location.search).get("sub-customer-id");

        if (!customerId && !subCustomerId) {
            history.push(DEFAULT_AUTH_PATH_ADMIN);
            return;
        }

        if (!customerId && subCustomerId) {
            logoutUser();
        }

        if (customerId && !subCustomerId) {
            logoutUser();
        }

        handleLoginAsCustomer(customerId, subCustomerId);
    }, []);


    const handleLoginAsCustomer = (id, subId) => {
        const customerParams = {target: {account_user_id: id}};
        const subCustomerParams = {target: {subacc_user_id: subId}};

        const method = id ? ACCOUNT_USER_LOGIN_MASTER_API :  SUBACC_LOGIN_USER_METHOD;
        const params = id ? customerParams : subCustomerParams;

        api(method, params)
            .then((r => {
                if (!r) {
                    setTitle("Redirection failed");
                    return;
                }

                if (id) {
                    const sessionKey = r?.session?.session_key;

                    if (sessionKey) {
                        window.location.href = `${process.env.REACT_APP_CLIENT_URL}/login?session-key=${sessionKey}`;
                        // window.location.href = `http://localhost:3000/login?session-key=${sessionKey}`;
                    }
                    // ATX-3242
                    // try to create some tool to block update and replace path to admin default path
                    // localStorage.setItem(LOCAL_STORAGE_DELAYED_SERVICE_CHANGE, true);
                    history.replace("/reload");
                } else if (subId) {

                    const sessionId = r?.session_id;
                    const host = r?.host;

                    if (sessionId) {
                        window.location.href = `https://${host}/login?session-id=${sessionId}`;
                    }
                }
            }));
    }


    return (
        <StyledContainer>

            <Header>
                <Navbar appearance="inverse">
                    <Navbar.Body style={{minHeight: 56}}>
                    </Navbar.Body>
                </Navbar>
            </Header>

            <Content>
                <FlexboxGrid justify="center" style={{width: "100%"}}>
                    <FlexboxGrid.Item
                        style={{
                            marginTop: "20vh",
                            width: "100%",
                            maxWidth: "500px",
                            minWidth: "340px",
                            opacity: 0.7,
                        }}
                    >
                        <Panel
                            className="panel__noShadow"
                            header={<h3>{title}</h3>}
                            style={{
                                width: "100%",
                                textAlign: "center",
                            }}
                        >
                            <StyledIcon
                                icon={icon}
                                size="5x"
                            />
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </StyledContainer>
    );
};


const StyledContainer = styled(Container)`
    min-height: 100vh;
`;


const rotate = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;

const StyledIcon = styled(Icon)`
    display: block;
    animation: ${rotate} 2s linear infinite;
`;