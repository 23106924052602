import React, {useMemo, useContext, useCallback} from "react";
import {Form, FormGroup, InputNumber, Schema} from "rsuite";
import {useIntl} from "react-intl";
import * as S from "./styled";
import m from "../../../../definedMessages";
import {Select} from "../../../../components/Select";
import CheckboxControl from "../../../../components/Form/CheckboxControl";
import TemplateWhisper from "../../../../components/Template/TemplateRegexWhisper";
import styled, {css} from "styled-components";
import {RtlContext} from 'App';

const {StringType, NumberType} = Schema.Types;
const MIN_NUMBER_DEFAULT = 1;
const MIN_CHARACTERS = 3;
const MAX_CHARACTERS = 200;

export default React.forwardRef(({
    disabled = false,
    formValue = {},
    hideTitle = false,
    hideAmount = false,
    hideRandom = false,
    onChange,
    setIsBySde,
    allocationLimit,
    subdestinationList,
}, ref) => {
    const rtl = useContext(RtlContext);
    const {formatMessage} = useIntl();

    const formModel = useMemo(() => {

        const MAX_NUMBER = allocationLimit 
            ? allocationLimit.once
            : null;

        return Schema.Model({
            template: StringType()
                .pattern(/^[1-9](?:\d*[xX]*(\[((\d)|(\d-\d))((,\d-\d)?(,\d)?)*\])?)*$/, formatMessage(m.pleaseEnterValidNumbers))
                .addRule((value, data) => !!value || !!data.sde_key, formatMessage(m.thisFieldIsRequired), true)
                .addRule((value) => {
                    let replacedValue = value.replace( /\[.+\]/g, 'X')
                    if (replacedValue.length > MAX_CHARACTERS || replacedValue.length < MIN_CHARACTERS) {
                        return false
                    }
                }, formatMessage(m.templateShouldBeSize, {minNumber: MIN_CHARACTERS, maxNumber: MAX_CHARACTERS})),
            numbers: NumberType(formatMessage(m.pleaseEnterValidNumber))
                .min(MIN_NUMBER_DEFAULT, formatMessage(m.tooFewNumbers))
                .max(MAX_NUMBER, formatMessage(m.tooMuchNumbers))
                .isRequired(formatMessage(m.thisFieldIsRequired))
        })
    }, [allocationLimit]);

    const onChangeValue = useCallback((val) => {
        onChange && onChange(val);
    }, [onChange]);

    return (
        <Form
            formValue={formValue}
            ref={ref}
            model={formModel}
            onChange={onChangeValue}
        >
            {!hideTitle && <S.FormTitle disabled={disabled}>{formatMessage(m.allocatePerTemplate)}</S.FormTitle>}

            <FormGroup>
                <S.Label disabled={disabled}>{formatMessage(m.template)}</S.Label>
                <TemplateContainer rtl={rtl}>
                    <TemplateWhisper/>
                    <S.Field
                        name='template'
                        placeholder={'12356789XXXXXX'}
                        disabled={disabled}
                        onChange={() => setIsBySde(false)}
                    />
                </TemplateContainer>
            </FormGroup>

            <FormGroup>
                <S.Label disabled={disabled}>{formatMessage(m.orChooseItFromList)}</S.Label>
                <S.Field
                    name='sde_key'
                    accepter={Select}
                    data={subdestinationList}
                    labelKey="name"
                    valueKey="sde_key"
                    onChange={() => setIsBySde(true)}
                    placeholder={formatMessage(m.subdestination)}
                    disabled={disabled}
                />
            </FormGroup>

            <FormGroup>
                <S.Label disabled={disabled}>{formatMessage(m.amount)}</S.Label>
                <S.Field
                    accepter={InputNumber}
                    name='numbers'
                    disabled={(!formValue.template && !formValue.sde_key) || disabled}
                    placeholder={'3'}
                />
            </FormGroup>

            {!hideRandom && <CheckboxControl
                name="random_number"
                disabled={(!formValue.template && !formValue.sde_key) || disabled}
                checked={!!(formValue && formValue.random_number)}
            >
                {formatMessage(m.randomOrder)}
            </CheckboxControl>}

        </Form>
    )
});

const TemplateContainer = styled.div`
    && {
        margin: 0;
        position: relative;
        /* width: 222px; */
        height: 36px;
    }

    && .rs-icon {
        position: absolute;
        z-index: 100;
        top: 10px;
        ${props => props.rtl ? css`
            left: 10px;
        ` : css`
            right: 10px;
        `}
    }
`;
