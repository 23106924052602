import {DIALER_GROUPS as PREFIX} from '../const/';

const initialState = {
    list: [],
    loading: false,
    accountList: [],
    otherAccountList: [],
    accountLoading: false
};

const handlers = {
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {list}}) => ({
        ...state,
        list: list,
    }),
    ['SET_LOADING_' + PREFIX + '_ACCOUNTS']: (state, {payload}) => ({...state, accountLoading: payload}),
    ['SET_ITEMS_' + PREFIX + '_ACCOUNTS']: (state, {payload: {account_list, other_account_list}}) => ({
        ...state,
        accountList: account_list,
        otherAccountList: other_account_list
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}