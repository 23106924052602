import React, {useState, useEffect, useRef} from "react";
import {Form, Schema} from "rsuite";
import styled from "styled-components";
import {MIN_RATE_VALUE} from "const";
import {api} from "api/loginRoutes";
import {FormControl, Icon} from "rsuite/es";
import Message from "rsuite/es/Message";
import axios from "axios";

const {NumberType} = Schema.Types;


const formModel = Schema.Model({
    custom_rate: NumberType()
        .min(MIN_RATE_VALUE, `The minimum of this field is ${MIN_RATE_VALUE}`)
        .max(10, "The maximum of this field is 10")
});

let cancelToken = axios.CancelToken.source();

export default ({
    trunksList,
    trunkId,
    updateFormRef,
    sdeKey,

    formValue,

    setFormValue,
    setAutoPriceValue
}) => {

    const formRef = useRef(null);
    const [showRate, setShowRate] = useState(false);

    useEffect( () => {
        getAutoPrice(trunkId);
    }, [trunkId]);

    useEffect(() => {
        if (formValue.custom_rate) {
            setShowRate(!formValue.custom_rate);
        }
    }, [formValue.custom_rate])

    const getAutoPrice = (trunkId) => {
        setShowRate(false);
        formRef.current.cleanErrors();

        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
        }

        cancelToken = axios.CancelToken.source();

        if (!sdeKey || !trunkId || !trunksList || !trunksList.length)
            return;

        const trunkAccountId = trunkId
            ? trunksList.find(item => item.trunk_id === trunkId)?.account_id
            : null;
        const trunk = trunksList.find(trunk => trunk.trunk_id === trunkId);

        api("account_price__get_fullprice_subdestination", {
            sp_key: trunk.sp_key,
            target: {account_id: trunkAccountId},
            sde_key: sdeKey
        }, null, cancelToken)
            .then(response => {
                if (!response)
                    return;

                // console.log("axios.isCancel(response)", axios.isCancel(response));
                if (axios.isCancel(response)) {
                    return;
                }

                const fullprice = response.fullprice_for_account
                    ? response.fullprice_for_account.rate
                    : 0;

                if (!fullprice) {
                    setShowRate(!formValue.custom_rate);
                } else {
                    setAutoPriceValue(fullprice);
                }
            })
            .catch(() => {
                return <Message type="error" description="Error"/>
            });
    };

    return (

        <Form
            layout="inline"
            model={formModel}
            ref={(ref) => {
                updateFormRef(ref);
                formRef.current = ref;
            }}
            formValue={formValue}
            onChange={setFormValue}
        >

            {!showRate

                ? <StyledRateShowLabel
                        onClick={() => setShowRate(true)}
                    >
                        <Icon icon="book" inverse />
                        <a>Put custom rate</a>
                    </StyledRateShowLabel>

                : <>
                    <StyledRateLabel>Rate</StyledRateLabel>

                    <StyledRateField>
                        <FormControl
                            name="custom_rate"
                            placeholder="0.0001"
                            errorPlacement="bottomStart"
                        />
                    </StyledRateField>

                    {formValue.custom_rate 
                        ? <StyledIconClose>
                            <Icon
                                icon="close"
                                inverse
                                onClick={() => {
                                    getAutoPrice(trunkId);
                                }}
                            />
                        </StyledIconClose>
                        : null
                    }
                </>
            }

        </Form>
    );
};


const StyledRateShowLabel = styled.div`
    cursor: pointer; 
    margin: 8px 20px 0 0; 
    display: inline-block;
    
    .rs-icon {
        margin-right: 5px; 
    }
    
    a,
    .rs-icon {
        color: #5e2f9d !important;
    }
`;


const StyledRateLabel = styled.div`
    display: inline-block;
    // margin-left: 15px;
    margin-top: 8px;
`;

const StyledRateField = styled.div`
    display: inline-block; 
    margin-right: 10px;
    
    .rs-input {
        width: 140px !important;
    }
`;

const StyledIconClose = styled.div`
    display: inline-block;
    margin-top: 8px; 
    margin-right: 10px;
    
    .rs-icon {
        color: #5e2f9d; 
        cursor: pointer;
        margin-right: 5px; 
    }
`;