export const CDR_PER_PAGE = 'CDR_PER_PAGE';
export const MDR_FAILED_PER_PAGE = 'MDR_FAILED_PER_PAGE';

export const LOCAL_STORAGE_USER_INFO = 'userInfo';

export const LOCAL_STORAGE_DELAYED_SERVICE_CHANGE = 'LOCAL_STORAGE_DELAYED_SERVICE_CHANGE';

export const LOCAL_STORAGE_TRAFFIC_STAT_FILTERS = 'payments/LOCAL_STORAGE_TRAFFIC_STAT_FILTERS';
export const LOCAL_STORAGE_PAYMENTS_TRAFFIC_REPORTS_FILTERS = 'payments/LOCAL_STORAGE_PAYMENTS_TRAFFIC_REPORTS_FILTERS';
export const LOCAL_STORAGE_PAYMENTS_LIST_FILTERS = 'payments/LOCAL_STORAGE_PAYMENTS_LIST_FILTERS';

export const LOCAL_STORAGE_SIM_TRAFFIC_RANGES_FILTERS = 'LOCAL_STORAGE_SIM_TRAFFIC_RANGES_FILTERS';
export const LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE = 'LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE';

export const LOCAL_STORAGE_CHECKED_PAYMENT_STATUS = 'LOCAL_STORAGE_CHECKED_PAYMENT_STATUS';

export const LOCAL_STORAGE_SUBACC_LIVE_CALLS_AUTO_UPDATE = "LOCAL_STORAGE_SUBACC_LIVE_CALLS_AUTO_UPDATE";