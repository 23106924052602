import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import {api, getFileResponse} from "../../../../api/loginRoutes";
import AllocationResult from "../../../../components/Modal/ModalAllocationResult";
import AccountsGetForm from "../../../../components/Filters/AccountsGetForm";
import AllocateForm from "./AllocateForm";
import styled from "styled-components";
import DialerTrunk from "../../../../components/Filters/DialerTrunk";
import {getBlobContent, getServiceSmsPrefix} from "../../../../utils";
import {ButtonPrimary} from "../../../../components/base/BaseButton";
import {SMS_ACCOUNT_ID, SP_KEY__CONFERENCE_CALLS} from "const";
import {
    TRUNK_NUMBER_GET_LIST_API, 
    TRUNK_LIST_FOR_ACCOUNTS_METHOD, 
    SMS_TRUNK_LIST_FOR_ACCOUNTS_METHOD,
    ALLOCATION_INCORPORATED_BY_MANAGER_API,
    SMS_ALLOCATION_GAN_BY_MANAGER_API
} from "../../../../const/apiMethods";
import {servicePick} from "../../../../utils/helpers";


export default ({
    disabled, 
    account_id,
    service,
    sde_key,
    pr_key,
    update, 
    getList,
    filters,
    rangesFilter,
    incorporatedOnly,
    selectedNumbersInfo,
    showReasonHandler,
}) => {

    const [show, setShow] = useState(false);
    const [_disabled, setDisabled] = useState(false);
    const [formValue, setFormValue] = useState({});
    
    const [showResult, setShowResult] = useState(false);
    const [dialers, setDialers] = useState([]);
    const [trunkId, setTrunkId] = useState(null);

    const [summaryState, setSummaryState] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [csvContent, setCsvContent] = useState(null);
    const [totalNumbersForced, setTotalNumbersForced] = useState(null);

    const [accountFilter, setAccountFilter] = useState(null);
    const [filteredAccounts, setFilteredAccounts] = useState(null);
    const [filteredAccountsLoading, setFilteredAccountsLoading] = useState(false);
    const [clearTrunk, setClearTrunk] = useState(false);

    const [autoPriceValue, setAutoPriceValue] = useState(null);
    
    const [hashCode, setHashCode] = useState(null);

    let formRef = null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //     if (!_disabled && !rate) {
    //         setDisabled(true);
    //     } else {
    //         if (rate) {
    //             setDisabled(false);
    //         }
    //     }
    // }, [rate]);

    useEffect(() => {
        setClearTrunk(false);
    }, []);

    useEffect(() => {
        if (dialers.length) {
            const firstTrunk = dialers[0];
            const currentTrunkIdList = dialers.map((trunk) => trunk.trunk_id);

            if (!currentTrunkIdList.includes(trunkId)) {
                setTrunkId(firstTrunk.trunk_id);
            }
        }
    }, [dialers]);

    useEffect(() => {
        if (show) {
            setTrunkId(null);

            const method = servicePick(service, TRUNK_LIST_FOR_ACCOUNTS_METHOD, SMS_TRUNK_LIST_FOR_ACCOUNTS_METHOD);
            const ganValue = "only_with_incorporated_numbers" in rangesFilter && rangesFilter.only_with_incorporated_numbers;

            api(method, {
                filter: {
                    gan: ganValue,
                    str: accountFilter || ""
                }
            })
                .then(({trunk_list}) => {
                    setDialers((trunk_list || [])
                        .map(x => ({
                            ...x,
                            trunk_id: x.trunk_id,
                            _name: `${x.acc_name}\\${x.tr_name}\\${x.sp_name}`
                        })));
            });
        }
    }, [show, accountFilter, incorporatedOnly]);


    const handleSubmit = async () => {
        if ( !formRef.check() ) {
            return;
        }

        const selected = getList();
        const numbersKeysList = selected.list;
        const selectedAll = selected.all;

        const params = {
            target: {
                ...(service ? {trunk_id: trunkId} : {"sms.trunk_id": trunkId})
            },
            rate: +formValue.custom_rate,
            number_list: selectedNumbersInfo.map(item => item.number)
        };

        if (selectedAll) {
            if (service) {
                params.pr_key = pr_key;
            } else {
                params["sms.pr_key"] = pr_key;
            }
            // params.prn_key_list = null;
            params.filter = filters;
        } else {
            if (incorporatedOnly) {
                if (service) {
                    params.inn_key_list = selectedNumbersInfo.map(item => item.inn_key);
                } else {
                    params["sms.supplier_gan_key_list"] = selectedNumbersInfo.map(item => item.slg_key);
                }
            } else {
                if (service) {
                    params.pr_key = pr_key;
                } else {
                    params["sms.pr_key"] = pr_key;
                }  
                params.prn_key_list = numbersKeysList;
                params.filter = filters;
            }
        }

        const method = incorporatedOnly 
            ? servicePick(service, ALLOCATION_INCORPORATED_BY_MANAGER_API, SMS_ALLOCATION_GAN_BY_MANAGER_API) 
            : (service 
                ? "price_range_number:allocate" 
                : "sms.price_range_number:allocate"
            );

        setDisabled(true);

        const result = await api(method, params);

        if (result && result?.error && result.error.code === 4) {
            setDisabled(false);
            setClearTrunk(true);
            setShow(false);
            return;
        }

        if (result) {
            if (result.hash) {
                setHashCode(result.hash);
            }

            const activeTrunk = dialers.find(item => item.trunk_id === trunkId);

            if (result.reason_code && (!result.trunk_number_transaction || !result.trunk_number_transaction.numbers) ) {
                showReasonHandler({
                    ...params,
                    reasonCode: result.reason_code,
                    reasonHash: result.hash,
                    trunk: activeTrunk || {},
                });
                setDisabled(false);
                return;
            }

            const {trunk_number_transaction} = result;
            const summaryInfo = {...trunk_number_transaction};
            const transactionId = summaryInfo.id;

            const method = getServiceSmsPrefix(service, TRUNK_NUMBER_GET_LIST_API);

            getFileResponse(method, {target: {
                ...(service ? {trunk_number_transaction_id: transactionId} : {"sms.trunk_number_transaction_id": transactionId})
            }})
                .then((response) => {
                    if (response) {
                        const csvText = getBlobContent(response);
                        csvText.then((text) => {
                            setCsvContent(text);
                        });
                    }
                });

            setSummaryState(summaryInfo);
            setTransactionId(transactionId);

            setTotalNumbersForced(summaryInfo.numbers);
            setShowResult(true);
            setDisabled(false);
            setShow(false);
            setClearTrunk(true);
            setAccountFilter(null);
            setAutoPriceValue(null);
            update();
        }
    };

    const activeTrunk = useMemo(() => {
        return dialers.find(item => item.trunk_id === trunkId) || null;
    }, [dialers, trunkId]);


    const recomendedRate = useMemo(() => {
        if (!selectedNumbersInfo.length || !activeTrunk) {
            return null;
        }

        const groupList = selectedNumbersInfo.map((number) => {
            return number?.supplier_gan_group || number?.incorporated_group || {};
        });

        const allGroupEqual = groupList.length ? groupList.every((group) => {
            return group.id === groupList[0].id;
        }) : false;

        if (!allGroupEqual) {
            return null;
        }
     
        const group = groupList[0];
        const currentRateList = group?.rate_list || [];

        const currentRateData = activeTrunk ? currentRateList.find((rate) => {
            return rate.pt_key === activeTrunk.pt_key && rate.cur_key === activeTrunk.cur_key;
        }) : {};

        return currentRateData?.rate || null;
    }, [dialers, activeTrunk, selectedNumbersInfo]);

    useEffect(() => {
        const ganValue = "only_with_incorporated_numbers" in rangesFilter && rangesFilter.only_with_incorporated_numbers;
            
        let currentRate = recomendedRate;

        const allNumbersHaveGroup = selectedNumbersInfo.length ? selectedNumbersInfo.every((number) => {
            return !!number.supplier_gan_group || !!number.incorporated_group;
        }) : false;

        if (!allNumbersHaveGroup && ganValue) {
            currentRate = null;
        }

        if (!ganValue) {
            currentRate = autoPriceValue;
        }

        if (currentRate) {
            setFormValue((currentFormValue) => {
                return {
                    ...currentFormValue,
                    custom_rate: currentRate
                }
            });
        }
    }, [recomendedRate, autoPriceValue, rangesFilter, selectedNumbersInfo]);


    return (
        <>
            <ButtonPrimary
                disabled={disabled} 
                onClick={() => setShow(true)}
            >
                Allocate
            </ButtonPrimary>

            {show &&
                <Modal
                    show={show}
                    title="Allocate numbers"
                    onClose={() => {
                        setShow(false)
                        setClearTrunk(true);
                        setAccountFilter(null);
                        setAutoPriceValue(null);
                    }}
                    footer={true}
                    successText="Confirm"
                    disabled={_disabled || !dialers.length}
                    extraDisabled={disabled || !formValue.custom_rate || !trunkId}
                    onSuccess={handleSubmit}
                >

                    Choose dialer and trunk.

                    <AccountsGetFormWrapper>
                        <AccountsGetForm
                            setAccountFilter={setAccountFilter}
                            setLoading={setFilteredAccountsLoading}
                            setFilteredAccounts={setFilteredAccounts}
                        />
                    </AccountsGetFormWrapper>

                    <DialerTrunk
                        trunkId={trunkId}
                        clearTrunk={clearTrunk}
                        disabledTrunk={disabled}
                        accounts={filteredAccounts}
                        dialerTrunkList={dialers}
                        setTrunkId={setTrunkId}

                        onClear={() => setTrunkId(null)}
                        onChange={(trunk) => setTrunkId(trunk.trunk_id)}
                    />

                    <AllocateForm
                        trunkId={trunkId}
                        trunksList={dialers}
                        updateFormRef={ref => formRef = ref}
                        accountId={account_id}
                        sdeKey={sde_key}
                        disabled={disabled || filteredAccountsLoading}
                        accounts={filteredAccounts}
                        formValue={formValue}
                        setFormValue={setFormValue}
                        setAutoPriceValue={setAutoPriceValue}
                    />

                </Modal>
            }
            
            <AllocationResult
                show={showResult}
                service={service}
                accountId={account_id}
                summaryState={summaryState}
                totalNumbersForced={totalNumbersForced}
                trunkId={trunkId}
                showFullList={true}
                hideBufferButtons={false}
                csvContent={csvContent}
                transactionId={transactionId}

                isSms={activeTrunk?.account_id === SMS_ACCOUNT_ID}
                hashCode={hashCode}

                onClose={() => {
                    setShowResult(false);

                    if (totalNumbersForced)
                        setTotalNumbersForced(null);

                    if (csvContent)
                        setCsvContent(null);

                    if (hashCode)
                        setHashCode(null);
                }}
                // showFullList={true}
            />
        </>

    );
}


const AccountsGetFormWrapper = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
`;