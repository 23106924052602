import React from "react";
import {FormControl, SelectPicker} from "rsuite";
import styled from "styled-components";

export default ({name, placeholder, ...props}) => {

    const langs = [
        {lang_key: 'en', lang_name: 'English'},
        {lang_key: 'ar', lang_name: 'اللغة العربية'}
    ];

    return (
        <Switcher
            name={name || 'lang'}
            data={props.options || langs}
            labelKey="lang_name"
            valueKey="lang_key"
            placeholder={placeholder || 'Select Language'}
            searchable={false}
            cleanable={false}
            {...props}
        />
    )
};

const Switcher = styled(SelectPicker) `
    && {
        width: 180px;
    }
`;