export const getRoles = () => {
    const userInfo = getUserInfo();
    try {
        return userInfo?.session?.account_user?.role_list || [];
    } catch (error) {
        console.error(error)
        return []
    }
    
}

export const getUserInfo = () => {
    const userInfo = localStorage.getItem('userInfo');
    if (!userInfo) return {};

    try {
        return JSON.parse(userInfo);
    } catch (error) {
        console.error(error)
        return {}
    }
} 
