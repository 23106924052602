import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import {Button} from "rsuite";
import styled, {css} from "styled-components";
import m from "../../../definedMessages";
import {useIntl} from "react-intl";
import {toUTCDateTime} from '../../../utils';

const LINE_HEIGHT = 20;
const MAX_LINES = 3;

const MAX_HEIGHT = LINE_HEIGHT * MAX_LINES;

export default (
    {
        lang,
        item
    }
) => {

    const {formatMessage} = useIntl();
    const textRef = useRef(null);
    const [excerpted, setExcerpted] = useState(false);

    useEffect(() => {
        if (!textRef?.current) {
            return;
        }

        const height = textRef.current.offsetHeight;
        if (height > MAX_HEIGHT) {
            setExcerpted(true);
            return;
        }

        setExcerpted(false);
    }, [item]);

    const text = lang === "en"
        ? item.text
        : item.text_arabic || item.text;

    return (
        <div style={{position: 'relative'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <b>
                        <time
                            dateTime={moment(toUTCDateTime(new Date(item.date))).format("YYYY.MM.DD hh:mm")}
                            // title={moment(new Date(item.date)).format('LLL')}
                        >
                            {moment(toUTCDateTime(new Date(item.date))).format("YYYY-MM-DD hh:mm")}
                            {/*{moment(new Date(item.date)).startOf('hour').fromNow()}*/}
                        </time>
                    </b>
                </div>
            </div>

            <StyledText withReadMore={excerpted}>
                <div ref={textRef} dangerouslySetInnerHTML={{__html: text}}/>
            </StyledText>

            {excerpted &&
                <StyledReadMore>
                    <Button
                        onClick={() => setExcerpted(false)}
                        appearance="link"
                        size="sm"
                    >
                        {formatMessage(m.readMore)}
                    </Button>
                </StyledReadMore>
            }
        </div>
    )
};


const StyledText = styled.div`
    ${props => props.withReadMore && css`
        max-height: ${MAX_HEIGHT}px; 
        overflow: hidden;
    `}
`;


const StyledReadMore = styled.div`
    position: relative;
    width: 100%;
    text-align: center;
    background: linear-gradient(to bottom, transparent, #ffffff 50%);
    padding-top: 20px;
    margin-bottom: -6px;
    margin-top: -17px;
    
    .rs-btn {
        color: var(--color-primary) !important;
    }
`;