import {connect} from "react-redux";
import Account from "./Account";
import {withRouter} from "react-router-dom";
import {
    changeRateNumbers,
    revokeNumbers,
    exportNumbers,

    getSubAccountNumberList,
    getSubAccountPaymentList,

    addNewPayment,
    removePayment,

    modifySubAccount,
    closeSubAccount,
    openSubAccount,

    getSubAccountData,

    getSubAccountCustomerList,
    createSubAccountCustomer,
    modifySubAccountCustomer,
    removeSubAccountCustomer
} from "actions/client/subaccounts";

const mapState = ({auth, references, auth_dialer, client_subaccounts}) => {
    return {
        accountData: client_subaccounts.accountData,
        accountDataLoading: client_subaccounts.accountDataLoading,
        customerList: client_subaccounts.customerList.map(customer => {return {...customer, key: customer.user_id}}),
        customerListLoading: client_subaccounts.customerListLoading,
        numberList: client_subaccounts.subAccountNumberList,
        numberPage: client_subaccounts.subAccountNumberPage,
        numberCount: client_subaccounts.subAccountNumberCount,
        numberPerPage: client_subaccounts.subAccountNumberPerPage,
        numberListLoading: client_subaccounts.subAccountNumberListLoading,
        paymentList: client_subaccounts.subAccountPaymentList,
        paymentListLoading: client_subaccounts.subAccountPaymentListLoading,
        billingServiceList: auth_dialer.billingServiceList,
        billingCurrencyList: auth_dialer.billingCurrencyList,

        currencyList: references.currency_list,

        accountId: auth.dialerInfo ? auth.dialerInfo.account_list.find(account => account.service === "voice")?.id : null,
    }
};

const mapMethods = {
    changeRateNumbers,
    revokeNumbers,
    exportNumbers,

    getNumberList: getSubAccountNumberList,
    getPaymentList: getSubAccountPaymentList,

    addNewPayment,
    removePayment,

    modifySubAccount,
    closeSubAccount,
    openSubAccount,
    
    getSubAccountData,

    getSubAccountCustomerList,
    createSubAccountCustomer,
    modifySubAccountCustomer,
    removeSubAccountCustomer
};

export default withRouter(connect(mapState, mapMethods)(Account));