import React, { useRef, useState, useEffect } from "react";
import TableServerSort from "components/Table/TableServerSort";
import { DEFAULT_PER_PAGE_MAX } from "const";
import styled from "styled-components";

import m from "definedMessages";


const AccessListTable = ({
    data,
    page,
    count,
    perPage,
    loading,
    isMobile,
    
    setSort,
    getItems,

    formatMessage
}) => {
    const tableRef = useRef(null);

    const columns = [
        {
            id: "a_description",
            label: formatMessage(m.origin),
            dataKey: "access_origin",
            value: ({a_description}) => {
                return a_description;
            },
            align: "center",
            flexGrow: 3,
            minWidth: 220
        },
        {
            id: "b_description",
            label: formatMessage(m.destination),
            dataKey: "access_destination",
            value: ({b_description}) => {
                return b_description;
            },
            align: "center",
            flexGrow: 3,
            minWidth: 220
        },
        {
            id: "rate",
            label: formatMessage(m.myPayout),
            dataKey: "rate",
            value: ({rate, currency_name}) => {
                return `${rate} ${currency_name}`;
            },
            align: "center",
            flexGrow: 1,
            minWidth: 100
        },
        {
            id: "weekly_rate",
            label: formatMessage(m.subPayoutWeekly),
            dataKey: "rate_subacc_week",
            value: ({rate_subacc_week, currency_name}) => {
                return `${rate_subacc_week} ${currency_name}`;
            },
            align: isMobile ? "center" : "right",
            flexGrow: 1,
            minWidth: 130
        },
        {
            id: "monthly_rate",
            label: formatMessage(m.subPayoutMonthly),
            dataKey: "rate_subacc_month",
            value: ({rate_subacc_month, currency_name}) => {
                return `${rate_subacc_month} ${currency_name}`;
            },
            align: isMobile ? "center" : "right",
            flexGrow: 1,
            minWidth: 140
        }
    ];

    return (
        <StyledTable
            data={data}
            page={page}
            count={count}
            ref={tableRef}
            loading={loading}
            columns={columns}
            per_page={perPage}
            disabled={loading}
            isMobile={isMobile}
            originalColumns={columns}

            row_key="number"
            shouldUpdateScroll={false}
            perPageMaximum={DEFAULT_PER_PAGE_MAX}

            ispagination

            getItems={getItems}

            onSort={(column, type) => setSort({column, type})}
        />
    );
};

export default AccessListTable;

const StyledTable = styled(TableServerSort)`
    && {
        margin-bottom: 20px;
    }
`;