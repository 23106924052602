import React, {useRef, useEffect, useState} from "react";
import {Form, Schema, FormControl, InputNumber, Input, InputGroup, FlexboxGrid,} from "rsuite";
import CustomField from "pages/NewAccount/ContactCustomField";
import {BaseButton} from "components/base/BaseButton";
import Modal from "components/Modal";
import styled from "styled-components";
import { fixedFloat } from "utils";
import m from "definedMessages";

const {NumberType} = Schema.Types;

const ChangeRateModal = ({
    show,
    disabled,

    defaultFormValue,

    onSuccess,
    onCloseModal,
    formatMessage
}) => {
    const discountRef = useRef(null);
    const discountModel = Schema.Model({
        week_percent: NumberType()
            .min(0, "Min number is 0")
            .max(100, "Max number is 100")
            .isRequired(formatMessage(m.required)),
        month_percent: NumberType()
            .min(0, "Min number is 0")
            .max(100, "Max number is 100")
            .isRequired(formatMessage(m.required))
    });

    const [formState, setFormState] = useState(defaultFormValue);

    useEffect(() => {
        setFormState({
            week_percent: fixedFloat((defaultFormValue?.week_percent || 0) * 100),
            month_percent:  fixedFloat((defaultFormValue?.month_percent || 0) * 100)
        });
    }, [defaultFormValue]);

    const onChangeDiscount = () => {
        const {week_percent, month_percent, ..._} = formState;

        const percentWeek = parseInt(week_percent) / 100;
        const percentMonth = parseInt(month_percent) / 100;


        if (discountRef.current.check()) {
            onSuccess(percentWeek, percentMonth);
        }
    };

    return <Modal 
        width={480}
        show={show}
        title={formatMessage(m.changeDefaultDiscount)}
        onClose={onCloseModal}
        footer={false}
    >
        <StyledForm
            layout="inline"
            ref={discountRef}
            model={discountModel}
            formValue={formState}
            onChange={(formValue) => setFormState(formValue)}
        >
            <FlexboxGrid>
                <ProcentFlexboxGridItem>
                    <ProcentInputGroup>
                        <FormControl
                            disabled={disabled}
                            name="week_percent"
                            placeholder={`${formatMessage(m.weeklyDiscount)}...`}
                        />
                        <InputGroup.Addon>
                            %
                        </InputGroup.Addon>
                    </ProcentInputGroup>
                    <ProcentInputGroup>
                        <FormControl
                            disabled={disabled}
                            name="month_percent"
                            placeholder={`${formatMessage(m.monthlyDiscount)}...`}
                        />
                        <InputGroup.Addon>
                            %
                        </InputGroup.Addon>
                    </ProcentInputGroup>
                </ProcentFlexboxGridItem>
            </FlexboxGrid>
           

            <FormButton
                disabled={!formState?.week_percent || !formState?.month_percent} 
                onClick={onChangeDiscount}
            >
                {formatMessage(m.change)}
            </FormButton>
        </StyledForm>
    </Modal>
};

export default ChangeRateModal;



const StyledForm = styled(Form)`
    && > .rs-form-group {
        margin-bottom: 0px;
        margin-right: 15px;
    }
`;


const FormButton = styled(BaseButton).attrs(() => ({
    buttonStyle: "primary",
    size: "sm"
}))`
    &&& {
        height: 36px;
        margin-right: 5px;
        margin-bottom: 0px;
    }
`;

const ProcentInputGroup = styled(InputGroup)`
    && {
        width: 180px;
        height: 36px;
    }

    &&:first-child {
        margin-right: 20px;
    }
`;

const ProcentFlexboxGridItem = styled(FlexboxGrid.Item)`
    && {
        display: flex;
        flex-direction: row;
    }
`;