import React from 'react';
import {FormGroup, FormControl, ControlLabel, HelpBlock} from 'rsuite'

const widthValues = {
  "md": '200px'
};


class CustomField extends React.PureComponent {
    render() {
        const {
            name, message, label, labelWidth, accepter, error, width, useBr, withCheckbox,
            style: style_, mr, styleFormGroupExternal, styleLabelExternal, ...props
        } = this.props;

        const style = style_ ? {...style_} : {};

        if (width) {
            style.width = widthValues[width] || width;
        }
        const styleFormGroup = {...styleFormGroupExternal};
        (mr) && (styleFormGroup.marginRight = mr);

        const propsLabel = {style: {...styleLabelExternal}};
        if (labelWidth) {
            propsLabel.style.width = labelWidth;
        }

        return (
            <>
                <FormGroup className={error ? 'has-error' : ''} style={styleFormGroup}>
                    {label &&
                        <ControlLabel {...propsLabel} >{label} </ControlLabel>
                    }
                    <FormControl
                        name={name}
                        accepter={accepter}
                        errorMessage={error}
                        errorPlacement="topRight"
                        {...props}
                        style={style}
                    />
                    {message && <HelpBlock>{message}</HelpBlock>}
                </FormGroup>
                {useBr && <br/>}
            </>
        );
    }
}


export default CustomField;