import React from "react";
import * as S from "./styled";
import {Icon} from "rsuite";

export default ({title = null, show = false, callback = null, ...props}) => {

    const handleClick = (e) => {
        if (callback) 
            callback(e);

        return null;
    };

    return (
        <S.FlexBlock align="middle">
            {title &&
                <S.SectionTitle>{title}</S.SectionTitle>
            }
            <S.ToggleButton onClick={handleClick}>
                {show
                    ? <>
                        <Icon icon="angle-up"/> Hide
                    </>
                    : <>
                        <Icon icon="angle-down" /> Show
                    </>
                }
            </S.ToggleButton>
        </S.FlexBlock>
    )
};