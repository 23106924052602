import React from "react";
import Modal from "../../../../components/Modal";
import {Spacer} from "../../../../components/base/Spacer";

export default (
    {
        show,
        onClose,
    }
) => {
    return (
        <Modal
            show={show}
            onClose={onClose}
            title="Submit is denied"
        >
            <p>This Price is not allowed for dialer</p>
        </Modal>
    )
};