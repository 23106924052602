import React, { useMemo, useState, useEffect } from "react";
import { FlexboxGrid, ButtonGroup, Button, Grid, Row, Col, Placeholder, Loader, Icon} from "rsuite";
import { BaseButton, ButtonGhost, ButtonPrimary } from "components/base/BaseButton";
import ModalShowPicture from "components/Modal/ModalShowPicture";

import styled, {css} from "styled-components";
import m from "definedMessages";

import { range } from "utils";

const SettingsTemplateMenu = ({
    loading,
    issueWidth,
    currentHost,
    templateKey,
    templateList,

    setTemplateKey,

    formatMessage
}) => {

    const [templateLoading, setTemplateLoading] = useState(loading);
    const [loadedImages, setLoadedImages] = useState(0);

    const currentTemplate = useMemo(() => {
        return templateList.find(template => template.template_key === templateKey);
    }, [templateList, templateKey]);

    const currentDir = currentTemplate && currentTemplate?.dir 
        ? currentTemplate.dir
        : null;

    const carouselSrcList = range(4).map((number) => {
        return `${process.env.PUBLIC_URL}/templates/${currentDir}/${number+1}.png`;
    });

    useEffect(() => {
        if (currentHost) {
            setTemplateLoading(true);
            setLoadedImages(0);
        }
    }, [templateList]);

    useEffect(() => {
        setTimeout(() => {
            setTemplateLoading(false);
        }, 500);
    }, [loading]);

    const filteredTemplateList = templateList.filter((template) => !!template.template_key);

    return <TemplatesControlContainer>
        <TemplateControlPanel>
            <ButtonGroup vertical>
                {filteredTemplateList.map((template, idx) => {
                    const appearance = template.template_key === templateKey ? "secondary" : "ghost";
                    const isCurrentTemplate = template.template_key === currentHost?.template?.template_key;
                    return <BaseButton
                        buttonStyle={appearance}
                        onClick={() => {
                            setTemplateKey(template.template_key);
                        }}
                    >
                        {template.name} {isCurrentTemplate && <Icon icon="check-circle"/>}
                    </BaseButton>
                })}
            </ButtonGroup>
        </TemplateControlPanel>
        <HostInfoPanel issueWidth={issueWidth}>
            <span>{formatMessage(m.configurationInfo)}:</span>
            <StatusesInfo>{currentHost?.statuses}</StatusesInfo>
        </HostInfoPanel>



        <TemplateExamplePanel>
            <StyledGrid issueWidth={issueWidth}>
                {range(2).map((rowIdx) => {
                    return <Row>
                        {range(2).map((colIdx) => {
                            const idNumber = rowIdx * 2 + (colIdx + 1);
                            const currentPath = `${process.env.PUBLIC_URL}/templates/${currentDir}/${idNumber}.png`;

                            return <Col>
                                {!loading && currentDir
                                    ? <ModalShowPicture
                                        index={idNumber-1}
                                        carouselSrcList={carouselSrcList}
                                    >
                                        <StyledImage
                                            src={currentPath}
                                            onLoad={() => {
                                                setLoadedImages((value) => value+1);
                                            }}
                                        />
                                    </ModalShowPicture>
                                    : 
                                    <EmptyTemplateImage/>
                                }
                            </Col>
                        })}
                    </Row>
                })}
                {templateLoading && <Loader backdrop content="loading..." vertical />}
            </StyledGrid>
        </TemplateExamplePanel>
    </TemplatesControlContainer>
};

const EmptyTemplateImage = () => {
    return <Placeholder.Paragraph className="image" graph="image" rows={0}/>
};

/*
           <Row>
                    <Col xs={12}>
                        <img src={`${process.env.PUBLIC_URL}/templates/1.png`} width={500}/>
                    </Col>
                    <Col xs={12}>
                        <img src={`${process.env.PUBLIC_URL}/templates/1.png`} width={500}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <img src={} width={500}/>
                    </Col>

                    <Col xs={12}>
           
                    </Col>
                </Row>
*/

export default SettingsTemplateMenu;

const TemplatesControlContainer = styled(FlexboxGrid)`
`;

const TemplateControlPanel = styled(FlexboxGrid.Item)`
    && {
        width: 300px;
    }

    && .rs-btn-group {
        width: 280px;
    }

    && .rs-btn-group .rs-btn:first-child {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }

    && .rs-btn-group .rs-btn:last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
`;

const TemplateExamplePanel = styled(FlexboxGrid.Item)`
`;

const StyledGrid = styled(Grid)`
    && div.rs-row:not(:last-child) {
        margin-bottom: 10px;
    }

    && div.rs-row {
        display: flex;

        .rs-placeholder-paragraph-graph {
            margin-right: 0;
            background: #bed3c2;
            border: 2px solid lightgray;
            border-radius: 5px;
        }
        .rs-placeholder-paragraph-graph-image {
            ${props => props.issueWidth ? css`
                width: 150px;
                height: 80px;
            ` : css`
                width: 500px;
                height: 248px;
            `}
        }
        .rs-placeholder-paragraph-graph-image::before {
            left: calc(40px * 6.25);
            bottom: calc(10px * 6.25);
            border-bottom-width: calc(22px * 2.5);
            border-left-width: calc(22px * 3);
            border-right-width: calc(22px * 3);
        }
        .rs-placeholder-paragraph-graph-image::after {
            left: calc(20px * 6.25);
            bottom: calc(10px * 6.25);
            border-bottom-width: calc(36px * 3.25);
            border-left-width: calc(20px * 4);
            border-right-width: calc(25px * 4);
        }

        .rs-placeholder-paragraph-graph-image .rs-placeholder-paragraph-graph-inner {
            width: calc(12px * 3);
            height: calc(12px * 3);
            right: calc(33px * 5);
            top: calc(12px * 6.25);
        }
    }

    && div.rs-row .rs-col:not(:last-child) {
        margin-right: 10px;
    }

    && div.rs-col {
        display: flex;
        justify-content: center;
        padding: 0;
   

        ${props => props.issueWidth ? css`
            width: 150px;
        ` : css`
            width: 500px;
        `}
        
    }

    && div.rs-col img {
        ${props => props.issueWidth ? css`
            width: 150px;
        ` : css`
            width: 500px;
        `}
    }

    && .rs-loader-backdrop-wrapper {
        margin-bottom: 30px;
        margin-top: 20px;
        padding: 40px;
    }

    && .rs-loader-backdrop {
        background: (235, 235, 235, 0.6);
        height: calc(100% - 49px);
        border-radius: 5px;
    }
`;

const StyledImage = styled.img`
    && {
        border: 2px solid lightgray;
        border-radius: 5px;
    }
`;

const HostInfoPanel = styled.div`
    && {
        ${props => props.issueWidth ? css`
            margin-top: 15px;
            margin-bottom: 10px;
        ` : css`
            margin-left: 50px;
        `}


        display: flex;
        flex-direction: column;
    }
`;

const StatusesInfo = styled.pre`
    && {
        font-family: Roboto, Apple-System, Arial, Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif;
        font-size: 14px;
        color: #575757;
    }
`;