import React, {useEffect} from 'react';
import {Spacer} from "../../../../../components/base/Spacer";
import PageTabs from "../../../../../components/PageTabs";


const menuItems = [
    {id: "single", eventKey: "single", title: "Single prefix"},
    {id: "operator", eventKey: "operator", title: "Prefix by operator"},
    {id: "template", eventKey: "template", title: "Prefix by template"},
    {id: "mass", eventKey: "mass", title: "Mass prefixes"}
];


export default ({
    activeTab,
    onSelect
}) => {

    return (
            <>
                <PageTabs
                    {...{activeTab, onSelect, menuItems}}
                />
                <Spacer size={30}/>
            </>    
        )
}