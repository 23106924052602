import {getFileResponse} from "../api/loginRoutes";
import {
    SMS_SUPPLIER_PREFIX_GET_LIST_METHOD,
    SUBACC_TRUNK_GET_NUMBER_LIST_METHOD
} from "../const/apiMethods";
import {servicePick, downloadDataAsFile, getNumbersByDigit, getRangeByNumberDigit, getServiceSmsPrefix, downloadNumbersFile} from "./helpers";
import {Alert} from "rsuite";
import moment from "moment";


const FORMAT_NUMBERS_CSV = 'numbers-csv';
const FORMAT_PREFIX_CSV = 'prefix-csv';
const FORMAT_RANGES_CSV = 'ranges-csv';
const FORMAT_NUMBERS_TXT = 'numbers-txt';


function Perf() {
    this.startDate = 0
    this.start = () => this.startDate = performance.now();
    this.show = (string = 'new') => {
        const diff = moment.duration(performance.now() - this.startDate);
        console.log(string, `${diff._data.minutes || '00'}:${diff._data.seconds || '00'}:${diff._data.milliseconds || '000'}`);
    }
}

export const updateCsvTextNoDigits = (csvText) => {
    const [csvHeader, ...csvData] = csvText.split('\n');
    const csvHeaderData = csvHeader.split(';');
    const csvHeaderUpdated = [
        ...csvHeaderData
    ].join(';');

    return csvData
        .reduce((summ, item) => {
            if (!item) {
                return summ;
            }
 
            return [
                ...summ,
                item
            ]
        }, [csvHeaderUpdated])
        .join('\n');
}


export const updateCsvText = (csvText, format = '', isSubAcc=false) => {
    const [csvHeader, ...csvData] = csvText.split('\n');
    const csvHeaderData = csvHeader.split(';');

    const csvHeaderUpdated = [
        (format === FORMAT_NUMBERS_CSV && !isSubAcc) ? 'Number' : csvHeaderData[0],
        ...(format === FORMAT_PREFIX_CSV ? csvHeaderData.slice(1) : 
        isSubAcc ? csvHeaderData.slice(1) : csvHeaderData.slice(2))
    ].join(';');


    return csvData
        .reduce((summ, item) => {
            if (!item) {
                return summ;
            }
            

            const itemDataArray = item.split(';');

            if (isSubAcc) {
                return [
                    ...summ,
                    item
                ];
            }

            if (format === FORMAT_NUMBERS_CSV) {
                return [
                    ...summ,
                    ...getNumbersByDigit(itemDataArray[0], itemDataArray[1])
                        .map(item => [item, ...itemDataArray.slice(2)].join(';'))
                ]
            }

            if (format === FORMAT_NUMBERS_TXT) {
                return [
                    ...summ,
                    ...getNumbersByDigit(itemDataArray[0], itemDataArray[1])
                ]
            }

            if (format === FORMAT_PREFIX_CSV) {
                return [
                    ...summ,
                    [getRangeByNumberDigit(itemDataArray[0], itemDataArray[1]), ...itemDataArray.slice(1)]
                        .join(';')
                ];
            }

            return [
                ...summ,
                [getRangeByNumberDigit(itemDataArray[0], itemDataArray[1]), ...itemDataArray.slice(2)]
                    .join(';')
            ]
        }, format === FORMAT_NUMBERS_TXT ? [] : [csvHeaderUpdated])
        .join('\n');
}

// export const updateCsvTextWithoutDigits = (csvText, format = '') => {

//     const [csvHeader, ...csvData] = csvText.split('\n');
//     const csvHeaderData = csvHeader.split(';');
//     const csvHeaderUpdated = [
//         format === FORMAT_NUMBERS_CSV ? 'Number' : csvHeaderData[0],
//         ...csvHeaderData.slice(2)
//     ].join(';');

//     return csvData
//         .reduce((summ, item) => {
//             if (!item) {
//                 return summ;
//             }

//             const itemDataArray = item.split(';');

//             if (format === FORMAT_NUMBERS_CSV) {
//                 return [
//                     ...summ,
//                     ...getNumbersByDigit(itemDataArray[0], itemDataArray[1])
//                         .map(item => [item, ...itemDataArray.slice(2)].join(';'))
//                 ]
//             }

//             if (format === FORMAT_NUMBERS_TXT) {
//                 return [
//                     ...summ,
//                     ...getNumbersByDigit(itemDataArray[0], itemDataArray[1])
//                 ]
//             }

//             return [
//                 ...summ,
//                 [getRangeByNumberDigit(itemDataArray[0], itemDataArray[1]), ...itemDataArray.slice(2)]
//                     .join(';')
//             ]
//         }, format === FORMAT_NUMBERS_TXT ? [] : [csvHeaderUpdated])
//         .join('\n');
// }


const getNumbersCsv = async (params = {}, service=true, plan_type = null) => { /* TODO: change params every function call */
    const method = !plan_type 
        ? getServiceSmsPrefix(service, 'trunk_number__get_list')
        : 'sms.supplier_prefix:get_list';


    // target / get prefix
    const response = await getFileResponse(method, params);
    
    if (!response) {
        Alert.error("Something went wrong", 10000);
        return Promise.reject(response);
    }

    return response.text();
};


export const downloadTrunkNumbersCsv = (params, service, setLoading, fileName, afterAllocation = false) => {
    const methodFunc = afterAllocation ? getNumbersCsvAfterAllocation : getNumbersCsv;

    methodFunc(params, service)
        .then(csvText => {
            if (!csvText) {
                setLoading && setLoading(null);
            }

            downloadDataAsFile(csvText, fileName);
            setLoading && setLoading();
        })
        .catch(() => {
            setLoading && setLoading();
        })
}


const getNumbersCsvAfterAllocation = async (params = {}, service = true, isSubAcc = false) => {
    const method = !isSubAcc
        ? service ? "trunk_number:get_list" : "sms.trunk_number:get_list"
        : SUBACC_TRUNK_GET_NUMBER_LIST_METHOD;

    const response = await getFileResponse(method, params);

    if (!response) {
        Alert.error("Something went wrong", 10000);
        return Promise.reject(response);
    }

    return response.text();
};


export const downloadCompletedList = (trunkId, service, setLoading, isSubAcc=false, fileName = 'allocated_numbers.csv') => {
    const params = {
        target: {[servicePick(service, 'trunk_id', 'sms.trunk_id')]: trunkId},
        ...(isSubAcc ? {show_allocated: true, show_free: false} : {})
    };

    getNumbersCsvAfterAllocation(params, service, isSubAcc)
        .then(csvText => {
            if (!csvText) {
                setLoading && setLoading(null);
            }

            const updatedCsvText = updateCsvText(csvText, FORMAT_NUMBERS_CSV, isSubAcc);
            downloadDataAsFile(updatedCsvText, fileName);
            setLoading && setLoading();
        })
        .catch((error) => {
            setLoading && setLoading();
        })
}


export const downloadCompletedListAsPrefixes = (trunkId, service, setLoading, fileName = 'allocated_numbers.csv') => {
    const params = {target: {[servicePick(service, 'trunk_id', 'sms.trunk_id')]: trunkId}};

    getNumbersCsvAfterAllocation(params, service)
        .then(csvText => {
            if (!csvText) {
                setLoading && setLoading(null);
            }

            downloadDataAsFile(updateCsvText(csvText, FORMAT_RANGES_CSV), fileName);
            setLoading && setLoading();
        })
        .catch(() => {
            setLoading && setLoading();
        })
}


export const downloadNewNumbers = (transactionId, service, setLoading, fileName = 'numbers.csv') => {
    const params = {target: {[servicePick(service, 'trunk_number_transaction_id', 'sms.trunk_number_transaction_id')]: transactionId}};

    getNumbersCsvAfterAllocation(params, service)
        .then(csvText => {
            if (!csvText) {
                setLoading && setLoading(null);
            }

            downloadDataAsFile(updateCsvText(csvText, FORMAT_NUMBERS_CSV), fileName);
            setLoading && setLoading();
        })
        .catch(() => {
            setLoading && setLoading();
        })
}


export const downloadNewNumbersAsPrefixes = (transactionId, service, setLoading, fileName = 'numbers.csv') => {
    const params = {target: {[servicePick(service, 'trunk_number_transaction_id', 'sms.trunk_number_transaction_id')]: transactionId}};

    getNumbersCsvAfterAllocation(params, service)
        .then(csvText => {
            if (!csvText) {
                setLoading && setLoading(null);
            }

            downloadDataAsFile(updateCsvText(csvText, FORMAT_RANGES_CSV), fileName);
            setLoading && setLoading();
        })
        .catch(() => {
            setLoading && setLoading();
        })
}


export const downloadNewNumbersSimpleList = (transactionId, service, setLoading, fileName = 'numbers.txt') => {
    const params = {target: {[getServiceSmsPrefix(service, 'trunk_number_transaction_id')]: transactionId}};

    getNumbersCsvAfterAllocation(params, service)
        .then(csvText => {
            if (!csvText) {
                setLoading && setLoading(null);
            }

            downloadDataAsFile(updateCsvText(csvText, FORMAT_NUMBERS_TXT), fileName);
            setLoading && setLoading();
        })
        .catch(() => {
            setLoading && setLoading();
        })
}


export const downloadNumbersCommon = (trunkId, filter, setLoading, service, fileName = 'allocated_numbers.csv') => {
    const params = {
        target: {[getServiceSmsPrefix(service, 'trunk_id')]: trunkId},
        filter: filter || undefined,
    };

    getNumbersCsv(params, service)
        .then(csvText => {
            if (!csvText) {
                setLoading && setLoading(null);
            }

            downloadDataAsFile(updateCsvText(csvText), fileName);
            setLoading && setLoading();
        })
        .catch(() => {
            setLoading && setLoading();
        })
}

export const downloadNumbersTxtCommon = (trunkId, filter, setLoading, service, fileName = 'allocated_numbers.txt', plan_type = null, isSupplier = false) => {
    const trunkIdName = !plan_type ? getServiceSmsPrefix(service, 'trunk_id') : 'sms.supplier_trunk_id';
    const params = {
        target: {[trunkIdName]: trunkId},
        filter: filter || undefined,
    };

    getNumbersCsv(params, service, plan_type)
        .then(csvText => {
            if (!csvText) {
                setLoading && setLoading(null);
            }

            if (!isSupplier) {
                downloadDataAsFile(updateCsvText(csvText, FORMAT_NUMBERS_TXT), fileName);
            } else {
                const onlyNumbersArray = csvText.split('\n').slice(0).map(value => {
                    const valueArray = value.split(';');
                    return valueArray[0];
                });
                downloadNumbersFile(onlyNumbersArray, false, fileName);
            }
            setLoading && setLoading();
        })
        .catch(() => {
            setLoading && setLoading();
        })
}


export const downloadNumbersCommonClient = (csvText, fileName = 'allocated_numbers.csv') => {
    downloadDataAsFile(updateCsvText(csvText, FORMAT_NUMBERS_CSV), fileName);
}

export const downloadNumbersAsPrefixesCommonClient = (csvText, fileName = 'allocated_numbers.csv') => {
    downloadDataAsFile(updateCsvText(csvText), fileName);
}

export const downloadNumbersSimpleCommonClient = (csvText, fileName = 'allocated_numbers.txt') => {
    downloadDataAsFile(updateCsvText(csvText, FORMAT_NUMBERS_TXT), fileName);
}

const getPrefixCsvAfterCreating = async (params = {}) => {
    const method = SMS_SUPPLIER_PREFIX_GET_LIST_METHOD;

    const response = await getFileResponse(method, params);

    if (!response) {
        Alert.error("Something went wrong", 10000);
        return Promise.reject(response);
    }

    return response.text();
};

export const downloadPrefixList = (trunkId, setLoading, all = false, fileName = 'prefixes.csv', extraFormat = null) => {
    const params = {
        target: {"sms.supplier_trunk_id": trunkId},
        filter: {
            last_created: !all,
        },
        csv: true
    };

    getPrefixCsvAfterCreating(params)
        .then(csvText => {
            if (!csvText) {
                setLoading && setLoading(null);
            }

            const updatedCsvText = updateCsvText(csvText, extraFormat);

            downloadDataAsFile(updatedCsvText, fileName);
            setLoading && setLoading();
        })
        .catch(() => {
            setLoading && setLoading();
        })
};
