import React, {useMemo, useState, useEffect} from "react";
import { BaseToggle } from "components/base/BaseToggle";
import Page from "components/base/Page";
import PageHeader from "components/base/PageHeader";
import { ButtonPrimary } from "components/base/BaseButton";

import TrafficFilter from "./TrafficFilter";

import TrafficTable from "./TrafficTable";

import m from "definedMessages";

import {useIntl} from "react-intl";

import { DEFAULT_PER_PAGE_SMALL } from "const";
import { toStartDayTime, toEndDayTime, compareObjects } from "utils";
import { usePrevious, useWindowWidth } from "hooks";

import { FilterSectionFlexboxGrid, StyledItemFitWidth, StyledItemAutoMargin } from "components/base/FitContainers";

import styled from "styled-components";



export const CDR_TYPE = true;
export const CDR_REPORT_TYPE = false;


const Traffic = ({
    cdrList,
    cdrReportList,
    cdrSummaries,

    page,
    count,
    perPage,
    loading,

    
    subAccountList,
    accountJoinList,
    
    getSubAccountList,
    getTrafficCdrList,
    exportTrafficCdrList
}) => {

    const windowWidth = useWindowWidth();
    const isSmallMobile = windowWidth < 536;
    const isMobile = windowWidth < 767;
    const issueWidth = windowWidth < 1280;

    const {formatMessage} = useIntl();
    
    const today = new Date();

    const defaultCdrFilterValue = {
        range: [toStartDayTime(today), toEndDayTime(today)],
        filter_cli: "",
        filter_phone: "",
        subacc_id: null
    };

    const defaultCdrReportFilterValue = {
        range: [toStartDayTime(today), toEndDayTime(today)],
        group_list: ["subacc", "name", "cli", "phone"],
        filter_cli: "",
        filter_phone: "",
        subacc_id: null
    };


    const [trafficStatType, setTrafficStatType] = useState(CDR_TYPE);
    const [filterState, setFilterState] = useState(defaultCdrReportFilterValue);

    const prevFilterState = usePrevious(filterState);

    const isCdr = useMemo(() => {
        return trafficStatType === CDR_TYPE;
    }, [trafficStatType]);

    const defaultFilterValue = useMemo(() => {
        return isCdr ? defaultCdrFilterValue : defaultCdrReportFilterValue;
    }, [isCdr]); 

    const data = useMemo(() => {
        return trafficStatType ? cdrList : cdrReportList;
    }, [cdrList, cdrReportList, trafficStatType]);

    const dataWithSummary = useMemo(() => {
        return cdrSummaries && data.length ? [...data, cdrSummaries] : data;
    }, [data, trafficStatType, cdrSummaries]);

    const accountData = useMemo(() => {
        return accountJoinList.find((account) => {
            return account.service === "voice";
        })
    }, [accountJoinList]);

    useEffect(() => {
        setFilterState(defaultFilterValue);
    }, [defaultFilterValue])

    useEffect(() => {
        if (accountData && accountData.id) {
            update(false, true);
            getSubAccountList(accountData.id);
        }
    }, [accountData, defaultFilterValue]);

    const update = (defaultPagination=false, defaultFilter=false) => {
        const currentPage = defaultPagination ? 1 : page;
        const currentPerPage = perPage;

        const filter = defaultFilter ? defaultFilterValue : filterState;

        getTrafficCdrList(accountData.id, filter, trafficStatType, currentPage, currentPerPage);
    };

    const getItems = (_page, _perPage) => {
        if (accountData && accountData.id) {
            getTrafficCdrList(accountData.id, filterState, trafficStatType, _page, _perPage);
        }
    };

    return <Page>
        <PageHeader title={formatMessage(m.trafficStat)} showService={false}/>
        <BaseToggle
            key={trafficStatType}
            checkedChildren={formatMessage(m.cdr)}
            unCheckedChildren={formatMessage(m.trafficStat)}
            checked={trafficStatType}
            onChange={value => setTrafficStatType(value)}
        />
        <FilterSectionFlexboxGrid>
            <StyledItemFitWidth isMobile={isMobile}>
                <TrafficFilter
                    isCdr={isCdr}
                    filter={filterState}
                    disabled={loading}
                    isSmallMobile={isSmallMobile}
                    issueWidth={issueWidth}
          
                    subAccountList={subAccountList}
                    defaultFilter={defaultFilterValue}
                    formatMessage={formatMessage}
                    onChangeFilter={setFilterState}
                />
            </StyledItemFitWidth>
            <StyledItemAutoMargin isMobile={isMobile}>
                <ButtonPrimary
                    disabled={loading || compareObjects(filterState, defaultFilterValue)}
                    onClick={() => {
                        update(true);
                    }}
                >
                    {formatMessage(m.applyFilters)}
                </ButtonPrimary>
                <ButtonPrimary
                    disabled={loading}
                    onClick={() => {
                        if (accountData && accountData.id) {
                            exportTrafficCdrList(accountData.id, filterState, trafficStatType);
                        }
                    }}
                >
                    {formatMessage(m.exportReport)}
                </ButtonPrimary>
            </StyledItemAutoMargin>
        </FilterSectionFlexboxGrid>
        <TrafficTable
            data={dataWithSummary}
            page={page}
            count={count}
            perPage={perPage}
            loading={loading}
            isMobile={isMobile}
            formatMessage={formatMessage}
            isCdr={isCdr}
            filter={filterState}

            getItems={getItems}
        />
        
    </Page>
};

export default Traffic;