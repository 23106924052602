export default {
    // customer fields
    name: {
        name: 'name',
        label: 'Name',
        labelId: 'name'
    },
    skype: {
        name: 'skype',
        label: 'Skype',
        labelId: 'skype'
    },
    email: {
        name: 'email',
        label: 'Email',
        labelId: 'email'
    },
    cntr_code: {
        name: 'cntr_code',
        type: 'select_cntr_code',
        label: 'Country',
        labelId: 'cntr_code'
    },
    hf_key: {
        name: 'hf_key',
        type: 'hf_key_options',
        label: 'How did you find us',
        labelId: 'hf_key'
    },
    month_traffic_minute: {
        name: 'month_traffic_minute',
        type: 'integer',
        label: 'Monthly traffic (minutes)',
        labelId: 'month_traffic_minute'
    },
    cur_key: {
        name: 'cur_key',
        type: 'select_cur_key',
        label: 'Currency',
        labelId: 'cur_key'
    },
    traffic_type: {
        name: 'traffic_type',
        type: 'select_traffic_type',
        label: 'Traffic type',
        labelId: 'traffic_type'
    },
}

export const selectableFields = {
    select_traffic_type: [
        {
            name: 'Single CLI',
            id: 0
        },
        {
            name: 'Multiple CLI\'s',
            id: 1
        }
    ]
}

export const registrationFormFields = [
    'name',
    'skype',
    'email',
    'cntr_code',
    'hf_key',
    'month_traffic_minute',
    'cur_key',
    'traffic_type',
];