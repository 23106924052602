import React, {useEffect, useMemo, useState} from "react";
import {InputPicker} from "rsuite";


export default (
    {
        data,
        value,
        onChange,
        ...props
    }
) => {

    const [searchValue, setSearchValue] = useState(value);

    const dataUpdated = useMemo(() => searchValue
        ? [
            {
                [props.valueKey]: searchValue,
                [props.labelKey]: searchValue
            },
            ...data
        ]
        : data, [data, searchValue]);

    return <InputPicker
        data={dataUpdated}
        value={searchValue}
        onSearch={(val) => {
            setSearchValue(val);
            onChange(val);
        }}
        onChange={(val) => {
            setSearchValue(val);
            onChange(val);
        }}
        onClean={() => {
            setSearchValue(null);
            onChange(null);
        }}
        {...props}
    />;
};