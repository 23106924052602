import React, {useState, useRef, useEffect, useMemo} from 'react';
import {FlexboxGrid} from 'rsuite';
import LiveCallsTable from './LiveCallsTable';
import LiveCallsHeader from "./LiveCallsHeader";
import StopCallModal from './StopCallModal';
import PanelLayout from '../../components/base/PanelLayout';
import PageHeader from '../../components/base/PageHeader'
import {Spacer} from '../../components/base/Spacer';
import {LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE} from "../../const/localStorageKeys";


const searchKeysIncluses = ['acc_name_orig', 'acc_name_term',  'a_subdestination_name', 'b_subdestination_name'];
const searchKeysStarts = ['range', 'a_number', 'b_number'];


export default (
    {
        data,
        getLiveCalls,
        stopCall,
        loading,
        loadingItem,
    }
) => {

    const timerUpdater = useRef(null);
    const prevData = useRef(null);

    const [filteredData, setFilteredData] = useState([]);
    const [activeCallId, setActiveCallId] = useState(null);
    const [modalStopShow, setModalStopShow] = useState(false);
    const [filter, setFilter] = useState("");
    const [autoUpdateFormValue, setAutoUpdateFormValue] = useState(() => {
        const savedAutoUpdate = localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE);
        const timerValue = savedAutoUpdate && !isNaN(savedAutoUpdate) && parseFloat(savedAutoUpdate) <= 100000
            ? savedAutoUpdate
            : null;
        return {timer: timerValue || null}
    });


    useEffect(() => {
        prevData.current = data;
        getLiveCalls();
    }, [filter]);


    useEffect(() => {
        return () => {
            clearTimeout(timerUpdater.current);
            timerUpdater.current = null;
        }
    }, []);


    useEffect(() => {
        onTableDataUpdate();
    }, [autoUpdateFormValue]);


    useEffect(() => {
        if (data === prevData.current) {
            return;
        }

        const filteredData = data.filter( row => {
            for (let k of searchKeysIncluses){
                if(row[k].toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                    return true;
            }
            for (let k of searchKeysStarts){
                if(row[k].toLowerCase().startsWith(filter.toLowerCase())) {
                    return true;
                }
            }
            return false;
        });
        setFilteredData(filteredData);
    }, [data, filter]);


    const onUpdate = () => {
        getLiveCalls();
    };


    const handleStopClick = (id) => {
        setActiveCallId(id);
        setModalStopShow(true);
    };


    const onConfirmStopCallModal = () => {
        stopCall(activeCallId, onCancelStopCallModal)
    };


    const onCancelStopCallModal = () => {
        setModalStopShow(false);
        onUpdate();
    };


    const onTableDataUpdate = () => {

        if (timerUpdater.current) {
            clearTimeout(timerUpdater.current);
            timerUpdater.current = null;
        }

        if (!autoUpdateFormValue.timer) {
            return;
        }

        timerUpdater.current = setTimeout(() => {
            onUpdate();
        }, autoUpdateFormValue.timer * 1000);
    };


    const onChangeAutoUpdateValue = (value) => {
        const timer = isNaN(value.timer) || !parseFloat(value.timer) ? "" : value.timer;

        localStorage.setItem(LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE, timer);
        setAutoUpdateFormValue({timer});
    };


    return (
        <PanelLayout >

            <PageHeader title="Live calls"/>

            <FlexboxGrid >
                <FlexboxGrid.Item colspan={24}>
                    <LiveCallsHeader
                        autoUpdateFormValue={autoUpdateFormValue}
                        onChangeAutoUpdateValue={onChangeAutoUpdateValue}
                        onUpdate={onUpdate}
                        onChange={setFilter}
                        loading={loading}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <LiveCallsTable
                onStop={handleStopClick}
                data={filteredData}
                loading={loading}
                onDataUpdated={onTableDataUpdate}
            />

            <Spacer />

            <StopCallModal
                size={'xs'}
                show={modalStopShow}
                loading={loadingItem}
                onCancel={onCancelStopCallModal}
                onConfirm={onConfirmStopCallModal}
            />
        </PanelLayout>
    )
}