import React, {useState, useEffect} from "react";
import {Icon, IconButton, Table} from "rsuite";
import RenderedColumns from '../../components/Table/RenderColumns';
import styled from "styled-components";
import BaseTable from "../../components/base/BaseTable";

const {Cell, Column, HeaderCell} = Table;

const columns = [
    {
        id: 'account_user_manager_name',
        label: "Manager",
        value: ({date, account_user_manager_name, session_cnt}) => date
            ? <div className="row-inner-indent" style={{display: 'flex'}}>
                <div style={{width: '50%'}}>{date}</div>
                <div style={{width: '50%'}}>{session_cnt || 0}</div>
            </div>
            : account_user_manager_name,
        minWidth: 300,
        flexGrow: 2,
    },
    {
        id: 'session_cnt',
        label: "Sessions",
        value: ({session_cnt, first_action, last_action, date}) => date
            ? <div style={{display: 'flex'}}>
                <div style={{width: '50%'}}>{first_action}</div>
                <div style={{width: '50%'}}>{last_action}</div>
            </div>
            : session_cnt || 0,
        minWidth: 300,
        flexGrow: 1,
    },
    {
        id: 'activity_duration',
        label: "Activity duration",
        value: ({activity_duration}) => activity_duration || '00:00:00',
        width: 200,
    },
    {
        id: 'actions',
        label: "Actions",
        value: ({actions}) => actions || 0,
        width: 200,
    },
    {
        id: 'allocations',
        label: "Allocated numbers",
        value: ({allocations}) => allocations,
        width: 200,
    }
];


export default ({
    data = [],
    loading,
    toggleParamsModal,
    rowKey="account_user_manager_id",
    expandedRowData = [],
    handleExpanded,
    dateRange,
    ...props
}) => {

    const [dailyItemsLength, setDailyItemsLength] = useState(0);
    useEffect( () => {
        if (dateRange) {
            const days = (dateRange[1] - dateRange[0]) / 1000 / 3600 / 24;
            setDailyItemsLength( Math.floor(days) );
        }
    }, [dateRange] );

    const extendedData = expandedRowData.length
        ?  data.reduce((result, nextValue) => {
            const expandedData = expandedRowData.find(item => item.id === nextValue[rowKey]);
            if (expandedData && expandedData.list.length) {
                result.push({...nextValue, rowClassName: 'row-inner-parent'});
                result.push({
                    date: 'Date',
                    session_cnt: 'Sessions',
                    activity_duration: "Activity duration",
                    actions: "Actions",
                    allocations: "Allocated numbers",
                    first_action: 'First action',
                    last_action: 'Last action',

                    rowClassName: 'row-inner-header row-inner',
                    inner_header: true,
                    inner: true,
                });
                expandedData.list.forEach((value) => {
                    result.push({...value, rowClassName: 'row-inner', inner: true});
                });
            } else {
                result.push(nextValue);
            }
            return result
        }, [])
        : data;

    return (
        <StyledTable
            shouldUpdateScroll={false}
            virtualized
            rowHeight={30}
            height={700}
            data={extendedData}
            rowClassName={(rowData) => {
                return (rowData && rowData.rowClassName) || null;
            }}
            {...{
                loading
            }}
            {...props}
        >
            {RenderedColumns(columns, null)}

            {dailyItemsLength &&
                <Column width={70} align="center">
                    <HeaderCell>#</HeaderCell>
                    <ExpandCell
                        dataKey={rowKey}
                        onChange={handleExpanded}
                        expandedRowData={expandedRowData}
                    />
                </Column>
            }
        </StyledTable>
    )
}


const ExpandCell = ({ rowData, dataKey, expandedRowData, onChange, ...props }) => {
    const isExpanded = expandedRowData.some(item => item.id === rowData[dataKey]);
    return !rowData.inner
        ? <Cell {...props}>
            <IconButton
                size="xs"
                appearance="subtle"
                onClick={() => onChange(rowData[dataKey], isExpanded)}
                icon={<Icon icon={isExpanded ? 'minus-square-o' : 'plus-square-o'}/>}
            />
        </Cell>
        : null
};

const StyledTable = styled(BaseTable)`
    &&& {
    
        .row-inner .row-inner-indent {
            padding-left: 25px;
        }
    
        .row-inner-parent {
            .rs-table-cell-content {
                background-color: #e5e9f2;
            }
        }
        
        .row-inner-header {
            .rs-table-cell-content {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }
`;