import React, {useMemo, useState, useEffect} from "react";
import PageHeader from "components/base/PageHeader";
import PanelLayout from "components/base/PanelLayout";
import IncorporatedNumbersGroup from "./IncorporatedNumbersGroup/IncorporatedNumbersGroup";
import IncorporatedNumbersList from "./IncorporatedNumbersList/IncorporatedNumbersList";
import { Spacer } from "components/base/Spacer";
import {useLocation} from "react-router";
import queryString from "query-string";
import { getManagedServices } from "utils/helpers";
import { transformed_service_reverse_data } from "const";

export default (
    {
        trunks,
        ivrList,
        service,
        authInfo,
        suppliers,
        suppliersLoading,
        groupList,
        groupListLoading,
        suppliersGroupList,
        suppliersGroupLoading,
        supplierList,
        subdestinationList,
        destinationList,
        paymentTermsList,
        currencyList,
        numberList,
        numberCount,
        permissions,
        getTrunks,
        getSuppliers,
        numberListLoading,
        supplierPrefixCount,
        suppliersPrefixList,
        suppliersPrefixLoading,
        supplierListFiltered,

        getIvrList,
        getSupplierNumbers,
        getSupplierGroups,
        getGanSuppliersCommonList,
        getIncorporatedNumbersGroups,
        getIncorporatedNumbersList,
        getIncorporatedNumbersSuppliers,
        getAccountManagersInformators,
        clearIncorporatedNumberList,
        showReasonHandler,
    }
) => {

    const rowKey = service ? "ing_key" : "id";

    const location = useLocation();
    const [currentGroup, setCurrentGroup] = useState(null);
    const [groupsFilter, setGroupsFilter] = useState(() => ({
        actual_price: queryString.parse(location.search)["without-payout-only"] === "1"
    }));

    const [numbersFilter, setNumbersFilter] = useState({
        show_allocated_numbers: true,
        show_unallocated_numbers: true,
        show_test_numbers: true,
        show_block_allocation_numbers: true,
    });

    useEffect(() => {
        if (service) {
            getIvrList();
        }
    }, [service]);

    const data = useMemo(() => {
        return service ? groupList || [] : suppliersGroupList || [];
    }, [service, groupList, suppliersGroupList]);

    const numberData = useMemo(() => {
        return service ? numberList || [] : suppliersPrefixList || [];
    }, [service, numberList, suppliersPrefixList])

    const numberDataCount = useMemo(() => {
        return service ? numberCount || [] : supplierPrefixCount || [];
    }, [service, numberCount, supplierPrefixCount])

    const currentGroupData = useMemo(() => (
        currentGroup 
            ? data.find(item => rowKey in item && item[rowKey] === currentGroup)
            : null
    ), [currentGroup, data]);

    const loading = groupListLoading || suppliersGroupLoading || false;
    const roleList = authInfo?.session?.account_user?.role_list;
    const isRatemod = roleList && roleList.length ? (roleList.includes("Ratemod") || roleList.includes("Ratemod (sms)")) : null;

    const currentServiceTransformed = transformed_service_reverse_data[service];

    const accountManangedServiceList = useMemo(() => {
        return getManagedServices(authInfo)
    }, [authInfo]);

    const serviceIsCorrect = accountManangedServiceList.includes(currentServiceTransformed);

    return (
        <PanelLayout>

            <PageHeader title="Global access numbers"></PageHeader>
            

            {/* TODO: getSupplierNumbers */}

            <IncorporatedNumbersGroup
                data={data}
                loading={loading}
                filter={groupsFilter}
                setFilter={setGroupsFilter}
                isRatemod={isRatemod}
                {...{
                    rowKey,
                    ivrList,
                    service,
                    trunks,
                    serviceIsCorrect,
                    suppliers,
                    suppliersLoading,
                    permissions,
                    numbersFilter,
                    supplierList,
                    subdestinationList,
                    destinationList,
                    paymentTermsList,
                    currencyList,
                    getTrunks,
                    getSuppliers,
                    getSupplierGroups,
                    getSupplierNumbers,
                    getGanSuppliersCommonList,
                    getIncorporatedNumbersList,
                    getIncorporatedNumbersGroups,
                    getIncorporatedNumbersSuppliers,
                    getAccountManagersInformators,
                    clearIncorporatedNumberList,
                    setCurrentGroup,
                    currentGroup,
                    showReasonHandler,
                    supplierListFiltered
                }}
            />

            <Spacer size={30}/>

            <IncorporatedNumbersList
                data={numberData}
                count={numberDataCount}
                loading={numberListLoading}
                filter={numbersFilter}
                setFilter={setNumbersFilter}
                {...{
                    rowKey,
                    service,
                    groupsFilter,
                    permissions,
                    currentGroup,
                    currentGroupData,
                    destinationList,
                    subdestinationList,
                    getSupplierGroups,
                    getSupplierNumbers,
                    getIncorporatedNumbersList,
                    getIncorporatedNumbersGroups,
                    showReasonHandler,
                }}
            />

        </PanelLayout>
    )
}