import {defineMessages} from "react-intl";

export default defineMessages({
    exportPrice: { id: "price.exportPrice", defaultMessage: "Export price", description: 'Price: Export price' },
    exportPriceList: { id: "price.exportPriceList", defaultMessage: "Export price list", description: 'Price: Export price list' },
    exportPriceListAs: { id: "price.exportPriceListAs", defaultMessage: "Export price list as {type}", description: 'Price: Export price list as {type}' },
    filterPerPrefixOrRangeName: {
        id: "price.filterPerPrefixOrRangeName",
        defaultMessage: "Filter per prefix or range name",
        description: 'Price: Filter per prefix or range name'
    },
    rangeName: {
        id: "price.rangeName",
        defaultMessage: "Range name",
        description: 'Price: Range name'
    },
    groupName: {
        id: "price.groupName",
        defaultMessage: "Group name",
        description: 'Price: Group name'
    },
    getNumbersModal: {id: "price.getNumbersModal", defaultMessage: "Numbers(max {count})", description: 'Price, PriceGetNumbersModal: Numbers(max value)'},
    getNumbersForRange: {id: "price.getNumbersForRange", defaultMessage: "Get numbers for range", description: 'Price, PriceGetNumbersModal: Get numbers for range'},
    allTheTrafficMustBeClearedByTheCarrier: {
        id: "priceTable.allTheTrafficMustBeClearedByTheCarrier",
        defaultMessage: "Warning: all the traffic must be cleared by the carrier. Premiumy reserves the right to withhold the payment in case of dispute. Simultaneous calls from same A-numbers to same B-numbers are not allowed by the panel.",
        description: "PriceTable: Warning: all the traffic must be cleared by the carrier. Premiumy reserves the right to withhold the payment in case of dispute. Simultaneous calls from same A-numbers to same B-numbers are not allowed by the panel."
    },
    allTheTrafficWillBePaidOnMonthlyBasis: {
        id: "priceTable.allTheTrafficWillBePaidOnMonthlyBasis",
        defaultMessage: "All the traffic with less than {count} seconds duration will be paid on monthly basis",
        description: "PriceTable: All the traffic with less than {count} seconds duration will be paid on monthly basis"
    },
    pemiumyDoesntPayForCallsBelowDuration: {
        id: "priceTable.pemiumyDoesntPayForCallsBelowDuration",
        defaultMessage: "Premiumy doesn’t to pay for the calls below {count} seconds duration.",
        description: "PriceTable: Premiumy doesn’t to pay for the calls below {count} seconds duration."
    },
})