import React, {useContext, useState} from 'react';
import {connect} from 'react-redux';
import {Button} from "rsuite";
import styled, {css} from "styled-components";
import {APP_TYPE_CLIENT} from 'const';
import {RtlContext} from 'App';
import {CustomModal} from 'components/base';
import m from "../definedMessages";
import {useWindowWidth} from '../hooks'
import {useIntl} from 'react-intl'
import {logoutUser, rulesAccept} from '../actions/auth'


const RulesRegulationWidget = ({lang, link_english, link_arabic, text_english, text_arabic, rulesNotAccepted, rulesAccept, authInfo, logoutUser, appType}) => {
    const rtl = useContext(RtlContext);
    const {formatMessage} = useIntl();

    const windowWidth = useWindowWidth();
    const [showModal, onChangeShowModal] = useState(null);
    const [firstLine, ...text] = (rtl ? text_arabic : text_english).split('\n');

    const isTest = authInfo?.session?.is_test;

    if (appType !== APP_TYPE_CLIENT || isTest) {
        return null;
    }

    const isMobile = windowWidth < 720;

    return (
        <>
            <RulesButton
                rtl={rtl}
                appearance="link"
                onClick={onChangeShowModal}
            >
                {rtl ? link_arabic : link_english}
            </RulesButton>
            <CustomModal
                show={showModal || (showModal === null && (rulesNotAccepted === null || rulesNotAccepted))}
                showFooter={rulesNotAccepted === null || rulesNotAccepted}
                confirmButtonText={formatMessage(m.accept)}
                title={rtl ? link_arabic : link_english}
                onConfirm={() => {
                    rulesAccept(lang);
                    onChangeShowModal(false)
                }}
                onClose={() => {
                    if (rulesNotAccepted === null || rulesNotAccepted) {
                        logoutUser();
                    }
                    onChangeShowModal(false)
                }}
                width={isMobile ? windowWidth : 720}
            >
                {rulesNotAccepted ? <StyledHeader><b>{formatMessage(m.rulesAndRegulationsWereUpdated)}</b></StyledHeader> : <></>}
                <StyledText>
                    {firstLine}
                    {text.map(line => {
                        return <><br/>{line}</>
                    })}
                </StyledText>
                <ExtraFooter>
                    {rulesNotAccepted === false && isMobile ? <StyledCancelButton
                        onClick={() => {
                            onChangeShowModal(false)}
                        }
                    >
                        {formatMessage(m.cancel)}
                    </StyledCancelButton> : <></>}
                </ExtraFooter>
            </CustomModal>
        </>
    );
}

const StyledHeader = styled.div`
    && {
        color: #fd5e59;
    }
`;

const RulesButton = styled(Button)`
    && {
        position: relative;
        bottom: 20px;
        left: 35px;
        width: 36px;
        
        @media (max-width: 767px) {
            margin-top: 20px;
            bottom: 20px;
            left: 10px;
        }
        
        ${props => props.rtl && css`
            right: 35px;
            left: auto;
    
            @media (max-width: 767px) {
                right: 10px;
                left: auto;
            }
        `}
    }
`;

const ExtraFooter = styled.div`
    display: flex;
    justify-content: end;
    padding-right: 10px;
`;

const StyledCancelButton = styled(Button)`
    margin-top: 20px;
`;

const StyledText = styled.p`
    text-align: justify;
    padding-right: 10px;
    
    .rtl & {
        padding-left: 10px;
        padding-right: 0;
    }
`;


const mapState = ({references, auth_dialer, auth})=> ({
    link_english: references.rules_and_regulations_link_english,
    link_arabic: references.rules_and_regulations_link_arabic,
    text_english: references.rules_and_regulations_text_english,
    text_arabic: references.rules_and_regulations_text_arabic,
    rulesNotAccepted: auth.rulesNotAccepted,
    dialerInfo: auth_dialer.dialerInfo,
    authInfo: auth.authInfo,
    appType: auth.appType,
    lang: auth.lang
});

export default connect(mapState, {rulesAccept, logoutUser})(RulesRegulationWidget);