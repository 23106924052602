import React from 'react';
import {Modal as RsuiteModal} from "rsuite";
import {ButtonContent, ButtonPrimary} from '../base/BaseButton';
import {CustomModal} from '../base/BaseModal';

export const Modal = ({
    show, 
    onClose, 
    onSuccess,
    checkBefore, 
    children, 
    title, 
    width, 
    disabled,
    footer = false, 
    successText = "Ok",
    cancelText = "Cancel",
    extraDisabled = false,
    extraDialogClassName="",

    successButtonColor = "#" +
    "20BA88",
    onEntered
        = () => {

    }
}) => {

    const style = {};
    width && (style.width = width);

    return (
        <CustomModal
            show={show}
            as={RsuiteModal}
            onHide={onClose}
            style={style}
            onEntered={onEntered}
            dialogClassName={extraDialogClassName}
        >

            <RsuiteModal.Header>
                <RsuiteModal.Title>{title}</RsuiteModal.Title>
            </RsuiteModal.Header>

            <RsuiteModal.Body>
                {children}
            </RsuiteModal.Body>
            
            {footer && 
                <RsuiteModal.Footer
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around'
                    }}>
                    <ButtonPrimary
                        color={successButtonColor}
                        loading={disabled}
                        disabled={extraDisabled}
                        onClick={() => {
                            if(checkBefore && !checkBefore()) return false;
                            onSuccess()
                        }}
                    >
                        {successText}
                    </ButtonPrimary>
                    <ButtonContent
                        onClick={onClose}
                        disabled={disabled}
                    >
                        {cancelText}
                    </ButtonContent>
                </RsuiteModal.Footer>
            }

        </CustomModal>
    );
};

export default Modal;