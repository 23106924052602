import React, {useMemo} from "react";
import TableEditCellsNew from "../../components/Table/TableEditCells/TableEditCellsNew";
import {Input, SelectPicker, Schema, Button, Icon} from "rsuite";
import styled from "styled-components";


const {StringType} = Schema.Types;

const formModelLogin = Schema.Model({
    login: StringType()
        .isRequired('Required')
});

const formModelEmail = Schema.Model({
    email: StringType()
        .isRequired('Required')
});

const formModelRoleList = Schema.Model({
    role_list: StringType()
        .isRequired('Required')
});


export default (
    {
        data,
        adminRoleList,
        getManagerAccountUser,
        setActiveUserId,
        setShowModalRemove,
        setShowModalCreate,
        ...props
    }
) => {

    const columns = useMemo(() => [
        {
            name: 'Login',
            dataKey: "login",
            flexGrow: 2,
            title: true,

            accepter: Input,
            placeholder: 'Login',
            model: formModelLogin,
        },
        {
            name: 'Name',
            dataKey: "name",
            value: ({name, surname}) => `${name}${surname ? ' ' + surname : ''}`,
            flexGrow: 2,
            title: true,

            accepter: Input,
            placeholder: 'Name',
            model: formModelLogin,
        },
        {
            name: 'Email',
            dataKey: "email",
            flexGrow: 2,
            title: true,

            accepter: Input,
            placeholder: 'Email',
            model: formModelEmail,
        },
        {
            name: 'Role list',
            dataKey: "role_list",
            value: ({role_list}) => role_list.join(", "),
            flexGrow: 2,
            title: true,

            accepter: SelectPicker,
            placeholder: 'Role list',
            model: formModelRoleList,
            defaultValue: ({role_list}) => role_list[0],

            searchable: false,
            cleanable: false,
            data: adminRoleList
                ? adminRoleList.map(x => ({value: x, label: x}))
                : [],
        },
        {
            name: '',
            dataKey: "actions",
            value: (rowData) => {
                return (
                    <div className="tableFilters_buttons">
                        <Button
                            size="sm"
                            color="lightblue"
                            onClick={() => {
                                setActiveUserId(rowData.id);
                                setShowModalCreate(true);
                            }}
                        >
                            <Icon icon="edit2"/>
                        </Button>
                        <Button
                            size="sm"
                            color="red"
                            onClick={() => {
                                setActiveUserId(rowData.id);
                                setShowModalRemove(true);
                            }}
                        >
                            <Icon icon="trash2"/>
                        </Button>
                    </div>
                )
            }
        }
    ], [adminRoleList]);


    return (
        <StyledTableWrapper>
            <TableEditCellsNew
                data={data}
                columns={columns}
                height={700}
                autoHeight={false}
                editable={false}
                showFormCreate={false}
                rowClassName={(rowData) => rowData && !rowData.active ? "disabled" : ""}
                {...props}
            />
        </StyledTableWrapper>
    )
};

const StyledTableWrapper = styled.div`
    .rs-table-row.disabled:after {
        content: '';
        position: absolute;
        opacity: 0.2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #4c4c59;
        pointer-events: none;
    }
`;