import React, {Component} from 'react';
import {sortData} from "utils";
import {ASC_SORT} from "const";
// import Table from './Table';
import TableCommonNew from './TableCommonNew';


function TableClientSortHOC(TableComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                sortType: props.defaultSortType || props.sortType || ASC_SORT,
                sortColumn: props.defaultSortColumn || props.sortColumn || props?.columns?.[0]?.dataKey,
                data: props.data || []
            };
        }

        componentDidMount() {
            const {data, sortType, sortColumn} = this.state;

            if (data.length && sortType && sortColumn) {
                this.setState({
                    data: sortData(data, sortColumn, sortType)
                })
            }
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            const {sortType, sortColumn, defaultSortType, defaultSortColumn} = this.props;

            //Apply when page changed or count per page
            if (this.props.data !== prevProps.data) {
                this.setState({
                    data: sortData(this.state.data, this.state.sortColumn, this.state.sortType)
                })
            }

            if (prevProps.sortType !== sortType || prevProps.sortColumn !== sortColumn) {
                this.setState({
                    sortType: sortType || defaultSortType ||  ASC_SORT,
                    sortColumn: sortColumn || defaultSortColumn || this.props?.columns?.[0]?.dataKey,
                    data: sortData(this.state.data, sortColumn, sortType),
                });
            }
        }

        static getDerivedStateFromProps(props, state) {
            return {
                data: props.data,
            };
        }

        handleSortColumn = (column, type) => {
            this.setState({
                sortColumn: column,
                sortType: type,
                data: sortData(this.state.data, column, type),
            });
        };

        render() {
            const {...props} = this.props;

            return (
                <>
                    <TableComponent
                        {...props}
                        originalColumns={props?.originalColumns ? props.originalColumns : props.columns}
                        data={this.state.data}
                        onSortColumn={this.handleSortColumn}
                        sortType={this.state.sortType}
                        sortColumn={this.state.sortColumn}
                    />
                </>
            );
        }
    }
}



export {TableClientSortHOC};
export default TableClientSortHOC(TableCommonNew);

