import {REASON_HANDLER as PREFIX} from '../const';

const initialState = {
    params: {},
    show: false,
};

const handlers = {
    ['SHOW_' + PREFIX]: (state, {payload}) => ({
        ...state,
        show: true,
        params: payload,
    }),
    ['CLOSE_' + PREFIX]: (state) => ({
        ...state,
        show: false,
        params: {},
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}