import {GAN_SUPPLIERS as PREFIX} from '../const';
import {
    GAN_SUPPLIERS_COMMON_LIST_API,
    GAN_SUPPLIERS_LIST_API,
} from '../const/apiMethods';

import {createAction} from './defaults';
import {api} from "../api/loginRoutes";
import {servicePick, getServiceSmsPrefix} from "../utils/helpers";


const setGanSuppliersList = createAction('SET_ITEMS_' + PREFIX);
export const clearGanSuppliersList = createAction('CLEAR_ITEMS_' + PREFIX);
const setGanSuppliersCommonList = createAction('SET_ITEMS_COMMON_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);


export const getGanSuppliersList = (filter) => (dispatch) => {

    const params = {filter};

    dispatch( setLoading(true) );
    api(GAN_SUPPLIERS_LIST_API, params)
        .then((r) => {

            if (r === undefined || !r.supplier_list) {
                dispatch( setLoading(false) )
                return;
            }

            dispatch( setGanSuppliersList({
                list: r.supplier_list,
            }) );
            dispatch( setLoading(false) )
        });
};


export const getGanSuppliersCommonList = (service, filter) => (dispatch) => {

    const params = {filter};

    dispatch( setLoading(true) );
    const method = getServiceSmsPrefix(service, GAN_SUPPLIERS_COMMON_LIST_API);

    api(method, params)
        .then((r) => {
            const checkKey = servicePick(service, "supplier_list", "sms.supplier_list");

            if (r === undefined || !r[checkKey]) {
                dispatch( setLoading(false) )
                return;
            }

            dispatch( setGanSuppliersCommonList({
                list: r[checkKey],
            }) );
            dispatch( setLoading(false) )
        });
};