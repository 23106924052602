import {MANAGER_ACCOUNT_USER as PREFIX} from '../const';
import {
    MANAGER_ACCOUNT_USER_GET_DROPDOWN_LIST,
    MANAGER_ACCOUNT_USER_GET_LIST,
} from '../const/apiMethods';

import {createAction} from './defaults';
import {api} from "../api/loginRoutes";

const setManagerAccountUserList = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);
const setManagerAccountUserDropdownList = createAction('SET_DROPDOWN_ITEMS_' + PREFIX);
const setDropdownLoading = createAction('SET_DROPDOWN_LOADING_' + PREFIX);


export const getManagerAccountUser = () => (dispatch) => {
    dispatch( setLoading(true) );
    api(MANAGER_ACCOUNT_USER_GET_LIST)
        .then((r) => {
            if (r === undefined || !r.account_user_list) {
                return;
            }
            dispatch( setManagerAccountUserList({
                list: r.account_user_list
            }) );
        })
        .finally(() => {
            dispatch( setLoading(false) )
        });
};


export const getManagerAccountUserDropdownList = () => (dispatch) => {
    dispatch( setLoading(true) );
    api(MANAGER_ACCOUNT_USER_GET_DROPDOWN_LIST)
        .then((r) => {
            if (r === undefined || !r.account_user_dropdown_list) {
                return;
            }
            dispatch( setManagerAccountUserDropdownList({
                list: r.account_user_dropdown_list
            }) );
        })
        .finally(() => {
            dispatch( setDropdownLoading(false) )
        });
};