import {useEffect, useState} from "react";
import {customDebounce} from "../utils";


export default () => {

    const [resizedWidth, setWidth] = useState(window.innerWidth);

    useEffect(() => {

        const debouncedHandleResize = customDebounce(function handleResize() {
            setWidth(window.innerWidth);
        }, 300);

        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);

    return resizedWidth;
}