/* 
Component name: Table Edit Cells
Product: ATX
Author: Applesinkin

Please, do not change it. You can fork it to make some changes...
*/


import React, {useState, useEffect, useRef} from "react";
import {Button, FormControl, Icon, Table, Form} from "rsuite";
import styled from "styled-components";
import BaseTable from "../../../components/base/BaseTable";
import ModalSubmitRowApprove from "./ModalSubmitRowApprove";
import ModalRemoveRow from "./ModalRemoveRow";
import FormCreateRow from "./FormCreateRow";

const {Cell, Column, HeaderCell} = Table;


export default ({
    data = [],
    loading,
    columns,
    idKey = "id",
    formModel = {},
    editable = true,

    canRemove = true,
    canCreate = true,
    canModify = true,

    onDataUpdate,
    onModifyRow,
    onRemoveRow,
    checkParamsBeforeModify,

    ivrList,

    ...props
}) => {

    const refs = new Map();

    const [editedRows, setEditedRows] = useState(new Map());
    const [rowsLoading, setRowsLoading] = useState([]);

    const [modalRemoveData, setModalRemoveData] = useState(null);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [removeDataLoading, setRemoveDataLoading] = useState(null);

    const [modalApproveData, setModalApproveData] = useState(false);
    const [showModalApprove, setShowModalApprove] = useState(false);
    const [modalApproveLoading, setModalApproveLoading] = useState(null);


    useEffect(() => {
        // on data updated
        const editedRowsCopy = new Map(editedRows);
        const rowsLoadingCopy = new Set(rowsLoading);
        for (const editedRowData of editedRows) {
            const rowData = data.find(item => item[idKey] === editedRowData[0]);
            if (rowData && JSON.stringify(rowData) !== JSON.stringify(editedRowData[1])) {
                editedRowsCopy.delete(rowData[idKey]);
                rowsLoadingCopy.delete(rowData[idKey]);
            }
        }
        setEditedRows(editedRowsCopy);
        setRowsLoading(Array.from(rowsLoadingCopy));
    }, [data]);


    const handleModifyRow = async (rowData) => {

        // check form
        const rowForms = refs.get(rowData[idKey]);

        if (!rowForms) {
            setRowEditing(rowData, false);
            return;
        }

        for ( const form of Object.keys(rowForms) ) {
            if (!rowForms[form].check()) {
                return;
            }
        }


        // get params
        const params = Object.keys(rowForms)
            .reduce( (sum, current) => (
                {...sum, [current]: rowForms[current].getFormValue()[current]}
            ), {[idKey]: rowData[idKey]} );


        // check differences
        const hasDifferences = Object.keys(params)
            .reduce( (sum, current) => {
                if (sum === true)
                    return sum;
                const value = ( rowData[current] !== null && !isNaN(rowData[current]) )
                    ? +params[current]
                    : params[current];
                return rowData[current] !== value;
            }, false );


        if (!hasDifferences) {
            setRowEditing(rowData, false);
            setRowLoading(rowData, false);
            return;
        }


        // Approve modal
        if (checkParamsBeforeModify) {
            const notApproved = checkParamsBeforeModify(params);
            if (notApproved) {
                setShowModalApprove(true);
                setModalApproveData(params);
                return;
            }
        }

        if (!onModifyRow) {
            return;
        }

        // approve modal with text and condition and on submit, remove loading when open. Modal is hear
        // optimize refs array. share callbacks (row edit loading) in function except calss component
        setRowLoading(rowData, true);
        const res = await onModifyRow(params, rowData, setRowEditing, setRowLoading);
        
        setRowLoading(rowData, false);
        
        if (!res) {
            return;
        }

        setRowEditing(rowData, false);

        if (onDataUpdate) {
            onDataUpdate();
        }
    };


    const handleApprovedSubmit = async () => {
        if (!onModifyRow) {
            return;
        }
        setModalApproveLoading(true);
        await onModifyRow(modalApproveData);
        setModalApproveLoading(false);
        setShowModalApprove(false);
        
        if (onDataUpdate) {
            onDataUpdate();
        }
    };


    const handleDeleteRow = async () => {
        if (!onRemoveRow) {
            return;
        }
        setRemoveDataLoading(true);
        const res = await onRemoveRow(modalRemoveData[idKey]);
        if (res) {
            setRemoveDataLoading(false);
            setShowRemoveModal(false);
            setModalRemoveData(null);
            
            if (onDataUpdate) {
                onDataUpdate();
            }
        }
    };


    const setRowEditing = (rowData, status) => {
        const editedRowsCopy = new Map(editedRows);
        if ( editedRows.has(rowData[idKey]) && !status) {
            editedRowsCopy.delete(rowData[idKey]);
        } else if (!editedRows.has(rowData[idKey] && status)) {
            editedRowsCopy.set(rowData[idKey], rowData)
        }
        setEditedRows(editedRowsCopy);
    };


    const setRowLoading = (rowData, status) => {
        if (!status) {
            setRowsLoading( rowsLoading.filter(item => item !== rowData[idKey]) );
            return;
        }
        setRowsLoading([...rowsLoading, rowData[idKey]]);
    };


    return (
        <>
            <StyledTable
                data={data}
                loading={loading}
                className={'tableFilters'}
                shouldUpdateScroll={false}
                headerHeight={46}
                rowHeight={46}
                autoHeight
                {...props}
            >

                
                
                {/* Row Cells */}

                {columns.map( ({dataKey, dataValue, customValue, flexGrow = null, minWidth = 130, width, model, label = "", type, ...fieldProps}) => (
                    <Column
                        {...{
                            flexGrow,
                            minWidth,
                            width
                        }}>
                        <HeaderCell>
                            <span className="tableFilters__headerText">{label}</span>
                        </HeaderCell>
                        <Cell {...{dataKey}}>
                            {(rowData) => {
                                if ( editedRows.has(rowData[idKey]) && fieldProps.accepter ) {
                                    return (
                                        <Form
                                            ref={ref => {
                                                if (!ref) {
                                                    return;
                                                }

                                                if (refs.has(rowData[idKey])) {
                                                    const currentRefsCopy = refs.get(rowData[idKey]);
                                                    refs.set(rowData[idKey], {
                                                        ...currentRefsCopy,
                                                        [dataKey]: ref
                                                    });
                                                    return;
                                                }
                                                
                                                refs.set(rowData[idKey], {[dataKey]: ref});
                                            }}
                                            model={model}
                                            formDefaultValue={{[dataKey]: rowData[dataKey]}}
                                        >
                                            <EditField
                                                name={dataKey}
                                                {...fieldProps}
                                            />
                                        </Form>
                                    );
                                }

                                return <span className="tableFilters__previewText">{(customValue && customValue(rowData)) || rowData[dataValue || dataKey]}</span>
                            }}
                        </Cell>
                    </Column>
                ))}



                {/* Row Actions */}

                {editable &&
                    <Column width={174}>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {rowData => {
                                const rowLoading = rowsLoading.includes(rowData[idKey]);
                                return <div className="tableFilters_buttons">
                                    {canModify &&
                                        <>
                                            {!editedRows.has(rowData[idKey])
                                                ? <Button
                                                    size="sm"
                                                    color="lightblue"
                                                    disabled={rowLoading}
                                                    onClick={() => setRowEditing(rowData, true)}
                                                >
                                                    <Icon icon="edit2"/>
                                                </Button>
                                                : <>
                                                    <Button
                                                        size="sm"
                                                        color="green"
                                                        disabled={rowLoading}
                                                        onClick={() => handleModifyRow(rowData)}
                                                    >
                                                        <Icon icon="check-circle"/>
                                                    </Button>

                                                    <Button
                                                        size="sm"
                                                        color="red"
                                                        disabled={rowLoading}
                                                        onClick={() => setRowEditing(rowData, false)}
                                                    >
                                                        <Icon icon="close-circle"/>
                                                    </Button>
                                                </>
                                            }
                                        </>
                                    }

                                    {canRemove && 
                                        <Button
                                            size="sm"
                                            color="red"
                                            disabled={rowLoading}
                                            onClick={() => {
                                                setModalRemoveData(rowData);
                                                setShowRemoveModal(true);
                                            }}
                                        >
                                            <Icon icon="trash2"/>
                                        </Button>
                                    }
                                </div>
                            }}
                        </Cell>
                    </Column>
                }

            </StyledTable>

            {editable && canCreate &&
                <FormCreateRow
                    formModel={formModel}
                    ivrList={ivrList}
                    onSubmit={onModifyRow}
                    onDataUpdate={onDataUpdate}
                />
            }

            <ModalRemoveRow
                show={showRemoveModal}
                onSubmit={handleDeleteRow}
                onClose={() => setShowRemoveModal(false)}
                disabled={removeDataLoading}
            />

            <ModalSubmitRowApprove
                show={showModalApprove}
                onSubmit={handleApprovedSubmit}
                onClose={() => setShowModalApprove(false)}
                disabled={modalApproveLoading}
            />

        </>
    )
}

const StyledTable = styled(BaseTable)`
    && {
    
        .tableFilters__previewText {
            display: block;
            line-height: 20px;
            margin-top: 7px;
            word-break: normal;
        }
        
        .tableFilters_buttons {
            height: 20px;
            margin-top: 7px;
        }
    }
`;

const EditField = styled(FormControl).attrs(() => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    type: "text",
    cleanable: true,
    searchable: true,
}))`
`;

