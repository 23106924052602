import React, {useState, useEffect} from "react";
import {usePrevious, useWindowWidth} from "hooks";
import {withRouter} from "react-router-dom";
import Page from "components/base/Page";
import PageHeader from "components/base/PageHeader";

import AccountHeader from "./AccountHeader";
import TabGeneral from "./Tabs/TabGeneral";
import TabNumbers from "./Tabs/TabNumbers";
import TabPayments from "./Tabs/TabPayments";
import { useIntl } from "react-intl";
import m from "definedMessages";


const tabsList = {
    general: "general",
    numbers: "numbers",
    payments: "payments"
};



const Account = ({
    match,
    history,
    location,
    accountId,
    accountData,
    accountDataLoading,
    customerList,
    customerListLoading,
    
    numberList,
    numberPage,
    numberCount,
    numberPerPage,
    numberListLoading,

    paymentList,
    paymentListLoading,

    currencyList,

    changeRateNumbers,
    revokeNumbers,
    exportNumbers,

    billingServiceList,
    billingCurrencyList,

    getNumberList,

    getPaymentList,
    addNewPayment,
    removePayment,

    modifySubAccount,
    closeSubAccount,
    openSubAccount,

    getSubAccountData,
    getSubNumberList,
    getSubPaymentList,

    getSubAccountCustomerList,
    createSubAccountCustomer,
    modifySubAccountCustomer,
    removeSubAccountCustomer
}) => {

    // const {formatMessage} = useIntl();
    const {formatMessage} = useIntl()
    const subAccId = match.params.id;

    const defaultTab = "numbers";
    const [activeTab, setActiveTab] = useState(defaultTab);

    const prevActiveTab = usePrevious(activeTab);

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 767;

    useEffect(() => {
        if (subAccId) {
            getSubAccountData(subAccId);
            getSubAccountCustomerList(subAccId);
        }
    }, [subAccId]);

    const onSelectTab = (activeKey) => {
        const tab = new URLSearchParams(location.search);
        tab.delete("tab");

        history.replace({
            ...location,
            search: tab.toString()
        });

        setActiveTab(activeKey);
    };

    const update = () => {
        if (subAccId) {
            getSubAccountData(subAccId)
        }
    };

    return (
        <Page>
            <PageHeader title={formatMessage(m.subaccount)} showService={false}/>
            
            <AccountHeader
                activeTab={activeTab}
                onSelect={onSelectTab}
                account={accountData}
                isMobile={isMobile}
                formatMessage={formatMessage}
            />
            {
                (() => {
                    switch (activeTab) {
                        case tabsList.general:
                            
                            return <TabGeneral
                                history={history}
                                disabled={accountDataLoading}
                                account={accountData}
                                subAccId={subAccId}
                                accountId={accountId}
                  
                                modifySubAccount={modifySubAccount}

                                customerList={customerList}
                                customerListLoading={customerListLoading}

                                createCustomer={createSubAccountCustomer}
                                modifyCustomer={modifySubAccountCustomer}
                                removeCustomer={removeSubAccountCustomer}
                                
                                update={(id) => {
                                    getSubAccountData(id);
                             
                                }}
                                updateCustomers={(id) => {
                                    getSubAccountCustomerList(id);   
                                }}
                                cancel={() => {
                                    if (activeTab !== prevActiveTab) {
                                        onSelectTab(prevActiveTab);
                                    } else {
                                        onSelectTab(defaultTab);
                                    }
                                }}
                                close={async () => {
                                    await closeSubAccount(subAccId)
                                        .then(() => {
                                            getSubAccountData(subAccId);
                                        });
                                }}
                                open={async () => {
                                    await openSubAccount(subAccId)
                                        .then(() => {
                                            getSubAccountData(subAccId);
                                        });
                                }}
                                formatMessage={formatMessage}
                            />;

                        case tabsList.numbers:
                           
                            return <TabNumbers
                                subAccId={subAccId}

                                data={numberList}
                                page={numberPage}
                                count={numberCount}
                                perPage={numberPerPage}
                                loading={numberListLoading}

                                getData={getNumberList}

                                changeRateFunc={changeRateNumbers}
                                revokeFunc={revokeNumbers}
                                exportFunc={exportNumbers}

                                formatMessage={formatMessage}
                            />

                        case tabsList.payments:
                            return <TabPayments
                                subAccId={subAccId}

                                data={paymentList}
                                currencyList={currencyList}
                                loading={paymentListLoading}

                                billingServiceList={billingServiceList}
                                billingCurrencyList={billingCurrencyList}

                                getData={getPaymentList}
                                addNewFunc={addNewPayment}
                                removeFunc={removePayment}
                                accountUpdate={() => {
                                    getSubAccountData(subAccId);
                                }}
                                formatMessage={formatMessage}
                            />
                        default:
                            return null;
                    }
                })()
            }

        </Page>
    );
};

export default withRouter(Account);