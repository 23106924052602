import React, {Component} from 'react';
import {ASC_SORT} from "const";
import TableCommonNew from "./TableCommonNew";


function TableServerSortHOC(TableComponent) {
    class TableServerSort extends Component {
        constructor(props) {
            super(props);

            this.state = {
                sortType: props.defaultSortType || props.sortType || ASC_SORT,
                sortColumn: props.defaultSortColumn || props.sortColumn || props?.columns?.[0]?.dataKey,
            };
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            const {sortType, sortColumn, defaultSortType, defaultSortColumn} = this.props;

            if (prevProps.sortType !== sortType || prevProps.sortColumn !== sortColumn) {
                this.setState({
                    sortType: sortType || defaultSortType ||  ASC_SORT,
                    sortColumn: sortColumn || defaultSortColumn || this.props?.columns?.[0]?.dataKey,
                });
            }
        }

        fetchData = (page = 1, per_page = this.props.per_page) =>{
            const {sortColumn: column, sortType: type} = this.state;
            this.props.getItems(page, per_page, {column, type});
        };

        handleSortColumn = (column, type) => {
            if (this.props.onSort) this.props.onSort(column, type);

            this.props.getItems(1, this.props.per_page, {column, type});

            this.setState({
                sortColumn: column,
                sortType: type
            });
        };

        render() {
            const {forwardedRef, ...props} = this.props;

            return (
                <>
                    <TableComponent
                        {...props}
                        ref={forwardedRef}
                        onSortColumn={this.handleSortColumn}
                        sortType={this.state.sortType}
                        sortColumn={this.state.sortColumn}
                        getItems={this.fetchData}
                    />
                </>
            );
        }
    }

    return React.forwardRef((props, ref) => {
        return <TableServerSort {...props} forwardedRef={ref}/>
    })
}

export default TableServerSortHOC(TableCommonNew);

