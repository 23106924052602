import React from "react";
import TableClientSort from "components/Table/TableClientSort";
import PaginationTotal from "components/base/Pagination/PaginationTotal";
import {formatDateApi} from "utils"
import styled from "styled-components";
import m from "definedMessages";


const LiveCallsTable = ({
    data,
    page,
    count,
    perPage,
    accountData,
    loading = false,

    formatMessage,
    getItems,
    onDataUpdated,
    onChangePage,
    onChangePerPage,
    ...props
}) => {

    const columns = [
        {
            id: "a_number", 
            label: formatMessage(m.aNumber), 
            dataKey: "a_number",
            flexGrow: 2,
            minWidth: 120, 
            align: "center",
            value: ({a_number, a_subdestination_name}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">{a_number}</div>
                        <div className="table-two-staged__cell-second">{a_subdestination_name}</div>
                    </div>
                )
            }
        },
        {
            id: "b_number", 
            label: formatMessage(m.bNumber), 
            dataKey: "b_number",
            flexGrow: 2,
            minWidth: 120, 
            align: "center",
            value: ({b_number, b_subdestination_name}) => {
                return (
                    <>
                        <div className="table-two-staged__cell">
                            <div className="table-two-staged__cell-first">{b_number}</div>
                            <div className="table-two-staged__cell-second">{b_subdestination_name}</div>
                        </div>
                    </>
                )
            }
        },
        {
            id: "range", 
            label: formatMessage(m.range), 
            dataKey: "range", 
            flexGrow: 4,
            minWidth: 200, 
            align: "center",
            value: ({range}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-single">{range}</div>
                    </div>
                )
            }
        },
        {
            id: "subaccount", 
            label: formatMessage(m.subaccount), 
            dataKey: "subaccount", 
            flexGrow: 2,
            minWidth: 120, 
            align: "center",
            value: ({subaccount}) => {
                const currencyName = accountData ? accountData.currency_name : "";
                const accountName = subaccount && subaccount?.account_name ? subaccount.account_name : "";
                const accountRate = subaccount && subaccount?.rate ? subaccount.rate : "";
                
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">{accountName}</div>
                        <div className="table-two-staged__cell-second">{accountRate} {currencyName}</div>
                    </div>
                )
            }
        },
        {
            id: "duration", 
            label: formatMessage(m.duration), 
            dataKey: "duration", 
            flexGrow: 2,
            minWidth: 120, 
            align: "center",
            value: ({duration}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-single">{duration}</div>
                    </div>
                )
            }
        }
    ];

    return <>
        <StyledTable
            data={data}
            page={page}
            count={count}
            per_page={perPage}
            rowHeight={50}
            columns={columns}
            renderedColumns={columns}
            loading={loading}
            ispagination
            getItems={getItems}
            onDataUpdated={onDataUpdated}
            {...props}
        />
        <StyledPaginationTotal
            total={count}
        />
    </>
};

export default LiveCallsTable;

const StyledTable = styled(TableClientSort)`
    && {
        margin-bottom: 20px;
    }
`;

const StyledPaginationTotal = styled(PaginationTotal)`
    && {
        position: relative;
        text-align: right;
        width: 100px;
        top: -65px;
    }
`;