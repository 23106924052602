import React, { useEffect, useState, createRef } from 'react';
import {connect} from 'react-redux';
import {Icon} from 'rsuite';
import TreePicker from '../../components/TreePicker';
import styled, { css } from 'styled-components';


const findIndexByTrunkId = (id, list) => {
    for (let i = 0; i < list.length; i++) {
        if (list[i].trunk_id === id) {
            return i;
        }
    }
    return -1;
};


const DialerTrunkList = ({
    trunkId = null,
    disabledTrunk,
    // accounts = null,
    clearTrunk,
    dialerTrunkList = [],
    dialerLoading = false,

    defaultTrunkId,
    trunkFromTable,
    setTrunkId,
    onChange,
    onClear,
    show
}) => {

    const treeRef = createRef();
    const [isExpanded, setIsExpanded] = useState(false);
    const [dialerTreeValue, setDialerTreeValue] = useState(trunkId);

    // this need test
    // useEffect(() => {
    //     const accountsId = accounts && accounts.map(item => item.id);
    //     if (trunkId && accountsId) {
    //         const trunks = dialerTrunkList.filter( item => accountsId.includes(item.account_id) );
    //         if (!trunks.some(item => item.trunk_id === trunkId)) {
    //             if (onClear) onClear();
    //         }
    //     }
    // }, [accounts]);

    // set current trunk on init
    useEffect( () => {
        if (!dialerTrunkList || !dialerTrunkList.length)
            return;
        
        let currentTrunkId;

        if (trunkId) {
            currentTrunkId = trunkId;
        }

        if (defaultTrunkId) {
            currentTrunkId = defaultTrunkId;
        }
        
        if ((!trunkFromTable && !trunkId || !disabledTrunk && dialerTrunkList[0].trunk_id !== trunkId) && !dialerTreeValue) {
            onChange(dialerTrunkList[0]);

            currentTrunkId = dialerTrunkList[0].trunk_id;
        } 

        if (trunkFromTable && defaultTrunkId && !trunkId) {
            const defaultTrunk = dialerTrunkList.find(dialer => dialer.trunk_id === defaultTrunkId);

            if (defaultTrunk) {
                onChange(defaultTrunk);

                currentTrunkId = defaultTrunk.trunk_id;
            }
        }

        const itemIndex = findIndexByTrunkId(currentTrunkId, dialerTrunkList);

        if (treeRef && treeRef.current) {
            treeRef.current.focusNode({refKey: '0-' + itemIndex});
        }
    }, [show, dialerTrunkList]);

    useEffect( () => {
        if (!dialerTreeValue || dialerTreeValue !== trunkId)
            setDialerTreeValue(trunkId);

    }, [trunkId]);

    useEffect(() => {
        if (!!clearTrunk) {
            setDialerTreeValue(null);
            // ATX-4099 Was commented because it's causing allocaiton result model doesn't have a trunkId
            // setTrunkId(null);
        }
    }, [clearTrunk]);

    const renderTreeNode = (item) => {
        if (!item)
            return '';

        return (
            <>
                <div>
                    {item.account_manager_name &&
                        <><b>Manager:</b> {item.account_manager_name}&nbsp;</>
                    }
                    <b>Account:</b> {item.acc_name}&nbsp;
                </div>
                <div>
                    {item?.trunk_id && <><b>Trunk: </b> {item.tr_name}&nbsp;</>}
                    {item?.sp_key && <><b>Price: </b> {item.sp_name}&nbsp;</>}
                </div>
            </>
        )
    };

    const renderTreeHeader = (item) => {
        if (!item)
            return '';

        return (
            <>
                {item.account_manager_name &&
                    <><b>Manager:</b> {item.account_manager_name}&nbsp;</>
                }
                <b>Account:</b> {item.acc_name}&nbsp;
                <b>Trunk: </b> {item.tr_name}&nbsp;
                <b>Price: </b> {item.sp_name}&nbsp;
            </>
        )
    };


    const renderTreeIcon = () => {
        return null;
    };


    const focusItem = (itemIndex) => {
        if (!treeRef || !treeRef.current)
            return;
        const {current: ref} = treeRef;
        ref.focusNode({refKey: '0-' + itemIndex});
    };

    return (
        <>
            <Wrapper>
                <StyledTreeHeader isEmpty={!trunkId}>
                    {trunkId
                        ? <>
                            <ResultString
                                hasValue={trunkId}
                                disabledTrunk
                                onClick={() => {
                                    if (disabledTrunk || !dialerTrunkList.length)
                                        return;

                                    if (trunkId) {
                                        const itemIndex = findIndexByTrunkId(trunkId, dialerTrunkList);
                                        focusItem(itemIndex);
                                    }
                                }}
                            >
                                {dialerTrunkList.length 
                                    ? renderTreeHeader( dialerTrunkList.find(trunk => trunk.trunk_id === trunkId) )
                                    : ''
                                }
                            </ResultString>

                            {!disabledTrunk &&
                                <StyledTreeHeaderClear onClick={() => onClear()}>&#x2715;</StyledTreeHeaderClear>
                            }
                        </>

                        : <ResultString
                            disabledTrunk
                            onClick={() => {
                                if (!disabledTrunk && dialerTrunkList.length)
                                    focusItem(0);
                            }}
                        >
                            Select trunk below
                        </ResultString>
                    }
                    
                    {!disabledTrunk && 
                        <ExpandButton active={isExpanded} onClick={() => setIsExpanded(!isExpanded)}/>
                    }

                </StyledTreeHeader>

                {!disabledTrunk &&
                    <StyledTree
                        virtualized
                        inline={true}
                        // disabledItemValues={( emptyAccounts && emptyAccounts.map(item => item.id) ) || []}
                        ref={treeRef}
                        height={isExpanded ? 306 : 153}
                        labelKey="tr_name"
                        valueKey="trunk_id"
                        data={dialerTrunkList}
                        value={dialerTreeValue}
                        loading={dialerLoading}
                        renderTreeNode={renderTreeNode}
                        renderTreeIcon={renderTreeIcon}
                        onChange={(id) => {
                            setDialerTreeValue(id);
                            // setTrunkId(id);

                            return onChange 
                                ? onChange(dialerTrunkList.find(trunk => trunk.trunk_id === id)) 
                                : onChange
                        }}
                    />
                }
            </Wrapper>

        </>
    )
};


const mapState = ({references}) => ({
    // dialerTrunkList: references.dialerTrunkList,
});

export default connect(mapState, {})(DialerTrunkList);


const StyledTree = styled(TreePicker)`

    && > .rs-tree > .ReactVirtualized__Grid.ReactVirtualized__List {
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }

    && {
        overflow: hidden !important;

        .rs-tree-node > .rs-tree-node-label > .rs-tree-node-label-content {
            font-size: 13px;
            font-weight: 400;
            padding-left: 24px;
            width: 100%;
        }
    }

    .rs-tree-node {
        border-bottom: 1px solid #f1f1f1;
        padding: 0 !important;
        margin: 0;

        &:last-child {
            border-bottom: none;
        }

        & > .rs-tree-node-label {
            font-weight: 400;
            padding-left: 0;

            &:before {
                display: none;
            }
        }
    }

    .text-muted span {
        opacity: 0.7;
    }
`;

const Wrapper = styled.div`
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    margin-bottom: 30px;
    overflow: visible !important;
`;

const ResultString = styled.p`
    color: #333333;
    cursor: ${props => props.disabledTrunk ? 'default' : 'pointer'};
    padding: 10px 12px;

    ${props => !props.hasValue && css`
            opacity: 0.6;
        `
    }
`;

const StyledTreeHeader = styled.div`
    align-items: center;
    background-color: ${props => props.isEmpty ? 'transparent' : '#f2faff' };
    box-shadow: 0 1px 5px -1px rgba(33, 33, 33, 0.2);
    display: flex;
    padding-right: 10px;
    position: relative;
    z-index: 5;
`;

const StyledTreeHeaderClear = styled.span.attrs(props => ({
    role: "button"
}))`
    cursor: pointer;
    display: block;
    transition: color 0.2s ease;

    &:hover {
        color: var(--color-brand1);
    }
`;

const ExpandButton = styled(Icon).attrs(props => ({
    icon: "sequence"
}))`
    cursor: pointer;
    margin-left: auto;
    margin-right: -10px;
    padding: 10px;
    ${props =>
        props.active && css`
            color: var(--color-brand1);
        `
    }
    &:hover {
        color: var(--color-brand1);
    }
`;