import {connect} from "react-redux";
import AccountManagers from "./AccountManagers";
import {
    createAccountManager,
    getAccountManagersBillingList,
    getAccountManagersList,
    getAccountManagersReferences,
    modifyAccountManager,
    removeAccountManager
} from "../../actions/account_managers";


const mapStateToProps = ({account_managers}) => ({
    managersList: account_managers.list,
    loading: account_managers.loading,
    userList: account_managers.userList,
    teamLeadList: account_managers.teamLeadList,
    billingList: account_managers.billingList,
    permission_list: account_managers.permission_list,
});

export default connect(mapStateToProps, {
    getAccountManagersList,
    getAccountManagersReferences,
    getAccountManagersBillingList,
    createAccountManager,
    modifyAccountManager,
    removeAccountManager,
})(AccountManagers);