import {
    PRICE_RANGE_QUARANTINE_SETTING_LOADING,
    PRICE_RANGE_QUARANTINE_SETTING_LIST,
} from "../const";

import {
    PRICE_RANGE_QUARANTINE_SETTING_GET_LIST_API,
    PRICE_RANGE_QUARANTINE_SETTING_CREATE_API,
    PRICE_RANGE_QUARANTINE_SETTING_MODIFY_API,
    PRICE_RANGE_QUARANTINE_SETTING_REMOVE_API,
} from "../const/apiMethods";

import {createAction} from './defaults';
import {api} from "../api/loginRoutes";

const setPriceRangeQuarantineSettingList = createAction(PRICE_RANGE_QUARANTINE_SETTING_LIST);
const setPriceRangeQuarantineSettingLoading = createAction(PRICE_RANGE_QUARANTINE_SETTING_LOADING);


// get list
export const getPriceRangeQuarantineSettingList = (filter = {}) => async (dispatch) => {
    dispatch( setPriceRangeQuarantineSettingLoading(true) );

    await api(PRICE_RANGE_QUARANTINE_SETTING_GET_LIST_API, {filter})
        .then((response) => {
            if (response && response.price_range_quarantine_setting) {

                dispatch( setPriceRangeQuarantineSettingList({
                    list: response.price_range_quarantine_setting || [],
                }) );

            }
        })
        .finally(() => {
            dispatch( setPriceRangeQuarantineSettingLoading(false) );
        })
};

export const createPriceRangeQuarantineSetting = (data) => async () => {
    const params = {target: {}, ...data};
    const response = await api(PRICE_RANGE_QUARANTINE_SETTING_CREATE_API, {...params});
    return response;
};

export const modifyPriceRangeQuarantineSetting = (params) => async () => {
    const response = await api(PRICE_RANGE_QUARANTINE_SETTING_MODIFY_API, {
        ...params,
        period_traffic: +params.period_traffic,
        period_allocation: +params.period_allocation,
        period_autorevoke: +params.period_autorevoke
    });
    return response;
};

export const removePriceRangeQuarantineSetting = (prqs_key) => async () => {
    const response = await api(PRICE_RANGE_QUARANTINE_SETTING_REMOVE_API, {prqs_key});
    return response;
};

