import React, {useRef, useState, useMemo, useEffect} from "react";
import {Button, CheckPicker, Form, FormControl, Input, Schema, SelectPicker} from "rsuite";
import styled from "styled-components";
import Icon from "rsuite/es/Icon";

const {StringType, NumberType, ArrayType} = Schema.Types;

const formModel = Schema.Model({
    email: StringType()
        .isRequired('Required')
        .isEmail('Please enter an email address')
        .maxLength(256, 'The maximum of this field is 256 characters')
        .addRule((value) => {
            return value
        }, 'Required 2'),
        // .minLength(6, 'The minimum of this field is 6 characters'),
    name: StringType()
        .isRequired('Required')
        .maxLength(256, 'The maximum of this field is 256 characters')
        .addRule((value) => {
            return value
        }, 'Required 2'),
        // .minLength(3, 'The minimum of this field is 3 characters'),
    account_user_teamlead_id: StringType()
        .isRequired('Required'),
    account_user_manager_id_list: ArrayType()
        .isRequired('Required'),
    billing_acm_key: NumberType()
        .isRequired('Required'),
    skype: StringType()
        .isRequired('Required')
        .minLength(2, 'Required 2')
});

export default ({
    teamLeadList,
    billingList,
    managersList,
    userList,
    onSubmit,
    getAccountManagersList,
}) => {
    const formDefaultValue = {
        name: "",
        email: "",
        account_user_teamlead_id: null,
        billing_acm_key: null,
        account_user_manager_id_list: [],
        skype: "",
        permission_list: []
    };

    const formInitValue = {
        name: null,
        email: null,
        account_user_teamlead_id: null,
        billing_acm_key: null,
        account_user_manager_id_list: [],
        skype: null,
        permission_list: []
    };

    const formRef = useRef(null);
    const [formValue, setFormValue] = useState(formInitValue);

    // useEffect(() => {
    //     const _formValue = Object.assign({}, formValue);
    //     if (Object.keys(_formValue).some(key => _formValue[key] === "")) {
    //         setFormValue((values) => {
    //             const localValue = Object.keys(values).reduce((acc, key) => {
    //                 if (values[key] !== "") {
    //                     acc[key] = values[key];
    //                     return acc;
    //                 }
    //                 acc[key] = null;
    //                 return acc;
    //             }, {});
    //             return localValue;
    //         });
    //     }
    // }, [formValue]);

    const [createAmLoading, setCreateAmLoading] = useState(false);
    const disabledBillingList = useMemo(() => (
        billingList
            .filter(billingAM => managersList.find(manager => manager.billing_acm_key === billingAM.ACM_KEY))
            .map(item => item.ACM_KEY)
    ), [billingList, managersList]);

    const accountManagerPermissions = [
        {
            value: "access_list_notification_daily",
            label: "Send access list daily"
        },
        {
            value: "access_list_notification_apperance",
            label: "Send access lists per appearance"
        }
    ];

    const handleSubmit = () => {
        if (!formRef.current.check())
            return;

        setFormValue(formDefaultValue);
        setCreateAmLoading(true);

        const formValue = formRef.current.getFormValue();
        onSubmit(formValue)
            .then(res => {
                setCreateAmLoading(false);
      
                if (res) {
                    getAccountManagersList();
                    setFormValue(formInitValue);
                }
            });
    };

    return (
        <StyledForm
            ref={formRef}
            model={formModel}
            formValue={formValue}
            formDefaultValue={formDefaultValue}
            onChange={(value) => {
                setFormValue(value);
            }}
            onSubmit={handleSubmit}
        >
            <StyledFieldWrapper>
                <StyledField
                    name="name"
                    accepter={Input}
                    placeholder="Name"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <StyledField
                    name="email"
                    accepter={Input}
                    placeholder="Email"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <StyledField
                    name="account_user_teamlead_id"
                    accepter={SelectPicker}
                    data={teamLeadList}
                    placeholder="Team Lead"
                    valueKey="id"
                    labelKey="name"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <StyledField
                    name="billing_acm_key"
                    accepter={SelectPicker}
                    data={billingList}
                    disabledItemValues={disabledBillingList}
                    placeholder="AM from Billing"
                    valueKey="ACM_KEY"
                    labelKey="ACM_NAME"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <StyledField
                    accepter={CheckPicker}
                    data={userList}
                    valueKey="id"
                    labelKey="name"
                    name="account_user_manager_id_list"
                    placeholder={"Users"}
                    sticky
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <StyledField
                    name="skype"
                    accepter={Input}
                    placeholder="Skype"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <StyledField
                    data={accountManagerPermissions}
                    name="permission_list"
                    accepter={CheckPicker}
                    searchable={false}
                    placeholder="Permissions"
                    className="tableFilters_field"
                />
            </StyledFieldWrapper>

            <StyledSubmitWrapper>
                <Button
                    className="tableFilters_submit"
                    color="green"
                    type="submit"
                    loading={createAmLoading}
                >
                    <Icon icon="plus"/>
                </Button>
            </StyledSubmitWrapper>
        </StyledForm>
    )
};

const StyledField = styled(FormControl).attrs(props => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    placement: "autoVerticalStart",
    type: "text",
    cleanable: true,
    searchable: false,
}))`
`;

const StyledFieldWrapper = styled.div`
    flex: 1 0 200px;
    padding: 5px 10px;
    width: 200px;
`;
const StyledSubmitWrapper = styled.div`
    padding: 5px 10px;
    width: 174px;
`;

const StyledForm = styled(Form)`
    display: flex;
`;