import React, {useState} from 'react';
import Modal from '../../../components/Modal';
import {api} from '../../../api/loginRoutes';
import {Alert} from 'rsuite';
import Icon from 'rsuite/es/Icon';
import {ButtonPrimary} from "../../../components/base/BaseButton";


export default (
    {
        extraParams,
        disabled,
        onSuccess,

        listMethod,

        checkKey='incorporated_numbers',
        method='incorporated_number:unset_block_allocation'
    }
) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);


    const onSubmit = async () => {
        setLoading(true);

        const result = await api(method, {...extraParams});

        if (result && result[checkKey]) {
            Alert.success(`${result[checkKey]} number were unblocked`);
            setShow(false);
            onSuccess();
        }
        setLoading(false);

    };
    return (
        <>
            <ButtonPrimary
                disabled={disabled}
                onClick={() => setShow(true)}
            >
                <Icon icon="unlock-alt"/> Unblock
            </ButtonPrimary>

            <Modal
                show={show}
                title="Allocate numbers"
                onClose={() => setShow(false)}
                footer={true}
                successText="Confirm"
                disabled={loading}
                onSuccess={onSubmit}
            >
                Selected numbers will be set as free. You can allocate them or set test in future
            </Modal>
        </>

    );
}
