import React, {useEffect, useState, useMemo, useRef} from 'react';
import PageHeader from '../../components/base/PageHeader';
import HotAccessesFilters from './HotAccessesFilters';
import HotAccessesTable from './HotAccessesTable';
import {
    LOCAL_STORAGE_ACCESSES_FILTERS_DIALER,
    LOCAL_STORAGE_ACCESSES_PER_PAGE_DIALER,
    LIMITED_PER_PAGE_DIALER,
    MIN_PER_PAGE_DIALER,
    SCREEN_MEDIA,
    USD_DEFAULT_CURRENCY_KEY,
    transformed_service_data,
    transformed_service_reverse_data
} from '../../const';
import {compareObjects, servicePick, getServiceByLocation} from '../../utils/helpers';
import {injectIntl} from 'react-intl';
import {FlexGrid, FlexGridItem} from '../../components/base/FlexGrid';
import {Spacer} from '../../components/base/Spacer';
import styled, {createGlobalStyle} from 'styled-components';
import {api} from '../../api/loginRoutes';
import Page from '../../components/base/Page';

import axios from 'axios';
import m from '../../definedMessages';
import {ButtonPrimary} from '../../components/base/BaseButton';
import {Icon, Schema} from 'rsuite';
// import ModalSettings from './ModalSettings';
import {checkPermissionsFor} from '../../store/storeHelpers';
import {withRouter} from 'react-router';
// import GlobalAccessNumbersFilters from './GlobalAccessNumbersFilters';
import {usePrevious} from '../../hooks';


const {md} = SCREEN_MEDIA;
const widthDesktopMin = md.min;

const {StringType} = Schema.Types;

const MIN_LENGTH = 3;
const MAX_LENGTH = 40;


const HotAccesses = ({
    intl,

    accessesList,
    service,

    account,
    getTrunkList,
    setDialerService,
    getHotAccessList,
    setEmptyHotAccessList,

    page,
    count,
    per_page,

    accessesLoading,

    accountDialerState,
    accountJoinList=[],

    ...props
}) => {

    const formatMessage = intl.formatMessage.bind(intl);
    let cancelTokenAccessesList = axios.CancelToken.source();
    let currentPerPage = null;

    let savedTableFilter = {};
    
    const accountInfo = useMemo(() => {
        return accountJoinList.find(accountService => 
            transformed_service_data[accountService?.service] === service
        ) || {};
    }, [service, accountJoinList]);

    const accountIsSigle = useMemo(() => {
        return accountJoinList.length && accountJoinList.length === 1;
    }, [accountJoinList]);

    const currencyKey = ( (savedTableFilter && savedTableFilter.cur_key) || {} ).cur_key || accountInfo.cur_key;
    const isTest = 'is_test' in accountInfo ? accountInfo.is_test : false;

    const filtersModel = Schema.Model({
        str: StringType()
            .minLength(MIN_LENGTH, formatMessage(m.minLength, {count: MIN_LENGTH}))
            .maxLength(MAX_LENGTH, formatMessage(m.maxLength, {count: MAX_LENGTH})),
        str2: StringType()
            .minLength(MIN_LENGTH, formatMessage(m.minLength, {count: MIN_LENGTH}))
            .maxLength(MAX_LENGTH, formatMessage(m.maxLength, {count: MAX_LENGTH})),
        origin: StringType()
            .minLength(MIN_LENGTH, formatMessage(m.minLength, {count: MIN_LENGTH}))
            .maxLength(MAX_LENGTH, formatMessage(m.maxLength, {count: MAX_LENGTH})),
        destination: StringType()
            .minLength(MIN_LENGTH, formatMessage(m.minLength, {count: MIN_LENGTH}))
            .maxLength(MAX_LENGTH, formatMessage(m.maxLength, {count: MAX_LENGTH})),
    });

    const filterDefault = {
        [servicePick(service, 'str', 'origin')]: '',
        [servicePick(service, 'str2', 'destination')]: '',
        ...(service ? {} : {message: ''}),
        sp_key_list: null,
        cur_key: USD_DEFAULT_CURRENCY_KEY,
        ...(isTest && currencyKey ? {cur_key: currencyKey} : {cur_key: USD_DEFAULT_CURRENCY_KEY})
    };

    let filter = {
        ...filterDefault,
        ...(savedTableFilter || {}),
    };

    const defaultFilter = useMemo(() => {
        return filterDefault;
    }, [service]);
   
    const savedFilter = useMemo(() => {
        return filter;
    }, [service]);

    const [isSupport, setIsSupport] = useState(null);
    const [filterState, setFilter] = useState({...defaultFilter});

    const prevService = usePrevious(service);
    const prevFilter = usePrevious(filterState);

    const [savedPerPage, setSavedPerPage] = useState(JSON.parse( localStorage.getItem(LOCAL_STORAGE_ACCESSES_PER_PAGE_DIALER) ));

    const hasMounted = useRef(false);

    useEffect(() => {
        let currentService = service;
        
        // uncomment  
        const checker = filtersModel.check(filterState);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError && accountInfo?.id) {
            if (service === null) {
                currentService = getServiceByLocation(props.location);
            }
    
            getTrunkList(currentService);
            getAccessListCommon(savedPerPage || per_page, defaultFilter, 1);
        }

        if (account && Object.keys(account).length) {
            if (account?.session) {
                const accountUser = account.session.account_user;
                if (Object.keys(accountUser).length && accountUser.role_list && accountUser.role_list.length) {
                    const isSupportFlag = accountUser.role_list.includes("Account Support");
                    setIsSupport(isSupportFlag)
                }
            }
        }

        // resize listener
        // window.addEventListener('resize', this.handleResize);
        return () => {
            if (typeof cancelTokenAccessesList !== typeof undefined) {
                cancelTokenAccessesList.cancel("Operation canceled due to new request.");
            }
        }
    }, [service, accountInfo?.id]); // check it

    useEffect(() => {
        if (service !== null) {

            if (!compareObjects(filterState, defaultFilter)) {
                setFilter(defaultFilter);
                localStorage.setItem(LOCAL_STORAGE_ACCESSES_FILTERS_DIALER, JSON.stringify(defaultFilter));
                getTrunkList(service);
            }
        }
    }, [service]);

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
            return;
        }

        let currentFilter = filterState;
        if (prevService !== service) {
            currentFilter = defaultFilter;
        }
        
        const checker = filtersModel.check(filterState);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError && filterState !== defaultFilter && prevFilter !== filterState) { // prevFilter !== filterState
            // catch error => execute
            // or if methodRecaptcha is not null => execute
            getAccessListCommon(savedPerPage || currentPerPage || per_page, filterState, 1, false); 
        } else {
            setEmptyHotAccessList();
        }
    }, [filterState]);

    const getAccessListCommon = (per_page, filter, page, isAuth) => {
        if (typeof cancelTokenAccessesList !== typeof undefined) {
            cancelTokenAccessesList.cancel("Operation canceled due to new request.");
        }
        cancelTokenAccessesList = axios.CancelToken.source();

        let currentService = service;
        
        if (service === null) {
            currentService = getServiceByLocation(props.location);
        }

        getHotAccessList(currentService, per_page, filter, page, isAuth, cancelTokenAccessesList);
    };

    const onChangeFilters = (filters) => {
        const newFilter = {...filterState, ...filters};

        setFilter(newFilter);

        localStorage.setItem(LOCAL_STORAGE_ACCESSES_FILTERS_DIALER, JSON.stringify(newFilter));
    };

    const onChangePerPage = (perPage) => {
        setSavedPerPage(perPage ? null : currentPerPage);
        const per_page = Number(perPage) || currentPerPage;
        currentPerPage = per_page <= 0
            ? MIN_PER_PAGE_DIALER
            : per_page > LIMITED_PER_PAGE_DIALER ? LIMITED_PER_PAGE_DIALER : per_page;
            
        localStorage.setItem(LOCAL_STORAGE_ACCESSES_PER_PAGE_DIALER, JSON.stringify(currentPerPage));

        const checker = filtersModel.check(filterState);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError) { //&& ((filterState?.str || filterState?.str2))) {
            getAccessListCommon(currentPerPage, filterState, 1);
        }
    };

    const onChangePage = (pageNumber) => {
        const checker = filtersModel.check(filterState);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError) { //&& ((filterState?.str || filterState?.str2))) {
            getAccessListCommon(savedPerPage || currentPerPage || per_page, filterState, pageNumber);
        }
    };


    const headerTitle = formatMessage(m.hotAccessList);

    return (
        <Page>
            <GlobalStyle/>
            <PageHeader title={ headerTitle }/>

            <FlexGrid style={{maxWidth: '100%'}} justify="space-between">
                <FlexGridItem grow={1} style={{maxWidth: '100%'}}>
                    <HotAccessesFilters
                        onChangeFilter={onChangeFilters}
                        loading={accessesLoading || false}
                        defaultFilter={defaultFilter}
                        savedFilter={savedFilter}
                        filtersModel={filtersModel}
                        {...{
                            service,
                            accountInfo,
                            filterState,
                            accountDialerState
                        }}
                    />
                </FlexGridItem>
            </FlexGrid>

            <Spacer/>

            <HotAccessesTable
                data={accessesList}
                loading={accessesLoading || false}
                filter={filterState}
                {...{
                    service,
                    accountInfo,
                    accountIsSigle,
                    isTest,
                    isSupport,
                    onChangePage,
                    onChangePerPage,
                    setDialerService,
                    page,
                    count,
                    per_page,
                    savedPerPage,
                    accountDialerState
                }}
            />

        </Page>
    )
};


export default injectIntl(withRouter(HotAccesses));


const GlobalStyle = createGlobalStyle`
    .rs-picker-check-menu-items {
        overflow-y: hidden !important;
        max-height: none !important;
    }
`;