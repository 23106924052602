import React, {useState} from "react";
import {Checkbox, Form, FormControl, Input, SelectPicker} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../../components/base/FlexGrid";
import {debounce, servicePick} from "../../../utils";


export default (
    {
        service,
        filter = {},
        destinationList,
        subdestinationList,
        onChangeFilter,
    }
) => {

    const strPlaceholder = servicePick(service, 'Filter per number or dialer name', 'Filter per number')

    return (
        <Form
            formDefaultValue={filter}
            onChange={debounce((value) => {
                onChangeFilter(value);
            }, 1000)}
        >
            <FlexGrid align="middle">
                <FlexGridItem>
                    <FormControl
                        accepter={Input}
                        name={'str'}
                        placeholder={strPlaceholder}
                        style={{width: 300}}
                    />
                </FlexGridItem>
                <FlexGridItem>
                    <FormControl
                        accepter={SelectPicker}
                        data={destinationList}
                        name={'de_key'}
                        placeholder={'Destination'}
                        valueKey="de_key"
                        labelKey="name"
                        style={{width: 224}}
                    />
                </FlexGridItem>
                <FlexGridItem>
                    <FormControl
                        accepter={SelectPicker}
                        data={subdestinationList}
                        name={'sde_key'}
                        placeholder={'Subdestination'}
                        valueKey="sde_key"
                        labelKey="name"
                        style={{width: 224}}
                    />
                </FlexGridItem>
                <FlexGridItem>
                    <FormControl
                        accepter={CustomCheckbox}
                        name={'show_allocated_numbers'}
                    >
                        Show allocated numbers
                    </FormControl>
                </FlexGridItem>
                <FlexGridItem>
                    <FormControl
                        accepter={CustomCheckbox}
                        name={'show_unallocated_numbers'}
                    >
                        Show free numbers
                    </FormControl>
                </FlexGridItem>
                <FlexGridItem>
                    <FormControl
                        accepter={CustomCheckbox}
                        name={'show_test_numbers'}
                    >
                        Show test numbers
                    </FormControl>
                </FlexGridItem>
                <FlexGridItem>
                    <FormControl
                        accepter={CustomCheckbox}
                        name={'show_block_allocation_numbers'}
                    >
                        Show blocked numbers
                    </FormControl>
                </FlexGridItem>
            </FlexGrid>
        </Form>
    )
};


const CustomCheckbox = ({children, defaultValue = false, onChange, ...props}) => {
    const [_value, _setValue] = useState(defaultValue);

    return <Checkbox
        value={_value}
        checked={_value}
        onChange={() => {
            _setValue(!_value);
            onChange(!_value);
        }}
        {...props}
    >
        {children}
    </Checkbox>
};