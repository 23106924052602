import React, {useState} from 'react';
import PageHeader from "../../components/base/PageHeader";
import {FlexboxGrid} from "rsuite";
import ExportCsv from "../../components/ExportCsv";
import PanelLayout from "../../components/base/PanelLayout";
import TrafficReportsTable from "pages/TrafficReports/TrafficReportsTable";
import { TRAFFIC_ADMIN_PATHNAME } from "const";
import { TRAFFIC_REPORTS_METHOD, TRAFFIC_REPORTS_SMS_METHOD, FAILED_MDR_REPORTS_METHOD} from "const/apiMethods";
import TrafficReportsFilters from "pages/TrafficReports/TrafficReportsFilters";
import { LOCAL_STORAGE_TRAFFIC_STAT_FILTERS } from 'const/localStorageKeys';
import {connect} from "react-redux";
import {servicePick, toEndDayTime, toStartDayTime, removeTZFromDate} from "utils";
import { useEffect } from 'react';
import { usePrevious } from 'hooks';

const TrafficReportsPage = ({service, globalSettings, ...props}) => {
    const currentPathName = props.location.pathname;
    const isFailedMdr = !currentPathName.split("-").find((path) => path === TRAFFIC_ADMIN_PATHNAME);
    const prevIsFailedMdr = usePrevious(isFailedMdr);

    const today = new Date(Date.now());

    let savedFilters = null;
    const defaultFilter = {range: [toStartDayTime(today), toEndDayTime(today)], group_checkbox: [], ...(!service ? {only_my_account: true} : {})};
    const storagedFilters = localStorage.getItem(LOCAL_STORAGE_TRAFFIC_STAT_FILTERS);
    if (storagedFilters && storagedFilters !== '') {
        savedFilters = {
            ...JSON.parse(storagedFilters),
            range: [
                new Date(JSON.parse(storagedFilters).start_end_date[0]),
                new Date(JSON.parse(storagedFilters).start_end_date[1]),
            ]
        };

        localStorage.removeItem(LOCAL_STORAGE_TRAFFIC_STAT_FILTERS);
    }

    const [filters, setFilters] = useState(
        savedFilters
            ? savedFilters
            : defaultFilter
    );
    const [preFilters, setPreFilters] = useState(
        savedFilters
            ? savedFilters
            : defaultFilter
    );

    useEffect(() => {
        if (prevIsFailedMdr !== isFailedMdr) { 
            localStorage.removeItem(LOCAL_STORAGE_TRAFFIC_STAT_FILTERS);
            setFilters(defaultFilter);
        }
    }, [isFailedMdr]);

    const methodExport = isFailedMdr ? FAILED_MDR_REPORTS_METHOD : servicePick(service, TRAFFIC_REPORTS_METHOD, TRAFFIC_REPORTS_SMS_METHOD);

    const exportFilter = {...preFilters};

    if (exportFilter.range && exportFilter.range.length) {
        exportFilter['start_date'] = removeTZFromDate(toStartDayTime(exportFilter.range[0]));
        exportFilter['end_date'] = removeTZFromDate(toEndDayTime(exportFilter.range[1]));

        delete exportFilter.range;
    }

    const exportGroupCheckbox = exportFilter?.group_checkbox || [];

    if (exportGroupCheckbox.length) {
        delete exportFilter.group_checkbox;
    }

    return (
        <PanelLayout>
            {isFailedMdr
                ? <PageHeader title="Failed MDR Reports">
                      <ExportCsv
                        method={methodExport}
                        params={{filter: exportFilter, group_checkbox: exportGroupCheckbox}}
                        title='Export CSV'
                        fileName='failed_traffic_report.csv'
                        style={{position: 'relative', right: 15}}
                    />
                </PageHeader>
                : <PageHeader title={`${service ? "Traffic" : "MDR"} Reports`}>
                    <ExportCsv
                        method={methodExport}
                        params={{filter: exportFilter, group_checkbox: exportGroupCheckbox}}
                        title='Export CSV'
                        fileName='traffic_report.csv'
                        style={{position: 'relative', right: 15}}
                    />
                </PageHeader>
            }
            <FlexboxGrid >
                <FlexboxGrid.Item colspan={24}>
                    <TrafficReportsFilters
                        onApplyFilters={(filters) => {
                            setFilters(filters)
                        }}
                        preFilters={preFilters}
                        setPreFilters={setPreFilters}
                        currentFilters={filters}
                        defaultFilters={defaultFilter}
                        isFailedMdr={isFailedMdr}
                        globalSettings={globalSettings}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <TrafficReportsTable
                filters={filters}
                isFailedMdr={isFailedMdr}
            />
        </PanelLayout>
    );
};

const mapState = ( {auth, references} )=> ({
    service: auth.service,
    globalSettings: references.global_setting,
});

export default connect(mapState)(TrafficReportsPage);