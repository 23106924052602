import {connect} from "react-redux";
import AmActivities from "./AmActivities";
import {
    getAmActivitiesDateList, 
    getAmActivitiesList,
    removeAmActivitiesDateListItem,
    removeAmActivitiesDateList
} from "../../actions/am_activities";
import {getReferences} from "../../actions/action_logs";


const mapStateToProps = ({auth, action_logs, am_activities}) => ({
    appType: auth.appType,
    service: auth.service,

    managerList: action_logs.managerList,
    data: am_activities.list,
    dateList: am_activities.dateList,
    loading: am_activities.loading,
});

export default connect(mapStateToProps, {
    getReferences,
    getAmActivitiesList,
    getAmActivitiesDateList,
    removeAmActivitiesDateListItem,
    removeAmActivitiesDateList
})(AmActivities);