import React, {useMemo, useState, useCallback, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons'
import {Divider, Loader, Popover, Whisper, Icon, FlexboxGrid} from 'rsuite';
import {TableClientSortHOC} from '../../components/Table/TableClientSort'
import { withRouter } from "react-router";
import {commentTypes} from "../../const";
import {api} from "../../api/loginRoutes";
import BaseTable, {Column, HeaderCell, Cell} from "../../components/base/BaseTable";
import styled, {css} from "styled-components";
import Badge from 'rsuite/es/Badge'
import Tooltip from 'rsuite/es/Tooltip'


export default TableClientSortHOC(withRouter(({
    data = [],
    authInfo,
    loading,
    history,
    onSetRedirectTab,
    isMobile = false,
    ...props
}) => {
    const [sortColumn, setSortColumn] = useState("name");
    const [sortType, setSortType] = useState("asc");
    const [sortLoading, setSortLoading] = useState(false);
    const [partialRedirect, setPartialRedirect] = useState(null);

    // useEffect(() => {
    //     if (partialRedirect) {
    //         const partialPath = `?tab=${partialRedirect}`;

    //         setTimeout((location, history) => {
    //             history.replace({
    //                 ...location,
    //                 search: partialPath
    //             });
    //         },
    //         100,
    //         props.location, history);
    //     }
    // }, [partialRedirect]);

    const getData = () => {
        const formatedData = data.map(value => {
            const billing = value?.billing;
            const traffic = billing?.VOLUME_1_IN ? billing.VOLUME_1_IN : billing?.VOLUME_7_IN ? billing.VOLUME_7_IN : billing?.VOLUME_30_IN;

            return {
                ...value,
                traffic: traffic,
                pending_amount: value?.billing?.pending_amount,
                ready_for_payment: value?.billing?.ready_for_payment,
                traffic_for_day: value?.billing?.VOLUME_1_IN,
                traffic_for_week: value?.billing?.VOLUME_7_IN,
                traffic_for_month: value?.billing?.VOLUME_30_IN,
                created_at_date: new Date(value?.created_at)
            }
        });

        if (sortColumn && sortType) {
            return formatedData.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
               
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }

                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }

        return formatedData;
    };

    const columns = useMemo( () => {
        const hasBlockedSupplier = data.find(item => {
            const {allocation_ban_expired_at = null} = item;
            return allocation_ban_expired_at !== null
        });

        const columns = [
            {
                flexGrow: 2,
                minWidth: 120,
                sortable: true,
                name: "Supplier Name",
                dataKey: "name",
                align: 'left',
                value: ({id, name, bound, account_note_last}) => {
                    return <SupplierNameContainer>
                        <SupplierNotesIcon
                            setPartialRedirect={setPartialRedirect}
                            type={account_note_last}
                            supplierId={id}
                        />
                        <SupplierBoundIcon
                            status={bound}
                        />
                        <div className="Supplier_name">{name}</div>
                    </SupplierNameContainer>
                },
                isMobile: false,
            },
            {
                flexGrow: 2,
                minWidth: 120,
                sortable: true,
                name: "Account Manager Name",
                dataKey: "account_manager_name",
                align: 'left',
                isMobile: false,
            },
            // {
            //     flexGrow: 1,
            //     width: 120,
            //     sortable: true,
            //     align: 'right',
            //     dataKey: "pending",
            //     name: "Balance",
            //     value: ({billing={}, currency_name = ""}) => {
            //         const balance = <>
            //             {billing?.pending_amount}/{billing?.ready_for_payment} {currency_name}
            //         </>;
            //         return balance;
                    
            //     },
            //     isMobile: false,
            // },
            {
                flexGrow: 1,
                width: 120,
                sortable: true,
                align: 'right',
                dataKey: "pending_amount",
                name: "Current period",
                value: ({billing={}, currency_name = ""}) => {
                    const balance = <>
                        {billing?.pending_amount} {currency_name}
                    </>;
                    return balance;
                    
                },
                isMobile: false,
            },
            {
                flexGrow: 1,
                width: 120,
                sortable: true,
                align: 'right',
                dataKey: "ready_for_payment",
                name: "Invoiced",
                value: ({billing={}, currency_name = ""}) => {
                    const balance = <>
                        {billing?.ready_for_payment} {currency_name}
                    </>;
                    return balance;
                    
                },
                isMobile: false,
            },
            {
                flexGrow: 1,
                minWidth: 90,
                sortable: true,
                align: 'right',
                name: "Traffic for day",
                dataKey: "traffic_for_day",
                value: ({billing = {}}) => {
                    const traffic = <>{billing?.VOLUME_1_IN}</>

                    return traffic;
                
                },
                isMobile: false,
            },
            {
                flexGrow: 1,
                minWidth: 90,
                sortable: true,
                align: 'right',
                name: "Traffic for week",
                dataKey: "traffic_for_week",
                value: ({billing = {}}) => {
                    const traffic = <>{billing?.VOLUME_7_IN}</>

                    return traffic;
                
                },
                isMobile: false,
            },
            {
                flexGrow: 1,
                minWidth: 90,
                sortable: true,
                align: 'right',
                name: "Traffic for month",
                dataKey: "traffic_for_month",
                value: ({billing = {}}) => {
                    const traffic = <>{billing?.VOLUME_30_IN}</>

                    return traffic;
                
                },
                isMobile: false,
            },
            {
                flexGrow: 1,
                minWidth: 90,
                sortable: true,
                align: 'right',
                name: "Credit limit",
                dataKey: "supplier_credit_limit",
                isMobile: false,
            },
            {
                flexGrow: 1,
                minWidth: 130,
                sortable: true,
                align: 'right',
                name: "Creation date",
                dataKey: "created_at_date",
                value: ({created_at}) => {
                    const date = new Date(created_at);
                    const options = {year: 'numeric', month: 'long', day: 'numeric'};
                    return date.toLocaleDateString('en-US', options);
                },
                isMobile: false,
            },
            {
                width: 50,
                sortable: false,
                align: 'right',
                name: "",
                value: ({not_verified_users}) => {
                    return <SupplierNotVerifiedUsersIcon
                        setPartialRedirect={setPartialRedirect}
                        show={not_verified_users && not_verified_users !== 0 }
                        count={not_verified_users}
                    />
                },
                isMobile: false,
            },
            ...(hasBlockedSupplier ? [{
                width: 50,
                sortable: false,
                align: 'right',
                name: "",
                value: ({allocation_ban_expired_at = null}) => {
                    return <SupplierBlockedNumberMinerIcon
                        show={allocation_ban_expired_at !== null}
                    />
                },
                isMobile: false,
            }] : []),
            {
                flexGrow: 2,
                minWidth: 170,
                sortable: true,
                name: "Supplier",
                dataKey: "name",
                value: ({id, name, account_note_last, supplier_manager_name}) => {
                    return <FlexboxGrid justify="space-between">
                        <FlexGridItemPrefix>
                            <SupplierNotesIcon
                                setPartialRedirect={setPartialRedirect}
                                type={account_note_last}
                                supplierId={id}
                            />
                        </FlexGridItemPrefix>
                        <FlexboxGrid.Item>
                            {name}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            {supplier_manager_name}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                },
                isMobile: true,
            },
            {
                flexGrow: 1,
                minWidth: 100,
                sortable: true,
                dataKey: "pending_amount",
                name: "Balance",
                value: ({billing = {}, currency_name = ""}) => {
                    const balancePending = billing?.pending_amount
                    const balanceInvoice = `${billing?.ready_for_payment}, ${currency_name}`
                    return <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">{balancePending}</div>
                        <div className="table-two-staged__cell-second">{balanceInvoice}</div>
                    </div>
                },
                isMobile: true,
            },

            {
                width: 50,
                sortable: false,
                align: 'right',
                name: "",
                value: ({not_verified_users}) => {
                    return <SupplierNotVerifiedUsersIcon
                        setPartialRedirect={setPartialRedirect}
                        show={not_verified_users && not_verified_users !== 0 }
                        count={not_verified_users}
                    />
                },
                isMobile: true,
            },
            ...(hasBlockedSupplier ? [{
                width: 50,
                sortable: false,
                align: 'right',
                name: "3",
                value: ({allocation_ban_expired_at = null}) => {
                    return <SupplierBlockedNumberMinerIcon
                        show={allocation_ban_expired_at !== null}
                    />
                },
                isMobile: true,
            }]: []),
        ];

        return columns.filter(column => column.isMobile === isMobile);

    }, [data, isMobile]);

    const handleSortColumn = (sortColumn, sortType) => {
        setSortLoading(true);
        setTimeout(() => {
            setSortLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 300);
    };

    return (
        <BaseTable
            virtualized
            height={700}
            data={getData()}
            loading={loading || sortLoading}
            rowHeight={!isMobile ? 30 : 50}s
            rowClassName={(rowData) => {
                if (rowData) {
                    const {is_managed} = rowData;
                    let rowClassName = !rowData.active 
                        ? 'table-row-account__closed table-row-account---link' 
                        : 'table-row-account table-row-account---link';

                    if (!is_managed) {
                        rowClassName += " table-row-account--disabled";
                    }
          
                    return rowClassName;
                }
            }}
            onRowClick={ ({id, supplier_id, is_managed, ...acc}) => {
                if (is_managed) {
                    localStorage.setItem(`CURRENT_SUPPLIER_${id}`, JSON.stringify({
                        id,
                        supplier_id,
                        ...acc
                    }));

                    const activeTab = partialRedirect ? partialRedirect : 'gan_trunks';

                    const path = `/sms-suppliers/view/${id}?service=sms`; 

                    window.open(path);
                    localStorage.setItem('ACCOUNT_ACTIVE_TAB', activeTab);
                    
                }
            }}
            {...props}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
        >
            {columns.map(({dataKey, name, value = null, ...columnProps}) => (
                <Column {...columnProps}>
                    <HeaderCell>{name}</HeaderCell>
                    <Cell dataKey={dataKey}>{value}</Cell>
                </Column>
            ))}

            <Column key="table_scrollfix" width={0} fixed>
                <HeaderCell></HeaderCell>
                <Cell></Cell>
            </Column>

        </BaseTable>
    );
}));

const SupplierNotVerifiedUsersIcon = ({show, count, setPartialRedirect}) => {

    return (
        <StyledSupplierNotVerifiedUsersIcon
            style={{
                width: 20,
                marginRight: 10,
                display: 'inline-block'
            }}
        >
            {show
                ? <Whisper
                    onClick={() => {
                        localStorage.setItem("ACCOUNT_ACTIVE_TAB", "general");
                    }}
                    placement="auto"
                    speaker={<Tooltip>Count of unconfirmed users: {count}</Tooltip>}
                >
                    <Badge
                        content={<span className="badge-count">{count}</span>}
                    >
                        <Icon 
                            onMouseEnter={() => {
                                setPartialRedirect('general');
                            }}
                            onMouseOut={() => {
                                setPartialRedirect(null);
                            }}
                            icon="envelope-o" 
                            className="badge-icon"
                        />
                    </Badge>
                </Whisper>
                : null
            }
        </StyledSupplierNotVerifiedUsersIcon>
    )
}

const SupplierBlockedNumberMinerIcon = ({show, count}) => {
    return (
        <StyledSupplierBlockedNumberMinerIcon
            style={{
                width: 20,
                marginRight: 10,
                display: 'inline-block'
            }}
        >
            {show ? <Whisper
                placement="auto"
                speaker={<Tooltip>Blocked</Tooltip>}
            >
                <>
                    <FontAwesomeIcon icon={faBan} className="badge-icon"/>
                    <span className="icon-letter">A</span>
                </>
            </Whisper>
            : null}
        </StyledSupplierBlockedNumberMinerIcon>
    )
}

const SupplierNotesIcon = ({
    setPartialRedirect,
    supplierId,
    type
}) => {
    const [comments, setComments] = useState([]);
    const [timerId, setTimerId] = useState(null);


    useEffect(() => {
        setComments([]);
    }, [timerId]);


    const getNotes = useCallback( () => {
        setPartialRedirect("notes");
        const timerId = setTimeout(() => {
            api('account_note:get_short_list', {
                target: {account_id: supplierId}
            })
                .then(res => {
                    if (res && res.account_note_list)
                        setComments(res.account_note_list);
                })
        }, 500);
        setTimerId(timerId);
    }, [supplierId] );


    const clearNotes = useCallback(() => {
        setPartialRedirect(null);
        clearTimeout(timerId);
    }, [timerId]);


    return (
        <span style={{width: 20, display: 'inline'}}>
            {type || type === 0
                ? <Whisper
                    enterable={comments.length}
                    placement="auto"
                    speaker={
                        <Popover
                            title={"Recent notes"}
                            onMouseEnter={() => {
                                setPartialRedirect("notes");
                            }}
                            onMouseOut={() => {
                                setPartialRedirect(null);
                            }}
                        >
                            <div>
                                {comments && comments.length
                                    ? comments.map(item => (
                                        <div style={{textAlign: 'left'}}>
                                            <span
                                                className={"rs-icon rs-icon-" + commentTypes[item.type].icon}
                                                style={{
                                                    width: 20,
                                                    color: commentTypes[item.type].color,
                                                }}
                                            />
                                            &nbsp;{item.text}
                                        </div>
                                    ))
                                    : <Loader content="Loading..." style={{paddingTop: 6}} size="xs"/>
                                }
                            </div>
                        </Popover>
                    }
                >
                    <span
                        onMouseEnter={getNotes}
                        onMouseOut={clearNotes}
                        className={"rs-icon rs-icon-" + commentTypes[type].icon}
                        style={{color: commentTypes[type].color}}
                    />
                </Whisper>
                : null
            }
        </span>
    )
};

const SupplierBoundIcon = ({status}) => {
    return <StyledBoundIconContainer>
        <StyledStatusCirle
            status={status}
        />
    </StyledBoundIconContainer>
};

const StyledSupplierBlockedNumberMinerIcon = styled.span`
    && {
        color: red;
    }
    && .badge-icon {
        margin-top: -2px;
        font-size: 24px;
        font-weight: 300;
    }
    && .icon-letter {
        position: absolute;
        top: 6px;
        left: 17px;
        font-size: 17px;
        line-height: 1em;
        font-weight: 500;
    }
`;

const FlexGridItemPrefix = styled(FlexboxGrid.Item)`

`;

const ServiceCell = styled.span`
    ${props => props.service === "voice" ? css`
        color: var(--color-violet);
    ` : css`
        color: var(--color-info);
    `
    }
`;


const SupplierNameContainer = styled.div`
    display: flex;

    .supplier_name {
        margin-left: 5px;
    }
`;

const StyledSupplierNotVerifiedUsersIcon = styled.span`
    .rs-badge {
    
        padding: 2px;
        font-size: 9px;
        
        .rs-badge-content {
            box-shadow: 0 0 0 1px #ffffff;
            background-color: var(--color-info);
            border-radius: 11px;
            font-size: 10px !important;
            line-height: 11px;
            padding: 0 2px;
            color: #ffffff;
            margin-right: 1px;
            margin-top: 3px;
            height: 11px;
            min-width: 11px;
            text-align: center;
        }
        
        .badge-count {
            font-weight: 700;
            font-size: 9px;
        }
    }
`;

const StyledBoundIconContainer = styled.span`
    width: 20px;
    height: 20px;

    display: flex;
    align-items: center;
`;

const StyledStatusCirle = styled.div`
    width: 12px;
    height: 12px;

    background-color: grey;
    border-radius: 50%;

    ${props => props.status === "all" && css`
        background-color: var(--color-success);
    ` || props.status === "none" && css`
        background-color: var(--color-error2);
    ` || props.status === "some" && css`
        // split logic
        width: 0px;
        height: 0px;
        border-right-color: var(--color-error2);
        border-top-color: var(--color-success);
        border-bottom-color: var(--color-error2);
        border-left-color:  var(--color-success);

        border-radius: 6px;
        border-width: 6px;
        border-style: solid;
    ` || props.status === null && css`
        display: none;
    `}
`;