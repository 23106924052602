import React, {useMemo, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import { useWindowWidth } from "../../../hooks";
import styled from "styled-components";
import { Form, FormControl, SelectPicker, Button, Input, Schema, Message } from "rsuite";
import {Spacer} from "../../../components/base/Spacer";
import * as S from "../styled";
import {SCREEN_MEDIA, currency_names_data} from '../../../const';
import m from "../../../definedMessages";
import {BaseInputField, BaseInputNumberField, BaseSelectPicker} from "../../../components/base/BaseForm";

import {
    FieldCurrency
} from "../../PaymentDetails/ModalAddPaymentDetail/Field";
import { compare, compareTwoArrays } from "utils";

const {sm} = SCREEN_MEDIA;
const {StringType, NumberType} = Schema.Types;

export default ({
    show,
    onClose,
    maxWidth = 700,
    paymentDetailsItems,
    currencyName,
    currencyList,
    requestPayment,
    accountJoinList,
    pickedCurrencyKey,
    setPickedCurrencyKey,
    billingCurrencyList = [],
    getPaymentRequestList,
    getPaymentDetailsList,
    handleShowModalAddPaymentDetail,
    dropdownVisibility,
    amountLimitMin = 0.01,
    onSuccess,

    service,
    formValue,
    setFormValue,
}) => {

    const currentBillingCurrency = useMemo(() => {
        return billingCurrencyList ? billingCurrencyList.find(item => item?.cur_key === pickedCurrencyKey) : {};
    }, [pickedCurrencyKey, billingCurrencyList]);

    const availableForPayment = useMemo(() => {
        return currentBillingCurrency ? currentBillingCurrency?.available_for_payment : 0;
    }, [pickedCurrencyKey, currentBillingCurrency]);

    const presentBillingCurrency = billingCurrencyList ? billingCurrencyList
        .filter(billingCurrency => billingCurrency)
        .map(billingCurrency => billingCurrency.cur_key) : [];

    const singleCurKey = useMemo(() => {
        return presentBillingCurrency && presentBillingCurrency.length === 1 ? presentBillingCurrency[0] : null;
    }, [currentBillingCurrency]);
    
    const filteredPaymentDetailsList = useMemo(() => {
        return paymentDetailsItems.filter((paymentDetail) => {
            return paymentDetail.cur_key === pickedCurrencyKey;
        });
    }, [paymentDetailsItems, pickedCurrencyKey]);

    const {formatMessage} = useIntl();
    let formRef = null;

    const formModel = Schema.Model({
        amount: NumberType()
            .isRequired( formatMessage(m.thisFieldIsRequired) )
            .min(amountLimitMin, formatMessage(m.minNumber, {number: amountLimitMin}))
            .max(availableForPayment, formatMessage(m.maxNumber, {number: availableForPayment})),
        payment_detail_id: StringType()
            .isRequired( formatMessage(m.thisFieldIsRequired) ),
    });

    const [loading, setLoading] = useState(false);
    const resizedWidth = useWindowWidth();


    // clean Amount error on limit change
    useEffect( () => {
        if (formRef)
            formRef.cleanErrorForFiled('amount');
    }, [amountLimitMin] );;

    useEffect(() => {
        if (show && pickedCurrencyKey) {
            getPaymentDetailsList({cur_key: pickedCurrencyKey});
        }
    }, [show, pickedCurrencyKey]);

    const handleSubmit = async () => {
        
        if (!formRef.check() )
            return null;

        setLoading(true);

        const value = formRef.getFormValue();
        const data = {
            cur_key: pickedCurrencyKey,
            ...value,
            amount: +value.amount, 
            target: {
                payment_detail_id: value.payment_detail_id,
            },
        };

        delete data.payment_detail_id;

        await requestPayment(data)
            .then((response) => {
                if (response && !response.error) {
                    onSuccess(+value.amount);
                }
            });
        
        getPaymentRequestList();
        setLoading(false);

        onClose();
    };


    const renderMenuItem = (label, item) => {
        return item.status === 1 ? label : <span style={{color: 'var(--color-disabled)'}}>{label}</span>;
    };


    const hasOnlyOneCurrency = compareTwoArrays(presentBillingCurrency, currencyList.map(currency => currency.cur_key));
    const singleCurrency = singleCurKey ? currency_names_data[singleCurKey] : null;

    return (
        <S.FormModal
            show={show}
            title={formatMessage(m.paymentRequest)}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showFooter={true}
            confirmButtonText={formatMessage(m.confirm)}
            loading={loading}
            disabled={!pickedCurrencyKey}
            focusTarget="close"
            onConfirm={handleSubmit}
            onClose={() => {
                onClose(false);
            }}
            onExited={() => {
                setFormValue({amount: 0});
            }}
        >
            <Form
                ref={ref => formRef = ref}
                model={formModel}
                formValue={formValue}
                onChange={setFormValue}
            >
                
                {billingCurrencyList && billingCurrencyList.length ? billingCurrencyList.map(currencyKey => {
                    const available = currencyKey.available_for_payment || 0;
                    const currencyName = currencyKey?.currency_name;
                    return <CurrencyText>{formatMessage(m.readyForPaymentInfo, {count: `${available} ${currencyName}`})}</CurrencyText>
                }) : <></>}

                <Spacer/>

                {!pickedCurrencyKey && <Message type="info" description={formatMessage(m.currencyToCreatePayment)}/>}
                
                <Spacer/>

                <S.FormRow>
                    <S.FormItem>

                        <S.FormGroup>
                            <S.Label>{formatMessage(m.amount)}</S.Label>
                            <S.FieldAmountWrapper>
                                <S.Field
                                    accepter={BaseInputNumberField}
                                    name='amount'
                                />
                            </S.FieldAmountWrapper>
                            {currencyName && <S.Currency>{currencyName}</S.Currency>}
                            <StyledCurrencyPicker>
                                {presentBillingCurrency && presentBillingCurrency.length > 1 ? <>
                                    {/* <S.Label>{formatMessage(m.currency)}</S.Label> */}
                                    <StyledFormControl
                                        name="cur_key"
                                        accepter={SelectPicker}
                                        data={currencyList}
                                        labelKey="name"
                                        valueKey="cur_key"
                                        cleanable={false}
                                        searchable={false}
                                        onChange={(value) => {
                                            setPickedCurrencyKey(value);
                                            setFormValue({...formValue, cur_key: value, payment_detail_id: null});
                                        }}
                                    />
                                </> : singleCurrency && 
                                <SingleCurrencyName>{singleCurrency}</SingleCurrencyName>}
                            </StyledCurrencyPicker>
                        </S.FormGroup>

                    </S.FormItem>
                    <S.FormItem grow>

                        <S.FormGroup>
                            <S.Label>{formatMessage(m.paymentInfo)}</S.Label>
                            <S.Field
                                accepter={BaseSelectPicker}
                                data={filteredPaymentDetailsList}
                                cleanable={false}
                                name='payment_detail_id'
                                valueKey="id"
                                block={true}
                                placement={resizedWidth <= sm ? "auto" : "bottomStart"}
                                labelKey="payment_detail_info"
                                menuClassName={dropdownVisibility ? "" : "hide-dropdown"}
                                renderMenuItem={renderMenuItem}
                                disabled={!pickedCurrencyKey}
                                renderExtraFooter={() => {
                                    return (
                                        <Button 
                                            appearance="link"
                                            onClick={() => {
                                                handleShowModalAddPaymentDetail();
                                            }}
                                        >
                                            + <FormattedMessage id="paymentDetails.addNew" defaultMessage="Add new"/>
                                        </Button>
                                    )
                                }}
                            />
                        </S.FormGroup>
                    </S.FormItem>
                
                </S.FormRow>

                <Spacer/>

                <S.Field
                    name="comment"
                    accepter={BaseInputField}
                    componentClass="textarea"
                    placeholder={formatMessage(m.comment)}
                />
                
            </Form>
        </S.FormModal>
    )
};


const StyledFormControl = styled(FormControl)`
    && {
        width: 90px;
    }
`;

const SingleCurrencyName = styled.span`
    && {

    }
`;

const StyledCurrencyPicker = styled.div`
    && {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .rs-form-control-wrapper {
            width: 90px;
            margin-right: 20px;
        }

        .rs-message .rs-message-container {
            padding: 10px 20px 10px 20px;
            height: 40px;
            display: flex;
            justify-content: center;
        }
    }
`;

const CurrencyText = styled.p`
    && {
        margin-top: 10px;
    }
`;