import React, {useEffect, useState} from "react";
import PageHeader from "components/base/PageHeader";

import RegistrationRequestsTable from "./RegistrationRequestsTable"
import RegistrationRequestsFilter from "./RegistrationRequestsFilter"
import PanelLayout from "components/base/PanelLayout";


export default ({
    service,
    countryList,
    currencyList,
    onSetRedirectTab,
    registrationList,
    getRegistrationList,
    approveRegistration,
    rejectRegistration,
    getRegistrationCount,
    setDefaultFormCreateAccount,
    loading,
}) => {

    useEffect(() => {
        // getAccounts();
        getRegistrationList()
    }, []);

    const [filterState, onChangeFilter] = useState({processed: false});

    return (
        <>
            <PanelLayout>
                <PageHeader title="Registration requests"/>
                <RegistrationRequestsFilter
                    filter={filterState}
                    onChangeFilter={onChangeFilter}
                />
                <RegistrationRequestsTable
                    dataList={registrationList}
                    {...{
                        service,
                        filter: filterState,
                        countryList,
                        currencyList,
                        onSetRedirectTab,
                        getRegistrationList,
                        approveRegistration,
                        rejectRegistration,
                        getRegistrationCount,
                        setDefaultFormCreateAccount,

                        loading,
                    }}
                />
            </PanelLayout>
        </>
    )
};