import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import {E212_SET_LOADING, E212_SET_LIST, E212_OPERATOR_SET_LIST} from './actionTypes';
import {
    E212_GET_LIST_API,
    E212_OPERATOR_GET_LIST_API
} from '../const/apiMethods';
import {E212 as PREFIX} from "../const";

const setLoading = createAction(E212_SET_LOADING);
const setSettingList = createAction(E212_SET_LIST);
const setOperatorList = createAction(E212_OPERATOR_SET_LIST);

export const getE212List = (filter, page, perPage, sort) => async (dispatch) => {
    dispatch(setLoading(true));

    return await api(E212_GET_LIST_API, {filter, page, per_page: perPage, sort})
        .then((response) => {
            localStorage.setItem(PREFIX + '_per_page', perPage);

            dispatch(setSettingList({
                list: response.e212_e164_list,
                count: response.e212_e164_count,
                page,
                perPage
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};

export const getE212DropdownList = (filter={}, no_code=false, landline=null) => async (dispatch) => {
    return await api(E212_GET_LIST_API, {filter, no_code, landline})
        .then((response) => {
            dispatch(setSettingList({
                list: response.e212_e164_list,
                count: response.e212_e164_count
            }));
        });
};

export const getE212OperatorList = () => async (dispatch) => {
    return await api(E212_OPERATOR_GET_LIST_API, {})
        .then((response) => {
            dispatch(setOperatorList({
                operator_list: response.operator_list
            }))
        });
}