import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Checkbox } from "rsuite";
import { debounce, renderField } from "utils";
import { newSubAccount } from "routes/routes";
import styled from "styled-components";

import { ButtonPrimary } from "../../../components/base/BaseButton";

import m from "definedMessages";


const AccessListFilter = ({
    value,
    setValue,
    
    formatMessage
}) => {

    const [showClosed, setShowClosed] = useState(value.show_closed);

    const formFields = [
        {
            name: "name",
            placeholder: formatMessage(m.subaccontName),
            width: 300
        }
    ];

    const renderedFields = formFields.map(renderField);

    return (
        <StyledForm
            formDefaultValue={value}
            onChange={debounce((formValue) => {
                setValue((value) => {
                    return {
                        ...value,
                        ...formValue,
                        show_closed: showClosed
                    }
                });
            }, 500)}
        >
            {renderedFields}
            <FormGroup style={{width: 120}}>
                <Checkbox
                    name="show_closed"
                    onChange={(_, checked) => {
                        setShowClosed(checked)
                        setValue((value) => {
                            return {
                                ...value,
                                show_closed: checked
                            }
                        });
                    }}
                    defaultChecked={showClosed}
                >
                    {formatMessage(m.showClosed)}
                </Checkbox>
            </FormGroup>
        </StyledForm>
    )
};

export default AccessListFilter;

const StyledForm = styled(Form)`
    && {
        display: flex;
    }

    && .pull-right-button {
        height: 36px;
        width: 180px;
        margin-left: auto;
    }
`;