import React from "react";
import {Form, FormControl, Icon, Input, InputGroup} from "rsuite";
import styled from "styled-components";
import {debounce} from '../../../utils';

export default (
    {
        filter,
        setFilter
    }
) => {

    return (
        <StyledField>
            <Form
                defaultValue={filter}
                onChange={debounce((value) => setFilter(value), 700)}
            >
                <InputGroup>
                    <FormControl
                        name="str"
                        accepter={Input}
                        placeholder={'Sound filename, IVR, CLI or phone'}
                    />
                    <InputGroup.Addon>
                        <Icon icon="search" />
                    </InputGroup.Addon>
                </InputGroup>
            </Form>
        </StyledField>
    )
}

const StyledField = styled.div`
    max-width: 300px;
    width: 100%;
`;