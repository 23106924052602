import React, { useState } from 'react';
import {ButtonPrimary} from 'components/base/BaseButton';
import {getCsvResponse} from 'api/loginRoutes';
import {downloadDataAsFile} from 'utils';


export default () => {

    const [loading, setLoading] = useState(false);

    const downloadAuthNumbers = () => {
        setLoading(true);
        getCsvResponse('e164:get_list')
            .then((response) => {
                downloadDataAsFile(response, 'E164.csv')
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    return (
        <ButtonPrimary
            loading={loading}
            onClick={downloadAuthNumbers}
        >
            Download
        </ButtonPrimary>
    )
}