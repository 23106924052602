import {NEWS as PREFIX} from '../const';
import {
    NEWS_LIST_API,
    NEWS_LIST_DIALER_API,
    NEWS_NEW_COUNT_LAST_API,
} from '../const/apiMethods';

import {createAction} from './defaults';
import {api} from "../api/loginRoutes";

const setNews = createAction('SET_ITEMS_' + PREFIX);
const setNewsDialer = createAction('SET_DIALER_ITEMS_' + PREFIX);
const setCountDialer = createAction('SET_DIALER_COUNT_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);

export const clearCountDialer = createAction('CLEAR_DIALER_COUNT_' + PREFIX);


export const getNews = (page = 1, perPage = 10) => (dispatch) => {
    dispatch(setLoading(true));
    api(NEWS_LIST_API, {per_page: perPage, page: page})
        .then((r) => {
            if (r === undefined) {
                return;
            }
            dispatch( setNews({
                list: r.news_list,
                count: r.news_count,
            }) );
        })
        .finally(() => {
            dispatch( setLoading(false) )
        });
};

export const getNewsDialer = (page = 1, perPage = 10) => (dispatch) => {
    dispatch(setLoading(true));
    api(NEWS_LIST_DIALER_API, {
        per_page: perPage, 
        page: page,
        set_read_all: true,
    })
        .then((r) => {
            if (r === undefined) {
                return;
            }
            dispatch( setNewsDialer({
                list: r.news_list,
                count: r.news_count,
            }) );
        })
        .finally(() => {
            dispatch( setLoading(false) )
        });
};

export const getCountDialer = () => (dispatch) => {
    api(NEWS_NEW_COUNT_LAST_API)
        .then((r) => {
            if (r === undefined) {
                return;
            }
            dispatch( setCountDialer({
                countDialer: r.news_new_count,
                post: r.news_new_last,
            }) );
        });
};