import React, {useRef, useMemo, useState} from 'react';
import Checkbox from '../../../../../hoc/Checkbox';
import {useWindowWidth} from "../../../../../hooks";
import {SelectPicker, Form, Schema, InputNumber, ControlLabel, FlexboxGrid, FormControl, Whisper, Tooltip} from "rsuite";
import HiddenRate from './HiddenRate';
import CustomField from '../../../../../components/Form/CustomField';

const {NumberType} = Schema.Types;

export default ({
    defaultFormValue,
    onChangeForm,
    formValue,
    disabled,
    operatorList
}) => {

    const [prefixCount, setPrefixCount] = useState(1);

    const numbersList = useMemo(() => {
        const prefixCount = 0;
        const difference = 6-prefixCount > 0 ? 6-prefixCount : 1;

        const numberArray = Array.from(Array(difference).keys());
        return numberArray.map(x => 10 ** x).map(x => ({label:x, value:+x}));
        
    }, [formValue]);

    const formModel = Schema.Model({
        sde_key: NumberType()
            .isRequired('Required'),
        prefixes: NumberType()
            .min(1, 'Minimum is 1')
            .max(100000, `Maximum is 100000`)
            .isRequired('Required'),
        numbers: NumberType()
            .isRequired('Required'),
    });

    return (
        <Form
            defaultFormValue={defaultFormValue}
            onChange={onChangeForm}
            formModel={formModel}
            formValue={formValue}
        >
            <FlexboxGrid justify="start" style={{margin: '0 0 20px 0'}}>
                <FlexboxGrid.Item>
                    <ControlLabel>Operator</ControlLabel>
                    <CustomField
                        disabled={disabled}
                        accepter={SelectPicker}
                        data={operatorList}

                        // renderMenuItem={(label, _) => {
                        //     return <Whisper
                        //         trigger="hover" 
                        //         placement="bottomLeft"
                        //         enterable={true}
                        //         speaker={<Tooltip>{label}</Tooltip>}
                        //     >
                        //         <>{label}</>
                        //     </Whisper>
                        // }}
                        labelKey="operatorMccMnc"
                        valueKey="mccMnc"
                        placeholder="Operator"
                        errorPlacement="topRight"
                        name="mccMnc"
                        style={{width: 520}}
                        // Зробити підказку щоб видно було все значення
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <FlexboxGrid justify="start" style={{margin: '0 0 20px 0'}}>
            <FlexboxGrid.Item>
                    <ControlLabel>Prefix count</ControlLabel>
                    <FormControl
                        disabled={disabled}
                        accepter={InputNumber}
                        name="prefixes"
                        placeholder="Prefixes"
                        style={{width: 180}}
                        value={prefixCount}
                        onChange={(value) => {
                            setPrefixCount(value);
                        }}
                        min={1}
                        max={100000}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="ml-4">
                    <ControlLabel>Numbers</ControlLabel>
                    <FormControl
                        disabled={disabled || !formValue?.prefixes}
                        accepter={SelectPicker}
                        data={numbersList}
                        name="numbers"
                        labelKey="label"
                        valueKey="value"
                        placeholder="Numbers"
                        style={{width: 230}}
                        searchable={false}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <br/>
            <HiddenRate/>

        </Form>    
    )
}