import {IVR_SOUNDS as PREFIX} from '../const/';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';
import {IVR_SOUND_LIST_API} from "../const/apiMethods";

const setIvrSoundList = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);

export const getIvrSoundList = (filter = {}) => (dispatch) => {
    dispatch(setLoading(true));
    api(IVR_SOUND_LIST_API, {filter})
        .then(({ivr_sound_list}) => {
            if (ivr_sound_list === undefined) {
                return;
            }
            dispatch(setIvrSoundList({
                list: ivr_sound_list
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};