import React, { useRef, useMemo, useState, useEffect } from "react";
import Table from "components/Table/TableCommonNew";
import { Table as RSTable, Alert, Whisper, Tooltip } from "rsuite";
import {
    ButtonRowEdit, ButtonRowUpload, ButtonRowDownload
} from "components/base/BaseButton";
import styled from "styled-components";
import ModalAllocationNumbers from "components/client/Modal/ModalAllocationNumbers";
import ModalAllocationResult from "components/client/Modal/ModalAllocationResult";
import NumbersChangeDiscountModal from "./NumbersChangeDiscountModal";

import { TRUNK_NUMBER_GET_LIST_API } from "const/apiMethods";
import { getBlobContent, onCopyList, downloadList } from "utils";
import { getFileResponse } from "api/loginRoutes";

import m from "definedMessages";


const {Column, HeaderCell, Cell} = RSTable;

const NumbersTrunkTable = ({
    data,
    isMobile,
    loading,
    uploadLoading,
    allocationLoading,

    currentTrunk,
    currentTrunkId,
    trunkNumberTransaction,

    isSubAccAllocation,

    allAllocated,
    allocatedNumbers,
    allocatedNumbersCount,
    showResultAllocationModal,

    setIsSubAccAllocation,

    setAllocatedNumbers,
    setAllocatedNumbersCount,
    setShowResultAllocationModal,

    setCurrentTrunkId,

    update,

    downloadNumbers,
    showReasonHandler,
    setUploadingLoading,
    setTrunkNumberTransaction,

    downloadNumbersFunc,
    changeDefaultDiscountFunc,

    formatMessage
}) => {
    const tableRef = useRef(null);

    const columns = useMemo(() => [
        {
            label: formatMessage(m.name),
            dataKey: "name",
            value: (({name}) => {
                return <span className="table-centered__cell">
                    {name}
                </span>
            }),
            sortable: false,
            flexGrow: 2,
            minWidth: 220,
        },
        {
            id: "type",
            label: formatMessage(m.type),
            value: (({tprefix, ip, port, is_default_term_point, ivr_name}) => {
                return !is_default_term_point
                    ? (ip && port ? `${ip}:${port} ${tprefix}` : "")
                    : (ivr_name);
            }),
            align: "center",
            minWidth: 170,
            flexGrow: 1
        },
        {
            label: formatMessage(m.price),
            dataKey: "service_plan_name",
            value: (({service_plan_name}) => {
                return <span className="table-centered__cell">
                    {service_plan_name}
                </span>
            }),
            sortable: false,
            flexGrow: 1,
            minWidth: 120,
        },
        {
            label: formatMessage(m.numbers),
            dataKey: "numbers",
            value: (({allocated_numbers, subacc_allocated_numbers}) => {
                return <span className="table-centered__cell">
                    {allocated_numbers}/{subacc_allocated_numbers}
                </span>
            }),
            sortable: false,
            minWidth: 100,
            flexGrow: 1
        },
        {
            label: formatMessage(m.defaultDiscount),
            dataKey: "discount",
            value: ({subacc_rate_percent_week, subacc_rate_percent_month}) => {
                const weekRatePercent = parseFloat(subacc_rate_percent_week * 100).toFixed(1);
                const monthRatePercent = parseFloat(subacc_rate_percent_month * 100).toFixed(1);

                
                if (isMobile) {
                    return <div className="table-two-staged__cell same">
                        <div className="table-two-staged__cell-first">{formatMessage(m.weekly)}: -{weekRatePercent}%</div>
                        <div className="table-two-staged__cell-second">{formatMessage(m.monthly)}: -{monthRatePercent}%</div>
                    </div>
                }

                return <span className="table-centered__cell">
                    {formatMessage(m.weekly)}: -{weekRatePercent}%, {formatMessage(m.monthly)}: -{monthRatePercent}%
                </span>
            },
            sortable: false,
            minWidth: 140,
            flexGrow: 1
        }
    ], [isMobile]);

    const [showAddNewNumbersModal, setShowAddNewNumbersModal] = useState(false);
    const [showChangeDiscountModal, setShowChangeDiscountModal] = useState(false);

    const [showWarningAllocationMessage, setShowWarningAllocationMessage] = useState(false);

    const [transactionId, setTransactionId] = useState(null);
    const [resultLoading, setResultLoading] = useState(false);


    const [notAllocatedNumbers, setNotAllocatedNumbers] = useState(null);


    const onAddNewNumbers = () => {
        setIsSubAccAllocation(false);
        setShowAddNewNumbersModal(true);
    };

    const onDownloadNumbers = () => {
        // download
        downloadNumbersFunc(currentTrunkId);
    };


    const onChangeDiscount = (id) => {
        setShowChangeDiscountModal(true);
    };

    const onAllocationGetResult = (response) => {
        setTrunkNumberTransaction(response.trunk_number_transaction || {});
        const transaction = response.trunk_number_transaction;
        const notAllocatedNumbers = response.not_allocated_numbers;

        if (transaction) {
            const transactionId = transaction.id;
            const count = transaction.numbers;

            setTransactionId(transactionId);
            setAllocatedNumbersCount(count);
            setResultLoading(true);

            getFileResponse(TRUNK_NUMBER_GET_LIST_API, {
                target: {
                    trunk_number_transaction_id: transactionId
                }
            })
                .then((response) => {
                    if (response) {
                        const csvText = getBlobContent(response);
                        csvText.then((text) => {
                            setShowAddNewNumbersModal(false);

                            setAllocatedNumbers(text)
                            setShowResultAllocationModal(true)
                            setResultLoading(false);
                        });
                    }
                })
        }

        if (notAllocatedNumbers) {
            setNotAllocatedNumbers(notAllocatedNumbers);
        }
    };

    const clearAllocatedData = () => {
        setAllocatedNumbers(null);
        setAllocatedNumbersCount(0);
    };


    const actions = ActionCell(
        onAddNewNumbers,
        onDownloadNumbers,
        onChangeDiscount,
        formatMessage
    );

    return <>
        <StyledTable
            row_key="id"
            ref={tableRef}

            data={data}

            rowClassName={(rowData) => {
                if (rowData && rowData.id === currentTrunkId) {
                    return "row-active";
                }
            }}

            onRowClick={(id) => {
                if (id && currentTrunkId !== id) {
                    setCurrentTrunkId(id);
                }
            }}

            shouldUpdateScroll={true}
            extraHeight={isMobile ? 208 : 180}

            loading={loading}
            virtualized={true}
            rowHeight={isMobile ? 42 : 32}
            headerHeight={40}

            originalColumns={columns}
            columns={columns}
            
            ActionCell={actions}

        />
        <ModalAllocationNumbers
            show={showAddNewNumbersModal}
            selectedTrunkId={currentTrunkId}
            loading={resultLoading}
            onClose={setShowAddNewNumbersModal}
            onSuccess={(response) => {
                onAllocationGetResult(response);
                update();

                if (response?.reason_list && Object.keys(response.reason_list).includes("template_max_numbers")) {
                    setShowWarningAllocationMessage(true);
                }
            }}
            spKey={null}
            showReasonHandler={showReasonHandler}
            service={true}
            trunks={data}
        />

        <ModalAllocationResult
            show={showResultAllocationModal}
            maxWidth={850}
            isSubacc
            trunks={data}
            onCopy={(text) => onCopyList(Alert, text)}
            downloadNumbers={(list, id, setLoading) => {
                downloadList(list, "allocated_numbers", "csv", () => {
                    setLoading(id, false);
                }); 
            }}
            downloadNumbersTxt={(list, id, setLoading) => {
                downloadList(list, "allocated_numbers", "txt", () => {
                    setLoading(id, false);
                }); 
            }}
            onDownloadNumbers={(id) => {
                downloadNumbers(id, true);
            }}
            uploadLoading={uploadLoading}
            loading={allocationLoading}
            trunkId={currentTrunkId}
            defaultTrunkId={null}
            trunkAllocatedByOTP={false}
            methodName="Trunks"
            account={null}
            service={true}
            allAllocated={allAllocated}
            csvContent={allocatedNumbers}
            notAllocatedNumbers={notAllocatedNumbers}
            trunkNumberTransaction={trunkNumberTransaction}
            allocatedNumbersCount={allocatedNumbersCount}
            setUploadingLoading={setUploadingLoading}
            showWarningAllocationMessage={showWarningAllocationMessage}
            onClose={() => {
                clearAllocatedData();
                setShowWarningAllocationMessage(false);
                setNotAllocatedNumbers(null);
                setShowResultAllocationModal(false);
            }}
        />
        {currentTrunk && <NumbersChangeDiscountModal
            show={showChangeDiscountModal}
            disabled={loading}
            defaultFormValue={{
                week_percent: currentTrunk?.subacc_rate_percent_week, 
                month_percent: currentTrunk?.subacc_rate_percent_month  
            }}
            onSuccess={(percentWeek, percentMonth) => {
                changeDefaultDiscountFunc(currentTrunkId, percentWeek, percentMonth)
                    .then((response) => {
                        update();
                    })
                    .finally(() => {
                        setShowChangeDiscountModal(false);
                    });
            }}
            onCloseModal={() => {
                setShowChangeDiscountModal(false);
            }}
            formatMessage={formatMessage}
        />}
    </>
};

export default NumbersTrunkTable;

const StyledTable = styled(Table)`
    .table-two-staged__cell.same {
        .table-two-staged__cell-first {
            font-size: 13px;
            color: #575757;
        }
        .table-two-staged__cell-second {
            font-size: 13px;
            color: #575757;
        }
    }

`;

const ActionCell = (addNew, download, changeDiscount, formatMessage) => (
    <Column minWidth={120} flexGrow={1}>
        <HeaderCell>{formatMessage(m.actions)}</HeaderCell>
        <Cell>
            {rowData => {

                const isGan = rowData.incorporated;
                const allocationAllowed = rowData.allocation_limit.hasOwnProperty("allocate") 
                    && rowData.allocation_limit.allocate;

                return <StyledActionCell>
                    
                    {!!allocationAllowed && addNew && (!isGan) && <Whisper
                        speaker={<Tooltip>{formatMessage(m.addNewNumbers)}</Tooltip>}
                        trigger="hover"
                    >
                        <ButtonRowUpload 
                            onClick={() => addNew()}
                        />
                    </Whisper>}
                    
                    {download && <Whisper
                        speaker={<Tooltip>{formatMessage(m.downloadNumbers)}</Tooltip>}
                        trigger="hover"
                    >
                        <ButtonRowDownload 
                            loading={rowData.downloadLoading} 
                            onClick={() => download(rowData.id)}
                        />
                    </Whisper>}
                    
                    {changeDiscount && <Whisper
                        speaker={<Tooltip>{formatMessage(m.changeDefaultDiscount)}</Tooltip>}
                        trigger="hover"
                    >
                        <ButtonRowEdit 
                            onClick={() => changeDiscount(rowData.id)}
                        />
                    </Whisper>}

                </StyledActionCell>
            }}
        </Cell>
    </Column>
);

const StyledActionCell = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
`;