import React, { useRef, useMemo, useState, useEffect } from "react";
import { usePrevious } from "hooks";
import { compareObjects, formatDateApi, toStringDate } from "utils";
import Page from "components/base/Page";
import PageHeader from "components/base/PageHeader";
import NumbersFilter from "./NumbersFilter";
import NumbersTable from "./NumbersTable";
import NumbersTrunkTable from "./NumbersTrunkTable";
import ChangeRateModal from "../Accounts/Account/Tabs/Numbers/NumbersChangeRateModal";
import NumbersAllocateModal from "./NumbersAllocateModal";
import { Spacer } from "components/base/Spacer";
import { useWindowWidth } from "hooks";
import { Alert } from "rsuite";
import { ButtonPrimary } from "components/base/BaseButton";
import ModalConfirm from "components/Modal/ModalConfirm";
import { FilterSectionFlexboxGrid, StyledItemFitWidth, StyledItemAutoMargin } from "components/base/FitContainers";
import { useIntl } from "react-intl";

import m from "definedMessages";


const Numbers = ({
    numberList,
    numberPage,
    numberCount,
    numberPerPage,
    numberListLoading,

    trunkList,
    trunkListLoading,

    trunkNumberTransaction,
    uploadLoading,
    allocationLoading,

    subAccountList,
    accountJoinList,

    getNumberList,
    getSubAccountList,
    getNumberTrunksList,

    downloadNumbers,
    showReasonHandler,
    setUploadingLoading,
    setTrunkNumberTransaction,

    addNewNumbersFunc,
    downloadNumbersFunc,
    changeDefaultDiscountFunc,

    changeRateNumbersFunc,
    revokeNumbersFunc,
    allocateNumbersFunc,
    exportSelectedFunc
}) => {
    const {formatMessage} = useIntl();

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 1080;

    const defaultSort =  {column: "name", type: "asc"};

    const defaultFilterValue = {subacc_id_list: [], show_allocated: true, show_free: true};

    const defaultSelectedItems = {all: false, list: []};
    
    const [filterState, setFilterState] = useState(defaultFilterValue);
    const [currentTrunkId, setCurrentTrunkId] = useState(null);
    const [currentTrunk, setCurrentTrunk] = useState(null);

    const [allAllocated, setAllAllocated] = useState(false);

    const [selectedItems, setSelectedItems] = useState(defaultSelectedItems);
    const [selectedData, setSelectedData] = useState([]);
    const [clearSelectedNumbersData, setClearSelectedNumbersData] = useState(false);

    const [showChangeRateModal, setShowChangeRateModal] = useState(false);
    const [showConfirmNameModal, setShowConfirmNameModal] = useState(null);
    const [showAllocateNumbersModal, setShowAllocateNumbersModal] = useState(false);

    const [numbersSort, setNumbersSort] = useState(defaultSort);

    const [allocatedNumbers, setAllocatedNumbers] = useState(null);
    const [allocatedNumbersCount, setAllocatedNumbersCount] = useState(0);

    const [showResultAllocationModal, setShowResultAllocationModal] = useState(false);

    const [isSubAccAllocation, setIsSubAccAllocation] = useState(false);

    const prevShowResultAllocationModal = usePrevious(showResultAllocationModal);

    const confirmationDictionary = {
        revoke: {
            show: true, 
            onClose: () => {
                onCloseConfirmModal();
            },
            onSuccess: async () => {
                await onRevoke().then(({revoked_number_count}) => {
                    update();
                    
                    Alert.success(formatMessage(m.numbersWereRevoked, {count: revoked_number_count}))
                });
                onCloseConfirmModal();
            },
            title: formatMessage(m.areYouSure),
            body: selectedItems.all 
                ? formatMessage(m.areYouSureAllNumbersWillBeRevoked) 
                : formatMessage(m.numbersWillBeRevoked, {count: selectedItems.list.length})
        },
        [null]: {
            show: false
        }
    };

    const prevFilterState = usePrevious(filterState);

    const activeTrunkList = useMemo(() => {
        return trunkList.filter((trunk) => !!trunk.active && !trunk.closed);
    }, [trunkList]);

    const accountData = useMemo(() => {
        return accountJoinList.find((account) => {
            return account.service === "voice";
        })
    }, [accountJoinList]);

    useEffect(() => {
        if (prevShowResultAllocationModal === true && 
            prevShowResultAllocationModal !== showResultAllocationModal) {
            setAllAllocated(false);
        }
    }, [showResultAllocationModal]);

    useEffect(() => {
        if (accountData?.id) {
            getSubAccountList(accountData.id);
        }
    }, [accountData]);

    useEffect(() => {
        update();
    }, [currentTrunkId]);

    useEffect(() => {
        if (!compareObjects(filterState, prevFilterState)) {
            update(true);
        }
    }, [filterState]);

    useEffect(() => {
        if (activeTrunkList && activeTrunkList.length) {
            const firstTrunk = activeTrunkList[0];
            if (!currentTrunkId) {
                setCurrentTrunkId(firstTrunk?.id);
                setCurrentTrunk(firstTrunk);
            } else {
                const currentTrunk = activeTrunkList.find((trunk) => trunk.id === currentTrunkId);
                setCurrentTrunk(currentTrunk);
            }
        }
    }, [trunkList]);

    useEffect(() => {
        if (clearSelectedNumbersData) {
            setClearSelectedNumbersData(false);
        }
    }, [clearSelectedNumbersData]);

    const update = (onlyNumbers=false) => {
        setClearSelectedNumbersData(true);

        if (!onlyNumbers) {
            getNumberTrunksList(true);
        }

        if (currentTrunkId) {
            getNumberList(currentTrunkId, null, filterState, 1, numberPerPage, numbersSort);
        }
    };

    const getNumberItems = (_page, _perPage, sort) => {
        if (currentTrunkId) {
            getNumberList(currentTrunkId, null, filterState, _page, _perPage, sort);
        }
    };

    const onChangeRate = async (rate) => {
        const filter = {
            ...(filterState?.filter_phone  ? {filter_phone: filterState.filter_phone} : {})
        };

        await changeRateNumbersFunc(currentTrunkId, rate, filter, selectedItems.list, selectedItems.all)
            .then(({change_rate_number_count}) => {
                update();
                Alert.success(formatMessage(m.rateWasChanged, {count: change_rate_number_count}));
            })
            .finally(() => {
                setShowChangeRateModal(false);
            })
    };

    const onRevoke = async () => {
        const filter = {
            ...(filterState?.filter_phone  ? {filter_phone: filterState.filter_phone} : {})
        };

        return await revokeNumbersFunc(currentTrunkId, filter, selectedItems.list, selectedItems.all);
    };

    const onAllocate = (rate, subAccId) => {
        const filter = {
            ...(filterState?.filter_phone  ? {filter_phone: filterState.filter_phone} : {})
        };

        allocateNumbersFunc(currentTrunkId, subAccId, rate, filter, selectedItems.list, selectedItems.all)
            .then((response) => {
                if (!!response && !response.error) {
                    const allocatedData = response.allocated_number_list;
                    const csvDataList = allocatedData && allocatedData.length
                        ? allocatedData.reduce((result, value, idx) => {
                            if (idx === 0) {
                                result.push("Prefix;Digit;Range name;Payout;Currency");
                                // return result;
                            }
                            
                            const collectedData = [value.number, 0, value.name, value.rate, value.currency_name];
                            
                            result.push(collectedData.join(";"));
                            
                            return result;  
                        }, [])
                        : [];
          
                    const csvData = csvDataList.join("\n");

                    setShowResultAllocationModal(true);
                    setAllocatedNumbersCount(response.allocated_number_count);
                    setAllocatedNumbers(csvData);
                }
            })
            .finally(() => {
                setShowAllocateNumbersModal(false);
                update();
            });
    };

    const onCloseConfirmModal = () => {
        setShowConfirmNameModal(null);
    };

    const notSelectedDisabled = (!selectedItems.list.length && !selectedItems.all);

    const selectedItemsHasAllocated = selectedData.some((data) => !!data.subacc_id);

    const activeSubAccountList = subAccountList.filter((subAccount) => {
        return !!subAccount.active;
    });

    const actionButtonsDisabled = numberListLoading || notSelectedDisabled;
    const allocationButtonDisabled = actionButtonsDisabled || selectedItemsHasAllocated || !activeSubAccountList.length;

    return (<Page>
        <PageHeader title={formatMessage(m.numbers)} showService={false}/>
        <NumbersTrunkTable
            data={trunkList}
            isMobile={isMobile}

            loading={trunkListLoading}
            uploadLoading={uploadLoading}
            allocationLoading={allocationLoading}

            currentTrunk={currentTrunk}
            currentTrunkId={currentTrunkId}
            trunkNumberTransaction={trunkNumberTransaction}

            setCurrentTrunkId={setCurrentTrunkId}

            update={update}

            allAllocated={allAllocated}
            allocatedNumbers={allocatedNumbers}
            allocatedNumbersCount={allocatedNumbersCount}
            showResultAllocationModal={showResultAllocationModal}

            downloadNumbers={downloadNumbers}
            showReasonHandler={showReasonHandler}
            setUploadingLoading={setUploadingLoading}
            setTrunkNumberTransaction={setTrunkNumberTransaction}

            downloadNumbersFunc={downloadNumbersFunc}
            changeDefaultDiscountFunc={changeDefaultDiscountFunc}

            isSubAccAllocation={isSubAccAllocation}
            setIsSubAccAllocation={setIsSubAccAllocation}

            setAllocatedNumbers={setAllocatedNumbers}
            setAllocatedNumbersCount={setAllocatedNumbersCount}
            setShowResultAllocationModal={setShowResultAllocationModal}

            formatMessage={formatMessage}
        />
        <Spacer/>
        <FilterSectionFlexboxGrid>
            <StyledItemFitWidth isMobile={isMobile}>
                <NumbersFilter
                    isMobile={isMobile}
                    
                    filter={filterState}
                    subAccountList={subAccountList}

                    onChangeFilter={setFilterState}
                    formatMessage={formatMessage}
                />
            </StyledItemFitWidth>
            <StyledItemAutoMargin isMobile={isMobile}>
                <ButtonPrimary
                    disabled={actionButtonsDisabled}
                    onClick={() => {
                        setShowChangeRateModal(true);
                    }}
                >
                    {formatMessage(m.changeRate)}
                </ButtonPrimary>
                <ButtonPrimary
                    disabled={actionButtonsDisabled}
                    onClick={() => {
                        setShowConfirmNameModal("revoke");
                    }}
                >
                    {formatMessage(m.revoke)}
                </ButtonPrimary>
                <ButtonPrimary
                    disabled={allocationButtonDisabled}
                    onClick={() => {
                        setShowAllocateNumbersModal(true);
                        setIsSubAccAllocation(true);
                    }}
                >
                    {formatMessage(m.allocate)}
                </ButtonPrimary>
                <ButtonPrimary
                    disabled={actionButtonsDisabled}
                    onClick={() => {
                        downloadNumbersFunc(currentTrunkId, filterState, selectedItems.list, selectedItems.all);
                    }}
                >
                    {formatMessage(m.exportSelected)}
                </ButtonPrimary>
            </StyledItemAutoMargin>
        </FilterSectionFlexboxGrid>
        <Spacer/>
        <NumbersTable
            isMobile={isMobile}

            data={numberList}
            loading={numberListLoading}
            page={numberPage}
            count={numberCount}
            perPage={numberPerPage}
            sort={numbersSort}

            allAllocated={allAllocated}
            selectedData={selectedData}
            selectedItems={selectedItems}
            clearSelected={clearSelectedNumbersData}
            setSelectedData={setSelectedData}
            setSelectedItems={setSelectedItems}
            setAllAllocated={setAllAllocated}
            setSort={setNumbersSort}

            currentTrunkId={currentTrunkId}

            getItems={getNumberItems}
            formatMessage={formatMessage}
        />
        <ChangeRateModal
            show={showChangeRateModal}
            disabled={numberListLoading}
            changeRateFunc={onChangeRate}
            onCloseModal={() => {
                setShowChangeRateModal(false);
            }}
            formatMessage={formatMessage}
        />
        <NumbersAllocateModal
            show={showAllocateNumbersModal}
            disabled={numberListLoading}

            subAccountList={activeSubAccountList}

            onSuccess={onAllocate}
            onCloseModal={() => {
                setShowAllocateNumbersModal(false);
            }}
            formatMessage={formatMessage}
        />
        <ModalConfirm
            show={confirmationDictionary[showConfirmNameModal].show}
            onClose={confirmationDictionary[showConfirmNameModal].onClose}
            onSuccess={confirmationDictionary[showConfirmNameModal].onSuccess}
            title={confirmationDictionary[showConfirmNameModal].title}
            width={300}
        >{confirmationDictionary[showConfirmNameModal].body}</ModalConfirm>
    </Page>);
};

export default Numbers;