import {defineMessages} from "react-intl";

export default defineMessages({
    accessList: {id: "accesses.accessList", defaultMessage: "Access list", description: "Accesses: Access list"},
    globalAccessList: {id: "accesses.globalAccessList", defaultMessage: "Global access list", description: "Accesses: Global access list"},
    pleaseNoteItHasLimitation: {
        id: "accesses.pleaseNoteItHasLimitation",
        defaultMessage: "Please note it has limitation for services can be used for making traffic. CLI of services you can find at Other->OTP numbers",
        description: "Accesses: Please note it has limitation for services can be used for making traffic. CLI of services you can find at Other->OTP numbers"
    }
})