import React, {useEffect} from "react";
import PanelLayout from "../../components/base/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import AccountManagersTable from "./AccountManagersTable";
import AccountManagersCreateForm from "./AccountManagersCreateForm";

export default ({
    managersList,
    loading,
    teamLeadList,
    userList,
    billingList,
    getAccountManagersList,
    getAccountManagersReferences,
    getAccountManagersBillingList,
    createAccountManager,
    modifyAccountManager,
    removeAccountManager,
}) => {

    useEffect(() => {
        getAccountManagersList();
        getAccountManagersReferences();
        getAccountManagersBillingList();
    }, []);

    const advanceTeamLeadList = makeAdvancedAccountUserList(teamLeadList);

    const advanceUserManagerList = makeAdvancedAccountUserList(userList);

    return (
        <PanelLayout>
            <PageHeader extraTitle="Account Managers"/>
            <AccountManagersTable
                data={managersList}
                {...{
                    loading,
                    teamLeadList: advanceTeamLeadList,
                    userList: advanceUserManagerList,
                    billingList,
                    modifyAccountManager,
                    removeAccountManager,
                    getAccountManagersList,
                }}
            />
            <AccountManagersCreateForm
                onSubmit={createAccountManager}
                {...{
                    managersList,
                    billingList,
                    teamLeadList: advanceTeamLeadList,
                    userList: advanceUserManagerList,
                    getAccountManagersList,
                }}
            />
        </PanelLayout>
    )
};

const makeAdvancedAccountUserList = (list) => {
    return list.map(value => {
        const name = value?.account_user_surname ? `${value.account_user_name} ${value.account_user_surname}` : value.account_user_name;
        return {
            id: value.account_user_id,
            name
        }
    });
};