import React from "react";
import {getFormattedTime} from "utils/helpers";
import {Table} from "rsuite";
import CommonTable from "../CommonTable";
import styled, {css} from "styled-components";
import * as S from "../styled.js";
import {formatDateApi} from "utils";

const {Column, HeaderCell, Cell} = Table;


export default ({
    data = [], 
    loading = false, 
    handleCancelPaymentRequest,
    handleApprovePaymentRequest,
    handleManagePaymentDetails,
    height = null,
    withFilters = false,
}) => {

    const statusLabels = ["Active", "Canceled", "Approved", "Rejected", "Completed"];

    const columns = [
        {
            id: "amount",
            label: "Amount",
            value: ({amount, currency_name}) => `${amount}${currency_name ? " " + currency_name : ""}`,
            width: 110,
            // width: 287,
            align: "center"
        },
        {
            id: "paymentInfo",
            label: "Payment Info",
            value: ({payment_detail_info, move_to_account_name}) => {
                let text = "-";

                if (payment_detail_info)
                    text = payment_detail_info;

                if (move_to_account_name)
                    text = `Transfer to account ${move_to_account_name}`;

                return <SecondStagedCell>
                    <div className="table-two-staged__cell-text">
                        {text}
                    </div>
                </SecondStagedCell>;
            },
            flexGrow: 2,
            width: 150,
            align: "center"
        },
        {
            id: "status",
            label: "Status",
            value: ({account_name, create_date, approve_user, approve_date, reject_user, reject_date, status}) => {
                const statusLabel = statusLabels[status] || "Rejected";
                
                const hasStatus = [
                    statusLabels.indexOf("Approved"),
                    statusLabels.indexOf("Rejected")
                ].includes(status);

                const statusUser = {
                    [statusLabels.indexOf("Approved")]: approve_user,
                    [statusLabels.indexOf("Rejected")]: reject_user
                }[status];

                const statusDate = {
                    [statusLabels.indexOf("Approved")]: approve_date,
                    [statusLabels.indexOf("Rejected")]: reject_date
                }[status];


                const statusCanceled = statusLabels.indexOf("Canceled") === status;

                const showSecondCell = !!(hasStatus && !!statusUser) || statusCanceled;

                const statusText = !statusCanceled ? `${statusLabel} by ${statusUser} at ${statusDate ? formatDateApi(statusDate) : ""}` : "Canceled";

                return <SecondStagedCell single={!showSecondCell}>
                    <div className="table-two-staged__cell-first">
                        Created by {account_name} at {create_date ? formatDateApi(create_date) : ""}
                    </div>
                    {showSecondCell && <div className="table-two-staged__cell-second">
                        {statusText}
                    </div>}
                </SecondStagedCell>;
            },
            flexGrow: 2,
            width: 150,
            align: "center"
        },
    ];


    if (withFilters) {
        columns.unshift({
            id: "account",
            label: "Account",
            value: ({account_name, account_manager_name}) => {
                return (
                    <SecondStagedCell same>
                        <div className="table-two-staged__cell-first">
                            {account_name}
                        </div>
                        {account_manager_name && <div className="table-two-staged__cell-second">
                            {account_manager_name || "no manager"}
                        </div>}
                    </SecondStagedCell>
                )
            },
            flexGrow: 1,
            minWidth: 260,
            align: "center"
        });
    }

    const hasProcessedData = data.find((item) => item.status === 0);

    const tableActions = (
        <Column key="table__actions" width={hasProcessedData ? 340 : 160} flexGrow={0}>
            <HeaderCell> </HeaderCell>
            <Cell>
                {rowData => {
                    if (rowData.status !== 0)
                        return null;
                    
                    return (
                        <S.StyledTableActions>

                            {handleManagePaymentDetails &&
                                <S.StyledTableButton
                                    onClick={() => handleManagePaymentDetails(rowData)}
                                >
                                    Manage payment details
                                </S.StyledTableButton>
                            }

                            <S.StyledTableButton
                                color="green"
                                onClick={() => handleApprovePaymentRequest(rowData)}
                                disabled={rowData.method === "Misc"}
                            >
                                Approve
                            </S.StyledTableButton>

                            <S.StyledTableButton
                                color="red"
                                onClick={() => handleCancelPaymentRequest(rowData)}
                            >
                                Reject
                            </S.StyledTableButton>
                            
                        </S.StyledTableActions>
                    );
                }}
            </Cell>
        </Column>
    );  

    return (
        <CommonTable
            height={height}
            data={data}
            rowHeight={36}
            loading={loading}
            columns={columns}
            tableActions={tableActions}
        />
    )
};

const SecondStagedCell = styled.div`
    margin-top: -7px;
    
    .table-two-staged__cell-first {
        ${props => props.same && css`
            font-size: 14px;
        `}
        ${props => props.single && css`
            font-size: 14px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
        `}
    }
    .table-two-staged__cell-second {
        margin-top: -4px;
        font-size: 12px;
        ${props => !props.same ? css`
            color: var(--color-brand1);
        ` : css`
            font-size: 14px;
        `}
        opacity: 1;
    }
    .table-two-staged__cell-text {
        white-space: wrap;
        line-height: 1.4;
        font-size: 13px;
        margin-top: 1px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
    }
`;
