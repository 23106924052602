import {connect} from "react-redux";
import Payments from "./Payments";
import {
    getPaymentList,
    getPaymentRequestList,
    getTrafficReportsList,
    requestPayment,
    rejectPaymentRequest,
    approvePaymentRequest,
    modifyPaymentSettings,
    getMiscPaymentRequest,
    exportMiscPayments,
    getHawalaPaymentRequestList,
    exportHawalaPayments,
} from "../../actions/payments";
import {
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    getPaymentDetailsCryptoList,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails
} from "../../actions/paymentDetails";
import {
    getReferencesForReports
} from "../../actions/references";
import {setService} from '../../actions/auth';
import {getAccount} from "../../actions";
import {getDropdownAccounts} from "../../actions/dropdown_accounts";
import {getAccountManagersDropdownList} from '../../actions/account_managers'


const mapStateToProps = ({auth, accounts, account_managers, payments, paymentDetails, references, dropdown_accounts}) => ({
    paymentItems: payments.paymentItems,
    paymentItemsLoading: payments.paymentItemsLoading,

    paymentRequestItems: payments.paymentRequestItems,
    paymentRequestLoading: payments.paymentRequestLoading,
    
    paymentTrafficReportsItems: payments.paymentTrafficReportsItems,
    paymentTrafficReportsLoading: payments.paymentTrafficReportsLoading,

    paymentHawalaItems: payments.hawalaItems,
    paymentHawalaLoading: payments.hawalaLoading,

    paymentMiscInited: payments.miscDataInited,
    paymentMiscItems: payments.miscItems,
    paymentMiscLoading: payments.miscLoading,
    
    paymentDetailsItems: paymentDetails.items,
    paymentDetailsLoadinig: paymentDetails.loading,
    
    service: auth.service,
    authInfo: auth.authInfo,
    permissions: auth.permissions,
    accountInfo: accounts.accountView,
    accounts: dropdown_accounts.items,
    accountJoinList: auth.accountJoinList,
    account_manager_list: account_managers.dropdown_list,
    globalSettings: references.global_setting,
    organizationTypeList: references.organization_type_list,
    cryptoList: paymentDetails.crypto_items,
    countryList: references.country_list,
    currencyList: references.currency_list,
    dialerList: auth.service ? references.dialer_list : references['sms.dialer_list'],
    globalSetting: references.global_setting_admin,
    paymentMethodList: references.payment_method_list,
    paymentTermsList: references.payment_terms_list,

    referencesLoading: references.reportLoading
});

export default connect(mapStateToProps, {
    getPaymentList,
    getPaymentRequestList,
    getTrafficReportsList,
    requestPayment,
    rejectPaymentRequest,
    approvePaymentRequest,
    modifyPaymentSettings,

    getHawalaPaymentRequestList,
    exportHawalaPayments,
    
    getMiscPaymentRequest,
    exportMiscPayments,

    getPaymentDetailsList,
    getPaymentDetailsSingle,
    getPaymentDetailsCryptoList,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails,

    getAccounts: getDropdownAccounts,
    getAccount,
    setService,
    getAccountManagersDropdownList,

    getReferencesForReports
})(Payments);