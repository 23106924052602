import React, {useContext, useMemo} from 'react'
import styled from 'styled-components';
import {Whisper, Tooltip, Icon, Divider} from "rsuite";
import {RtlContext} from "../../../App";
import {createExternalLink} from '../Navigation'


export default ({
    dialerInfo = {},
    accountJoinList = [],
    supportContacts = {},
    isMobile = false
}) => {

    const rtl = useContext(RtlContext);

    const data = useMemo(() => {
        const accountManagerInfo = dialerInfo?.account_manager || {};

        return {
            ...(accountManagerInfo && accountManagerInfo.hasOwnProperty("skype") && accountManagerInfo.skype ? {"Account Manager": accountManagerInfo.skype} : {}),
            ...supportContacts
        }
    }, [dialerInfo, supportContacts, isMobile]);

    return (
        <Wrapper>
            <Whisper
                enterable
                placement={rtl ? "bottomStart" : "bottomEnd"}
                trigger={isMobile ? "click" : "hover"}
                speaker={
                    <StyledTooltip>
                        {Object.keys(data).map(key => {
                            const supportName = data[key].hasOwnProperty("name") ? data[key].name : "";
                            const supportSkypeLogin = data[key].hasOwnProperty("skype") ? data[key].skype : "";
                            return <>
                                <StyledItem>{supportName}: {createExternalLink(supportSkypeLogin, "skype")}</StyledItem>
                            </>
                        })}
                    </StyledTooltip>
                }
            >
                <StyledIcon
                    size="lg"
                    icon="skype"
                />
            </Whisper>
        </Wrapper>
    )
}


const Wrapper = styled.div`
    display: inline-block;
    margin-right: 10px;
    margin-top: 12px;

    .rtl & {
        margin-right: 0;
        margin-left: 20px;
    }
`;


const StyledTooltip = styled(Tooltip)`
    && {
        display: flex;
    }
    && .rs-tooltip-inner {
        padding-bottom: 5px;
        padding-top: 5px;
    }
`;

const StyledIcon = styled(Icon)`
    padding: 5px 5px;
`;

const StyledItem = styled.div`
    text-align: left;

    .rtl & {
        text-align: right;
    }
`;

const StyledDivider = styled(Divider)`
    && {
        margin-bottom: 6px;
        margin-top: 6px;
    }    
`;