import {connect} from "react-redux";
import {
    setDefaultFormCreateAccount,
    onSetRedirectTab
} from "actions/accounts";
import {
    getRegistrationCount,
    getRegistrationList,
    approveRegistration,
    rejectRegistration
} from "actions/registration";
import RegistrationRequestsPage from "./RegistrationRequestsPage"

const mapState = ({auth, registration, references}) => ({
    registrationList: registration.registration_list,
    currencyList: references.currency_list,
    countryList: references.country_list,
    // accountList: accounts.items,
    service: auth.service,

    loading: registration.loading
});

export default connect(mapState, {
    getRegistrationCount,
    getRegistrationList,
    approveRegistration,
    rejectRegistration,
    setDefaultFormCreateAccount,
    onSetRedirectTab
})(RegistrationRequestsPage);