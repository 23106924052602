import React, {useMemo, useState} from 'react';
import {Alert, Button, Icon, FormGroup, Whisper, Tooltip} from 'rsuite';
import TableServerSort from '../../components/Table/TableServerSort';
import styled from 'styled-components';
import {useIntl} from "react-intl";
import {ButtonPrimary} from "../../components/base/BaseButton";
import ManualModifyAccessModal from './ManualModifyAccessModal';
import {CustomModal} from "../../components/base";

const resultStatusList = {
    already_working:  "Already working",
    traffic_started: "Traffic started",
    no_traffic:  "No traffic",
    canceled:  "Canceled",
};


export default ({
    data = [], 
    getItems, 
    manualAccessListLoading = false, 
    page = 1, 
    count = 0, 
    per_page,
    paymentTermsList = [],
    supplierList,
    service_plan_list,
    subdestinationList,
    numberTypeFamilyList,
    service,
    updateManualAccessList,
    onCreateManualAccessItem,
    onCancelManualAccessItem,
    onRemoveManualAccessItem,
    ...props
}) => {
    const {formatMessage} = useIntl();

    const [showModifyAccessModal, setShowModifyAccessModal] = useState(false);
    const [showCancelAccessModal, setShowCancelAccessModal] = useState(false);
    const [cancelKey, setCancelKey] = useState(null);
    const [createAccessModal, setCreateAccessModal] = useState(false);
    const [modifyAccessData, setModifyAccessData] = useState({});

    const columns = useMemo(() => {
        const columnList = service 
        ? [
            {
                label: "Origin", 
                dataKey: "type_family_name",
                value: ({type_family_name, a_number_list, a_subdestination_name}) => {
                    const otpService = type_family_name;
                    const numberList = a_number_list && a_number_list.length ? a_number_list.join(", ") : "";
                    const resultString = otpService ? `${otpService} ${numberList}` : a_subdestination_name || ""
                    return resultString;
                },
                flexGrow: 1,
                minWidth: 210
            },
            {
                label: "Destination", 
                dataKey: "b_subdestination_name",
                value: ({b_subdestination_name}) => {
                    return b_subdestination_name;
                },
                flexGrow: 1,
                minWidth: 210
            },
            {
                label: "Created",
                dataKey: "created_at",
                value: ({created_at, created_by}) => {
                    const date = new Date(created_at);
                    const options = {
                        // year: "numeric", month: "long", day: "numeric", 
                        // hour: "2-digit", minute: "2-digit", second:"2-digit",
                        dateStyle: "medium", timeStyle: "medium"
                    };
                    const dateString = date.toLocaleString("en-US", options);

                    const tooltipText = `Created by ${created_by} at ${dateString}`;

                    return <Whisper
                        enterable
                        placement="auto"
                        speaker={
                            <Tooltip>{tooltipText}</Tooltip>
                        }
                    >
                        <div className={"table-two-staged__cell"}>
                            <div className="table-two-staged__cell-first">Created by {created_by}</div>
                            <div className="table-two-staged__cell-second">{dateString}</div>
                        </div>
                    </Whisper>
                },
                flexGrow: 1,
                minWidth: 140
            },
            {
                label: "Result", 
                dataKey: "result",
                value: ({expired_at, finished_at, result}) => {
                    const options = {
                        // year: "numeric", month: "long", day: "numeric", 
                        // hour: "2-digit", minute: "2-digit", second:"2-digit",
                        dateStyle: "medium", timeStyle: "medium"
                    };
                    const expiredDate = new Date(expired_at);
                    const expiredDateString = expiredDate.toLocaleString("en-US", options);
                    const finishedDate = new Date(finished_at);
                    const finishedDateString = finishedDate.toLocaleString("en-US", options);

                    const date = finished_at ? finishedDateString : expiredDate ? expiredDateString : null;
                    const status = resultStatusList[result];

                    const resultStatus = result ? 
                        date ? <div className={"table-two-staged__cell"}>
                            <div className="table-two-staged__cell-first">{status}</div>
                            <div className="table-two-staged__cell-second">{date}</div>
                        </div>
                        : <span>{status}</span>
                    : <span>Active</span>;

                    const tooltipText = result ? date ? `${status} at ${date}` : status : "Active";

                    return result ? <Whisper
                        trigger="hover"
                        placement="auto"
                        speaker={
                            <Tooltip>{tooltipText}</Tooltip>
                        }
                    >
                        {resultStatus}
                    </Whisper> : resultStatus;
                },
                flexGrow: 2,
                minWidth: 210,
                
                
                // hideOnService: transformed_service_data["sms"]
            },
            {
                id: "modify",
                label: "Modify",
                value: (rowData) => {
                    return <div className="tableFilters_buttons">
                        <Button
                            // size="sm" 
                            color="lightblue" 
                            onClick={() => {
                                setShowModifyAccessModal(true);
                                setModifyAccessData(rowData);
                            }}
                        >
                            <Icon icon="edit2"/>
                        </Button>
                    </div>
                },
                // flexGrow: 1,
                align: "center",
                width: 60
            },
            {
                id: "cancel",
                label: "Cancel",
                value: ({alm_key, result}) => {
                    return  <div className="tableFilters_buttons">
                        <Button
                            // size="sm" 
                            color="red" 
                            disabled={result === "canceled"}
                            onClick={() => {
                                setShowCancelAccessModal(true);
                                setCancelKey(alm_key);
                            }}
                        >
                            <Icon icon="close"/>
                        </Button>
                    </div>
                },
                // flexGrow: 1,
                align: "center",
                width: 60
            }
        ] 
        : [
            {
                label: "Origin", 
                dataKey: "senderid",
                value: ({senderid, a_subdestination_name}) => {
                    const resultString = senderid ? senderid : a_subdestination_name || "";
                    return resultString;
                },
                flexGrow: 1,
                minWidth: 210
            },
            {
                label: "Destination", 
                dataKey: "b_subdestination_name",
                value: ({b_subdestination_name}) => {
                    return b_subdestination_name;
                },
                flexGrow: 1,
                minWidth: 210
            },
            {
                label: "Supplier", 
                dataKey: "supplier_name",
                flexGrow: 2,
                minWidth: 210
            },
            {
                label: "Created",
                dataKey: "created_at",
                value: ({created_at, created_by}) => {
                    const date = new Date(created_at);
                    const options = {
                        // year: "numeric", month: "long", day: "numeric", 
                        // hour: "2-digit", minute: "2-digit", second:"2-digit",
                        dateStyle: "medium", timeStyle: "medium"
                    };
                    const dateString = date.toLocaleString("en-US", options);
                    
                    const tooltipText = `Created by ${created_by} at ${dateString}`;

                    return <Whisper
                        enterable
                        placement="auto"
                        speaker={
                            <Tooltip>{tooltipText}</Tooltip>
                        }
                    >
                        <div className={"table-two-staged__cell"}>
                            <div className="table-two-staged__cell-first">Created by {created_by}</div>
                            <div className="table-two-staged__cell-second">{dateString}</div>
                        </div>
                    </Whisper>
                },
                flexGrow: 1,
                minWidth: 175
            },
            {
                label: "Result", 
                dataKey: "result",
                value: ({expired_at, finished_at, result}) => {
                    const options = {
                        // year: "numeric", month: "long", day: "numeric", 
                        // hour: "2-digit", minute: "2-digit", second:"2-digit",
                        dateStyle: "medium", timeStyle: "medium"
                    };
                    const expiredDate = new Date(expired_at);
                    const expiredDateString = expiredDate.toLocaleString("en-US", options);
                    const finishedDate = new Date(finished_at);
                    const finishedDateString = finishedDate.toLocaleString("en-US", options);

                    const date = finished_at ? finishedDateString : expiredDate ? expiredDateString : null;
                    const status = resultStatusList[result];

                    const resultStatus = result ? 
                        date ? <div className={"table-two-staged__cell"}>
                            <div className="table-two-staged__cell-first">{status}</div>
                            <div className="table-two-staged__cell-second">{date}</div>
                        </div>
                        : <span>{status}</span>
                    : <span>Active</span>;

                    const tooltipText = result ? date ? `${status} at ${date}` : status : "Active";

                    return result ? <Whisper
                        trigger="hover"
                        placement="auto"
                        speaker={
                            <Tooltip>{tooltipText}</Tooltip>
                        }
                    >
                        {resultStatus}
                    </Whisper> : resultStatus;
                },
                flexGrow: 1,
                minWidth: 180,
                
                
                // hideOnService: transformed_service_data["sms"]
            },
            {
                id: "modify",
                label: "Modify",
                value: (rowData) => {
                    return <div className="tableFilters_buttons">
                        <Button
                            // size="sm" 
                            color="lightblue" 
                            onClick={() => {
                                setShowModifyAccessModal(true);
                                setModifyAccessData(rowData);
                            }}
                        >
                            <Icon icon="edit2"/>
                        </Button>
                    </div>
                },
                // flexGrow: 1,
                align: "center",
                width: 60
            },
            {
                id: "cancel",
                label: "Cancel",
                value: ({alm_key, result}) => {
                    return  <div className="tableFilters_buttons">
                        <Button
                            // size="sm" 
                            color="red" 
                            disabled={result === "canceled"}
                            onClick={() => {
                                setShowCancelAccessModal(true);
                                setCancelKey(alm_key);
                            }}
                        >
                            <Icon icon="close"/>
                        </Button>
                    </div>
                },
                // flexGrow: 1,
                align: "center",
                width: 60
            }
        ];
        return columnList;
    }, [service]);

    const onCreateManualAccess = (data) => {
        const createData = service ? {
            a_sde_key: data?.a_sde_key || null,
            a_number_list: data?.a_number_list || null,
            b_sde_key: data.b_sde_key,
            antf_key: data.antf_key
        } : {
            ...(data.senderid ? {senderid: data.senderid} : {}),
            ...(data.a_sde_key ? {a_sde_key: data.a_sde_key} : {}),
            b_sde_key: data.b_sde_key,
        };

        const targetData = {
            ...(data.supplier_id ? {"sms.supplier_id": data.supplier_id} : {})
        };

        onCreateManualAccessItem(service, createData, targetData).then(() => {
            updateManualAccessList();
        });
    };

    const onModifyManualAccess = (data) => {
        let successCounter = 0;
        const currentManualAccessKey = data.alm_key;

        const createData = service ? {
            a_sde_key: data?.a_sde_key || null,
            a_number_list: data?.a_number_list || null,
            b_sde_key: data.b_sde_key,
            antf_key: data.antf_key
        } : {
            ...(data.senderid ? {senderid: data.senderid} : {}),
            ...(data.a_sde_key ? {a_sde_key: data.a_sde_key} : {}),
            b_sde_key: data.b_sde_key
        };

        const targetData = {
            ...(data.supplier_id ? {"sms.supplier_id": data.supplier_id} : {})
        };


        if (currentManualAccessKey) {
            onRemoveManualAccessItem(service, currentManualAccessKey)
                .then((response) => {
                    successCounter += 1;

                    if (response) {
                        onCreateManualAccessItem(service, createData, targetData).then(() => {
                            successCounter += 1;
             
                            updateManualAccessList();
                        });
                    }
                })
                .catch(() => {
                    Alert.error("Something wrong");
                })
                .finally(() => {
                    updateManualAccessList();
                })

        }

    };
    
    const onCancelManualAccess = (key) => {
        onCancelManualAccessItem(service, key).then(() => {
            updateManualAccessList();
        });
    };

    return (<>
        <FormGroupRight>
            <ButtonPrimary
                style={{
                    padding: '8px 34px',
                    marginLeft: 12
                }}
                onClick={() => {
                    setShowModifyAccessModal(true);
                    setCreateAccessModal(true);
                }}
                disabled={manualAccessListLoading}
            >
                Create
            </ButtonPrimary>
        </FormGroupRight>
        <StyledTable
            data={data}
            rowHeight={48}
            loading={manualAccessListLoading}
            columns={columns}
            originalColumns={columns}
            count={count}
            per_page={per_page}
            page={page}
            getItems={getItems}
            {...props}
        />
        {showModifyAccessModal && <ManualModifyAccessModal
            show={showModifyAccessModal}
            service={service}
            createAccessModal={createAccessModal}
            onCreateAccess={onCreateManualAccess}
            onModifyAccess={onModifyManualAccess}
            onCloseModal={() => {
                setShowModifyAccessModal(false);
                setCreateAccessModal(false);
                setModifyAccessData({});
                updateManualAccessList();
            }}
            loading={manualAccessListLoading}
            modifyAccessData={modifyAccessData}
            numberTypeFamilyList={numberTypeFamilyList}
            subdestinationList={subdestinationList}
            supplierList={supplierList}
        />}

        {showCancelAccessModal && <CustomModal
            show={showCancelAccessModal}
            onConfirm={() => {
                onCancelManualAccess(cancelKey);
                setShowCancelAccessModal(false);
                setCancelKey(null);
            }}
            title="Cancel manual access"
            loading={manualAccessListLoading}
            showFooter={true}
            width={360}
            confirmButtonText="Yes"
            onClose={() => {
                setShowCancelAccessModal(false);
                setCancelKey(null);
            }}
        >
            Are you sure?
        </CustomModal>}
    </>)
};

const FormGroupRight = styled(FormGroup)`
    && {
        /* float: right; */
        position: relative;
        /* height: 120px; */
        margin-right: 0;
    }

    && button {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 160px;
    }
`;

const StyledTooltipIcon = styled(Icon)`
    margin-left: 5px;

    .rtl & {
        margin-left: 0;
        margin-right: 5px;
    }
`;

const SecondStagedCell = styled.div`
    margin-top: -7px;
    
    .table-two-staged__cell-second {
        margin-top: -4px;
        font-size: 12px;
        color: var(--color-brand1);
        opacity: 1;
    }
`;

const StyledTable = styled(TableServerSort)`
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons {
        float: right;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons > .rs-btn {
        width: 28px;
        height: 28px;
        padding: 4px 8px;
    }
    
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons > .rs-btn {
        margin-right: 5px;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons .rs-btn-lightblue {
        color: var(--color-light);
        background: var(--color-info);
    }
`;