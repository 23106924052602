import {connect} from "react-redux";
import NewsManagement from "./NewsManagement";
import {getAccountManagersDropdownList} from "../../actions/account_managers";
import {getDropdownAccounts} from "../../actions/dropdown_accounts";
import {getDialerGroupsList,} from "../../actions/dialer_groups";

import {getNews} from "../../actions/news_management";


const mapStateToProps = ({
    account_managers, 
    references, 
    dialer_groups,
    dropdown_accounts, 
    news_management
}) => ({
    accountManagerList: account_managers.dropdown_list,
    paymentTermsList: references.payment_terms_list,
    dialerGroupsList: dialer_groups.list,
    currencyList: references.currency_list,
    servicePlanList: references.service_plan_list,
    clientRoleList: references.client_role_list,
    accounts: dropdown_accounts.items,
    data: news_management.list,
    count: news_management.count,
    inited: news_management.inited,
    loading: news_management.loading,
});

export default connect(mapStateToProps, {
    getAccountManagersDropdownList,
    getDropdownAccounts,
    getDialerGroupsList,
    getNews,
})(NewsManagement);