export const formValid = data =>  !Object.values( data ).filter( x => x.hasError).length;

export const fullPhoneRegex = /^(\+)?(\(\d{2,3}\) ?\d|\d)(([ \-]?\d)|( ?\(\d{2,3}\) ?)){5,12}\d$/;

// export const validatePhoneNumbersRange = (row) => {
//     if (!row.includes('-')) {
//         return false;
//     }

//     const range = row.split('-');

//     if (range.length <= 2) {
//         const rangeHasError = range.find(value => value.length > MAX_CHARACTERS || value.length < MIN_CHARACTERS);
//         if (rangeHasError) {
//             return false
//         }
//     } else {
//         return false
//     }
// }




/* Hetmanenko's hacks

export const requiredOfValidator = function (arrayOfFieldsNames, errorMessage = 'One of these is required') {
    return {
        check: (value, data) => {
            const fieldsNames = Object.keys(data);

            if (value) {
                return {
                    hasError: false
                }
            }

            for (let name of fieldsNames) {
                if (arrayOfFieldsNames.includes(name) && data[name]) {
                    return {
                        hasError: false
                    }
                }
            }

            return {
                hasError: true,
                errorMessage: errorMessage
            }
        }
    }
};

export function generateRequiredOfValidator(fieldNamesArray) {
    const schema = {};
    fieldNamesArray.forEach((field, i) => {
        let fieldNamesArrayClone = fieldNamesArray.slice();

        fieldNamesArrayClone.splice(i, 1);

        schema[field] = requiredOfValidator(fieldNamesArrayClone);
    });
    return schema;
}
*/