import React, { useState, useEffect } from 'react';
import PageHeader from "../../components/base/PageHeader";
import { FlexboxGrid } from "rsuite";
import ExportCsv from "../../components/ExportCsv";
import PanelLayout from "../../components/base/PanelLayout";
import CDRTable from "./CDRTable";
import CDRFilters from "pages/CDR/CDRFilters";
import { connect } from 'react-redux';
import { CDR_LIST_METHOD, CDR_LIST_SMS_METHOD, MDR_FAILED_LIST_METHOD} from "const/apiMethods";
import { servicePick } from 'utils';
import { CDR_ADMIN_PATHNAME } from '../../const/';
import {toEndDayTime, toStartDayTime, removeTZFromDate} from "utils";


const CDRPage = (
    {
        service,
        globalSettings,
        ...props
    }
) => {
    const currentPathName = props.location.pathname;
    const isFailedMdr = !currentPathName.split("-").find((path) => path === CDR_ADMIN_PATHNAME);

    const today = new Date();
    const defaultFilters = { range: [toStartDayTime(today), toEndDayTime(today)], ...(!service ? {only_my_account: true} : {})};

    const [filters, setFilters] = useState(defaultFilters);
    const [preFilters, setPreFilters] = useState(defaultFilters);
    
    useEffect(() => {
        setFilters(defaultFilters);
    }, [isFailedMdr])
    
    const methodExport = isFailedMdr ? MDR_FAILED_LIST_METHOD : servicePick(service, CDR_LIST_METHOD, CDR_LIST_SMS_METHOD);

    const headerTitle = isFailedMdr ? "Failed MDR" : servicePick(service, "CDR", "MDR");
    const exportFileName = isFailedMdr ? "failed_mdr.csv" : servicePick(service, "cdr.csv", "mdr.csv");

    const exportFilter = {...preFilters};

    if (exportFilter.range && exportFilter.range.length) {
        exportFilter['start_date'] = removeTZFromDate(toStartDayTime(exportFilter.range[0]));
        exportFilter['end_date'] = removeTZFromDate(toEndDayTime(exportFilter.range[1]));

        delete exportFilter.range;
    }


    return (
        <PanelLayout>
            <PageHeader title={headerTitle}>
                <ExportCsv
                    method={methodExport}
                    params={{ filter: preFilters }}
                    title='Export CSV'
                    fileName={exportFileName}
                    style={{ position: 'relative', right: 15 }}
                />
            </PageHeader>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24}>
                    <CDRFilters
                        onApplyFilters={(filters) => {
                            setFilters(filters)
                        }}
                        preFilters={preFilters}
                        setPreFilters={setPreFilters}
                        defaultFilters={defaultFilters}
                        isFailedMdr={isFailedMdr}
                        globalSettings={globalSettings}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <CDRTable
                filters={filters}
                isFailedMdr={isFailedMdr}
            />
        </PanelLayout>
    );
};

const mapState = ({ auth, references }) => ({
    service: auth.service,
    globalSettings: references.global_setting,
});

export default connect(mapState)(CDRPage);