import React, {useEffect} from 'react';
import {Route, useRouteMatch} from "react-router-dom";
import Layout from "../Layout";
import {APP_TYPE_CLIENT, APP_TYPE_DEFAULT} from "../../const";

const RouteWithLayout = ({layout = Layout, component: Component, lang, rtl, ...rest}) => {

    if (Component) {
        return (
            <Route
                {...rest}
                render={(props) => React.createElement(layout, props, React.createElement(Component, props))}
            />
        );
    }

    return (
        <Layout>
            <Route {...rest}>
                {rest.children}
            </Route>
        </Layout>
    );
};

export default RouteWithLayout;