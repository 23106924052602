import {
    SET_ROLES
} from '../const/';
import { getRoles } from '../utils';


const setRoles = (state, role_list) => {
    let account_user_modify_method = '';
    let account_user_remove_method = '';

    // if(role_list.includes('AM_TL_ADMIN')){
        account_user_modify_method = 'account_user:modify_by_account_manager';
        account_user_remove_method = 'account_user:remove_by_account_manager';
    // }
    
    return  {...state, role_list, account_user_modify_method, account_user_remove_method}
}

const initialState = {
    role_list: [],
    account_user_modify_method: '',
    account_user_remove_method: '',
    ...setRoles({}, getRoles())
};


const handlers = {    
    [SET_ROLES]: (state,{payload: role_list}) => setRoles(state, role_list || []),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};
