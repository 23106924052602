import React, {useMemo, useEffect, useState} from "react";

import {Panel} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import PageHeader from "../../components/base/PageHeader";
import DialerGroupsList from "./DialerGroupsList";
import DialerGroupsAccountsList from "./DialerGroupsAccountsList";
import DialerGroupsAccountsFilter from "./DialerGroupsAccountsFilter";

import styled from "styled-components";
import { useDebounce } from "hooks";


export default (
    {
        loading,
        data = [],
        accountLoading,
        accountsData = [],
        otherAccountsData = [],

        createDialerGroup,
        modifyDialerGroup,
        removeDialerGroup,
        getDialerGroupsList,
        addAccountToGroup,
        removeAccountFromGroup,
        getDialerGroupsAccountsList,
        ...props
    }
) => {

    const [accountsFilter, setAccoutsFilter] = useState({str: ""});
    const [activeGroupKey, setActiveGroupKey] = useState(null);
    const [filterClearedFlag, setFilterClearedFlag] = useState(false);

    const groupsAreExists = useMemo(() => data.length, [data]);

    useEffect(() => {
        getDialerGroupsList();
    }, []);

    useEffect(() => {
        if (activeGroupKey) {
            getDialerGroupsAccountsList(activeGroupKey, {});
        }
    }, [activeGroupKey]);

    useEffect(() => {
        if (!data.length) {
            setActiveGroupKey(null);
            return;
        }

        if (!data.find(item => item.nwg_key === activeGroupKey) ) {
            setActiveGroupKey(data[0].nwg_key);
        }
    }, [data]);


    const handleSelectGroup = (key) => {
        if (!key) {
            setActiveGroupKey(null);
            return;
        }

        setActiveGroupKey(key);
    };

    const onChangeAccountsFilter = (filter) => {
        setAccoutsFilter(filter);
        setFilterClearedFlag(false);
    };

    const deferredFilter = useDebounce(accountsFilter, 700);

    useEffect(() => {
        if (activeGroupKey && !filterClearedFlag) {
            getDialerGroupsAccountsList(activeGroupKey, deferredFilter);
        }
    }, [deferredFilter]);

    const clearAccountsFilter = () => {
        setAccoutsFilter({str: ""});
        setFilterClearedFlag(true);
    };

    const updateAccounts = () => {
        if (activeGroupKey)
            getDialerGroupsAccountsList(activeGroupKey, accountsFilter);
    };

    return (
        <Panel style={{marginTop: 10, marginBottom: 20}} classPrefix="white-panel">
            <PageHeader title="News dialer groups"/>
            <StyledContentWrapper>

                <FlexGrid hSpace={'-20px'} align={'stretch'} noWrap>
                    <FlexGridItem width="300px" hSpace={'20px'}>
                        <DialerGroupsList
                            data={data}
                            loading={loading}
                            activeGroupKey={activeGroupKey}
                            groupsAreExists={groupsAreExists}

                            handleSelectGroup={handleSelectGroup}

                            getDialerGroupsList={getDialerGroupsList}

                            clearAccountsFilter={clearAccountsFilter}
                            setActiveGroupKey={setActiveGroupKey}
                            createDialerGroup={createDialerGroup}
                            modifyDialerGroup={modifyDialerGroup}
                            removeDialerGroup={removeDialerGroup}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <DialerGroupsAccountsFilter
                            filter={accountsFilter}
                            onChangeFilter={onChangeAccountsFilter}
                        />
                        <DialerGroupsAccountsList
                            data={accountsData}
                            dataOther={otherAccountsData}

                            groupsAreExists={groupsAreExists}

                            activeGroupKey={activeGroupKey}

                            loading={accountLoading}

                            updateItems={updateAccounts}
                            addAccountToGroup={addAccountToGroup}
                            removeAccountFromGroup={removeAccountFromGroup}
                        />
                         
                    </FlexGridItem>
                </FlexGrid>
            </StyledContentWrapper>
        </Panel>
    )
};


const StyledContentWrapper = styled.div`
`;