import React, {useState} from 'react'
import Modal from '../../../components/Modal';
import {api} from '../../../api/loginRoutes';

export default ({onClose, service, update, account_id, trunk_id}) => {
    
    const [disabled, setDisabled ] = React.useState(false)

    const onSubmit = async () => {
        setDisabled(true)
        const method = service ? 'trunk:revoke_all_numbers' : 'sms.trunk:revoke_all_numbers';

        const result = await api(method, {
            target:{
                account_id,
                ...(service ? {trunk_id} : {'sms.trunk_id': trunk_id})
            }
        })
        if(result) {
            onClose();
            update()
        }
        setDisabled(false)
    }

    return (

        <Modal 
            show 
            onClose={onClose}
            footer
            successText = "Yes"
            onSuccess = {onSubmit}
            disabled = {disabled}
        >
            All numbers from this trunk will be revoked. Do you really want to do it?
        </Modal>
    )
};

