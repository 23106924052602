import React, {useEffect, useState} from 'react';
import SearchTable from './SearchTable';
import PageHeader from '../../components/base/PageHeader';
import PanelLayout from '../../components/base/PanelLayout';
import SearchFilter from './SearchFilter';

export default ({
    data,
    loading,
    getSearch
}) => {

    const defaultFilters = {};
    const [filters, setFilters] = useState(defaultFilters);

    useEffect(() => {
        getSearch(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);


    return (
        <PanelLayout>
            <PageHeader title="OTP Search"></PageHeader>
            {/*<HeaderMessage type="info" description="Parsing rules"/>*/}
            <SearchFilter
                onChange={setFilters}
                defaultFilters={defaultFilters}
            />
            <SearchTable
                data={data}
                loading={loading}
            />
        </PanelLayout>
    );
};