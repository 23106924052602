import React from "react";
import Form from "rsuite/es/Form";
import Text from "../../../../../components/Form/Text";
import FormHOC from "../../../../../hoc/FilltersForm";
import {FormControl, Schema, SelectPicker} from "rsuite";
import {debounce} from "../../../../../utils";
import CustomField from "../../../../../components/Form/CustomField";

const { StringType } = Schema.Types;

const filtersModel = Schema.Model({
    number: StringType().maxLength(40, "The maximum is only 40 characters."),
});


const AccessListFiltersFilter = ({onChange, defaultFilters, service, ...props}) => {
    const handleChange = (formValues) => {
        onChange(formValues)
    };

    const strPlaceholder = service ? "Origin/origin prefix/destination" : "Sender ID/destination"; 

    return (
        <>
            <Form
                model={filtersModel}
                layout="inline"
                onChange={debounce((value) => {
                    handleChange(value);
                }, 400)}
                formDefaultValue={defaultFilters}
            >
                {service ? <CustomField
                    style={{width: 160}}
                    name="antf_key"
                    className="tableFilters_field"
                    errorPlacement="topEnd"
                    accepter={SelectPicker}
                    data={props.numberTypeFamilyList}
                    placeholder="Service name"
                    valueKey="antf_key"
                    labelKey="family_name"
                    type="text"
                /> : <></>}
                <Text name="str" placeholder={strPlaceholder} style={{display: "inline-block"}}/>
            </Form>
        </>
    );
};
export default FormHOC (AccessListFiltersFilter, filtersModel,300);