import {IVR_CLI as PREFIX} from '../const/';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';
import {IVR_CLI_LIST_API} from "../const/apiMethods";

const setIvrCliList = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);

export const getIvrCliList = (filter = {}) => (dispatch) => {
    dispatch(setLoading(true));
    api(IVR_CLI_LIST_API, {filter})
        .then(({ivr_cli_list}) => {
            if (ivr_cli_list === undefined) {
                return;
            }
            dispatch(setIvrCliList({
                list: ivr_cli_list.map(item => ({
                    ...item,
                    ivr_id: item.ivr ? item.ivr.id : '',
                    ivr_name: item.ivr ? item.ivr.name : '',
                }))
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};