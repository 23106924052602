import React, {useState} from 'react';
import apiNavigation from './apiNavigation.json';
import {Tree, List, Icon} from 'rsuite';
import {NavHashLink as NavLink} from 'react-router-hash-link';
import styled, {css} from 'styled-components';

export default ({keyList, loading, onSelect: handleSelect, ...props}) => {

    const [apiTurn, setApiTurn] = useState('docs');
    
    return (
        <Tree
            className="api_menu"
            defaultExpandAll={true}
            defaultValue={'docs'}
            data={apiNavigation}
            style={{
                maxWidth: '320px',
                maxHeight: '600px',
                height: '600px',
                overflowX: 'hidden'
            }}
            renderTreeNode={(node) => {
                const activeKeyList = keyList.length && keyList.filter(key => key.active);
                const keysHaveLoading = (loading || !activeKeyList.length) && node.value === "keys";

                return (
                    !keysHaveLoading ?
                        <span
                            className="api_menu_item"
                            onClick={() => {
                                // console.log("node.value", node.value);
                                setApiTurn(node.value);
                                handleSelect(node.value);
                            }}
                        >
                            {node.label}

                            {node.anchors && apiTurn === node.value &&
                                <List className="api_menu_item-anchor">
                                    {node.anchors.map(anchor => {
                                        const hasChildren = anchor?.children && anchor.children.length;
                                        return (
                                            <List.Item className="api_menu_item_anchor-item">
                                                {anchor.children ? <>
                                                    <AnchorHeader className={`api_menu_item_anchor_item-link ${!hasChildren}`} to={anchor.to}>
                                                        {anchor.icon && 
                                                            <StyledItemIcon icon={anchor.icon}/>
                                                        }
                                                        <StyledItemLabel>{anchor.label}</StyledItemLabel>
                                                    </AnchorHeader>
                                                    <ListDrop className="api_menu_item_anchor_item-drop" label={anchor.label}>
                                                        {anchor.children.map(item => {
                                                            return <List.Item className="api_menu_item_anchor_item_drop-item">
                                                                <NavLink className="api_menu_item_anchor_item-link" to={item.to}>
                                                                    {item.icon && 
                                                                        <StyledIcon icon={item.icon}/>
                                                                    }
                                                                    {item.label}
                                                                </NavLink>
                                                            </List.Item>
                                                        })}
                                                    </ListDrop>
                                                </> : <NavLink className={`api_menu_item_anchor_item-link ${!hasChildren ? 'api_menu_item_anchor_item-nodrop' : ''}`} to={anchor.to} >
                                                    {anchor.icon && 
                                                        <StyledIcon icon={anchor.icon}/>
                                                    }
                                                    {anchor.label}
                                                </NavLink>}
                                            </List.Item>
                                        )
                                    })}
                                </List>
                            }
                        </span>

                    : <></>
                )
            }}
        />
    )
}

const AnchorHeader = styled(NavLink)`
    && {
        display: flex;
        align-items: center;

        padding-bottom: 5px;
        font-size: inherit;
    }
`;

const StyledItemLabel = styled.p`
    && {
        color: var(--color-info);
    }
`;

const ListDrop = styled(List)`
    && > .rs-list-item .rs-list-item-content .api_menu_item_anchor_item-link {
        ${props => props.label === "Voice" ? css`
            color: var(--color-violet) !important;
        ` : css`
            color: var(--color-info) !important;
        `}
    }
    && {
        ${props => props.label === "Voice" ? css`
            border-left: 2px solid var(--color-violet);
            ` : css`
            border-left: 2px solid var(--color-info);
        `}
    }
`;

const StyledIcon = styled(Icon)`
    margin-right: 5px;

    width: 14px;
    height: 14px;

    .rtl & {
        margin-left: 5px;
        margin-right: 0;
    }
`;

const StyledItemIcon = styled(Icon)`
    margin-right: 5px;
    color: var(--color-info);

    width: 14px;
    height: 14px;

    .rtl & {
        margin-left: 5px;
        margin-right: 0;
    }
`;