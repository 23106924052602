import React, {useState, useEffect} from "react";
import PageHeader from "../../components/base/PageHeader";
import {FormattedMessage} from "react-intl";
import PaymentDetailsTable from "./PaymentDetailsTable";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import styled from "styled-components";
import {Icon} from "rsuite";
import {Spacer} from "../../components/base/Spacer";
import ModalAddPayment from "./ModalAddPaymentDetail";
import {LOCAL_STORAGE_USER_INFO_DIALER} from "../../const";
import ModalRemovePaymentDetail from "./ModalRemovePaymentDetail";
import flaser from "object-flaser/lib/flaser";
import {ButtonPrimary} from "../../components/base/BaseButton";
import Page from "../../components/base/Page";
import {PAYMENT_DETAIL_LIST_API, PAYMENT_DETAIL_CRYPTO_LIST_API} from "const/apiMethods";
import {checkPermissionsFor} from "store/storeHelpers";
import { getAccountInfoFromSession } from "utils";


const PaymentDetails = ({
    data, 
    service,
    loading,
    cryptoList,
    dialerInfo,
    accountJoinList,
    organizationTypeList,
    countryList,
    currencyList,
    paymentMethodList, 
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    getPaymentDetailsCryptoList,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails,
    permissions,
}) => {
    const [showModalAddPaymentDetail, setShowModalAddPaymentDetail] = useState(false);
    const [showModalRemovePaymentDetail, setShowModalRemovePaymentDetail] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModify, setIsModify] = useState(false);
    const [addPaymentDefaultValue, setAddPaymentDefaultValue] = useState(null);
    const [loadingEditElementsSet, setLoadingEditElementsSet] = useState(new Set());
    const [pickedCurrencyKey, setPickedCurrencyKey] = useState(null);

    let storagedAccountInfo = localStorage.getItem(LOCAL_STORAGE_USER_INFO_DIALER);
    storagedAccountInfo = storagedAccountInfo !== null ? JSON.parse(storagedAccountInfo) : {session: false};
    // const accountSession = storagedAccountInfo && storagedAccountInfo.session ? storagedAccountInfo.session : null;
    // const accountInfo = getAccountInfoFromSession(accountSession, service);
    const accountId = dialerInfo?.id;

    useEffect(() => {
        let pickedCurKey = null;
        // TODO NEED ATTENTION
        const curKeyAllEqual = accountJoinList.every(item => item?.cur_key === accountJoinList[0]?.cur_key);

        if (accountJoinList.length === 2 && curKeyAllEqual) {
            pickedCurKey = accountJoinList[0]?.cur_key;
        } else if (accountJoinList.length === 1) {
            pickedCurKey = accountJoinList[0]?.cur_key;
        }

        setPickedCurrencyKey(pickedCurKey);
    }, [accountJoinList]);

    useEffect(() => {
        if (checkPermissionsFor(PAYMENT_DETAIL_LIST_API)) {
            getPaymentDetailsList();
        }

        if (checkPermissionsFor(PAYMENT_DETAIL_CRYPTO_LIST_API)) {
            getPaymentDetailsCryptoList();
        }
    }, [permissions]);

    const onShowModalAddPaymentDetail = () => {
        setShowModalAddPaymentDetail(true);
    };

    const closeModalAddPaymentDetail = () => {
        setIsModify(false);
        setAddPaymentDefaultValue(null);
        setShowModalAddPaymentDetail(false);
    };

    const handleEditPaymentDetails = async (value) => {
        const {id} = value;

        // set elements loading status
        const editElements = new Set(loadingEditElementsSet);
        setLoadingEditElementsSet( editElements.add(id) );

        const response = await getPaymentDetailsSingle({target: {payment_detail_id: id}});

        // remove elements loading status
        if (editElements.has(id)) {
            editElements.delete(id);
            setLoadingEditElementsSet(editElements);
        }

        if (!response && response.payment_detail) {
            return;
        }

        const fetchedData = response.payment_detail;

        let files = null;
        if (fetchedData.ext_files) {
            files = [];
            for (const file of fetchedData.ext_files) {
                files.push({
                    url: `data:${file.type};base64,${file.file}`,
                    fileKey: file.file_id,
                    name: file.name,
                });
            }
        }

        const defaultValue = {
            ...fetchedData,
            cur_key: value?.cur_key,
            ext_files: files
        };

        setIsModify(true);
        setAddPaymentDefaultValue( flaser(defaultValue) );
        setShowModalAddPaymentDetail(true);
    };

    const handleRemovePaymentDetails = (value) => {
        setSelectedItem(value);
        setShowModalRemovePaymentDetail(true);
    };

    return (
        <Page>
            <PageHeader title={<FormattedMessage id="common.paymentDetails" defaultMessage="Payment details" />} showService={false}/>

            <FlexGrid>
                <FlexGridItem grow={1}>
                    <StyledActionButtonWrapper>
                        <ButtonPrimary
                            onClick={onShowModalAddPaymentDetail}
                            disabled={loading}
                        >
                            + <FormattedMessage id="paymentDetails.addNew" defaultMessage="Add new"/>
                        </ButtonPrimary>
                    </StyledActionButtonWrapper>
                </FlexGridItem>
            </FlexGrid>

            <Spacer/>

            <PaymentDetailsTable
                data={data}
                loading={loading}
                loadingEditButtonsSet={loadingEditElementsSet}
                {...{
                    handleRemovePaymentDetails,
                    handleEditPaymentDetails
                }}
            />

            {showModalAddPaymentDetail && <ModalAddPayment
                show={showModalAddPaymentDetail}
                onClose={closeModalAddPaymentDetail}
                defaultFormValue={isModify ? addPaymentDefaultValue : null }
                modify={isModify}
                {...{
                    accountId,
                    organizationTypeList,
                    cryptoList,
                    countryList,
                    currencyList,
                    accountJoinList,
                    pickedCurrencyKey,
                    paymentMethodList,
                    createPaymentDetails,
                    modifyPaymentDetails,
                    setPickedCurrencyKey,
                    getPaymentDetailsList
                }}
            />}

            <ModalRemovePaymentDetail
                show={showModalRemovePaymentDetail}
                onClose={() => {
                    setShowModalRemovePaymentDetail(false)
                }}
                {...{
                    accountId,
                    selectedItem,
                    getPaymentDetailsList,
                    removePaymentDetails
                }}
            />
        </Page>
    )
};

export default PaymentDetails;

const StyledActionButtonWrapper = styled.div`
    text-align: center;
    
    @media (min-width: 768px) {
        text-align: right;
    }
`;

const StyledActionButtonIcon = styled(Icon)`
    margin-right: 5px;
`;