import React, {useState, useEffect} from "react";
import FormAddFilter from "./FormAddFilter/FormAddFilter";
import AccessListFiltersSettings from "./AccessListFiltersSettings/AccessListFiltersSettings";
import ModalRemoveFilter from "./ModalRemoveFilter/ModalRemoveFilter";
import AccessListFiltersTable from "./AccessListFiltersTable/AccessListFiltersTable";
import AccessListFiltersFilter from "./AccessListFiltersFilter/AccessListFiltersFilter";
import "./AccessListFilters.css";
import {unsetAccessListNotificationSettings} from "../../../../actions";

const AccessListFilters = ({
    items,
    service,
    accountId,
    itemsLoading,
    worldzoneList,
    destinationList,
    subdestinationList,
    notificationSettings,
    numberTypeFamilyList,

    addItem,
    getItems,
    modifyItem,
    getAuthNumberType,
    getNotificationSettings,
    unsetNotificationSettings,
    modifyNotificationSettings
}) => {
    const [data, setData] = useState([]);
    const [currentItemData, setCurrentItemData] = useState({});
    const [filter, setFilter] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [editedItemId, setEditedItemId] = useState(null);
    const [showModalItemRemove, setShowModalItemRemove] = useState(false);
    const [showModalItemEdit, setShowModalItemEdit] = useState(false);

    useEffect(() => {
        getItems(accountId);
        getNotificationSettings(accountId);

        if (service) {
            getAuthNumberType();
        }
    }, [service, accountId]);

    useEffect(() => {
        return () => {
            unsetNotificationSettings();
        };
    }, []);

    const getItemsOnUpdate = () => {
        getItems(accountId);
    };

    useEffect(() => {
        setData(items);
    }, [items])

    const handleEditFilter = async (accountId, alf_key, data) => {
        return await modifyItem(accountId, alf_key, data);
    };

    const handleRemoveFilter = (rowData) => {
        setCurrentItemData({...rowData});
        setShowModalItemRemove(true);
    };


    const onChangeFilter = (filter) => {
        setFilter(filter);
        setIsLoading(false);
    };


    return (
        <div>
            <AccessListFiltersSettings
                accountId={accountId}
                handleSubmit={modifyNotificationSettings}
                settings={notificationSettings}/>

            <AccessListFiltersFilter
                onChange={onChangeFilter}
                defaultFilters={filter}
                service={service}
                {...{numberTypeFamilyList}}
            />

            <AccessListFiltersTable
                filter={filter}
                accountId={accountId}
                data={data}
                loading={itemsLoading || isLoading}
                height={"30%"}
                onRemoveItem={handleRemoveFilter}
                onEditItem={handleEditFilter}
                // onItemShowEdit={handleFilterShowEdit}
                update={getItemsOnUpdate}
                service={service}
                {...{worldzoneList, destinationList, subdestinationList, numberTypeFamilyList}}
            />

            <FormAddFilter
                addItem={addItem}
                accountId={accountId}
                update={getItemsOnUpdate}
                service={service}
                {...{worldzoneList, destinationList, subdestinationList, numberTypeFamilyList}}
            />

            {showModalItemRemove &&
            <ModalRemoveFilter
                account_id={accountId}
                data={currentItemData}
                handleClose={() => {setShowModalItemRemove(false)}}
                update={getItemsOnUpdate}/>
            }
        </div>
    );
};

export default AccessListFilters;