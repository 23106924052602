import React, {useMemo} from "react";
import {Tooltip, Whisper} from "rsuite";
import TableCommonNew from "../../../components/Table/TableCommonNew";
import {servicePick, copyArrayWithoutReference} from "../../../utils";


export default React.forwardRef(({service, ...props}, ref) => {

    const columns = useMemo(() => [
        {
            id: 'number',
            dataKey: 'number',
            label: 'Number',
            flexGrow: 1,
            minWidth: 200,
        },
        {
            id: 'destination_name',
            dataKey: 'destination_name',
            label: 'Destination',
            flexGrow: 2,
        },
        {
            id: 'subdestination_name',
            dataKey: 'subdestination_name',
            value: ({mcc, mnc, subdestination_name}) => {
                const mccMncValue = mcc ? ` (${mcc || ''}${mnc})` : ''; 
                return `${subdestination_name}${mccMncValue}`;
            },
            label: `Subdestination ${!service ? '(mcc mnc)' : ''}` ,
            flexGrow: 2,
        },
        {
            id: 'allocated_to',
            label: 'Allocated to',
            value: ({
                account_name, trunk_name, cur_name, blocked, trunk_number_rate, test_number,
                allocated_account_name, allocated_rate, allocated_cur_name
            }) => {
                if (blocked) {
                    return 'Blocked';
                }
                if (test_number) {
                    return 'Test number';
                }
                const accountName = servicePick(service, account_name, allocated_account_name);
                const trunkNumberRate = servicePick(service, trunk_number_rate, allocated_rate);
                const curName = servicePick(service, cur_name, allocated_cur_name);

                return trunk_name ? `${accountName} / ${trunk_name} ${trunkNumberRate} ${curName}` : ''
            },
            flexGrow: 2,
        },
        ...(!service ? [{
            id: 'trafic_limit_list',
            label: 'Traffic limits',
            dataKey: 'trafic_limit_list',
            // sortable: true,
            value: ({trafic_limit_list}) => {
                const sortedList = copyArrayWithoutReference(trafic_limit_list).sort((a, b) => {
                    if (a.senderid < b.senderid) {
                        return -1;
                    } else if (a.senderid >= b.senderid) {
                        return 1;
                    }
                    return 0;
                });

                const firstElement = sortedList.length ? sortedList[0] : {};
                const remainingElementsList = sortedList.splice(1, sortedList.length);

                const stringElementsList = remainingElementsList.map(value => {
                    const hourDayValue = !value?.hour && !value?.day ? null : `${value.hour} hour/${value.day} day`;
                    
                    if (hourDayValue) {
                        return `${value?.senderid || '<empty>'} — ${hourDayValue}`;
                    }

                     return 'No limits';
                });

                const newLinedString = stringElementsList.length ? stringElementsList.map(element => <p>{element}</p>) : 'No more limits';

                const hour = firstElement.hour;
                const day = firstElement.day;
                const hourDayValue = !hour && !day ? null : `${hour} hour/${day} day`;
                const finalValue = hourDayValue ? `${firstElement.senderid || 'Any senderId'} — ${hourDayValue}` : 'No limits';
                

                return (
                    <Whisper
                        enterable
                        placement="auto"
                        speaker={
                            // Back later
                            <Tooltip>{newLinedString}</Tooltip>
                        }
                    >
                        <span>{finalValue}</span>
                    </Whisper>
                )
            },
            minWidth: 120,
            flexGrow: 1
        }] : [])
    ], []);


    return (
        <TableCommonNew
            ref={ref}
            columns={columns}
            originalColumns={columns}

            {...props}
        />
    )
});