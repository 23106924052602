import React, {useMemo, useState, useEffect} from "react";
import { useWindowWidth } from "../../../hooks";
import { Form, InputNumber, SelectPicker, Button, Input, Schema, Message } from "rsuite";
import {Spacer} from "../../../components/base/Spacer";
import * as S from "../styled";
import {SCREEN_MEDIA, currency_data, currency_names_data} from '../../../const';

const {sm} = SCREEN_MEDIA;

const {StringType, NumberType} = Schema.Types;

const AMOUNT_MIN = 0.01;

export default ({
    show,
    onClose,
    maxWidth = 700,
    paymentDetailsItems,
    currencyList,
    currencyName,
    currency,
    requestPayment,
    // billingCurrency,
    getPaymentRequestList,
    handleShowModalAddPaymentDetail,
    accountId,
    dropdownVisibility,
    amountLimit = 0,
    amountLimitMin = 0.01,
    amountLimitByCurrency = {},
    accounts = [],
    filter,

    service,
    formValue,
    setFormValue,
    updateAccount,
    showReasonHandler,
    getPaymentDetailsList
}) => {

    let formRef = null;

    const [pickedCurrency, setPickedCurrency] = useState(null);

    const formattedAccounts = accounts.map((account) => {
        return {
            ...account,
            name: `${account.name} / ${account.service}`
        }
    });

    const filteredPaymentDetailsList = useMemo(() => {
        return paymentDetailsItems.filter((paymentDetail) => {
            return paymentDetail.cur_key === pickedCurrency;
        });
    }, [paymentDetailsItems, pickedCurrency]);

    const hasAmountLimitMyCurrency = !!Object.keys(amountLimitByCurrency).length;
    const filteredCurrencyList = currencyList && hasAmountLimitMyCurrency ? currencyList.filter(currencyValue => Object.keys(amountLimitByCurrency).includes(currencyValue.cur_key.toString())) : [];

    const formModel = useMemo(() => {
        const currentAmountLimit = hasAmountLimitMyCurrency ? amountLimitByCurrency[pickedCurrency] : amountLimit;
        const AMOUNT_MAX = currentAmountLimit ? parseFloat(currentAmountLimit) : 0;

        return Schema.Model({
            amount: NumberType()
                .isRequired("This field is required")
                .min(AMOUNT_MIN, `The minimum number is ${AMOUNT_MIN}`)
                .max(AMOUNT_MAX, `The maximum number is ${AMOUNT_MAX}`),
            payment_detail_id: StringType()
                .addRule((value, data) => {
                    if (!value && !data.move_to_account_id)
                        return false;

                    return true;
                }, "This field is required", true)
                .addRule((value, data) => {
                    if (value && data.move_to_account_id)
                        return false;
                    
                        return true;
                }, 'You cannot choose payment details with "Transfer to another account" option'),
        });
    }, [amountLimitByCurrency, amountLimit, pickedCurrency]);

    const [loading, setLoading] = useState(false);
    const resizedWidth = useWindowWidth();
    const [formIsChanged, setFormIsChanged] = useState(false);


    // clean Amount error on limit change
    useEffect(() => {
        if (accountId)
            getPaymentDetailsList({target: {account_id: accountId}});
    }, []);

    useEffect( () => {
        if (formRef)
            formRef.cleanErrorForFiled('amount');
    }, [amountLimitMin] );


    useEffect(() => {
        setPickedCurrency(formValue?.cur_key || currency)
    }, [formValue, currency]);

    const handleSubmit = async () => {

        if (!formIsChanged)
            setFormIsChanged(true);
        
        if (!formRef.check() )
            return null;

        setLoading(true);

        const value = formRef.getFormValue();
        const data = {
            ...value,
            cur_key: value?.cur_key || currency || currency_data[currencyName],
            amount: +value.amount,
            target: {
                account_id: accountId,
                payment_detail_id: value.payment_detail_id,
            },
        };

        delete data.payment_detail_id;

        if (data.move_to_account_id) {
            data.target.account1_id = data.move_to_account_id;
            delete data.move_to_account_id;
        }

        await requestPayment(data);

        if (getPaymentRequestList) {
            const dataGetList = {
                target: {account_id: accountId},
                filter: filter|| {}
            };
            getPaymentRequestList(dataGetList);
        }

        updateAccount(accountId);
        
        setFormValue({amount: 0, payment_detail_id: null, move_to_account_id: null});
        setLoading(false);
        onClose();
    };

    const handleAmountChange = () => {
        if (!formIsChanged)
            setFormIsChanged(true);
    };

    const renderMenuItem = (label, item) => {
        return item.status === 1 ? label : <span style={{color: '#939191'}}>{label}</span>;
    };

    const showAmountAvailableText = (amountLimit, currencyName) => {
        return <p>{`${amountLimit || 0} ${currencyName || ''} is available for payment now. You cannot request more than this amount`}</p>
    };

    return (
        <S.FormModal
            {...{show, onClose}}
            title="Payment request"
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showCloseSuccessButtons={true}
            onSuccessClose={false}
            successButton="Confirm"
            loading={loading}
            focusTarget="close"
            footer={true}
            onSuccess={handleSubmit}
            onClose={() => {
                setFormValue({amount: 0, payment_detail_id: null, move_to_account_id: null});
                onClose(false);
            }}
            onExited={() => {
                setFormValue({amount: 0, payment_detail_id: null, move_to_account_id: null});
            }}
        >
            <Form
                ref={ref => formRef = ref}
                model={formModel}
                formValue={formValue}
                onChange={setFormValue}
            >
                {hasAmountLimitMyCurrency ? Object.keys(amountLimitByCurrency).map(currencyKey => {
                    const currentAmountLimit = amountLimitByCurrency[currencyKey];
                    const currentCurrencyName = currency_names_data[currencyKey];
                    return showAmountAvailableText(currentAmountLimit, currentCurrencyName);
                }) : showAmountAvailableText(amountLimit, currencyName)}

                <Spacer/>

                <S.FormRow>
                    <S.FormItem>

                        <S.FormGroup>
                            <S.Label>Amount</S.Label>
                            <S.FieldAmountWrapper>
                                <S.Field
                                    accepter={InputNumber}
                                    name='amount'
                                    onChange={handleAmountChange}
                                />
                            </S.FieldAmountWrapper>
                            {filteredCurrencyList.length > 1 ? <S.FieldAmountWrapper ml={10}>
                                <S.Field
                                    accepter={SelectPicker}
                                    data={currencyList}
                                    labelKey="name"
                                    valueKey="cur_key"
                                    name="cur_key"
                                    defaultValue={currency}
                                    searchable={false}
                                    cleanable={false}
                                    errorPlacement="topEnd"
                                    onChange={(value) => {
                                        setPickedCurrency(value);
                                        setFormValue({...formValue, cur_key: value, payment_detail_id: null});
                                    }}
                                    // disabled={currencyDisabled}
                                />
                            </S.FieldAmountWrapper> : currencyName && <S.Currency>{currencyName}</S.Currency>}
                          
                        </S.FormGroup>

                    </S.FormItem>
                    <S.FormItem grow>

                        <S.FormGroup>
                            <S.Label>Payment info</S.Label>
                            <S.Field
                                accepter={SelectPicker}
                                data={filteredPaymentDetailsList}
                                cleanable={true}
                                name='payment_detail_id'
                                valueKey="id"
                                block={true}
                                errorPlacement={resizedWidth <= sm ? "auto" : "bottomEnd"}
                                labelKey="payment_detail_info"
                                menuClassName={dropdownVisibility ? "" : "hide-dropdown"}
                                renderMenuItem={renderMenuItem}
                                renderExtraFooter={() => {
                                    return (
                                        <Button 
                                            appearance="link"
                                            onClick={() => {
                                                handleShowModalAddPaymentDetail();
                                            }}
                                        >
                                            + Add new
                                        </Button>
                                    )
                                }}
                            />
                        </S.FormGroup>
                    </S.FormItem>
                
                </S.FormRow>

                <Spacer/>

                <S.FormRow>
                    <S.FormItem grow>
                        <S.FormGroup>
                            <S.Label>or transfer to another account</S.Label>
                            <S.Field
                                accepter={SelectPicker}
                                data={formattedAccounts.filter((item) => item.is_managed)}
                                cleanable={true}
                                name='move_to_account_id'
                                valueKey="id"
                                labelKey="name"
                                block={true}
                                errorPlacement={resizedWidth <= sm ? "auto" : "bottomStart"}
                                menuClassName={dropdownVisibility ? "" : "hide-dropdown"}
                            />
                        </S.FormGroup>
                    </S.FormItem>
                </S.FormRow>

                <Spacer/>

                <S.Field
                    name="comment"
                    accepter={Input}
                    componentClass="textarea"
                    placeholder="Comment"
                />

                {amountLimitMin > formValue.amount && formValue.payment_detail_id && formIsChanged &&
                    <>
                        <Spacer/>
                        <Message 
                            type="warning" 
                            closable 
                            description={<p><b>Amount</b> should be greater than <b>{amountLimitMin}</b></p>}
                        />
                    </>
                }
                
            </Form>
        </S.FormModal>
    )
};