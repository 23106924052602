import React from 'react';
import { Form, FormGroup,SelectPicker, Input, InputGroup, Icon, Schema} from 'rsuite';
import CustomField from '../../components/Form/CustomField';
import InputSearch from "../../components/Form/InputSearch";
import FormHOC from '../../hoc/FilltersForm';
import Checkbox from '../../hoc/Checkbox';
import ServicePlans from "../../components/Filters/ServicePlans";
import references from "../../reducers/references";
import {connect} from "react-redux";
const { StringType } = Schema.Types;

const filtersModel = Schema.Model({
    str: StringType().maxLength(40, 'The maximum is only 40 characters.'),
});


const RangesFilters = ({loading, worldzoneList, destinationList, subdestinationList, defaultValues={}, onChange, defaultSPKey}) =>  {
    const [sde_key, setSde] = React.useState(null);

    React.useEffect(() => {
        if (defaultValues.sde_key)
            setSde(defaultValues.sde_key)
    }, [defaultValues]);

    return (
        <>
            <Form 
                layout="inline" 
                style={{marginBottom: '-25px'}}
                onChange={onChange}
                formDefaultValue={defaultValues}
                model={filtersModel}
            >
                <CustomField
                    name="str"
                    disabled={loading}
                    accepter={InputSearch}
                    errorPlacement="topRight"
                    placeholder="Range name/Numbers search"
                    style={{width:220}}
                />
                <CustomField
                    disabled={loading}
                    accepter={SelectPicker}
                    labelKey="name"
                    valueKey="wz_key"
                    data={worldzoneList}
                    placeholder="Worldzone"
                    name="wz_key"
                />
                <CustomField
                    disabled={loading}
                    accepter={SelectPicker}
                    data={destinationList}
                    labelKey="name"
                    valueKey="de_key"
                    placeholder="Destination"
                    name="de_key"
                /> 
                <CustomField
                    disabled={loading}
                    accepter={SelectPicker}
                    data={subdestinationList}
                    labelKey="name"
                    valueKey="sde_key"
                    onChange = { (sde_key) => setSde(sde_key) }
                    value = {sde_key}
                    placeholder="Subdestination"
                    name="sde_key"
                    classPrefix="allocated"
                />
                <Checkbox name="only_with_unallocated_numbers" defaultChecked disabled={loading}>Show only available ranges</Checkbox>
                <Checkbox name="only_without_test_numbers" disabled={loading}>Show private ranges</Checkbox>
                <Checkbox name="only_with_incorporated_numbers" disabled={loading}>Show only global access numbers ranges</Checkbox>
            </Form>
        </>
    )
};

const mapStateToProps = ({references}) => ({
    defaultSPKey: references.defaultSPKey
});

export default connect(mapStateToProps)(FormHOC(RangesFilters, filtersModel,300));
