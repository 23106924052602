import React, {useState, useMemo} from 'react';
import {Form, Input, Whisper, Icon, Schema, SelectPicker, Alert, Table, FormControl, Tooltip} from 'rsuite';
import {servicePick, getManagedServices} from '../../utils';
import {LOCAL_STORAGE_DELAYED_SERVICE_CHANGE} from '../../const/localStorageKeys';
import {ACCOUNT_CREATE_JOINED_METHOD, SMS_ACCOUNT_CREATE_JOINED_METHOD} from '../../const/apiMethods';
import { transformed_service_data } from '../../const';
import OpenAccount from '../../pages/NewAccount/OpenAccount';
import BaseTable from "../../components/base/BaseTable";
import CloseAccount from './CloseAccount';
import Button from 'rsuite/es/Button'
import styled, {css} from 'styled-components';
import {AccountCreate} from './SaveModel';
import {api} from '../../api/loginRoutes';

const {StringType, NumberType} = Schema.Types;


const serviceTableModel = Schema.Model({
    account_manager_id: StringType().isRequired('Required'),
    cur_key: NumberType().isRequired('Required'),
    pt_key: NumberType().isRequired('Required'),
});

const UNEDITABLE = 0;
const CREATING_CODE = 1;
const EDITING_CODE = 2;


const ServiceTable = ({
    formServiceRef,
    data,
    service,
    loading,
    history,
    location,
    authInfo,
    isMobile,
    accountId,
    setService,
    currencyList,
    paymentTermsList,
    accountManagerList,
    getAccountJoin
}) => {

    const [localLoading, setLocalLoading] = useState(false);
    const [editData, setEditData] = useState({
        sms: null,
        voice: null
    });

    const onlyActiveAccountManagerList = accountManagerList.filter((accountManager) => accountManager.active);

    const renderedServiceData = useMemo(() => {
        return data.map((serviceData) => {
            const allManagersByService = data.map((serviceData) => {
                const currentAccountManager = accountManagerList.find((accountManager) => accountManager.name === serviceData.account_manager_name);
        
                return currentAccountManager ? currentAccountManager : {
                    id: serviceData.account_manager_name,
                    name: serviceData.account_manager_name
                }
            });

            const currentCurrency = currencyList.find(currency => currency.name === serviceData.currency_name);
            const currentAccountManager = accountManagerList.find(accountManager => accountManager.name === serviceData.account_manager_name);
            const currentPaymentTerm = paymentTermsList.find(payment => payment.name === serviceData.payment_terms_name);

            const managedServiceList = getManagedServices(authInfo);
            const serviceButtonDisabled = managedServiceList.includes(serviceData.service);

            const serviceDisabled = true;
            const currencyDisabled = !([CREATING_CODE].includes(editData[serviceData.service]?.editing));
            const accountManagerDisabled = !([CREATING_CODE, EDITING_CODE].includes(editData[serviceData.service]?.editing));
            const paymentTermsDisabled = !([CREATING_CODE, EDITING_CODE].includes(editData[serviceData.service]?.editing));

            const defaultFormData = {
                cur_key: currentCurrency?.cur_key,
                account_manager_id: (serviceData.active && !!currentAccountManager) ? currentAccountManager?.id : serviceData.account_manager_name,
                pt_key: currentPaymentTerm?.pt_key
            };
   
            return currencyList.length && paymentTermsList.length && accountManagerList.length && <ServiceForm
                ref={ref => formServiceRef[serviceData.service] = ref}
                model={serviceTableModel}
                disabled={loading || localLoading}
                onChange={(formValue) => {
                    const currentEditeAccountServiceData = {...editData};
                    currentEditeAccountServiceData[serviceData.service] = {
                        ...currentEditeAccountServiceData[serviceData.service],
                        ...formValue
                    };
                    setEditData(currentEditeAccountServiceData);
                }}
                formDefaultValue={defaultFormData}
                styleClosed={!serviceData.active && !editData[serviceData.service]}
                mobile={isMobile}
            >
                <StyledSplitedFieldWrapper>
                    <StyledField style={{width: 80, minWidth: 60}}>
                        <Whisper 
                            placement={"bottomRight"} 
                            trigger="hover" 
                            speaker={<Tooltip>Service</Tooltip>}
                            enterable={true}
                        >
                            <FormControl
                                accepter={Input}
                                value={serviceData.service_name}
                                errorPlacement="topEnd"
                                disabled={serviceDisabled}
                            />
                        </Whisper>
                    </StyledField> 
                    <StyledField style={{width: 100, minWidth: 60}}>
                        <Whisper 
                            placement={"bottomRight"} 
                            trigger="hover" 
                            speaker={<Tooltip>Currency</Tooltip>}
                            enterable={true}
                        >
                            <FormControl
                                accepter={SelectPicker}
                                data={currencyList}
                                labelKey="name"
                                valueKey="cur_key"
                                name="cur_key"
                                searchable={false}
                                cleanable={false}
                                errorPlacement="topEnd"
                                disabled={currencyDisabled || localLoading}
                            />
                        </Whisper>
                    </StyledField>
                </StyledSplitedFieldWrapper>
                <StyledSplitedFieldWrapper>
                    <StyledField style={{width: 240}}>
                        <Whisper 
                            placement={"bottomRight"} 
                            trigger="hover" 
                            speaker={<Tooltip>Account manager</Tooltip>}
                            enterable={true}
                        >
                            <FormControl
                                accepter={SelectPicker}
                                placeholder="Account manager"
                                data={editData[serviceData.service] ? onlyActiveAccountManagerList : allManagersByService}
                                labelKey="name"
                                valueKey="id"
                                name="account_manager_id"
                                label="Account manager"
                                searchable={false}
                                cleanable={false}
                                errorPlacement="topEnd"
                                disabled={accountManagerDisabled || localLoading}
                            />
                        </Whisper>
                    </StyledField>
                    <StyledField style={{width: 90}}>
                        <Whisper 
                            placement={"bottomRight"} 
                            trigger="hover" 
                            speaker={<Tooltip>Payment terms</Tooltip>}
                            enterable={true}
                        >
                            <FormControl
                                accepter={SelectPicker}
                                data={paymentTermsList}
                                labelKey="name"
                                valueKey="pt_key"
                                name="pt_key"
                                searchable={false}
                                cleanable={false}
                                errorPlacement="topEnd"
                                disabled={paymentTermsDisabled || localLoading}
                            />
                        </Whisper>
                    </StyledField>
                </StyledSplitedFieldWrapper>
                <StyledButtonContainer style={{width: 120}}>
                    {!serviceData.hasService && !editData[serviceData.service] 
                        ? <ServiceButton
                            service={serviceData.service}
                            appearance="ghost"
                            onClick={() => {
                                const currentEditeAccountServiceData = {...editData};
                                currentEditeAccountServiceData[serviceData.service] = {
                                    pt_key: null,
                                    account_manager_id: null,
                                    service: serviceData.service,
                                    cur_key: null,
                                    editing: CREATING_CODE
                                };
                                setEditData(currentEditeAccountServiceData);
                            }}
                            disabled={!serviceButtonDisabled}
                        >
                            Create {serviceData.service}
                        </ServiceButton>
                    : (!serviceData.hasService || [CREATING_CODE, EDITING_CODE].includes(editData[serviceData.service]?.editing))
                    && Object.keys(editData[serviceData.service]).length 
                        ? <>
                            <SaveServiceButton
                                appearance="ghost"
                                disabled={localLoading}
                                onClick={async () => {
                                    setLocalLoading(true);

                                    const currentService = transformed_service_data[serviceData.service];
                                    const method = !serviceData.hasService 
                                    ? servicePick(currentService, ACCOUNT_CREATE_JOINED_METHOD, SMS_ACCOUNT_CREATE_JOINED_METHOD)
                                    : 'account:modify';

                                    const editedData = editData[serviceData.service];
  
                                    const data = new AccountCreate(editedData);

                                    const ref = formServiceRef[serviceData.service];

                                    if (ref) {
                                        ref.check()
                                    }
                                    const serviceFormCheckedData = serviceTableModel.check(editedData);
                                    const serviceFormHasError = Object.keys(serviceFormCheckedData).find(key => {
                                        return !!serviceFormCheckedData[key].hasError;
                                    })
                                    if (serviceData.hasService || !serviceFormHasError) {                                       
                                        const currentService = transformed_service_data[serviceData.service];
                                        
                                        history.replace('/reload');
                                        localStorage.setItem(LOCAL_STORAGE_DELAYED_SERVICE_CHANGE, currentService);
                                        setService(currentService);

                                        await api(method, {
                                            ...data,
                                            target: {
                                                account_id: !serviceData.hasService ? accountId : serviceData.id,
                                                account_manager_id: editedData.account_manager_id,
                                                // pt_key: currentPaymentTerm?.pt_key
                                            }
                                        }, true).then((response) => {
                                            if (response) {
                                                const {account, account_id} = response || {};
                                                let id = account_id;

                                                if (!account_id && account) {
                                                    id = account?.id;
                                                }

                                                if (id) {
                                                    // ATX-2922
                                                    // localStorage.removeItem("SMS_SERVICE");
                                            
                                                    // history.replace('/reload');
                                        
                                                    setTimeout((tHistory, tLocation) => {
                                                        tHistory.replace({
                                                            ...tLocation,
                                                            pathname: `/accounts/view/${id}`,
                                                            search: '?tab=general'
                                                        });    
                                                    }, 200, history, location);
                                    
                                                    // ATX-2922
                                                    // localStorage.setItem("SMS_SERVICE", currentService);
                                                } else {
                                                    history.push({
                                                        ...location,
                                                        pathname: `/accounts/view/${accountId}`,
                                                        search: '?tab=general'
                                                    });    
                                                }

                                                localStorage.setItem("AFTER_CREATE_SERVICE", true)
                                            }
                                            
                                        }).finally(() => {
                                            const currentEditeAccountServiceData = {...editData};

                                            currentEditeAccountServiceData[serviceData.service].editing = UNEDITABLE;
                                            setEditData(currentEditeAccountServiceData);

                                            // getAccountJoin(accountId);
                                            setLocalLoading(false);
                                        });
                                    } else {
                                        setLocalLoading(false);
                                    }
                                }}
                            >
                                <Icon icon="check-circle"/>
                            </SaveServiceButton>
                            <CancelServiceButton
                                appearance="ghost"
                                disabled={localLoading}
                                onClick={() => {
                                    const currentEditeAccountServiceData = {...editData};

                                    if (!serviceData.hasService) {
                                        currentEditeAccountServiceData[serviceData.service] = null;
                                    } else {
                                        currentEditeAccountServiceData[serviceData.service].editing = UNEDITABLE;
                                    }
                                    setEditData(currentEditeAccountServiceData);
                                }}
                            >
                                <Icon icon="close-circle"/>
                            </CancelServiceButton>
                        </>
                    : serviceData.hasService
                        ? <>
                        <Whisper
                            placement={"bottomRight"} 
                            trigger="hover" 
                            speaker={<StyledTooltip show={!serviceData.active || !serviceData.is_managed}>
                                {!serviceData.is_managed ? <>Account service is not editable</> : <>Open account service for editing</>}
                            </StyledTooltip>}
                            enterable={true}
                        >
                            <EditServiceButton
                                appearance="ghost"
                                disabled={!serviceData.active || !serviceData.is_managed}
                                onClick={() => {
                                    const currentEditeAccountServiceData = {...editData};

                                    currentEditeAccountServiceData[serviceData.service] = {
                                        ...serviceData,
                                        editing: EDITING_CODE
                                    };
                                    setEditData(currentEditeAccountServiceData);
                                }}
                            >
                                <Icon icon="edit"/>
                            </EditServiceButton>
                        </Whisper>
                        {serviceData.active
                            ? <CloseAccount
                                onUpdate={() => {
                                    getAccountJoin(accountId);
                                }}
                                target={{account_id: serviceData.id}}
                            />
                            : <OpenAccount
                                onUpdate={() => {
                                    getAccountJoin(accountId);
                                }}
                                target={{account_id: serviceData.id}}
                            />}
                        </>
                        : <></>
                    }
                </StyledButtonContainer>
            </ServiceForm>});
    }, [data, editData, isMobile, localLoading, currencyList.length, paymentTermsList.length, accountManagerList.length]);
    
    return (<ServiceFormContainer>
        <ServiceHeader>Account services</ServiceHeader>
        {renderedServiceData}
    </ServiceFormContainer>);
}

export default ServiceTable;


const ServiceFormContainer = styled.div`
    && {
        margin-top: 14px;
        margin-right: 30px;
    }
`;

const ServiceHeader = styled.label`
    && {
        min-height: 20px;
        // line-height: 1.42857143;
        font-size: 14px;
        padding: 8px 0;
        width: 170px;
        margin-right: 12px;
        margin-bottom: 4px;


        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */
        color: var(--color-main);
    }
`;

const StyledSplitedFieldWrapper = styled.div`
    && {
        display: flex;
        margin-bottom: 15px;
    }
`;

const ServiceForm = styled(Form)`
    && {
        display: ${props => props.mobile ? 'block' : 'flex'};
        margin-bottom: 15px;
    }

    && .rs-input[disabled], && .rs-picker-select.rs-picker-disabled, && .rs-picker-select.aria-disabled {
        color: inherit;
        background-color: inherit;
        opacity: 0.6;
    }

    && .rs-form-control-wrapper .rs-error-message-placement-top-end .rs-error-message {
        bottom: auto;
        font-size: 10px;
        padding: 0 2px;
        border: none;
        box-shadow: none;
        right: 6px;
        top: -4px;
    }

    &&& .rs-input, &&& .rs-picker-select .rs-btn {
        ${props => 
            props.styleClosed &&
            css`
                background-color: #d3d3d9 !important;
                border-color: #b4b4b4;
            `
        }
    }
`;

const StyledField = styled.div`
    && {
        margin-right: 10px;
    }
`;


const StyledButtonContainer = styled.div`
`;

const ServiceButton = styled(Button)`
    height: 34px;
    width: 110px;
`;

const SaveServiceButton = styled(Button)`
    && {
        margin-right: 10px;
        width: 34px;
        height: 34px;
        padding: 0px;
        border-color: var(--color-secondary) !important;
        color: var(--color-secondary) !important;
    }
`;

const CancelServiceButton = styled(Button)`
    && {
        width: 34px;
        height: 34px;
        padding: 0px;
        border-color: red !important;
        color: red !important;
    }
`;

const EditServiceButton = styled(Button)`
    && {
        margin-right: 10px;
        width: 34px;
        height: 34px;
        padding: 0px;
        border-color: var(--color-primary) !important;
        color: var(--color-primary) !important;
    }
`;


const StyledTooltip = styled(Tooltip)`
    display: ${props => !props.show ? 'none !important' : undefined};
    && .rs-tooltip-inner {
        padding-bottom: 5px;    
        padding-top: 5px;
    }
`;