import React, {useContext, useEffect, useState} from "react";
import {renderColumn} from "../../utils/helpers";
import {Table} from 'rsuite';
import {SCREEN_MEDIA} from '../../const';
import {useWindowWidth} from "../../hooks";
import * as S from "./styled.js";
import {RtlContext} from "../../App";

const {sm} = SCREEN_MEDIA;
const {Column, HeaderCell, Cell} = Table;

export default ({
    data = [], 
    loading = false,
    columns,
    tableActions
}) => {


    const rtl = useContext(RtlContext);
    const resizedWidth = useWindowWidth();
    const [currentTop, setCurrentTop] = useState(0);

    let tableRef = null;

    useEffect(() => {
        if (!tableRef)
            return;
        tableRef.scrollTop( Math.abs(currentTop) );
    }, [data, loading, tableRef]);

    // push table actions
    const renderedColumns = columns.map(renderColumn);
    if (tableActions) {
        renderedColumns.push(tableActions);
    }

    // add fixing column to fix horizontal scroll
    const columnMobileFix = <Column key="table_scrollfix" width={0} fixed><HeaderCell/><Cell/></Column>;
    renderedColumns.push(columnMobileFix);

    if ( rtl ) renderedColumns.reverse();

    return (
        <S.Table
            virtualized
            ref={ref => tableRef = ref}
            data={data}
            loading={loading}
            autoHeight={resizedWidth <= sm.max}
            onScroll={(scrollX, scrollY) => {
                setCurrentTop(scrollY);
            }}
        >
            {renderedColumns}
        </S.Table>
    )
};