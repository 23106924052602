import React, { useRef, useMemo, useState, useEffect } from "react";
import Page from "components/base/Page";
import PageHeader from "components/base/PageHeader";

import { FormattedMessage, useIntl } from "react-intl";

import LiveCallsFilter from "./LiveCallsFilter";
import LiveCallsTable from "./LiveCallsTable";
import LiveCallsSettings from "./LiveCallsSettings";

import { pipe, compareObjects } from "utils";
import { DEFAULT_PER_PAGE_SMALL } from "const";
import { LOCAL_STORAGE_SUBACC_LIVE_CALLS_AUTO_UPDATE } from "const/localStorageKeys";

import { FilterSectionFlexboxGrid, StyledItemFitWidth, StyledItemAutoMargin } from "components/base/FitContainers";

import {useWindowWidth} from "hooks";

const LiveCalls = ({
    data,

    loading,
    accountJoinList,

    getLiveCallsList
}) => {

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 767;

    const updateTimerRef = useRef(null);

    const accountData = useMemo(() => {
        return accountJoinList.find((account) => {
            return account.service === "voice";
        })
    }, [accountJoinList]);

    const filteredDataBySubaccounts = useMemo(() => {
        return data.filter((value) => {
            return !!value.subaccount;
        });
    }, [data]);

    const defaultFilterValue = {number_destination: null};

    const {formatMessage} = useIntl();

    const [filterState, setFilterState] = useState(defaultFilterValue);
    const [filteredData, setFilteredData] = useState(filteredDataBySubaccounts);

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE_SMALL);
    const [count, setCount] = useState(0);

    const [clientLoading, setClientLoading] = useState(false);

    
    const savedAutoUpdate = localStorage.getItem(LOCAL_STORAGE_SUBACC_LIVE_CALLS_AUTO_UPDATE);
    const timerValue = savedAutoUpdate && !isNaN(savedAutoUpdate) && parseInt(savedAutoUpdate) <= 100000
        ? savedAutoUpdate
        : null;

    const [autoUpdateFormState, setAutoUpdateFormState] = useState({
        timer: timerValue
    });


    const filtersModel = {
        number_destination: (value, filterValue) => {
            return (value.a_number && value.a_number.includes(filterValue))
                || (value.b_number && value.b_number.includes(filterValue))
                || (value.a_subdestination_name && value.a_subdestination_name.includes(filterValue))
                || (value.b_subdestination_name && value.b_subdestination_name.includes(filterValue))
                || (value.termination_point && value.termination_point.includes(filterValue))
                || !filterValue;
        }
    };

    useEffect(() => {
        update();
    }, [])
    
    useEffect(() => {
        setFilteredData((currentData) => {
            if (filteredDataBySubaccounts && !filteredDataBySubaccounts.length) {
                return [];
            }

            onChangePage(1);

            if (filteredDataBySubaccounts && filteredDataBySubaccounts.length) {
                const filteredDataByFilter = pipe(filteredDataBySubaccounts, filterState, filtersModel);
                return getPaginatedData(filteredDataByFilter, 1);
            }

            return getPaginatedData(currentData);
        });
    }, [filterState, filteredDataBySubaccounts]);

    const getPaginatedData = (_data, _page=null, _perPage=null) => {
        const currentPage = _page ? _page : page;
        const currentPerPage = _perPage ? _perPage : perPage;

        setCount(_data.length);

        return _data 
            ? _data.slice(currentPage * currentPerPage - currentPerPage, currentPage * currentPerPage)
            : [];
    };

    const onChangePage = (_page) => {
        if (!clientLoading) {
            setClientLoading(true);
        }

        setPage(_page);

        setTimeout(() => {
            setClientLoading(false);
        }, 500);
    };

    const onChangePerPage = (_perPage) => {
        if (!clientLoading) {
            setClientLoading(true);
        }

        setPerPage(_perPage);
       
        setTimeout(() => {
            setClientLoading(false);
        }, 500);
    };

    const update = () => {
        getLiveCallsList();
    };

    const autoUpdate = (formValue={}) => {
        const timerValue = parseInt(formValue.timer || autoUpdateFormState.timer || 0);
        if (!timerValue && updateTimerRef.current) {
            clearTimeout(updateTimerRef.current);
            return;
        }

        if (updateTimerRef.current) {
            clearTimeout(updateTimerRef.current);
        }

        if (timerValue >= 1) {
            updateTimerRef.current = setTimeout(() => {
                update();
            }, timerValue * 1000);
        }
    };
    
    const onChangeAutoUpdateValue = (formValue) => {
        const currentTimerValue = formValue.timer;

        localStorage.setItem(LOCAL_STORAGE_SUBACC_LIVE_CALLS_AUTO_UPDATE, currentTimerValue);

        setAutoUpdateFormState(() => {
            autoUpdate(formValue);

            return formValue;
        });
    };

    return <Page>
        <PageHeader  showService={false} title={<FormattedMessage id="liveCalls.liveCalls.header" defaultMessage="LIVE CALLS" />}/>
        <FilterSectionFlexboxGrid>
            <StyledItemFitWidth isMobile={isMobile}>
                <LiveCallsFilter
                    filter={filterState}
                    disabled={loading}

                    formatMessage={formatMessage}
                    onChangeFilter={setFilterState}
                />
            </StyledItemFitWidth>
            <StyledItemAutoMargin isMobile={isMobile}>
                <LiveCallsSettings
                    disabled={loading}
                    formDefaultValue={autoUpdateFormState}

                    update={update}
                    onChange={onChangeAutoUpdateValue}
                    formatMessage={formatMessage}
                />
            </StyledItemAutoMargin>
        </FilterSectionFlexboxGrid>
        <LiveCallsTable
            data={filteredData}
            page={page}
            count={count}
            perPage={perPage}
            loading={loading}
            accountData={accountData}

            formatMessage={formatMessage}
            getItems={(_page, _perPage) => {
                onChangePage(_page);
                onChangePerPage(_perPage);

                setFilteredData(() => {
                    const filteredDataByFilter = pipe(filteredDataBySubaccounts, filterState, filtersModel);
                    return getPaginatedData(filteredDataByFilter, _page, _perPage);
                });
            }}
            onDataUpdated={autoUpdate}
        />
    </Page>
};

export default LiveCalls;