import {AUTH_NUMBERS_CLIENT as PREFIX,
    DEFAULT_PER_PAGE_MEDIUM
} from "const";
import {
    AUTH_NUMBERS_GET_LIST_DIALER_API
} from "const/apiMethods";
import {api} from "api/loginRoutes";
import {_setValue} from "./defaults";

const setAuthNumbersList = _setValue("SET_ITEMS_" + PREFIX);
const setLoading = _setValue("SET_LOADING_" + PREFIX);

export const getAuthNumbersList = (filter = {}, page = 1, per_page = DEFAULT_PER_PAGE_MEDIUM, sort = null) => (dispatch) => {
    dispatch(setLoading(true));

    const method = AUTH_NUMBERS_GET_LIST_DIALER_API;
    const params = {
        filter,
        ...(sort ? {sort} : {}),
        page,
        per_page
    };

    api(method, params)
    .then((response) => {
        if (response !== undefined) {
            dispatch(setAuthNumbersList({
                list: response["auth_number_list"] || [],
                count: response["auth_number_count"],
                page,
                per_page
            }));
        }
    })
    .finally(() => {
        dispatch(setLoading(false));
    });
};

export const loadingData = (load) => (dispatch) => {
    dispatch(setLoading(load));
};
