import React, {useEffect, useState, useMemo} from 'react';
import PageHeader from '../../components/base/PageHeader';
import {connect} from 'react-redux';
import PanelLayout from '../../components/base/PanelLayout';
import E212Table from './E212Table';
import E212Filter from './E212Filter';
import {getE212List, getE212OperatorList} from 'actions/e212';
import {useWindowWidth} from 'hooks';
import {getReferencesForReports} from 'actions/references';


const E212Page = ({
    page,
    per_page,
    count,
    loading,
    e212List,
    operatorList,

    countryList,

    getE212List,
    getE212OperatorList,
    getReferencesForReports,
    ...props
}) => {

    const defaultFilter = {
        e_code: "",
        mcc: "",
        mnc: "",
        country: null,
        operator: "",
        length_min_max: null
    };

    const [filter, onChangeFilter] = useState(defaultFilter);
    const defaultSort = {column: "e_code", type: "asc"};

    const [sort, setSort] = useState(defaultSort);

    useEffect(() => {
        getE212List(filter, 1, per_page, sort);
        getE212OperatorList();
    }, []);
    
    const onChangeFilters = (filterValue) => {
        onChangeFilter({...filterValue});
        getE212List(filterValue, 1, per_page, sort);
    };

    const getItems = (page, perPageValue, sortValue) => {
        getE212List(filter, page, perPageValue, sortValue);
    };

    const windowWidth = useWindowWidth();

    return (
        <PanelLayout>
            <PageHeader title="E164/E212"/>
            <E212Filter
                defaultFilter={defaultFilter}
                onChangeFilter={onChangeFilters}
                operatorList={operatorList}
                countryList={countryList}
            />
            <E212Table
                windowWidth={windowWidth}
                getList={getE212List}
                dataList={e212List}
                loading={loading}
                page={page}
                per_page={per_page}
                count={count}
                sort={sort}
                getItems={getItems}
            />
        </PanelLayout>
    );
};

const mapStateToProps = ({e212, references}) => ({
    countryList: references.country_list,

    per_page: e212.per_page,
    page: e212.page,
    count: e212.count,
    e212List: e212.list,
    operatorList: e212.operator_list,
    loading: e212.loading
});

const mapDispatchToProps = {
    getE212List,
    getE212OperatorList,
    getReferencesForReports
};

export default connect(mapStateToProps, mapDispatchToProps)(E212Page);

