import React, {useState, useEffect} from "react";
import PaymentsSectionHeader from "../../Payments/PaymentsSectionHeader";
import PaymentsRequestTable from "../../Payments/PaymentsRequestSection/PaymentsRequestTable";
import {Spacer} from "../../../components/base/Spacer";
import {FlexGrid, FlexGridItem} from "../../../components/base/FlexGrid";
import {Collapse} from "react-collapse";
import {ButtonPrimary} from "../../../components/base/BaseButton";
import ModalChangePaymentRequestStatus from "../../Payments/ModalChangePaymentRequestStatus";

export default ({
    service,
    accountId,
    currencyName,
    paymentRequestItems, 
    paymentRequestLoading, 
    rejectPaymentRequest,
    approvePaymentRequest,
    getPaymentRequestList,
    openModalRequestPayment,
    openModalPaymentDetail,
    updateAccount
}) => {
    
    const [showTable, setShowTable] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [showModalCancelPaymentRequest, setShowModalCancelPaymentRequest] = useState(false);
    const [showModalApprovePaymentRequest, setShowModalApprovePaymentRequest] = useState(false);

    useEffect( () => {
        if (accountId) {
            getPaymentRequestList({
                target: {account_id: accountId}
            });
        }
    }, [accountId] );

    const handleCancelPaymentRequest = (value) => {
        setSelectedRequest(value);
        setShowModalCancelPaymentRequest(true);
    };


    const handleCancelPayment = async () => {
        const data = {
            target: {
                payment_request_id: (selectedRequest || {})['id']
            },
        };

        await rejectPaymentRequest(data).then((response) => {
            if (response?.payment_request) {
                getPaymentRequestList({
                    target: {account_id: accountId}
                });
                if (updateAccount) {
                    updateAccount(accountId);
                }
            }
        });
    };


    const handleApprovePaymentRequest = (value) => {
        setSelectedRequest(value);
        setShowModalApprovePaymentRequest(true);
    };

    const handleApprovePayment = async () => {
        const data = {
            target: {
                payment_request_id: (selectedRequest || {})['id']
            },
        };

        await approvePaymentRequest(data);

        getPaymentRequestList({
            target: {account_id: accountId}
        });
    };

    return (
        <>
            <PaymentsSectionHeader
                title="Payment request"
                show={showTable}
                callback={() => {
                    setShowTable(!showTable);
                }}
            />

            <Collapse isOpened={showTable}>
                <div>
                    <Spacer />
                    <FlexGrid hSpace="-5px" justify="end">
                        <FlexGridItem hSpace="5px">
                            
                            <ButtonPrimary onClick={openModalPaymentDetail}>
                                Manage payment details
                            </ButtonPrimary>

                        </FlexGridItem>
                        <FlexGridItem hSpace="5px">
                            
                            <ButtonPrimary onClick={openModalRequestPayment}>
                                Request payment
                            </ButtonPrimary>

                        </FlexGridItem>
                    </FlexGrid>

                    <Spacer/>
                    <PaymentsRequestTable
                        height={160}
                        data={paymentRequestItems}
                        loading={paymentRequestLoading}
                        {...{
                            handleCancelPaymentRequest,
                            handleApprovePaymentRequest,
                            currencyName,
                        }}
                    />
                </div>
            </Collapse>

            <ModalChangePaymentRequestStatus
                title="Approve payment request"
                show={showModalApprovePaymentRequest}
                onClose={() => {
                    setShowModalApprovePaymentRequest(false)
                }}
                onSuccess={handleApprovePayment}
            />

            <ModalChangePaymentRequestStatus
                title="Cancel payment request"
                show={showModalCancelPaymentRequest}
                onClose={() => {
                    setShowModalCancelPaymentRequest(false)
                }}
                onSuccess={handleCancelPayment}
            />
        </>
    )
};