import React, {useEffect, useState} from "react";
import { FlexboxGrid } from "rsuite";
import {Spacer} from "../../../components/base/Spacer";
import {Alert} from "rsuite";
import {ButtonPrimary} from "../../../components/base/BaseButton";
import MiscPaymentRequestFilter from "./MiscPaymentRequestFilter";
import MiscPaymentRequestTable from "./MiscPaymentRequestTable";
import MiscPaymentRequestExportModal from "./MiscPaymentRequestExportModal";
import styled from "styled-components"
import { usePrevious } from "hooks";

export default ({
    service,
    
    data = [],
    inited = false,
    loading = false,
    dialerList,
    currencyList,
    globalSetting,
    referencesLoading,

    fetchData,
    exportFunc
}) => {

    const defaultFilter = {
        dialer_id_list: []
    };

    const defaultSelectedItems = {
        list: [], all: false
    };

    const savedFilterMinimumPayment = 0;

    const [miscPaymentsList, setMiscPaymentsList] = useState([]);

    const [filter, setFilter] = useState({...defaultFilter, minimum_payment: +savedFilterMinimumPayment});
    const [selectedItems, setSelectedItems] = useState(defaultSelectedItems);
    const [clearSelectedData, setClearSelectedData] = useState(null);

    const [showExportModal, setShowExportModal] = useState(false);

    const prevInited = usePrevious(inited);

    useEffect(() => {
        fetchData(filter);
    }, []);

    useEffect(() => {
        if (!prevInited && data.length) {
            setMiscPaymentsList(data);
        }
    }, [prevInited, data]);

    useEffect(() => {
        const filteredData = data.filter((item) => {
            return filter.dialer_id_list.includes(item.account_id) || !filter.dialer_id_list.length;
        });

        setMiscPaymentsList(filteredData);
    }, [data, filter]);

    const handleShowModalRequest = () => {
        setShowExportModal(true);
    };

    const getKeysFromSelectedItems = (items) => {
        return items && Object.keys(items).length && Object.keys(items) || [];
    };

    return (<>
        <FlexboxGrid>
            <FlexboxGrid.Item>
                <MiscPaymentRequestFilter
                    filters={filter}
                    onChangeFilters={(value) => {
                        setFilter(value);
                    }}
                    {...{
                        dialerList,
                        referencesLoading
                    }}
                />
            </FlexboxGrid.Item>
            <StyledItemAutoMargin>
                <ButtonPrimary
                    disabled={!getKeysFromSelectedItems(selectedItems.list).length}
                    onClick={handleShowModalRequest}
                >
                    Export payments
                </ButtonPrimary>
            </StyledItemAutoMargin>
        </FlexboxGrid>

        <Spacer/>

        <MiscPaymentRequestTable
            filter={filter}
            data={miscPaymentsList}
            loading={loading}
            setSelected={(value) => {
                setSelectedItems(value);
            }}
            {...{
                service,
                selectedItems,
                clearSelectedData,
                setClearSelectedData
            }}
        />

        {showExportModal && <MiscPaymentRequestExportModal
            show={showExportModal}
            data={miscPaymentsList}
            selectedList={selectedItems.list}
            currencyList={currencyList}
            globalSetting={globalSetting}
            onSuccess={() => {
                Alert.success("Payments were exported!")

                fetchData({filter});
                setSelectedItems(defaultSelectedItems);
                setShowExportModal(false);
            }}
            onClose={() => {
                setShowExportModal(false);
            }}
            exportFunc={exportFunc}
        />}
    </>);
};


const StyledItemAutoMargin = styled(FlexboxGrid.Item)`
    && {
        margin-left: auto;
    }
`;