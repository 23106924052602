import React from "react";
import {FormGroup, FormControl, HelpBlock} from "rsuite";
import styled from "styled-components";


const ContactCustomField = ({name, message, label, widthLabel, accepter, error, width=180, ...props}) => {
    return (
        <StyledFormGroup
            className={error ? "has-error" : ""}
            width={width}
        >
            <FormControl
                name={name}
                accepter={accepter}
                errorMessage={error}
                errorPlacement="topRight"
                {...props}
            />
            <HelpBlock>{error}</HelpBlock>
        </StyledFormGroup>
  );
};

export default ContactCustomField;


const StyledFormGroup = styled(FormGroup)`
    && {
        margin-bottom: 10;
        margin-right: 5;
        width: ${props => props.width}px;
    }
    
    &&.rs-form-group .rs-input {
        width: 100%;
    }

    &&.rs-form-group .rs-form-control-wrapper {
        width: 100%;
    }
`;