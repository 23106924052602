import {GAN_SUPPLIERS as PREFIX} from '../const';


const initialState = {
    loading: false,
    list: [],
    commonList: [],
};


const handlers = {

    ['SET_ITEMS_' + PREFIX]: (state, {payload: {list}}) => ({
        ...state,
        list: list,
        loading: false
    }),

    ['CLEAR_ITEMS_' + PREFIX]: (state) => ({
        ...state,
        list: [],
        loading: false
    }),

    ['SET_ITEMS_COMMON_' + PREFIX]: (state, {payload: {list}}) => {
        return {
            ...state,
            commonList: list,
            loading: false
        }
    },

    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({
        ...state,
        loading: payload
    }),

    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action)
};
