import React, {useRef, useMemo, useState} from "react";

import TableServerSort from "components/Table/TableServerSort";
import styled from "styled-components";

import m from "../../../definedMessages";

import { DEFAULT_PER_PAGE_MAX } from "const";
import { formatDateApi } from "utils";

const TrafficCDR = ({
    data,
    page,
    count,

    isCdr,
    filter,
    perPage,
    loading,

    isMobile,
    formatMessage,

    getItems
}) => {
    
    const tableRef = useRef(null);

    const defaultSort =  {column: "range", type: "asc"};

    const [sort, setSort] = useState(defaultSort);

    const columns = useMemo(() => {
        const cdrColumns = [
            {
                label: formatMessage(m.date),
                dataKey: "datetime",
                value: (({datetime}) => {
                    return <span className="table-centered__cell">
                        {formatDateApi(datetime || '')}
                    </span>
                }),
                flexGrow: 1,
                minWidth: 140,
            },
            {
                label: formatMessage(m.aNumber), 
                dataKey: "a_number", 
                value: ({a_number}) => {
                    return <span className="table-centered__cell">
                        {a_number}
                    </span>
                },
                flexGrow: 1,
                minWidth: 196,
                minWidthMobile: 160
            },
            {
                label: formatMessage(m.bNumber), 
                dataKey: "b_number", 
                value: ({b_number}) => {
                    return <span className="table-centered__cell">
                        {b_number}
                    </span>
                },
                flexGrow: 1,
                minWidth: 196,
                minWidthMobile: 160
            },
            {
                label: formatMessage(m.range), 
                dataKey: "range", 
                value: ({range}) => {
                    return <span className="table-centered__cell">
                        {range}
                    </span>
                },
                flexGrow: 2,
                minWidth: 220,
                minWidthMobile: 220
            },
            {
                label: formatMessage(m.rate), 
                dataKey: "rate_resel", 
                value: ({rate_resel, cur_name}) => {
                    return <span className="table-centered__cell">
                        {rate_resel} {cur_name}
                    </span>
                },
                flexGrow: 1,
                width: 100,
                minWidthMobile: 80
            },
            {
                label: formatMessage(m.cost), 
                dataKey: "cost_resel", 
                value: ({cost_resel, cur_name}) => {
                    const cost = parseFloat(cost_resel).toFixed(3);
                    return <span className="table-centered__cell">
                        {cost} {cur_name}
                    </span>
                },
                flexGrow: 1,
                width: 100,
                minWidthMobile: 80
            },
            {
                label: formatMessage(m.duration), 
                dataKey: "duration", 
                value: ({duration}) => {
                    return <span className="table-centered__cell">
                        {duration}
                    </span>
                },
                flexGrow: 1,
                width: 80,
                minWidthMobile: 80
            },
            {
                label: formatMessage(m.subaccontName), 
                dataKey: "subacc_name", 
                value: ({subacc_name}) => {
                    return <span className="table-centered__cell">
                        {subacc_name}
                    </span>
                },
                flexGrow: 1,
                minWidth: 200,
                minWidthMobile: 160
            },
            {
                label: formatMessage(m.subaccountRate), 
                dataKey: "rate_subacc", 
                value: ({rate_subacc, cur_name}) => {
                    return <span className="table-centered__cell">
                        {rate_subacc} {cur_name}
                    </span>
                },
                flexGrow: 1,
                width: 100,
                minWidthMobile: 80
            },
            {
                label: formatMessage(m.subCost), 
                dataKey: "cost_subacc", 
                value: ({cost_subacc, cur_name}) => {
                    const cost = parseFloat(cost_subacc).toFixed(3);
                    return <span className="table-centered__cell">
                        {cost} {cur_name}
                    </span>
                },
                flexGrow: 1,
                width: 100,
                minWidthMobile: 80
            }
        ];
        const cdrReportColumns = [
            {
                label: formatMessage(m.aNumber),
                dataKey: "a_number", 
                value: ({a_number}) => {
                    return <span className="table-centered__cell">
                        {a_number}
                    </span>
                },
                showOnGroup: "cli",
                flexGrow: 1,
                minWidth: 196,
                minWidthMobile: 160
            },
            {
                label: formatMessage(m.bNumber), 
                dataKey: "b_number", 
                value: ({b_number}) => {
                    return <span className="table-centered__cell">
                        {b_number}
                    </span>
                },
                showOnGroup: "phone",
                flexGrow: 1,
                minWidth: 196,
                minWidthMobile: 160
            },
            {
                label: formatMessage(m.range), 
                dataKey: "range", 
                value: ({range}) => {
                    return <span className="table-centered__cell">
                        {range}
                    </span>
                },
                showOnGroup: "name",
                flexGrow: 2,
                minWidth: 220,
                minWidthMobile: 220
            },
            {
                label: formatMessage(m.duration), 
                dataKey: "duration_sum", 
                value: ({duration_sum}) => {
                    return <span className="table-centered__cell">
                        {duration_sum}
                    </span>
                },
                flexGrow: 1,
                width: 100,
                minWidthMobile: 80
            },
            {
                label: formatMessage(m.calls), 
                dataKey: "calls", 
                value: ({calls}) => {
                    return <span className="table-centered__cell">
                        {calls}
                    </span>
                },
                flexGrow: 1,
                width: 100,
                minWidthMobile: 80
            },
            {
                label: formatMessage(m.cost), 
                dataKey: "cost_resel", 
                value: ({cost_sum_resel, cur_name}) => {
                    return <span className="table-centered__cell">
                        {cost_sum_resel} {cur_name}
                    </span>
                },
                flexGrow: 1,
                width: 100,
                minWidthMobile: 80
            },
            {
                label: formatMessage(m.subaccount), 
                dataKey: "subacc_name",
                value: ({subacc_name}) => {
                    return <span className="table-centered__cell">
                        {subacc_name}
                    </span>
                },
                showOnGroup: "subacc",
                flexGrow: 1,
                minWidth: 200,
                minWidthMobile: 160
            },
            {
                label: formatMessage(m.subCost), 
                dataKey: "cost_subacc", 
                value: ({cost_sum_subacc, cur_name}) => {
                    return <span className="table-centered__cell">
                        {cost_sum_subacc} {cur_name}
                    </span>
                },
                flexGrow: 1,
                width: 100,
                minWidthMobile: 80
            },
            {
                label: formatMessage(m.margin), 
                dataKey: "cost_sum", 
                value: ({margin, cur_name}) => {
                    const marginParsed = parseFloat(margin).toFixed(3);
                    return <span className="table-centered__cell">
                        {marginParsed} {cur_name}
                    </span>
                },
                flexGrow: 1,
                width: 100,
                minWidthMobile: 80
            },
        ];
        const filteredCdrReportColumns = cdrReportColumns.filter((column) => {
            if ("showOnGroup" in column) {
                if (filter.group_list 
                    && filter.group_list.length
                    && filter.group_list.includes(column.showOnGroup)
                ) {
                    return true;
                }

                return false;
            }

            return true;
        });

        return isCdr ? cdrColumns : filteredCdrReportColumns;
    }, [isCdr, data]);

    return <>
        <StyledTable
            shouldUpdateScroll={false}
            ref={tableRef}
            data={data}
            // height="50%"
            loading={loading}
            columns={columns}
            originalColumns={columns}
            count={count}
            per_page={perPage}
            perPageMaximum={DEFAULT_PER_PAGE_MAX}
            page={page}

            row_key="number"

            ispagination

            getItems={getItems}

            onSort={(column, type) => setSort({column, type})}
            isMobile={isMobile}
        />

    </>
};

export default TrafficCDR;

const StyledTable = styled(TableServerSort)`
    && {
        .table-centered__cell {
            line-height: 30px;
        }

        .rs-table-cell-content {
            padding: 0 0 0 10px !important;
        }
        .rs-table-cell-content > .rs-checkbox {
            top: 0px !important;
        }
        
        .rs-table-row-header .rs-table-cell-content {
            line-height: 40px;
        }

        margin-bottom: 20px;
    }
`;