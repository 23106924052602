import {useEffect, useState} from "react";

export default () => {

    const [resizedHeight, setHeight] = useState(window.innerHeight);

    useEffect(() => {

        const handleResize = () => {
            setHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return resizedHeight;
}