import React, { useMemo, useState, useEffect } from "react";

import Page from "components/base/Page";
import PageHeader from "components/base/PageHeader";

import AccessListTable from "./AccessListTable";
import AccessListFilter from "./AccessListFilter";

import { useIntl } from "react-intl";
import { compareObjects } from "utils";
import { ButtonPrimary } from "components/base/BaseButton";
import { Spacer } from "components/base/Spacer";
import { FilterSectionFlexboxGrid, StyledItemFitWidth, StyledItemAutoMargin } from "components/base/FitContainers";

import {useWindowWidth} from "hooks";

import m from "definedMessages";


const defaultFilterValue = {
    filter_cli: "",
    filter_phone: ""
};


const AccessList = ({
    data,
    page,
    count,
    perPage,
    loading,
    accountJoinList,

    getList,
    exportAccessList
}) => {

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 767;
    const issueWidth = windowWidth < 960;

    const {formatMessage} = useIntl();

    const accountData = useMemo(() => {
        return accountJoinList.find((account) => {
            return account.service === "voice";
        })
    }, [accountJoinList]);

    const defaultSort =  {column: "access_origin", type: "asc"};

    const [filterState, setFilterState] = useState(defaultFilterValue);
    const [sort, setSort] = useState(defaultSort);

    useEffect(() => {
        update();
    }, [accountData]);

    useEffect(() => {
        update();
    }, [filterState]);

    const update = () => {
        if (accountData && accountData.id) {
            getList(accountData.id, filterState, 1, perPage, sort);
        }
    };

    const getItems = (_page, _perPage, _sort) => {
        getList(accountData.id, filterState, _page, _perPage, _sort);
    };


    return <Page>
        <PageHeader title={formatMessage(m.accessList)} showService={false}></PageHeader>
        <FilterSectionFlexboxGrid>
            <StyledItemFitWidth isMobile={isMobile}>
                <AccessListFilter
                    filter={filterState}
                    disabled={loading}
                    issueWidth={issueWidth}

                    onChangeFilter={setFilterState}
                    formatMessage={formatMessage}
                />
            </StyledItemFitWidth>
            <StyledItemAutoMargin isMobile={isMobile}>
                <ButtonPrimary
                    className="export-button"
                    buttonStyle="default"
                    disabled={loading}
                    onClick={() => {
                        if (accountData && accountData.id) {
                            exportAccessList(accountData.id, filterState);
                        }
                    }}
                >
                    {formatMessage(m.exportToCsv)}
                </ButtonPrimary>
            </StyledItemAutoMargin>
        </FilterSectionFlexboxGrid>
        <AccessListTable
            data={data}
            sort={sort}
            page={page}
            count={count}
            perPage={perPage}
            loading={loading}
            isMobile={isMobile}

            setSort={setSort}
            getItems={getItems}

            formatMessage={formatMessage}
        />
    </Page>
};

export default AccessList;