import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import {ROUTING_SET_LOADING, ROUTING_SET_LIST} from './actionTypes';
import {
    ROUTING_GET_LIST_API, ROUTING_CREATE_API, ROUTING_MODIFY_API, ROUTING_REMOVE_API
} from '../const/apiMethods';

const setLoading = createAction(ROUTING_SET_LOADING);
const setSettingList = createAction(ROUTING_SET_LIST);

export const getRoutingList = () => async (dispatch) => {
    dispatch(setLoading(true));

    return await api(ROUTING_GET_LIST_API, {})
        .then((response) => {
            dispatch(setSettingList({list: response["sms.routing_list"]}));
        })
        .finally(() => dispatch(setLoading(false)));
};

export const onCreateRoutingItem = (data) => async (dispatch) => {
    return await api(ROUTING_CREATE_API, {...data});
};

export const onModifyRoutingItem = (data) => async (dispatch) => {
    return await api(ROUTING_MODIFY_API, {...data})
};

export const onRemoveRoutingItem = (key) => async (dispatch) => {
    return await api(ROUTING_REMOVE_API, {routing_key: key});
};