import { useWindowWidth } from 'hooks';
import React, { useState } from 'react';
import { Checkbox, DatePicker, Form } from 'rsuite';
import {CustomModal} from "../../components/base";

const ModalAccessListFilterRemove = (
    {
        show,
        loading,
        revokedDataInfo,
        
        onClose,
        onSubmit,
    }
) => {

    
    const windowWidth = useWindowWidth();

    // const [fromDate, setFromDate] = useState(null);
    // const [withDate, setWithDate] = useState(false);


    const handleClose = () => {
        onClose();
    }


    const handleSubmit = () => {
        onSubmit();
    }


    return (
        <CustomModal
            showFooter
            show={show}
            title={"Mass Revoke"}
            loading={loading}
            confirmButtonText="Revoke"

            onConfirm={handleSubmit}
            onClose={handleClose}
            onExited={() => {
                // setFromDate(null);
                // setWithDate(false);
            }}
        >
            <p>Selected <b>{revokedDataInfo.numbers}</b> numbers from <b>{revokedDataInfo.accounts}</b> accounts will be revoked</p>
            
            {/* <Form
                style={{display: 'flex', justifyContent: 'center'}}
                layout="inline"
            >
                
                <div
                    className="mt-4"
                    style={{
                        textAlign: windowWidth >= 600 
                        ? 'center' 
                        : 'left'
                    }}
                >
                    <Checkbox
                        inline 
                        className="mr-2" 
                        onChange={(v, checked) => {
                            setWithDate(checked);

                            if (!checked) {
                                setFromDate(null);
                            }
                        }}
                    >
                        From date
                    </Checkbox>
                    
                    <DatePicker
                        placeholder="Enter Date"
                        disabled={!withDate}
                        onChange={(date) => {
                            date && setFromDate(date);
                        }}
                        ranges={[
                            {
                                label: 'Today',
                                value: new Date()
                            },
                            {
                                label: 'Tomorrow',
                                value: new Date( new Date().setDate(new Date().getDate() + 1) )
                            }
                        ]}

                        // mobile
                        inline={windowWidth < 600 && withDate}
                        oneTap={windowWidth < 600 && withDate}
                    />
                </div>
            </Form> */}

        </CustomModal>
    )
};

export default ModalAccessListFilterRemove;