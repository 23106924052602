import React, {useState} from 'react';
import {Alert, Checkbox, DatePicker, SelectPicker} from 'rsuite';
import ModalSendApi from '../../../components/Modal/ModalSendApi'
import ApiRequest from '../../../components/ApiRequest'
import {getServiceSmsPrefix, toStartDayUTCTime} from 'utils';
import CustomDatePicker from "../../../components/Form/CustomDatePicker";
import {useWindowWidth} from "../../../hooks";


export default ({service=true, initialValues, target, getCount, entity, trunks, update, ...props}) => {
    const [trunkToModeId, setTrunkToMoveId] = useState(null);

    const windowWidth = useWindowWidth();

    const numbersCount = getCount();

    const moveToTrunkMethod = getServiceSmsPrefix(service, "trunk_number__filter:move_to_trunk");

    return (
        <ModalSendApi
            {...props}
            title="Move Number"
            successText="Yes"
            extraDisabled={!trunkToModeId}
        >
            <ApiRequest
                method={moveToTrunkMethod}
                checkResultKey = 'trunk_numbers'
                target = {{
                    ...target, 
                    [getServiceSmsPrefix(service, 'trunk1_id')]: trunkToModeId
                }}
                update = { ({trunk_numbers}) => {
                    update();
                    Alert.success(`Moved ${trunk_numbers} numbers`)
                }}
                data={{
                    ...initialValues
                }}
            >
                <div>
                    <p>Selected <strong>{numbersCount}</strong> {numbersCount === 1 ? "number" : "numbers"} from this trunk will be moved. Do you really want to do it?</p>
                    <div
                        style={{textAlign: windowWidth >= 600 ? 'center' : 'left'}}
                        className="mt-5"
                    >
                        <span className="mr-4">Move to trunk</span>
                        <SelectPicker
                            cleanable={false}
                            data={trunks}
                            placeholder={'Select trunk to move'}
                            valueKey="id"
                            labelKey="name"
                            onChange={(trunkId) => setTrunkToMoveId(trunkId || null)}
                            style={{
                                width: 250
                            }}
                        />
                    </div>
                </div>
            </ApiRequest>
        </ModalSendApi>
    );
};