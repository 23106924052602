import {POSTBACK_LOGS as PREFIX, POSTBACK_LOGS, SET_POSTBACK_LOGS_LIST, DEFAULT_PER_PAGE_MEDIUM} from '../const/';
import {POSTBACK_LOGS_GET_LIST_API} from '../const/apiMethods';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';
import {toEndDayUTCTime, toStartDayUTCTime} from "utils";

const setLoading = createAction('SET_LOADING_'+ POSTBACK_LOGS);
const setPostbackLogsList = createAction(SET_POSTBACK_LOGS_LIST);


export const getPostbackList = (account_id, filter, page, per_page = DEFAULT_PER_PAGE_MEDIUM) => (dispatch) => {
    dispatch(setLoading(true));

    if (filter.range && filter.range.length) {
        filter['start_date'] = toStartDayUTCTime(filter.range[0]);
        filter['end_date'] = toEndDayUTCTime(filter.range[1]);

        delete filter.range;
    }

    localStorage.setItem(PREFIX + '_per_page', per_page);

    api(POSTBACK_LOGS_GET_LIST_API, {
        target: {account_id},
        filter,
        page,
        per_page
    })
        .then( ({ postback_list, postback_count }) => {
            dispatch(setPostbackLogsList({
                items: postback_list,
                count: postback_count,
                page,
                per_page
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};