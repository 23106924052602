import React from 'react';
import {Form, Icon, IconButton, Schema, Table as _Table} from 'rsuite';
import Checkbox from '../../../../../hoc/Checkbox';
import WZ from '../../../../../components/Filters/WZ';
import DE from '../../../../../components/Filters/DE';
import SDE from '../../../../../components/Filters/SDE';
import NumberField from '../../../../../components/Form/NumberField';
import MyForm from '../../../../../components/MyForm';
import {api} from '../../../../../api/loginRoutes';
import Table from '../../../../../components/Table';
import {pipe, getTargetByService} from '../../../../../utils/helpers';
import RateField from '../../../../../components/Form/RateField';
import {ALLOCATION_NUMBER_LIMIT_PANEL} from '../../../../../const';
import {useWindowWidth} from "../../../../../hooks";
import {Collapse} from "react-collapse";
import styled from 'styled-components';
import {methodByService} from '../../../../../const/apiMethods';


const {Column, HeaderCell, Cell} = _Table;
const {NumberType} = Schema.Types;

const NUMBER_LIMIT = 1000;

const subdestFilterDefaultValue = {
    wz_key: null,
    de_key: null,
    sde_key: null,
    rate_from: null,
    rate_to: null
};

const columns = [
    {
        label: 'Subdestination',
        dataKey: 'sde_name',
        minWidth: 280,
        flexGrow: 3
    }
];

const columnsMobile = [
    {
        label: 'Subdestination',
        dataKey: 'sde_name',
        minWidth: 140,
        flexGrow: 1
    }
];


export default ({
    activeTab, 
    onSelect, 
    service,
    target, 
    sp_key,
    show,

    activeTrunk,

    showResult, 
    setHideBufferButtons,

    setSummaryState,
    setCsvContent,
    setTotalNumbersForced,

    account_id,
    defaultAccountId,
    spAuth, 
    setNotAllocatedNumbers,
    isMobile,

    clearSelectedData,
    setClearSelectedData,

    setTransactionId,
    service_plan_list,
    ...props
}) => {

    const [valueForm, setValueForm] = React.useState({random_number: true, ranges: 1, numbers: 10});
    const [showFilters, setShowFilters] = React.useState(false);
    const [subdestFilterState, setSubdestFilterState] = React.useState(subdestFilterDefaultValue);

    const [data, setData] = React.useState({
        current: [],
        loading: true,
        state: []
    });

    const windowWidth = useWindowWidth();

    React.useEffect(() => {
        if (clearSelectedData) {
            setClearSelectedData(false);
        }
    }, [clearSelectedData]);

    React.useEffect(() => {
        setData({
            ...data,
            loading: true,
        });

        const currentAccountId = account_id || defaultAccountId;

        if (!currentAccountId) {
            setData({
                current:  [],
                loading: false,
                state: [],
            });
            return;
        }

        const availableSpKeyList = service_plan_list.map(item => item.sp_key);

        if (availableSpKeyList.includes(sp_key)) {
            api('account_price__get_fullprice', {sp_key, target: {account_id: currentAccountId || undefined}})
                .then(
                    (r) => {
                        setData({
                            current: r?.fullprice_for_account || [],
                            loading: false,
                            state: r?.fullprice_for_account || [],
                        });
                    }
                );
        }
            // sp_key
    }, [sp_key, account_id, defaultAccountId]);

    const clearSubdestFilters = () => {
        setSubdestFilterState(subdestFilterDefaultValue);
    };

    React.useEffect(() => {
        clearSubdestFilters();
    }, [activeTrunk]);

    const onChangePrice = (sde_key, price) => {
        const stateItems = data.state.map(
            row => row.sde_key === sde_key ? {...row, checked: true, rate: price} : row
        );
        const currentItems = data.current.map(
            row => row.sde_key === sde_key ? {...row, checked: true, rate: price} : row
        );

        setData({
            ...data,
            current: currentItems,
            state: stateItems,
        });
    };
    const onChangeChecked = (selected) => {
        const stateItems = data.state.map(
            row => selected.list.includes(row.sde_key) ? {...row, checked: true} : {...row, checked: false}
        );
        const currentItems = data.current.map(
            row => selected.list.includes(row.sde_key) ? {...row, checked: true} : {...row, checked: false}
        );
        setData({
            ...data,
            current: currentItems,
            state: stateItems,
        });
    };
    const onChangeFilters = (filters) => {
        setData({
            ...data,
            state: pipe(data.current, filters, FiltersFuncs)
        });
    };

    const handleFormChange = async (newFormValues) => {
        setValueForm(newFormValues);
    };

    const model = {
        numbers: NumberType()
            .isRequired('Required')
            .isInteger('Only whole numbers')
            .max(NUMBER_LIMIT, `The maximum of this field is ${NUMBER_LIMIT}`)
    };


    const handleUpdateNew = ({trunk_number_transaction, not_allocated_numbers}) => {
        if (trunk_number_transaction) {
            const transactionId = trunk_number_transaction.id;
            const summaryInfo = {...trunk_number_transaction};

            setSummaryState(summaryInfo);
            setTransactionId(transactionId);

            setTotalNumbersForced(summaryInfo.numbers);

            setHideBufferButtons(true);
            setNotAllocatedNumbers(not_allocated_numbers);
            showResult();
        }
    };

    return (<>

        <div>
            {isMobile &&
                <div style={{paddingBottom: 10}}>
                    <IconButton
                        appearance={showFilters ? 'primary' : 'default'}
                        icon={<Icon icon="filter"/>}
                        onClick={() => setShowFilters(!showFilters)}
                    >
                    </IconButton>
                </div>
            }
            <Collapse isOpened={!isMobile || showFilters}>
                <FiltersSubdestination 
                    setFilterState={setSubdestFilterState}
                    filterState={subdestFilterState}
                    onChange={onChangeFilters}
                />
            </Collapse>
        </div>

        <StyledTable
            virtualized
            shouldUpdateScroll={false}
            data={data.state}
            height="30%"
            loading={data.loading}
            row_key="sde_key"
            setSelected={onChangeChecked}
            isselected
            stateChecked
            ActionCell={ActionCell(onChangePrice)}

            width={isMobile ? windowWidth - 50 : 'auto'}
            columns={isMobile ? columnsMobile : columns}
            wordWrap={isMobile}
            rowHeight={isMobile ? 46 : 30}
            clearSelected={clearSelectedData}
        />

        <MyForm
            activeTrunk={activeTrunk}
            target={getTargetByService(target, service)}
            method={methodByService['mass'][service]}
            checkResultKey='price_range_number_list'
            onChange={handleFormChange}
            update={handleUpdateNew}
            {...props}
            formValue={valueForm}
            addData={{
                list: data.current
                    .filter(x => x.checked)
                    .map(x => ({sde_key: x.sde_key, rate: +x.rate}))
            }}
            model={model}

            deepCloned={true}
           >

            <NumberField
                name="ranges"
                label="Ranges per subdestination"
                // validationKey="numbers"
                max={Infinity}
                min={0}
                step={1}
                useBr={isMobile ? false : true}
            />

            <NumberField
                name="numbers"
                label="Number per range"
                max={NUMBER_LIMIT}
                min={0}
                step={1}
            />
            <Checkbox name="random_number" defaultChecked>Random order</Checkbox>
        </MyForm>
    </>);
}


const FiltersFuncs = {
    sde_key: (x, f) => x.sde_key === f,
    de_key: (x, f) => x.de_key === f,
    wz_key: (x, f) => x.wz_key === f,
    rate_from: (x, f) => x.rate >= +f,
    rate_to: (x, f, allfilters) => {
        // debugger;
        return (+f ? x.rate <= +f : true);
    },
};

const FiltersSubdestination = ({filterState, setFilterState, onChange}) => {
    return (
        <Form
            layout="inline"
            formValue={filterState}
            onChange={(value) => {
                setFilterState(value);
                onChange(value);
            }}
        >
            <WZ/>
            <DE/>
            <SDE/>
            <br/>
            <RateField label={'Rate range from'} name="rate_from" value={filterState?.rate_from}/>
            <RateField label={'to'} name="rate_to" value={filterState?.rate_to}/>
        </Form>
    );
};

const ActionCell = (onChange) => (
    <Column width={150} minWidth={140}>
        <HeaderCell>Price</HeaderCell>
        <Cell>
            {rowData => (
                <Form>
                    <StyledNumberField
                        key={rowData.sde_key}
                        max={10}
                        min={0}
                        size="sm"
                        placeholder="0"
                        value={rowData.rate}
                        calculated={true}
                        onChange={value => onChange(rowData.sde_key, value)}
                    />
                </Form>
            )}
        </Cell>
    </Column>
);

const StyledTable = styled(Table)`
    @media (max-width: 767px) {
        font-size: 12px;
    }
    .rs-table-cell-content {
        padding: 0 10px !important;
    }
`;

const StyledNumberField = styled(NumberField)`
    position: relative;
    width: 120px;

    @media (min-width: 1200px) {
        //top: -9px;
        width: 100px;
    }

    &.rs-input-group {
        width: 100px !important;

        @media (min-width: 767px) {
            width: 135px !important;
        }
    }
`;