import React, {useState, useEffect, useRef} from 'react';
import {Form, Schema, FormControl, Checkbox} from 'rsuite';
import { NumberField } from '../../../../components';


const {NumberType} = Schema.Types;


const formModel = Schema.Model({
    numbers: NumberType()
        .min(0, `The minimum of this field is ${0}`)
});


export default ({
    updateFormRef,

    formValue,
    setFormValue
}) => {

    const formRef = useRef(null);


    return (

        <Form
            layout="inline"
            model={formModel}
            ref={(ref) => {
                updateFormRef(ref);
                formRef.current = ref;
            }}
            formValue={formValue}
            onChange={setFormValue}
        >

            <NumberField
                name="numbers"
                label="Numbers per group"
                min={0}
                step={1}
            />

            <FormControl
                accepter={CustomCheckbox}
                name="random_number"
            >
                Random numbers
            </FormControl>

        </Form>
    );
};


const CustomCheckbox = ({children, defaultValue = false, value, onChange, ...props}) => {
    const [_value, _setValue] = useState(defaultValue);

    useEffect(() => {
        _setValue(value);
    }, [value]);

    return <Checkbox
        value={_value}
        checked={_value}
        onChange={() => {
            _setValue(!_value);
            onChange && onChange(!_value);
        }}
        {...props}
    >
        {children}
    </Checkbox>
};