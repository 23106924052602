import React, {useState} from "react";
import {Input, Checkbox, SelectPicker, Form, FormControl} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import FieldDataRange from "../../components/Form/FieldDataRange";
import {debounce} from "../../utils";


export default ({
    defaultFilter,
    dialerList,
    supplierList,
    onChangeFilter,
    mccMncMatchList,
    exceptionList
}) => {

    return (
        <>
            <Form
                formDefaultValue={defaultFilter}
                onChange={debounce((value) => {
                    onChangeFilter(value);
                }, 1000)}
            >
                <FlexGrid align="middle">
                    <FlexGridItem>
                        {/* <Checkbox
                            name="is_exception"
                            // checked={isException}
                            defaultChecked={false}
                            onChange={(_, check) => {
                                setIsException(check);
                                onChangeFilter((value) => {
                                    return {...value, is_exception: check}
                                });
                            }}
                        >
                            Exception
                        </Checkbox> */}
                        <FormControl
                            accepter={SelectPicker}
                            cleanable
                            searchable={false}
                            data={exceptionList}
                            valueKey="value"
                            labelKey="name"
                            name="is_exception"
                            placeholder="Exception"
                            style={{width: 130}}
                        />
                    </FlexGridItem>

                    <FlexGridItem>
                        <FormControl
                            accepter={SelectPicker}
                            cleanable
                            searchable
                            data={supplierList}
                            valueKey="supplier_id"
                            labelKey="name"
                            name="supplier_id"
                            placeholder="Supplier"
                            style={{width: 200}}
                        />
                    </FlexGridItem>

                    <FlexGridItem>
                        <FormControl
                            accepter={Input}
                            name="senderid"
                            placeholder="Sender ID"
                            style={{width: 180}}
                        />
                    </FlexGridItem>

                    <FlexGridItem>
                        <FormControl
                            accepter={Input}
                            name="mcc_mnc"
                            placeholder="MCC/MNC"
                            style={{width: 160}}
                        />
                    </FlexGridItem>

                    <FlexGridItem>
                        <FormControl
                            cleanable
                            name="dialer_id"
                            accepter={SelectPicker}
                            data={dialerList}
                            valueKey="id"
                            labelKey="name"
                            placeholder="Dialer"
                            style={{width: 200}}
                        />
                    </FlexGridItem>

                </FlexGrid>
            </Form>
        </>
    )
};