import {
    SET_DIALER_SERVICE,
    SET_ACCOUNT_DIALER_INFO,
    SET_ACCOUNT_READY_FOR_PAYMENT
} from '../const';
import {
    ACCOUNT_GET_DIALER_API,
    ACCOUNT_GET_TEST_DIALER_API
} from "../const/apiMethods";
import {createAction} from  './defaults';
import {api} from "../api/loginRoutes";


const setDialerInfo = createAction(SET_ACCOUNT_DIALER_INFO);
const setAccountReadyForPaymentAction = createAction(SET_ACCOUNT_READY_FOR_PAYMENT);
const setDialerServiceAction = createAction(SET_DIALER_SERVICE);

export const setReadyForPayment = (value) => (dispatch) => {
    dispatch( setAccountReadyForPaymentAction(value.toFixed(2)) );
};

export const getAccountDialerInfo = () => (dispatch) => {
    const authInfo = localStorage.getItem('userInfo');
    const isTest = authInfo ? JSON.parse(authInfo).session.is_test : false;
    const request = !isTest ? ACCOUNT_GET_DIALER_API : ACCOUNT_GET_TEST_DIALER_API;
    
    api(request)
        .then(response => {
            if (response !== undefined) {
                const { account = {}, account_join = {} } = response;
                const accountJoinList = account_join?.account_list || account?.account_list || [];
                const activeAccountJoinList = accountJoinList.length ? accountJoinList.filter(account => account.active) : [];

                const readyForPayment = activeAccountJoinList.reduce((result, account) => {
                    if (account) {
                        const accountService = account?.service;
                        const accountForPayment = account?.available_for_payment;
                        result[accountService] = accountForPayment;
                    }
                    return result;
                }, {});

                if (!isTest) {
                    dispatch( setDialerInfo({
                        ...account_join,
                        accountJoinList: activeAccountJoinList,
                        billingCurrencyList: account_join?.billing_currency_list,
                        billingServiceList: account_join?.billing_service_list,
                        readyForPayment
                    }) );
                } else {
                    dispatch( setDialerInfo({
                        ...account,
                        accountJoinList: activeAccountJoinList,
                        billingCurrencyList: account?.billing_currency_list,
                        billingServiceList: account?.billing_service_list,
                        readyForPayment
                    }) );
                }
            }
        });
};

export const setDialerService = (service) => (dispatch) => {
    dispatch( setDialerServiceAction(service) );
};