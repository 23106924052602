import React, {useRef, useState} from 'react';
import {Form, Schema, InputNumber, FormControl, ControlLabel, Checkbox} from "rsuite";
import {SCREEN_MEDIA} from "const";
import CustomField from "../../components/Form/CustomField";
import styled, {css} from "styled-components";
import {useWindowWidth} from "../../hooks";
import {CustomModal} from "../../components/base";


const {StringType} = Schema.Types;

const formModel = Schema.Model({
    comment: StringType()
        .maxLength(200, `Max length is 200`)
});


export default ({
    show,
    maxWidth = 600,
    formValue,
    setFormValue,
    supplierData,
    loading,

    onClose,
    onConfirm,

    ...props
}) => {

    const formRef = useRef(null);
    const windowWidth = useWindowWidth();

    const handleSubmit = () => {
        if (!formRef.current.check()) {
            return;
        }

        onConfirm();
    };

    return (
        <CustomModal
            show={show}
            title={`Modify supplier ${supplierData?.supplier_name}`}
            width={windowWidth > maxWidth ? maxWidth : windowWidth}
            showFooter={true}
            loading={loading}
            focusTarget="close"
            onClose={onClose}
            onConfirm={handleSubmit}
            {...props}
        >

            <StyledForm
                ref={formRef}
                model={formModel}
                formValue={formValue}
                onChange={setFormValue}
            >

                <StyledField>
                    <ControlLabel>Comment</ControlLabel>
                    <FormControl
                        name="comment"
                        placeholder="Enter your comment here..."
                        componentClass="textarea"
                        style={{height: 140}}
                    />
                </StyledField>

                <StyledField>
                    <ControlLabel>Timeless access list</ControlLabel>
                    <FormControl
                        accepter={Checkbox}
                        defaultChecked={supplierData?.access_list_timeless}
                        onChange={(_, value) => {

                            setFormValue({
                                ...formValue,
                                access_list_timeless: value
                            })
                        }}
                    />
                </StyledField>

                <hr/>

                <FormRow>

                    {supplierData?.price_percent_payment_terms_list && supplierData?.price_percent_payment_terms_list.map(item => {
                        return (
                            <FormCol>
                                <StyledField>
                                    <ControlLabel>Rate percent {item.payment_terms_name + ' (%)'}</ControlLabel>
                                    <FormControl
                                        accepter={InputNumber}
                                        errorPlacement={'topStart'}
                                        name={`rate_percent.${item.pt_key}`}
                                        min={0}
                                        max={100}
                                        style={{width: 90}}
                                    />
                                </StyledField>
                            </FormCol>
                        )
                    })}

                </FormRow>

            </StyledForm>

        </CustomModal>
    )
}


const StyledForm = styled(Form)`
    .rs-form-group {
        display: flex;
        align-items: center;
    }
    
    .rs-control-label {
        margin-right: 20px;
        margin-bottom: 0;
        width: 100px;
    }
    
    .rs-picker-select {
        width: 100%;
    }
`;


const FormRow = styled.div`
    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
        flex-wrap: wrap;
    }
`;


const FormCol = styled.div`

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        padding-left: 10px;
        padding-right: 10px;
        width: 50%;

        &:nth-child(2n - 1) {
            padding-left: 0
        }

        &:nth-child(2n) {
            padding-right: 0
        }
    }
`;


const StyledField = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
    }

    .rs-control-label {
        flex-shrink: 0;
        align-self: center;
        padding-right: 10px;
        width: 100%;
        margin-right: 0;

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            width: 160px;
        }
    }

    .rs-input-number {

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            max-width: 90px;
        }
    }

    ${props => props.isCheckbox && css`
        display: flex;
        
        @media (max-width: ${SCREEN_MEDIA.sm.max}px) {

            .rs-form-control-wrapper {
                width: auto;
            }

            .rs-control-label {
                width: auto;
                order: 1;
            }
        }
    `}
`;