import React from "react";
import {Whisper, Tooltip, Icon} from "rsuite/es";
import styled from "styled-components";
import {useIntl} from "react-intl";
import m from "../../definedMessages";

const TemplateWhisper = () => {
    const {formatMessage} = useIntl();

    return (<Whisper 
        placement="auto" 
        trigger="hover" 
        speaker={<StyledTooltip>
            {formatMessage(m.useInTemplateRandomDigit)}
        </StyledTooltip>}
        >
        <Icon icon="question-circle2"/>
    </Whisper>)
};

export default TemplateWhisper;

const StyledTooltip = styled(Tooltip)`
    &&.rs-tooltip .rs-tooltip-inner {
        text-align: left !important;
    }
`;