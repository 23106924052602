import React, {useState, useEffect} from "react";
import {Button, Form, Checkbox, Icon, SelectPicker, Table, Input, CheckboxGroup, Schema} from "rsuite";
import styled, {css} from "styled-components";
import ModalRemoveRouting from "./ModalRemoveRouting";
import BaseTable from "../../components/base/BaseTable";
// import Checkbox from '../../hoc/Checkbox'
import {hasErrorObject} from "../../utils";

const {Cell, Column, HeaderCell} = Table;

const {StringType, NumberType} = Schema.Types;

const routingModel = Schema.Model({
    senderid: StringType()
        .minLength(1, 'The minimum is 1 character')
        .maxLength(40, "The maximum is only 40 character"),
    mcc_mnc: StringType()
        .pattern(/^[0-9][\d]*$/, 'The mcc and mcc must contain only digits')
        // .pattern(/^\d+$/, "Only numbers")
        .maxLength(6, "The maximum is only 6 character"),
    min_rate: NumberType()
        .min(0, 'Minimum 0')
        .max(10, 'Maximum 10')
        .isRequired('Required')
});


export default ({
    dataList = [],
    dialerList = [],
    supplierList = [],
    mccMncMatchList,
    exceptionList,

    loading,
    service,

    getList,

    handleRowCreate,
    handleRowModify,
    handleRowRemove,
    ...props
}) => {
    const [forms, setForms] = useState(new Map());
    const [rowsLoading, setRowsLoading] = useState([]);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [removeData, setRemoveData] = useState(null);
    const [removeDataLoading, setRemoveDataLoading] = useState(null);

    const changeForm = (name, value, routing_key) => {
        const formsCopy = new Map(forms);
        const currentForm = forms.get(routing_key);
        let newValue;
        if (value instanceof Object && value.hasOwnProperty(name)) {
            newValue = {[name]: value[name]}
        } else {
            newValue = {[name]: value};
        }

        formsCopy.set(routing_key, {...currentForm, ...newValue});
        setForms(formsCopy);
    };

    const modifyRow = async (rowData) => {
        setRowsLoading([...rowsLoading, rowData.routing_key]);
        const formValue = {...forms.get(rowData.routing_key)};
        const {supplier_id, dialer_id, ...restValues} = formValue;
 
        const checkedForm = routingModel.check(restValues);

        if (hasErrorObject(checkedForm) || restValues.min_rate === null) {
            setRowsLoading( rowsLoading.filter(item => item !== rowData.routing_key) );
            return;
        }

        const params = {target: {supplier_id, dialer_id}, ...restValues, senderid: restValues?.senderid ? restValues.senderid : "", min_rate: parseFloat(restValues.min_rate)};
        
        const res = await handleRowModify(params);

        if (!res) {
            setRowsLoading( rowsLoading.filter(item => item !== rowData.routing_key) );
            return;
        }
        if (!res.routing_key) {
            const formsCopy = new Map(forms);
            if (forms.has(rowData.routing_key)) {
                formsCopy.delete(rowData.routing_key);
            }
            setForms(formsCopy);
            setRowsLoading( rowsLoading.filter(item => item !== rowData.routing_key) );
            return;
        }
        getList().then(() => {
            setItemEditing(rowData);
            setRowsLoading( rowsLoading.filter(item => item !== rowData.routing_key) );
        });

        return;
    };

    const deleteRow = async () => {
        setRemoveDataLoading(true);
        const res = await handleRowRemove(removeData.routing_key);
        if (res) {
            await getList();
            setItemEditing(res.routing_key);
            getList()
                .then(res => {
                    setRemoveDataLoading(false);
                    setShowRemoveModal(false);
                    setRemoveData(null);
                });
        }
    };

    const setItemEditing = (rowData) => {
        const formsCopy = new Map(forms);
        if (forms.has(rowData.routing_key)) {
            formsCopy.delete(rowData.routing_key);
        } else {
            formsCopy.set(rowData.routing_key, {
                is_exception: rowData.is_exception,
                supplier_id: rowData.supplier_id,
                senderid: rowData.senderid,
                mcc_mnc: rowData.mcc_mnc,
                mcc_mnc_match_like: rowData.mcc_mnc_match_like,
                dialer_id: rowData.dialer_id,
                min_rate: rowData.min_rate,
                routing_key:  rowData.routing_key
            })
        }
        setForms(formsCopy);
    };

    return (
        <>
            <StyledTable
                className={'tableFilters'}
                shouldUpdateScroll={true}
                headerHeight={46}
                height={500}
                // autoHeight

                wordWrap
                data={[...dataList]}
                {...{
                    loading
                }}
                {...props}
            >

                <Column width={130}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">Exception</span>
                    </HeaderCell>
                    <Cell dataKey={"name"}>
                        {(rowData) => {
                            if ( forms.has(rowData.routing_key) ) {
                                const editableRow = forms.get(rowData.routing_key);
                                return <EditField
                                    as={SelectPicker}
                                    cleanable={false}
                                    searchable={false}
                                    defaultValue={false}
                                    value={editableRow.is_exception}
                                    data={exceptionList}
                                    valueKey="value"
                                    labelKey="name"
                                    name="is_exception"
                                    placeholder="Exception"
                                    onChange={(val) => changeForm("is_exception", val, rowData.routing_key)}
                                />
                            }
                            const exception = exceptionList.find(exception => exception.value === rowData["is_exception"])
                            return <span className="tableFilters__previewText">{exception.name || ""}</span>
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={1} minWidth={130}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">Supplier</span>
                    </HeaderCell>
                    <Cell dataKey={"supplier_id"}>
                        {(rowData) => {
                            if ( forms.has(rowData.routing_key) ) {
                                const editableRow = forms.get(rowData.routing_key);
                                return <EditField
                                    as={SelectPicker}
                                    cleanable
                                    searchable
                                    data={supplierList}
                                    value={editableRow["supplier_id"]}
                                    valueKey="supplier_id"
                                    labelKey="name"
                                    name="supplier_id"
                                    placeholder="Supplier"
                                    onChange={(val) => changeForm("supplier_id", val, rowData.routing_key)}
                                />
                            }
                            return <span className="tableFilters__previewText">
                                {rowData.supplier_id
                                    ? rowData.supplier_name
                                    : ''
                                }
                            </span>
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={2} minWidth={130}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">Sender ID</span>
                    </HeaderCell>
                    <Cell dataKey="skype">
                        {(rowData) => {
                            if ( forms.has(rowData.routing_key) ) {
                                const editableRow = forms.get(rowData.routing_key);
                   
                                const checkedForm = routingModel.check(editableRow);
                                const hasErrorData = checkedForm?.senderid ? checkedForm["senderid"] : {};

                                return <div className="rs-form-control-wrapper">
                                    <EditField
                                        as={Input}
                                        value={editableRow["senderid"]}
                                        name="senderid"
                                        placeholder="Sender ID"
                                        onChange={(val) => changeForm("senderid", val, rowData.routing_key)}
                                    />
                                    {hasErrorData.hasError &&
                                        <EditFieldError text={hasErrorData.errorMessage} />
                                    }
                                </div>
                            }
                            return <span className="tableFilters__previewText">{rowData["senderid"]}</span>
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={1} minWidth={130}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">MCC/MNC</span>
                    </HeaderCell>
                    <Cell dataKey="mcc_mnc">
                        {(rowData) => {
                            if ( forms.has(rowData.routing_key) ) {
                                const editableRow = forms.get(rowData.routing_key);

                                const checkedForm = routingModel.check(editableRow);
                                const hasErrorData = checkedForm?.senderid ? checkedForm["mcc_mnc"] : {};
 
                                return <div className="rs-form-control-wrapper">
                                    <EditField
                                        as={Input}
                                        value={editableRow["mcc_mnc"]}
                                        name="mcc_mnc"
                                        placeholder="MCC/MNC"
                                        onChange={(val) => changeForm("mcc_mnc", val, rowData.routing_key)}
                                    />
                                    {hasErrorData.hasError &&
                                        <EditFieldError text={hasErrorData.errorMessage} />
                                    }
                                </div>
                            }
                            return <span className="tableFilters__previewText">{rowData["mcc_mnc"]}</span>
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={1} minWidth={130}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">MCC/MNC Match</span>
                    </HeaderCell>
                    <Cell dataKey={"mcc_mnc_match_like"}>
                        {(rowData) => {
                            if ( forms.has(rowData.routing_key) ) {
                                const editableRow = forms.get(rowData.routing_key);
                                return <EditField
                                    as={SelectPicker}
                                    cleanable={false}
                                    searchable={false}
                                    defaultValue={false}
                                    value={editableRow.mcc_mnc_match_like}
                                    data={mccMncMatchList}
                                    valueKey="value"
                                    labelKey="name"
                                    name="mcc_mnc_match_like"
                                    placeholder="Match"
                                    onChange={(val) => changeForm("mcc_mnc_match_like", val, rowData.routing_key)}
                                />
                            }
                            const match = mccMncMatchList.find(mccMnc => mccMnc.value === rowData["mcc_mnc_match_like"])
                            return <span className="tableFilters__previewText">{match.name || ""}</span>
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={1} minWidth={130}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">Dialer</span>
                    </HeaderCell>
                    <Cell dataKey="dialer_name">
                        {(rowData) => {
                            if ( forms.has(rowData.routing_key) ) {
                                const editableRow = forms.get(rowData.routing_key);
                                return <div className="rs-form-control-wrapper">
                                    <EditField
                                        name="dialer_id"
                                        accepter={SelectPicker}
                                        data={dialerList}
                                        defaultValue={editableRow.dialer_id}
                                        placeholder="Dialer"
                                        valueKey="id"
                                        labelKey="name"
                                        onChange={(val) => changeForm("dialer_id", val, rowData.routing_key)}
                                    />
                                </div>
                            }
                            return <span className="tableFilters__previewText">{rowData["dialer_name"]}</span>
                        }}
                    </Cell>
                </Column>

                <Column width={140}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">Rate</span>
                    </HeaderCell>
                    <Cell dataKey="min_rate">
                        {(rowData) => {
                            if ( forms.has(rowData.routing_key) ) {
                                const editableRow = forms.get(rowData.routing_key);
                                const checkedForm = routingModel.check(editableRow);
                                const hasErrorData = checkedForm["min_rate"];

                                return <div className="rs-form-control-wrapper">
                                    <EditField
                                        as={Input}
                                        value={editableRow["min_rate"]}
                                        name="min_rate"
                                        placeholder="Rate"
                                        onChange={(val) => changeForm("min_rate", val, rowData.routing_key)}
                                    />
                                    {hasErrorData.hasError &&
                                        <EditFieldError text={hasErrorData.errorMessage} />
                                    }
                                </div>
                            }
                            return <span className="tableFilters__previewText">{rowData["min_rate"]}</span>
                        }}
                    </Cell>
                </Column>

                <Column width={250}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">Created</span>
                    </HeaderCell>
                    <Cell dataKey={"modified_at"}>
                        {(rowData) => {
                            const previewParts = {
                                modified_date: rowData["modified_at"],
                                created_date: rowData["created_at"],
                                name: rowData["account_user_name"],
                                role: rowData["account_user_role"]
                            };

                            const date = previewParts.modified_date || previewParts.created_date;
                            const userName = previewParts.name ? `${previewParts.name} (${previewParts.role})` : null;

                            const dateObject = new Date(date);
                            const options = {
                                dateStyle: "medium", timeStyle: "medium"
                            };
                            const dateString = dateObject.toLocaleString("en-US", options);

                            return (<>
                                <span className="tableFilters__previewText">
                                    <SecondStagedCell>
                                        {userName ? <div className="table-two-staged__cell-first">
                                            Created by {userName}
                                        </div> : <></>}
                                        <div className={`table-two-staged__cell-second ${!userName ? "single" : ""}`}>
                                            {dateString}
                                        </div>
                                    </SecondStagedCell>
                                </span>
                            </>)
                        }}
                    </Cell>
                </Column>

                <Column width={174}>
                    <HeaderCell></HeaderCell>
                    <Cell>
                        {rowData => {
                            const rowLoading = rowsLoading.includes(rowData.routing_key);
                            return <div className="tableFilters_buttons">

                                {!forms.has(rowData.routing_key)
                                    ? <Button
                                        size="sm"
                                        color="lightblue"
                                        disabled={rowLoading}
                                        onClick={() => setItemEditing(rowData)}
                                    >
                                        <Icon icon="edit2"/>
                                    </Button>
                                    : <>
                                        <Button
                                            size="sm"
                                            color="green"
                                            disabled={rowLoading}
                                            onClick={() => modifyRow(rowData)}
                                        >
                                            <Icon icon="check-circle"/>
                                        </Button>

                                        <Button
                                            size="sm"
                                            color="red"
                                            disabled={rowLoading}
                                            onClick={() => setItemEditing(rowData)}
                                        >
                                            <Icon icon="close-circle"/>
                                        </Button>
                                    </>
                                }

                                <Button
                                    size="sm"
                                    color="red"
                                    disabled={rowLoading}
                                    onClick={() => {
                                        setRemoveData(rowData);
                                        setShowRemoveModal(true);
                                    }}
                                >
                                    <Icon icon="trash2"/>
                                </Button>
                            </div>
                        }}
                    </Cell>
                </Column>

            </StyledTable>

            <ModalRemoveRouting
                show={showRemoveModal}
                onSubmit={deleteRow}
                onClose={() => setShowRemoveModal(false)}
                disabled={removeDataLoading}
            />

        </>
    )
}

const StyledTable = styled(BaseTable)`
    && {
    
        .tableFilters__previewText {
            display: block;
            line-height: 20px;
            margin-top: 7px;
            word-break: normal;
        }
        
        .tableFilters_buttons {
            height: 20px;
            margin-top: 7px;
        }
    }
`;


const EditFieldError = ({text}) => (
    <div className="rs-error-message-wrapper rs-form-control-message-wrapper rs-error-message-placement-top-end">
        <span className="rs-error-message rs-error-message-show">
            <span className="rs-error-message-arrow"></span>
            <span className="rs-error-message-inner">{text}</span>
        </span>
    </div>
);

const EditField = styled(SelectPicker).attrs(props => {
    return {
        className: "tableFilters_field",
        errorPlacement: "topEnd",
        type: "text",
    }
})`
`;

const CustomCheckbox = styled(Checkbox)`
 &&.rs-checkbox-disabled > .rs-checkbox-checker > label {
    color: inherit;
 }
`;

const SecondStagedCell = styled.div`
    margin-top: -7px;
    
    .table-two-staged__cell-first {
        ${props => props.same && css`
            font-size: 13px;
        `}
    }
    .table-two-staged__cell-second:not(.single) {
        margin-top: -2px;
        font-size: 13px;
        ${props => !props.same ? css`
            color: gray;
        ` : css`
            font-size: 13px;
        `}
        opacity: 1;
    }
`;