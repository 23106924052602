import React, { useState, useEffect } from "react";
import NumbersFilter from "./Numbers/NumbersFilter";
import NumbersTable from "./Numbers/NumbersTable";
import { Alert, FlexboxGrid } from "rsuite";
import Modal from "components/Modal";
import { ButtonPrimary } from "components/base/BaseButton";
import ChangeRateModal from "./Numbers/NumbersChangeRateModal";
import { FilterSectionFlexboxGrid, StyledItemAutoMargin } from "components/base/FitContainers";

import m from "definedMessages";
import { withRouter } from "react-router";


const TabNumbers = ({
    account,
    subAccId,

    data,
    page,
    count,
    perPage,
    loading,

    history,

    getData,

    changeRateFunc,
    revokeFunc,
    exportFunc,
    formatMessage
}) => {

     const defaultFilterValue = {
        filter_phone: ""
    };

    const defaultSelectedItems = {
        list: [], all: false
    };

    const [filterState, setFilterState] = useState(defaultFilterValue);
    const [selectedItems, setSelectedItems] = useState(defaultSelectedItems);
    const [clearSelectedData, setClearSelectedData] = useState(null);

    const [showChangeRateModal, setShowChangeRateModal] = useState(false);
    const [showRevokeConfirmModal, setShowRevokeConfirmModal] = useState(false);


    useEffect(() => {
        update();
    }, [subAccId, filterState]);

    const update = () => {
        getData(subAccId, filterState, 1, perPage);
    };

    const onChangeRate = (rate) => {
        changeRateFunc(subAccId, rate, filterState, selectedItems.list, selectedItems.all)
            .then(({change_rate_number_count}) => {
                update();
                Alert.success(formatMessage(m.rateWasChanged, {count: change_rate_number_count}));
            })
            .finally(() => {
                setShowChangeRateModal(false);
            });
    };

    const onRevoke = () => {
        revokeFunc(subAccId, filterState, selectedItems.list, selectedItems.all)
            .then(({revoked_number_count}) => {
                update();
                Alert.success(formatMessage(m.numbersWereRevoked, {count: revoked_number_count}));
            })
            .finally(() => {
                setShowRevokeConfirmModal(false);
            });
    };

    const onExport = () => {
        exportFunc(subAccId, filterState, selectedItems.list, selectedItems.all);
    };

    const notSelectedDisabled = (!selectedItems.list.length && !selectedItems.all);

    return <>
        <FilterSectionFlexboxGrid>
            <FlexboxGrid.Item>
                <NumbersFilter
                    filter={filterState}
                    onChangeFilter={setFilterState}

                    formatMessage={formatMessage}
                />
            </FlexboxGrid.Item>
            <StyledItemAutoMargin>
                <ButtonPrimary
                    disabled={loading}
                    onClick={() => {
                        history.push({
                            pathname: "/sub-numbers"
                        })
                    }}
                >
                    {formatMessage(m.addNewNumbers)}
                </ButtonPrimary>
                <ButtonPrimary
                    disabled={loading || notSelectedDisabled}
                    onClick={() => {
                        setShowChangeRateModal(true);
                    }}
                >
                    {formatMessage(m.changeRate)}
                </ButtonPrimary>
                <ButtonPrimary
                    disabled={loading || notSelectedDisabled}
                    onClick={() => {
                        setShowRevokeConfirmModal(true);
                    }}
                >
                    {formatMessage(m.revoke)}
                </ButtonPrimary>
                <ButtonPrimary
                    disabled={loading || notSelectedDisabled}
                    onClick={() => {
                        onExport();
                    }}
                >
                    {formatMessage(m.exportSelected)}
                </ButtonPrimary>
            </StyledItemAutoMargin>
        </FilterSectionFlexboxGrid>

        <NumbersTable
            height={440}

            data={data}
            page={page}
            count={count}
            perPage={perPage}
            loading={loading}
            selectedItems={selectedItems}
            setSelected={(value) => {
                setSelectedItems(value);
            }}
            clearSelectedData={clearSelectedData}
            setClearSelectedData={setClearSelectedData}

            getItems={(_page, _perPage) => {
                getData(subAccId, filterState, _page, _perPage)
            }}
            formatMessage={formatMessage}
        />
        {/* Revoke confirmation modal */}
        <Modal
            show={showRevokeConfirmModal}
            disabled={loading}
            title={formatMessage(m.revokeConfirmation)}
            onClose={() => {
                setShowRevokeConfirmModal(false);
            }}
            onSuccess={() => {
                onRevoke();
            }}
            successText={formatMessage(m.ok)}
            cancelText={formatMessage(m.cancel)}
            footer={true}
        >
            {formatMessage(m.areYouSureAllNumbersWillBeRevoked)}
        </Modal>

        <ChangeRateModal
            show={showChangeRateModal}
            disabled={loading}
            changeRateFunc={onChangeRate}
            onCloseModal={() => {
                setShowChangeRateModal(false);
            }}
            formatMessage={formatMessage}
        />
    </>
};


export default withRouter(TabNumbers);