import {connect} from "react-redux";
import {getIvrList} from "actions/ivr";
import {setDefaultIvrLoading, getDefaultIvrList, changeDefaultIvrList} from "actions/ivr_default";
import PageIvrDefault from "./PageIvrDefault";

const mapState = ({ivr, ivr_default}) => ({
    ivrDefaultList: ivr_default.ivrDefaultList,
    ivrDefaultLoading: ivr_default.loading,
    ivrList: ivr.list,
});

export default connect(mapState, {
    getIvrList,
    setDefaultIvrLoading,
    getDefaultIvrList,
    changeDefaultIvrList,
})(PageIvrDefault);