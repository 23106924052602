import React, {useContext} from "react";
import {Icon, IconButton, Loader} from "rsuite";
import styled from "styled-components";
import {PermissionContext} from "../../../../App";
import {IVR_CREATE_API} from "../../../../const/apiMethods";
import TreePicker from "../../../../components/TreePicker/TreePicker";


export default ({
    data,
    activeIvr,
    loading,
    handleSelectIvr,
    handleSelectIvrCreate,
}) => {

    const permissions = useContext(PermissionContext);

    return (
        <>
            <StyledTreeWrapper>
                <BlockHeader>
                    Choose IVR
                </BlockHeader>
                <StyledTree
                    virtualized
                    inline={true}
                    rowHeight={38}
                    data={data}
                    valueKey="id"
                    labelKey="name"
                    height={300}
                    value={activeIvr && activeIvr.id}
                    onSelect={(item) => {
                        handleSelectIvr(item.id);
                    }}
                    defaultExpandAll
                />
                {permissions.includes(IVR_CREATE_API) &&
                    <StyledButtonCreateIvr
                        appearance="link"
                        onClick={handleSelectIvrCreate}
                        icon={<Icon icon="plus"/>}
                    >
                        Create new IVR
                    </StyledButtonCreateIvr>
                }
                {loading && <Loader backdrop size="sm" />}
            </StyledTreeWrapper>
        </>
    )
};

const StyledTreeWrapper = styled.div`
    position: relative;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 100%;
    overflow: hidden;
    
    .rs-picker-none {
        border-top: 2px solid transparent;
        height: 300px;
        width: 100%;
    }
`;

const StyledTree = styled(TreePicker)`

    padding-top: 0;
  
    .rs-tree-node {
        padding: 0 !important;
    }
    
    &&& .rs-tree-node-label-content {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
    
    .rs-tree-node-label-content {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    
    .rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content {
        
        &,
        &:hover {
            font-weight: normal;
        }
    }
`;

const StyledButtonCreateIvr = styled(IconButton)`
`;

const BlockHeader = styled.h5`
    font-family: inherit;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-brand1);
    padding: 20px;
`;