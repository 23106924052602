import React, {useContext, useMemo, useRef, useState} from "react";
import {SelectPicker, Schema} from "rsuite";
import TableEditCells from "../../../components/Table/TableEditCells";
import styled from "styled-components";
import IvrListener from "../../../components/IvrListener/IvrListener";
import {PermissionContext} from "../../../App";
import {
    IVR_CLI_CREATE_API,
    IVR_CLI_MODIFY_API,
    IVR_CLI_REMOVE_API
} from "../../../const/apiMethods";


const {StringType} = Schema.Types;

const formModelPrefix = Schema.Model({
    prefix: StringType()
        .isRequired('Required')
        .pattern(/^[0-9][\d]*$/, 'The number must contain only digits')
        .minLength(3, 'The minimum is 3 characters.')
        .maxLength(15, "The maximum is 15 characters"),
});
const formModelIvr = Schema.Model({
    ivr_id: StringType()
    .isRequired('Required')
});
const formModel = Schema.Model.combine(
    formModelPrefix,
    formModelIvr
);


export default ({
    data = [],
    filter,
    loading,
    getIvrCliList,
    ivrList,
    handleSubmit,
    handleRemove,
    ...props
}) => {

    const permissions = useContext(PermissionContext);

    const handleUpdate = () => {
        getIvrCliList(filter);
    };

    const [currentIvrPrefix, setCurrentIvrPrefix] = useState(null);
    const cliRefs = useRef(new Map());


    const canCreate = useMemo(() => permissions.includes(IVR_CLI_CREATE_API), [permissions]);
    const canModify = useMemo(() => permissions.includes(IVR_CLI_MODIFY_API), [permissions]);
    const canRemove = useMemo(() => permissions.includes(IVR_CLI_REMOVE_API), [permissions]);


    const columns = useMemo(() => [
        {
            dataKey: "prefix",
            dataValue: 'prefix',
            flexGrow: 2,
            label: 'CLI',
        },
        {
            dataKey: 'description',
            dataValue: 'description',
            flexGrow: 2,
            label: 'Description',
        },
        {
            dataKey: 'ivr_id',
            dataValue: 'ivr_name',
            flexGrow: 2,
            label: 'IVR',

            placeholder: 'IVR',
            accepter: SelectPicker,
            model: formModelIvr,

            data: ivrList,
            valueKey: "id",
            labelKey: "name",
            searchable: false,
            cleanable: false,
        },
        {
            dataKey: 'listen',
            customValue: ({ivr_id, prefix}) => (
                <StyledButtonPlay buttonStyle="secondary">
                    <IvrListener
                        onClick={() => {
                            if (currentIvrPrefix && currentIvrPrefix !== prefix) {
                                cliRefs.current.get(currentIvrPrefix).stop();
                            }
                            setCurrentIvrPrefix(prefix);
                        }}
                        ref={ref => cliRefs.current.set(prefix, ref)}
                        ivrId={ivr_id}
                    />
                </StyledButtonPlay>
            )
        }
    ], [ivrList, currentIvrPrefix]);

    return (
        <>
            <TableEditCells
                data={data}
                loading={loading}
                idKey="prefix"
                columns={columns}
                height={643}
                editable={canCreate || canModify || canRemove}

                formModel={formModel}

                onDataUpdate={handleUpdate}
                onModifyRow={handleSubmit}
                onRemoveRow={handleRemove}

                ivrList={ivrList}

                autoHeight={false}
                canCreate={canCreate}
                canModify={canModify}
                canRemove={canRemove}

                {...props}
            />
        </>
    )
}

const StyledButtonPlay = styled.div`
    && {
        .rs-btn {
            width: 28px;
            height: 28px;
            line-height: 28px;
            border-radius: 28px;
            margin: -3px 0 0;
        }
        .rs-icon {
            line-height: 28px;
            font-size: 13px;
        }
    }
`;
