import {
    SUBACC_SET_RESELLER_METHOD,
    SUBACC_UNSET_RESELLER_METHOD
} from "../const/apiMethods"
import { api } from "../api/loginRoutes";


export const setResellerMode = (accountId, setOrUnset=true) => async (dispatch) => {
    const method = setOrUnset ? SUBACC_SET_RESELLER_METHOD : SUBACC_UNSET_RESELLER_METHOD;
    return await api(method, {target: {account_id: accountId}});
};
