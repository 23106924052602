import React from "react";
import Dropdown from "rsuite/es/Dropdown";
import styled from "styled-components";
import {Icon, Loader} from "rsuite";
import { SMS_ACCOUNT_ID } from "const";

const NumbersDownloadDropdown = (
    {
        isTransitTrunks,
        disabled,
        loading,
        onDownloadNumbersCsv,
        onDownloadOnlyNumbersCsv,
        isMobile = false,
        account,

        ...props
     }
 ) => {
    return (
        <StyledDropdown
            isMobile={isMobile}
            title={<>{loading
                ? <StyledLoader/>
                : isMobile ? <Icon icon={'download'}/> : "Download"}</>
            }
            appearance="default"
            placement={isMobile ? 'bottomEnd' : 'bottomStart'}
            disabled={disabled}
            {...props}
        >
            <Dropdown.Item
                onClick={onDownloadNumbersCsv}
            >
                Download allocated {isTransitTrunks ? 'prefixes' : 'numbers'} as csv
            </Dropdown.Item>

            {account?.id !== SMS_ACCOUNT_ID &&
                <Dropdown.Item
                    onClick={onDownloadOnlyNumbersCsv}
                >
                    Download only {isTransitTrunks ? 'prefixes' : 'numbers'} (txt version)
                </Dropdown.Item>
            }
        </StyledDropdown>
    )
};

export default NumbersDownloadDropdown;

const StyledDropdown = styled(Dropdown)`    
    & > a {
        padding: ${props => props.isMobile 
            ? '8px 32px 8px 12px !important' 
            : '12px 34px !important'
        };
        width: 100%;
    }
    &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
        background-color: #d6d6db !important;
    }
`;

const StyledLoader = styled(Loader)`
  & span.rs-loader-spin {
    margin-top: 3px;
    margin-right: 5px;
  }
`;