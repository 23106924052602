import {connect} from 'react-redux';
import TrafficStat from './TrafficStat';
import {exportDialerReport, setEmptyTrafficStat, getDialerTrafficStat} from "../../../actions/client/trafficStat";

const mapState = ({client_traffic_stat, references}) => ({
    loading: client_traffic_stat.loading,
    page: client_traffic_stat.page,
    per_page: client_traffic_stat.per_page,
    count: client_traffic_stat.count,
    trafficStat: client_traffic_stat.trafficStat,
    summaries: client_traffic_stat.summaries,
    uploadLoading: references.uploadLoadingDialer,
    globalSettings: references.global_setting,
});

export default connect(mapState, {
    getTrafficStat: getDialerTrafficStat,
    exportReport: exportDialerReport,
    setEmptyTrafficStat
})(TrafficStat);
