import React, {useContext, useMemo} from "react";
import {Spacer} from "../../../../components/base/Spacer";
import {Button, ButtonGroup, Icon, IconButton, List, Message, SelectPicker} from "rsuite";
import styled, {css} from "styled-components";
import {PermissionContext} from "../../../../App";
import {IVR_CREATE_API} from "../../../../const/apiMethods";

export default (
    {
        actions,
        actionId,
        setActionId,
        ivrTypes,
        params,
        editState,
        showNameEdit,
        handleChangeType,
        handleAddAction,
        handleRemoveAction,
        handleDecreasePosition,
        handleIncreasePosition,
    }
) => {

    const permissions = useContext(PermissionContext);

    const controlsDisabled = useMemo(() => {
        return !permissions.includes(IVR_CREATE_API) || (!showNameEdit && !editState)
    }, [permissions, editState, showNameEdit]);

    return (
        <>
            <Spacer size={30}/>

            <StyledList>
                {actions && actions.map((item, index) => {
                    const isActive = actionId === item.id;
                    const formParams = params[item.id];
                    let classNames = isActive ? "active" : "";

                    if (formParams.isChanged) {
                        classNames += " changed";
                    }

                    if (item.hasError) {
                        classNames += " has-error";
                    }

                    return (
                        <StyledListItem

                            key={item.id}
                            index={index}
                            onClick={(e) => {
                                setActionId(item.id);
                            }}
                            className={classNames}
                            disabled={controlsDisabled}
                        >
                            <StyledListItemContent>
                                <StyledIndex disabled={controlsDisabled}>{index + 1}</StyledIndex>
                                <SelectPicker
                                    className="ivr-action-type-dropdown"
                                    data={ivrTypes}
                                    labelKey="name"
                                    valueKey="action_id"
                                    value={item.action_id}
                                    placeholder="Action"
                                    name="dialer_id"
                                    searchable={false}
                                    cleanable={false}
                                    disabled={controlsDisabled}
                                    onChange={(val) => handleChangeType(index, val)}
                                />

                                <div className="ivr-action-type-control">
                                    <StyledListButton
                                        disabled={controlsDisabled}
                                        icon={<Icon icon="plus"/>}
                                        onClick={() => handleAddAction(index)}
                                    />

                                    <StyledListButton
                                        icon={<Icon icon="minus"/>}
                                        disabled={actions.length === 1 || controlsDisabled}
                                        onClick={(e) => handleRemoveAction(index, e)}
                                    />

                                    <ButtonGroup style={{paddingLeft: 10}}>
                                        <StyledListButton
                                            disabled={index === 0 || controlsDisabled}
                                            onClick={(e) => handleDecreasePosition(index, e)}
                                            icon={<Icon icon="caret-up"/>}
                                        />
                                        <StyledListButton
                                            disabled={index === actions.length - 1 || controlsDisabled}
                                            onClick={(e) => handleIncreasePosition(index, e)}
                                            icon={<Icon icon="caret-down"/>}
                                        />
                                    </ButtonGroup>
                                </div>
                            </StyledListItemContent>
                        </StyledListItem>
                    )
                })}
            </StyledList>

            {actions && !actions.length &&
            <Message
                showIcon
                type="warning"
                description={
                    <>
                        You must create at least one action.
                        <StyledMessageButton
                            onClick={() => handleAddAction(-1)}
                            appearance="link"
                        >
                            Create action
                        </StyledMessageButton>
                    </>
                }
            />
            }
        </>
    )
};


const StyledIndex = styled.div`
    ${({disabled}) => disabled ? css`
        color: lightgray;
    ` : css`
        color: inherit;
    `}

    width: 22px;
    font-size: 1.2em;
    padding-top: 6px;
    font-weight: 500;
`;

const StyledMessageButton = styled(Button)`
    padding: 0 10px !important;
`;

const StyledList = styled(List)`
    margin-left: -20px;
    margin-right: -20px;
    box-shadow: none !important;
    overflow: visible !important;
`;

const StyledListItemContent = styled.div.attrs(() => ({
    className: 'ivr-list-item-content'
}))`
    display: flex;
    
    .ivr-action-type-dropdown {
        width: 100%;
        max-width: 400px;
    }
    
    .ivr-action-type-control {
        margin-left: auto;
        flex-shrink: 0;
        .rs-btn {
            margin-left: 10px;
        }
    }
`;

const StyledListButton = styled(IconButton).attrs(() => ({
    className: 'action-list-button'
}))`
    
`;

const StyledListItem = styled(List.Item)`
    padding-left: 20px;
    padding-right: 20px;
    background-color: transparent;
    transition: background-color 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease;
    box-shadow: none !important;
    border-bottom: 1px solid #e5e5ea;
    border-top: 1px solid #e5e5ea;
    
    & + & {
        margin-top: -1px;
    }
    
    &.active {
        background-color: #f2faff;
    }
    
    &:hover {
        position: relative;
        z-index: 6;
        box-shadow: 0 0 4px 2px #e5e5ea !important;
        border-color: transparent;
    }
    
    &.has-error {
        box-shadow: inset 0 0 6px -2px var(--color-error) !important;
    }
`;