import React, {useState} from 'react'
import Modal from '../../../../components/Modal';
import {Alert} from "rsuite";
import {api} from '../../../../api/loginRoutes';
import {
    SMS_SUPPLIER_GAN_GROUP_REMOVE_METHOD,
    SMS_SUPPLIER_GAN_GROUP_REMOVE_ALLOCATED_METHOD
} from '../../../../const/apiMethods';
import {checkPermissionsFor} from "../../../../store/storeHelpers";

export default ({show, onClose, onSuccess, selectedItems, showReasonHandler}) => {
    
    const removeAllocatedMethod = checkPermissionsFor(SMS_SUPPLIER_GAN_GROUP_REMOVE_ALLOCATED_METHOD) 
        ? SMS_SUPPLIER_GAN_GROUP_REMOVE_ALLOCATED_METHOD 
        : SMS_SUPPLIER_GAN_GROUP_REMOVE_METHOD;

    const method = removeAllocatedMethod;

    const [disabled, setDisabled] = useState(false)

    const onSubmit = async () => {
        setDisabled(true)

        const result = await api(method, {
            target:{
                "sms.supplier_gan_group_id": selectedItems?.id
            }
        });

        if (result === undefined || (result.code && !result.reason_code)) {
            return;
        }

        // if (r.reason_code === 'not_enough_free_numbers_sequence') {
        //     setFormValue(prev => ({...prev, random_number: true}));
        // }

        if (result.reason_code) {
            if (result.reason_code === "template_is_too_short") {
                Alert.warning("The empty group was deleted");
            }

            showReasonHandler({
                reasonCode: result.reason_code,
                reasonHash: result.hash,
            });
        } else {
            onSuccess();
        }

        setDisabled(false);
    }

    return (
        <Modal 
            show={show}
            onClose={onClose}
            footer
            successText = "Yes"
            onSuccess = {onSubmit}
            disabled = {disabled}
        >
            This group will be deleted. Are you sure?
        </Modal>
    )
};

