import {
    CDR_DIALER,
    ACCESS_LIST_DIALER,
    SET_VISIBLE_RECAPTCHA,
    SET_TRUNK_NUMBER_DIALER,
    SET_REQUEST_DATA_RECAPTCHA, 
    SET_NUMBER_ALLOCATION_MODAL
} from '../const/';
import {
    TRUNK_LIST_API,
    CDR_LIST_DIALER_API,
    ACCESS_LIST_ACCOUNT_PRICE_DIALER_API
} from '../const/apiMethods';
import {createAction} from './defaults';


const setStatusAllocationModal = createAction(SET_NUMBER_ALLOCATION_MODAL);
const setVisibleRecaptcha = createAction(SET_VISIBLE_RECAPTCHA);
const setRequiestData = createAction(SET_REQUEST_DATA_RECAPTCHA);

const setAccessList = createAction('SET_ITEMS_' + ACCESS_LIST_DIALER);
const setTestCalls = createAction('SET_ITEMS_' + CDR_DIALER);
const setViewNumberTrunk = createAction(SET_TRUNK_NUMBER_DIALER);

export const storeDataOnRecaptchaComplete = (method, response) => (dispatch) => {
    const actions = {
        [ACCESS_LIST_ACCOUNT_PRICE_DIALER_API]: setAccessList,
        [CDR_LIST_DIALER_API]: setTestCalls,
        [TRUNK_LIST_API]: setViewNumberTrunk
    };

    return dispatch(actions[method]({
        ...response,
        page: 1
    }))
};

export const openAllocationModal = () => (dispatch) => {
    dispatch(setStatusAllocationModal(true));
};

export const closeAllocationModal = () => (dispatch) => {
    dispatch(setStatusAllocationModal(false));
};

export const setRequestData = (methodRecaptcha, paramsRecaptcha) => (dispatch) => {
    dispatch(setRequiestData({methodRecaptcha, paramsRecaptcha}));
};

export const onVisibleRecaptcha = () => (dispatch) => {
    dispatch(setVisibleRecaptcha(true));
};

export const onInvisibleRecaptcha = () => (dispatch) => {
    dispatch(setVisibleRecaptcha(false));
};