import React, {useState, useEffect} from 'react'
import styled from 'styled-components';

import {Editor} from 'react-draft-wysiwyg';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import {EditorState} from 'draft-js';


const renderText = (value) => {
    const blocksFromHTML = stateFromHTML(value);
    return value && (typeof value === 'string' || value instanceof String)
        ? EditorState.createWithContent(blocksFromHTML)
        : EditorState.createEmpty()
};


export default ({defaultValue, onChange, ...props}) => {
    const [text, setText] = useState(() => renderText(defaultValue?.message));

    useEffect(() => {
        setText( renderText(defaultValue?.message) );
    }, [defaultValue]);

    return (
        <StyledEditorContainer>
            <Editor
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"

                editorState={text}
                onEditorStateChange={(editorState) => {
                    setText(editorState);
                    const value = stateToHTML(editorState.getCurrentContent());
                    onChange && onChange(value);
                }}

                toolbar={{
                    inline: {
                        inDropdown: true
                    },
                    list: {
                        inDropdown: true, 
                        options: ['unordered', 'ordered']
                    },
                    textAlign: {
                        inDropdown: true
                    },
                    link: {
                        inDropdown: false
                    },
                    history: {
                        inDropdown: false
                    },
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'emoji', 'history']
                }}
                {...props}
            />
        </StyledEditorContainer>
    )
}


const StyledEditorContainer = styled.div`
    .wrapper-class {
        margin-bottom: 5px;
    }
    .editor-class {
        border: 1px solid #e5e5ea;
        border-radius: 5px;
        cursor: text;
        min-height: 140px;
        padding: 2px 5px;
    }
    .toolbar-class .rdw-link-modal-target-option {
        display: none;
    }
`;