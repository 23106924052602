import React from 'react';
import Modal from '../../../components/Modal';
import { api } from '../../../api/loginRoutes';
import { Alert } from 'rsuite';
import {ButtonPrimary} from "../../../components/base/BaseButton";

export default (
    {
        isSetTest,
        selected,
        extraParams,
        disabled,
        onSuccess,
        checkKey='incorporated_numbers',
        methodSet='incorporated_number:set_test_number',
        methodUnset='incorporated_number:unset_test_number'
    }
) => {

    const [show, setShow ] = React.useState(false);
    const [loading, setLoading ] = React.useState(false);


    const onSubmit = async () => {
        setLoading(true);

        const method = isSetTest 
            ? methodSet
            : methodUnset;

        const result = await api(method, {...extraParams});

        if (!result) {
            setLoading(false);
            return;
        }

        if (result[checkKey] === selected.list.length) {
            Alert.success(isSetTest
                ? `Setted ${result[checkKey]} numbers`
                : `Unsetted ${result[checkKey]} numbers`
            );
        } else {
            if (result[checkKey]) {
                Alert.warning(
                    isSetTest
                        ? `Setted ${result[checkKey]} numbers, but some weren't setted because allocated numbers can't setted as test`
                        : `Unsetted ${result[checkKey]} numbers, but some weren't unsetted because not test numbers can't unsetted`
                );
            } else {
                Alert.error(
                    isSetTest
                        ? `Allocated numbers can't be setted as test`
                        : `Not tested numbers can't be unset`
                );
            }
        }

        onSuccess();
        setShow(false);
        setLoading(false)
    };

    return (
        <>
            <ButtonPrimary disabled={disabled} onClick={() => setShow(true)}>
                {isSetTest ? 'Set test' : 'Unset test'}
            </ButtonPrimary>

            <Modal
                show={show}
                title={isSetTest
                    ? 'Set test numbers'
                    : 'Unset test numbers'
                }
                onClose={() => setShow(false)}
                footer={true}
                successText="Confirm"
                disabled={loading}
                onSuccess={onSubmit}
            >
                {isSetTest
                    ? `Selected numbers will be set as test. 
                        Pay your attention - if they are allocated to any dialer they will be revoked from them too.`
                    : `Selected numbers will be unset as test?`
                }
            </Modal>
            
        </>
        
    );
}