import {
    ACCESS_LIST_DIALER as PREFIX,
    SET_LOADING_ACCESS_ALLOCATED_NUMBERS_DIALER,
    SET_UPLOAD_LOADING_DIALER, SP_KEY__INCORPORATED, SP_KEY__GLOBAL_ACCESS
} from '../../const';

import {
    ACCESS_LIST_ACCOUNT_PRICE_DIALER_API,
    ACCESS_LIST_DEFAULT_PRICE_DIALER_API,
    ACCESS_LIST_OTP_ACCOUNT_PRICE_DIALER_API,
    ACCESS_LIST_OTP_DEFAULT_PRICE_DIALER_API,
    ACCESS_LIST_FILTER_GET_LIST_API,
    ALLOCATION_INCORPORATED_DIALER_API,
    ALLOCATION_SUBDESTINATION_DIALER_API,
    SMS_ACCESS_LIST_DEFAULT_PRICE_DIALER_API,
    SMS_ACCESS_LIST_ACCOUNT_PRICE_DIALER_API,
    SMS_ALLOCATION_SUBDESTINATION_DIALER_API,
    SMS_ALLOCATION_INCORPORATED_DIALER_API,
    SMS_ALLOCATION_GAN_API
} from "../../const/apiMethods";

import {api, getFileResponse} from '../../api/loginRoutes';
import {_setValue} from './defaults';
import {downloadDataAsFile} from '../../utils/helpers';


const setAccessList = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);
const setUploadLoading = _setValue(SET_UPLOAD_LOADING_DIALER);


export const setAccessLoadingAllocatedNumbers = _setValue(SET_LOADING_ACCESS_ALLOCATED_NUMBERS_DIALER);

const setAccountAccessListFiltersDialer = _setValue('SET_FILTERS_' + PREFIX);
const setAccountAccessListFiltersLoadingDialer = _setValue('SET_FILTERS_LOADING_' + PREFIX);


export const setLoadingData = (load) => (dispatch) => {
    dispatch( setLoading(load) );
};


export const getAccessList = (service, per_page = 12, filter = {}, page = 1, isAuth = false, cancelToken) => (dispatch) => {
    dispatch( setLoading(true) );
    
    const isTest = localStorage.getItem('userInfo') 
        ? JSON.parse(localStorage.getItem('userInfo')).session.is_test 
        : false;

    const request = !isTest 
        ? (service ? ACCESS_LIST_ACCOUNT_PRICE_DIALER_API : SMS_ACCESS_LIST_ACCOUNT_PRICE_DIALER_API) 
        : (service ? ACCESS_LIST_DEFAULT_PRICE_DIALER_API : SMS_ACCESS_LIST_DEFAULT_PRICE_DIALER_API);

    const {no_number, ...remainingFilter} = filter; 
    const params = {filter: remainingFilter, page, per_page, cancelToken};

    if (isAuth) {
        params.sort1 = 'sp_key';
        params.sort1_desc = true;
    }

    if (no_number !== undefined) {
        params.no_number = no_number;
    }
        
    return api(request, params).then((r) => {

        if (r.cancelled) {
            return;
        }

        if (r !== undefined) {
            dispatch( setAccessList({
                access_list_count: r.access_list_count || 0,
                access_list_list: r.access_list_list || [],
                page,
                per_page
            }) );
        }

        return (r && r.data && r.data.result) || {};
    }).catch((error) => {

        if (error && !error.response) {
            return error;
        }

        return error.response.data;
    })
    
    ;
};


export const setEmptyAccessList = () => (dispatch) => {
    dispatch( setAccessList({
        access_list_count: 0,
        access_list_list: [],
        page: 1
    }) );
};


export const downloadAccesses = (service, account_id = '', filter = {}, isAuth = false) => async (dispatch) => {
    
    const isTest = localStorage.getItem('userInfo') 
        ? JSON.parse(localStorage.getItem('userInfo')).session.is_test 
        : false;

        const isIncorporated = filter?.sp_key_list && filter.sp_key_list.includes(SP_KEY__INCORPORATED);

        const request = isAuth 
            ? !isTest
                ? ACCESS_LIST_OTP_ACCOUNT_PRICE_DIALER_API
                : ACCESS_LIST_OTP_DEFAULT_PRICE_DIALER_API
            : !isTest
                ? (service ? ACCESS_LIST_ACCOUNT_PRICE_DIALER_API : SMS_ACCESS_LIST_ACCOUNT_PRICE_DIALER_API) 
                : (service ? ACCESS_LIST_DEFAULT_PRICE_DIALER_API : SMS_ACCESS_LIST_DEFAULT_PRICE_DIALER_API);
    
    dispatch( setUploadLoading(true) );
    await getFileResponse(request, {
        filter,
        no_number: filter?.no_number,
        csv_result_key: 1
    })
        .then((response) => downloadDataAsFile(response, 'accesses_list.csv'))
        .catch( e => e );
    dispatch( setUploadLoading(false) );
};


export const allocateByAccess = (service=true, trunk_id, sde_key, numbers, random_number, ing_key) => async (dispatch) => {
    try {
        dispatch( setAccessLoadingAllocatedNumbers(true) );

        const subdestMethod = service ? ALLOCATION_SUBDESTINATION_DIALER_API : SMS_ALLOCATION_SUBDESTINATION_DIALER_API;
        const incorpMethod = service ? ALLOCATION_INCORPORATED_DIALER_API : SMS_ALLOCATION_GAN_API;
        const method = !ing_key ? subdestMethod : incorpMethod;
        
        const params = {
            target: service ? {
                trunk_id
            } : {
                'sms.trunk_id': trunk_id,
                ...(ing_key ? {"sms.supplier_gan_group_id": ing_key} : {})
            }, 
            random_number: random_number,
            numbers: +numbers,
        }


        if (ing_key) {
            if (service) {
                params.ing_key = ing_key;
            }
        } else {
            params.sde_key = sde_key;
        }
        

        const response = await api(method, params);

        dispatch( setAccessLoadingAllocatedNumbers(false) );

        if (response.response && response.response.data.error) {
            return response.response.data.error;
        }

        return response;

    } catch {
        dispatch( setAccessLoadingAllocatedNumbers(false) );
    }
};


export const getAccessListFiltersDialer = (id, service) => (dispatch) => {
    dispatch( setAccountAccessListFiltersLoadingDialer(true) );
    api(ACCESS_LIST_FILTER_GET_LIST_API,
        {
            target: {account_id: id},
            service: service ? 'voice' : 'sms'
        }
    )
        .then( ({access_list_filter_list}) => {
            let payload = access_list_filter_list;
            dispatch( setAccountAccessListFiltersDialer(payload) );
        } )
        .finally(() => {
            dispatch( setAccountAccessListFiltersLoadingDialer(false) );
        })
};