import React from "react";

import { FlexGrid, FlexGridItem } from "components/base/FlexGrid";
import {Icon} from "rsuite";
import {
    SMS_SUPPLIER_GAN_REVOKE_LIST_METHOD,
    SMS_SUPPLIER_GAN_REMOVE_LIST_METHOD,
    SMS_SUPPLIER_GAN_REMOVE_LIST_ALLOCATED_METHOD,
    SMS_SUPPLIER_GAN_SET_TEST_NUMBER_LIST_METHOD,
    SMS_SUPPLIER_GAN_UNSET_TEST_NUMBER_LIST_METHOD,
    SMS_SUPPLIER_GAN_SET_BLOCK_ALLOCATION_LIST_METHOD,
    SMS_SUPPLIER_GAN_UNSET_BLOCK_ALLOCATION_LIST_METHOD,
    SMS_ALLOCATION_GAN_BY_MANAGER_API
} from '../../../../../const/apiMethods';
import Allocate from "../../../../IncorporatedNumbers/IncorporatedNumbersList/Allocate/Allocate";
import Block from "../../../../IncorporatedNumbers/IncorporatedNumbersList/Block";
import Remove from "../../../../IncorporatedNumbers/IncorporatedNumbersList/Remove";
import Revoke from "../../../../IncorporatedNumbers/IncorporatedNumbersList/Revoke";
import SetOrUnsetTest from "../../../../IncorporatedNumbers/IncorporatedNumbersList/SetOrUnsetTest";
import Unblock from "../../../../IncorporatedNumbers/IncorporatedNumbersList/Unblock";
import {ButtonPrimary} from "../../../../../components/base/BaseButton";


export default (
    {
        selected,
        count,
        service,
        extraParams,
        onSuccess,
        hasSelected,
        hasBlocked,
        selectedItems,
        permissions,
        currentDisplay,
        currentGroupData,
        showReasonHandler,

        setShowLimit
    }
) => {
    const checkKey = "sms.supplier_gan";

    const filterAllExtraParams = !!selected?.all ? {
        ...extraParams,
        filterAll: selected.all 
    } : extraParams;

    const allocateExtraParams = !!selected?.all ? {
        ...extraParams,
        numbers: null
    } : extraParams;

    const removeAllocatedMethod = permissions.includes(SMS_SUPPLIER_GAN_REMOVE_LIST_ALLOCATED_METHOD) 
        ? SMS_SUPPLIER_GAN_REMOVE_LIST_ALLOCATED_METHOD 
        : SMS_SUPPLIER_GAN_REMOVE_LIST_METHOD;

    return (
        <>
            {currentDisplay === "numbers" ?
                <FlexGrid>
                    
                {/* {permissions.includes(SMS_SUPPLIER_GAN_REVOKE_LIST_METHOD) && */}
                    <FlexGridItem>
                        <Revoke
                            {...{
                                count,
                                checkKey,
                                selected,
                                extraParams: filterAllExtraParams
                            }}
                            disabled={!hasSelected}
                            onSuccess={onSuccess}
                            method={SMS_SUPPLIER_GAN_REVOKE_LIST_METHOD}
                        />
                    </FlexGridItem>
                {/* } */}

                {/* {permissions.includes('allocation:incorporated_by_manager') && */}
                    <FlexGridItem>
                        <Allocate
                            {...{
                                checkKey,
                                service,
                                selected,
                                currentGroupData,
                                extraParams: allocateExtraParams,
                                showReasonHandler
                            }}
                            isSupplier
                            disabled={!hasSelected || hasBlocked}
                            onSuccess={onSuccess}
                            method={SMS_ALLOCATION_GAN_BY_MANAGER_API}
                        />
                    </FlexGridItem>
                
                {/* {permissions.includes(SMS_SUPPLIER_GAN_SET_TEST_NUMBER_LIST_METHOD) && */}
                    <FlexGridItem>
                        <SetOrUnsetTest
                            {...{
                                checkKey,
                                selected,
                                extraParams: filterAllExtraParams
                            }}
                            disabled={!hasSelected || hasBlocked || selected.all}
                            onSuccess={onSuccess}
                            isSetTest={true}
                            methodSet={SMS_SUPPLIER_GAN_SET_TEST_NUMBER_LIST_METHOD}
                        />
                    </FlexGridItem>
                {/* } */}

                {/* {permissions.includes(SMS_SUPPLIER_GAN_UNSET_TEST_NUMBER_LIST_METHOD) && */}
                    <FlexGridItem>
                        <SetOrUnsetTest
                            {...{
                                checkKey,
                                selected,
                                extraParams: filterAllExtraParams
                            }}
                            disabled={!hasSelected || hasBlocked}
                            onSuccess={onSuccess}
                            isSetTest={false}
                            methodUnset={SMS_SUPPLIER_GAN_UNSET_TEST_NUMBER_LIST_METHOD}
                        />
                    </FlexGridItem>
                {/* } */}

                {/* {permissions.includes('incorporated_number:set_block_allocation') && */}
                    <FlexGridItem>
                        <Block
                            {...{
                                checkKey,
                                extraParams: filterAllExtraParams
                            }}
                            disabled={!hasSelected || hasBlocked || selected.all}
                            onSuccess={onSuccess}
                            method={SMS_SUPPLIER_GAN_SET_BLOCK_ALLOCATION_LIST_METHOD}
                        />
                    </FlexGridItem>
                {/* } */}

                {/* {permissions.includes('incorporated_number:unset_block_allocation') && */}
                    <FlexGridItem>
                        <Unblock
                            {...{
                                checkKey,
                                extraParams: filterAllExtraParams
                            }}
                            disabled={!hasSelected || (selected.list.length && !hasBlocked)}
                            onSuccess={onSuccess}
                            method={SMS_SUPPLIER_GAN_UNSET_BLOCK_ALLOCATION_LIST_METHOD}
                        />
                    </FlexGridItem>
                {/* } */}

                {/* {permissions.includes(SMS_SUPPLIER_GAN_REMOVE_LIST_METHOD) && */}
                    <FlexGridItem>
                        <Remove
                            {...{
                                checkKey,
                                extraParams: filterAllExtraParams,
                                showReasonHandler,
                            }}
                            disabled={!hasSelected}
                            onSuccess={onSuccess}
                            method={removeAllocatedMethod}
                        />
                    </FlexGridItem>
                {/* } */}

                {/* <FlexGridItem>
                    <ButtonPrimary
                        disabled={!hasSelected || selected.all}
                        onClick={() => setShowLimit(true)}
                    >
                        <Icon icon="unlock-alt"/> Set Limit
                    </ButtonPrimary>
                </FlexGridItem> */}
            </FlexGrid>
            : <></>}
        </>
    )
};