import {
    IVR_DEFAULT as PREFIX,
} from '../const';

const initialState = {
    ivrDefaultList: [],
    loading: true,
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {ivr_default_list}}) => ({...state, ivrDefaultList: ivr_default_list}),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}