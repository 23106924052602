import { FlexGrid, FlexGridItem } from "components/base/FlexGrid";
import React from "react";
import Allocate from "./Allocate/Allocate";
import Block from "./Block";
import Remove from "./Remove";
import Revoke from "./Revoke";
import SetOrUnsetTest from "./SetOrUnsetTest";
import Unblock from "./Unblock";
import {
    INCORPORATED_REVOKE_API,
    INCORPORATED_SET_TEST_API,
    INCORPORATED_UNSET_TEST_API,
    INCORPORATED_SET_BLOCK_API,
    INCORPORATED_UNSET_BLOCK_API,
    INCORPORATED_REMOVE_API,

    ALLOCATION_INCORPORATED_BY_MANAGER_API,
    SMS_ALLOCATION_GAN_BY_MANAGER_API,

    SMS_SUPPLIER_GAN_REVOKE_LIST_METHOD,
    SMS_SUPPLIER_GAN_REMOVE_LIST_METHOD,
    SMS_SUPPLIER_GAN_REMOVE_LIST_ALLOCATED_METHOD,
    SMS_SUPPLIER_GAN_SET_TEST_NUMBER_LIST_METHOD,
    SMS_SUPPLIER_GAN_UNSET_TEST_NUMBER_LIST_METHOD,
    SMS_SUPPLIER_GAN_SET_BLOCK_ALLOCATION_LIST_METHOD,
    SMS_SUPPLIER_GAN_UNSET_BLOCK_ALLOCATION_LIST_METHOD
} from "../../../const/apiMethods";
import {servicePick} from '../../../utils';


export default (
    {
        selected,
        count,
        service,
        extraParams,
        onSuccess,
        hasSelected,
        hasBlocked,
        permissions,
        currentGroupData,
        showReasonHandler,
    }
) => {
    const revokeMethod = servicePick(service, INCORPORATED_REVOKE_API, SMS_SUPPLIER_GAN_REVOKE_LIST_METHOD);
    const allocateMethod = servicePick(service, ALLOCATION_INCORPORATED_BY_MANAGER_API, SMS_ALLOCATION_GAN_BY_MANAGER_API);
    const setTestMethod = servicePick(service, INCORPORATED_SET_TEST_API, SMS_SUPPLIER_GAN_SET_TEST_NUMBER_LIST_METHOD);
    const unsetTestMethod = servicePick(service, INCORPORATED_UNSET_TEST_API, SMS_SUPPLIER_GAN_UNSET_TEST_NUMBER_LIST_METHOD);
    const blockMethod = servicePick(service, INCORPORATED_SET_BLOCK_API, SMS_SUPPLIER_GAN_SET_BLOCK_ALLOCATION_LIST_METHOD);
    const unblockMethod = servicePick(service, INCORPORATED_UNSET_BLOCK_API, SMS_SUPPLIER_GAN_UNSET_BLOCK_ALLOCATION_LIST_METHOD);
    
    const removeAllocatedMethod = permissions.includes(SMS_SUPPLIER_GAN_REMOVE_LIST_ALLOCATED_METHOD) 
        ? SMS_SUPPLIER_GAN_REMOVE_LIST_ALLOCATED_METHOD 
        : SMS_SUPPLIER_GAN_REMOVE_LIST_METHOD;
    
    const removeMethod = servicePick(service, INCORPORATED_REMOVE_API, removeAllocatedMethod);

    const checkKey = servicePick(service, 'incorporated_numbers', 'sms.supplier_gan');


    const filterAllExtraParams = !!selected?.all ? {
        ...extraParams,
        filterAll: selected.all 
    } : extraParams;

    const allocateExtraParams = !!selected?.all ? {
        ...extraParams,
        numbers: null
    } : extraParams;

    const isManagedDisabled = !service ? !currentGroupData?.is_managed : false;

    return (
        <>
            <FlexGrid>
                
                {permissions.includes(revokeMethod) &&
                    <FlexGridItem>
                        <Revoke
                            {...{
                                selected,
                                count,
                                extraParams: filterAllExtraParams,
                            }}
                            disabled={!hasSelected}
                            onSuccess={onSuccess}
                            method={revokeMethod}
                            checkKey={checkKey}
                        />
                    </FlexGridItem>
                }

                {permissions.includes(allocateMethod) &&
                    <FlexGridItem>
                        <Allocate
                            {...{
                                service,
                                selected,
                                currentGroupData,
                                extraParams: allocateExtraParams,
                                showReasonHandler
                            }}
                            disabled={!hasSelected || hasBlocked}
                            onSuccess={onSuccess}
                            method={allocateMethod}
                        />
                    </FlexGridItem>
                }

                {permissions.includes(setTestMethod) &&
                    <FlexGridItem>
                        <SetOrUnsetTest
                            {...{
                                selected,
                                extraParams: filterAllExtraParams,
                            }}
                            disabled={!hasSelected || hasBlocked || selected.all || isManagedDisabled}
                            onSuccess={onSuccess}
                            methodSet={setTestMethod}
                            checkKey={checkKey}

                            isSetTest
                        />
                    </FlexGridItem>
                }

                {permissions.includes(unsetTestMethod) &&
                    <FlexGridItem>
                        <SetOrUnsetTest
                            {...{
                                selected,
                                extraParams: filterAllExtraParams,
                            }}
                            disabled={!hasSelected || hasBlocked || selected.all || isManagedDisabled}
                            onSuccess={onSuccess}
                            methodUnset={unsetTestMethod}
                            checkKey={checkKey}

                            isSetTest={false}
                        />
                    </FlexGridItem>
                }

                {permissions.includes(blockMethod) &&
                    <FlexGridItem>
                        <Block
                            {...{
                                extraParams: filterAllExtraParams,
                            }}
                            disabled={!hasSelected || hasBlocked || selected.all}
                            onSuccess={onSuccess}
                            method={blockMethod}
                            checkKey={checkKey}
                        />
                    </FlexGridItem>
                }

                {permissions.includes(unblockMethod) &&
                    <FlexGridItem>
                        <Unblock
                            {...{
                                extraParams: filterAllExtraParams,
                            }}
                            disabled={!hasSelected || (selected.list.length && !hasBlocked) || selected.all}
                            onSuccess={onSuccess}
                            method={unblockMethod}
                            checkKey={checkKey}
                        />
                    </FlexGridItem>
                }

                {permissions.includes(removeMethod) &&
                    <FlexGridItem>
                        <Remove
                            {...{
                                extraParams: filterAllExtraParams,
                                showReasonHandler,
                            }}
                            disabled={!hasSelected || isManagedDisabled}
                            onSuccess={onSuccess}
                            method={removeMethod}
                            checkKey={checkKey}
                        />
                    </FlexGridItem>
                }
            </FlexGrid>
        </>
    )
};