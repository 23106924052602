import React from 'react';
import {FlexboxGrid} from 'rsuite';
import {useIntl} from "react-intl";
import {useWindowWidth} from "../../hooks";
import m from "../../definedMessages";
import {CustomModal} from "../../components/base";

export default ({maxWidth = 600, show, onClose, loading, ...props}) => {

    const {formatMessage} = useIntl();

    const resizedWidth = useWindowWidth();

    return (
        <CustomModal
            show={show}
            onClose={() => onClose(false)}
            title={formatMessage(m.revokeNumbers)}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showFooter={true}
            confirmButtonText={formatMessage(m.yes)}
            focusTarget="close"
            loading={loading}
            onConfirm={props.onSuccess}
        >
            <FlexboxGrid justify="end">
                <FlexboxGrid.Item colspan={23}>
                    <span>{formatMessage(m.numbersWillBeRevoked, {count: props.getCount()})} </span>
                </FlexboxGrid.Item>
            </FlexboxGrid>

        </CustomModal>
    );
};