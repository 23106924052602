import React from 'react';
import MyForm from './../../../../../components/MyForm';
import Checkbox from '../../../../../hoc/Checkbox';
import Text from '../../../../../components/Form/Text';
import SDE from '../../../../../components/Filters/SDE';
import CustomField from '../../../../../components/Form/CustomField';
import NumberField from '../../../../../components/Form/NumberField';
import {MIN_RATE_VALUE, ALLOCATION_NUMBER_LIMIT_PANEL} from '../../../../../const';
import {api, getFileResponse} from '../../../../../api/loginRoutes';
import {getBlobContent, getTargetByService} from "../../../../../utils";
import styled from 'styled-components';
import {Toggle, FormControl, Icon, Schema, SelectPicker} from "rsuite";
import {methodByService} from '../../../../../const/apiMethods';
import TemplateWhisper from "../../../../../components/Template/TemplateRegexWhisper";


const NUMBER_LIMIT = 10000;

const {NumberType} = Schema.Types;

export default ({
    onSelect, target, showResult, setPriceRangeNumbers, setCsvContent, activeTrunk, closeModal, onClose,
    setHideBufferButtons, spAuth = false, getTrunkNumberCsvById, setSummaryState, setTransactionId, 
    setTotalNumbersForced, setNotAllocatedNumbers, isMobile, service, getNewNumbers, supplierList, ...props
}) => {

    const [method, setMethod] = React.useState(methodByService['template'][service]);
    const [valueTemplate, setValueTemplate] = React.useState('');
    const [valueSde, setValueSde] = React.useState(null);
    const [pickedInput, changeInput] = React.useState(true); // true - template, false - subdestination
    const [valueForm, setValueForm] = React.useState({
        random_number: true,
        numbers: 10, 
        ranges: 1
    });
    const [showRateInput, onChangeShowRateInput] = React.useState(null);
    const [customRateValue, onChangeCustomRateValue] = React.useState(null);
    const [fieldsToRemove, setFieldsToRemove] = React.useState([]);
    
    // callback: on Form change
    const handleFormChange = async (newFormValues) => {
        setValueForm(newFormValues);
    };

    // input: Template
    const templateInput = (
        <StyledField>
            <TemplateContainer>
                <TemplateWhisper/>
                <Text
                    name="template"
                    validationKey={method === methodByService['template'][service] ? 'in_template_range' : null}
                    label="Template" width="md"
                    onChange={(value) => {
                        setValueTemplate(value);
                    }}
                    value={valueTemplate}
                    placeholder="1234567890XX"
                    onClick={() => {
                        setMethod(methodByService['template'][service]);
                        changeInput(true);
                        setValueSde(null);
                        setFieldsToRemove(["sde_key"]);
                    }}
                />
            </TemplateContainer>
        </StyledField>
    );


    // input: SubDestination
    const sdeInput = (
        <StyledField>
            <SDE
                name="sde_key"
                onChange={(value) => {
                    setValueSde(value);
                }}
                value={valueSde}
                validationKey="num_required"
                width={240}
                onClick={() => {
                    setMethod(methodByService['subdestination'][service]);
                    changeInput(false);
                    setValueTemplate(null);
                    setFieldsToRemove(["template"]);
                }}
            />
        </StyledField>
    );

    const model = {
        rate: NumberType()
            .min(MIN_RATE_VALUE, `The minimum of this field is ${MIN_RATE_VALUE}`)
            .max(10, 'The maximum of this field is 10'),
        numbers: NumberType()
            .isRequired('Required')
            .isInteger('Only whole numbers')
            .max(NUMBER_LIMIT, `The maximum of this field is ${NUMBER_LIMIT}`)
    };
    
    const handleUpdateNew = ({trunk_number_transaction, not_allocated_numbers}) => {
        if (trunk_number_transaction) {
            const transactionId = trunk_number_transaction.id;
            const summaryInfo = {...trunk_number_transaction};
            
            getNewNumbers(service, transactionId);

            setSummaryState(summaryInfo);
            setTransactionId(transactionId);

            setTotalNumbersForced(summaryInfo.numbers);

            setHideBufferButtons(false);
            setNotAllocatedNumbers(not_allocated_numbers);
            
            showResult();
        }
    };

    const fieldRate = !showRateInput

        ? <div
            style={{cursor: 'pointer', margin: '8px 20px 0 0', display: 'inline-block'}}
            onClick={() => {onChangeShowRateInput(true)}}
        >
            <Icon icon="book"
                  inverse
                  style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d', marginRight: '5px'}}
            />
            <a style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d',}}>Put custom rate</a>
        </div>
        : <>
            <div style={{display: 'inline-block', marginTop: '8px', marginRight: '10px'}}>Rate</div>
            <div style={{display: 'inline-block', marginRight: '10px'}}>

                <FormControl
                    style={{width: 140}}
                    errorPlacement="bottomStart"
                    placeholder="0.0001"
                    name="rate"
                    value={customRateValue}
                    onChange={(value) => {
                        onChangeCustomRateValue(value);
                    }}
                />

            </div>
            <div style={{display: 'inline-block', marginTop: '8px', marginRight: '10px'}}>
                <Icon
                    icon="close"
                    inverse
                    style={{
                        color: '#5e2f9d',
                        marginRight: '5px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        onChangeShowRateInput(false);
                        onChangeCustomRateValue(null)
                    }}
                />
            </div>
        </>;

    
    const targetByService = getTargetByService(target, service);
    
    return (
        <>
            <MyForm
                activeTrunk={activeTrunk}
                target={method === methodByService["subdestination"][service] ? {
                    ...targetByService, 
                    sde_key: valueSde,
                    ...(valueForm?.supplier_id ? {"sms.supplier_id": valueForm.supplier_id} : {})
                } : {
                    ...targetByService,
                    ...(valueForm?.supplier_id ? {"sms.supplier_id": valueForm.supplier_id} : {})
                }}
                method={method}
                checkResultKey="price_range_number_list"
                update={handleUpdateNew}
                {...props}
                clearFormValueByKeys={["supplier_id"]}
                fieldsToRemove={fieldsToRemove}
                className="numberForm"
                onChange={handleFormChange}
                formValue={valueForm}
                addData={{
                    rate: customRateValue !== null ? +customRateValue : null,
                    ...(!service ? {check_supplier_prefix: valueForm?.supplier_id ? true : false} : {})
                }}
                model={model}
                deepCloned={true}
                closeModal={closeModal}
                onClose={onClose}
            >

                {!isMobile
                    ? <>
                        {!pickedInput
                            ? <Fieldset name="template-disabled"> {templateInput} </Fieldset>
                            : templateInput
                        }
                        <span className="rs-form-inline rs-control-label">or</span>
                        {pickedInput
                            ? <>
                                <Fieldset name="sde-disabled"> {sdeInput} </Fieldset>
                            </>
                            : sdeInput
                        }
                        {!service ? <StyledSuppliersField
                            accepter={SelectPicker}
                            data={supplierList}
                            name="supplier_id"
                            label="Supplier"
                            placeholder="Suppliers"
                            valueKey="supplier_id"
                            labelKey="name"
                            cleanable={true}
                            searchable={true}
                        /> : <></>}
                        <br/>
                    </>
                    : <>
                        <StyledToggleWrapper>
                            <StyledToggle
                                // style={styles}
                                size="lg"
                                checkedChildren={"Template"}
                                className=""
                                unCheckedChildren={"Subdestination"}
                                defaultChecked={pickedInput}
                                onChange={changeInput}
                            />
                        </StyledToggleWrapper>
                        {pickedInput
                            ? templateInput
                            : sdeInput
                        }
                    </>
                }
               
                {/* Show Rate form */}
                {!isMobile && fieldRate}

                <StyledField>
                    <NumberField name="ranges" label="Ranges" max={Infinity} step={1} min={1}/>
                </StyledField>

                <StyledField>
                    <NumberField
                        name="numbers"
                        label="Numbers per range"
                        min={1}
                        max={NUMBER_LIMIT}
                    />
                </StyledField>

                <StyledField>
                    <Checkbox name="random_number" defaultChecked>Random order</Checkbox>
                </StyledField>

                {isMobile && <div>{fieldRate}</div>}
            </MyForm>
        </>
    );
};

const StyledSuppliersField = styled(CustomField)`
    && {
        width: 240px !important;
        display: block;
    }
`;

const Fieldset = styled.fieldset`
    border: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    
    .rs-input,
    .rs-btn {
        cursor: not-allowed !important;
    }
    
    > div > div > input {
        cursor: not-allowed
    }
    > div > div {
        opacity: 0.6;
        color: #888888
    }
`;

const StyledToggleWrapper = styled.div`
    && {
        margin: 0;
        width: 100%;
    }
`;

const StyledToggle = styled(Toggle)`
    && {
        background-color: #939191;
        margin: 0 0 20px;
        
        &:hover {
            background-color: #939191;
        }
    }
    &.rs-btn-toggle-checked {
        background-color: var(--color-brand1);
        
        &:hover {
            background-color: var(--color-brand1);
        }
    }
`;


const StyledField = styled.div`
    display: inline-block;
    
    @media (max-width: 567px) {
        display: block;
        padding-bottom: 10px;
        
        && .rs-control-label {
            display: block;
            margin-bottom: 5px;
            margin-top: 0;
        }
        
        && .rs-form-group {
            display: block;
        }
    }
`;

const TemplateContainer = styled.div`
    && {
        margin: 0;
        position: relative;
        width: 300px;
        height: 36px;
    }

    && .rs-icon {
        position: absolute;
        z-index: 100;
        top: 10px;
        right: 40px;
    }
`;
