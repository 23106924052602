import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {ApiRequest, API_Modal} from '../../components';
import { Form, Alert, Button, FormControl, Schema } from 'rsuite';
import Icon from 'rsuite/es/Icon';
import { hasErrorObject } from 'utils';
// import {ButtonClose} from "../../components/base/BaseButton";

const {StringType} = Schema.Types;

const closeFormModel = Schema.Model({
    reason: StringType()
        .isRequired("Required")
        .maxLength(256, "The maximum is only 256 characters.")
});

export default ({target, onUpdate}) => {
    const [show, setShow] = useState(false);

    const closeFormRef = useRef(null);
    const [closeFormValue, setCloseFormValue] = useState({});

    const formHasError = hasErrorObject(closeFormModel.check(closeFormValue));

    return (
        <>
            <StyledButtonClose
                appearance="ghost"
                onClick={() => setShow(true)}
            >
                <Icon icon="lock" style={{marginRight: 5}}/>
            </StyledButtonClose>
            { show && 
                <API_Modal
                    title="Close service"
                    onClose={() => setShow(false)}
                    extraDisabled={formHasError}
                >
                    <ApiRequest
                        method="account:remove"
                        target={target}
                        data={closeFormValue}
                        checkResultKey="account"
                        update={() => {
                            onUpdate();
                            Alert.success(`Account was closed`)
                        }}
                    >
                        <p>You are sure?</p>
                        <p>All numbers will revoke and all trunks remove</p>
                        <p>Reason for account closure</p>

                        <Form
                            ref={closeFormRef}
                            model={closeFormModel}
                            onChange={setCloseFormValue}
                        >
                            <FormControl
                                name="reason"
                                placeholder="Reason..."
                                componentClass="textarea"
                                style={{height: 200}}
                            />
                        </Form>
                    </ApiRequest>   
                </API_Modal>}
        </>
    );
}

const StyledButtonClose = styled(Button)`
    && {
        border-color: red !important;
        color: red !important;
        width: 34px;
        height: 34px;
        background-color: white;
    }
`;