import React from "react";
import {Redirect, Route, Switch} from "react-router";
import AuthRoute from "../components/Router/AuthRoute";
import Payments from "../pages/Payments";
import Accounts from "../containers/Accounts";
import AccountView from "../pages/Account";
import NewAccount from "../containers/CreateAccount";
import NewSupplier from "../containers/CreateSupplier";
import AccessList from "../pages/AccessList/AccessList";
import Rules from "../pages/Rules/RulesContainer";
import Search from "../pages/Search/SearchContainer";
import LiveCalls from "../pages/LiveCalls";
import Prices from "../pages/Prices/PricesRefactored";
import RangesNumbers from "../pages/RangesAndNumbers/Ranges";
import CDRPage from "../pages/CDR/CDRPage";
import TrafficReportsPage from "../pages/TrafficReports/TrafficReportsPage";
import AuthNumbers from "../pages/AuthNumbers";
import Reload from "../components/Reload";
import AccessListException from "../pages/AccessListException/AccessListException";
import ManualAccessList from "../pages/ManualAccessList/ManualAccessList";
import SIMTrafficRangesPage from "../pages/SIMTrafficRanges/SIMTrafficRangesPage";
import BlockedDestinationsPage from "../pages/BlockedDestinations/BlockedDestinationsPage";
import ActionLogs from "../pages/ActionLogs";
import AmActivities from "../pages/AmActivities";
import AccountManagers from "../pages/AccountManagers";
import SettingsPage from "../pages/Settings/SettingsPage";
import PriceRangeQuarantineSetting from "../pages/PriceRangeQuarantineSetting";
import RegistrationRequests from '../pages/RegistrationRequests';
import {DEFAULT_AUTH_PATH_ADMIN, DEFAULT_AUTH_PATH_ADMIN_SMS} from "../const";
import Ivr from "../pages/Ivr/Ivr";
import Users from "../pages/Users";
import NewsManagement from "../pages/NewsManagement";
import IncorporatedNumbers from "../pages/IncorporatedNumbers";
import RedirectPage from "../pages/Redirect";
import MassRevoke from "../pages/MassRevoke";
import GanSuppliers from "../pages/GanSuppliers";
import Suppliers from "../pages/Suppliers";
import {servicePick} from "utils";
import RoutingPage from "../pages/Routing/RoutingPage";
import E164Page from "../pages/e164/E164Page";
import E212Page from "../pages/E212/E212Page";
import DialerGroups from "../pages/DialerGroups";

 //
// ATX-2922
//
export default ({auth, service, authInfo}) => {
    const redirectVoicePath = `${DEFAULT_AUTH_PATH_ADMIN}?service=voice`;
    const redirectSmsPath = `${DEFAULT_AUTH_PATH_ADMIN_SMS}?service=sms`;
    const supervisorSmsRole =  authInfo?.session?.account_user?.role_list.includes("Supervisor (sms)");

    return (
        <Switch>
            <AuthRoute exact path="/payments" component={Payments}/>
            <AuthRoute exact path="/accounts" component={Accounts}/>
            {/* <AuthRoute exact path="/dialers" component={Dialers}/> */}
            <AuthRoute exact path="/sms-suppliers" component={Suppliers}/>
            <AuthRoute exact path="/sms-suppliers/view/:id" component={AccountView}/>
            <AuthRoute exact path="/sms-suppliers/new" component={NewSupplier}/>
            <AuthRoute exact path="/accounts/view/:id" component={AccountView}/>
            <AuthRoute exact path="/accounts/new" component={NewAccount}/>
            <AuthRoute exact path="/access-list" component={AccessList}/>
            <AuthRoute exact path="/otp-rules" component={Rules}/>
            <AuthRoute exact path="/otp-search" component={Search}/>
            <AuthRoute exact path="/live-calls" component={LiveCalls}/>
            <AuthRoute exact path="/prices" component={Prices}/>
            <AuthRoute exact path="/ranges-numbers" component={RangesNumbers}/>
            <AuthRoute exact path="/cdr" component={CDRPage}/>
            <AuthRoute exact path="/traffic-reports" component={TrafficReportsPage}/>
            <AuthRoute exact path="/failed-mdr" component={CDRPage}/>
            <AuthRoute exact path="/failed-mdr-reports" component={TrafficReportsPage}/>
            <AuthRoute exact path="/auth-numbers" component={AuthNumbers}/>
            <AuthRoute exact path="/reload" component={Reload}/>
            <AuthRoute exact path="/exceptions-accesses" component={AccessListException}/>
            <AuthRoute exact path="/manual-accesses" component={ManualAccessList}/>
            <AuthRoute exact path="/blocked-destinations" component={BlockedDestinationsPage}/>
            <AuthRoute exact path="/action-logs" component={ActionLogs}/>
            <AuthRoute exact path="/am-activities" component={AmActivities}/>
            <AuthRoute exact path="/account-managers" component={AccountManagers}/>
            <AuthRoute exact path="/settings" component={SettingsPage}/>
            <AuthRoute exact path="/routing" component={RoutingPage}/>
            <AuthRoute exact path="/e164" component={E164Page}/>
            <AuthRoute exact path="/e212" component={E212Page}/>
            <AuthRoute exact path="/special-quarantine-terms" component={PriceRangeQuarantineSetting}/>
            <AuthRoute exact path="/registration-requests" component={RegistrationRequests}/>
            <AuthRoute exact path="/ivrs" component={Ivr}/>
            <AuthRoute exact path="/admin-panel-users" component={Users}/>
            <AuthRoute exact path="/news-management" component={NewsManagement}/>
            <AuthRoute exact path="/news-dialer-groups" component={DialerGroups}/>
            <AuthRoute exact path="/global-access-numbers" component={IncorporatedNumbers}/>
            <AuthRoute exact path="/mass-revoke" component={MassRevoke}/>
            <AuthRoute exact path="/suppliers" component={GanSuppliers}/>
            <Route path="/redirect" component={RedirectPage}/>

            {authInfo?.session?.account_user?.role_list.includes('Ratemod') &&
            <Redirect to={'global-access-numbers'}/>
            }

            <Redirect to={servicePick(service, redirectVoicePath, redirectSmsPath)}/>
        </Switch>
    )
}