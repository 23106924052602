import React, {useMemo, useState, useEffect, useContext} from "react";

import {useIntl} from "react-intl";
import styled, {css} from "styled-components";

import {EOL} from "const";

import {
    Alert, FlexboxGrid, Icon, List, Panel,
    Button, ButtonToolbar, Dropdown, Loader
} from "rsuite";
import {useWindowWidth} from "../../../hooks";

import {
    copyToClipboard, makeNumbersFromPrefixesToCsv, between, renderColumn, 
    arrayToObject, getRangeByDigit, getCsvDataWithHeaders,
} from "utils/helpers";

import {Spacer} from "../../base/Spacer";
import BaseTable from "../../base/BaseTable";
import {CustomModal} from "../../base";

import {RtlContext} from "../../../App";
import m from "../../../definedMessages";
import {
    downloadCompletedList,
    downloadNumbersCommonClient,
    downloadCompletedListAsPrefixes,
    downloadNumbersSimpleCommonClient,
    downloadNumbersAsPrefixesCommonClient, makeRangeFromPrefixesToCsv,
} from "utils";
import { Link } from "react-router-dom";


const headerStyle = {
    background: "#fff",
    color: "#000"
};


export default (
    {
        show,
        trunks,
        trunkId,
        csvContent,

        service,
        loading,
        isSubacc = false,
        maxWidth = 600,
        priceData = {},
        allAllocated=false,
        methodName,
        extraColumns,
        uploadLoading,
        prefixViewNumbers,
        notAllocatedNumbers,
        trunkAllocatedByOTP,
        allocatedNumbersCount,
        trunkNumberTransaction,
        showWarningAllocationMessage,

        onClose,
        setUploadingLoading,
    }
) => {

    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);
    const windowWidth = useWindowWidth();
    const [isEnetered, setIsEntered] = React.useState(false);
    
    const isMobile = windowWidth < 720;

    const statusLabels = useMemo(() => ({
        1: formatMessage(m.numberIsUnavailable),
        3: formatMessage(m.destinationDoesNotExist),
        4: formatMessage(m.noRateForThisDestination),
        7: formatMessage(m.numberIsQuarantined),
        10: formatMessage(m.numbersFromRangeCannotBeAllocatedSingle),
    }), []);


    const columns = useMemo(() => [
        {
            id: "Prefix",
            dataKey: "Prefix",
            align: "center",
            className: "number",
            label: formatMessage(m.prefix),
            headerStyle: headerStyle,
            width: 160,
            ...(!isMobile ? {flexGrow: 1} : {})
            
        },
        {
            id: "Range name",
            dataKey: "Range name",
            align: "left",
            label: formatMessage(m.rangeName),
            className: "range-name",
            headerStyle: headerStyle,
            width: 260,
            ...(!isMobile ? {flexGrow: 2} : {})
        },
        {
            id: "Payout",
            dataKey: "Payout",
            align: "center",
            label: formatMessage(m.payout),
            headerStyle: headerStyle,
            width: 75,
            ...(!isMobile ? {flexGrow: 1} : {})
        },
        {
            id: "Currency",
            dataKey: "Currency",
            align: "center",
            label: formatMessage(m.currency),
            headerStyle: headerStyle,
            width: 75,
            ...(!isMobile ? {flexGrow: 1} : {})
        },
        ...(!isSubacc ? (service ? [{
            id: "Billing increment",
            dataKey: "Billing increment",
            align: "center",
            label: formatMessage(m.billingIncrement),
            headerStyle: headerStyle,
            width: 110,
            ...(!isMobile ? {flexGrow: 1} : {})
        }] : []) : []),
    ], [service, isSubacc, isMobile]);


    const copyNumbers = (csvData) => {
        if (!csvData || !csvData.length)
            return [];

        const dataArr = csvData.slice(1).map(line => line.split(";")[0]);
        // if (trunkNumberTransaction.method2 === "google_otp") {

        //     const chunksTotal = dataArr.length / 100;
        //     const chunks = [];
        //     for (let i = 0; i < chunksTotal; i++) {
        //         const startItem = i * 100;
        //         const endItem = startItem + 100;
        //         const chunk = dataArr.slice(startItem, endItem);
        //         chunk.sort(() => Math.random() - 0.5);
        //         chunk.sort(() => Math.random() - 0.5);
        //         chunks.push(chunk);
        //     }

        //     return chunks.reduce((sum, current) => {
        //         return [...sum, ...current];
        //     }, []).join(EOL);
        // }
        return dataArr.join(EOL);
    };


    const renderCsvDataToTableData = (data, headers) => {
        const csvBody = data.length ? data.slice(1) : [];

        return (
            csvBody.map(line => {
                const items = line.split(";");
                const prefix = getRangeByDigit(items[0], items[1]);

                const body = items.map((item, index) => {
                    if (between(index, 2, 5)) {
                        return {[headers[index]]: item}
                    }
                    return null;
                }).filter(value => value);

                return {
                    [headers[0]]: prefix,
                    ...(arrayToObject(body))
                }
            })
                .filter(obj => Object.keys(obj).some(key => obj[key]))
        );
    };


    const handleDownloadCompletedList = () => {
        setUploadingLoading(trunkId, true);
        downloadCompletedList(trunkId, service, () => setUploadingLoading(trunkId, false), isSubacc);
    }


    const handleDownloadCompletedListAsPrefixes = () => {
        setUploadingLoading(trunkId, true);
        downloadCompletedListAsPrefixes(trunkId, service, () => setUploadingLoading(trunkId, false));
    }


    const handleDownloadNewNumbers = () => {
        setUploadingLoading(trunkId, true);
        downloadNumbersCommonClient(csvContent);
        setUploadingLoading(trunkId, false);
    }

    const handleDownloadNewNumbersAsPrefixes = () => {
        setUploadingLoading(trunkId, true);
        downloadNumbersAsPrefixesCommonClient(csvContent);
        setUploadingLoading(trunkId, false);
    }

    const handleDownloadNewNumbersAsTxt = () => {
        setUploadingLoading(trunkId, true);
        downloadNumbersSimpleCommonClient(csvContent);
        setUploadingLoading(trunkId, false);
    }


    const numberListNotAllocated = notAllocatedNumbers || [];
    const uploadLoadSetting = uploadLoading[trunkId];

    let combinedTitle = formatMessage(m.newAllocatedNumbers);

    if (methodName === "Trunks") {
        combinedTitle = formatMessage(m.newAllocatedNumbers);
    } else if (methodName === "Ratecard") {
        combinedTitle = priceData.range_name
            ? `${formatMessage(m.getNumbersForRange)} ${priceData.range_name}`
            : formatMessage(m.newAllocatedNumbers);
    } else if (methodName === "AccessList") {
        combinedTitle = formatMessage(m.allocatedNumberList);
    }

    const activeTrunk = useMemo(() => {
        return trunks.find(({id}) => {
            return trunkId === id;
        }) || {};
    }, [trunkId, trunks]);


    const csvDataList = csvContent ? csvContent.split("\n") : [];
    const csvHeaders = csvDataList.length ? csvDataList[0].split(";") : [];
    const comparedData = renderCsvDataToTableData(csvDataList, csvHeaders);

    const tableColumns = extraColumns ? extraColumns : columns;
    const renderedColumns = tableColumns.map(renderColumn);

    const [copiedToClipboard, setCopiedToClipboard] = useState(false);

    useEffect(() => {
        if (activeTrunk && !!activeTrunk.sp_auth && csvDataList.length && !copiedToClipboard) {
            const copyText = formatMessage(m.pleaseNoteAllocatedNumbers);

            copyToClipboard(copyText);
            setCopiedToClipboard(true);
            Alert.success("Copied!");
        }
    }, [activeTrunk, csvDataList]);

    if ( rtl ) renderedColumns.reverse();


    return (
        <CustomModal
            show={show}
            successButton={formatMessage(m.getNumbers)}
            title={combinedTitle}
            width={windowWidth > maxWidth ? maxWidth : windowWidth}
            height={800}
            showCloseSuccessButtons={false}
            onEntered={() => {
                setTimeout( () => {
                    setIsEntered(true);
                }, 500 );
            }}
            onClose={() => {
                setIsEntered(false);
                setCopiedToClipboard(false);
                onClose();
            }}
        >

            {service ? <div>
                <Icon icon="exclamation-triangle"/> {formatMessage(m.payYourAttention)}
            </div> : <></>}

            <Spacer/>

            <div className="allocation-total">{formatMessage(m.numbersWereAllocated, {count: allocatedNumbersCount || 0})}</div>

            <Spacer size={10}/>

            {showWarningAllocationMessage && <div className="allocation-warning">{formatMessage(m.amountOfOrderedNumbersExceedsPolicy)}</div>}

            <Spacer size={10}/>

            {!!activeTrunk.sp_auth && <WhitelistNotice>
                {formatMessage(m.pleaseNoteAllocatedNumbers)}
                <StyledLink
                    to={"/auth-numbers"}
                >
                    {formatMessage(m.noteAllocatedNumbersLink)}
                </StyledLink>
            </WhitelistNotice>}

            <Spacer size={20}/>

            <StyledGrid justify="space-between">


                {!allAllocated ? <StyledButtonToolbar>
                    <StyledDropdown
                        title={<>{uploadLoadSetting && <StyledLoader/>} {formatMessage(m.copyList)}</>}
                        disabled={uploadLoadSetting || !allocatedNumbersCount}
                    >
                        <Dropdown.Item
                            onClick={ () => {
                                const csvData = makeNumbersFromPrefixesToCsv(csvContent);
                                const csvDataList = csvData && csvData.length ? csvData.split("\n") : [];
                                if (!allocatedNumbersCount) {
                                    Alert.warning( formatMessage(m.unableToCopyEmptyTable) )
                                } else {
                                    copyToClipboard( copyNumbers(csvDataList) );
                                    Alert.success( formatMessage(m.copied) )
                                }
                            }}
                        >
                            {formatMessage(m.copyJustNumbers)}
                        </Dropdown.Item>

                        {/* {prefixViewNumbers &&
                        <Dropdown.Item
                            onClick={() => {
                                const csvData = prefixViewNumbers
                                    ? makeRangeFromPrefixesToCsv(csvContent)
                                    : csvContent;

                                const csvDataList = csvData && csvData.length
                                    ? csvData.split("\n")
                                    : [];

                                if (!allocatedNumbersCount) {
                                    Alert.warning( formatMessage(m.unableToCopyEmptyTable) )
                                } else {
                                    copyToClipboard( getCsvDataWithHeaders(csvDataList).join(EOL) );
                                    Alert.success( formatMessage(m.copied) )
                                }
                            }}
                        >
                            {formatMessage(m.copyListAllocatedByPrefix)}
                        </Dropdown.Item>
                        } */}

                        <Dropdown.Item
                            onClick={() => {
                                const csvData = makeNumbersFromPrefixesToCsv(csvContent);
                                const csvDataList = csvData && csvData.length ? csvData.split("\n") : [];
                                if (!allocatedNumbersCount) {
                                    Alert.warning( formatMessage(m.unableToCopyEmptyTable) )
                                } else {
                                    copyToClipboard( getCsvDataWithHeaders(csvDataList).join(EOL) );
                                    Alert.success( formatMessage(m.copied) )
                                }
                            }}
                        >
                            {formatMessage(m.copyList)}
                        </Dropdown.Item>
                    </StyledDropdown>
                </StyledButtonToolbar> : <></>}



                <StyledButtonToolbar>
                    <StyledDropdown
                        title={<>{uploadLoadSetting && <StyledLoader/>} {formatMessage(m.downloadList)}</>}
                        disabled={uploadLoadSetting}
                        onSelect={() => setUploadingLoading(trunkId, true)}
                    >

                        <Dropdown.Item
                            onClick={handleDownloadCompletedList}>
                            {windowWidth > 400
                                ? <span>{formatMessage(m.downloadCompleteList)}</span>
                                : <span>{formatMessage(m.downloadAllNumbers)}</span>
                            }
                        </Dropdown.Item>

                        {/* {prefixViewNumbers && !allAllocated && <Dropdown.Item
                            onClick={handleDownloadCompletedListAsPrefixes}
                        >
                            {formatMessage(m.downloadCompletePrefixesListAs, {type: "CSV"})}
                        </Dropdown.Item>} */}

                        {!allAllocated && <Dropdown.Item
                            disabled={!allocatedNumbersCount}
                            onClick={handleDownloadNewNumbers}>
                            {formatMessage(m.downloadNewAllocatedNumbers)}
                        </Dropdown.Item>}

                        {/* {prefixViewNumbers && !allAllocated && 
                            <Dropdown.Item
                                disabled={!allocatedNumbersCount}
                                onClick={handleDownloadNewNumbersAsPrefixes}
                            >
                                {formatMessage(m.downloadNewAllocatedNumbersAsRanges)}
                        </Dropdown.Item>} */}

                        {!allAllocated && <Dropdown.Item
                            disabled={!allocatedNumbersCount}
                            onClick={handleDownloadNewNumbersAsTxt}
                        >
                            {formatMessage(m.downloadOnlyNumbers)}
                        </Dropdown.Item>}

                    </StyledDropdown>
                </StyledButtonToolbar>



                <StyledButton
                    onClick={() => {
                        setIsEntered(false);
                        setCopiedToClipboard(false);
                        onClose(false);
                    }}
                >
                    <Icon icon="window-close-o"/> {formatMessage(m.close)}
                </StyledButton>

            </StyledGrid>

            <FlexboxGrid justify="space-around">
                <StyledTable
                    className="access-table"
                    virtualized={true}
                    height={250}
                    width={windowWidth > maxWidth ? maxWidth - 100 : windowWidth - 100}
                    data={comparedData}
                    rowHeight={35}
                    wordWrap={windowWidth > maxWidth}
                    loading={loading || !isEnetered}
                >
                    {renderedColumns}
                </StyledTable>

            </FlexboxGrid>

            {numberListNotAllocated.length !== 0 &&
            <>
                <Spacer size={30}/>
                <Panel
                    bordered
                    collapsible
                    header={formatMessage(m.numbersWereNotAllocated, {
                        count: numberListNotAllocated.length
                    })}
                >
                    <StyledList>
                        {numberListNotAllocated.map( (item, index) => (
                            <List.Item
                                key={index}
                                index={index}
                            >
                                {statusLabels[item.status]
                                    ? `${item.number} ${statusLabels[item.status]}`
                                    : formatMessage(m.cannotBeAllocated, {number: item.number})
                                }
                            </List.Item>
                        ))}
                    </StyledList>
                </Panel>
            </>
            }

        </CustomModal>
    );
};


const StyledGrid = styled(FlexboxGrid)`
    padding: 0 20px 20px 20px;
    width: 100%;
`;


const StyledDropdown = styled(Dropdown).attrs(props => {
    return {
        appearance: "ghost",
        color: "violet"
    }
})`
    width: 100%;
    
    & > a {
        width: 100%;
    }
    
    .rs-btn {
        padding-right: 20px !important;
    }
    
    ul.rs-dropdown-menu {
        background-color: white;
    }
    
    ul.rs-dropdown-menu li.rs-dropdown-item:hover, 
    ul.rs-dropdown-menu li.rs-dropdown-item:focus, 
    ul.rs-dropdown-menu li.rs-dropdown-item:active,
    ul.rs-dropdown-menu li.rs-dropdown-item a.rs-dropdown-item-content:hover, 
    ul.rs-dropdown-menu li.rs-dropdown-item a.rs-dropdown-item-content:focus, 
    ul.rs-dropdown-menu li.rs-dropdown-item a.rs-dropdown-item-content:active {
        background-color: #c5c5c5;
    }
`;


const StyledButtonToolbar = styled(ButtonToolbar)`
    width: 30%;
    display: inline-block; 
    ${props =>
    props.width && css`
            width: ${props.width};
    `};

    @media (max-width: 600px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;


const StyledTable = styled(BaseTable)`
    && {
        box-shadow: none;
    }

    .rs-table-cell.range-name .rs-table-cell-content {
        word-break: break-word;
        overflow: visible;
        font-size: 12px;
        //margin-top: -5px;
        display: flex;
        align-items: center;
    }
    
    .rs-table-cell.number .rs-table-cell-content {
        word-break: break-word;
        overflow: visible;
        font-size: 13px;
        //margin-top: -5px;
        display: flex;
        justify-content: center;
        align-items: center;
}
`;


const StyledButton = styled(Button).attrs(() => ({
    appearance: "ghost",
    color: "violet"
}))`

    && {
        width: 30%
    };
    
    @media (max-width: 600px) {
        width: 100% !important;
    }
`;


const StyledList = styled(List).attrs(props => ({
    size: "sm",
    hover: true
}))`
    width: 100%;
`;


const StyledLoader = styled(Loader)`
    & span.rs-loader-spin {
        margin-top: 3px;
        margin-right: 5px;
    }
`;

const StyledLink = styled(Link)`
    && {
        color: var(--color-secondary);
        /* text-decoration: underline; */
    }
`;

const WhitelistNotice = styled.div`
    && span {
        padding-right: 5px;
    }
`;