import {
    SET_SERVICE,
    DEFAULT_AUTH_PATH_ADMIN,
    DEFAULT_AUTH_PATH_ADMIN_RATEMOD,
    DEFAULT_NOT_AUTH_PATH,
    SET_AUTH,
    SET_LOADING_INFO,
    SET_USER_LOGIN_INFO,
    SET_MAINTENANCE_MODE,
    SET_USER_LOCALIZATION,
    APP_TYPE_ADMIN,
    DEFAULT_AUTH_PATH_CLIENT_TEST,
    DEFAULT_AUTH_PATH_CLIENT,
    HAS_BOTH_SERVICE_VALUE,
    SET_INITED_REFERENCFES,
    APP_TYPE_CLIENT
} from '../const';
import {langFullNames, DEFAULT_ERROR_MESSAGE, SET_INFO_ACCOUNT} from '../const';
import {login, logout} from '../api/auth';
import {createAction} from  './defaults';
import { setRoles } from './index';
import history from '../config/history';
import {NEED_SECOND_AUTH, SET_PERMISSION, SET_PERMISSION_ADD} from "./actionTypes";
import {api} from "../api/loginRoutes";
import {Alert} from 'rsuite';
import {LOCAL_STORAGE_USER_INFO} from '../const/localStorageKeys';
import {ACCOUNT_USER_GET_SESSION_DIALER_API, SET_RULES_ACCEPT} from '../const/apiMethods';


export const setAuth = (payload, appType = false) => ({type: SET_AUTH, payload, appType});

const setLoading = createAction(SET_LOADING_INFO);
const setInfoAccount = createAction(SET_INFO_ACCOUNT);
const setUserLoginInfo = createAction(SET_USER_LOGIN_INFO);
const setMaintenanceModeAction = createAction(SET_MAINTENANCE_MODE);
const setSecondAuthType = createAction(NEED_SECOND_AUTH);
const setUserLocals = createAction(SET_USER_LOCALIZATION);
const setNotAccepted = createAction(SET_RULES_ACCEPT);
const setInited = createAction(SET_INITED_REFERENCFES);

export const setServiceAction = createAction(SET_SERVICE);

export const setMaintenanceMode = (value) => (dispatch) => {
    dispatch( setMaintenanceModeAction(value) );
    if (value) {
        localStorage.removeItem('api_key');
        localStorage.removeItem(LOCAL_STORAGE_USER_INFO);
        dispatch( setAuth(false) );
        dispatch( setInfoAccount(null) );
    }
};


export const loginBySessionKey = (id) => (dispatch) => {
    localStorage.setItem('api_key', id);

    api(ACCOUNT_USER_GET_SESSION_DIALER_API)
        .then(r => {
            if (!r) {
                localStorage.removeItem('api_key');
                history.push(DEFAULT_NOT_AUTH_PATH);
             
                // ATX-2922
                // localStorage.removeItem("SMS_SERVICE");
                // setServiceAction(true);
              
                return;
            }
            
            const sessionSite = r.session.site;
            const sessionLang = 'en';
            const sessionLRtl = sessionLang === "ar";

            // save data in LocalStorage
            localStorage.setItem('lang', sessionLang);
            localStorage.setItem('rtl', sessionLRtl);
            localStorage.setItem('api_key', r.session.session_key);
            localStorage.setItem('userInfo', JSON.stringify(r));

            dispatch( setAuth(true, sessionSite) );
            dispatch( setRoles(r.session.account_user.role_list) );
            dispatch( setUserLoginInfo(r) );
            dispatch( setUserLocals({lang: sessionLang, rtl: sessionLRtl}) );

            const accountList = r.session?.account_list;

            if (accountList.length === 1 && accountList[0]?.service === "sms") {
                history.push(`${DEFAULT_AUTH_PATH_CLIENT}-sms`);
                return;
            }

            history.push(`${DEFAULT_AUTH_PATH_CLIENT}-voice`);
        });
}


export const loginUser = ({lang = "en", loginHistory, loginLocation, ...data}) => (dispatch) => {
    dispatch(setLoading(true));

    const loginRecently = JSON.parse(sessionStorage.getItem("login_recently"));
    const logoutRecently = JSON.parse(sessionStorage.getItem("logout_recently"));

    const lastPageBeforeSessionExpired = localStorage.getItem("LAST_PAGE_BEFORE_SESSION_EXPIRED");

    return login(data)
        .then((response) => {
            if (!response) {
                return;
            }

            const result = response?.data?.result || {};

            const secondAuthType = result.second_authentication_type;

            sessionStorage.setItem("secondAuth", !!secondAuthType);

            if (secondAuthType) {
                dispatch(setSecondAuthType({
                    secondAuthType: secondAuthType === 1
                        ? 'email'
                        : 'phone',
                    secondAuthLink: secondAuthType === 1
                        ? result.email
                        : result.phone,
                    secondAuthEmail: result.email
                }));

                return;
            }

            const sessionSite = result?.session?.site;
            const storedSessionSite = sessionStorage.setItem("sessionSite", sessionSite);

            if (!sessionSite) {
                if (result?.reason) {
                    Alert.error(result.reason);
                }

                return;
            }

            const sessionLang = sessionSite === APP_TYPE_ADMIN ? 'en' : lang;
            const sessionLRtl = sessionLang === "ar";
            const isTest = result.session.is_test;

            // save data in LocalStorage
            localStorage.setItem('lang', sessionLang);
            localStorage.setItem('rtl', sessionLRtl);
            localStorage.setItem('api_key', result.session.session_key);
            localStorage.setItem('userInfo', JSON.stringify(result));

            dispatch( setAuth(true, sessionSite) );
            dispatch( setRoles(result.session.account_user.role_list) );
            dispatch( setUserLoginInfo(result) );
            dispatch( setUserLocals({lang: sessionLang, rtl: sessionLRtl}) );
            // dispatch( setInited(false) );

            const roleList = result.session.account_user.role_list;
            const isRatemod = roleList.includes('Ratemod');
            const isRatemodSms = roleList.includes('Ratemod (sms)');

            if (!lastPageBeforeSessionExpired) {
                if (isRatemod) {
                    const path = `${DEFAULT_AUTH_PATH_ADMIN_RATEMOD}?service=voice`;
                    history.push(path);
                    return;
                }
                
                if (isRatemodSms) {
                    const path = `${DEFAULT_AUTH_PATH_ADMIN_RATEMOD}?service=sms`;
                    history.push(path);
                    return;
                }

                if (sessionSite === APP_TYPE_ADMIN) {
                    // after login nothing happend
                    const path = `${DEFAULT_AUTH_PATH_ADMIN}`;
                    
                    // history.replace('/reload');

                    history.replace('/reload');
                    history.push(path);
        
                    return response;
                }

                if (isTest) {
                    const path = `${DEFAULT_AUTH_PATH_CLIENT_TEST}`;
                    history.push(path);
                    return;
                }
                
                if (sessionSite === APP_TYPE_CLIENT) {
                    history.push(`${DEFAULT_AUTH_PATH_CLIENT}-voice`);
                }
            }
            return response;

        })
        .catch((error) => {
            dispatch(setAuth(false));

            const reasonCode = error?.response?.data?.error?.reason_code;
            if (!reasonCode) {
                Alert.error(error?.response?.data?.error?.data?.error_type || DEFAULT_ERROR_MESSAGE);
            }

            return error;
            // return Promise.reject(error);

        })
        .finally(() => {
            const location = !loginRecently || (!loginRecently && logoutRecently) ? history.location : loginLocation;
            // !logoutRecently 
            const isSecondAuth = JSON.parse(sessionStorage.getItem("secondAuth"));
 
            // const lastPageBeforeSessionExpired = localStorage.getItem("LAST_PAGE_BEFORE_SESSION_EXPIRED");

            if (!isSecondAuth) {
                extraHistoryPush(location.pathname)
                    .then((path) => {
                        // const sessionSite = sessionStorage.getItem("sessionSite");
                        
                        // history.replace("/reload");

                        // if (sessionSite && parseInt(sessionSite) === APP_TYPE_ADMIN) {
              
                        //     history.push({
                        //         pathname: DEFAULT_AUTH_PATH_ADMIN
                        //     });
                        // } else {
                        //     history.push({
                        //         pathname: `${DEFAULT_AUTH_PATH_CLIENT}-voice`
                        //     });
                        // }

                        if (!lastPageBeforeSessionExpired) {
                            if (localStorage.getItem("SESSION_EXPIRED")) {
                                history.goBack();

                                const sessionSite = sessionStorage.getItem("sessionSite");
                        
                                if (sessionSite && parseInt(sessionSite) === APP_TYPE_ADMIN) {
                                    history.push({
                                        pathname: DEFAULT_AUTH_PATH_ADMIN
                                    });
                                } else {
                                    history.push({
                                        pathname: `${DEFAULT_AUTH_PATH_CLIENT}-voice`
                                    });
                                }

                                history.goForward();
                            }

                            setTimeout(() => {
                                history.goForward();
                            }, 300);
                        }
                    })
                    .catch(() => {
                        console.log('Login')
                    })
                    .finally(() => {
                        const sessionSite = sessionStorage.getItem("sessionSite");
                        if (!lastPageBeforeSessionExpired) {
                            history.replace('/reload');

                            if (sessionSite && parseInt(sessionSite) === APP_TYPE_ADMIN) {
                                history.push({
                                    pathname: DEFAULT_AUTH_PATH_ADMIN
                                });
                            } else {
                                history.push({
                                    pathname: `${DEFAULT_AUTH_PATH_CLIENT}-voice`
                                });
                            }
                        }

                        localStorage.removeItem("SESSION_EXPIRED");
                    })
            }
            dispatch(setLoading(false));
            sessionStorage.removeItem("logout_recently");
            sessionStorage.setItem("login_recently", true);
            sessionStorage.removeItem("secondAuth");
            localStorage.removeItem("LAST_PAGE_BEFORE_SESSION_EXPIRED");
            localStorage.removeItem("LAST_SERVICE_BEFORE_SESSION_EXPIRED");
        })
};

const extraHistoryPush = (path) => {
    return new Promise((resolve, reject) => {
        if (path === "/login") {
            resolve(path);
        } else {
            reject();
        }
    })
};

export const rulesAccept = (lang) => (dispatch) => {
    const savedAuthInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};
    localStorage.setItem('userInfo', JSON.stringify({...savedAuthInfo, 'rules_not_accepted': false}));

    api(SET_RULES_ACCEPT, {language: langFullNames[lang]}).then(() => {
        dispatch(setNotAccepted(false));
    });
};

export const getPermission = () => (dispatch) => {
    api('permitted_methods').then((resp) => {
        dispatch({
            type: SET_PERMISSION,
            payload: resp
        })
    });
    // api('permitted_methods_add').then((resp) => {
    //    dispatch({
    //        type: SET_PERMISSION_ADD,
    //        payload: resp
    //    })
    // })
};


export const logoutUser = () => async (dispatch) => {
    dispatch( setServiceAction(true) );
    await logout();


    sessionStorage.removeItem("login_recently");
    sessionStorage.setItem("logout_recently", true);


    dispatch( setServiceAction(true) );

    localStorage.removeItem("api_key");
    localStorage.removeItem(LOCAL_STORAGE_USER_INFO);
    localStorage.removeItem(HAS_BOTH_SERVICE_VALUE);

    Object.keys(localStorage).forEach((key) => {
        if (key.startsWith('CURRENT_SUPPLIER')) {
            localStorage.removeItem(key);
        }
    });

    dispatch( setAuth(false) );
    dispatch( setInited(false) );
    dispatch( setInfoAccount(null) );


    history.push({
        pathname: DEFAULT_NOT_AUTH_PATH
    });
};


export const ping = () => async (dispatch) => {
    const res = await api('ping');

    if (res instanceof Error || !res.ip)
        return false;

    dispatch( setMaintenanceModeAction(false) );
    return true;
};

export const setService = (service) => (dispatch) => {
    dispatch( setServiceAction(service) );
};

export const changeLanguage = (lang, rtl = false) => (dispatch) => {
    dispatch( setUserLocals({lang, rtl}) );
};