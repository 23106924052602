import React, {useState} from "react";
import {Input, Checkbox, SelectPicker, Form, FormControl, Schema} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import FieldDataRange from "../../components/Form/FieldDataRange";
import {debounce, hasErrorObject} from "../../utils";
import E164DownloadButton from "./E164DownloadButton";

const {StringType} = Schema.Types;

const e164model = Schema.Model({
    code: StringType()
        .pattern(/^\d*$/, "Only numbers")
        .maxLength(15, 'The maximum is only 15 characters.')
});

export default ({
    defaultFilter,
    // dialerList,
    onChangeFilter,
    worldzone_list,
    destination_list,
    subdestination_list
}) => {

    return (
        <>
            <Form
                model={e164model}
                formDefaultValue={defaultFilter}
                onChange={debounce((value) => {
                    const formCheckData = e164model.check(value);
                    if (!hasErrorObject(formCheckData)) {
                        onChangeFilter(value);
                    }
                }, 1000)}
            >
                <FlexGrid align="middle">
                    <FlexGridItem>
                        <FormControl
                            accepter={Input}
                            name="code"
                            placeholder="Code"
                            style={{width: 130}}
                        />
                    </FlexGridItem>

                    <FlexGridItem>
                        <FormControl
                            accepter={SelectPicker}
                            cleanable
                            searchable
                            data={worldzone_list}
                            valueKey="wz_key"
                            labelKey="name"
                            name="wz_key"
                            placeholder="Worldzone"
                            style={{width: 200}}
                        />
                    </FlexGridItem>

                    <FlexGridItem>
                        <FormControl
                            accepter={SelectPicker}
                            cleanable
                            searchable
                            data={destination_list}
                            valueKey="de_key"
                            labelKey="name"
                            name="de_key"
                            placeholder="Destination"
                            style={{width: 200}}
                        />
                    </FlexGridItem>

                    <FlexGridItem>
                        <FormControl
                            accepter={SelectPicker}
                            cleanable
                            searchable
                            data={subdestination_list}
                            valueKey="sde_key"
                            labelKey="name"
                            name="sde_key"
                            placeholder="Subdestination"
                            style={{width: 200}}
                        />
                    </FlexGridItem>


                    <FlexGridItem  style={{marginLeft: 'auto'}}>
                        <E164DownloadButton/>
                    </FlexGridItem> 

                </FlexGrid>
            </Form>
        </>
    )
};