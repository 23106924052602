import React, {useRef, useState, useEffect} from 'react';
import FormHOC from '../../hoc/FilltersForm';
import CustomSelfClearedForm from '../../components/client/Form/CustomSelfClearedForm';
import CustomField from '../../components/client/Form/CustomField/CustomField';
import {debounce} from '../../utils';
import {useIntl} from "react-intl";
import {FlexGrid, FlexGridItem} from '../../components/base/FlexGrid';
import m from "../../definedMessages";
import {BaseInputField} from "../../components/base/BaseForm";
import {useWindowWidth} from "../../hooks";
import {clearFormRef} from "../../utils/helpers";


export default  FormHOC(({
    loading,
    service,
    defaultFilter,
    filtersModel,
    onChangeFilter
}) => {
    const formRef = useRef(null);

    useEffect(() => {
        clearFormRef(formRef, true)
    }, [service]);

    const {formatMessage} = useIntl();
    const windowWidth = useWindowWidth();

    const onSubmit = (data, checkedData) => {
        const checker = Object.keys(checkedData)
            .map(i => checkedData[i].hasError)
            .every(value => value === false);

        if (checker) {
            onChangeFilter({
                ...data
            });
        }
    };

    return (
        <CustomSelfClearedForm
            ref={formRef}
            clearOn={service}
            model={filtersModel}
            formDefaultValue={defaultFilter}
            onChange={debounce((data) => {
                const checkedData = filtersModel.check(data);
                onSubmit(data, checkedData);
            }, 1000)}
            fluid
        >
            <FlexGrid align="top">
                <FlexGridItem width={windowWidth > 767 ? "320px" : "100%"}>
                    <CustomField
                        name={service ? "str" : "origin"}
                        accepter={BaseInputField}
                        readOnly={loading}
                        errorPlacement="topEnd"
                        placeholder={formatMessage(m.destinationFilterMinCharacter)}
                    />
                </FlexGridItem>
                <FlexGridItem width={windowWidth > 767 ? "360px" : "100%"}>
                    <CustomField
                        name={service ? "str2" : "destination"}
                        accepter={BaseInputField}
                        readOnly={loading}
                        errorPlacement="topEnd"
                        placeholder={formatMessage(m.customDestinationOnlyFilterMinCharacter, {destination: 'destination'})}
                    />
                </FlexGridItem>
            </FlexGrid>
        </CustomSelfClearedForm>
    );
});