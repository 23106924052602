import React, {useState, useEffect, useRef, useMemo} from "react";
import { api } from "../../api/loginRoutes";
import {MANAGER_ACCOUNT_USER_CREATE, MANAGER_ACCOUNT_USER_MODIFY} from "../../const/apiMethods";
import {Modal} from "../../components/Modal";
import {useWindowWidth} from "../../hooks";
import {Button, Checkbox, FlexboxGrid, Form, Icon, Alert, Schema, SelectPicker, CheckPicker} from "rsuite";
import CustomField from "../../components/Form/CustomField";
import styled from "styled-components";
import m from "../../definedMessages";
import {useIntl} from "react-intl";
import {Spacer} from "../../components/base/Spacer";
import {compareTwoArrays} from '../../utils'

const {StringType, ArrayType} = Schema.Types;
const defaultFormValue = {};

const MAX_EMAIL_LENGTH = 256;
const MAX_PHONE_LENGTH = 15;

export default ({
    show,
    onClose,
    userId,
    maxWidth = 600,
    onUpdate,
    adminRoleList,
    userFormValue,
    ...props
}) => {

    const formRef = useRef(null);
    const passwordFormRef = useRef(null);

    const {formatMessage} = useIntl();
    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(!!userId);
    const [formValue, setFormValue] = useState(defaultFormValue);
    const [passwordFormValue, setPasswordFormValue] = useState(defaultFormValue);
    const [isActive, setIsActive] = useState(false);
    const [uniqueRoleList, setUniqueRoleList] = useState([]);


    const formPasswordModel = useMemo(() => Schema.Model({
        password: StringType()
            .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])[A-Za-z'\d#?!_@$%^&*-]{8,}$/, "At least one number and uppercase and lowercase letter")
            .minLength(8, "Password must be at least 8 characters")
            .addRule((value) => !!userId || !!value, 'Is required', true),
    }), [userId]);


    const formCreateModifyModel = useMemo(() => Schema.Model({
        name: StringType().isRequired("Is required"),
        phone: StringType()
            .pattern(/^[1-9][\d]*$/, formatMessage(m.numberMust))
            .maxLength(MAX_PHONE_LENGTH, formatMessage(m.maxLength, {count: MAX_PHONE_LENGTH})),
        email: StringType()
            .isRequired("Is required")
            .isEmail('Please enter an email address')
            .maxLength(MAX_EMAIL_LENGTH, `The maximum of this field is ${MAX_EMAIL_LENGTH} characters`),
        login: StringType().isRequired("Is required"),
        role_list: ArrayType().isRequired("Is required"),
    }), []);


    useEffect(() => {
        setFormValue(userFormValue ? {...userFormValue} : defaultFormValue);
        setPasswordFormValue(defaultFormValue);
        setIsActive(userFormValue ? userFormValue.active : false);

        const unitedRoleList = [...(adminRoleList || []), ...(userFormValue?.role_list || [])]
        const uniqueRoleList = [...new Set(unitedRoleList)];
        setUniqueRoleList(uniqueRoleList)
    }, [userFormValue, adminRoleList]);

    useEffect(() => {
        setShowChangePassword(!userId);
    }, [userId]);


    const rolesUpdated = useMemo(() => uniqueRoleList.length
        ? uniqueRoleList.map((x) => ({value: x, label: x}))
        : [], [uniqueRoleList, adminRoleList]);


    const onChangeRoleList = (roleList) => {
        const roleDifference = roleList.filter(x => !notExistRoleList.includes(x));

        if (compareTwoArrays(roleList, roleDifference)) {
            setUniqueRoleList(adminRoleList)
        }
    }

    const handleSuccess = () => {

        if ([passwordFormRef.current.check(), formRef.current.check()].includes(false)) {
            return;
        }

        console.log(passwordFormValue);
        if (passwordFormValue.password
            && passwordFormValue.password !== passwordFormValue.confirm_password) {
            return;
        }

        const formData = formRef.current.getFormValue();

        const method = userId
            ? MANAGER_ACCOUNT_USER_MODIFY
            : MANAGER_ACCOUNT_USER_CREATE;

        const params = {
            ...formData,
            role_list: formData.role_list,
            active: isActive
        };
        if (passwordFormValue.password) {
            params.password = passwordFormValue.password;
        }
        if (userId) {
            params.target = {account_user_id: userId}
        }

        setLoading(true);

        api(method, params)
            .then((r) => {
                if (!r) {
                    return;
                }

                Alert.success(userId
                    ? <>User <b>{params.login}</b> was modified</>
                    : <>New user <b>{params.login}</b> was created</>
                );

                onUpdate && onUpdate();
                setFormValue(defaultFormValue);
                setPasswordFormValue(defaultFormValue);
                setIsActive(false);
                onClose();
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const defaultRoleList = userFormValue?.role_list || [];
    const notExistRoleList = defaultRoleList.filter(x => !adminRoleList.includes(x));

    return (
        <Modal
            show={show}
            title={`Create new User`}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            footer={true}
            disabled={loading}
            onSuccessClose={false}
            successText={userId ? "Update" : "Create"}
            focusTarget="close"
            onSuccess={handleSuccess}
            onClose={() => {
                setFormValue(defaultFormValue);
                setPasswordFormValue(defaultFormValue);
                setIsActive(false);
                onClose(false);
            }}
            {...props}
        >

            <StyledForm
                ref={formRef}
                model={formCreateModifyModel}
                formValue={formValue}
                onChange={(data) => setFormValue(data)}
            >
                <CustomField
                    label="Name"
                    name="name"
                    placeholder="Name"
                    inline
                />
                <CustomField
                    label="Surname"
                    name="surname"
                    placeholder="Surname"
                />
                <CustomField
                    label="Phone"
                    name="phone"
                    placeholder="Phone"
                />
                <CustomField
                    label="Email"
                    name="email"
                    placeholder="Email"
                />
                <CustomField
                    label="Login"
                    name="login"
                    placeholder="Login"
                />
                <CustomField
                    accepter={CheckPicker}
                    label="Roles"
                    name="role_list"
                    placeholder="Roles"
                    data={rolesUpdated}
                    searchable={false}
                    cleanable={false}
                    onChange={onChangeRoleList}
                />

                <CustomField
                    label="Active"
                    accepter={Checkbox}
                    checked={isActive}
                    onChange={(data, value) => setIsActive(value)}
                />

            </StyledForm>


            <StyledForm
                ref={passwordFormRef}
                model={formPasswordModel}
                // autocomplete="off"
                onChange={setPasswordFormValue}
                formValue={passwordFormValue}
            >

                {!showChangePassword &&
                    <FlexboxGrid style={{marginTop: '20px', width: '100%'}}>
                        <FlexboxGrid.Item style={{width: '100%'}}>
                            <Button
                                style={{width: '100%'}}
                                onClick={() => {
                                    setShowChangePassword(true)
                                }}
                                appearance="ghost"
                                color="violet"
                                disabled={loading}
                            >
                                <Icon icon="pencil"/> {formatMessage(m.changePassword)}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                }

                {showChangePassword &&
                    <>
                        <Spacer />

                        <CustomField
                            name="password"
                            type="password"
                            label="Password"
                            placeholder={formatMessage(m.password)}
                        />

                        <CustomField
                            errorMessage={passwordFormValue.password
                                && passwordFormValue.password !== passwordFormValue.confirm_password
                                    ? formatMessage(m.equalPasswordsAlert)
                                    : null
                            }
                            name="confirm_password"
                            type="password"
                            label="Confirm password"
                            placeholder={formatMessage(m.confirmPassword)}
                        />

                        {userId &&
                            <FlexboxGrid style={{marginTop: '20px', width: '100%'}}>
                                <FlexboxGrid.Item style={{width: '100%'}}>
                                    <Button
                                        style={{width: '100%'}}
                                        onClick={() => {
                                            setShowChangePassword(false);
                                            setPasswordFormValue(defaultFormValue);
                                        }}
                                        appearance="ghost"
                                        color="violet"
                                        disabled={loading}
                                    >
                                        <Icon icon="reply"/> {formatMessage(m.dontChangePassword)}
                                    </Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        }
                    </>
                }
            </StyledForm>

        </Modal>
    )
}


const StyledForm = styled(Form)`
    .rs-form-group {
        display: flex;
        align-items: center;
    }
    
    .rs-control-label {
        margin-right: 20px;
        margin-bottom: 0;
        width: 100px;
    }
    
    .rs-picker-select {
        width: 100%;
    }
`;