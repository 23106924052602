import {ACCESS_LIST as PREFIX, DEFAULT_PER_PAGE_MEDIUM} from '../const'
import {ACCESS_EXCLUSION_LIST_API} from 'const/apiMethods';

const initialState = {
    items: [],
    count: 0,
    page: 1,
    per_page: +localStorage.getItem(PREFIX+'_per_page') || DEFAULT_PER_PAGE_MEDIUM,
    access_list_exclusion: [],
    loading:false
};

const handlers = {
    ['SET_ITEMS_'+PREFIX]: (state,{payload: {count, page, per_page, access_list_list} }) => ({
        ...state,
        count,
        items: access_list_list,
        page,
        per_page,
        loading: false
    }),
    ['SET_LOADING_'+PREFIX]: (state,{payload:loading}) =>({...state, loading}),
    [ACCESS_EXCLUSION_LIST_API]: (state, {payload}) => ({...state, access_list_exclusion: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action)
}