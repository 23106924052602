import {
    SET_SUB_ACCOUNT_LIST,
    SET_SUB_ACCOUNT_LIST_LOADING,
    
    SET_SUB_ACCOUNT_DATA,
    SET_SUB_ACCOUNT_DATA_LOADING,

    SET_SUB_ACCOUNT_USER_LIST,
    SET_SUB_ACCOUNT_USER_LIST_LOADING,

    SET_SUB_ACCOUNT_NUMBER_LIST,
    SET_SUB_ACCOUNT_NUMBER_LIST_LOADING,

    SET_SUB_ACCOUNT_PAYMENT_LIST,
    SET_SUB_ACCOUNT_PAYMENT_LIST_LOADING,

    SET_RESELLER_NUMBER_LIST,
    SET_RESELLER_NUMBER_LIST_LOADING,

    SET_RESELLER_TRAFFIC_CDR_LIST,
    SET_RESELLER_TRAFFIC_CDR_LIST_LOADING,

    SET_RESELLER_ACCESS_LIST,
    SET_RESELLER_ACCESS_LIST_LOADING,

    SET_RESELLER_HOST_LIST,
    SET_RESELLER_HOST_LIST_LOADING,

    SET_RESELLER_TEMPLATE_LIST,

    DEFAULT_PER_PAGE_SMALL,
    DESC_SORT,
    DEFAULT_PER_PAGE_MEDIUM
} from "const";

import {
    SUBACC_GET_SUB_ACCOUNT_LIST_METHOD,
    SUBACC_CREATE_SUB_ACCOUNT_METHOD,
    SUBACC_MODIFY_SUB_ACCOUNT_METHOD,
    SUBACC_REMOVE_SUB_ACCOUNT_METHOD,
    SUBACC_RESTORE_SUB_ACCOUNT_METHOD,
    SUBACC_GET_SUBACC_METHOD,
    SUBACC_GET_USER_LIST_METHOD,
    SUBACC_CREATE_USER_METHOD,
    SUBACC_MODIFY_USER_METHOD,
    SUBACC_REMOVE_USER_METHOD,
    SUBACC_LOGIN_USER_METHOD,
    SUBACC_GET_NUMBER_LIST_METHOD,
    SUBACC_CHANGE_RATE_NUMBER_LIST_METHOD,
    SUBACC_REVOKE_NUMBER_LIST_METHOD,
    SUBACC_GET_RESELLER_SUBACC_PAYMENT_LIST_METHOD,
    SUBACC_CREATE_SUB_ACCOUNT_PAYMENT_METHOD,
    SUBACC_REMOVE_SUB_ACCOUNT_PAYMENT_METHOD,

    SUBACC_GET_RESELLER_SUBACC_CDR_LIST_METHOD,
    SUBACC_GET_RESELLER_SUBACC_CDR_REPORT_LIST_METHOD,

    SUBACC_GET_ACCESS_LIST_METHOD,
    
    SUBACC_TRUNK_MODIFY_METHOD,
    SUBACC_TRUNK_GET_NUMBER_LIST_METHOD,
    SUBACC_TRUNK_ALLOCATE_METHOD,
    SUBACC_TRUNK_CHANGE_RATE_METHOD,
    SUBACC_TRUNK_REVOKE_METHOD,

    SUBACC_HOST_GET_LIST_METHOD,
    SUBACC_HOST_CREATE_METHOD,
    SUBACC_HOST_MODIFY_METHOD,
    SUBACC_GET_RESELLER_TEMPLATE_LIST_METHOD
} from "const/apiMethods";
import {api, getCsvResponse, getFileResponse} from "api/loginRoutes";
import {downloadDataAsFile, toUTCDateTime} from "utils";

import {_setValue} from "./defaults";

const setSubAccountList = _setValue(SET_SUB_ACCOUNT_LIST);
const setSubAccountListLoading = _setValue(SET_SUB_ACCOUNT_LIST_LOADING);

const setSubAccountData = _setValue(SET_SUB_ACCOUNT_DATA);
const setSubAccountDataLoading = _setValue(SET_SUB_ACCOUNT_DATA_LOADING);

const setSubAccountCustomerList = _setValue(SET_SUB_ACCOUNT_USER_LIST);
const setSubAccountCustomerListLoading = _setValue(SET_SUB_ACCOUNT_USER_LIST_LOADING);

const setSubAccountNumberList = _setValue(SET_SUB_ACCOUNT_NUMBER_LIST);
const setSubAccountNumberListLoading = _setValue(SET_SUB_ACCOUNT_NUMBER_LIST_LOADING);

const setSubAccountPaymentList = _setValue(SET_SUB_ACCOUNT_PAYMENT_LIST);
const setSubAccountPaymentListLoading = _setValue(SET_SUB_ACCOUNT_PAYMENT_LIST_LOADING);

const setNumberList = _setValue(SET_RESELLER_NUMBER_LIST);
const setNumberListLoading = _setValue(SET_RESELLER_NUMBER_LIST_LOADING);

const setTrafficCdrList = _setValue(SET_RESELLER_TRAFFIC_CDR_LIST);
const setTrafficCdrListLoading = _setValue(SET_RESELLER_TRAFFIC_CDR_LIST_LOADING);

const setAccessList = _setValue(SET_RESELLER_ACCESS_LIST);
const setAccessListLoading = _setValue(SET_RESELLER_ACCESS_LIST_LOADING);

const setHostList = _setValue(SET_RESELLER_HOST_LIST);
const setHostListLoading = _setValue(SET_RESELLER_HOST_LIST_LOADING);

const setTemplateList = _setValue(SET_RESELLER_TEMPLATE_LIST);

// SUBACCOUNTS
export const getSubAccountList = (accountId, filter) => (dispatch) => {
    const method = SUBACC_GET_SUB_ACCOUNT_LIST_METHOD;

    dispatch(setSubAccountListLoading(true));

    api(method, {target: {account_id: accountId}})
        .then((response) => {
            if (response.subacc_list) {
                dispatch(setSubAccountList({list: response.subacc_list}));
            }
        })
        .finally(() => {
            dispatch(setSubAccountListLoading(false));
        })
};

// ---------------- SUBACCOUNT ---------------- 

export const getSubAccountData = (subAccId) => (dispatch) => {
    const method = SUBACC_GET_SUBACC_METHOD;

    dispatch(setSubAccountDataLoading(true));

    api(method, {target: {subacc_id: subAccId}})
        .then((response) => {
            if (response.subacc) {
                dispatch(setSubAccountData({data: response.subacc}));
            }
        })
        .finally(() => {
            dispatch(setSubAccountDataLoading(false));
        })
};


export const createSubAccount = (accountId, params) => async (dispatch) => {
    const method = SUBACC_CREATE_SUB_ACCOUNT_METHOD;

    return await api(method, {target: {account_id: accountId}, ...params})
        .then((response) => {
            return response.subacc_id;
        })
};

export const modifySubAccount = (subAccId, params) => async (dispatch) => {
    const method = SUBACC_MODIFY_SUB_ACCOUNT_METHOD;

    return await api(method, {target: {subacc_id: subAccId}, ...params});
};

export const closeSubAccount = (subAccId) => async (dispatch) => {
    const method = SUBACC_REMOVE_SUB_ACCOUNT_METHOD;

    return await api(method, {target: {subacc_id: subAccId}});
};

export const openSubAccount = (subAccId) => async (dispatch) => {
    const method = SUBACC_RESTORE_SUB_ACCOUNT_METHOD;

    return await api(method, {target: {subacc_id: subAccId}})
        .then((response) => {
            return response.subacc_id;
        })
};

// SUBACCOUNT:USERS
export const getSubAccountCustomerList = (subAccId) => (dispatch) => {
    const method = SUBACC_GET_USER_LIST_METHOD;

    dispatch(setSubAccountCustomerListLoading(true));

    api(method, {target: {subacc_id: subAccId}})
        .then((response) => {
            if (response.user_list) {
                dispatch(setSubAccountCustomerList({list: response.user_list}));
            }
        })
        .finally(() => {
            dispatch(setSubAccountCustomerListLoading(false));
        })
};

export const createSubAccountCustomer = (subAccId, data) => async (dispatch) => { 
    const method = SUBACC_CREATE_USER_METHOD;

    return await api(method, {target: {subacc_id: subAccId}, ...data});
};

export const modifySubAccountCustomer = (subAccUserId, data) => async (dispatch) => {
    const method = SUBACC_MODIFY_USER_METHOD;

    return await api(method, {target: {subacc_user_id: subAccUserId}, ...data});
};
export const removeSubAccountCustomer = (subAccUserId) => async (dispatch) => { 
    const method = SUBACC_REMOVE_USER_METHOD;
    
    return await api(method, {target: {subacc_user_id: subAccUserId}});
};
export const loginSubAccountCustomer = (subAccUserId, host) => (dispatch) => {
    const method = SUBACC_LOGIN_USER_METHOD;

    api(method, {target: {subacc_user_id: subAccUserId}, host});
};

// SUBACCOUNT:NUMBERS
export const getSubAccountNumberList = (subAccId, filter={}, page=1, perPage=DEFAULT_PER_PAGE_SMALL) => (dispatch) => {
    const method = SUBACC_GET_NUMBER_LIST_METHOD;

    dispatch(setSubAccountNumberListLoading(true));

    api(method, {target: {subacc_id: subAccId}, ...filter, page: page, per_page: perPage})
        .then((response) => {
            if (response.number_list) {
                dispatch(setSubAccountNumberList({
                    list: response.number_list,
                    count: response.number_count,
                    page: page,
                    perPage: perPage
                }));
            }
        })
        .finally(() => {
            dispatch(setSubAccountNumberListLoading(false));
        });
};
// SUBACCOUNT:NUMBERS:EXPORT
export const exportNumbers = (subAccId, filter, list, all) => (dispatch) => {
    const method = SUBACC_GET_NUMBER_LIST_METHOD;

    const fileName = "numbers.csv";
    const params = {
        target: {subacc_id: subAccId},
        ...(all ? {...filter} : {number_list: list})
    };

    getFileResponse(method, params)
        .then((response) => {
            downloadDataAsFile(response, fileName);
        })

};

// SUBACCOUNT:PAYMENTS
export const getSubAccountPaymentList = (subAccId, filter={}) => (dispatch) => {
    const method = SUBACC_GET_RESELLER_SUBACC_PAYMENT_LIST_METHOD;

    dispatch(setSubAccountPaymentListLoading(true));

    const filterCopy = Object.assign({}, filter);

    if (filterCopy.range && filterCopy.range.length) {
        filterCopy["date_start"] = toUTCDateTime(filterCopy.range[0]);
        filterCopy["date_end"] = toUTCDateTime(filterCopy.range[1]);

        delete filterCopy.range;
    }

    api(method, {target: {subacc_id: subAccId}, ...filterCopy})
        .then((response) => {
            if (response.payment_list) {
                dispatch(setSubAccountPaymentList({
                    list: response.payment_list
                }));
            }
        })
        .finally(() => {
            dispatch(setSubAccountPaymentListLoading(false));
        });
};
// SUBACCOUNT:PAYMENTS:ADD
export const addNewPayment = (subAccId, data) => (dispatch) => {
    dispatch(setSubAccountPaymentListLoading(true));
    const method = SUBACC_CREATE_SUB_ACCOUNT_PAYMENT_METHOD;

    const params = {
        target: {subacc_id: subAccId},
        ...data
    };

    return api(method, params)
        .then((response) => {
            return response;
        })
        .finally(() => {
            dispatch(setSubAccountPaymentListLoading(false));
        });
};
// SUBACCOUNT:PAYMENTS:REMOVE
export const removePayment = (subAccId, ptKey) => (dispatch) => {
    const method = SUBACC_REMOVE_SUB_ACCOUNT_PAYMENT_METHOD;

    const params = {
        target: {subacc_id: subAccId},
        payment_key: ptKey
    };

    return api(method, params)
        .then((response) => {
            return response;
        });
};
// SUBACCOUNT:PAYMENTS:CHANGE RATE
export const changeRateNumbers = (subAccId, rate, filter, list, all) => (dispatch) => {
    const method = SUBACC_CHANGE_RATE_NUMBER_LIST_METHOD;

    const params = {
        target: {subacc_id: subAccId},
        ...(all ? {...filter, all_numbers: true} : {number_list: list}),
        rate
    };

    return api(method, params)
        .then((response) => {
            return response;
        });
};
// SUBACCOUNT:PAYMENTS:REVOKE
export const revokeNumbers = (subAccId, filter, list, all) => (dispatch) => {
    const method = SUBACC_REVOKE_NUMBER_LIST_METHOD;

    const params = {
        target: {subacc_id: subAccId},
        ...(all ? {...filter, all_numbers: true} : {number_list: list})
    };

    return api(method, params)
        .then((response) => {
            return response;
        });
};
//  -------------------------------------------- 

// --------- SUBACCOUNTS ACCESS LIST -----------
// GET LIST
export const getSubAccAccessList = (accountId, filter={}, page=1, perPage=DEFAULT_PER_PAGE_MEDIUM, sort=null) => (dispatch) => {
    const method = SUBACC_GET_ACCESS_LIST_METHOD;

    dispatch(setAccessListLoading(true));
    
    const target = {
        account_id: accountId
    };
    
    const sortValue = sort ? {
        sort: sort.column,
        sort_desc: sort.type === DESC_SORT
    } : {};

    api(method, {target, ...filter, page: page, per_page: perPage, ...sortValue})
        .then((response) => {
            if (response.access_list_list) {
                dispatch(setAccessList({
                    list: response.access_list_list,
                    count: response.access_list_count,
                    page: page,
                    perPage: perPage
                }));
            }
        })
        .finally(() => {
            dispatch(setAccessListLoading(false));
        });
};

// EXPORT ACCESS LIST
export const exportAccessList = (accountId, filter={}) => (dispatch) => {
    const method = SUBACC_GET_ACCESS_LIST_METHOD;
    
    dispatch(setAccessListLoading(true));

    const params = {
        target: {
            account_id: accountId,
        },
        ...filter
    };

    const fileName ="access_list.csv" ;

    getFileResponse(method, params)
        .then((response) => {
            downloadDataAsFile(response, fileName);
        })
        .finally(() => {
            dispatch(setAccessListLoading(false));
        });
};

//  -------------------------------------------- 

//  ------------- SUBACCOUNTS TRUNK ------------
// NUMBERS
export const trunkSubAccGetNumberList = (trunkId, subAccId, filter={}, page=1, perPage=DEFAULT_PER_PAGE_SMALL, sort=null) => (dispatch) => {
    const method = SUBACC_TRUNK_GET_NUMBER_LIST_METHOD;

    dispatch(setNumberListLoading(true));

    const {subacc_id_list, ...restFilter} = filter;
    const target = {
        ...(subAccId ? {subacc_id: subAccId} : {}),
        ...(subacc_id_list && subacc_id_list.length ? {subacc_id_list} : {}),
        trunk_id: trunkId
    };

    const sortValue = sort ? {
        sort: sort.column,
        sort_desc: sort.type === DESC_SORT
    } : {};

    api(method, {target, ...restFilter, page: page, per_page: perPage, ...sortValue})
        .then((response) => {
            if (response.number_list) {
                dispatch(setNumberList({
                    list: response.number_list,
                    count: response.number_count,
                    page: page,
                    perPage: perPage
                }));
            }
        })
        .finally(() => {
            dispatch(setNumberListLoading(false));
        });
};
// ALLOCATE
export const trunkSubAccAllocateNumbers = (trunkId, subAccId, rate, filter, list, all) => async (dispatch) => {
    const method = SUBACC_TRUNK_ALLOCATE_METHOD;


    const params = {
        target: {trunk_id: trunkId, subacc_id: subAccId},
        ...(all ? {...filter, all_numbers: true} : {number_list: list}),
        ...(rate ? {rate: parseFloat(rate)} : {})
    };

    return await api(method, params)
        .then((response) => {
            return response;
        });
};
// DOWNLOAD NUMBERS
export const trunkSubAccDownloadNumbers = (trunkId, filter, list, all) => (dispatch) => {
    const method = SUBACC_TRUNK_GET_NUMBER_LIST_METHOD;

    const fileName = "numbers.csv";
    const params = {
        target: {trunk_id: trunkId},
        ...(all 
            ? {...filter, all_numbers: true} 
            : {...(list && list.length ? {number_list: list} : {})}
        )
    };

    getCsvResponse(method, params)
        .then((response) => {
            downloadDataAsFile(response, fileName);
        })

};
// CHANGE DISCOUNT
export const trunkSubAccChangeDefaultDiscount = (trunkId, rateWeek, rateMonth) => async (dispatch) => {
    const method = SUBACC_TRUNK_MODIFY_METHOD;

    return await api(method, {
        target: {trunk_id: trunkId}, 
        subacc_rate_percent_week: rateWeek,
        subacc_rate_percent_month: rateMonth
    });
};
// CHANGE RATE
export const trunkSubAccChangeRateNumbers = (trunkId, rate, filter, list, all) => async (dispatch) => {
    const method = SUBACC_TRUNK_CHANGE_RATE_METHOD;

    const params = {
        target: {trunk_id: trunkId},
        ...(all ? {...filter, all_numbers: true} : {number_list: list}),
        rate
    };

    return await api(method, params)
        .then((response) => {
            return response;
        });
};
// REVOKE
export const trunkSubAccRevokeNumbers = (trunkId, filter, list, all) => async (dispatch) => {
    const method = SUBACC_TRUNK_REVOKE_METHOD;

    const params = {
        target: {trunk_id: trunkId},
        ...(all ? {...filter, all_numbers: true} : {number_list: list})
    };

    return await api(method, params)
        .then((response) => {
            return response;
        });
};

//  -------------------------------------------- 


//  ----------------- TRAFFIC  -----------------

export const getTrafficCdrList = (accountId, filter, isCdr=false, page=1, perPage=DEFAULT_PER_PAGE_SMALL) => (dispatch) => {
    const methodOptions = {
        [true]: {
            method: SUBACC_GET_RESELLER_SUBACC_CDR_LIST_METHOD,
            list: "call_list",
            count: "call_count",
            summaries: "call_summaries",
            storagedListName: "cdrList"
        },
        [false]: {
            method: SUBACC_GET_RESELLER_SUBACC_CDR_REPORT_LIST_METHOD,
            list: "cdr_report_list",
            count: "cdr_report_count",
            summaries: "cdr_report_summaries",
            storagedListName: "cdrReportList"
        }
    };
    
    dispatch(setTrafficCdrListLoading(true));

    const filterCopy = Object.assign({}, filter);

    if (filterCopy.range && filterCopy.range.length) {
        filterCopy["date_start"] = toUTCDateTime(filterCopy.range[0]);
        filterCopy["date_end"] = toUTCDateTime(filterCopy.range[1]);

        delete filterCopy.range;
    }
    let subAcc = {};

    if (filterCopy.subacc_id) {
        subAcc = {subacc_id: filter.subacc_id};
    }

    delete filterCopy.subacc_id;

    const clearedFilter = Object.keys(filterCopy).reduce((result, key) => {
        const value = filterCopy[key];

        if (!!value) {
            result[key] = value;
        }
        
        return result;
    }, {})

    const params = {
        target: {
            account_id: accountId,
            ...subAcc
        },
        ...clearedFilter,
        page: page,
        per_page: perPage
    };

    api(methodOptions[isCdr].method, params)
        .then((response) => {
            if (methodOptions[isCdr].list in response && response[methodOptions[isCdr].list]) {
                dispatch(setTrafficCdrList({
                    [methodOptions[isCdr].storagedListName]: response[methodOptions[isCdr].list],
                    count: response[methodOptions[isCdr].count],
                    page: page,
                    perPage: perPage,
                    ...(methodOptions[isCdr].summaries ? {
                        summaries: response[methodOptions[isCdr].summaries]
                    } : {})
                }));
            }
        })
        .finally(() => {
            dispatch(setTrafficCdrListLoading(false));
        });
};

export const exportTrafficCdrList = (accountId, filter, isCdr=false) => (dispatch) => {
    const methodOptions = {
        [true]: {
            method: SUBACC_GET_RESELLER_SUBACC_CDR_LIST_METHOD,
            list: "call_list",
            count: "call_count",
            storagedListName: "cdrList"
        },
        [false]: {
            method: SUBACC_GET_RESELLER_SUBACC_CDR_REPORT_LIST_METHOD,
            list: "cdr_report_list",
            count: "cdr_report_count",
            storagedListName: "cdrReportList"
        }
    };
    
    dispatch(setTrafficCdrListLoading(true));

    const filterCopy = Object.assign({}, filter);

    if (filterCopy.range && filterCopy.range.length) {
        filterCopy["date_start"] = toUTCDateTime(filterCopy.range[0]);
        filterCopy["date_end"] = toUTCDateTime(filterCopy.range[1]);

        delete filterCopy.range;
    }
    let subAcc = {};

    if (filterCopy.subacc_id) {
        subAcc = {subacc_id: filter.subacc_id};
    }

    delete filterCopy.subacc_id;

    const params = {
        target: {
            account_id: accountId,
            ...subAcc
        },
        ...filterCopy
    };

    const fileName = isCdr ? "cdr_list.csv" : "cdr_report_list.csv";

    getFileResponse(methodOptions[isCdr].method, params)
        .then((response) => {
            downloadDataAsFile(response, fileName);
        })
        .finally(() => {
            dispatch(setTrafficCdrListLoading(false));
        });
};

//  -------------------------------------------- 

//  ------------------- HOST  ------------------

export const getSubAccHostList = (accountId) => (dispatch) => {
    const method = SUBACC_HOST_GET_LIST_METHOD;

    const params = {
        target: {account_id: accountId}
    };

    dispatch(setHostListLoading(true));

    api(method, params)
        .then((response) => {
            if (response.domain_list) {
                dispatch(setHostList(response.domain_list));
            }
        })
        .finally(() => {
            dispatch(setHostListLoading(false));
        });
};

export const changeHostListLoading = (value=false) => (dispatch) => {
    dispatch(setHostListLoading(value));
};

export const createSubAccHost = (accountId, host, templateKey) => async (dispatch) => {
    const method = SUBACC_HOST_CREATE_METHOD;

    const params = {
        target: {account_id: accountId},
        host: host,
        template_key: templateKey,
        active: true
    };

    return await api(method, params);
};

export const modifySubAccHost = (accountId, hostKey, host, templateKey) => async (dispatch) => {
    const method = SUBACC_HOST_MODIFY_METHOD;

    const params = {
        target: {account_id: accountId},
        host_key: hostKey,
        host: host,
        template_key: templateKey,
        active: true
    };

    return await api(method, params);
};

export const getSubAccTemplateList = (accountId) => (dispatch) => {
    const method = SUBACC_GET_RESELLER_TEMPLATE_LIST_METHOD;

    const params = {
        target: {account_id: accountId}
    };

    api(method, params)
        .then((response) => {
            if (response.template_list) {
                dispatch(setTemplateList(response.template_list));
            }
        })
};

//  -------------------------------------------- 