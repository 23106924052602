import {AUTH_NUMBERS as PREFIX} from '../const/';
import {SET_AUTH_NUMBER_TYPE_LIST} from '../const'

const initialState = {
    loading: false,
    rule_count: 0,
    rule_list: [],
    auth_number_list: [],
    auth_number_list_setted: false,
    loadingItem: false,
    auth_number_type: [],
    auth_number_type_family_list: [],
};


const handlers = {
    ['SET_ITEMS_'+PREFIX]: (state, {payload: auth_number_list}) => ({...state, auth_number_list, loading: false, auth_number_list_setted: true}),
    ['SET_LOADING_'+PREFIX]: (state, {payload}) => ({...state,loading: payload}),
    ['SET_LOADING_ITEM_'+PREFIX]: (state, {payload}) => ({...state, loadingItem: payload}),
    [SET_AUTH_NUMBER_TYPE_LIST]: (state, {payload: {auth_number_type_list, auth_number_type_family_list}}) => ({
        ...state, 
        auth_number_type: auth_number_type_list,
        auth_number_type_family_list: auth_number_type_family_list,
    }),
    ['SET_ITEMS_RULES']: (state, {payload}) => ({...state, ...payload}),
    ['SET_LOADING_RULES']: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};
