import {
    SET_DIALER_SERVICE,
    SET_ACCOUNT_DIALER_INFO, 
    SET_ACCOUNT_READY_FOR_PAYMENT
} from '../const';
import { getServiceByLocation } from '../utils/helpers';
import history from '../config/history';

const initialState = {
    dialerInfo: null,
    serviceDialer: getServiceByLocation(history.location),
    accountJoinList: [],
    readyForPayment: {}
};

const handlers = {
    [SET_ACCOUNT_DIALER_INFO]: (state, {payload: {
        accountJoinList, readyForPayment, billingCurrencyList, billingServiceList, ...payload
    }}) => {
        return ({
            ...state,
            dialerInfo: payload,
            accountJoinList,
            billingServiceList,
            billingCurrencyList,
            readyForPayment
            // readyForPayment: payload.available_for_payment || 0
        })
    },
    [SET_ACCOUNT_READY_FOR_PAYMENT]: (state, {payload}) => ({...state, readyForPayment: payload}),
    [SET_DIALER_SERVICE]: (state, {payload}) => ({...state, serviceDialer: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};



