import React from 'react';
import TableClientSort from '../../components/Table/TableClientSort';
import {Table as _Table} from "rsuite";
import {BaseButton} from '../../components/base/BaseButton';
import './LiveCalls.css'
import styled from "styled-components";
import PaginationTotal from '../../components/base/Pagination/PaginationTotal';
import { Spacer } from 'components/base/Spacer';

const { Column, HeaderCell, Cell } = _Table;

const columns = [
     // {label: 'Account', dataKey: 'acc_name_orig', minWidth: 120, title:true, sortable: true},
     // {label: 'A-number', dataKey: 'a_number' , minWidth: 120, sortable: true},
     // {label: 'Originated from', dataKey: 'a_subdestination_name', minWidth: 150, title:true, sortable: true},
     // {label: 'Rate orig', dataKey: 'rate_orig', minWidth: 120, align: 'center', sortable: true},
     // {label: 'B-number', dataKey: 'b_number', minWidth: 120, sortable: true},
     // {label: 'Destination', dataKey: 'b_subdestination_name', minWidth: 120, title:true, sortable: true},
     // {label: 'Range', dataKey: 'range', title:true, sortable: true, minWidth: 100},
     // {label: 'Rate term', dataKey: 'rate_term', minWidth: 120, align: 'center', sortable: true},
     // {label: 'Dialer', dataKey: 'acc_name_term', title:true, sortable: true, minWidth: 100},
     // {label: 'Termination point', dataKey: 'termination_point', minWidth: 150, align:'center', title:true, sortable: true},
     // {label: 'Duration', dataKey: 'duration', minWidth: 100, sortable: true},
    {label: 'Account', dataKey: 'acc_name_orig', minWidth: 120, title:true, sortable: true, value: ({acc_name_orig, rate_orig}) => {
            return (
                <div className="table-two-staged__cell">
                    <div className="table-two-staged__cell-first">{acc_name_orig}</div>
                    <div className="table-two-staged__cell-second">Rate: {rate_orig}</div>
                </div>
            )
        }
    },
    {label: 'A-number', dataKey: 'a_number', minWidth: 120, sortable: true, value: ({a_number, a_subdestination_name}) => {
            return (
                <div className="table-two-staged__cell">
                    <div className="table-two-staged__cell-first">{a_number}</div>
                    <div className="table-two-staged__cell-second">{a_subdestination_name}</div>
                </div>
            )
        }
    },
    {label: 'B-number', dataKey: 'b_number', minWidth: 120, sortable: true, value: ({b_number, b_subdestination_name}) => {
            return (
                <div className="table-two-staged__cell">
                    <div className="table-two-staged__cell-first">{b_number}</div>
                    <div className="table-two-staged__cell-second">{b_subdestination_name}</div>
                </div>
            )
        }
    },
    {label: 'Range', dataKey: 'range', title:true, sortable: true, minWidth: 100, value: ({range, rate_term}) => {
            return (
                <div className="table-two-staged__cell">
                    <div className="table-two-staged__cell-first">{range}</div>
                    <div className="table-two-staged__cell-second">Rate: {rate_term}</div>
                </div>
            )
        }
    },
    {label: 'Dialer', dataKey: 'acc_name_term', title:true, sortable: true, minWidth: 100, value: ({acc_name_term, termination_point}) => {
            return (
                <div className="table-two-staged__cell">
                    <div className="table-two-staged__cell-first">{acc_name_term}</div>
                    <div className="table-two-staged__cell-second">{termination_point}</div>
                </div>
            )
        }
    },
    {label: 'Duration', dataKey: 'duration', minWidth: 100, width: 100, align: 'right', sortable: true, value: ({duration}) => {
            return (
                <div className="table-two-staged__cell">
                    <div className="table-two-staged__cell-single">{duration}</div>
                </div>
            )
        }
    }
];


export default ({onStop, data, loading, onDataUpdated }) =>  {
        return (
            <>
                <TableClientSort
                    data={data}
                    rowHeight={50}
                    extraHeight={639}
                    shouldUpdateScroll={false}
                    autoHeight={false}
                    loading={loading}
                    columns = {columns}
                    columnSelectorLSKey="LiveCallsTable"
                    ActionCell = {ActionCell(onStop)}
                    onDataUpdated={onDataUpdated}
                />
                <Spacer size={10} />
                <PaginationTotal
                    style={{textAlign: 'right'}}
                    total={data.length}
                />
            </>
        );
}

const ActionCell = (onStop) => (
    <Column align={'center'} width={100}>
        <HeaderCell>Action</HeaderCell>
        <Cell>
            {({call_key}) => <div className="table-two-staged__cell">
                <div className="table-two-staged__cell-single">
                    <StyledCloseButton
                        buttonStyle="error"
                        size="esm"
                        onClick={() =>onStop(call_key)}
                        style={{position:'relative', top:'-3px'}}>
                        Stop
                    </StyledCloseButton>
                </div>
            </div> }
        </Cell>
    </Column>
)


const StyledCloseButton = styled(BaseButton)`
    && {
        padding-top: 4px;
        padding-bottom: 4px;
    }
`;