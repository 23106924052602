import React from "react";
import PageHeader from "components/base/PageHeader";
import TestCallsTable from "./TestCallsTable";
import TestCallsFilters from "./TestCallsFilters";
import {getServiceByLocation, objectIsEmpty, removeTZFromDate, servicePick} from "utils/helpers";
import {
    LOCAL_STORAGE_TEST_CALLS_FILTERS_DIALER,
    LOCAL_STORAGE_TEST_CALLS_PER_PAGE_DIALER,
    MAX_PER_PAGE_DIALER,
    MIN_PER_PAGE_DIALER
} from "const";
import {injectIntl} from "react-intl";
import {FormattedMessage} from "react-intl";
import {FlexGrid, FlexGridItem} from "components/base/FlexGrid";
import {Spacer} from "components/base/Spacer";
import {Drawer, Schema, FlexboxGrid, Icon, IconButton} from "rsuite";
import styled from "styled-components";
import m from "definedMessages";
import Page from "components/base/Page";

const {StringType, ArrayType} = Schema.Types;

const MIN_CHARACTERS = 3;
const MAX_CHARACTERS = 40;

class TestCalls extends React.Component {
    constructor(props) {
        super(props);

        this.filter = {};
        this.defaultFilter = {
            start_date: removeTZFromDate(new Date(new Date().setHours(0, 0, 0, 0))),
            end_date: removeTZFromDate(new Date(new Date().setHours(23, 59, 59, 999)))
        };
        this.curPerPage = null;

        this.formatMessage = this.props.intl.formatMessage.bind(this.props.intl);

        this.filtersModel = Schema.Model({
            start_end_date: ArrayType()
                .addRule((value) => {
                    if (value && value[0] && value[1]) {
                        const startDate = new Date(value[0]);
                        const endDate = new Date(value[1]);
                        const startDay = startDate.getDate();
                        const endDay = endDate.getDate();

                        if ((endDay-startDay) > 1) {
                            return false;
                        }
                    }
                    return true;
                // }, formatMessage(m.cdrHistoryPeriodIs, {count: globalSettings.cdr_full__interval_month})),
            }, this.formatMessage(m.filterAvailableForTwoDays)),
            a_number: StringType()
                .pattern(/^\d+$/, this.formatMessage(m.correctNumber))
                // .minLength(MIN_CHARACTERS, this.formatMessage(m.minLength, {count: MIN_CHARACTERS}))
                .maxLength(MAX_CHARACTERS, this.formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
            b_number: StringType()
                .pattern(/^\d+$/, this.formatMessage(m.correctNumber))
                // .minLength(MIN_CHARACTERS, this.formatMessage(m.minLength, {count: MIN_CHARACTERS}))
                .maxLength(MAX_CHARACTERS, this.formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
        }
    );

        this.state = {
            searchLoading: false,
            savedTableFilter: JSON.parse(localStorage.getItem(LOCAL_STORAGE_TEST_CALLS_FILTERS_DIALER)),
            savedPerPage: JSON.parse(localStorage.getItem(LOCAL_STORAGE_TEST_CALLS_PER_PAGE_DIALER)),
            resizedWidth: window.innerWidth,
            activeFiltersCount: 0,
            showFilters: false,
        };
    }

    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };

    componentDidMount() {
        const {savedPerPage, savedTableFilter} = this.state;
        const {getDialerTestCalls, per_page, service, location} = this.props;

        this.setActiveFiltersCount(this.state.savedTableFilter);

        this.curPerPage = savedPerPage;
        let pickedFilter;

        // if (!objectIsEmpty(savedTableFilter)) {
        //     pickedFilter = savedTableFilter;
        // } else 
        if (!objectIsEmpty(this.filter)) {
            pickedFilter = this.filter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        let currentService = service;

        if (service === null) {
            currentService = getServiceByLocation(location);
        }

        const checker = this.filtersModel.check(pickedFilter);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError && service !== null) {
            getDialerTestCalls(currentService, savedPerPage || per_page, pickedFilter, 1);
        }

    }

    componentDidUpdate = (prevProps) => {
        const {savedPerPage, savedTableFilter} = this.state;
        const {getDialerTestCalls, per_page, service} = this.props;

        if (service !== null && prevProps.service !== service) {
            this.curPerPage = savedPerPage;

            let pickedFilter = this.defaultFilter;

            getDialerTestCalls(service, savedPerPage || per_page, pickedFilter, 1);
        }
    } 

    componentWillMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    onChangeFilters = (filtersObj) => {
        const {savedPerPage} = this.state;
        const {getDialerTestCalls, setEmptyTrafficStat, service, per_page} = this.props;

        this.setActiveFiltersCount(filtersObj);

        this.filter = filtersObj;

        localStorage.setItem(LOCAL_STORAGE_TEST_CALLS_FILTERS_DIALER, JSON.stringify(this.filter));

        this.setState({
            savedTableFilter: filtersObj
        });

        const checker = this.filtersModel.check(this.filter);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError) {
            getDialerTestCalls(service, savedPerPage || this.curPerPage || per_page, this.filter, 1);
        } 
        // else {
        //     setEmptyTrafficStat();
        // }
    };

    combineFilters = (service = true, filter, savedFilter) => {
        return {
            start_date: filter && filter.start_date ? filter.start_date :
                savedFilter && savedFilter.start_date ? savedFilter.start_date :
                    removeTZFromDate(new Date(new Date().setHours(0, 0, 0, 0))),
            end_date: filter && filter.end_date ? filter.end_date :
                savedFilter && savedFilter.end_date ? savedFilter.end_date :
                    removeTZFromDate(new Date(new Date().setHours(23, 59, 59, 999))),
            ...(service ? {
                a_number: filter && (filter.a_number || filter.a_number === "") ? filter.a_number :
                    savedFilter && (savedFilter.a_number || savedFilter.a_number === "") ? savedFilter.a_number : "",
            }: {
                senderid: filter && (filter.senderid || filter.senderid === "") ? filter.senderid :
                    savedFilter && (savedFilter.senderid || savedFilter.senderid === "") ? savedFilter.senderid : "",
            }),
            ...(service ? {
                b_number: filter && (filter.b_number || filter.b_number === "") ? filter.b_number :
                    savedFilter && (savedFilter.b_number || savedFilter.b_number === "") ? savedFilter.b_number : ""
            } : {
                phone: filter && (filter.phone || filter.phone === "") ? filter.phone :
                    savedFilter && (savedFilter.phone || savedFilter.phone === "") ? savedFilter.phone : ""
            })
            
        };
    };

    setActiveFiltersCount = (value = null) => {
        if (!value)
            return;

        const filters = Object.keys(value);
        const dateFilters = ["start_end_date", "start_date", "end_date"];
        const activeFilters = filters.filter(item => {
            if ( dateFilters.includes(item) )
                return false;

            if ( value[item] === this.defaultFilter[item] || (!value[item] && value[item] !== 0) ) {
                return false;
            }
            return true;
        });

        this.setState({
            activeFiltersCount: activeFilters.length + 1
        });
    };

    onChangePerPage = (perPage) => {
        const {savedTableFilter} = this.state;
        const {getDialerTestCalls, service} = this.props;

        if (perPage)
            this.setState({savedPerPage: null});
        else
            this.setState({savedPerPage: this.curPerPage});

        let pickedFilter;
        const per_page = Number(perPage) || this.curPerPage;
        const combinedFilter = this.combineFilters(service, this.filter, {});

        if (!objectIsEmpty(this.filter) 
        // || !objectIsEmpty(savedTableFilter)
        ) {
            pickedFilter = combinedFilter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        per_page <= 0 ? this.curPerPage = MIN_PER_PAGE_DIALER : per_page > MAX_PER_PAGE_DIALER ? this.curPerPage = MAX_PER_PAGE_DIALER : this.curPerPage = per_page;

        localStorage.setItem(LOCAL_STORAGE_TEST_CALLS_PER_PAGE_DIALER, JSON.stringify(this.curPerPage));

        const checker = this.filtersModel.check(pickedFilter);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError) {
            getDialerTestCalls(service, this.curPerPage, pickedFilter, 1);
        }

    };

    onChangePage = (pageNumber) => {
        const {savedTableFilter, savedPerPage} = this.state;
        const {getDialerTestCalls, service, per_page} = this.props;

        let pickedFilter;
        const combinedFilter = this.combineFilters(service, this.filter, {});

        if (!objectIsEmpty(this.filter) 
        // || !objectIsEmpty(savedTableFilter)
        ) {
            pickedFilter = combinedFilter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        const checker = this.filtersModel.check(pickedFilter);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError) {
            getDialerTestCalls(service, savedPerPage || this.curPerPage || per_page, pickedFilter, pageNumber);
        }
    };

    render() {
        const {searchLoading, savedTableFilter, savedPerPage} = this.state;
        const {loading, test_calls_list, more, count, page, per_page, service} = this.props;
        const onChangePage = this.onChangePage;
        const onChangePerPage = this.onChangePerPage;

        return (
            <Page>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item>
                        <PageHeader 
                            {...{
                                ...(service ? 
                                    {title: <FormattedMessage id="testCalls.testCalls" defaultMessage="Test Calls" />} : 
                                    {extraTitle: `Test ${this.formatMessage(m.sms)}`}
                                ),
                            }}
                        />
                    </FlexboxGrid.Item>

                    {this.state.resizedWidth < 768 &&

                        <FlexboxGrid.Item>
                            <Toggler
                                icon={<Icon icon="filter"/>}
                                onClick={() => this.setState({showFilters: true})}
                            >
                                {this.state.activeFiltersCount}
                            </Toggler>
                            <Drawer
                                size="xs"
                                placement="bottom"
                                style={{height: "240px"}}
                                show={this.state.showFilters}
                                onHide={() => this.setState({showFilters: false})}
                            >
                                <Drawer.Header>
                                </Drawer.Header>
                                <Drawer.Body>
                                    <TestCallsFilters
                                        onChangeFilters={this.onChangeFilters}
                                        loading={loading || searchLoading}
                                        service={service}
                                        filtersModel={this.filtersModel}
                                        {...{savedTableFilter}}
                                    />
                                </Drawer.Body>
                            </Drawer>
                        </FlexboxGrid.Item>

                    }

                </FlexboxGrid>

                {this.state.resizedWidth >= 768 &&
                    <FlexGrid>
                        <FlexGridItem>
                            <TestCallsFilters
                                onChangeFilters={this.onChangeFilters}
                                loading={loading || searchLoading}
                                service={service}
                                filtersModel={this.filtersModel}
                                {...{savedTableFilter}}
                            />
                            <Spacer/>
                        </FlexGridItem>
                    </FlexGrid>
                }

                <TestCallsTable
                    data={test_calls_list}
                    loading={loading || searchLoading}
                    {...{
                        onChangePage,
                        onChangePerPage,
                        more,
                        page,
                        count,
                        service,
                        per_page,
                        savedPerPage
                    }}
                />
            </Page>
        );
    }
}

export default injectIntl(TestCalls);

const Toggler = styled(IconButton)`
    && {
        color: #363434;
    }
`;