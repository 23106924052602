import React, {useEffect} from 'react';
import RouteWithLayout from "./RouteWithLayout";
import {connect} from "react-redux";
import {Route, Redirect} from "react-router-dom";
import {APP_TYPE_CLIENT, APP_TYPE_DEFAULT, DEFAULT_NOT_AUTH_PATH} from "../../const";
import {useRouteMatch} from "react-router";

const AuthRoute = ({auth, ...props}) => {

    if (!auth) {
        return <Redirect to={DEFAULT_NOT_AUTH_PATH} />;
    }

    return <RouteWithLayout {...props} />
};

const mapState = ({auth})=> ({
    auth: auth.auth,
    lang: auth.lang,
    rtl: auth.rtl,
});

export default connect(mapState)(AuthRoute);