import {Alert} from 'rsuite';
import React, {useEffect} from 'react';
import RulesTable from './RulesTable';
import PageHeader from '../../components/base/PageHeader';
import PanelLayout from '../../components/base/PanelLayout';
import {checkPermissionsFor} from '../../store/storeHelpers';
import { OTP_RULE_API_CREATE, OTP_RULE_API_MODIFY, OTP_RULE_API_REMOVE } from '../../const/apiMethods';

export default ({
    data,
    loading,
    createRule,
    modifyRule,
    removeRule,

    getRules
}) => {

    useEffect(() => {
        getRules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDataUpdate = () => {
        getRules();
    };

    const handleCreateRule = async (params) => {
        if (params && !params.hasOwnProperty('senderid')) {
            params.senderid = '';
        }

        const r = await createRule(params);

        if (r.hasOwnProperty('otp_rule.key')) {
            Alert.success(`Rule was added`);
        }

        return r;
    };

    const handleModifyRule = async (params) => {
        if (params && params?.text === '') {
            params.text = null;
        }

        const r = await modifyRule(params);
        if (r.hasOwnProperty('otp_rule.key')) {
            Alert.success(`Rule was modified`);
        }
        return r;
    };

    const handleRemoveRule = async (key) => {
        const r = await removeRule(key);
        if (r.hasOwnProperty('otp_rule.key')) {
            Alert.success(`Rule was deleted`);
        }
        return r;
    };

    return (
        <PanelLayout>
            <PageHeader title="OTP Rules"></PageHeader>
            {/*<HeaderMessage type="info" description="Parsing rules"/>*/}
            <RulesTable
                data={data}
                loading={loading}
                onDataUpdate={onDataUpdate}
                onRowCreate={handleCreateRule}
                onRowSubmit={handleModifyRule}
                onRowRemove={handleRemoveRule}
                createPermission={checkPermissionsFor(OTP_RULE_API_CREATE)}
                modifyPermission={checkPermissionsFor(OTP_RULE_API_MODIFY)}
                removePermission={checkPermissionsFor(OTP_RULE_API_REMOVE)}
            />
        </PanelLayout>
    );
};