import React, {useEffect, useMemo, useRef} from 'react';
import WZ from '../../../../components/Filters/WZ';
import DE from '../../../../components/Filters/DE';
import SDE from '../../../../components/Filters/SDE';
import Search, {str} from '../../../../components/Filters/Search';
import {
    Form, FormGroup, Schema, DatePicker, SelectPicker,
    FormControl, IconButton, Icon, RadioGroup, Radio
} from 'rsuite';
import {Spacer} from "../../../../components/base/Spacer";
import FormHOC from '../../../../hoc/FilltersForm';
import './Allocated.css';
import Checkbox from '../../../../hoc/Checkbox';
import {debounce, hasErrorObject} from '../../../../utils';
import {DEFAULT_EQUAL_SIGN} from '../../../../const'
import * as S from "./styled";
import {Collapse} from "react-collapse";
import styled from "styled-components";
import NumbersDownloadDropdown from "./NumbersDownloadDropdown";

const {StringType} = Schema.Types;

const numbersFiltersModel = Schema.Model({str});
const prefixesFiltersModel = Schema.Model({
    prefix: StringType().pattern(/^[0-9][\d]*$/, 'The prefix must contain only digits')
        .maxLength(15, "The maximum is 15 characters"),
    mcc_mnc: StringType().pattern(/^[0-9][\d]*$/, 'The mcc and mcc must contain only digits')
        // .minLength(3, 'The minimum is 6 characters.')
        .maxLength(15, "The maximum is 15 characters"),
});

const NumbersFilters = React.forwardRef((
    {
        onChange,
        disabled,
        filters,
        onChangeFilters,
        currentDisplay,
        currentRangeNumber,
        onChangeGroup,
        isMobile,

        isGanTrunks,

        isTransitTrunks,

        downloadLoading,
        downloadDisabled,
        onDownloadNumbersCsv,
        onDownloadNumbersCsv1,
        onDownloadOnlyNumbersCsv,
        onDownloadOnlyNumbersCsv1,

    }, ref) => {

    let searchRef = useRef(null);

    const [showFilters, setShowFilters] = React.useState(false);
    const [searchInput, onChangeSearchInput] = React.useState(currentRangeNumber);
    const [formValue, onChangeFormValue] = React.useState({});
    const [startDateState, onChangeStartDate] = React.useState(null);
    const [equalSignState, onChangeEqualSign] = React.useState(DEFAULT_EQUAL_SIGN);
    const equalObject = {"<": -1, "=": DEFAULT_EQUAL_SIGN, ">": 1};

    useEffect(() => {
        onChangeSearchInput(currentRangeNumber);
        if (searchRef && searchRef.current) {
            if (!currentRangeNumber) {
                searchRef.current.value = null;
            }
        }
    }, [currentRangeNumber]);

    useEffect(() => {
        equalSignState && onChangeEqualSign(DEFAULT_EQUAL_SIGN);
        onChangeStartDate(null);
    }, [currentDisplay]);

    const isSuppliersTrunks = (isTransitTrunks || isGanTrunks);

    const numbersFilters = useMemo(() =>
        <Form
            defaultValue={filters || {}}
            model={numbersFiltersModel}
            ref={ref}
            readOnly={disabled}
            onChange={debounce((value) => {
                onChangeFormValue(value);
                const equalSign = value.start_date_inequation || Number.isInteger(value.start_date_inequation)
                    ? parseInt(value.start_date_inequation)
                    : undefined;
                let startDate = new Date(value.start_date);
                const day = startDate.getDate();
                const month = startDate.getMonth() + 1;
                const year = startDate.getFullYear();
                startDate = value.start_date
                    ? `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`
                    : undefined;
                const filterObject = {
                    ...value,
                    str: searchInput,
                    start_date: startDate,
                    start_date_inequation: startDate
                        ? startDateState ? parseInt(equalSign) : DEFAULT_EQUAL_SIGN
                        : undefined
                };

                onChange(filterObject);
            }, 500)}
        >
            <S.Grid noGutters={isMobile}>

            {!isGanTrunks && <>
                <S.GridItem isFullWidth={isMobile}>
                    <StyledField>
                        <WZ disabled={disabled}/>
                    </StyledField>
                </S.GridItem>

                <S.GridItem isFullWidth={isMobile}>
                    <StyledField>
                        <DE disabled={disabled}/>
                    </StyledField>
                </S.GridItem>

                <S.GridItem isFullWidth={isMobile}>
                    <StyledField>
                        <SDE disabled={disabled}/>
                    </StyledField>
                </S.GridItem>
            </>}
                
            {currentDisplay === "trunk_number" &&
                <S.GridItem isFullWidth={isMobile}>
                    <StyledField>
                        <FormGroup style={{marginRight: 10}}>
                            <FormControl
                                name="start_date"
                                style={{width: 150}}
                                accepter={DatePicker}
                                placeholder="Enter Date"
                                onChange={() => {
                                    onChangeStartDate(true);
                                }}
                                onClean={() => {
                                    onChangeStartDate(null);
                                    onChangeEqualSign(DEFAULT_EQUAL_SIGN);
                                }}
                                defaultValue={!startDateState && filters && filters.start_date}
                                disabled={disabled}
                            />
                        </FormGroup>
                    </StyledField>
                </S.GridItem>
            }

            {currentDisplay === "trunk_number" &&
                <S.GridItem isFullWidth={isMobile}>
                    <StyledField>
                        <FormGroup>
                            <FormControl
                                labelKey="label"
                                valueKey="value"
                                cleanable={false}
                                searchable={false}
                                value={equalSignState}
                                accepter={SelectPicker}
                                disabled={!startDateState || disabled}
                                name="start_date_inequation"
                                onChange={(value) => {
                                    onChangeEqualSign(value)
                                }}
                                data={Object.keys(equalObject).map(val => { return {value: equalObject[val], label: val}})}
                            />
                        </FormGroup>
                    </StyledField>
                </S.GridItem>
            }
            {!isSuppliersTrunks && 
                <S.GridItem isFullWidth={isMobile}>
                    <StyledField>
                        <Checkbox
                            disabled={disabled}
                            name="actual"
                            // checked={(filters || {}).actual === false}
                            defaultChecked={false}
                        >
                            Show outdated/revoked
                        </Checkbox>
                    </StyledField>
                </S.GridItem>
            }
            </S.Grid>
         
        </Form>, [currentDisplay, filters]);

    const transitTrunksFilters = useMemo(() =>
        <Form
            defaultValue={filters || {}}
            model={prefixesFiltersModel}
            ref={ref}
            readOnly={disabled}
            onChange={debounce((value) => {
                onChangeFormValue(value);
                const prefixValue = value.prefix;
                const mccMncValue = value.mcc_mnc;

                const filterObject = {
                    ...value,
                    prefix: prefixValue,
                    mcc_mnc: mccMncValue
                };

                const allValid = !hasErrorObject(prefixesFiltersModel.check(filterObject));

                if (allValid) {
                    onChange(filterObject);
                }
            }, 1000)}
        >
            <FlexibleGrid noWrap>
                <S.GridItem>
                    <StyledField>
                        <Search
                            name="prefix"
                            inputRef={searchRef}
                            onBlur={(event) => {
                                // event.target && event.target.name === 'str' && event.target.focus();
                            }}
                            disabled={disabled}
                            placeholder="Prefix"
                        />
                    </StyledField>
                </S.GridItem>
                <S.GridItem>
                    <StyledField>
                        <FormGroup>
                            <FormControl
                                cleanable={false}
                                searchable={false}
                                disabled={disabled}
                                name="mcc_mnc"
                                placeholder="MCC MNC"
                            />
                        </FormGroup>
                    </StyledField>
                </S.GridItem>
            </FlexibleGrid>
        </Form>, [currentDisplay, filters]
    );

    const ganTrunksFilters = useMemo(() =>
        <Form
            defaultValue={filters || {}}
            model={numbersFiltersModel}
            ref={ref}
            readOnly={disabled}
            onChange={debounce((value) => {
                onChangeFormValue(value);

                const filterObject = {
                    // str: searchInput,
                    ...value
      
                };

                onChange(value);
            }, 1000)}
        >
            <FlexibleGrid 
                noWrap 
                style={{marginBottom: 15, marginLeft: 2}}
            >
                <S.GridItem>
                    <StyledField>
                        <Search
                            name="str"
                            inputRef={searchRef}
                            onBlur={(event) => {
                                // event.target && event.target.name === 'str' && event.target.focus();
                            }}
                            disabled={disabled}
                            placeholder="Number/Group Name"
                            onChange={debounce((value) => {
                                onChangeSearchInput(value);
                                // onChange({...formValue, str: value})
                            }, 1000)}
                            style={{"min-width": "200px"}}
                        />
                    </StyledField>
                </S.GridItem>

                {currentDisplay === "numbers" && <>
                    <S.GridItem 
                    // isFullWidth={isMobile}
                    >
                        <StyledField>
                            <WZ disabled={disabled}/>
                        </StyledField>
                    </S.GridItem>

                    <S.GridItem>
                        <StyledField>
                            <DE disabled={disabled}/>
                        </StyledField>
                    </S.GridItem>

                    <S.GridItem>
                        <StyledField>
                            <SDE disabled={disabled}/>
                        </StyledField>
                    </S.GridItem>
                </>}

                {currentDisplay === "numbers" && <S.GridItem
                    isFullWidth={isMobile}
                    // style={{paddingLeft: isMobile ? 0 : 20}}
                >
                    <FormGroup>
                        <StyledField>
                            <Checkbox
                                disabled={disabled}
                                name="show_allocated_numbers"
                                // checked={(filters || {}).show_allocated_numbers === false}
                                defaultChecked={true}
                            >
                                Show allocated numbers
                            </Checkbox>
                        </StyledField>
        
                        <StyledField>
                            <Checkbox
                                disabled={disabled}
                                name="show_unallocated_numbers"
                                // checked={(filters || {}).show_unallocated_numbers === false}
                                defaultChecked={true}
                            >
                                Show unallocated numbers
                            </Checkbox>
                        </StyledField>
                
                        <StyledField>
                            <Checkbox
                                disabled={disabled}
                                name="show_test_numbers"
                                // checked={(filters || {}).show_test_numbers === false}
                                defaultChecked={true}
                            >
                                Show test numbers
                            </Checkbox>
                        </StyledField>
    
                        <StyledField>
                            <Checkbox
                                disabled={disabled}
                                name="show_block_allocation_numbers"
                                // checked={(filters || {}).show_block_allocation_numbers === false}
                                defaultChecked={true}
                            >
                                Show block allocation numbers
                            </Checkbox>
                        </StyledField>
                    </FormGroup>
                </S.GridItem>}
            </FlexibleGrid>
        </Form>, [currentDisplay, filters]
    );

    return (
        <S.Grid>
            <S.GridItem isFullWidth={isMobile}>
                <S.Grid noWrap style={{height: '58px'}}>
                    {/* refactor after ATX-2853 */}
                    {isTransitTrunks ? 
                        transitTrunksFilters
                    : isGanTrunks ? 
                        ganTrunksFilters
                    : <S.GridItem>
                        <Form
                            ref={ref}
                            style={{paddingRight: isMobile ? 0 : 18}}
                            formDefaultValue={{str: searchInput}}
                        >
                            <StyledField>
                                <Search
                                    inputRef={searchRef}
                                    onBlur={(event) => {
                                        // event.target && event.target.name === 'str' && event.target.focus();
                                    }}
                                    disabled={disabled}
                                    placeholder="Range/Number search"
                                    onChange={debounce((value) => {
                                        onChangeSearchInput(value);
                                        onChange({...formValue, str: value})
                                    }, 1000)}
                                />
                            </StyledField>
                        </Form>
                    </S.GridItem>}

                    {!isSuppliersTrunks && isMobile &&
                        <S.GridItem>
                            <IconButton
                                icon={<Icon icon="filter"/>}
                                appearance={showFilters ? "primary" : "default"}
                                onClick={() => setShowFilters(!showFilters)}
                            >
                            </IconButton>
                        </S.GridItem>
                    }

                    {!isGanTrunks && isMobile &&
                        <S.GridItem style={{marginLeft: 'auto'}}>
                            <NumbersDownloadDropdown
                                loading={downloadLoading}
                                disabled={downloadDisabled}
                                onDownloadNumbersCsv={onDownloadNumbersCsv}
                                onDownloadNumbersCsv1={onDownloadNumbersCsv1}
                                onDownloadOnlyNumbersCsv={onDownloadOnlyNumbersCsv}
                                onDownloadOnlyNumbersCsv1={onDownloadOnlyNumbersCsv1}
                                isMobile={isMobile}
                            />
                        </S.GridItem>
                    }
                </S.Grid>
            </S.GridItem>

            <S.GridItem>
                {!isSuppliersTrunks && <Collapse isOpened={!isMobile || showFilters}>
                    {numbersFilters}
                    {isMobile &&
                        <S.GridItem>
                            <RadioGroup
                                inline
                                onChange={onChangeGroup}
                                defaultValue={'trunk_number'}
                                name="radioList"
                            >
                                <Radio value="trunk_number" disabled={disabled}>Numbers</Radio>
                                <Radio value="price_range" disabled={disabled}>Ranges</Radio>
                                <Radio value="subdestination" disabled={disabled}>Subdestinations</Radio>
                            </RadioGroup>
                        </S.GridItem>
                    }
                </Collapse>}
            </S.GridItem>
        </S.Grid>
    );
});

export default FormHOC(NumbersFilters, numbersFiltersModel, 300, false);

const StyledField = styled.div`
    display: inline-block;
    
    @media (max-width: 567px) {
        display: block;
        
        .rs-input {
            min-width: 30px;
        }
        
        && .rs-control-label {
            display: block;
            margin-bottom: 5px;
            margin-top: 0;
        }
        
        && .rs-form-group {
            display: block;
        }
    }
`;

const FlexibleGrid = styled(S.Grid)`
    && {
        display: flex;
        flex-flow: row wrap;
    }

    .rs-flex-box-grid-item:nth-child(4n) {
        page-break-after: always;
        break-after: always;
        wrap-after: flex;
    }
`;