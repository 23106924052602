import {DROPDOWN_ACCOUNTS as PREFIX} from "../const";

const initialState = {
    loading: false,
    fetched: false,
    items: [],
};


const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload}) => ({
        ...state,
        items: payload.items,
        loading: true,
        fetched: true,
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};
