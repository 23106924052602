import React from "react";
import {Link} from "react-router-dom";
import {Icon, Message} from "rsuite";
import {BaseButton} from "../../components/base/BaseButton";
import {Spacer} from "../../components/base/Spacer";
import styled from "styled-components";


export default ({success, token}) => {

    return (
        <StyledMessage
            type="error"
            title=""
            success={success}
            description={(
                <div>
                    {success
                        ? <ContentSuccess {...{token}}/>
                        : <ContentReject />
                    }
                    {!token &&
                        <>
                            <Spacer size={30}/>
                            <BaseButton
                                componentClass={Link}
                                buttonStyle="primary"
                                to={"/reset-password"}
                            >
                                Send verification again
                            </BaseButton>
                        </>
                    }
                </div>
            )}
        />
    )
};


const ContentReject = () => (
    <>
        <p><Icon icon="warning" size="2x"/></p>
        <Spacer size={20}/>
        <h5>Email is not verified</h5>
        <p>Verification link is wrong. Please, reset your password or contact your manager</p>
    </>
);

const ContentSuccess = ({token}) => (
    <>
        <p><Icon icon="check-circle" size="2x"/></p>
        <Spacer size={20}/>
        <h5>Verification Success</h5>
        <p>Your email address was successfully verified.</p>
        {token &&
            <>
                <p>Please, follow the link below to set your account password</p>
                <Spacer size={30}/>
                <p>
                    <BaseButton
                        componentClass={Link}
                        buttonStyle="primary"
                        to={"/reset-password/" + token}
                    >
                        Set password
                    </BaseButton>
                </p>
            </>
        }
    </>
);


const StyledMessage = styled(Message)`
    .rs-message-container {
        border: 2px solid #cdcdcd;
        border-radius: 5px;
        background-color: #ffffff;
        text-align: center;
        
        body & {
            padding-bottom: 30px;
            padding-top: 30px;
        }
        
        h5 {
            margin-bottom: 15px;
        }
        
        .rs-icon {
            color: ${props => props.success 
                ? 'var(--color-success)' 
                : 'var(--color-error)'};
        }
    }
`;