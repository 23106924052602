import {defineMessages} from "react-intl";
import {Tooltip} from "rsuite";
import React from "react";

export default defineMessages({
    name: {
        id: 'numbers.trunkTable.name',
        defaultMessage: 'Name',
        description: 'Numbers, trunk table: Name'
    },
    type: {
        id: 'numbers.trunkTable.type',
        defaultMessage: 'Type',
        description: 'Numbers, trunk table: Type'
    },
    price: {
        id: 'numbers.trunkTable.price',
        defaultMessage: 'Price',
        description: 'Numbers, trunk table: Price'
    },
    trunkIsNotActive: {
        id: 'numbers.trunkTable.trunkIsNotActive',
        defaultMessage: 'Trunk is not active',
        description: 'Numbers, trunk table: Trunk is not active'
    },
    googleOTPAllocation: {
        id: 'numbers.trunkTable.googleOTPAllocation',
        defaultMessage: 'Google OTP allocation',
        description: 'Numbers, trunk table: Google OTP allocation'
    },
    allocatedNumbersTrunkTable: {
        id: 'numbers.trunkTable.allocatedNumbersTrunkTable',
        defaultMessage: 'Allocated Numbers',
        description: 'Numbers, trunk table: Allocated Numbers'
    },
    allocatePerTemplate: {
        id: "numbers.allocatePerTemplate",
        defaultMessage: "Allocate per template",
        description: "Numbers, ModalAllocationNumbers: Allocate per template"
    },
    defaultTerminationPoint: {
        id: "numbers.trunkTable.defaultTerminationPoint",
        defaultMessage: "Default termination point",
        description: "Numbers, trunk table: Default termination point"
    },
    clickToSeeCLILimitationForTrunk: {
        id: "numbers.trunkTable.clickToSeeCLILimitationForTrunk",
        defaultMessage: "Click to see CLI limitation for trunk",
        description: "Numbers, trunk table: Click to see CLI limitation for trunk"
    },
    putListOfOwnNumbers: {
        id: "numbers.putListOfOwnNumbers",
        defaultMessage: "Put list of own numbers in the field below",
        description: "Numbers, ModalAllocationNumbers: Put list of own numbers in the field below"
    },
    allocateNumbers: {
        id: "numbers.allocateNumbers",
        defaultMessage: "Allocate numbers",
        description: "Numbers, ModalAllocationNumbers: Allocate numbers"
    },
    clickToEnable: {
        id: "numbers.clickToEnable",
        defaultMessage: "CLICK TO <colored>ENABLE</colored>",
        description: "Numbers, ModalAllocationNumbers: CLICK TO ENABLE"
    },
    eachPhoneNumberConditions: {
        id: "numbers.eachPhoneNumberConditions",
        defaultMessage: "Each phone number<break></break>in a separate row with<break></break><wrapt>ENTER</wrapt> button",
        description: "Numbers, ModalAllocationNumbers: Each phone number in a separate row with ENTER button"
    },


    allocateNumbersForGoogleOTP: {
        id: "numbers.googleOTPAllocateModal.allocateNumbersForGoogleOTP",
        defaultMessage: "Allocate numbers for Google OTP",
        description: 'ModalAllocationGoogleOtp: Allocate numbers for Google OTP'
    },
    downloadCompleteList: {
        id: "numbers.listModal.downloadCompleteList",
        defaultMessage: "Download complete list of allocated numbers",
        description: 'Numbers, NumbersAllocateListModal: Download complete list of allocated numbers'
    },
    downloadAllNumbers: {
        id: "numbers.listModal.downloadAllNumbers",
        defaultMessage: "Download all numbers",
        description: 'Numbers, NumbersAllocateListModal: Download all numbers'
    },
    newAllocatedNumbers: {
        id: "numbers.listModal.newAllocatedNumbers",
        defaultMessage: "New allocated numbers",
        description: 'Numbers, NumbersAllocateListModal: New allocated numbers'
    },
    numbersWereAllocated: {
        id: "numbers.listModal.numbersWereAllocated",
        defaultMessage: "{count} numbers were allocated",
        description: 'Numbers, NumbersAllocateListModal: {count} numbers were allocated'
    },
    numbersWereNotAllocated: {
        id: "numbers.listModal.numbersWereNotAllocated",
        defaultMessage: "{count} numbers were not allocated",
        description: "Numbers, NumbersAllocateListModal: {count} numbers were not allocated",
    },
    cannotBeAllocated: {
        id: "numbers.listModal.cannotBeAllocated",
        defaultMessage: "\"{number}\" cannot be allocated",
        description: "Numbers, NumbersAllocateListModal: \"{number}\" cannot be allocated",
    },
    putTemplatePrefixForDestination: {
        id: 'numbers.googleOTPAllocateModal.putTemplatePrefixForDestination',
        defaultMessage: 'Put template/prefix for destination',
        description: 'ModalAllocationGoogleOtp: Put template/prefix for destination'
    },
    orChooseItFromList: {
        id: 'numbers.googleOTPAllocateModal.orChooseItFromList',
        defaultMessage: 'or choose it from list',
        description: 'ModalAllocationGoogleOtp: or choose it from list'
    },
    rangeNumberSearch: {id: "numbers.filters.rangeNumberSearch", defaultMessage: "Range/Number search", description: 'Numbers, filters: Range/Number search'},
    rateRangeFrom: {id: "numbers.filters.rateRangeFrom", defaultMessage: "Rate range from", description: 'Numbers, filters: Rate range from'},
    rateRange: {id: "numbers.filters.rateRange", defaultMessage: "Rate range", description: 'Numbers, filters: Rate range'},
    to: { id: "numbers.filters.to", defaultMessage: "to", description: 'Numbers, filters: to' },
    from: { id: "from.filters.from", defaultMessage: "from", from: 'Numbers, filters: from' },
    zone: { id: "numbers.allocatedNumbersTable.zone", defaultMessage: "Zone", description: 'Numbers, allocated numbers table: Zone' },
    range: { id: "numbers.allocatedNumbersTable.range", defaultMessage: "Range", description: 'Numbers, allocated numbers table: Range' },
    number: { id: "numbers.allocatedNumbersTable.number", defaultMessage: "Number", description: 'Numbers, allocated numbers table: Number' },
    start_date: { id: "numbers.allocatedNumbersTable.start_date", defaultMessage: "From date", description: 'Numbers, allocated numbers table: From date' },
    rate: { id: "numbers.allocatedNumbersTable.rate", defaultMessage: "Rate", description: 'Numbers, allocated numbers table: Rate' },

    trunkWithWhitelist: {id: "NumbersRevokeModal.trunkWithWhitelist", defaultMessage: "Trunk with whitelist", description: 'common: Trunk with whitelist'},
    revokeNumbers: {id: "NumbersRevokeModal.revokeNumbers", defaultMessage: "Revoke numbers", description: 'common: Revoke numbers'},
    numbersWillBeRevoked: {id: "NumbersRevokeModal.numbersWillBeRevoked", defaultMessage: "{count} numbers will be revoked and you will loose them. Do you really want to do that?", description: 'NumbersRevokeModal: {count} numbers will be revoked and you will loose them. Do you really want to do that?'},
    numbersWereRevoked: {
        id: "NumbersRevokeModal.numbersWereRevoked",
        defaultMessage: "{count} numbers have been revoked.",
        description: "Subaccounts : {count} numbers have been revoked.",
    },
    rateWasChanged: {
        id: "numbers.rateWasChanged",
        defaultMessage: "Rate was changed for {count} numbers.",
        description: "Subaccounts: Rate was changed for {count} numbers.",
    },
    payYourAttention: {
        id: "allocationModals.payYourAttention",
        defaultMessage: "Pay your attention that allocated numbers will be activated during 10 minutes after allocation. Don't dial these numbers before.",
        description: "Allocation Modals: Pay your attention that allocated numbers will be activated during 10 minutes after allocation. Don't dial these numbers before."
    },
    pleaseNoteAllocatedNumbers: {
        id: "allocationModals.pleaseNoteAllocatedNumbers",
        defaultMessage: "Please note allocated numbers have limitation for services can be used for making traffic. CLI of services you can find here",
        description: "Allocation Modals: Please note allocated numbers have limitation for services can be used for making traffic. CLI of services you can find here"
    },
    noteAllocatedNumbersLink: {
        id: "allocationModals.noteAllocatedNumbersLink",
        defaultMessage: "OTP Numbers",
        description: "Allocation Modals, Page: OTP Numbers"
    },
    amountOfOrderedNumbersExceedsPolicy: {
        id: "numbers.amountOfOrderedNumbersExceedsPolicy",
        defaultMessage: "Amount of ordered numbers exceeds the allocation policy.",
        description: "Numbers, Allocation warning: Amount of ordered numbers exceeds the allocation policy."
    }
})
