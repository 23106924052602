import {
    ACCESS_LIST_FILTERS_CLIENT as PREFIX,
    DEFAULT_PER_PAGE_MEDIUM,

    SET_ACCESS_LIST_FILTERS_NOTIFICATION_DIALER
} from "const";

const initialState = {
    items: [],
    settings: {},
    settingsIsFetched: false,
    // count: 0,
    // page: 1,
    // per_page: DEFAULT_PER_PAGE_MEDIUM,
    loading: false
};

const handlers = {
    ["SET_ITEMS_" + PREFIX]: (state, {payload: {list}}) => ({
        ...state,
        items: list
    }),
    ["SET_LOADING_" + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    [SET_ACCESS_LIST_FILTERS_NOTIFICATION_DIALER]: (state, {payload}) => ({
        ...state, 
        settings: payload,
        settingsIsFetched: !state.settingsIsFetched ? true : state.settingsIsFetched 
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}