import React, {useState, useRef} from "react";
import styled from "styled-components";
import {
    ControlLabel, Form, FormControl,
    Uploader, Icon
} from "rsuite";

import useWindowWidth from "../../hooks/useWindowWidth";
import {Spacer} from "../../components/base/Spacer";
import {CustomModal} from "../../components/base";
import { BaseButton } from "components/base/BaseButton";


const FILES_DEFAULT = [];


export default (
    {
        setFormValue,
        maxWidth = 600,
        loading,
        supplierData,
        onClose,
        ...props
    }
) => {

    const formRef = useRef(null);
    const resizedWidth = useWindowWidth();

    const [files, setFiles] = useState(FILES_DEFAULT);

    return (
        <>
            <StyledModal
                showFooter={true}
                title={`Upload access lists for ${supplierData?.supplier_name}`}
                width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
                loading={loading}
                onClose={() => {
                    onClose();
                }}
                onExited={() => setFiles(FILES_DEFAULT)}
                {...props}
            >
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                >
                    <FormControl
                        disabled={files.length > 0}
                        accepter={StyledUploader}
                        fileList={files}
                        autoUpload={false}
                        multiple={false}
                        removable={true}
                        accept="text/csv"
                        name="files"
                        onChange={(files) => {
                            setFiles(files);
                        }}
                    >
                        <BaseButton>
                            Upload via CSV
                        </BaseButton>
                    </FormControl>

                    <Spacer size={15}/>

                    <StyledControlLabel>
                        * The CSV file must consist of 2 columns: A-number, and B-number
                    </StyledControlLabel>

                </Form>
            </StyledModal>
        </>
    )
};


const StyledUploader = styled(Uploader)`
    
    .rs-uploader-file-item.rs-uploader-file-item-text {
        display: block !important;
    }

    .rs-uploader-file-item {
        background-color: transparent !important;
    }
    
    .rs-uploader-file-items {
        margin-top: 5px !important;
        flex-grow: 1;
    }
    
    .rs-uploader-file-item-title {
        white-space: normal !important;
    }

    .rs-uploader-file-item-size {
        margin-left: auto;
    }

    &&& {
        .rs-btn-disabled {
            color: var(--color-text);
        }
    }
`;

const StyledModal = styled(CustomModal)`
    .rs-modal-body {
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const StyledControlLabel = styled(ControlLabel)`
    font-style: italic;
    opacity: 0.6;
`;

const StyledCloseIcon = styled(Icon)`
    order: 1;
    align-self: center;
    margin-left: 20px;
    cursor: pointer;
`;