import React, { useContext } from "react";
import { Form, FormControl, FormGroup, InputGroup, Icon } from "rsuite";
import { debounce } from "lodash";
import styled, {css} from "styled-components";
import m from "definedMessages";
import { RtlContext } from "App";


const AuthNumbersFilter = ({
    filter,
    setFilter,

    formatMessage
}) => {
    const rtl = useContext(RtlContext);

    return (<StyledForm
        formDefaultValue={filter}
        onChange={debounce((value) => {
            setFilter(value)
        }, 300)}
    >
        <StyledFormGroup rtl={rtl}>
            <InputGroup inside>
                {rtl && <InputGroup.Addon><Icon icon="search"/></InputGroup.Addon>}
                <StyledFormControl
                    name="str"
                    placeholder={`${formatMessage(m.number)}/OTP ${formatMessage(m.service)}`}
                />
                {!rtl && <InputGroup.Addon><Icon icon="search"/></InputGroup.Addon>}
            </InputGroup>
        </StyledFormGroup>
    </StyledForm>)
};


export default AuthNumbersFilter;


const StyledForm = styled(Form)`
    && {
        width: 100%;
        display: flex;
    }

    .rs-form-control-wrapper {
        width: auto;
        margin-right: 20px;
    }
`;

const StyledFormControl = styled(FormControl)`
    && {
        width: 100%;
        min-width: 300px;
        max-width: 320px;
        overflow: hidden;
    }
`;

const StyledFormGroup = styled(FormGroup)`
    &&&& {
        display: flex;
        align-items: center;

        width: 100%;

        .rs-control-label {
            margin-right: 10px;
            margin-bottom: 0px;
        }

        .rs-input-group.rs-input-group-inside .rs-input {
            padding-right: 11px;
        }

        .rs-input-group-addon {
            ${props => props.rtl ? css`
                right: 300px !important;
                width: 28px;
            ` : css`
                left: 280px !important;
            `}
        }
    }
`;