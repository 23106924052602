import React, {useRef, useEffect, useState} from 'react';
// import Modal from '../../../../components/Modal/Modal';
import {CustomModal} from "../../../../components/base";
import {api} from '../../../../api/loginRoutes';
import {SCREEN_MEDIA} from '../../../../const';
import {Spacer} from "../../../../components/base/Spacer";
import {Alert, Schema, ControlLabel, Form, FormControl, InputNumber} from 'rsuite';

import styled, {css} from "styled-components";
import {
    INCORPORATED_GROUP_DEFAULT_RATE_LIST_API,
    SMS_SUPPLIER_GAN_GROUP_MODIFY_METHOD,
    INCORPORATED_GROUP_PRICE_MODIFY_API,
    SMS_SUPPLIER_GAN_GROUP_GROUP_PRICE_MODIFY_METHOD
} from '../../../../const/apiMethods';
import TablePayments from "../../../../components/Table/TablePayments";
import {servicePick} from "../../../../utils/helpers";

const {StringType, NumberType} = Schema.Types;

const formModel = Schema.Model({
    name: StringType()
        .maxLength(40, 'Max length is 40'),
    comment: StringType()
        .maxLength(200, 'Max length is 200'),
    rate: NumberType()
        .min(0, '0 is minimum')
        .max(10, '10 is maximum')
});



export default (
    {
        show,
        service,
        selectedItems,
        currentGroupData,
        extraParams,
        currencyList = [],
        paymentTermsList = [],
        onSuccess,
        onClose,
        disabled=false
    }
) => {
    
    const rateEditTimer = useRef(null);
    const formRef = useRef(null);
    const [_disabled, setDisabled] = React.useState(disabled);
    const [loading, setLoading] = useState(false);

    const [rates, setRates] = useState([]);
    const [formValue, setFormValue] = useState(selectedItems);
    const [ratesUpdateLoading, setRatesUpdateLoading] = useState(false);

    const checkKey = 'sms.supplier_gan_group_id';
    const method = SMS_SUPPLIER_GAN_GROUP_MODIFY_METHOD;

    useEffect(() => {
        if (!formValue?.rate_list || !formValue.rate_list.length) {
            setRates([]);
            return;
        }

        setRates(formValue.rate_list.map(item => ({cur_key: item.cur_key, pt_key: item.pt_key, rate: item.rate})));
    }, [formValue?.rate_list]);

    const handleChangeRate = (value) => {
        clearTimeout(rateEditTimer.current);
        rateEditTimer.current = setTimeout( () => {
            
            if (!value || formModel.check({rate: value}).rate.hasError) {
                return;
            }

            if (service) {
                setRatesUpdateLoading(true);
            }

            const method = INCORPORATED_GROUP_DEFAULT_RATE_LIST_API;

            const params = {
                ing_key: currentGroupData.ing_key,
                rate: +value
            };
            
            if (service) {
                api(method, params)
                    .then(r => {
                        if (!r || !r.default_rate_list) {
                            return;
                        }

                        setRates(r.default_rate_list.map(item => ({cur_key: item.cur_key, pt_key: item.pt_key, rate: item.rate})));

                    })
                    .finally(() => {
                        setRatesUpdateLoading(false);
                    });
            }
        }, 500 );
    };

    const handleChangeRates = (value) => {
        if (!rates.find(item => item.cur_key === value.cur_key && item.pt_key === value.pt_key)) {
            setRates(rates => [...rates, value]);
            return;
        }

        const ratesUpdated = rates.map(item => {
            return item.cur_key === value.cur_key && item.pt_key === value.pt_key 
                ? value
                : item;
        });
        setRates(ratesUpdated);
    };

    const onSubmit = async () => {
        
        const params = {
            target: {
                'sms.supplier_gan_group_id': selectedItems?.id
            },
            name: formValue?.name,
            comment: formValue?.comment || '',
            rate: +formValue?.rate
        };

        setDisabled(true);
        setLoading(true);
        
        const result = await api(method, params).then((response) => {
            const rateListMethod = servicePick(service, INCORPORATED_GROUP_PRICE_MODIFY_API, SMS_SUPPLIER_GAN_GROUP_GROUP_PRICE_MODIFY_METHOD);
            const rateListParams = servicePick(service, {
                ing_key: currentGroupData.ing_key,
            }, {
                target: {
                    "sms.supplier_gan_group_id": currentGroupData.id
                },
            })
            api(rateListMethod, {
                ...rateListParams,
                rate_list: rates
                    .filter(item => item?.rate)
                    .map(item => ({...item, rate: +item.rate})),
            })
                .then(r => {
    
                    if (!r) {
                        return;
                    }
    
                    Alert.success('Group prices have been updated');
                })
                .finally(() => {
                    setLoading(false);
                });
        }).finally(() => {
            onSuccess();
            setDisabled(false);
            onClose();
        });
    };


    return (
        <>
            {show && <CustomModal
                show={show}
                title="Modify numbers"
                showFooter={true}
                successText="Modify"
                disabled={_disabled}
                loading={loading}
                onClose={onClose}
                onConfirm={onSubmit}
            >
                <StyledForm
                    ref={formRef}
                    model={formModel}
                    formValue={formValue}
                    onChange={setFormValue}
                >

                    <StyledField>
                        <ControlLabel>Name</ControlLabel>
                        <FormControl
                            name="name"
                            placeholder="Enter name"
                        />
                    </StyledField>

                    <StyledField>
                        <ControlLabel>Comment</ControlLabel>
                        <FormControl
                            name="comment"
                            placeholder="Enter your comment here..."
                            componentClass="textarea"
                            style={{height: 140}}
                        />
                    </StyledField>

                    <StyledField>
                        <ControlLabel>Rate</ControlLabel>
                        <FormControl
                            name="rate"
                            accepter={InputNumber}
                            onChange={handleChangeRate}
                            placeholder="Rate"
                            max={10}
                            min={0}
                        />
                    </StyledField>

                    <>
                        <Spacer />

                        <TablePayments
                            {...{
                                paymentTermsList,
                                currencyList,
                                rates,
                            }}
                            loading={ratesUpdateLoading}
                            disabled={false}
                            onChangeRates={handleChangeRates}
                        />
                    </>

                </StyledForm>
            
            </CustomModal>
            }
        </>

    );
}



const StyledForm = styled(Form)`
    .rs-form-group {
        display: flex;
        align-items: center;
    }
    
    .rs-control-label {
        margin-right: 20px;
        margin-bottom: 0;
        width: 100px;
    }
    
    .rs-picker-select {
        width: 100%;
    }
`;

const StyledField = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
    }

    .rs-control-label {
        flex-shrink: 0;
        align-self: center;
        padding-right: 10px;
        width: 100%;
        margin-right: 0;

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            width: 160px;
        }
    }

    .rs-input-number {

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            max-width: 90px;
        }
    }

    ${props => props.isCheckbox && css`
        display: flex;
        
        @media (max-width: ${SCREEN_MEDIA.sm.max}px) {

            .rs-form-control-wrapper {
                width: auto;
            }

            .rs-control-label {
                width: auto;
                order: 1;
            }
        }
    `}
`;