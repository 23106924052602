import React, {useState} from "react";
import Modal from "../../../components/Modal";
import {api} from "../../../api/loginRoutes";
import {Alert, Checkbox, DatePicker} from "rsuite";
import {toUTCDateTime, uniqueArrayFilter} from "utils";
import {ButtonClose as Revoke} from "../../../components/base/BaseButton";
import {servicePick, getServiceSmsPrefix} from "../../../utils/helpers";


export default ({getList, disabled, update, service, pr_key, filters, selectedNumbersInfo, incorporatedOnly}) => {
    const [show, setShow] = React.useState(false);
    const [_disabled, setDisabled] = React.useState(false);

    const onSubmit = async () => {
        setDisabled(true);

        const selected = getList();
        const numbersKeysList = selected.list;
        const selectedAll = selected.all;

        let params = {};

        if (incorporatedOnly && !service) {
            params = {
                target: {
                    // ...(service ? {trunk_id: trunkId} : {'sms.trunk_id': trunkId}) // TODO
                }
            };
        }
        
        if (selectedAll) {
            params[getServiceSmsPrefix(service, "pr_key")] = pr_key;
            params.filter = {str: filters?.str};
            params.filtersAll = true;
        } else {

            if (incorporatedOnly) {
                if (service) {
                    params.inn_key_list = selectedNumbersInfo.map(item => item.inn_key);
                } else {
                    params["sms.supplier_gan_key_list"] = selectedNumbersInfo.map(item => item.slg_key);
                }
            } else {
                params[getServiceSmsPrefix(service, "pr_key")] = pr_key;
                params.prn_key_list = numbersKeysList;
            }
        }

        const method = incorporatedOnly 
            ? servicePick(service, "incorporated_number:revoke", "sms.supplier_gan:revoke_list")
            : getServiceSmsPrefix(service, "price_range_number:revoke_list");

        // let result = null;
        // let resultNumbers = 0;
        
        // if (incorporatedOnly && !service) {
        //     const trunkList = selectedNumbersInfo.map(value => value.trunk_id);
        //     const uniqueTrunkList = trunkList.filter(uniqueArrayFilter);

        //     uniqueTrunkList.forEach(async (id) => {
        //         params = {
        //             ...params,

        //             target: {
        //                 "sms.supplier_trunk_id": id
        //             }
        //         }

        //         result = await api(method, params);

        //         const numberCount = incorporatedOnly 
        //             ? service ? result["incorporated_numbers"] : result["sms.supplier_gan"]
        //             : result["price_range_numbers"];

        //         resultNumbers += numberCount;
        //     });
         
        // } else {
        //     result = await api(method, params);
        //     resultNumbers = incorporatedOnly 
        //         ? service ? result["incorporated_numbers"] : result["sms.supplier_gan"]
        //         : result["price_range_numbers"];
        // }

        const  result = await api(method, params);
        const resultNumbers = incorporatedOnly 
            ? service ? result["incorporated_numbers"] : result["sms.supplier_gan"]
            : result["price_range_numbers"];

        if (result && resultNumbers) {
            if (resultNumbers === 1) {
                Alert.success(`1 number was revoked`);
            } else {
                Alert.success(`${resultNumbers} numbers were revoked`);
            }
        } else {
            Alert.warning(
                `${numbersKeysList.length > 1 ? "These numbers" : "This number"} can"t be revoked`
            );   
        }

        setShow(false);
        update();
        setDisabled(false);
    };

    const selectedCount = () => {
        const data = getList();

        if (data.all)
            return data.countAll;
        return selectedNumbersInfo.reduce((summ, item) => summ + item.numbers_count, 0);
    };

    return (
        <>
            <Revoke disabled={disabled} onClick={() => setShow(true)}>Revoke</Revoke>
            {show && <Modal
                show={show}
                title="Revoke numbers"
                onClose={() => setShow(false)}
                footer={true}
                successText="Yes"
                disabled={_disabled}
                onSuccess={onSubmit}
            >
                Selected {selectedCount()} numbers from this range will be revoked.
                Do you really want to do it?
            </Modal>
            }
        </>

    );
}