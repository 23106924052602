import {IVR_DEFAULT as PREFIX} from "const/";
import {api} from "api/loginRoutes";
import {createAction} from  "./defaults";
import {IVR_DEFAULT_GET_LIST_API, IVR_SET_DEFAULT_LIST_API} from "const/apiMethods";
import {Alert} from "rsuite";

const setDefaultIvrList = createAction("SET_ITEMS_" + PREFIX);
const setLoading = createAction("SET_LOADING_" + PREFIX);

export const getDefaultIvrList = () => (dispatch) => {
    dispatch(setLoading(true));
    api(IVR_DEFAULT_GET_LIST_API)
        .then(({ivr_default_list}) => {
            if (ivr_default_list === undefined) {
                return;
            }
            dispatch(setDefaultIvrList({ivr_default_list}));
        })
        .finally(() => dispatch(setLoading(false)));
};


export const changeDefaultIvrList = (params) => async(dispatch) => {
    return await api(IVR_SET_DEFAULT_LIST_API, params);
};

export const setDefaultIvrLoading = (loading) => (dispatch) => {
    dispatch(setLoading(loading));
};