import React, {useRef, useState} from 'react';
import FormHOC from '../../hoc/FilltersForm';
import ReactJson from 'react-json-view';
import {List, Alert, FlexboxGrid, Message, Icon, Divider, Affix, Table, Button} from 'rsuite';
import {Link} from 'react-router-dom'
import {NavHashLink as NavLink} from 'react-router-hash-link';
import ApiCurrentApiKey from './ApiCurrentApiKey';
import styled from 'styled-components';

import {SCREEN_MEDIA} from '../../const';
import Badge from 'rsuite/es/Badge'

const {md} = SCREEN_MEDIA;

const requestBodyParams =
    {
        "jsonrpc": "2.0",
        "method": "YOUR_METHOD",
        "params": "YOUR_PARAMS",
        "id": null
    }
;

const {Column, HeaderCell, Cell} = Table;

export default FormHOC(({pickedApiKey, newItem, methods = []}) => {
    const refTextAreaObj = {};
    const anchorRefObj = {};

    const apiKey = pickedApiKey || newItem || '{YOUR_API_KEY}';

    const [topAnchorVisible, onChangeFixedTopAnchor] = useState(false);
    const [showJsonResponse, setShowJsonResponse] = useState({});

    const createCsvBadge = (method) => {
        return method.csv ? <StyledBadge content='CSV available'/> : <></>
    };

    const baseURL = process.env.REACT_APP_API_URL;

    const apiLink = `${baseURL}`;
    const defaultLink = `${apiLink}`;

    refTextAreaObj['default'] = useRef(null);

    const scrollEffect = (targetRef) =>{
        targetRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    };

    const makeLayerRecursive = (layer, count=null) => {
        count = count || 1;
        const tabulationsLayer = '\t'.repeat(count);
        
        return Object.keys(layer).map((key, idx) => {
            const value = layer[key];

            const coma = idx+1 !== Object.keys(layer).length ? ',' : '';

            if (typeof value === "object" && !(value instanceof Array) && value !== null) {
                return `\n${tabulationsLayer}"${key}": {${makeLayerRecursive(value, count+1)}\n${tabulationsLayer}}${coma}`;
            }

            if (key[0] === "#") {
                return `\n${tabulationsLayer}# ${key.slice(1)}`;
            }

            return `\n${tabulationsLayer}"${key}": ${JSON.stringify(value)}${coma}`;
        }).join("");
    };

    const apiLinkContainer = (link, curl=true, method='default', api_key=null, body={}, csv=false) => {
        const curlLink = `curl -X POST -H "Content-Type: application/json" -H "Api-Key: ${api_key}" --data '{"id": null, "jsonrpc": "2.0",\n\t"method": "${method}",\n\t"params": {${makeLayerRecursive(body, 2)}\n\t}\n}' '${link}'`;

        const currentLink = curl ? curlLink : link;
        const showLink = curl ? <pre>{curlLink}</pre> : <div>{link}</div>;

        return (
            <FlexboxGrid className="api_documentation-api_link">
                <FlexboxGrid.Item>
                    <Message
                        className="api_documentation-api_link_wrapper"
                        appereace="ghost"
                        title={
                            <>
                                <span className="api_documentation-api_link_wrapper_header">
                                    {curl ? 'CURL Link' : 'API Link'}
                                </span>
                                <Icon
                                    className="api_documentation-api_link_wrapper_icon"
                                    onClick={() => {
                                        refTextAreaObj[method].current.select();
                                        document.execCommand('copy');
                                        Alert.info('URL has been copied');
                                    }}
                                    icon="clone"
                                />
                            </>
                        }
                        description={showLink}
                    />
                    <textarea
                        ref={refTextAreaObj[method]}
                        value={currentLink}
                        style={{
                            width: '0',
                            height: '0',
                            resize: 'none',
                            border: '0',
                            position: 'absolute',
                            opacity: 0
                        }}
                        readOnly
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        )
    };

    return (
        <>
            <div className="top-mark" id="top"/>
            <span className="api_documentation">API Documentation</span>
            <ApiCurrentApiKey apiKey={apiKey}/>
            <Affix
                onChange={(fixed) => {
                    onChangeFixedTopAnchor(fixed)
                }}
                className="api_documentation-anchor"
            >
                {topAnchorVisible && <NavLink className="api_documentation_anchor-link" to="#top">To the top <Icon icon="sequence-up"/></NavLink>}
            </Affix>

            <BriefList bordered>
                {methods.map(method => {
                    return (
                        <List.Item className="briefList__item">
                            <NavLink
                                className="briefList__method"
                                to={`#${method.method_api}`}
                                scroll={el => el.scrollIntoView({
                                    behaviour: 'smooth',
                                    block: 'start',
                                    inline: 'center'
                                })}
                            >
                                {method.method_api}
                            </NavLink>
                            <Divider vertical className="briefList__divider"/>
                            <p className="briefList__desc">{method.description}</p>
                        </List.Item>
                    )
                })}
            </BriefList>

            <div className="api_documentation-request">
                <div className="api_documentation-request_header">Request</div>

                {apiLinkContainer(defaultLink, false)}

                <FlexboxGrid>
                    <FlexboxGrid.Item>
                        <span className="api_documentation-request_http_method_header">HTTP Method:</span>
                        <span className="api_documentation-request_http_text">POST</span>
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <div className="api_documentation-request_body_header">Body:</div>

                <ReactJson
                    src={requestBodyParams}
                    name={null}
                    displayDataTypes={false}
                    displayObjectSize={false}
                />
            </div>

            <div className="api_documentation_list-methods_header">Methods</div>
            <List className="api_documentation_list">
                {
                    methods.map(method => {
                        const group = method['group'];

                        const link = `${apiLink}${method.csv ? '/csv' : ''}`;

                        refTextAreaObj[method.method_api] = useRef(null);
                        anchorRefObj[method.method_api] = useRef(null);

                        return <List.Item className="api_documentation_list-item" id={method.method_api}>

                            {/* ANCHOR */}
                            <textarea
                                ref={anchorRefObj[method.method_api]}
                                value={method.method_api}
                                style={{
                                    width: '0',
                                    height: '0',
                                    resize: 'none',
                                    border: '0',
                                    position: 'absolute',
                                    opacity: 0
                                }}
                                readOnly
                            />

                            {method?.id ? <FlexboxGrid justify="center">
                                {method?.service ? <FlexboxGrid.Item>
                                    <span className={`api_documentation_list-service ${method.service}`}>{method.service.toUpperCase()}</span>
                                </FlexboxGrid.Item> : <></>}
                                <FlexboxGrid.Item>
                                    <span className="api_documentation_list-group" id={`${method.id ? method.id : ''}`}>{group}</span>
                                </FlexboxGrid.Item>
                            </FlexboxGrid> : <></>}

                            <FlexboxGrid className="api_documentation_list-method_api">
                                <FlexboxGrid.Item>
                                    <span className="api_documentation_list-method_api_item header">METHOD:</span><span
                                    className="api_documentation_list-method_api_item text">
                                        {method.method_api}{createCsvBadge(method)}
                                    </span>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <FlexboxGrid className="api_documentation_list-description" align="middle">
                                <FlexboxGrid.Item>
                                    <span className="api_documentation_list-method_api_item header">DESCRIPTION:</span>
                                    <span className="api_documentation_list-method_api_item text">{method.description}</span>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            {method.params && Array.isArray(method.params) ? <>
                                <span className="api_documentation_list-method_api_item header">PARAMS:</span>
                                <Table
                                    height={method.params.length <= 10 ? (method.params.length + 1) * 42 + 40 : 440}
                                    data={method.params}
                                    wordWrap="break-word"
                                    rowHeight={40}
                                >
                                    <Column width={280} resizable>
                                        <HeaderCell>Name</HeaderCell>
                                        <Cell dataKey="name" />
                                    </Column>

                                    <Column width={160} resizable>
                                        <HeaderCell>Type</HeaderCell>
                                        <Cell dataKey="type" />
                                    </Column>

                                    <Column width={740}>
                                        <HeaderCell>Description</HeaderCell>
                                        <Cell dataKey="description" />
                                    </Column>
                                </Table>
                            </> : <></>}
                            
                            {apiLinkContainer(link, true, method.method_api, apiKey, method.request_body, method.csv)}
                            
                            <div className="api_documentation_list-response">
                                {/* make anchor after hide response */}
                                <div className="api_documentation_list-method_api_item header">RESPONSE EXAMPLES: </div>
                                {method.response ? <div className="api_documentation_list-method_api_item json">
                                    {showJsonResponse[method.method_api] ?
                                        Array.isArray(method.response) ? 
                                        <div className="api_documentation_list_response-json-list">
                                            {method.response.map((response, idx) => {
                                                return <div className="api_documentation_list_response-json-item">
                                                    <ReactJson
                                                        src={response}
                                                        name={false}
                                                        displayDataTypes={false}
                                                        displayObjectSize={false}
                                                    />
                                                    {idx < method.response.length-1 ? <hr /> : <></>}
                                                </div>
                                            })}
                                            <Button
                                                className="api_documentation_list_response_json-button hide_button"
                                                onClick={() => {
                                                    setShowJsonResponse((state) => {
                                                        return {
                                                            ...state,
                                                            [method.method_api]: false
                                                        }
                                                    })
                                                    scrollEffect(anchorRefObj[method.method_api]);
                                                }}
                                            >
                                                Hide example <Icon icon="eye-slash"/>
                                            </Button>
                                        </div>

                                        : <div className="api_documentation_list_response-json">
                                            <ReactJson
                                                src={method.response}
                                                name={false}
                                                displayDataTypes={false}
                                                displayObjectSize={false}
                                            />
                                            <Button
                                                className="api_documentation_list_response_json-button hide_button"
                                                onClick={() => {
                                                    setShowJsonResponse((state) => {
                                                        return {
                                                            ...state,
                                                            [method.method_api]: false
                                                        }
                                                    })
                                                    scrollEffect(anchorRefObj[method.method_api]);
                                                }}
                                            >
                                                Hide example <Icon icon="eye-slash"/>
                                            </Button>
                                        </div>
                                        : <>
                                        <Button
                                            className="api_documentation_list_response_json-button show_button"
                                            onClick={() => {
                                                setShowJsonResponse((state) => {
                                                    return {
                                                        ...state,
                                                        [method.method_api]: true
                                                    }
                                                })
                                            }}
                                        >
                                            Show example <Icon icon="eye"/>
                                        </Button>
                                        </>
                                    }
                                </div> : <></>}
                            </div>
                            
                            {
                            //  method.params && Array.isArray(method.params)
                            //         ? <List className="api_documentation_list-parameters_list" bordered>
                            //             {
                            //                 method.params.map(param => {
                            //                     const key = Object.keys(param).length !== 0 ? Object.keys(param)[0] : null;
                            //                     const paramProps = param && key && param.hasOwnProperty(key) ? param[key] : {};
                            //                     const cloneParams = Object.assign({}, paramProps);
                            //                     const description = paramProps['description'];
                            //                     const required = (method.required ? method.required.includes(key) : false).toString();
                            //                     delete cloneParams.description;

                            //                     return <List.Item
                            //                         className="api_documentation_list-parameters_list_item">
                            //                         <FlexboxGrid align="middle" className="api_documentation_list-parameters_list_item_wrapper">
                            //                             <FlexboxGrid.Item>
                            //                                 <div className="api_documentation_list-parameters_list_item_wrapper_item header">Name:</div>
                            //                                 <div className="api_documentation_list-parameters_list_item_wrapper_item">{key}</div>
                            //                             </FlexboxGrid.Item>

                            //                         </FlexboxGrid>
                            //                         <FlexboxGrid align="middle" className="api_documentation_list-parameters_list_item_wrapper">
                            //                             <FlexboxGrid.Item>
                            //                                 <div className="api_documentation_list-parameters_list_item_wrapper_item header">Description:</div>
                            //                                 <div className="api_documentation_list-parameters_list_item_wrapper_item">{description}</div>
                            //                             </FlexboxGrid.Item>
                            //                         </FlexboxGrid>
                            //                         <FlexboxGrid align="middle" className="api_documentation_list-parameters_list_item_wrapper">
                            //                             <FlexboxGrid.Item>
                            //                                 <div className="api_documentation_list-parameters_list_item_wrapper_item header">Type:</div>
                            //                                 <div className="api_documentation_list-parameters_list_item_wrapper_item">{!cloneParams.type ? 'Object' : cloneParams.type}</div>
                            //                             </FlexboxGrid.Item>
                            //                         </FlexboxGrid>
                            //                         <FlexboxGrid align="middle" className="api_documentation_list-parameters_list_item_wrapper">
                            //                             <FlexboxGrid.Item>
                            //                                 <div className="api_documentation_list-parameters_list_item_wrapper_item header">Required:</div>
                            //                                 <div className="api_documentation_list-parameters_list_item_wrapper_item">{required}</div>
                            //                             </FlexboxGrid.Item>
                            //                         </FlexboxGrid>
                            //                         <div className="api_documentation_list-parameters_list_item_wrapper">
                            //                             <div className="api_documentation_list-parameters_list_item_wrapper_item header">Structure:
                            //                             </div>
                            //                         </div>
                            //                         <ReactJson
                            //                             src={cloneParams}
                            //                             name={key}
                            //                             displayDataTypes={false}
                            //                             displayObjectSize={false}
                            //                         />

                            //                     </List.Item>;
                            //                 })
                            //             }
                            //         </List>
                            //         : <span className="api_documentation_list-method_api_item text not_available">
                            //             {method.params ? method.params : 'Additional parameters are not available'}
                            //         </span>
                            }

                        </List.Item>;
                    })
                }
            </List>
        </>
    );
});

const StyledBadge = styled(Badge)`
    && {
      margin-left: 5px;
      background-color: var(--color-primary);
    }
`;

const BriefList = styled(List)`
    margin-top: 20px;

    & .briefList__item {
        padding-top: 7px;
        padding-bottom: 7px;

        @media (min-width: ${md.min}px) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    
    .rs-list-item-content {
        min-height: 40px;
        
        @media (min-width: ${md.min}px) {
            align-items: center;
            display: flex;
        }
    }

    .briefList__method {
        color: var(--activate-bg) !important;
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        padding-bottom: 4px;
        padding-top: 4px;
        word-break: break-word;

        @media (min-width: ${md.min}px) {
            min-width: 300px;
        }
    }

    .briefList__desc {
        padding-bottom: 4px;
        padding-top: 4px;
        padding-left: 20px;

        .rtl & {
            padding-left: 0;
            padding-right: 20px;
        }
    }

    .briefList__divider {
        display: none;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;

        @media (min-width: ${md.min}px) {
            display: block;
        }
    }
`;