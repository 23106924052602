import {connect} from "react-redux";
import MassRevoke from "./MassRevoke";
import {getRevokeList} from "../../actions/mass_revoke";
import {getDropdownAccounts} from "../../actions/dropdown_accounts";
import {getGanSuppliersCommonList} from "../../actions/gan_suppliers";
import {getAccountManagersDropdownList} from "../../actions/account_managers";
import {getManagerAccountUserDropdownList} from "../../actions/manager_account_users";


const mapStateToProps = (
    {
        auth,
        references,
        mass_revoke,
        account_managers,
        dropdown_accounts,
        gan_suppliers,
        manager_account_users,
    }
) => ({
    defaultSPKey: references.defaultSPKey,
    worldzoneList: references.worldzone_list,
    destinationList: references.destination_list,
    servicePlanList: references.service_plan_list,
    referencesInited: references.inited,
    subdestinationList: references.subdestination_list,

    data: mass_revoke.list,
    service: auth.service,
    loading: mass_revoke.loading,

    adminUsers: manager_account_users.dropdown_list,
    accountManagers: account_managers.dropdown_list,
    accounts: dropdown_accounts.items,
    supplierList: gan_suppliers.commonList,
});

export default connect(mapStateToProps, {
    getRevokeList,
    getDropdownAccounts,
    getManagerAccountUserDropdownList,
    getAccountManagersDropdownList,
    getGanSuppliersCommonList,
})(MassRevoke);