import React, {useMemo, useState, useEffect} from 'react';
import {Button, Form, FormControl, Icon, Input, Schema, SelectPicker, Table} from 'rsuite';
import styled, {css} from "styled-components";
import BaseTable from "../../components/base/BaseTable";
import { renderColumn, servicePick } from 'utils';

// const {Column, HeaderCell, Cell} = Table;
const {NumberType, StringType} = Schema.Types;


export default ({data = [], filter = {}, service, onRemoveItem, onEditItem, loading, isLoading, update, ...props}) => {
    const [tableData, setTableData] = useState([]);
    const [currentEditedFieldKeyList, setCurrentEditedFieldKeyList] = React.useState(null);
    const [editedFieldKeyList, setEditedFieldKeyList] = useState([]);
    const [localLoading, setLocalLoading] = useState(isLoading);
    const [inited, setInited] = useState(false);

    const [disabledEditedFields, setDisabledEditedFields] = useState([]);

    let formGroupsRefs = new Map();

    const searchedData = useMemo(() => {
        const voiceKeys = [
            "a_worldzone_name", "a_destination_name", "a_subdestination_name", 
            "a_prefix", "b_worldzone_name", "b_destination_name", 
            "b_subdestination_name", "bil_acc_orig_name", "bil_acc_term_name"
        ];
        
        const smsKeys = [
            "sender_worldzone_name", "sender_destination_name", "sender_subdestination_name", 
            "senderid", "phone_worldzone_name", "phone_destination_name", 
            "phone_subdestination_name", "acc_orig_name", "acc_term_name"
        ]
        return servicePick(service, voiceKeys, smsKeys);
    }, [service]);

    const voiceValidationFields = {
        a_wz_key: NumberType(),
        a_de_key: NumberType(),
        a_sde_key: NumberType(),
        a_prefix: StringType()
            .pattern(/^[0-9][\d]*$/, 'The number must contain only digits')
            .minLength(6, "Min length is 6 characters")
            .maxLength(15, "Max length is 15 characters"),
        b_wz_key: NumberType(),
        b_de_key: NumberType(),
        b_sde_key: NumberType(),
        bil_acc_orig: NumberType(),
        bil_acc_term: NumberType(),
    };

    const smsValidationFields = {
        sender_wz_key: NumberType(),
        sender_de_key: NumberType(),
        sender_sde_key: NumberType(),
        senderid: StringType().maxLength(40, "Too long"),
        // sender_name: StringType().maxLength(40, "Too long"),
        phone_wz_key: NumberType(),
        phone_de_key: NumberType(),
        phone_sde_key: NumberType(),
        acc_supp_id: StringType(),
        acc_dial_id: StringType()
    };
    
    const formModel = useMemo(() => {
        return Schema.Model(servicePick(service, voiceValidationFields, smsValidationFields));
    }, [service]);

    useEffect( () => {

        const tableDataList = data.filter((item) => {
            // return item if filter is empty
            if (!filter || filter && !filter.str)
                return true;

            const match = filter.str.toLowerCase();

            const filterMatching = searchedData.map((keyName) => {
                if (item[keyName]) {
                    const matchedString = item[keyName].toString().toLowerCase();
                    if (matchedString.includes(match)) {
                        return true
                    }
                }
            });

            return filterMatching.some(dataMatch => !!dataMatch);
        });

        setTableData(tableDataList);
        setInited(true);
        if (currentEditedFieldKeyList) {
            let editedFilters = new Set([...editedFieldKeyList, currentEditedFieldKeyList]);
            editedFilters.delete(currentEditedFieldKeyList);
            setEditedFieldKeyList(Array.from(editedFilters));
            setCurrentEditedFieldKeyList(null);
            setDisabledEditedFields([]);
        }
    }, [data, filter]);


    useEffect( () => {
        if (!inited)
            return;
        setTableData([...tableData]);
        if (tableData.length) {
            setTimeout(() => {
                setTableData([...tableData]);
            });
        }
    }, [editedFieldKeyList, inited]);

    const createFormGroupRefs = (key, ref) => {
        if ( !formGroupsRefs.has(key) ) {
            formGroupsRefs.set(key, new Set([ref]))
        } else {
            formGroupsRefs.get(key).add(ref);
        }
    };

    const handleSubmit = (key) => {
        const formFields = formGroupsRefs.get(key);

        let data = {};
        formFields.forEach(field => {
            if (!field)
                return;

            const formData = field.getFormValue();
            data = Object.assign(data, formData);
        });

        data.a_prefix = data.a_prefix || null;

        const promise = onEditItem(service, key, data).then(() => {
            update(service)
        });

        let editedFilters = new Set([...editedFieldKeyList, key]);
        editedFilters.delete(key);
        setEditedFieldKeyList(Array.from(editedFilters));

        promise.then(() => {
            setCurrentEditedFieldKeyList(null)
        })
    };

    const setDisabledItemForm = (id) => {
        if ( id && !disabledEditedFields.includes(id)) {
            setDisabledEditedFields([...disabledEditedFields, id]);
        }
    }

    const setEnabledItemForm = (id) => {
        if ( id && disabledEditedFields.includes(id)) {
            setDisabledEditedFields(disabledEditedFields.filter(item => item !== id))
        }
    }

    const calculatedTableHeight = window.innerHeight - 320;
    const calculatedTableRows = Math.round(calculatedTableHeight / 45);
    const defaultTableRowsHeight = 8 * 45 + 47;

    const columns = useMemo(() => {
        const voiceColumns = [
            {
                id: "bil_acc_orig_name",
                label: "Originator / Origin",
                dataKey: "bil_acc_orig_name",
                value: (rowData) => {
                    const previewParts = {
                        originator: [
                            rowData["bil_acc_orig_name"]
                        ].filter(item => item),
                        origin: [
                            rowData["a_worldzone_name"],
                            rowData["a_destination_name"],
                            rowData["a_subdestination_name"],
                            rowData["a_prefix"]
                        ].filter(item => item),
                    };
                    if ( editedFieldKeyList.includes(rowData["alx_key"]) ) {
                        return (
                            <StyledForm
                                model={formModel}
                                ref={(ref) => createFormGroupRefs(rowData["alx_key"], ref)}
                                onChange={(val) => {
                                    const isEnabled = Object.keys(val).find(item => {
                                        return !!val[item];
                                    });
                                    if (!isEnabled) {
                                        setDisabledItemForm(rowData["alx_key"]);
                                    } else {
                                        setEnabledItemForm(rowData["alx_key"]);
                                    }
                                }}
                                formDefaultValue={{
                                    bil_acc_orig: rowData["bil_acc_orig"],
                                    a_prefix: rowData["a_prefix"],
                                    a_wz_key: rowData["a_wz_key"],
                                    a_de_key: rowData["a_de_key"],
                                    a_sde_key: rowData["a_sde_key"],
                                }}
                            >
                                <StyledField
                                    name="bil_acc_orig"
                                    accepter={SelectPicker}
                                    data={props.clientList}
                                    placeholder="Originator name"
                                    valueKey="acc_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                                <StyledField
                                    name="a_prefix"
                                    accepter={Input}
                                    placeholder="Origin Prefix"
                                />
                                <StyledField
                                    name="a_wz_key"
                                    accepter={SelectPicker}
                                    data={props.worldzoneList}
                                    placeholder="Origin zone"
                                    valueKey="wz_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                                <StyledField
                                    name="a_de_key"
                                    accepter={SelectPicker}
                                    data={props.destinationList}
                                    placeholder="Origin country"
                                    valueKey="de_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                                <StyledField
                                    name="a_sde_key"
                                    accepter={SelectPicker}
                                    data={props.subdestinationList}
                                    placeholder="Origin operator"
                                    valueKey="sde_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                            </StyledForm>
                        )
                    }
                    return (
                        <>
                            <span className="tableFilters__previewText">
                                <b>Originator</b>: {previewParts.originator.length ? previewParts.originator.join(' / ') : 'Any'}, &nbsp;
                                <b>Origin</b>: {previewParts.origin.length ? previewParts.origin.join(' / ') : 'Any'}
                            </span>
                        </>
                    )
                },
                flexGrow: 1, 
                minWidth: 130
            },
            {
                id: "bil_acc_term_name",
                label: "Destination / Terminator",
                dataKey: "bil_acc_term_name",
                value: (rowData) => {
                    const previewParts = {
                        destination: [
                            rowData["b_worldzone_name"],
                            rowData["b_destination_name"],
                            rowData["b_subdestination_name"]
                        ].filter(item => item),
                        terminator: [
                            rowData["bil_acc_term_name"]
                        ].filter(item => item),
                    };
                    if ( editedFieldKeyList.includes(rowData["alx_key"]) ) {
                        return (
                            <StyledForm
                                model={formModel}
                                ref={(ref) => createFormGroupRefs(rowData["alx_key"], ref)}
                                onChange={(val) => {
                                    const isEnabled = Object.keys(val).find(item => {
                                        return !!val[item];
                                    });
                                    if (!isEnabled) {
                                        setDisabledItemForm(rowData["alx_key"]);
                                    } else {
                                        setEnabledItemForm(rowData["alx_key"]);
                                    }
                                }}
                                formDefaultValue={{
                                    b_wz_key: rowData["b_wz_key"],
                                    b_de_key: rowData["b_de_key"],
                                    b_sde_key: rowData["b_sde_key"],
                                    bil_acc_term: rowData["bil_acc_term"]
                                }}
                            >
                                <StyledField
                                    name="b_wz_key"
                                    accepter={SelectPicker}
                                    data={props.worldzoneList}
                                    placeholder="Destination zone"
                                    valueKey="wz_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                                <StyledField
                                    name="b_de_key"
                                    accepter={SelectPicker}
                                    data={props.destinationList}
                                    placeholder="Destination country"
                                    valueKey="de_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                                <StyledField
                                    name="b_sde_key"
                                    accepter={SelectPicker}
                                    data={props.subdestinationList}
                                    placeholder="Destination operator"
                                    valueKey="sde_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                                <StyledField
                                    name="bil_acc_term"
                                    accepter={SelectPicker}
                                    data={props.clientList}
                                    placeholder="Terminator name"
                                    valueKey="acc_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                            </StyledForm>
                        )
                    }
                    return (
                        <>
                            <span className="tableFilters__previewText">
                                <b>Destination</b>: {previewParts.destination.length ? previewParts.destination.join(' / ') : 'Any'}, &nbsp;
                                <b>Terminator</b>: {previewParts.terminator.length ? previewParts.terminator.join(' / ') : 'Any'}
                            </span>
                        </>
                    )
                },
                flexGrow: 1, 
                minWidth: 130
            },
            {
                id: "modified_at",
                label: "Created",
                dataKey: "modified_at",
                value: (rowData) => {
                    const previewParts = {
                        modified_date: rowData["modified_at"],
                        created_date: rowData["created_at"],
                        name: rowData["account_user_name"],
                        role: rowData["account_user_role"]
                    };

                    const date = previewParts.modified_date || previewParts.created_date;
                    const userName = previewParts.name ? `${previewParts.name} (${previewParts.role})` : null;

                    const dateObject = new Date(date);
                    const options = {
                        dateStyle: "medium", timeStyle: "medium"
                    };
                    const dateString = date ? dateObject.toLocaleString("en-US", options) : "";

                    return (<>
                        <span className="tableFilters__previewText">
                            <SecondStagedCell>
                                {userName ? <div className="table-two-staged__cell-first">
                                    Created by {userName}
                                </div> : <></>}
                                <div className={`table-two-staged__cell-second ${!userName ? "single" : ""}`}>
                                    {dateString}
                                </div>
                            </SecondStagedCell>
                        </span>
                    </>)
                },
                // flexGrow: 1,
                width: 250
            },
            {
                id: "action",
                dataKey: "alx_key",
                label: "",
                value: (rowData) => {
                    return <div className="tableFilters_buttons">
                        {!editedFieldKeyList.includes(rowData["alx_key"])
                            ? <Button 
                                size="sm" 
                                color="lightblue" 
                                onClick={() => {
                                    if ([
                                        rowData["bil_acc_orig"],
                                        rowData["a_prefix"],
                                        rowData["a_wz_key"],
                                        rowData["a_de_key"],
                                        rowData["a_sde_key"],
                                        rowData["b_wz_key"],
                                        rowData["b_de_key"],
                                        rowData["b_sde_key"],
                                        rowData["bil_acc_term"]
                                    ].every(item => !item)) {
                                        setDisabledItemForm(rowData["alx_key"]);
                                    }

                                    let editedFilters = new Set([...editedFieldKeyList, rowData["alx_key"]]);
                                    setEditedFieldKeyList(Array.from(editedFilters));
                                }}
                            >
                                <Icon icon="edit2"/>
                            </Button>
                            : <>
                                <Button 
                                    size="sm" 
                                    color="green" 
                                    disabled={disabledEditedFields.includes(rowData["alx_key"])}
                                    onClick={() => {
                                        handleSubmit(rowData["alx_key"]);
                                    }}
                                >
                                    <Icon icon="check-circle"/>
                                </Button>

                                <Button 
                                    size="sm" 
                                    color="red" 
                                    onClick={() => {
                                        let editedFilters = new Set([...editedFieldKeyList, rowData["alx_key"]]);
                                        editedFilters.delete(rowData["alx_key"]);
                                        setEditedFieldKeyList(Array.from(editedFilters));
                                        setEnabledItemForm(rowData["alx_key"]);
                                    }}
                                >
                                    <Icon icon="close-circle"/>
                                </Button>
                            </>
                        }
                        <Button
                            size="sm"
                            color="red"
                            onClick={() => {
                                onRemoveItem(rowData["alx_key"]);
                                setEnabledItemForm(rowData["alx_key"]);
                            }}
                        >
                            <Icon icon="trash2"/>
                        </Button>
                    </div>
                },
                width: 174
            }
        ];

        const smsColumns = [
            {
                id: "acc_supp_name",
                label: "Supplier / Origin",
                dataKey: "acc_supp_name",
                value: (rowData) => {
                    const previewParts = {
                        supplier: [
                            rowData["acc_supp_name"]
                        ].filter(item => item),
                        origin: [
                            rowData["sender_worldzone_name"],
                            rowData["sender_destination_name"],
                            rowData["sender_subdestination_name"],
                            `${rowData["senderid"] || ''}${rowData["senderid"] ? ' ' : ''}`
                        ].filter(item => item),
                    };
                    if ( editedFieldKeyList.includes(rowData["alx_key"]) ) {
                        return (
                            <StyledForm
                                model={formModel}
                                ref={(ref) => createFormGroupRefs(rowData["alx_key"], ref)}
                                onChange={(val) => {
                                    const isEnabled = Object.keys(val).find(item => {
                                        return !!val[item];
                                    });
                                    if (!isEnabled) {
                                        setDisabledItemForm(rowData["alx_key"]);
                                    } else {
                                        setEnabledItemForm(rowData["alx_key"]);
                                    }
                                }}
                                formDefaultValue={{
                                    acc_supp_id: rowData["acc_supp_id"],
                                    senderid: rowData["senderid"],
                                    // sender_name: rowData["sender_name"],
                                    sender_wz_key: rowData["sender_wz_key"],
                                    sender_de_key: rowData["sender_de_key"],
                                    sender_sde_key: rowData["sender_sde_key"],
                                }}
                            >
                                <StyledField
                                    name="acc_supp_id"
                                    accepter={SelectPicker}
                                    data={props.supplierList}
                                    placeholder="Supplier name"
                                    valueKey="id"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                                <StyledField
                                    name="senderid"
                                    accepter={Input}
                                    placeholder="Sender ID"
                                />
                                {/* <StyledField
                                    name="sender_name"
                                    accepter={Input}
                                    placeholder="Sender Name"
                                /> */}
                                <StyledField
                                    name="sender_wz_key"
                                    accepter={SelectPicker}
                                    data={props.worldzoneList}
                                    placeholder={servicePick(service, "Origin zone", "Sender worldzone")}
                                    valueKey="wz_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                                <StyledField
                                    name="sender_de_key"
                                    accepter={SelectPicker}
                                    data={props.destinationList}
                                    placeholder={servicePick(service, "Origin country", "Sender destination")}
                                    valueKey="de_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                                <StyledField
                                    name="sender_sde_key"
                                    accepter={SelectPicker}
                                    data={props.subdestinationList}
                                    placeholder={servicePick(service, "Origin operator", "Sender subdestination")}
                                    valueKey="sde_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                            </StyledForm>
                        )
                    }
                    return (
                        <>
                            <span className="tableFilters__previewText">
                                <b>Supplier</b>: {previewParts.supplier.length ? previewParts.supplier.join(' / ') : 'Any'}, &nbsp;
                                <b>Origin</b>: {previewParts.origin.length ? previewParts.origin.join(' / ') : 'Any'}
                            </span>
                        </>
                    )
                },
                flexGrow: 1, 
                minWidth: 130
            },
            {
                id: "acc_dial_name",
                label: "Destination / Dialer",
                dataKey: "acc_dial_name",
                value: (rowData) => {
                    const previewParts = {
                        destination: [
                            rowData["phone_worldzone_name"],
                            rowData["phone_destination_name"],
                            rowData["phone_subdestination_name"]
                        ].filter(item => item),
                        dialer: [
                            rowData["acc_dial_name"]
                        ].filter(item => item),
                    };
                    if ( editedFieldKeyList.includes(rowData["alx_key"]) ) {
                        return (
                            <StyledForm
                                model={formModel}
                                ref={(ref) => createFormGroupRefs(rowData["alx_key"], ref)}
                                onChange={(val) => {
                                    const isEnabled = Object.keys(val).find(item => {
                                        return !!val[item];
                                    });
                                    if (!isEnabled) {
                                        setDisabledItemForm(rowData["alx_key"]);
                                    } else {
                                        setEnabledItemForm(rowData["alx_key"]);
                                    }
                                }}
                                formDefaultValue={{
                                    phone_wz_key: rowData["phone_wz_key"],
                                    phone_de_key: rowData["phone_de_key"],
                                    phone_sde_key: rowData["phone_sde_key"],
                                    acc_dial_id: rowData["acc_dial_id"]
                                }}
                            >
                                <StyledField
                                    name="phone_wz_key"
                                    accepter={SelectPicker}
                                    data={props.worldzoneList}
                                    placeholder={servicePick(service, "Desctination zone", "Phone worldzone")}
                                    valueKey="wz_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                                <StyledField
                                    name="phone_de_key"
                                    accepter={SelectPicker}
                                    data={props.destinationList}
                                    placeholder={servicePick(service, "Destination country", "Phone destination")}
                                    valueKey="de_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                                <StyledField
                                    name="phone_sde_key"
                                    accepter={SelectPicker}
                                    data={props.subdestinationList}
                                    placeholder={servicePick(service, "Destination operator", "Phone subdestination")}
                                    valueKey="sde_key"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                                <StyledField
                                    name="acc_dial_id"
                                    accepter={SelectPicker}
                                    data={props.dialerList}
                                    // value={rowData.acc_dial_id}
                                    placeholder="Dialer name"
                                    valueKey="id"
                                    labelKey="name"
                                    placement="autoVerticalStart"
                                />
                            </StyledForm>
                        )
                    }
                    return (
                        <>
                            <span className="tableFilters__previewText">
                                <b>Destination</b>: {previewParts.destination.length ? previewParts.destination.join(' / ') : 'Any'}, &nbsp;
                                <b>Dialer</b>: {previewParts.dialer.length ? previewParts.dialer.join(' / ') : 'Any'}
                            </span>
                        </>
                    )
                },
                flexGrow: 1, 
                minWidth: 130
            },
            {
                id: "modified_at",
                label: "Created",
                dataKey: "modified_at",
                value: (rowData) => {
                    const previewParts = {
                        modified_date: rowData["modified_at"],
                        created_date: rowData["created_at"],
                        name: rowData["account_user_name"],
                        role: rowData["account_user_role"]
                    };

                    const date = previewParts.modified_date || previewParts.created_date;
                    const userName = previewParts.name ? `${previewParts.name} (${previewParts.role})` : null;

                    const dateObject = new Date(date);
                    const options = {
                        dateStyle: "medium", timeStyle: "medium"
                    };
                    const dateString = date ? dateObject.toLocaleString("en-US", options) : "";

                    return (<>
                        <span className="tableFilters__previewText">
                            <SecondStagedCell>
                                {userName ? <div className="table-two-staged__cell-first">
                                    Created by {userName}
                                </div> : <></>}
                                <div className={`table-two-staged__cell-second ${!userName ? "single" : ""}`}>
                                    {dateString}
                                </div>
                            </SecondStagedCell>
                        </span>
                    </>)
                },
                // flexGrow: 1,
                width: 250
            },
            {
                id: "action",
                dataKey: "alx_key",
                label: "",
                value: (rowData) => {
                    return <div className="tableFilters_buttons">
                        {!editedFieldKeyList.includes(rowData["alx_key"])
                            ? <Button 
                                size="sm" 
                                color="lightblue" 
                                onClick={() => {
                                    if ([
                                        rowData["acc_supp_id"],
                                        rowData["senderid"],
                                        // rowData["sender_name"],
                                        rowData["sender_wz_key"],
                                        rowData["sender_de_key"],
                                        rowData["sender_sde_key"],
                                        rowData["phone_wz_key"],
                                        rowData["phone_de_key"],
                                        rowData["phone_sde_key"],
                                        rowData["acc_dial_id"]
                                    ].every(item => !item)) {
                                        setDisabledItemForm(rowData["alx_key"]);
                                    }

                                    let editedFilters = new Set([...editedFieldKeyList, rowData["alx_key"]]);
                                    setEditedFieldKeyList(Array.from(editedFilters));
                                }}
                            >
                                <Icon icon="edit2"/>
                            </Button>
                            : <>
                                <Button 
                                    size="sm" 
                                    color="green" 
                                    disabled={disabledEditedFields.includes(rowData["alx_key"])}
                                    onClick={() => {
                                        handleSubmit(rowData["alx_key"]);
                                    }}
                                >
                                    <Icon icon="check-circle"/>
                                </Button>

                                <Button 
                                    size="sm" 
                                    color="red" 
                                    onClick={() => {
                                        let editedFilters = new Set([...editedFieldKeyList, rowData["alx_key"]]);
                                        editedFilters.delete(rowData["alx_key"]);
                                        setEditedFieldKeyList(Array.from(editedFilters));
                                        setEnabledItemForm(rowData["alx_key"]);
                                    }}
                                >
                                    <Icon icon="close-circle"/>
                                </Button>
                            </>
                        }
                        <Button
                            size="sm"
                            color="red"
                            onClick={() => {
                                onRemoveItem(rowData["alx_key"]);
                                setEnabledItemForm(rowData["alx_key"]);
                            }}
                        >
                            <Icon icon="trash2"/>
                        </Button>
                    </div>
                },
                width: 174
            }
        ];

        return servicePick(service, voiceColumns, smsColumns);
    }, [service, editedFieldKeyList]);

    const renderedColumns = columns.map(renderColumn);

    return (
        <StyledTable
            className="tableFilters"
            data={tableData}
            loading={loading || localLoading}
            headerHeight={47}
            rowHeight={45}
            wordWrap
            height={calculatedTableHeight < defaultTableRowsHeight ? defaultTableRowsHeight : calculatedTableHeight}
            autoHeight={data.length <= calculatedTableRows}
        >
            {renderedColumns}
        </StyledTable>
    )
}

const StyledForm = styled(Form)`
    display: flex;
    flex-wrap: wrap;
    
    .rs-form-control-wrapper {
        flex-shrink: 0;
        min-width: 175px;
        padding-bottom: 5px;
        padding-right: 5px;
        width: 33%;
    }
`;

const StyledField = styled(FormControl).attrs(props =>({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    type: "text",
}))`
`;

const StyledTable = styled(BaseTable)`
    && {
    
        .tableFilters__previewText {
            display: block;
            line-height: 20px;
            margin-top: 7px;
            word-break: normal;
        }
        
        .tableFilters_buttons {
            height: 20px;
            margin-top: 7px;
        }
    }
`;

const SecondStagedCell = styled.div`
    margin-top: -7px;
    
    .table-two-staged__cell-first {
        ${props => props.same && css`
            font-size: 13px;
        `}
    }
    .table-two-staged__cell-second:not(.single) {
        margin-top: -2px;
        font-size: 13px;
        ${props => !props.same ? css`
            color: gray;
        ` : css`
            font-size: 13px;
        `}
        opacity: 1;
    }
`;