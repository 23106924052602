import React from "react";
import {List, Panel} from "rsuite";
import styled from 'styled-components';

const statusLabels = {
    1: 'number is unavailable',
    3: 'destination does not exist',
    4: 'no rate for this destination',
    7: 'number is quarantined',
    10: 'numbers from this range cannot be allocated to this trunk'
};

export default ({count, numbers = []}) => {
    return (
        <div style={{paddingBottom: '20px'}}>
            <StyledPanel
                header={`${count} numbers were not allocated`}
                collapsible
                bordered
            >
                <StyledList size='sm' hover>
                    {numbers.map( (item, index) => (
                        <List.Item key={index} index={index}>
                            {item.number} {statusLabels[item.status] || 'cannot be allocated'}
                        </List.Item>
                    ))}
                </StyledList>
            </StyledPanel>
        </div>
    )
};


const StyledList = styled(List)`
    width: 100%;
`;

const StyledPanel = styled(Panel)`
    .rs-panel-body {
        box-shadow: none;
    }
`;