import {DIALER_GROUPS as PREFIX} from '../const/';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';
import {
    DIALER_GROUPS_LIST_API,
    DIALER_GROUPS_CREATE_API,
    DIALER_GROUPS_MODIFY_API,
    DIALER_GROUPS_ACCOUNTS_ADD_API,
    DIALER_GROUPS_ACCOUNTS_LIST_API,
    DIALER_GROUPS_ACCOUNTS_REMOVE_API
} from "../const/apiMethods";


const setDialerGroupsList = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);
const setDialerGroupsAccountsList = createAction('SET_ITEMS_' + PREFIX + '_ACCOUNTS');
const setAccountsLoading = createAction('SET_LOADING_' + PREFIX + '_ACCOUNTS');

export const getDialerGroupsList = () => (dispatch) => {
    dispatch(setLoading(true));
    api(DIALER_GROUPS_LIST_API, {})
        .then(({news_agroup_list}) => {
            if (news_agroup_list === undefined) {
                return;
            }
            dispatch(setDialerGroupsList({
                list: news_agroup_list
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};

export const createDialerGroup = (value) => async (dispatch) => {
    return await api(DIALER_GROUPS_CREATE_API, {name: value?.name});
};

export const modifyDialerGroup = (nwg_key, params={}) => async (dispatch) => {
    return await api(DIALER_GROUPS_MODIFY_API, {target: {nwg_key}, ...params});
};

export const removeDialerGroup = (nwg_key) => async (dispatch) => {
    return await api(DIALER_GROUPS_MODIFY_API, {target: {nwg_key}, deleted: true});
};

export const getDialerGroupsAccountsList = (key, filter = {}) => (dispatch) => {
    dispatch(setAccountsLoading(true));
    api(DIALER_GROUPS_ACCOUNTS_LIST_API, {target: {nwg_key: key}, filter})
        .then(({account_list, other_account_list}) => {
            dispatch(setDialerGroupsAccountsList({
                account_list,
                other_account_list
            }));
        })
        .finally(() => dispatch(setAccountsLoading(false)));
};

export const addAccountToGroup = (nwg_key, idList=[]) => async (dispatch) => {
    return await api(DIALER_GROUPS_ACCOUNTS_ADD_API, {
        target: {
            nwg_key,
            account_id_list: idList
        }
    });
};

export const removeAccountFromGroup = (nwg_key, idList=[]) => async (dispatch) => {
    return await api(DIALER_GROUPS_ACCOUNTS_REMOVE_API, {
        target: {
            nwg_key,
            account_id_list: idList
        }
    });
};
