import React, {useMemo} from 'react';
import Modal from '../../components/Modal';

import {Form, Schema, SelectPicker, Whisper, Tooltip, Icon} from 'rsuite';
import CustomField from '../../components/Form/CustomField';
import {api} from '../../api/loginRoutes';
import FormHoc from '../../hoc/FilltersForm';
import InputSearch from '../../components/Form/InputSearch';
import ExportPrice from './ExportPrice';
import styled from 'styled-components';
import {servicePick} from "../../utils";
import {DEFAULT_PER_PAGE_MEDIUM, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY} from '../../const';
import {RATECARD_LIST_DEFAULT_DIALER, SMS_RATECARD_LIST_DEFAULT_DIALER} from "../../const/apiMethods";
import {ASC_SORT, DESC_SORT} from "const";
import TableServerSort from '../../components/Table/TableServerSort';
import {ButtonSideIcon} from "../../components/base/BaseButton";

const {StringType} = Schema.Types;
const per_page = DEFAULT_PER_PAGE_MEDIUM;


const ShowPrice = ({
    currency_list,
    sp_key,
    paymentTermsList,
    account,
    template,
    getTemplate,
    service,
    otpType
}) => {
    const defaultCurKey = servicePick(service, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY);

    const defaultSortColumn = 'subdestination_name';

    const [show, setShow] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [filter, setFilter] = React.useState({
        cur_key: defaultCurKey, str: ''
    });
    const [sort, setSort] = React.useState({column: defaultSortColumn, type: ASC_SORT})

    React.useEffect(() => {
        if (!show)
            return;
        setFilter({cur_key: defaultCurKey, str: ''});
        getData({cur_key: defaultCurKey, str: ''}, 1, sort);
    }, [show]);


    const onChangeFilters = (_filters) => {
        setFilter({str: _filters._str || '', cur_key: _filters.cur_key});
        getData({str: _filters._str || '', cur_key: _filters.cur_key}, 1, sort);
    };


    const getData = (filter, page, _sort) => {
        setLoading(true);
        let sort1, sort1_desc;

        if (_sort.column) {
            sort1 = _sort.column;
            sort1_desc = _sort.type && _sort.type === DESC_SORT;
        }
        const method = otpType === null
            ? servicePick(service, RATECARD_LIST_DEFAULT_DIALER, SMS_RATECARD_LIST_DEFAULT_DIALER)
            : 'ratecard_otp:get_default_price';
        api(method, {
            filter: {cur_key: defaultCurKey, str: '', ...filter, sp_key}, page, per_page, sort1, sort1_desc
            // add: {test_trunk_number_list:{}, service_plan_price:{} }
        })
            .then(({ratecard_standart_list, ratecard_otp_list, ratecard_standart_count, ratecard_otp_count}) => {
                setCount(ratecard_standart_count || ratecard_otp_count || 0);
                setPage(page);
                setItems(
                    (ratecard_standart_list || ratecard_otp_list || []).map(
                        item => {
                            // set payment terms
                            for (const term of paymentTermsList) {
                                item[`payments_${term.name}`] = '';
                                if (item.payment_terms_rate_list && item.payment_terms_rate_list.length) {
                                    item[`payments_${term.name}`] = (item.payment_terms_rate_list
                                        .find(el => el.pt_key === term.pt_key) || {})
                                        .rate || '';
                                }
                            }

                            // set test number
                            item.test_number = '';
                            if (item.test_trunk_number_list && item.test_trunk_number_list.length) {
                                item.test_number = item.test_trunk_number_list[0];
                                item.test_trunk_number_list[1] && (item.test_number += ' ...');
                            }
                            return item;
                        }
                    )
                );
                setLoading(false);
            })
            .catch(error => setLoading(false));
    };


    const columns = useMemo(() => ( 
        [
            {
                label: 'Template', 
                dataKey: 'template', 
                sortable: false,
                flexGrow: 1,
                hideOnOtp: false,
            },
            {
                label: 'Prefix', 
                dataKey: 'prefix', 
                sortable: true,
                flexGrow: 1,
                hideOnOtp: true
            },
            {
                label: 'Subdestination',
                dataKey: 'subdestination_name',
                value: ({subdestination_name, template}) => (
                    subdestination_name && template
                        ? `${subdestination_name} (${template})`
                        : ""
                ),
                sortable: true,
                flexGrow: 2,
            },
            {
                id: 'test_trunk_number_list',
                label: 'Test numbers',
                dataKey: 'test_trunk_number_list',
                value: (({test_number, test_trunk_number_list}) => {
                    if (!test_trunk_number_list)
                        return;
    
                    const firstTwoElements = test_trunk_number_list.slice(0, 2).join(",");
    
                    return (
                        firstTwoElements
                            ? <>
                                <Whisper
                                    placement="auto"
                                    speaker={
                                        <Tooltip>{test_trunk_number_list.join("\n")}</Tooltip>
                                    }
                                >
                                     <span>{firstTwoElements}...</span>
                                </Whisper>
                            </>
                            :
                            <span>—</span>
                    );
                }),
                flexGrow: 2,
                minWidth: 130,
                align: 'center',
                hideOnOtp: true
            },
            {
                id: 'available_numbers',
                label: 'Available numbers',
                dataKey: 'available_numbers',
                flexGrow: 1,
                minWidth: 130,
                sortable: true,
                align: 'right',
                hideOnOtp: true
            },
            {
                id: 'min_dur',
                label: 'Min. duration',
                value: (() => <>
                    <span>25 sec</span>
                    <Whisper
                        placement="auto"
                        speaker={
                            <Tooltip>Premiumy doesn’t to pay for the calls below 25 seconds duration.</Tooltip>
                        }
                    >
                        <StyledTooltipIcon icon='info'/>
                    </Whisper>
                </>),
                flexGrow: 1,
                minWidth: 120,
                align: 'center',
            },
        ]
    ));

    
    if (paymentTermsList) {
        for (const term of paymentTermsList) {
            columns.push({label: `Payout ${term.name.replace("_", "-")}`, dataKey: `payments_${term.name}`, width: 95, align: 'right'});
        }
    }

    const filteredColumns = useMemo(() => {
        return columns.filter(value => value.hideOnOtp === undefined || value.hideOnOtp && otpType === null || !value.hideOnOtp && otpType !== null);
    });


    return (
        <>
            <ButtonSideIcon onClick={() => setShow(true)} icon="eye">Show price</ButtonSideIcon>
            <Modal
                show={show}
                title="Price"
                onClose={() => setShow(false)}
                width="90%"
            >
                <Header>
                    <div>
                        <Filters
                            {...{
                                currency_list,
                                defaultCurKey
                            }}
                            onChange={onChangeFilters}
                        />
                    </div>
                    <div>
                        <ExportPrice
                            modal={true}
                            str={filter.str}
                            service={service}
                            cur_key={filter.cur_key}
                            sp_key={sp_key}
                            account={account}
                            template={template}
                            getTemplate={getTemplate}
                            otpType={otpType}
                        />
                    </div>
                </Header>

                <TableServerSort
                    data={items}
                    height="50%"
                    extraHeight={490}
                    loading={loading}
                    columns={filteredColumns}
                    originalColumns={columns}
                    count={count}
                    page={page}
                    per_page={per_page}
                    ispagination
                    hidePerPage
                    getItems={(page, _, _sort) => getData(filter, page, _sort)}
                    onSort={(column, type) => setSort({column, type})}
                    defaultSortColumn={defaultSortColumn}
                />
            </Modal>
        </>
    );
};

export default ShowPrice;


const filtersModel = Schema.Model({
    _str: StringType().maxLength(40, 'The maximum is only 40 characters.'),
});

const Filters = FormHoc(({onChange, currency_list, defaultCurKey}) => {

    return (
        <Form layout="inline"
              onChange={onChange}
              formDefaultValue={{cur_key: defaultCurKey}}
              model={filtersModel}
        >
            <div style={{display: 'inline-block', width: 200}}>
                <CustomField
                    name="_str"
                    accepter={InputSearch}
                    errorPlacement="topRight"
                    placeholder="Prefix/Range name"
                    // style={{width:220}}
                />
            </div>
            <CustomField
                accepter={SelectPicker}
                data={currency_list}
                cleanable={false}
                searchable={false}
                labelKey="name"
                valueKey="cur_key"
                name="cur_key"
                classPrefix="allocated"
            />
        </Form>
    );
}, filtersModel, 300);

const Header = styled.div`
    margin-bottom: -20px;
    > div {
        display: inline-block;
        width: 50%
    }
    >div:last-child{
        text-align: right
    }
`;


const StyledTooltipIcon = styled(Icon)`
    margin-left: 5px;

    .rtl & {
        margin-left: 0;
        margin-right: 5px;
    }
`;