import React, {useEffect, useCallback, useState} from 'react';
import {SelectPicker, ControlLabel, Form} from "rsuite";
import CustomField from "../../components/Form/CustomField";
import {connect} from "react-redux";
import {getReferencesForReports} from "actions/references";
import FormHOC from "hoc/FilltersForm";
import {debounce, removeNilValues} from "utils";
import styled from 'styled-components';

const BlockedDestinationsFilters = ({onSubmit, defaultFilters, loading, servicePlanList, ...props}) => {
    const [filters, setFilters] = useState(defaultFilters);

    const handleChange = (formValues) => {
        onSubmit(removeNilValues(formValues))
    };

    const debounceOnChange = useCallback(debounce(handleChange, 400), []);

    useEffect(() => {
        props.getReferencesForReports();
    }, []);

    const filteredServicePlanList = servicePlanList ? servicePlanList.filter((item) => {
        return !item.gan;
    }) : [];

    return (
        <>
            <StyledForm
                layout="inline"
                onChange={(value) => {
                    setFilters({...value});
                    debounceOnChange(value);
                }}
                formDefaultValue={defaultFilters}
                formValue={filters}
            >
                <ControlLabel className={'d-block mb-2'}>Filters</ControlLabel>
                <CustomField
                    accepter={SelectPicker}
                    width={200}
                    data={props.worldzoneList}
                    labelKey="name"
                    valueKey="wz_key"
                    placeholder="Zone"
                    name="wz_key"
                />
                <CustomField
                    accepter={SelectPicker}
                    width={200}
                    data={props.destinationList}
                    labelKey="name"
                    valueKey="de_key"
                    placeholder="Destination"
                    name="de_key"
                />
                <CustomField
                    accepter={SelectPicker}
                    width={200}
                    data={props.subdestinationList}
                    labelKey="name"
                    valueKey="sde_key"
                    placeholder="Subdestination"
                    name="sde_key"
                />
                <CustomField
                    width={200}
                    name="prefix"
                    className="tableFilters_field"
                    errorPlacement="topEnd"
                    placeholder="Prefix"
                />
                <CustomField
                    accepter={SelectPicker}
                    width={200}
                    data={filteredServicePlanList}
                    labelKey="name"
                    valueKey="sp_key"
                    placeholder="Price"
                    name="sp_key"
                />
                <CustomField
                    accepter={SelectPicker}
                    width={200}
                    data={props.accountList}
                    labelKey="name"
                    valueKey="id"
                    placeholder="Account"
                    name="account_id"
                />
            </StyledForm>
        </>
    );
};

const mapStateToProps = ({auth, references, blocked_destinations}) => ({
    loading: blocked_destinations.loading,
    subdestinationList: references.subdestination_list,
    destinationList: references.destination_list,
    worldzoneList: references.worldzone_list,
    servicePlanList: references.service_plan_list,
    accountList: auth.service ? references.dialer_list : references['sms.dialer_list']
});

const mapDispatchToProps = {
    getReferencesForReports
};

export default connect(mapStateToProps, mapDispatchToProps)(FormHOC(BlockedDestinationsFilters));


const StyledForm = styled(Form)`
  & .rs-form-control-wrapper {
    margin-left: 0 !important;
  }
  & .rs-form-group {
    margin-bottom: 10px;
  }
`;