import React, {useContext} from "react";
import {Dropdown, Icon, Badge} from "rsuite";
import {Link, useLocation} from "react-router-dom";
import styled from "styled-components";
import * as S from "../styled.js"
import {AppTypeContext} from "../../../App";
import {APP_TYPE_ADMIN} from "../../../const";
import {useIntl} from "react-intl";
import m from "../../../definedMessages";
import {REGISTRATION_GET_COUNT_API} from '../../../const/apiMethods'
import {checkPermissionsFor} from '../../../store/storeHelpers'

export default ({
    rtl,
    data = [],
    logoutUser,
    registrationCount,
    ...props
}) => {

    const {formatMessage} = useIntl();
    const location = useLocation();
    const appType = useContext(AppTypeContext);

    return (
        <StyledDropdown
            activeKey={location.pathname}
            icon={<Icon icon="bars"/>}
            title={formatMessage(m.menu)}
            {...props}
        >
            {data.map(item => {
                if (item.children && !item.children.length) {
                    return null;
                }

                if (item.children && item.children.length) {
                    return (
                        <Dropdown.Menu 
                            title={item.title}
                            pullLeft={!rtl}
                            pullRight={rtl}
                        >
                            {item.children.map(child => (
                                <Dropdown.Item
                                    key={child.key} 
                                    componentClass={Link} 
                                    to={child.key} 
                                    eventKey={child.key}
                                    onClick={child.onClick || null}
                                >
                                    {child.title}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    )
                }

                return item.onClick
                    ? <Dropdown.Item componentClass={Link} key={item.key} to={item.key} onClick={item.onClick} eventKey={item.key}>
                        {item.title}
                    </Dropdown.Item>
                    : <Dropdown.Item componentClass={Link} key={item.key} to={item.key} eventKey={item.key}>
                        {item.title}
                    </Dropdown.Item>
            })}

            <Dropdown.Item divider/>

            {checkPermissionsFor(REGISTRATION_GET_COUNT_API) && appType === APP_TYPE_ADMIN &&
                <Dropdown.Item componentClass={Link} to="/registration-requests" eventKey="/registration-requests">
                    <Badge content={registrationCount}><Icon icon="group"/></Badge>
                </Dropdown.Item>
            }

            <Dropdown.Item componentClass={Link} onClick={logoutUser} to="/login" eventKey="/login">
                <Icon icon="sign-out"/> {formatMessage(m.unlogin)}
            </Dropdown.Item>
        </StyledDropdown>
    )
};


const StyledDropdown = styled(S.StyledDropdown).attrs(() => ({
    noCaret: true,
    className: 'custom-dropdown-menu appMenu',
    menuStyle: {borderTopLeftRadius: 0, borderTopRightRadius: 0},
    toggleClassName: 'custom-dropdown-menu-toggle',
    placement: 'bottomEnd'
}))`
    &&&&&& .rs-dropdown-toggle {
        .app-client & {
            background-color:  !important;
        }
    }
`;