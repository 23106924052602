import React from 'react';
import { connect } from 'react-redux';
import { SelectPicker } from 'rsuite';
import CustomField from '../Form/CustomField';

const Protocols = ({protocols, ...props}) => (
    <CustomField
        block
        data={protocols}
        accepter={SelectPicker}
        labelKey="name"
        valueKey="prt_key"
        placeholder="Protocols"
        name="prt_key"
        searchable={false}
        errorPlacement="topRight"
        {...props}
        // classPrefix=""
    />
)

const mapState = ( {references} )=> ({
    protocols: references.protocol_list
});

export default connect( mapState, {})(Protocols);