import React, {useMemo, useState, useEffect} from "react";
import {List, Loader, Placeholder} from "rsuite";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import NewsPost from "./NewsPost";

const {Paragraph} = Placeholder;


export default (
    {
        data,
        count,
        inited,
        lang = "en",
        getNews,
        onClickEdit,
        onClickRemove,
        onClickDeactivate,
    }
) => {

    const [perPage, setPerPage]  = useState(10);

    const dataUpdated = useMemo(() => {

        if (!data) {
            return [];
        }

        return data.map(item => {
            return {
                id: item.id,
                active: item.active,
                text: item.text,
                text_arabic: item.text_arabic,
                date: item.created_at,
                time: item.created_at,
                author: item.creator_user_name,
                authorRole: item.creator_user_role,
                conditions: {
                    accountManagers: item.account_user_manager_list && item.account_user_manager_list
                        .map(item => item.account_manager_name)
                        .join(", "),
                    dialers: item.account_list && item.account_list
                        .map(item => item.account_name)
                        .join(", "),
                    servicePlans: item.sp_key_list && item.sp_key_list
                        .map(item => item.name)
                        .join(", "),
                    paymentTerms: item.pt_key_list && item.pt_key_list
                        .map(item => item.name)
                        .join(", "),
                    currencies: item.cur_key_list && item.cur_key_list
                        .map(item => item.name)
                        .join(", "),
                    roleList: item.role_list && item.role_list
                        .join(", "),
                }
            }
        });
    }, [data]);

    useEffect(() => {
        getNews(1, perPage);
    }, [perPage]);


    if (!inited && data.length === 0) {
        return <div style={{position: "relative"}}>
            <Paragraph rows={16}/>
            <Loader center content="loading" />
        </div>
    }


    const handleFetchNextData = () => {
        setPerPage(perPage + 10);
    };


    return (
        <InfiniteScroll
            dataLength={dataUpdated.length}
            next={handleFetchNextData}
            hasMore={dataUpdated.length < count}
            loader={
                <StyledLoader>
                    <Loader size={'md'} content="Loading data..." />
                </StyledLoader>
            }
            endMessage={<StyledLoader>All data is completely fetched.</StyledLoader>}
        >
            <StyledList>
                {dataUpdated.map((item, index) => {
                    return (
                        <List.Item key={index}>
                            <NewsPost
                                lang={lang}
                                {...{
                                    item,
                                    onClickEdit,
                                    onClickRemove,
                                    onClickDeactivate,
                                }}
                            />
                        </List.Item>
                    )
                })}
            </StyledList>
        </InfiniteScroll>
    )
};


const StyledList = styled(List)`
    display: flex;
    flex-wrap: wrap;

    .rs-list-item {
        padding: 30px 20px;
        width: 100%;
    }
`;


const StyledLoader = styled.div`
    padding: 30px;
    text-align: center;
`;