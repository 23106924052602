import React, {useState, useEffect} from "react";
import {FlexboxGrid} from "rsuite"
import PageHeader from "../../components/base/PageHeader"
import ManualAccessListFilters from "./ManualAccessListFilters"
import ManualAccessListTable from "./ManualAccessListTable"
import PanelLayout from "../../components/base/PanelLayout";
import ExportCsv from "../../components/ExportCsv";
import {connect} from "react-redux";
import {
    getManualAccessList,
    onCreateManualAccessItem,
    onCancelManualAccessItem,
    onRemoveManualAccessItem
} from "../../actions/manual_access_list";
import {
    getAuthNumberType
} from "../../actions/auth_numbers";
import {createGlobalStyle} from "styled-components"
import {getGanSuppliersCommonList} from "../../actions/gan_suppliers";
import { pipe } from "utils";


const ManualAccessList = ({
    page,
    count,
    per_page,
    service,
    manualAccessList,
    manualAccessListLoading,
    supplierList,
    currency_list,
    paymentTermsList,
    service_plan_list,
    subdestinationList,
    numberTypeFamilyList,

    getAuthNumberType,
    getManualAccessList,
    getGanSuppliersCommonList,
    onCreateManualAccessItem,
    onCancelManualAccessItem,
    onRemoveManualAccessItem
}) => {;

    let defaultSort = service ? {column: "access_origin", type: "asc"} : {column: "origin", type: "asc"};

    const defaultFilterValue = {
        active: true
    };

    const filtersModel = {
        active: (value, filterValue) => value.active === filterValue || !filterValue
    };

    const [sortState, setSortState] = useState(defaultSort);
    const [filterState, setFilterState] = useState(defaultFilterValue);
    const [filteredData, setFilteredData] = useState(manualAccessList);

    useEffect(() => {
        getManualAccessList(service)

        if (!service) {
            getGanSuppliersCommonList();
        }

        if (service) {
            getAuthNumberType();
        }
    }, [service]);

    useEffect(() => {
        setFilteredData((data) => {
            if (manualAccessList && manualAccessList.length) {
                return pipe(manualAccessList, filterState, filtersModel);
            }
            return data;
        });
    }, [filterState, manualAccessList]);

    const updateManualAccessList = () => {
        getManualAccessList(service);
    };
    
    const getItems = () => {
        getManualAccessList(service);
    };

    return (
        <>
            <PanelLayout>
                <GlobalStyle/>
                <PageHeader title="Manual Access List"/>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <ManualAccessListFilters
                            filter={filterState}
                            defaultFilter={defaultFilterValue}

                            onChange={setFilterState}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                

                <ManualAccessListTable
                    {...{
                        data: filteredData,
                        // service_plan_list,
                        manualAccessListLoading,
                        updateManualAccessList,

                        onCreateManualAccessItem,
                        onCancelManualAccessItem,
                        onRemoveManualAccessItem,

                        numberTypeFamilyList,
                        subdestinationList,
                        supplierList,
                        page,
                        count,
                        per_page,
                        service,
                    }}
                    // paymentTermsList={paymentTermsList}
                    getItems = {getItems}
                    onSort={(column, type) => {
                        console.log()
                        setSortState({column, type})}
                    }
                    sortType={sortState.type}
                    sortColumn={sortState.column}
                />

            </PanelLayout>
        </>
    )
};

const mapState = ( {auth, auth_numbers, manual_access_list,gan_suppliers,  references} )=> ({
    manualAccessList: manual_access_list.items,
    count: manual_access_list.count,
    service: auth.service,

    manualAccessListLoading: manual_access_list.loading,
    page: manual_access_list.page,
    per_page: manual_access_list.per_page,
    defaultSPKey: references.defaultSPKey,
    currency_list: references.currency_list,

    supplierList: gan_suppliers.commonList,
    paymentTermsList: references.payment_terms_list,
    service_plan_list: references.service_plan_list,
    subdestinationList: references.subdestination_list,
    numberTypeFamilyList: auth_numbers.auth_number_type_family_list,
});

export default connect(mapState, {
    getAuthNumberType,
    getManualAccessList,
    getGanSuppliersCommonList,

    onCreateManualAccessItem,
    onCancelManualAccessItem,
    onRemoveManualAccessItem
})(ManualAccessList);

const GlobalStyle = createGlobalStyle`
    .rs-picker-check-menu-items {
        overflow-y: hidden !important;
        max-height: none !important;
    }
`;