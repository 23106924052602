import React, {useMemo, useState, useEffect} from "react";
import Page from "components/base/Page";
import PageHeader from "components/base/PageHeader";

import AccountsTable from "./AccountsTable";
import AccountsFilter from "./AccountsFilter";
import { pipe } from "utils";
import { useIntl } from "react-intl";
import { FilterSectionFlexboxGrid, StyledItemFitWidth, StyledItemAutoMargin } from "components/base/FitContainers";

// import { Link } from "react-router-dom";
// import { newSubAccount } from "routes/routes";
import CreateNewAccountModal from "./CreateNewAccountModal";

import { ButtonPrimary } from "components/base/BaseButton";

import {useWindowWidth} from "hooks";

import m from "definedMessages";


const Accounts = ({
    accountId,
    accountJoinList,
    paymentTermsList,

    accountList,
    accountListLoading,
    
    getSubAccountList,
    createSubAccount,
    ...props
}) => {

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 767;

    const {formatMessage} = useIntl();

    const accountData = useMemo(() => {
        return accountJoinList.find((account) => {
            return account.service === "voice";
        })
    }, [accountJoinList]);

    const defaultFilterValue = {
        name: "",
        show_closed: false
    };

    const accountDefaultData = {
        name: "",
        invoice_period: null,
        user_name: "",
        user_email: "",
        password: null
    };

    const [filterState, setFilterState] = useState(defaultFilterValue);
    const [filteredData, setFilteredData] = useState(accountList);

    const [showCreateNewAccountModal, setShowCreateNewAccountModal] = useState(false);

    const filtersModel = {
        name: (value, filterValue) => value.name.startsWith(filterValue) 
        || value.account_user_list.filter((account_user) => {
            return account_user.name.startsWith(filterValue) 
            || account_user.email.startsWith(filterValue) 
        }).length
        || !filterValue,
        show_closed: (value, filterValue) => !!value.active && !filterValue || filterValue
    };

    useEffect(() => {
        if (accountData?.id) {
            getSubAccountList(accountData.id);
        }
    }, [accountData]);

    useEffect(() => {
        setFilteredData((data) => {
            if (accountList && accountList.length) {
                return pipe(accountList, filterState, filtersModel);
            }
            return data;
        });
    }, [filterState, accountList]);



    return (<Page>
        <PageHeader title={formatMessage(m.subaccounts)} showService={false}/>
        <FilterSectionFlexboxGrid>
            <StyledItemFitWidth isMobile={isMobile}>
                <AccountsFilter
                    value={filterState}
                    setValue={setFilterState}

                    formatMessage={formatMessage}
                />
            </StyledItemFitWidth>
            <StyledItemAutoMargin isMobile={isMobile}>
                <ButtonPrimary
                    // componentClass={Link}
                    // to={newSubAccount}
                    className="pull-right-button"
                    disabled={accountListLoading}
                    onClick={() => {
                        setShowCreateNewAccountModal(true);
                    }}
                >
                    {formatMessage(m.createNewSubaccount)}
                </ButtonPrimary>
            </StyledItemAutoMargin>
        </FilterSectionFlexboxGrid>
       
        <AccountsTable
            data={filteredData}
            loading={accountListLoading}
            
            isMobile={isMobile}

            formatMessage={formatMessage}
        />

        <CreateNewAccountModal
            show={showCreateNewAccountModal}
            accountId={accountId}
            accountDefaultData={accountDefaultData}
            paymentTermsList={paymentTermsList}
            createSubAccount={createSubAccount}

            onCloseModal={() => {
                setShowCreateNewAccountModal(false);
            }}

            formatMessage={formatMessage}
        />
    </Page>)
};


export default Accounts;