import React from "react";
import {connect} from "react-redux";
import AccountForm from "./AccountForm";
import Page from "components/base/Page";
import {withRouter} from "react-router";
import PageHeader from "components/base/PageHeader";

import {createSubAccount} from "actions/client/subaccounts";
import { useIntl } from "react-intl";

import m from "definedMessages";


const NewAccount = ({
    history,
    location,
    accountId,
    paymentTermsList,

    createSubAccount
}) => {
    const {formatMessage} = useIntl();
    
    const accountDefaultData = {
        name: "",
        invoice_period: null,
        user_name: "",
        user_email: "",
        password: null
    };
    
    return <Page>
        <PageHeader title={formatMessage(m.createSubaccount)} showService={false}/>
        <AccountForm
            newAccount
            disabled={false}
            update={(subAccId) => {
                if (subAccId) {
                    history.push({
                        ...location,
                        pathname: `/sub-accounts/view/${subAccId}`
                    });
                }
            }}
            accountId={accountId}
            formDefaultValue={accountDefaultData}
            paymentTermsList={paymentTermsList}

            cancel={() => {
                history.goBack();
            }}

            createSubAccount={createSubAccount}
        />
    </Page>
};

const mapState = ( {auth, references} )=> ({
    accountId: auth.dialerInfo ? auth.dialerInfo.account_list.find(account => account.service === "voice")?.id : null,
    paymentTermsList: references.payment_terms_list
});

const mapMethods = {
    createSubAccount
};

export default connect( mapState, mapMethods)(withRouter(NewAccount));
  