import React, {useRef, useState} from "react";
import {Form, Schema, InputNumber} from "rsuite";
import CustomField from "pages/NewAccount/ContactCustomField";
import {BaseButton} from "components/base/BaseButton";
import Modal from "components/Modal";
import styled from "styled-components";

import m from "definedMessages";

const {NumberType} = Schema.Types;

const ChangeRateModal = ({
    show,
    disabled,

    update,
    changeRateFunc,
    onCloseModal,
    formatMessage
}) => {
    const rateRef = useRef(null);
    const rateModel = Schema.Model({
        rate: NumberType()
            .isRequired()
            .max(10, "Maximum 10")
            .min(0.0001, "Minimum 0.0001")
    });

    const [formState, setFormState] = useState(null);

    const onChangeRate = () => {
        const {rate, ..._} = formState;
        const parsedRate = parseFloat(rate);

        if (rateRef.current.check()) {
            changeRateFunc(parsedRate);
            setFormState(null);
        }
    };

    return <Modal 
        width={440}
        show={show}
        title={formatMessage(m.changeRate)}
        onClose={() => {
            onCloseModal();
            setFormState(null);
        }}
        successText={formatMessage(m.ok)}
        cancelText={formatMessage(m.cancel)}
        footer={false}
    >
        <StyledForm
            layout="inline"
            ref={rateRef}
            model={rateModel}
            formValue={formState}
            onChange={(formValue) => setFormState(formValue)}
        >
            <CustomField
                disabled={disabled}
                name="rate"
                placeholder={`${formatMessage(m.rate)}...`}
            />

            <FormButton disabled={!formState?.rate} onClick={onChangeRate}>{formatMessage(m.change)}</FormButton>
        </StyledForm>
    </Modal>
};

export default ChangeRateModal;



const StyledForm = styled(Form)`
    && > .rs-form-group {
        margin-bottom: 0px;
    }
`;


const FormButton = styled(BaseButton).attrs(() => ({
    buttonStyle: "primary",
    size: "sm"
}))`
    &&& {
        height: 36px;
        margin-right: 5px;
        margin-bottom: 0px;
    }
`;