import {
    INCORPORATED_NUMBERS as PREFIX,
} from '../const';

const initialState = {
    groupList: [],
    groupListLoading: false,
    supplierList: [],
    numberList: [],
    numberCount : 0,
    numberListLoading: false,
};

const handlers = {
    ["SET_GROUPS_" + PREFIX]: (state, {payload: {groupList}}) => ({...state, groupList, groupListLoading: false}),
    ["SET_GROUPS_LOADING_" + PREFIX]: (state, {payload}) => ({...state, groupListLoading: payload}),
    ["SET_SUPPLIERS_" + PREFIX]: (state, {payload: {supplierList}}) => ({...state, supplierList}),
    ["SET_NUMBER_LIST_" + PREFIX]: (state, {payload: {numberList, numberCount}}) => ({...state, numberList, numberCount}),
    ["SET_NUMBER_LIST_LOADING_" + PREFIX]: (state, {payload}) => ({...state, numberListLoading: payload}),
    ["CLEAR_NUMBER_LIST_" + PREFIX]: (state, {payload}) => ({...state, numberList: [], numberCount: 0}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}