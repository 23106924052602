import {Nav} from "rsuite";
import styled from "styled-components";


export default styled(Nav)`
    &&&&&& {
        .rs-nav-waterline {
            border-bottom: 1.5px solid var(--color-main);
            border-top: unset;
        }
        
        .rs-nav-item-active {
            .rs-nav-item-content {
                border: 1.5px solid var(--color-main);
                border-bottom-color: #fff;
                color: var(--color-main);
                padding: 7px 11px;
            }
        }
    }
`;