import React, { useState, useEffect } from "react";
import {injectIntl, defineMessages} from "react-intl";
import PaymentsSectionHeader from "../PaymentsSectionHeader";
import PaymentsTrafficReportsTable from "./PaymentsTrafficReportsTable";
import { Spacer } from "../../../components/base/Spacer";
import { getISOTime } from "../../../utils/helpers";
import { Collapse } from "react-collapse";
import CommonDateFilter from "../CommonDateFilter";
import { checkPermissionsFor } from "store/storeHelpers";
import { INVOICE_LIST_DIALER_API } from "const/apiMethods";

const m = defineMessages({
    trafficReports: {id: "payments.trafficReports", defaultMessage: "Traffic reports", description: 'Payments: Traffic reports'}
});

export default injectIntl( ({
    paymentTrafficReportsItems, 
    paymentTrafficReportsLoading,
    openModalRequestPayment,
    currencyName,
    getTrafficReportsList,
    globalSettings,
    accountJoinList,
    permissions,
    
    intl
}) => {

    const {formatMessage} = intl;

    const dateStart = new Date();
    dateStart.setDate(1);
    dateStart.setHours(0, 0, 0, 0);
    dateStart.setMonth(dateStart.getMonth() - 1);
    const dateEnd = new Date(new Date().setHours(23, 59, 59, 999));

    const [showTable, setShowTable] = useState(true);

    useEffect( () => {
        if ( !checkPermissionsFor(INVOICE_LIST_DIALER_API) ) {
            return;
        }
        const data = {
            filter: {
                start_date: getISOTime(dateStart),
                end_date: getISOTime(dateEnd)
            }
        };
        getTrafficReportsList(data);
    }, [permissions] );

    const handleChangeFilters = (value) => {
        if (!value)
            return;

        const start_date = new Date(value.start_end_date[0].setHours(0, 0, 0, 0));
        const end_date = new Date(value.start_end_date[1].setHours(23, 59, 59, 999));

        const filterData = {
            start_date: getISOTime(start_date),
            end_date: getISOTime(end_date),
            start_end_date: value.start_end_date
        }

        getTrafficReportsList({
            filter: filterData
        });
    };

    return (
        <>
            <PaymentsSectionHeader
                title={formatMessage(m.trafficReports)}
                show={showTable}
                callback={() => {
                    setShowTable(!showTable);
                }}
            />

            <Collapse isOpened={showTable}>
                <div>
                    <Spacer/>
                    <CommonDateFilter 
                        filters={{start_end_date: [dateStart, dateEnd]}}
                        onChangeFilters={handleChangeFilters}
                    />
                    
                    <Spacer/>
                    <PaymentsTrafficReportsTable
                        data={paymentTrafficReportsItems}
                        loading={paymentTrafficReportsLoading}
                        {...{
                            globalSettings,
                            accountJoinList,
                            openModalRequestPayment,
                            currencyName
                        }}
                    />
                </div>
            </Collapse>
        </>
    )
} );