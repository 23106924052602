import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { SelectPicker } from 'rsuite';
import CustomField from '../Form/CustomField';

const BillingIncrementComponent = ({excluded = [], raterc_list, ...props}) => {

    const filteredData = useMemo(() => {
        return raterc_list.filter(item => !excluded.includes(item.rrc_key));
    }, [raterc_list, excluded]);

    return <CustomField
        block
        data={filteredData}
        accepter={SelectPicker}
        labelKey = "name"
        valueKey = "rrc_key"
        placeholder="Billing increment"
        name="rrc_key"
        searchable={false}
        cleanable={false}
        classPrefix="allocated"
        {...props}
        virtualized={false}
    />
};

const mapState = ( {references} )=> ({
    raterc_list: references.raterc_list
});


const BillingIncrement = connect( mapState, {})(BillingIncrementComponent);

export default BillingIncrement;