import {loginAxios, requestAxios} from './';

import {Alert} from "rsuite";
import {responseErrorToString} from "utils";
import packageJson from "../../package.json";

const appVersion = 'atx_admin/' + packageJson.service;


export const login = (data) => {
    return loginAxios.post('',
        {
            jsonrpc: "2.0",
            method: "account_user_login",
            params: {...data},
            id: null,
        },
        {
            headers: {
                "X-App-Version": appVersion,
            }
        }
    )
    .then(r => r)
    .catch(e => e)
};


export const logout = () => {
    return requestAxios.post('',
        {
            jsonrpc: "2.0",
            method: "account_user_session_logout",
            id: null,
        },
        {
            headers: {
                "X-App-Version": appVersion,
            }
        }
    )
        .then(response => response)
        .catch(e => false)
};


export const notAuthApi = (method, params = {}, processErrorManual = true) => {
    return loginAxios.post('',
        {
            jsonrpc: "2.0",
            method,
            params,
            id: null
        },
        {
            headers: {
                "X-App-Version": appVersion,
            }
        }
    )
        .then(response => (response && response.data && response.data.result) || {})
        .catch((e) => {
            if (processErrorManual) {
                return Promise.reject(e);
            } else if (e.response) {
                Alert.error(responseErrorToString(e.response.data.error, e.response.status));
            }
        })
};
