import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { renderColumn } from "utils";
// import Table from "components/Table";

import {compareTwoArrays} from "utils";
import {DEFAULT_PAYMENT_MISC_STATUS_UNEXPORTED, DEFAULT_PAYMENT_MISC_STATUS_EXPORTED, DESC_SORT} from "const";
import Table from "components/Table/TableCommonNew";

import m from "definedMessages";


export default ({
    data = [],
    page,
    count,
    perPage,
    service,
    loading = false,
    filter = {},
    height = null,
    selectedItems,
    setSelected,
    currencyList,
    clearSelectedData,
    setClearSelectedData,

    getItems,
    formatMessage,
    ...props
}) => {

    var tableRef = null;

    const [numbersList, setNumbersList] = useState([]);

    const unexportedStatus = DEFAULT_PAYMENT_MISC_STATUS_UNEXPORTED;
    const exportedStatus = DEFAULT_PAYMENT_MISC_STATUS_EXPORTED;

    const uncheckedExportedData = data.map((item) => {
        const isExported = item.status !== unexportedStatus;
        return {
            ...item,
            checkPrevent: isExported,
            cantChoosenByAll: isExported
        }
    });

    useEffect(() => {
        if (!compareTwoArrays(data, numbersList)) {
            setNumbersList(uncheckedExportedData);
            setClearSelectedData(true);
        }
    }, [data]);

    //commis_percent / conv_cur_key
    useEffect(() => {
        const selectedData = data.filter((item) => selectedItems.list.includes(item.number));
        const selectedOnlyExportedData = selectedData.filter((item) => item.status === exportedStatus);
        // const selectedDataHasConflicts = selectedData
        if (selectedOnlyExportedData.length) {
            const disabledExportedData = data.map((item) => {
                const first = selectedOnlyExportedData[0];
                const isExported = item.status !== unexportedStatus;
                const hasDifferentCommisAndCurrency = (first.commis_percent !== item.commis_percent 
                    || first.conv_cur_key !== item.conv_cur_key)
                    && isExported;

                return {
                    ...item,
                    checkPrevent: hasDifferentCommisAndCurrency,
                    cantChoosenByAll: isExported,
                    disabled: hasDifferentCommisAndCurrency
                }
            });
            setNumbersList(disabledExportedData);
        } else {
            setNumbersList(uncheckedExportedData);
        }
    }, [selectedItems]);

    useEffect(() => {
        if (clearSelectedData) {
            setClearSelectedData(false);
        }
    }, [clearSelectedData]);


    const columns = [
        {
            label: formatMessage(m.range),
            dataKey: "name",
            value: (({name}) => {
                return <span className="table-centered__cell">
                    {name}
                </span>
            }),
            sortable: false,
            flexGrow: 2,
            minWidth: 280,
        },
        {
            label: formatMessage(m.number),
            dataKey: "number",
            value: (({number}) => {
                return <span className="table-centered__cell">
                    {number}
                </span>
            }),
            sortable: false,
            flexGrow: 1
        },
        {
            label: formatMessage(m.myRate),
            name: "rate",
            dataKey: "rate",
            value: ({rate, currency_name}) => (
                <span className="table-centered__cell">
                    {rate}{currency_name ? " " + currency_name : ""}
                </span>
            ),
            sortable: false,
            flexGrow: 1
        },
        {
            label: formatMessage(m.subaccountRate),
            dataKey: "subacc_rate",
            value: ({subacc_rate, currency_name}) => (
                <span className="table-centered__cell">
                    {subacc_rate}{currency_name ? " " + currency_name : ""}
                </span>
            ),
            sortable: false,
            flexGrow: 1
        }
    ];

    const renderedColumns = columns.map(renderColumn);
    

    return (
        <StyledTable
            row_key="number"
            ref={ref => tableRef = ref}

            page={page}
            count={count}
            per_page={perPage}
            data={numbersList}

            shouldUpdateScroll={true}
            extraHeight={height || 440}

            loading={loading}
            virtualized={true}
            rowHeight={40}
            headerHeight={40}

            originalColumns={columns}
            renderedColumns={renderedColumns}
            columns={columns}

            showedCount={count}
            isselected
            isSelectedAll
            ispagination

            getItems={getItems}

            clearSelected={clearSelectedData}
            setSelected={(selected) => {
                if (selected && Object.keys(selected).length) {
                    if (!compareTwoArrays(selectedItems.list, selected.list)) {
                        setSelected(selected);
                    }
                }
            }}

 
        />
    )
};


const StyledTable = styled(Table)`
    && {
        .table-centered__cell {
            line-height: 40px;
        }

        .rs-table-cell-content {
            padding: 0 0 0 10px !important;
        }
        .rs-table-cell-content > .rs-checkbox {
            top: 0px !important;
        }
        
        .rs-table-row-header .rs-table-cell-content {
            line-height: 40px;
        }

        margin-bottom: 20px;
    }
`;