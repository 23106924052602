import React, {useRef,useMemo, useContext, useState, useEffect} from 'react';
import {renderColumn} from '../../../utils/helpers';
import CustomizableTable from '../../../components/client/CustomizableTable/CustomizableTable';
import FormHOC from '../../../hoc/FilltersForm';
import {RtlContext} from "../../../App";

export default FormHOC(({
    data,
    pickedColumns,
    savedTableColumns,
    loading,
    more,
    page,
    count,
    service,
    per_page,
    savedPerPage,
    onChangePerPage,
    onChangePage,
    onSaveColumnsTable,
    extraRows
}) => {
    // const savedTableColumns = [];
    const rtl = useContext(RtlContext);
    const [columns, onChangeColumns] = useState(pickedColumns);
    
    useEffect(() => {
        onChangeColumns(pickedColumns);
    }, [pickedColumns]);

    // const memorizedColumns = useMemo(() => {
    //     const pickedIdList = pickedColumns.map(column => column.id);
    //     const currentColumns = columns.filter(column => pickedIdList.includes(column.id));

    //     return savedTableColumns && savedTableColumns.length
    //         ? currentColumns.filter(column => savedTableColumns.includes(column.id))
    //         : currentColumns;
    // }, [service, columns, pickedColumns, savedTableColumns]);

    const renderedColumns = columns.map(renderColumn);

    return (
        <CustomizableTable
            {...{
                rtl,
                data,
                savedTableColumns,
                onChangeColumns,
                count,
                per_page,
                savedPerPage,
                page,
                loading,
                onChangePage,
                onChangePerPage,
                onSaveColumnsTable,
                renderedColumns,
                extraRows
            }}
            pickedColumns={pickedColumns}
            isChangedColumns={true}
            isPaginated={true}
            rowClassName={rowData => {
                if (rowData && rowData.id === "summaries") {
                    return "summaries";
                }

                return "";
            }}
            moreCount={more}
        />

    );
});
