import React, {useRef, useMemo, useContext, useState} from 'react';
import {renderColumn} from '../../../utils/helpers';
import CustomizableTable from '../../../components/client/CustomizableTable/CustomizableTable';
import FormHOC from '../../../hoc/FilltersForm';
import { useWindowWidth } from '../../../hooks';
import {RtlContext} from "../../../App";

let renderColumns = [];

export default FormHOC(({
    data,
    type,
    page,
    count,
    per_page,
    loading,
    service,
    pickedColumns,
    savedTableColumns,
    savedPerPage,
    onChangePerPage,
    onChangePage,
    onSaveColumnsTable,
    extraRows
}) => {

    const tableRef = useRef();
    const rtl = useContext(RtlContext);
    const [columns, onChangeColumns] = useState(pickedColumns);

    const memorizedColumns = useMemo(() => {
        const pickedIdList = pickedColumns.map(column => column.id);
        const currentColumns = columns.filter(column => pickedIdList.includes(column.id));

        return savedTableColumns && savedTableColumns.length
            ? currentColumns.filter(column => savedTableColumns.includes(column.id))
            : currentColumns;
    }, [service, type, columns, pickedColumns, savedTableColumns]);


    const dataObj = data[0];

    const windowWidth = useWindowWidth();

    if (dataObj) {
        renderColumns = [];
        const dataLabels = [];
        for (let column of memorizedColumns) {
            if (dataObj[column.dataKey] || dataObj[column.dataKey] === 0) {
                dataLabels.push(column.label);
            }
        }

        for (let column of memorizedColumns) {
            if (dataObj[column.dataKey] || dataObj[column.dataKey] === 0 || dataObj[column.dataKey] === "") {
                renderColumns.push(column);
            }
        }
    }

    let selectedColumns = null;
    if (renderColumns.length > 0) {
        selectedColumns = renderColumns;
    } else {
        selectedColumns = memorizedColumns;
    }    

    const renderedColumns = selectedColumns.map(renderColumn);

    return (
        <CustomizableTable
            ref={tableRef}
            {...{
                rtl,
                data,
                count,
                page,
                per_page,
                loading,
                savedPerPage,
                onChangePage,
                onChangePerPage,
                renderedColumns,
                onChangeColumns,
                onSaveColumnsTable,
                savedTableColumns,
                extraRows
            }}
            pickedColumns={pickedColumns}
            isChangedColumns={true}
            isPaginated={true}
            isMobile={windowWidth < 768 ? true : false}
            rowClassName={rowData => {
                if (rowData && rowData.id === "summaries") {
                    return "summaries";
                }

                return "";
            }}
        />
    );
});
