import {
    SUPPLIERS as PREFIX,
    DROPDOWN_SUPPLIERS,
    SUPPLIERS_PLAN,
    SUPPLIERS_PREFIX,
    SUPPLIERS_GROUP,
    VIEW_SUPPLIER,
    SUPPLIER_DROPDOWN_PREFIX
} from '../const/'
import { Alert } from 'rsuite';
import { api, getFileResponse } from '../api/loginRoutes';
import { createAction } from  './defaults';
import { formatDateApiWithoutTime } from '../utils/format';
import {
    SMS_SUPPLIER_GET_LIST_METHOD,
    SMS_SUPPLIER_GET_DROPDOWN_LIST_METHOD,
    SMS_SUPPLIER_CREATE_METHOD,
    SMS_SUPPLIER_MODIFY_METHOD,
    SMS_SUPPLIER_REMOVE_METHOD,
    SMS_SUPPLIER_RESTORE_METHOD,
    SMS_SUPPLIER_PLAN_GET_LIST_METHOD,
    SMS_SUPPLIER_PLAN_CREATE_METHOD,
    SMS_SUPPLIER_PLAN_MODIFY_METHOD,
    SMS_SUPPLIER_PLAN_REMOVE_METHOD,
    SMS_SUPPLIER_PREFIX_GET_LIST_METHOD,
    SMS_SUPPLIER_TRAFFICLIMIT_CREATE_METHOD,
    SMS_SUPPLIER_TRAFFICLIMIT_MODIFY_METHOD,
    SMS_SUPPLIER_TRAFFICLIMIT_REMOVE_METHOD,
    SMS_SUPPLIER_GAN_GET_LIST_METHOD,
    SMS_SUPPLIER_GAN_GROUP_GET_LIST_METHOD
} from '../const/apiMethods';
import {downloadDataAsFile, updateCsvText, servicePick, updateCsvTextNoDigits} from '../utils';
import history from 'config/history';


const setSupplierData = createAction(VIEW_SUPPLIER);
const setNewAccountId = createAction('SET_NEW_ACCOUNT_ID');

const setSuppliersPlanList = createAction('SET_ITEMS' + SUPPLIERS_PLAN);
const setSupplierPlanLoading = createAction('SET_LOADING_' + SUPPLIERS_PLAN);

const setSuppliersPrefixList = createAction('SET_ITEMS' + SUPPLIERS_PREFIX);
const setSuppliersPrefixDropdownList = createAction('SET_ITEMS' + SUPPLIER_DROPDOWN_PREFIX);
const setSuppliersPrefixLoading = createAction('SET_LOADING_' + SUPPLIERS_PREFIX);

const setSuppliersGroupList = createAction('SET_ITEMS' + SUPPLIERS_GROUP);
const setSuppliersGroupLoading = createAction('SET_LOADING_' + SUPPLIERS_GROUP);

const setSuppliers = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);
const setAccountDropdownList = createAction('SET_ITEMS_' + DROPDOWN_SUPPLIERS);


export const getSupplierDropdownList = (filters = null) => (dispatch) => {
    dispatch(setLoading(true));

    const data = {
        filter: filters || {}
    };

    api(SMS_SUPPLIER_GET_DROPDOWN_LIST_METHOD, data)
        .then((r) => {
            if (!r || !r['sms.supplier_list']) {
                dispatch(setLoading(false));
                return;
            }

            if (r?.cancelled) {
                return;
            }

            dispatch(setAccountDropdownList({
                items: r['sms.supplier_list'],
                filter: filters
            }));

        });
}

export const getSuppliers = (id, filters = null) => (dispatch) => {
    dispatch(setLoading(true));
    const data = {
        filter: filters || {},
        add: {account_note_last: {}}
    };

    if (filters?.cancelToken) {
        data.cancelToken = filters.cancelToken;
        delete data.filter.cancelToken;
    }

    api(SMS_SUPPLIER_GET_LIST_METHOD, data)
        .then((r) => {
            if (!r || !r['sms.supplier_list']) {
                dispatch(setLoading(false));
                return;
            }

            if (r?.cancelled) {
                return;
            }

            dispatch(setSuppliers({
                items: r['sms.supplier_list'].map(
                    x => ({
                        ...x,
                        name: x.name || ''
                    })
                ),
                filter: filters
            }));

        });
};


export const createSupplier = (params) => (dispatch) => {
    api(SMS_SUPPLIER_CREATE_METHOD, params).then( data => {
        history.replace('/reload');
        history.push({
            pathname: `/suppliers/view/${data.supplier_id}`,
            search: '?tab=general'
        });
    } );

};


export const modifySupplier = (params) => (dispatch) => {
    dispatch(setLoading(true));
    const dataToSave = {...params};
    
    const method = SMS_SUPPLIER_MODIFY_METHOD;

    api(method, {
        target: {"sms.supplier_id": params?.id},
        ...params
    }, true)
        .then(response => {
            if (response) {
                dispatch(setLoading(false));
                if (response && response.s) {
                    // Something
                }
            }
        })
        .catch(error => {
            if (error.response) {
                dispatch(setLoading(false));
            }
        })
};

export const removeSupplier = (id) => (dispatch) => {
    dispatch(setLoading(true));
    
    const method = SMS_SUPPLIER_REMOVE_METHOD;

    api(method, {target: {"sms.supplier_id": id}}, true)
        .then(response => {
            if (response) {
                dispatch(setLoading(false));
                if (response && response.s) {
                    // Something
                }
            }
        })
        .catch(error => {
            if (error.response) {
                dispatch(setLoading(false));
            }
        })
};

export const setCurrentSupplier = (data) => (dispatch) => {
    dispatch(setSupplierData(data));
};

export const setNewCreatedAccountId = (account_id) => (dispatch) => {
    dispatch(setNewAccountId(account_id));
};

// SUPPLIER PLAN
export const getSupplierPlanList = (supplier_id) => (dispatch) => {
    setSupplierPlanLoading(true);

    api(SMS_SUPPLIER_PLAN_GET_LIST_METHOD, {target: {"sms.supplier_id": supplier_id}, })
        .then(response => {
            if (response) {
                dispatch(setSuppliersPlanList({
                    suppliersPlanList: response['sms.supplier_plan_list']
                }));
            }
        })
        .finally(() => {
            setSupplierPlanLoading(false);
        });
};

// export const modifySupplierTrafficLimit = (targetParams, data, defaultParams) => async () => {
//     const {hour: hour, day: day, ...params} = data;

//     const keyParams = {
//         'sms.traffic_limit_key_list': [defaultParams?.key],
//         'sms.supplier_id': targetParams?.supplier_id
//     }

//     if ('gan_key' in defaultParams) {
//         keyParams['sms.supplier_gan_key_list'] = [defaultParams?.gan_key];
//         keyParams['senderid_list'] = [defaultParams?.senderid];

//         delete keyParams['sms.traffic_limit_key_list'];
//     }

//     return await api(SMS_TRAFFIC_LIMITS_MODIFY_API, {
//         target: {
//             ...(targetParams?.gan_group_id ? {'sms.supplier_gan_group_id': targetParams?.gan_group_id} : {}),
//             ...(targetParams?.trunk_id ? {'sms.supplier_trunk_id': targetParams?.trunk_id} : {}),
//             ...keyParams
//         },
//         hour: parseInt(hour),
//         day: parseInt(day),
//         ...params
//     });
// };

export const createSupplierPlan = (targetParams, data, defaultParams) => async () => {
    const keyParams = {
        'sms.supplier_id': targetParams?.supplier_id
    };

    return await api(SMS_SUPPLIER_PLAN_CREATE_METHOD, {
        target: {...keyParams},
        ...data
    });
};

export const modifySupplierPlan = (targetParams, data, defaultParams) => async () => {
    const keyParams = {
        'sms.supplier_plan_id': targetParams.supplier_plan_id
    };

    return await api(SMS_SUPPLIER_PLAN_MODIFY_METHOD, {
        target: {...keyParams},
        ...data
    });
};

export const removeSupplierPlan = (targetParams) => async () => {
    const keyParams = {
        'sms.supplier_plan_id': targetParams.supplier_plan_id
    };

    return await api(SMS_SUPPLIER_PLAN_REMOVE_METHOD, {target: {...keyParams}})

};

export const getSupplierPrefixesDropdown = (trunk_id, lastCreated = false, csv = false) => (dispatch) => {
    api(SMS_SUPPLIER_PREFIX_GET_LIST_METHOD, {
        target: {"sms.supplier_trunk_id": trunk_id},
        filter: {
            last_created: lastCreated,
        },
        ...(csv ? {csv: csv} : {})
    })
        .then(response => {
            if (response && !response.error) {
                dispatch(setSuppliersPrefixDropdownList({
                    suppliersPrefixDropdownList: response['sms.supplier_prefix_list']
                }));
            }
        });
};

export const setEmptyPrefixes = () => (dispatch) => {
    dispatch(setSuppliersPrefixList({
        suppliersPrefixList: [], 
        count: 0,
        page: 1
    }));

    dispatch(setSuppliersGroupList({
        suppliersGroupList: []
    }));
};


export const getSupplierPrefixes = (trunk_id, filter, page, per_page, sort) => (dispatch) => {
    if (per_page) {
        localStorage.setItem('allocatedNumbersPerPage', per_page);
    }
    
    api(SMS_SUPPLIER_PREFIX_GET_LIST_METHOD, {target: {"sms.supplier_trunk_id": trunk_id}, filter, page, per_page, sort})
        .then(response => {
            if (response && !response.error) {
                dispatch(setSuppliersPrefixList({
                    suppliersPrefixList: response['sms.supplier_prefix_list'],
                    count: response['sms.supplier_prefix_list_count'],
                    page, 
                    per_page
                }));
            }
        });
};

export const getSupplierNumbers = (id, filter, page, per_page, sort, service=true, callback=null) => (dispatch) => {
    if (per_page) {
        localStorage.setItem('allocatedNumbersPerPage', per_page);
    }
    
    api(SMS_SUPPLIER_GAN_GET_LIST_METHOD, {
        target: {
            [servicePick(service, "sms.supplier_trunk_id", "sms.supplier_gan_group_id")]: id
        }, 
        filter, page, per_page, sort
    })
        .then(response => {
            if (response && !response.error) {
                dispatch(setSuppliersPrefixList({
                    suppliersPrefixList: response['sms.supplier_gan_list'],
                    count: response['sms.supplier_gan_count'],
                    page, 
                    per_page
                }));
            }

            if (callback) {
                callback();
            }
        });
};


export const getSupplierGroups = (trunk_id, supplier_id, filter, sort, callback = null) => (dispatch) => {
    dispatch(setSuppliersGroupLoading(true));

    api(SMS_SUPPLIER_GAN_GROUP_GET_LIST_METHOD, {
        target: {
            ...(trunk_id ? {"sms.supplier_trunk_id": trunk_id} : supplier_id ? {"sms.supplier_id": supplier_id} : {})
        }, 
        ...(filter ? {
            filter: {
                ...filter,
                actual_price: filter.actual_price ? false : null
            },
        } : {}),
        ...(sort ? {sort} : {})
    })
        .then(response => {
            if (response && !response.error) {
                dispatch(setSuppliersGroupList({
                    suppliersGroupList: response['sms.supplier_gan_group_list']
                }));
            }

            if (callback) {
                callback();
            }
        })
        .finally(() => {
            dispatch(setSuppliersGroupLoading(false));
        });
};

export const createTrafficLimit = (params) => async (dispatch) => {
    return await api(SMS_SUPPLIER_TRAFFICLIMIT_CREATE_METHOD, params, true);
};

export const modifyTrafficLimit = (params) => async (dispatch) => {
    return await api(SMS_SUPPLIER_TRAFFICLIMIT_MODIFY_METHOD, params, true);
};

export const removeTrafficLimit  = (params) => async (dispatch) => {
    return await api(SMS_SUPPLIER_TRAFFICLIMIT_REMOVE_METHOD, params, true);
};

export const onSetRedirectTab = () => {};


export const groupDownloadNumbers = (id) => async (dispatch) => {
    const method = 'sms.supplier_gan:get_list';

    const response = await getFileResponse(method, {
        target: {
            'sms.supplier_gan_group_id': id
        }, //  trunk_id => id
        plan_type: 'gan',
        filter: {
            show_allocated_numbers: true,
            show_unallocated_numbers: true,
            show_test_numbers: true,
            show_block_allocation_numbers: true
        }
    });

    if (!response) {
        Alert.error('Something went wrong', 10000);
        return Promise.reject(response);
    }

    const result = response.text();

    result.then(csvText => {
        downloadDataAsFile(updateCsvTextNoDigits(csvText), 'gan_numbers.csv');
    });
};
