import React from "react";
import {CheckPicker, DateRangePicker, Form, FormControl} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import {debounce} from "../../utils";

export default ({
    managerList,
    defaultFilter,
    onChangeFilter,
    dateRange,
    setDateRange
}) => {

    return (
        <>
            <Form
                defaultFormValue={defaultFilter}
                onChange={debounce((value) => {
                    onChangeFilter(value);
                }, 500)}
            >
                <FlexGrid align="middle">
                    <FlexGridItem>
                        <DateRangePicker
                            value={dateRange}
                            onChange={setDateRange}
                            cleanable={false}
                            placeholder="Select Date Range"
                            name="range"
                            width={300}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <FormControl
                            accepter={CheckPicker}
                            sticky
                            name="account_manager_user_id_list"
                            data={managerList}
                            valueKey="account_user_id"
                            labelKey="account_user_name"
                            style={{ width: 224 }}
                            placeholder={"Managers"}
                        />
                    </FlexGridItem>
                </FlexGrid>
            </Form>
        </>
    )
}