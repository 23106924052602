import {
    SET_AUTH,
    SET_SERVICE,
    SET_LOADING_INFO,
    SET_USER_LOGIN_INFO,
    SET_MAINTENANCE_MODE,
    SET_ACCOUNT_DIALER_INFO,
    APP_TYPE_DEFAULT,
    SET_USER_LOCALIZATION,
    APP_TYPE_ADMIN
} from '../const';
import {
    NEED_SECOND_AUTH,
    SET_PERMISSION,
    SET_PERMISSION_ADD
} from "../actions/actionTypes";
import {SET_INFO_ACCOUNT,  transformed_service_data} from '../const';
import {SET_RULES_ACCEPT} from '../const/apiMethods';
import {getManagedServices} from '../utils';
import {getSpecificValueFromSearch} from '../utils/pathHelpers';
import history from '../config/history';


const isAuth = !!localStorage.getItem('api_key');
const savedAuthInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
const appTypeSaved = savedAuthInfo?.session?.site || APP_TYPE_DEFAULT;
const rulesNotAcceptedStored = savedAuthInfo?.rules_not_accepted || false;
const role = savedAuthInfo?.session?.account_user?.role_list?.[0] || null;

const managedServiceList = getManagedServices(savedAuthInfo);
let managedService;

if (managedServiceList.length === 1) {
     managedService = transformed_service_data[managedServiceList[0]];
}

const savedLang = localStorage.getItem('lang');
const savedRtl = localStorage.getItem('rtl') && JSON.parse( localStorage.getItem('rtl') ) ;

// ATX-2922
// try to return to get service from location method and find out why interface know about current service when location doesn't

const serviceNameFromLocation = getSpecificValueFromSearch(history.location, 'service');
const serviceFromLocation = transformed_service_data[serviceNameFromLocation]; 

const serviceSaved = appTypeSaved === APP_TYPE_ADMIN && !serviceFromLocation && localStorage.getItem('service') ? JSON.parse(localStorage.getItem('service')) : null;

const lastServiceBeforeSessionExpired = localStorage.getItem("LAST_SERVICE_BEFORE_SESSION_EXPIRED");

const initialState = {
    authInfo: savedAuthInfo || {}, // completed info
    rulesNotAccepted: rulesNotAcceptedStored,
    auth:  isAuth && savedAuthInfo, // bool
    // ATX-2922
    // service: localStorage.getItem('SMS_SERVICE') ? 
    //     JSON.parse(localStorage.getItem('SMS_SERVICE')) : 
    //     managedServiceList.length === 1 ? managedService : true,
    service: (serviceFromLocation !== null && serviceFromLocation !== undefined)
        ? serviceFromLocation
        : lastServiceBeforeSessionExpired ? transformed_service_data[lastServiceBeforeSessionExpired] 
        : serviceSaved !== null ? serviceSaved : managedServiceList.length === 1 ? managedService : true,
    loading: false,
    permissions: [],
    permissions_add: [],
    role: role,
    dialerInfo: null,

    secondAuthType: null,
    secondAuthLink: null,
    secondAuthEmail: null,

    maintenanceMode: false,
    appType: appTypeSaved,
    lang: savedLang || 'en',
    rtl: savedRtl || false,
    readyForPayment: {}
};

const handlers = {
    [SET_AUTH]: (state, {payload, appType}) => ({
        ...state,
        auth: payload,
        appType: appType || APP_TYPE_DEFAULT,
        loading: false,
        secondAuthType: null,
        secondAuthLink: null,
        secondAuthEmail: null,
        permissions: [],
        permissions_add: [],
    }),
    [SET_SERVICE]: (state, {payload}) => {
        return {...state, service: payload}
    },
    [SET_RULES_ACCEPT]: (state, {payload}) => ({...state, rulesNotAccepted: payload}),
    [SET_USER_LOGIN_INFO]: (state, {payload}) => ({...state, authInfo: payload, rulesNotAccepted: payload.rules_not_accepted, appType: payload.session.site}),
    [SET_USER_LOCALIZATION]: (state, {payload}) => ({...state, lang: payload.lang, rtl: payload.rtl}),
    [SET_INFO_ACCOUNT]: (state, {payload}) => ({...state, authInfo: payload, loading: false}),
    [SET_ACCOUNT_DIALER_INFO]: (state, {payload: {
        accountJoinList, readyForPayment, ...payload
    }}) => ({
        ...state,
        dialerInfo: payload,
        accountJoinList,
        readyForPayment
        // readyForPayment: payload.available_for_payment || 0
    }),
    [SET_LOADING_INFO]: (state, {payload}) => ({...state, loading: payload}),
    [NEED_SECOND_AUTH]: (state, {payload}) => ({...state, ...payload}),
    [SET_PERMISSION]: (state, {payload}) => ({...state, permissions: payload}),
    [SET_PERMISSION_ADD]: (state, {payload}) => ({...state, permissions_add: payload}),
    [SET_MAINTENANCE_MODE]: (state, {payload}) => ({...state, maintenanceMode: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};



