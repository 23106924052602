import React, {useMemo, useState, useEffect} from "react";
import styled from "styled-components";
import Table from "../../../../../components/Table";
// import TableEditCellsNew from "components/Table/TableEditCells/TableEditCellsNew";
import {
    ButtonRowUpload, ButtonRowDownload, ButtonRowEdit
} from "../../../../../components/base/BaseButton";
import AccessListEditModal from "../AccessListModals/AccessListEditModal";
import AccessListUploadModal from "../AccessListModals/AccessListUploadModal";
import {Input, Schema, SelectPicker, Checkbox, Table as _Table} from "rsuite";
import {formatDateApi, capitalizeString} from "../../../../../utils";

const {Column, HeaderCell, Cell} = _Table;

const {StringType, NumberType} = Schema.Types;



export default ({
    data,
    onUpdateData,
    loading,
    showReasonHandler,
    ...props
}) => {
    
    const [dataState, setData] = useState(data !== null ? data : data);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [uploadDataModal, setUploadDataModal] = useState({});
    const [showAccessListEditModal, setShowAccessListEditModal] = useState(false);
    const [currentAccessListData, setCurrentAccessListData] = useState({});

    useEffect(() => {
        if (data !== null) {
            setData(data);
        }
    }, [data]);

    // const handleSortColumn = (column, type) => {
    //      setSortColumn(column);
    //      setSortType(type);
    //      setData(sortData(dataState, column, type));
    // };
    
    const columns = useMemo(() => [
        {
            dataKey: "name",
            label: "Trunk Type",
            value: ({type, name}) => `${name} (${type === "gan" ? "GAN" : "Trasit"})`,
            flexGrow: 1,
            minWidth: 100
        },
        {
            dataKey: "comment_admin",
            label: "Admin comment",
            flexGrow: 2,
            minWidth: 220,
        }, // supplier_access_list_upload_date
        {
            dataKey: "comment_dialer",
            label: "Dialer comment",
            flexGrow: 2,
            minWidth: 220,
        }, // supplier_access_list_upload_date
        {
            dataKey: "supplier_access_list_upload_date",
            label: "Last upload date",
            flexGrow: 1,
            value: ({supplier_access_list_upload_date, type}) => type === "gan" ?  formatDateApi(supplier_access_list_upload_date || "") : "—"
        },
        {
            label: "Timeless",
            dataKey: "supplier_access_list_timeless",
            value: ({supplier_access_list_timeless}) => <Checkbox
                disabled
                checked={supplier_access_list_timeless}
                style={{marginTop: "-4px"}}
            />,
            width: 80,
            align: "center"
        }
    ], [data]);

    const onEditAccessList = (rowData) => {
        const endDate = rowData.end_date 
            ? new Date(rowData.end_date) 
            : null;
    
        setShowAccessListEditModal(true);

        setCurrentAccessListData({
            ...rowData,
            end_date: endDate 
                ? new Date( 
                    endDate.getFullYear(), 
                    endDate.getMonth(), 
                    endDate.getDate(), 
                    endDate.getHours(), 
                    endDate.getMinutes(), 
                    endDate.getSeconds() - 1 
                )
                : null
        });
    };


    const onUploadPrefixes = (id) => {
   
        setUploadDataModal({
            id
        });
        
        setShowUploadModal(true);
    };

    const actionCells = ActionCell(
        onEditAccessList,
        onUploadPrefixes
    );


    return (
        <>
            <StyledTable
                // active_id={trunk_id}
                virtualized
                data={dataState}
                columns={columns}
                loading={loading}
                rowHeight={40}
                shouldUpdateScroll={false}   
                ActionCell={actionCells}
                // onRowClick={this.handleClickTrunk}
            />
            
            <AccessListEditModal
                show={showAccessListEditModal}
                currentData={currentAccessListData}
                toCreate={false}
                onClose={() => {
                    setShowAccessListEditModal(false);
                }}
                onSuccess={() => {
                    setShowAccessListEditModal(false);
                    onUpdateData();
                }}
                disabled={loading}
            />
            
            <AccessListUploadModal
                show={showUploadModal}
                currentData={uploadDataModal}
                showReasonHandler={showReasonHandler}
                onClose={() => {
                    setShowUploadModal(false);
                }}
                onSuccess={() => {
                    setShowUploadModal(false);
                    onUpdateData();
                }}
                disabled={loading}
            />
        </>
    );
};


const ActionCell = (edit, upload) => (
    <Column width={218}>
        <HeaderCell>Actions</HeaderCell>
        <Cell>
            {rowData => (
                <StyledActionCell>
                    
                    {edit && <ButtonRowEdit 
                        onClick={() => edit(rowData)}
                    />}
                    
                    {upload && <ButtonRowUpload
                        disabled={rowData.type === "transit"}
                        onClick={() => upload(rowData.id)}
                    />}

                </StyledActionCell>
            )}
        </Cell>
    </Column>
);

const StyledActionCell = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
`;

// const StyledTable = styled(TableEditCellsNew)`
const StyledTable = styled(Table)`
    &&.rs-table.rs-table-hover {
        margin-top: 0;
        margin-bottom: 0;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons {
        float: right;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons > .rs-btn {
        width: 28px;
        height: 28px;
        padding: 4px 8px;
    }
    
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons > .rs-btn {
        margin-right: 5px;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons .rs-btn-lightblue {
        color: var(--color-light);
        background: var(--color-info);
    }
    
    .rs-error-message.rs-error-message-show {
    
        position: absolute;
        color: red;
        top: -4px;
        font-size: 10px;
        border-radius: 10px;
        bottom: auto !important;
        padding: 0 4px;
        right: 6px !important;
    }
    
    .rs-error-message.rs-error-message-show .rs-error-message-arrow {
        display: none;
    }
    .rs-error-message.rs-error-message-show .rs-error-message-inner {
        white-space: nowrap;
    }

    .rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before {
        opacity: 0.7;
    }
`;