import {
    ACCOUNTS as PREFIX,
    VIEW_ACCOUNT,
    SET_VIEW_ACCOUNT,
    SET_VIEW_ACCOUNT_JOIN,
    ADD_TRUNK_ACCOUNT,
    SET_ALLOCATED_ACCOUNT,
    SET_TRUNK_ACCOUNT,
    SET_TRUNK_ACCOUNT_LOADING,
    SET_ALLOCATED_ACCOUNT_LOADING,
    SET_USERS_ACCOUNT,
    SET_USERS_ACCOUNT_LOADING,
    SET_VIEW_ACCOUNT_AFTER_DELETE,
    DESC_SORT,
    SET_INFO_ACCOUNT,
    SET_LOADING_INFO,
    SET_EDIT_ERROR,
    SET_EDIT_ERROR_MESSAGE,
    SET_GENERATE_NAME_LIST,
    SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST,
    SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST_LOADING,
    SET_ACCESS_LIST_NOTIFICATION_SETTINGS,
    UNSET_ACCESS_LIST_NOTIFICATION_SETTINGS,
    DEFAULT_AUTH_PATH_ADMIN,
    SET_CURRENT_TRUNK_ID,
    SET_LIMIT_MODIFIED,
    SET_REDIRECT_TAB,
    APP_TYPE_ADMIN,
    SET_TRUNKS_SUPPLIER,
    SET_TRUNKS_SUPPLIER_LOADING,
    SET_TRUNKS_SUPPLIER_DROPDOWN,
    transformed_service_reverse_data
} from '../const/'
import {Alert} from 'rsuite';
import {api, getFileResponse} from '../api/loginRoutes';
import {createAction} from  './defaults';
import { formatDateApiWithoutTime } from '../utils/format';
import {
    ACCOUNT_USER_GET_REALTIME_STATUS,
    ACCOUNT_GET_GENERATE_NAME_LIST_METHOD,
    ACCOUNT_USER_MODIFY_BY_SELF,
    ACCOUNT_USER_MODIFY_BY_SELF_IN_MANAGER_ACCOUNT,
    TRUNK_NUMBER_GET_LIST_API,
    SET_REALTIME_PAYMENT_STATUS,
    TRUNK_LIST_API,
    TRUNK_LIST_SMS_API,
    SMS_SUPPLIER_TRUNK_GET_LIST_METHOD,
    SMS_SUPPLIER_TRUNK_GET_DROPDOWN_LIST_METHOD,
    ACCESS_LIST_FILTER_GET_LIST_API
} from '../const/apiMethods'
import {LOCAL_STORAGE_USER_INFO} from '../const/localStorageKeys';
import {ACCOUNT_CREATE_METHOD, SMS_ACCOUNT_CREATE_METHOD} from 'const/apiMethods';
import {
    SET_ACCESS_LIST_FILTERS, 
    SET_ACCESS_LIST_FILTERS_LOADING,
    SET_CURRENT_RANGE_NUMBER, 
    SET_DEFAULT_FORM_CREATE_ACCOUNT
} from 'const';
import history from 'config/history';
import store from '../store/index';
import {TRUNKS_LIST_UNSET_INITED} from '../const';
import {downloadDataAsFile, getServiceSmsPrefix, updateCsvText, servicePick, updateCsvTextNoDigits} from '../utils';



const setAccounts = createAction('SET_ITEMS_' + PREFIX);

const setLoading = createAction('SET_LOADING_' + PREFIX);
const setJoinLoading = createAction('SET_JOIN_LOADING' + PREFIX);
const viewAccount = createAction(VIEW_ACCOUNT);
const setViewAccount = createAction(SET_VIEW_ACCOUNT);
const setAccountJoin = createAction(SET_VIEW_ACCOUNT_JOIN);

const setEditError = createAction(SET_EDIT_ERROR);
const setEditErrorMessage = createAction(SET_EDIT_ERROR_MESSAGE);

const setViewAccountTrunk = createAction(SET_TRUNK_ACCOUNT);
const setViewAccountTrunkAfterDelete = createAction(SET_VIEW_ACCOUNT_AFTER_DELETE)

const setViewAccountTrunkLoading = createAction(SET_TRUNK_ACCOUNT_LOADING);

const setViewSupplierTrunk = createAction(SET_TRUNKS_SUPPLIER);
const setViewSupplierTrunkLoading = createAction(SET_TRUNKS_SUPPLIER_LOADING);

const setSupplierTrunkDropdownList = createAction(SET_TRUNKS_SUPPLIER_DROPDOWN);

const setViewAccountAllocated = createAction(SET_ALLOCATED_ACCOUNT);
const setViewAccountAllocatedLoading = createAction(SET_ALLOCATED_ACCOUNT_LOADING);
const addViewAccountTrunk = createAction(ADD_TRUNK_ACCOUNT);

const setViewAccessListFiltersLoading = createAction(SET_ACCESS_LIST_FILTERS_LOADING);
const setViewAccessListFilters = createAction(SET_ACCESS_LIST_FILTERS);

const setViewAccountUsers = createAction(SET_USERS_ACCOUNT);
const setViewAccountUsersLoading = createAction(SET_USERS_ACCOUNT_LOADING);

const setInfoAccount = createAction(SET_INFO_ACCOUNT);
const setLoadingInfo = createAction(SET_LOADING_INFO);

const setAccountAccessListFiltersList = createAction(SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST);
const setAccountAccessListFiltersListLoading = createAction(SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST_LOADING);

const setAccessListNotificationSettings = createAction(SET_ACCESS_LIST_NOTIFICATION_SETTINGS);
const unsetAccountAccessListNotificationSettings = createAction(UNSET_ACCESS_LIST_NOTIFICATION_SETTINGS);

const dispatchCurrentTrunkId = createAction(SET_CURRENT_TRUNK_ID);
const dispatchTrunksInited = createAction(TRUNKS_LIST_UNSET_INITED);
const dispatchCurrentRangeNumber = createAction(SET_CURRENT_RANGE_NUMBER);

const setModifiedLimit = createAction(SET_LIMIT_MODIFIED);
const setDefaultFormCreateAccountAction = createAction(SET_DEFAULT_FORM_CREATE_ACCOUNT);

const setRedirectTab = createAction(SET_REDIRECT_TAB);

const setRealtimePaymentStatus = createAction(SET_REALTIME_PAYMENT_STATUS);

const setGeneratedNameList = createAction(SET_GENERATE_NAME_LIST);

export const unsetAccessListNotificationSettings = () => (dispatch) => {
    dispatch( unsetAccountAccessListNotificationSettings() );
};

export const getDropdownAccounts = (filters = null) => (dispatch) => {
    dispatch(setLoading(true));

    const data = {
        filter: filters || {}
    };

    api('account:get_dropdown_list', data)
        .then((r) => {
            if (!r || !r.account_list) {
                dispatch(setLoading(false));
                return;
            }

            if (r?.cancelled) {
                return;
            }

            dispatch(setAccounts({
                items: r.account_list.map(
                    x => ({
                        ...x,
                        name: x.name || '',
                        account_manager: x.account_manager || {},
                        closed: !x.active || x.deleted,
                        balance: x.pending_amount || 0,
                        invoice_balance: x.ready_for_payment || 0
                    })
                ),
                filter: filters
            }));

        });
}

export const getAccounts = (id, filters = null) => (dispatch) => {
    dispatch(setLoading(true));
    const data = {
        filter: filters || {},
        add: {account_note_last: {}}
    };

    if (filters?.cancelToken) {
        data.cancelToken = filters.cancelToken;
        delete data.filter.cancelToken;
    }

    api('account:get_list', data)
        .then((r) => {
            if (!r || !r.account_list) {
                dispatch(setLoading(false));
                return;
            }

            if (r?.cancelled) {
                return;
            }

            dispatch(setAccounts({
                items: r.account_list.map(
                    x => ({
                        ...x,
                        name: x.name || '',
                        account_manager: x.account_manager || {},
                        // closed: !x.active || x.deleted,
                        // balance: x.pending_amount || 0,
                        // invoice_balance: x.ready_for_payment || 0
                    })
                ),
                filter: filters
            }));

        });
};

export const getAccountJoin = (id, callback=null) => (dispatch) => {
    dispatch(setJoinLoading(true));

    api('account:get_join', {target: {account_id: id}})
        .then((response) => {
            // debugger;
            if (response === undefined || !response.account_join) {
                dispatch(setJoinLoading(false));
            }
                
            const {account_join = {}} = response || {};
            const currentJoinedAccount = account_join?.account_list ? account_join.account_list.find(account => account.id === id) : {};

            const {id: _id, supplier_id, ...acc} = currentJoinedAccount;

            const newAccountObject = {
                id: _id,
                supplier_id,
                ...acc
            };

            localStorage.setItem(`CURRENT_SUPPLIER_${_id}`, JSON.stringify(newAccountObject));

            if (callback)
                callback(newAccountObject);

            dispatch(
                setAccountJoin({
                    ...account_join,
                    ...(currentJoinedAccount || {}),
                    accountJoinList: account_join?.account_list,
                    billingCurrencyList: account_join?.billing_currency_list,
                    billingServiceList: account_join?.billing_service_list
                }));
        })
        .finally(() => dispatch(setJoinLoading(false)));
    
};


export const getAccount = (id, getFromApi = true, redirectOnError = false) => (dispatch) => {
    dispatch(setLoading(true));

    if (!getFromApi) {
        dispatch(viewAccount(id));
    } else {
        api('account:get_join', {target: {account_id: id}})
            .then((response) => {
                // debugger;
                if (response === undefined || !response.account_join) {
                    dispatch(setLoading(false));
                    
                    if (redirectOnError) {
                        history.push(DEFAULT_AUTH_PATH_ADMIN);
                    }
                    
                    return;
                }
                    
                const {account_join = {}} = response || {};

                const currentJoinedAccount = account_join.account_list && account_join.account_list.length ? account_join.account_list.find(account => account.id === id) : {};
   
                dispatch(
                    setViewAccount({
                        ...account_join,
                        ...(currentJoinedAccount || {}),
                        accountJoinList: account_join?.account_list || [],
                        trunk_notificaton: account_join.trunk_notificaton || []
                    }));
            })
            .finally(() => dispatch(setLoading(false)));
    }
};

export const getAccessListFilter = (id, filter, page = 1, per_page, sort = {}) => (dispatch) => {
    dispatch(setViewAccessListFiltersLoading(true));

    api('access:get_list', {target: {account_id: id}, filter, page, per_page, sort})
        .then(({access_list}) => dispatch(setViewAccessListFilters(access_list)))
};

export const getAccountTrunk = (id, service, callback) => (dispatch) => {
    dispatch(setViewAccountTrunkLoading(true));

    dispatch(setViewAccountAllocated({
        items: [],
        count: 0,
        page: 1
    }));
    const method = service ? TRUNK_LIST_API : TRUNK_LIST_SMS_API;

    api(method, {target: {account_id: id}})
        .then((r) => {
            if (r === undefined) {
                return;
            }

            dispatch(setViewAccountTrunk(
                (r.trunk_list || []).map(trunk => ({
                    ...trunk,
                    type: trunk.is_default_term_point
                        ? 'Default IVR'
                        : `${trunk.protocol_name}/${trunk.ip}:${trunk.port} ${trunk.tprefix}`
                }))
            ));

            callback && callback(r.trunk_list || []);
        })
        .finally(() => dispatch(setViewAccountTrunkLoading(false)));
};

export const setEmptyAccountTrunk = () => (dispatch) => {

    dispatch(setViewSupplierTrunk({
        items: [],
        count: 0,
        page: 1
    }));
};

export const getSupplierAccountTrunk = (id, type='gan') => (dispatch) => {
    dispatch(setViewSupplierTrunkLoading(true));

    dispatch(setViewSupplierTrunk({
        items: [],
        count: 0,
        page: 1
    }));
    const method = SMS_SUPPLIER_TRUNK_GET_LIST_METHOD;

    api(method ,{target:  {'sms.supplier_id': id }, plan_type: type})
        .then( (r) => {
            if (r === undefined) {
                return;
            }
            
            const tranksDataNames = {
                gan: 'accountSupplierGanTrunks',
                transit: 'accountSupplierTransitTrunks',
                default: 'supplier_trunk_list'
            };
        
            
            const dispatchTrunksName = tranksDataNames[type] || tranksDataNames['default'];

            dispatch( setViewSupplierTrunk({
                [dispatchTrunksName]: (r['sms.supplier_trunk_list'] || []).map( trunk => ({
                    ...trunk,
                    ...(trunk?.plan ? {
                        plan_id: trunk.plan.id,
                        name: trunk.plan.name,
                        type: trunk.plan.type,
                        comment: trunk.plan.comment,
                        discount_list: trunk.plan.discount_list
                    }: {})
                })),

            }));
            //  callback && callback(r['sms.supplier_trunk_list'] || [])
            }
        )
        .finally(() => dispatch(setViewSupplierTrunkLoading(false)));
};

export const getSupplierTrunkDropdownList = (type='gan') => (dispatch) => {
    dispatch(setSupplierTrunkDropdownList({
        items: [],
        count: 0,
        page: 1
    }));

    const method = SMS_SUPPLIER_TRUNK_GET_DROPDOWN_LIST_METHOD;

    api(method, {plan_type: type})
        .then( (r) => {
            if (r === undefined) {
                return;
            }
            
            const tranksDataNames = {
                gan: 'accountSupplierGanTrunks',
                transit: 'accountSupplierTransitTrunks'
            };
        
            
            const dispatchTrunksName = tranksDataNames[type];

            dispatch( setSupplierTrunkDropdownList({
                [dispatchTrunksName]: (r['sms.supplier_trunk_list'] || []).map( trunk => ({
                    ...trunk,
                    ...(trunk?.plan ? {
                        plan_id: trunk.plan.id,
                        name: trunk.plan.name,
                        type: trunk.plan.type,
                        comment: trunk.plan.comment,
                        discount_list: trunk.plan.discount_list
                    }: {})
                })),

            }));
            //  callback && callback(r['sms.supplier_trunk_list'] || [])
            }
        )
};


export const getAccountAllocatedNumbers = (account_id, trunk_id, service, group, filter, page = 1, per_page, sort = {}) => (dispatch) => {
    dispatch(setViewAccountAllocatedLoading(true));

    let sort1, sort1_desc;

    if (sort.column) {
        sort1 = sort.column === 'name' ? 'price_range_name' : sort.column;
        sort1_desc = sort.type && sort.type === DESC_SORT;
    }
    if (per_page) {
        localStorage.setItem('allocatedNumbersPerPage', per_page);
    }
    
    const method = getServiceSmsPrefix(service, 'trunk_number__get_list');

    api(method,{target: {[getServiceSmsPrefix(service, 'trunk_id')]: trunk_id}, group, filter, page, per_page, sort1, sort1_desc}).then(
        ({
             trunk_number_list,
             group_price_range_list,
             group_subdestination_list,
             row_count,
             group_price_range_count,
             group_subdestination_count,
             trunk_number_count,
             group_price_range_number_count,
             group_subdestination_number_count
         }) => dispatch(setViewAccountAllocated(
            {
                items: (trunk_number_list || group_price_range_list || group_subdestination_list || []).map(
                    x => {
                        if(group_price_range_list) {
                            x.pr_key = x.price_range.pr_key;
                            x.sde_key = x.price_range.sde_key;
                            x.sp_key = x.price_range.sp_key;
                            x.subdestination_name = x.price_range.subdestination_name;
                        }

                        if(!x.subdestination_name){
                            x.subdestination_name = x.sde_name;
                        }

                        if(x.start_date && x.end_date)
                            x.date = formatDateApiWithoutTime(x.start_date) + '-' + formatDateApiWithoutTime(x.end_date);
                        if(x.price_range)
                            x.name = x.price_range.name;

                        if(x.price_ranges && x.trunk_numbers)
                            x.allocated_numbers_and_ranges = x.trunk_numbers +'/'+ x.price_ranges;

                        return x;
                    }
                ),
                count: row_count || group_price_range_count || group_subdestination_count || 0,
                trunk_number_count: trunk_number_count || group_price_range_number_count || group_subdestination_number_count,
                page,
                per_page
            }
            )))
        .finally(() => dispatch(setViewAccountAllocatedLoading(false)));
};

export const getAccountUsers = (account_id) => (dispatch) => {
    dispatch(setViewAccountUsersLoading(true));
    // console.log(account_id);
    api('account_user:get_list', {target: {account_id}} )
        .then( (r) => {
            if (r === undefined)
                return;
            dispatch(setViewAccountUsers(r.account_user_list || []))
        })
        .finally(() => dispatch(setViewAccountUsersLoading(false)));

};

export const createAccount = (service, params) => dispatch => {
    const method = servicePick(service, ACCOUNT_CREATE_METHOD, SMS_ACCOUNT_CREATE_METHOD);
    api(method, params).then( data => {
        // console.log(data)
    } )
    // api('trunk_number__get_list',{target:  {account_id: id , trunk_id: trunk_id }}).then( ({ trunk_list = [] }) => dispatch(setViewAccountAllocated(trunk_list || [])))
    // api('trunk_number__get_list',{target:  {account_id: id , trunk_id: trunk_id }}).then( responce => console.log(responce))
};

export const modifyAccountRequest = ({name, surname, email, phone, password, old_password}) => (dispatch) => {
    const user = localStorage.getItem(LOCAL_STORAGE_USER_INFO);
    const userInfo = user && JSON.parse(user);

    const params = {
        ...(name ? {name: name} : {}),
        ...(surname ? {surname: surname} : {}),
        ...(phone ? {phone: phone} : {}),
        ...(email ? {email: email} : {}),
    };

    const dataToSave = {...userInfo, ...params};
    
    // define method by appType 
    const appType = store.getState().auth.appType;
    const method = appType === APP_TYPE_ADMIN
        ? ACCOUNT_USER_MODIFY_BY_SELF_IN_MANAGER_ACCOUNT
        : ACCOUNT_USER_MODIFY_BY_SELF;

    api(method, {
        ...params,
        ...(password ? {password: password} : {}),
        old_password
    }, true)
        .then(response => {
            if (response) {
                dispatch(setEditError(false));
                if (response && response.account_user) {
                    const lang = store.getState().auth.lang;

                    Alert.success(lang === 'en' 
                        ? 'Profile data has successfully changed!' 
                        : 'تم تغيير بيانات الملف الشخصي بنجاح!', 15000);
                    
                    dispatch(setInfoAccount({
                        ...userInfo,
                        name: userInfo.name,
                        surname: userInfo.surname,
                        phone: userInfo.phone,
                        email: userInfo.email,
                        ...params
                    }));
                    localStorage.setItem(LOCAL_STORAGE_USER_INFO, JSON.stringify(dataToSave))
                }
            }
        })
        .catch(error => {
            if (error.response && error.response.data.error.data.old_password) {
                Alert.error('Wrong old password', 15000);

                dispatch(setEditErrorMessage('Wrong old password'))
            }
        })
};

export const getGeneratedNameList = () => (dispatch) => {
    api(ACCOUNT_GET_GENERATE_NAME_LIST_METHOD).then(response => {
        dispatch(setGeneratedNameList(response.name_list));
    })
};

export const clearGeneratedNameList = () => (dispatch) => {
    dispatch(setGeneratedNameList([]));
};


export const getRealtimePaymentStatus = (id, service) => (dispatch) => {
    api(ACCOUNT_USER_GET_REALTIME_STATUS).then(response => {
        dispatch(setRealtimePaymentStatus(
            response.realtime_status
            && response.realtime_status.hasOwnProperty('payment_not_viewed_count')
            && response.realtime_status.payment_not_viewed_count) )
    });
};

/* Account:AccessListFilters */

// thunk 'Access List Filters' get items
export const getAccessListFiltersItems = (service, id) => (dispatch) => {
    dispatch( setAccountAccessListFiltersListLoading() );

    api(ACCESS_LIST_FILTER_GET_LIST_API,
        {
            target: {account_id: id},
            service: transformed_service_reverse_data[service]
        }
    ).then( ({access_list_filter_list}) => {
        let payload = access_list_filter_list;
        dispatch( setAccountAccessListFiltersList(payload) );
    } )
};

// thunk 'Access List Filters' create new item
export const createNewAccessListFiltersItem = (service, id, data) => (dispatch) => {
    dispatch( setAccountAccessListFiltersListLoading() );

    api(
        'access_list_filter:create',
        {
            target: {
                account_id: id
            },
            ...data,
            service: transformed_service_reverse_data[service]
        }
    ).then( response => {
        dispatch( setAccountAccessListFiltersListLoading() );
        dispatch( getAccessListFiltersItems(service, id) );
    } );
};


// thunk 'Access List Filters' modify item
export const modifyAccessListFiltersItem = (accountId, alf_key, data) => async (dispatch) => {

    let promise = await api(
        'access_list_filter:modify',
        {
            target: {
                alf_key: alf_key
            },
            ...data,
        }
    )

    return promise;
};



// thunk 'Access List Filters' remove item
export const removeAccessListFiltersItem = (id, data) => (dispatch) => {
    api('access_list_filter:remove',{
        target: {
            alf_key: data['alf_key']
        }
    });
};

// thunk 'Notifications' get settings
export const getAccessListNotificationSettings = (id) => (dispatch) => {
    api('access_list_notification:get',{
        target: {
            account_id: id
        }
    }).then(response => {
        dispatch( setAccessListNotificationSettings(response.access_list_notification) )
    });
};


// thunk 'Notifications' modify settings
export const modifyAccessListNotificationSettings = (id, data) => async (dispatch) => {
    let response = await api('access_list_notification:modify',{
        target: {
            account_id: id,
        },
        ...data
    });

    dispatch( getAccessListNotificationSettings(id) )

    return response;
};

export const setCurrentTrunkId = (id) => (dispatch) => {
    dispatch(dispatchCurrentTrunkId(id))
};

export const unsetTrunksInited = (value) => (dispatch) => {
    dispatch(dispatchTrunksInited())
};

export const setCurrentRangeNumber = (number) => (dispatch) => {
    dispatch(dispatchCurrentRangeNumber(number))
};

export const setAllocationRestrictions = (id, data, key) => (dispatch) => {
    api('account_setting:modify', {
        target: {
            account_id: id
        },
        ...data
    }).then(
        (response) => {
            if (response) {
                dispatch(setModifiedLimit(key));
            }
            if (Object.keys(response).length && key) {
                Alert.success('Allocation Restrictions has been modified!')
            }
            if (Object.keys(response).length && !key) {
                Alert.warning('Allocation limit has been changed to default!')
            }
        }
    )
};

export const setModifiedLimitId = (key) => (dispatch) => {
    dispatch(setModifiedLimit(key))
};

export const setDefaultFormCreateAccount = (data) => (dispatch) => {
    dispatch(setDefaultFormCreateAccountAction(data))
};

export const onSetRedirectTab = (tab) => (dispatch) => {
    dispatch(setRedirectTab(tab))
};

export const trunkDownloadNumbers = (trunk_id, service, plan_type, supplier_id) => async (dispatch) => {
    const method = service ? TRUNK_NUMBER_GET_LIST_API :
        plan_type === 'transit' ? 'sms.supplier_prefix:get_list' :
        plan_type === 'gan' ? 'sms.supplier_gan:get_list' : getServiceSmsPrefix(false, TRUNK_NUMBER_GET_LIST_API);

    const response = await getFileResponse(method, {
        target: {
            ...(service ? {trunk_id} : {
                ...(plan_type ? {'sms.supplier_trunk_id': trunk_id} : {'sms.trunk_id': trunk_id}),
                ...(supplier_id ? {'sms.supplier_id': supplier_id} : {})
            })
        }, //  trunk_id => id
        ...(plan_type ==='gan' ? {
            filter: {
                show_allocated_numbers: true,
                show_unallocated_numbers: true,
                show_test_numbers: true,
                show_block_allocation_numbers: true
           },
        } : {}),
        ...(plan_type ? {plan_type} : {})
    });

    if (!response) {
        Alert.error('Something went wrong', 10000);
        return Promise.reject(response);
    }

    const result = response.text();
    
    const fileNames = {
        transit: 'transit_prefixes.csv',
        gan: 'gan_prefixes.csv',
        default: 'allocated_numbers.csv'
    }
    const fileName = fileNames[plan_type] || fileNames['default']; 

    if (!plan_type) {
        result.then(csvText => {
            downloadDataAsFile(updateCsvText(csvText), fileName);
        });
    } else if (plan_type) {
        result.then(csvText => {
            downloadDataAsFile(updateCsvTextNoDigits(csvText), fileName);
        });
    }
};
