import React, { useRef } from "react";
import { Form, FormControl, Schema } from "rsuite";
import { debounce } from "utils";
import { useWindowWidth } from "hooks";
import { FlexGrid, FlexGridItem } from "components/base/FlexGrid";
import { BaseInputField } from "components/base/BaseForm";
import styled from "styled-components";

import m from "definedMessages";


const {StringType} = Schema.Types;

const MAX_CHARACTERS = 40;

const LiveCallsFilter = ({
    filter,
    disabled,

    onChangeFilter,
    formatMessage
}) => {

    const formRef = useRef(null);

    const filtersModel = Schema.Model({
            number_destination: StringType()
                .maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS}))
        }
    );

    const resizedWidth = useWindowWidth();

    const onSubmit = (data) => {
        if (formRef.current.check()) {
            onChangeFilter({...data});
        }
    };

    return (
        <StyledForm
            ref={formRef}
            model={filtersModel}
            formDefaultValue={filter}
            onChange={debounce((data) => {
                onSubmit(data);
            }, 500)}
            disabled={disabled}
        >
            <FormControl
                accepter={BaseInputField}
                name="number_destination"
                errorPlacement="topEnd"
                placeholder={resizedWidth > 300 ? formatMessage(m.filterPerNumOrDest) : "Numbers/Destination"}
            />
        </StyledForm>
    );
};

export default LiveCallsFilter;


const StyledForm = styled(Form)`
    && {
        display: flex;
        justify-content: flex-start;

        .rs-form-control-wrapper {
            width: 260px;
            margin-right: 15px;
        }
    }
`;