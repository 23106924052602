import {connect} from "react-redux";
import {
    getPostbackList
} from "../../../actions/postback_logs";
import TabPostbackLogs from "./TabPostbackLogs";

const mapStateToProps = ({auth, references, postback_logs}) => ({
    service: auth.service,

    page: postback_logs.page,
    per_page: postback_logs.per_page,
    count: postback_logs.count,

    postbackList: postback_logs.items,
    postbackLoading: postback_logs.loading,

    globalSettings: references.global_setting,
});

export default connect(mapStateToProps, {
    getPostbackList
})(TabPostbackLogs);