import React, {useEffect, useState, useMemo, useRef} from "react";
import axios from "axios";
import moment from "moment";
import {
    DEFAULT_SP_KEY, SMS_DEFAULT_SP_KEY,
} from '../../const/';
import {downloadDataAsFile, toStartDayUTCTime} from "../../utils";
import {api, getCsvResponse} from "../../api/loginRoutes";
import {REVOKE_MASS_REVOKE_API, SMS_REVOKE_MASS_REVOKE_API, REVOKE_MASS_REVOKED_LIST_API} from "../../const/apiMethods";

import {Spacer} from "../../components/base/Spacer";
import PageHeader from "../../components/base/PageHeader";
import PanelLayout from "../../components/base/PanelLayout";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";

import MassRevokeTable from "./MassRevokeTable";
import ModalMassRevoke from "./ModalMassRevoke";
import MassRevokeFilter from "./MassRevokeFilter";
import MassRevokeParams from "./MassRevokeParams";
import ModalMassRevokeResult from "./ModalMassRevokeResult";
import { getServiceSmsPrefix } from "../../utils";


export default (
    {
        data,
        service,
        loading,
        servicePlanList,
        worldzoneList,
        destinationList,
        subdestinationList,
        accountManagers,
        adminUsers,
        accounts,
        supplierList,
        getRevokeList,
        defaultSPKey,
        referencesInited,

        getDropdownAccounts,
        getManagerAccountUserDropdownList,
        getAccountManagersDropdownList,
        getGanSuppliersCommonList,
    }
) => {
    
    const rowKey = getServiceSmsPrefix(service, 'trunk_id');
    const transactionKey = getServiceSmsPrefix(service, 'trunk_number_transaction');

    const defaultSPKeyByService = service ? DEFAULT_SP_KEY : SMS_DEFAULT_SP_KEY;

    const accountManagersFiltered = accountManagers.filter(value => value.active || value.has_accounts);

    const tableRef = useRef(null);
    const cancelToken = useRef(axios.CancelToken.source());

    const [filterMain, setFilterMain] = useState({sp_key: defaultSPKeyByService }); // defaultSPKey
    const [filterSecondary, setFilterSecondary] = useState({});

    const [revokeLoading, setRevokeLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    
    const [modalMassRevokeShow, setModalMassRevokeShow] = useState(false);
    const [modalMassRevokeResultShow, setModalMassRevokeResultShow] = useState(false);
    const [modalMassRevokeResultData, setModalMassRevokeResultData] = useState({});

    const [selected, setSelected] = useState({all: false, list: []});

    useEffect(() => {
        if (defaultSPKey !== defaultSPKeyByService)
            setFilterMain({sp_key: defaultSPKeyByService})
    }, [defaultSPKeyByService, defaultSPKey]);

    const revokedDataInfo = useMemo(() => {
        const selectedData = selected.list.map((id) => data.find(item => item[rowKey] === id));
        const info = selectedData.reduce((summ, item) => {
            return {
                ...summ,
                numbers: summ.numbers + item.numbers_to_revoke,
                accounts: summ.accounts.includes(item.account_name)
                    ? summ.accounts
                    : [...summ.accounts, item.account_name]
                }
            }
        , {accounts: [], numbers: 0});

        return {
            accounts: info.accounts.length,
            numbers: info.numbers
        }
    }, [selected, data]);



    useEffect(() => {
        getDropdownAccounts();
        getManagerAccountUserDropdownList();
        getAccountManagersDropdownList();
        getGanSuppliersCommonList(service);
    }, [service]);


    useEffect(() => {
        if (filterMain.sp_key !== 8) {
            setFilterSecondary(prev => ({
                ...prev,
                supplier_id: "",
            }));
        }
    }, [filterMain.sp_key]);


    useEffect(() => {
        if (!referencesInited) {
            return
        }
        handleGetRevokeList({sp_key: defaultSPKeyByService}, filterSecondary);
    }, [referencesInited]);


    useEffect(() => {
        tableRef.current.clear();
    }, [filterMain]);


    const handleChangeParams = (params) => {
        setFilterSecondary(params);
        handleGetRevokeList(filterMain, params);
    };

    const handleChangeFilter = (filter) => {
        setFilterMain(filter);
        if (filter.sp_key !== 8) {
            setFilterSecondary(prev => ({
                ...prev,
                supplier_id: "",
            }));
        }
        handleGetRevokeList(filter, filterSecondary);
    };


    const handleGetRevokeList = (filterMain, filterSecondary) => {
        if (typeof cancelToken.current !== typeof undefined) {
            cancelToken.current.cancel("Operation canceled due to new request.");
        }
        cancelToken.current = axios.CancelToken.source();
        getRevokeList(filterMain, service, filterSecondary, cancelToken.current);
    };


    const handleRevokeList = () => {

        const params = {
            sp_key: filterMain.sp_key,
            filter: {
                ...filterSecondary,
                start_date: filterSecondary?.date?.[0]
                    ? moment(filterSecondary.date[0]).format( moment.defaultFormatUtc )
                    : undefined,
                end_date: filterSecondary?.date?.[1]
                    ? moment(filterSecondary.date[1].setHours(23, 59, 59, 999)).format( moment.defaultFormatUtc )
                    : undefined,
                date: undefined,
                account_name: filterMain.account_name || undefined,
                supplier_id: filterSecondary.supplier_id && filterMain.sp_key === 8
                    ? filterSecondary.supplier_id
                    : undefined,
            },
            target: {
                account_id_list: filterMain.acc_key ? [filterMain.acc_key] : undefined,
                account_manager_id: filterMain.am_key || undefined,
                [`${rowKey}_list`]:  selected?.list?.length && selected?.list?.length === data.length
                    ? null
                    : selected?.list ? selected.list : undefined
            }
        }

        // if (startDate) {
        //     params.start_date = startDate
        //         ? toStartDayUTCTime(startDate)
        //         : null;
        // }

        if (filterSecondary.allocated_by) {
            const managerList = filterSecondary.allocated_by.filter(item => item !== 'self_allocation');
            params.filter.dialer = !!filterSecondary.allocated_by.find(item => item === 'self_allocation') || null;
            params.target.account_user_manager_id_list = managerList.length ? managerList : undefined;
            delete params.filter.allocated_by;
        }

        setRevokeLoading(true);

        const revokeMethod = service ? REVOKE_MASS_REVOKE_API : SMS_REVOKE_MASS_REVOKE_API;

        api(revokeMethod, params)
            .then((r) => {
                if (!r) {
                    return;
                }

                handleShowResult(r);
                setModalMassRevokeShow(false);
                handleGetRevokeList(filterMain, filterSecondary);
                tableRef.current.clear();

            })
            .finally(() => {
                setRevokeLoading(false);
            });
    };


    const handleShowResult = (r) => {
        setModalMassRevokeResultData(r);
        setModalMassRevokeResultShow(true);
    }


    const handleDownloadLog = () => {
        setDownloadLoading(true);
        getCsvResponse(getServiceSmsPrefix(service, REVOKE_MASS_REVOKED_LIST_API), {
            [getServiceSmsPrefix(service, 'trunk_number_transaction_id')]: modalMassRevokeResultData[transactionKey]?.id
        })
            .then(r => {
                if (!r) {
                    return;
                }
                downloadDataAsFile(r, 'revoking_log.csv');
            })
            .finally(() => {
                setDownloadLoading(false);
            });
    }


    return (
        <PanelLayout>
            <PageHeader title="Mass Revoke">
            </PageHeader>

            <MassRevokeFilter
                {...{
                    servicePlanList,
                    accountManagers: accountManagersFiltered,
                    accounts,
                    supplierList,

                    getDropdownAccounts,
                    getAccountManagersDropdownList,
                }}

                filter={filterMain}
                onChange={handleChangeFilter}
            />

            <Spacer />

            <FlexGrid>
                <FlexGridItem grow={1}>
                    <MassRevokeTable
                        style={{width: '100%'}}
                        isselected
                        ref={tableRef}
                        rowKey={rowKey}
                        onRevoke={handleRevokeList}
                        {...{
                            data,
                            service,
                            loading,
                            revokedDataInfo,
                            setModalMassRevokeShow,
                            selected,
                            setSelected,
                        }}
                    />
                </FlexGridItem>
                <FlexGridItem style={{width: 400}}>
                    <MassRevokeParams
                        {...{
                            servicePlanList,
                            worldzoneList,
                            destinationList,
                            subdestinationList,
                            accountManagers: accountManagersFiltered,
                            adminUsers,
                            accounts,
                            supplierList,

                            getDropdownAccounts,
                            getAccountManagersDropdownList,
                        }}

                        filter={filterSecondary}
                        filterMain={filterMain}
                        onChange={handleChangeParams}
                    />
                </FlexGridItem>
            </FlexGrid>
            <Spacer />

            <ModalMassRevoke
                show={modalMassRevokeShow}
                loading={revokeLoading}
                revokedDataInfo={revokedDataInfo}
                onSubmit={handleRevokeList}
                onSuccess={handleShowResult}
                onClose={() => setModalMassRevokeShow(false)}
            />

            <ModalMassRevokeResult
                show={modalMassRevokeResultShow}
                data={modalMassRevokeResultData}
                loading={downloadLoading}
                transactionKey={transactionKey}
                onClose={() => {
                    setModalMassRevokeResultShow(false);
                    setModalMassRevokeResultData({});
                }}
                onDownloadLog={handleDownloadLog}
            >

            </ModalMassRevokeResult>
        </PanelLayout>
    )
}