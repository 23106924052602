import React from 'react';
import Table from '../../../components/Table'
import {Whisper, Tooltip} from "rsuite/es"


export default ({
    data = [],
    loading,
    perPage,
    page,
    count,
    getItems,
    toggleParamsModal,
    toggleErrorModal,
    ...props
}) => {
    const columns = [
        {
            label: 'Date / Time',
            dataKey: 'date',
            width: 200,
            formatData: 'datetime_en',
        },
        {
            label: 'Manager',
            // dataKey: 'account_manager_name',
            value: ({account_user_name, account_user_role}) => `${account_user_name} ${account_user_role}`,
            width: 200
        },
        {
            label: 'Changes',
            dataKey: 'object',
            width: 350
        },
        {
            label: 'Status',
            dataKey: 'type',
            value: ({type}) => {
                switch(type) {
                    case 0:
                        return 'Created';
                    case 1:
                        return 'Changed';
                    case 2:
                        return 'Deleted';
                    default:
                        return 'Changed';
                }
            },
            width: 100
        },
        {
            label: 'Previous value',
            dataKey: 'value0',
            value: ({value0}) => {
                const data = value0;
                return (
                    <Whisper
                        enterable
                        placement="auto"
                        speaker={
                            <Tooltip>{data}</Tooltip>
                        }
                    >
                        <span>{data}</span>
                    </Whisper>
                )
            },
            width: 500
        },
        {
            label: 'Current value',
            dataKey: 'value1',
            value: ({value1}) => {
                const data = value1;
                return (
                    <Whisper
                        enterable
                        placement="auto"
                        speaker={
                            <Tooltip>{data}</Tooltip>
                        }
                    >
                        <span>{data}</span>
                    </Whisper>
                )
            },
            width: 500
        }
    ];

    return <Table
        data={data}
        loading={loading}
        columns={columns}
        getItems={getItems}
        {...props}
    />
};