import {
    SET_VISIBLE_RECAPTCHA,
    SET_REQUEST_DATA_RECAPTCHA,
    SET_NUMBER_ALLOCATION_MODAL
} from '../const/';

const initialState = {
    showAllocation: false,
    visibleRecaptcha: false,
    methodRecaptcha: null,
    paramsRecaptcha: {}
};

const handlers = {
    [SET_NUMBER_ALLOCATION_MODAL]: (state, {payload: showAllocation}) => ({...state, showAllocation}),
    [SET_VISIBLE_RECAPTCHA]: (state, {payload}) => {
        return {...state, visibleRecaptcha: payload}
    },
    [SET_REQUEST_DATA_RECAPTCHA]: (state, {payload: {methodRecaptcha, paramsRecaptcha}}) => ({
        ...state,
        methodRecaptcha, 
        paramsRecaptcha
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action)
};
