import {connect} from 'react-redux';
import {
    allocateTrunkNumbersByTemplate,
    allocateTrunkNumbersByNumberList,

    setAllocatedNumbersByTemplate,
    setAllocatedNumbersByNumberList,
    setLoadingAllocatedNumbers,
    setUploadingLoading,
    downloadNumbers,
    getNumberTrunksList,
    getTrunkNumbers,
    setTrunkNumberTransaction,

} from '../../actions/client/numbers';
import Numbers from './Numbers';
import {showReasonHandler} from "../../actions/reason_handler";


const mapState = ({client_numbers, auth, auth_dialer, references}) => ({
    trunks: client_numbers.numberTrunks.filter((trunk) => !trunk.is_reseller),
    numberTrunkLoading: client_numbers.numberTrunkLoading,
    allocatedNumbers: client_numbers.allocatedNumbers,
    allocatedNumbersLoading: client_numbers.allocatedNumbersLoading,
    loadingAllocation: client_numbers.loadingAllocatedNumbers,
    count: client_numbers.count,
    totalNumbersCount: client_numbers.totalNumbersCount,
    page: client_numbers.page,
    per_page: client_numbers.per_page,
    uploadLoading: client_numbers.uploadTrunkLoading,

    allocated_numbers_temp: client_numbers.allocated_numbers_by_template,
    allocated_numbers_num_list: client_numbers.allocated_numbers_by_number_list,
    trunk_number_transaction: client_numbers.trunk_number_transaction,

    accountDialerState: auth_dialer.dialerInfo, 
    accountJoinList: auth_dialer.accountJoinList,
    account: auth.authInfo,
    // service: auth.service,
    service: auth_dialer.serviceDialer,
    permittedMethods: auth.permittedMethods,
});

export default connect(mapState,
    {
        getNumberTrunksList,
        getTrunkNumbers,
        downloadNumbers,
        allocateTrunkNumbersByNumberList,
        allocateTrunkNumbersByTemplate,
        setAllocatedNumbersByNumberList,
        setAllocatedNumbersByTemplate,
        setLoadingAllocatedNumbers,
        setTrunkNumberTransaction,
        setUploadingLoading,
        showReasonHandler
    })(Numbers);