import React, {useMemo, useEffect, useState} from 'react';
import {ButtonPrimary} from '../../../../../components/base/BaseButton';
import Modal from './Modal';
import {
    SMS_SUPPLIER_PREFIX_CREATE_METHOD,
    SMS_SUPPLIER_PREFIX_CREATE_SUBDESTINATION_METHOD,
    SMS_SUPPLIER_PREFIX_CREATE_SUBDESTINATION_LIST_METHOD,
    SMS_SUPPLIER_PREFIX_CREATE_MCCMNC_METHOD,
    SMS_SUPPLIER_PREFIX_CREATE_MCCMNC_LIST_METHOD,
    SMS_SUPPLIER_PREFIX_CREATE_TEMPLATE_LIST_METHOD
} from '../../../../../const/apiMethods';
import { Alert } from 'rsuite';

export default ({
    account_id,
    trunk_id,
    update,
    onClose,
    forsed_open = false,

    disabled,
    currentTrunk,
    hideBtn,

    suppliersPrefixDropdownList,
    getSupplierPrefixesDropdown
}) => {

    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState('single');


    const method = useMemo(() => {
        if (activeTab === "single") {
            return SMS_SUPPLIER_PREFIX_CREATE_METHOD;
        } else if (activeTab === "operator") {
            return SMS_SUPPLIER_PREFIX_CREATE_MCCMNC_LIST_METHOD;
        } else if (activeTab === "subdestination") {
            return SMS_SUPPLIER_PREFIX_CREATE_SUBDESTINATION_LIST_METHOD;
        } else if (activeTab === "mass") {
            return SMS_SUPPLIER_PREFIX_CREATE_MCCMNC_LIST_METHOD;
        } else if (activeTab === "template") {
            return SMS_SUPPLIER_PREFIX_CREATE_TEMPLATE_LIST_METHOD;
        }
        return SMS_SUPPLIER_PREFIX_CREATE_METHOD;
    }, [activeTab]);


    useEffect(() => {
        if (forsed_open) {
            setShow(true)
        }
    }, [forsed_open]);

    if (hideBtn) {
        return null;
    }

    return (
        <>
        
            <ButtonPrimary 
                disabled={disabled} 
                onClick={() => setShow(true)}
            >
                Add New Prefixes
            </ButtonPrimary>

            <Modal
                show={show}
                onFaultResult={(result) => {
                    if (!result?.error) {
                        setShow(false);
                    }
                }}
                account_id={account_id}
                trunk_id={trunk_id}
                update={update}
                onClose={() => {
                    setShow(false);
                    // onClose();
                }}
                currentTrunk={currentTrunk}
                target={{
                    "sms.supplier_trunk_id": trunk_id,
                }}
                method={method}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                suppliersPrefixDropdownList={suppliersPrefixDropdownList}
                getSupplierPrefixesDropdown={getSupplierPrefixesDropdown}
            />
        </>
    );
}
