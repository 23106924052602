import React, {useRef, useState} from 'react';
import {apiFormData} from "api/loginRoutes";
import styled, {css} from 'styled-components';
import {toStartDayUTCTime} from 'utils';
import {SCREEN_MEDIA} from  '../../../../const';
import {Spacer} from "../../../../components/base/Spacer";
import {SMS_SUPPLIER_PREFIX_UPLOAD_CSV_METHOD, TRUNK_MODIFY_API} from '../../../../const/apiMethods';
import {Form, FormControl, Alert, ControlLabel} from "rsuite";

import CheckboxControl from "components/Form/CheckboxControl";
import {CustomModal} from "../../../../components/base/BaseModal";
import CustomUploaderSingle from "../../../../components/Form/CustomUploaderSingle";

const FILES_DEFAULT = [];

export default ({
    show,
    currentSupplier,
    currentTrunkData,
    onSuccess,
    onClose,
    ...props
}) => {

    const formRef = useRef();
    const [files, setFiles] = useState(FILES_DEFAULT);
    const [loading, setLoading] = useState(false);

    // submit
    const handleSubmit = () => {
        if ( !formRef.current.check() ) {
            return;
        }

        if (!files || !files.length) {
            Alert.error('Please upload CSV file with prices');
            return;
        }

        const formValue = formRef.current.getFormValue();

        const startDate = formValue.start_date 
            ? toStartDayUTCTime(formValue.start_date).getTime() !== toStartDayUTCTime( new Date() ).getTime()
                ? toStartDayUTCTime(formValue.start_date)
                : undefined
            : undefined;
    

        const formData = {
            active: formValue?.active === undefined ? true : formValue.active,
            full_price: formValue?.full_price || false,
            target: {
                'sms.supplier_trunk_id': currentTrunkData?.id,
            }
        };

        const dt = new DataTransfer();
        dt.items.add(files[0].blobFile);
        formData.files = dt.files;
        delete formData.file;

        setLoading(true);
        apiFormData(SMS_SUPPLIER_PREFIX_UPLOAD_CSV_METHOD, formData)
            .then(r => {

                if (!r) {
                    return;
                }

                if (r.validation_error) {
                    Alert.error(<>
                        <p><b>Error</b>: {r.validation_error}</p>
                        {r.line_number &&
                            <p>
                                {formData.files && `${formData.files[0].name}: `}
                                line <b>{r.line_number}</b><br/>
                                {r.line_str}
                            </p>
                        }
                    </>, 10000);
                    return;
                }

                onSuccess && onSuccess(r);
                handleClose();

            })
            .finally(() => {
                setLoading(false);
            });
    };


    const handleClose = () => {
        setFiles([]);
        onClose && onClose();
    };


    return (
        <CustomModal
            showFooter
            show={show}
            title={'Supplier prefix upload'}
            width={560}
            loading={loading}
            onConfirm={handleSubmit}
            onClose={handleClose}
            {...props}
        >
            <StyledForm
                ref={formRef}
                // model={formCommonModel}
            >
                <StyledField>
                    <CheckboxControl
                        name="full_price"
                        defaultChecked={false}
                    >
                        Replace all prefixes
                    </CheckboxControl>
                </StyledField>
                <StyledField>
                    {/* <ControlLabel>File</ControlLabel> */}
                    <FormControl
                        errorPlacement={'topStart'}
                        name="file"
                        text="Upload file list"
                        files={files}
                        accepter={CustomUploaderSingle}
                        setFiles={setFiles}
                    />
                </StyledField>
            </StyledForm>

            <Spacer size={15}/>

            <StyledControlLabel>
                * The CSV file must consist of 3 required columns: prefix, name and rate
            </StyledControlLabel>
        </CustomModal>
    )
};

const StyledControlLabel = styled(ControlLabel)`
    font-style: italic;
    opacity: 0.6;
`;

const StyledForm = styled(Form)`

`;

const FormRow = styled.div`
    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const FormCol = styled.div`

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        padding-left: 10px;
        padding-right: 10px;
        width: 50%;

        &:nth-child(2n - 1) {
            padding-left: 0
        }

        &:nth-child(2n) {
            padding-right: 0
        }
    }
`;

const StyledField = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
    }

    .rs-control-label {
        flex-shrink: 0;
        align-self: center;
        padding-right: 10px;
        width: 100%;

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            width: 150px;
        }
    }

    .rs-input-number {

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            max-width: 90px;
        }
    }

    ${props => props.isCheckbox && css`
        display: flex;
        
        @media (max-width: ${SCREEN_MEDIA.sm.max}px) {

            .rs-form-control-wrapper {
                width: auto;
            }

            .rs-control-label {
                width: auto;
                order: 1;
            }
        }
    `}
`;