import {connect} from 'react-redux';
import AccountChanges from "./AccountChanges";
import {getAccountChanges} from "../../../actions/account_changes";

const mapState = ({account_changes}) => ({
    data: account_changes.list,
    loading: account_changes.loading,
});

export default connect(mapState, {
    getAccountChanges
})(AccountChanges)