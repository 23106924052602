import React from "react";
import Modal from "../../../components/Modal";
import {List} from "rsuite";
import {Spacer} from "../../../components/base/Spacer";

export default (
    {
        show,
        data = [],
        onClose,
        onSuccess,
        loading,
    }
) => {

    return (
        <Modal
            show={show}
            footer
            title="Deactivate trunks"
            successButtonColor="#F8432F"
            successText="Deactivate and Submit"
            onClose={onClose}
            onSuccess={onSuccess}
            disabled={loading}
        >
            <p><b>Deactivate next trunks?</b></p>

            <Spacer />

            <List>
                {data.map(({id, name, service_plan_name}) => (
                    <List.Item key={id}>
                        Trunk: <b>{name}</b>; Price: <b>{service_plan_name}</b>
                    </List.Item>
                ))}
            </List>
        </Modal>
    )
};