import React, {useEffect} from 'react';
import {Nav} from 'rsuite';
import {Spacer} from "../../components/base/Spacer";
import PageTabs from "../../components/PageTabs";


const menuItems = [
    {id: "payment-requests", eventKey: "payment-requests", title: "Payment requests"},
    {id: "payments", eventKey: "payments", title: "Payments"},
    {id: "invoices", eventKey: "invoices", title: "Invoices"},
    {id: "hawala-payment-request", eventKey: "hawala-payment-request", title: "Hawala payment request"},
    {id: "misc-payment-request", eventKey: "misc-payment-request", title: "Misc payments"},
];


export default ({
    activeTab,
    onSelect
}) => {

    return (
            <>
                <PageTabs
                    {...{activeTab, onSelect, menuItems}}
                />
                <Spacer size={30}/>
            </>    
        )
}