import {DEFAULT_PER_PAGE_SMALL, PRICES, SET_SERVICE_PLAN_PRICE} from '../const/';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';
import {setRanges} from './ranges';

const setLoading = createAction('SET_LOADING_'+ PRICES);
const setServicePlanPriceList = createAction(SET_SERVICE_PLAN_PRICE);

export const getPrices = (_filter, page, with_price) => (dispatch) => {
    const {cur_key, p_key, ...filter} = _filter;

    // dispatch(setRanges([]))
    dispatch(setLoading(true));
    api(PRICES, {filter, cur_key, p_key, page, with_price, per_page: DEFAULT_PER_PAGE_SMALL, "sort1": ["subdestination_name"], "output_type": 2})
        .then( ({service_plan_price_list = [], service_plan_price_count = 0 }) => {
            dispatch(setServicePlanPriceList({
                items: service_plan_price_list,
                count: service_plan_price_count,
                page
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};