import React, {useState, useEffect} from 'react';
import ModalSendApi from '../../../../../components/Modal/ModalSendApi';
import {Alert, Checkbox, DatePicker, Schema} from 'rsuite';
import ApiRequest from '../../../../../components/ApiRequest';
import {toStartDayUTCTime, getServiceSmsPrefix} from 'utils';
import FormControl from 'rsuite/es/FormControl';
import ControlLabel from 'rsuite/es/ControlLabel';
import FormGroup from 'rsuite/es/FormGroup';
import {MIN_RATE_VALUE, SMS_DEFAULT_SP_KEY} from 'const';
import MyForm from '../../../../../components/MyForm';
import {useWindowWidth} from "../../../../../hooks";
import CheckboxControl from "components/Form/CheckboxControl";


const {NumberType} = Schema.Types;

export default ({
    initialValues,
    getData,
    trunk_id,
    targetPrice,
    update,
    sp_key,
    account_name,
    onClose,
    prefixKeyList,
    ...props
}) => {

    const {trn_key_list, ...restIntialValues} = initialValues;

    const formDefaultValue = {
        active: true,
        ...(restIntialValues || {})
    };

    const [formValue, onChangeFormValue] = useState(formDefaultValue);

    const [canSend, setSend] = useState([]);
    const [rate, setRate] = useState(0);
    const [endDate, setEndDate] = useState(null);
    const [withDate, setWithDate] = useState(false);
    const windowWidth = useWindowWidth();
    const data = getData();

    const changeRateModel = {
        rate: NumberType()
            .min(MIN_RATE_VALUE, `The minimum value of this field is ${MIN_RATE_VALUE}`)
            .max(10, `The minimum value of this field is 10`)
    };

    const changeRateMethod =  "sms.supplier_prefix:modify_list";

    const formActiveValue = (formValue?.active !== null || formValue?.active !== undefined) ? formValue.active : true;

    const selectedKeysList = Array.from(prefixKeyList.keys());

    return (
        <ModalSendApi
            {...props}
            title="Change Rate"
            successText="Change"
            update={update}
            onClose={onClose}
            extraDisabled={!rate || withDate && (!endDate || !rate)}
        >
            {/* {data.length ? data.map(item => 
                <ApiRequest
                    key={item.sde_key}
                    target={targetPrice}
                    method="sms.supplier_prefix:create"
                    checkResultKey='supplier_prefix'
                    canSend={canSend.includes(item.sde_key)}
                    data={{
                        sp_key: SMS_DEFAULT_SP_KEY,
                        sde_key: item.sde_key,
                        rate: rate
                    }}
                    update = { () => {
                        Alert.success(`Default rate ${rate} for ${item.sde_name} was set`);
                    }}
                >
                    <Checkbox
                        value={item.sde_key}
                        onChange={(value, checked) => {
                            if (checked) {
                                setSend([...canSend, item.sde_key]);
                                return;
                            }
                            setSend( canSend.filter(stateItem => stateItem !== item.sde_key) );
                        }}
                        className="mb-3"
                    >
                        Set this  rate as default for {item.sde_name} for {account_name} (current: <b>{item.rate}</b>)
                    </Checkbox>
                </ApiRequest>
            )  : <></>} */}
                

            <MyForm
                formDefaultValue={formDefaultValue}
                model={changeRateModel}
                target={{"sms.supplier_trunk_id": trunk_id}}
                method={changeRateMethod}
                checkResultKey = 'supplier_prefix_update_list'
                update = { (result) => {
                    const supplierPrefixes = result?.supplier_prefixes;
                    const count = supplierPrefixes ? supplierPrefixes?.updated : 0;

                    Alert.success(`Change Rate for ${count} numbers`);
                    
                }}
                onChange={(value) => {
                    onChangeFormValue(value);
                }}
                addData={{
                    ...(initialValues?.filterAll ? {} : {"sms.supplier_prefix_key_list": selectedKeysList}),
                    rate: rate,
                    active: formActiveValue,
                    ...(endDate ? {end_date: endDate} : {})
                }}
                className={`d-flex flex-column ${windowWidth >= 600 ? 'align-items-center' : null}`}

                // life huck: disabled do not work. need to be refactored
                deepCloned={false}
            >

                <FormGroup className="mb-3 mr-0">
                    <ControlLabel className="mr-3 mb-0">New Rate</ControlLabel>
                    <FormControl name="rate"
                        label="New Rate"
                        style={{width: 140}}
                        placeholder="0.0001"
                        onChange={value => setRate(+value)}
                        errorPlacement="topStart"
                    />
                    <CheckboxControl
                        name="active"
                        defaultChecked
                    >
                        Active
                    </CheckboxControl>
                </FormGroup>
                <FormGroup className="mb-0 mr-0">
                    <Checkbox
                        inline
                        className="mr-4 ml-0"
                        onChange={(v, checked) => {
                            setWithDate(checked);

                            if (!checked) {
                                setEndDate(null);
                            }
                        }}
                    >
                        From date
                    </Checkbox>

                        <DatePicker
                            name="end_date"
                            placeholder="Enter Date"
                            disabled={!withDate}
                            onChange={(date) => {
                                date && setEndDate(toStartDayUTCTime(date));
                            }}
                            ranges={[
                                {
                                    label: 'Today',
                                    value: new Date()
                                },
                                {
                                    label: 'Tomorrow',
                                    value: new Date(new Date().setDate(new Date().getDate() + 1))
                                }
                            ]}
                            
                            disabledDate={(date) => {
                                const today = new Date();
                                today.setHours(0, 0, 0, 0);
                                return date < today;
                            }}

                            // mobile
                            inline={windowWidth < 600 && withDate}
                            oneTap={windowWidth < 600 && withDate}
                        />

                </FormGroup>
            </MyForm>
        </ModalSendApi>
    );
};