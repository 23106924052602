import React from 'react';
import { connect } from 'react-redux';
import { Select } from '../../index';
import CustomField from '../../Form/CustomField';

const WZ = ({worldzone_list, ...props}) => (
    <CustomField
        accepter={Select}
        labelKey="name"
        valueKey="wz_key"
        data={worldzone_list}
        placeholder="Worldzone"
        name="wz_key"
        classPrefix="allocated"
        cleanable
        {...props}
    />
)

const mapState = ( {references} )=> ({
    worldzone_list: references.worldzone_list
});

export default connect( mapState, {})(WZ);