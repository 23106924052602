import {defineMessages} from "react-intl";
import React from "react";

export default defineMessages({

    // a
    accept: {
        id: 'common.accept',
        defaultMessage: "Accept",
        description: 'common: Accept'
    },
    accessDestination: {
        id: 'common.accessDestination',
        defaultMessage: 'Access destination',
        description: 'common: Access destination'
    },
    accessOrigin: {
        id: 'common.accessOrigin',
        defaultMessage: 'Access origin',
        description: 'common: Access origin'
    },
    accessLimits: {
        id: 'common.accessLimits',
        defaultMessage: 'Access limits',
        description: 'common: Access Limits'
    },
    action: {
        id: 'common.action',
        defaultMessage: 'Action',
        description: 'common: Action'
    },
    active: {
        id: 'common.active',
        defaultMessage: 'Active',
        description: 'common: Active'
    },
    address: {
        id: 'common.address',
        defaultMessage: 'Address',
        description: 'common: Address'
    },
    allocate: {
        id: 'common.allocate',
        defaultMessage: 'Allocate',
        description: 'common: Allocate'
    },
    allocatedNumbers: {
        id: 'common.allocatedNumbers',
        defaultMessage: 'Allocated numbers',
        description: 'common: Allocated numbers'
    },
    allocatedNumbersAndRanges: {
        id: 'common.allocatedNumbersAndRanges',
        defaultMessage: 'Allocated numbers and ranges',
        description: 'common: Allocated numbers and ranges'
    },
    allocatedNumberList: {
        id: "common.allocatedNumberList",
        defaultMessage: "Allocated number list",
        description: 'Access, AccessNewNumbersModal: Allocated number list'
    },
    allDataIsCompletelyFetched: {
        id: "common.allDataIsCompletelyFetched",
        defaultMessage: "All data is completely fetched.",
        description: 'common: All data is completely fetched.'
    },
    amount: {
        id: 'common.amount',
        defaultMessage: 'Amount',
        description: 'common: Amount'
    },
    areYouSure: {
        id: 'common.areYouSure',
        defaultMessage: 'Are you sure?',
        description: 'common: Are you sure?'
    },
    availableNumbers: {
        id: 'common.availableNumbers',
        defaultMessage: 'Available numbers',
        description: 'common: Available numbers'
    },
    applyFilter: {
        id: 'common.applyFilter',
        defaultMessage: 'Apply filter',
        description: 'common: Apply filter'
    },

    // b
    bNumber: {
        id: 'common.bNumber',
        defaultMessage: 'B-number',
        description: 'common: B-number'
    },
    billingIncrement: {
        id: 'common.billingIncrement',
        defaultMessage: 'Billing increment',
        description: 'common: Billing increment'
    },
    byName: {
        id: 'common.byName',
        defaultMessage: 'by {name}',
        description: 'common: by {name}'
    },


    // c
    calls: {
        id: 'common.calls',
        defaultMessage: 'Calls',
        description: 'common: Calls'
    },
    cancel: {
        id: 'common.cancel',
        defaultMessage: 'Cancel',
        description: 'common: Cancel'
    },
    canceled: {
        id: 'common.canceled',
        defaultMessage: 'Canceled',
        description: 'common: Canceled'
    },
    city: {
        id: 'common.city',
        defaultMessage: 'City',
        description: 'common: City'
    },
    cli: {
        id: 'common.cli',
        defaultMessage: 'CLI',
        description: 'common: CLI'
    },
    close: {
        id: 'common.close',
        defaultMessage: 'Close',
        description: 'common: Close'
    },
    completed: {
        id: 'common.completed',
        defaultMessage: 'Completed',
        description: 'common: Completed'
    },
    confirm: {
        id: 'common.confirm',
        defaultMessage: 'Confirm',
        description: 'common: Confirm'
    },
    confirmation: {
        id: 'common.confirmation',
        defaultMessage: 'Confirmation',
        description: 'common: Confirmation'
    },
    confirmed: {
        id: 'common.confirmed',
        defaultMessage: 'Confirmed',
        description: 'common: Confirmed'
    },
    copied: {
        id: 'common.copied',
        defaultMessage: 'Copied!',
        description: 'common: Copied!'
    },
    copyJustNumbers: {
        id: 'common.copyJustNumbers',
        defaultMessage: 'Copy just numbers',
        description: 'common: Copy just numbers'
    },
    copyList: {
        id: 'common.copyList',
        defaultMessage: 'Copy list',
        description: 'common: Copy list'
    },
    copyListAllocatedByPrefix: {
        id: 'common.copyListAllocatedByPrefix',
        defaultMessage: 'Copy list allocated by prefix',
        description: 'common: Copy list allocated by prefix'
    },
    cost: {
        id: 'common.cost',
        defaultMessage: 'Cost',
        description: 'common: Cost'
    },
    country: {
        id: 'common.country',
        defaultMessage: 'Country',
        description: 'common: Country'
    },
    created: {
        id: 'common.created',
        defaultMessage: 'Created',
        description: 'common: Created'
    },
    currency: {
        id: 'common.currency',
        defaultMessage: 'Currency',
        description: 'common: Currency'
    },


    // d
    date: {
        id: 'common.date',
        defaultMessage: 'Date',
        description: 'common: Date'
    },
    dateFrom: {
        id: 'common.dateFrom',
        defaultMessage: 'Date from',
        description: 'common: Date from'
    },
    datetime: {
        id: 'common.datetime',
        defaultMessage: 'Datetime',
        description: 'common: Datetime'
    },
    declined: {
        id: 'common.declined',
        defaultMessage: 'Declined',
        description: 'common: Declined'
    },
    delete: {
        id: 'common.delete',
        defaultMessage: 'Delete',
        description: 'common: Delete'
    },
    description: {
        id: 'common.description',
        defaultMessage: 'Description',
        description: 'common: Description'
    },
    destination: {
        id: 'common.destination',
        defaultMessage: 'Destination',
        description: 'common: Destination'
    },
    destinationDoesNotExist: {
        id: 'common.destinationDoesNotExist',
        defaultMessage: 'destination does not exist',
        description: 'common: destination does not exist'
    },
    destinationFilter: {
        id: 'common.destinationFilter',
        defaultMessage: 'Access origin or number',
        description: 'common: Access origin or number'
    },
    destinationFilterMinCharacter: {
        id: 'common.destinationFilterMinCharacter',
        defaultMessage: 'Access origin or number (min 3 characters)',
        description: 'common: Access origin or number (min 3 characters)'
    },
    destinationFilterMinCharacterGan: {
        id: 'common.destinationFilterMinCharacterGan',
        defaultMessage: 'Access origin (min 3 characters)',
        description: 'common: Access origin (min 3 characters)'
    },
    customDestinationFilterMinCharacter: {
        id: 'common.customDestinationFilterMinCharacter',
        defaultMessage: 'Access {destination} or number (min 3 characters)',
        description: 'common: Access {destination} or number (min 3 characters)'
    },
    customDestinationOnlyFilterMinCharacter: {
        id: 'common.customDestinationOnlyFilterMinCharacter',
        defaultMessage: 'Access {destination} (min 3 characters)',
        description: 'common: Access {destination} (min 3 characters)'
    },
    destinationOrService: {
        id: 'common.destinationOrService',
        defaultMessage: 'Access origin, number or service',
        description: 'common: Access origin, number or service'
    },
    details: {
        id: 'common.details',
        defaultMessage: 'Details',
        description: 'common: Details'
    },
    dialerTraffic: {
        id: 'common.dialerTraffic',
        defaultMessage: 'Dialer traffic',
        description: 'common: Dialer traffic'
    },
    doYouWantToChooseRecordsOnAllPages: {
        id: 'common.doYouWantToChooseRecordsOnAllPages',
        defaultMessage: 'Do you want to choose records on all pages according to clause of filter?',
        description: 'common: Do you want to choose records on all pages according to clause of filter?'
    },
    download: {
        id: 'common.download',
        defaultMessage: 'Download',
        description: 'common: Download'
    },
    downloadAllocatedNumbersAs: {
        id: 'common.downloadAllocatedNumbersAs',
        defaultMessage: 'Download allocated numbers as {type}',
        description: 'common: Download allocated numbers as {type}'
    },
    downloadAllocatedOnlyNumbersAs: {
        id: 'common.downloadAllocatedOnlyNumbersAs',
        defaultMessage: 'Download only numbers as {type}',
        description: 'common: Download only numbers as {type}'
    },
    downloadCompleteNumberListAs: {
        id: 'common.downloadCompleteNumberListAs',
        defaultMessage: 'Download complete number list as {type}',
        description: 'common: Download complete number list as {type}'
    },
    downloadCompletePrefixesListAs: {
        id: 'common.downloadCompletePrefixesListAs',
        defaultMessage: 'Download complete prefixes list as {type}',
        description: 'common: Download complete prefixes list as {type}'
    },
    downloadList: {
        id: 'common.downloadList',
        defaultMessage: 'Download list',
        description: 'common: Download list'
    },
    downloadListNumbers: {
        id: 'common.downloadListNumbers',
        defaultMessage: 'Download new allocated numbers',
        description: 'common: Download new allocated numbers'
    },
    downloadListPrefixes: {
        id: 'common.downloadListPrefixes',
        defaultMessage: 'Download new allocated numbers as ranges',
        description: 'common: Download new allocated numbers as ranges'
    },
    downloadNewAllocatedNumbers: {
        id: 'common.downloadNewAllocatedNumbers',
        defaultMessage: 'Download new allocated numbers',
        description: 'common: Download new allocated numbers'
    },
    downloadNewAllocatedNumbersAsRanges: {
        id: 'common.downloadNewAllocatedNumbersAsRanges',
        defaultMessage: 'Download new allocated numbers as ranges',
        description: 'common: Download new allocated numbers as ranges'
    },
    downloadNumberListAs: {
        id: 'common.downloadNumberListAs',
        defaultMessage: 'Download number list as {type}',
        description: 'common: Download number list as {type}'
    },
    downloadOnlyNumbers: {
        id: 'common.downloadOnlyNumbers',
        defaultMessage: 'Download only numbers',
        description: 'common: Download only numbers'
    },
    downloadPrefixListAs: {
        id: 'common.downloadPrefixListAs',
        defaultMessage: 'Download prefix list as {type}',
        description: 'common: Download prefix list as {type}'
    },
    duration: {
        id: 'common.duration',
        defaultMessage: 'Duration',
        description: 'common: Duration'
    },


    enterFiltersToActivateTheButton: {
        id: "tooltip.enterFiltersToActivateTheButton",
        defaultMessage: "Enter filters to activate the button",
        description: 'tooltip: Enter filters to activate the button'
    },


    // f
    filterPerDestination: {
        id: 'common.filterPerDestination',
        defaultMessage: 'Filter per access destination',
        description: 'common: Filter per access destination'
    },
    filterPerDestinationOrService: {
        id: 'common.filterPerDestinationOrService',
        defaultMessage: 'Filter per access destination or service',
        description: 'common: Filter per access destination or service'
    },
    fromDate: {
        id: 'common.fromDate',
        defaultMessage: 'From Date',
        description: 'common: From Date'
    },


    // g
    getNumbers: {
        id: 'common.getNumbers',
        defaultMessage: 'Get numbers',
        description: 'common: Get numbers'
    },
    getNumbersForDestination: {
        id: 'common.getNumbersForDestination',
        defaultMessage: 'Get numbers for destination {name}',
        description: 'common: Get numbers for destination {name}'
    },
    getNumbersForRange: {
        id: 'common.getNumbersForRange',
        defaultMessage: 'Get numbers for range {name}',
        description: 'common: Get numbers for range {name}'
    },

    // h
    hide: {
        id: 'common.hide',
        defaultMessage: 'Hide',
        description: 'common: Hide'
    },
    hidePrefixes: {
        id: 'common.hidePrefixes',
        defaultMessage: 'Hide prefixes',
        description: 'common: Hide prefixes'
    },


    // l
    loadingData: {
        id: 'common.loadingData',
        defaultMessage: 'Loading data...',
        description: 'common: Loading data...'
    },
    last7Days: {
        id: 'common.last7Days',
        defaultMessage: 'Last 7 days',
        description: 'common: Last 7 days'
    },
    lastName: {
        id: 'common.lastName',
        defaultMessage: 'Last name',
        description: 'common: Last name'
    },


    // m
    managePaymentDetails: {
        id: 'common.managePaymentDetails',
        defaultMessage: 'Manage payment details',
        description: 'common: Manage payment details'
    },
    menu: {
        id: 'common.menu',
        defaultMessage: 'Menu',
        description: 'common: Menu'
    },
    minDuration: {
        id: 'common.minDuration',
        defaultMessage: 'Min. duration',
        description: 'common: Min. duration'
    },

    moreContacts: {
        id: 'common.moreContacts',
        defaultMessage: 'More contacts',
        description: 'common: More contacts'
    },
    contacts: {
        id: 'common.contacts',
        defaultMessage: 'Contacts',
        description: 'common: Contacts'
    },
    premiumyContacts: {
        id: 'common.premiumyContacts',
        defaultMessage: 'Premiumy contacts',
        description: 'common: Premiumy contacts'
    },
    officialWebpage: {
        id: 'common.officialWebpage',
        defaultMessage: 'Official webpage',
        description: 'common: Official webpage'
    },
    officialFacebookPage: {
        id: 'common.officialFacebookPage',
        defaultMessage: 'Official facebook page',
        description: 'common: Official facebook page'
    },
    officialLinkedInPage: {
        id: 'common.officialLinkedInPage',
        defaultMessage: 'Official linked in page',
        description: 'common: Official linked in page'
    },



    originZone: {
        id: "common.originZone", 
        defaultMessage: "Origin zone", 
        description: 'common: Origin zone'
    },
    originCountry: {
        id: "common.originCountry", 
        defaultMessage: "Origin country", 
        description: 'common: Origin country'
    },
    originOperator: {
        id: "common.originOperator", 
        defaultMessage: "Origin operator", 
        description: 'common: Origin operator'
    },
    originPrefix: {
        id: "common.originPrefix", 
        defaultMessage: "Origin Prefix", 
        description: 'common: Origin Prefix'
    },
    destinationZone: {
        id: "common.destinationZone", 
        defaultMessage: "Desctination zone", 
        description: 'common: Desctination zone'
    },
    destinationCountry: {
        id: "common.destinationCountry", 
        defaultMessage: "Destination country", 
        description: 'common: Destination country'
    },
    destinationOperator: {
        id: "common.destinationOperator", 
        defaultMessage: "Destination operator", 
        description: 'common: Destination operator'
    },
    minimalPayout: {
        id: "common.minimalPayout", 
        defaultMessage: "Minimal payout", 
        description: 'common: Minimal payout'
    },
    removeItem: {
        id: "common.removeItem", 
        defaultMessage: "Remove item", 
        description: 'common: Remove item'
    },



    englishSupport: {
        id: 'common.englishSupport',
        defaultMessage: 'English Support',
        description: 'common: English Support'
    },
    arabicSupport: {
        id: 'common.arabicSupport',
        defaultMessage: 'Arabic Support',
        description: 'common: Arabic Support'
    },
    skype: {
        id: 'common.skype',
        defaultMessage: 'Skype',
        description: 'common: Skype'
    },
    eMail: {
        id: 'common.eMail',
        defaultMessage: 'E-mail',
        description: 'common: E-mail'
    },


    // n
    name: {
        id: 'common.name',
        defaultMessage: 'Name',
        description: 'common: Name'
    },
    noContactData: {
        id: 'common.noContactData',
        defaultMessage: 'No contact data',
        description: 'common: No contact data'
    },
    noRateForThisDestination: {
        id: 'common.noRateForThisDestination',
        defaultMessage: 'no rate for this destination',
        description: 'common: no rate for this destination'
    },
    number: {
        id: 'common.number',
        defaultMessage: 'Number',
        description: 'common: Number'
    },
    numberIsQuarantined: {
        id: 'common.numberIsQuarantined',
        defaultMessage: 'number is quarantined',
        description: 'common: number is quarantined'
    },
    numberIsUnavailable: {
        id: 'common.numberIsUnavailable',
        defaultMessage: 'number is unavailable',
        description: 'common: number is unavailable'
    },
    numberList: {
        id: 'common.numberList',
        defaultMessage: 'Number list',
        description: 'common: Number list'
    },
    numbers: {
        id: 'common.numbers',
        defaultMessage: 'Numbers',
        description: 'common: Numbers'
    },
    numbersFromRangeCannotBeAllocated: {
        id: 'common.numbersFromRangeCannotBeAllocated',
        defaultMessage: 'numbers from this range cannot be allocated to this trunk',
        description: 'common: numbers from this range cannot be allocated to this trunk'
    },
    numbersFromRangeCannotBeAllocatedSingle: {
        id: 'common.numbersFromRangeCannotBeAllocatedSingle',
        defaultMessage: 'numbers from this range cannot be allocated to this trunk',
        description: 'common: numbers from this range cannot be allocated to this trunk'
    },


    // o
    onlyOnThisPage: {
        id: 'common.onlyOnThisPage',
        defaultMessage: 'No, only on this page',
        description: 'common: No, only on this page'
    },
    or: {
        id: 'common.or',
        defaultMessage: 'or',
        description: 'common: or'
    },
    origin: {
        id: 'common.origin',
        defaultMessage: 'Origin',
        description: 'common: Origin'
    },


    // p
    parameters: {
        id: 'common.parameters',
        defaultMessage: 'Parameters',
        description: 'common: Parameters'
    },
    passed: {
        id: 'common.passed',
        defaultMessage: 'Passed',
        description: 'common: Passed'
    },
    password: {
        id: 'common.password',
        defaultMessage: 'Password',
        description: 'common: Password'
    },
    paymentDetails: {
        id: 'common.paymentDetails',
        defaultMessage: 'Payment Details',
        description: 'common: Payment Details'
    },
    paymentInfo: {
        id: 'common.paymentInfo',
        defaultMessage: 'Payment Info',
        description: 'common: Payment Info'
    },
    payments: {
        id: 'common.payments',
        defaultMessage: 'Payments',
        description: 'common: Payments'
    },
    payout: {
        id: 'common.payout',
        defaultMessage: 'Payout',
        description: 'common: Payout'
    },
    payYourAttention: {
        id: "common.payYourAttention",
        defaultMessage: "Pay your attention that allocated numbers will be activated during 10 minutes after allocation. Don't dial these numbers before.",
        description: "Allocation Modals: Pay your attention that allocated numbers will be activated during 10 minutes after allocation. Don't dial these numbers before."
    },
    pending: {
        id: 'common.pending',
        defaultMessage: 'Pending',
        description: 'common: Pending'
    },
    phone: {
        id: 'common.phone',
        defaultMessage: 'Phone',
        description: 'common: Phone'
    },
    postalCode: {
        id: 'common.postalCode',
        defaultMessage: 'Postal code',
        description: 'common: Postal code'
    },
    prefix: {
        id: 'common.prefix',
        defaultMessage: 'Prefix',
        description: 'common: Prefix'
    },
    price: {
        id: 'common.price',
        defaultMessage: 'Price',
        description: 'common: Price'
    },


    // r
    randomOrder: {
        id: 'common.randomOrder',
        defaultMessage: 'Random order',
        description: 'common: Random order'
    },
    range: {
        id: 'common.range',
        defaultMessage: 'Range',
        description: 'common: Range'
    },
    rangeName: {
        id: 'common.rangeName',
        defaultMessage: 'Range name',
        description: 'common: Range name'
    },
    ranges: {
        id: 'common.ranges',
        defaultMessage: 'Ranges',
        description: 'common: Ranges'
    },
    rate: {
        id: 'common.rate',
        defaultMessage: 'Rate',
        description: 'common: Rate'
    },
    ratecard: {
        id: 'common.ratecard',
        defaultMessage: 'Ratecard',
        description: 'common: Ratecard'
    },
    readMore: {
        id: 'common.readMore',
        defaultMessage: 'Read More',
        description: 'common: Read More'
    },
    readyForPaymentInfo: {
        id: 'common.readyForPaymentInfo',
        defaultMessage: '{count} is available for payment now. You cannot request more than this amount',
        description: 'common: {count} is available for payment now. You cannot request more than this amount'
    },
    region: {
        id: 'common.region',
        defaultMessage: 'Region',
        description: 'common: Region'
    },
    rejected: {
        id: 'common.rejected',
        defaultMessage: 'Rejected',
        description: 'common: Rejected'
    },
    required: {
        id: 'common.required',
        defaultMessage: 'Required',
        description: 'common: Required'
    },
    revoke: {
        id: 'common.revoke',
        defaultMessage: 'Revoke',
        description: 'common: Revoke'
    },
    revokeCountNumbers: {
        id: 'common.revokeCountNumbers',
        defaultMessage: 'Revoke {count} items',
        description: 'common: Revoke {count} items'
    },
    rulesAndRegulationsWereUpdated: {
        id: 'common.rulesAndRegulationsWereUpdated',
        defaultMessage: 'Rules and regulations were updated. New version is below',
        description: 'common: Rules and regulations were updated. New version is below'
    },


    // s
    selectTrunk: {
        id: 'common.selectTrunk',
        defaultMessage: 'Select trunk',
        description: 'common: Select trunk'
    },
    service: {
        id: 'common.service',
        defaultMessage: 'Service',
        description: 'common: Service'
    },
    serviceName: {
        id: 'common.serviceName',
        defaultMessage: 'Service Name',
        description: 'common: Service Name'
    },
    show: {
        id: 'common.show',
        defaultMessage: 'Show',
        description: 'common: Show'
    },
    sipCause: {
        id: 'common.sipCause',
        defaultMessage: 'SIP cause',
        description: 'common: SIP cause'
    },
    status: {
        id: 'common.status',
        defaultMessage: 'Status',
        description: 'common: Status'
    },
    structure: {
        id: 'common.structure',
        defaultMessage: 'Structure',
        description: 'common: Structure'
    },
    subdestination: {
        id: 'common.subdestination',
        defaultMessage: 'Subdestination',
        description: 'common: Subdestination'
    },
    subdestinations: {
        id: 'common.subdestinations',
        defaultMessage: 'Subdestinations',
        description: 'common: Subdestinations'
    },
    submit: {
        id: 'common.submit',
        defaultMessage: 'Submit',
        description: 'common: Submit'
    },
    summary: {
        id: 'common.summary',
        defaultMessage: 'Summary',
        description: 'common: Summary'
    },


    // t
    taxVATNumber: {
        id: 'common.taxVATNumber',
        defaultMessage: 'Tax/VAT number',
        description: 'common: Tax/VAT number'
    },
    template: {
        id: 'common.template',
        defaultMessage: 'Template',
        description: 'common: Template'
    },
    termPoint: {
        id: 'common.termPoint',
        defaultMessage: 'Term point',
        description: 'common: Term point'
    },
    testNumber: {
        id: 'common.testNumber',
        defaultMessage: 'Test number',
        description: 'common: Test number'
    },
    testNumbers: {
        id: 'common.testNumbers',
        defaultMessage: 'Test numbers',
        description: 'common: Test numbers'
    },
    today: {
        id: 'common.today',
        defaultMessage: 'Today',
        description: 'common: Today'
    },
    trafficPeriod: {
        id: 'common.trafficPeriod',
        defaultMessage: 'Traffic period',
        description: 'common: Traffic period'
    },
    trunk: {
        id: 'common.trunk',
        defaultMessage: 'Trunk',
        description: 'common: Trunk'
    },
    type: {
        id: 'common.type',
        defaultMessage: 'Type',
        description: 'common: Type'
    },


    // u
    unableToCopyEmptyTable: {
        id: 'common.unableToCopyEmptyTable',
        defaultMessage: 'Unable to copy empty table',
        description: 'common: Unable to copy empty table'
    },
    useInTemplateRandomDigit: {
        id: 'common.useInTemplateRandomDigit',
        defaultMessage: 'Use in template digits or \'X\' (random digit).\n'+
            'Also is possible using set and ranges of digits like [7,9], [1-4] or[1-4,7,9].\n'+
            'Some examples:\n'+
            '123456789XX\n'+
            '123456789[0,2-5,9]X',
        description: 'common: Tempate helper (random digit)'
    },

    // y
    yes: {
        id: 'common.yes',
        defaultMessage: 'Yes',
        description: 'common: Yes'
    },
    yesterday: {
        id: 'common.yesterday',
        defaultMessage: 'Yesterday',
        description: 'common: Yesterday'
    },

    aNumber: {id: "common.aNumber", defaultMessage: "A-number", description: 'common: A-number'},
    getNumbersForIncorporatedGroup: {
        id: "common.getNumbersForIncorporatedGroup",
        defaultMessage: "Get numbers for incorporated group {group}",
        description: "common: Get numbers for incorporated group {group}"
    },
    getNumbersForGlobalAccessNumbersGroup: {
        id: "common.getNumbersForGlobalAccessNumbersGroup",
        defaultMessage: "Get numbers for global access numbers group {group}",
        description: "common: Get numbers for global access numbers group {group}"
    },
    voice: {
        id: "common.voice",
        defaultMessage: "Voice",
        description: "common: Voice"
    },
    sms: {
        id: "common.sms",
        defaultMessage: "SMS",
        description: "common: SMS"
    },
    weekly: {
        id: "common.weekly",
        defaultMessage: "Weekly",
        description: "common: Weekly"
    },
    monthly: {
        id: "common.monthly",
        defaultMessage: "Monthly",
        description: "common: Monthly"
    },
    newGeneratedPasswordHasBeenCopied: {
        id: "common.newGeneratedPasswordHasBeenCopied",
        defaultMessage: "New generated password has been copied",
        description: "common: New generated password has been copied"
    },
    pleaseEnterLegalCharacters: {
        id: "common.pleaseEnterLegalCharacters",
        defaultMessage: "Please enter legal characters",
        description: "common: Please enter legal characters"
    },
    open: {
        id: "common.open",
        defaultMessage: "Open",
        description: "common: Open"
    },
    no: {
        id: "common.no",
        defaultMessage: "No",
        description: "common: No"
    },
    daily: {
        id: "common.daily",
        defaultMessage: "Daily",
        description: "common: Daily"
    }
})