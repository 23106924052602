import React from "react";
import {Form, FormControl, InputGroup, FormGroup, DatePicker} from "rsuite";
import styled from "styled-components";
import DateTimeRangePicker from "components/DateRangePicker/DateTimerRangePicker";
import {debounce} from "utils";

export default ({
    filter,
    defaultFilter,
    onChangeFilter
}) => {
    const handleChangeDateTime = (value) => {
        const filterValue = {
            ...filter,
            range: [value.range[0], value.range[1]]
        }

        onChangeFilter(filterValue);
    };

    return (
        <StyledForm>
            <StyledFormGroup>
                <InputGroup inside>
                    <DateTimeRangePicker
                        onChange={debounce((value) => {
                            handleChangeDateTime(value);
                        }, 500)}
                        defaultStartDate={defaultFilter?.range[0]}
                        defaultEndDate={defaultFilter?.range[1]}
                        startDate={filter.range[0]}
                        endDate={filter.range[1]}
                        width={270}
                        // input={false}
                    />
                </InputGroup>
            </StyledFormGroup>
        </StyledForm>
    )
};

const StyledForm = styled(Form)`
    && {
        width: 100%;
        display: flex;
    }

    .rs-form-control-wrapper {
        width: auto;
        margin-right: 20px;
    }
`;


const StyledFormGroup = styled(FormGroup)`
    && {
        display: flex;
        align-items: center;

        width: 100%;

        .rs-control-label {
            margin-right: 10px;
            margin-bottom: 0px;
        }

        .rs-input-group.rs-input-group-inside .rs-input {
            padding-right: 11px;
        }
    }
`;