import {transformed_service_data} from '../const';

export const getSpecificValueFromSearch = (location, name) => {
    if (!location || !name) {
        return null;
    }

    const searchParams = new URLSearchParams(location.search);
    return searchParams ? searchParams.get(name) : null;
};

export const getSearchParams = (location) => {
    if (!location) {
        return;
    }

    return new URLSearchParams(location.search);
};

export const getServiceFromLocation = (location) => {
    if (!location) {
        return;
    }
    const searchParams = new URLSearchParams(location.search);
    return searchParams ? transformed_service_data[searchParams.get('service')] : null;
};