import React from 'react';
import Pagination from '../base/Pagination';
import {Button, ButtonToolbar, Checkbox, Notification} from 'rsuite';
import RenderedColumns from './RenderColumns';
import {ObjectSet, isFunction} from '../../utils';
import ShowColumnPicker from "components/Table/ShowColumnPicker";
import BaseTable, {Column, HeaderCell, Cell} from "../base/BaseTable";
import { compareTwoArrays } from '../../utils';
import {injectIntl} from 'react-intl';

import m from "definedMessages";

/**
 * Для правильной работы выбора колонок в таблицу нужно передать columnSelectorLSKey
 * это и будет являться ключём для храрения выбраных колонок в LocalStorage
 */
 export class MyTable extends React.Component {

    constructor(props) {
        super(props);

        this.selectedlist = this.props.isSetObject
            ? new ObjectSet()
            : new Set();

        const allColumnsKeys = props.originalColumns
            // .filter(column => column.pickable)
            .map(column => column.dataKey);

        let columnsKeys = props.originalColumns
            .filter(column => !column.hideDefault)// && column.pickable)
            .map(column => column.dataKey);

        if (props.columnSelectorLSKey) {
            //Get saved columns to show from Local Storage

            columnsKeys = localStorage.getItem(props.columnSelectorLSKey)
                ? JSON.parse(localStorage.getItem(props.columnSelectorLSKey))
                : columnsKeys;
        }

        this.formatMessage = props.intl.formatMessage.bind(props.intl);

        this.state = {
            selectAll: false,
            columns: [],
            isSelectColumnsDropdownOpen: false,
            showColumnsKeys: columnsKeys || allColumnsKeys,
        };
    }

    // configureColumns () {
    //     const { columnSelectorLSKey, isChangedColumns } = this.props;

    //     // implement isChangedColumns to this logic
    
    //     let columns = [...this.props.columns];
    //     const showColumnsKeys = this.state.showColumnsKeys;
    
        
    //     let columnsKeys = columns
    //         .filter(column => !column.hideDefault)
    //         .map(column => column.dataKey);

    //     let currentColumnsKeys = showColumnsKeys;

    //     if (!compareTwoArrays(showColumnsKeys, columnsKeys)) {
    //         currentColumnsKeys = columnsKeys;
    //     }

    //     if (columnSelectorLSKey) {
    //         //Set saved
    //         localStorage.setItem(columnSelectorLSKey, JSON.stringify(showColumnsKeys));

    //         columns = columns.filter((column) => showColumnsKeys.includes(column.dataKey));

    //         if (columns.length) {
    //             columns[0].headerProps = {className:`rs-table-cell-header-with-icon`};
    //         }
    //     }

    //     this.setState({
    //         columns,
    //         // showColumnsKeys: currentColumnsKeys
    //     });
        
    // }

    configureColumns () {
        let columns = [...this.props.originalColumns];
        const showColumnsKeys = this.state.showColumnsKeys;
        const {columnSelectorLSKey} = this.props;

        if (columnSelectorLSKey) {
            //Set saved
            localStorage.setItem(columnSelectorLSKey, JSON.stringify(showColumnsKeys));

            columns = this.filterColumns(showColumnsKeys);

            if (columns.length) {
                columns[0].headerProps = {className:`rs-table-cell-header-with-icon`};
            }
        }

        this.setState({columns});
    }

    filterColumns = (columnKeys, update = false) => {
        const {originalColumns} = this.props;

        const columns = originalColumns.filter(column => columnKeys.includes(column.dataKey));
        
        if (update) {
            this.setState({columns});
        }
        
        return columns;
    };

    clear = () => {
        const {isselected} = this.props;
        const {selectAll} = this.state;

        if(isselected) {
            this.selectedlist.clear();
            
            if(selectAll) {
                this.setState({selectAll: false}, this.setSelectParent)
            } else {
                this.setSelectParent()
            }
        }
    };

    shouldComponentUpdate({data, stateChecked}) {
        if(stateChecked && data !== this.props.data) {
            data.map(row => {
                return (row.checked) && ( this.selectedlist.add( this.gettingSelectItem(row) ) )
            })
        }
        return true;
    }

    componentDidMount() {
        this.configureColumns();
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     //Uncheck checkboxes when data in table changes
    //     if (this.props.service !== prevProps.service && this.props.isChangedColumns) {
    //         const columns = this.props.columns
    //             .filter(column => !column.hideDefault)
    //             .map(column => column.dataKey);

    //         this.setState({showColumnsKeys: columns});
    //     }

    //     if (this.props.count && prevProps.count !== this.props.count) {
    //         this.onToggleAll(false);
    //     }

    //     if (this.state.showColumnsKeys !== prevState.showColumnsKeys && this.props.isChangedColumns) {
    //         this.configureColumns();
    //     }

    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //Uncheck checkboxes when data in table changes
        if (this.props.count && prevProps.count !== this.props.count) {
            this.onToggleAll(false);
        }

        // if (this.props.columns !== prevProps.columns
        //     || this.state.showColumnsKeys !== prevState.showColumnsKeys) {
        //     this.configureColumns();
        // }

        const allColumnsKeys = this.props.originalColumns.map(column => column.dataKey);

        if (this.props.additionalColumnUpdate !== prevProps.additionalColumnUpdate) {
            this.configureColumns();
            this.setState({
                showColumnsKeys: allColumnsKeys
            });
            this.filterColumns(allColumnsKeys, true);
        }

        if (this.props.service !== prevProps.service) {
            localStorage.removeItem(this.props.columnSelectorLSKey)
        }

        if (!compareTwoArrays(allColumnsKeys, this.state.showColumnsKeys) && !localStorage.getItem(this.props.columnSelectorLSKey)) {
            this.setState({
                showColumnsKeys: allColumnsKeys
            });
            this.filterColumns(allColumnsKeys, true);
            // localStorage.removeItem(this.props.columnSelectorLSKey)
        }

        if (!compareTwoArrays(this.state.showColumnsKeys, prevState.showColumnsKeys) && localStorage.getItem(this.props.columnSelectorLSKey)) {
            localStorage.setItem(this.props.columnSelectorLSKey, JSON.stringify(this.state.showColumnsKeys));
            this.filterColumns(this.state.showColumnsKeys, true);
        }

        if (this.props.extraColumnUpdate !== undefined && this.props.extraColumnUpdate !== prevProps.extraColumnUpdate) {
            this.filterColumns(this.state.showColumnsKeys, true);
        }

        if (!compareTwoArrays(this.props.columns, this.state.columns) &&
            compareTwoArrays(this.props.columns, this.props.originalColumns) &&
            !this.props.columnSelectorLSKey
        ) {
            this.setState({
                columns: this.props.columns
            });
        }
        if (!compareTwoArrays(this.props.originalColumns, prevProps.originalColumns)) {
            this.setState({
                showColumnsKeys: allColumnsKeys
            });
        }

        if (this.props.clearSelected) {
            this.clear();
        }
    }

    onChangePage = (page) => {
        const { customChangePage = false} = this.props;

        if (!customChangePage) {
            this.props.getItems(page, this.props.per_page);
        }

        if (this.props.onChangePage)
            this.props.onChangePage(page);
    };

    onChangePerPage = (per_page) => {
        const { customChangePerPage = false} = this.props;

        if (!customChangePerPage) {
            this.props.getItems(1, per_page);
        }

        if (this.props.onChangePerPage)
            this.props.onChangePerPage(per_page);
    };

    setSelectParent = () => {
        const {setSelected} = this.props;
        const {selectAll} = this.state;

        if (!setSelected) return;

        setSelected({
            list: Array.from(this.selectedlist.values()),
            all: selectAll
        })
    };

    onToggle = (id, checked) => {
        const {useSelectedData, data = [], row_key = 'id', onCheckPrevented} = this.props;

        if (checked) {
            this.selectedlist.add(id);
        } else {
            this.selectedlist.delete(id);
        }

        if (useSelectedData) {
            const checkedData = data.filter(item => item[row_key] === id);
            useSelectedData(checkedData, checked)
        }

        if (checked && onCheckPrevented) {
            const checkedItem = data.find(item => item.id === id);
            if (checkedItem.preventCheck)
                onCheckPrevented();
        }

        this.setSelectParent();
        this.forceUpdate();
    };

    onToggleAll = (checked) => {
        const {selectAll} = this.state;
        const {data, ispagination, isSelectedAll, useSelectedData} = this.props;

        if (!checked) {
            speaker(Notification.close("allocatedNumberChooser"), null, this.formatMessage);
        }





        if (isSelectedAll && checked && !selectAll && ispagination) {
            Notification.open({
                duration: 0,
                description: [
                    <div className="mb-2">{this.formatMessage(m.doYouWantToChooseRecordsOnAllPages)}</div>,
                    <div className="text-right">{
                        speaker(
                            () => {
                                this.selectedlist.clear();
                                this.setState({selectAll: true}, this.setSelectParent);
                                Notification.closeAll();
                            },
                            () => {
                                Notification.closeAll();
                            }, 
                            this.formatMessage
                        )
                    }</div>
                ],
                key: 'allocatedNumberChooser'
            });
        } else if (!checked && selectAll) {
            this.setState({selectAll: false}, this.setSelectParent);
        }

        for (let row of data) {
            (checked && !row.preventCheck)
            ? this.selectedlist.add(this.gettingSelectItem(row))
            : this.selectedlist.delete(this.gettingSelectItem(row))
        }

        if (useSelectedData) {
            useSelectedData(data, checked)
        }
        
        this.setSelectParent();
        this.forceUpdate();
    };

    getHeight = () => {
        const { data = [], height, customHeight, rowHeight}  = this.props;
        const heightValues = ((data.length * (rowHeight || 30)) || 100) + 50;

        switch (height) {
            case '75%':
                const h75 = (window.innerHeight/1.33);
                return Math.min(h75,heightValues);
            case '50%':
                const h50 = (window.innerHeight/2);
                return Math.min(h50,heightValues);
            case '30%':
                const h30 = (window.innerHeight/3);
                return Math.min(h30,heightValues);
            case '25%':
                const h25 = (window.innerHeight/4);
                return Math.min(h25,heightValues);
            default:
                return customHeight ? customHeight : heightValues;
        }
    };

    gettingSelectItem = (row) => {
        const { isSetObject, row_key = 'id' } = this.props;
        return isSetObject ? row : row[row_key];
    };

    render () {
        const {
            data = [], loading, isselected, row_key = 'id', active_id,
            ispagination, count, page, per_page, onRowClick, customRowClick, isMobile, perPageMaximum, showFirstAndLast,
            width, ActionCell, hidePerPage, showedCount, disabled, columns, getItems, height, extraHeight, 
            hideTotalCount, resizable=false, ...props
        } = this.props;

        const {selectAll} = this.state;
        const _renderedColumns = RenderedColumns(this.state.columns, null, isMobile, resizable);

        const _columns = _renderedColumns;

        const propTables = {...props};

        if (onRowClick)
            propTables.onRowClick = (row) => {
                if (customRowClick) {
                    return onRowClick(row);
                }

                return onRowClick(row[row_key]);
            }
        if (active_id) {
            propTables.rowClassName = (row) => {
                let rowClass = row && row[row_key] === active_id ? 'row-active' : null;
                if (!rowClass && props.rowClassName) {
                    rowClass = isFunction(props.rowClassName)
                        ? props.rowClassName(row)
                        : props.rowClassName;
                }
                return rowClass;
            }
        }

        if (width)
            propTables.width = width;

        if (!ispagination)
            propTables.virtualized = true;

        if (this.state.showColumnsKeys && !localStorage.getItem(this.props.columnSelectorLSKey)) {
            localStorage.setItem(this.props.columnSelectorLSKey, JSON.stringify(this.state.showColumnsKeys));
        }
        
        return  (
            <div className="position-relative">
                {this.props.columnSelectorLSKey && !this.props.noSelector &&
                    <ShowColumnPicker
                        value={this.state.showColumnsKeys}
                        columns={this.props.originalColumns}
                        onChange={(value) => {
                            if (!!this.props.onPickColumns) {
                                this.props.onPickColumns(value);
                            }
                            this.setState({showColumnsKeys: value});
                        }}
                        loading={loading}
                    />
                }
                <BaseTable
                    height={ extraHeight ? extraHeight : this.getHeight()}
                    data={data}
                    loading={loading}
                    rowHeight={30}
                    columnSelector={!!this.props.columnSelectorLSKey}
                    {...propTables}

                >
                    {_columns}
                    {ActionCell}

                    {isselected &&
                        <Column width={50}>
                            <HeaderCell>
                                {data.length
                                    ? <Checkbox
                                        onChange={(v,checked) => this.onToggleAll(checked)}
                                        checked = {selectAll || data.every(
                                            row => this.selectedlist.has( this.gettingSelectItem(row) )
                                        )}
                                        style={{position:'relative', top:'-5px'}}
                                    />
                                    : ''
                                    }
                            </HeaderCell>
                            <Cell>
                                {(row) => (
                                    <Checkbox
                                        disabled = {selectAll || row.disabled}
                                        checked = {row.checked || this.selectedlist.has( this.gettingSelectItem(row) ) || selectAll}
                                        
                                        onChange={(v,checked) => this.onToggle( this.gettingSelectItem(row), checked )}
                                        style={{position:'relative', top:'-5px'}}
                                    />
                                )}
                            </Cell>
                        </Column>
                    }

                    <Column key="table_scrollfix" width={0} fixed>
                        <HeaderCell></HeaderCell>
                        <Cell></Cell>
                    </Column>
                </BaseTable>

                {ispagination &&
                    <Pagination
                        disabled = {disabled}
                        total = {count}
                        per_page = {per_page}
                        activePage = {page}
                        hidePerPage = {hidePerPage}
                        showedCount={showedCount}
                        perPageMaximum={perPageMaximum}
                        showFirstAndLast={showFirstAndLast}
                        onChangePage = {this.onChangePage}
                        onChangePerPage  = {this.onChangePerPage}
                    />
                }

            </div>
        );
             
    }
}


export default injectIntl(MyTable, {forwardRef: true});

const speaker = (Ok, No, formatMessage) =>  (
    <div>
        <ButtonToolbar>
          <Button onClick ={Ok}>{formatMessage(m.yes)}</Button>
          <Button onClick ={No} >{formatMessage(m.onlyOnThisPage)}</Button>
        </ButtonToolbar>
      </div>
);