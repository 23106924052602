import React from 'react';
import PageHeader from '../../components/base/PageHeader';
import CLIFilters from './CLIFilters';
import CLITable from './CLITable';
import {LOCAL_STORAGE_CLI_FILTERS_DIALER} from '../../const';
import {injectIntl} from "react-intl";
import {Spacer} from '../../components/base/Spacer';
import m from "../../definedMessages";
import Page from "../../components/base/Page";
import {BaseToggle} from "../../components/base/BaseToggle";
import {getServiceByLocation} from "../../utils/helpers";

class CLI extends React.Component {
    constructor(props) {
        super(props);
        
        this.defaultFilter = {str: ""};
        const savedTableFilter = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CLI_FILTERS_DIALER));
        
        this.formatMessage = props.intl.formatMessage.bind(props.intl);

        this.state = {
            filter: {
                ...this.defaultFilter,
                ...(savedTableFilter || {})
            },
            tableData: props.CLIList || [],
            isTest: false,
            isAuth: false,
            searchLoading: false,
            resizedWidth: window.innerWidth
        };
    }


    componentDidMount() {
        const {filter, isAuth} = this.state;
        const {getCLIList, location, service} = this.props;

        if (filter && filter.str) {
            let currentService = service;

            if (service === null) {
                currentService = getServiceByLocation(location);
            }

            getCLIList(currentService, filter, isAuth);
        }

        window.addEventListener('resize', this.handleResize);
    }


    componentDidUpdate(prevProps, prevState) {
        const {filter, isAuth} = this.state;
        const {getCLIList, CLIList, dialerInfo, service} = this.props;
        
        if (isAuth !== prevState.isAuth  && filter.str) {
            getCLIList(service, filter, isAuth);
        }


        if (prevProps.service !== null && service !== prevProps.service) {
            if (filter.str) {
                this.setState({
                    filter: this.defaultFilter
                });
            }

            this.setState({
                tableData: []
            });
        }
        
        if (dialerInfo !== prevProps.dialerInfo) {
            const isTest = 'is_test' in dialerInfo ? dialerInfo.is_test : false;

            this.setState({isTest});
        }

        if (CLIList.length !== prevProps.CLIList.length) {
            this.setState({
                tableData: CLIList
            });
        }
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    onChangeFilters = (filtersObj) => {
        const {isAuth} = this.state;
        const {getCLIList, service} = this.props;

        localStorage.setItem(LOCAL_STORAGE_CLI_FILTERS_DIALER, JSON.stringify(filtersObj));
        this.setState({
            filter: {...filtersObj}
        });

        getCLIList(service, filtersObj, isAuth);
    };


    changeTab = (value) => {
        this.setState({isAuth: value});
    }


    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth})
    };


    render() {
        const {searchLoading, tableData, filter, isAuth} = this.state;
        const {CLIList, CLILoading, service, count} = this.props;

        const onChangeFilters = this.onChangeFilters;

        return (
            <Page>
                <PageHeader title={this.formatMessage(m.cliLookup)}/>
                
                <BaseToggle
                    size="lg"
                    unCheckedChildren={this.formatMessage(m.dialerTraffic)}
                    checkedChildren={this.formatMessage(m.otp)}
                    defaultChecked={isAuth}
                    onChange={value => this.changeTab(value)}
                />
                
                <Spacer size={30}/>
                
                <CLIFilters
                    onChangeFilter={onChangeFilters}
                    loading={CLILoading || searchLoading}
                    savedTableFilter={filter}
                    defaultFilter={this.defaultFilter}
                    service={service}
                    spAuth={isAuth}
                />
                
                <Spacer/>
                
                <CLITable
                    data={tableData}
                    filter={filter}
                    spAuth={isAuth}
                    service={service}
                    loading={CLILoading || searchLoading} 
                    count={count}
                />
            </Page>
        );

    }

}

export default injectIntl(CLI)