import {connect} from 'react-redux';
import {
    getSupplierAccountTrunk, setCurrentTrunkId, setCurrentRangeNumber,
    setAllocationRestrictions, setEmptyAccountTrunk
} from '../../../actions/accounts';
import {
    getSupplierPrefixes,
    getSupplierNumbers,
    getSuppliers
} from '../../../actions/suppliers';
import {getAccountServicePlanList} from "../../../actions/self_allocation_restrictions";
import {getTrunkNumberCsvById} from '../../../actions/numbers';
import TabGanTrunks from './TabGanTrunks';
import {trunkDownloadNumbers, unsetTrunksInited} from '../../../actions';
import {groupDownloadNumbers} from '../../../actions/suppliers';
import {
    getSupplierPlanList, 
    createSupplierPlan,
    modifySupplierPlan,
    removeSupplierPlan,
    createTrafficLimit,
    modifyTrafficLimit,
    removeTrafficLimit,
    getSupplierGroups,
    setEmptyPrefixes
} from '../../../actions/suppliers';
import {showReasonHandler} from "../../../actions/reason_handler";



const mapState = ({ auth, accounts, suppliers, references, self_allocation_restrictions}) => ({
    authInfo: auth.authInfo,
    permissions: auth.permissions,
    account: accounts.accountView,
    currentSupplier: suppliers.currentSupplier,
    trunks: accounts.accountSupplierGanTrunks,
    trunksInited: accounts.trunksInited,
    trunksLoading: accounts.accountTrunkLoading,
    ipOptionList: references["sms.supplier_trunk_ip.option_list"],
    allocatedNumbers: accounts.accountAllocated,
    allocatedNumbersCount: accounts.accountAllocatedCount,
    allocatedNumbersTotalCount: accounts.accountAllocatedNumbersTotalCount,
    allocatedNumbersPage: accounts.allocatedNumbersPage,
    allocatedNumbersPerPage: accounts.allocatedNumbersPerPage,
    allocatedNumbersLoading: accounts.accountAllocatedLoading,
    worldzone_list: references.worldzone_list,
    subdestination_list: references.subdestination_list,
    destination_list: references.destination_list,
    currentTrunkId: accounts.currentTrunkId,
    currentRangeNumber: accounts.currentRangeNumber,
    accountServicePlanList: self_allocation_restrictions.list,
    raterc_list: references.raterc_list,
    currencyList: references.currency_list,
    paymentTermsList: references.payment_terms_list,
    rerouteStatusList: references['sms.reroute_status_list'],
    supplierList: suppliers.items,
    suppliersPlanList: suppliers.suppliersPlanList,
    suppliersPrefixList: suppliers.suppliersPrefixList,
    suppliersPrefixListСount: suppliers.suppliersPrefixListCount,
    suppliersPrefixListPage: suppliers.suppliersPrefixListPage,
    suppliersPrefixListPerPage: suppliers.suppliersPrefixListPerPage,
    suppliersPrefixLoading: suppliers.suppliersPrefixLoading,
    suppliersGroupList: suppliers.suppliersGroupList
});


export default connect(mapState, {
    setCurrentTrunkId,
    unsetTrunksInited,
    setCurrentRangeNumber,
    getAccountTrunk: getSupplierAccountTrunk,
    setEmptyAccountTrunk,
    getSupplierPrefixes: getSupplierNumbers,
    getSupplierGroups,
    
    getSupplierPlanList,
    getSuppliers,

    createSupplierPlan,
    modifySupplierPlan,
    removeSupplierPlan,

    createTrafficLimit,
    modifyTrafficLimit,
    removeTrafficLimit,

    setEmptyPrefixes,

    showReasonHandler,
    getTrunkNumberCsvById,
    getAccountServicePlanList,
    trunkDownloadNumbers,
    groupDownloadNumbers
})(TabGanTrunks);
  
