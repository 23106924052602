import {
    PAYMENT_DETAIL as PREFIX,
} from "../const";

import {
    PAYMENT_DETAIL_LIST_API,
    PAYMENT_DETAIL_GET_API,
    PAYMENT_DETAIL_CREATE_API,
    PAYMENT_DETAIL_MODIFY_API,
    PAYMENT_DETAIL_REMOVE_API,
    PAYMENT_DETAIL_CONFIRM_API,
    PAYMENT_DETAIL_CRYPTO_LIST_API,
    PAYMENT_DETAIL_CONFIRM_STATUS_API
} from "../const/apiMethods";

import {createAction} from './defaults';
import {api, apiWithHeaders} from "../api/loginRoutes";
import Fingerprint2 from 'fingerprintjs2';

const setLoading = createAction('SET_LOADING_' + PREFIX);
const setPaymentDetailsList = createAction('SET_ITEMS_' + PREFIX);
const setPaymentDetailsCryptoList = createAction('SET_ITEMS_CRYPTO_' + PREFIX);
const setConfirmPayment = createAction(PAYMENT_DETAIL_CONFIRM_API);
const setConfirmStatus = createAction(PAYMENT_DETAIL_CONFIRM_STATUS_API);

// get list
export const getPaymentDetailsList = (data = {}) => (dispatch) => {
    dispatch(setLoading(true));
    api(PAYMENT_DETAIL_LIST_API, data)
        .then((response) => {
            if (response !== undefined) {
                dispatch(setPaymentDetailsList({
                    items: response.payment_detail_list || []
                }));
            }
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
};

// get single
export const getPaymentDetailsSingle = (data) => async (dispatch) => {
    const response = await api(PAYMENT_DETAIL_GET_API, data);
    return response;
};

// create new
export const createPaymentDetails = (data) => async (dispatch) => {
    const response = await api(PAYMENT_DETAIL_CREATE_API, data);
    return response;
};

// modify single
export const modifyPaymentDetails = (data) => async (dispatch) => {
    const response = await api(PAYMENT_DETAIL_MODIFY_API, data);
    return response;
};

// remove single
export const removePaymentDetails = (data) => async (dispatch) => {
    const response = await api(PAYMENT_DETAIL_REMOVE_API, data);
    return response;
};

// confirm
export const confirmPaymentDetails = (token) => (dispatch) => {
    dispatch(setLoading(true));
    Fingerprint2.get({
        preprocessor: ((key, value) => {
            return value
        })
    }, ((components) => {
        const convertArrayToObject = (array, key) => {
            const initialValue = {};
            return array.reduce((obj, item) => {
                return {
                    ...obj,
                    [item[key]]: item,
                };
            }, initialValue);
        };
        const objectUserData = convertArrayToObject(components, 'key');

        apiWithHeaders(PAYMENT_DETAIL_CONFIRM_API, {token, user_data: objectUserData})
            .then((response) => {

                if (response !== undefined) {
                    dispatch(setConfirmPayment({
                        items: response.payment_detail || []
                    }));
                    dispatch(setConfirmStatus({
                        status: response.confirm_status
                    }))
                }
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    }));

};

export const getPaymentDetailsCryptoList = () => (dispatch) => {
    api(PAYMENT_DETAIL_CRYPTO_LIST_API, {})
    .then((response) => {
        if (response !== undefined) {
            dispatch(setPaymentDetailsCryptoList({
                items: response.crypto_currency_list || []
            }));
        }
    });
};