import React, {useCallback, useState} from "react";
import {Icon, IconButton, List, Loader, Placeholder} from "rsuite";
import styled from "styled-components";
import moment from "moment";
import ModalNoteDeactivate from "./ModalNoteDeactivate";

const { Paragraph } = Placeholder;

export default ({
    commentTypes = [],
    notesList,
    accountId,
    isFetched,
    removeAccountNote,
    getAccountNoteList,
}) => {


    const [deactivationNoteId, setDeactivationNoteId] = useState(null);
    const [showDeactivationNoteModal, setShowDeactivationNoteModal] = useState(false);
    const [deactivationNoteLoading, setDeactivationNoteLoading] = useState(null);


    const handleRemoveNote = useCallback(() => {
        setDeactivationNoteLoading(true);
        removeAccountNote(accountId, deactivationNoteId).then(() => {
            getAccountNoteList(accountId);
            setDeactivationNoteLoading(false);
            setShowDeactivationNoteModal(false);
        });
    }, [accountId, deactivationNoteId]);

    const showModal = useCallback((id) => {
        setDeactivationNoteId(id);
        setShowDeactivationNoteModal(true);
    }, []);

    const hideModal = useCallback(() => {
        setShowDeactivationNoteModal(false);
    }, []);

    if (!isFetched)
        return (
            <div style={{position: "relative"}}>
                <Paragraph rows={8}></Paragraph>
                <Loader center content="loading" />
            </div>
        );

    return (
        <>
            <List hover>
                {notesList.map((noteProps, index) => {
                    const currentType = commentTypes[noteProps.type] || {};
                    return <List.Item key={noteProps.id} index={index}>
                        <Note
                            icon={currentType.icon}
                            color={currentType.color}
                            onRemove={showModal}
                            {...noteProps}
                        />
                    </List.Item>
                })}
            </List>
            <ModalNoteDeactivate
                show={showDeactivationNoteModal}
                onSubmit={handleRemoveNote}
                onClose={hideModal}
                disabled={deactivationNoteLoading}
            />
        </>
    )
};


const Note = ({accn_key, active, text, datetime, author, color, icon, onRemove}) => {
    return <NoteBody className={`note${!active ? ' note--disabled' : ''}`}>
        <div className="note__part note__type">
            <Icon
                icon={icon}
                style={{color: color}}
                size="lg"
            />
        </div>
        <div className="note__part">{moment(new Date(datetime)).format("MM.DD.YYYY, hh:mm")}</div>
        <div className="note__part"><b>{author || 'no author'}:</b></div>
        <p className="note__part note__part---shrink">{text}</p>
        {active &&
            <IconButton
                title="Deactivate note"
                icon={<Icon icon="close-circle" size="md"/>}
                className="note__close"
                appearance="link"
                onClick={() => onRemove(accn_key)}

            />
        }
    </NoteBody>
};


const NoteBody = styled.div`
    display: flex;
    padding: 0 40px 0 5px;
    position: relative;
    
    &.note--disabled {
        opacity: 0.6;
    }
    
    .note__type {
        width: 35px;
    }
    
    && .note__close {
        position: absolute;
        color: #f44336;
        margin-top: -8px;
        right: 0;
        top: 0;
    }
    
    .note__part {
        flex-shrink: 0;
        padding: 0 5px
    }
    
    .note__part---shrink {
        flex-shrink: 1;
    }
`;