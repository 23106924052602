import React from 'react';
import styled, {css} from 'styled-components';
import {useWindowWidth} from "../../hooks";
import {servicePick} from "../../utils";
import {Header as SectionHeader} from "./Trunks/styled";
import PageTabs from "../../components/PageTabs";
import {checkPermissionsFor} from 'store/storeHelpers';
import {SMS_SUPPLIER_MODIFY_METHOD, ACCOUNT_MODIFY_METHOD, PAYMENT_REQUEST_LIST_API, ACCOUNT_GET_DIALER_API, 
    TRUNK_LIST_API, TRUNK_LIST_SMS_API, SMS_SUPPLIER_TRUNK_GET_LIST_METHOD,
    SMS_SUPPLIER_PRICE_GET_LIST_METHOD, ACCOUNT_PRICE_GET_LIST_API,
    ACCESS_LIST_FILTER_GET_LIST_API, SMS_SUPPLIER_PLAN_GET_LIST_METHOD,
    TRAFFIC_LIMITS_LIST_API, SMS_TRAFFIC_LIMITS_LIST_API,
    POSTBACK_LOGS_GET_LIST_API, ACCOUNT_SERVICE_PLAN_LIST_API,
    ACCOUNT_NOTE_GET_LIST_API, ACCOUNT_CHANGES_LIST_API
} from '../../const/apiMethods'
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import {API_PAGE_LIST, transformed_service_reverse_data} from '../../const'


export default ({
    service,
    activeTab,
    onSelect,
    serviceCompared,
    accountByService,

    account = {},
    role_list = [],
    isSupplier = false,
    accountJoinList = [],
    billingServiceList = []
}) => {

    const windowWidth = useWindowWidth();

    const {
        billing,
        account_manager_name,
        currency_name,
        payment_terms_name,
    } = accountByService;

    const ratemodRole = (role_list.includes('Ratemod') || role_list.includes('Ratemod (sms)'));
    const isRatemod = role_list && role_list.length ? ratemodRole: null;

    const menuItems = [
        ...(account.id
            ? [{
                id: "general", eventKey: "general", title: "General",
                checkPermission: isRatemod, checkedMethods: [isSupplier ? SMS_SUPPLIER_MODIFY_METHOD : ACCOUNT_MODIFY_METHOD]
            }]
            : []
        ),
        {
            id: "trunks", eventKey: "trunks", title: "Trunks", disabled: !serviceCompared, hide: isSupplier, 
            checkPermission: isRatemod, checkedMethods: [servicePick(service, TRUNK_LIST_API, TRUNK_LIST_SMS_API)]
        },
        {
            id: "gan_trunks", eventKey: "gan_trunks", title: "GAN Trunks",  disabled: !serviceCompared, hide: !isSupplier,
            checkPermission: isRatemod, checkedMethods: [SMS_SUPPLIER_TRUNK_GET_LIST_METHOD]
        },
        {id: "transit_trunks", eventKey: "transit_trunks", title: "Transit Trunks",  disabled: !serviceCompared, hide: !isSupplier,
            checkPermission: isRatemod, checkedMethods: [SMS_SUPPLIER_TRUNK_GET_LIST_METHOD]
        },
        {id: "rates", eventKey: "rates", title: "Rates", disabled: !serviceCompared, hide: isSupplier,
            checkPermission: false, checkedMethods: [ACCOUNT_PRICE_GET_LIST_API]
        },
        {id: "transit_rates", eventKey: "transit_rates", title: "Transit Rates", hide: !isSupplier,
            checkPermission: isRatemod, checkedMethods: [SMS_SUPPLIER_PRICE_GET_LIST_METHOD]
        },
        {id: "access", eventKey: "access", title: "Access List Filters", disabled: !serviceCompared, hide: isSupplier,
            checkPermission: false, checkedMethods: [ACCESS_LIST_FILTER_GET_LIST_API]
        },
        {id: "supplier_access", eventKey: "supplier_access", title: "Access List", hide: !isSupplier,
            checkPermission: isRatemod, checkedMethods: [SMS_SUPPLIER_PLAN_GET_LIST_METHOD]
        },
        {id: "traffic-limits", eventKey: "traffic-limits", title: servicePick(service, "Duration Limits", "Traffic Limits"), disabled: !serviceCompared, hide: !service && !isSupplier,
            checkPermission: isRatemod, checkedMethods: [servicePick(service, TRAFFIC_LIMITS_LIST_API, SMS_TRAFFIC_LIMITS_LIST_API)]
        },
        {id: "postback-logs", eventKey: "postback-logs", title: "Postback Logs",  disabled: !serviceCompared, hide: service || isSupplier,
            checkPermission: false, checkedMethods: [POSTBACK_LOGS_GET_LIST_API]
        },
        ...(checkPermissionsFor(API_PAGE_LIST)
            ? [{id: "api", eventKey: "api", title: "API Keys", disabled: !serviceCompared, hide: isSupplier}]
            : []
        ),
        {id: "self-allocation", eventKey: "self-allocation", title: "Trunk settings", disabled: !serviceCompared, hide: isSupplier,
            checkPermission: false, checkedMethods: [ACCOUNT_SERVICE_PLAN_LIST_API]
        },
        ...(checkPermissionsFor(PAYMENT_REQUEST_LIST_API)
            ? [{id: "payment-management", eventKey: "payment-management", title: "Payment management"}]
            : []
        ),
        {id: "notes", eventKey: "notes", title: "Notes",
            checkPermission: isRatemod, checkedMethods: [ACCOUNT_NOTE_GET_LIST_API]
        },
        {id: "changes", eventKey: "changes", title: "Changes",
            checkPermission: isRatemod, checkedMethods: [ACCOUNT_CHANGES_LIST_API]
        }
    ];

    const filterMenuItems = (menuItems) => {
        return menuItems.filter((item) => {
            const checkedMethods = [];

            if ('checkPermission' in item && !!item.checkPermission && item.checkedMethods) {
                for (const method of item.checkedMethods) {
                    checkedMethods.push(checkPermissionsFor(method));
                }
            }

            if (!!item && !item.hide && checkedMethods.every((check) => check === true)) {
                return true;
            }
        });
    };

    const dropdownTitle = (menuItems.find(item => item.eventKey === activeTab) || {}).title;
    const isMobile = windowWidth < 1200;

    return (
            <>
                <Header>
                     <>
                        <div><Name>Account:</Name><Value>{account?.name}</Value></div>
                        <div><Name>Manager:</Name><Value>{account_manager_name}</Value></div>
                        <div><Name>Currency:</Name><Value>{currency_name}</Value></div>
                        <div><Name>Payment terms:</Name><Value>{payment_terms_name}</Value></div>
                    </>

                    <CurrencyContainer>
                        {billingServiceList && billingServiceList.length 
                            ? billingServiceList.map(billingService => {
                            const currentCurrencyName = billingService?.currency_name;
                            return <CurrencyService>
                                <ServiceCell service={billingService?.service}>{billingService?.service}</ServiceCell>
                                <CurrencyCell minWidth={210}>
                                    <Name>Pending amount:</Name>
                                    <Value>{billingService?.pending_amount} {currentCurrencyName}</Value>
                                </CurrencyCell>
                                {!isSupplier ? <CurrencyCell minWidth={220}>
                                    <Name>Ready for payment:</Name>
                                    <Value>{billingService?.ready_for_payment} {currentCurrencyName}</Value>
                                </CurrencyCell> : <></>}
                                <CurrencyCell minWidth={210}>
                                    <Name>Invoiced balance:</Name>
                                    <Value>{billingService?.BALANCE_INV2} {currentCurrencyName}</Value>
                                </CurrencyCell>
                            </CurrencyService>
                        }) : <></>}
                    </CurrencyContainer>
                </Header>

                {!isMobile
                    ? <PageTabs
                        {...{
                            activeTab,
                            onSelect,
                            menuItems: filterMenuItems(menuItems)
                        }}
                    />
                    : <>
                        <FlexGrid
                            noWrap
                            align={"middle"}
                            justify={"space-between"}
                        >
                            <FlexGridItem>
                                <SectionHeader>{dropdownTitle}</SectionHeader>
                            </FlexGridItem>
                            <FlexGridItem>
                                <PageTabs
                                    isMobile={true}
                                    {...{
                                        activeTab,
                                        onSelect,
                                        menuItems: filterMenuItems(menuItems)
                                    }}
                                />
                            </FlexGridItem>
                        </FlexGrid>
                    </>
                }
            </>
        )
}

const CurrencyContainer = styled.div`
    && {
        margin-left: auto;
        display: flex;
        flex-direction: column;
    }

    && div {
        display: inline-block;
        text-align: start;
    }
`;

const CurrencyService = styled.span`
    && {
        text-align: end;
    }
`;

const ServiceCell = styled.div`
    && {
        color: var(--color-main);
        text-align: start;
        margin-right: 10px;
        min-width: 40px;
        font-weight: 700;
    }
`;

const CurrencyCell = styled.div`
    && {
        ${props => props.minWidth && css`
            min-width: ${props.minWidth}px;
        `}
    }
`

const Name = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-main);
    margin-right: 3px;
`;

const Value = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #363434;
    margin-right: 15px;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    
    & > div {
        padding-bottom: 10px;
    }
`;