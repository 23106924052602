import React from 'react';
import {getFileResponse} from '../../../api/loginRoutes';
import {downloadDataAsFile} from '../../../utils/helpers';
import styled, {css} from "styled-components";
import {ButtonToolbar, Dropdown, Icon} from 'rsuite';
import {Loader} from 'rsuite/es';


export default (
    {
        filter = {},
        disabled = false,
        account_id,
        setModalAddPriceShow,
        setModalUploadPriceShow
    }
) => {
 
    
    return (
        <>
            <StyledButtonToolbar>
                <StyledDropdown
                    size="sm"
                    disabled={disabled}
                    style={{marginLeft: 10}}
                    title="Add prices"
                    appearance="default"
                    placement="bottomEnd"
                >
                    <Dropdown.Item onClick={() => setModalAddPriceShow(true)}>+ Add new special price</Dropdown.Item>
                    <Dropdown.Item onClick={() => setModalUploadPriceShow(true)}>Upload price list</Dropdown.Item>
                </StyledDropdown>
            </StyledButtonToolbar>
        </>
    )
};



const StyledButtonToolbar = styled(ButtonToolbar)`
    display: inline-block; 
    ${props => props.width && css`
        width: ${props.width};
    `}
`;


const StyledDropdown = styled(Dropdown)`
    height: 30px;
    & .rs-btn {
        padding-left: 10px !important;
    }
    & .rs-loader-spin {
        margin-top: 0 !important;
    }
    & > a {
        padding-top: 5px !important;
        top: 0;
        height: 30px;
        width: 100%;
    }
    & .rs-dropdown-toggle-caret {
        top: 5px;
        margin-top: 0 !important;
    }
    &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
        background-color: #d6d6db !important;
    }
`;

const StyledLoader = styled(Loader)`
    & span.rs-loader-spin {
        margin-top: 3px;
        margin-right: 5px;
    }
`;