import React, {useState} from 'react';
import {Icon, FlexboxGrid, FormControl} from "rsuite";


export default () => {

    const [showRateInput, onChangeShowRateInput] = useState(null);
    const [customRateValue, onChangeCustomRateValue] = useState(null);

    return (
        <FlexboxGrid justify="start" style={{margin: '0 0 20px 0'}}>

            {!showRateInput ? <FlexboxGrid.Item>
                    <div
                        style={{
                            marginTop: '8px',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            onChangeShowRateInput(true);
                        }}
                    >
                        <Icon 
                            icon="book" 
                            inverse
                            style={{
                                color: showRateInput === null ? '#2f63bf' : '#5e2f9d', marginRight: '5px'
                            }}
                        />
                        <a
                            style={{
                                color: showRateInput === null ? '#2f63bf' : '#5e2f9d',
                            }}
                        >
                            Put custom rate
                        </a>
                    </div>

                </FlexboxGrid.Item>
                : <>
                    <FlexboxGrid.Item
                        style={{
                            marginTop: '8px',
                            marginRight: '10px'
                        }}
                    >
                        Rate
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        style={{
                            marginRight: '10px'
                        }}
                    >
                        <FormControl
                            style={{
                                width: 140
                            }}
                            errorPlacement="bottomEnd"
                            placeholder="0.0001"
                            name="rate"
                            value={customRateValue}
                            onChange={(value) => {
                                onChangeCustomRateValue(value);
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        style={{
                            marginTop: '8px',
                            marginRight: '10px'
                        }}
                    >
                        <Icon
                            icon="close"
                            inverse
                            style={{
                                color: '#5e2f9d',
                                marginRight: '5px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                onChangeShowRateInput(false);
                                onChangeCustomRateValue(null)
                            }}
                        />
                    </FlexboxGrid.Item>
                </>
            }
        </FlexboxGrid>
    )
};