import React, {useContext} from "react";
import {connect} from "react-redux";
import {Button, Icon, Whisper, Popover} from "rsuite";
import styled, {css} from "styled-components";
import {APP_TYPE_CLIENT} from "const";
import {RtlContext} from "App";
import { downloadFileByUrl } from "utils";
import {useWindowWidth} from "../hooks"


const ManualWidget = ({
    lang,
    appType
}) => {
    
    const rtl = useContext(RtlContext);

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 767;

    if (appType !== APP_TYPE_CLIENT) {
        return null;
    }

    return (<>
        <Whisper 
            placement={rtl ? "right" : "left"} 
            trigger="hover" 
            speaker={<Popover>{lang === "ar" ? "Arabic manual" : "Manual"} file</Popover>}
            enterable={true}
        >
            <ManualButton rtl={rtl} onClick={() => {
                const fileName = lang === "ar" ? "Manual_ar.pdf" : "Manual.pdf";
                const fileURL = `${process.env.PUBLIC_URL}/${fileName}`;
  
                downloadFileByUrl(fileURL, fileName)
            }}>
                <Icon icon="book2"/>
                {!isMobile && <ManualButtonText>
                    <span className="mc1">M</span>
                    <span className="mc2">A</span>
                    <span className="mc3">N</span>
                    <span className="mc4">U</span>
                    <span className="mc5">A</span>
                    <span className="mc6">L</span>
                </ManualButtonText>}
            </ManualButton>
        </Whisper>
    </>);
}

const ManualButtonText = styled.div`
    && {
        position: absolute;
        width: 60px;
        height: 60px;
        margin: 0px auto;
        top: 0px;
        font-size: 10px;
        color: white;
        font-weight: 600;

        span {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 20px;
            height: 38px;
            margin-left: -10px;
            line-height: 53px; /* height*2 - font-size */
            text-align: center;
            transform-origin: top center;
        }

        span.mc1 {
            transform: rotate(41deg);
        }
        span.mc2 {
            transform: rotate(23deg);
        }
        span.mc3 {
            transform: rotate(9deg);
        }
        span.mc4 {
            transform: rotate(-9deg);
        }
        span.mc5 {
            transform: rotate(-23deg);
        }
        span.mc6 {
            transform: rotate(-40deg);
        }
    }
`;


const ManualButton = styled(Button)`
    && {
        padding: 0;
        width: 60px;
        height: 60px;
        border-radius: 60px;
        background: linear-gradient(#9399bd, #717797);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 4px;
        position: relative;

        &:before {
            top: 0;
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            background: #9399bd;
            z-index: -1;
            opacity: 0;
            transition: opacity 0.2s ease;
        }

        &:hover {
            background: linear-gradient(#9399bd, #717797);
            
            &:before {
                opacity: 1;
            }
        }

        &:focus, 
        &:active, 
        &:active:hover, 
        &:active:focus, 
        &:active.focus {
            background: linear-gradient(#9399bd, #717797);;
        }

        .rs-icon {
            color: #fff;
            font-size: 25px;
            line-height: 0px;
        }

        bottom: 140px;
        position: fixed;
        right: 10px;
        z-index: 10;

        @media (max-width: 767px) {
            bottom: 220px;
            right: 5px;

            width: 36px;
            height: 36px;

            .rs-icon {
                font-size: 16px;
            }
        }

        ${props => props.rtl && css`
            left: 10px;
            right: auto;

            @media (max-width: 767px) {
                left: 5px;
                right: auto;
            }
        `}
    }
`;


const mapState = ({auth})=> ({
    lang: auth.lang,
    appType: auth.appType
});

export default connect(mapState)(ManualWidget);