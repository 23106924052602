import React from 'react';
import {Loader} from 'rsuite';
import PanelLayout from '../base/PanelLayout';

const styleLoader = {
    transform: 'translate(0, 35%)'
}

export default  class  extends React.Component{

    render () {
        return (
            <div>
                <PanelLayout style = {{position: 'relative'}}>
                    <Loader size="md" center content="Loading" style={styleLoader} />
                </PanelLayout>
            </div>
        )

    }
}