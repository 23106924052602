import {generateId} from '../../utils';
import {
    DEFAULT_PER_PAGE_DIALER,
    LIVE_CALLS_DIALER as PREFIX
} from '../../const';

const initialState = {
    loading: true,
    live_calls_list: [],
    count: 0,
    page: 1,
    per_page: DEFAULT_PER_PAGE_DIALER,
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {live_calls_list}}) => ({
        ...state,
        live_calls_list: [
            ...live_calls_list.map(x => {
                x.id = generateId();
                return x;
            })
        ],
    }),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => {
        return ({...state, loading: payload})
    },
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};
