import React from "react";
import { Icon } from "rsuite";
import LiveCallsAutoUpdateForm from "pages/LiveCalls/LiveCallsAutoUpdateForm";
import { ButtonPrimary } from "components/base/BaseButton";
import styled from "styled-components";
import m from "definedMessages";


const LiveCallsSettings = ({
    disabled,
    formDefaultValue,

    update,
    onChange,
    formatMessage
}) => {
    return <SettingsContainer>
        <LiveCallsAutoUpdateForm
            formDefaultValue={formDefaultValue}
            onChange={onChange}
        />
        <ButtonPrimary
            onClick={update}
            loading={disabled}
        >
            <StyledActionButtonIcon icon='refresh'/>
            {formatMessage(m.updateData)}
        </ButtonPrimary>
    </SettingsContainer>
};

export default LiveCallsSettings;

const SettingsContainer = styled.div`
    && {
        display: flex;
        align-items: center;
    }
`;

const StyledActionButtonIcon = styled(Icon)`
    margin-right: 5px;
    
    .rtl & {
        margin-left: 5px;
        margin-right: 0;
    }
`;
