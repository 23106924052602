import React from 'react';
import {connect} from 'react-redux';
import ApiPage from 'pages/Account/ApiPage/ApiPage';
import {getKeyList, createApiKey, modifyApiKey, deleteApiKey} from 'actions/apiPage';

const ApiPageContainer = ({keyList, service, getKeyList, deleteApiKey, modifyApiKey, createApiKey, accountSession, accountJoinList}) => {
    return (
        <>
            <ApiPage
                {...{
                    deleteApiKey, 
                    modifyApiKey, 
                    createApiKey, 
                    getKeyList, 
                    keyList, 
                    service, 
                    accountSession,
                    accountJoinList
                }}
            />
        </>
    );
};

const mapState = ({auth, accounts, apiPage}) => ({
    accountInfo: accounts.accountView,
    accountSession: auth.authInfo.session,
    accountJoinList: accounts.accountJoinList,
    keyList: apiPage.items.sort((a, b) => a.api_key > b.api_key),
    service: auth.service,
    count: apiPage.count,
});

export default connect(mapState,
{
    getKeyList,
    createApiKey,
    modifyApiKey,
    deleteApiKey
})(ApiPageContainer);