import React, {useState, useMemo, useRef} from "react";
import styled, {css} from "styled-components";

import {apiFormData} from "api/loginRoutes";
import {servicePick, toStartDayUTCTime} from "utils";
import {SCREEN_MEDIA, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY} from "const";
import {SERVICE_PLAN_PRICE_UPLOAD_CSV_API} from "const/apiMethods";
import {
    ControlLabel, DatePicker, Schema, Form, Alert, 
    FormControl, InputNumber, SelectPicker,
} from "rsuite";

import FormCheckbox from "../../components/Form/FormCheckbox";
import {CustomModal} from "../../components/base/BaseModal";
import CustomUploaderSingle from "../../components/Form/CustomUploaderSingle";


const fullPriceData = [
    {value: true, name: "Upload as full price"},
    {value: false, name: "Update existing and add new"},
];

const FILES_DEFAULT = [];
const {BooleanType, NumberType, DateType} = Schema.Types;


const formCommonModel = Schema.Model({
    p_key: NumberType()
        .isRequired('Required'),
    cur_key: NumberType()
        .isRequired('Required'),
    id_column: NumberType()
        .isRequired('Required')
        .min(1, 'Min value 1')
        .max(9, 'Max value 9'),
    name_column: NumberType()
        .min(1, 'Min value 1')
        .max(9, 'Max value 9'),
    full_price: BooleanType()
        .isRequired('Required'),
    update_account_price: BooleanType()
        .isRequired('Required'),
    update_allocated_numbers_price: BooleanType()
        .isRequired('Required'),
});

export default (
    {
        show,
        service,
        price_list,
        currency_list,
        paymentTermsList,
        onSuccess,
        onClose,
        ...props
    }
) => {

    const formRef = useRef();
    const [files, setFiles] = useState(FILES_DEFAULT);
    const [loading, setLoading] = useState(false);


    // validation model
    const formModel = useMemo(() => {
        if (!paymentTermsList || !paymentTermsList.length) {
            return formCommonModel;
        }
        const paymentOrdersModel = paymentTermsList.reduce((summ, item) => {
            return [
                ...summ,
                Schema.Model({
                    [`pt_${item.pt_key}_column`]: NumberType()
                        .min(1, 'Min value 1')
                        .max(9, 'Max value 9')
                })
            ]
        }, []);
        return Schema.Model.combine(formCommonModel, ...paymentOrdersModel);
    }, [paymentTermsList]);

    const formDefaultValue = {
        p_key: price_list.find(p_key => p_key === 1) ? 1 : null,
        cur_key: servicePick(service, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY),
        id_column: 1,
        name_column: 2,
        update_account_price: false,
        update_allocated_numbers_price: false,
    }

    // submit
    const handleSubmit = () => {
        if ( !formRef.current.check() ) {
            return;
        }

        if (!files || !files.length) {
            Alert.error('Please upload CSV file with prices');
            return;
        }

        const formValue = formRef.current.getFormValue();

        const startDate = formValue.start_date 
            ? toStartDayUTCTime(formValue.start_date).getTime() !== toStartDayUTCTime( new Date() ).getTime()
                ? toStartDayUTCTime(formValue.start_date)
                : null
            : null;

        const formData = {
            ...formValue,
            
            id_column: formValue.id_column 
                ? +formValue.id_column 
                : undefined,
            
            name_column: formValue.name_column 
                ? +formValue.name_column 
                : undefined,
            
            start_date: startDate,
        };

        const dt = new DataTransfer();
        dt.items.add(files[0].blobFile);
        formData.files = dt.files;
        delete formData.file;

        

        const termsOrder = paymentTermsList.reduce((summ, item) => {
            const propName = `pt_${item.pt_key}_column`;
            return !formData[propName] && !formData[propName] !== 0
                ? summ
                : [...summ, {
                    pt_key: item.pt_key,
                    column: +formData[propName],
                }];
        }, []);

        if (!termsOrder || !termsOrder.length) {
            Alert.error('Please set at least one column order of payment terms.');
            return;
        }
        
        formData.pt_rate_ar = termsOrder;

        for (const term of paymentTermsList) {
            delete formData[`pt_${term.pt_key}_column`];
        }

        setLoading(true);
        apiFormData(SERVICE_PLAN_PRICE_UPLOAD_CSV_API, formData)
            .then(r => {

                if (!r) {
                    return;
                }

                if (r.validation_error) {
                    Alert.error(<>
                        <p><b>Error</b>: {r.validation_error}</p>
                        {r.line_number &&
                            <p>
                                {formData.files && `${formData.files[0].name}: `}
                                line <b>{r.line_number}</b><br/>
                                {r.line_str}
                            </p>
                        }
                    </>, 10000);
                    return;
                }

                onSuccess && onSuccess(r);
                handleClose();

            })
            .finally(() => {
                setLoading(false);
            });
    };


    const handleClose = () => {
        setFiles([]);
        onClose && onClose();
    };

    return (
        <CustomModal
            showFooter
            show={show}
            title={'Upload Price'}
            loading={loading}
            onConfirm={handleSubmit}
            onClose={handleClose}
            {...props}
        >
            <StyledForm
                ref={formRef}
                model={formModel}
                formDefaultValue={formDefaultValue}
            >

                <StyledField style={{paddingTop: 0}}>
                    <ControlLabel>Price</ControlLabel>
                    <FormControl
                        errorPlacement={'topStart'}
                        data={price_list}
                        accepter={SelectPicker}
                        name="p_key"
                        labelKey="name"
                        valueKey="p_key"
                        searchable={false}
                        cleanable={false}
                    />
                </StyledField>

                <StyledField>
                    <ControlLabel>Currency</ControlLabel>
                    <FormControl
                        errorPlacement={'topStart'}
                        data={currency_list}
                        accepter={SelectPicker}
                        name="cur_key"
                        labelKey="name"
                        valueKey="cur_key"
                        searchable={false}
                        cleanable={false}
                    />
                </StyledField>

                <StyledField>
                    <ControlLabel>Date</ControlLabel>
                    <FormControl
                        errorPlacement={'topStart'}
                        accepter={DatePicker}
                        name="start_date"
                                
                        ranges={[
                            {
                                label: 'Today',
                                value: new Date()
                            },
                            {
                                label: 'Tomorrow',
                                value: new Date().setDate(new Date().getDate() + 1)
                            }
                        ]}

                        disabledDate={(date) => {
                            const today = new Date();
                            today.setHours(0, 0, 0, 0);
                            return date < today;
                        }}
                    />
                </StyledField>

                <StyledField>
                    <ControlLabel>File</ControlLabel>
                    <FormControl
                        errorPlacement={'topStart'}
                        name="file"
                        text="Upload file list"
                        files={files}
                        accepter={CustomUploaderSingle}
                        setFiles={setFiles}
                    />
                </StyledField>

                <hr />

                <FormRow>
                    
                    <FormCol>
                        <StyledField>
                            <ControlLabel>Column "ID" order</ControlLabel>
                            <FormControl
                                errorPlacement={'topStart'}
                                min={1}
                                max={9}
                                accepter={InputNumber}
                                name="id_column"
                            />
                        </StyledField>
                    </FormCol>

                    <FormCol>
                        <StyledField>
                            <ControlLabel>Column "Subdestination" order</ControlLabel>
                            <FormControl
                                errorPlacement={'topStart'}
                                min={1}
                                max={9}
                                accepter={InputNumber}
                                name="name_column"
                            />
                        </StyledField>
                    </FormCol>

                    {paymentTermsList.map(item => (
                        <FormCol>
                            <StyledField>
                                <ControlLabel>Column "{item.name}" order</ControlLabel>
                                <FormControl
                                    errorPlacement={'topStart'}
                                    min={1}
                                    max={9}
                                    accepter={InputNumber}
                                    name={`pt_${item.pt_key}_column`}
                                />
                            </StyledField>
                        </FormCol>
                    ))}

                </FormRow>

                <hr />

                <StyledField>
                    <ControlLabel>Upload action</ControlLabel>
                    <FormControl
                        errorPlacement={'topStart'}
                        data={fullPriceData}
                        accepter={SelectPicker}
                        name="full_price"
                        labelKey="name"
                        valueKey="value"
                        searchable={false}
                        cleanable={false}
                    />
                </StyledField>

                <FormRow>

                    <FormCol>
                        <StyledField isCheckbox>
                            <ControlLabel>Update account price</ControlLabel>
                            <FormControl
                                errorPlacement={'topStart'}
                                accepter={FormCheckbox}
                                name="update_account_price"
                            />
                        </StyledField>
                    </FormCol>

                    <FormCol>       
                        <StyledField isCheckbox>
                            <ControlLabel>Update allocated numbers</ControlLabel>
                            <FormControl
                                errorPlacement={'topStart'}
                                accepter={FormCheckbox}
                                name="update_allocated_numbers_price"
                            />
                        </StyledField>
                    </FormCol>

                </FormRow>
                
            </StyledForm>
        </CustomModal>
    )
}

const StyledForm = styled(Form)`

`;

const FormRow = styled.div`
    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const FormCol = styled.div`

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        padding-left: 10px;
        padding-right: 10px;
        width: 50%;

        &:nth-child(2n - 1) {
            padding-left: 0
        }

        &:nth-child(2n) {
            padding-right: 0
        }
    }
`;

const StyledField = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
    }

    .rs-control-label {
        flex-shrink: 0;
        align-self: center;
        padding-right: 10px;
        width: 100%;

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            width: 150px;
        }
    }

    .rs-input-number {

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            max-width: 90px;
        }
    }

    ${props => props.isCheckbox && css`
        display: flex;
        
        @media (max-width: ${SCREEN_MEDIA.sm.max}px) {

            .rs-form-control-wrapper {
                width: auto;
            }

            .rs-control-label {
                width: auto;
                order: 1;
            }
        }
    `}
`;