import React from "react";
import {Form, FormControl, InputGroup, FormGroup} from "rsuite";
import styled from "styled-components";
import {debounce} from "utils";

import m from "definedMessages";


export default ({
    filter,
    onChangeFilter,
    formatMessage
}) => {
    return (
        <StyledForm
            formDefaultValue={filter}
            onChange={debounce((value) => {
                onChangeFilter(value);
            }, 500)}
        >
            <StyledFormGroup>
                <InputGroup inside>
                    <StyledFormControl
                        placeholder={formatMessage(m.rangeOrNumber)}
                        name="filter_phone"
                        errorPlacement="topRight"
                    />
                </InputGroup>
            </StyledFormGroup>
        </StyledForm>
    )
}
const StyledForm = styled(Form)`
    && {
        width: 100%;
        display: flex;
    }

    .rs-form-control-wrapper {
        width: auto;
        margin-right: 20px;
    }
`;

const StyledAddon = styled(InputGroup.Addon)`
    &&&& {
        position: relative;
        padding: 0;
    }
`;

const StyledFormControl = styled(FormControl)`
    && {
        width: 100%;
        min-width: 300px;
        max-width: 320px;
        overflow: hidden;
    }
`;

const StyledFormGroup = styled(FormGroup)`
    && {
        display: flex;
        align-items: center;

        .rs-control-label {
            margin-right: 10px;
            margin-bottom: 0px;
        }
    }
`;