import React, {useState} from "react";
import { useWindowWidth } from "hooks";
import {Modal, Carousel} from "rsuite";
import styled from "styled-components";


const ModalShowPicture = ({header="", index=0, carouselSrcList=null, children}) => {
    const windowWidth = useWindowWidth();

    const [show, setShow] = useState();

    const picture = React.isValidElement(children) 
        ? React.cloneElement(children, {
                onClick: () => {
                    setShow(true);
                }
            })
        : children;

    const onClose = () => {
        setShow(false);
    };

    const shuffledCarouserlItemList = [
        ...carouselSrcList.slice(index, carouselSrcList.length),
        ...carouselSrcList.slice(0, index)
    ].map((src, srcIdx) => {
        return <img
            key={srcIdx}
            src={src}
        />
    })

    const carouselPlacement = windowWidth < 768 ? "top" : "bottom";

    return (
        show ? <ModalPicture
            show={show}
            onHide={onClose}
            onClose={onClose}
        >
            <Modal.Header closeButton={true}>{header}</Modal.Header>
            <Modal.Body>
                {!!carouselSrcList && carouselSrcList.length ?
                    <Carousel shape="bar" placement={carouselPlacement}>
                        {shuffledCarouserlItemList}
                    </Carousel>
                    : children
                }
            </Modal.Body>
        </ModalPicture> 
        : <Image>
            {picture}
        </Image>
    );
};

export default ModalShowPicture;

const ModalPicture = styled(Modal)`
    && {
        margin: 10vh auto 0 auto !important;
        
        width: 80vw;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 768px) {
            margin: 0 !important;
        }
    }
    
    &&.rs-modal[role="dialog"] {
        margin: 10vh auto 0 auto !important;

        @media (max-width: 768px) {
            margin: 0 !important;
        }
    }

    && .rs-modal-header {
        padding: 0;
    }
    
    && .rs-modal-header .rs-modal-header-close {
        z-index: 1;
    }

    && .rs-modal-dialog {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    && .rs-modal-content {
        padding: 0;
        width: 100%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: none;
    }

    && .rs-modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        max-width: inherit;
        max-height: 100% !important;
    }

    && .rs-carousel {
        height: inherit;

        @media (max-width: 768px) {
            height: 100%;
        }
    }

    && .rs-carousel-label-wrapper input:checked ~ label {
        background: var(--color-info);
                
        @media (max-width: 768px) {
            background: var(--color-status-badge);
        }
    }

    && .rs-carousel-label {
        background: gray;
        
        @media (max-width: 768px) {
            background: white;
        }
    }

    && img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: none;
        border-radius: 0;
        overflow: hidden;

        @media (max-width: 768px) {
            object-fit: scale-down;
        }
    }
`;

const Image = styled.div`
`;