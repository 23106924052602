import React, {useContext, useEffect} from "react";
import Page from "../../components/base/Page";
import PageHeader from "../../components/base/PageHeader";
import {FormattedMessage} from "react-intl";
import NewsList from "./NewsList/NewsList";

export default (
    {
        data,
        count,
        inited,
        loading,
        getNewsDialer,
        clearCountDialer,
        lang,
        ...props
    }
) => {

    useEffect(() => {
        clearCountDialer();
    }, []);

    return (
        <Page showNewsWidget={false}>
            <PageHeader title={<FormattedMessage id="news.news" defaultMessage="News" />}/>

            <NewsList
                {...{
                    data,
                    count,
                    loading,
                    inited,
                    lang,
                    getNewsDialer,
                }}
            />
        </Page>
    )
};