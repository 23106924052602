import React from "react";
import {Form} from "rsuite";
import styled from "styled-components";
import FieldDataRange from '../../../components/Form/FieldDataRange'

export default (
    {
        filter,
        setFilter
    }
) => {

    return (
        <StyledField>
            <Form
                defaultValue={filter}
                onChange={(value) => {
                    setFilter(value)
                }}
            >
                <FieldDataRange
                    name="range"
                    placement="bottomStart"
                    placeholder={"Date"}
                    style={{ width: 234 }}
                />
            </Form>
        </StyledField>
    )
}

const StyledField = styled.div`
    max-width: 300px;
    width: 100%;
    margin-top: 10px;
`;