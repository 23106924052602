import React, {useState, useEffect} from 'react';
import PanelLayout from '../../components/base/PanelLayout';
import PageHeader from '../../components/base/PageHeader';
import {FlexboxGrid} from 'rsuite';
import {
    getBlockedDestinationsList,
    onAddBlockDestinationsItem,
    onEditBlockDestinationsItem,
    onRemoveBlockDestinationsItem,
    onEditBlockDestinationsItemList
} from 'actions/blocked_destinations';
import {connect} from 'react-redux';
import BlockedDestinationsFilters from 'pages/BlockedDestinations/BlockedDestinationsFilters';
import BlockedDestinationsTable from 'pages/BlockedDestinations/BlockedDestinationsTable';
import BlockedDestinationsAdd from 'pages/BlockedDestinations/BlockedDestinationsAdd';


const BlockedDestinationsPage = ({
    loading,
    service,
    accountList,
    servicePlanList,
    worldzoneList,
    destinationList,
    subdestinationList,
    blockedDestinationsList,
    getBlockedDestinationsList,
    onAddBlockDestinationsItem,
    onEditBlockDestinationsItem,
    onRemoveBlockDestinationsItem,
    onEditBlockDestinationsItemList
}) => {
    const [filters, setFilters] = useState({});

    useEffect(() => {
        getBlockedDestinationsList(filters, service);
    }, []);

    const onChangeFilters = (filter) => {
        setFilters(filter);
        getBlockedDestinationsList(filter, service);
    };

    return (
        <PanelLayout>
            <PageHeader title="Blocked Destinations"/>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24}>
                    <BlockedDestinationsFilters onSubmit={onChangeFilters} defaultFilters={filters}/>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <BlockedDestinationsTable
                data={blockedDestinationsList}
                update={() => getBlockedDestinationsList(filters, service)}
                onEditItem={onEditBlockDestinationsItem}
                onEditItemList={onEditBlockDestinationsItemList}
                onRemoveItem={onRemoveBlockDestinationsItem}
                onAddItem={onAddBlockDestinationsItem}
                {...{
                    filter: filters,
                    loading,
                    service,
                    accountList,
                    servicePlanList,
                    worldzoneList,
                    destinationList,
                    subdestinationList,
                }}
            />
            <BlockedDestinationsAdd
                addItem={onAddBlockDestinationsItem}
                update={() => getBlockedDestinationsList(filters, service)}
                {...{
                    service,
                    accountList,
                    servicePlanList,
                    worldzoneList,
                    destinationList,
                    subdestinationList,
                }}
            />
        </PanelLayout>
    )
};

const mapStateToProps = ({auth, blocked_destinations, references}) => ({
    blockedDestinationsList: blocked_destinations.list,
    worldzoneList: references.worldzone_list,
    destinationList: references.destination_list,
    subdestinationList: references.subdestination_list,
    servicePlanList: references.service_plan_list,
    accountList: auth.service ? references.dialer_list : references['sms.dialer_list'],
    loading: blocked_destinations.loading,
    service: auth.service
});

const mapDispatchToProps = {
    getBlockedDestinationsList,
    onAddBlockDestinationsItem,
    onEditBlockDestinationsItem,
    onRemoveBlockDestinationsItem,
    onEditBlockDestinationsItemList
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedDestinationsPage);