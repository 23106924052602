// media queries
export const SCREEN_MEDIA = {
    xxs: {min: 0, max: 479.98},
    xs: {min: 480, max: 575.98},
    sm: {min: 576, max: 767.98},
    md: {min: 768, max: 991.98},
    lg: {min: 992, max: 1199.98},
    xl: {min: 1200, max: 1399.98},
    mx: {min: 1899, max: 1919.98}
};

export const APP_TYPE_CLIENT = 2;
export const APP_TYPE_ADMIN = 4;

export const APP_TYPE_DEFAULT = window.app_type ? +window.app_type : 2;

export const SMS_ACCOUNT_ID = 'MIYBG9-CTF6kXmg_TNOg9w';

export const service_data = [
    {"name": "Voice", "value": "voice"},
    {"name": "SMS", "value": "sms"}
];

export const transformed_service_data = {
    "voice": true,
    "sms": false
};

export const langFullNames = {
    "en": "english",
    "ar": "arabic"
};

export const transformed_service_reverse_data = {
    [true]: "voice",
    [false]: "sms"
};

export const commentTypes = [
    {id: 0, label: 'Very important', icon: "exclamation-circle", color: "#f85071"},
    {id: 1, label: 'Info', icon: "info-circle", color: "#1E90FF"},
    {id: 2, label: 'Notice', icon: "commenting", color: "var(--color-main)"},
];

export const DEFAULT_PER_PAGE = 15;

export const ALLOCATION_NUMBER_LIMIT_PANEL = 100000;
export const valueAllocationLimitList = 100000;

export const SP_OTP_TYPE = 1;
export const SP_KEY__STANDARD = 4;
export const SP_KEY__GOOGLE_OTP = 6;
export const SP_KEY__INCORPORATED = 8;
export const SP_KEY__CONFERENCE_CALLS = 13;
export const SP_KEY__RESELLER = 101;
export const SP_KEY__GLOBAL_ACCESS = 102;

export const PAYMENT_METHOD_PAYPAL = 2;
export const PAYMENT_METHOD_HAWALA = 5;
export const PAYMENT_METHOD_CRYPTO = 6;
export const PAYMENT_METHOD_MISC = 7;

export const DEFAULT_PAYMENT_MISC_STATUS_UNEXPORTED = 0;
export const DEFAULT_PAYMENT_MISC_STATUS_EXPORTED = 2;

export const REASON_HANDLER = 'REASON_HANDLER';

export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE';

export const PAGE_LOGGER = 'PAGE_LOGGER';


// ACCOUNTS
export const ACCOUNTS = 'ACCOUNTS';
export const DROPDOWN_ACCOUNTS = 'DROPDOWN_ACCOUNTS';

export const VIEW_ACCOUNT = 'accounts::VIEW';
export const SET_VIEW_ACCOUNT = 'accounts::SET_VIEW';
export const SET_VIEW_ACCOUNT_JOIN = 'accounts::SET_VIEW_JOIN';
export const SET_INFO_ACCOUNT = 'accounts::SET_INFO';

// OTP SEARCH
export const SEARCH = 'SEARCH';

// SUPPLIERS
export const VIEW_SUPPLIER = 'suppliers::VIEW';
export const SUPPLIERS = 'SUPPLIERS';
export const DROPDOWN_SUPPLIERS = 'DROPDOWN_SUPPLIERS';

// SUPPLIERS RATES
export const SET_TRANSIT_RATES = 'suppliers::SET_TRANSIT_TRUNKS';

// SUPPLIERS TRUNK
export const SET_TRUNKS_SUPPLIER = 'suppliers::SET_TRUNKS';
export const SET_TRUNKS_SUPPLIER_LOADING = 'suppliers::SET_TRUNKS_LOADING';
export const SET_TRUNKS_SUPPLIER_DROPDOWN = 'suppliers::SET_TRUNKS_DROPDOWN';

// SUPPLIERS PLAN
export const SUPPLIERS_PLAN = 'SUPPLIERS_PLAN';

// SUPPLIERS PREFIX
export const SUPPLIERS_PREFIX = 'SUPPLIERS_PREFIX';
export const SUPPLIER_DROPDOWN_PREFIX = 'SUPPLIER_DROPDOWN_PREFIX';

// SUPPLIERS GROUP
export const SUPPLIERS_GROUP = ' SUPPLIERS_GROUP';

export const SET_TRUNK_ACCOUNT = 'accounts::SET_TRUNK';
export const SET_VIEW_ACCOUNT_AFTER_DELETE = 'accounts::SET_TRUNK_AFTER_DELETE';
export const ADD_TRUNK_ACCOUNT = 'accounts::ADD_TRUNK';

export const SET_ACCESS_LIST_FILTERS_LOADING = 'accounts::SET_ACCESS_LIST_FILTERS_LOADING';
export const SET_ACCESS_LIST_FILTERS = 'accounts::SET_ACCESS_LIST_FILTERS';

export const SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST = 'accounts::SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST';
export const SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST_LOADING = 'accounts::SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST_LOADING';

export const SET_ACCESS_LIST_NOTIFICATION_SETTINGS = 'accounts::SET_ACCESS_LIST_NOTIFICATION_SETTINGS';
export const UNSET_ACCESS_LIST_NOTIFICATION_SETTINGS = 'accounts::UNSET_ACCESS_LIST_NOTIFICATION_SETTINGS';
export const MODIFY_ACCESS_LIST_NOTIFICATION_SETTINGS = 'accounts::MODIFY_ACCESS_LIST_NOTIFICATION_SETTINGS';

export const SET_USERS_ACCOUNT = 'accounts::SET_ACCOUNTS';
export const SET_USERS_ACCOUNT_LOADING = 'accounts::SET_ACCOUNTS_LOADING';

export const SET_EDIT_ERROR = 'accounts::SET_EDIT_ERROR';
export const SET_EDIT_ERROR_MESSAGE = 'accounts::SET_EDIT_ERROR_MESSAGE';

export const SET_TRUNK_ACCOUNT_LOADING = 'accounts::SET_TRUNK_LOADIING';
export const SET_ALLOCATED_ACCOUNT = 'accounts::SET_ALLOCATED';
export const SET_ALLOCATED_ACCOUNT_LOADING = 'accounts::SET_ALLOCATED_LOADING';

export const SETTINS_MODAL_SHOW = 'settings::MODAL';
export const SET_SIDEBAR = 'settings::SIDEBAR';
export const SETTINS_MODAL_COLOR_SHOW = 'settings::MODAL_COLOR';
export const SET_COLOR = 'settings::COLOR';

export const SET_LIMIT_MODIFIED = 'settings::SET_LIMIT_MODIFIED';

export const SET_AUTH = 'auth::SET_AUTH';
export const SET_LOADING_INFO = 'auth::SET_LOADING';
export const SET_SERVICE = 'auth::SET_SERVICE';
export const SET_DIALER_SERVICE = 'auth::SET_DIALER_SERVICE';

export const HAS_BOTH_SERVICE_VALUE = 'HAS_BOTH_SERVICE_VALUE';

export const SET_AUTH_NUMBER_TYPE_LIST = 'auth_numbers::SET_AUTH_NUMBER_TYPE_LIST';

export const ACCESS_LIST = 'ACCESS_LIST';

export const HOT_ACCESS_LIST = 'HOT_ACCESS_LIST';

export const LIVE_CALLS = 'live_calls';
export const LIVE_CALLS_LIST = 'live_call:get_list';
export const SET_LIVE_CALLS_LIST = 'live_calls::SET_LIVE_CALLS';

export const PRICES = 'service_plan_price:get_list_actual';
export const SET_SERVICE_PLAN_PRICE = 'SET_SERVICE_PLAN_PRICE';

export const SET_CURRENT_TRUNK_ID = 'SET_CURRENT_TRUNK_ID';
export const TRUNKS_LIST_UNSET_INITED = 'TRUNKS_LIST_UNSET_INITED';
export const SET_CURRENT_RANGE_NUMBER = 'SET_CURRENT_RANGE_NUMBER';

export const REFERENCES_DIALER_TRUNK = 'REFERENCES_DIALER_TRUNK';

export const PRICE_RANGES = 'price_range:get_list';
export const SMS_PRICE_RANGES = 'sms.price_range:get_list';

export const PRICE_RANGE_NUMBERS = 'price_range_number:get_list';
export const SMS_PRICE_RANGE_NUMBERS = 'sms.price_range_number:get_list';

export const SET_RANGES = 'price_range:SET_RANGES';

export const SPECIAL_RATES = 'account::special_rates';

export const  PRICE_NUMBERS = 'price_numbers';
export const  SET_NUMBERS = 'set_price_numbers';
export const  SET_NUMBERS_CSV = 'set_price_numbers_csv';

export const AUTH_NUMBERS = 'auth_numbers';

export const ACCOUNT_CHANGES = 'account_changes';

export const SET_ROLES = 'atx/roles/SET_ROLES';

export const SET_NUMBER_ALLOCATION_MODAL = 'atx/settings/SET_NUMBER_ALLOCATION_MODAL';
export const SET_VISIBLE_RECAPTCHA = 'atx/settings/SET_VISIBLE_RECAPTCHA';
export const SET_REQUEST_DATA_RECAPTCHA = 'atx/settings/SET_REQUEST_DATA_RECAPTCHA';

export const ALERT_DISPLAY_DURATION = 5000;

export const DEFAULT_AUTH_PATH_ADMIN = '/accounts';
export const DEFAULT_AUTH_PATH_ADMIN_RATEMOD = '/global-access-numbers';
export const DEFAULT_AUTH_PATH_ADMIN_SMS = '/sms-suppliers';
export const DEFAULT_AUTH_PATH_CLIENT = '/hot-accesses';
export const DEFAULT_AUTH_PATH_CLIENT_TEST = '/hot-accesses';
// export const DEFAULT_AUTH_PATH_CLIENT_SMS = '/hot-accesses-sms';
export const DEFAULT_NOT_AUTH_PATH = '/login';
export const SET_USER_LOGIN_INFO = 'SET_USER_LOGIN_INFO';

export const DEFAULT_SP_KEY = 2;
export const SMS_DEFAULT_SP_KEY = 101;

export const DEFAULT_PRICE_KEY = 2;
export const SMS_DEFAULT_PRICE_KEY = 101;

export const DEFAULT_PRT_KEY = 1;
export const OTP_SERVICE_PLAN_KEY = 3;

export const USD_CURRENCY_NAME = 'USD';
export const USD_DEFAULT_CURRENCY_KEY = 1;

export const EUR_CURRENCY_NAME = 'EUR';
export const EUR_DEFAULT_CURRENCY_KEY = 2;

export const currency_data = {
    [USD_CURRENCY_NAME]: USD_DEFAULT_CURRENCY_KEY,
    [EUR_CURRENCY_NAME]: EUR_DEFAULT_CURRENCY_KEY
};

export const currency_names_data = {
    [USD_DEFAULT_CURRENCY_KEY]: USD_CURRENCY_NAME,
    [EUR_DEFAULT_CURRENCY_KEY]: EUR_CURRENCY_NAME
};


export const DEFAULT_PER_PAGE_TINY = 5;
export const DEFAULT_PER_PAGE_SMALL = 10;
export const DEFAULT_PER_PAGE_MEDIUM = 15;
export const DEFAULT_PER_PAGE_BIG = 20;
export const DEFAULT_PER_PAGE_MAX = 100;

export const API_PAGE = "API_PAGE";
export const API_PAGE_LIST  = "account_apikey:get_list";
export const API_CREATE_KEY = "account_apikey:create";
export const API_MODIFY_KEY = "account_apikey:modify";
export const API_DELETE_KEY = "account_apikey:delete";

export const DESC_SORT = 'desc';
export const ASC_SORT = 'asc';
export const DEFAULT_EQUAL_SIGN = 0;
export const MIN_RATE_VALUE = 0.0001;

export const DEFAULT_PAYMENT_MISC_EXPORT_COMMIS = 3;

export const EOL = navigator.appVersion.indexOf("Win") !== -1 ? '\n' : '\r\n';

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

export const ERROR_CODE_TOO_MUCH_OPERATIONS = -32001;

export const SET_LOADING_ACCESS_EXCLUSION = 'SET_LOADING_ACCESS_EXCLUSION';
export const SET_HOT_LOADING_ACCESS = 'SET_HOT_LOADING_ACCESS';

// Payments DUCKS
export const PAYMENTS = 'payments::PAYMENTS';
export const PAYMENT_LIST = 'payments::PAYMENTS_LIST';
export const PAYMENT_REQUEST_LIST = 'payments::PAYMENTS_REQUEST_LIST';
export const PAYMENT_TRAFFIC_REPORTS_LIST = 'payments::PAYMENTS_TRAFFIC_REPORTS_LIST';
export const PAYMENT_HAWALA_LIST = 'payments::PAYMENTS_HAWALA_LIST';
export const PAYMENT_MISC_LIST = 'payments::PAYMENTS_MISC_LIST';

// Payment Details DUCKS
export const PAYMENT_DETAIL = 'payment_detail';

// Google OTP Quarantined ranges
export const GOOGLE_OTP_QUARANTINED_SET_LIST = 'GOOGLE_OTP_QUARANTINED_SET_LIST';
export const GOOGLE_OTP_QUARANTINED_SET_LOADING = 'GOOGLE_OTP_QUARANTINED_SET_LOADING';

// Google OTP Allocation
export const GOOGLE_OTP_ALLOCATION_SET_LIST = 'GOOGLE_OTP_ALLOCATION_SET_LIST';
export const GOOGLE_OTP_ALLOCATION_SET_LOADING = 'GOOGLE_OTP_ALLOCATION_SET_LOADING';

// Action Logs
export const ACTION_LOGS_LIST = 'ACTION_LOGS_LIST';
export const ACTION_LOGS_SET_LOADING = 'ACTION_LOGS_SET_LOADING';
export const ACTION_LOGS_REFERENCES = 'ACTION_LOGS_REFERENCES';

// AM Activities
export const AM_ACTIVITIES_SET_LOADING = 'AM_ACTIVITIES_SET_LOADING';
export const AM_ACTIVITIES_LIST = 'AM_ACTIVITIES_LIST';
export const AM_ACTIVITIES_DATE_LIST_ITEM = 'AM_ACTIVITIES_DATE_LIST_ITEM';
export const AM_ACTIVITIES_DATE_LIST_ITEM_REMOVE = 'AM_ACTIVITIES_DATE_LIST_ITEM_REMOVE';
export const AM_ACTIVITIES_DATE_LIST_REMOVE = 'AM_ACTIVITIES_DATE_LIST_REMOVE';

// Account Managers
export const ACCOUNT_MANAGERS_SET_LOADING = 'ACCOUNT_MANAGERS_SET_LOADING';
export const ACCOUNT_MANAGERS_LIST = 'ACCOUNT_MANAGERS_LIST';
export const ACCOUNT_MANAGERS_DROPDOWN_LIST = 'ACCOUNT_MANAGERS_DROPDOWN_LIST';
export const ACCOUNT_MANAGERS_USER_LIST = 'ACCOUNT_MANAGERS_USER_LIST';
export const ACCOUNT_MANAGERS_REFERENCES = 'ACCOUNT_MANAGERS_REFERENCES';
export const ACCOUNT_MANAGERS_BIL = 'ACCOUNT_MANAGERS_BIL';
export const ACCOUNT_MANAGERS_MODIFY = 'ACCOUNT_MANAGERS_MODIFY';
export const ACCOUNT_MANAGERS_INFORMATORS_LIST = 'ACCOUNT_MANAGERS_INFORMATORS_LIST';

// Special limit terms
export const PRICE_RANGE_QUARANTINE_SETTING_LOADING = 'PRICE_RANGE_QUARANTINE_SETTING_LOADING';
export const PRICE_RANGE_QUARANTINE_SETTING_LIST = 'PRICE_RANGE_QUARANTINE_SETTING_LIST';

// Account note
export const ACCOUNT_NOTE_LIST = 'ACCOUNT_NOTE_LIST';
export const ACCOUNT_NOTE_LOADING = 'ACCOUNT_NOTE_LOADING';

// New account
export const SET_GENERATE_NAME_LIST = "SET_GENERATE_NAME_LIST";

//Registration
export const REGISTRATION_LIST = 'REGISTRATION_LIST';
export const REGISTRATION_COUNT = 'REGISTRATION_COUNT';
export const REGISTRATION_ACCOUNT = 'REGISTRATION_ACCOUNT';
export const REGISTRATION_SET_LOADING = 'REGISTRATION_SET_LOADING';

export const REGISTRATION_REQUEST_STATUS_SENT = 0;
export const REGISTRATION_REQUEST_STATUS_APPROVED = 1;
export const REGISTRATION_REQUEST_STATUS_REJECTED = 2;

export const SET_DEFAULT_FORM_CREATE_ACCOUNT = 'SET_DEFAULT_FORM_CREATE_ACCOUNT';

export const SET_REDIRECT_TAB = 'SET_REDIRECT_TAB';

// IVR
export const IVR = "panel/IVR";
export const IVR_CLI = "panel/IVR_CLI";
export const IVR_SOUNDS = "panel/IVR_SOUNDS";
export const IVR_DEFAULT = "panel/IVR_DEFAULT";

// Traffic limits
export const TRAFFIC_LIMITS = 'TRAFFIC_LIMITS';


// CDR
export const CDR_ADMIN_PATHNAME = "/cdr";

// TRAFFIC REPORTS
export const TRAFFIC_ADMIN_PATHNAME = "/traffic";

/* TABLE_RESIZE */
export const LOCAL_STORAGE_TABLE_RESIZE = "TABLE_RESIZE";


/* CLIENT */
export const MIN_RATE_DIALER = 0;
export const MAX_RATE_DIALER = 9.9999;

export const MIN_PER_PAGE_DIALER = 1;
export const MAX_PER_PAGE_DIALER  = 10000;
export const LIMITED_PER_PAGE_DIALER  = 200;
export const DEFAULT_PER_PAGE_DIALER  = 15;
export const TWO_STAGE_DEFAULT_PER_PAGE_DIALER  = 11;
export const OTP_SERVICE_PLAN_KEY_DIALER  = 3;
export const SET_UPLOAD_LOADING_DIALER = 'SET_UPLOAD_LOADING_DIALER';
export const SET_INITED_REFERENCFES = 'SET_INITED_REFERENCFES';
export const LOCAL_STORAGE_USER_INFO_DIALER = 'userInfo';
export const REFERENCES_SERVICE_PLAN_DIALER = 'references::SERVICE_PLAN';

export const LOCAL_STORAGE_NUMBERS_FILTERS_DIALER = 'numbersTableFilterObject';
export const LOCAL_STORAGE_NUMBERS_PER_PAGE_DIALER = 'numbersPerPage';

// number
export const NUMBERS_DIALER = "NUMBERS_DIALER";
export const SET_ALL_ALLOCATED_NUMBER_DIALER = 'numbers::SET_ALL_ALLOCATED_NUMBER';
export const SET_ALLOCATED_NUMBER_DIALER = 'numbers::SET_ALLOCATED_NUMBER';
export const SET_TRUNK_NUMBER_DIALER = 'numbers::SET_TRUNKS';
export const SET_TRUNK_NUMBER_ALLOCATED_LOADING_DIALER = 'numbers::SET_TRUNK_ALLOCATED_LOADING';
export const SET_TRUNK_NUMBER_LOADING_DIALER = 'numbers::SET_TRUNK_LOADING';
export const SET_LOADING_NUMBERS_ALLOCATED_NUMBERS_DIALER = "numbers::SET_LOADING_NUMBERS_ALLOCATED_NUMBERS";
export const SET_UPLOAD_TRUNK_LOADING_DIALER = 'SET_UPLOAD_TRUNK_LOADING';
export const SET_TRUNK_NUMBER_TRANSACTION_DIALER = 'SET_TRUNK_NUMBER_TRANSACTION';

// postback logs
export const POSTBACK_LOGS = "POSTBACK_LOGS";
export const SET_POSTBACK_LOGS_LIST = 'postback_logs::SET_POSTBACK_LOGS_LIST';

// client auth
export const SET_ACCOUNT_DIALER_INFO = "SET_ACCOUNT_DIALER_INFO";
export const SET_ACCOUNT_READY_FOR_PAYMENT = 'SET_ACCOUNT_READY_FOR_PAYMENT';
export const SET_USER_LOCALIZATION = 'SET_USER_LOCALIZATION';

// client auth numbers
export const AUTH_NUMBERS_CLIENT = "client_auth_numbers";

// client access list filters
export const ACCESS_LIST_FILTERS_CLIENT = "client_access_list_filters";
export const SET_ACCESS_LIST_FILTERS_NOTIFICATION_DIALER = "SET_ACCESS_LIST_FILTERS_NOTIFICATION_DIALER";

// client numbers
export const SET_ACCOUNT_TRUNKS_LOADING_CLIENT = 'SET_ACCOUNT_TRUNKS_LOADING_CLIENT';
export const SET_ACCOUNT_TRUNKS_CLIENT = 'SET_ACCOUNT_TRUNKS_CLIENT';
export const SET_UPLOAD_TRUNK_NUMBER_LOADING = 'SET_UPLOAD_TRUNK_NUMBER_LOADING';

// client test calls
export const TEST_CALLS_DIALER = 'TEST_CALLS_DIALER';
export const LOCAL_STORAGE_TEST_CALLS_FILTERS_DIALER = 'testCallsTableFilterObject';
export const LOCAL_STORAGE_TEST_CALLS_PER_PAGE_DIALER = 'testCallsPerPage';

// client live calls
export const LIVE_CALLS_DIALER = 'LIVE_CALLS_DIALER';
export const LOCAL_STORAGE_LIVE_CALLS_FILTERS_DIALER = 'liveCallsTableFilterObject';
export const LOCAL_STORAGE_LIVE_CALLS_PER_PAGE_DIALER = 'liveCallsPerPage';
export const LOCAL_STORAGE_LIVE_CALLS_COLUMNS_DIALER = 'liveCallsTableColumns';

// client CDR
export const CDR_DIALER = 'CDR_DIALER';
export const LOCAL_STORAGE_CDR_FILTERS_DIALER = 'cdrTableFilterObject';
export const LOCAL_STORAGE_CDR_PER_PAGE_DIALER = 'cdrPerPage';
export const LOCAL_STORAGE_CDR_COLUMNS_DIALER = 'cdrTableColumns';

// client Traffic
export const TRAFFIC_STAT_DIALER = 'TRAFFIC_STAT_DIALER';
export const LOCAL_STORAGE_TRAFFIC_STAT_FILTERS_DIALER = 'tsTableFilterObject';
export const LOCAL_STORAGE_TRAFFIC_STAT_PER_PAGE_DIALER = 'tsPerPage';
export const LOCAL_STORAGE_TRAFFIC_STAT_COLUMNS_DIALER = 'tsTableColumns';

// client price
export const PRICE_DIALER = 'PRICE_DIALER';
export const LOCAL_STORAGE_PRICE_FILTERS_DIALER = 'priceTableFilterObject';
export const LOCAL_STORAGE_PRICE_PER_PAGE_DIALER = 'pricePerPage';
export const SET_LOADING_PRICE_ALLOCATED_NUMBERS_DIALER = 'price::SET_LOADING_PRICE_ALLOCATED_NUMBERS';

// client accesses
export const ACCESS_LIST_DIALER = 'ACCESS_LIST_DIALER';
export const LOCAL_STORAGE_ACCESSES_FILTERS_DIALER = 'accessesTableFilterObject';
export const LOCAL_STORAGE_ACCESSES_PER_PAGE_DIALER = 'accessesPerPage';
export const SET_LOADING_ACCESS_ALLOCATED_NUMBERS_DIALER = 'access_list::SET_LOADING_ACCESS_ALLOCATED_NUMBERS';

// manual access list
export const MANUAL_ACCESS_LIST = 'MANUAL_ACCESS_LIST';

// cli
export const CLI_DIALER = 'CLI_DIALER';
export const LOCAL_STORAGE_CLI_FILTERS_DIALER = 'cliTableFilterObject';

// Api page
export const API_PAGE_DIALER = 'API_PAGE_DIALER';
export const LOCAL_STORAGE_API_KEY_DIALER = 'apiPageKey';


// PAYMENT MANAGEMENT HAWALA
export const HAWALA_PAYMENT_MININUM = 0.01;
export const HAWALA_NOT_RECOMMENDET_PAYMENT = 100;


// Admin Panel Users
export const MANAGER_ACCOUNT_USER = "MANAGER_ACCOUNT_USER";


export const ACCOUNT_SERVICE_PLAN = "ACCOUNT_SERVICE_PLAN";


// News
export const NEWS = "NEWS";

// News Dialer Group
export const DIALER_GROUPS = "DIALER_GROUPS";

// Global access numbers
export const INCORPORATED_NUMBERS = "INCORPORATED_NUMBERS";
export const ACCESSES_LIST_CLIENT_PATHNAME = "/accesses";
export const GLOBAL_ACCESS_NUMBERS_CLIENT_PATHNAME = "/global-accesses-numbers";

// Mass revoke
export const MASS_REVOKE = "MASS_REVOKE";


// Global Access Numbers Suppliers
export const GAN_SUPPLIERS = "GAN_SUPPLIERS";

// Subaccounts
export const SET_SUB_ACCOUNT_LIST = "SET_SUB_ACCOUNT_LIST";
export const SET_SUB_ACCOUNT_LIST_LOADING = "SET_SUB_ACCOUNT_LIST_LOADING";

export const SET_SUB_ACCOUNT_DATA = "SET_SUB_ACCOUNT_DATA";
export const SET_SUB_ACCOUNT_DATA_LOADING = "SET_SUB_ACCOUNT_DATA_LOADING";

export const SET_SUB_ACCOUNT_USER_LIST = "SET_SUB_ACCOUNT_USER_LIST";
export const SET_SUB_ACCOUNT_USER_LIST_LOADING = "SET_SUB_ACCOUNT_USER_LIST_LOADING";

export const SET_SUB_ACCOUNT_NUMBER_LIST = "SET_SUB_ACCOUNT_NUMBER_LIST";
export const SET_SUB_ACCOUNT_NUMBER_LIST_LOADING = "SET_SUB_ACCOUNT_NUMBER_LIST_LOADING";

export const SET_SUB_ACCOUNT_PAYMENT_LIST = "SET_SUB_ACCOUNT_PAYMENT_LIST";
export const SET_SUB_ACCOUNT_PAYMENT_LIST_LOADING = "SET_SUB_ACCOUNT_PAYMENT_LIST_LOADING";

export const SET_RESELLER_NUMBER_LIST = "SET_RESELLER_NUMBER_LIST";
export const SET_RESELLER_NUMBER_LIST_LOADING = "SET_RESELLER_NUMBER_LIST_LOADING";

export const SET_RESELLER_TRAFFIC_CDR_LIST = "SET_RESELLER_TRAFFIC_CDR_LIST";
export const SET_RESELLER_TRAFFIC_CDR_LIST_LOADING = "SET_RESELLER_TRAFFIC_CDR_LIST_LOADING";

export const SET_RESELLER_ACCESS_LIST = "SET_RESELLER_ACCESS_LIST";
export const SET_RESELLER_ACCESS_LIST_LOADING = "SET_RESELLER_ACCESS_LIST_LOAIDNG";

export const SET_RESELLER_HOST_LIST = "SET_RESELLER_HOST_LIST";
export const SET_RESELLER_HOST_LIST_LOADING = "SET_RESELLER_HOST_LIST_LOADING";

export const SET_RESELLER_TEMPLATE_LIST = "SET_RESELLER_TEMPLATE_LIST";

// E164
export const E164 = "E164";

// E212
export const E212 = "E212";

export const REASON_CODES = {

    not_found_rate: "not_found_rate",
    allocation_blocked: "allocation_blocked",
    not_found_free_numbers: "not_found_free_numbers",
    not_enough_free_numbers: "not_enough_free_numbers",
    not_enough_free_numbers_sequence: "not_enough_free_numbers_sequence",
    account_trunk_range_mismatch: "account_trunk_range_mismatch",
    self_allocation_limit_exceeded: "self_allocation_limit_exceeded",
    self_allocation_for_sde_limit_exceeded: "self_allocation_for_sde_limit_exceeded",
    not_defined_range: "not_defined_range",

    prefix_in_quarantine: "prefix_in_quarantine",
    non_google_otp_allocation: "non_google_otp_allocation",
    max_numbers_per_range_value_exceeded: "max_numbers_per_range_value_exceeded",
    allocation_to_test_trunk: "allocation_to_test_trunk",
    non_google_otp_trunk: "non_google_otp_trunk",
    non_multiple_100_number: "non_multiple_100_number",
    not_allowed_self_google_otp_allocation: "not_allowed_self_google_otp_allocation",
    non_power_of_10_number: "non_power_of_10_number",
    non_power_of_10_number_start_1000: "non_power_of_10_number_start_1000",
    illegal_template_length: "illegal_template_length",
    illegal_template: "illegal_template",
    too_much_operations: "too_much_operations",
    too_much_operations_sde: "too_much_operations_sde",

    self_allocation_limit_exceeded_once: "self_allocation_limit_exceeded_once",
    self_allocation_limit_exceeded_daily: "self_allocation_limit_exceeded_daily",
    self_allocation_limit_exceeded_daily_sde: "self_allocation_limit_exceeded_daily_sde",
    self_allocation_limit_exceeded_all: "self_allocation_limit_exceeded_all",

    allocated: "allocated",

    // Incorporated
    wrong_sde: "wrong_sde",
    undefined_sde: "undefined_sde",
    different_sde: "different_sde",
    different_group: "different_group",
    invalid_number: "invalid_number",
};