import React, {useEffect, useState, useMemo} from "react";
import {Button, FormControl, Icon, InputNumber, Schema, SelectPicker, Table, Form} from "rsuite";
import styled from "styled-components";
import ModalSettingSubmitApprove from "./ModalSettingSubmitApprove";
import ModalSettingRemove from "./ModalSettingRemove";
import BaseTable from "../../components/base/BaseTable";

const {Cell, Column, HeaderCell} = Table;
const {NumberType} = Schema.Types;

const formModelPeriodTraffic = Schema.Model({
    period_traffic: NumberType()
        .isRequired('Required')
        .addRule((value) => value > 0, 'The number must be greater then 0'),
});

const formModelPeriodAllocation = Schema.Model({
    period_allocation: NumberType()
        .isRequired('Required')
        .addRule((value) => value > 0, 'The number must be greater then 0'),
});

const formModelPeriodAutoRevoke = Schema.Model({
    period_autorevoke: NumberType()
        .isRequired('Required')
        .addRule((value) => value > 0, 'The number must be greater then 0'),
});

const idKey = 'prqs_key';

export default ({
    data = [],
    loading,
    filter,
    worldzoneList,
    destinationList,
    subdestinationList,
    getPriceRangeQuarantineSettingList,
    modifyPriceRangeQuarantineSetting,
    removePriceRangeQuarantineSetting,
    ...props
}) => {

    const columns = useMemo(() => {
        return [
            {
                flexGrow: 2,
                dataKey: 'wz_key',
                dataValue: 'wz_name',
                label: 'Zone',

                // field props
                placeholder: 'Zone',
                data: worldzoneList,
                valueKey: "wz_key",
                labelKey: "name",
                accepter: SelectPicker,
            },
            {
                flexGrow: 2,
                dataKey: 'de_key',
                dataValue: 'de_name',
                label: 'Destination',

                // field props
                placeholder: 'Destination',
                data: destinationList,
                valueKey: "de_key",
                labelKey: "name",
                accepter: SelectPicker,
            },
            {
                flexGrow: 2,
                dataKey: 'sde_key',
                dataValue: 'sde_name',
                label: 'Subdestination',

                // field props
                placeholder: 'Subdestination',
                data: subdestinationList,
                valueKey: "sde_key",
                labelKey: "name",
                accepter: SelectPicker,
            },
            {
                flexGrow: 1,
                dataKey: 'period_traffic',
                label: 'From last call',
                model: formModelPeriodTraffic,

                // field props
                placeholder: 'From last call ',
                min: 0.01,
                max: Infinity,
                accepter: InputNumber,
            },
            {
                flexGrow: 1,
                dataKey: 'period_allocation',
                label: 'From allocation',
                model: formModelPeriodAllocation,

                // field props
                placeholder: 'From allocation',
                min: 0.01,
                max: Infinity,
                accepter: InputNumber,
            },
            {
                flexGrow: 1,
                dataKey: 'period_autorevoke',
                label: 'Autorevoke period',
                model: formModelPeriodAutoRevoke,

                // field props
                placeholder: 'Autorevoke period',
                min: 0.01,
                max: Infinity,
                accepter: InputNumber,
            }
        ]
    }, [worldzoneList, destinationList, subdestinationList]);

    const refs = new Map();

    const [editedRows, setEditedRows] = useState(new Map());
    const [rowsLoading, setRowsLoading] = useState([]);

    const [modalRemoveData, setModalRemoveData] = useState(null);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [removeDataLoading, setRemoveDataLoading] = useState(null);

    const [modalApproveData, setModalApproveData] = useState(false);
    const [showModalApprove, setShowModalApprove] = useState(false);
    const [modalApproveLoading, setModalApproveLoading] = useState(null);

    useEffect(() => {
        const editedRowsCopy = new Map(editedRows);
        const rowsLoadingCopy = new Set(rowsLoading);
        for (const editedRowData of editedRows) {
            const rowData = data.find(item => item[idKey] === editedRowData[0]);
            if (rowData && JSON.stringify(rowData) !== JSON.stringify(editedRowData[1])) {
                editedRowsCopy.delete(rowData[idKey]);
                rowsLoadingCopy.delete(rowData[idKey]);
            }
        }
        setEditedRows(editedRowsCopy);
        setRowsLoading(Array.from(rowsLoadingCopy));
    }, [data]);


    const modifyRow = async (rowData) => {

        // check form
        const rowForms = refs.get(rowData[idKey]);
        for ( const form of Object.keys(rowForms) ) {
            if (!rowForms[form].check()) {
                return;
            }
        }

        // get params
        const params = Object.keys(rowForms)
            .reduce( (sum, current) => (
                {...sum, [current]: rowForms[current].getFormValue()[current]}
            ), {[idKey]: rowData[idKey]} );

        // check differences
        const hasDifferences = Object.keys(params)
            .reduce( (sum, current) => {
                if (sum === true)
                    return sum;
                const value = ( rowData[current] !== null && !isNaN(rowData[current]) )
                    ? +params[current]
                    : params[current];
                return rowData[current] !== value;
            }, false );

        if (!hasDifferences) {
            setRowEditing(rowData, false);
            setRowLoading(rowData, false);
            return;
        }

        // Approve modal
        if (!params.wz_key && !params.de_key && !params.sde_key) {
            setShowModalApprove(true);
            setModalApproveData(params);
            return;
        }

        // approve modal with text and condition and on submit, remove loading when open. Modal is hear
        // optimize refs array. share callbacks (row edit loading) in function except calss component
        setRowLoading(rowData, true);
        const res = await modifyPriceRangeQuarantineSetting(params);
        if (!res) {
            setRowLoading(rowData, false);
            return;
        }

        getPriceRangeQuarantineSettingList(filter);
    };


    const onApprovedSubmit = async () => {
        setModalApproveLoading(true);
        await modifyPriceRangeQuarantineSetting(modalApproveData);
        getPriceRangeQuarantineSettingList(filter);
        setModalApproveLoading(false);
        setShowModalApprove(false);
    };


    const deleteRow = async () => {
        setRemoveDataLoading(true);
        const res = await removePriceRangeQuarantineSetting(modalRemoveData[idKey]);
        if (res) {
            await getPriceRangeQuarantineSettingList(filter);
            setRemoveDataLoading(false);
            setShowRemoveModal(false);
            setModalRemoveData(null);
        }
    };


    const setRowEditing = (rowData, status) => {
        const editedRowsCopy = new Map(editedRows);
        if ( editedRows.has(rowData[idKey]) && !status) {
            editedRowsCopy.delete(rowData[idKey]);
        } else if (!editedRows.has(rowData[idKey] && status)) {
            editedRowsCopy.set(rowData[idKey], rowData)
        }
        setEditedRows(editedRowsCopy);
    };


    const setRowLoading = (rowData, status) => {
        if (!status) {
            setRowsLoading( rowsLoading.filter(item => item !== rowData[idKey]) );
            return;
        }
        setRowsLoading([...rowsLoading, rowData[idKey]]);
    };


    return (
        <>
            <StyledTable
                className={'tableFilters'}
                shouldUpdateScroll={false}
                headerHeight={46}
                rowHeight={46}
                autoHeight
                {...{
                    data,
                    loading
                }}
                {...props}
            >

                {columns.map( ({dataKey, dataValue, flexGrow = null, minWidth = 130, model, label = "", type, ...fieldProps}) => (
                    <Column {...{flexGrow, minWidth}}>
                        <HeaderCell>
                            <span className="tableFilters__headerText">{label}</span>
                        </HeaderCell>
                        <Cell {...{dataKey}}>
                            {(rowData) => {
                                if ( editedRows.has(rowData[idKey]) ) {
                                    return (
                                        <Form
                                            ref={ref => {
                                                if (ref) {
                                                    if (refs.has(rowData[idKey])) {
                                                        const currentRefsCopy = refs.get(rowData[idKey]);
                                                        refs.set(rowData[idKey], {
                                                            ...currentRefsCopy,
                                                            [dataKey]: ref
                                                        });
                                                        return;
                                                    }
                                                    refs.set(rowData[idKey], {[dataKey]: ref});
                                                }
                                            }}
                                            model={model}
                                            formDefaultValue={{[dataKey]: rowData[dataKey]}}
                                        >
                                            <EditField
                                                name={dataKey}
                                                {...fieldProps}
                                            />
                                        </Form>
                                    );
                                }

                                return <span className="tableFilters__previewText">{rowData[dataValue || dataKey]}</span>
                            }}
                        </Cell>
                    </Column>
                ))}

                <Column width={174}>
                    <HeaderCell></HeaderCell>
                    <Cell>
                        {rowData => {
                            const rowLoading = rowsLoading.includes(rowData[idKey]);
                            return <div className="tableFilters_buttons">

                                {!editedRows.has(rowData[idKey])
                                    ? <Button
                                        size="sm"
                                        color="lightblue"
                                        disabled={rowLoading}
                                        onClick={() => setRowEditing(rowData, true)}
                                    >
                                        <Icon icon="edit2"/>
                                    </Button>
                                    : <>
                                        <Button
                                            size="sm"
                                            color="green"
                                            disabled={rowLoading}
                                            onClick={() => modifyRow(rowData)}
                                        >
                                            <Icon icon="check-circle"/>
                                        </Button>

                                        <Button
                                            size="sm"
                                            color="red"
                                            disabled={rowLoading}
                                            onClick={() => setRowEditing(rowData, false)}
                                        >
                                            <Icon icon="close-circle"/>
                                        </Button>
                                    </>
                                }

                                <Button
                                    size="sm"
                                    color="red"
                                    disabled={rowLoading}
                                    onClick={() => {
                                        setModalRemoveData(rowData);
                                        setShowRemoveModal(true);
                                    }}
                                >
                                    <Icon icon="trash2"/>
                                </Button>
                            </div>
                        }}
                    </Cell>
                </Column>

            </StyledTable>

            <ModalSettingRemove
                show={showRemoveModal}
                onSubmit={deleteRow}
                onClose={() => setShowRemoveModal(false)}
                disabled={removeDataLoading}
            />

            <ModalSettingSubmitApprove
                show={showModalApprove}
                onSubmit={onApprovedSubmit}
                onClose={() => setShowModalApprove(false)}
                disabled={modalApproveLoading}
            />

        </>
    )
}

const StyledTable = styled(BaseTable)`
    && {
    
        .tableFilters__previewText {
            display: block;
            line-height: 20px;
            margin-top: 7px;
            word-break: normal;
        }
        
        .tableFilters_buttons {
            height: 20px;
            margin-top: 7px;
        }
    }
`;

const EditField = styled(FormControl).attrs(() => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    type: "text",
    cleanable: true,
    searchable: true,
}))`
`;

