import React, {useMemo} from "react";
import TableCommonNew from "../../components/Table/TableCommonNew";
import { ButtonPrimary } from "../../components/base/BaseButton";
import { FlexGrid, FlexGridItem } from "../../components/base/FlexGrid";
import {servicePick} from "../../utils/helpers";


export default React.forwardRef((
    {
        data,
        rowKey,
        service,
        selected,
        revokedDataInfo,
        onRevoke,
        setModalMassRevokeShow,
        ...props
    }, ref) => {

    const columns = useMemo(() => {
        return [
            {
                id: 'dialer',
                value: ({account_name, account_manager_name}) => `${account_name} / ${account_manager_name}`,
                label: 'Dialer / AM',
                flexGrow: 1,
                minWidth: 200,
            },
            {
                id: 'trunk',
                value: ({trunk_name, service_plan_name, ...props}) => `${servicePick(service, trunk_name, props['sms.trunk_name'])} / ${service_plan_name}`,
                label: 'Trunk / Price',
                flexGrow: 1,
                minWidth: 200,
            },
            {
                id: 'numbers',
                value: ({numbers_to_revoke, numbers_all}) => `${numbers_to_revoke} / ${numbers_all}`,
                label: 'Numbers to revoke / All numbers',
                flexGrow: 1,
                minWidth: 200,
            },
        ]
    }, [service]);
        

    return (
        <>
        <TableCommonNew
            virtualized
            ref={ref}
            data={data}
            columns={columns}
            originalColumns={columns}
            shouldUpdateScroll={false}
            row_key={rowKey}
            extraHeight={(data?.length && data.length < 19) ? (data.length * 30) + 40 : 610}

            {...props}
        />
            <FlexGrid>
                <FlexGridItem grow={1}>
                    <p
                        style={{
                            color: 'var(--color-disabled)',
                            marginTop: 10,
                        }}
                    >
                        Selected {revokedDataInfo.numbers} numbers from {revokedDataInfo.accounts} accounts
                    </p>
                </FlexGridItem>
                <FlexGridItem>
                    <ButtonPrimary
                        disabled={!selected.list.length && !selected.all}
                        onClick={() => setModalMassRevokeShow(true)}
                    >
                        Revoke selected
                    </ButtonPrimary>
                </FlexGridItem>
            </FlexGrid>
        </>
    )
})