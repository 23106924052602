import React from "react";
import styled from "styled-components";
import {SelectPicker, Uploader, Icon, FormControl} from "rsuite";
import {service_data, transformed_service_data} from "../../../const";
import * as S from "./styled";


export const FieldInput = ({name, label, type, ...props}) => (
    <S.Group>
        <S.LabelWrapper>
            <S.Label>{label}</S.Label>
        </S.LabelWrapper>
        <S.Field name={name} {...props}/>
    </S.Group>
);

export const FieldFile = ({name, label, type, defaultFileList, ...props}) => (
    <S.Group>
        <S.LabelWrapper 
            vAlign="flex-start" 
            top={18}
        >
            <S.Label>{label}</S.Label>
        </S.LabelWrapper>

        <S.FileUploader 
            name={name}
            defaultFileList={defaultFileList || []}
            accepter={Uploader}
            {...props}
        >
            <button><Icon icon='file-image-o' size="2x" /></button>
        </S.FileUploader>
    </S.Group>
);

export const FieldSelect = ({name, label, type, data, valueKey, labelKey, ...props}) => (
    <S.Group>
        {label &&
            <S.LabelWrapper>
                <S.Label>{label}</S.Label>
            </S.LabelWrapper>
        }
        <S.Field
            name={name}
            accepter={SelectPicker}
            cleanable={false}
            data={data}
            valueKey={valueKey}
            labelKey={labelKey}
            {...props}
        />
    </S.Group>
);