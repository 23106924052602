import React from "react";
import styled from "styled-components";
import {SelectPicker, Uploader, Icon, FormControl} from "rsuite";
import {service_data, transformed_service_data} from "../../../const";
import * as S from "./styled";


export const FieldInput = ({name, label, type, ...props}) => (
    <S.Group>
        {label &&
            <S.LabelWrapper>
                <S.Label>{label}</S.Label>
            </S.LabelWrapper>
        }
        <S.Field name={name} {...props}/>
    </S.Group>
);


export const FieldTextArea = React.forwardRef(({name, label, type, data, ...props}, ref) =>  (
    <S.Group>
        {label &&
            <S.LabelWrapper>
                <S.Label>{label}</S.Label>
            </S.LabelWrapper>
        }
        <div className="rs-form-control-wrapper">
            <S.FieldTextAreaAutoResize
                ref={ref}
                className={`rs-input ${props.className}`}
                name={name}
                {...props}
            />
        </div>
    </S.Group>
));


export const FieldSelect = ({name, label, type, data, valueKey, labelKey, ...props}) => (
    <S.Group>
        {label &&
            <S.LabelWrapper>
                <S.Label>{label}</S.Label>
            </S.LabelWrapper>
        }
        <S.Field
            name={name}
            accepter={SelectPicker}
            cleanable={false}
            data={data}
            valueKey={valueKey}
            labelKey={labelKey}
            {...props}
        />
    </S.Group>
);


export const FieldFile = ({name, label, type, defaultFileList, ...props}) => (
    <S.Group>
        {label &&    
            <S.LabelWrapper 
                vAlign="flex-start" 
                top={18}
            >
                <S.Label>{label}</S.Label>
            </S.LabelWrapper>
        }

        <S.FileUploader 
            name={name}
            defaultFileList={defaultFileList || []}
            accepter={Uploader}
            {...props}
        >
            <button>
                <Icon icon='file-image-o' size="2x" />
            </button>
        </S.FileUploader>
    </S.Group>
);


export const FieldOrganizationType = ({label, data = [], ...props}) => (
    <S.Group>
        {label &&
            <S.Label>{label}</S.Label>
        }
        <FormControl
            name="ot_key"
            accepter={SelectPicker}
            data={data}
            labelKey="name"
            valueKey="ot_key"
            cleanable={false}
            searchable={false}
            {...props}
        />
    </S.Group>
);


export const FieldPaymentMethod = ({label, data = [], ...props}) => (
    <S.Group>
        {label &&
            <S.Label>{label}</S.Label>
        }
        <FormControl
            name="pm_key"
            accepter={SelectPicker}
            data={data}
            labelKey="name"
            valueKey="pm_key"
            cleanable={false}
            searchable={false}
            {...props}
        />
    </S.Group>
);

export const FieldService = ({label, joinList = [], ...props}) => {
    const data = [...service_data];
    const accountServices = joinList.map(account => account?.service);
    const currentData = data.filter(service => {
        return accountServices.includes(service.value)
    });

    return (<S.Group>
        {label &&
            <S.LabelWrapper>
                <S.Label>{label}</S.Label>
            </S.LabelWrapper>
        }
        <FormControl
            name="service"
            accepter={SelectPicker}
            data={currentData}
            labelKey="name"
            valueKey="value"
            cleanable={false}
            searchable={false}
            {...props}
        />
    </S.Group>)
};

export const FieldCurrency = ({label, data = [], ...props}) => (
    <S.Group>
        {label &&
            <StyledLabelWrapper>
                <S.Label>{label}</S.Label>
            </StyledLabelWrapper>
        }
        <FormControl
            name="cur_key"
            accepter={SelectPicker}
            data={data}
            labelKey="name"
            valueKey="cur_key"
            cleanable={false}
            searchable={false}
            {...props}
        />
    </S.Group>
);

const StyledLabelWrapper = styled(S.LabelWrapper)`
    && {
        width: auto;
    }
`;

export default FieldInput