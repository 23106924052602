import React, { useRef, useMemo, useState, useEffect } from "react";
import TableServerSort from "components/Table/TableServerSort";
import { Button } from "rsuite";
import { DEFAULT_PER_PAGE_MAX } from "const";
import styled from "styled-components";

import m from "definedMessages";


const NumbersTable = ({
    data,
    page,
    count,
    perPage,
    loading,
    isMobile,
    
    setSort,

    allAllocated,
    clearSelected,

    setSelectedData,
    setSelectedItems,
    setAllAllocated,
    
    currentTrunkId = null,

    getItems,
    formatMessage
}) => {

    const tableRef = useRef(null);

    const columns = useMemo(() => [
        {
            label: formatMessage(m.range), 
            dataKey: "name", 
            sortable: true, 
            value: ({name}) => {
                return <span className="table-centered__cell">
                    {name}
                </span>
            },
            flexGrow: isMobile ? 1 : 2,
            minWidth: 196,
            minWidthMobile: 300
        },
        {
            label: formatMessage(m.number), 
            dataKey: "number", 
            sortable: true,
            value: ({number}) => {
                return <span className="table-centered__cell">
                    {number}
                </span>
            },
            flexGrow: 1,
            minWidth: 196,
            minWidthMobile: 160,
            align: isMobile ? "left" : "right"
        },
        {
            label: formatMessage(m.payout),
            dataKey: "rate",
            sortable: true,
            value: ({rate, currency_name}) => {
                const result = `${rate} ${currency_name}`;
                return <span className="table-centered__cell">
                    {result}
                </span>
            },
            flexGrow: 1,
            minWidth: 100,
            align: isMobile ? "left" : "center"
        },
        {
            label: formatMessage(m.defaultPayout),
            dataKey: "discount",
            value: ({subacc_rate_default_week, subacc_rate_default_month, currency_name}) => {
                const weekRate = subacc_rate_default_week;
                const monthRate = subacc_rate_default_month;
                if (isMobile) {
                    return <div className="table-two-staged__cell same">
                        <div className="table-two-staged__cell-first">{formatMessage(m.weekly)}: -{weekRate} {currency_name}</div>
                        <div className="table-two-staged__cell-second">{formatMessage(m.monthly)}: -{monthRate} {currency_name}</div>
                    </div>
                }

                return <span className="table-centered__cell">
                   {formatMessage(m.weekly)}: {weekRate} {currency_name}, {formatMessage(m.monthly)}: {monthRate} {currency_name}
                </span>
            },
            sortable: false,
            flexGrow: isMobile ? 1 : 2,
            minWidth: 140,
            align: isMobile ? "left" : "center"
        },
        {
            label: formatMessage(m.allocatedTo),
            dataKey: "subacc_name",
            value: ({subacc_name, subacc_rate, currency_name}) => {
                const name = subacc_name;
                const rate = `${subacc_rate} ${currency_name}`;
                const result = name ?  `${name}/${rate}` : "-";
                return <span className="table-centered__cell">
                    {result}
                </span>
            },
            sortable: false,
            flexGrow: 1,
            minWidth: 140,
            align: "center"
        }
    ], [isMobile]);

    return <StyledTable
        shouldUpdateScroll={false}
        ref={tableRef}
        data={data}
        // height="50%"
        loading={loading}
        columns={columns}
        originalColumns={columns}
        count={count}
        per_page={perPage}
        perPageMaximum={DEFAULT_PER_PAGE_MAX}
        page={page}

        // extraHeight={isMobile ? 208 : 180}
        rowHeight={isMobile ? 42 : 30}

        row_key="number"
        
        disabled={!currentTrunkId}

        showedCount={count}
        ispagination
        isselected
        isSelectedAll

        getItems={getItems}

        setSelected={(selected) => {
            if (selected.list && selected.list.length) {
                selected.list.forEach((number) => {
                    setSelectedData((selectedData) => {
                        const selectedDataNumberList = selectedData.map((selectedValue) => selectedValue.number);
                        const dataNumberList = data.map((value) => value.number);
        
                        if (!selectedDataNumberList.includes(number) && dataNumberList.includes(number)) {
                            return [...selectedData, data.find((value) => value.number === number)]
                        }

                        return selectedData.filter(_data => selected.list.includes(_data.number));
                    });
                });
            } else {
                setSelectedData([]);
            }

            if (!allAllocated && !!selected.all || selected.list.length) {
                setAllAllocated(selected.all);
            }
            setSelectedItems(selected);
        }}

        clearSelected={clearSelected}

        onSort={(column, type) => setSort({column, type})}
        isMobile={isMobile}
    />
};

export default NumbersTable;

const StyledTable = styled(TableServerSort)`
    && {
        .table-centered__cell {
            line-height: ${props => props.isMobile ? "42px" : "30px"};
        }

        .rs-table-cell-content {
            padding: 0 0 0 10px !important;
        }
        .rs-table-cell-content > .rs-checkbox {
            top: 0px !important;
        }
        
        .rs-table-row-header .rs-table-cell-content {
            line-height: 40px;
        }

        .table-two-staged__cell.same {
            .table-two-staged__cell-first {
                font-size: 13px;
                color: #575757;
            }
            .table-two-staged__cell-second {
                font-size: 13px;
                color: #575757;
            }
        }

        margin-bottom: 20px;
    }
`;