import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {DateRangePicker, Schema} from 'rsuite';
import FormHOC from '../../hoc/FilltersForm';
import {removeTZFromDate, debounce} from '../../utils';
import CustomSelfClearedForm from '../../components/client/Form/CustomSelfClearedForm';
import {useIntl} from "react-intl";
import {compose} from "redux";
import {useWindowWidth} from "../../hooks";
import {FlexGrid, FlexGridItem} from '../../components/base/FlexGrid';
import CustomField from '../../components/client/Form/CustomField/CustomField';
import m from "../../definedMessages";
import {BaseDateRangePicker, BaseInputField} from "../../components/base/BaseForm";
import {RtlContext} from "../../App";
import DatePickerCustom from '../../components/Filters/DatePickerCustom';

const {combine, before, after} = DateRangePicker;


const TestCallsFilters = (
    {
        loading,
        service,
        filtersModel,
        onChangeFilters, 
        savedTableFilter, 
        ...props
    }
) => {

    const {formatMessage} = useIntl();
    const resizedWidth = useWindowWidth();


    // let form;

    const todayDateStart = new Date(new Date().setHours(0, 0, 0, 0));
    const todayDateEnd = new Date(new Date().setHours(23, 59, 59, 999));

    const defaultFilter = {
        start_end_date: [todayDateStart, todayDateEnd],
        start_date: new Date(),
        end_date: new Date(),
        ...(service ? {a_number: ''} : {senderid: ''}),
        ...(service ? {b_number: ''} : {phone: ''})
    };

    const [changedFormValue, changeFormData] = useState(savedTableFilter || defaultFilter);

    useEffect(() => {
        changeFormData(defaultFilter);
    }, [service]);


    const onSubmit = (data, checkedData) => {
        const checker = Object.keys(checkedData).map(i => checkedData[i].hasError).every(value => value === false);

        if (checker) {
            onChangeFilters({
                ...data,
                start_date: removeTZFromDate(data.start_date),
                end_date: removeTZFromDate(data.end_date),
            });
        }

        return false;
    };

    return (
        <CustomSelfClearedForm
            // ref={ref => (form = ref)}
            clearOn={service}
            model={filtersModel}
            formDefaultValue={defaultFilter}
            onChange={debounce((data) => {
                const startDate = new Date(new Date(data.start_end_date[0]).setHours(0, 0, 0, 0));
                const endDate = new Date(new Date(data.start_end_date[1]).setHours(23, 59, 59, 999));
                const formData = {...data, start_date: startDate, end_date: endDate};
                const checkedData = filtersModel.check(formData);
                onSubmit(formData, checkedData);
                changeFormData(formData);
            }, 1000)}
            fluid
            readOnly={loading}
        >

            <FlexGrid align="middle">

                {
                    (resizedWidth > 912) &&
                        <FlexGridItem>{formatMessage(m.fromDate)}</FlexGridItem>
                }
                <FlexGridItem width={resizedWidth > 767 ? "230px" : "100%"}>
                    <DatePickerCustom
                        isDisabledDates
                        changedFormValue={changedFormValue}
                        showRangeForSevenDays={false}
                    />
                </FlexGridItem>

                <FlexGridItem width={resizedWidth > 767 ? "230px" : "100%"}>
                    <CustomField
                        // formValue={changedFormValue?.senderid}
                        accepter={BaseInputField}
                        name={service ? "a_number" : "senderid"}
                        errorPlacement="topEnd"
                        placeholder={service ? formatMessage(m.aNumber) : formatMessage(m.senderId)}
                    />
                </FlexGridItem>

                <FlexGridItem width={resizedWidth > 767 ? "230px" : "100%"}>
                    <CustomField
                        accepter={BaseInputField}
                        name={service ? "b_number" : "phone"}
                        errorPlacement="topEnd"
                        placeholder={formatMessage(m.bNumber)}
                    />

                </FlexGridItem>
            </FlexGrid>
        </CustomSelfClearedForm>
    );
};

export default compose(
    FormHOC
)(TestCallsFilters)