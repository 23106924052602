import React from 'react';
import styled from 'styled-components';

export const Header = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-main);
    padding: 15px 0px;
     div {
        display: inline-block;
        width: 50%;
     }
     div:last-child{
         text-align: right
     }
`;
