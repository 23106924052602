import React, { useMemo, useState, useEffect } from "react";
import Page from "components/base/Page";
import PageHeader from "components/base/PageHeader";

import SettingsTopPanel from "./SettingsTopPanel";
import SettingsTemplateMenu from "./SettingsTemplateMenu";
import { useIntl } from "react-intl";
import { useWindowWidth } from "hooks";

import m from "definedMessages";

const localTemplateList = [
    {
        id: "0",
        dir: "templ1"
    },
    {
        id: "1",
        dir: "templ2"
    },
    {
        id: "2",
        dir: "templ3"
    }
];

const Settings = ({
    loading,
    hostList,
    templateList,
    accountJoinList,

    getSubAccTemplateList,
    getSubAccHostList,
    createSubAccHost,
    modifySubAccHost
}) => {

    const windowWidth = useWindowWidth();
    const issueWidth = windowWidth < 876;

    const {formatMessage} = useIntl();

    const accountData = useMemo(() => {
        return accountJoinList.find((account) => {
            return account.service === "voice";
        })
    }, [accountJoinList]);

    const [formDomain, setFormDomain] = useState({host: null})
    const [currentHost, setCurrentHost] = useState(null);
    const [templateKey, setTemplateKey] = useState(null);

    useEffect(() => {
        update();
    }, [accountData]);

    const update = () => {
        if (accountData?.id) {
            getSubAccHostList(accountData.id);
            getSubAccTemplateList(accountData.id);
        }
    };

    useEffect(() => {
        const lastHost = hostList.length ? hostList[hostList.length-1] : null;

        if (lastHost) {
            setCurrentHost(lastHost);
            setFormDomain({host: lastHost.host});

            if (lastHost.template && lastHost.template.template_key) {
                setTemplateKey(lastHost.template.template_key);
            }
        }
    }, [hostList]);

    
    useEffect(() => {
        // if (!templateKey && templateList.length) {
        //     const firstTemplate = templateList.length ? templateList[0] : null;
        //     if (firstTemplate) {
        //         setTemplateKey(firstTemplate.template_key);
        //     }
        // }
    }, [templateList]);


    const jointTemplateList = useMemo(() => {
        return localTemplateList.map((localTemplate) => {
            const actualTemplate = templateList.find((template) => template.id === localTemplate.id) || {};

            return {
                ...actualTemplate,
                ...localTemplate
            };
        })
    }, [templateList]);

    return <Page>
        <PageHeader title={formatMessage(m.configureDomain)} showService={false}></PageHeader>
        <SettingsTopPanel
            accountData={accountData}
            currentHost={currentHost}

            templateKey={templateKey}
            formValue={formDomain}
            setFormValue={(value) => {
                setFormDomain(value);
            }}

            update={update}
            createHostFunc={createSubAccHost}
            modifyHostFunc={modifySubAccHost}
            formatMessage={formatMessage}
        />
        <SettingsTemplateMenu
            loading={loading}
            issueWidth={issueWidth}
            currentHost={currentHost}
            templateKey={templateKey}
            templateList={jointTemplateList}
            setTemplateKey={setTemplateKey}
            formatMessage={formatMessage}
        />
    </Page>
};

export default Settings;