import React from 'react';
import Table from '../../components/Table';

import {useWindowHeight} from '../../hooks';

const RulesTable = ({
    data,
    ...props
}) => {

    const columns = [
        {
            flexGrow: 2,
            id: 'senderid',
            label: 'Sender',
            dataKey: 'senderid',
        },
        {
            flexGrow: 2,
            id: 'sender_name',
            label: 'Origin',
            value: (({sender_name}) => sender_name || '—'),
            dataKey: 'sender_name',
        },
        {
            flexGrow: 2,
            id: 'phone',
            label: 'Phone',
            dataKey: 'phone',
        },
        {
            flexGrow: 4,
            id: 'message',
            label: 'Message',
            dataKey: 'message',
            placeholder: 'Message',
        },
        {
            flexGrow: 1,
            id: 'cnt',
            align: 'center',
            label: 'Count',
            dataKey: 'cnt',
            placeholder: 'Count'
        }
    ];

    const resizeHeight = useWindowHeight();

    return (
        <Table
            virtualized
            data={data}
            extraHeight={500}
            columns={columns}
            headerHeight={40}
            autoHeight={false}
            errorPlacement={"bottom"}
            {...props}
        />
    )
};

export default RulesTable;


// const StyledTable = styled(TableEditCellsNew)`
//     && {
//         box-shadow: none;
//         border-radius: 5px;
//         margin-top: 10px;
//         margin-bottom: 10px;
        
//         .rs-table-row {
//             &.rs-table-row-header {
            
//                 &,
//                 & .rs-table-cell {
//                     background: #fff !important;
//                     color: #fff !important;
//                 }
//             }
            
//             &.row-active {
//                 color: var(--color-darkgrey);
                
//                 .rs-table-cell {
//                     background: var(--color-row-active);
//                 }                
//             }
            
//             &.row-closed {
//                 color: #6a686a;
                
//                 .rs-table-cell {
//                     background: #d4d4d4;
//                 }            
//             }
            
//             &.row-disabled {
            
//                 .rs-input,
//                 .rs-input-number {
//                     background: transparent;
//                 }
                
//                 .rs-table-cell {
//                     background: #d6d6db;
//                 }
                
//                 &:hover .rs-table-cell {
//                     background: #d6d6db !important;
//                 }
//             }
//         }
//     }
    
//     &&.rs-table {
//         border-radius: 4px;
//     }
//     .rs-table-row.rs-table-row-header {
//         font-weight: 700;
//         border-bottom: 1px solid var(--color-bg);
//     }
    
//      .rs-table-row-header .rs-table-cell-content {
//         color: var(--color-darkgrey) !important;
//         line-height: 10px;
//     }

//     .rs-table-body-row-wrapper .rs-table-cell-content {
//         padding: 5px 10px !important;
//     }
// `;