import React, {useEffect, useState} from "react";
import {Button, Icon, Table} from "rsuite";
import styled from "styled-components";
import ModalRejectRegistration from "./ModalRejectRegistration";

import {selectableFields} from "../Login/RegistrationFormFields";
import {
    REGISTRATION_REQUEST_STATUS_APPROVED,
    REGISTRATION_REQUEST_STATUS_REJECTED,
    REGISTRATION_REQUEST_STATUS_SENT
} from "const";
import {Link} from "react-router-dom";
import {servicePick} from "utils";
import BaseTable from "components/base/BaseTable";

const {Cell, Column, HeaderCell} = Table;


export default ({
    dataList,
    filter,
    currencyList,
    onSetRedirectTab,
    rejectRegistration,
    getRegistrationList,
    getRegistrationCount,
    setDefaultFormCreateAccount,


    loading,

}) => {

    const [dataListState, onChangeDataList] = useState(dataList);
    const allRequestStatusList = [
        REGISTRATION_REQUEST_STATUS_SENT,
        REGISTRATION_REQUEST_STATUS_APPROVED,
        REGISTRATION_REQUEST_STATUS_REJECTED
    ];

    useEffect(() => {
        if (!filter)
            onChangeDataList(dataList)

        if (!filter.processed) {
            onChangeDataList(dataList.filter(value => value.status === REGISTRATION_REQUEST_STATUS_SENT))
        } else if (filter.processed) {
            onChangeDataList(dataList.filter(value => allRequestStatusList.includes(value.status)))
        }
    }, [dataList, filter.processed])

    const columns = [
        {
            flexGrow: 2,
            label: "Name",
            value: ({name}) => {
                return <span>{name}</span>
            }
        },
        {
            flexGrow: 3,
            label: "Contacts",
            value: ({email, skype}) => {
                return <span>
                    {getColumnKeyValueString({email: email, skype: skype})}
                </span>
            }
        },
        {
            flexGrow: 3,
            label: "Detail",
            value: ({cntr_code, cur_key, month_traffic_minute, traffic_type, comment}) => {
                let currencyName = cur_key;
                if (cur_key) {
                    const dataListValue = currencyList.find(value => value["cur_key"] === cur_key);
                    currencyName = dataListValue && dataListValue.name;
                }

                let trafficTypeValue = traffic_type;
                if (traffic_type !== null && traffic_type.toString()) {
                    const dataListValue = selectableFields["select_traffic_type"].find(value => {
                        return value.id === trafficTypeValue
                    });
                    trafficTypeValue = dataListValue && dataListValue.name;
                }
                return <span>
                    {getColumnKeyValueString({country: cntr_code, currency: currencyName, "monthly traffic": month_traffic_minute, "traffic type": trafficTypeValue, "how found": comment})}
                </span>
            }
        },
        {
            flexGrow: 2,
            label: "Comment",
            value: ({rejected_at, rejected_manager_name, account}) => {
                const accountId = account?.id;
                const accountName = account?.name;
                const existingLink = `/accounts/view/${accountId}`;
                const linkedAccountName = accountName ? <Link to={existingLink} onClick={() => {onSetRedirectTab("general")}}>{accountName}</Link> : null;
                const date = createFormatDate(rejected_at);
                return <span>
                    {getColumnKeyValueString(
                        {
                            "Email already exist in": linkedAccountName,
                            "rejected at": date,
                            "manager": rejected_manager_name}
                        )}
                </span>
            }
        },
        {
            flexGrow: 1,
            label: "Service",
            value: ({voice, sms}) => {
                const services = `${voice && sms ? "Voice, SMS" : voice && !sms ? "Voice" : !voice && sms ? "SMS" : ""}`;
                return services;
            },
            minWidth: 120,
            align: "center"
        },
        {
            flexGrow: 1,
            label: "Created at",
            value: ({created_at}) => {
                const date = createFormatDate(created_at);
                return <span>{date}</span>
            }
        }
    ];

    const [showRejectModal, setShowRejectModal] = useState(false);
    // const [showExtended, onShowExtended] = useState({});

    const fillZeroDateValue = (date) => {
        if (date.toString().length === 1) {
            return `0${date}`;
        }
        return date;
    };


    const getColumnKeyValueString = (keyValueObj) => {
        return <StyledKeyValueColumn>
            {Object.keys(keyValueObj).map((key) => {
                if (keyValueObj[key]) {
                    return <StyledColumnItem><b>{key}</b>: {keyValueObj[key]}</StyledColumnItem>
                }
            })}
        </StyledKeyValueColumn>
    };

    const createFormatDate = (data) => {
        const dateObject = new Date(data);
        const day = fillZeroDateValue(dateObject.getDate());
        const month = fillZeroDateValue(dateObject.getMonth() + 1);
        const year = fillZeroDateValue(dateObject.getFullYear() - 2000);
        const hours = fillZeroDateValue(dateObject.getHours());
        const minutes = fillZeroDateValue(dateObject.getMinutes());
        return data ? `${day}/${month}/${year} ${hours}:${minutes}` : null
    }

    return (
        <>
            <StyledTable
                virtualized
                className="registration"
                shouldUpdateScroll={false}
                headerHeight={46}
                rowHeight={46}
                autoHeight
                rowClassName={(rowData) => {
                    if (rowData) {
                        let rowClassName = "registration_column sent";

                        if (rowData.status === REGISTRATION_REQUEST_STATUS_APPROVED) {
                            rowClassName = "registration_column approved";
                        } else if (rowData.status === REGISTRATION_REQUEST_STATUS_REJECTED) {
                            rowClassName = "registration_column rejected";
                        }

                        return rowClassName;
                    }
                }}
                data={dataListState}
                loading={loading}
            >
                {columns.map(({
                    dataKey, dataValue, flexGrow = null, minWidth = 130, label = "", data, accepter, valueKey, type,
                    date, tooltip, value, ...columnProps
                }) => {
                    return (<Column {...{flexGrow, minWidth, ...columnProps}}>
                            <HeaderCell>
                                <span className="registration_text_field">{label}</span>
                            </HeaderCell>
                            <Cell {...{dataKey}}>
                                {value}
                            </Cell>
                        </Column>
                    )}
                )}
                <Column width={90}>
                    <HeaderCell></HeaderCell>
                    <Cell>
                        {(rowData) => {
                            let requestAction = <div className="registration_buttons_field">
                                <Link to="/accounts/new">
                                    <Button
                                        disabled={
                                            rowData.status!==REGISTRATION_REQUEST_STATUS_SENT ||
                                            rowData.account_id && rowData.status === REGISTRATION_REQUEST_STATUS_APPROVED ||
                                            !!rowData.account_id
                                        }
                                        className="registration_button approve"
                                        color="green"
                                        onClick={() => {
                                            setDefaultFormCreateAccount({
                                                name: rowData.name,
                                                email: rowData.email,
                                                skype: rowData.skype,
                                                cur_key: rowData.cur_key,
                                                reg_key: rowData.reg_key,
                                                hf_key: rowData.hf_key,
                                                cntr_code: rowData.cntr_code,
                                            })
                                        }}
                                    >
                                        <Icon icon="plus-square"/>
                                    </Button>
                                </Link>
                                <Button
                                    disabled={rowData.status!==REGISTRATION_REQUEST_STATUS_SENT}
                                    className="registration_button reject"
                                    color="red"
                                    onClick={() => {
                                        setShowRejectModal(rowData["reg_key"]);
                                    }}
                                >
                                    <Icon icon="warning"/>
                                </Button>
                            </div>;

                            return requestAction
                        }}
                    </Cell>
                </Column>
            </StyledTable>
            <ModalRejectRegistration
                show={showRejectModal}
                onSubmit={() => {
                    rejectRegistration({reg_key: showRejectModal}).then(() => {
                        setShowRejectModal(null);
                        getRegistrationList();
                        getRegistrationCount();
                    });
                }}
                onClose={() => setShowRejectModal(null)}
            />
        </>
    )
}

const StyledTable = styled(BaseTable)`
    && {
        .registration_button {
            width: 30px;
            height: 30px;
            padding: 5px;
        }
        .registration_text_field {
            display: block;
            line-height: 20px;
            margin-top: 7px;
            word-break: normal;
        }
        
        .registration_column.approved .rs-table-cell {
            background-color: #dfffdf;
        }
        .registration_column.rejected .rs-table-cell {
            background-color: #e2e2e2;
        }
        .registration_buttons_field {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .created_account_link {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .existing_account_buttons_field {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .rs-table-cell-content {
            padding: 6px 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
`;
const StyledKeyValueColumn = styled.div`
    line-break: auto;
    line-height: 20px;
    display: block;
`;
const StyledColumnItem = styled.span`
    display: block;
    margin-right: 15px;
    float: left;
`;
// const StyledNotExtendedColumn = styled.div`
// `;
// const BackButton = styled(Button)`
//     display: block !important;
//     position: absolute !important;
//     width: 30px;
//     height: 30px;
//     padding: 4px 5px !important;
//     right: 0;
//     top: 8px;
// `;