import React, {useEffect, useMemo, useState} from 'react'
import AccountChangesTable from './AccountChangesTable'
import AccountChangesFilter from './AccountChangesFilter'
import {Icon, Whisper} from 'rsuite'
import Tooltip from 'rsuite/es/Tooltip'
import Badge from 'rsuite/es/Badge'

export default (
    {
        accountId,
        loading,
        data,
        getAccountChanges,
    }
) => {
    const [filter, setFilter] = useState({});

    useEffect(() => {
        if (!accountId) {
            return;
        }
        getAccountChanges(accountId);
    }, [accountId]);

    const getItems = () => {
        getAccountChanges(accountId);
    };

    const filteredData = useMemo(() => {
        if (!filter || !filter.range) {
            return data;
        }

        const filterDate = {start: new Date(filter.range[0]), end: new Date(filter.range[1])};

        return data.filter((item) => {
            const currentDate = new Date(new Date(item.date).setHours(0,0,0,0));

            return filterDate.start <= currentDate && filterDate.end >= currentDate;
        });

    }, [data, filter]);

    return (
        <>
            <AccountChangesFilter
                filter={filter}
                setFilter={setFilter}
            />
            <AccountChangesTable
                loading={loading}
                data={filteredData}
                getItems={getItems}
            />
        </>
    )
}