import React, {useRef, useEffect, useState} from 'react';
import {Loader, Placeholder} from 'rsuite/es';
import {connect} from 'react-redux';
import styled from 'styled-components';

const {Paragraph} = Placeholder;

const ContentLoader = ({service}) => {
    const mountedRef = useRef()    
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (mountedRef.current) {
        setLoading(true)
        
  
        let timer = setTimeout(() => setLoading(false), 400);

        return () => {
          clearTimeout(timer);
        };
        
      }
    }, [service])

    useEffect(() => {
      mountedRef.current = true;
    }, [])

    return (
      <>
        {loading && <StyledFader className="rs-modal-backdrop fade in">
            <Loader loading center inverse content="Loading"/>
        </StyledFader>}
      </>
    );
}

const StyledFader = styled.div`
  && {
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  && .rs-loader-wrapper {
    width: 180px; 
    height: 100px;
    border-radius: 5px;
    /* background-color: var(--color-bg); */
  }

  && .rs-loader-spin::after {
    border-color: var(--color-main) #fff #fff;
  }
`;

const mapState = ({})=> ({});

export default connect(mapState, {})(ContentLoader);