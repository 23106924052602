import React, {useState, useEffect, useCallback} from "react";
import PostbackTable from "./PostbackTable/PostbackTable";
import PostbackFilter from "./PostbackFilter/PostbackFilter";
import {FlexboxGrid} from "rsuite";
import {Spacer} from "../../../components/base/Spacer";
import styled from "styled-components";
import {ButtonPrimary} from "../../../components/base/BaseButton";
import ModalConfirm from '../../../components/Modal/ModalConfirm';
import {POSTBACK_LOGS_RESEND_LIST_API} from '../../../const/apiMethods';
import {api} from '../../../api/loginRoutes';


export default ({
    page, 
    count, 
    per_page,
    account_id,

    globalSettings,

    postbackList,
    postbackLoading,
    getPostbackList
}) => {
   
    const today = new Date();
    const defaultFilters = { range: [today, today] };
    const defaultSelected = { list: [], all: false };

    const [filters, setFilters] = useState(defaultFilters);
    const [selectedItems, setSelectedItems] = useState(defaultSelected);
    const [clearSelectedData, setClearSelectedData] = useState(false);
    const [showModalResendPayback, setModalResendPaybackShow] = useState(false);
    const [modalResendPaybackLoading, setModalResendPaybackLoading] = useState(false);

    const getKeysFromSelectedItems = (items) => {
        return items && Object.keys(items).length && Object.keys(items) || [];
    };

    useEffect(() => {
        getPostbackList(account_id, filters, 1, per_page);
    }, [filters]);

    const getItems = (page, per_page) => { // sort not need
        getPostbackList(account_id, filters, page, per_page);
    };

    const handleShowModalResendPayback = useCallback(() => setModalResendPaybackShow(true), []);


    const resendPaybacks = () => {
        setModalResendPaybackLoading(true);

        api(POSTBACK_LOGS_RESEND_LIST_API, {
            target: {
                account_id
            },
            ...(!selectedItems?.all ? {
                postback_key_list: selectedItems.list
            } : {
                filterAll: true
            }),
            filter: filters
        })
        .then(() => {
            getItems(page, per_page);
        })
        .finally(() => {
            setSelectedItems(defaultSelected);
            setClearSelectedData(true);
            setModalResendPaybackLoading(false);
        });
    };

    return (
        <>
            <Header>Postback Logs</Header>
            <FlexboxGrid>
                <FlexboxGrid.Item>
                    <PostbackFilter
                        onApplyFilters={(filters) => {
                            setFilters(filters)
                        }}
                        defaultFilters={defaultFilters}
                        globalSettings={globalSettings}
                    />
                </FlexboxGrid.Item>
                <StyledItemAutoMargin>
                    <ButtonPrimary
                        disabled={!selectedItems?.list?.length && selectedItems?.all === false}
                        onClick={handleShowModalResendPayback}
                    >
                        Resend postbacks
                    </ButtonPrimary>
                </StyledItemAutoMargin>
            </FlexboxGrid>

            {/* <Spacer/> */}
    
            <PostbackTable
                filters={filters}
                page={page}
                count={count}
                per_page={per_page}
                getItems={getItems}
                data={postbackList}
                loading={postbackLoading}
                clearSelectedData={clearSelectedData}
                setClearSelectedData={setClearSelectedData}
                setSelected={(value) => setSelectedItems(value)}
            />
            <ModalConfirm
                show={showModalResendPayback}
                onClose={() => setModalResendPaybackShow(false)}
                onSuccess={resendPaybacks}
                loading={modalResendPaybackLoading}
                title="Resend postback confirmation"
            >
                Selected postbacks will be resent. Continue?
            </ModalConfirm>
        </>
    )
};

const Header = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-main);
    padding: 15px 0px;
    div {
        display: inline-block;
        width: 50%;
    }
    div:last-child{
        text-align: right
    }
`;

const StyledItemAutoMargin = styled(FlexboxGrid.Item)`
    && {
        margin-left: auto;
    }
`;