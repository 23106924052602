import React, {useState, useEffect} from "react";
import {List} from "rsuite";
import styled from "styled-components";
import {useWindowWidth} from "hooks";

import {CustomModal} from "../../../../components/base";
import { ButtonContent } from "components/base/BaseButton";
import { Spacer } from "components/base/Spacer";


const reasons = {
    undefined_sde: 'subdestination is undefined',
    number_exists: 'already in another group',
    number_exists_same_group: 'already in same group',
    no_range: 'range is not created',
    duplicate_number: 'number is duplicated',
};


export default (
    {
        data,
        maxWidth = 600,
        currentGroupData,
        onClose,
        onSuccess,
        ...props
    }
) => {

    const resizedWidth = useWindowWidth();
    const [reasonNumbers, setReasonNumbers] = useState([]);

    useEffect(() => {
        if (!data || !data?.not_uploaded_numbers || !data?.number_list) {
            setReasonNumbers([]);
            return;
        }

        let reasonNumbers = [];
        for (const number of data.number_list) {
            if (data.not_uploaded_numbers <= reasonNumbers.length) {
                break;
            }
            if (!number.reason) {
                continue;
            }
            reasonNumbers.push(number);
        }
        setReasonNumbers([...reasonNumbers]);
    }, [data]);


    return (
        <>
            <StyledModal
                showFooter={true}
                title={'Upload numbers result'}
                width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
                customFooter={() => <ButtonContent onClick={onClose}>Close</ButtonContent>}
                onClose={onClose}
                {...props}
            >

                <p>All numbers{data?.isByFile ? " (in file)" : ""} - <b>{data?.all_numbers}</b></p>
                <p>Uploaded - <b>{data?.uploaded_numbers}</b></p>

                {reasonNumbers.length
                    ? <>
                        <Spacer />
                        <p>Not uploaded:</p>
                        <StyledList 
                            size='sm' 
                            style={{height: reasonNumbers.length * 36, maxHeight: 288}}
                        >
                            {
                                reasonNumbers.map(item => {
                                    if (!item.reason) {
                                        return null;
                                    }
                                    return (
                                        <List.Item key={item.number}>
                                            {item.number} - {item.reason || 'cannot upload number'} ({item.exists_supplier_name || 'no supplier'}, {item.exists_group_name || 'no group'})
                                        </List.Item>
                                    )
                                })
                            }
                        </StyledList>
                    </>
                    : null
                }
                
            </StyledModal>
        </>
    )
};


const StyledModal = styled(CustomModal)`
    .rs-modal-body {
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

export const StyledList = styled(List)`
    margin-top: 10px;
    box-shadow: none !important;
    
    @media (max-width: 767px) {
        display: none !important;
    }
`;