import React, {useState} from "react";
import {Form, Checkbox} from "rsuite";
import styled from "styled-components";


const ManualAccessListFilters = ({ filter, defaultFilter, onChange }) => {
    const [activeState, setActiveState] = useState(defaultFilter.active);

    return (
        <Form
            formValue={filter}
            formDefaultValue={defaultFilter}
            onChange={(formValue) => {
                onChange({
                    ...formValue,
                    active: activeState
                });
            }}
            layout="inline"
        >
            <Checkbox 
                name="active"
                checked={filter.active}
                onChange={(_, checked) => {
                    setActiveState(checked);
                    onChange((value) => {
                        return {
                            ...value,
                            active: checked
                        }
                    });
                }}
            >
                Show only active
            </Checkbox>
        </Form>
    )
};

export default ManualAccessListFilters;

const StyledField = styled.div`
    display: inline-block;
`;