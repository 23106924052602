import React, {useState} from 'react';
import Modal from '../../../components/Modal';
import {api} from '../../../api/loginRoutes';
import {Alert, Checkbox, DatePicker} from 'rsuite';
import {toUTCDateTime} from 'utils';
import {ButtonClose as Revoke} from "../../../components/base/BaseButton";

export default (
    {
        selected,
        count,
        extraParams,
        disabled,
        onSuccess,
        checkKey='incorporated_numbers',
        method='incorporated_number:revoke'
    }
) => {

    const [show, setShow] = React.useState(false);
    const [_disabled, setDisabled] = React.useState(false);

    const onSubmit = async () => {
        
        const params = {
            ...extraParams
        };

        setDisabled(true);
        
        const result = await api(method, params);

        if (result && result[checkKey]) {

            if (result[checkKey] === 1) {
                Alert.success(`1 number was revoked`);
            } else {
                Alert.success(`${result[checkKey]} numbers were revoked`);
            }

        } else {

            Alert.warning(
                `${(selected.all ? count : selected.list.length) > 1 ? 'These numbers' : 'This number'} can't be revoked`
            );
            
        }

        setShow(false);
        onSuccess();
        setDisabled(false);
    };


    return (
        <>
            <Revoke disabled={disabled} onClick={() => setShow(true)}>Revoke</Revoke>
            {show && <Modal
                show={show}
                title="Revoke numbers"
                onClose={() => setShow(false)}
                footer={true}
                successText="Yes"
                disabled={_disabled}
                onSuccess={onSubmit}
            >
                Selected {selected.all ? count : selected.list.length} numbers from this range will be revoked.
                Do you really want to do it?
            </Modal>
            }
        </>

    );
}