import React from 'react';
import Modal from '../Account/Trunks/AllocatedNumbers/AllocateNumbersModal/Modal';
import {connect} from "react-redux";
import {closeAllocationModal} from "../../actions";
import {withRouter} from "react-router";

const NumberAlocation = ({show, service, onClose, defaultSPKey, account, match}) => {

    return <Modal
            show={show}
            account={account}
            pageId={"/number-allocation"}
            match={match}
            disabledTrunk={false}
            sp_key={defaultSPKey}
            onClose={onClose}
            service={service}
            isDialerTrunkFiltered={true}
        />
    ;
};

const mapState = ({auth, settings, accounts, references}) => ({
    service: auth.service,
    show: settings.showAllocation,
    defaultSPKey: references.defaultSPKey,
    account: accounts.accountView,
    accountJoinList: accounts.accountJoinList,
});

export default connect(mapState, {
    onClose: closeAllocationModal
})( withRouter(NumberAlocation) );