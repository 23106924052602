import {defineMessages} from "react-intl";

export default defineMessages({
    subaccounts: { 
        id: "subaccounts.subaccounts",
        defaultMessage: "Subaccounts",
        description: "Subaccounts: Subaccounts"
    },
    subaccontName: {
        id: "subaccounts.subaccontName",
        defaultMessage: "Subaccont name",
        description: "Subaccounts->Filter: Subaccont name"
    },
    showClosed: {
        id: "subaccounts.showClosed",
        defaultMessage: "Show closed",
        description: "Subaccounts->Filter: Show closed"
    },
    currentPeriodTraffic: {
        id: "subaccounts.currentPeriodTraffic",
        defaultMessage: "Current period traffic",
        description: "Subaccounts->Column name: Current period traffic"
    },
    previousPeriodTraffic: {
        id: "subaccounts.previousPeriodTraffic",
        defaultMessage: "Previous period traffic",
        description: "Subaccounts->Column name: Previous period traffic"
    },
    allocatedNumbers: {
        id: "subaccounts.allocatedNumbers",
        defaultMessage: "Allocated numbers",
        description: "Subaccounts->Column name: Allocated numbers"
    },
    createNewSubaccount: {
        id: "subaccounts.createNewSubaccount",
        defaultMessage: "Create new subaccount",
        description: "Subaccounts->Button: Create new subaccount"
    },
    customerName:{
        id: "subaccounts.customerName",
        defaultMessage: "Customer name",
        description: "Subaccounts->Create new: Customer name"
    },
    paymentTerms: {
        id: "subaccounts.paymentTerms",
        defaultMessage: "Payment terms",
        description: "Subaccounts->Create new: Payment terms"
    },
    accountName: {
        id: "subaccounts.accountName",
        defaultMessage: "Account name",
        description: "Subaccounts->Create new: Account name"
    },
    subaccount: {
        id: "subaccounts.subaccount",
        defaultMessage: "Subaccount",
        description: "Subaccounts/Subaccounts/Subaccount: Subaccount"
    },
    settings: {
        id: "subaccounts.settings",
        defaultMessage: "Settings",
        description: "Subaccounts/Settings: Settings"
    },
    customerEmail: {
        id: "subaccounts.customerEmail",
        defaultMessage: "Customer email",
        description: "Subaccounts : Customer email"
    },
    newSubaccountSetPassword: {
        id: "subaccounts.newSubaccountSetPassword",
        defaultMessage: "Set password",
        description: "Subaccounts : Set password"
    },
    newSubaccountGeneratePassword: {
        id: "subaccounts.newSubaccountGeneratePassword",
        defaultMessage: "Generate password",
        description: "Subaccounts : Generate password"
    },
    newSubaccountConfirmPassword: {
        id: "subaccounts.newSubaccountConfirmPassword",
        defaultMessage: "Confirm password",
        description: "Subaccounts : Confirm password"
    },
    save: {
        id: "subaccounts.save",
        defaultMessage: "Save",
        description: "Subaccounts : Save"
    },
    ok: {
        id: "subaccounts.ok",
        defaultMessage: "Ok",
        description: "Subaccounts : Ok"
    },
    createSubaccount: {
        id: "subaccounts.createSubaccount",
        defaultMessage: "Create subaccount",
        description: "Subaccounts : Create subaccount"
    },
    customerPasswordValidation: {
        id: "subaccounts.customerPasswordValidation",
        defaultMessage: "The minimal length of password is 8 characters (just latin, digits special symbols #?!_@$%^&* are allowed). Password must contain at least per one uppercase, lowercase and digit.",
        description: "Subaccounts : The minimal length of password is 8 characters (just latin, digits special symbols #?!_@$%^&* are allowed). Password must contain at least per one uppercase, lowercase and digit."
    },
    week: {
        id: "subaccounts.week",
        defaultMessage: "week",
        description: "Subaccounts : week"
    },
    addPayment: {
        id: "subaccounts.addPayment",
        defaultMessage: "Add payment",
        description: "Subaccounts : Add payment"
    },
    addNewPayment: {
        id: "subaccounts.addNewPayment",
        defaultMessage: "Add new payment",
        description: "Subaccounts : Add new payment"
    },
    actions: {
        id: "subaccounts.actions",
        defaultMessage: "Actions",
        description: "Subaccounts : Actions"
    },
    month: {
        id: "subaccounts.month",
        defaultMessage: "month",
        description: "Subaccounts : month"
    },
    remove: {
        id: "subaccounts.remove",
        defaultMessage: "Remove",
        description: "Subaccounts : Remove"
    },
    paymentWillBeDeleted: {
        id: "subaccounts.paymentWillBeDeleted",
        defaultMessage: "Payment will be deleted",
        description: "Subaccounts : Payment will be deleted"
    },
    general: {
        id: "subaccounts.general",
        defaultMessage: "General",
        description: "Subaccounts : General"
    },
    rangeOrNumber: {
        id: "subaccounts.rangeOrNumber",
        defaultMessage: "Range/Number",
        description: "Subaccounts : Range/Number"
    },
    subaccountRate: {
        id: "subaccounts.subaccountRate",
        defaultMessage: "Subaccount rate",
        description: "Subaccounts : Subaccount rate"
    },
    myRate: {
        id: "subaccounts.myRate",
        defaultMessage: "My rate",
        description: "Subaccounts : My rate"
    },
    changeRate: {
        id: "subaccounts.changeRate",
        defaultMessage: "Change rate",
        description: "Subaccounts : Change rate"
    },
    change: {
        id: "subaccounts.change",
        defaultMessage: "Change",
        description: "Subaccounts : Change"
    },
    exportSelected: {
        id: "subaccounts.exportSelected",
        defaultMessage: "Export selected",
        description: "Subaccounts : Export selected"
    },
    revokeConfirmation: {
        id: "subaccounts.revokeConfirmation",
        defaultMessage: "Revoke confirmation",
        description: "Subaccounts : Revoke confirmation"
    },
    areYouSureAllNumbersWillBeRevoked: {
        id: "subaccounts.areYouSureAllNumbersWillBeRevoked",
        defaultMessage: "Are you sure? All numbers will be revoked",
        description: "Subaccounts : Are you sure? All numbers will be revoked"
    },
    defaultDiscount: {
        id: "subaccounts.defaultDiscount",
        defaultMessage: "Default discount",
        description: "Subaccounts : Default discount"
    },
    addNewNumbers: {
        id: "subaccounts.addNewNumbers",
        defaultMessage: "Add new numbers",
        description: "Subaccounts : Add new numbers"
    },
    downloadNumbers: {
        id: "subaccounts.downloadNumbers",
        defaultMessage: "Download numbers",
        description: "Subaccounts : Download numbers"
    },
    changeDefaultDiscount: {
        id: "subaccounts.changeDefaultDiscount",
        defaultMessage: "Change default discount",
        description: "Subaccounts : Change default discount"
    },
    showFree: {
        id: "subaccounts.showFree",
        defaultMessage: "Show free",
        description: "Subaccounts : Show free"
    },
    showAllocated: {
        id: "subaccounts.showAllocated",
        defaultMessage: "Show allocated",
        description: "Subaccounts : Show allocated"
    },
    allocateInto: {
        id: "subaccounts.allocateInto",
        defaultMessage: "Allocate into",
        description: "Subaccounts : Allocate into"
    },
    defaultRate: {
        id: "subaccounts.defaultRate",
        defaultMessage: "Default rate",
        description: "Subaccounts : Default rate"
    },
    customRate: {
        id: "subaccounts.customRate",
        defaultMessage: "Custom rate",
        description: "Subaccounts : Custom rate"
    },
    uncheckTheDefaultRateCheckboxToEnterACustomRate: {
        id: "subaccounts.uncheckTheDefaultRateCheckboxToEnterACustomRate",
        defaultMessage: "Uncheck the default rate checkbox to enter a custom rate",
        description: "Subaccounts : Uncheck the default rate checkbox to enter a custom rate"
    },
    payout: {
        id: "subaccounts.payout",
        defaultMessage: "Payout",
        description: "Subaccounts : Payout"
    },
    defaultPayout: {
        id: "subaccounts.defaultPayout",
        defaultMessage: "Default payout",
        description: "Subaccounts : Default payout"
    },
    allocatedTo: {
        id: "subaccounts.allocatedTo",
        defaultMessage: "Allocated to",
        description: "Subaccounts : Allocated to"
    },
    applyFilters: {
        id: "subaccounts.applyFilters",
        defaultMessage: "Apply filters",
        description: "Subaccounts : Apply filters"
    },
    subaccountCost: {
        id: "subaccounts.subaccountCost",
        defaultMessage: "Subaccount cost",
        description: "Subaccounts : Subaccount cost"
    },
    subCost: {
        id: "subaccounts.subCost",
        defaultMessage: "Sub cost",
        description: "Subaccounts : Sub cost"
    },
    margin: {
        id: "subaccounts.margin",
        defaultMessage: "Margin",
        description: "Subaccounts : Margin"
    },
    exportToCsv: {
        id: "subaccounts.exportToCsv",
        defaultMessage: "Export to csv",
        description: "Subaccounts : Export to csv"
    },
    myPayout: {
        id: "subaccounts.myPayout",
        defaultMessage: "My payout",
        description: "Subaccounts : My payout"
    },
    subPayoutWeekly: {
        id: "subaccounts.subPayoutWeekly",
        defaultMessage: "Sub payout (Weekly)",
        description: "Subaccounts : Sub payout (Weekly)"
    },
    subPayoutMonthly: {
        id: "subaccounts.subPayoutMonthly",
        defaultMessage: "Sub payout (Monthly)",
        description: "Subaccounts : Sub payout (Monthly)"
    },
    destinationNameOrNumberMinDigits: {
        id: "subaccounts.destinationNameOrNumberMinDigits",
        defaultMessage: "Destination name or number (min 3 digits)",
        description: "Subaccounts : Destination name or number (min 3 digits)"
    },
    originNameOrNumberMinDigits: {
        id: "subaccounts.originNameOrNumberMinDigits",
        defaultMessage: "Origin name or number (min 3 digits)",
        description: "Subaccounts : Origin name or number (min 3 digits)"
    },
    configureDomain: {
        id: "subaccounts.configureDomain",
        defaultMessage: "Configure domain",
        description: "Subaccounts : Configure domain"
    },
    configurationInfo: {
        id: "subaccounts.configurationInfo",
        defaultMessage: "Configuration info",
        description: "Subaccounts : Configuration info"
    },
    makeArecordForDomainIp: {
        id: "subaccounts.makeArecordForDomainIp",
        defaultMessage: "Make A-record for domain ip",
        description: "Subaccounts : Make A-record for domain ip"
    },
    certificateGeneration: {
        id: "subaccounts.certificateGeneration",
        defaultMessage: "Certificate generation",
        description: "Subaccounts : Certificate generation"
    },
    saveConfiguration: {
        id: "subaccounts.saveConfiguration",
        defaultMessage: "Save configuration",
        description: "Subaccounts : Save configuration"
    },
    passwordDoesntMatch: {
        id: "subaccounts.passwordDoesntMatch",
        defaultMessage: "Password doesn't match",
        description: "Subaccounts : passwordDoesntMatch"
    },
    weeklyDiscount: {
        id: "subaccounts.weeklyDiscount",
        defaultMessage: "Weekly discount %",
        description: "Subaccounts : Weekly discount %"
    },
    monthlyDiscount: {
        id: "subaccounts.monthlyDiscount",
        defaultMessage: "Monthly discount %",
        description: "Subaccounts : Monthly discount %"
    },
    loginOrEmail: {
        id: "subaccounts.loginOrEmail",
        defaultMessage: "Login/Email",
        description: "Subaccounts : Login/Email"
    },
    loginAs: {
        id: "subaccounts.loginAs", 
        defaultMessage: "Login as",
        description: "Subaccounts : Login as"
    },
    customers: {
        id: "subaccounts.customers", 
        defaultMessage: "Customers",
        description: "Subaccounts : Customers"
    },
    saved: {
        id: "common.saved",
        defaultMessage: "Saved",
        description: "common: Saved"
    }
});