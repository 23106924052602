import {SET_UPLOAD_LOADING_DIALER, TRAFFIC_STAT_DIALER as PREFIX} from '../../const';
import {Alert} from "rsuite";
import {api, getFileResponse} from '../../api/loginRoutes';
import {_setValue} from './defaults';
import {downloadDataAsFile} from '../../utils/helpers';
import {TRAFFIC_STAT_LIST_DIALER_API, SMS_TRAFFIC_STAT_LIST_DIALER_API} from "../../const/apiMethods";

const setTestCalls = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);
const setUploadLoading = _setValue(SET_UPLOAD_LOADING_DIALER);

export const getDialerTrafficStat = (service = true, per_page = 12, filterData = {}, page = 1, group = 'range/a_number/b_number') => (dispatch) => {
    dispatch(setLoading(true));
    const filter = {...filterData};
    if (filter.checkbox)
        delete filter.checkbox;
    if (filter.group)
        delete filter.group;

    const method = service ? TRAFFIC_STAT_LIST_DIALER_API : SMS_TRAFFIC_STAT_LIST_DIALER_API;

    api(method, {group, filter, page, per_page})
        .then((response) => {
            if (response === undefined) {
                dispatch( setLoading(false) );
                return;
            } else if (response?.error && response.error?.data) {
                // const interval = response.error.data?.time_interval_days;
                // Alert.error(`The filter is only available for the selected ${interval} days`);
                dispatch( setLoading(false) );
                return;
            }

            dispatch(setTestCalls({
                group_list: response.group_list || [],
                group_count: response.group_count || 0,
                group_summaries: response.group_summaries || {},
                page,
                per_page
            }));
        })
        .catch(error => {
            dispatch( setLoading(false) );
        });
};

export const setEmptyTrafficStat = () => (dispatch) => {
    dispatch( setTestCalls({
        group_count: 0,
        group_list: [],
        page: 1
    }) );
};

export const exportDialerReport = (service = true, filterData = {}, group = 'range/a_number/b_number') => async (dispatch) => {
    dispatch(setUploadLoading(true));

    const filter = {...filterData};
    if (filter.checkbox)
        delete filter.checkbox;
    if (filter.group)
        delete filter.group;

    const method = service ? TRAFFIC_STAT_LIST_DIALER_API : SMS_TRAFFIC_STAT_LIST_DIALER_API;
    
    await getFileResponse(method, {filter, group}).then((response) => downloadDataAsFile(response, 'traffic_stats.csv'));
    dispatch(setUploadLoading(false));
};
