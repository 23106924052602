import React, {useMemo} from "react";
import {Redirect, Route, Switch} from "react-router";
import AuthRoute from "components/Router/AuthRoute";
import {
    DEFAULT_AUTH_PATH_CLIENT,
    DEFAULT_AUTH_PATH_CLIENT_TEST,
    HAS_BOTH_SERVICE_VALUE
} from "const";
import CLI from "pagesClient/CLI";
import News from "pagesClient/News";
import Price from "pagesClient/Price";
import Numbers from "pagesClient/Numbers";
import ApiPage from "pagesClient/ApiPage";
import HotAccesses from "pagesClient/HotAccesses";
import Accesses from "pagesClient/Accesses";
import Payments from "pagesClient/Payments";
import LiveCalls from "pagesClient/LiveCalls";
import TestCalls from "pagesClient/TestCalls";
import TrafficStat from "pagesClient/Traffic/Traffic";
import PaymentDetails from "pagesClient/PaymentDetails";
import PaymentDetailConfirm from "pagesClient/PaymentDetails/PaymentDetailConfirm";
import SubAcccounts from "pagesClient/SubAccounts/Accounts/AccountsContainer";
import Account from "pagesClient/SubAccounts/Accounts/Account/AccountContainer";
import ResellerNumbers from "pagesClient/SubAccounts/Numbers/NumbersContainer";
import ResellerTraffic from "pagesClient/SubAccounts/Traffic/TrafficContainer";
import ResellerLiveCalls from "pagesClient/SubAccounts/LiveCalls/LiveCallsContainer";
import ResellerAccessList from "pagesClient/SubAccounts/AccessList/AccessListContainer";
import ResellerSettings from "pagesClient/SubAccounts/Settings/SettingsContainer";
import NewAccount from "pagesClient/SubAccounts/Accounts/Account/Tabs/General/NewAccount";
import RedirectPage from "pages/Redirect";
import AuthNumbers from "pagesClient/AuthNumbers/AuthNumbersContainer";



export default ({
    authInfo, 
    dialerInfo,
    resetRecaptcha,
    accountJoinList,
    executeRecaptcha
}) => {
    const isTest = dialerInfo?.is_test;

    const hideOnSmsSign = useMemo(() => {
        return accountJoinList && accountJoinList.length === 1 && (accountJoinList.find(account => account.service === 'sms') || false);
    }, [accountJoinList]);

    const hasBothService = useMemo(() => {
        if (accountJoinList && accountJoinList.length === 2) {
            return true;
        }
        return JSON.parse(localStorage.getItem(HAS_BOTH_SERVICE_VALUE)) || false;
    }, [accountJoinList]);

    const singleAccount = !hasBothService ? accountJoinList[0] : {};
    const singleAccountService = hasBothService ? null : singleAccount?.service;

    const bothServiceRoutes = <Switch>
        {!isTest && <AuthRoute exact path="/numbers-voice" component={Numbers}/>}
        {!isTest && <AuthRoute exact path="/numbers-sms" component={Numbers}/>}
        {!isTest && <AuthRoute exact path="/api" component={ApiPage}/>}
        {!isTest && <AuthRoute exact path="/payments" component={Payments}/>}
        
        {!isTest && <AuthRoute 
            exact 
            path="/traffic-voice"
            component={TrafficStat}
            render={(props) => {
                return <TrafficStat 
                    {...{
                        executeRecaptcha,
                        resetRecaptcha,
                        ...props
                    }}
                />
            }}
        />}
        {!isTest && <AuthRoute 
            exact 
            path="/traffic-sms"
            component={TrafficStat}
            render={(props) => {
                return <TrafficStat 
                    {...{
                        executeRecaptcha,
                        resetRecaptcha,
                        ...props
                    }}
                />
            }}
        />}
        {!isTest && <AuthRoute exact path="/cli-voice" component={CLI}/>}
        {!isTest && <AuthRoute exact path="/cli-sms" component={CLI}/>}
        {!isTest && <AuthRoute exact path="/payment-details" component={PaymentDetails}/>}
        {!isTest && <AuthRoute exact path="/payment-detail/:token" component={PaymentDetailConfirm}/>}


        <AuthRoute exact path="/test-calls-voice" component={TestCalls}/>
        <AuthRoute exact path="/test-calls-sms" component={TestCalls}/>
        <AuthRoute exact path="/live-calls" component={LiveCalls}/>
        <AuthRoute exact path="/price-voice" component={Price}/>
        <AuthRoute exact path="/price-sms" component={Price}/>
        <AuthRoute 
            exact
            path="/hot-accesses-voice"
            component={HotAccesses}
            render={(props) => {
                return <HotAccesses 
                    {...{
                        executeRecaptcha,
                        resetRecaptcha,
                        ...props
                    }}
                />
            }}
            key={'/hot-accesses'}
        />
         <AuthRoute 
            exact
            path="/hot-accesses-sms"
            component={HotAccesses}
            render={(props) => {
                return <HotAccesses 
                    {...{
                        executeRecaptcha,
                        resetRecaptcha,
                        ...props
                    }}
                />
            }}
            key={'/hot-accesses'}
        />
        <AuthRoute 
            exact
            path="/accesses-voice"
            component={Accesses}
            render={(props) => {
                return <Accesses 
                    {...{
                        executeRecaptcha,
                        resetRecaptcha,
                        ...props
                    }}
                />
            }}
            key={'/access'}
        />
        <AuthRoute 
            exact
            path="/accesses-sms"
            component={Accesses}
            render={(props) => {
                return <Accesses 
                    {...{
                        executeRecaptcha,
                        resetRecaptcha,
                        ...props
                    }}
                />
            }}
            key={'/access'}
        />
        <AuthRoute 
            exact 
            path="/global-accesses-numbers-voice"
            component={Accesses}
            render={(props) => {
                return <Accesses 
                    {...{
                        executeRecaptcha,
                        resetRecaptcha,
                        ...props
                    }}
                />
            }}
            key={'/global-accesses-numbers'}
        />
        <AuthRoute 
            exact 
            path="/global-accesses-numbers-sms"
            component={Accesses}
            render={(props) => {
                return <Accesses 
                    {...{
                        executeRecaptcha,
                        resetRecaptcha,
                        ...props
                    }}
                />
            }}
            key={'/global-accesses-numbers'}
        />
        <AuthRoute exact path="/auth-numbers" component={AuthNumbers}/>

        <AuthRoute exact path="/news" component={News}/>
        <AuthRoute exact path="/sub-accounts" component={SubAcccounts}/>
        <AuthRoute exact path="/sub-accounts/view/:id" component={Account}/>
        <AuthRoute exact path="/sub-accounts/new" component={NewAccount}/>
        <AuthRoute exact path="/sub-numbers" component={ResellerNumbers}/>
        <AuthRoute exact path="/sub-traffic" component={ResellerTraffic}/>
        <AuthRoute exact path="/sub-live-calls" component={ResellerLiveCalls}/>
        <AuthRoute exact path="/sub-access-list" component={ResellerAccessList}/>
        <AuthRoute exact path="/sub-settings" component={ResellerSettings}/>
        <Route path="/redirect" component={RedirectPage}/>

        {isTest
            ? <Redirect to={DEFAULT_AUTH_PATH_CLIENT_TEST}/>
            : <Redirect to={`${DEFAULT_AUTH_PATH_CLIENT}-voice`}/>
        }
    </Switch>;

    const oneServiceRoutes = <Switch>
        {!hideOnSmsSign && <AuthRoute exact path="/live-calls" component={LiveCalls}/>}
        <AuthRoute exact path="/numbers" component={Numbers}/>
        <AuthRoute exact path="/api" component={ApiPage}/>
        <AuthRoute exact path="/auth-numbers" component={AuthNumbers}/>

        <AuthRoute exact path="/payments" component={Payments}/>
        
        <AuthRoute 
            exact 
            path="/traffic"
            render={(props) => {
                return <TrafficStat 
                    {...{
                        executeRecaptcha,
                        resetRecaptcha,
                        ...props
                    }}
                />
            }}
        />
        <AuthRoute exact path="/cli" component={CLI}/>
        <AuthRoute exact path="/payment-details" component={PaymentDetails}/>
        <AuthRoute exact path="/payment-detail/:token" component={PaymentDetailConfirm}/>

        <AuthRoute exact path="/test-calls" component={TestCalls}/>
        <AuthRoute exact path="/price" component={Price}/>
        <AuthRoute 
            exact
            path={`/hot-accesses-${singleAccountService}`}
            render={(props) => {
                return <HotAccesses 
                    {...{
                        executeRecaptcha,
                        resetRecaptcha,
                        ...props
                    }}
                />
            }}
            key={'/hot-access'}
        />
        <AuthRoute 
            exact
            path="/accesses"
            render={(props) => {
                return <Accesses 
                    {...{
                        executeRecaptcha,
                        resetRecaptcha,
                        ...props
                    }}
                />
            }}
            key={'/access'}
        />
        <AuthRoute 
            exact 
            path="/global-accesses-numbers"
            render={(props) => {
                return <Accesses 
                    {...{
                        executeRecaptcha,
                        resetRecaptcha,
                        ...props
                    }}
                />
            }}
            key={'/global-accesses-numbers'}
        />
        <AuthRoute exact path="/sub-accounts" component={SubAcccounts}/>
        <AuthRoute exact path="/sub-accounts/view/:id" component={Account}/>
        <AuthRoute exact path="/sub-accounts/new" component={NewAccount}/>
        <AuthRoute exact path="/sub-numbers" component={ResellerNumbers}/>
        <AuthRoute exact path="/sub-traffic" component={ResellerTraffic}/>
        <AuthRoute exact path="/sub-live-calls" component={ResellerLiveCalls}/>
        <AuthRoute exact path="/sub-access-list" component={ResellerAccessList}/>
        <AuthRoute exact path="/sub-settings" component={ResellerSettings}/>
        <Route path="/redirect" component={RedirectPage}/>

        <AuthRoute exact path="/news" component={News}/>

        {!!hideOnSmsSign 
            ? <Redirect to={`${DEFAULT_AUTH_PATH_CLIENT}-sms`}/>
            : <Redirect to={`${DEFAULT_AUTH_PATH_CLIENT}-voice`}/>
        }
    </Switch>;

    return (
        !hasBothService ? oneServiceRoutes : bothServiceRoutes
    )
}