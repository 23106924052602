import React, {useState, useRef, createRef} from 'react';
import {Table} from 'rsuite';
import {Form, Schema, SelectPicker, FormControl, Button} from "rsuite";
import Icon from "rsuite/es/Icon";
import BaseTable from "../../components/base/BaseTable";


const {Column, HeaderCell, Cell} = Table;

export default ({defaultFilter, addItem, update, service, servicePlanList, ...props}) => {
    const form = useRef(null);

    const formControlNames = ["wz_key", "de_key", "sde_key", "prefix", "sp_key", "account_id"];

    const [fields, setFields] = useState({});
    const [hasRequiredException, setRequiredException] = useState(false);

    const {StringType, NumberType} = Schema.Types;
    const formModel = Schema.Model({
        wz_key: NumberType(),
        de_key: NumberType(),
        sde_key: NumberType(),
        prefix: StringType()
            .pattern(/^[0-9][\d]*$/, "The prefix must contain only digits")
            .minLength(3, "The minimum is 3 characters.")
            .maxLength(15, "The maximum is 15 characters"),
        account_id: StringType()
    });

    const filteredServicePlanList = servicePlanList ? servicePlanList.filter((item) => {
        return !item.gan;
    }) : [];


    const formControlItems = [
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="wz_key" accepter={SelectPicker} data={props.worldzoneList} placeholder="Zone" valueKey="wz_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="de_key" accepter={SelectPicker} data={props.destinationList} placeholder="Destination" valueKey="de_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="sde_key" accepter={SelectPicker} data={props.subdestinationList} placeholder="Subdestination" valueKey="sde_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="prefix" placeholder="Prefix" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="sp_key" accepter={SelectPicker} data={filteredServicePlanList} placeholder="Price" valueKey="sp_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="account_id" accepter={SelectPicker} data={props.accountList} placeholder="Account" valueKey="id" labelKey="name" type="text" />,
    ];

    const handleChange = (formValues) => {
        setFields(formValues);

        checkAtLeastOneException(formValues);
    };
    const checkAtLeastOneException = (values) => {
        setRequiredException(!!formControlNames.map(key => values[key]).filter(value => value).length);
    };
    const handleSubmit = (_, event) => {
        event.preventDefault();

        let validStatus = form.current.check();

        if (!validStatus)
            return;

        let formValues = form.current.getFormValue();
        let data = {...formValues, action: true};

        addItem(data, service).then(() => {
            update();
        });

    };

    const columns = formControlNames.map( key => {
        return {dataKey: key, label: ''}
    } );

    const tableColumnsElements = {};
    for (let i = 0; i < formControlNames.length; i++) {
        tableColumnsElements[formControlNames[i]] = formControlItems[i];
    }

    const columnsElements = formControlNames.map( (item) => {
        return (
            <Column flexGrow>
                <HeaderCell></HeaderCell>
                <Cell dataKey={item}/>
            </Column>
        )
    } );

    return(
        <>
            <Form
                onSubmit={handleSubmit}
                ref={form}
                model={formModel}
                onChange={handleChange}
                // formDefaultValue={fields}
                formValue={fields}
                style={{margin: '0px', width: '100%'}}
            >

                <BaseTable
                    data={[tableColumnsElements]}
                    loading={false}
                    columns={columns}
                    headerHeight={0}
                    autoHeight
                    rowHeight={46}
                    className="tableFilters tableFilters__form"
                >
                    {columnsElements}
                    <Column width={hasRequiredException ? 164 : 64}>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {() => (
                                <Button disabled={!hasRequiredException} className="tableFilters_submit" color="green" type="submit">
                                    <Icon icon="plus"/>
                                </Button>
                            )}
                        </Cell>
                    </Column>
                </BaseTable>

            </Form>
        </>
    )
};