import React from 'react';
import {useIntl} from 'react-intl';
import Modal from "../../Modal";
import m from "../../../definedMessages";


export default ({
    show,
    data,
    title,
    onClose,
    onSubmit,
    disabled,
}) => {

    const {formatMessage} = useIntl();

    return (
        <>
            <Modal
                show={show}
                footer
                data={data}
                disabled = {disabled}
                onSuccess={onSubmit}
                onClose={onClose}
                successText={formatMessage(m.delete)}
                cancelText={formatMessage(m.cancel)}
                title={title}
            >
                <p>{formatMessage(m.areYouSure)}</p>
            </Modal>
        </>
    )
};