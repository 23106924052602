import React, {useRef, useMemo, useState, useEffect, useCallback} from "react";
import {Schema, Form} from "rsuite";
import FormHOC from "hoc/FilltersForm";
import CustomField from "components/client/Form/CustomField/CustomField";
import CustomSelfClearedForm from "components/client/Form/CustomSelfClearedForm";
import {transformed_service_data, LOCAL_STORAGE_USER_INFO_DIALER, USD_DEFAULT_CURRENCY_KEY} from "const";
import {debounce, getServiceByLocation} from "utils";
import {servicePlanNoTrunkFilter} from "utils/filters";
import {injectIntl} from "react-intl";
import {useWindowWidth} from "hooks";
import {FlexGrid, FlexGridItem} from "components/base/FlexGrid";
import m from "definedMessages";
import {BaseInputField, BaseSelectPicker} from "components/base/BaseForm";

const {StringType} = Schema.Types;
const MAX_CHARACTERS = 40;
const filtersModel = Schema.Model({
        str: StringType()
            .maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS} characters`)
    }
);


const PriceFilters = FormHOC(({
    location,
    loading,
    onChangeFilter,
    prices = [],
    service: propService,
    savedTableFilter,
    accountJoinList,
    currencyList,
    intl
}) => {
    const service = propService !== null ? propService : getServiceByLocation(location);

    const {formatMessage} = intl;

    const formRef = useRef(null);

    const filtersModelInner = Schema.Model({
            str: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS}))
        }
    );

    const priceList = useMemo(() => {
        return prices 
            ? prices.filter(servicePlanNoTrunkFilter).map(({sp_key: value, name: label}) => ({value, label})) 
            : [];
    }, [prices]);

    const curList = useMemo(() => {
        return currencyList
            ? currencyList.map(({cur_key: value, name: label}) => ({value, label}))
            : [];
    }, [currencyList]);

    const filteredPriceList = useMemo(() => {
        if (priceList && priceList.length && savedTableFilter) {
            return priceList.filter(price => {
                return price.value === savedTableFilter.sp_key
            });
        }

        return [];
    }, [priceList, savedTableFilter]);

    const savedFilter = useMemo(() => {
        if (filteredPriceList && filteredPriceList.length) {
            return {
                ...savedTableFilter,
                sp_key: filteredPriceList[0].value,
                str: (savedTableFilter && savedTableFilter.str) || "",
                cur_key: (savedTableFilter && savedTableFilter.cur_key) || USD_DEFAULT_CURRENCY_KEY
            }
        } 
        return {
            ...savedTableFilter,
            sp_key: (prices && prices.length && prices[0].sp_key) || (savedTableFilter && savedTableFilter.sp_key) || 1,
            str: (savedTableFilter && savedTableFilter.str) || "",
            cur_key: (savedTableFilter && savedTableFilter.cur_key) || USD_DEFAULT_CURRENCY_KEY
        }
    }, [prices, filteredPriceList]);



    // temporary crutch for SP key
    const [spKey, setSpKey] = useState(savedTableFilter?.sp_key);
    const [curKey, setCurKey] = useState(USD_DEFAULT_CURRENCY_KEY);

    const [formStateValue, setFormStateValue] = useState(savedTableFilter);
    const [localState, setLocalState] = useState(formStateValue);


    const windowWidth = useWindowWidth();

    useEffect(() => {
        const currentAccountJoinList = accountJoinList || [];
        const currentAccountInfo = currentAccountJoinList.find(accountService => transformed_service_data[accountService?.service] === service) || {};    
        
        const sp_key = (savedTableFilter && savedTableFilter.sp_key) || (prices && prices.length && prices[0].sp_key) || 1;
        const cur_key = currentAccountInfo?.cur_key || (savedTableFilter && savedTableFilter.cur_key) || USD_DEFAULT_CURRENCY_KEY;

        setSpKey(sp_key);
        setCurKey(cur_key);
    }, [propService, savedTableFilter, prices]);

    const resizedWidth = useWindowWidth();

    let storagedAccountInfo = localStorage.getItem(LOCAL_STORAGE_USER_INFO_DIALER);
    storagedAccountInfo = storagedAccountInfo !== null ? JSON.parse(storagedAccountInfo) : {session: false};
    
    const currentAccountJoinList = accountJoinList || [];
    const currentAccountInfo = currentAccountJoinList.find(accountService => transformed_service_data[accountService?.service] === service) || {};    

    const accountInfoIsTest = "is_test" in currentAccountInfo ? currentAccountInfo.is_test : false;

    useEffect(() => {
        if (savedFilter?.sp_key !== formStateValue.sp_key) {
            setFormStateValue(savedFilter);
        }
    }, [savedFilter]);

    const debouncedUpdate = useCallback(
        debounce((data) => {
            setFormStateValue((prevState) => ({
                ...prevState,
                ...data
            }));
        }, 1000)
    , []);
    

    const onSubmit = (data, checkedData) => {
        const checker = Object.keys(checkedData).map(i => checkedData[i].hasError).every(value => value === false);

        if (checker) {
            onChangeFilter({...data, sp_key: data.sp_key || savedTableFilter.sp_key || 1});
        }
        return false;
    };

    return (
        <Form
            formRef={formRef}
            // clearOn={service}
            model={filtersModelInner}
            formValue={localState}
            formDefaultValue={localState}
            fluid
            readOnly={loading}
            onChange={(data) => {
                const price = prices.find(current => current.sp_key === data?.sp_key);
                const currentFilterData = {
                    ...data,
                    sp_key: priceList.map(value => value.value).includes(data.sp_key) ? data.sp_key : savedFilter.sp_key,
                    gan: price ? price?.gan : null
                };

                const checkedData = filtersModel.check(currentFilterData);
                onSubmit(currentFilterData, checkedData);

                setLocalState(currentFilterData);
                debouncedUpdate(currentFilterData);
            }}
        >

            <FlexGrid align="top">
                <FlexGridItem width={windowWidth > 767 ? "180px" : "100%"}>
                    <CustomField
                        data={priceList}
                        value={spKey}
                        searchable={false}
                        accepter={BaseSelectPicker}
                        name="sp_key"
                        cleanable={false}
                        virtualized={false}
                        onChange={(value) => {
                            setSpKey(value);
                        }}
                    />
                </FlexGridItem>

                { accountInfoIsTest&&
                    <FlexGridItem>
                        <CustomField
                            data={curList}
                            value={curKey}
                            accepter={BaseSelectPicker}
                            name="cur_key"
                            width={windowWidth > 767 ? "320px" : "100%"}
                            cleanable={false}
                            searchable={false}
                            onChange={(value) => {
                                setCurKey(value);
                            }}
                        />
                    </FlexGridItem>
                }

                <FlexGridItem width={windowWidth > 767 ? "320px" : "100%"}>
                    <CustomField
                        name="str"
                        accepter={BaseInputField}
                        errorPlacement="topEnd"
                        placeholder={
                            resizedWidth > 300 ? 
                                formatMessage(m.filterPerPrefixOrRangeName) 
                                : formatMessage(m.rangeName)
                            }
                    />
                </FlexGridItem>
            </FlexGrid>

        </Form>
    );
}, filtersModel, 500);


export default injectIntl(PriceFilters)