import React, {useContext, useState} from 'react';
import ModalEdit from '../../components/Modal/ModalEdit';
import {FlexboxGrid, Form, FormControl, Schema, Message} from 'rsuite';
import {replaceUndefinedOrNull} from '../../utils/helpers';
import m from "../../definedMessages";
import { useIntl } from 'react-intl';
import { RtlContext } from '../../App';


const {StringType} = Schema.Types;

const styles = {
    marginRight: '20px'
};


export default ({maxWidth = 520, show, onClose, data, modifyAccountRequest}) => {
    const [formValue, onChangeFormValue] = useState({});
    const [disabledButton, onDisableButton] = useState(false);

    let form;
    const rtl = useContext(RtlContext);

    const {formatMessage} = useIntl();

    const formModel = Schema.Model({
        old_password: StringType()
            .isRequired(formatMessage(m.thisFieldIsRequired)),
    });


    const onSubmit = (form, model, value) => {
        if (!form.check()) {
            return false;
        }

        const comparedData = {...data, old_password: value.old_password};

        const formData = JSON.stringify(comparedData, replaceUndefinedOrNull);
        const clearedData = JSON.parse(formData);

        modifyAccountRequest(clearedData);

        return true;
    };

    const styled = getComputedStyle(document.documentElement);

    return (
        <ModalEdit {...{show, onClose}}
               onClose={() => onClose()}
               onSuccess={() => onSubmit(form, formModel, formValue)}
               successButtonProps={ {disabled: !disabledButton}}
               title={formatMessage(m.confirmProfileChanges)}
               successButton={formatMessage(m.accept)}
               width={maxWidth}
        >
            <Form
                model={formModel}
                ref={ref => form = ref}
                onChange={(data) => {
                    const checkedData = formModel.check(data);

                    const checker = Object.keys(checkedData).map(i => checkedData[i].hasError).every(value => value === false);
                    if (checker) {
                        onDisableButton(true);
                    } else {
                        onDisableButton(false);
                    }

                    onChangeFormValue(data);
                }}
            >
                <Message
                    type="info"
                    description={formatMessage(m.typeCurrentPassword)}
                    style={{
                        width: '90%',
                        left: rtl ? 'auto' : '5%',
                        right: rtl ? '5%' : 'auto',
                        marginBottom: '20px',
                        border: `1px solid ${styled.getPropertyValue('--color-info')}`
                    }}
                />

                <FlexboxGrid align="middle" style={{width: '100%', marginBottom: '20px'}}>
                    {
                        <FlexboxGrid.Item style={{...styles, width: '100%', maxWidth: '100px'}}>
                            {formatMessage(m.password)}
                        </FlexboxGrid.Item>
                    }
                    <FlexboxGrid.Item
                        style={{
                            width: '100%',
                            maxWidth: '300px',
                            minWidth: '140px'
                        }}>
                        <FormControl
                            name="old_password"
                            type="password"
                            placeholder={formatMessage(m.password)}
                            style={{
                                maxWidth: '300px',
                                minWidth: '140px'
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
        </ModalEdit>
    );
};