import {APP_TYPE_ADMIN, APP_TYPE_CLIENT} from "../const";

const colors = {
    violet: '#8256C8',
    green: '#20BA88',
    bg: '#E5E5E5',
    text: '#575757',
    info: '#299AF3',
    warning: '#FFB300',
    statusBadge: '#00ffa1',
    main: 'var(--color-main)',
    mainHover: 'var(--color-main-hover)'
};

export const themeAdmin = {
    appType: APP_TYPE_ADMIN,
    colors: {
        brand1: colors.main,
        brand2: colors.green,
        primary: colors.main,
        secondary: colors.green,
        bg: colors.bg,
        text: colors.text,
        info: colors.info,
        warning: colors.warning,
        statusBadge: colors.statusBadge,
        activeRow: '#F0E7FF',
    }
};
export const themeClient = {
    appType: APP_TYPE_CLIENT,
    colors: {
        brand1: colors.green,
        brand2: colors.violet,
        primary: colors.green,
        secondary: colors.violet,
        bg: colors.bg,
        text: colors.text,
        info: colors.info,
        warning: colors.warning,
        statusBadge: colors.statusBadge,
        activeRow: '#d9d9f2',
    }
};

export default {
    [APP_TYPE_ADMIN]: themeAdmin,
    [APP_TYPE_CLIENT]: themeClient
}