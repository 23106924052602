import Modal from '../../components/Modal';
import React, {memo, useEffect, useState} from 'react';
import {Alert, Form, FormControl, Schema} from 'rsuite/es';
import {FormGroup, SelectPicker} from 'rsuite';
import styled from 'styled-components';
import {shuffleArray} from 'utils';
import {
    addNewPriceRangePriority,
    getPossiblePriceRangePriorityList,
    setLoading,
} from 'actions/sim_traffic_ranges';
import {connect} from 'react-redux';

const {StringType} = Schema.Types;

const SIMTrafficRangesAddNewModal = memo(({show, setLoading, subdestination, getList, filters, perPage, codeList, onClose, addNewPriceRangePriority, getPossiblePriceRangePriorityList}) => {
    let formRef;
    const formModal = Schema.Model({
        prefix: StringType('This field should only contain numbers')
            .pattern(/^\d+$/, 'This field should only contain numbers')
            .minLength(1, 'This is the minimum for this field')
            .maxLength(15, 'This is the maximum for this field')
            .isRequired('This field is required')
    });

    const [codeListState, onChangeCodeListState] = useState(null);
    const [hasError, onHasError] = useState({error: false, message: null});

    useEffect(() => {
        if (show && show.sde_key) {
            getPossiblePriceRangePriorityList(show.sde_key);
        }
    }, [show]);

    useEffect(() => {
        const generatedCodeList = randomCodeList(codeList);
        onChangeCodeListState(generatedCodeList);
    }, [codeList]);

    const onSubmit = (data) => {
        addNewPriceRangePriority(subdestination.sde_key, data.prefix).then((response) => {
            if (response && response.prefix) {
                Alert.success(`${response.prefix} prefix has been created!`);
                getList(filters, 1, perPage);
            } else {
                setLoading(false);
            }
        })
    };

    const randomCodeList = (list) => {
        return shuffleArray(list).splice(0, 10).join(', ');
    };

    return (
        <Modal
            show={show}
            onClose={() => {
                onClose();
            }}
            footer
            successText="Add"
            onSuccess={() => {
                formRef.check();
                const formData = formRef.getFormValue();
                const check = formModal.check(formData);
                const error = Object.keys(check).map(key => check[key])[0];
                const errorBody = {error: error.hasError, message: error.errorMessage};
                onHasError(errorBody);

                console.log('isChecked', error, errorBody);
                if (!errorBody.error) {
                    onSubmit(formData);
                    onClose();
                }
            }}
        >
            For <b>{subdestination && subdestination.sde_name}</b> subdestination prefix can start with one of the following prefixes:
            <br/>
            {codeListState}
            <StyledForm
                ref={ref => formRef = ref}
                modal={formModal}
            >
                <FormGroup>
                    Type prefix:
                     <FormControl
                        name="prefix"
                        errorMessage={hasError.error ? hasError.message : null}
                    />
                </FormGroup>
            </StyledForm>
        </Modal>
    );
});

const mapStateToProps = ({sim_traffic_ranges}) => ({
    codeList: sim_traffic_ranges.codeList,
    perPage: sim_traffic_ranges.perPage,
    page: sim_traffic_ranges.page,
    loading: sim_traffic_ranges.loading
});

const mapDispatchToProps = {
    addNewPriceRangePriority,
    getPossiblePriceRangePriorityList,
    setLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(SIMTrafficRangesAddNewModal);

const StyledForm = styled(Form)`
  margin-top: 20px;
  & .rs-btn {
    margin-right: 10px;
  }
  & .rs-form-control-wrapper {
    width: auto;
    margin-left: 10px
  }
  & .rs-form-control-wrapper .rs-input {
    width: 200px;
  }
  
`;