import React from 'react';
import Modal from '../../../components/Modal';
import { api } from '../../../api/loginRoutes';
import { Alert } from 'rsuite';
import {ButtonPrimary} from "../../../components/base/BaseButton";
import {getServiceSmsPrefix, servicePick} from "../../../utils/helpers";


export default (
    {
        service,
        getList,
        disabled,
        update,
        isSetTest,
        pr_key,
        incorporatedOnly,
        selectedNumbersInfo,
    }
) => {

    const [show, setShow ] = React.useState(false);
    const [_disabled, setDisabled ] = React.useState(false);

    const method = isSetTest
        ? incorporatedOnly ? servicePick(service, 'incorporated_number:set_test_number', 'sms.supplier_gan:set_test_number_list') 
            : getServiceSmsPrefix(service, 'price_range_number:set_test_number_list')
        : incorporatedOnly ? servicePick(service, 'incorporated_number:unset_test_number', 'sms.supplier_gan:unset_test_number_list')
        : getServiceSmsPrefix(service, 'price_range_number:unset_test_number_list');

    const onSubmit = async () => {
        const numbersList = getList().list;
        
        
        let params = {};

        if (incorporatedOnly && !service) {
            params = {
                target: {
                    // ...(service ? {trunk_id: trunkId} : {'sms.trunk_id': trunkId}) // TODO 
                }
            };
        }

        if (incorporatedOnly) {
            if (service) {
                params.inn_key_list = selectedNumbersInfo.map(item => item.inn_key);
            } else {
                params['sms.supplier_gan_key_list'] = selectedNumbersInfo.map(item => item.slg_key);
            }
        } else {
            params[getServiceSmsPrefix(service, 'pr_key')] = pr_key;
            params.prn_key_list = numbersList;
        }

        setDisabled(true);

        const result = await api(method, params);

        if (!result) {
            setDisabled(false);
            return;
        }

        const resultNumbers = incorporatedOnly 
            ? result['incorporated_numbers'] 
            : result['price_range_numbers'];

        if (resultNumbers === numbersList.length) {
            Alert.success(isSetTest
                ? `Setted ${resultNumbers} numbers`
                : `Unsetted ${resultNumbers} numbers`
            );
        } else {
            if (resultNumbers) {
                Alert.warning(
                    isSetTest
                        ? `Setted ${resultNumbers} numbers, but some weren't setted because allocated numbers can't setted as test`
                        : `Unsetted ${resultNumbers} numbers, but some weren't unsetted because not test numbers can't unsetted`
                );
            } else {
                Alert.error(
                    isSetTest
                        ? `Allocated numbers can't be setted as test`
                        : `Not tested numbers can't be unset`
                );
            }
        }

        setShow(false);
        update();

        setDisabled(false);
    };

    return (
        <>
            <ButtonPrimary disabled={disabled} onClick={() => setShow(true)}>
                {isSetTest ? 'Set test' : 'Unset test'}
            </ButtonPrimary>

            {show &&
                <Modal
                    show={show}
                    title={isSetTest
                        ? 'Set test numbers'
                        : 'Unset test numbers'
                    }
                    onClose={() => setShow(false)}
                    footer={true}
                    successText="Confirm"
                    disabled={_disabled}
                    onSuccess={onSubmit}
                >
                    {isSetTest
                        ? `Selected numbers will be set as test. 
                           Pay your attention - if they are allocated to any dialer they will be revoked from them too.`
                        : `Selected numbers will be unset as test?`
                    }
                </Modal>
            }
        </>
        
    );
}