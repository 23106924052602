import React, {useMemo, useState} from 'react';
import {Alert, Form, Loader, Schema} from 'rsuite';
import {ControlLabel, FormControl, FormGroup, Placeholder} from 'rsuite/es';
import {ButtonPrimary} from "../../../../components/base/BaseButton";
import {api} from '../../../../api/loginRoutes';
import { useEffect } from 'react';

const { NumberType } = Schema.Types;

const formModel = Schema.Model({
    call_duration_max: NumberType().min(1, 'Enter a positive number').max(10800, '3 hours is maximum').isRequired('Required')

});

const TrafficLimitsSettings = ({
    settings, 
    account, 
    isSupplier, 
    ...props
}) => {
    const [callDurationState, setCallDurationState] = useState(null);
    
    useEffect(() => {
        const defaultCallDurationMax = settings.call_duration_max;

        setCallDurationState(defaultCallDurationMax);
    }, [settings.call_duration_max]);

    const [loading, setLoading] = useState(false);


    if (!isSupplier && !settings?.call_duration_max) {
        return  <>
            <div style={{ height: 106, position: "relative" }}>
                <Loader backdrop content="loading..." vertical />
                <Placeholder.Paragraph/>
            </div>
        </>
    }

    let formRef = null;

    const handleApply = async () => {
        if ( !formRef.check() )
            return;

        setLoading(true);

        let formValues = formRef.getFormValue();

        let newData = {
            call_duration_max: parseInt(formValues.call_duration_max || settings.call_duration_max)
        };

        api('account:modify', {
                ...newData,
                cur_key: undefined,
                target: {
                    account_id: account.id
                }
            },
            true).then((result) => {
            if (result.account) {
                setLoading(false);
                Alert.success('Saved');
            } else {
                Alert.error('Something wrong');
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
           {!isSupplier && <Form
                layout="inline"
                model={formModel}
                className="mailingSettings"
                ref={ref => (formRef = ref)}
                // formDefaultValue={{call_duration_max: defaultCallDurationMax}}
            >
                <FormGroup>
                    <ControlLabel> Call Duration </ControlLabel>
                    <FormControl
                        name="call_duration_max"
                        placeholder="2400"
                        style={{width: '180px'}}
                        onChange={setCallDurationState}
                        defalutValue={settings.call_duration_max}
                        value={callDurationState}
                    />
                </FormGroup>
                <FormGroup>
                    <ButtonPrimary
                        style={{padding: '8px 34px', marginLeft: 12}}
                        onClick={handleApply}
                        loading={loading}
                    >
                        Save
                    </ButtonPrimary>
                </FormGroup>

            </Form>}
        </>
    )
}

export default TrafficLimitsSettings