import {connect} from 'react-redux';
import {
    addAuthNumber,
    addUploadAuthNumbers,
    createNewAuthNumberType,
    deleteAuthNumber,
    editAuthNumber,
    getAuthNumbers,
    getAuthNumberType,
    setAnyLoading
} from '../../actions/auth_numbers';
import AuthNumbers_ from './AuthNumbers';


const mapStateToProps = ({auth_numbers, references, auth}) => ({
    loading: auth_numbers.loading,
    data: auth_numbers.auth_number_list,
    numberTypeList: auth_numbers.auth_number_type,
    numberTypeFamilyList: auth_numbers.auth_number_type_family_list,
    spGroupList: references.service_plan_group_list,

    auth_number_list_setted: auth_numbers.auth_number_list_setted,
    loadingItem: auth_numbers.loadingItem,
    service: auth.service,
    permissions: auth.permissions
});

export default connect(mapStateToProps, {
    createNewAuthNumberType,
    getAuthNumberType,
    getAuthNumbers,
    editAuthNumber,
    deleteAuthNumber,
    addAuthNumber,
    addUploadAuthNumbers,
    setAnyLoading
})(AuthNumbers_);