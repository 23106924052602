import React from "react";
import {Form, FormControl, Icon, Input, InputGroup} from "rsuite";
import styled from "styled-components";
import {debounce} from '../../../utils';

export default ({
    onChangeFilter
}) => {

    return (
        <StyledField>
            <Form onChange={debounce((value) => onChangeFilter(value), 700)}>
                <InputGroup>
                    <FormControl
                        name="str"
                        accepter={Input}
                        placeholder={'Sound filename, IVR, CLI, phone or account'}
                    />
                    <InputGroup.Addon>
                        <Icon icon="search" />
                    </InputGroup.Addon>
                </InputGroup>
            </Form>
        </StyledField>
    )
}

const StyledField = styled.div`
    max-width: 400px;
`;