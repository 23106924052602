import React from 'react';
import {Pagination, Schema, Form, InputNumber} from 'rsuite';
import {debounce} from "../../../utils";

import {DEFAULT_PER_PAGE_MEDIUM} from "../../../const";
import s from "./Pagination.module.css"

import CustomField from '../../Form/CustomField';
import styled from "styled-components";

import {useIntl} from "react-intl";
import {useWindowWidth} from "../../../hooks";
import m from "../../../definedMessages"

const { NumberType } = Schema.Types;


export default ({
    total = 0,
    per_page = DEFAULT_PER_PAGE_MEDIUM,
    perPageMaximum = 10000,
    limitWidth = 475,
    activePage = 1,
    hidePerPage,
    onChangePage,
    onChangePerPage,
    showedCount,
    showFirstAndLast=true,
    disabled = false
}) => {

    const handleChange = ({per_page = 0}) => (
        per_page ? onChangePerPage(+per_page) : null
    );

    const perPageModel = Schema.Model({
        per_page: NumberType()
            .isInteger('Integer')
            .isRequired('Required')
            .min(1,'Minimum 1')
            .max(perPageMaximum,`Maximum ${perPageMaximum}`),
    });

    const {formatMessage} = useIntl();
    const resizedWidth = useWindowWidth();

    return (
        <div className={s.wrapper}>
            <div className={s.pagination}>
                <StyledPagination
                    size="xs"
                    pages={Math.ceil(total/per_page)}
                    activePage={activePage || 1}
                    total={total || 0}
                    maxButtons={5}
                    onSelect={onChangePage}
                    // disabled={disabled || total === 0}
                    first={showFirstAndLast}
                    last={showFirstAndLast}
                    next
                    prev                
                />
            </div>

            {!hidePerPage &&
                <Form
                    layout="inline"
                    model={perPageModel}
                    className={s.form}
                    formDefaultValue={{per_page}}
                    onChange={debounce((value) => {
                        const formChecked = perPageModel.check(value);
                        const formValid = formChecked?.per_page && !formChecked.per_page?.hasError;

                        if (formValid)
                            handleChange(value);
                    }, 500)}
                >
                    <CustomField
                        name="per_page"
                        accepter={InputNumber}
                        errorPlacement="topRight"
                        defaultValue={per_page}
                        max={perPageMaximum}
                        min={1}
                        size="sm"
                        style={{width: 135}}
                        placeholder={formatMessage(m.rowsPerPage)}
                        disabled={disabled}
                    />
                </Form>
            }

            {!!showedCount &&
            <StyledCount>
                {(resizedWidth > (limitWidth + 75)) && formatMessage(m.total) + ':'} {showedCount}
            </StyledCount>}

        </div>
    )
}

const StyledCount = styled.div`
    && {
        margin: 5px 6px;
        color: gray
    }
`;

const StyledPagination = styled(Pagination)`
    && {
        border: 1px solid #C4C4C4;
        border-radius: 3px;
        
        a {
            color: #C4C4C4;
            border-right: 1px solid #C4C4C4;
            border-radius: 0 !important;
        }
        
        .rs-pagination-btn:last-child a {
            border-right: none;
        }
        
        & > li.rs-pagination-btn-active > a {
            color: #fff;
            background-color: var(--color-brand2);
        }
    }
`;