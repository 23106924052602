import React, {useState, useMemo, useRef} from 'react';
import {Alert, Radio, RadioGroup} from 'rsuite';
import Modal from '../../../components/Modal';
import FormRandom from "./FormRandom";
import FormTemplateList from "./FormTemplateList";
import FormNumbersList from "./FormNumbersList";
import {
    PRICE_RANGE_GENERATE_BY_SDE_API,
    PRICE_RANGE_GENERATE_BY_TEMPLATE_API,
    PRICE_RANGE_GENERATE_BY_NUMBER_LIST_API
} from "../../../const/apiMethods";
import {api} from "../../../api/loginRoutes";
import {Spacer} from "../../../components/base/Spacer";
import ModalNewRangeResult from "./ModalNewRangeResult";
import {getServiceSmsPrefix, responseErrorToString} from "../../../utils";
import {ButtonPrimary} from "../../../components/base/BaseButton";



export default ({sde_key, service, update, handleSubmit}) => {
    const templateMethod = getServiceSmsPrefix(service, PRICE_RANGE_GENERATE_BY_TEMPLATE_API);
    const randomMethod = getServiceSmsPrefix(service, PRICE_RANGE_GENERATE_BY_SDE_API);
    const numberListMethod = getServiceSmsPrefix(service, PRICE_RANGE_GENERATE_BY_NUMBER_LIST_API);

    const [newNumbersCount, setNewNumbersCount] = useState(null);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formMethod, setFormMethod] = useState(PRICE_RANGE_GENERATE_BY_TEMPLATE_API);
    const formRef = useRef(null);
    const [resultDataRejected, setResultDataRejected] = useState([]);
    const [resultData, setResultData] = useState([]);
    const [showResultModal, setShowResultModal] = useState(false);


    const formMethods = useMemo(() => [
        {id: templateMethod, label: "Per Template"},
        {id: randomMethod, label: "Random"},
        // {id: PRICE_RANGE_GENERATE_BY_NUMBER_LIST_API, label: "Numbers"},
    ], [service]);


    React.useEffect(() => {
        setFormMethod(!!sde_key ? randomMethod : templateMethod);
    }, [sde_key, service]);


    const onSubmit = () => {
        if ( !formRef.current.check() )
            return;

        setLoading(true);

        const value = {...formRef.current.getFormValue()};

        if (value.template_list) {
            value.template_list = value.template_list
                .replace(/\r?\n/g, ' ').split(' ').filter(s => s !== '');
        }
        if (value.number_list) {
            value.number_list = value.number_list
                .replace(/\r?\n/g, ' ').split(' ').filter(s => s !== '');
        }
        if (value.ranges) {
            value.ranges = +value.ranges;
        }


        api(formMethod, value)
            .then(response => {
                setLoading(false);

                console.log(response);

                if (!response)
                    return;

                if (response.reason_code) {
                    Alert.error(responseErrorToString(response, response.status));
                    return;
                }

                if (formMethod === PRICE_RANGE_GENERATE_BY_NUMBER_LIST_API) {
                    setShowResultModal(true);
                    setNewNumbersCount(response.new_numbers_count);
                    setResultDataRejected(response.rejected_numbers);
                    setResultData(response.price_range_list);
                    setShow(false);
                    return;
                }

                Alert.success(`Generated ${response.ranges || 0} ranges`);
                setShow(false);

                if (handleSubmit) {
                    handleSubmit(response);
                }

                update();
            })
    };

    return (
        <>
            <ButtonPrimary
                onClick={() => setShow(true)}
            >
                + Add ranges
            </ButtonPrimary>

            <Modal
                show={show}
                successText="Create"
                width={600}
                footer={true}
                disabled={loading}
                title="Add new ranges"
                onSuccess={onSubmit}
                onClose={() => {
                    setShow(false);
                }}
            >
                <RadioGroup 
                    value={formMethod} 
                    onChange={setFormMethod}
                    inline
                >
                    {formMethods.map(item => (
                        <Radio value={item.id}>{item.label}</Radio>
                    ))}
                </RadioGroup>
                <Spacer size={24}/>

                {formMethod === randomMethod &&
                    <FormRandom sde_key={sde_key} ref={formRef} service={service}/>
                }
                {formMethod === templateMethod &&
                    <FormTemplateList ref={formRef} service={service}/>
                }
                {formMethod === numberListMethod &&
                    <FormNumbersList ref={formRef} service={service}/>
                }
            </Modal>

            <ModalNewRangeResult
                newNumbersCount={newNumbersCount}
                show={showResultModal}
                data={resultData}
                rejectedNumbers={resultDataRejected}
                onClose={() => setShowResultModal(false)}
            />

        </>
    );
};