import React from 'react';
import Modal from '../../../components/Modal';
import {api} from '../../../api/loginRoutes';
import {Alert} from 'rsuite';
import Icon from 'rsuite/es/Icon';
import {ButtonClose as Revoke} from "../../../components/base/BaseButton";
import {servicePick, getServiceSmsPrefix} from "../../../utils/helpers";


export default (
    {
        service,
        getList, 
        disabled, 
        update, 
        pr_key, 
        incorporatedOnly, 
        selectedNumbersInfo
    }
) => {
    const [show, setShow] = React.useState(false);
    const [_disabled, setDisabled] = React.useState(false);


    // eslint-disable-next-line react-hooks/exhaustive-deps

    const onSubmit = async () => {
        setDisabled(true);
        const numbersKeysList = getList().list;

        let params = {};

        if (incorporatedOnly && !service) {
            params = {
                target: {
                    // ...(service ? {trunk_id: trunkId} : {'sms.trunk_id': trunkId}) // TODO 
                }
            };
        }

        if (incorporatedOnly) {
            if (service) {
                params.inn_key_list = selectedNumbersInfo.map(item => item.inn_key);
            } else {
                params['sms.supplier_gan_key_list'] = selectedNumbersInfo.map(item => item.slg_key);
            }

        } else {
            params[getServiceSmsPrefix(service, 'pr_key')] = pr_key;
            params.prn_key_list = numbersKeysList;
        }

        const method = incorporatedOnly
            ? servicePick(service, 'incorporated_number:set_block_allocation', 'sms.supplier_gan:set_block_allocation_list')
            : getServiceSmsPrefix(service, 'price_range_number:set_block_allocation_list');

        const result = await api(method, params);

        if (!result) {
            setDisabled(false);
            return;
        }

        const blockedNumbersCountName = incorporatedOnly ? servicePick(service, 'incorporated_numbers', 'sms.supplier_gan') : 'price_range_numbers';
        const resultNumbers = Object.keys(result).includes(blockedNumbersCountName) ? result[blockedNumbersCountName] : '0';

        if (resultNumbers) {
            Alert.success(`${resultNumbers} number were blocked`);
            setShow(false);
            update();
        }
        
        setDisabled(false);

    };
    return (
        <>
            <Revoke
                disabled={disabled}
                onClick={() => setShow(true)}
            >
                <Icon icon="lock"/> Block
            </Revoke>
                {show && <Modal
                    show={show}
                    title="Block numbers"
                    onClose={() => setShow(false)}
                    footer={true}
                    successText="Confirm"
                    disabled={_disabled}
                    onSuccess={onSubmit}
                >
                    Selected numbers will be set as blocked. It means you can't allocate these numbers to any dialer or set test
                </Modal>
            }
        </>

    );
}
