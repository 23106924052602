import React from 'react';
import {Schema, SelectPicker, Input} from 'rsuite';
import TableEditCellsNew from '../../components/Table/TableEditCells/TableEditCellsNew';
import {useWindowHeight} from '../../hooks';

const {StringType} = Schema.Types;

const senderModel = Schema.Model({
    senderid: StringType().maxLength(40, '40 characters length is maximum for sender')
});
const textModel = Schema.Model({
    text: StringType().maxLength(1020, '1020 characters length is maximum for text')
});
const originModel = Schema.Model({
    sender_name: StringType().maxLength(40, '40 characters length is maximum for origin')
        .isRequired('Origin is required')
});

const matchNameList = [
    {
        match: 'equal',
        label: 'Equal'
    },
    {
        match: 'contain',
        label: 'Contain'
    },
    {
        match: 'startwith',
        label: 'Start with'
    }
];

const formModel = Schema.Model.combine(
    senderModel,
    textModel,
    originModel
);

const RulesTable = ({
    data,
    ...props
}) => {

    const columns = [
        {
            accepter: Input,
            flexGrow: 2,
            id: 'sender_name',
            align: 'center',
            label: 'Origin',
            dataKey: 'sender_name',
            fieldName: 'sender_name',
            name: 'Origin',
            model: originModel,
            placeholder: 'Origin',
        },
        {
            accepter: Input,
            flexGrow: 2,
            id: 'senderid',
            align: 'center',
            label: 'Sender',
            dataKey: 'senderid',
            fieldName: 'senderid',
            name: 'Sender ID',
            model: senderModel,
            placeholder: 'Sender ID',
        },
        {
            accepter: SelectPicker,
            flexGrow: 1,
            data: matchNameList,
            valueKey: 'match',
            labelKey: 'label',
            id: 'match',
            name: 'Match',
            dataKey: 'match',
            value: ({match}) => {
                const matchValue = matchNameList.find(value => value.match === match);
                return matchValue.label
            },
            label: 'Match',
            align: 'center',
            searchable: false,
            cleanable: false,
            placeholder: 'Match'
        },
        {
            accepter: Input,
            flexGrow: 4,
            newGrow: 7,
            id: 'text',
            align: 'center',
            label: 'Text',
            dataKey: 'text',
            fieldName: 'text',
            name: 'Text',
            model: textModel,
            placeholder: 'Text',
        }
    ];


    return (
        <TableEditCellsNew
            rowIdKey={'key'}
            virtualized
            data={data}
            height={500}
            columns={columns}
            headerHeight={40}
            autoHeight={false}
            shouldUpdateScroll={false}
            formModel={formModel}
            addButtonWidth={104}
            errorPlacement={"bottom"}
            {...props}
        />
    )
};

export default RulesTable;


// const StyledTable = styled(TableEditCellsNew)`
//     && {
//         box-shadow: none;
//         border-radius: 5px;
//         margin-top: 10px;
//         margin-bottom: 10px;
        
//         .rs-table-row {
//             &.rs-table-row-header {
            
//                 &,
//                 & .rs-table-cell {
//                     background: #fff !important;
//                     color: #fff !important;
//                 }
//             }
            
//             &.row-active {
//                 color: var(--color-darkgrey);
                
//                 .rs-table-cell {
//                     background: var(--color-row-active);
//                 }                
//             }
            
//             &.row-closed {
//                 color: #6a686a;
                
//                 .rs-table-cell {
//                     background: #d4d4d4;
//                 }            
//             }
            
//             &.row-disabled {
            
//                 .rs-input,
//                 .rs-input-number {
//                     background: transparent;
//                 }
                
//                 .rs-table-cell {
//                     background: #d6d6db;
//                 }
                
//                 &:hover .rs-table-cell {
//                     background: #d6d6db !important;
//                 }
//             }
//         }
//     }
    
//     &&.rs-table {
//         border-radius: 4px;
//     }
//     .rs-table-row.rs-table-row-header {
//         font-weight: 700;
//         border-bottom: 1px solid var(--color-bg);
//     }
    
//      .rs-table-row-header .rs-table-cell-content {
//         color: var(--color-darkgrey) !important;
//         line-height: 10px;
//     }

//     .rs-table-body-row-wrapper .rs-table-cell-content {
//         padding: 5px 10px !important;
//     }
// `;