import React from 'react';
import FormHOC from '../../hoc/FilltersForm';
import {Tooltip, Icon, InputGroup, Input, Whisper} from 'rsuite';
import styled from 'styled-components';

import {SCREEN_MEDIA} from '../../const';
const {md} = SCREEN_MEDIA;

export default FormHOC(({apiKey}) => {

    return (
        <CurrentApiKey>
            <InputGroup>
                <InputGroup.Addon className="currentApiHeader__header">Current API key:</InputGroup.Addon>
                <Input className="currentApiHeader__value" value={apiKey}/>
                <InputGroup.Addon>
                    <Whisper 
                        placement="bottom" 
                        trigger="click"
                        speaker={<Tooltip>This api key can be changed at "Keys page". That will be pasted to all api links</Tooltip>}
                    >
                        <Icon icon="info"/>
                    </Whisper>
                </InputGroup.Addon>
            </InputGroup>
        </CurrentApiKey>
    )
});


const CurrentApiKey = styled.div`
    margin-top: 20px; 

    @media (min-width: ${md.min}px) {
        display: inline-block;
        margin-left: 40px;
        margin-top: 0;
        position: absolute;
        top: -5px;
    }
    
    .currentApiHeader__header {
        font-size: 13px;
        user-select: none;
    }
    .currentApiHeader__value {
        margin-right: 30px;
    }
`;