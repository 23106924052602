import React, {useMemo, useContext, useState, useEffect} from 'react';
import {Icon, Table, Tooltip, Whisper} from 'rsuite';
import {useIntl} from "react-intl";
import styled from 'styled-components';
import {ButtonRowClient, ButtonToolbar} from "../../components/base/BaseButton";
import m from "../../definedMessages";
import {RtlContext} from "../../App";
import {ASC_SORT, DESC_SORT} from '../../const';
import {servicePick} from "../../utils/helpers";
import TableServerSort from '../../components/Table/TableServerSort'
import { usePrevious } from 'hooks';

export default ({
    data = [], 
    loading,
    page, 
    count,
    trunks,
    prices,
    service,
    location,
    per_page, 
    savedPerPage, 
    onChangePage,
    onChangePerPage, 
    customChangePage,
    customChangePerPage,
    onShowGetNumbersModal,
    spAuth,
    isIncorporated,
    accountInfo,
    isTest,
    isSupport,
    accountDialerState,
    currentServicePlan,
    filters,
    getItems,
    setSortState
}) => {

    const defaultSortColumn = 'subdestination_name';
    const [currentSortColumn, setCurrentSortColumn] = useState(defaultSortColumn);

    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);

    const accountSpList = accountDialerState?.account_service_plan_list || [];
    const currentSp = accountSpList.find(item => {
        return item.sp_key === filters?.sp_key
    });

    const activeTrunks = trunks.filter((trunk) => {
        return trunk.active && currentSp && trunk.sp_key === currentSp.sp_key;
    });

    const actionShowFlag = currentSp 
        && currentSp.allocation_limit 
        && currentSp.allocation_limit.hasOwnProperty('allocate') 
        && currentSp.allocation_limit.allocate
        && activeTrunks.length;


    const columns = [
        // {
        //     id: 'subdestination_name',
        //     label: formatMessage(m.subdestination),
        //     dataKey: 'subdestination_name',
        //     value: ({subdestination_name, template}) => (
        //             `${subdestination_name ? subdestination_name : ""}${template ? ` (${template})` : ""}`
        //     ),
        //     align: 'center',
        //     flexGrow: 3,
        //     minWidth: 180,
        //     sortable: true,
        //     spAuth: true
        // },
        ...(!currentServicePlan?.gan ? [{
            id: 'prefix',
            label: formatMessage(m.prefix),
            dataKey: 'prefix',
            // align: 'center',
            flexGrow: 1,
            minWidth: 120,
            sortable: !isIncorporated,
            spAuth: false
        }] : []),
        ...(service || !service && !currentServicePlan?.gan ? [{
            id: 'subdestination_name',
            label: formatMessage(m.subdestination),
            dataKey: 'subdestination_name',
            value: ({subdestination_name, template}) => (
                    `${subdestination_name ? subdestination_name : ""}${template ? ` (${template})` : ""}`
            ),
            align: 'center',
            flexGrow: 3,
            minWidth: 180,
            sortable: true,
            // spAuth: true
        }] : []),
        // {
        //     id: 'range',
        //     label: formatMessage(m.rangeName),
        //     dataKey: 'range_name',
        //     value: (({range_name}) => `${range_name}`),
        //     flexGrow: 4,
        //     minWidth: (actionShowFlag ? 210 : 200),
        //     align: 'center',
        //     spAuth: false,
        //     sortable: true,
        //     isIncorporated: false,
        // },
        {
            id: 'incorporated_group_name',
            label: formatMessage(m.groupName),
            dataKey: 'incorporated_group_name',
            value: (({incorporated_group_name}) => `${incorporated_group_name}`),
            flexGrow: 3,
            minWidth: (actionShowFlag ? 210 : 200),
            align: 'center',
            spAuth: false,
            sortable: true,
            isIncorporated: true,
        },
        {
            id: 'test_trunk_number_list',
            label: formatMessage(m.testNumbers),
            dataKey: 'test_trunk_number_list',
            value: (({test_trunk_number_list}) => {
                if (!test_trunk_number_list)
                    return;

                const firstElement = test_trunk_number_list[0];

                return (
                    firstElement
                        ? <>
                            <Whisper
                                placement="auto"
                                speaker={
                                    <Tooltip>{test_trunk_number_list.join("\n")}</Tooltip>
                                }
                            >
                                 <span>{firstElement}</span>
                            </Whisper>
                            <Whisper
                                placement="auto"
                                speaker={
                                    <Tooltip>{formatMessage(m.allTheTrafficMustBeClearedByTheCarrier)}</Tooltip>
                                }
                            >
                                <StyledTooltipIcon icon='info'/>
                            </Whisper>
                        </>
                        :
                        <span>—</span>
                );
            }),
            flexGrow: 2,
            minWidth: 130,
            align: 'center',
            spAuth: false
        },
        {
            id: 'available_numbers',
            label: formatMessage(m.availableNumbers),
            dataKey: 'available_numbers',
            flexGrow: 1,
            minWidth: 140,
            align: 'right',
            spAuth: false,
            sortable: false,
        },
        ...(!isTest && !isSupport ? [{
            id: 'payout',
            label: formatMessage(m.payout),
            value: (({payment_terms_rate_list}) => {
                if (payment_terms_rate_list && !payment_terms_rate_list.length)
                    return "—";
                const obj = payment_terms_rate_list.find(value => {
                    return value.payment_terms_name === (accountInfo.hasOwnProperty('payment_terms_name') ? accountInfo.payment_terms_name : '');
                });
                if (obj) {
                    return `${Number(obj.rate).toFixed(4)} ${accountInfo.currency_name}`;
                } else {
                    return '—';
                }
            }),
            dataKey: "payout",
            // flexGrow: 1,
            width: 120,
            align: 'right'
        }] : []),
        ...(service ? [{
            id: 'min_dur',
            label: formatMessage(m.minDuration),
            value: (() => <>
                <span>25 sec</span>
                <Whisper
                    placement="auto"
                    speaker={
                        <Tooltip>{formatMessage(m.pemiumyDoesntPayForCallsBelowDuration, {count: 25})}</Tooltip>
                    }
                >
                    <StyledTooltipIcon icon='info'/>
                </Whisper>
            </>),
            dataKey: "min_dur",
            flexGrow: 1,
            minWidth: 120,
            align: 'center',
            spAuth: false,
            isIncorporated: false,
        }] : []),
        {
            id: 'comment',
            label: formatMessage(m.comment),
            dataKey: "comment",
            value: ({supplier_comment}) => <span title={supplier_comment}>{supplier_comment}</span>,
            flexGrow: 1,
            minWidth: 120,
            align: 'center',
            isIncorporated: true,
        },
        ...(actionShowFlag ? [{
            id: 'table_actions',
            label: formatMessage(m.action),
            value: rowData => {
                const data = {
                    range_name: rowData.range_name,
                    pr_key: rowData.pr_key,
                    ing_key: servicePick(service, rowData.ing_key, rowData["sms.supplier_gan_group_id"]),
                    sp_key: rowData.sp_key,
                    sde_key: rowData.sde_key,
                    subdestination_name: rowData.subdestination_name,
                    test_trunk_number_list: rowData.test_trunk_number_list,
                    incorporated_group_name: rowData.incorporated_group_name,
                    available_numbers: rowData.available_numbers,
                    template: `${rowData.prefix}${rowData.digit > 0 ? 'X'.repeat() : ''}`,
                    template_dest: rowData.template,
                };

                const trunkSpList = trunks.map((trunk) => trunk.sp_key);
                    
                const disabled = !trunkSpList.includes(data.sp_key);

                return (
                    <StyledButtonToolbar>
                        <ButtonRowClient
                            size="sm"
                            onClick={() => {
                                onShowGetNumbersModal(data)
                            }}
                            disabled={disabled}
                        >
                            <b>{formatMessage(m.getNumbers)}</b>
                        </ButtonRowClient>
                    </StyledButtonToolbar>
                );
            },
            flexGrow: 1,
            width: rtl ? 120 : 110,
            align: 'center',
        }] : [])
    ]
        .filter(value => {
            return value.spAuth === undefined || value.spAuth === spAuth
        })
        .filter(value => {
            return value.isIncorporated === undefined || value.isIncorporated === isIncorporated
        });

    useEffect(() => {
        const sortColumn = !isIncorporated ? defaultSortColumn : 'incorporated_group_name';
    
        // setSortState({column: sortColumn, type: ASC_SORT});
        setCurrentSortColumn(sortColumn);
    }, [spAuth, isIncorporated])

    const testColumns = [];


    if (isTest) {
        for (const column of columns) {
            if (column.id === 'table_actions') {
                columns.splice(columns.indexOf(column), 1)
            }
        }
    }

    // if (!isTest && !isSupport) {
    //     for (const column of nonTestColumns) {
    //         columns.splice(-1, 0, column);
    //     }
    // }


    const allTermList = [];

    for (const priceRange of data) {
        if (!priceRange.payment_terms_rate_list)
            continue;

        for (const term of priceRange.payment_terms_rate_list) {
            allTermList.push(term.payment_terms_name);
        }
    }

    const uniqueTermList = allTermList.filter((v, i, a) => a.indexOf(v) === i);
    
    for (const term of uniqueTermList) {
        testColumns.push(
            {
                id: 'payouts',
                label: `${formatMessage(m.payout)} ${term}`,
                dataKey: 'payment_terms_rate_list',
                value: (({payment_terms_rate_list}) => {
                    const obj = payment_terms_rate_list.find(value => value.payment_terms_name === term);
                    
                    let currency = payment_terms_rate_list.find(value=> value.rate !== null);
                    if (currency) {
                        currency = currency.currency_name;
                    }
                    if (obj) {
                        if (accountInfo.hasOwnProperty('currency_name')) {
                            return `${Number(obj.rate).toFixed(4)} ${currency ? currency : ''}`;
                        }
                    } else {
                        return '—';
                    }
                }),
                flexGrow: 1,
                minWidth: 120,
                align: 'right'
            });
    }

    if (isTest) {
        for (const column of testColumns) {
            columns.splice(2, 0, column);
        }
    }

    return (
        <>
            <StyledTableServerSort
                {...{
                    rtl,
                    data,
                    count,
                    page,
                    per_page: savedPerPage || per_page,
                    loading: loading,
                    onChangePage,
                    onChangePerPage,
                    customChangePage,
                    customChangePerPage,
                    columns
                }}
                columnSelectorLSKey={"PriceTable"}
                originalColumns={columns}
                noSelector={true}
                getItems={getItems}
                data={data}
                isChangedColumns={false}
                extraColumnUpdate={prices && !!prices.length && !loading}
      
                defaultSortColumn={defaultSortColumn}
                sortColumn={currentSortColumn}
                onSortColumn={(column, type) => {
                    setCurrentSortColumn(column)
                }}
                ispagination
                showedCount={count}
                showFirstAndLast={false}
            />
        </>
    );

};

const StyledTableServerSort = styled(TableServerSort)`
  && {
      margin-bottom: 20px;
  }
`

const StyledButtonToolbar = styled(ButtonToolbar)`
  && {
    margin-top: -4px;
    .rs-btn-primary, .rs-ripple-pond {
      font-size: 12px;
    }
  }
`;

const StyledTooltipIcon = styled(Icon)`
    margin-left: 5px;

    .rtl & {
        margin-left: 0;
        margin-right: 5px;
    }
`;