import React from 'react';
import CustomField from '../Form/CustomField';
import { SelectPicker } from 'rsuite';

// const numbers =  ['1000','10000','100000','1000000'].map( x => ({label:x, value:+x}))
const numbers =  ['1000'].map( x => ({label:x, value:+x}))

export const NumbersSelect = (props) => (
    <CustomField
        accepter={SelectPicker}
        data={numbers}
        label="Number per range"
        name="numbers"
        cleanable = {false}
        searchable = {false}
        {...props}
    />
)


export default NumbersSelect;