import React from "react";
import {
    Form, FormControl, InputGroup, FormGroup, CheckPicker
} from "rsuite";
import styled from "styled-components";
import {debounce} from "utils";
import Checkbox from "hoc/Checkbox";

import m from "definedMessages";


export default ({
    filter,
    disabled,
    subAccountList,

    onChangeFilter,
    formatMessage
}) => {
    return (
        <StyledForm
            formDefaultValue={filter}
            onChange={debounce((value) => {
                onChangeFilter(value);
            }, 300)}
        >
            {/* <StyledFormGroup> */}
                <FormControl
                    disabled={disabled}
                    placeholder={formatMessage(m.rangeOrNumber)}
                    name="filter_phone"
                    errorPlacement="topRight"
                />
                <FormControl
                    disabled={disabled}
                    placeholder={formatMessage(m.subaccounts)}
                    accepter={CheckPicker}
                    data={subAccountList}
                    valueKey="id"
                    labelKey="name"
                    name="subacc_id_list"
                    errorPlacement="topRight"
                />
                <CheckboxGroup>
                    <StyledField>
                        <Checkbox
                            disabled={disabled || filter?.subacc_id_list.length}
                            name="show_allocated"
                            defaultChecked={filter?.show_allocated}
                        >
                            {formatMessage(m.showAllocated)}
                        </Checkbox>
                    </StyledField>
                    <StyledField>
                        <Checkbox
                            disabled={disabled || filter?.subacc_id_list.length}
                            name="show_free"
                            defaultChecked={filter?.show_free}
                        >
                            {formatMessage(m.showFree)}
                        </Checkbox>
                    </StyledField>
                </CheckboxGroup>
            {/* </StyledFormGroup> */}
        </StyledForm>
    )
}
const StyledForm = styled(Form)`
    && {
        width: 100%;
        display: flex;
        flex-grow: 1;
    }

    .rs-form-control-wrapper {
        width: 300px;
        margin-right: 20px;
    }
`;

const CheckboxGroup = styled.div`
    && {
        display: flex;
    }
`;

const StyledField = styled.div`
    .rs-form-control-wrapper {
        width: 140px;
        margin-right: 10px;
    }

    /* display: inline-block;
    
    @media (max-width: 767px) {
        display: block;
        
        .rs-input {
            min-width: 30px;
        }
        
        && .rs-control-label {
            display: block;
            margin-bottom: 5px;
            margin-top: 0;
        }
        
        && .rs-form-group {
            display: block;
        }
    } */
`;

const StyledAddon = styled(InputGroup.Addon)`
    &&&& {
        position: relative;
        padding: 0;
    }
`;

const StyledFormControl = styled(FormControl)`
    && {
        width: 100%;
        min-width: 300px;
        max-width: 320px;
        overflow: hidden;
    }
`;

const StyledFormGroup = styled(FormGroup)`
    && {
        display: flex;
        align-items: center;

        .rs-control-label {
            margin-right: 10px;
            margin-bottom: 0px;
        }
    }
`;