import React, {useContext, useMemo} from "react";
import styled from "styled-components";
import {
    ControlLabel, Form, FormControl, Input, InputNumber, 
    List, 
    Loader, Panel, SelectPicker
} from "rsuite";

import {IVR_CREATE_API} from "../../../../const/apiMethods";
import {PermissionContext} from "../../../../App";

import {Spacer} from "../../../../components/base/Spacer";

const defCodes = [
    {"sip": 480},
    {"sip": 486},
    {"sip": 503},
    {"sip": 403},
    {"sip": 487}
];


export default (
    {
        ivrTypes,
        editState,
        showNameEdit,
        forwardedRef,
        formValue,
        setFormValue,
        formModel,
        ivrSoundsList,
        loading,
        activeActionType = null
    }
) => {

    const permissions = useContext(PermissionContext);
    const paramsDisabled = useMemo(() => {
        return !permissions.includes(IVR_CREATE_API) || (!showNameEdit && !editState)
    }, [permissions, editState, showNameEdit]);


    const getValidRule = (name) => {
        return ivrTypes.find(type => type.name === name)?.params_validation;
    };

    return (
        <StyledPanel shaded>
            <BlockHeader>Parameters</BlockHeader>
            <Spacer size={20} />

            {loading &&
                <Loader backdrop size="sm" />
            }

            <Form
                ref={(ref) => forwardedRef(ref)}
                model={formModel}
                formValue={formValue}
                onChange={setFormValue}
            >

                {activeActionType === 'RejectCall' &&
                <>
                    <FormSection>
                        <FormSectionTitle></FormSectionTitle>
                        <FormRow>
                            <ControlLabel>Percent common</ControlLabel>
                            <ParameterNumberField 
                                name="percent" 
                                readOnly={paramsDisabled}
                                min={0}
                                max={100}
                            />
                            <ControlLabel>%</ControlLabel>
                        </FormRow>
                    </FormSection>

                    <FormSection>
                        <FormSectionTitle>SIP codes</FormSectionTitle>
                        <FormRow>
                            <List style={{width: '100%'}}>
                                {defCodes.map((item) => {
                                    return <List.Item>
                                        <StyledSipListItem>
                                            <div className="sip-code">
                                                {item.sip}
                                            </div>
                                            <ParameterNumberField
                                                name={`sip_${item.sip}`}
                                                min={0}
                                                max={100}
                                                disabled={paramsDisabled} 
                                            />
                                            <span>%</span>
                                        </StyledSipListItem>
                                    </List.Item>
                                })}
                            </List>
                        </FormRow>
                    </FormSection>
                </>
                }

                {activeActionType === 'WaitBeforeConnect' &&
                <>
                    <FormSection>
                        <FormSectionTitle>Delay before ringback <small>(0 - 60)</small></FormSectionTitle>
                        <FormRow>
                            <ControlLabel>from</ControlLabel>
                            <ParameterNumberField name="before_min" min={0} max={60} readOnly={paramsDisabled}/>

                            <ControlLabel>to</ControlLabel>
                            <ParameterNumberField name="before_max" min={0} max={60} readOnly={paramsDisabled}/>
                        </FormRow>
                    </FormSection>

                    <FormSection>
                        <FormSectionTitle>Ringback duration <small>(0 - 60)</small></FormSectionTitle>
                        <FormRow>
                            <ControlLabel>from</ControlLabel>
                            <ParameterNumberField name="ringing_min" min={0} max={60} readOnly={paramsDisabled}/>

                            <ControlLabel>to</ControlLabel>
                            <ParameterNumberField name="ringing_max" min={0} max={60} readOnly={paramsDisabled}/>
                        </FormRow>
                    </FormSection>
                </>
                }

                {activeActionType === 'Playback' &&
                <>
                    <FormSection>
                        <FormSectionTitle>Playback <small>(1 - 7200)</small></FormSectionTitle>
                        <FormRow>
                            <ControlLabel>from</ControlLabel>
                            <ParameterNumberField name="playback_min" readOnly={paramsDisabled}/>

                            <ControlLabel>to</ControlLabel>
                            <ParameterNumberField name="playback_max" readOnly={paramsDisabled}/>
                        </FormRow>
                    </FormSection>
                    <FormSection>
                        <FormSectionTitle>Sound</FormSectionTitle>
                        <FormRow>
                            <ParameterSelectField
                                readOnly={paramsDisabled}
                                data={ivrSoundsList}
                                name="sound"
                            />
                        </FormRow>
                    </FormSection>
                </>
                }

                {activeActionType === 'PlaybackLoop' &&
                <>
                    <FormSection>
                        <FormSectionTitle></FormSectionTitle>
                        <FormRow>
                            <ControlLabel>Repeat</ControlLabel>
                            <ParameterNumberField name="repetitions" readOnly={paramsDisabled}/>
                        </FormRow>
                    </FormSection>
                    <FormSection>
                        <FormSectionTitle>Sound</FormSectionTitle>
                        <FormRow>
                            <ParameterSelectField
                                data={ivrSoundsList}
                                name="sound"
                                readOnly={paramsDisabled}
                            />
                        </FormRow>
                    </FormSection>
                </>
                }

                {activeActionType === 'Radio' &&
                <FormSection>
                    <FormSectionTitle>Radio params <small>(1 - 7200)</small></FormSectionTitle>
                    <FormRow>
                        <ControlLabel>from</ControlLabel>
                        <ParameterNumberField name="radio_min" readOnly={paramsDisabled}/>

                        <ControlLabel>to</ControlLabel>
                        <ParameterNumberField name="radio_max" readOnly={paramsDisabled}/>
                    </FormRow>
                </FormSection>
                }

                {activeActionType === 'Ringback' &&
                <FormSection>
                    <FormSectionTitle>Ringbacktone params <small>(1 - 7200)</small></FormSectionTitle>
                    <FormRow>
                        <ControlLabel>from</ControlLabel>
                        <ParameterNumberField name="ringbacktone_min" readOnly={paramsDisabled}/>

                        <ControlLabel>to</ControlLabel>
                        <ParameterNumberField name="ringbacktone_max" readOnly={paramsDisabled}/>
                    </FormRow>
                </FormSection>
                }

                {activeActionType === 'SendDTMF' &&
                <FormSection>
                    <FormSectionTitle></FormSectionTitle>
                    <FormRow>
                        <ControlLabel>DTMF Signal</ControlLabel>
                        <div style={{maxWidth: '50px'}}>
                            <FormControl errorPlacement="topEnd" accepter={Input} name="digit" readOnly={paramsDisabled}/>
                        </div>
                    </FormRow>
                </FormSection>
                }

                {activeActionType === 'Silence' &&
                <FormSection>
                    <FormSectionTitle>Silence params <small>(1 - 7200)</small></FormSectionTitle>
                    <FormRow>
                        <ControlLabel>from</ControlLabel>
                        <ParameterNumberField name="silence_min" readOnly={paramsDisabled}/>

                        <ControlLabel>to</ControlLabel>
                        <ParameterNumberField name="silence_max" readOnly={paramsDisabled}/>
                    </FormRow>
                </FormSection>
                }

                {activeActionType === 'Wait' &&
                <FormSection>
                    <FormSectionTitle>Wait <small>(0 - 7200)</small></FormSectionTitle>
                    <FormRow>
                        <ControlLabel>from</ControlLabel>
                        <ParameterNumberField name="wait_min" min={0} readOnly={paramsDisabled}/>

                        <ControlLabel>to</ControlLabel>
                        <ParameterNumberField name="wait_max" readOnly={paramsDisabled}/>
                    </FormRow>
                </FormSection>
                }

                {activeActionType === 'ReadDTMF' &&
                <FormSection>
                    <FormSectionTitle>Wait <small>(1 - 10)</small></FormSectionTitle>
                    <FormRow>
                        <ParameterNumberField name="read_wait" min={1} max={10} readOnly={paramsDisabled}/>
                    </FormRow>
                </FormSection>
                }

            </Form>
            <Spacer size={20}/>
        </StyledPanel>
    )
};


const ParameterNumberField = (props) => (
    <div className="form-input" style={{maxWidth: '90px'}}>
        <FormControl
            accepter={InputNumber}
            errorPlacement="topEnd"
            min={1}
            max={7200}
            step={1}
            {...props}
        />
    </div>
);


const ParameterSelectField = (props) => (
    <FormControl
        searchable={false}
        cleanable={false}
        errorPlacement="topEnd"
        labelKey="filename"
        valueKey="id"
        style={{width: '100%'}}
        accepter={SelectPicker}
        {...props}
    />
);


const BlockHeader = styled.h5`
    font-family: inherit;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-brand1);
`;

const StyledPanel = styled(Panel)`
    min-height: 100% !important;
    overflow: visible !important;
`;

const FormSection = styled.div`
    & + * {
        padding-top: 20px;
    }
`;

const FormSectionTitle = styled.p`
    padding-bottom: 10px;
    font-weight: 500;
`;

const FormRow = styled.div`
    display: flex;
    align-items: center;

    .rs-list {
        overflow: visible
    }
    
    .rs-control-label {
        margin-right: 10px;
    }
    .form-input {
        padding-right: 15px;
    }
    
    & + * {
        padding-top: 20px;
    }
`;

const StyledSipListItem = styled.div`
    align-items: center;
    display: flex;
    width 100%;

    .sip-code {
        font-weight: 600;
        width 70px;
    }
`;