import styled, { css } from 'styled-components';
import { ControlLabel, FormControl, Toggle } from 'rsuite';
import {CustomModal} from '../../../../components/base/BaseModal';
import { BaseToggle } from 'components/base/BaseToggle';


export const FormModal = styled(CustomModal)`
    .rs-modal-content {
        display: flex;
        flex-direction: column;
    }

    .rs-modal-footer {
        margin-top: auto;
        padding-bottom: 20px;
        padding-top: 20px;
    }
`;

export const FormColumns = styled.div`
    ${props => !props.single && css`
        align-items: stretch;
        display: flex;
    `}

    @media (max-width: 600px) {
        display: block;
    }
`;

export const FormColumn = styled.div`

    ${props => !props.single && css`
        flex: 0 0 45.6%;
        max-width: 45%;
    `}

    position: relative;

    @media (max-width: 600px) {
        flex: auto;
        max-width: 100%;
    }
    
    ${props => 
        props.divider &&
        css`
            flex: 1 0 5%;
        `
    }

    @media (min-width: 768px) {
        padding: 0 15px;
    }

    .rs-form-control-wrapper {
        width: 100%;
    }
`;

export const FormDivider = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;

    .divider__text {
        text-transform: uppercase;
    }

    .divider {
        flex-grow: 1;
        margin: 0;
    }
`;

export const FormTitle = styled.div`
    &&& {
        font-size: 14px;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 20px;
        color: ${props => props.disabled
            ? "var(--color-disabled)"
            : "var(--color-secondary)"
        };

        @media (min-width: 768px) {
            width: 150px;
        }
    }
`;

export const Label = styled(ControlLabel)`
    font-size: 14px;
    padding: 0;   
    text-align: left;

    .rtl & {
        text-align: right;
    }
    
    ${props =>
        props.disabled &&
        css`
            color: var(--color-disabled)
        `
    }
`;

export const Field = styled(FormControl)`

    &&&& {
        min-width: 0;
        width: 100%;
        
        &.rs-picker-disabled {
            opacity: 1;
            
            .rs-picker-toggle {
                border-color: var(--color-grey);
            }
        }
        &.rs-input:hover {
           ${props =>
                props.disabled &&
                css`
                    border-color: var(--color-grey);
                `
            }
        }

        ${props => 
            props.componentClass === 'textarea' &&
            css`
                min-height: 200px;
                overflow-x: auto;
            `
        }
    }

    ${props =>
        props.disabled &&
        css`
            border-color: var(--color-grey);
            opacity: var(--value-disabled-opacity)
        `
    }

`;

export const FormToggle = styled(BaseToggle)`
    margin: 0;
`;