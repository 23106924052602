import React, {useMemo, useEffect, useState} from "react";
import Table from "components/Table/TableClientSort";
import {ASC_SORT} from "const";
import {connect} from "react-redux";
import {getTrafficReports, getFailedMDRReports} from "actions/traffic_reports";
import {isNil, filter, servicePick} from "../../utils";
import { usePrevious } from "hooks";
import {LOCAL_STORAGE_DELAYED_SERVICE_CHANGE} from "../../const/localStorageKeys";

const TrafficReportsTable = (props) => {
    const {
        getTrafficReports, 
        reportsData = [], 
        reportsLoading, 
        reportsCount,

        mdrFailedList = [],
        mdrFailedLoading,
        mdrFailedCount,

        getFailedMDRReports,
        
        isFailedMdr,

        perPage, 
        page, 
        filters, 
        service
    } = props;


    const loading = isFailedMdr ? mdrFailedLoading : reportsLoading;
    const dataList = isFailedMdr ? mdrFailedList : reportsData;
    const count = isFailedMdr ? mdrFailedCount : reportsCount;

    const prevIsFailedMdr = usePrevious(isFailedMdr);

    const columns = useMemo(() => {
        const trafficReportsColumns = [
            {label: "Period", dataKey: "group_period", minWidth: 160, sortable: true},
            {label: "Range", dataKey: "range_name", minWidth: 200, title: true, sortable: true},
            {label: "Originator", dataKey: "originator_name", minWidth: 150, sortable: true},
            {
                label: "Originator AM", 
                dataKey: "originator_manager_name", 
                minWidth: 120,
                width: 320,
                sortable: true
            },
            {
                label: "Dialer", 
                dataKey: "dialer_name", 
                value: ({dialer_name}) => `${dialer_name}`, 
                minWidth: 120,
                width: 280,
                sortable: true
            },
            {
                label: "Dialer AM", 
                dataKey: "dialer_manager_name", 
                minWidth: 120,
                width: 320,
                sortable: true
            },
            {label: "Supplier", dataKey: "supplier_name", minWidth: 120, sortable: true},
            {label: "Zone (origin)", dataKey: "a_wz_name", minWidth: 130, title: true, sortable: true},
            {label: "Zone (dest)", dataKey: "b_wz_name", minWidth: 130, title: true, sortable: true},
            {label: "Dest (origin)", dataKey: "a_de_name", minWidth: 130, title: true, sortable: true},
            {label: "Dest (dest)", dataKey: "b_de_name", minWidth: 130, title: true, sortable: true},
            {label: "Subdest (origin)", dataKey: "a_sde_name", minWidth: 130, title: true, sortable: true},
            {label: "Subdest (dest)", dataKey: "b_sde_name", minWidth: 130, title: true, sortable: true},
            {label: "A-Number", dataKey: "a_number", width: 180, sortable: true},
            {
                label: "Trunk type", 
                dataKey: "service_plan_name", 
                value: ({service_plan_name}) => `${service_plan_name}`, 
                minWidth: 120, 
                width: 240,
                sortable: true
            },
            {label: "B-Number", dataKey: "b_number", minWidth: 180, sortable: true},
            {
                label: "Margin", 
                dataKey: "sum_margin",
                value: ({sum_margin, sum_cur_name}) => `${sum_margin || "0"} ${sum_cur_name}`,
                minWidth: 80,
                width: 120,
                sortable: true,
                align: "right",
                isDefault: true
            },
            {
                label: "Duration", 
                dataKey: "sum_duration",
                value: ({sum_duration}) => `${sum_duration || "0"}`,
                minWidth: 75, 
                sortable: true, 
                align: "right",
                isDefault: true
            },
            {
                label: "Cost", 
                dataKey: "sum_cost_dialer",
                value: ({sum_cost_dialer, sum_cur_name}) => `${sum_cost_dialer || "0"} ${sum_cur_name}`,
                minWidth: 75, 
                sortable: true, 
                align: "right",
                isDefault: true
            },
            {
                label: "Originator Rate",
                value: ({originator_rate}) => `${originator_rate || "0"}`, 
                dataKey: "originator_rate",
                fullLabel: "Originator Rate",
                width: 120,
                sortable: true,
                align: "right"
            },
            {
                label: "Dialer Rate",
                dataKey: "dialer_rate",
                value: ({dialer_rate}) => `${dialer_rate || "0"}`, 
                fullLabel: "Dialer Rate",
                width: 100,
                sortable: true,
                align: "right"
            },
            {
                label: "Calls", 
                dataKey: "count_cost_dialer",
                value: ({count_cost_dialer}) => `${count_cost_dialer || "0"}`,
                minWidth: 75, 
                sortable: true, 
                align: "right",
                isDefault: true
            }
        ];

        const mdrTrafficReportsColumns = [
            {
                label: "Hour",
                dataKey: "dt_hour",
                value: ({dt_hour}) => dt_hour ? dt_hour : "",
                width: 120,
                sortable: true
            },
            {
                label: "Day",
                dataKey: "dt_day",
                value: ({dt_day}) => dt_day ? dt_day : "",
                width: 100,
                sortable: true
            },
            {
                label: "Month",
                dataKey: "dt_month",
                value: ({dt_month}) => dt_month ? dt_month : "",
                width: 100,
                sortable: true
            },
            {
                label: "Year",
                dataKey: "dt_year",
                value: ({dt_year}) => dt_year ? dt_year : "",
                width: 100,
                sortable: true
            },
            {label: "Range", dataKey: "range_name", minWidth: 200, title: true, sortable: true},
            {
                label: "Originator",
                dataKey: "originator_name",
                minWidth: 150,
                sortable: true
            },
            {
                label: "Supplier rate", 
                dataKey: "supplier_rate",
                value: ({supplier_rate, supplier_cur_name}) => `${supplier_rate || "0"} ${supplier_cur_name}`, 
                minWidth: 150, 
                sortable: true
            },
            {
                label: "Operator",
                dataKey: "operator",
                minWidth: 200,
                sortable: true
            },
            {
                label: "Country",
                dataKey: "country",
                minWidth: 200,
                sortable: true
            },
            {
                label: "Supplier", 
                dataKey: "supplier_name", 
                minWidth: 120, 
                width: 240,
                sortable: true
            },
            {
                label: "Supplier AM", 
                dataKey: "supplier_manager", 
                minWidth: 120, 
                width: 240,
                sortable: true
            },
            {
                label: "Supplier trunk",
                dataKey: "supplier_plan",
                minWidth: 120,
                sortable: true
            },
            {
                label: "Dialer rate", 
                dataKey: "dialer_rate", 
                value: ({dialer_rate, dialer_cur_name}) => `${dialer_rate || "0"} ${dialer_cur_name}`, 
                minWidth: 120, 
                sortable: true
            },
            {
                label: "Dialer", 
                dataKey: "dialer_name", 
                value: ({dialer_name}) => `${dialer_name || "no dialer"}`, 
                minWidth: 120, 
                width: 240,
                sortable: true
            },
            {
                label: "Trunk type", 
                dataKey: "service_plan_name", 
                value: ({service_plan_name}) => `${service_plan_name}`, 
                minWidth: 120, 
                width: 240,
                sortable: true
            },
            {
                label: "Dialer AM", 
                dataKey: "dialer_manager", 
                value: ({dialer_manager}) => `${dialer_manager || "no manager"}`, 
                minWidth: 120, 
                width: 240,
                sortable: true
            },
            {label: "B-Number", dataKey: "phone", minWidth: 180, sortable: true},
            {label: "Zone (origin)", dataKey: "sender_wz_name", minWidth: 130, title: true, sortable: true},
            {label: "Zone (dest)", dataKey: "phone_wz_name", minWidth: 130, title: true, sortable: true},
            {label: "Dest (origin)", dataKey: "sender_de_name", minWidth: 130, title: true, sortable: true},
            {label: "Dest (dest)", dataKey: "phone_de_name", minWidth: 130, title: true, sortable: true},
            {label: "Subdest (origin)", dataKey: "sender_sde_name", minWidth: 130, title: true, sortable: true},
            {label: "Subdest (dest)", dataKey: "phone_sde_name", minWidth: 130, title: true, sortable: true},
            {label: "Sender ID", dataKey: "senderid", width: 140, sortable: true},
            {
                label: "Reason", 
                dataKey: "reason",
                value: ({reason}) => {
                    return <a title={reason}>
                        {reason}
                    </a>
                },
                minWidth: 700,
                // width: 700, 
                sortable: true
            },
            {label: "MCC/MNC", dataKey: "mcc_mnc", width: 100, sortable: true},
            {label: "MCC", dataKey: "mcc", width: 100, sortable: true},
            {
                label: "Cost Originator",
                dataKey: "sum_originator_rate",
                value: ({sum_originator_rate, sum_cur_name}) => `${sum_originator_rate || "0"} ${sum_cur_name}`,
                minWidth: 75,
                width: 140, 
                sortable: true,
                align: "right",
                isDefault: true
            },
            {
                label: "Cost Dialer",
                dataKey: "sum_dialer_rate",
                value: ({sum_dialer_rate, sum_cur_name}) => `${sum_dialer_rate || "0"} ${sum_cur_name}`,
                minWidth: 75,
                width: 140, 
                sortable: true,
                align: "right",
                isDefault: true
            },
                        // {
            //     label: "Delivery date",
            //     dataKey: "delivery_datetime",
            //     width: 170,
            //     sortable: true
            // },
            {
                label: "Delivery status",
                dataKey: "delivery_status",
                width: 120,
                sortable: true
            },
            {
                label: "Margin",
                dataKey: "sum_margin",
                value: ({sum_margin, sum_cur_name}) => `${sum_margin || "0"} ${sum_cur_name}`,
                minWidth: 80,
                width: 120,
                sortable: true,
                align: "right",
                isDefault: true
            },
            {
                label: "Messages", 
                dataKey: "count",
                value: ({count}) => count || "0",
                minWidth: 75, 
                width: 140,
                sortable: true, 
                align: "right", 
                isDefault: true
            }
        ];

        const mdrFailedColumns = [
            {
                label: "Hour",
                dataKey: "dt_hour",
                value: ({dt_hour}) => dt_hour ? dt_hour : "",
                width: 120,
                sortable: true
            },
            {
                label: "Day",
                dataKey: "dt_day",
                value: ({dt_day}) => dt_day ? dt_day : "",
                width: 100,
                sortable: true
            },
            {
                label: "Month",
                dataKey: "dt_month",
                value: ({dt_month}) => dt_month ? dt_month : "",
                width: 100,
                sortable: true
            },
            {
                label: "Year",
                dataKey: "dt_year",
                value: ({dt_year}) => dt_year ? dt_year : "",
                width: 100,
                sortable: true
            },
            {label: "Range", dataKey: "range_name", minWidth: 200, title: true, sortable: true},
            {label: "Originator", dataKey: "originator_name", minWidth: 150, sortable: true},
            {
                label: "Operator",
                dataKey: "operator",
                minWidth: 200,
                sortable: true
            },
            {
                label: "Country",
                dataKey: "country",
                minWidth: 200,
                sortable: true
            },
            {
                label: "Supplier rate", 
                dataKey: "supplier_rate",
                value: ({supplier_rate, supplier_cur_name}) => `${supplier_rate || ""} ${supplier_cur_name}`, 
                minWidth: 150, 
                sortable: true
            },
            {label: "Supplier", dataKey: "supplier_name", minWidth: 120, sortable: true},
            {
                label: "Supplier trunk",
                dataKey: "supplier_plan",
                minWidth: 120,
                sortable: true
            },
            {
                label: "Dialer rate", 
                dataKey: "dialer_rate", 
                value: ({dialer_rate, dialer_cur_name}) => `${dialer_rate || ""} ${dialer_cur_name}`, 
                minWidth: 120, 
                sortable: true
            },
            {
                label: "Dialer", 
                dataKey: "dialer_name", 
                value: ({dialer_name, dialer_manager}) => `${dialer_name || "no dialer"} / ${dialer_manager || "no manager"}`, 
                minWidth: 120, 
                width: 480,
                sortable: true
            },
            {label: "B-Number", dataKey: "phone", minWidth: 180, sortable: true},
            {label: "Zone (origin)", dataKey: "sender_wz_name", minWidth: 130, title: true, sortable: true},
            {label: "Zone (dest)", dataKey: "phone_wz_name", minWidth: 130, title: true, sortable: true},
            {label: "Dest (origin)", dataKey: "sender_de_name", minWidth: 130, title: true, sortable: true},
            {label: "Dest (dest)", dataKey: "phone_de_name", minWidth: 130, title: true, sortable: true},
            {label: "Subdest (origin)", dataKey: "sender_sde_name", minWidth: 130, title: true, sortable: true},
            {label: "Subdest (dest)", dataKey: "phone_sde_name", minWidth: 130, title: true, sortable: true},
            {label: "Sender ID", dataKey: "senderid", width: 140, sortable: true},
            {
                label: "Reason", 
                dataKey: "reason",
                value: ({reason}) => {
                    return <a title={reason}>
                        {reason}
                    </a>
                },
                minWidth: 700, 
                // width: 700, 
                sortable: true
            },
            {label: "MCC/MNC", dataKey: "mcc_mnc", width: 100, sortable: true},
            {label: "MCC", dataKey: "mcc", width: 100, sortable: true},
            {
                label: "Messages", 
                dataKey: "count", 
                minWidth: 75, 
                width: 140,
                sortable: true, 
                align: "right", 
                isDefault: true
            }
        ];

        return isFailedMdr ? mdrFailedColumns : servicePick(service, trafficReportsColumns, mdrTrafficReportsColumns);
    }, [service, isFailedMdr]);

    const [tableColumns, setTableColumns] = useState(columns);

    useEffect(() => {
        if (dataList.length) {
            const serviceColumns = columns;

            const groups = filters.group_checkbox;
            //SELECT Columns To SHOW
            const filteredData = filter(serviceColumns, (column) => {
                return !groups.includes(column.dataKey) ? dataList.find(item => !isNil(item[column.dataKey])) || column?.isDefault : true;
            });

            setTableColumns(filteredData);
        }
    }, [service, dataList]);

    const getReportsItems = (page, perPage, sort) => {
        if (isFailedMdr) {
            getFailedMDRReports(filters, page, perPage, sort)
            return;
        }

        getTrafficReports(service, filters, page, perPage, sort);
    };

    useEffect(() => {
        const serviceDelayed = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DELAYED_SERVICE_CHANGE));

        if (isFailedMdr === prevIsFailedMdr && serviceDelayed === null) {
            getReportsItems(1, perPage);
        }
    }, [filters, isFailedMdr]);

    return (
        <Table
            data = {dataList}
            loading = {loading}
            columns = {tableColumns}
            count = {count}
            resizable
            per_page = {perPage}
            page = {page}
            getItems = {getReportsItems}
            ispagination
            sortColumn={tableColumns && tableColumns.length && tableColumns[0].dataKey}
            sortType={ASC_SORT}
            showedCount={count}
        />
    );
};

const mapStateToProps = ({traffic_reports, auth}) => ({
    service: auth.service,

    reportsLoading: traffic_reports.loading,
    reportsData: traffic_reports.list,
    reportsCount: traffic_reports.count,

    mdrFailedLoading: traffic_reports.mdrFailedLoading,
    mdrFailedList: traffic_reports.mdrFailedList,
    mdrFailedCount: traffic_reports.mdrFailedCount,

    perPage: traffic_reports.perPage,
    page: traffic_reports.page
});

const mapDispatchToProps = {
    getTrafficReports,
    getFailedMDRReports
};

export default connect(mapStateToProps, mapDispatchToProps)(TrafficReportsTable);