import React, {useEffect, useState} from 'react';
import {
    Loader,
    Message,
    Alert,
    Container,
    Header,
    Navbar,
    Nav,
    Icon,
    Content,
    FlexboxGrid,
    Panel
} from "rsuite";
import {notAuthApi} from "../../api/auth";
import {Link} from 'react-router-dom';
import VersionLabel from "../../components/VersionLabel/VersionLabel";
import styled from "styled-components";
import EmailVerificationResult from "./EmailVerificationResult";


export default ({
    match,
}) => {

    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [tokenResetPassword, setTokenResetPassword] = useState(false);

    useEffect(() => {
        notAuthApi('account_user:verify_email_modify', {token: match.params.token})
            .then((r) => {
                if (r.token_reset_password) {
                    setTokenResetPassword(r.token_reset_password);
                }
                setSuccess(true);
            }).catch(() => {
                // Alert.error('Email verification token expired, please try to reset password again');
            }).finally(() => {
                setLoading(false);
            });

    }, [match]);

    return (
        <Container style={{height: '100vh'}}>
            <Header>
                <Navbar appearance="inverse">
                    <Navbar.Header>
                    </Navbar.Header>
                    <Navbar.Body>
                        <Nav pullRight>
                            <StyledNavItem
                                componentClass={Link}
                                icon={<Icon icon="sign-in"/>}
                                to="/login"
                            >
                                Log in
                            </StyledNavItem>
                        </Nav>
                    </Navbar.Body>
                </Navbar>
            </Header>
            <Content >
                <FlexboxGrid justify="center" >
                    <FlexboxGrid.Item colspan={12} >
                        <StyledPanel
                            header={<h3>Email Verification</h3>}
                            bordered
                        >
                            {loading
                                ? <Loader size="md" content="Email Verification" />
                                : <EmailVerificationResult
                                    token={tokenResetPassword}
                                    success={success}
                                />
                            }
                        </StyledPanel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <VersionLabel/>
        </Container>
    )
}




const StyledNavItem = styled(Nav.Item)`
    .rs-nav-item-content {
    
        &:hover,
        &:active,
        &:focus {
            background-color: #20b188 !important;
        }
    }
`;

const StyledPanel = styled(Panel)`
    margin-top: 15vh;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
`;