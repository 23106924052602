import React, {useRef, useState, useMemo, useEffect} from "react";
import {Button, Checkbox, Form, FormControl, Input, Schema, SelectPicker} from "rsuite";
import styled from "styled-components";
import Icon from "rsuite/es/Icon";

const {StringType, NumberType, ArrayType} = Schema.Types;

const formModel = Schema.Model({
    senderid: StringType()
        .minLength(1, 'Required minimum 1 character')
        .maxLength(40, "The maximum is only 40 character"),
    mcc_mnc:  StringType()
        .pattern(/^[0-9][\d]*$/, 'The mcc and mcc must contain only digits')
        .maxLength(6, "The maximum is 6 characters"),
    min_rate: NumberType()
        .min(0, 'Minimum 0')
        .max(10, 'Maximum 10')
        .isRequired('Required')
});

export default ({
    onSubmit,
    dialerList,
    supplierList,
    getRoutingList,
    mccMncMatchList,
    exceptionList
}) => {
    const formDefaultValue = {
        is_exception: false,
        supplier_id: null,
        senderid: "",
        mcc_mnc: "",
        mcc_mnc_match_like: true,
        dialer_id: null,
        min_rate: 0
    };

    const formInitValue = {
        is_exception: false,
        supplier_id: null,
        senderid: null,
        mcc_mnc: null,
        mcc_mnc_match_like: true,
        dialer_id: null,
        min_rate: 0
    };

    const formRef = useRef(null);
    const [formValue, setFormValue] = useState(formInitValue);

    // useEffect(() => {
    //     const _formValue = Object.assign({}, formValue);
    //     if (Object.keys(_formValue).some(key => _formValue[key] === "")) {
    //         setFormValue((values) => {
    //             const localValue = Object.keys(values).reduce((acc, key) => {
    //                 if (values[key] !== "") {
    //                     acc[key] = values[key];
    //                     return acc;
    //                 }
    //                 acc[key] = null;
    //                 return acc;
    //             }, {});
    //             return localValue;
    //         });
    //     }
    // }, [formValue]);

    const [createLoading, setCreateLoading] = useState(false);

    const handleSubmit = () => {
        if (!formRef.current.check())
            return;

        setFormValue(formDefaultValue);
        setCreateLoading(true);

        const {supplier_id, dialer_id, ...refFormValue} = {...formRef.current.getFormValue()};
        const _formValue = {
            target: {
                dialer_id,
                supplier_id
            },
            ...refFormValue,
            senderid: refFormValue?.senderid ? refFormValue.senderid : "",
            min_rate: parseFloat(refFormValue.min_rate),
        };

        onSubmit(_formValue)
            .then(res => {
                setCreateLoading(false);

                if (res) {
                    getRoutingList();
                    setFormValue(formInitValue);
                }
            });
    };


    return (
        <StyledForm
            ref={formRef}
            model={formModel}
            formValue={formValue}
            formDefaultValue={formDefaultValue}
            onChange={(value) => {
                setFormValue(value);
            }}
            onSubmit={handleSubmit}
        >
            <StyledFieldWrapper>
                <FormControl
                    accepter={SelectPicker}
                    cleanable={false}
                    searchable={false}
                    defaultValue={false}
                    data={exceptionList}
                    valueKey="value"
                    labelKey="name"
                    name="is_exception"
                    placeholder="Exception"
                    // style={{width: 130}}
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <FormControl
                    accepter={SelectPicker}
                    cleanable
                    searchable
                    data={supplierList}
                    valueKey="supplier_id"
                    labelKey="name"
                    name="supplier_id"
                    placement="topStart"
                    placeholder="Supplier"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <FormControl
                    accepter={Input}
                    name="senderid"
                    placeholder="Sender ID"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <FormControl
                    accepter={Input}
                    name="mcc_mnc"
                    placeholder="MCC/MNC"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <FormControl
                    accepter={SelectPicker}
                    cleanable={false}
                    searchable={false}
                    defaultValue={false}
                    data={mccMncMatchList}
                    valueKey="value"
                    labelKey="name"
                    name="mcc_mnc_match_like"
                    placement="topStart"
                    placeholder="Match"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <FormControl
                    name="dialer_id"
                    accepter={SelectPicker}
                    data={dialerList}
                    valueKey="id"
                    labelKey="name"
                    placement="topStart"
                    placeholder="Dialer"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <FormControl
                    accepter={Input}
                    name="min_rate"
                    placeholder="Rate"
                    // style={{width: 100}}
                />
            </StyledFieldWrapper>

            <StyledSubmitWrapper>
                <Button
                    className="tableFilters_submit"
                    color="green"
                    type="submit"
                    loading={createLoading}
                >
                    <Icon icon="plus"/>
                </Button>
            </StyledSubmitWrapper>
        </StyledForm>
    )
};

const StyledField = styled(FormControl).attrs(props => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    placement: "autoVerticalStart",
    type: "text",
    cleanable: true,
    searchable: false,
    ...props
}))`
`;

const StyledFieldWrapper = styled.div`
    flex: 1 0 200px;
    padding: 5px 10px;
    width: 200px;
`;
const StyledSubmitWrapper = styled.div`
    padding: 5px 10px;
    width: 174px;
`;

const StyledForm = styled(Form)`
    display: flex;
`;