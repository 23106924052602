import React, { useRef } from "react";
import TableServerSort from "components/Table/TableServerSort";
import styled from "styled-components";
import { DEFAULT_PER_PAGE_MAX } from "const";
import m from "definedMessages";


const AuthNumbersTable = ({
    data,
    count,
    page,
    perPage,
    loading,

    setSort,
    getItems,
    
    formatMessage
}) => {
    const isMobile = false;

    const tableRef = useRef(null);

    const columns = [
        {
            label: formatMessage(m.number),
            dataKey: "number", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 196,
            minWidthMobile: 300
        },
        {
            label: `OTP ${formatMessage(m.service)}`,
            dataKey: "otp_service", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 196,
            minWidthMobile: 300
        }
    ];

    return <StyledTable
        shouldUpdateScroll={false}
        ref={tableRef}
        data={data}
        // height="50%"
        loading={loading}
        columns={columns}
        originalColumns={columns}
        count={count}
        per_page={perPage}
        perPageMaximum={DEFAULT_PER_PAGE_MAX}
        page={page}

        // extraHeight={isMobile ? 208 : 180}
        rowHeight={isMobile ? 42 : 30}

        row_key="number"

        showedCount={count}
        ispagination

        onSort={(column, type) => setSort({column, type})}
        getItems={getItems}
    />
};

export default AuthNumbersTable;


const StyledTable = styled(TableServerSort)`
    && {
        margin-bottom: 20px;
    }
`;