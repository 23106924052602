import React, { useState } from 'react';
import {ButtonPrimary} from 'components/base/BaseButton';
import {getCsvResponse} from 'api/loginRoutes';
import {
    INCORPORATED_GROUP_LIST_API,
    SMS_SUPPLIER_GAN_GROUP_GET_LIST_METHOD
} from '../../../const/apiMethods';
import {servicePick, downloadDataAsFile} from 'utils';
import {Icon} from 'rsuite';
import styled from 'styled-components';


export default ({service}) => {
    const [loading, setLoading] = useState(false);

    const method = servicePick(service, INCORPORATED_GROUP_LIST_API, SMS_SUPPLIER_GAN_GROUP_GET_LIST_METHOD);

    const downloadIncorporatedGroup = () => {
        setLoading(true);
        getCsvResponse(method)
            .then((response) => {
                const file = 'gan_group.csv';
                downloadDataAsFile(response, file)
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <StyledButtonPrimary
            loading={loading}
            onClick={downloadIncorporatedGroup}
        >
            <Icon icon='download'/> Download CSV
        </StyledButtonPrimary>
    )
}

const StyledButtonPrimary = styled(ButtonPrimary)`
    && {
        margin-right: 10px;
    }
`;