import React, {useMemo, useContext} from "react";
import {Whisper, Tooltip, Icon} from "rsuite";
import {renderColumn, servicePick} from "utils/helpers";
import {useIntl} from "react-intl";
import CustomizableTable from "components/client/CustomizableTable/CustomizableTable";
import {ButtonRowClient, ButtonToolbar} from "components/base/BaseButton";
import m from "definedMessages";
import {RtlContext} from "../../App";
import {
    SP_KEY__INCORPORATED,
    SP_KEY__GLOBAL_ACCESS
} from "const/index";
import {SCREEN_MEDIA} from "const";
import {useWindowWidth} from "hooks";
import styled, {css} from "styled-components";

const {md} = SCREEN_MEDIA;

export default (
    {
        data = [],
        loading,
        page,
        count,
        trunks,
        per_page,
        savedPerPage,
        onChangePerPage,
        onChangePage,
        onShowGetNumbersModal,
        service,
        filter,
        spAuth,
        accountInfo,
        isTest,
        isSupport,
        accountDialerState,
        isGlobalAccess,
        ...props
    }
) => {

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < md.min;

    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);

    const transformedService = {
        "voice": true,
        "sms": false
    };
    const dataSpKeyList = data.map((value) => value.sp_key);

    const accountSpList = accountDialerState?.account_service_plan_list || [];

    const currentSpList = accountSpList.filter(item => {
        return filter?.sp_key_list 
            ? filter.sp_key_list.includes(item.sp_key)
            : dataSpKeyList.includes(item.sp_key);
    });
    const globalAccessSp = isGlobalAccess ? accountSpList.find((value) => {
        return service ? value.sp_key === SP_KEY__INCORPORATED : value.sp_key === SP_KEY__GLOBAL_ACCESS;
    }) : null;

    const actionShowFlag = isGlobalAccess ? 
            !!globalAccessSp 
            && globalAccessSp.allocation_limit 
            && globalAccessSp.allocation_limit.hasOwnProperty("allocate") 
            && globalAccessSp.allocation_limit.allocate
            && !isTest
        : !!currentSpList.length 
            && currentSpList.some((currentSp) => {
                return currentSp.allocation_limit 
                    && currentSp.allocation_limit.hasOwnProperty("allocate") 
                    && currentSp.allocation_limit.allocate
            })
            && (filter.sp_key_list 
                ? !!trunks.some((trunk) => filter.sp_key_list.includes(trunk.sp_key))
                : !!trunks.some((trunk) => dataSpKeyList.includes(trunk.sp_key)))
            && !isTest;

    const columns = useMemo(() => {
        return [
            ...(!isGlobalAccess ? [
            {
                id: "sp_key",
                label: formatMessage(m.type),
                dataKey: "sp_key",
                value: ({service_plan_name}) => service_plan_name,
                width: 120
            }] : []),
            {
                id: "a_description", 
                label: formatMessage(m.accessOrigin), 
                dataKey: "a_description",
                value: ({a_description, a_number}) => {
                    return (
                        <SecondStagedCell className="table-two-staged__cell">
                            <div className="table-two-staged__cell-first">
                                {a_description}
                            </div>
                            {a_number && <div className="table-two-staged__cell-second">
                                {a_number}
                            </div>}
                        </SecondStagedCell>
                    )
                },
                flexGrow: 2,
                minWidth: 200,
            },
            {
                id: "b_description", 
                label: formatMessage(m.accessDestination), 
                dataKey: "b_description",
                value: ({b_description, b_number}) => {
                    return (
                        b_number ? <SecondStagedCell className="table-two-staged__cell">
                            <div className="table-two-staged__cell-first">
                                {b_description}
                            </div>
                            <div className="table-two-staged__cell-second">
                                {b_number}
                            </div>
                        </SecondStagedCell> : 
                        <span>{b_description}</span>
                    )
                },
                flexGrow: 2,
                minWidth: 200,
            },
            {
                id: "b_test_number_list", 
                label: formatMessage(m.testNumbers), 
                dataKey: "b_test_number_list", 
                // flexGrow: 1,
                width: 190,
                value: ({b_test_number_list}) => {
                    const data = b_test_number_list;
                    const firstCell = data.slice(0, 2).join(", ");
                    const secondCell = data.slice(2, 4).join(", ");
                    return (
                        data.length ?<SecondStagedCell same>
                            {data.length >= 2 && <div className="table-two-staged__cell-first">
                                {firstCell}{data.length >= 4 && ","}
                            </div>}
                            {data.length >= 4 && <div className="table-two-staged__cell-second">
                                {secondCell}
                            </div>}
                        </SecondStagedCell> : 
                        <></>
                    )
                },
            },
            {
                id: "comment", 
                label: formatMessage(m.comment), 
                value: ({comment}) => <span title={comment}>{comment}</span>,
                flexGrow: 2,
                minWidth: 200
            },
            ...(!isGlobalAccess ? [{
                id: "message",
                label: formatMessage(m.message),
                // dataKey: "message",
                value: ({message}) => <span title={message}>{message}</span>,
                flexGrow: 2,
                minWidth: 160,
                hideOnService: transformedService["voice"]
            }] : []),
            ...(!isTest && !isSupport ? [{
                id: "payout",
                label: formatMessage(m.payout),
                value: (({payment_terms_rate_list, sp_auth}) => {
                    let payoutOutput = "—";

                    const obj = payment_terms_rate_list ? payment_terms_rate_list.find(value => {
                        return value.payment_terms_name === (accountInfo.hasOwnProperty("payment_terms_name") ? accountInfo.payment_terms_name : "");
                    }) : null;

                    if (obj) {
                        payoutOutput = `${Number(obj.rate).toFixed(4)} ${accountInfo.currency_name}`;
                    };

                    const tooltip = <Tooltip>{formatMessage(m.pleaseNoteItHasLimitation)}</Tooltip>;
                    // Please note it has limitation for services can be used for making traffic. CLI of services you can find at Other->OTP numbers
                    return <ColumnName>
                        <ColumnNameValue>
                            {payoutOutput}
                            {sp_auth ? <Whisper placement="auto" trigger="hover" speaker={tooltip}>
                                <ColumnNameWhitelist icon="exclamation-circle2"/>
                            </Whisper> : <></>}
                        </ColumnNameValue>
    
                    </ColumnName>
                }),
                // ...(!isMobile ? {flexGrow: 1} : {}),
                width: 110
            }] : []),
            ...(!service ? [{
                id: "limit_day",
                label: "Limits",
                dataKey: "limit_day",
                value: ({limit_day, limit_hour}) => {
                
                    const limitDay = limit_day ? `${limit_day} SMS per day` : "";
                    const limitHour = limit_hour ? `${limit_hour} SMS per hour` : "";
                    const hasBothLimits = limit_day && limit_hour;
                    const signleLimit = limit_day ? limitDay : limit_hour ? limitHour : "";

                    return hasBothLimits ? <SecondStagedCell same>
                            <div className="table-two-staged__cell-first">
                                {limitDay}
                            </div>
                            <div className="table-two-staged__cell-second">
                                {limitHour}
                            </div>
                        </SecondStagedCell>
                    : signleLimit;
                },
                ...(!isMobile ? {flexGrow: 1} : {}),
                width: rtl ? 120 : 110,
                // align: "center"
            }] : []),
            ...(!isGlobalAccess ? [{
                id: "datetime",
                label: formatMessage(m.date), 
                dataKey: "datetime",
                sortable: false,
                width: 155
            }] : []),
            ...(actionShowFlag ? [{
                id: "table_actions", 
                fixed: "right",
                label: formatMessage(m.action),
                // dataKey: "table_actions",
                value: (rowData) => {
                    const data = {
                        id: rowData.id,
                        a_description: rowData.a_description,
                        b_description: rowData.b_description,
                        a_number: rowData.a_number,
                        b_number: rowData.b_number,
                        sp_key: rowData.sp_key,
                        sde_key: rowData.sde_key,
                        ing_key: servicePick(service, rowData.ing_key, rowData["sms.supplier_gan_group_id"]),
                        gan: !!rowData["sms.supplier_gan_group_id"],
                        subdestination_name: rowData.subdestination_name
                    };

                    const trunkSpList = trunks.map((trunk) => trunk.sp_key);
                    
                    const disabled = !trunkSpList.includes(data.sp_key);

                    return (
                        <ButtonToolbar>
                            <ButtonRowClient
                                size="sm"
                                onClick={() => {
                                    onShowGetNumbersModal(true, data);
                                }}
                                disabled={disabled}
                            >
                                <b>{formatMessage(m.getNumbers)}</b>
                            </ButtonRowClient>
                        </ButtonToolbar>
                    );
                },
                // ...(!isMobile && windowWidth > 1600 ? {flexGrow: 1} : {}),
                width: rtl ? 125 : 115,
                align: "center",
            }] : []),
        ];
    }, [trunks]);


    // const nonTestColumns = [
    //     {
    //         id: "payout",
    //         label: formatMessage(m.payout),
    //         value: (({payment_terms_rate_list}) => {
    //             const obj = payment_terms_rate_list ? payment_terms_rate_list.find(value => {
    //                 return value.payment_terms_name === (accountInfo.hasOwnProperty("payment_terms_name") ? accountInfo.payment_terms_name : "");
    //             }) : null;
    //             if (obj) {
    //                 return `${Number(obj.rate).toFixed(4)} ${accountInfo.currency_name}`;
    //             } else {
    //                 return "—";
    //             }
    //         }),
    //         flexGrow: 1,
    //         width: 110,
    //         align: "center"
    //     }
    // ];

    // if (isGlobalAccess) {
    //     columns.splice(0, 1)
    // }

    // if (isTest) {
    //     for (const column of columns) {
    //         if (column.id === "table_actions") {
    //             columns.splice(columns.indexOf(column), 1)
    //         }
    //     }
    // }

    // if (!isTest && !isSupport) {
    //     for (const column of nonTestColumns) {
    //         columns.splice(-1, 0, column);
    //     }
    // }

    
    const testColumns = [];
    const allTermList = [];

    for (const priceRange of data) {
        if (priceRange && priceRange.payment_terms_rate_list) {
            for (const term of priceRange.payment_terms_rate_list)
                allTermList.push(term.payment_terms_name);
        }
    }

    
    const uniqueTermList = allTermList.filter((v, i, a) => a.indexOf(v) === i);
    for (const term of uniqueTermList) {
        testColumns.push(
            {
                id: "payouts",
                label: `${formatMessage(m.payout)} ${term}`,
                value: (({payment_terms_rate_list}) => {
                    if (payment_terms_rate_list) {
                        const obj = payment_terms_rate_list.find(value => value.payment_terms_name === term);
                        let currency = payment_terms_rate_list.find(value=> value.rate !== null);
                        if (currency) {
                            currency = currency.currency_name;
                        }
                        if (obj && obj.rate && currency) {
                            if (accountInfo.hasOwnProperty("currency_name")) {
                                return `${Number(obj.rate).toFixed(4)} ${currency ? currency : ""}`;
                            }
                        } else {
                            return "—";
                        }
                    } else {
                        return "—";
                    }
                }),
                ...(!isMobile ? {flexGrow: 1} : {}),
                width: 110,
                align: "center"
            });
    }

    
    if (isTest) {
        for (const column of testColumns) {
            columns.push(column);
        }
    }

    const filteredColumns = columns.filter(column => ("hideOnService" in column && column?.hideOnService !== service) || !("hideOnService" in column));
    
    const renderedColumns = filteredColumns.map(renderColumn);
    
    return (
        <>
            <CustomizableTable
                data={data.filter(item => !!item)}
                isChangedColumns={false}
                isPaginated={true}
                {...{
                    rtl,
                    count,
                    page,
                    per_page,
                    loading,
                    // columns,
                    savedPerPage,
                    onChangePage,
                    onChangePerPage,
                    renderedColumns,
                    ...props
                }}
            />
        </>
    );
};

const SecondStagedCell = styled.div`
   margin-top: -7px;
    
    .table-two-staged__cell-first {
        ${props => props.same && css`
            font-size: 13px;
        `}
    }
    .table-two-staged__cell-second {
        margin-top: -4px;
        font-size: 12px;
        ${props => !props.same ? css`
            color: var(--color-brand1);
        ` : css`
            font-size: 13px;
        `}
        opacity: 1;
    }
`;

const ColumnName = styled.div`
    display: flex;
    align-items: center;
`;

const ColumnNameValue = styled.div`
    width: 100%;
    text-align: center;
`;

const ColumnNameWhitelist = styled(Icon)`
    font-size: 12px;
    position: absolute;
    color: #4aaef4;
    margin-left: 4px;
    margin-top: 2px;
`;
