import {TEST_CALLS_DIALER as PREFIX} from '../../const'
import {api} from '../../api/loginRoutes'
import {_setValue} from './defaults'
import {TEST_CALLS_LIST_DIALER_API, SMS_TEST_CALLS_LIST_DIALER_API} from "../../const/apiMethods";
import {servicePick} from 'utils';

const setTestCalls = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);

export const getDialerTestCalls = (service=true, per_page = 12, filter = {}, page = 1) => (dispatch) => {
    dispatch(setLoading(true));
    const method = servicePick(service, TEST_CALLS_LIST_DIALER_API, SMS_TEST_CALLS_LIST_DIALER_API);

    api(method, {filter, page, per_page})
        .then((response) => {
            if (response === undefined) {
                dispatch(setLoading(false));
                return;
            }
            dispatch(setTestCalls({
                ...(service ? {
                    cdr_full_test_list: response.cdr_full_test_list,
                    cdr_full_test_count: response.cdr_full_test_count,
                    cdr_full_test_more: response.cdr_full_test_more
                } : {
                    cdr_full_test_list: response.mdr_full_test_list,
                    cdr_full_test_count: response.mdr_full_test_count,
                    cdr_full_test_more: response.mdr_full_test_more
                }),
                page,
                per_page
            }))
    })
};


export const setEmptyTrafficStat = () => (dispatch) => {
    dispatch( setTestCalls({
        group_count: 0,
        group_list: [],
        page: 1
    }) );
};
