import React, {Component} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {FlexboxGrid, Alert} from 'rsuite';

import {
    LOCAL_STORAGE_NUMBERS_FILTERS_DIALER,
    LOCAL_STORAGE_NUMBERS_PER_PAGE_DIALER,
    LOCAL_STORAGE_USER_INFO_DIALER,
    MAX_RATE_DIALER,
    MIN_PER_PAGE_DIALER,
    MIN_RATE_DIALER,
    SCREEN_MEDIA,
    transformed_service_reverse_data
} from '../../const';

import {api, getFileResponse} from '../../api/loginRoutes';

import {objectIsEmpty, copyToClipboard, getBlobContent, getServiceSmsPrefix, compareObjects, compareTwoArrays} from '../../utils/helpers';

import Page from "../../components/base/Page";
import {Spacer} from '../../components/base/Spacer';
import PageHeader from './../../components/base/PageHeader';
import {FlexGrid, FlexGridItem} from '../../components/base/FlexGrid';
import {ButtonSecondary, BaseButton} from '../../components/base/BaseButton';
import ModalAllocationResult from '../../components/client/Modal/ModalAllocationResult';

import TrunkTable from './TrunkTable';
import NumbersFilters from './NumbersFilters/';
import NumbersRevokeModal from './NumbersRevokeModal';
import AllocatedNumbersTable from './AllocatedNumbersTable';
import NumbersDownloadActions from './NumbersDownloadActions';
import ModalAllocationNumbers from "../../components/client/Modal/ModalAllocationNumbers";

import {getServiceByLocation} from "../../utils/helpers";

import m from "../../definedMessages";

const NUMBER_KEY = 'trn_key';
const RANGE_KEY = 'pr_key';
const SUBDEST_KEY = 'sde_key';

const {md} = SCREEN_MEDIA;


class Numbers extends Component {

    constructor(props) {
        super(props);

        this.tableRef = null;

        this.filter = {};
        this.defaultFilter = {
            str: '',
            rate_min: MIN_RATE_DIALER,
            rate_max: MAX_RATE_DIALER
        };

        this.curPerPage = null;

        this.formatMessage = props.intl.formatMessage.bind(props.intl);

        const accountInfo = localStorage.getItem(LOCAL_STORAGE_USER_INFO_DIALER);
        const parsedAccountInfo = accountInfo !== null ? JSON.parse(accountInfo) : {};

        let storagedPerPage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NUMBERS_PER_PAGE_DIALER));
        if ( storagedPerPage && !isNaN(storagedPerPage) && storagedPerPage > 100 ) {
            localStorage.setItem(LOCAL_STORAGE_NUMBERS_PER_PAGE_DIALER, 100);
            storagedPerPage = 100;
        }

        this.state = {
            firstTrunkId: null,
            activeTrunkId: null,
            // permissionList: parsedAccountInfo && parsedAccountInfo.session ? parsedAccountInfo.session.account_join.permission_list : null,
            savedTableFilter: JSON.parse(localStorage.getItem(LOCAL_STORAGE_NUMBERS_FILTERS_DIALER)),
            savedPerPage: storagedPerPage,
            resultModalShow: null,
            showNumbersRevokeModal: null,
            numbersRevokeModalLoading: false,
            showModalResponseHandler: null,
            searchLoading: false,
            selectedAllocatedNumbers: [],
            resizedWidth: window.innerWidth,
            selectedData: {all: false, list: []},

            activeFiltersCount: 0,
            selectedItems: new Map(),

            filtersValue: JSON.parse(localStorage.getItem(LOCAL_STORAGE_NUMBERS_FILTERS_DIALER)),
            currentDisplay: 'trunk_number',

            trunkAllocatedByOTP: false,
            csvContent: null,
            transactionId: null,
            notAllocatedNumbers: null,
            allocatedNumbersCount: 0,

            activeAllocationLimit: {},

            showWarningAllocationMessage: false,

            // -----
            resultLoading: false,
            numbersModalShow: false,
            currentSpKey: null
        };
    };


    componentDidMount() {
        const {service, location, getNumberTrunksList} = this.props;
        this.setActiveFiltersCount(this.state.savedTableFilter);

        let currentService = service;
        const serviceByLocation = getServiceByLocation(location);

        if (serviceByLocation !== null) {
            currentService = serviceByLocation;
        }
        
        if (service !== null) {
            getNumberTrunksList(currentService, () => this.setState({activeTrunkId: null}));
        }
    }


    componentDidUpdate(prevProps, prevState) {
        const {trunks, service, getNumberTrunksList} = this.props;
        const {activeTrunkId} = this.state;

        if (!compareTwoArrays(prevProps.trunks, trunks)) {
            const filteredTrunks = trunks.filter(trunk => !trunk.closed);
            const firstTrunkId = filteredTrunks.length && filteredTrunks[0].id;

            if (filteredTrunks.length) {
                if (firstTrunkId === activeTrunkId) {
                    this.onGetTrunkNumbers(firstTrunkId);
                }
            }
        }

        if (prevState.activeTrunkId === null && activeTrunkId) {
            const trunksByService = trunks.filter(trunk => trunk.service === transformed_service_reverse_data[service]);

            if (trunksByService.length) {
                this.onGetTrunkNumbers(activeTrunkId);
            }
        }

        if (service !== null && prevProps.service !== service) {
            getNumberTrunksList(service, () => this.setState({activeTrunkId: null}));

            this.setState({
                filterValue: this.defaultFilter,
                savedTableFilter: null
            });
            this.filter = this.defaultFilter;
        }

        
    }


    componentWillReceiveProps(nextProps) {
        const {trunks} = nextProps;
        const id = this.state.firstTrunkId;

        const filteredTrunks = trunks.filter(trunk => !trunk.closed);

        if (filteredTrunks.length && !id) {
            this.setState({
                firstTrunkId: filteredTrunks[0].id,
                activeTrunkId: filteredTrunks[0].id,
                currentSpKey: filteredTrunks[0].sp_key,
                activeAllocationLimit: filteredTrunks[0].allocation_limit
            });
        }
    };


    componentWillMount() {
        window.addEventListener('resize', this.handleResize);
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);

        localStorage.removeItem(LOCAL_STORAGE_NUMBERS_FILTERS_DIALER);
    }


    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };


    onCopyList = (text) => {
        copyToClipboard(text);
        Alert.success('Copied!')
    };


    onGetTrunkNumbers = (trunk_id, sp_key, sp_auth, allocation_limit) => {
        const {savedPerPage, savedTableFilter, currentDisplay} = this.state;
        const {getTrunkNumbers, service, per_page} = this.props;
        let pickedFilter;

        this.curPerPage = savedPerPage;

        // if (!objectIsEmpty(savedTableFilter)) {
        //     pickedFilter = savedTableFilter;
        // } else 
        if (!objectIsEmpty(this.filter)) {
            pickedFilter = this.filter;
        } else {
            pickedFilter = this.defaultFilter;
        }
        
        getTrunkNumbers(trunk_id, service, savedPerPage || per_page, pickedFilter, 1, currentDisplay);
        
        this.setState({currentSpKey: sp_key});
        this.tableRef.clear();
        this.clearSelectedNumbers();

        this.setActiveTrunk(trunk_id, allocation_limit);
    };


    setActiveTrunk = (trunk_id, allocation_limit) => {
        this.setState({
            activeTrunkId: trunk_id,
            activeAllocationLimit: allocation_limit
        });
    };

    setShowWarningAllocationMessage = (flag=false) => {
        this.setState({
            showWarningAllocationMessage: flag
        });
    };


    combineFilters = (filter, savedFilter) => {
        return {

            rate_min: filter && filter.rate_min 
                ? filter.rate_min 
                : savedFilter && savedFilter.rate_min 
                    ? savedFilter.rate_min 
                    : MIN_RATE_DIALER,
                    
            rate_max: filter && filter.rate_max 
                ? filter.rate_max 
                : savedFilter && savedFilter.rate_max 
                    ? savedFilter.rate_max 
                    : MAX_RATE_DIALER,

            str: filter && (filter.str || filter.str === '') 
                ? filter.str 
                : savedFilter && (savedFilter.str || savedFilter.str === '') 
                    ? savedFilter.str 
                    : ''
        };
    };
    

    setActiveFiltersCount = (value = null) => {
        if (!value)
            return;

        const filters = Object.keys(value);
        const activeFilters = filters.filter(item => {
            if (value[item] === this.defaultFilter[item] || (!value[item] && value[item] !== 0)) {
                return false;
            }
            return true;
        });

        this.setState({
            activeFiltersCount: activeFilters.length
        });
    };


    onChangeGroup = (currentDisplay) => {
        const {activeTrunkId, savedPerPage} = this.state;
        const {getTrunkNumbers, service, per_page} = this.props;

        this.setState({
            currentDisplay
        });

        if (activeTrunkId) {
            getTrunkNumbers(
                activeTrunkId, service,
                savedPerPage || this.curPerPage || per_page, 
                this.filter, 
                1, 
                currentDisplay
            );
        }

        this.tableRef.clear();
        this.clearSelectedNumbers();
    };


    onChangeFilters = (filtersObj) => {
        const {activeTrunkId, savedPerPage, currentDisplay} = this.state;
        const {getTrunkNumbers, service, per_page} = this.props;

        this.setActiveFiltersCount(filtersObj);

        this.filter = filtersObj;
        localStorage.setItem(LOCAL_STORAGE_NUMBERS_FILTERS_DIALER, JSON.stringify(this.filter));
        this.setState({
            savedTableFilter: filtersObj
        });

        if (activeTrunkId) {
            getTrunkNumbers(activeTrunkId, service, savedPerPage || this.curPerPage || per_page, this.filter, 1, currentDisplay);
        }
        this.tableRef.clear();
        this.clearSelectedNumbers();
    };


    onChangePerPage = (perPage) => {
        const {activeTrunkId, currentDisplay} = this.state;
        const {service, getTrunkNumbers} = this.props;

        const savedPerPage = perPage ? null : this.curPerPage;
        this.setState({savedPerPage: savedPerPage});

        const pickedFilter = this.getActiveFilter();
        const per_page = Number(perPage) || this.curPerPage;
        this.curPerPage = per_page <= 0 ? MIN_PER_PAGE_DIALER : per_page > 100 ? 100 : per_page;
        localStorage.setItem(LOCAL_STORAGE_NUMBERS_PER_PAGE_DIALER, JSON.stringify(this.curPerPage));

        getTrunkNumbers(activeTrunkId, service, this.curPerPage, pickedFilter, 1, currentDisplay);
    };


    onChangePage = (pageNumber) => {
        const {activeTrunkId, savedPerPage, currentDisplay} = this.state;
        const {getTrunkNumbers, service, per_page} = this.props;
        
        const pickedFilter = this.getActiveFilter();

        getTrunkNumbers(activeTrunkId, service, savedPerPage || this.curPerPage || per_page, pickedFilter, pageNumber, currentDisplay);
    };


    onDownloadNumbers = (trunk_id) => {
        const {service, downloadNumbers} = this.props;
        downloadNumbers(trunk_id, service)
    };

    
    getActiveFilter = () => {
        const {savedTableFilter} = this.state;
        const combinedFilter = this.combineFilters(this.filter, savedTableFilter);

        return (!objectIsEmpty(this.filter) || !objectIsEmpty(savedTableFilter)) 
            ? combinedFilter 
            : this.defaultFilter;
    };


    onAllocationGetResult = (response, service = true) => {
        const {setTrunkNumberTransaction, setLoadingAllocatedNumbers} = this.props;

        setTrunkNumberTransaction(response.trunk_number_transaction || {});
        const trunkNumberTransaction = response.trunk_number_transaction;
        const notAllocatedNumbers = response.not_allocated_numbers;

        if (trunkNumberTransaction) {
            const transactionId = trunkNumberTransaction.id;
            const allocatedNumbersCount = trunkNumberTransaction.numbers;

            this.setState({
                transactionId,
                allocatedNumbersCount,
                resultLoading: true,
            });

            const numbersMethod = getServiceSmsPrefix(service, 'trunk_number:get_list');

            getFileResponse(numbersMethod, {
                target: {
                    [getServiceSmsPrefix(service, 'trunk_number_transaction_id')]: transactionId
                }
            })
                .then((response) => {
                    setLoadingAllocatedNumbers(false);

                    if (response) {
                        const csvText = getBlobContent(response);
                        csvText.then((text) => {
                            this.setShowNumbersModal(false);
                            this.setState({
                                csvContent: text,
                                resultModalShow: true,
                                resultLoading: false,
                            })
                        });
                    }
                })
        }

        notAllocatedNumbers && this.setState({notAllocatedNumbers});
    };


    setShowNumbersRevokeModal = (value) => {
        this.setState({showNumbersRevokeModal: value});
    };


    handleRevokeNumbersSubmit = async () => {
        const {service, getNumberTrunksList} = this.props;
        const {savedTableFilter} = this.state;
        const {all, list} = this.state.selectedData;
        const trunkId = this.state.activeTrunkId;

        const method =  getServiceSmsPrefix(service, "trunk_number__filter:revoke");
        
        const params = {
            target: {
                [getServiceSmsPrefix(service, "trunk_id")]: trunkId
            }
        };

        if (all) {
            params.filterAll = true;
            if (savedTableFilter) {
                params.filter = savedTableFilter;
            }
        }

        if (!all) {
            const key = this.getRowKeyList();
            params[key] = list;
        }

        this.setState({numbersRevokeModalLoading: true});

        await api(method, params).then(({trunk_numbers}) => {
            Alert.success(this.formatMessage(m.numbersWereRevoked, {count: trunk_numbers}))
        });

        this.setState({numbersRevokeModalLoading: false});
        getNumberTrunksList(service, () => this.setState({activeTrunkId: trunkId}));
        this.onGetTrunkNumbers(trunkId);
        this.setShowNumbersRevokeModal(false);
    };

    
    downloadNumberList = (csvNumberList, trunkId, setUploadingLoading) => {
        const encodedUri = encodeURI(csvNumberList);
        const link = document.createElement('a');

        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'allocated_numbers.csv');
        document.body.appendChild(link);
        link.click();
        setUploadingLoading(trunkId, false);
    };


    downloadNumberListTxt = (csvNumberList, trunkId, setUploadingLoading) => {
        const encodedUri = encodeURI(csvNumberList);
        const link = document.createElement('a');

        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'allocated_numbers.txt');
        document.body.appendChild(link);
        link.click();
        setUploadingLoading(trunkId, false);
    };


    setSelectedData = (selected) => {
        this.setState({selectedData: selected});
    };


    getRowKey = () => {
        const {currentDisplay} = this.state;
        switch (currentDisplay) {
            case 'trunk_number':
                return NUMBER_KEY;
            case 'price_range':
                return RANGE_KEY;
            case 'subdestination':
                return SUBDEST_KEY;
        }
    };


    getRowKeyList = () => {
        const {currentDisplay} = this.state;
        switch (currentDisplay) {
            case 'trunk_number':
                return 'trn_key_list';
            case 'price_range':
                return 'pr_key_list';
            case 'subdestination':
                return 'sde_key_list';
        }
    };


    setTableRef = (ref) => {
        this.tableRef = ref;
    };


    keepSelectedItems = (data, checked) => {
        const {selectedItems} = this.state;
        const updatedCollection = new Map(selectedItems);

        const rowKey = this.getRowKey();

        for (const item of data) {
            if (checked) {
                updatedCollection.set(item[rowKey], item);
            } else {
                updatedCollection.delete(item[rowKey]);
            }
        }

        this.setState({selectedItems: updatedCollection});
    };


    getSelectedNumbersCount = () => {
        const {selectedItems, selectedData: {all}} = this.state;

        if (all)
            return this.props.totalNumbersCount;

        const rowKey = this.getRowKey();
        let key = 'id';
        switch (rowKey) {
            case NUMBER_KEY:
                key = 'numbers_count';
                break;
            case RANGE_KEY:
                key = 'price_range_trunk_numbers';
                break;
            case SUBDEST_KEY:
                key = 'subdestination_trunk_numbers';
                break;
        }

        let count = 0;
        for (const item of selectedItems) {
            count += item[1][key];
        }

        return count;
    };
    

    clearSelectedNumbers = () => {
        this.setState({
            selectedItems: new Map()
        });
    };


    clearAllocatedData = () => {
        this.setState({
            csvContent: null,
            allocatedNumbersCount: 0
        })
    };



    /* ------------------- */

    setShowNumbersModal = (value) => {
        this.setState({numbersModalShow: value});
    };


    onShowNumbersModal = (trunk_id, allocation_limit) => {
        this.setShowNumbersModal(true);
        this.setState({
            activeTrunkId: trunk_id,
            activeAllocationLimit: allocation_limit
        });
    };

    saveCurrentTrunkId = () => {
        const {activeTrunkId} = this.state;

        this.setState({activeTrunkId});
    };


    render() {
        const {
            activeTrunkId, permissionList, showNumbersRevokeModal, searchLoading, savedPerPage,
            savedTableFilter, resizedWidth, activeFiltersCount, currentDisplay, numbersRevokeModalLoading,
            numbersModalShow, resultModalShow, loadingAllocation, trunkAllocatedByOTP, account, csvContent,
            notAllocatedNumbers, allocatedNumbersCount, currentSpKey, filterValue,
            resultLoading, showWarningAllocationMessage
        } = this.state;

        const {list: selectedList, all: selectedAll} = this.state.selectedData;

        const {
            trunks, allocatedNumbers, numberTrunkLoading, allocatedNumbersLoading, page, count, per_page, 
            uploadLoading, accountDialerState, allocation_limit, setUploadingLoading,
            showReasonHandler, getTrunkNumbers, getNumberTrunksList, trunk_number_transaction, service
        } = this.props;

        const isMobile = resizedWidth < md.min;

        const onChangePage = this.onChangePage;
        const onChangePerPage = this.onChangePerPage;
        const onChangeFilters = this.onChangeFilters;
        const onChangeGroup = this.onChangeGroup;
        const onDownloadNumbers = this.onDownloadNumbers;
        const onShowNumbersModal = this.onShowNumbersModal;
        const setSelectedData = this.setSelectedData;
        const getRowKey = this.getRowKey;
        const keepSelectedItems = this.keepSelectedItems;
        const getSelectedNumbersCount = this.getSelectedNumbersCount;


        const activeFilter = this.getActiveFilter();
        const revokeButton = (
            <BaseButton
                buttonStyle="primary"
                as={!isMobile ? BaseButton : ButtonSecondary}
                onClick={() => {
                    this.setShowNumbersRevokeModal(true);
                }}
                loading={false}
                disabled={!selectedList.length && !selectedAll}
            >
                <>
                    {!isMobile
                        ? <FormattedMessage id="common.revoke" defaultMessage="Revoke"/>
                        : <FormattedMessage
                            id="common.revokeCountNumbers"
                            defaultMessage="Revoke {count} items"
                            values={{count: this.getSelectedNumbersCount()}}
                        />
                    }
                </>
    
            </BaseButton>
        );

        return (
            <Page>
                {/* Trunks */}
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <PageHeader title={<FormattedMessage id="numbers.trunks" defaultMessage="Trunks"/>}/>
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <TrunkTable
                    data={trunks}
                    loading={numberTrunkLoading || allocatedNumbersLoading}
                    onClickTrunk={this.onGetTrunkNumbers}
                    activeTrunk={activeTrunkId}
                    accountDialerState={accountDialerState}
                    onChangeActiveTrunk={this.setActiveTrunk}
                    {...{
                        onDownloadNumbers,
                        onShowNumbersModal,
                        uploadLoading,
                        service
                    }}
                />

                <Spacer/>

                {/* Numbers */}

                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item>
                        <PageHeader title={<FormattedMessage id="numbers.allocatedNumbers" defaultMessage="Allocated numbers"/>}/>
                    </FlexboxGrid.Item>

                    {isMobile &&
                    <FlexboxGrid.Item>
                        <NumbersFilters
                            {...{
                                service,
                                onChangeFilters,
                                onChangeGroup,
                                savedTableFilter,
                                filterValue,
                                allocatedNumbersLoading,
                                activeFiltersCount,
                                currentDisplay
                            }}
                            defaultFilter={this.defaultFilter}
                            isMobile={isMobile}
                        />
                    </FlexboxGrid.Item>
                    }

                </FlexboxGrid>

                {!isMobile &&
                    <>
                        <FlexGrid justify="space-between">

                            <FlexGridItem>
                                <NumbersFilters
                                    {...{
                                        service,
                                        onChangeFilters,
                                        onChangeGroup,
                                        filterValue,
                                        savedTableFilter,
                                        allocatedNumbersLoading,
                                        currentDisplay,
                                    }}
                                    defaultFilter={this.defaultFilter}
                                />
                            </FlexGridItem>

                            <FlexGridItem>
                                <NumbersDownloadActions
                                    {...{
                                        service,
                                        currentDisplay,
                                        activeTrunkId,
                                        uploadLoading,
                                        setUploadingLoading,
                                        activeFilter,
                                    }}
                                />

                                {revokeButton}
                            </FlexGridItem>

                        </FlexGrid>
                        <Spacer/>
                    </>
                }

                <AllocatedNumbersTable
                    data={allocatedNumbers}
                    setRef={this.setTableRef}
                    loading={allocatedNumbersLoading || searchLoading}
                    useSelectedData={keepSelectedItems}
                    {...{
                        service,
                        page,
                        count,
                        per_page,
                        savedPerPage,
                        currentDisplay,
                        getRowKey,
                        onChangePage,
                        onChangePerPage,
                        setSelectedData,
                    }}
                />

                {isMobile &&
                    <NumbersDownloadActions
                        placement={'topStart'}
                        {...{
                            service,
                            currentDisplay,
                            activeTrunkId,
                            uploadLoading,
                            setUploadingLoading,
                            activeFilter,
                        }}
                    />

                }

                {isMobile && (selectedList.length || selectedAll) &&
                <>
                    <Spacer size={40}/>
                    <FlexboxGrid.Item>

                        {revokeButton}

                    </FlexboxGrid.Item>
                </>
                }


                {/* Modals */}

                <ModalAllocationNumbers
                    show={numbersModalShow}
                    selectedTrunkId={activeTrunkId}
                    allocationLimit={allocation_limit}
                    loading={resultLoading}
                    onClose={this.setShowNumbersModal}
                    onSuccess={(r) => {
                        this.onAllocationGetResult(r, service);
                        getNumberTrunksList(service, () => this.setState({activeTrunkId: activeTrunkId}));
                        getTrunkNumbers(
                            activeTrunkId, 
                            service,
                            savedPerPage || this.curPerPage || per_page, 
                            this.filter, 
                            1, 
                            currentDisplay
                        );

                        if (r?.reason_list && Object.keys(r.reason_list).includes("template_max_numbers")) {
                            this.setShowWarningAllocationMessage(true);//r.comment);
                        }
                    }}
                    {...{
                        spKey: currentSpKey,
                        showReasonHandler,
                        service,
                        trunks,
                    }}
                />

                <ModalAllocationResult
                    show={resultModalShow}
                    maxWidth={850}
                    onCopy={this.onCopyList}
                    downloadNumbers={this.downloadNumberList}
                    downloadNumbersTxt={this.downloadNumberListTxt}
                    onDownloadNumbers={onDownloadNumbers}
                    uploadLoading={uploadLoading}
                    loading={loadingAllocation}
                    trunks={trunks}
                    trunkId={this.state.activeTrunkId}
                    defaultTrunkId={null}
                    trunkAllocatedByOTP={trunkAllocatedByOTP}
                    methodName={'Trunks'}
                    account={account}
                    service={service}
                    csvContent={csvContent}
                    notAllocatedNumbers={notAllocatedNumbers}
                    trunkNumberTransaction={trunk_number_transaction}
                    allocatedNumbersCount={allocatedNumbersCount}
                    setUploadingLoading={setUploadingLoading}
                    showWarningAllocationMessage={showWarningAllocationMessage}
                    onClose={() => {
                        this.clearAllocatedData();
                        this.setShowWarningAllocationMessage(false);
                        this.setState({
                            notAllocatedNumbers: null,
                            resultModalShow: false
                        });
                    }}
                />

                <NumbersRevokeModal
                    show={showNumbersRevokeModal}
                    loading={numbersRevokeModalLoading}
                    onClose={this.setShowNumbersRevokeModal}
                    getCount={getSelectedNumbersCount}
                    onSuccess={() => {
                        this.handleRevokeNumbersSubmit();
                    }}
                >
                </NumbersRevokeModal>

            </Page>
        );
    }
};

export default injectIntl(Numbers, {forwardRef: true});