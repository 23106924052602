import React from 'react';
import CDRTable from './CDRTable';
import CDRFilters from './CDRFilters';
import {hasError, objectIsEmpty, removeTZFromDate} from '../../../utils/helpers';
import {ButtonPrimary} from '../../../components/base/BaseButton';
import {
    LOCAL_STORAGE_CDR_COLUMNS_DIALER,
    LOCAL_STORAGE_CDR_FILTERS_DIALER,
    LOCAL_STORAGE_CDR_PER_PAGE_DIALER,
    LIMITED_PER_PAGE_DIALER,
    MIN_PER_PAGE_DIALER
} from '../../../const';
import {injectIntl} from "react-intl";
import { FlexGrid, FlexGridItem } from '../../../components/base/FlexGrid';
import {Spacer} from "../../../components/base/Spacer"
import {Drawer, Schema, Icon, IconButton} from "rsuite";
import styled from "styled-components";
import m from "../../../definedMessages";
import ButtonDownload from "../../../components/client/ButtonDownload";
import {toUTCDateTime} from "utils";

const {StringType, ArrayType} = Schema.Types;
const MIN_CHARACTERS = 3;
const MAX_CHARACTERS = 40;


class CDR extends React.Component {
    constructor(props) {
        super(props);

        this.filter = {};
        this.defaultFilter = {
            start_date: removeTZFromDate(new Date(new Date().setHours(0, 0, 0, 0))),
            end_date: removeTZFromDate(new Date(new Date().setHours(23, 59, 59, 999)))
        };
        this.formatMessage = this.props.intl.formatMessage.bind(this.props.intl);
        this.curPage = 1;
        this.curPerPage = null;

        this.filtersModel = Schema.Model({
            // range: ArrayType()
            //     .addRule((value) => {
            //         if (value && value[0] && value[1]) {
            //             const startDate = new Date(value[0]);
            //             const endDate = new Date(value[1]);
            //             const startDay = startDate.getDate();
            //             const endDay = endDate.getDate();

            //             if ((endDay-startDay) > 1) {
            //                 return false;
            //             }
            //         }
            //         return true;
            //     // }, formatMessage(m.cdrHistoryPeriodIs, {count: globalSettings.cdr_full__interval_month})),
            // }, "The filter is only available for the selected two days"),
            a_number: StringType()
                // .isRequired(this.formatMessage(m.thisFieldIsRequired))
                .pattern(/^\d+$/, this.formatMessage(m.correctNumber))
                // .minLength(MIN_CHARACTERS, this.formatMessage(m.minLength, {count: MIN_CHARACTERS}))
                .maxLength(MAX_CHARACTERS, this.formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
            b_number: StringType()
                // .isRequired(this.formatMessage(m.thisFieldIsRequired))
                .pattern(/^\d+$/, this.formatMessage(m.correctNumber))
                // .minLength(MIN_CHARACTERS, this.formatMessage(m.minLength, {count: MIN_CHARACTERS}))
                .maxLength(MAX_CHARACTERS, this.formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
        });

        this.state = {
            searchLoading: false,
            savedTableFilter: null,
            applyButtonLoading: false,
            preparedFilter: this.defaultFilter,
            savedPerPage: JSON.parse(localStorage.getItem(LOCAL_STORAGE_CDR_PER_PAGE_DIALER)),
            savedTableColumns: JSON.parse(localStorage.getItem(LOCAL_STORAGE_CDR_COLUMNS_DIALER)),
            resizedWidth: window.innerWidth,
            activeFiltersCount: 0,
            showFilters: false,
            showColumns: props.columns,
            currentType: props.prevType
        };
    }

    componentDidUpdate = (prevProps) => {
        const {savedPerPage, currentType, savedTableFilter} = this.state;
        const {getCDRList, per_page, service, prevType, type, columns, location} = this.props;

        if (service !== null && (prevProps.service !== service || (prevProps.service === service && currentType !== type))) {
            if (currentType !== type) {
                this.setState({
                    currentType: type
                });
            }
            const columnsIdList = columns.map(column => column.id);
            this.onSaveColumnsTable(columnsIdList);
            this.setState({
                showColumns: columns,
                savedTableColumns: columnsIdList
            });

            this.curPerPage = savedPerPage;

            // const serviceByLocation = getServiceByLocation(location);
            // if (service !== null) {
            //     getCDRList(service, savedPerPage || per_page, pickedFilter, 1);
            // }
        }

        if (prevProps.service !== service) {
            let pickedFilter;

            if (!objectIsEmpty(savedTableFilter)) {
                pickedFilter = savedTableFilter;
            } else if (!objectIsEmpty(this.filter)) {
                pickedFilter = this.filter;
            } else {
                pickedFilter = this.defaultFilter;
            }

            if (prevProps.service !== service) {
                pickedFilter = this.defaultFilter;
            }

            if (service !== null) {
                this.getCDRListCommon(savedPerPage || per_page, pickedFilter);
            }
        }

        if (prevProps.columns !== columns) {
            const columnsIdList = columns.map(column => column.id);
            this.onSaveColumnsTable(columnsIdList);
            this.setState({
                showColumns: columns,
                savedTableColumns: columnsIdList
            });
        }
    } 

    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };

    getCDRListCommon = (perPage, filter, page=1) => {
        const {service, getCDRList, executeRecaptcha} = this.props;

        // executeRecaptcha();
        getCDRList(service, perPage, filter, page)
    };

    componentDidMount() {
        const {savedPerPage, currentType, savedTableFilter} = this.state;
        const {type, per_page, prevType, service} = this.props;

        this.setActiveFiltersCount(this.state.savedTableFilter);
        
        if (currentType === prevType) {
            this.setState({currentType: !type});
        }

        if (service !== null) {
            this.curPerPage = savedPerPage;
            let pickedFilter;

            if (!objectIsEmpty(savedTableFilter)) {
                pickedFilter = savedTableFilter;
            } else if (!objectIsEmpty(this.filter)) {
                pickedFilter = this.filter;
            } else {
                pickedFilter = this.defaultFilter;
            }

            const checker = this.filtersModel.check(pickedFilter);
            const formHasError = Object.keys(checker).some(key => checker[key].hasError);

            if (!formHasError) {
                this.getCDRListCommon(savedPerPage || per_page, pickedFilter, 1);
            }
        }
    }

    componentWillMount() {
        // const {resetRecaptcha} = this.props;

        // resetRecaptcha();

        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    onChangeFilters = (filtersObj) => {
        const {savedPerPage} = this.state;
        const {setEmptyCDRList, per_page} = this.props;

        // this.setState({
        //     applyButtonLoading: true
        // });

        this.setActiveFiltersCount(filtersObj);

        this.filter = filtersObj;

        this.setState({
            savedTableFilter: filtersObj,
            preparedFilter: filtersObj
        });
        // , () => {
        //     setTimeout(() => {
        //         this.setState({
        //             applyButtonLoading: false
        //         });
        //     }, 600);
        // });
        // else {
        //     setEmptyCDRList();
        // }
    };

    setActiveFiltersCount = (value = null) => {
        if (!value)
            return;

        const filters = Object.keys(value);
        const dateFilters = ['range', 'start_date', 'end_date'];
        const activeFilters = filters.filter(item => {
            if ( dateFilters.includes(item) )
                return false;

            if ( value[item] === this.defaultFilter[item] || (!value[item] && value[item] !== 0) ) {
                return false;
            }
            return true;
        });

        this.setState({
            activeFiltersCount: activeFilters.length + 1
        });
    };

    combineFilters = (service, filter, savedFilter) => {
        return {
            start_date: filter && filter.start_date ? filter.start_date :
                savedFilter && savedFilter.start_date ? savedFilter.start_date :
                    removeTZFromDate(new Date(new Date().setHours(0, 0, 0, 0))),
            end_date: filter && filter.end_date ? filter.end_date :
                savedFilter && savedFilter.end_date ? savedFilter.end_date :
                    removeTZFromDate(new Date(new Date().setHours(23, 59, 59, 999))),
            ...(service ?  {
                a_number: filter && (filter.a_number || filter.a_number === '') ? filter.a_number :
                    savedFilter && savedFilter.a_number ? savedFilter.a_number : ''
            } : {
                senderid: filter && (filter.senderid || filter.senderid === '') ? filter.senderid :
                    savedFilter && savedFilter.senderid ? savedFilter.senderid : ''
            }),
            ...(service ?  {
                b_number: filter && (filter.b_number || filter.b_number === '') ? filter.b_number :
                    savedFilter && savedFilter.b_number ? savedFilter.b_number : ''
            } : {
                phone: filter && (filter.phone || filter.phone === '') ? filter.phone :
                    savedFilter && savedFilter.phone ? savedFilter.phone : ''
            })
        };
    };

    onChangePerPage = (perPage) => {
        const {savedTableFilter} = this.state;
        const {service} = this.props;

        if (perPage)
            this.setState({savedPerPage: null});
        else
            this.setState({savedPerPage: this.curPerPage});

        let pickedFilter;
        const per_page = Number(perPage) || this.curPerPage;
        const combinedFilter = this.combineFilters(service, this.filter, savedTableFilter);

        if (!objectIsEmpty(this.filter) || !objectIsEmpty(savedTableFilter)) {
            pickedFilter = combinedFilter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        per_page <= 0 ? this.curPerPage = MIN_PER_PAGE_DIALER : per_page > LIMITED_PER_PAGE_DIALER ? this.curPerPage = LIMITED_PER_PAGE_DIALER : this.curPerPage = per_page;

        localStorage.setItem(LOCAL_STORAGE_CDR_PER_PAGE_DIALER, JSON.stringify(this.curPerPage));
        
        const checker = this.filtersModel.check(pickedFilter);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError) {
            this.getCDRListCommon(this.curPerPage, pickedFilter, 1);
        }
    };

    onChangePage = (pageNumber) => {
        const {savedTableFilter, savedPerPage} = this.state;
        const {getCDRList, service, per_page} = this.props;

        let pickedFilter;
        const combinedFilter = this.combineFilters(service, this.filter, savedTableFilter);

        if (!objectIsEmpty(this.filter) || !objectIsEmpty(savedTableFilter)) {
            pickedFilter = combinedFilter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        const checker = this.filtersModel.check(pickedFilter);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError) {
            this.getCDRListCommon(savedPerPage || this.curPerPage || per_page, pickedFilter, pageNumber);
        }
    };

    exportCDR = () => {
        const {savedTableFilter} = this.state;
        const {service} = this.props;

        let pickedFilter;

        if (!objectIsEmpty(savedTableFilter)) {
            pickedFilter = savedTableFilter;
        } else if (!objectIsEmpty(this.filter)) {
            pickedFilter = this.filter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        this.props.exportCDR(service, pickedFilter, this.curPage, this.curPerPage);
    };

    onSaveColumnsTable = (idListToSave) => {
        localStorage.setItem(LOCAL_STORAGE_CDR_COLUMNS_DIALER, JSON.stringify(idListToSave));
    };


    render() {
        const {
            showColumns, searchLoading, savedPerPage, preparedFilter, savedTableFilter, savedTableColumns,
            applyButtonLoading
        } = this.state;
        const {
            loading, uploadLoading, cdr_list, more, page, count, per_page, service, summaries, globalSettings, columns
        } = this.props;
        const exportCDR = this.exportCDR;
        const onChangePage = this.onChangePage;
        const onChangePerPage = this.onChangePerPage;
        const onSaveColumnsTable = this.onSaveColumnsTable;

        // remake with reselector
        let dataWithSummaries = [];
        let summariesData = {};

        if (Object.keys(summaries).length && cdr_list.length) {
            summariesData = {...cdr_list[0]};

            Object.keys(summariesData).forEach(item => {

                switch (item) {
                    case "cost_dialer":
                        summariesData[item] = summaries["cost"] !== undefined ? summaries["cost"] : null;
                        break;

                    case "id":
                        summariesData[item] = "summaries";
                        break;

                    default:
                        summariesData[item] = summaries[item] || null;
                }

            });

            dataWithSummaries = [...cdr_list];
            dataWithSummaries.push(summariesData);
        }

        return (
            <>

                {this.state.resizedWidth < 768 &&

                    <>
                        <ButtonApplyFilterMobile
                            disabled={applyButtonLoading}
                            onClick={() => {
                                const checker = this.filtersModel.check(preparedFilter);
                                const formHasError = Object.keys(checker).some(key => checker[key].hasError);

                                const preparedFilterValues = {...preparedFilter};

                                if (preparedFilterValues.range && preparedFilterValues.range.length) {
                                    preparedFilterValues['start_date'] = toUTCDateTime(preparedFilterValues.range[0]);
                                    preparedFilterValues['end_date'] = toUTCDateTime(preparedFilterValues.range[1]);
                        
                                    delete preparedFilterValues.range;
                                }

                                if (!formHasError) {
                                    this.getCDRListCommon(savedPerPage || this.curPerPage || per_page, preparedFilterValues, 1);
                                }
                            }}
                        >
                            {this.formatMessage(m.applyFilter)}
                        </ButtonApplyFilterMobile>
                        <Toggler
                            icon={<Icon icon="filter"/>}
                            onClick={() => this.setState({showFilters: true})}
                        >
                            {this.state.activeFiltersCount}
                        </Toggler>
                        <Drawer
                            size="xs"
                            placement="bottom"
                            style={{height: '240px'}}
                            show={this.state.showFilters}
                            onHide={() => this.setState({showFilters: false})}
                        >
                            <Drawer.Header>
                            </Drawer.Header>
                            <DrawerBody>
                                <CDRFilters
                                    onChangeFilters={this.onChangeFilters}
                                    loading={loading || searchLoading}
                                    filtersModel={this.filtersModel}
                                    {...{
                                        service,
                                        savedTableFilter,
                                        globalSettings
                                    }}
                                    mobile
                                />
                            </DrawerBody>
                        </Drawer>
                    </>

                }

                <Spacer size={30}/>

                {this.state.resizedWidth >= 768 &&
                    <div>
                        <FlexGrid justify="space-between">

                            <FlexGridItem>
                                <CDRFilters
                                    onChangeFilters={this.onChangeFilters}
                                    loading={loading || searchLoading}
                                    filtersModel={this.filtersModel}
                                    {...{
                                        service,
                                        savedTableFilter,
                                        globalSettings
                                    }}
                                />
                            </FlexGridItem>
                            <FlexGridItem>
                                <ButtonApplyFilter
                                    disabled={applyButtonLoading}
                                    onClick={() => {
                                        const checker = this.filtersModel.check(preparedFilter);
                                        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

                                        const preparedFilterValues = {...preparedFilter};

                                        if (preparedFilterValues.range && preparedFilterValues.range.length) {
                                            preparedFilterValues['start_date'] = toUTCDateTime(preparedFilterValues.range[0]);
                                            preparedFilterValues['end_date'] = toUTCDateTime(preparedFilterValues.range[1]);
                                
                                            delete preparedFilterValues.range;
                                        }

                                        if (!formHasError) {
                                            this.getCDRListCommon(savedPerPage || this.curPerPage || per_page, preparedFilterValues, 1);
                                        }
                                    }}
                                >
                                    {this.formatMessage(m.applyFilter)}
                                </ButtonApplyFilter>
                                <ButtonDownload
                                    onDownload={exportCDR}
                                    loading={uploadLoading}
                                    limitWidth={1179}
                                    buttonText={this.formatMessage(m.exportReport)}
                                />
                            </FlexGridItem>
                        </FlexGrid>
                        <Spacer size={19}/>
                    </div>
                }

                <Spacer size={1}/>

                <CDRTable
                    data={Object.keys(summariesData).length ? dataWithSummaries : cdr_list}
                    extraRows={Object.keys(summariesData).length ? 1 : 0}
                    pickedColumns={showColumns}
                    loading={loading || searchLoading}
                    {...{
                        onChangePage,
                        onChangePerPage,
                        page,
                        per_page,
                        count,
                        more,
                        service,
                        savedPerPage,
                        savedTableColumns,
                        onSaveColumnsTable
                    }}
                />

                {this.state.resizedWidth < 768 &&
                    <StyledActionButtonWrapper>
                        <ButtonDownload
                            onDownload={exportCDR}
                            loading={uploadLoading}
                            limitWidth={1179}
                            buttonText={this.formatMessage(m.exportReport)}
                        />
                    </StyledActionButtonWrapper>
                }
            </>
        );
    }
}

export default injectIntl(CDR)

const Toggler = styled(IconButton)`
    && {
        color: #363434;
        float: right;
        margin-top: 6px;
    }
`;

const DrawerBody = styled(Drawer.Body)`
    && {
        position: initial;
    }
    && .dateTimePicker {
        height: 40px;
    }

    && .dateTimePicker.dateFrom {
        margin-top: -485px;
        position: absolute;
    }
    && .dateTimePicker.dateTo {
        margin-top: -165px;
        position: absolute;
    }
    && .dateTimePicker .dateTimeName {
        color: var(--color-light);
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 10px;
    }
`;

const ButtonApplyFilter = styled(ButtonPrimary)`
    && {
        margin-right: 20px;
    }
`;

const ButtonApplyFilterMobile = styled(ButtonPrimary)`
    && {
        position: absolute;
        right: 100px;
    }
`;


const StyledActionButtonWrapper = styled.div`
    padding-top: 15px;
    text-align: center;
    
    @media (min-width: 768px) {
        text-align: right;
    }
`;