import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";

import BlockIvrList from "./BlockIvrList/BlockIvrList";
import BlockIvrFilter from "./BlockIvrFilter";
import BlockIvrSettings from "./BlockIvrSettings/BlockIvrSettings";

import {Spacer} from "../../../components/base/Spacer";
import {FlexGrid, FlexGridItem} from "../../../components/base/FlexGrid";


export default (
    {
        loading,
        data,
        ivrTypes,
        ivrSoundsList,
        getIvrList,
        getIvrTypes,
        getIvrSoundList,
        setActiveTab,
    }
) => {

    const [filter, setFilter] = useState({});
    const [activeIvrId, setActiveIvrId] = useState(null);


    // memo: active IVR data
    const activeIvr = useMemo(() => {
        return activeIvrId
            ? data.find(item => item.id === activeIvrId)
            : {name: null, isNew: true}
    }, [activeIvrId, data]);


    // fetch all data (IVRs, Action types, sounds)
    useEffect(() => {
        getIvrList();
        getIvrTypes();
        getIvrSoundList();
    }, []);

    useEffect(() => {
        getIvrList(filter);
    }, [filter]);

    // set active IVR from data
    useEffect(() => {
        if (!data.length) {
            setActiveIvrId(null);
            return;
        }

        if ( !data.find(item => item.id === activeIvrId) ) {
            setActiveIvrId(data[0].id);
        }

    }, [data]);


    const handleSelectIvr = (id) => {
        if (!id) {
            setActiveIvrId(null);
            // applesinkin: where is return?
        }
        setActiveIvrId(id);
    };


    const handleSelectIvrCreate = () => {
        setActiveIvrId(null);
    };    


    return (
        <StyledContentWrapper>
            <Spacer size={30} />

            <BlockIvrFilter
                activeIvr={activeIvr}
                onChangeFilter={setFilter}
            />

            <Spacer size={20} />

            <FlexGrid hSpace={'-20px'} align={'stretch'} noWrap>

                <FlexGridItem width="300px" hSpace={'20px'}>
                    <BlockIvrList
                        data={data}
                        {...{
                            loading,
                            activeIvr,
                            handleSelectIvr,
                            handleSelectIvrCreate
                        }}
                    />
                </FlexGridItem>

                <FlexGridItem grow={1} hSpace={'20px'}>
                    <BlockIvrSettings
                        {...{
                            loading,
                            activeIvr,
                            ivrTypes,
                            ivrSoundsList,
                            getIvrList,
                            setActiveTab,
                        }}
                    />
                </FlexGridItem>

            </FlexGrid>

            <Spacer size={30}/>
        </StyledContentWrapper>
    )
};

const StyledContentWrapper = styled.div`
    padding: 0;
`;