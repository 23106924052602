import {defineMessages} from "react-intl";

export default defineMessages({
    cliLookup: {id: "cli.cliLookup", defaultMessage: "CLI Lookup", description: 'CLI: CLI Lookup'},
    putYourCliMin: {id: "cli.putYourCliMin", defaultMessage: "Put your CLI (min {count} digits)", description: 'CLI: Put your CLI (min {count} digits)'},
    putYourCli: {id: "cli.putYourCli", defaultMessage: "Put your CLI", description: 'CLI: Put your CLI'},
    autorefreshPeriod: {id: "cli.autorefreshPeriod", defaultMessage: "Autorefresh period", description: 'CLI: Autorefresh period'},
    putSenderIdMin: {id: "cli.putSenderIdMin", defaultMessage: "Put SenderId (min {count} digits)", description: "CLI: Put SenderId (min {count} digits)"},
    putSenderId: {id: "cli.putSenderId", defaultMessage: "Put SenderId", description: "CLI: Put SenderId"},
    otp: {id: "cli.otp", defaultMessage: "OTP", description: "CLI: OTP"}
})