import React, {useState, useEffect, useMemo, useContext} from 'react';
import {Schema} from 'rsuite';
import FormHOC from '../../../hoc/FilltersForm';
import {getAllowedRangesByIntervalMonth, removeTZFromDate, servicePick, toUTCDateTime} from '../../../utils';
import CustomSelfClearedForm from '../../../components/client/Form/CustomSelfClearedForm';
import {injectIntl, useIntl} from "react-intl";
import {compose} from "redux";
import {useWindowWidth} from "../../../hooks";
import { FlexGrid, FlexGridItem } from '../../../components/base/FlexGrid';
import CustomField from '../../../components/client/Form/CustomField/CustomField';
import m from "../../../definedMessages";
import {BaseDateRangePicker, BaseInputField} from "../../../components/base/BaseForm";
import {RtlContext} from "../../../App";
import DatePickerCustom from '../../../components/Filters/DatePickerCustom';
import DateTimeRangePicker from "../../../components/DateRangePicker/DateTimerRangePicker";
import styled from "styled-components";

const CDRFilters = (
    {
        mobile,
        service,
        loading,
        filtersModel,
        onChangeFilters,
        savedTableFilter,
        globalSettings = {}
    }
) => {
    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);

    const allowedRanges = useMemo(() => {
        return getAllowedRangesByIntervalMonth(globalSettings.cdr_full__interval_month);
    }, [globalSettings]);

    const resizedWidth = useWindowWidth();

    // let form;
    const todayDateStart = new Date(new Date().setHours(0, 0, 0, 0));
    const todayDateEnd = new Date(new Date().setHours(23, 59, 59, 999));

    const defaultFilter = {
        range: [todayDateStart, todayDateEnd],
        start_date: new Date(new Date().setHours(0, 0, 0, 0)),
        end_date: new Date(new Date().setHours(23, 59, 59, 999)),
        ...(service ? {a_number: ''} : {senderid: ''}),
        ...(service ? {b_number: ''} : {phone: ''})
    };

    const [changedFormValue, changeFormData] = useState(savedTableFilter || defaultFilter);

    useEffect(() => {
        changeFormData(defaultFilter);
        onChangeFilters({
            ...defaultFilter,
            start_date: defaultFilter.start_date,
            end_date: defaultFilter.end_date,
        });
    }, [service]);

    const onSubmit = (data, checkedData) => {
        const checker = Object.keys(checkedData).map(i => checkedData[i].hasError).every(value => value === false);
        const validatedData = filtersModel.check(data);

        for (const item of Object.keys(validatedData)) {
            if (validatedData[item].hasError) {
                return false;
            }
        }

        if (checker) {
            onChangeFilters({
                ...data,
                start_date: data.start_date,
                end_date: data.end_date,
            });
        }

        return false;
    };

    const calendarPlacement = resizedWidth >= 768 ? (rtl ? "bottomEnd" : "bottomStart") : 'auto';

    const handleChangeDateTime = (value) => {
        const filter = {...value};
        const currentDatetimeFilter = {range: [filter.range[0],filter.range[1]]};

        const filtersToUpdate = {
            ...changedFormValue,
            ...filter,
            ...currentDatetimeFilter
        };

        changeFormData(filtersToUpdate);

        const filterValues = Object.assign({}, filtersToUpdate);

        if (filterValues.range && filterValues.range.length) {
            // filterValues['start_date'] = toStartDayUTCTime(filterValues.range[0]);
            // filterValues['end_date'] = toEndDayUTCTime(filterValues.range[1]);
            filterValues['start_date'] = toUTCDateTime(filterValues.range[0]);
            filterValues['end_date'] = toUTCDateTime(filterValues.range[1]);

            // delete filterValues.range;
        }
        const checkedData = filtersModel.check(filterValues);
        onSubmit(filterValues, checkedData);
    };

    return (
        <CustomSelfClearedForm
            // ref={ref => (form = ref)}
            clearOn={service}
            model={filtersModel}
            formDefaultValue={defaultFilter}
            onChange={(data) => {
                const formData = {
                    ...data,
                    range: changedFormValue?.range
                };
                changeFormData(formData);

                const formDataValues = {
                    ...formData,
                    range: changedFormValue?.range
                };

                if (formDataValues.range && formDataValues.range.length) {
                    // filterValues['start_date'] = toStartDayUTCTime(filterValues.range[0]);
                    // filterValues['end_date'] = toEndDayUTCTime(filterValues.range[1]);
                    formDataValues['start_date'] = toUTCDateTime(formDataValues.range[0]);
                    formDataValues['end_date'] = toUTCDateTime(formDataValues.range[1]);
        
                    delete formDataValues.range;
                }

                const checkedData = filtersModel.check(formDataValues);
                onSubmit(formDataValues, checkedData);
            }}
            fluid
            // readOnly={loading && 'disabled' || ''}
            readOnly={loading}
        >
            <FlexGrid align="middle">
                
                {(resizedWidth > 1005) && 
                    <FlexGridItem>{formatMessage(m.dateFrom)}</FlexGridItem>
                }

                <FreeSpaceFlexGridItem>
                {/* <FlexGridItem width="350px"> */}
                    {/* <DatePickerCustom
                        isDisabledDates
                        changedFormValue={changedFormValue}
                        showRangeForSevenDays={false}
                        // withTime
                    /> */}
                    <DateTimeRangePicker
                        onChange={(value) => {
                            handleChangeDateTime(value);
                        }}
                        defaultStartDate={defaultFilter.range[0]}
                        defaultEndDate={defaultFilter.range[1]}
                        startDate={changedFormValue?.range[0]}
                        endDate={changedFormValue?.range[1]}
                        mobile={mobile}
                        // input={false}
                    />
                </FreeSpaceFlexGridItem>
                <FlexGridItem width="260px">
                    <CustomField
                        accepter={BaseInputField}
                        name={servicePick(service, "a_number", "senderid")}
                        placeholder={service ? formatMessage(m.aNumber) : formatMessage(m.senderId)}
                    />
                </FlexGridItem>
                <FlexGridItem width="260px">
                    <CustomField
                        accepter={BaseInputField}
                        name={servicePick(service, "b_number", "phone")}
                        placeholder={formatMessage(m.bNumber)}
                    />
                </FlexGridItem>
            </FlexGrid>
        </CustomSelfClearedForm>
    );
};

export default compose(
    FormHOC,
    injectIntl
)(CDRFilters);


const FreeSpaceFlexGridItem = styled(FlexGridItem)`
    && {
        width: auto;
        position: inherit;
    }
`;
