import React, {useRef, useState} from "react";
import Modal from "../../../../../components/Modal/Modal";
import {api} from "../../../../../api/loginRoutes";
import {SCREEN_MEDIA} from "../../../../../const";
import {Alert, Schema, ControlLabel, Form, Input, FormControl, SelectPicker} from "rsuite";

import styled, {css} from "styled-components";
import {
    SMS_SUPPLIER_PLAN_CREATE_METHOD,
    SMS_SUPPLIER_PLAN_MODIFY_METHOD
} from "../../../../../const/apiMethods";
import { useEffect } from "react";
import Checkbox from "../../../../../hoc/Checkbox";

const {StringType, NumberType} = Schema.Types;


export default (
    {
        show,
        currentData,
        onSuccess,
        onClose,
        isSupplier,
        toCreate=true,
        disabled=false,
    }
) => {
    const formEditModel = Schema.Model({
        name: StringType()
            .minLength(1, "The minimum is 1 characters.")
            .maxLength(40, "The maximum is 40 characters"),
        comment_admin: StringType()
            .maxLength(255, "The maximum is 255 characters"),
        comment_dialer: StringType()
            .maxLength(255, "The maximum is 255 characters")
    });

    const formRef = useRef(null);
    const [_disabled, setDisabled] = useState(disabled);
    const [formValue, setFormValue] = useState({});

    const checkKey = "sms.access_list";
    const method = toCreate ? SMS_SUPPLIER_PLAN_CREATE_METHOD : SMS_SUPPLIER_PLAN_MODIFY_METHOD;

    useEffect(() => {
        if (currentData) {
            const currentFormData = {
                name: currentData?.name,
                comment_admin: currentData?.comment_admin || "",
                comment_dialer: currentData?.comment_dialer || "",
                supplier_access_list_timeless: currentData?.supplier_access_list_timeless
            }

            setFormValue(currentFormData);
        }
    }, [currentData]);

    const onSubmit = async () => {

        const params = {
            target: {
                "sms.supplier_plan_id": currentData?.id,
                "sms.supplier_id": currentData?.supplier_id
            },
            ...formValue
        };

        setDisabled(true);
        
        const result = await api(method, params);

        if (result && result[checkKey]) {

            const messageAction = toCreate ? "created" : "modified";

            if (result[checkKey] === 1) {
                Alert.success(`1 access was ${messageAction}`);
            } else {
                Alert.success(`${result[checkKey]} accesses were ${messageAction}`);
            }

        }

        onSuccess();
        setDisabled(false);
    };

    const formDefaultData = {
        name: currentData?.name,
        comment_admin: currentData?.comment_admin || "",
        comment_dialer: currentData?.comment_dialer || "",
        supplier_access_list_timeless: currentData?.supplier_access_list_timeless,
        type: currentData?.type
    };

    const onlyForGan = !formValue?.type && formDefaultData.type === "gan" || formValue.type === "gan";
    const modalAction = toCreate ? "Create" : "Modify";
    const formEditModelErrorFields = formEditModel.check(formValue);
    const someFieldHasError = Object.keys(formEditModelErrorFields).some(key => {
        const field = formEditModelErrorFields[key];
        return field.hasError;
    });


    return (
        <>
            {show && <Modal
                show={show}
                title={`${modalAction} Trunk Type`}
                onClose={onClose}
                footer={true}
                successText={modalAction}
                disabled={_disabled}
                extraDisabled={someFieldHasError}
                onSuccess={onSubmit}
            >
                <StyledForm
                    ref={formRef}
                    model={formEditModel}
                    formValue={formValue}
                    formDefaultValue={formDefaultData}
                    onChange={setFormValue}
                >
                    <StyledField>
                        <ControlLabel>Trunk Type</ControlLabel>
                        <FormControl
                            name="name"
                            accepter={Input}
                            placeholder="Trunk Type..."
                            disabled={!toCreate}
                        />
                    </StyledField>

                    <StyledField>
                        <ControlLabel>Admin comment</ControlLabel>
                        <FormControl
                            name="comment_admin"
                            accepter={Input}
                            componentClass="textarea"
                            placeholder="Comment..."
                        />
                    </StyledField>

                    <StyledField>
                        <ControlLabel>Dialer comment</ControlLabel>
                        <FormControl
                            name="comment_dialer"
                            accepter={Input}
                            componentClass="textarea"
                            placeholder="Comment..."
                        />
                    </StyledField>

                    {/* <StyledField>
                        <ControlLabel>Type</ControlLabel>
                        <FormControl
                            name="type"
                            accepter={SelectPicker}
                            data={[
                                {
                                    value: "gan",
                                    name: "GAN"
                                },
                                {
                                    value: "transit",
                                    name: "Transit"
                                }
                            ]}
                            valueKey={"value"}
                            labelKey={"name"}
                            placeholder="Seletect supplier plan type"
                            searchable={false}
                            disabled={!toCreate}
                        />
                    </StyledField> */}

                    {onlyForGan && <StyledField>
                        <ControlLabel>Timeless</ControlLabel>
                        <Checkbox
                            name="supplier_access_list_timeless"
                            defaultChecked={formDefaultData?.supplier_access_list_timeless || false}
                        />
                    </StyledField>}
                    
                </StyledForm>
            
            </Modal>
            }
        </>

    );
}

const StyledForm = styled(Form)`
    .rs-form-group {
        display: flex;
        align-items: center;
    }
    
    .rs-control-label {
        margin-right: 20px;
        margin-bottom: 0;
        width: 100px;
    }
    
    .rs-picker-select {
        width: 100%;
    }
`;

const StyledField = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
    }

    .rs-control-label {
        flex-shrink: 0;
        align-self: center;
        padding-right: 10px;
        width: 100%;
        margin-right: 0;

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            width: 160px;
        }
    }

    .rs-input-number {

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            max-width: 90px;
        }
    }

    ${props => props.isCheckbox && css`
        display: flex;
        
        @media (max-width: ${SCREEN_MEDIA.sm.max}px) {

            .rs-form-control-wrapper {
                width: auto;
            }

            .rs-control-label {
                width: auto;
                order: 1;
            }
        }
    `}
`;