import {createAction} from './defaults';
import {api} from "../api/loginRoutes";
import {toEndDayUTCTime, toStartDayUTCTime} from "../utils";
import {ACTION_LOGS_LIST, ACTION_LOGS_REFERENCES, ACTION_LOGS_SET_LOADING} from "../const";
import {ACTION_LOGS_GET_LIST_API, ACTION_LOGS_GET_REFERENCES_API} from "../const/apiMethods";

const setLogsList = createAction(ACTION_LOGS_LIST);
const setLogsListLoading = createAction(ACTION_LOGS_SET_LOADING);
const setReferences = createAction(ACTION_LOGS_REFERENCES);

// get list
export const getLogsList = (filter = {}, page = 1, perPage = 100) => (dispatch) => {
    dispatch( setLogsListLoading(true) );

    const data = {
        filter: {
            ...filter,
            site_list: filter.site_list && filter.site_list.length
                ? filter.site_list
                : null,
            action_list: filter.action_list && filter.action_list.length
                ? filter.action_list
                : null,
        },
        target: {account_id_list: null},
        page: page,
        per_page: perPage,
    };

    if (filter?.cancelToken) {
        data.cancelToken = filter.cancelToken;
        delete data.filter.cancelToken;
    }

    if (filter.range && filter.range.length) {
        data.filter['start_date'] = toStartDayUTCTime(filter.range[0]);
        data.filter['end_date'] = toEndDayUTCTime(filter.range[1]);

        delete data.filter.range;
    }

    if (filter.account_user_id_list) {
        data.target.account_user_id_list = filter.account_user_id_list && filter.account_user_id_list.length
            ? filter.account_user_id_list
            : null;
        delete data.filter.account_user_id_list;
    }
    if (filter.account_id_list) {
        data.target.account_id_list = filter.account_id_list && filter.account_id_list.length
            ? filter.account_id_list
            : null;
        delete data.filter.account_id_list;
    }

    api(ACTION_LOGS_GET_LIST_API, data)
        .then((response) => {
            if (response !== undefined) {

                if (response?.cancelled) {
                    return;
                }

                dispatch( setLogsList({
                    list: response.log_action_list || [],
                    count: response.log_action_count,
                    page,
                    perPage,
                }) );
            } else {
                setLogsListLoading(false);
            }
        })
};

// get manager list
export const getReferences = () => (dispatch) => {
    api(ACTION_LOGS_GET_REFERENCES_API)
        .then((response) => {
            if (response !== undefined) {
                dispatch(setReferences({
                    action_list: response.action_list || [],
                    account_user_manager_list: response.account_user_manager_list || [],
                    account_user_list: response.account_user_list || [],
                }));
            }
        })
};