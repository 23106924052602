import React, { useMemo, useEffect } from 'react';
import {connect} from 'react-redux';
import {SelectPicker, Form, Schema} from 'rsuite';
import CustomField from '../Form/CustomField';
import styled from 'styled-components';
import CustomSelectPicker from "../Form/CustomSelectPicker";

const {StringType} = Schema.Types;

const model = Schema.Model({
    trunk_id: StringType().isRequired('Required')
});

const DialerTrunkList = ({
    dialerTrunkList, 
    disabledTrunk,
    trunkId = null,
    updateRef, 
    onChange,
    onClear = () => {},
    accounts = null,
}) => {

    useEffect(() => {
        const filteredAccountsId = accounts && Array.isArray(accounts) && accounts.map(item => item.id);
        if (trunkId && filteredAccountsId) {
            const filteredTrunks = dialerTrunkList
                .filter(item => filteredAccountsId.indexOf(item.account_id) !== -1);
            if (!filteredTrunks.some(item => item.trunk_id === trunkId)) {
                onClear();
            }
        }
    }, [accounts]);


    const renderHeaderMenuItem = (label, item) => {
        if (!item)
            return "";

        return (
            <>{`${item.acc_name} / ${item.tr_name} / ${item.sp_name}`}</>
        )
    };


    const renderMenuGroup = (groupTitle, {children}) => {
        const managerName = children && children[0] && children[0].account_manager_name
            ? children[0].account_manager_name + " "
            : "";
        return (
            <>{`${managerName}${groupTitle}`}</>
        )
    };


    const renderMenuItem = (label, {tr_name, sp_name}) => {
        return (
            <>{`${tr_name} ${sp_name ? "/ " + sp_name : ""}`}</>
        )
    };


    // get filtered trunks list
    const filteredAccountsId = accounts && Array.isArray(accounts) && accounts.map(item => item.id);

    const trunksList = useMemo(() => {
        return filteredAccountsId && Array.isArray(filteredAccountsId)
        ? dialerTrunkList
            .filter( item => filteredAccountsId.includes(item.account_id) )
        : [...dialerTrunkList]
    }, [accounts, dialerTrunkList]);


    // get empty accounts
    const emptyAccounts = accounts && accounts.filter(account => (
        !dialerTrunkList.some(trunk => trunk.account_id === account.id)
    ));
    if (emptyAccounts) {
        emptyAccounts.forEach(account => {
            trunksList.push({
                _name: "",
                acc_name: account.name,
                account_id: account.id,
                account_manager_name: account.account_manager_name
                    ? `(${account.account_manager_name})`
                    : '',
                sp_auth: false,
                sp_key: null,
                sp_name: '',
                sp_otp_type: null,
                tr_active: true,
                tr_key: null,
                tr_name: "Empty",
                trunk_id: false,
            });
        });
    }

    return (
        <>
            <Form
                formValue={{trunk_id: trunkId}}
                style={{marginBottom: 20}}
                model={model}
                ref={ref => updateRef ? updateRef(ref) : null}
            >
                <StyledFormField>
                    <CustomField
                        block
                        data={trunksList}
                        accepter={CustomSelectPicker}
                        labelKey="_name"
                        valueKey="trunk_id"
                        label="Dialer/Trunk"
                        name="trunk_id"
                        errorPlacement="topRight"
                        groupBy="acc_name"
                        menuClassName={"picker-menu-trunks"}
                        renderMenuItem={renderMenuItem}
                        renderValue={renderHeaderMenuItem}
                        renderMenuGroup={renderMenuGroup}
                        disabled={disabledTrunk}
                        disabledItemValues={[false]}
                        onClean={onClear}
                        onChange={(id) => {
                            return onChange ? onChange(dialerTrunkList.find(trunk => trunk.trunk_id === id)) : onChange
                        }}
                    />
                </StyledFormField>
            </Form>
        </>
    )
};


const mapState = ({references}) => ({
    dialerTrunkList: references.dialerTrunkList,
});

export default connect(mapState, {})(DialerTrunkList);

const StyledFormField = styled.div`
    &&& {
        .rs-picker-toggle-value, 
        .rs-picker-toggle-custom-value {
            white-space: normal;
        }
        
        .rs-form-group .rs-form-control-wrapper {
            display: block;
            width: 100%;
        }
    }
`;
