import React from 'react';
import styled from 'styled-components';
import {ApiRequest, API_Modal} from '../../components'
import {Alert, Button} from 'rsuite';
import Icon from 'rsuite/es/Icon';
import {ButtonPrimary} from "../../components/base/BaseButton";

export default ({target, onUpdate}) => {
    const [show, setShow] = React.useState(false);

    return (
        <>
            <StyledButtonOpen
                appearance="ghost"
                onClick={() => setShow(true)}
            >
                <Icon icon="unlock" style={{marginRight: 5}}/>
            </StyledButtonOpen>

            {show &&
                <API_Modal
                    title="Open service"
                    onClose={() => setShow(false)}

                >
                    <ApiRequest
                        method="account:restore"
                        target={target}
                        checkResultKey="account"
                        update={() => {
                            onUpdate();
                            Alert.success(`Account was opened`)
                        }}
                    >
                        <p>You are sure?</p>
                    </ApiRequest>
                </API_Modal>
            }
        </>
    );
}

const StyledButtonOpen = styled(Button)`
    && {
        border-color: var(--color-secondary) !important;
        color: var(--color-secondary) !important;
        width: 34px;
        height: 34px;
        background-color: white;
    }
`;