import React, {useState, useEffect} from 'react';
import {Checkbox} from 'rsuite';

const CustomCheckbox = ({children, defaultValue = false, value, onChange, ...props}) => {
    const [_value, _setValue] = useState(defaultValue);

    useEffect(() => {
        _setValue(value);
    }, [value]);

    return <Checkbox
        value={_value}
        checked={_value}
        onChange={() => {
            _setValue(!_value);
            onChange && onChange(!_value);
        }}
        {...props}
    >
        {children}
    </Checkbox>
};

export default CustomCheckbox;