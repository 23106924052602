import React, {useState, useEffect, useContext} from "react";
import {Icon, Nav, Navbar, Badge, Toggle} from "rsuite";
import {useLocation, withRouter} from "react-router"
import ModalEditProfile from "../../components/EditProfile/ModalEditProfile";
import {connect} from "react-redux";
import "../../components/EditProfile/EditProfile.css"
import styled, {css} from "styled-components";
import UserProfileMenu from "./UserProfileMenu";
import NavigationMenu from "./MainMenu/MainMenu";
import {defineMessages, useIntl} from "react-intl";
import AccountManagerInfo from "./ManagerInfo";
import BalanceMenu from "./BalanceMenu";
import {useWindowWidth} from "../../hooks";
import {
    transformed_service_reverse_data, 
    APP_TYPE_ADMIN, 
    APP_TYPE_CLIENT,
    DEFAULT_AUTH_PATH_ADMIN,
    DEFAULT_AUTH_PATH_ADMIN_RATEMOD
} from "../../const";
import {Link} from "react-router-dom";
import SkypeInfo from "./Contacts/SkypeInfo";
import ContactModal from "./Contacts/ContactModal";
import {AppTypeContext} from "../../App";
import {checkPermissionsFor} from "../../store/storeHelpers"
import {
    ACCOUNT_MANAGERS_GET_INFORMATORS_API,
    ACCOUNT_USER_GET_REALTIME_STATUS,
    REGISTRATION_GET_COUNT_API
} from "../../const/apiMethods";
import {LOCAL_STORAGE_DELAYED_SERVICE_CHANGE} from "../../const/localStorageKeys";
import Whisper from "rsuite/es/Whisper"
import Tooltip from "rsuite/es/Tooltip"
import {setService} from "../../actions/auth";
import {setDialerService} from "../../actions/auth_dialer";
import usePrevious from "../../hooks/usePrevious";
import {debounce} from "../../utils";
import {getAccountManagersInformators} from "../../actions/account_managers";
import { FlexBlock } from "pages/Payments/styled";

import m from "definedMessages";


const Navigation = (
    {
        openAllocationModal,
        logoutUser,
        authInfo = {},
        accountJoinList = [],
        billingServiceList = [],
        billingCurrencyList = [],
        service,
        serviceDialer,
        history: historyProp,
        location: locationProp,
        dialerInfo,
        permissions,
        informators,
        servicePlanList,

        modifyPasswordRequest,
        modifyAccountRequest,

        registrationCount,
        getRegistrationCount,
        realtimePaymentStatus,
        getRealtimePaymentStatus,
        getAccountManagersInformators,
        setService,
        setDialerService,
        supportContacts,
        contactText,
        countDialer
    }
) => {
    const intl = useIntl();
    const windowWidth = useWindowWidth();
    const prevService = usePrevious(service);
    const [showEditProfileModal, onChangeShowEditProfileModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const appType = useContext(AppTypeContext);

    const isTest = authInfo?.session?.is_test;

    const location = useLocation();

    const roleList = authInfo?.session?.account_user?.role_list;
    const isRatemod = roleList && roleList.length ? (roleList.includes("Ratemod") || roleList.includes("Ratemod (sms)")) : null;

    // do reload and replace to /accounts and on isRatemod too
    // if (!isRatemod && prevService !== service && changedByToggle !== null) {
    //     historyProp.replace("/reload");


    // useEffect(() => {
    //     // Only on change toggle
    //     const changedByToggle = localStorage.getItem("SMS_SERVICE") ? JSON.parse(localStorage.getItem("SMS_SERVICE")) : null;
    //     const serviceDelayed = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DELAYED_SERVICE_CHANGE));

    //     // delete ?tab=general from search
    //     if (!isRatemod && prevService !== service && changedByToggle !== null) {
      
    //         if (serviceDelayed === null) {
    //             historyProp.replace("/reload");

    //             setTimeout((locationParam, historyParam) => {
    //                 historyParam.replace({
    //                     ...locationParam,
    //                     pathname: "/accounts",
    //                     search: ""
    //                 });
    //             }, 300,
    //             locationProp, historyProp);
    //         }
    //     }

    //     if (service === serviceDelayed && serviceDelayed !== null) {
    //         localStorage.removeItem(LOCAL_STORAGE_DELAYED_SERVICE_CHANGE);
    //     }
    // }, [service]);

    useEffect(() => {
        if ((!registrationCount || service !== prevService) && permissions?.length && permissions.includes(REGISTRATION_GET_COUNT_API) && appType === APP_TYPE_ADMIN) {
            getRegistrationCount();
        }
        else if (!realtimePaymentStatus && permissions?.length && permissions.includes(ACCOUNT_USER_GET_REALTIME_STATUS) && appType === APP_TYPE_CLIENT) {
            if (!isTest) {
                const accountId = authInfo?.session?.account_user?.id;
                getRealtimePaymentStatus(accountId, service);
            }
        }
    }, [registrationCount, service, permissions, realtimePaymentStatus, appType]);

    useEffect(() => {
        if (Object.keys(informators).length && permissions.length && permissions.includes(ACCOUNT_MANAGERS_GET_INFORMATORS_API) && appType === APP_TYPE_ADMIN) {
            getAccountManagersInformators()
        }

        const pathPartList = location.pathname.split("-");
        const lastPathPart = pathPartList.length ? pathPartList[pathPartList.length-1] : null;
        const pathService = lastPathPart && ["sms", "voice"].includes(lastPathPart) ? lastPathPart : null;

        if (pathService === "sms") {
            setDialerService(false);
        } else if (pathService === "voice") {
            setDialerService(true);
        }
    }, [permissions, location.pathname, accountJoinList, appType]);

    const accountSession = authInfo?.session;
    const accountManangedServiceList = accountSession?.account_user?.managed_service_list;

    return (
        <TopNavbar
            isMobile={windowWidth < 1575}
        >
            <TopNavbar.Header>
                <UserProfileMenu
                    savedUser={authInfo}
                    appType={appType}
                    onChangeShowEditProfileModal={() => onChangeShowEditProfileModal(true)}
                />
                <ModalEditProfile
                    show={showEditProfileModal}
                    onClose={() => onChangeShowEditProfileModal(false)}
                    {...{
                        authInfo,
                        modifyAccountRequest,
                        modifyPasswordRequest,
                    }}
                />
            </TopNavbar.Header>

            <TopNavbar.Body>
                <NavigationMenu
                    {...{
                        service,
                        serviceDialer,
                        setDialerService,
                        accountJoinList,
                        authInfo,
                        logoutUser,
                        informators,
                        permissions,
                        servicePlanList,
                        registrationCount,
                        openAllocationModal
                    }}
                />

                <NavbarInfo>

                    {appType === APP_TYPE_CLIENT && accountJoinList.length ?
                        <>
                            {billingServiceList !== null ? <BalanceMenu
                                isMobile={windowWidth < 1325}
                                {...{
                                    realtimePaymentStatus,
                                    accountJoinList,
                                    billingServiceList,
                                    billingCurrencyList
                                }}
                            /> : <></>}
                            <AccountManagerInfo
                                isMobile={windowWidth < 1540}
                                {...{
                                    authInfo,
                                    accountJoinList,
                                    showContactModal,
                                    setShowContactModal
                                }}
                            />
                            {/* <SkypeInfo
                                isMobile={windowWidth < 1200}
                                {...{
                                    dialerInfo,
                                    supportContacts
                                }}
                            /> */}
                        </> : <></>
                    }


                    <StyledNav>
                        {appType === APP_TYPE_ADMIN
                            && accountManangedServiceList
                            && accountManangedServiceList.length > 1 
                            && <StyledToggle 
                                mobile={windowWidth < 1200}
                                checked={service} 
                                 //
                                // ATX-2922
                                //
                                onChange={debounce((checked) => {
                                    // localStorage.setItem("SMS_SERVICE", checked);
                                    const currentService = transformed_service_reverse_data[checked];
                                    const path = isRatemod ? DEFAULT_AUTH_PATH_ADMIN_RATEMOD : DEFAULT_AUTH_PATH_ADMIN;

                                    setTimeout((locationParam, historyParam) => {
                                        historyParam.replace({
                                            ...locationParam,
                                            pathname: path,
                                            search: `?service=${currentService}`
                                        });
                                    }, 300,
                                    locationProp, historyProp);

                                    setService(checked);
                                }, 50)} 
                                checkedChildren="Voice"
                                unCheckedChildren="SMS"
                            />
                        }
                        {windowWidth >= 1200 && checkPermissionsFor(REGISTRATION_GET_COUNT_API) && appType === APP_TYPE_ADMIN &&
                            (registrationCount > 0
                                ? <StyledBadge content={registrationCount}>
                                    <NavLink
                                        icon={<Icon icon="group"/>}
                                        to="/registration-requests"
                                        eventKey="/registration-requests"
                                    />
                                </StyledBadge>
                                : <NavLink
                                    icon={<Icon icon="group"/>}
                                    to="/registration-requests"
                                    eventKey="/registration-requests"
                                />)
                        }

                        {appType === APP_TYPE_CLIENT && !isTest &&
                            <>
                                {countDialer
                                    ? <StyledBadge content={countDialer}>
                                        <NavLink
                                            icon={<Icon icon="envelope"/>}
                                            to="/news"
                                            eventKey="/news"
                                        />
                                    </StyledBadge>
                                    : <NavLink
                                        icon={<Icon icon="envelope"/>}
                                        to="/news"
                                        eventKey="/news"
                                    />
                                }
                            </>
                        }
                        {appType === APP_TYPE_ADMIN && informators.hasOwnProperty("replication_stuck_count") && informators.replication_stuck_count !== 0 && <>
                            <Whisper placement="auto" trigger="hover" speaker={
                                <Tooltip>Replication has got stuck. Contact to developers please</Tooltip>
                            }>
                                <InformatorContainer
                                   mobile={windowWidth < 1200}
                                >
                                    <InformatorIcon icon="exclamation-triangle"/>
                                </InformatorContainer>
                            </Whisper>
                        </>}
                        {windowWidth >= 1200 && 
                            <Nav.Item
                                icon={<Icon icon="sign-out"/>}
                                onClick={logoutUser}
                            >
                                {intl.formatMessage(m.unlogin)}
                            </Nav.Item>
                        }
                    </StyledNav>


                </NavbarInfo>
            </TopNavbar.Body>
            <ContactModal
                {...{
                    contactText
                }}
                isMobile={windowWidth < 1200}
                show={showContactModal}
                onClose={setShowContactModal}
            />
        </TopNavbar>
    )
};


const mapState = ({auth, accounts, account_managers, auth_dialer, registration, references, news_management})=> ({
    service: auth.service,
    serviceDialer: auth_dialer.serviceDialer,
    accountJoinList: auth_dialer.accountJoinList,
    billingServiceList: auth_dialer.billingServiceList,
    billingCurrencyList: auth_dialer.billingCurrencyList,
    informators: account_managers.informators,
    permissions: auth.permissions,
    authInfo: auth.authInfo,
    updatedInfo: auth.authInfo,
    registrationCount: registration.registration_count,
    dialerInfo: auth_dialer.dialerInfo,
    supportContacts: references.support_contacts,
    servicePlanList: references.service_plan_list,
    contactText: references.contacts_text,
    realtimePaymentStatus: accounts.realtimePaymentStatus,
    countDialer: news_management.countDialer,
});

export default withRouter(connect(mapState, {
    setService,
    setDialerService,
    getAccountManagersInformators
})(Navigation));

export const createExternalLink = (link, type = null, replacement = null) => {
    let linkString = link;
    const linkText = replacement ? replacement : link;

    if (type === "skype") {
        linkString = `${type}:${linkString}?chat`;
    } else if (type) {
        linkString = `${type}:${linkString}`;
    }
    return <StyledExternalLink href={linkString}>{linkText}</StyledExternalLink>
}

const StyledToggle = styled(Toggle)`

    ${props => props.mobile && css `
        && {
            margin-top: 16px;
            margin-right: 20px;
        }
    `}

    &.rs-btn-toggle-checked, &.rs-btn-toggle-checked:hover {
        background-color: transparent;
        box-shadow: inset 0px 0px 0px 2px var(--activate-bg);
        box-sizing: border-box;
    }
    &.rs-btn-toggle-checked::after {
        background-color: var(--color-info);
    }
    &:not(.rs-btn-toggle-checked).rs-btn-toggle {
        background-color: transparent;
        box-shadow: inset 0px 0px 0px 2px var(--color-violet);
        box-sizing: border-box;
    }
    &:not(.rs-btn-toggle-checked).rs-btn-toggle::after {
        background-color: var(--color-violet);
    }
`;

const StyledNav = styled(Nav)`
    & > ul {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;

const NavLink = withRouter(props => (
    <Nav.Item componentClass={Link}
              active={props.location.pathname.indexOf(props.to) === 0}
              {...props}
    />)
);

const InformatorContainer = styled.div`


  && {

    ${props => props.mobile && css `
        margin-top: 16px;
        margin-right: 8px;
    `}
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 14px solid white;
    position: relative;
    display: inline-block;
    border-radius: 1px;
    top: 0px;
  }
`;

const InformatorIcon = styled(Icon)`
  && {
    display: inline-block;
    position: relative;
    top: -2px;
    left: -8px;
    color: red;
    font-size: 17px;
    //border: 2px solid white;
  }
`

const StyledExternalLink = styled.a`
    && {
        text-decoration: none;
        color: #FFFFFF;
    }
    &:hover {
        color: #dddddd;
    }
`

const TopNavbar = styled(Navbar).attrs(() => ({
    appearance: "inverse"
}))`
    align-items: stretch;
    display: flex;
    
    .rs-nav-waterline {
        border-top-color: transparent !important;
    }

    .rs-navbar-header {
        align-self: stretch;
        float: none;
        height: auto;
    }

    .rs-navbar-body {
        align-items: stretch;
        display: flex;
        flex-direction: row-reverse;
        flex-grow: 1;
        
        @media (min-width: 1200px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
    
    .rs-nav-item-content::before {
        background-color: #ffffff !important;
    }
    
    &&&&& {
        .rs-dropdown-toggle,
        .rs-nav-item-content {
            background-color: transparent !important;
        }
        
        ${props => props.theme.appType === APP_TYPE_CLIENT && css `
            .rs-nav-item-active {
                .rs-dropdown-toggle,
                .rs-nav-item-content {
                    background-color: #16b07e !important;
                }
            }
        `}
        
        .rs-nav-item-content {
            padding: 18px 16px;
            height: 56px;
            ${props => props.isMobile && css`
                padding: 18px 6px;
                font-size: 14px;
            `}
        }
    }

    &:before {
        display: none;
    }
    
    ${props => props.theme.appType === APP_TYPE_CLIENT && css`
        box-shadow: none !important;
    `}
`;

const NavbarInfo = styled.div`
    display: flex;
`;

const StyledBadge = styled(Badge)`
    & .rs-badge-content {
        margin: 15px;
        
        .rtl & {
            transform: translate(-50%, -50%);
            left: 0;
            right: auto;
        }
    }
`;