import React, {useState} from 'react';
import CustomField from './CustomField';
import {InputNumber} from 'rsuite';
import {calculateFixedSignsLength} from 'utils';

export const NumberField = ({onChange, calculated=false, width = 135, ...props}) => {
    const [value, setValue] = React.useState(props.value);

    return (
        <CustomField
            accepter={InputNumber}
            size="sm"
            style={{width: width}}
            width={width}
            placeholder="0"
            value = {value}
            max={100000}
            step={calculated ? calculateFixedSignsLength(value || props.value) : 1}
            onChange = {(v) => {
                if(onChange) onChange(v);
                setValue(v);
            }}
            {...props}
        />
)};

export default NumberField;















