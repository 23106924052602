import React, {useContext} from 'react';
import {renderColumn} from '../../utils/helpers';
import {useIntl} from "react-intl";
import {Link} from 'react-router-dom';
import CustomizableTable from '../../components/client/CustomizableTable/CustomizableTable';
import {ButtonRowClient, ButtonToolbar} from "../../components/base/BaseButton";
import m from "../../definedMessages";
import {RtlContext} from "../../App";
import {transformed_service_reverse_data} from "../../const/index";
import {SCREEN_MEDIA} from "../../const";
import {useWindowWidth} from '../../hooks';
import {ButtonPrimary} from "../../components/base/BaseButton";
import styled from "styled-components";

const {md} = SCREEN_MEDIA;


export default (
    {
        data = [],
        loading,
        page,
        count,
        per_page,
        savedPerPage,
        onChangePerPage,
        onChangePage,
        setDialerService,
        service,
        filter,
        accountInfo,
        accountIsSigle,
        isTest,
        isSupport,
        accountDialerState,
        ...props
    }
) => {

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < md.min;

    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);

    const columns = [
        {
            id: 'a_description', 
            label: formatMessage(m.accessOrigin), 
            dataKey: 'a_description',
            value: ({a_description, a_number}) => {
                return (
                    a_number ? <SecondStagedCell className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">
                            {a_description}
                        </div>
                        {a_number && <div className="table-two-staged__cell-second">
                            {a_number}
                        </div>}
                    </SecondStagedCell> : a_description
                )
            },
            flexGrow: 2,
            minWidth: 200,
            align: 'center',
        },
        {
            id: 'b_description', 
            label: formatMessage(m.accessDestination), 
            dataKey: 'b_description',
            value: ({b_description, b_number}) => {
                return (
                    b_number ? <SecondStagedCell className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">
                            {b_description}
                        </div>
                        <div className="table-two-staged__cell-second">
                            {b_number}
                        </div>
                    </SecondStagedCell> : 
                    <span>{b_description}</span>
                )
            },
            flexGrow: 2,
            minWidth: 200,
            align: 'center',
        },
        ...(!isTest && !isSupport ? [{
            id: 'payout',
            label: formatMessage(m.payout),
            value: (({payment_terms_rate_list}) => {
                const obj = payment_terms_rate_list ? payment_terms_rate_list.find(value => {
                    return value.payment_terms_name === (accountInfo.hasOwnProperty('payment_terms_name') ? accountInfo.payment_terms_name : '');
                }) : null;
                if (obj) {
                    return `${Number(obj.rate).toFixed(4)} ${accountInfo.currency_name}`;
                } else {
                    return '—';
                }
            }),
            ...(!isMobile ? {flexGrow: 1} : {}),
            width: 110,
            align: 'center'
        }] : []),
        ...(!isTest ? [{
            id: 'details', 
            label: formatMessage(m.details),
            value: (rowData) => {
                return (
                    <ButtonToolbar>
                        <ButtonRowClient
                            size="sm"
                            onClick={() => {
                                const redirectData = {
                                    a_description: rowData?.a_description,
                                    b_description: rowData?.b_description,
                                    sp_key: rowData?.sp_key
                                };

                                localStorage.setItem("PICKED_HOT_ACCESS", JSON.stringify(redirectData));
                                const path = accountIsSigle ? "/accesses" : `/accesses-${transformed_service_reverse_data[service]}`;
                                window.open(path);
                                // setDialerService(service);
                            }}
                        >
                            <b>{formatMessage(m.details)}</b>
                        </ButtonRowClient>
                    </ButtonToolbar>
                );
            },
            // ...(!isMobile ? {flexGrow: 1} : {}),
            width: rtl ? 120 : 110,
            align: 'center',
        }] : []),
    ];
    
    const testColumns = [];
    const allTermList = [];

    for (const priceRange of data) {
        if (priceRange && priceRange.payment_terms_rate_list) {
            for (const term of priceRange.payment_terms_rate_list)
                allTermList.push(term.payment_terms_name);
        }
    }
    
    const uniqueTermList = allTermList.filter((v, i, a) => a.indexOf(v) === i);
    for (const term of uniqueTermList) {
        testColumns.push(
            {
                id: 'payouts',
                label: `${formatMessage(m.payout)} ${term}`,
                value: (({payment_terms_rate_list}) => {
                    if (payment_terms_rate_list) {
                        const obj = payment_terms_rate_list.find(value => value.payment_terms_name === term);
                        let currency = payment_terms_rate_list.find(value=> value.rate !== null);
                        if (currency) {
                            currency = currency.currency_name;
                        }
                        if (obj && obj.rate && currency) {
                            if (accountInfo.hasOwnProperty('currency_name')) {
                                return `${Number(obj.rate).toFixed(4)} ${currency ? currency : ''}`;
                            }
                        } else {
                            return '—';
                        }
                    } else {
                        return '—';
                    }
                }),
                ...(!isMobile ? {flexGrow: 1} : {}),
                width: 110,
                align: 'center'
            });
    }

    
    if (isTest) {
        for (const column of testColumns) {
            columns.push(column);
        }
    }

    const filteredColumns = columns.filter(column => ('hideOnService' in column && column?.hideOnService !== service) || !('hideOnService' in column));
    
    const renderedColumns = filteredColumns.map(renderColumn);

    return (
        <>
            <CustomizableTable
                data={data.filter(item => !!item)}
                isChangedColumns={false}
                isPaginated={true}
                {...{
                    rtl,
                    count,
                    page,
                    per_page,
                    loading,
                    // columns,
                    savedPerPage,
                    onChangePage,
                    onChangePerPage,
                    renderedColumns,
                    ...props
                }}
            />
        </>
    );
};

const SecondStagedCell = styled.div`
    margin-top: -5px;
    
    &&& .table-two-staged__cell-second {
        font-size: 12px;
        margin-top: -3px;
    }
`;