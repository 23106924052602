import React from 'react';
import {Pagination, Schema} from 'rsuite';
import {debounce} from '../../../utils';
import {useIntl} from "react-intl";
import {DEFAULT_PER_PAGE_DIALER} from "../../../const";
import {useWindowWidth} from "../../../hooks";
import * as S from './styled';
import m from "../../../definedMessages"

const {NumberType} = Schema.Types;

const perPageModel = Schema.Model({
    per_page: NumberType()
        .isInteger('Integer')
        .isRequired('Required')
        .min(1, 'Minimum 1')
        .max(10000, 'Maximum 10000'),
});

export default (
    {
        total = 0,
        per_page = DEFAULT_PER_PAGE_DIALER,
        activePage = 1,
        limitWidth = 475,
        savedPerPage,
        hidePerPage,
        hideTotalCount,
        onChangePage = () => {},
        onChangePerPage,
        moreCount = false
    }
) => {

    const {formatMessage} = useIntl();
    const resizedWidth = useWindowWidth();

    return (
        <S.PaginationContainer>
            <Pagination 
                size="xs"
                pages={Math.ceil(total / per_page)}
                activePage={activePage || 1}
                total={total || 0}
                // onChangePage={onChangePage}
                maxButtons={3}
                onSelect={onChangePage}
                boundaryLinks={resizedWidth > (limitWidth + 250)}
                ellipsis={resizedWidth > (limitWidth + 250)}
                first={resizedWidth < (limitWidth + 250)}
                last={resizedWidth < (limitWidth + 250)}
                next
                prev
            />
            
            {!hidePerPage &&

                <S.PaginationForm
                    onChange={debounce(({per_page = 0}) => {
                        return per_page ? onChangePerPage(+per_page) : null
                    }, 500)}
                    model={perPageModel}
                >
                    <S.PaginationFormGroup>

                        <S.PaginationFormField
                            name="per_page"
                            errorPlacement="topEnd"
                            placeholder={resizedWidth > (limitWidth - 50) ? formatMessage(m.rowsPerPage) + ':' : ''}
                        />

                        <S.PaginationFormGroupCount>
                            {savedPerPage || per_page}
                        </S.PaginationFormGroupCount>

                    </S.PaginationFormGroup>
                </S.PaginationForm>
            }

            {!hideTotalCount &&
                <S.PaginationTotal>
                    {(resizedWidth > (limitWidth + 75)) && formatMessage(m.total) + ':'} {moreCount ? <S.BoldText>></S.BoldText> : <></>}{total}
                </S.PaginationTotal>
            }
        </S.PaginationContainer>
    );
}