import React, {useState, useEffect} from "react";
import {List, Panel} from "rsuite";
import styled from "styled-components";
import {useWindowWidth} from "hooks";

import {Spacer} from "../../../components/base/Spacer";
import {CustomModal} from "../../../components/base";
import {ButtonContent} from "../../../components/base/BaseButton";
import { capitalizeString } from "utils";


const reasons = {
    undefined_sde: 'subdestination is undefined',
    number_exists: 'already in another group',
    number_exists_same_group: 'already in same group',
    no_range: 'range is not created',
    duplicate_number: 'number is duplicated',
};


export default (
    {
        data,
        maxWidth = 600,
        onClose,
        isSupplier,
        ...props
    }
) => {

    const resizedWidth = useWindowWidth();

    const updatedNumbers = isSupplier ? data?.supplier_prefixes_updated : data?.numbers_updated;
    const priceUpdateSdeList = isSupplier ? data?.supplier_price_update_sde_list : data?.account_price_update_sde_list;
    const currentPrices = isSupplier ? data?.supplier_prices : data?.account_prices;

    const uploadSubjectTitle = !isSupplier ? "numbers" : "prefixes";
    const title = `Upload ${uploadSubjectTitle} result`;

    return (
        <>
            <StyledModal
                showFooter={true}
                title={title}
                width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
                customFooter={() => <ButtonContent onClick={onClose}>Close</ButtonContent>}
                onClose={onClose}
                {...props}
            >

                <p>Prices in file - <b>{currentPrices?.in_file}</b></p>
                <p>Prices updated - <b>{currentPrices?.updated}</b></p>
                <p>Prices inserted - <b>{currentPrices?.inserted}</b></p>
                <p>Prices closed - <b>{currentPrices?.closed}</b></p>

                {priceUpdateSdeList && priceUpdateSdeList.length
                    ? <>
                        <hr/>
                        <p style={{color: 'var(--color-primary)'}}>Updated subdestinations list:</p>
                        <StyledList size='sm'>
                            {priceUpdateSdeList
                                .map(item => (
                                    <List.Item key={item.sde_key}>

                                        <StyledAccountInfo>
                                            <span className="account-name">{item.subdestination_name}</span> 
                                            <StyledPrice><del>{item.rate_old} {item.currency_name}</del><ins>{item.rate_new}</ins> {item.currency_name}</StyledPrice>
                                        </StyledAccountInfo>
                                    </List.Item>
                                ))
                            }
                        </StyledList>
                    </>
                    : null
                }
                <hr />
                <p>{capitalizeString(uploadSubjectTitle)} updated - <b>{updatedNumbers}</b></p>
            </StyledModal>
        </>
    )
};


const StyledModal = styled(CustomModal)`
    .rs-modal-body {
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const StyledList = styled(List)`
    margin-top: 10px;
    box-shadow: none !important;
    
    @media (max-width: 767px) {
        display: none !important;
    }
`;

const StyledAccountInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .account-name {
        color: var(--color-dark);
        display: block;
        padding-right: 10px;
    }
`;

const StyledPrice = styled.div`
    del {
        color: var(--color-disabled);
        text-decoration: line-through;
        margin-right: 10px;
    }

    ins {
        color: var(--color-primary);
        font-size: 16px;
        text-decoration: none;
    }
`;

const StyledPanel = styled(Panel)`
    && {

        .rs-panel-heading {
            font-size: 14px;

            &:before {
                top: 0;
            }
        }
    }

    .rs-panel-heading, 
    .rs-panel-body {
        padding: 0;
    }
    
`;