import React, {useState, useMemo} from "react";
import {Form, FormControl, SelectPicker, CheckPicker, Panel, DateRangePicker, Checkbox} from "rsuite";
import {debounce} from "../../utils";
import styled from "styled-components";


export default (
    {
        filter,
        onChange,
        worldzoneList,
        destinationList,
        subdestinationList,
        supplierList,
        adminUsers,

        filterMain,
    }
) => {


    const adminUsersUpdated = useMemo(() => {
        return [
            {id: 'self_allocation', name: 'Self-Allocation'},
            ...adminUsers,
        ]
    }, [adminUsers]);


    return (
        <FormWrapper
            shaded
            header="Mass revoke params"
        >
            <Form
                formDefaultValue={filter}
                onChange={debounce((value) => {
                    onChange(value);
                }, 1000)}
            >

                <FormControl
                    accepter={SelectPicker}
                    data={worldzoneList}
                    name={'wz_key'}
                    placeholder={'Zone'}
                    valueKey={'wz_key'}
                    labelKey={'name'}
                />

                <FormControl
                    accepter={SelectPicker}
                    data={destinationList}
                    name={'de_key'}
                    placeholder={'Destination'}
                    valueKey={'de_key'}
                    labelKey={'name'}
                />

                <FormControl
                    accepter={SelectPicker}
                    data={subdestinationList}
                    name={'sde_key'}
                    placeholder={'Subdestination'}
                    valueKey={'sde_key'}
                    labelKey={'name'}
                />

                {filterMain.sp_key === 8 &&
                <FormControl
                    accepter={SelectPicker}
                    data={supplierList}
                    name={'supplier_id'}
                    placeholder={'Suppliers'}
                    valueKey={'supplier_key'}
                    labelKey={'supplier_name'}
                />
                }

                <FormControl
                    showOneCalendar
                    accepter={DateRangePicker}
                    name={'date'}
                    placeholder={'Date'}
                />

                <FormControl
                    accepter={CheckPicker}
                    data={adminUsersUpdated}
                    name={'allocated_by'}
                    placeholder={'Allocated by'}
                    valueKey={'id'}
                    labelKey={'name'}
                    renderMenuItem={(label, item) => {
                        if (item.id !== "self_allocation") {
                            return label
                        }
                        return <span style={{color: '#000000'}}>{label}</span>
                      }}
                />

            </Form>
        </FormWrapper>
    )
};


const FormWrapper = styled(Panel)`
    margin-left: 30px;
    margin-top: 10px;
    padding-bottom: 30px;

    .rs-form-control-wrapper {
        min-width: 224px;
        
        & + .rs-form-control-wrapper {
            margin-top: 15px;
        }
    }
    
    .rs-panel-heading {
        color: var(--color-primary);
    }
`;


const CustomCheckbox = ({children, defaultValue = false, onChange, ...props}) => {
    const [_value, _setValue] = useState(defaultValue);

    return <Checkbox
        value={_value}
        checked={_value}
        onChange={() => {
            _setValue(!_value);
            onChange(!_value);
        }}
        {...props}
    >
        {children}
    </Checkbox>
};