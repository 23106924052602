export function  AccountCreate (form) {
    this.active = true;
    this.description = 'Description is required? Realy??? You dont read task!';
    this.is_dailer = true;
    this.is_supplier = false;
    this.permission_list = [];

    form.allocate_number && this.permission_list.push('allocate_number');
    form.allocate_pattern && this.permission_list.push('allocate_pattern');
    this.trunk_notificaton = form.trunk_notificaton;

    this.name  = form.name;
    this.cur_key = form.cur_key;
    this.pt_key = form.pt_key || undefined;
    this.hf_key = form.hf_key || null;
    this.cntr_code = form.cntr_code;
    this.call_duration_max = form.call_duration_max ? Number.parseInt(form.call_duration_max) : 2400;
    this.service = form.service;
    this.user_name = form.user_name;
    this.user_email = form.user_email;
    this.user_messenger = form.user_messenger;
    this.user_telegram_username = form.telegram_username;
    this.supplier_credit_limit = form.supplier_credit_limit ? Number.parseInt(form.supplier_credit_limit) : 0;
    this.report_number_change = form.report_number_change;
    this.block_traffic_credit_excess = form.block_traffic_credit_excess;
    // this.account_manager_id = form.account_manager_id;
    // this.CONTACT_LIST  = form.contacts;
}
