import React, {useRef} from "react";
import { Input, Form, FormControl, FlexboxGrid, Schema } from "rsuite";
import { ButtonPrimary } from "components/base/BaseButton";
import styled from "styled-components";
import m from "definedMessages";

const {StringType, NumberType} = Schema.Types;


const SettingsTopPanel = ({
    accountData,
    currentHost,

    templateKey,
    formValue,
    setFormValue,

    update,
    createHostFunc,
    modifyHostFunc,
    formatMessage
}) => {
    
    const domainRef = useRef(null);
    const domainModel = Schema.Model({
        host: StringType()
            .isRequired(formatMessage(m.required))
            .maxLength(255, "255 is maximum"),
        template_key: NumberType()
            .isRequired("Please select a template")
    })

    const onModifyHost = () => {
        if (accountData.id && domainRef.current.check()) {
            if ( currentHost) {
                modifyHostFunc(accountData.id, currentHost.host_key, formValue.host, templateKey)
                    .finally(() => {
                        update();
                    });
            } else {
                createHostFunc(accountData.id, formValue.host, templateKey)
                    .finally(() => {
                        update();
                    });
            }
        }
    };

    return <TopPanelContainer>
        <TopPanelDomainFormContainer>
            <StyledDomainForm
                ref={domainRef}
                model={domainModel}
                formValue={{
                    ...formValue,
                    template_key: templateKey
                }}
                onChange={(value) => {
                    setFormValue(value);
                }}
            >
                <FormControl
                    className="input-domain"
                    name="host"
                />
                <div className="hidden-field-wrapper">
                    <FormControl
                        className="hidden-field"
                        name="template_key"
                        errorPlacement="topStart"
                        value={templateKey}
                    />
                </div>
            </StyledDomainForm>
            <ButtonPrimary
                className="save-button"
                onClick={() => {
                    onModifyHost();
                }}
            >
                {formatMessage(m.saveConfiguration)}
            </ButtonPrimary>
        </TopPanelDomainFormContainer>
    </TopPanelContainer>
};

export default SettingsTopPanel;

const TopPanelContainer = styled(FlexboxGrid)`
    && {
        display: flex;
        margin-bottom: 20px;
        height: 120px;
    }
`;

const TopPanelDomainFormContainer = styled(FlexboxGrid.Item)`
    && {
        display: flex;
        flex-direction: column;
    }

    && .save-button {
        margin-top: 40px;
        width: 280px;
    }
`;

const StyledDomainForm = styled(Form)`
    && {
        margin-right: 20px;
    }

    && input.input-domain {
        width: 340px;
    }
    && div.hidden-field-wrapper .rs-form-control-wrapper {
        top: 100px;
    }
    && input.hidden-field {
        display: none;
    }
`;