import React, { useRef, useMemo, useState, useEffect } from "react";
import ActionLogsFilter from "./ActionLogsFilter";
import PanelLayout from "../../components/base/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import ActionLogsTable from "./ActionLogsTable";
import ModalActionParams from "./ModalActionParams";
import ModalActionError from "./ModalActionError";
import axios from "axios";
import { APP_TYPE_CLIENT, APP_TYPE_ADMIN } from "const";

export default ({
    data,
    count,
    perPage,
    page,
    appType, 
    service,
    loading,
    managerList,
    referencesFetched,
    accountsFetched,
    actionList,
    userList,
    accounts,
    getReferences,
    getLogsList: getLogsList_,
    getAccounts,
}) => {

    const cancelToken = useRef(axios.CancelToken.source());

    const today = new Date();
    const [filter, onChangeFilter] = useState({
        site_list: [APP_TYPE_ADMIN+service],
        service: "voice",
        range: [today, today]
    });
    const [showParamsModal, setShowParamsModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [currentLogItem, setCurrentLogItem] = useState(null);

    useEffect(() => {
        getReferences(appType, service);
        getAccounts({one_of_joined: true, show_suppliers: true});
    }, [service]);

    const unitedFilter =  useMemo(() => {
        const clonedFilter = {...filter};

        const unitedVoiceSite = APP_TYPE_ADMIN+true;
        const siteListHasUnitedVoice = clonedFilter.site_list.includes(unitedVoiceSite);
        const siteListHasUnitedSMS = clonedFilter.site_list.includes(APP_TYPE_ADMIN);
        const siteListOnlyAdmin = !clonedFilter.site_list.includes(APP_TYPE_CLIENT);
        const siteListHasBothServices = siteListHasUnitedVoice && siteListHasUnitedSMS;
      
        if (clonedFilter?.service) {
            delete clonedFilter.service;
        }
        
        const unitedFilterValue = {
            ...clonedFilter,
            site_list: [...new Set(clonedFilter.site_list.map((site) => {
                if (site === APP_TYPE_ADMIN + true) {
                    return APP_TYPE_ADMIN;
                }

                return site;
            }))],
            ...(clonedFilter?.site_list.length && siteListOnlyAdmin && !siteListHasBothServices ? {
                service: siteListHasUnitedVoice ? "voice" : "sms"
            } : {})
        };

        return unitedFilterValue;
    }, [filter, service])

    useEffect(() => {
        getLogsList(unitedFilter, 1, perPage);
    }, [filter]);

    const getLogsList = (filter, page, perPage) => {
        if (typeof cancelToken.current !== typeof undefined) {
            cancelToken.current.cancel("Operation canceled due to new request.");
        }
        cancelToken.current = axios.CancelToken.source();
        getLogsList_({...filter, cancelToken: cancelToken.current}, page, perPage);
    }

    const getItems = (page, perPage) => {
        getLogsList(unitedFilter, page, perPage);
    };

    const toggleParamsModal = (show, data) => {
        setShowParamsModal(show);
        if (data) {
            setCurrentLogItem({...data});
        }
    };

    const toggleErrorModal = (show, data) => {
        setShowErrorModal(show);
        if (data) {
            setCurrentLogItem({...data});
        }
    };

    return (
        <PanelLayout>
            <PageHeader title={"Action Logs"}/>
            <ActionLogsFilter
                defaultFilter={filter}
                filterValue={filter}
                {...{
                    onChangeFilter,
                    userList,
                    actionList,
                    accounts,
                    service
                }}
            />
            <ActionLogsTable
                loading={loading || !referencesFetched || !accountsFetched}
                {...{
                    data,
                    count,
                    perPage,
                    page,
                    service,
                    toggleParamsModal,
                    toggleErrorModal
                }}
                getItems={getItems}
            />
            <ModalActionParams
                onClose={() => toggleParamsModal(false)}
                title={"Action Parameters"}
                show={showParamsModal}
                currentLogItem={currentLogItem}
            />
            <ModalActionError
                onClose={() => toggleErrorModal(false)}
                title={"Error result"}
                show={showErrorModal}
                currentLogItem={currentLogItem}
            />
        </PanelLayout>
    )
};