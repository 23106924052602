import React from 'react';
import styled, {css} from 'styled-components';
import {Button, Form} from 'rsuite/es';
import TextareaAutosize from 'react-textarea-autosize';
import FormGroup from 'rsuite/es/FormGroup';


import {Table} from 'rsuite';
import ControlLabel from 'rsuite/es/ControlLabel';
import SettingsEditModal from 'pages/Settings/SettingsEditModal';
import SettingsJsonEditor from 'pages/Settings/SettingsJsonEditor';


const {Cell} = Table;

export const FieldTextArea = ({name, label, type, data, ...props}) => (
    <Group>
        {label &&
        <LabelWrapper>
            <Label>{label}</Label>
        </LabelWrapper>
        }
        <div className="rs-form-control-wrapper">
            <FieldTextAreaAutoResize
                className="rs-input"
                name={name}
                {...props}
            />
        </div>
    </Group>
);

// Responsive
export const StyledForm = styled(Form)`
  & .react-json-view .variable-value {
    //background-color: magenta !important;
     width: ${props => {
    //     console.log('CSS:props', props);
    //     return `${props.windowWidth}px`
    }}
`;
export const StyledCompare = styled.div`
  margin-top: 10px;
  ${props => !props.isMobile ? css`
    display: flex;
    justify-content: space-between;
  ` : ''}
  
  & > div {
    display: ${props => props.isMobile ? 'block' : 'inline-block'}
  }
  // & {
     
  // }

`;//${props => (!props.isMobile ? scss`display: flex;justify-content: space-between; flex-direction: row` : '')
export const FieldTextAreaAutoResize = styled(TextareaAutosize)`
    &&& {
        min-width: 100%;
        min-height: 100% !important;
        width: ${props => {
            return props.width ? `${(props.width * 0.35 - 40)}px` : '100%'
        }};
        @media (max-width: 768px) {
            width: ${props => {
                return props.width ? `${(props.width - 40)}px` : '100%'
            }};
        }
        overflow: hidden;
        resize: none;
    } 
    &.rs-input {
      max-height: 100%;
    }
`;

export const Group = styled(FormGroup)`
    margin-bottom: 15px;
    
    @media (min-width: 768px) {
        align-items: center;
        display: flex;
    }
`;

export const LabelWrapper = styled.div`
    flex-shrink: 0;
    width: 160px;
    
    ${props => props.vAlign &&
    css`
            align-self: ${props.vAlign};
        `
}
    ${props => props.top &&
    css`
            margin-top: ${props.top}px;
        `
}
`;

export const Label = styled(ControlLabel)`
    flex-shrink: 0;
    font-weight: 600;
    padding-right: 20px;
    text-align: left;
    width: auto;

    .rtl & {
        padding-left: 20px;
        padding-right: 0;
        text-align: right;
    }
    
    && {
        
        @media (min-width: 768px) {
            margin-bottom: 0;
        }
    }
`;
export const StyledEditButton = styled(Button)`
  width: 100%;
    
  &.rs-btn-disabled {
    background-color: #cdcdcd !important;
  }
`;
export const StyledButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  
  &.continue {
    margin-top: 0;
    background-color: var(--primary);
    color: white;
  }
`;
export const StyledKeyValue = styled.div`
  & div {
    display: inline-block;
    vertical-align: top;
  }
`;
export const StyledValue = styled.div`
  margin: 0 10px 20px 10px;
`;
export const StyledLabel = styled.div`
    margin: 0 0 20px 0;
    font-weight: 800;
`;

export const CustomCell = styled(Cell)`
  .duplicates_badge {
    position: absolute;
    top: 14px;
    left: 5px;
  }
`;
