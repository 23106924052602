import {LIVE_CALLS as PREFIX} from '../const/';

const initialState = {
    loading: false,
    live_calls_list: [],
    loadingItem: false,
};


const handlers = {
    ['SET_ITEMS_'+PREFIX]: (state,{payload:live_calls_list}) =>({...state, live_calls_list, loading: false}),
    ['SET_LOADING_'+PREFIX]: (state,{payload}) =>({...state,loading: payload}),
    ['SET_LOADING_ITEM_'+PREFIX]: (state,{payload}) =>({...state, loadingItem: payload}),
    ['REMOVE_ITEM_'+PREFIX]: (state,{payload:id}) =>({...state, live_calls_list: state.live_calls_list.filter( x => x.id !== id),   loadingItem: false}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};
