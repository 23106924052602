import {connect} from "react-redux";
import {
    getAuthNumbersList
} from "actions/client/auth_numbers";

import {servicePlanNoTrunkFilter} from "utils/filters";

import AuthNumbersPage from "./AuthNumbersPage";


const mapState = ({auth, client_auth_numbers, references}) => {
    return {
        data: client_auth_numbers.items,
        count: client_auth_numbers.count,
        loading: client_auth_numbers.loading,
        page: client_auth_numbers.page,
        perPage: client_auth_numbers.per_page,

        account: auth.authInfo,
        permissions: auth.permissions,

        service_plan_list: references.service_plan_list.filter(servicePlanNoTrunkFilter),

        currency_list: references.currency_list,
    
        worldzoneList: references.worldzone_list,
        destinationList: references.destination_list,
        subdestinationList: references.subdestination_list,
    }
};


export default connect(mapState, {
    getAuthNumbersList
})(AuthNumbersPage);