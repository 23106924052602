import React from "react";
import Modal from "components/Modal";
import AccountForm from "./Account/Tabs/General/AccountForm";
import styled from "styled-components";
import { withRouter } from "react-router";

import m from "definedMessages";

const CreateNewAccountModal = ({
    show,
    history,
    location,
    accountId,
    accountDefaultData,
    paymentTermsList,
    createSubAccount,

    onCloseModal,

    formatMessage
}) => {
    return <Modal 
        width={600}
        show={show}
        title={formatMessage(m.createSubaccount)}
        onClose={() => {
            onCloseModal();
        }}
        successText={formatMessage(m.ok)}
        cancelText={formatMessage(m.cancel)}
    >
        <AccountForm
            newAccount
            disabled={false}
            update={(subAccId) => {
                if (subAccId) {
                    history.push({
                        ...location,
                        pathname: `/sub-accounts/view/${subAccId}`
                    });
                }
            }}
            accountId={accountId}
            formDefaultValue={accountDefaultData}
            paymentTermsList={paymentTermsList}

            cancel={() => {
                onCloseModal();
            }}

            createSubAccount={createSubAccount}
        />
    </Modal>
};

export default withRouter(CreateNewAccountModal);


