import {MASS_REVOKE as PREFIX} from '../const';
import {
    REVOKE_MASS_LIST_API,
    SMS_REVOKE_MASS_LIST_API
} from '../const/apiMethods';

import {createAction} from './defaults';
import {api} from "../api/loginRoutes";
import moment from "moment";

const setRevokeList = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);


export const getRevokeList = (filter, service, filterSecondary, cancelToken) => (dispatch) => {

    const params = {
        sp_key: filter.sp_key,
        filter: {
            ...filterSecondary,
            start_date: filterSecondary?.date?.[0]
                ? moment(filterSecondary.date[0]).format( moment.defaultFormatUtc )
                : undefined,
            end_date: filterSecondary?.date?.[1]
                ? moment(filterSecondary.date[1].setHours(23, 59, 59, 999)).format( moment.defaultFormatUtc )
                : undefined,
            date: undefined,
            account_name: filter.account_name || undefined,
            supplier_id: filterSecondary.supplier_id && filter.sp_key === 8
                ? filterSecondary.supplier_id
                : undefined,
        },
        target: {
            account_id_list: filter.acc_key ? [filter.acc_key] : undefined,
            account_manager_id: filter.am_key || undefined,
        },
        cancelToken: cancelToken,
    }

    if (filterSecondary?.allocated_by) {
        const managerList = filterSecondary.allocated_by.filter(item => item !== 'self_allocation');
        params.filter.dialer = !!filterSecondary.allocated_by.find(item => item === 'self_allocation') || null;
        params.target.account_user_manager_id_list = managerList.length ? managerList : undefined;
        delete params.filter.allocated_by;
    }

    const method = service ? REVOKE_MASS_LIST_API : SMS_REVOKE_MASS_LIST_API;

    dispatch( setLoading(true) );
    api(method, params)
        .then((r) => {
            if (r?.cancelled) {
                return;
            }
            
            if (r === undefined || !r.revoke_mass_list) {
                dispatch( setLoading(false) )
                return;
            }

            dispatch( setRevokeList({
                list: r.revoke_mass_list,
            }) );
            dispatch( setLoading(false) )
        });
};