import React, {useEffect, useMemo} from "react";
import {Whisper, Tooltip} from "rsuite";
import styled from "styled-components";
import Table from "../../../../components/Table";


export default ({
    page, 
    count, 
    per_page,
    getItems,

    data,
    filters,
    loading,
    setSelected,
    clearSelectedData,
    setClearSelectedData,
    ...props
}) => {
    
    const columns = useMemo(() => [
        {
            dataKey: "datetime",
            label: "SMS Date",
            flexGrow: 1,
            minWidth: 170,
            formatData: 'datetime',
        },
        {
            dataKey: "postback_datetime",
            label: "Postback Date",
            flexGrow: 1,
            minWidth: 170,
            formatData: 'datetime',
        },
        {
            dataKey: "senderid",
            label: "Sender ID",
            flexGrow: 2,
            minWidth: 220,
        },
        {
            dataKey: "phone",
            label: "Phone",
            flexGrow: 1,
            minWidth: 140,
        },
        {
            dataKey: "dialer_rate",
            label: "Payout",
            width: 80,
        },
        {
            dataKey: "message",
            label: "Text",
            value: ({message}) => {
                const data = message;
                return (
                    <Whisper
                        enterable
                        placement="auto"
                        speaker={
                            <Tooltip>{data}</Tooltip>
                        }
                    >
                        <span>{data}</span>
                    </Whisper>
                )
            },
            flexGrow: 3,
            minWidth: 400,
        },
        {
            dataKey: "status",
            label: "Status",
            flexGrow: 1,
            minWidth: 160,
        },
        {
            dataKey: "error",
            label: "Error",
            flexGrow: 1,
            minWidth: 160,
        },
        {
            dataKey: "url",
            label: "SMS URL",
            value: ({url}) => {
                const data = url;
                return (
                    <Whisper
                        enterable
                        placement="auto"
                        speaker={
                            <Tooltip>{data}</Tooltip>
                        }
                    >
                        <span>{data}</span>
                    </Whisper>
                )
            },
            flexGrow: 2,
            minWidth: 380,
        },
        {
            dataKey: "http_status",
            label: "HTTP Status",
            align: "center",
            width: 90,
        },
        {
            dataKey: "response_body",
            label: "Response body",
            value: ({response_body}) => {
                const data = response_body;
                return (
                    <Whisper
                        enterable
                        placement="auto"
                        speaker={
                            <Tooltip>{data}</Tooltip>
                        }
                    >
                        <span>{data}</span>
                    </Whisper>
                )
            },
            flexGrow: 3,
            minWidth: 300,
            // width: 300
        },
        {
            dataKey: "mesage_id",
            label: "SMS ID",
            // value: ({mesage_id}) => {
            //     const data = mesage_id;
            //     return (
            //         <Whisper
            //             enterable
            //             placement="bottom"
            //             speaker={
            //                 <Tooltip>{data}</Tooltip>
            //             }
            //         >
            //             <span>{data}</span>
            //         </Whisper>
            //     )
            // },
            // flexGrow: 1,
            // minWidth: 160,
            width: 285
        },
    ], [data]);

    useEffect(() => {
        if (clearSelectedData) {
            setClearSelectedData(false);
        }
    }, [clearSelectedData]);

    const rowKey = "key";

    return (
        <>
            <StyledTable
                virtualized
                data={data}
                columns={columns}
                loading={loading}
                rowHeight={40}
                row_key={rowKey}

                isselected
                isSelectedAll
                ispagination
                
                page={page}
                count={count}
                per_page={per_page}
                getItems={getItems}

                setSelected={setSelected}
                shouldUpdateScroll={false}
                clearSelected={clearSelectedData}
            />
            
        </>
    );
};


const StyledActionCell = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
`;

// const StyledTable = styled(TableEditCellsNew)`
const StyledTable = styled(Table)`
    &&.rs-table.rs-table-hover {
        margin-top: 0;
        margin-bottom: 0;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons {
        float: right;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons > .rs-btn {
        width: 28px;
        height: 28px;
        padding: 4px 8px;
    }
    
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons > .rs-btn {
        margin-right: 5px;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons .rs-btn-lightblue {
        color: var(--color-light);
        background: var(--color-info);
    }
    
    .rs-error-message.rs-error-message-show {
    
        position: absolute;
        color: red;
        top: -4px;
        font-size: 10px;
        border-radius: 10px;
        bottom: auto !important;
        padding: 0 4px;
        right: 6px !important;
    }
    
    .rs-error-message.rs-error-message-show .rs-error-message-arrow {
        display: none;
    }
    .rs-error-message.rs-error-message-show .rs-error-message-inner {
        white-space: nowrap;
    }

    .rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before {
        opacity: 0.7;
    }
`;