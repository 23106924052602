import React from "react";
import { Form, Alert } from "rsuite";
import { CheckboxControl } from "components/Form/CheckboxControl";
import { getAccount } from "actions";


export default ({
    accountId,
    getAccount,
    defaultValue = {},
    settingValue,
    setSettingValue,
    modifyPaymentSettings
}) => {
    const handleChange = async ({...value} = {}) => {
        const params = {
            target: {
                account_id: accountId
            },
            ...(Object.keys(value).length ? {options: {...value}} : {}),
        };

        setSettingValue(value);
        const response = await modifyPaymentSettings(params);
        // const responseAccount = await modifyAccountRequest()

        if (response === undefined) {
            Alert.error('Something went wrong', 15000);
        }

        Alert.success('Payment settings has successfully changed!', 5000);
    };

    if (defaultValue.pause_payouts === undefined) {
        return null;
    }

    return (
        <Form 
            layout="inline"
            defaultFormValue={defaultValue}
            onChange={handleChange}
            formValue={settingValue}
        >
            <CheckboxControl
                name="pause_payouts"
                defaultChecked={settingValue.pause_payouts || defaultValue.pause_payouts || false}
            >
                Pause all payments
            </CheckboxControl>

            <CheckboxControl
                name="auto_accept_our_stat"
                defaultChecked={settingValue.auto_accept_our_stat || defaultValue.auto_accept_our_stat || false}
            >
                Autoaccept our stat as invoice
            </CheckboxControl>

            <CheckboxControl
                name="accept_hawala"
                defaultChecked={settingValue.accept_hawala || defaultValue.accept_hawala || false}
            >
                Allow hawala payment request
            </CheckboxControl>
        </Form>
    )
}