import {defineMessages} from "react-intl";

export default defineMessages({
    pendingAmount: {
        id: "balanceInfo.pendingAmount",
        defaultMessage: "Pending amount",
        description: 'Balance info: Pending amount'
    },
    currentBalance: {
        id: "balanceInfo.currentBalance",
        defaultMessage: "Current balance",
        description: 'Balance info: Current balance'
    },
    readyForPayment: {
        id: "balanceInfo.readyForPayment",
        defaultMessage: "Ready for payment",
        description: 'Balance info: Ready for payment'
    },
    paymentTerms: {
        id: "balanceInfo.paymentTerms",
        defaultMessage: "Payment terms",
        description: 'Balance info: Payment terms'
    },
    traffic: {
        id: "balanceInfo.traffic",
        defaultMessage: "Traffic",
        description: 'Balance info: Traffic'
    },
    lastTraffic: {
        id: "balanceInfo.lastTraffic",
        defaultMessage: "Traffic for last 1/3/7 days",
        description: 'Balance info: Traffic for last 1/3/7 days'
    },
    allocatedNumbers: {
        id: "balanceInfo.allocatedNumbers",
        defaultMessage: "Allocated numbers",
        description: 'Balance info: Allocated numbers'
    },
    ranges: {
        id: "balanceInfo.ranges",
        defaultMessage: "Ranges",
        description: 'Balance info: Ranges'
    },
})