import {
    CLI_DIALER as PREFIX
} from '../../const';

const initialState = {
    items: [],
    count: 0,
    loading: false
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {cli_lookup}}) => ({
        ...state,
        items: cli_lookup,
        count: cli_lookup.length,
        loading: false
    }),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}