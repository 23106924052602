import {connect} from "react-redux";
import {
    getPaymentList,
    getPaymentRequestList,
    getTrafficReportsList,
    requestPayment,
    rejectPaymentRequest,
    approvePaymentRequest,
    modifyPaymentSettings,
} from "../../../actions/payments";
import {
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    getPaymentDetailsCryptoList,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails
} from "../../../actions/paymentDetails";
import TabPaymentManagement from "./TabPaymentManagement";
import {getDropdownAccounts} from "../../../actions/dropdown_accounts";
import {showReasonHandler} from '../../../actions/reason_handler';
import {getAccount} from '../../../actions'

const mapStateToProps = ({auth, payments, suppliers, paymentDetails, accounts, dropdown_accounts, references}) => ({
    service: auth.service,

    currentSupplier: suppliers.currentSupplier,

    permissions: auth.permissions,
    paymentItems: payments.paymentItems,
    paymentItemsLoading: payments.paymentItemsLoading,

    paymentRequestItems: payments.paymentRequestItems,
    paymentRequestLoading: payments.paymentRequestLoading,
    
    paymentTrafficReportsItems: payments.paymentTrafficReportsItems,
    paymentTrafficReportsLoading: payments.paymentTrafficReportsLoading,
    
    cryptoList: paymentDetails.crypto_items,
    paymentDetailsItems: paymentDetails.items,
    paymentDetailsLoadinig: paymentDetails.loading,
    
    accountJoinList: accounts.accountJoinList,
    accountInfo: accounts.accountView,
    accounts: dropdown_accounts.items,
    
    organizationTypeList: references.organization_type_list,
    countryList: references.country_list,
    paymentMethodList: references.payment_method_list,
    currencyList: references.currency_list,
});

export default connect(mapStateToProps, {
    getAccount,
    getPaymentList,
    getPaymentRequestList,
    getTrafficReportsList,
    requestPayment,
    rejectPaymentRequest,
    approvePaymentRequest,
    modifyPaymentSettings,
    
    showReasonHandler,

    getPaymentDetailsList,
    getPaymentDetailsSingle,
    getPaymentDetailsCryptoList,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails,

    getAccounts: getDropdownAccounts
})(TabPaymentManagement);