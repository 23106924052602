import React, {useState, useEffect, useRef} from "react";
import {CheckTree, FormControl, Input, Form, CheckPicker, Panel, Schema} from "rsuite";
import styled from "styled-components";
import {useDebounce, useWindowWidth} from "hooks";

import {Spacer} from "../../components/base/Spacer";
import {CustomModal} from "../../components/base";


const {StringType} = Schema.Types;

const formModel = Schema.Model({
    str: StringType().maxLength(128, 'The maximum is only 128 characters.'),
});


export default (
    {
        show,
        p_key,
        sde_key,
        maxWidth = 600,
        accountManagerList,
        onClose,
        dialers,
        managers,
        getDialers,
        getManagers,
        onSubmit,
        ...props
    }
) => {

    const formRef = useRef(null);
    const resizedWidth = useWindowWidth();
    const [filter, setFilter] = useState({});
    const [chosenDialers, setChosenDialers] = useState([]);
    
    const deferredFilter = useDebounce(filter, 500);

    
    useEffect(() => {
        if (show) {
            const dialerFilterParams = {p_key, sde_key};
            
            getDialers(dialerFilterParams);
            getManagers();
        }
    }, [show]);


    useEffect(() => {
        handleFilterAccounts(deferredFilter);
    }, [deferredFilter]);

    // fetch filtered accounts
    const handleFilterAccounts = (value) => {
        if (!formRef.current) {
            return;
        }

        if ( !formRef.current.check() ) {
            return;
        }
        const keyParams = {p_key, sde_key};

        const params = {
            ...keyParams,
            ...value,
            acc_manager_id_list: value?.acc_manager_id_list?.length 
                ? value.acc_manager_id_list 
                : null
        }

        getDialers(params);
    }


    const handleClose = () => {
        onClose && onClose();
    }

    const handleSubmit = () => {
        onSubmit && onSubmit(chosenDialers ? [...chosenDialers] : []);
        handleClose();
    }


    return (
        <CustomModal
            show={show}
            showFooter={true}
            title={'Select dialers for changing rates'}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            onClose={handleClose}
            onConfirm={handleSubmit}
            confirmButtonText="Change"
            cancelButtonText="Back"
            onExited={() => {
                setChosenDialers([]);
            }}
            {...props}
        >

            <Form
                ref={formRef}
                model={formModel}
                formValue={filter}
                onChange={setFilter}
            >

                <FormRow>

                    <FormCol>
                        <FormControl
                            name={'str'}
                            accepter={Input}
                            placeholder={'Account name / Email address'}
                            errorPlacement="topRight"
                        />
                    </FormCol>
                    
                    <FormCol>
                        <FormControl
                            accepter={CheckPicker}
                            placeholder="Account manager"
                            data={managers}
                            labelKey="name"
                            valueKey="id"
                            name="acc_manager_id_list"
                            label="Account manager"
                            searchable={false}
                            errorPlacement="topRight"
                        />
                    </FormCol>

                </FormRow>

                <Spacer size={30}/>

                <StyledPanel bordered>
                    <CheckTree
                        virtualized
                        defaultExpandAll
                        cascade={false}
                        valueKey={'id'}
                        labelKey={'name'}
                        data={dialers}
                        value={chosenDialers}
                        onChange={setChosenDialers}
                    />
                </StyledPanel>

            </Form>
            
        </CustomModal>
    )
};


const FormRow = styled.div`
    margin-bottom: -20px;
        
    @media (min-width: 600px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const FormCol = styled.div`
    flex: 0 0 auto;
    position: relative;
    width: 100%;

    @media (min-width: 768px) {
        padding: 0 15px 20px;
        width: 50%;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
`;

export const StyledPanel = styled(Panel)`
    height: 338px;

    .rs-panel-body {
        padding: 0;
        margin-bottom: -24px;
    }

    && .rs-check-item {
        width: 100%;
    }
`;