import React from "react";
import {SelectPicker} from "rsuite";
import {useWindowHeight} from "../../hooks";


export default ({isMobile = true, maxHeight = 320, menuClassName, ...props}) => {

    // const windowHeight = useWindowHeight();

    let customMenuClassName = menuClassName;
    if (isMobile) {
        customMenuClassName += " picked-menu-mobile";
    }

    return (
        <SelectPicker
            isMobile={isMobile}
            menuClassName={customMenuClassName}
            maxHeight={!isMobile ? 320 : 200}
            {...props}
        />
    )
};