import React, {useState} from 'react'
import styled from 'styled-components';

import {Editor} from 'react-draft-wysiwyg';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import { EditorState} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'


const SettingsContactEditor = ({defaultValue, onChange}) => {

    const blocksFromHTML = stateFromHTML(defaultValue);
    const [contactState, onChangeContactState] = useState(defaultValue && (typeof defaultValue === 'string' || defaultValue instanceof String) ?
        EditorState.createWithContent(blocksFromHTML) : EditorState.createEmpty()
    );

    return (
        <StyledEditorContainer>
            <Editor
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                editorState={contactState}
                onEditorStateChange={(editorState) => {
                    onChangeContactState(editorState);
                    const value = stateToHTML(editorState.getCurrentContent());
                    onChange(value)
                }}
                toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true, options: ['unordered', 'ordered'] },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: false},
                    history: { inDropdown: false },
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'emoji', 'history']
                }}
            />
        </StyledEditorContainer>
    )
}

export default SettingsContactEditor;

const StyledEditorContainer = styled.div`
    .wrapper-class {
        margin-bottom: 5px;
    }
    .editor-class {
        padding: 2px 5px;
        border: 1px solid #e5e5ea;
        border-radius: 5px;
    }
    .toolbar-class .rdw-link-modal-target-option {
        display: none;
    }
`;