import {E212 as PREFIX, DEFAULT_PER_PAGE_MEDIUM} from '../const';
import {E212_SET_LOADING, E212_SET_LIST, E212_OPERATOR_SET_LIST} from '../actions/actionTypes';

const initialState = {
    list: [],
    operator_list: [],
    loading: true,
    page: 1,
    count: 0,
    per_page: +localStorage.getItem(PREFIX+'_per_page') || DEFAULT_PER_PAGE_MEDIUM
};

const handlers = {
    [E212_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [E212_SET_LIST]: (state, {payload: {list, page, perPage, count}}) => ({
        ...state,
        list,
        page, 
        per_page: perPage,
        count,
        loading: false,
    }),
    [E212_OPERATOR_SET_LIST]: (state, {payload: {operator_list}}) => ({
        ...state,
        operator_list
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}