import styled, {css} from 'styled-components';
import {APP_TYPE_CLIENT} from "../../const";

export default styled.div`
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    padding: 10px;
    min-height: 80vh;
    
    ${props => props.theme.appType === APP_TYPE_CLIENT && css`
        background-color: rgb(242, 242, 242);
        box-shadow: 0 4px 4px rgba(0,0,0,.12), 0 0 10px rgba(0,0,0,.06);
        margin-bottom: 30px;
        margin-top: 20px;
        padding: 40px;
        
        @media (max-width: 767px) {
            margin: 0;
            border-radius: 0;
            padding: 30px 15px 80px;
            width: 100%;
        }
    `}
`;