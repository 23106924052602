import React, {useMemo, useState, useEffect} from "react";
import {Form, FormControl, SelectPicker, Input, Checkbox} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../../components/base/FlexGrid";
import {debounce, servicePick} from "../../../utils";
import BillingIncrement from '../../../components/Filters/BillingIncrement';
import { usePrevious } from "hooks";


export default (
    {
        filter = {},
        service,
        supplierList,
        destinationList,
        subdestinationList,
        onChangeFilter,
    }
) => {
    const voiceService = service;
    const prevService = usePrevious(service);

    const [currentSupplierList, setCurrentSupplierList] = useState(supplierList);

    useEffect(() => {
        let clear = false;

        if (prevService !== service) {
            clear = true;
            setCurrentSupplierList([]);
        }

        if (!clear) {
            setCurrentSupplierList(supplierList);
        }
    }, [service, supplierList]);

    const supplierFilter = useMemo(() => {
        return <FormControl
            accepter={SelectPicker}
            data={currentSupplierList}
            name={servicePick(service, "supplier_key", "supplier_id")}
            placeholder="Supplier"
            valueKey={servicePick(service, "supplier_key", "supplier_id")}
            labelKey={servicePick(service, "supplier_name", "name")}
            style={{width: 224}}
        />
    }, [currentSupplierList]);
    
    return (
        <Form
            formDefaultValue={filter}
            onChange={debounce((value) => {
                onChangeFilter(value);
            }, 1000)}
        >
            <FlexGrid align="middle">
                <FlexGridItem>
                    {supplierFilter}
                </FlexGridItem>
                <FlexGridItem>
                    <FormControl
                        accepter={SelectPicker}
                        data={destinationList}
                        name={'de_key'}
                        placeholder={'Destination'}
                        valueKey="de_key"
                        labelKey="name"
                        style={{width: 224}}
                    />
                </FlexGridItem>
                <FlexGridItem>
                    <FormControl
                        accepter={SelectPicker}
                        data={subdestinationList}
                        name={'sde_key'}
                        placeholder={'Subdestination'}
                        valueKey="sde_key"
                        labelKey="name"
                        style={{width: 224}}
                    />
                </FlexGridItem>
                {voiceService && <FlexGridItem>
                    <BillingIncrement
                        cleanable={true}
                        classPrefix={"rs-form-control"}
                        style={{verticalAlign: 'none'}}
                    />
                </FlexGridItem>}
                <FlexGridItem>
                    <FormControl
                        accepter={Input}
                        name={'str'}
                        placeholder={'Group name / phone number'}
                        style={{width: 300}}
                    />
                </FlexGridItem>
                <FlexGridItem>
                    <FormControl
                        accepter={CustomCheckbox}
                        name={'actual_price'}
                    >
                        Show only groups without payout
                    </FormControl>
                </FlexGridItem>
            </FlexGrid>
        </Form>
    )
};


const CustomCheckbox = ({children, defaultValue = false, onChange, ...props}) => {
    const [_value, _setValue] = useState(defaultValue);

    return <Checkbox
        value={_value}
        checked={_value}
        onChange={() => {
            _setValue(!_value);
            onChange(!_value);
        }}
        {...props}
    >
        {children}
    </Checkbox>
};