import React from "react";
import {Badge, Dropdown, Nav} from "rsuite";
import {Link, withRouter} from "react-router-dom";
import styled from "styled-components";
import {useLocation} from "react-router";
import {createBrowserHistory} from 'history';


export default ({
    data = [],
}) => {

    return (
        <Nav appearance="subtle">
            {data.map(item => {

                const itemContent = <>{item.icon && item.icon}{item.title}</>;

                if (item.children) {
                    if (item.children.length) {
                        return (
                            <MenuDropdown 
                                item={item}
                                badge={item.badge}
                                badgeLink={item.badgeLink}
                            />
                        )
                    } else {
                        return null;
                    }
                }

                return item.onClick
                    ? <NavLink
                        key={item.key}
                        badge={item.badge}
                        badgeLink={item.badgeLink}
                        onClick={item.onClick}
                        to={item.key}
                    >
                        {itemContent}
                    </NavLink>
                    : <NavLink
                        key={item.key}
                        badge={item.badge}
                        badgeLink={item.badgeLink}
                        to={item.key}
                    >
                        {itemContent}
                    </NavLink>
            })}
        </Nav>
    )
};


const NavLink = withRouter( (
    {
        badge,
        badgeLink,
        ...props
    }
) => {

    const navItem = <Nav.Item
        componentClass={Link}
        active={props.location.pathname.indexOf(props.to) === 0}
        {...props}
    />;

    return badge
        ? <StyledBadge content={badge}>{navItem}</StyledBadge>
        : navItem
});


const MenuDropdown = (
    {
        item,
        badge,
    }
) => {

    const history = createBrowserHistory();

    const location = history.location;
    const isSubMenuActive = item.children.find(item => item.key === location.pathname);

    if (item.children && !item.children.length) {
        return null;
    }

    const dropdown = (
        <StyledDropdown
            key={item.key}
            isActive={!!isSubMenuActive}
            activeKey={location.pathname}
            title={item.title}
        >
            {item.children.map(subItem => {
                return <Dropdown.Item
                    active={location.pathname === subItem.key}
                    componentClass={Link}
                    to={subItem.key}
                    onClick={subItem.onClick || null}
                >
                    {subItem.title}
                </Dropdown.Item>
            })}
        </StyledDropdown>
    );


    return badge
        ? <StyledBadge content={badge}>{dropdown}</StyledBadge>
        : dropdown
};


const StyledDropdown = styled(Dropdown).attrs(props => ({
    className: props.isActive ? 'active' : ''
}))`
    &&&&&& {

        .rs-dropdown-menu {
            min-width: 120px;
        }

        .rs-dropdown-toggle-caret {
            left: 0;
            margin: 0 0 0 5px;
            padding: 0;
            position: inherit;
            transition: left .3s ease-out,right .3s ease-out;
        }

        .rs-dropdown-item-active {
            border-left: 3px solid var(--color-brand1);
        }
        
        .rs-dropdown-toggle {
            background-color: transparent !important;
            padding: 18px 16px;
        }

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            right: 50%;
            height: 2px;
            background-color: #fff;
            transition: left .3s ease-out,right .3s ease-out;
        }

        &.active {
            background-color: transparent;
            
            &:before {
                left: 0;
                right: 0;
            }
        }
    }
`;


const StyledBadge = styled(Badge)`
    && .rs-badge-content {
        top: 15px;
        z-index: 7;
        
        a {
            border-radius: 8px;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
`;