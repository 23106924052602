import React, {useRef, useMemo, useState, useEffect, forwardRef, useImperativeHandle} from "react";
import Filters from "./NumbersFilters";
import TableServerSort from "components/Table/TableServerSort";
import Revoke from "./Revoke";
import Allocate from "./Allocate/Allocate";
import SetOrUnsetTest from "./SetOrUnsetTest";
import Block from "pages/RangesAndNumbers/Numbers/Block";
import Unblock from "pages/RangesAndNumbers/Numbers/Unblock";
import FlexboxGrid from "rsuite/es/FlexboxGrid";
import {getRangeByNumber} from "utils";
import {Link} from "react-router-dom";
import "./Numbers.css";
import {Icon, Whisper, Tooltip} from "rsuite";
import styled from "styled-components";
import { currency_names_data } from "const";


export default forwardRef((
    {
        sort,
        pr_key,
        sde_key,
        service,
        filters,
        numbers,
        account_id,
        numbersPage,
        rangesFilter,
        numbersCount,
        numbersPerPage,
        numbersLoading,
        incorporatedOnly,
        showReasonHandler,
        allocatedNumbersCount,

        onSort,
        getNumbers,
        updateRanges,
        selectedRange,
        setCurrentTrunkId,
        setCurrentRangeNumber,
        onChangeNumberFilters
    },
    ref
) => {

    const [selected, setSelected] = useState({all: false, list: []});
    const [selectedNumbersInfo, setSelectedNumbersInfo] = useState([]);

    const [columns, setColumns] = useState([]);

    let tableRef = useRef(null);

    
    const unitedFilters = useMemo(() => {
        return {
            ...filters,
            show_only_incorporated_numbers: rangesFilter?.only_with_incorporated_numbers
        }
    }, [filters, rangesFilter]);

    useImperativeHandle(ref, () => ({
        clearSelected: () => {
            clearSelected();
        }
    }));

    useEffect(() => {
        const isRange = numbers.some(item => item.numbers_count > 1);

        const changableKey = `key-${isRange}-${service}-${numbersLoading}-${incorporatedOnly}`;

        setColumns([
            {
                label: "Number",
                dataKey: "number",
                value: (({number, numbers_count, incorporated_group, supplier_gan_group}) => {

                    const group = service ? incorporated_group : supplier_gan_group;

                    if (!group) {
                        return getRangeByNumber(number, numbers_count);
                    }

                    const {rate, cur_name, supplier_rate, supplier_cur_name} = group;

                    const groupRate = service ? rate : supplier_rate;
                    const groupCurName = service ? cur_name : supplier_cur_name;

                    const payoutGroups = group.rate_list
                        .reduce((summ, item) => {
                            return {
                                ...summ,
                                [item.cur_name]: summ[item.cur_name]
                                    ? summ[item.cur_name] + ` ${item.rate}/${item.payment_terms_name}`
                                    : `${item.rate}/${item.payment_terms_name}`
                            }
                        }, {});

                    return (
                        <>
                            {getRangeByNumber(number, numbers_count)}
                            {group &&
                                <Whisper
                                    enterable
                                    placement="right"
                                    speaker={
                                        <Tooltip>
                                            <StyledTooltipInner>
                                                <p>Supplier account name: <br/>{group.supplier_name}</p>
                                                <p>Group name: <br/>{group.name}</p>
                                                <p>Rate: <br/>{groupRate} {groupCurName}</p>
                                                <p>Default payouts: <br/>
                                                    {Object.keys(payoutGroups).map((item) => (
                                                        <><b>{item}</b>: {payoutGroups[item]} <br /></>
                                                    ))}
                                                </p>
                                            </StyledTooltipInner>
                                        </Tooltip>
                                    }
                                >
                                    <StyledTooltipIcon icon="info"/>
                                </Whisper>
                            }
                        </>
                    )
                }),
                sortable: true,
                width: isRange ? 260 : 200
            },
            {
                key: changableKey,
                label: "Allocated to and trunk",
                dataKey: "trunk_name",
                value: (({
                    account_name, account_manager_name, trunk_name, number, account_id, trunk_id, test_number, rate, currency_name
                }) => {
                    const textResult = account_name && !numbersLoading ?
                        <>
                            <Link className="ranges_numbers_table_link" to={`/accounts/view/${account_id}`} onClick={() => {
                                setCurrentTrunkId(trunk_id);
                                setCurrentRangeNumber(number)
                            }}>
                                {account_name} {!test_number && `/ ${account_manager_name || "no manager"}`}&nbsp;
                            </Link>
                            / <AdditionalInfo>{trunk_name} {rate} {currency_name}</AdditionalInfo>
                        </>
                    : trunk_name;

                    return textResult;
                }),
                sortable: true
            },
        ]);

        if (numbers.length) {
            clearSelected();
        }
    }, [service, numbers, numbersLoading, incorporatedOnly]);


    const getSelectedList = () => ({
        ...selected,
        countAll: allocatedNumbersCount
    });

    const getNumberList = (filterValue, page, per_page, sort) => {
        getNumbers(filterValue, page, per_page, sort);
    };

    const onChangeFilters = (filters) => {
        const currentFilters = {
            ...filters,
            show_only_incorporated_numbers: rangesFilter?.only_with_incorporated_numbers
        };

        onChangeNumberFilters(currentFilters);
        getNumberList(currentFilters, 1, numbersPerPage, sort);
        clearSelected();
    };

    const clearSelected = () => {
        setSelected({all: false, list: []});
        setSelectedNumbersInfo([]);
        tableRef.current.clear();
    };

    const includedNumber = selected.list.length && numbers.filter(number => selected.list.includes(number.prn_key));
    const disabledBlockedNumbersButtonSign = includedNumber && includedNumber.some(number => number.trunk_name === "blocked");
    const hasNoZeroPrefixNumbers = includedNumber && includedNumber.some(number => number.numbers_count !== 1);


    const hasSelected = (selected.list && selected.list.length) || selected.all;



    return (<>
        <FlexboxGrid justify="space-around" style={{marginBottom: 25, textAlign: "right"}}>
            <FlexboxGrid.Item>
                <Revoke
                    incorporatedOnly={incorporatedOnly}
                    selectedNumbersInfo={selectedNumbersInfo}
                    disabled={!hasSelected}
                    pr_key={pr_key}
                    service={service}
                    filters={unitedFilters}
                    getList={getSelectedList}
                    update={() => {
                        updateRanges();
                        getNumberList(unitedFilters, 1, numbersPerPage, sort);
                        tableRef.current.clear();
                    }}
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
                <Allocate
                    showReasonHandler={showReasonHandler}
                    incorporatedOnly={incorporatedOnly}
                    selectedNumbersInfo={selectedNumbersInfo}
                    service={service}
                    filters={unitedFilters}
                    disabled={!hasSelected || disabledBlockedNumbersButtonSign}
                    rangesFilter={rangesFilter}
                    getList={getSelectedList}
                    pr_key={pr_key}
                    update={() => {
                        updateRanges();
                        getNumberList(unitedFilters, 1, numbersPerPage, sort);
                        tableRef.current.clear();
                    }}
                    account_id={account_id}
                    sde_key={sde_key}
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
                <SetOrUnsetTest
                    incorporatedOnly={incorporatedOnly}
                    selectedNumbersInfo={selectedNumbersInfo}
                    disabled={!hasSelected || disabledBlockedNumbersButtonSign || hasNoZeroPrefixNumbers || rangesFilter?.only_with_incorporated_numbers || selected.all}
                    getList={getSelectedList}
                    pr_key={pr_key}
                    service={service}
                    update={() => {
                        updateRanges();
                        getNumberList(unitedFilters, 1, numbersPerPage, sort);
                        tableRef.current.clear();
                    }}
                    isSetTest={true}
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
                <SetOrUnsetTest
                    incorporatedOnly={incorporatedOnly}
                    selectedNumbersInfo={selectedNumbersInfo}
                    disabled={!hasSelected || disabledBlockedNumbersButtonSign || rangesFilter?.only_with_incorporated_numbers || selected.all}
                    getList={getSelectedList}
                    pr_key={pr_key}
                    service={service}
                    update={() => {
                        updateRanges();
                        getNumberList(unitedFilters, 1, numbersPerPage, sort);
                        tableRef.current.clear();
                    }}
                    isSetTest={false}
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
                <Block
                    incorporatedOnly={incorporatedOnly}
                    selectedNumbersInfo={selectedNumbersInfo}
                    disabled={!hasSelected || disabledBlockedNumbersButtonSign || selected.all}
                    getList={getSelectedList}
                    pr_key={pr_key}
                    service={service}
                    update={() => {
                        updateRanges();
                        getNumberList(unitedFilters, 1, numbersPerPage, sort);
                        tableRef.current.clear();
                    }}
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
                <Unblock
                    incorporatedOnly={incorporatedOnly}
                    selectedNumbersInfo={selectedNumbersInfo}
                    disabled={!hasSelected || selected.list.length && !disabledBlockedNumbersButtonSign || selected.all}
                    getList={getSelectedList}
                    pr_key={pr_key}

                    service={service}
                    update={() => {
                        updateRanges();
                        getNumberList(unitedFilters, 1, numbersPerPage, sort);
                        tableRef.current.clear();
                    }}
                />
            </FlexboxGrid.Item>
        </FlexboxGrid>
        <div style={{marginLeft: 20}}>
            <Filters
                value={filters}
                onChange={onChangeFilters}
            />
            <TableServerSort
                ref={tableRef}
                data={numbers}
                loading={numbersLoading}
                columns={columns}
                originalColumns={columns}
                count={numbersCount}
                per_page={numbersPerPage}
                page={numbersPage}
                getItems={(page, perPage) => {
                    getNumberList(unitedFilters, page, perPage, sort);
                }}
                row_key="prn_key"
                isSelectedAll={!incorporatedOnly}
                setSelected={selected => {
                    const filteredNumbers = selectedNumbersInfo.filter(item => selected.list.includes(item.prn_key));
                    const currentPageNumbers = numbers.filter(item => selected.list.includes(item.prn_key));
                    const numbersUpdated = currentPageNumbers.reduce((summ, item) => {
                        if (summ.find(_item => _item.prn_key === item.prn_key)) {
                            return summ;
                        }
                        return [...summ, item];
                    }, [...filteredNumbers]);

                    setSelected(selected);
                    setSelectedNumbersInfo(numbersUpdated);
                }}
                ispagination
                isselected
                onSort={(column, type) => {
                    onSort({column, type})
                }}
            />
        </div>
    </>)
});

const StyledTooltipIcon = styled(Icon)`
    margin-left: 5px;

    .rtl & {
        margin-left: 0;
        margin-right: 5px;
    }
`;

const StyledTooltipInner = styled.div`
    text-align: left;
`;

const AdditionalInfo = styled.span`
    && {
        color: gray;
    }
`;