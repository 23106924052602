export const REFERENCES_SET_LISTS = 'REFERENCES_SET_LISTS';
export const REFERENCES_SET_LOADING = 'REFERENCES_SET_LOADING';
export const REFERENCES_REPORT_SET_LOADING = 'REFERENCES_REPORT_SET_LOADING';
export const REFERENCES_ALL_SET_LISTS = 'REFERENCES_ALL_SET_LISTS';

/* CDR */
export const CDR_SET_LOADING = 'CDR_SET_LOADING';
export const CDR_GET_LIST_SUCCESS = 'CDR_GET_LIST_SUCCESS';

/* TRAFFIC REPORTS */
export const TRAFFIC_REPORTS_SET_LOADING = 'TRAFFIC_REPORTS_SET_LOADING';
export const TRAFFIC_REPORTS_GET_LIST_SUCCESS = 'TRAFFIC_REPORTS_GET_LIST_SUCCESS';

/* FAILED MDR */
export const MDR_FAILED_SET_LOADING = 'MDR_FAILED_SET_LOADING';
export const MDR_FAILED_GET_LIST_SUCCESS = 'MDR_FAILED_GET_LIST_SUCCESS';

/* AUTH */
export const NEED_SECOND_AUTH = 'NEED_SECOND_AUTH';
export const SET_PERMISSION = 'SET_PERMISSION';
export const SET_PERMISSION_ADD = 'SET_PERMISSION_ADD';

/* SIM TRAFFIC RANGES */
export const SIM_TRAFFIC_RANGES_SET_LOADING = 'SIM_TRAFFIC_RANGES_SET_LOADING';
export const SIM_TRAFFIC_RANGES_SET_LIST = 'SIM_TRAFFIC_RANGES_SET_LIST';

/* BLOCKED DESTINATIONS */
export const BLOCKED_DESTINATIONS_SET_LOADING = 'BLOCKED_DESTINATIONS_SET_LOADING';
export const BLOCKED_DESTINATIONS_SET_LIST = 'BLOCKED_DESTINATIONS_SET_LIST';

/* GLOBAL SETTINGS */
export const GLOBAL_SETTING_SET_LOADING = 'GLOBAL_SETTING_SET_LOADING';
export const GLOBAL_SETTING_SET_LIST = 'GLOBAL_SETTING_SET_LIST';

/* ROUTING */
export const ROUTING_SET_LOADING = 'ROUTING_SET_LOADING';
export const ROUTING_SET_LIST = 'ROUTING_SET_LIST';

/* E164 */
export const E164_SET_LOADING = 'E164_SET_LOADING';
export const E164_SET_LIST = 'E164_SET_LIST';

/* E212 */
export const E212_SET_LOADING = 'E164_SET_LOADING';
export const E212_SET_LIST = 'E164_SET_LIST';
export const E212_OPERATOR_SET_LIST = 'E212_OPERATOR_SET_LIST';