import React from 'react';
import { Form, FormControl,SelectPicker, Input, InputGroup, Icon, Schema} from 'rsuite';
import CustomField from '../../components/Form/CustomField';
import FormHOC from '../../hoc/FilltersForm';
import Checkbox from '../../hoc/Checkbox';
import ServicePlans from "../../components/Filters/ServicePlans";
import {connect} from "react-redux";
import {servicePick} from "../../utils";
import {USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY, DEFAULT_PRICE_KEY, SMS_DEFAULT_PRICE_KEY} from "../../const";
import { debounce } from 'lodash';
const { StringType } = Schema.Types;

const numbersFiltersModel = Schema.Model({
    str: StringType().maxLength(40, 'The maximum is only 40 characters.'),
});


const PriceFilters = ({service, onChange, currency_list, worldzone_list, subdestination_list, destination_list, defaultPriceKey, price_list}) =>  {
    return (
        <>
            <Form layout="inline" 
                onChange={debounce((value) => {
                    onChange(value);
                }, 400)}
                formDefaultValue={{
                    cur_key: servicePick(service, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY),
                    p_key: price_list.length ? price_list[0].p_key : (service ? DEFAULT_PRICE_KEY : SMS_DEFAULT_PRICE_KEY)
                }}
                model={numbersFiltersModel}
            >
                <CustomField
                    accepter={SelectPicker}
                    labelKey="name"
                    valueKey="p_key"
                    name="p_key"
                    data={price_list}
                    placeholder="Prices"
                    searchable={false}
                    cleanable={false}
                    classPrefix="allocated"
                />
                <CustomField
                    accepter={SelectPicker}
                    labelKey="name"
                    valueKey="wz_key"
                    data={worldzone_list}
                    placeholder="Worldzone"
                    name="wz_key"
                    classPrefix="allocated"
                />
                    <CustomField
                    accepter={SelectPicker}
                    data={destination_list}
                    labelKey="name"
                    valueKey="de_key"
                    placeholder="Destination"
                    name="de_key"
                    classPrefix="allocated"
                /> 
                <CustomField
                    accepter={SelectPicker}
                    data={subdestination_list}
                    labelKey="name"
                    valueKey="sde_key"
                    placeholder="Subdestination"
                    name="sde_key"
                    classPrefix="allocated"
                />  
                <CustomField
                    accepter={SelectPicker}
                    data={currency_list}
                    cleanable={false}
                    searchable={false}
                    labelKey="name"
                    valueKey="cur_key"
                    name="cur_key"
                    classPrefix="allocated"
                />
            </Form>
        </>
    )
};

const mapStateToProps = ({references}) => ({
    defaultPriceKey: references.defaultPriceKey
});

export default connect(mapStateToProps)(FormHOC(PriceFilters, numbersFiltersModel,300));