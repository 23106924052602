import React from "react";
import {Icon, Button, Uploader} from "rsuite";
import styled from "styled-components";


export default (
    {

        files,
        setFiles,
        text = 'Upload',
        ...props
    }
) => {

    return (
        <StyledUploader
            fileList={files}
            autoUpload={false}
            multiple={false}
            removable={true}
            accept="text/csv"
            onChange={(files) => {
                const filesList = files[files.length - 1] ? [files[files.length - 1]] : [];
                setFiles(filesList);
            }}
        >
            <Button>Upload file list</Button>
        </StyledUploader>
    )
}


const StyledUploader = styled(Uploader)`
    
    .rs-uploader-file-item.rs-uploader-file-item-text {
        display: block !important;
    }

    .rs-uploader-file-item {
        background-color: transparent !important;
    }
    
    .rs-uploader-file-items {
        margin-top: 5px !important;
        flex-grow: 1;
    }
    
    .rs-uploader-file-item-title {
        white-space: normal !important;
    }

    .rs-uploader-file-item-size {
        margin-left: auto;
    }
`;


const StyledCloseIcon = styled(Icon)`
    order: 1;
    align-self: center;
    margin-left: 20px;
    cursor: pointer;
`;