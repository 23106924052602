import React, {useRef, useState} from 'react';
import Checkbox from '../../../../../hoc/Checkbox';
import {useWindowWidth} from "../../../../../hooks";
import {Icon, Form, Input, Schema, ControlLabel, DatePicker, FlexboxGrid, FormControl} from "rsuite";
import CustomField from "../../../../../components/Form/CustomField";
import {hasErrorObject, toStartDayUTCTime} from "../../../../../utils";
import HiddenRate from './HiddenRate';

const {StringType} = Schema.Types;

const singlePrefixFormModel = Schema.Model({
    prefix: StringType()
        .pattern(/^[0-9][\d]*$/, 'The number must contain only digits')
            // .minLength(6, "Min length is 6 characters")
            .maxLength(15, "Max length is 15 characters"),
    name: StringType()
        .minLength(1, "Min length is 1 characters")
        .maxLength(40, "Max length is 40 characters"),
});


export default ({
    defaultFormValue,
    onChangeForm,
    formValue,
    disabled
}) => {

    let formRef = useRef(null);

    const [withDate, setWithDate] = useState(false);
    const windowWidth = useWindowWidth();

    const [changedDate, onChangeDate] = useState({});


    return (
            <Form
                model={singlePrefixFormModel}
                defaultFormValue={defaultFormValue}
                onChange={(value) => {
                    const allValid = !hasErrorObject(singlePrefixFormModel.check(value));
                    
                    if (allValid) {
                        onChangeForm(value);
                    }
                }}
                formValue={formValue}
                ref={ref => formRef = ref}
            >
                <FlexboxGrid justify="start" style={{margin: '0 0 20px 0'}}>
                    <FlexboxGrid.Item>
                        <ControlLabel>Prefix</ControlLabel>
                        <FormControl
                            disabled={disabled}
                            accepter={Input}
                            name="prefix"
                            placeholder="Prefix"
                            style={{width: 230}}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="ml-4">
                        <ControlLabel>Name</ControlLabel>
                        <FormControl
                            disabled={disabled}
                            accepter={Input}
                            placeholder="Name"
                            name="name"
                            style={{width: 230}}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                {/* <FlexboxGrid justify="start" style={{margin: '0 0 20px 0'}}>
                    <FlexboxGrid.Item>
                        <ControlLabel>Prefixes</ControlLabel>
                        <FormControl
                            disabled={disabled}
                            accepter={InputNumber}
                            name="prefixes"
                            placeholder="Prefixes"
                            style={{width: 230}}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid> */}

                <Checkbox
                    name={'withDate'}
                    onChange={(checked) => {
                        setWithDate(checked);

                        if (!checked) {
                            onChangeForm({
                                ...formValue,
                                start_date: undefined,
                                end_date: undefined
                            });

                            onChangeDate({})
                        }
                    }}
                >
                    From date
                </Checkbox>

                <FlexboxGrid justify="start" style={{margin: '0 0 20px 0'}}>
                    <FlexboxGrid.Item>
                        <FormControl
                            name="start_date"
                            placeholder="Start Date"
                            cleanable={false}
                            disabled={!withDate}
                            accepter={DatePicker}
                            style={{width: 230}}
                            ranges={[
                                {
                                    label: 'Today',
                                    value: new Date()
                                },
                                {
                                    label: 'Tomorrow',
                                    value: new Date( new Date().setDate(new Date().getDate() + 1) )
                                }
                            ]}
                            value={changedDate?.start_date}
                            selected={changedDate?.start_date}
                            onChange={(value) => {
                                onChangeDate({
                                    ...changedDate,
                                    start_date: toStartDayUTCTime(new Date(value))
                                });
                            }}
                            // mobile
                            // inline={isMobile}
                            // oneTap={isMobile}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="ml-4">
                        <FormControl
                            name="end_date"
                            placeholder="End Date"
                            accepter={DatePicker}
                            style={{width: 230}}
                            disabled={!formValue.start_date}
                            ranges={[
                                {
                                    label: 'Today',
                                    value: new Date()
                                },
                                {
                                    label: 'Tomorrow',
                                    value: new Date( new Date().setDate(new Date().getDate() + 1) )
                                }
                            ]}
                            value={changedDate?.end_date}
                            selected={changedDate?.end_date}
                            onChange={(value) => {
                                onChangeDate({
                                    ...changedDate,
                                    end_date: toStartDayUTCTime(new Date(value))
                                });
                            }}
                            // mobile
                            // inline={isMobile}
                            // oneTap={isMobile}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <br/>
                <HiddenRate/>

            </Form>    
        )
}