import React, {useMemo} from "react";
import {Input, SelectPicker, Form, FormControl, Schema} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import {debounce, hasErrorObject} from "../../utils";
import E212DownloadButton from "./E212DownloadButton";

const {StringType} = Schema.Types;

const e212model = Schema.Model({
    code: StringType()
        .pattern(/^\d*$/, "Only numbers")
        .maxLength(15, 'The maximum is only 15 characters.'),
    mcc: StringType()
        .pattern(/^\d*$/, "Only numbers")
        .maxLength(10, "The minimum is 10 characters"),
    mnc: StringType()
        .pattern(/^\d*$/, "Only numbers")
        .maxLength(10, "The minimum is 10 characters"),
});


export default ({
    countryList,

    defaultFilter,
    onChangeFilter
}) => {

    return (
        <>
            <Form
                model={e212model}
                formDefaultValue={defaultFilter}
                onChange={debounce((formValue) => {
                    const formCheckData = e212model.check(formValue);
                    if (!hasErrorObject(formCheckData)) {

                        const pickedCountryCode = formValue?.country;
                        const pickedCountryName = countryList.find((item) => item?.cntr_code === pickedCountryCode);
                        const currentFormValue = {
                            ...formValue,
                            country: pickedCountryName?.name
                        };

                        onChangeFilter(currentFormValue);
                    }
                }, 1000)}
            >
                <FlexGrid align="middle">
                    <FlexGridItem>
                        <FormControl
                            accepter={Input}
                            name="code"
                            placeholder="Code"
                            style={{width: 130}}
                        />
                    </FlexGridItem>

                    <FlexGridItem>
                        <FormControl
                            accepter={Input}
                            name="mcc"
                            placeholder="MCC"
                            style={{width: 130}}
                        />
                    </FlexGridItem>

                    <FlexGridItem>
                        <FormControl
                            // ref={filterInputsRefs["mcc"]}
                            accepter={SelectPicker}
                            // value={filter?.mcc}
                            data={countryList}
                            // labelKey="countryMccName"
                            labelKey="name"
                            valueKey="cntr_code"
                            placeholder="Country"
                            name="country"
                            style={{width: 200}}
                        />
                    </FlexGridItem>

                    <FlexGridItem>
                        <FormControl
                            accepter={Input}
                            name="mnc"
                            placeholder="MNC"
                            style={{width: 130}}
                        />
                    </FlexGridItem>

                    <FlexGridItem>
                        <FormControl
                            accepter={Input}
                            name="operator"
                            placeholder="Operator"
                            style={{width: 200}}
                        />
                    </FlexGridItem>


                    <FlexGridItem  style={{marginLeft: 'auto'}}>
                        <E212DownloadButton/>
                    </FlexGridItem> 

                </FlexGrid>
            </Form>
        </>
    )
};
