import React, {useEffect, useState} from "react";
import {Whisper, Tooltip} from "rsuite";
import {BaseToggle} from "components/base/BaseToggle";
import Page from "components/base/Page";
import CDR from "./CDR/CDRContainer";
import TrafficStat from "./TrafficStat/TrafficStatContainer";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {useIntl} from "react-intl";
import m from "definedMessages";
import usePrevious from "hooks/usePrevious";



const Traffic = ({
    service,
    history,
    location,
    cdr_list,
    resetRecaptcha,
    executeRecaptcha
}) => {
    const {formatMessage} = useIntl();

    const searchParams = new URLSearchParams(window.location.search);
    const typeFromUrl = searchParams.get("type");

    const typesTab = {
        traffic_reports: true,
        cdr: false,
        mdr: false
    };

    const [typeTrafficCDR, setTypeTrafficCDR] = useState(typesTab[typeFromUrl] || false);
    const prevTypeTrafficCDR = usePrevious(typeTrafficCDR);

    const messageColumn = {
        id: "message",
        label: formatMessage(m.message),
        // dataKey: "message",
        value: ({message}) => {
            return (
                <Whisper
                    enterable
                    placement="bottomStart"
                    speaker={
                        <Tooltip>{message}</Tooltip>
                    }
                >
                    <span>{message}</span>
                </Whisper>
            )
        },
        // flexGrow: 2,
        width: 300,
        align: "center"
    };
    // need big refactoring in CDR and TrafficStat components
    const voiceColumns = [
        {
            id: "datetime",
            label: formatMessage(m.datetime),
            dataKey: "datetime",
            align: "center",
            flexGrow: 4,
            minWidth: 200
        },
        {
            id: "a_number",
            label: formatMessage(m.aNumber),
            dataKey: "a_number",
            flexGrow: 3,
            minWidth: 150,
            align: "center"
        },
        {
            id: "b_number",
            label: formatMessage(m.bNumber),
            dataKey: "b_number",
            flexGrow: 3,
            minWidth: 150,
            align: "center"
        },
        {
            id: "range",
            label: formatMessage(m.range),
            dataKey: "range",
            flexGrow: 5,
            minWidth: 250,
            align: "center"
        },
        {
            id: "rate_dialer",
            label: formatMessage(m.rate),
            // dataKey: "rate_dialer",
            value: ({rate_dialer, cur_name}) => {
                if (rate_dialer && cur_name) {
                    return `${rate_dialer} ${cur_name}`;
                } else if (rate_dialer && !cur_name) {
                    return rate_dialer;
                }
                return "";
            },
            flexGrow: 2,
            minWidth: 100,
            align: "right"
        },
        {
            id: "cost_dialer",
            label: formatMessage(m.cost),
            // dataKey: "cost_dialer",
            value: ({cost_dialer, cur_name}) => {
                if (cost_dialer && cur_name) {
                    return `${cost_dialer} ${cur_name}`;
                } else if (cost_dialer && !cur_name) {
                    return cost_dialer;
                }
                return "";
            },
            flexGrow: 2,
            minWidth: 100,
            align: "right"
        },
        {
            id: "term",
            label: formatMessage(m.termPoint),
            value: (item) => {
                if (item.id === "summaries")
                    return "";

                const {ip, port, tprefix_dialer} = item;
                return `${ip}:${port} ${tprefix_dialer}`;
            },
            flexGrow: 4,
            minWidth: 200,
            align: "center"
        },
        {
            id: "duration",
            label: formatMessage(m.duration),
            value: (item) => {
                return item.id === "summaries"
                    ? <span title={item.duration}>{item.duration}</span>
                    : item.duration;
            },
            // dataKey: "duration",
            flexGrow: 3,
            minWidth: 100,
            align: "center"
        },
        {
            id: "sip",
            label: formatMessage(m.sipCause),
            dataKey: "sip_cause",
            // flexGrow: 2,
            width: 100,
            align: "center"
        },
    ];

    const smsColumns = [
        {
            id: "datetime",
            label: formatMessage(m.datetime),
            dataKey: "datetime",
            align: "center",
            flexGrow: 4,
            minWidth: 200
        },
        {
            id: "a_number",
            label: formatMessage(m.senderId),
            dataKey: "senderid",
            flexGrow: 3,
            minWidth: 150,
            align: "center"
        },
        {
            id: "b_number",
            label: formatMessage(m.phone),
            dataKey: "phone",
            flexGrow: 3,
            minWidth: 150,
            align: "center"
        },
        {
            id: "range",
            label: formatMessage(m.range),
            dataKey: "range_name",
            flexGrow: 5,
            minWidth: 250,
            align: "center"
        },
        {
            id: "rate_dialer",
            label: formatMessage(m.rate),
            // dataKey: "rate_dialer",
            value: ({dialer_rate, dialer_cur_name}) => {
                if (dialer_rate && dialer_cur_name) {
                    return `${dialer_rate} ${dialer_cur_name}`;
                } else if (dialer_rate && !dialer_cur_name) {
                    return dialer_rate;
                }
                return "";
            },
            flexGrow: 2,
            minWidth: 100,
            align: "right"
        },
    ];

    const [cdrColumns, setCdrColumns] = useState(voiceColumns);

    useEffect(() => {
        const firstRecord = cdr_list && cdr_list.length ? cdr_list[0] : null;

        if (firstRecord && firstRecord?.message) {
            smsColumns.push(messageColumn);
        }

        if (service !== null) {
            const columns = service ? voiceColumns : smsColumns

            setCdrColumns(columns);
        }
    }, [service, cdr_list, typeFromUrl]);

    useEffect(() => {
        if (searchParams.has("type")) {
            searchParams.delete("type");

            history.replace({
                ...location,
                search: ""
            });
        }
    }, [typeTrafficCDR]);

    return (
        <Page>
            <BaseToggle
                key={typeTrafficCDR}
                checkedChildren={formatMessage(m.trafficStat)}
                unCheckedChildren={service ? formatMessage(m.cdr) : formatMessage(m.mdr)}
                // defaultChecked={typeTrafficCDR}
                checked={typeTrafficCDR}
                onChange={value => setTypeTrafficCDR(value)}
            />
            {typeTrafficCDR ? <TrafficStat
                service={service}
                location={location}
                type={typeTrafficCDR}
                prevType={prevTypeTrafficCDR}
            /> : <CDR
                service={service}
                location={location}
                columns={cdrColumns}
                type={typeTrafficCDR}
                prevType={prevTypeTrafficCDR}
                {...{
                    cdr_list,
                    resetRecaptcha,
                    executeRecaptcha
                }}
            />}
        </Page>
    );
};

const mapState = ({auth_dialer, client_cdr}) => ({
    service: auth_dialer.serviceDialer,
    cdr_list: client_cdr.cdr_list,
});

export default connect(mapState, {})(withRouter(Traffic));
