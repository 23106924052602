import React from 'react';
import styled from "styled-components";
import FormHOC from '../../hoc/FilltersForm';
import {pythonExample, jsExample} from './apiExamples.js';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {colorBrewer}  from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import ApiCurrentApiKey from './ApiCurrentApiKey';
import Badge from 'rsuite/es/Badge'

export default FormHOC(({selectedPage, newItem, apiKey, methods = []}) => {
    const url = process.env.REACT_APP_API_URL;
    const api_key = apiKey || newItem || '{YOUR_API_KEY}';
    console.log('URL:::', api_key, methods)
    const examples = [
        {
            examples_python: pythonExample
        },
        {
            examples_js: jsExample
        }
    ];
    const langProperty = {
        examples_python: {
            title: 'Python example',
            language: 'python',
            style: colorBrewer
        },
        examples_js: {
            title: 'Javascript example',
            language: 'javascript',
            style: colorBrewer, // atelierSavannaLight
        }
    };

    const csvDifference = {
        examples_python: {
            output_csv: 'print(response.text)',
            output: 'print(json.dumps(response.json(), intend=4))',
            csv_url_var: 'CSV_URL',
            url_val: 'URL'
        },
        examples_js: {
            output_csv: 'console.log(xhr.responseText);',
            output: 'console.log(xhr.responseText);',
            csv_url_var: 'url',
            url_val: 'csvUrl'
        }
    }

    const handleCodeCsv = (value, page, csv) => {
        const diff = csvDifference[page];
        return value
            .replace('{url}', csv ? diff.csv_url_var : diff.url_val)
            .replace('{output}', csv ? diff.output_csv : diff.output);
    }

    const handleResultCsv = (value, csv) => {
        return csv && value.hasOwnProperty('result_csv') ? value.result_csv : value.result;
    }

    const handleMethods = (example) => {
        const createdMethods = [];
        for (const method of methods) {
            const methodWithoutCsv = {...method};
            if (methodWithoutCsv.csv) {
                delete methodWithoutCsv.csv;
            }

            createdMethods.push(methodWithoutCsv);

            if (method.csv) {
                createdMethods.push(method);
            }
        }

        return createdMethods.map(method => {
            return example[selectedPage].hasOwnProperty(method.method_api) && <>
                <div className="api_examples-python_header">
                    Name: <span className="api_examples-python_header_method">
                    {method.method_api}{method.csv ? <StyledBadge content='CSV'/> : <></>}
                </span>
                </div>
                <div className="api_examples-python_header">
                    Description:
                </div>
                <div className="api_examples-python_header_method_description">
                    {method.description}
                </div>
                <div className="api_examples-python_header">
                    Code:
                </div>
                <SyntaxHighlighter language={langProperty[selectedPage].language} style={langProperty[selectedPage].style}>
                    {handleCodeCsv(example[selectedPage][method.method_api].code, selectedPage, method.csv)}
                </SyntaxHighlighter>
                <div className="api_examples-python_header">
                    Response:
                </div>
                <SyntaxHighlighter language='javascript' style={langProperty[selectedPage].style}>
                    {handleResultCsv(example[selectedPage][method.method_api], method.csv)}
                </SyntaxHighlighter>
            </>
        });
    }

    return (
        examples.map(example => {
            const optionsCode =  example[selectedPage] && example[selectedPage].options.code.replace('{your_api_key}', api_key).replace(/{your_url}/g, url);
            const optionsResultList =   example[selectedPage] && example[selectedPage].options.result.replace(/{your_api_key}/g, api_key).replace(/{your_url}/g, url).split('\n').filter(row => row && row.length);
            return (
                example[selectedPage]
                    && <>
                        <span className="api_documentation">{langProperty[selectedPage].title}</span>
                        <ApiCurrentApiKey apiKey={api_key}/>
                        <div className="api_examples-python api_examples-python_header">
                            Options
                        </div>
                        <div className="api_examples-python_options_description">
                            At first you should make your setup to get ready to use api methods. You have to declare api key and compile your url addresses.
                            <br/>
                            The following examples use this setup
                        </div>
                        <div className="api_examples-python_header">
                            Code:
                        </div>
                        <SyntaxHighlighter language={langProperty[selectedPage].language} style={langProperty[selectedPage].style} className="api_examples-python_options_code">
                            {optionsCode}
                        </SyntaxHighlighter>
                        <div className="api_examples-python_header">
                            Result:
                        </div>
                        <div className="simple_code api_examples-python_options_result">
                            {
                                optionsResultList.map(resultRow => {
                                    return <><span>{resultRow}</span><br /></>
                                })
                            }
                        </div>
                        <div className="api_examples-python api_examples-python_header">
                            Methods
                        </div>
                        {
                            handleMethods(example)
                        }
                    </>
            )
        }))
});

const StyledBadge = styled(Badge)`
    && {
      margin-left: 5px;
      background-color: var(--color-primary);
    }
`;