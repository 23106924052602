import React, { Component } from "react";
// import PropTypes from "prop-types";
import moment from "moment";
import Datetime from "react-datetime";
// import classNames from "classnames";
import onClickOutside from "react-onclickoutside";
// import { Input } from "semantic-ui-react";
import {Input} from "rsuite";
import styled from "styled-components";

// Import styles
// import "semantic-ui-css/semantic.min.css";
import "./Datetime.css";
import "./DateTimeRangePicker.css";
import {getFormattedTimeIOS, removeTZFromDate, toEndDayTime, toStartDayTime, toUTCDateTime} from "utils";

class DateTimeRangePicker extends Component {
  startDateRef = null;
  endDateRef = null;

  defaultFormatString = "YYYY/MM/DD HH:mm";

  // divDateRef = null;
  state = {
    showDateTimeRangePicker: false,

    startDate: moment(this.props.defaultStartDate) || moment(),
    endDate: moment(this.props.defaultEndDate) || moment(),
    dateTimeRangeString: `${moment(this.props.defaultStartDate).format(
      this.props.format || this.defaultFormatString
    )} - ${moment(this.props.defaultEndDate).format(
      this.props.format || this.defaultFormatString
    )}`
  };

  // State Helpers
  // ---------------------------------------------------
  getDateTimeRangeCallbackProps = () => {
    return {
      range: [this.state.startDate.toDate(), this.state.endDate.toDate()]
    }
  };
  // ---------------------------------------------------

  componentWillReceiveProps(nextProps) {
    const {format} = this.props;

    // You don't have to do this check first, but it can help prevent an unneeded render
    const nextStartDate = moment(nextProps.startDate);
    const nextEndDate = moment(nextProps.endDate);

    if (!nextStartDate.isSame(this.state.startDate)) {
      this.setState({ 
        startDate: nextStartDate,
      }, () => {
        this.setState({
          dateTimeRangeString: `${nextStartDate.format(
            format || this.defaultFormatString
          )} - ${this.state.endDate.format(
            format || this.defaultFormatString
          )}`
        });
      });
    }

    if (!nextEndDate.isSame(this.state.endDate)) {
      this.setState({
        endDate: nextEndDate,
      }, () => {
        this.setState({
          dateTimeRangeString: `${this.state.startDate.format(
            format || this.defaultFormatString
          )} - ${nextEndDate.format(
            format || this.defaultFormatString
          )}`
        });
      });
    }
  }

  // onClickOutside, then hide datetimerangepicker.
  handleClickOutside = e => {
    this.setState({
      showDateTimeRangePicker: false
    });

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  // On container focus, then show datetimerangepicker.
  handleFocus = () => {

    // if (this.divDataRef) {
    //   this.divDataRef.focus();
    // }

    this.setState({
      showDateTimeRangePicker: true
      // for debug
      /*dateTimeRangeString: `${this.state.startDate.format(
        "DD/MM/YYYY HH:mm"
      )} - ${this.state.endDate.format("DD/MM/YYYY HH:mm")}`*/
    });

    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  onResetInputClick = e => {
    console.log(e);
  };

  // On StartDateTime change.
  onStartDateTimeChange = date => {
    const {format} = this.props;
    let stateToUpdate = {
      startDate: date,
      endDate: this.state.endDate
    };

    if (!this.state.endDate.isBefore(date)) {
      // stateToUpdate.endDate = date.add(1, "d");
      // Update input text value.
      stateToUpdate.dateTimeRangeString = `${stateToUpdate.startDate.format(
        format || this.defaultFormatString
      )} - ${stateToUpdate.endDate.format(
        format || this.defaultFormatString
      )}`;

      this.setState(stateToUpdate, () => {
        this.props.onChange(this.getDateTimeRangeCallbackProps());
        // this.props.onStartDateTimeChange(
        //   this.getDateTimeRangeCallbackProps().startDate
        // );
      });
    }
  };

  // On EndDateTime change.
  onEndDateTimeChange = date => {
    const {format} = this.props;
    const stateToUpdate = {
      startDate: this.state.startDate,
      endDate: date
    };

    if (!this.state.startDate.isAfter(date)) {
      // Update input text value.
      stateToUpdate.dateTimeRangeString = `${stateToUpdate.startDate.format(
        format || this.defaultFormatString
      )} - ${stateToUpdate.endDate.format(
        format || this.defaultFormatString
      )}`;

      this.setState(stateToUpdate, () => {
        this.props.onChange(this.getDateTimeRangeCallbackProps());
        // this.props.onEndDateTimeChange(
        //   this.getDateTimeRangeCallbackProps().endDate
        // );
      });
    }
  };

  // DateTime modification
  // ----------------------------------------------------
  // Disable all end dates that older than start date.
  disableOlderStartDate = (currentDate, selectedDate) => {
    return this.state.startDate.isSameOrBefore(currentDate, "d");
  };

  renderDay = (props, currentDate, selectedDate) => {
    const { startDate, endDate } = this.state;
    const { className, ...rest } = props;
    const date = moment(rest.key, "M_D");

    // Set style for all dates in range.
    let classes = date.isBetween(startDate, endDate, "d")
      ? `${className} inSelectingRange`
      : className;

    classes =
      date.isSame(startDate, "d") || date.isSame(endDate, "d")
        ? `${classes} rdtActive`
        : classes;

    return (
      <td {...rest} className={classes}>
        {currentDate.date()}
      </td>
    );
  };
  // ----------------------------------------------------

  // Props helper
  // ----------------------------------------------------
  getDateTimeRangeBaseProps = () => {
    return {
      input: this.props.input,
      className: "dateTimeRangePickerDisplay"
    };
  };

  getStartDateTimeProps = () => {
    const baseProps = this.getDateTimeRangeBaseProps();

    return {
      ...baseProps,
      defaultValue: this.props.startDate
    };
  };

  getEndDateTimeProps = () => {
    const baseProps = this.getDateTimeRangeBaseProps();

    return {
      ...baseProps,
      defaultValue: this.props.endDate
    };
  };
  // ----------------------------------------------------------------------

  render() {
    const {
      mobile,
      width
    } = this.props;

    const {
      showDateTimeRangePicker,
      dateTimeRangeString,
      startDate,
      endDate
    } = this.state;

    const startDateTimeProps = this.getStartDateTimeProps();
    const endDateTimeProps = this.getEndDateTimeProps();

    // rs-drawer-body position: initial
    // dateTimeRangePickerContainer lift up

    return (
      <div   
      // ref={ref => this.divDataRef = ref} 
      className="outsideContainer" onFocus={this.handleFocus}>
        <Input 
          fluid 
          value={dateTimeRangeString} 
          readOnly
          style={width ? {width: `${width}px`} : {}}
        />
        {mobile ? <div className="dateTimeRangePickerContainer">
          <div className="dateTimePicker dateFrom">
            {showDateTimeRangePicker && <div className="dateTimeName">From</div>}
            <StyledDatetime
              ref={ref => this.startDateRef = ref}
              {...startDateTimeProps}
              open={showDateTimeRangePicker}
              onChange={this.onStartDateTimeChange}
              renderDay={this.renderDay}
              value={this.state.startDate.toDate()}
              timeFormat={"HH:mm"}
              mobile={mobile}
            />
          </div>
          <div className="dateTimePicker dateTo">
            {showDateTimeRangePicker && <div className="dateTimeName">To</div>}
            <StyledDatetime
              ref={ref => this.endDateRef = ref}
              {...endDateTimeProps}
              open={showDateTimeRangePicker}
              isValidDate={this.disableOlderStartDate}
              onChange={this.onEndDateTimeChange}
              renderDay={this.renderDay}
              value={this.state.endDate.toDate()}
              timeFormat={"HH:mm"}
              mobile={mobile}
            />
          </div>
        </div> : <div className="dateTimeRangePickerContainer">
          <Datetime
            ref={ref => this.startDateRef = ref}
            {...startDateTimeProps}
            open={showDateTimeRangePicker}
            onChange={this.onStartDateTimeChange}
            renderDay={this.renderDay}
            value={this.state.startDate.toDate()}
            timeFormat={"HH:mm"}
          />
          <Datetime
            ref={ref => this.endDateRef = ref}
            {...endDateTimeProps}
            open={showDateTimeRangePicker}
            isValidDate={this.disableOlderStartDate}
            onChange={this.onEndDateTimeChange}
            renderDay={this.renderDay}
            value={this.state.endDate.toDate()}
            timeFormat={"HH:mm"}
          />
        </div>}
      </div>
    );
  }
}

// Set endDate default prop helper.
// var setEndDate = () => {
//   let date = new Date();
//   let currentMonth = date.getMonth();

//   if (currentMonth === 11) {
//     date.setFullYear(date.getFullYear() + 1);
//     date.setMonth(0);
//   } else {
//     date.setMonth(currentMonth + 1);
//   }

//   return date;
// };

// Define props
// DateTimeRangePicker.propTypes = {
//   startDate: PropTypes.instanceOf(Date),
//   endDate: PropTypes.instanceOf(Date),
//   input: PropTypes.bool,
//   // Event handlers
//   onChange: PropTypes.func,
//   onStartDateTimeChange: PropTypes.func,
//   onEndDateTimeChange: PropTypes.func
// };

// // Default props
// DateTimeRangePicker.defaultProps = {
//   startDate: new Date(),
//   endDate: new Date(),
//   input: false,
//   // Event handlers
//   onChange: () => {},
//   onStartDateTimeChange: () => {},
//   onEndDateTimeChange: () => {}
// };

export default onClickOutside(DateTimeRangePicker);


const StyledDatetime = styled(Datetime)`
  &&.dateTimeRangePickerDisplay {
    display: ${props => !props.mobile ? 'inline-block' : 'revert'};
  }
`;