import Modal from '../../components/Modal';
import React, {memo, useEffect, useState} from 'react';
import {Alert, Form, FormControl, Schema} from 'rsuite/es';
import {FormGroup, SelectPicker} from 'rsuite';
import styled from 'styled-components';
import {shuffleArray} from 'utils';
import {
    mergeAccounts,
    getDropdownAccounts
} from 'actions/dropdown_accounts';
import {connect} from 'react-redux';

const {StringType} = Schema.Types;
const reverseService = {
    "voice": "sms",
    "sms": "voice"
};

const MergeAccountsModal = memo(({show, loading, accountId, dropdownAccounts, accountService, setLoading, mergeAccounts, updateCurrentPage, getDropdownAccounts, onClose}) => {
    let formRef;
    const formModal = Schema.Model({
        account_id: StringType()
            .isRequired('This field is required')
    });

    const neededService = reverseService[accountService];
    const [hasError, onHasError] = useState({error: false, message: null});

    useEffect(() => {
        if (show) {
            getDropdownAccounts({
                service: neededService,
                joined: false
            });
        }
    }, [show]);

    const onSubmit = (data) => {
        const firstAccountId = accountId;
        const secondAccountId = data.account_id;

        mergeAccounts(firstAccountId, secondAccountId).then((response) => {
            if (response && response.account) {
                Alert.success("Accounts was merged!");
                // getList(filters, 1, perPage);
                updateCurrentPage();
            }
        })
    };

    return (
        <Modal
            show={show}
            onClose={() => {
                onClose();
            }}
            footer
            title="Merge accounts"
            extraDisabled={loading}
            successText="Merge"
            onSuccess={() => {
                formRef.check();
                const formData = formRef.getFormValue();
                const check = formModal.check(formData);
                const error = Object.keys(check).map(key => check[key])[0];
                const errorBody = {error: error.hasError, message: error.errorMessage};
                onHasError(errorBody);

                if (!errorBody.error) {
                    onSubmit(formData);
                    onClose();
                }
            }}
        >
            Account merging means that this account will be connected with selected (under that name). Both accounts will save all traffic, trunks, allocated numbers, currency, payment terms and account manager.
            Dialer will be available see balances, traffic, trunks, allocated numbers, invoices and payments of both accounts in one place and make payment requests for both balances.
            <br/>
            <StyledForm
                ref={ref => formRef = ref}
                modal={formModal}
            >
                <FormGroup>
                    Select account:
                    <FormControl
                        name="account_id"
                        valueKey="id"
                        labelKey="name"
                        accepter={SelectPicker}
                        data={dropdownAccounts}
                        errorMessage={hasError.error ? hasError.message : null}
                    />
                </FormGroup>
            </StyledForm>
        </Modal>
    );
});

const mapStateToProps = ({dropdown_accounts}) => ({
    dropdownAccounts: dropdown_accounts.items
});

const mapDispatchToProps = {
    mergeAccounts,
    getDropdownAccounts
};

export default connect(mapStateToProps, mapDispatchToProps)(MergeAccountsModal);

const StyledForm = styled(Form)`
  margin-top: 20px;
  & .rs-btn {
    margin-right: 10px;
  }
  & .rs-form-control-wrapper {
    width: auto;
    margin-left: 10px
  }
  & .rs-form-control-wrapper .rs-input {
    width: 200px;
  }
  
`;
