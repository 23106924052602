import React from 'react';
import {SelectPicker} from "rsuite";

export const Select = ({onChange = (v) => v , ...props}) => {
    return (
        <SelectPicker
            onChange={(value) => onChange(value === null ? undefined : value)}
            {...props}
        />
    )
};

export default Select;