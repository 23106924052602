import React, { useMemo, useEffect, useState } from "react";
import Table from "../../components/Table/TableServerSort";
import { Whisper, Tooltip } from "rsuite";
import { connect } from "react-redux";
import { getCDRList, getFailedMDRList } from "../../actions/cdr";
import { DESC_SORT } from "const";
import styled from "styled-components";
import { servicePick } from "utils";
import { usePrevious } from "hooks";

const DEFAULT_SORT = { column: "datetime", type: DESC_SORT };


const CDRTable = (props) => {
    const [sort, setSort] = useState(DEFAULT_SORT);
    const { 
        cdrMdrLoading,
        cdrMdrList, 
        cdrMdrMore,
        cdrMdrCount,

        mdrFailedLoading,
        mdrFailedList, 
        mdrFailedMore,
        mdrFailedCount,

        perPage,
        page,
        service,
        filters,
        isFailedMdr
    } = props;

    useEffect(() => {
        setSort(DEFAULT_SORT);
    }, [service, isFailedMdr]);

    const prevService = usePrevious(service);
    const prevIsFailedMdr = usePrevious(isFailedMdr);

    const loading = isFailedMdr ? mdrFailedLoading : cdrMdrLoading;
    const dataList = isFailedMdr ? mdrFailedList : cdrMdrList;
    const more = isFailedMdr ? mdrFailedMore : cdrMdrMore;
    const count = isFailedMdr ? mdrFailedCount : cdrMdrCount;

    const columnSelector = localStorage.getItem("CDRTable") && JSON.parse(localStorage.getItem("CDRTable"));

    const columns = useMemo(() => {
        const cdrColumns = [
            {
                label: "Date Time",
                dataKey: "datetime",
                formatData: "datetime",
                flexGrow: 1,
                minWidth: 170,
                title: true,
                sortable: true
            },
            {
                label: "Originator",
                dataKey: "originator_name",
                minWidth: 200,
                sortable: true
            },
            {
                label: "Tprefix",
                fullLabel: "Originator Tprefix",
                dataKey: "tprefix_originator",
                width: 90,
                hideDefault: true,
                sortable: true
            },
            {
                label: "Cost",
                fullLabel: "Originator Cost",
                dataKey: "cost_originator",
                value: ({ cost_originator}) => {
                    return cost_originator || "0"
                },
                width: 75,
                sortable: true,
                align: "right"
            },
            {
                label: "Originator Rate",
                fullLabel: "Originator Rate",
                dataKey: "rate_originator",
                value: ({ rate_originator}) => {
                    return rate_originator || "0"
                },
                width: 120,
                sortable: true,
                align: "right"
            },
            {
                label: "A-Number",
                dataKey: "a_number",
                width: 120,
                sortable: true
            },
            {
                label: "Origin",
                dataKey: "a_subdestination_name",
                minWidth: 200,
                title: true,
                sortable: true
            },
            {
                label: "B-Number",
                dataKey: "b_number",
                width: 120,
                sortable: true
            },
            {
                label: "Destination",
                dataKey: "b_subdestination_name",
                minWidth: 280,
                title: true,
                sortable: true
            },
            {
                label: "Range",
                dataKey: "range",
                minWidth: 300,
                title: true,
                sortable: true
            },
            {
                label: "Dialer",
                dataKey: "dialer_name",
                value: ({ dialer_name, dialer_manager }) => `${dialer_name || "no dialer"} / ${dialer_manager || "no manager"}`,
                minWidth: 250,
                sortable: true
            },
            {
                label: "TPrefix",
                fullLabel: "Dialer TPrefix",
                dataKey: "tprefix_dialer",
                width: 90,
                hideDefault: true,
                sortable: true
            },
            {
                label: "Dialer Rate",
                dataKey: "rate_dialer",
                fullLabel: "Dialer Rate",
                value: ({ rate_dialer, cur_name_dialer}) => {
                    return `${rate_dialer || "0"} ${cur_name_dialer}`
                },
                width: 100,
                sortable: true,
                align: "right"
            },
            {
                label: "Cost",
                fullLabel: "Dialer Cost",
                dataKey: "cost_dialer",
                value: ({cost_dialer}) => cost_dialer || "0",
                width: 75,
                sortable: true,
                align: "right"
            },
            {
                label: "Term. Point",
                dataKey: "term_point",
                minWidth: 200,
                hideDefault: true,
                sortable: true
            },
            {
                label: "Duration",
                dataKey: "duration",
                value: ({duration}) => duration || "0",
                width: 100,
                sortable: true
            },
            { 
                label: "SIP Cause",
                dataKey: "sip_cause",
                width: 100,
                sortable: true,
                align: "right"
            },
            {
                label: "Margin",
                fullLabel: "Margin",
                dataKey: "margin",
                value: (({ margin, cur_name_margin }) => (margin !== null && margin !== undefined && !isNaN(margin)
                    ? `${margin.toFixed(5)} ${cur_name_margin}`
                    : `0 ${cur_name_margin}`)),
                width: 110,
                sortable: true,
                align: "right"
            }
        ];

        const mdrColumns = [
            {
                label: "Date Time",
                dataKey: "datetime",
                formatData: "datetime",
                flexGrow: 1,
                minWidth: 170,
                title: true,
                sortable: true
            },
            {
                label: "Originator",
                dataKey: "originator_name",
                minWidth: 200,
                sortable: true
            },
            {
                label: "Operator",
                dataKey: "operator",
                minWidth: 200,
                sortable: true
            },
            {
                label: "Country",
                dataKey: "country",
                minWidth: 200,
                sortable: true
            },
            { 
                label: "Supplier",
                dataKey: "supplier_name",
                minWidth: 200,
                sortable: true
            },
            {
                label: "Supplier Rate",
                dataKey: "supplier_rate",
                fullLabel: "Supplier Rate",
                value: ({ supplier_rate, supplier_cur_name }) => {
                    return`${supplier_rate || "0"} ${supplier_cur_name}`
                },
                width: 135,
                sortable: true,
                align: "right"
            },
            {
                label: "Supplier trunk",
                dataKey: "supplier_plan",
                minWidth: 120,
                // sortable: true
            },
            { 
                label: "Sender ID", 
                dataKey: "senderid", 
                width: 140, 
                sortable: true
            },
            { 
                label: "Phone",
                dataKey: "phone",
                width: 140,
                sortable: true
            },
            {
                label: "Destination",
                dataKey: "phone_sde_name",
                minWidth: 280,
                title: true,
                sortable: true
            },
            { 
                label: "Range",
                dataKey: "range_name",
                minWidth: 300,
                title: true,
                sortable: true
            },
            {
                label: "Dialer",
                dataKey: "dialer_name",
                value: ({ dialer_name, dialer_manager }) => {
                    return `${dialer_name || "no dialer"} / ${dialer_manager || "no manager"}`
                },
                minWidth: 250,
                sortable: true
            },
            {
                label: "Dialer Rate",
                dataKey: "dialer_rate",
                fullLabel: "Dialer Rate",
                value: ({ dialer_rate, dialer_cur_name }) => {
                    return `${dialer_rate || "0"} ${dialer_cur_name}`
                },
                width: 100,
                sortable: true,
                align: "right"
            },
            {
                label: "Reason",
                dataKey: "reason",
                value: ({ reason }) => {
                    return <a title={reason}>
                        {reason}
                    </a>
                },
                minWidth: 300,
                sortable: true
            },
            {
                label: "MCC/MNC",
                dataKey: "mcc_mnc",
                width: 100,
                sortable: true
            },
            {
                label: "Delivery date",
                dataKey: "delivery_datetime",
                formatData: "datetime",
                flexGrow: 1,
                minWidth: 170,
                // title: true,
                sortable: true
            },
            {
                label: "Delivery status",
                dataKey: "delivery_status",
                flexGrow: 1,
                minWidth: 120,
                // title: true,
                sortable: true
            },
            {
                label: "Margin",
                fullLabel: "Margin",
                dataKey: "margin",
                value: (({ margin, margin_cur_name }) => (margin !== null && margin !== undefined && !isNaN(margin)
                    ? `${margin.toFixed(5)} ${margin_cur_name}`
                    : `0 ${margin_cur_name}`)),
                width: 110,
                sortable: true,
                align: "right"
            },
            { 
                label: "Message",
                dataKey: "message",
                value: ({message}) => {
                    return (
                        <Whisper
                            enterable
                            placement="bottomStart"
                            speaker={
                                <Tooltip>{message}</Tooltip>
                            }
                        >
                            <span>{message}</span>
                        </Whisper>
                    )
                },
                width: 340,
                sortable: true
            },
        ];

        const mdrFailedColumns = [
            {
                label: "Date Time",
                dataKey: "datetime",
                formatData: "datetime",
                flexGrow: 1,
                minWidth: 170,
                title: true,
                sortable: true
            },
            {
                label: "Operator",
                dataKey: "operator",
                minWidth: 200,
                sortable: true
            },
            {
                label: "Country",
                dataKey: "country",
                minWidth: 200,
                sortable: true
            },
            {
                label: "Supplier",
                dataKey: "supplier_name",
                minWidth: 200,
                sortable: true
            },
            {
                label: "Supplier Rate",
                dataKey: "supplier_rate",
                fullLabel: "Supplier Rate",
                value: ({ supplier_rate, supplier_cur_name }) => {
                    return `${supplier_rate || "0"} ${supplier_cur_name}`
                },
                width: 135,
                sortable: true,
                align: "right"
            },
            {
                label: "Sender ID",
                dataKey: "senderid",
                width: 140,
                sortable: true
            },
            {
                label: "Supplier trunk",
                dataKey: "supplier_plan",
                minWidth: 120,
                // sortable: true
            },
            {
                label: "Phone",
                dataKey: "phone",
                width: 140,
                sortable: true
            },
            {
                label: "Destination",
                dataKey: "phone_sde_name",
                width: 250,
                sortable: true
            },
            {
                label: "MCC/MNC",
                dataKey: "mcc_mnc",
                width: 100,
                sortable: true
            },
            {
                label: "Reason",
                dataKey: "reason",
                value: ({ reason }) => {
                    return <a title={reason}>
                        {reason}
                    </a>
                },
                minWidth: 700,
                sortable: true
            },
            { 
                label: "Message",
                dataKey: "message",
                value: ({message}) => {
                    return (
                        <Whisper
                            enterable
                            placement="bottomStart"
                            speaker={
                                <Tooltip>{message}</Tooltip>
                            }
                        >
                            <span>{message}</span>
                        </Whisper>
                    )
                },
                width: 340,
                sortable: true
            },
        ];

        return isFailedMdr ? mdrFailedColumns : servicePick(service, cdrColumns, mdrColumns);
    }, [service, isFailedMdr]);

    const filteredColumns = useMemo(() => {
        return columns.filter(column => columnSelector && columnSelector.length ? columnSelector.includes(column.dataKey) : true);
    }, [columnSelector]);

    let getCDRItems = (page, perPage, sort) => {
        if (isFailedMdr) {
            props.getFailedMDRList(filters, page, perPage, sort);
            return;
        }
        props.getCDRList(service, filters, page, perPage, sort);
    };

    useEffect(() => {
        if (isFailedMdr === prevIsFailedMdr && service === prevService) {
            if (isFailedMdr) {
                props.getFailedMDRList(filters, 1, perPage, sort);
                return;
            }
            props.getCDRList(service, filters, 1, perPage, sort);
        }
    }, [service, filters, isFailedMdr]);

    return (
        <Table
            data={dataList}
            service={service}
            loading={loading}
            columns={filteredColumns}
            originalColumns={columns}
            resizable
            count={count}
            per_page={perPage}
            page={page}
            getItems={getCDRItems}
            // showedCount={more ? count : <><StyledBoldText>&gt;</StyledBoldText>{count}</>}
            showedCount={count}
            ispagination
            additionalColumnUpdate={isFailedMdr}
            columnSelectorLSKey={service ? "CDRTable" : isFailedMdr ? "CDRTableFailed" : "CDRTableSMS"}
            defaultSortColumn="datetime"
            defaultSortType={DESC_SORT}
            onSort={(column, type) => setSort({ column, type })}
        />
    );
};

const mapStateToProps = ({ cdr, auth }) => ({
    cdrMdrLoading: cdr.loading,
    cdrMdrList: cdr.list,
    cdrMdrMore: cdr.more,
    cdrMdrCount: cdr.count,

    mdrFailedLoading: cdr.mdrFailedLoading,
    mdrFailedList: cdr.mdrFailedList,
    mdrFailedMore: cdr.mdrFailedMore,
    mdrFailedCount: cdr.mdrFailedCount,

    perPage: cdr.perPage,
    page: cdr.page,
    service: auth.service
});

const mapDispatchToProps = {
    getCDRList,
    getFailedMDRList
};

export default connect(mapStateToProps, mapDispatchToProps)(CDRTable);

const StyledBoldText = styled.span`
    && {
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
    }
`;