import React, {useMemo, useState, useContext} from "react";
import {FlexboxGrid, Loader, Button, Schema} from "rsuite";
import styled from "styled-components";
import {PermissionContext} from "../../App";
import Modal from "../../components/Modal";
import TreePicker from "../../components/TreePicker/TreePicker";

const {StringType} = Schema.Types;

const newGroupModel = Schema.Model({
    name: StringType()
        .isRequired("Required")
        .maxLength(40, "The maximum is only 40 characters.")
});


export default ({
    data,
    dataOther,
    groupsAreExists,
    activeGroupKey,

    loading,

    updateItems,

    addAccountToGroup,
    removeAccountFromGroup,
}) => {

    const permissions = useContext(PermissionContext);

    const [activeMainAccountKey, setActiveMainAccountKey] = useState(null);
    const [activeOtherAccountKey, setActiveOtherAccountKey] = useState(null);

    const allMainAccountKeyList = useMemo(() => {
        return data.map((value) => {
            return value.id;
        });
    }, [data]);

    const allOtherAccountKeyList = useMemo(() => {
        return dataOther.map((value) => {
            return value.id;
        });
    }, [dataOther]);

    const handleMoveAccountToMain = () => {
        addAccountToGroup(activeGroupKey, [activeOtherAccountKey])
            .then((response) => {
                if (response && response.hasOwnProperty("accounts")) {
                    updateItems();
                    setActiveOtherAccountKey(null);
                }
            })
    };

    const handleMoveAccountToOther = () => {
        removeAccountFromGroup(activeGroupKey, [activeMainAccountKey])
            .then((response) => {
                if (response && response.hasOwnProperty("accounts")) {
                    updateItems();
                    setActiveMainAccountKey(null);
                }
            })
    };

    const handleMoveAllAccountsToMain = () => {
        addAccountToGroup(activeGroupKey, allOtherAccountKeyList)
            .then((response) => {
                if (response && response.hasOwnProperty("accounts")) {
                    updateItems();
                    setActiveOtherAccountKey(null);
                }
            });
    };

    const handleMoveAllAccountsToOther = () => {
        removeAccountFromGroup(activeGroupKey, allMainAccountKeyList)
            .then((response) => {
                if (response && response.hasOwnProperty("accounts")) {
                    updateItems();
                    setActiveMainAccountKey(null);
                }
            });
    };

    return (
        <>
            {<StyledFlexGrid hSpace={'-20px'} align={'stretch'} noWrap>
                <StyledAccountList hSpace={'20px'}>
                    <StyledTreeWrapper>
                        {!loading ? <StyledTree
                            virtualized
                            inline={true}
                            rowHeight={38}
                            data={dataOther}
                            valueKey="id"
                            labelKey="name"
                            height={500}
                            onSelect={(item) => {
                                setActiveOtherAccountKey(item.id);
                            }}
                        /> : <StyledTreeLoader
                            size="lg"
                        />}
                    </StyledTreeWrapper>
                </StyledAccountList>
                <StyledAccountTransferButton  width="80px" hSpace={'20px'}>
                    <Button 
                        onClick={handleMoveAccountToMain}
                        disabled={!activeOtherAccountKey}
                    >&gt;</Button>
                    <Button 
                        onClick={handleMoveAccountToOther}
                        disabled={!activeMainAccountKey}
                    >&lt;</Button>
                    <Button
                        onClick={handleMoveAllAccountsToMain}
                        disabled={!groupsAreExists}
                    >&gt;&gt;</Button>
                    <Button
                        onClick={handleMoveAllAccountsToOther}
                        disabled={!groupsAreExists}
                    >&lt;&lt;</Button>
                </StyledAccountTransferButton>
                <StyledAccountList hSpace={'20px'}>
                    <StyledTreeWrapper>
                        {!loading ? <StyledTree
                            virtualized
                            inline={true}
                            rowHeight={38}
                            data={data}
                            valueKey="id"
                            labelKey="name"
                            height={500}
                            onSelect={(item) => {
                                setActiveMainAccountKey(item.id);
                            }}
                        /> : <StyledTreeLoader
                            size="lg"
                        />}
                    </StyledTreeWrapper>
                </StyledAccountList>
            </StyledFlexGrid>}
            {/* {loading && <Loader backdrop size="sm"/>} */}
        </>
    )
};

const StyledTreeLoader = styled(Loader)`
    && {
        height: 500px;
        width: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const StyledTreeWrapper = styled.div`
    position: relative;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 100%;
    overflow: hidden;
    
    .rs-picker-none {
        border-top: 2px solid transparent;
        height: 300px;
        width: 100%;
    }
`;

const StyledTree = styled(TreePicker)`
    && {
        padding-top: 0;
        height: 500px;
        max-height: 500px;
    }
  
    .rs-tree-node {
        padding: 0 !important;
    }
    
    &&& .rs-tree-node-label-content {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
    
    .rs-tree-node-label-content {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    
    .rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content {
        
        &,
        &:hover {
            font-weight: normal;
        }
    }
`;

const StyledFlexGrid = styled(FlexboxGrid)`
    && {
        /* height: 500px; */
    }
`;

const StyledAccountList = styled(FlexboxGrid.Item)`
    && {
        width: 400px;
        min-height: 500px;
        height: 500px;
    }
`;

const StyledAccountTransferButton = styled(FlexboxGrid.Item)`
    && {
        display: flex;
        flex-direction: column;
        margin-left: 25px;
        margin-right: 25px;
    }

    && .rs-btn {
        margin-bottom: 15px;
        width: 60px;
    }
`;