import {CDR_DIALER as PREFIX} from '../../const';
import {Alert} from "rsuite";
import {api, getFileResponse} from '../../api/loginRoutes';
import {_setValue} from './defaults';
import {servicePick, downloadDataAsFile} from '../../utils/helpers';
import {SET_UPLOAD_LOADING_DIALER} from '../../const';
import {CDR_LIST_DIALER_API, SMS_MDR_LIST_DIALER_API} from "../../const/apiMethods";
import { C } from 'jssip';

const setTestCalls = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);
const setUploadLoading = _setValue(SET_UPLOAD_LOADING_DIALER);

export const getDialerCDRList = (service = true, per_page = 12, filter = {}, page = 1) => (dispatch) => {
    dispatch( setLoading(true) );
    // api method not found
    const method = service ? CDR_LIST_DIALER_API : SMS_MDR_LIST_DIALER_API;

    api(method, {per_page, filter, page})
        .then((response) => {
            if (response === undefined) {
                dispatch( setLoading(false) );
                return;
            } else if (response?.error && response.error?.data) {
                // const interval = response.error.data?.time_interval_days;
                // Alert.error(`The filter is only available for the selected ${interval} days`);
                dispatch( setLoading(false) );
                return;
            }

            dispatch(setTestCalls({
                cdr_full_list: service ? response.cdr_full_list : response.mdr_full_list,
                cdr_full_count: service ? response.cdr_full_count : response.mdr_full_count,
                cdr_full_more: service ? response.cdr_full_more : response.cdr_full_more,
                cdr_full_summaries: service ? response.cdr_full_summaries : response.cdr_full_summaries,
                page,
                per_page
            }));
        })
        .catch(() => {
            dispatch( setLoading(false) );
        });
};

export const setEmptyCDRList = () => (dispatch) => {
    dispatch( setTestCalls({
        cdr_full_count: 0,
        cdr_full_list: [],
        page: 1
    }) );
};


export const exportDialerCDR = (service = true, filter = {}) => async (dispatch) => {
    dispatch(setUploadLoading(true));
    const method = servicePick(service, CDR_LIST_DIALER_API, SMS_MDR_LIST_DIALER_API);
    const fileName = servicePick(service, 'cdr.csv', 'mdr.csv');
    await getFileResponse(method, {filter}).then((response) => downloadDataAsFile(response,fileName));
    dispatch(setUploadLoading(false));
};
