import React, {useMemo, useState, useEffect, useCallback} from "react";
import HawalaPaymentRequestTable from "./HawalaPaymentRequestTable";
import {Spacer} from "../../../components/base/Spacer";
import {FlexboxGrid} from "rsuite";
import HawalaPaymentRequestFilter from "./HawalaPaymentRequestFilter";
import ModalHawalaRequest from "./ModalHawalaRequest";
import ModalHawalaResult from "./ModalHawalaResult";
import HawalaPaymentRequestApiFilter from "./HawalaPaymentRequestApiFilter";
import {ButtonPrimary} from "../../../components/base/BaseButton";
import styled from "styled-components";
import {downloadDataAsFile} from "../../../utils";
import { api, getFileResponse } from "api/loginRoutes";
import {currency_data} from "const";
import {PAYMENT_REQUEST_HAWALA_GET_LIST_API} from "const/apiMethods";


export default ({
    currencyList,
    requestPayment,
    paymentMethodList,
    paymentTermsList,
    data,
    service,
    loading,
    fetchData,
    accountManagerList,
    exportHawalaPayments,
    getAccountManagersDropdownList
}) => {

    const accountManagersFiltered = accountManagerList.filter((value) => value.active || value.has_accounts);

    const defaultFilter = {
        payment_terms_name: "", account_manager_id: "", minimum_payment: 0, show_closed_account: false
    };

    const hawalaMethod = useMemo(() => paymentMethodList && paymentMethodList.length && paymentMethodList.find(item => item.pm_key === 5), [paymentMethodList]);

    const savedFilterMinimumPayment = localStorage.getItem("PAYMENT_MANAGMENT_HAWALA_FILTER_MINIMUM_PAYMENT") || hawalaMethod.limit || 0;

    const [apiFilter, setApiFilter] = useState({str: ""});
    const [filter, setFilter] = useState({...defaultFilter, minimum_payment: +savedFilterMinimumPayment});
    const [selectedItems, setSelectedItems] = useState([]);
    const [requestedPayments, setRequestedPayments] = useState([]);

    const [modalRequestShow, setModalRequestShow] = useState(false);
    const [modalRequestLoading, setModalRequestLoading] = useState(false);
    const [modalResultShow, setModalResultShow] = useState(false);
    const [clearSelectedData, setClearSelectedData] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);

    useEffect(() => {
        getAccountManagersDropdownList()
    }, [])

    useEffect(() => {
        fetchData({filter: apiFilter});
    }, [apiFilter]);

    const handleShowModalRequest = useCallback(() => setModalRequestShow(true), []);

    const exportPayments = async () => {
        setModalRequestLoading(true);
        
        const selectedItemsKeys = getKeysFromSelectedItems(selectedItems);
        const selectedAccounts = data.filter(item => {
            return selectedItemsKeys.includes(item.id);
        }).map(item => {
            return {...item, ...getValueFromSelectedItems(item.id, selectedItems)}
        });

        const accountList = selectedAccounts.map((item) => {
            return {
                account_id: item.id,
                amount: item.available_for_payment,
                cur_key: currency_data[item.currency]
            }
        });

        await exportHawalaPayments(accountList)
            .then((response) => {
                if (response?.hawala_export_account_list) {
                    setRequestedPayments(response.hawala_export_account_list);
                }

                fetchData({filter: apiFilter});
            })
            .finally(() => {
                setModalRequestLoading(false);
                setModalRequestShow(false);
                setModalResultShow(true);
                setClearSelectedData(true);
            })

    };

    const downloadLastPayments = () => {
        const method = PAYMENT_REQUEST_HAWALA_GET_LIST_API;

        const params = {filter: {str: ""}, get_last24h_payments: true};

        getFileResponse(method, params, "csv")
            .then((response) => {
                downloadDataAsFile(response, "last_payments.csv");
            });
    }

    const getKeysFromSelectedItems = (items) => {
        return items && Object.keys(items).length && Object.keys(items) || [];
    };

    const getValueFromSelectedItems = (key, items) => {
        const valueObject = items && items.hasOwnProperty(key) && items[key] !== undefined && Object.keys(items[key]).length && items[key];
        const resultObject = Object.entries(valueObject).filter(([_, value]) => {
            return !!value;
        }).reduce((result, [key, value]) => {
            result[key] = value
            return result
        }, {}) || {};

        return resultObject
    };

    return (
        <>
            <FlexboxGrid>
                <FlexboxGrid.Item>

                    <HawalaPaymentRequestApiFilter
                        onChange={setApiFilter}
                    />

                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <HawalaPaymentRequestFilter
                        filters={filter}
                        hawalaMethod={hawalaMethod}
                        account_manager_list={accountManagersFiltered}
                        paymentTermsList={paymentTermsList}
                        onChangeFilters={(value) => {
                            setFilter(value);
                        }}
                    />
                </FlexboxGrid.Item>
                <StyledItemAutoMargin>
                    <MRButtonPrimary
                            // disabled={!getKeysFromSelectedItems(selectedItems).length}
                        onClick={downloadLastPayments}
                    >
                        Last payments
                    </MRButtonPrimary>
                    <ButtonPrimary
                        disabled={!getKeysFromSelectedItems(selectedItems).length}
                        onClick={handleShowModalRequest}
                    >
                        Export payments
                    </ButtonPrimary>
                </StyledItemAutoMargin>
            </FlexboxGrid>

            <Spacer/>

            <HawalaPaymentRequestTable
                hawalaMethod={hawalaMethod}
                filter={filter}
                data={data}
                loading={loading}
                setSelected={(value) => setSelectedItems(value)}
                {...{
                    service,
                    currencyList,
                    setSelectedCurrency,
                    clearSelectedData,
                    setClearSelectedData
                }}
            />

            <ModalHawalaRequest
                show={modalRequestShow}
                onClose={() => setModalRequestShow(false)}
                onSuccess={exportPayments}
                loading={modalRequestLoading}
                {...{
                    data,
                    selectedItems,
                    getKeysFromSelectedItems,
                    getValueFromSelectedItems
                }}
            />

            <ModalHawalaResult
                show={modalResultShow}
                data={requestedPayments}
                onClose={() => setModalResultShow(false)}
            />
        </>
    )
};

const MRButtonPrimary = styled(ButtonPrimary)`
    && {
        margin-right: 10px;
    }
`;

const StyledItemAutoMargin = styled(FlexboxGrid.Item)`
    && {
        margin-left: auto;
    }
`