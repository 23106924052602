import { countDecimals } from 'utils';
import {NEWS as PREFIX} from '../const';

const initialState = {
    list: [],
    count: 0,
    listDialer: [],
    listDialerCount: 0,
    widgetPost: null,
    loading: true,
    inited: false,
    countDialer: null,
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {list, count}}) => ({
        ...state, 
        list: list, 
        count: count,
        loading: false, 
        inited: true,
    }),
    ['SET_DIALER_ITEMS_' + PREFIX]: (state, {payload: {list, count}}) => ({
        ...state, 
        listDialer: list,
        listDialerCount: count,
        loading: false, 
        inited: true,
    }),
    ['SET_DIALER_COUNT_' + PREFIX]: (state, {payload: {countDialer, post}}) => ({
        ...state, 
        countDialer: countDialer,
        widgetPost: post,
        loading: false
    }),
    ['CLEAR_DIALER_COUNT_' + PREFIX]: (state) => ({
        ...state, 
        countDialer: null,
        widgetPost: null,
    }),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}