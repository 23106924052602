import {
    APP_TYPE_ADMIN, APP_TYPE_CLIENT, DEFAULT_SP_KEY, DEFAULT_PRICE_KEY, SMS_DEFAULT_SP_KEY,
    REFERENCES_DIALER_TRUNK, SET_UPLOAD_LOADING_DIALER, SET_INITED_REFERENCFES, SMS_DEFAULT_PRICE_KEY,
    transformed_service_data
} from '../const/';
import { REFERENCES_ALL_SET_LISTS, REFERENCES_SET_LISTS, REFERENCES_SET_LOADING, REFERENCES_REPORT_SET_LOADING } from '../actions/actionTypes'
import { C } from 'jssip';
import { servicePick, pickObjectExist, getServiceByLocation } from '../utils/helpers';
import history from '../config/history';

const initialState = {
    appType: null,
    loading: false,
    reportLoading: false,
    price_list: [],
    currency_list: [],
    country_list: [],
    payment_terms_list: [],
    payment_terms_supplier_list: [],
    account_manager_list: [],
    worldzone_list: [],
    destination_list: [],
    subdestination_list: [],
    protocol_list: [],
    service_plan_list: [],
    "sms.service_plan_list": [],
    service_plan_group_list: [],
    service_plan_list_filtered: [],
    client_list: [],
    dialer_list: [],
    "sms.dialer_list": [],
    "sms.reason_list": [],
    supplier_list: [],
    "sms.supplier_list": [],
    dialerTrunkList: [],
    defaultSPKey: DEFAULT_SP_KEY,
    defaultPriceKey: DEFAULT_PRICE_KEY,
    client_role_list: [],
    uploadLoadingDialer: false,
    support_contacts: [],
    contacts_text: null,
    rules_and_regulations_link_arabic: '',
    rules_and_regulations_link_english: '',
    rules_and_regulations_text_arabic: '',
    rules_and_regulations_text_english: '',
    how_find_list: [],
    raterc_list: [],
    inited: false,
    initedService: null,
    sms_not_deployed: {},
    voice_not_deployed: {},
    sms_supplier_transit_trunk_limit_day: null,
    serviceByLocation: getServiceByLocation(history.location),
};

const handlers = {    
    [REFERENCES_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [REFERENCES_REPORT_SET_LOADING]: (state, {payload}) => {
        return {
            ...state,
            reportLoading: payload
        }
    },

    [REFERENCES_SET_LISTS]: (state, {payload: data}) => {
        const {accountJoinList: accountList, ...references} = data;
        const appType = references?.appType;
        let serviceByLocation;

        if (appType === APP_TYPE_CLIENT) {
            const singleAccount = accountList.length === 1 ? accountList[accountList.length-1] : {};
            serviceByLocation = accountList.length > 1 ? getServiceByLocation(history.location) : transformed_service_data[singleAccount.service];
        }
 
        const service = references.service !== null ? references.service : serviceByLocation;

        let serviceReferenceObject = {};
        const pickedReferenceNotDeployed = service !== null ? servicePick(service, references.voice, references.sms) : pickObjectExist(references.voice, references.sms);

        const referenceByService = service === true ? {
            ...references.voice,
            defaultSPKey: references?.voice?.service_plan_list?.[0]?.sp_key || DEFAULT_SP_KEY,
            defaultPriceKey: references?.voice?.price_list?.[0]?.p_key || DEFAULT_PRICE_KEY,
        } : service === false ? {
            ...references.sms,
            defaultSPKey: references?.sms?.service_plan_list?.[0]?.sp_key || SMS_DEFAULT_SP_KEY,
            defaultPriceKey: references?.sms?.price_list?.[0]?.p_key || SMS_DEFAULT_PRICE_KEY,
        } : {};

        serviceReferenceObject = referenceByService;

        if (referenceByService === null && accountList.length === 1) {
            serviceReferenceObject = pickedReferenceNotDeployed;
        }

        return ({
            ...state,
            ...references,
            ...serviceReferenceObject,
            sms_not_deployed: references.sms,
            voice_not_deployed: references.voice,
            loading: false,
            inited: true,
            initedService: service
        })
    },

    [REFERENCES_ALL_SET_LISTS]: (state, {payload}) => ({
        ...state,
        ...payload
    }),

    [REFERENCES_DIALER_TRUNK]: (state, {payload: dialerTrunkList}) => ({...state, dialerTrunkList}),

    [SET_INITED_REFERENCFES]: (state, {payload}) => ({...state, inited: payload}),

    [SET_UPLOAD_LOADING_DIALER]: (state, {payload}) => ({...state, uploadLoadingDialer: payload}),

    DEFAULT: state => state
};


export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};
