import {
    SUPPLIERS as PREFIX,
    DROPDOWN_SUPPLIERS,
    SUPPLIERS_PREFIX,
    SUPPLIERS_PLAN,
    VIEW_SUPPLIER,
    SUPPLIERS_GROUP,
    DEFAULT_PER_PAGE_SMALL,
    SUPPLIER_DROPDOWN_PREFIX
} from '../const/';

import store from '../store/index';

const initialState = {
    loading: false,
    items: [],
    dropdown_list: [],
    currentSupplier: {},
    newCreatedAcccountId: null,
    suppliersPlanList: [],
    suppliersPlanLoading: false,
    suppliersPrefixList: [],
    suppliersPrefixListCount: 0,
    suppliersPrefixListPage: 1,
    suppliersPrefixListPerPage: +localStorage.getItem('allocatedNumbersPerPage') || DEFAULT_PER_PAGE_SMALL,
    suppliersPrefixDropdownList: [],
    suppliersPrefixDropdownCount: 0,
    suppliersPrefixDropdownPage: 1,
    suppliersPrefixDropdownPerPage: DEFAULT_PER_PAGE_SMALL,
    suppliersGroupList: [],
    suppliersGroupLoading: false,
    suppliersPrefixLoading: false
};


const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload}) => ({
        ...state,
        items: payload.items,
        loading: false
    }),
    ['SET_ITEMS' + SUPPLIERS_PLAN]: (state, {payload: {suppliersPlanList}}) => ({
        ...state,
        suppliersPlanList: suppliersPlanList
    }),
    ['SET_LOADING_' + SUPPLIERS_PLAN]: (state, {payload}) => {
        return {
            ...state,
            suppliersPlanLoading: payload
        }
    },
    ['SET_ITEMS' + SUPPLIERS_PREFIX]: (state, {payload: {suppliersPrefixList, count, page, per_page}}) => {
        return {
            ...state,
            suppliersPrefixList: suppliersPrefixList,
            suppliersPrefixListCount: count,
            suppliersPrefixListPage: page,
            suppliersPrefixListPerPage: per_page
        }
    },
    ['SET_ITEMS' + SUPPLIER_DROPDOWN_PREFIX]: (state, {payload: {suppliersPrefixDropdownList, count, page, per_page}}) => {
        return {
            ...state,
            suppliersPrefixDropdownList: suppliersPrefixDropdownList,
            suppliersPrefixDropdownCount: count,
            suppliersPrefixDropdownPage: page,
            suppliersPrefixDropdownPerPage: per_page
        }
    },
    ['SET_LOADING_' + SUPPLIERS_PREFIX]: (state, {payload}) => {
        return {
            ...state,
            suppliersPrefixLoading: payload
        }
    },
    ['SET_ITEMS' + SUPPLIERS_GROUP]: (state, {payload: {suppliersGroupList}}) => {
        return {
            ...state,
            suppliersGroupList: suppliersGroupList
        }
    },
    ['SET_LOADING_' + SUPPLIERS_GROUP]: (state, {payload}) => {
        return {
            ...state,
            suppliersGroupLoading: payload
        }
    },
    [VIEW_SUPPLIER]: (state, {payload}) => ({...state, currentSupplier: payload}),
    ['SET_NEW_ACCOUNT_ID']: (state, {payload}) => ({...state, newCreatedAcccountId: payload}),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    ['SET_ITEMS_' + DROPDOWN_SUPPLIERS]: (state, {payload}) => ({...state, dropdown_list: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};
