import React, {useEffect, useState, useMemo} from "react";
import PageIvrSpecialCliFilter from "./PageIvrSpecialCliFilter";
import PageIvrSpecialCliTable from "./PageIvrSpecialCliTable";
import {Spacer} from "../../../components/base/Spacer";
import {api} from "../../../api/loginRoutes";
import {
    IVR_CLI_CREATE_API,
    IVR_CLI_MODIFY_API,
    IVR_CLI_REMOVE_API
} from "../../../const/apiMethods";


export default (
    {
        list,
        loading,
        ivrList,
        getIvrCliList,
        getIvrList,
    }
) => {

    const [filter, setFilter] = useState({
        str: localStorage.getItem("active-ivr-name") || "",
    });

    useEffect(() => {
        if (!filter.str) {
            getIvrCliList();
            getIvrList();
        }
    }, []);

    useEffect(() => {
        getIvrCliList(filter);
        localStorage.removeItem("active-ivr-name");
    }, [filter]);

    const handleSubmit = (formValue, data) => {
        
        const params = {
            target: {
                ivr_id: formValue.ivr_id
            },
            prefix: formValue.prefix || data.prefix
        };

        if (formValue.description) {
            params.description = formValue.description;
        }

        const method = data ? IVR_CLI_MODIFY_API : IVR_CLI_CREATE_API;

        return api(method, params).then(r => r)
    };

    const handleRemove = (prefix) => {
        return api(IVR_CLI_REMOVE_API, {
            prefix: prefix
        }).then(r => r)
    };


    return (
        <>
            <Spacer size={30}/>
            
            <PageIvrSpecialCliFilter
                style={{flexShrink: 0}}
                {...{
                    filter,
                    setFilter
                }}
            />

            <Spacer size={30}/>

            <PageIvrSpecialCliTable
                data={list}
                filter={filter}
                loading={loading}
                getIvrCliList={getIvrCliList}
                ivrList={ivrList}
                handleSubmit={handleSubmit}
                handleRemove={handleRemove}
            />
        </>
    )
};