import React, {useState, useMemo, useEffect} from "react";
import {Whisper, Tooltip, Icon} from "rsuite";
import TableServerSort from "components/Table/TableServerSort";
import styled, {css} from "styled-components";
import Table from "components/Table";
import { transformed_service_data } from "const";
import {useIntl} from "react-intl";
import m from "definedMessages";

export default ({
    accessList = [], 
    getItems, 
    accessListLoading = false, 
    page = 1, 
    count = 0, 
    per_page,
    paymentTermsList,
    service_plan_list,
    service,
    extended,
    filters,

    setExtended,
    ...props
}) => {
    const {formatMessage} = useIntl();

    const columns = useMemo(() => {
        return [
            {
                label: "Type", 
                dataKey: "sp_key",
                value: ({service_plan_name}) => service_plan_name,
                // sortable: true,
                width: 130,
            },
            ...(!service ? [{
                label: "Supplier", 
                dataKey: "supplier",
                value: ({supplier}) => {
                    return supplier;
                },
                sortable: false,
                flexGrid: 1,
                minWidth: 210
            }] : []),
            {
                label: "Access origin", 
                dataKey: service ? "access_origin" : "origin",
                value: ({a_description, a_number}) => {
                    return (
                        <SecondStagedCell>
                            <div className="table-two-staged__cell-first">
                                {a_description}
                            </div>
                            {a_number && <div className="table-two-staged__cell-second">
                                {a_number}
                            </div>}
                        </SecondStagedCell>
                    )
                },
                sortable: true,
                // flexGrid: 1,
                ...(service ? {flexGrid: 1, minWidth: 210} : {width: 130})
            },
            {
                label: "Access destination", 
                dataKey: service ? "access_destination" : "destination",
                value: ({b_description, b_number}) => {
                    return (
                        <SecondStagedCell>
                            <div className="table-two-staged__cell-first">
                                {b_description}
                            </div>
                            {b_number && <div className="table-two-staged__cell-second">
                                {b_number}
                            </div>}
                        </SecondStagedCell>
                    )
                },
                sortable: true,
                flexGrid: 1,
                minWidth: 210
            },
            {
                label: "Test numbers",
                dataKey: "b_test_number_list",
                value: ({b_test_number_list}) => {
                    const data = b_test_number_list && b_test_number_list.join(", ");
                    return (
                        <Whisper
                            enterable
                            placement="bottomStart"
                            speaker={
                                <Tooltip>{data}</Tooltip>
                            }
                        >
                            <span>{data}</span>
                        </Whisper>
                    )
                },
                flexGrid: 1,
                minWidth: 200
            },
            {
                label: "Сomment", 
                dataKey: "comment",
                flexGrid: 1,
                minWidth: 180
            },
            {
                id: "message",
                label: "Message",
                dataKey: "message",
                value: ({message}) => {
                    return <Whisper
                        enterable
                        placement="auto"
                        speaker={
                            <Tooltip>{message}</Tooltip>
                        }
                    >
                        <span>{message}</span>
                    </Whisper>
                },
                flexGrow: 2,
                minWidth: 160,
                hideOnService: transformed_service_data["voice"]
            },
            {
                id: "limit_day",
                label: "Limits",
                dataKey: "limit_day",
                value: ({limit_day, limit_hour}) => {
                    
                    const limitDay = limit_day ? `${limit_day} SMS per day` : "";
                    const limitHour = limit_hour ? `${limit_hour} SMS per hour` : "";
                    const hasBothLimits = limit_day && limit_hour;
                    const signleLimit = limit_day ? limitDay : limit_hour ? limitHour : "";

                    return hasBothLimits ? <SecondStagedCell same>
                            <div className="table-two-staged__cell-first">
                                {limitDay}
                            </div>
                            <div className="table-two-staged__cell-second">
                                {limitHour}
                            </div>
                        </SecondStagedCell>
                    : signleLimit;
                },
                // flexGrow: 1,
                width: 135,
                minWidth: 160,
                hideOnService: transformed_service_data["voice"]
            }
        ].filter(column => "hideOnService" in column ? column.hideOnService !== service : true)
    }, [service]);

    const extenedTypeColumns = [
        {
            label: "Originator", 
            dataKey: "originator",
            value: ({orig_name, orig_trpefix}) => {
                const text = `${orig_name || ""}${orig_trpefix ? " / " : ""}${orig_trpefix || ""}`;
                return <Whisper
                        enterable
                        placement="auto"
                        speaker={
                            <Tooltip>{text}</Tooltip>
                        }
                    >
                        <span>{text}</span>
                    </Whisper>
            },
            sortable: true,
            flexGrid: 1,
            minWidth: 210
        },
        {
            label: "Rate in", 
            dataKey: "rate",
            value: ({orig_rate, orig_currency}) => {
                return `${orig_rate} ${orig_currency}`;
            },
            sortable: false,
            flexGrid: 1,
            minWidth: 210
        },
        {
            label: "Access origin", 
            dataKey: "origin",
            value: ({a_description, a_number}) => {
                return (
                    a_description && a_number 
                        ? <SecondStagedCell>
                        <div className="table-two-staged__cell-first">
                                {a_description}
                            </div>
                            <div className="table-two-staged__cell-second">
                                {a_number}
                            </div>
                        </SecondStagedCell>
                        : a_description
                )
            },
            sortable: true,
            flexGrid: 1,
            minWidth: 210
        },
        {
            label: "Range", 
            dataKey: "phone",
            value: ({b_number}) => b_number,
            sortable: true,
            flexGrid: 2,
            minWidth: 160
        },
        {
            label: "Destination", 
            dataKey: "destination",
            value: ({b_description}) => b_description,
            sortable: true,
            flexGrid: 1,
            minWidth: 210
        },
        {
            label: "Numbers", 
            dataKey: "numbers",
            value: ({b_numbers}) => {
                return b_numbers;
            },
            sortable: true,
            flexGrid: 1,
            minWidth: 210
        },
        {
            label: "Dialer numbers", 
            dataKey: "b_numbers_atx",
            sortable: false,
            flexGrid: 1,
            minWidth: 210
        }
    ];

    const paymentColumns = paymentTermsList.map((term) => {
        return  {
            id: `payout_${term.name}`,
            label: `Payout ${term.name}`,
            value: (({payment_terms_rate_list}) => {
                if (payment_terms_rate_list) {

                    const termsRate = payment_terms_rate_list
                        ? payment_terms_rate_list.find(item => item.pt_key === term.pt_key)
                        : null;

                    let currency = payment_terms_rate_list
                        ? payment_terms_rate_list.find(value => value.rate !== null)
                        : null;
                    
                    if (currency) {
                        currency = currency.currency_name;
                    }
                    if (termsRate && termsRate.rate && currency) {
                        return `${Number(termsRate.rate).toFixed(4)} ${currency ? currency : ""}`;
                    } else {
                        return "—";
                    }
                } else {
                    return "—";
                }
            }),
            width: 100.1234,
            align: "center",
            filterable: true
        }
    });
    const endPositionColumns = [
        {
            
            label: "Date", 
            dataKey: "datetime",
            sortable: true,
            width: 150
        }
    ];

    const combinedColumns = [
        ...columns,
        ...paymentColumns,
        ...(!extended ? endPositionColumns : [])
    ];
    

    const [tableColumns, setTableColumns] = useState(combinedColumns);

    useEffect(() => {
        const pickedColumns = extended ? extenedTypeColumns : columns;
        setTableColumns(pickedColumns);
    }, [extended, ]);

    useEffect(() => {
        const pickedColumns = extended ? extenedTypeColumns : columns;
        const combinedColumns = [
            ...pickedColumns,
            ...paymentColumns,
            ...(!extended ? endPositionColumns : [])
        ];

        if (extended) {
            if (filters.sp_key) {
                setTableColumns(combinedColumns);
            } else {
                setTableColumns(pickedColumns);
            }
        } else {
            setTableColumns(combinedColumns);
        }
    }, [filters, paymentTermsList]);

    return (
        <TableServerSort
            data={accessList}
            loading={accessListLoading}
            columns={tableColumns}
            originalColumns={tableColumns}
            count={count}
            per_page={per_page}
            page={page}
            getItems={getItems}
            ispagination
            showedCount={count}
            {...props}
        />
    )
}


const StyledTooltipIcon = styled(Icon)`
    margin-left: 5px;

    .rtl & {
        margin-left: 0;
        margin-right: 5px;
    }
`;

const SecondStagedCell = styled.div`
    margin-top: -7px;
    
    .table-two-staged__cell-first {
        ${props => props.same && css`
            font-size: 13px;
        `}
    }
    .table-two-staged__cell-second {
        margin-top: -4px;
        font-size: 12px;
        ${props => !props.same ? css`
            color: var(--color-brand1);
        ` : css`
            font-size: 13px;
        `}
        opacity: 1;
    }
`;
