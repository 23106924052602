import React, {useEffect, useState} from "react";
import AccessListEditModal from "./AccessListModals/AccessListEditModal";
import AccessListTable from './AccessListTable/AccessListTable';
import styled from 'styled-components';
import {Button, FlexboxGrid} from 'rsuite';

export default ({
    currentSupplier,
    suppliersPlanList,
    getSupplierPlanList,
    createSupplierPlan,
    modifySupplierPlan,
    removeSupplierPlan,
    showReasonHandler,
    loading
}) => {

    const [showAccessListEditModal, setShowAccessListEditModal] = useState(false);
    const [currentAccessListData, setCurrentAccessListData] = useState({});

    const supplierId = currentSupplier?.supplier_id;
   
    useEffect(() => {
        if (supplierId)
            getSupplierPlanList(supplierId);
    }, [currentSupplier]);

    const onUpdateData = () => {
        if (supplierId)
            getSupplierPlanList(supplierId);
    };

    return (
        <>  
            <AccessListTable
               data={suppliersPlanList}
               onUpdateData={onUpdateData}
               showReasonHandler={showReasonHandler}
               loading={loading}
            />
            
            <AccessListEditModal
                 show={showAccessListEditModal}
                 currentData={currentAccessListData}
                 toCreate={true}
                 onClose={() => {
                     setShowAccessListEditModal(false);
                 }}
                 onSuccess={() => {
                     setShowAccessListEditModal(false);
                     onUpdateData();
                 }}
                 disabled={loading}
            />
        </>
    )
};

const CreateButton = styled(Button)`
    && {
        margin-bottom: 10px
    }
`;