import React, {useState} from 'react';
import {Alert, Checkbox, DatePicker, SelectPicker} from 'rsuite';
import ModalSendApi from '../../../components/Modal/ModalSendApi'
import ApiRequest from '../../../components/ApiRequest'
import {useWindowWidth} from "../../../hooks";
import { getServiceSmsPrefix } from 'utils';


export default ({service=true, initialValues, target, getCount, entity, raterc_list, update, ...props}) => {
    const [billingIncrementType, setBillingIncrementType] = useState(null);

    const windowWidth = useWindowWidth();

    const numbersCount = getCount();

    const setRateMethod = getServiceSmsPrefix(service, "trunk_number__filter:set_raterc");

    return (
        <ModalSendApi
            {...props}
            title="Change Billing type"
            successText="Yes"
            extraDisabled={!billingIncrementType}
        >
            <ApiRequest
                method={setRateMethod}
                checkResultKey="raterc_numbers_list"
                target = {{...target}}
                update = { () => {
                    update();
                    const billingType = raterc_list.find(type => type.rrc_key === billingIncrementType);
                    Alert.success(`Billing type for ${numbersCount} numbers was updated to ${billingType?.name}`)
                }}
                data={{
                    ...initialValues,
                    rrc_key: billingIncrementType
                }}
            >
                <div>
                    <p>Billing type for selected <strong>{numbersCount}</strong> {numbersCount === 1 ? "number" : "numbers"} from this trunk will changed. Do you really want to do it?</p>
                    <div
                        style={{textAlign: windowWidth >= 600 ? 'center' : 'left'}}
                        className="mt-5"
                    >
                        <span className="mr-4">Change billing type to:</span>
                        <SelectPicker
                            data={raterc_list}
                            cleanable={false}
                            searchable={false}
                            placeholder={'Select billing type'}
                            valueKey="rrc_key"
                            labelKey="name"
                            onChange={(key) => setBillingIncrementType(key || null)}
                            style={{
                                width: 230
                            }}
                        />
                    </div>
                </div>
            </ApiRequest>
        </ModalSendApi>
    );
};