export default {

    // customer fields
    customer_name: {
        name: "customer_name",
        label: "Name",
        labelId: "name"
    },
    customer_last_name: {
        name: "customer_last_name",
        label: "Last name",
        labelId: "lastName"
    },
    customer_phone: {
        name: "customer_phone",
        label: "Phone",
        labelId: "phone"
    },
    tax_vat_number: {
        name: "tax_vat_number",
        label: "Tax/VAT number",
        labelId: "taxVATNumber"
    },
    ext_files: {
        name: "ext_files",
        type: "file",
        label: "Attachments",
        labelId: "attachments"
    },
    customer_cntr_code: {
        name: "customer_cntr_code",
        type: "select_country",
        label: "Country",
        labelId: "country"
    },
    customer_cntr_name: {
        name: "customer_cntr_name",
        label: "Country",
        labelId: "country"
    },
    "customer_address.region": {
        name: "customer_address.region",
        label: "Region",
        labelId: "region"
    },
    "customer_address.city": {
        name: "customer_address.city",
        label: "City",
        labelId: "city"
    },
    "customer_address.address": {
        name: "customer_address.address",
        label: "Address",
        labelId: "address"
    },
    "customer_address.postal_code": {
        name: "customer_address.postal_code",
        label: "Postal code",
        labelId: "postalCode"
    },

    // bank fields
    bank_name: {
        name: "bank_name",
        label: "Bank name",
        labelId: "bankName"
    },
    account_iban_number: {
        name: "account_iban_number",
        label: "Account number/IBAN",
        labelId: "accountIbanNumber"
    },
    swift_number: {
        name: "swift_number",
        label: "SWIFT",
        labelId: "swift"
    },
    intermediate_bank_details: {
        name: "intermediate_bank_details",
        label: "Intermediate bank details",
        labelId: "intermediateBankDetails",
        componentClass: "textarea"
    },
    bank_cntr_code: {
        name: "bank_cntr_code",
        type: "select_country",
        label: "Bank country",
        labelId: "bankCountry"
    },
    bank_cntr_name: {
        name: "bank_cntr_name",
        label: "Bank country",
        labelId: "bankCountry"
    },
    "bank_address.region": {
        name: "bank_address.region",
        label: "Bank region",
        labelId: "bankRegion"
    },
    "bank_address.city": {
        name: "bank_address.city",
        label: "Bank city",
        labelId: "bankCity"
    },
    "bank_address.address": {
        name: "bank_address.address",
        label: "Bank address",
        labelId: "bankAddress"
    },
    "bank_address.postal_code": {
        name: "bank_address.postal_code",
        label: "Bank postal code",
        labelId: "bankPostalCode"
    },

    // beneficiary fields
    beneficiary_name: {
        name: "beneficiary_name",
        label: "Beneficiary name",
        labelId: "beneficiaryName"
    },
    beneficiary_last_name: {
        name: "beneficiary_last_name",
        label: "Beneficiary last name",
        labelId: "beneficiaryLastName",
        hideOn: [{ot_key: 2, pm_key: 1}]
    },
    beneficiary_cntr_code: {
        name: "beneficiary_cntr_code",
        type: "select_country",
        label: "Country",
        labelId: "country"
    },
    beneficiary_cntr_name: {
        name: "beneficiary_cntr_name", 
        label: "Country",
        labelId: "country"
    },
    "beneficiary_address.region": {
        name: "beneficiary_address.region",
        label: "Region",
        labelId: "region"
    },
    "beneficiary_address.city": {
        name: "beneficiary_address.city",
        label: "City",
        labelId: "city"
    },
    "beneficiary_address.address": {
        name: "beneficiary_address.address",
        label: "Address",
        labelId: "address"
    },
    "beneficiary_address.postal_code": {
        name: "beneficiary_address.postal_code",
        label: "Postal code",
        labelId: "postalCode"
    },

    // other fields
    paypal_id: {
        name: "paypal_id",
        label: "Paypal ID",
        labelId: "paypalId"
    },
    crypto_wallet_number: {
        name: "crypto_wallet_number",
        label: "Wallet number",
        labelId: "walletNumber"
    },
    crypto_currency_key: {
        name: "crypto_currency_key",
        type: "select_crypto_currency",
        label: "Crypto currency",
        labelId: "cryptoCurrency"
    },
    crypto_currency_name: {
        name: "crypto_currency_name",
        label: "Crypto currency",
        labelId: "cryptoCurrency"
    },
    crypto_network_key: {
        name: "crypto_network_key",
        type: "select_crypto_network",
        label: "Crypto network",
        labelId: "cryptoNetwork"
    },
    crypto_network_name: {
        name: "crypto_network_name",
        label: "Crypto network",
        labelId: "cryptoNetwork"
    },
    hawala_description: {
        name: "hawala_description",
        label: "Message",
        labelId: "message",
        componentClass: "textarea"
    },
    misc_description: {
        name: "misc_description",
        label: "Description",
        labelId: "description",
        componentClass: "textarea",
        rows: 10
    },
}


export const customerFields = [
    "customer_name",
    "customer_last_name",
    "customer_cntr_code",
    "customer_address.region",
    "customer_address.city",
    "customer_address.address",
    "customer_address.postal_code",
    "customer_phone",
    "ext_files"
];


export const customerConfirmFields = [
    "customer_name",
    "customer_last_name",
    "customer_cntr_name",
    "customer_address.region",
    "customer_address.city",
    "customer_address.address",
    "customer_address.postal_code",
    "customer_phone",
    "ext_files"
];

export const cryptoCustomerFields = [
    "customer_name",
    "customer_last_name",
    "customer_cntr_code",
    "customer_address.region",
    "customer_address.city",
    "customer_address.address",
    "customer_address.postal_code",
    "customer_phone"
];

export const cryptoCustomerConfirmFields = [
    "customer_name",
    "customer_last_name",
    "customer_cntr_name",
    "customer_address.region",
    "customer_address.city",
    "customer_address.address",
    "customer_address.postal_code",
    "customer_phone"
];


export const companyFields = [
    "customer_name",
    "tax_vat_number",
    "customer_cntr_code",
    "customer_address.region",
    "customer_address.city",
    "customer_address.address",
    "customer_address.postal_code",
    "customer_phone",
    "ext_files"
];

export const companyConfirmFields = [
    "customer_name",
    "tax_vat_number",
    "customer_cntr_name",
    "customer_address.region",
    "customer_address.city",
    "customer_address.address",
    "customer_address.postal_code",
    "customer_phone",
    "ext_files",
];


export const cryptoCompanyFields = [
    "customer_name",
    "tax_vat_number",
    "customer_cntr_code",
    "customer_address.region",
    "customer_address.city",
    "customer_address.address",
    "customer_address.postal_code",
    "customer_phone"
];

export const cryproCompanyConfirmFields = [
    "customer_name",
    "tax_vat_number",
    "customer_cntr_name",
    "customer_address.region",
    "customer_address.city",
    "customer_address.address",
    "customer_address.postal_code",
    "customer_phone"
];

export const bankTransferFields = [
    "beneficiary_name",
    "beneficiary_last_name",
    "beneficiary_cntr_code",
    "beneficiary_address.region",
    "beneficiary_address.city",
    "beneficiary_address.address",
    "beneficiary_address.postal_code",
    "bank_name",
    "bank_cntr_code",
    "bank_address.region",
    "bank_address.city",
    "bank_address.address",
    "bank_address.postal_code",
    "account_iban_number",
    "swift_number",
    "intermediate_bank_details",
];

export const bankTransferConfirmFields = [
    "beneficiary_name",
    "beneficiary_last_name",
    "beneficiary_cntr_name",
    "beneficiary_address.region",
    "beneficiary_address.city",
    "beneficiary_address.address",
    "beneficiary_address.postal_code",
    "bank_name",
    "bank_cntr_name",
    "bank_address.region",
    "bank_address.city",
    "bank_address.address",
    "bank_address.postal_code",
    "account_iban_number",
    "swift_number",
    "intermediate_bank_details",
];


export const paypalFields = [
    "paypal_id"
];


export const cryptoFields = [
    "crypto_wallet_number",
    "crypto_currency_key",
    "crypto_network_key"
];

export const cryptoConfirmFields = [
    "crypto_wallet_number",
    "crypto_currency_name",
    "crypto_network_name"
];



export const hawalaFields = [
    "hawala_description"
];

export const miscFields = [
    "misc_description"
];

export const otherTransferSystemsFields = [
    "beneficiary_name",
    "beneficiary_last_name",
    "beneficiary_cntr_code",
    "beneficiary_address.city"
];


export const otherTransferSystemsConfirmFields = [
    "beneficiary_name",
    "beneficiary_last_name",
    "beneficiary_cntr_name",
    "beneficiary_address.city"
];