import React from "react";
import {FormControl, FormGroup, ControlLabel, InputNumber, SelectPicker} from "rsuite";

// className="custom-field-number-form-control"
export const FieldNumber = (props) => {
    return (
        <FieldInput
            accepter={InputNumber}
            className="custom-field-number-form-control"
            {...props}
        />
    );
};

export const FieldSelect = (props) => {
    return (
        <FieldInput
            accepter={SelectPicker}
            className="custom-field-select-form-control"
            {...props}
        />
    );
};

export const FieldInput = ({name, label, width, ...props}) => {
    return (<FormGroup style={{width}}>
        {label &&
            <div>
                <ControlLabel>{label}</ControlLabel>
            </div>
        }
        <FormControl
            name={name}
            {...props}
        />
    </FormGroup>)
};