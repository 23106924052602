import styled from "styled-components";
import {Icon} from 'rsuite';

export const MenuShowColumnsIcon = styled(Icon)`
    
    && {
        position: absolute;
        z-index: 6;
        margin-left: 10px;
        margin-top: 8px;
        color: white;

        .rtl & {
            margin-left: 0;
            margin-right: 10px;
        }
    }
`;