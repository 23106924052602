import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import * as S from "./styled.js";

import {Table} from 'rsuite';
import {
    deletePriceRangePriority,
    getPossiblePriceRangePriorityList
} from 'actions/sim_traffic_ranges';
import Button from 'rsuite/es/Button';
import Modal from '../../components/Modal';
import ButtonToolbar from 'rsuite/es/ButtonToolbar';
import {MyTable} from 'components/Table';
import {connect} from 'react-redux';
import {checkPermissionsFor} from '../../store/storeHelpers'
import {SIM_TRAFFIC_RANGES_ADD_NEW, SIM_TRAFFIC_RANGES_REMOVE} from '../../const/apiMethods'

const {Column, HeaderCell, Cell} = Table;

const columns = [
    {label: 'Zone', dataKey: 'sde_wz_name', minWidth: 260, title: true},
    {label: 'Destination', dataKey: 'sde_de_name', minWidth: 260, title: true},
    {label: 'Subdestination', dataKey: 'sde_name', minWidth: 340, title: true},
    {label: 'Numbers', dataKey: 'numbers', width: 120, align: 'right'},
    {label: 'Used', dataKey: 'used_numbers', width: 120, align: 'right'},
    {label: 'Free', dataKey: 'free_numbers', width: 120, align: 'right'},
];

const SIMTrafficRangesTable = ({getList, dataList, loading, count, perPage, page, filters, setSubdestinationAddNewModal,
                                   deletePriceRangePriority, getPriceRangesPriorityList, ...props}) => {
    const [prefixToDelete, setPrefixToDelete] = useState(null);

    const getListItems = () => {
        getList(filters);
    };

    const handleDelete = (prefix) => {
        deletePriceRangePriority(prefix)
            .then(response => {
                if (response) {
                    getList(filters, 1, perPage);
                }
            });
    };
    const handleAddNew = (sde) => {
        setSubdestinationAddNewModal(sde);
    };

    useEffect(() => {
        getListItems();
    }, [filters]);

    return (
        <>
            <StyledTable
                virtualized
                data={dataList}
                loading={loading}
                columns={columns}
                getItems={getListItems}
                rowClassName={(rowData) => rowData && rowData.sde_key ? 'subdestination' : ''}
                ActionCell={ActionCell(handleAddNew, setPrefixToDelete)}
                extraHeight={600}
            />
            <Modal
                show={prefixToDelete}
                onClose={() => setPrefixToDelete(null)}
                width={400}
                title="Delete prefix"
                onSuccess={() => {
                    handleDelete(prefixToDelete);
                    setPrefixToDelete(false)
                }}
                successText="Delete"
                successButtonColor="red"
                footer
            >
                <div style={{display: 'flex', justifyContent: "center"}}>Are you sure?</div>
            </Modal>
        </>
    );
};
const mapStateToProps = ({sim_traffic_ranges}) => ({
    perPage: sim_traffic_ranges.perPage,
    page: sim_traffic_ranges.page,
    count: sim_traffic_ranges.count
});

const mapDispatchToProps = {
    deletePriceRangePriority
};
export default connect(mapStateToProps, mapDispatchToProps)(SIMTrafficRangesTable);

const ActionCell = (handleAddNew, setPrefixToDelete) =>  <Column width={120}>
    <HeaderCell>Action</HeaderCell>
    <Cell>
        {rowData => {
            return <S.StyledTableActions>
                {rowData.sde_key
                    ? <S.StyledTableButton
                        color="green"
                        disabled={!checkPermissionsFor(SIM_TRAFFIC_RANGES_ADD_NEW)}
                        onClick={() => handleAddNew(rowData)}
                        >
                        Add new range
                    </S.StyledTableButton>
                    : <S.StyledTableButton
                        color="red"
                        disabled={!checkPermissionsFor(SIM_TRAFFIC_RANGES_REMOVE)}
                        onClick={() => setPrefixToDelete(rowData.prefix)}
                    >
                        Delete
                    </S.StyledTableButton>}
            </S.StyledTableActions>
        }}
    </Cell>
</Column>
;

const StyledTable = styled(MyTable)`
  & .rs-table-row.subdestination .rs-table-cell{
     background: #e5e9f2;
  }
`;