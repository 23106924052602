import React, {useMemo, useRef} from "react";
import {ControlLabel, Form, FormControl, InputNumber, Schema} from "rsuite";
import m from "../../definedMessages";
import {useIntl} from "react-intl";
import {debounce} from "jsoneditor/src/js/util";
import styled from "styled-components";


const {NumberType} = Schema.Types;
const MIN_NUMBER = 0;
const MAX_NUMBER = 100000;

export default (
    {
        formDefaultValue = {},
        onChange
    }
) => {

    const ref = useRef(null);
    const {formatMessage} = useIntl();

    const formModel = useMemo(() => {
        return Schema.Model({
            timer: NumberType(formatMessage(m.pleaseEnterValidNumber))
                .min(MIN_NUMBER, formatMessage(m.minNumber, {number: MIN_NUMBER}))
                .max(MAX_NUMBER, formatMessage(m.maxNumber, {number: MAX_NUMBER}))
                .isInteger("It can only be an integer")
        });

    }, []);

    const handleChange = () => {
        if (!ref.current.check()) {
            return;
        }
        const formValue = ref.current.getFormValue();
        onChange && onChange(formValue);
    };

    return (
        <Form
            ref={ref}
            model={formModel}
            formDefaultValue={formDefaultValue}
            onChange={debounce(handleChange, 500)}
        >
            <StyledContent>
                <ControlLabel>{formatMessage(m.autorefreshPeriod)}</ControlLabel>
                <StyledField>
                    <FormControl
                        accepter={InputNumber}
                        name="timer"
                        min={MIN_NUMBER}
                        max={MAX_NUMBER}
                    />
                </StyledField>
            </StyledContent>
        </Form>
    )
};

const StyledContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: -10px;
    
    && > * {
        flex-shrink: 0;
        margin-bottom: 10px;
        padding-left: 5px;
        padding-right: 5px;
    }
`;
const StyledField = styled.div`
    max-width: 80px;
`;
