import React from 'react';
import {Button, List} from "rsuite";
import {useWindowWidth} from "../../hooks";
import {Spacer} from "../../components/base/Spacer";
import {CustomModal} from "../../components/base/BaseModal";

export default ({
    show,
    data = {},
    onClose,
}) => {

    const resizedWidth = useWindowWidth();

    const priceRangeListFiltered = data?.price_range_list_with_not_owned_numbers 
        ? data.price_range_list_with_not_owned_numbers.filter(range => !!range) : [];

    return (
        <CustomModal
            show={show}
            onClose={onClose}
            backdrop="static"
            width={resizedWidth > 600 ? 600 : resizedWidth}
            title="Delete Range Result"
            customFooter={() => (
                <Button onClick={onClose} appearance="primary">Ok</Button>
            )}
        >
            {!data?.error ? <>
                <p style={{textAlign: 'center'}}>Removed <b>{data.ranges}</b> ranges</p>
                {priceRangeListFiltered && priceRangeListFiltered.length
                    ? <>
                        <Spacer size={40}/>
                        Some of chosen ranges contain numbers allocated to not owned accounts and cannot be deleted:
                        <Spacer/>
                        <List size="sm">
                            {priceRangeListFiltered
                                .map(({pr_key, name}) => {
                                    return <List.Item key={pr_key}>{name}</List.Item>
                                })}
                        </List>
                    </>
                    : null
                }
            </> : <>
                <p style={{textAlign: 'center'}}>
                    {data.error && data.error.data ? data.error.data?.range_list : 'Cant delete range'}
                </p>
            </>}
        </CustomModal>
    )
};