import React, {useState, useEffect, useMemo} from "react";
import {Checkbox, Icon, SelectPicker, Table, Input, CheckboxGroup} from "rsuite";
import styled from "styled-components";
import TableServerSort from "../../components/Table/TableServerSort";
// import Checkbox from '../../hoc/Checkbox'
import {renderColumn} from "utils/helpers";


export default ({
    page,
    per_page,
    count,
    sort,
    dataList = [],
    getList,
    loading,

    getItems,

    worldzone_list = [],
    destination_list = [],
    subdestination_list = [],

    // mccMncMatchList,
    // exceptionList,
    ...props
}) => {

    const columns = useMemo(() => [
        {
            id: 'code',
            dataKey: 'code',
            align: 'left',
            className: 'code',
            label: 'Code',
            // width: 120,
            flexGrow: 1
            
        },
        {
            id: 'mcc',
            dataKey: 'mcc',
            align: 'left',
            label: 'MCC',
            className: 'mcc',
            // width: 140,
            flexGrow: 1
        },
        {
            id: 'country',
            dataKey: 'country_name',
            value: ({country_name}) => country_name,
            align: 'left',
            label: 'Country',
            className: 'country',
            // width: 260,
            flexGrow: 2
        },
        {
            id: 'mnc',
            dataKey: 'mnc',
            align: 'left',
            label: 'MNC',
            className: 'mnc',
            // width: 140,
            flexGrow: 1
        },
        {
            id: 'operator',
            dataKey: 'operator',
            align: 'left',
            label: 'Operator',
            className: 'operator',
            // width: 260,
            flexGrow: 2
        },
    ], []);

    return (
        <>
            <StyledTable
                className={'tableFilters'}
                shouldUpdateScroll={true}
                headerHeight={46}
                customHeight={700}
                wordWrap
                data={[...dataList]}
                {...{
                    loading
                }}
                getItems={getItems}
                columns={columns}
                originalColumns={columns}
                issort
                ispagination
                page={page}
                per_page={per_page}
                count={count}
                sort={sort}
                {...props}
            />
        </>
    )
}

const StyledTable = styled(TableServerSort)`
    && {
    
        .tableFilters__previewText {
            display: block;
            line-height: 20px;
            margin-top: 7px;
            word-break: normal;
        }
        
        .tableFilters_buttons {
            height: 20px;
            margin-top: 7px;
        }
    }
`;


const EditFieldError = ({text}) => (
    <div className="rs-error-message-wrapper rs-form-control-message-wrapper rs-error-message-placement-top-end">
        <span className="rs-error-message rs-error-message-show">
            <span className="rs-error-message-arrow"></span>
            <span className="rs-error-message-inner">{text}</span>
        </span>
    </div>
);

const EditField = styled(SelectPicker).attrs(props => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    type: "text",
}))`
`;

const CustomCheckbox = styled(Checkbox)`
 &&.rs-checkbox-disabled > .rs-checkbox-checker > label {
    color: inherit;
 }
`;
