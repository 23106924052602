import React, {useState, useEffect} from 'react';
import Form from 'rsuite/es/Form';
import {debounce} from '../../../utils';

const CustomSelfClearedForm = ({
    formRef,
    clearOn,
    disabled,
    formValue: _formValue,
    formDefaultValue,
    children,
    debounceWaitTime = 500,
    ...props
}) => {
    const [formValue, setFormValue] = useState(_formValue || formDefaultValue || {});

    useEffect(() => {
        if (clearOn !== null) {
            setFormValue(formDefaultValue || {});
        } 
    }, [clearOn]);

    const renderFields = (children) => {
        return React.Children.map(children, child => {

            // return a child that is not a React element
            if (!React.isValidElement(child)) {
                return child
            }

            const props = {};

            // add status "disabled" to FormControl
            if (formDefaultValue) {
                if (Object.keys(formDefaultValue).includes(child.props.name)) {
                    props['disabled'] = disabled;
                }
            }

            // clone element children if it has
            if (child.props.children) {
                child = React.cloneElement(child, {
                    ...props,
                    children: renderFields(child.props.children)
                })
            } else {
                child = React.cloneElement(child, props)
            }

            // return cloned child
            return child;
        })
    };

    return (
        <Form
            {...(formRef ? {ref: formRef} : {})}
            {...(formDefaultValue ? {formValue: formValue} : {})}

            {...props}

            onChange={(value) => {
                setFormValue(value);

                if (props?.onChange) {
                    props.onChange(value);
                }
            }}
        >
            {renderFields(children)}
        </Form>
    )
};

export default CustomSelfClearedForm;