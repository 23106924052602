import React from 'react';
import Modal from './Modal';
import {generateId} from '../../utils';


export const API_Modal =  ({
    show = true,
    extraLoader = false,
    extraDisabled = false, 
    onClose, 
    update, 
    checkFirstAll, 
    children,
    checkBeforeSubmit,
    ...props
}) => {

    let  formRefs = {};
    const [disabled, setDisabled] = React.useState(false);
    

    // clone child
    const copyChild = (child) => {
        const id = generateId();
        return React.cloneElement(child, {
            disabled: disabled || extraLoader,
            updateRef:  (ref) => ((id) => formRefs[id] = ref )(id)
        })
    }
    
    
    // clone form
    const form = Array.isArray(children)
        ? React.Children.map(children, child => copyChild(child))
        : copyChild(children);
   
    
    // submit
    const handleSubmit = async () => {
        if(checkFirstAll) {
            if(Object.keys(formRefs).map( key =>
                key && formRefs[key].check && formRefs[key].check()
            ).includes(false))
            return;
        }

        if (checkBeforeSubmit && !checkBeforeSubmit()) {
            return;
        }

        setDisabled(true);

        const results = await Promise.all(
            Object.keys(formRefs).map( key =>
                key && formRefs[key].send && formRefs[key].send()
            )
        );

        setDisabled(false);

        if(!results.includes(false)){
            onClose();
            update && update();
        }
    };
    
 
    return (
        <Modal
            show
            {...props}
            onSuccess={handleSubmit}
            disabled={disabled || extraLoader}
            extraDisabled={extraDisabled || extraLoader}
            onClose={onClose}
            footer
        >
            {form}
        </Modal>
    )
};

export default API_Modal;