import {connect} from "react-redux";
import TestCalls from "./TestCalls";
import {setEmptyTrafficStat, getDialerTestCalls} from "actions/client/testCalls";

const mapState = ({auth_dialer, client_test_calls}) => ({
    service: auth_dialer.serviceDialer,
    loading: client_test_calls.loading,
    test_calls_list: client_test_calls.test_calls_list,
    more: client_test_calls.more,
    count: client_test_calls.count,
    page: client_test_calls.page,
    per_page: client_test_calls.per_page
});

export default connect(mapState, {
    getDialerTestCalls,
    setEmptyTrafficStat
})(TestCalls);
