import { connect } from "react-redux";
import {
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    getPaymentDetailsCryptoList,

    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails
} from "../../actions/paymentDetails";
import PaymentDetails from "./PaymentDetails";

const mapState = ({paymentDetails, references, auth_dialer, auth}) => ({
    permissions: auth.permissions,
    accountJoinList: auth_dialer.accountJoinList,
    organizationTypeList: references.organization_type_list,
    countryList: references.country_list,
    currencyList: references.currency_list,
    paymentMethodList: references.payment_method_list,
    dialerInfo: auth_dialer.dialerInfo,
    data: paymentDetails.items,
    service: auth.service,
    loading: paymentDetails.loading,
    cryptoList: paymentDetails.crypto_items
});

export default connect(mapState, {
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    getPaymentDetailsCryptoList,

    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails
})(PaymentDetails);