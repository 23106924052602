import React, {useState} from 'react';
import {useIntl} from "react-intl";
import {useWindowWidth} from "../../hooks";
import {Form} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import FieldDataRange from "../../components/Form/FieldDataRange";
import m from "../../definedMessages";
import {BaseDateRangePicker} from "../../components/base/BaseForm";
import DatePickerCustom from '../../components/Filters/DatePickerCustom';


export default ({filters, onChangeFilters}) => {

    const {formatMessage} = useIntl();
    const resizedWidth = useWindowWidth();

    const todayDateStart = new Date(new Date().setHours(0, 0, 0, 0));
    const todayDateEnd = new Date(new Date().setHours(23, 59, 59, 999));

    const [changedFormValue, changeFormData] = useState({
        start_end_date: [todayDateStart, todayDateEnd],
        start_date: new Date(),
        end_date: new Date(),
    });

    return (
        <Form
            formDefaultValue={filters}
            onChange={(value) => {
                onChangeFilters(value);

                const startDate = new Date(new Date(value.start_end_date[0]).setHours(0, 0, 0, 0));
                const endDate = new Date(new Date(value.start_end_date[1]).setHours(23, 59, 59, 999));

                const formData = {...value, start_date: startDate, end_date: endDate};

                changeFormData(formData)
            }}
        >
            <FlexGrid align="middle">
                {(resizedWidth > 1005) && 
                    <FlexGridItem>{formatMessage(m.dateFrom)}</FlexGridItem>
                }

                <FlexGridItem>
                    <DatePickerCustom
                        changedFormValue={changedFormValue}
                    />
                </FlexGridItem>
            </FlexGrid>
        </Form>
    )
}