
import React, {useState} from 'react'
import Modal from '../../../components/Modal';
import {api} from '../../../api/loginRoutes';
import {
    SMS_SUPPLIER_PREFIX_REMOVE_LIST_METHOD
} from 'const/apiMethods';

export default ({show, update, trunk_id, prefixKeyList, onClose, initialValues}) => {
    const selectedPrefixKeys = prefixKeyList ? Array.from(prefixKeyList.keys()) : [];

    const [disabled, setDisabled ] = useState(false)

    const onSubmit = async () => {
        setDisabled(true);

        const method = SMS_SUPPLIER_PREFIX_REMOVE_LIST_METHOD;
        const result = await api(method, {
            target:{
                'sms.supplier_trunk_id': trunk_id
            },
            ...initialValues,
            ...(!initialValues.filterAll ? {'sms.supplier_prefix_key_list': selectedPrefixKeys} : {}) 
        });

        if(result) {
            onClose();
            update();
        }

        setDisabled(false);
    }

    return (

        <Modal 
            show={show}
            onSuccess={onSubmit}
            onClose={onClose}
            footer
            successText = "Yes"
            disabled = {disabled}
        >
            All prefixes from this trunk will be deleted. Do you really want to do it?
        </Modal>
    )
};



