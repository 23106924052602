import React, {useState, useEffect, useContext} from "react";
import {Icon, Form, FormGroup, FormControl, ControlLabel, Input, IconButton, Alert, Loader, Schema} from "rsuite";
import styled from "styled-components";
import {PermissionContext} from "../../App";
import {DIALER_GROUPS_CREATE_API, DIALER_GROUPS_MODIFY_API} from "../../const/apiMethods";
import Modal from "../../components/Modal";
import TreePicker from "../../components/TreePicker/TreePicker";

const {StringType} = Schema.Types;

const newGroupModel = Schema.Model({
    name: StringType()
        .isRequired("Required")
        .maxLength(40, "The maximum is only 40 characters.")
});


export default ({
    data,
    loading,
    activeGroupKey,
    groupsAreExists,

    handleSelectGroup,

    getDialerGroupsList,

    clearAccountsFilter,
    setActiveGroupKey,
    createDialerGroup,
    modifyDialerGroup,
    removeDialerGroup
}) => {

    const permissions = useContext(PermissionContext);

    const newGroupFormDefault = {name: null};

    const [newGroupForm, setNewGroupForm] = useState(newGroupFormDefault);

    const [modifiedGroupForm, setModifiedGroupForm] = useState(newGroupFormDefault);
    const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
    const [showModifyGroupModal, setShowModifyGroupModal] = useState(false);
    const [showRemoveGroupModal, setShowRemoveGroupModal] = useState(false);

    useEffect(() => {
        if (activeGroupKey) {
            const currentGroup = data.find(value => value.nwg_key === activeGroupKey);
            const currentData = {
                name: currentGroup?.name || ""
            };

            setModifiedGroupForm(currentData);
        }
    }, [data, activeGroupKey])

    const handleModifyGroup = () => {
        modifyDialerGroup(activeGroupKey, {...modifiedGroupForm})
            .then((response) => {
                if (response?.nwg_key) {
                    Alert.success("Group name was modified");

                    setShowModifyGroupModal(false);
                    getDialerGroupsList();
                }
            });
    };

    const handleCloseModifyGroupModal = () => {
        setShowModifyGroupModal(false);
        setModifiedGroupForm(newGroupFormDefault);
    };

    const handleRemoveGroup = () => {
        removeDialerGroup(activeGroupKey)
            .then((response) => {
                if (response?.nwg_key) {
                    Alert.success("Group was deleted");

                    setShowRemoveGroupModal(false);
                    getDialerGroupsList();
                    setActiveGroupKey(null);
                }
            });
    };

    const handleCloseRemoveGroupModal = () => {
        setShowRemoveGroupModal(false);
        setNewGroupForm(newGroupFormDefault);
    };

    const handleCreateGroup = () => {
        createDialerGroup(newGroupForm)
            .then((response) => {
                if (response?.nwg_key) {
                    Alert.success("New group was created");

                    setShowCreateGroupModal(false);
                    getDialerGroupsList();
                    setActiveGroupKey(response.nwg_key);
                }
            })
            .finally(() => {
                setNewGroupForm(newGroupFormDefault);
            });
    };

    const newGroupFormError = newGroupModel.check(newGroupForm);
    const newGroupNameFormError = newGroupFormError?.name && newGroupFormError.name?.hasError;

    const groupFormError = newGroupModel.check(modifiedGroupForm);
    const groupNameFormError = groupFormError?.name && groupFormError.name?.hasError;

    const handleCloseCreateGroupModal = () => {
        setShowCreateGroupModal(false);
        setNewGroupForm(newGroupFormDefault);
    };

    return (
        <>
            <StyledTreeWrapper>
                <BlockHeader>
                    Choose Group
                </BlockHeader>

                <StyledTree
                    virtualized
                    inline={true}
                    rowHeight={38}
                    data={data}
                    valueKey="nwg_key"
                    labelKey="name"
                    height={300}
                    value={activeGroupKey}
                    onSelect={(item) => {
                        handleSelectGroup(item.nwg_key);
                        const currentData = data.find((value) => value.nwg_key === item.nwg_key) || newGroupFormDefault;
                        setModifiedGroupForm({name: currentData?.name});
                        clearAccountsFilter();
                    }}
                />
                {permissions.includes(DIALER_GROUPS_MODIFY_API) &&
                    <StyledButtonCreate
                        appearance="link"
                        onClick={() => {
                            setShowModifyGroupModal(true);
                        }}
                        icon={ <Icon icon="edit"/>}
                        disabled={!groupsAreExists || !activeGroupKey}
                    >
                       Modify group name
                    </StyledButtonCreate>
                }
                {permissions.includes(DIALER_GROUPS_MODIFY_API) &&
                    <StyledButtonCreate
                        appearance="link"
                        onClick={() => {
                            setShowRemoveGroupModal(true);
                        }}
                        icon={ <Icon icon="trash"/>}
                        disabled={!groupsAreExists || !activeGroupKey}
                    >
                       Remove group
                    </StyledButtonCreate>
                }
                {permissions.includes(DIALER_GROUPS_CREATE_API) &&
                    <StyledButtonCreate
                        appearance="link"
                        onClick={() => {
                            setShowCreateGroupModal(true);
                        }}
                        icon={<Icon icon="plus"/>}
                    >
                        Create new group
                    </StyledButtonCreate>
                }
                {showModifyGroupModal &&
                    <Modal
                        show
                        onSuccess={handleModifyGroup}
                        onClose={handleCloseModifyGroupModal}
                        footer
                        title="Modify dialer group name"
                        successText="Confirm"
                        disabled={loading}
                        extraDisabled={groupNameFormError}
                    >
                        <Form
                            model={newGroupModel}
                            formValue={modifiedGroupForm}
                            onChange={(value) => {
                                setModifiedGroupForm(value);
                            }}
                        >
                            <StyledFormGroup className={groupNameFormError ? 'has-error' : ''}>
                                <StyledControlLabel>Type name</StyledControlLabel>
                                <StyledFormControl
                                    accepter={Input}
                                    name="name"
                                />
                            </StyledFormGroup>
                        </Form>
                    </Modal>
                }
                {showRemoveGroupModal &&
                    <Modal
                        show
                        onSuccess={handleRemoveGroup}
                        onClose={handleCloseRemoveGroupModal}
                        footer
                        title="Remove group"
                        successText="Confirm"
                        disabled={loading}
                        extraDisabled={!activeGroupKey}
                    >
                        Are you sure?
                    </Modal>
                }
                {showCreateGroupModal &&
                    <Modal
                        show
                        onSuccess={handleCreateGroup}
                        onClose={handleCloseCreateGroupModal}
                        footer
                        title="Create new dialer group"
                        successText="Confirm"
                        disabled={loading}
                        extraDisabled={newGroupNameFormError}
                    >
                        <Form
                            model={newGroupModel}
                            formValue={newGroupForm}
                            onChange={(value) => {
                                setNewGroupForm(value);
                            }}
                        >
                            <StyledFormGroup className={newGroupNameFormError ? 'has-error' : ''}>
                                <StyledControlLabel>Type name</StyledControlLabel>
                                <StyledFormControl
                                    accepter={Input}
                                    name="name"
                                />
                            </StyledFormGroup>
                        </Form>
                    </Modal>
                }
                {loading && <Loader backdrop size="sm"/>}
            </StyledTreeWrapper>
        </>
    )
};

const StyledTreeWrapper = styled.div`
    position: relative;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 100%;
    overflow: hidden;
    
    .rs-picker-none {
        border-top: 2px solid transparent;
        height: 300px;
        width: 100%;
    }
`;

const StyledTree = styled(TreePicker)`

    padding-top: 0;
  
    .rs-tree-node {
        padding: 0 !important;
    }
    
    &&& .rs-tree-node-label-content {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
    
    .rs-tree-node-label-content {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    
    .rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content {
        
        &,
        &:hover {
            font-weight: normal;
        }
    }
`;

const StyledButtonCreate = styled(IconButton)`
`;

const StyledControlLabel = styled(ControlLabel)`
    && {
        display: inline-block !important;
        margin-right: 20px;
    }
`;

const StyledFormControl = styled(FormControl)`
    && {
        width: 300px;
    }
`;

const StyledFormGroup = styled(FormGroup)`
    && .rs-form-control-wrapper {
        width: 300px;
    }
`;

const BlockHeader = styled.h5`
    font-family: inherit;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-brand1);
    padding: 20px;
`;