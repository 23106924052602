import React, {useState} from 'react';
import ModalSendApi from '../../../components/Modal/ModalSendApi';
import {Alert, SelectPicker} from 'rsuite';
import ApiRequest from '../../../components/ApiRequest';
import {useWindowWidth} from "../../../hooks";
import {createGlobalStyle} from 'styled-components';
import {getServiceSmsPrefix} from 'utils';

export default ({service=true, initialValues, target, getCount, entity, ivr_list, update, allocatedNumbers, ...props}) => {


    const windowWidth = useWindowWidth();

    const numbersCount = getCount();

    const emptyIvr = {
        id: "default",
        name: ""
    };
    const trn_key_list = initialValues?.trn_key_list;
    const selectedNumber = trn_key_list && trn_key_list.length === 1 ? allocatedNumbers.find(number => number.trn_key === trn_key_list[0]) : null;

    const [ivrId, setIvrId] = useState(selectedNumber && selectedNumber?.ivr_id);

    const sortedIvrList = [...ivr_list, emptyIvr];
    sortedIvrList.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    });


    const setIvrMethod = getServiceSmsPrefix(service, "trunk_number__filter:set_ivr");

    return (
        <>
            <GlobalStyle/>
            <ModalSendApi
                {...props}
                title="Set IVR type"
                successText="Yes"
                extraDisabled={!ivrId}
            >
                <ApiRequest
                    method={setIvrMethod}
                    checkResultKey="ivr_numbers_list"
                    target = {{...target, ivr_id: ivrId === "default" ? null : ivrId}}
                    update = { () => {
                        update();
                        const ivr = ivr_list.find(ivr => ivr.id === ivrId);
                        Alert.success(`IVR type for ${numbersCount} numbers was updated to ${ivr?.name}`)
                    }}
                    data={{
                        ...initialValues
                    }}
                >
                    <div>
                        <p>IVR type for selected <strong>{numbersCount}</strong> {numbersCount === 1 ? "number" : "numbers"} from this trunk will changed. Do you really want to do it?</p>
                        <div
                            style={{textAlign: windowWidth >= 600 ? 'center' : 'left'}}
                            className="mt-5"
                        >
                            <span className="mr-4">Set IVR to:</span>
                            <SelectPicker
                                data={sortedIvrList}
                                cleanable={false}
                                searchable={true}
                                placeholder={<>&#8203;</>}
                                valueKey="id"
                                labelKey="name"
                                value={ivrId}
                                onChange={(id) => setIvrId(id || null)}
                                style={{
                                    width: 300
                                }}
                            />
                        </div>
                    </div>
                </ApiRequest>
            </ModalSendApi>
        </>
    );
};

const GlobalStyle = createGlobalStyle`
    .rs-picker-menu .ReactVirtualized__Grid__innerScrollContainer > div:nth-child(1) .rs-picker-select-menu-item {
        padding: 18px !important;
    }
`;