import React, {useState} from "react";
import {api} from "../../../api/loginRoutes";
import {
    INCORPORATED_GROUP_REMOVE_API, 
    INCORPORATED_GROUP_REMOVE_ALLOCATED_API,
    SMS_SUPPLIER_GAN_GROUP_REMOVE_METHOD,
    SMS_SUPPLIER_GAN_GROUP_REMOVE_ALLOCATED_METHOD
} from "../../../const/apiMethods";
import {Modal} from "../../../components/Modal";
import {useWindowWidth} from "../../../hooks";
import {checkPermissionsFor} from "../../../store/storeHelpers";
import {servicePick} from "../../../utils";


export default ({
    show,
    service,
    onClose,
    id = null,
    maxWidth = 600,
    onSuccess,
    showReasonHandler,
    ...props
}) => {

    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);

    const handleSuccess = () => {
        if (!id) {
            return;
        }

        setLoading(true);
        const methodVoice = checkPermissionsFor(INCORPORATED_GROUP_REMOVE_ALLOCATED_API)
            ? INCORPORATED_GROUP_REMOVE_ALLOCATED_API
            : INCORPORATED_GROUP_REMOVE_API;
        
        const methodSms = checkPermissionsFor(SMS_SUPPLIER_GAN_GROUP_REMOVE_ALLOCATED_METHOD)
            ? SMS_SUPPLIER_GAN_GROUP_REMOVE_ALLOCATED_METHOD
            : SMS_SUPPLIER_GAN_GROUP_REMOVE_METHOD;

        const method = servicePick(service, methodVoice, methodSms);


        const params = service ? {
            ing_key: id
        } : {
            target: {
                "sms.supplier_gan_group_id": id
            }
        };

        api(method, params)
            .then((result) => {

                if (!result) {
                    return;
                }
                
                if (result.reason_code) {
                    showReasonHandler({
                        reasonCode: result.reason_code,
                        reasonHash: result.hash,
                    });
                    setLoading(false);
                    return;
                }

                onSuccess && onSuccess();
                onClose();
            })
            .finally(() => {
                setLoading(false);
            })
    };

    return (
        <Modal
            {...{show, onClose}}
            onClose={() => onClose(false)}
            title={`Remove Group`}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            footer={true}
            disabled={loading}
            onSuccessClose={false}
            successText="Yes"
            focusTarget="close"
            onSuccess={handleSuccess}
            {...props}
        >
            Are you sure?
        </Modal>
    )
}