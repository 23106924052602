import {ACCESS_LIST_FILTERS_CLIENT as PREFIX,
    DEFAULT_PER_PAGE_MEDIUM,
    transformed_service_reverse_data,

    SET_ACCESS_LIST_FILTERS_NOTIFICATION_DIALER
} from "const";
import {
    ACCESS_LIST_FILTERS_GET_NOTIFICATION_DIALER_API,
    ACCESS_LIST_FILTERS_MODIFY_NOTIFICATION_DIALER_API,
    ACCESS_LIST_FILTERS_GET_LIST_DIALER_API,
    ACCESS_LIST_FILTERS_CREATE_DIALER_API,
    ACCESS_LIST_FILTERS_MODIFY_DIALER_API,
    ACCESS_LIST_FILTERS_REMOVE_DIALER_API
} from "const/apiMethods";
import {api} from "api/loginRoutes";
import {_setValue} from "./defaults";

const setAccessListFiltersList = _setValue("SET_ITEMS_" + PREFIX);
const setLoading = _setValue("SET_LOADING_" + PREFIX);

const setNotification = _setValue(SET_ACCESS_LIST_FILTERS_NOTIFICATION_DIALER);

export const getNotification = (id) => (dispatch) => {
    
    const method = ACCESS_LIST_FILTERS_GET_NOTIFICATION_DIALER_API;
    const params = {
        target: {
            account_id: id
        }
    };

    api(method, params)
    .then((response) => {
        if (response !== undefined) {
            const accessListNotification = response.access_list_notification;

            dispatch(setNotification({
                ...accessListNotification
            }));
        }
    })
};

export const modifyNotification = (id, data) => async (dispatch) => {
    const response = await api(ACCESS_LIST_FILTERS_MODIFY_NOTIFICATION_DIALER_API, {
        target: {
            account_id: id,
        },
        ...data
    });

    return response;
};


export const getAccessListFiltersList = (service, id) => (dispatch) => {
    dispatch(setLoading(true));

    const method = ACCESS_LIST_FILTERS_GET_LIST_DIALER_API;
    const params = {
        service: transformed_service_reverse_data[service],
        target: {
            account_id: id
        }
    };

    api(method, params)
    .then((response) => {
        if (response !== undefined) {
            dispatch(setAccessListFiltersList({
                list: response["access_list_filter_list"] || []
            }));
        }
    })
    .finally(() => {
        dispatch(setLoading(false));
    });
};

export const createAccessListFilter = (id, service, data = {}) => async (dispatch) => {
    const method = ACCESS_LIST_FILTERS_CREATE_DIALER_API;
    const params = {
        target: {
            account_id: id
        },
        service: transformed_service_reverse_data[service],
        ...data
    };

    return await api(method, params);
};

export const modifyAccessListFilter = (alfKey, id, service, data = {}) => async (dispatch) => {
    const method = ACCESS_LIST_FILTERS_MODIFY_DIALER_API;
    const params = {
        target: {
            account_id: id,
            alf_key: alfKey
        },
        service: transformed_service_reverse_data[service],
        ...data
    };

    return await api(method, params);
};


export const removeAccessListFilter = (alfKey, id, service) => async (dispatch) => {
    const method = ACCESS_LIST_FILTERS_REMOVE_DIALER_API;
    const params = {
        target: {
            account_id: id,
            alf_key: alfKey
        },
        service: transformed_service_reverse_data[service],
    };

    return await api(method, params);
};

export const setLoadingData = (load) => (dispatch) => {
    dispatch(setLoading(load));
};
