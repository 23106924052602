import { connect } from 'react-redux';
import { 
    getSuppliers,
    getSupplierDropdownList,
    onSetRedirectTab,
} from '../../actions/suppliers';
import {
    getAccountManagersDropdownList
} from '../../actions/account_managers';
import Suppliers from './Suppliers';

const mapState = ( {auth, suppliers, account_managers, references} )=> ({
    service: auth.service,
    authInfo: auth.authInfo,
    loading: suppliers.loading,
    data: suppliers.items,
    account_manager_list: account_managers.dropdown_list,
    supplier_dropdown_list: suppliers.dropdown_list,
    paymentTermsList: references.payment_terms_supplier_list,
    servicePlanList: references.service_plan_list,
    countryList: references.country_list,
    howFindList: references.how_find_list,
});

export default connect( mapState, {
    getSuppliers,
    onSetRedirectTab,
    getSupplierDropdownList,
    getAccountManagersDropdownList
})(Suppliers);
  