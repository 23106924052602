import React, {useEffect, useMemo, useState} from 'react'
import {Whisper, Tooltip, Icon, Button} from "rsuite";
import styled, {css} from "styled-components";
import {FormattedMessage} from "react-intl";
import messages from "../../definedMessages";
import {Link} from 'react-router-dom'
import {createExternalLink} from './Navigation'

import m from "definedMessages";


export default ({
    isMobile = false,
    authInfo = {},
    accountJoinList,
    showContactModal,
    setShowContactModal
}) => {
    
    const managerInfo = useMemo(() => {
        return accountJoinList.reduce((result, accountService) => {
            const currentService = accountService?.service;
            result[currentService] = {
                name: accountService?.account_manager_name,
                email: accountService?.account_manager_email,
                skype: accountService?.account_manager_skype
            }
            return result;
        }, {});
    }, [accountJoinList]);

    const [showWhisper, onShowWhisper] = useState(false);

    useEffect(() => {
        if (!showContactModal) {
            onShowWhisper(false)
        }
    }, [showContactModal]);

    if (isMobile)
        return (
            <Whisper
                placement="auto"
                trigger="click"
                speaker={<StyledTooltip
                    show={!showContactModal && showWhisper}
                >
                    {accountJoinList.map(accountService => {
                        const currentService = accountService?.service;
                        const currentManagerInfo = managerInfo[currentService];

                        return <>
                            <div>{currentService} AM: {currentManagerInfo.name}</div>
                            <AMInfo mobile location="end">
                                <span className="link skype"><Link to={`skype:${currentManagerInfo.skype}`}>skype</Link></span>
                                <span className="link email"><Link to={`mailto:${currentManagerInfo.email}`}>mail</Link></span>
                            </AMInfo>
                        </>}
                    )}
                    {!showContactModal && showWhisper && <MobileStyledTextButton appearance="link" onClick={() => setShowContactModal(true)}>
                        <Icon icon="book2" className="contact_icon"/> <FormattedMessage {...messages.moreContacts}/>
                    </MobileStyledTextButton>}
                </StyledTooltip>}
            >
                <StyledIcon
                    size="lg"
                    icon="avatar"
                    className="menu_account-avatar"
                    onClick={() => {onShowWhisper(true)}}
                />
            </Whisper>
        );

    return (
        <StyledAccountManagerInfo>
            {accountJoinList.map(accounService => {
                const currentService = accounService?.service;
                const currentManagerInfo = managerInfo[currentService];

                return <>
                    {currentManagerInfo.name && <AMInfo>
                        {currentService} AM: {currentManagerInfo.name} {currentManagerInfo.skype && <span className="link skype">
                            <Whisper
                                placement="auto"
                                trigger="hover"
                                speaker={<StyledTooltip  show={true}>
                                    {currentManagerInfo.skype}
                                </StyledTooltip>}
                            >
                                {createExternalLink(currentManagerInfo.skype, "skype", <Icon icon="skype"/>)}
                            </Whisper>
                        </span>} {currentManagerInfo.email && <span className="link email">
                            <Whisper
                                placement="auto"
                                trigger="hover"
                                speaker={<StyledTooltip show={true}>
                                    {currentManagerInfo.email}
                                </StyledTooltip>}
                            >
                                {createExternalLink(currentManagerInfo.email, "mailto", <Icon icon="envelope"/>)}
                            </Whisper>
                        </span>}
                    </AMInfo>}
                </>
            })}
            
            <StyledTextButton appearance="link" onClick={() => {setShowContactModal(true)}}>
                <FormattedMessage {...messages.moreContacts}/>
            </StyledTextButton>
        </StyledAccountManagerInfo>
    )
};

const AMInfo = styled.div`
    display: flex;
    flex-direction: row;

    ${props => !props.location && css`
        justify-content: space-between;
    `}
    
    ${props => props.location === "end" && css`
        justify-content: flex-end;

        .link {
            margin-left: 5px;
        }
    `}
`;

const StyledIcon = styled(Icon)`
    padding-top: 17px;
    margin-right: 20px;
    
    .app-client.rtl & {
        margin-left: 20px;
        margin-right: 0;
    }
`;

const StyledTooltip = styled(Tooltip)`
    display: ${props => !props.show ? 'none !important' : undefined};
    && .rs-tooltip-inner {
        padding-bottom: 5px;    
        padding-top: 5px;
    }
`;

const StyledAccountManagerInfo = styled.div`
    font-size: 0.7rem;
    padding: 5px 10px 0 0;
    
    .app-client.rtl & {
        padding-right: 0;
        padding-left: 10px;
    }
    
    .email {
        margin-left: 5px;
    }
    .skype {
        margin-left: 5px;
        // margin-right: 5px;
    }
`;

const StyledTextButton = styled(Button)`    
    line-height: 0 !important;
    padding: 0 !important;
    margin-top: 7px !important;
    display: flex !important;
    font-size: 0.7rem !important;
    color: #ffffff !important;
    text-decoration: underline !important;
`;
const MobileStyledTextButton = styled(Button)`
    padding: 0 !important;
    margin-left: 0px !important;
    margin-top: 8px !important;
    display: flex !important;
    font-size: 0.7rem !important;
    color: #ffffff !important;
    text-decoration: underline !important;
    .contact_icon {
        margin-right: 5px;
    }
`;