import React, {useEffect, useRef, useState} from "react";
import {CheckPicker, Icon} from "rsuite";


const ShowColumnPicker = ({columns, onChange, value, loading}) => {
    const [isOpen, setOpen] = useState(false);
    const ref = useRef(null);
    const pickerData = columns.map(({dataKey, label, fullLabel, name}) => ({
        dataKey,
        label: fullLabel || label || name
    }));


    const handleClickOutside = (event) => {
        const menuBodyEl = ref?.current?.menuContainerRef?.current?.menuBodyContainerRef?.current;

        if ((!menuBodyEl || !menuBodyEl.contains(event.target))) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false);

        return () => document.removeEventListener('click', handleClickOutside, false);
    });

    return (
        <div className="js-column-selector-wrapper">
            {!loading && <Icon
                className={`position-absolute z-index-dropdown columns-picker-icon-show`}
                icon={isOpen ? "indent" : "align-justify"}
                onClick={() => setOpen(!isOpen)}
            />}
            <CheckPicker
                ref={ref}
                className="position-absolute z-index-dropdown invisible"
                open={isOpen && !loading}
                valueKey="dataKey"
                value={value}
                data={pickerData}
                // menuAutoWidth={false}
                searchable={false}
                onChange={onChange}
                style={{width: 190}}

                // disabled
                // loading
            />
        </div>
    );
};

export default ShowColumnPicker;