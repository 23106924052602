import React from "react";
import styled from "styled-components";
import PageTabs from "components/PageTabs";
import {checkPermissionsFor} from "store/storeHelpers";
import {
    SUBACC_GET_SUBACC_METHOD,
    SUBACC_MODIFY_SUB_ACCOUNT_METHOD
} from "const/apiMethods"
import {FlexGrid, FlexGridItem} from "components/base/FlexGrid";

import m from "definedMessages";

export default ({
    account,
    isMobile,
    activeTab,
    onSelect,
    formatMessage
}) => {

    
    const menuItems = [
        ...(account.id
            ? [{
                id: "general", eventKey: "general", title: formatMessage(m.general), checkedMethods: []
            }]
            : []
        ),
        {
            id: "numbers", eventKey: "numbers", title: formatMessage(m.numbers), checkedMethods: []
        },
        {
            id: "payments", eventKey: "payments", title: formatMessage(m.payments), checkedMethods: []
        }
    ];

    const filterMenuItems = (menuItems) => {
        return menuItems.filter((item) => {
            const checkedMethods = [];

            if (item.checkedMethods) {
                for (const method of item.checkedMethods) {
                    checkedMethods.push(checkPermissionsFor(method));
                }
            }

            if (!!item && checkedMethods.every((check) => check === true)) {
                return true;
            }
        });
    };

    const dropdownTitle = (menuItems.find(item => item.eventKey === activeTab) || {}).title;

    const accountName = account?.name;
    const paymentTerms = account?.invoice_period;
    const balanceUSD = account?.balance_USD ? account.balance_USD : {};
    const balanceEUR = account?.balance_EUR ? account.balance_EUR : {};
    const currentPeriodUSD = balanceUSD.current_balance;
    const currentPeriodEUR = balanceEUR.current_balance;
    const strCurrentPeriodUSD = `${balanceUSD.current_balance} USD`;
    const strCurrentPeriodEUR = `${balanceEUR.current_balance} EUR`;
    const currExistUSD = currentPeriodUSD || currentPeriodUSD === 0;
    const currExistEUR = currentPeriodEUR || currentPeriodEUR === 0;

    const previousPeriodUSD = balanceUSD.invoice_balance;
    const previousPeriodEUR = balanceEUR.invoice_balance;
    const strPreviousPeriodUSD = `${balanceUSD.invoice_balance} USD`;
    const strPreviousPeriodEUR = `${balanceEUR.invoice_balance} EUR`;
    const prevExistUSD = previousPeriodUSD || previousPeriodUSD === 0;
    const prevExistEUR = previousPeriodEUR || previousPeriodEUR === 0;

    const currentPeriod = `${currExistUSD ? strCurrentPeriodUSD : "0 USD"}/${currExistEUR ? strCurrentPeriodEUR : "0 EUR"}`;
    const previousPeriod = `${prevExistUSD ? strPreviousPeriodUSD : "0 USD"}/${prevExistEUR ? strPreviousPeriodEUR : "0 EUR"}`;
    

    return (
            <>
                <Header>
                     <>
                        <div><Name>{formatMessage(m.subaccount)}:</Name><Value>{accountName}</Value></div>
                        <div><Name>{formatMessage(m.paymentTerms)}:</Name><Value>{paymentTerms}</Value></div>
                        <div><Name>{formatMessage(m.currentPeriodTraffic)}:</Name><Value>{currentPeriod}</Value></div>
                        <div><Name>{formatMessage(m.previousPeriodTraffic)}:</Name><Value>{previousPeriod}</Value></div>
                    </>
                </Header>

                {!isMobile
                    ? <PageTabs
                        {...{
                            activeTab,
                            onSelect,
                            menuItems: filterMenuItems(menuItems)
                        }}
                    />
                    : <>
                        <FlexGrid
                            noWrap
                            align={"middle"}
                            justify={"space-between"}
                        >
                            <FlexGridItem>
                                <SectionHeader>{dropdownTitle}</SectionHeader>
                            </FlexGridItem>
                            <FlexGridItem>
                                <PageTabs
                                    isMobile={true}
                                    {...{
                                        activeTab,
                                        onSelect,
                                        menuItems: filterMenuItems(menuItems)
                                    }}
                                />
                            </FlexGridItem>
                        </FlexGrid>
                    </>
                }
            </>
        )
}


const Name = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-main);
    margin-right: 3px;
`;

const Value = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #363434;
    margin-right: 15px;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    
    & > div {
        padding-bottom: 10px;
    }
`;

const SectionHeader = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-main);
    padding: 15px 0px;
     div {
        display: inline-block;
        width: 50%;
     }
     div:last-child{
         text-align: right
     }
`;