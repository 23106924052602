import React, {useState, useEffect} from "react";
import {List, Panel} from "rsuite";
import styled from "styled-components";
import {useWindowWidth} from "hooks";

import {Spacer} from "../../components/base/Spacer";
import {CustomModal} from "../../components/base";
import {ButtonContent} from "../../components/base/BaseButton";


const reasons = {
    undefined_sde: 'subdestination is undefined',
    number_exists: 'already in another group',
    number_exists_same_group: 'already in same group',
    no_range: 'range is not created',
    duplicate_number: 'number is duplicated',
};


export default (
    {
        data,
        maxWidth = 600,
        onClose,
        ...props
    }
) => {

    const resizedWidth = useWindowWidth();

    return (
        <>
            <StyledModal
                showFooter={true}
                title={'Change rate for subdestination result'}
                width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
                customFooter={() => <ButtonContent onClick={onClose}>Close</ButtonContent>}
                onClose={onClose}
                {...props}
            >

                <p><b>Changed succesfully</b></p>

                {data.account_price_update_list && data.account_price_update_list.length
                    ? <>
                        <Spacer />
                        <StyledPanel
                            bordered
                            collapsible
                            header={<p style={{color: 'var(--color-primary)'}}>Account prices updated list</p>}
                        >
                            <StyledList size='sm'>
                                {data.account_price_update_list.map(item => (
                                    <List.Item key={item.account_id}>
                                        <StyledAccountInfo>
                                            <span className="account-name">{item.account_name}</span> 
                                            <StyledPrice><del>{item.rate_old} {item.currency_name}</del><ins>{item.rate_new}</ins> {item.currency_name}</StyledPrice>
                                        </StyledAccountInfo>
                                    </List.Item>
                                ))}
                            </StyledList>
                        </StyledPanel>
                    </>
                    : null
                }

                {data.account_number_update_list && data.account_number_update_list.length
                    ? <>
                        <Spacer />
                        <StyledPanel 
                            bordered
                            collapsible
                            header={<p style={{color: 'var(--color-primary)'}}>Account numbers updated list</p>}
                        >
                            <StyledList size='sm'>
                                {data.account_number_update_list.map(item => (
                                    <List.Item key={item.account_id}>
                                        <StyledAccountInfo>
                                            <span className="account-name">{item.account_name}</span> 
                                            <span>{item.updated_numbers}</span>
                                        </StyledAccountInfo>
                                    </List.Item>
                                ))}
                            </StyledList>
                        </StyledPanel>
                    </>
                    : null
                }

            </StyledModal>
        </>
    )
};


const StyledModal = styled(CustomModal)`
    .rs-modal-body {
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const StyledList = styled(List)`
    margin-top: 10px;
    box-shadow: none !important;
    
    @media (max-width: 767px) {
        display: none !important;
    }
`;

const StyledAccountInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .account-name {
        color: var(--color-dark);
        display: block;
        padding-right: 10px;
    }
`;

const StyledPrice = styled.div`
    del {
        color: var(--color-disabled);
        text-decoration: line-through;
        margin-right: 10px;
    }

    ins {
        color: var(--color-primary);
        font-size: 16px;
        text-decoration: none;
    }
`;

const StyledPanel = styled(Panel)`
    && {

        .rs-panel-heading {
            font-size: 14px;

            &:before {
                top: 50%;
                margin-top: -9px;
            }
        }
    }

    .rs-panel-heading,
    .rs-panel-body {
        padding: 12px;
    }
    
`;