import React, {useContext, useEffect, useMemo, useState} from "react"
import styled from "styled-components";
import m from "../../definedMessages";
import {Whisper, Tooltip, Icon, Divider} from "rsuite";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom"
import {RtlContext} from "../../App";
import {LOCAL_STORAGE_CHECKED_PAYMENT_STATUS} from "../../const/localStorageKeys"
import {getAccountInfoFromSession} from "../../utils/helpers";

export default ({
    dialerInfo = {},
    accountJoinList = {},
    billingServiceList,
    billingCurrencyList,
    realtimePaymentStatus,
    isMobile = false
}) => {

    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);

    const currencyIcon = useMemo(() => (
        dialerInfo && dialerInfo.currency_name
            ? dialerInfo.currency_name.toLowerCase()
            : "usd"
    ), [dialerInfo]);

    const data = useMemo(() => {
        return billingCurrencyList.reduce((result, billingCurrency) => {
            const currentCurrencyName = billingCurrency?.currency_name;
            const currentService = billingCurrency?.service || "None";

            const balanceValue = billingCurrency.pending_amount || billingCurrency.pending_amount === 0
                ? `${billingCurrency.pending_amount} ${currentCurrencyName}`
                : "";

            const balanceInvoiceValue = billingCurrency.ready_for_payment || billingCurrency.ready_for_payment === 0
                ? `${billingCurrency.ready_for_payment} ${currentCurrencyName}` 
                : "";


            const summary = (billingCurrency.pending_amount || billingCurrency.pending_amount === 0) &&
            (billingCurrency.ready_for_payment ||  billingCurrency.ready_for_payment === 0) 
                ? billingCurrency.pending_amount + billingCurrency.ready_for_payment
                : null;
            const formattedSummary = summary || summary === 0 ? Number.isInteger(summary)
                    ? summary 
                    : summary.toFixed(2)
                : null
            const summaryValue = 
                summary || summary === 0
                ? `${formattedSummary} ${currentCurrencyName}` 
                : "";

            const paymentTermsValue = billingCurrency.payment_terms_name || "";

            const currentBalance = [
                {
                    id: "balance",
                    value: balanceValue,
                    label: formatMessage(m.pendingAmount),
                },
                {
                    id: "balance_inv",
                    value: balanceInvoiceValue,
                    label: formatMessage(m.readyForPayment),
                },
                {
                    id: "summary",
                    value: summaryValue,
                    label: formatMessage(m.summary),
                },
                {
                    id: "payment_terms",
                    value: paymentTermsValue,
                    label: formatMessage(m.paymentTerms),
                }
            ];
            result[currentCurrencyName] = currentBalance.filter(item => (
                !((dialerInfo.isTest && item.hideOnTest) || (isMobile && item.hideOnMobile) )
            ));
            return result;
        }, {});
    }, [billingServiceList, billingCurrencyList, isMobile]);

    useEffect(() => {
        if (paymentStatusCountState !== realtimePaymentStatus) {
            localStorage.setItem(LOCAL_STORAGE_CHECKED_PAYMENT_STATUS, "false");
            onChangePaymentStatusCount(realtimePaymentStatus)
        }
    }, [realtimePaymentStatus])

    const [paymentStatusCountState, onChangePaymentStatusCount] = useState(realtimePaymentStatus);
    const paymentStatusHasChecked = localStorage.getItem(LOCAL_STORAGE_CHECKED_PAYMENT_STATUS) ? localStorage.getItem(LOCAL_STORAGE_CHECKED_PAYMENT_STATUS) : false;
    const paymentStatusCount = realtimePaymentStatus < 99 ? realtimePaymentStatus : 99;

    return (
        <Wrapper>
            <Whisper
                enterable
                placement={rtl ? "bottomStart" : "bottomEnd"}
                trigger={isMobile ? "click" : "hover"} // "hover" on devices is bad
                // trigger={"click"}
                speaker={
                    <StyledTooltip>
                        {billingCurrencyList.map(billingCurrency => {
                            const currentCurrency = billingCurrency?.currency_name;
                            const currentService = billingCurrency?.service;

                            const hasDifferentService = billingCurrencyList.find(value => {
                                return value.currency_name === currentCurrency 
                                    && value.service !== null ? value.service !== currentService : false;
                            });

                            const header = hasDifferentService ? `${currentService} ${currentCurrency}` : currentCurrency;

                            return <div>
                                {header}
                                {data[currentCurrency].map(item => (
                                    <div key={item.id}>
                                        <StyledItem>{`${item.label}: ${item.value}`}</StyledItem>
                                    </div>
                                ))}
                            </div>
                        })}
                    </StyledTooltip>
                }
            >
                <div>
                    <StyledIcon size="lg" icon={currencyIcon}/>
                    { realtimePaymentStatus > 0 && !JSON.parse(paymentStatusHasChecked) &&
                        <StyledPaymentLink to="/payments" onClick={() => {
                            localStorage.setItem(LOCAL_STORAGE_CHECKED_PAYMENT_STATUS, "true")
                        }}>
                            <div>{paymentStatusCount}</div>
                        </StyledPaymentLink>
                    }
                </div>
            </Whisper>
        </Wrapper>
    )
}


const Wrapper = styled.li`
    display: inline-block;
    margin-right: 20px;
    margin-top: 12px;

    .rtl & {
        margin-right: 0;
        margin-left: 20px;
    }
`;

const StyledPaymentLink = styled(Link)`
    position: absolute;
    background-color: red;
    border-radius: 10px;
    margin-left: -4px;
    height: 14px;
    text-decoration: none;

    div {
        text-decoration: none;
        color: white !important;
        font-weight: 700;
        padding: 4px 3px;
        border: 0;
        line-height: 5px;
        font-size: 13px;
        display: block;
        position: relative;
    }
`;

const StyledTooltip = styled(Tooltip)`
    && .rs-tooltip-inner {
        padding-bottom: 5px;
        padding-top: 5px;
    }
`;

const StyledIcon = styled(Icon)`
    padding: 5px 5px;
`;

const StyledItem = styled.div`
    text-align: left;

    .rtl & {
        text-align: right;
    }
`;

// const TooltipItem = styled.div`
//     display: flex;
//     flex-direction: rows;
//     justify-content: flex-start;
// `;

const StyledDivider = styled(Divider)`
    && {
        margin-bottom: 6px;
        margin-top: 6px;
    }    
`;