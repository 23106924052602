import {connect} from "react-redux";
import {
    downloadAccesses,
    getAccessList,
    setEmptyAccessList,
    setLoadingData,
    allocateByAccess,
    setAccessLoadingAllocatedNumbers, getAccessListFiltersDialer
} from "actions/client/accesses";

import {servicePlanNoTrunkFilter} from "utils/filters";

import {
    downloadNumbers,
    setTrunkNumberTransaction,
    setUploadingLoading
} from "actions/client/numbers";

import {transformed_service_reverse_data} from "const";
import history from "config/history";
import Accesses from "./Accesses";
import {getTrunkListDialer, allocateByTemplateDialer} from "actions/client/price";
import {showReasonHandler} from "actions/reason_handler";
import {getAuthNumberType} from "actions/auth_numbers";
import {getNotification, modifyNotification} from "actions/client/access_list_filters";
import { getServiceByLocation } from "utils";


const mapState = ({client_accesses, client_access_list_filters, auth, auth_dialer, client_numbers, references, client_price, auth_numbers}) => {
    const serviceByLocation = getServiceByLocation(history.location);
    
    return {
        service: auth_dialer.serviceDialer,
        account: auth.authInfo,
        accountId: auth.dialerInfo 
            ? auth.dialerInfo.account_list.find(account => account.service === transformed_service_reverse_data[auth_dialer.serviceDialer])?.id 
            : null,

        permissions: auth.permissions,
        accountDialerState: auth_dialer.dialerInfo,
        accountJoinList: auth_dialer.accountJoinList,

        settings: client_access_list_filters.settings,
        settingsIsFetched:  client_access_list_filters.settingsIsFetched,

        accessesList: client_accesses.items,
        count: client_accesses.count,
        accessesLoading: client_accesses.loading,
        loadingAllocation: client_accesses.loadingAllocatedNumbers,
        page: client_accesses.page,
        per_page: client_accesses.per_page,
        accessFilters: client_accesses.accessFilters,
        accessFiltersLoading: client_accesses.accessFiltersLoading,
        trunkList: client_price.trunk_list,

        service_plan_list: references.service_plan_list.filter(servicePlanNoTrunkFilter),

        currency_list: references.currency_list,
        uploadLoading: references.uploadLoadingDialer,

        uploadLoadingSetting: client_numbers.uploadTrunkLoading,
        trunk_number_transaction: client_numbers.trunk_number_transaction,

        worldzoneList: references.worldzone_list,
        destinationList: references.destination_list,
        subdestinationList: references.subdestination_list,

        // ...(serviceByLocation 
        //     ? references.voice_not_deployed
        //     : references.sms_not_deployed
        // ),

        numberTypeFamilyList: auth_numbers.auth_number_type_family_list
    }
};


export default connect(mapState, {
    getTrunkList: getTrunkListDialer,
    setAccessLoadingAllocatedNumbers,

    downloadNumbers,
    setTrunkNumberTransaction,
    setUploadingLoading,

    getAccessList,
    downloadAccesses,
    allocateByAccess,
    allocateByTemplate: allocateByTemplateDialer,
    setLoadingData,
    getAccessListFiltersDialer,
    getNotification,
    modifyNotification,

    setEmptyAccessList,

    getAuthNumberType,

    showReasonHandler,
})(Accesses);