import {
    SIM_TRAFFIC_RANGES_SET_LIST,
    SIM_TRAFFIC_RANGES_SET_LOADING
} from './actionTypes';

import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import {
    SIM_TRAFFIC_RANGES_GET_LIST, SIM_TRAFFIC_RANGES_REMOVE, SIM_TRAFFIC_RANGES_GET_POSSIBLE_LIST,
    SIM_TRAFFIC_RANGES_ADD_NEW
} from '../const/apiMethods';
import {Alert} from 'rsuite/es';

export const setLoading = createAction(SIM_TRAFFIC_RANGES_SET_LOADING);
const setPriceRangesPriorityList = createAction(SIM_TRAFFIC_RANGES_SET_LIST);
const setPossiblePricePriorityList = createAction(SIM_TRAFFIC_RANGES_GET_POSSIBLE_LIST);

export const getPriceRangesPriorityList = (filter, page=1, per_page=10000) => (dispatch) => {
    dispatch(setLoading(true));

    api(SIM_TRAFFIC_RANGES_GET_LIST, {filter, page, per_page})
        .then((response) => {
            dispatch(setPriceRangesPriorityList({...response, page, per_page}));
        })
        .finally(() => dispatch(setLoading(false)));
};

export const getPossiblePriceRangePriorityList = (sde_key) => (dispatch) => {
    api(SIM_TRAFFIC_RANGES_GET_POSSIBLE_LIST, {sde_key})
        .then((response) => {
            if (response) {
               dispatch(setPossiblePricePriorityList(response.code_list))
            }
        })
};

export const addNewPriceRangePriority = (sde_key, prefix) => async (dispatch) => {
    dispatch(setLoading(true));

    return await api(SIM_TRAFFIC_RANGES_ADD_NEW, {sde_key, prefix});
};

export const deletePriceRangePriority = (prefix) => async (dispatch) => {
    dispatch(setLoading(true));

    const response = await api(SIM_TRAFFIC_RANGES_REMOVE, {prefix})
    if (!response) {
        setLoading(false);
        return;
    }

    Alert.success(`${response.prefix} prefix has been deleted!`);
    return response;
};
