import React, {useMemo, useState, useEffect} from "react";
import PaymentsRequestSection from "./PaymentsRequestSection";
import PaymentsListSection from "./PaymentsListSection";
import PaymentsTrafficReportsSection from "./PaymentsTrafficReportsSection";
import ModalRequestPayment from "./ModalRequestPayment";
import ModalPaymentDetails from "./ModalPaymentDetails";
import ModalAddPaymentDetail from "../PaymentDetails/ModalAddPaymentDetail";
import {Panel} from "rsuite";
import PaymentsHeader from "./PaymentsHeader";
import HawalaPaymentRequest from "./HawalaPaymentRequest";
import MiscPaymentRequest from "./MiscPaymentRequest";
import {currency_data} from '../../const';
import {checkPermissionsFor} from "store/storeHelpers";
import {PAYMENT_DETAIL_CRYPTO_LIST_API} from "const/apiMethods";


export default ({
    paymentItems,
    paymentItemsLoading,
    paymentRequestItems,
    paymentRequestLoading,
    paymentTrafficReportsItems,
    paymentTrafficReportsLoading,
    paymentDetailsItems,
    paymentHawalaItems,
    paymentHawalaLoading,
    paymentMiscInited,
    paymentMiscItems,
    paymentMiscLoading,

    service,
    authInfo,
    accounts,
    accountInfo,
    cryptoList,
    dialerList,
    currencyList,
    permissions,
    account_manager_list,
    organizationTypeList,
    paymentMethodList,
    paymentTermsList,
    countryList,
    globalSettings,
    referencesLoading,
    globalSetting,

    // API methods
    getPaymentList,
    getTrafficReportsList,
    getPaymentRequestList,
    getHawalaPaymentRequestList,
    getMiscPaymentRequest,
    getAccountManagersDropdownList,
    requestPayment,
    rejectPaymentRequest,
    approvePaymentRequest,
    getPaymentDetailsList,
    getPaymentDetailsCryptoList,
    createPaymentDetails,
    modifyPaymentDetails,
    exportMiscPayments,
    exportHawalaPayments,
    setService,
    getAccount,
    getAccounts,
    getReferencesForReports
}) => {

    const [activeTab, setActiveTab] = useState('payment-requests'); // invoices, payment-requests, payments
    const [requestPaymentFormValue, setRequestPaymentFormValue] = useState({amount: 0});
    const [showModalRequestPayment, setShowModalRequestPayment] = useState(false);
    const [showModalAddPaymentDetail, setShowModalAddPaymentDetail] = useState(false);
    const [showModalPaymentDetail, setShowModalPaymentDetail] = useState(false);
    const [dropdownVisibility, setDropdownVisibility] = useState(true);
    const [amountLimitMin, setAmountLimitMin] = useState(0.01);

    // active account info (on action button click)
    const [activeAccountId, setActiveAccountId] = useState(null);
    const [activeAccountBalance, setActiveAccountBalance]  = useState(0);
    const [activeAccountCurrency, setActiveAccountCurrency]  = useState(null);

    const accountManagerList = account_manager_list.filter(value => value.active || value.has_accounts);

    useEffect( () => {
        getAccounts();
        getReferencesForReports();
    }, [] );

    useEffect(() => {
        if (checkPermissionsFor(PAYMENT_DETAIL_CRYPTO_LIST_API)) {
            getPaymentDetailsCryptoList();
        }
    }, [permissions]);

    useEffect( () => {
        // get account and set options
        updateAccount(activeAccountId);
    }, [activeAccountId]);

    const updateAccount = (id) => {
        if (id) {
            getAccount(id);
        }
    };


    // set payment detail amount min limit
    useEffect( () => {
        if (!requestPaymentFormValue.payment_detail_id || !paymentMethodList.length || !paymentDetailsItems.length) {
            setAmountLimitMin(0.01);
            return;
        }
        const currentPaymentDetail = paymentDetailsItems.find(item => item.id === requestPaymentFormValue.payment_detail_id);
        if (!currentPaymentDetail) {
            setAmountLimitMin(0.01);
            return;
        }

        const currentPaymentMethod = paymentMethodList.find(item => item.pm_key === currentPaymentDetail.pm_key);
        const currentPaymentMethodLimit = currentPaymentMethod && currentPaymentMethod.limit
            ? currentPaymentMethod.limit
            : 0.01;
        setAmountLimitMin(currentPaymentMethodLimit);
    }, [requestPaymentFormValue, paymentMethodList, paymentDetailsItems] );


    const clearActiveData = () => {
        setActiveAccountId(null);
        setActiveAccountBalance(0);
        setActiveAccountCurrency(null);
    };


    const openModalRequestPayment = (value) => {
        setActiveAccountId(value.account_id);
        setActiveAccountBalance(value.available_for_payment);
        setActiveAccountCurrency(value.currency_name);
        setShowModalRequestPayment(true);
    };


    const closeModalRequestPayment = () => {
        setShowModalRequestPayment(false);
        clearActiveData();
    };


    const openModalPaymentDetail = () => {
        setShowModalPaymentDetail(true);
    };


    const handleShowModalAddPaymentDetail = () => {
        setShowModalAddPaymentDetail(true);
        setDropdownVisibility(false);
    };


    const closeModalAddPaymentDetail = (value) => {
        if (value) {
            setRequestPaymentFormValue({
                ...requestPaymentFormValue,
                payment_detail_id: value.payment_detail.id
            });
        }
        setShowModalAddPaymentDetail(false);
        setDropdownVisibility(true);
    };


    const handleSetActiveTab = (activeTab) => {
        // setBreadcrumbs(`${document.location.pathname}/${activeTab}`);
        setActiveTab(activeTab);
    };

    const currentCurKey = useMemo(() => {
        const currentCurrency = currencyList.find((currency) => currency.name === activeAccountCurrency);
        return currentCurrency ? currentCurrency.cur_key : null;
    }, [currencyList, activeAccountCurrency]);

    return (
        <Panel
            style={{marginTop: 10}}
            classPrefix="white-panel"
        >

            <PaymentsHeader
                activeTab={activeTab}
                onSelect={handleSetActiveTab}
            />

            {( () => {

                switch (activeTab) {

                    case 'payment-requests':
                        return (
                            <PaymentsRequestSection
                                {...{
                                    paymentRequestItems,
                                    setActiveAccountId,
                                    setShowModalPaymentDetail,
                                    paymentRequestLoading,
                                    rejectPaymentRequest,
                                    approvePaymentRequest,
                                    getPaymentRequestList,
                                    openModalRequestPayment,
                                    openModalPaymentDetail,
                                    accounts,
                                    authInfo,
                                    service,
                                    updateAccount
                                }}
                            />
                        );

                    case 'payments':
                        return (
                            <PaymentsListSection
                                {...{
                                    paymentItems,
                                    paymentItemsLoading,
                                    getPaymentList,
                                    accounts,
                                }}
                            />
                        );

                    case 'invoices':
                        return (
                            <PaymentsTrafficReportsSection
                                {...{
                                    globalSettings,
                                    paymentTrafficReportsItems,
                                    paymentTrafficReportsLoading,
                                    openModalRequestPayment,
                                    getTrafficReportsList,
                                    setService,
                                    accounts,
                                    service
                                }}
                            />
                        );

                    case 'hawala-payment-request':
                        return (
                            <HawalaPaymentRequest
                                data={paymentHawalaItems}
                                loading={paymentHawalaLoading}
                                fetchData={getHawalaPaymentRequestList}
                                {...{
                                    service,
                                    getAccountManagersDropdownList,
                                    accountManagerList,
                                    currencyList,
                                    requestPayment,
                                    paymentMethodList,
                                    paymentTermsList,
                                    exportHawalaPayments
                                }}
                            />
                        );
                    
                    case 'misc-payment-request':
                        return (
                            <MiscPaymentRequest
                                data={paymentMiscItems}
                                inited={paymentMiscInited}
                                loading={paymentMiscLoading}
                                fetchData={getMiscPaymentRequest}
                                exportFunc={exportMiscPayments}
                                {...{
                                    service,
                                    dialerList,
                                    currencyList,
                                    globalSetting,
                                    referencesLoading
                                }}
                            />
                        )
                    default:
                        return null;
                }

            } )()}

            {showModalRequestPayment && <ModalRequestPayment
                accountId={activeAccountId}
                show={showModalRequestPayment}
                onClose={closeModalRequestPayment}
                formValue={requestPaymentFormValue}
                setFormValue={setRequestPaymentFormValue}
                amountLimit={activeAccountBalance}
                amountLimitMin={amountLimitMin}
                currencyName={activeAccountCurrency}
                currency={currentCurKey}
                {...{
                    currencyList,
                    requestPayment,
                    paymentDetailsItems, 
                    dropdownVisibility,
                    handleShowModalAddPaymentDetail,
                    paymentMethodList,
                    accounts,
                    service,
                    updateAccount,
                    getPaymentDetailsList
                }}
            />}

            {showModalPaymentDetail && <ModalPaymentDetails
                accountId={activeAccountId}
                show={showModalPaymentDetail}
                curKey={accountInfo?.cur_key}
                onClose={() => setShowModalPaymentDetail(false)}
            />}
            
            {showModalAddPaymentDetail && <ModalAddPaymentDetail
                accountId={activeAccountId}
                show={showModalAddPaymentDetail}
                onClose={closeModalAddPaymentDetail}
                currency={currentCurKey}
                {...{
                    organizationTypeList,
                    cryptoList,
                    countryList,
                    paymentMethodList,
                    createPaymentDetails,
                    modifyPaymentDetails,
                    getPaymentDetailsList
                }}
            />}

        </Panel>
    )
};