import { connect } from 'react-redux';
import { 
    getAccounts,
    getAccountJoin,
    onSetRedirectTab
} from '../actions/accounts';
import Accounts from '../pages/Accounts';
import {getAccountManagersDropdownList} from "../actions/account_managers";

const mapState = ( {auth, accounts, account_managers, references} )=> ({
    service: auth.service,
    loading: accounts.loading,
    data: accounts.items,
    authInfo: auth.authInfo,
    accountJoinList: accounts.accountJoinList,
    paymentTermsList: references.payment_terms_list,
    servicePlanList: references.service_plan_list,
    account_manager_list: account_managers.dropdown_list,
    countryList: references.country_list,
    howFindList: references.how_find_list,
});

export default connect( mapState, {
    getAccounts,
    getAccountJoin,
    onSetRedirectTab,
    getAccountManagersDropdownList,
})(Accounts);
  