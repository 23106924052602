import {connect} from "react-redux";
import Users from "./Users";
import {getManagerAccountUser} from "../../actions/manager_account_users";


const mapStateToProps = ({manager_account_users, references}) => ({
    data: manager_account_users.list,
    loading: manager_account_users.loading,
    adminRoleList: references.admin_role_list,
});

export default connect(mapStateToProps, {
    getManagerAccountUser
})(Users);