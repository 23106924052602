import React, {useState, useEffect} from "react";
import {Checkbox, FormControl} from "rsuite";

const ControlledCheckbox = ({children, defaultValue = false, value, onChange, ...props}) => {
    const [_value, _setValue] = useState(defaultValue);

    useEffect(() => {
        if (value !== undefined) {
            _setValue(value);
        }
    }, [value]);

    return <FormControl
        accepter={Checkbox}
        value={_value}
        checked={_value}
        defaultChecked={defaultValue}
        onChange={() => {
            _setValue(!_value);
            onChange && onChange(!_value);
        }}
        {...props}
    >
        {children}
    </FormControl>
};

export default ControlledCheckbox;