import {PRICES as PREFIX, SET_SERVICE_PLAN_PRICE} from '../const/';

const initialState = {
    loading: false,
    page: 1,
    count: 0,
    service_plan_price_list: []
};
const handlers = {
    [SET_SERVICE_PLAN_PRICE]: (state,{payload:{items,page,count}}) =>({...state,service_plan_price_list:items, count, page, loading: false}),
    ['SET_LOADING_'+PREFIX]: (state,{payload}) =>({...state,loading: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};