import React, {useRef, useState} from "react";
import {Form, FormControl, SelectPicker, Input, Schema} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import {debounce} from "../../utils";
import {servicePlanNoTrunkFilter} from "../../utils/filters";
import ServicePlans from "../../components/Filters/ServicePlans";


const {NumberType} = Schema.Types;

const formModel = Schema.Model({
    sp_key: NumberType()
        .isRequired('Required'),
});


export default (
    {
        filter,
        onChange,
        accountManagers,
    }
) => {
    const formRef = useRef(null);

    const [formValueState, setFormValueState] = useState({});

    return (
        <Form
            ref={formRef}
            model={formModel}
            formValue={{...filter, ...formValueState}}
            onChange={debounce((value) => {
                if (!formRef.current.check()) {
                    return;
                }
                onChange(value);
                setFormValueState(value);
            }, 800)}
        >
            <FlexGrid align="middle">

                <FlexGridItem>
                    <FormControl
                        accepter={Input}
                        name={'account_name'}
                        placeholder={'Dialer'}
                        style={{width: 224}}
                    />
                </FlexGridItem>

                <FlexGridItem>
                    <ServicePlans
                        classPrefix={"rs-form-control"}
                        style={{width: 224}}
                        filterFunc={servicePlanNoTrunkFilter}
                        cleanable={false}
                    />
                </FlexGridItem>

                <FlexGridItem>
                    <FormControl
                        accepter={SelectPicker}
                        data={accountManagers}
                        name={'am_key'}
                        placeholder={'Account Manager'}
                        valueKey={'id'}
                        labelKey={'name'}
                        style={{width: 224}}
                    />
                </FlexGridItem>
            </FlexGrid>
        </Form>
    )
};