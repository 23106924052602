import {
    HOT_ACCESS_LIST as PREFIX
} from '../../const';

import {
    HOT_TEST_DIALER_ACCESS_LIST_API, SMS_HOT_TEST_DIALER_ACCESS_LIST_API,
    HOT_DIALER_ACCESS_LIST_API, SMS_HOT_DIALER_ACCESS_LIST_API
} from "../../const/apiMethods";

import {api} from '../../api/loginRoutes';
import {_setValue} from './defaults';
import {servicePick} from "utils";


const setHotAccessList = _setValue('SET_ITEMS_' + PREFIX);
const setHotAccessLoading = _setValue('SET_LOADING_' + PREFIX);


export const setLoadingData = (load) => (dispatch) => {
    dispatch( setHotAccessLoading(load) );
};

export const getHotAccessList = (service, per_page = 12, filter = {}, page = 1, isAuth = false, cancelToken) => (dispatch) => {
    dispatch( setHotAccessLoading(true) );
    
    const isTest = localStorage.getItem('userInfo') 
        ? JSON.parse(localStorage.getItem('userInfo')).session.is_test 
        : false;

    const method = isTest ? servicePick(service, HOT_TEST_DIALER_ACCESS_LIST_API, SMS_HOT_TEST_DIALER_ACCESS_LIST_API)
        : servicePick(service, HOT_DIALER_ACCESS_LIST_API, SMS_HOT_DIALER_ACCESS_LIST_API);

    const params = {filter, page, per_page, cancelToken};
    
    // if (isAuth) {
    //     params.sort1 = 'sp_key';
    //     params.sort1_desc = true;
    // }

    api(method, params)
        .then((response) => {
            if (response.cancelled) {
                return;
            }
    
            if (response !== undefined) {
                dispatch(setHotAccessList({
                    access_list_count: response.access_list_count || 0,
                    access_list_list: response.access_list_list || [],
                    page,
                    per_page
                }));
            }

            return (response && response.data && response.data.result) || {};
        })
        .finally(() => dispatch(setHotAccessLoading(false)))
};


export const setEmptyHotAccessList = () => (dispatch) => {
    dispatch( setHotAccessList({
        hot_access_list_count: 0,
        hot_access_list: [],
        page: 1
    }) );
};
