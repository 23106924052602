import React from 'react'
import ContactList from './ContactList'
import {useWindowWidth} from '../../../hooks'
import Button from 'rsuite/es/Button'
import styled from 'styled-components';
import m from "definedMessages";
import {useIntl} from "react-intl";
import {CustomModal} from 'components/base';



export default ({
    show,
    onClose,
    isMobile,
    maxWidth = 1000,
    contactText
}) => {
    const resizedWidth = useWindowWidth();
    const {formatMessage} = useIntl();

    const contactList = contactText ? contactText.split('\n') : [];
    return (
        <StyledModal
            {...{show}}
            title={formatMessage(m.contacts)}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showFooter={isMobile}
            confirmButton="Success"
            keyboard={false}
            onClose={() => {
                onClose()
            }}
        >
            {contactList.length 
                ? <ContactList
                    {...{contactList}}
                /> 
                : <>{formatMessage(m.noContactData)}</>
            }
        </StyledModal>
    )
};

const StyledButton = styled(Button)`
    margin-top: 10px;
`;

const StyledModal = styled(CustomModal)`
    .button-confirm {
        display: none;
    }
`;