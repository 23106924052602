import React, { useRef, useEffect, useState } from "react";
import { Form, Alert, Schema, Input, SelectPicker, FlexboxGrid, FormControl } from "rsuite";
import { useWindowWidth } from "hooks";
import { compareObjects } from "utils";
import CustomField from "components/Form/CustomField";
import { BorderBtn, ButtonPrimary, ButtonContent } from "components/base/BaseButton";
import Customers from "./Customers";
import PasswordModal from "../../PasswordModal";
import styled from "styled-components";
import { useIntl } from "react-intl";

import m from "definedMessages";

const { StringType } = Schema.Types;


export default ({
    history,
    disabled,
    subAccId,
    accountId,
    formDefaultValue,

    customerList,
    customerListLoading,
    
    createSubAccount,
    modifySubAccount,

    createCustomer,
    modifyCustomer,
    removeCustomer,

    update,
    updateCustomers,
    cancel,
    close,
    open,

    newAccount=false
}) => {

    const {formatMessage} = useIntl();

    const initialCustomers = customerList && customerList.slice(0);

    const windowWidth = useWindowWidth();


    const invoicePeriod =  [formatMessage(m.week), formatMessage(m.month)].map((period) => {
        return {
            key: period,
            name: period 
        }
    });

    const formSubAccountModel = Schema.Model({
        name: StringType()
            .maxLength(40, formatMessage(m.minLength, {count: 40}))
            .pattern(/^([a-zA-Z0-9.,\(\)_\-+]+ )*[a-zA-Z0-9.,\(\)_\-+]+ ?$/, "Please enter a valid name")
            .isRequired(formatMessage(m.required)),
    });

    const formNewSubAccountModel = Schema.Model({
        user_name: StringType()
            .maxLength(256, formatMessage(m.minLength, {count: 256}))
            .pattern(/^([a-zA-Z0-9.,\(\)_\-+]+ )*[a-zA-Z0-9.,\(\)_\-+]+ ?$/, "Please enter a valid user name")
            .isRequired(formatMessage(m.required)),
        invoice_period: StringType()
            .isRequired(formatMessage(m.required)),
        user_email: StringType()
            .maxLength(40, formatMessage(m.minLength, {count: 40}))
            .pattern(/^[a-zA-Z0-9_\.\-\+]*@[a-zA-Z0-9\.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid user email")
            .isRequired(formatMessage(m.required)),
        password: StringType()
            .maxLength(40, formatMessage(m.minLength, {count: 40}))
            .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d#?!_@$%^&*\-]{8,}$/, "Please enter a valid password")
            .isRequired(formatMessage(m.required))
    });

    const formModel = newAccount ? Schema.Model.combine(formSubAccountModel, formNewSubAccountModel) : formSubAccountModel;

    const customerRefsData = {};
    const formRef = useRef(null);

    const [formState, setFormState] = useState(formDefaultValue);
    const [customers, setCustomers] = useState(customerList || []);

 

    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [passwordState, setPasswordState] = useState(null);
    const [passwordCustomerData, setPasswordCustomerData] = useState({key: null, email: null});

    const [errorsPasswordForm, setErrorsPasswordForm] = useState({
        password: null,
        confirm_password: null
    });

    useEffect(() => {
        if (customerList && customerList.length) {
            setCustomers(customerList.map((customer) => {
                const {password: _, ...restCustomerValues} = customer;
                
                return {
                    ...restCustomerValues
                }
            }));
        }
    }, [customerList]);

    useEffect(() => {
        setFormState(formDefaultValue);
    }, [formDefaultValue]);

    const onShowPasswordModal = (customer) => {
        setShowPasswordModal(true);
        setPasswordCustomerData({...customer});
        setErrorsPasswordForm({
            password: null,
            confirm_password: null
        });
    };

    const onCloseModal = () => {
        setPasswordState(null);
        setShowPasswordModal(false);
    };


    const fieldIsChanged = (key) => {
        return formDefaultValue.hasOwnProperty(key) 
            ? formState && !!formState[key] && formDefaultValue[key] !== formState[key]
            : formState && !!formState[key];
    };

    const addableIsChanged = (userId, addableObjectInitial, addableObject) => {
        const addedNew = addableObjectInitial && !addableObjectInitial.map((addable) => addable.user_id).includes(userId);
        const changed = addableObjectInitial && !compareObjects(addableObjectInitial.find(obj => obj.user_id === userId), addableObject);

        return addedNew || changed;
    };
    
    const updatePageFastForward = (message=null) => {
        history.goBack();
        
        if (message) {
            Alert.success(message);
        }

        history.goForward();
    };

    const onSubmit = async () => {
        if (formRef.current.check()) {
            if (newAccount) {
                const requestData = {
                    ...formRef.current.getFormValue(),
                    password: formState?.password
                };

                const responseSubAccId = await createSubAccount(accountId, requestData);

                if (responseSubAccId) {
                    update(responseSubAccId);
                    setFormState(formDefaultValue);
                }
            } else {

                const refCustomerKeyList = Object.keys(customerRefsData);
                const customerErrorList = refCustomerKeyList.map((key) => {
                    if (key !== "null" && customerRefsData[key]) {
                        return customerRefsData[key].check();
                    }

                    return true;
                });

                const formData = {...formRef.current.getFormValue()};
                const requestData = {
                    name: formData.name,
                    invoice_period: formData.invoice_period
                };

                const customerValid = customerErrorList.every((flag) => flag);

                if (customerValid) {
                    initialCustomers.forEach(async (initCustomer) => {
                        const customerKeyList = customers.map((customer) => customer.key);

                        const clearedInitialList = initialCustomers.map(initialValues => {
                            const {key, ...remainingInitialValues} = initialValues;
                            return remainingInitialValues;
                        });

                        if (customerKeyList.includes(initCustomer.key)) {

                            const currentCustomer = customers.find((customer) => customer.key === initCustomer.key);
                            const {key, ...clearedCustomerData} = currentCustomer;

                            if (addableIsChanged(initCustomer.user_id, clearedInitialList, clearedCustomerData)) {
                                const customerRequestData = {
                                    user_name: currentCustomer.user_name,
                                    user_email: currentCustomer.user_email,
                                    password: currentCustomer.password
                                };

                                await modifyCustomer(currentCustomer.user_id, customerRequestData)
                                    .then(() => {
                                        updateCustomers(subAccId);
                                    });
                            }
                        } else {
                            await removeCustomer(initCustomer.user_id)
                                .then(() => {
                                    updateCustomers(subAccId);
                                });
                        }
                    });

                    const filteredCustomerList = customers.filter((customer) => customer.key !== null);

                    filteredCustomerList.forEach(async (customer) => {
                        const initialCustomerKeyList = initialCustomers.map((customer) => customer.key);

                        if (!initialCustomerKeyList.includes(customer.key)) {
                            const customerRequestData = {
                                user_name: customer.user_name,
                                user_email: customer.user_email,
                                password: customer.password
                            };

                            await createCustomer(subAccId, customerRequestData)
                                .then(() => {
                                    updateCustomers(subAccId);
                                })
                        }
                    });

                    await modifySubAccount(subAccId, requestData)
                        .then(() => {
                            updatePageFastForward(formatMessage(m.saved));
                        })
                        .finally(() => {
                            update(subAccId);
                        })
                
                }
            }
        }
    };

    const onCancel = () => {
        cancel();
    };
        
    const onClose = () => {
        close();
        updatePageFastForward("Closed");
    };

    const onOpen = () => {
        open();
        updatePageFastForward("Opened");
    };

    const isMobile = windowWidth < 767;

    return (<>
        <Form
            model={formModel}
            // layout="horizontal"
            ref={formRef}
            formValue={formState}
            onChange={(formValue) => {
                setFormState(formValue);
            }}
            formDefaultValue={formDefaultValue}
        >
            <FlexboxGrid>
                <StyledRow isMobile={isMobile}>
                    <StyledFlexboxGridItem>
                        <CustomField
                            width={400}
                            disabled={disabled}
                            accepter={Input}
                            name="name"
                            placeholder={`${formatMessage(m.name)}...`}
                            label={formatMessage(m.accountName)}
                            errorPlacement="topStart"
                            className={`tab-general-custom-field ${fieldIsChanged("name") && "has-changed"}`}
                        />

                        <CustomField
                            width={400}
                            disabled={disabled}
                            accepter={SelectPicker}
                            data={invoicePeriod}
                            name="invoice_period"
                            valueKey="key"
                            labelKey="name"
                            searchable={false}
                            cleanable={false}
                            placeholder={`${formatMessage(m.paymentTerms)}...`}
                            label={formatMessage(m.paymentTerms)}
                            errorPlacement="topStart"
                            className={`tab-general-custom-field ${fieldIsChanged("invoice_period") && "has-changed"}`}
                        />

                        {newAccount && <CustomField
                            width={400}
                            disabled={disabled}
                            searchable={false}
                            cleanable={false}
                            accepter={Input}
                            name="user_name"
                            placeholder={`${formatMessage(m.name)}...`}
                            label={formatMessage(m.customerName)}
                            errorPlacement="topStart"
                            className={`tab-general-custom-field ${fieldIsChanged("user_name") && "has-changed"}`}
                        />}

                        {newAccount && <CustomField
                            width={400}
                            disabled={disabled}
                            accepter={Input}
                            name="user_email"
                            placeholder={`${formatMessage(m.email)}...`}
                            label={formatMessage(m.customerEmail)}
                            errorPlacement="topStart"
                            className={`tab-general-custom-field ${fieldIsChanged("user_email") && "has-changed"}`}
                        />}

                        {newAccount && <>
                            {!formState.password && <HiddenField
                                name="password"
                                errorPlacement="topStart"
                                value={formState.password}
                            />}
                            <BorderBtn
                                withShadow
                                buttonStyle="primary"
                                style={{marginRight: 10, width: 170}}
                                onClick={() => {
                                    const newCustomer = {
                                        user_name: formState?.user_name,
                                        user_email: formState?.user_emial
                                    };

                                    onShowPasswordModal(newCustomer);
                                }}
                                size="sm"
                            >
                                {formatMessage(m.newSubaccountSetPassword)}
                            </BorderBtn>
                        </>}

                    </StyledFlexboxGridItem>
                </StyledRow>
            </FlexboxGrid>

            {!newAccount && <Customers
                disabled={false}
                isMobile={isMobile}
                customers={customers}
                addableIsChanged={addableIsChanged}
                refsData={customerRefsData}
                initialCustomers={initialCustomers}

                updateCustomers={setCustomers}
                onShowPasswordModal={onShowPasswordModal}
                formatMessage={formatMessage}
            />}
            
            <PasswordModal
                show={showPasswordModal}
                email={passwordCustomerData?.user_email}
                disabled={disabled}
                passwordState={passwordState}
                setPasswordState={setPasswordState}
                errorsPasswordForm={errorsPasswordForm}

                formatMessage={formatMessage}
                setErrorsPasswordForm={setErrorsPasswordForm}

                onCloseModal={onCloseModal}
                update={(password) => {
                    if (newAccount) {
                        setFormState((currentFormState) => {
                            const formStateWithPassword = {
                                ...currentFormState,
                                password: password
                            };

                            return formStateWithPassword;
                        });
                    } else {
                        setCustomers([
                            ...customers.map(customer =>
                                customer.key === passwordCustomerData.key ? {...customer, password} : customer
                            )
                        ]);
                    }
                }}
            />

            <ButtonFooter>

                <ButtonPrimary
                    disabled={disabled}
                    onClick={onSubmit}
                >
                    {formatMessage(m.save)}
                </ButtonPrimary>

                <ButtonContent
                    onClick={() => onCancel()}
                >
                    {formatMessage(m.cancel)}
                </ButtonContent>

                
                {!newAccount && <ButtonContent
                    onClick={() => {
                        if (formState.active) {
                            onClose();
                        } else {
                            onOpen();
                        }
                    }}
                >
                    {formState.active ? formatMessage(m.close) : formatMessage(m.open)}
                </ButtonContent>}

            </ButtonFooter>
        </Form>
    </>);
};



const StyledRow = styled.div`
    display: flex;
    flex-direction: ${props => props.isMobile ? "column" : "row"};
    width: 100%;
    padding: 5px 10px 5px 10px !important;
`;

const StyledFlexboxGridItem = styled(FlexboxGrid.Item)`
    && .rs-form-group .rs-control-label {
        text-align: left;
    }
`;

const ButtonFooter = styled.div`
    text-align: center;
    margin-top: 30px;
    > .rs-btn {
        margin-right: 50px
    }
`;

const HiddenField = styled(FormControl)`
    && {
        display: none;
    }
`;