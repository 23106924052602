import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {Whisper, Tooltip} from 'rsuite';

export default ({onClick, children, ...props}) => {

    const computedStyle = getComputedStyle(document.documentElement);

    return (
        <Whisper
            placement="top"
            trigger="hover"
            speaker={
                <Tooltip>
                    <FormattedMessage
                        id="numbers.clickToEnable"
                        defaultMessage="CLICK TO <colored>ENABLE</colored>"
                        description="Numbers, NumbersAllocateModal: CLICK TO ENABLE"
                        values={{
                            colored: str => (
                                <span
                                    key="colored"
                                    className={'color-accept'}
                                    style={{color: '#4ccc37'}}
                                >
                                    {str}
                                </span>
                            )
                        }}
                    />
                </Tooltip>
            }
        >

            {children || <Content onClick={onClick} />}
        </Whisper>
    )
}

const Content = styled.div`
    cursor: not-allowed;
    height: 100%;
    margin-left: 0;
    margin-top: 0;
    position: absolute;
    width: 100%;
    z-index: 10000;
    left: 0;
    top: 0;
`;