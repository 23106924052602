import {Toggle} from "rsuite";
import styled from "styled-components";

export const BaseToggle = styled(Toggle).attrs(() => ({
    size: "lg"
}))`
    && {
        background-color: var(--color-brand1);
        
        &.rs-btn-toggle-checked {
            background-color: var(--color-brand2);
        }
        
        &:hover {
            background-color: var(--color-grey);
        }
    }
`;