import {
    SET_SUB_ACCOUNT_LIST,
    SET_SUB_ACCOUNT_LIST_LOADING,
    
    SET_SUB_ACCOUNT_DATA,
    SET_SUB_ACCOUNT_DATA_LOADING,

    SET_SUB_ACCOUNT_USER_LIST,
    SET_SUB_ACCOUNT_USER_LIST_LOADING,

    SET_SUB_ACCOUNT_NUMBER_LIST,
    SET_SUB_ACCOUNT_NUMBER_LIST_LOADING,

    SET_RESELLER_NUMBER_LIST,
    SET_RESELLER_NUMBER_LIST_LOADING,

    SET_SUB_ACCOUNT_PAYMENT_LIST,
    SET_SUB_ACCOUNT_PAYMENT_LIST_LOADING,

    SET_TRUNK_NUMBER_DIALER,
    SET_TRUNK_NUMBER_LOADING_DIALER,

    SET_RESELLER_TRAFFIC_CDR_LIST,
    SET_RESELLER_TRAFFIC_CDR_LIST_LOADING,

    SET_RESELLER_ACCESS_LIST,
    SET_RESELLER_ACCESS_LIST_LOADING,

    SET_RESELLER_HOST_LIST,
    SET_RESELLER_HOST_LIST_LOADING,

    SET_RESELLER_TEMPLATE_LIST,

    DEFAULT_PER_PAGE_MEDIUM,
    DEFAULT_PER_PAGE_SMALL
} from "const";

const initialState = {
    accountData: {},
    accountDataLoading: false,
    accountList: [],
    accountListLoading: false,
    customerList: [],
    customerListLoading: false,

    subAccountNumberList: [],
    subAccountNumberPage: 1,
    subAccountNumberCount: 0,
    subAccountNumberPerPage: DEFAULT_PER_PAGE_SMALL,
    subAccountNumberListLoading: false,

    subAccountPaymentList: [],
    subAccountPaymentListLoading: false,

    accessList: [],
    accessPage: 1,
    accessCount: 0,
    accessPerPage: DEFAULT_PER_PAGE_MEDIUM,
    accessListLoading: false,

    numberList: [],
    numberPage: 1,
    numberCount: 0,
    numberPerPage: DEFAULT_PER_PAGE_SMALL,
    numberListLoading: false,

    trunkList: [],
    trunkListLoading: false,

    cdrList: [],
    cdrReportList: [],
    cdrSummaries: {},
    cdrLoading: false,
    cdrPage: 1,
    cdrCount: 0,
    cdrPerPage: DEFAULT_PER_PAGE_SMALL,

    invoiceList: [],
    invoiceListLoading: false,

    hostList: [],
    hostListLoading: true,

    templateList: []
};

const handlers = {
    [SET_SUB_ACCOUNT_LIST]: (state, {payload: {list}}) => ({...state, accountList: list}),
    [SET_SUB_ACCOUNT_LIST_LOADING]: (state, {payload}) => ({...state, accountListLoading: payload}),
    [SET_SUB_ACCOUNT_DATA]: (state, {payload: {data}}) => ({...state, accountData: data}),
    [SET_SUB_ACCOUNT_DATA_LOADING]: (state, {payload}) => ({...state, accountDataLoading: payload}),
    [SET_SUB_ACCOUNT_USER_LIST]: (state, {payload: {list}}) => ({...state, customerList: list}),
    [SET_SUB_ACCOUNT_USER_LIST_LOADING]: (state, {payload}) => ({...state, customerListLoading: payload}),
    [SET_SUB_ACCOUNT_NUMBER_LIST]: (state, {payload: {list, page, count, perPage}}) => {
        return {
            ...state, 
            subAccountNumberList: list,
            subAccountNumberPage: page,
            subAccountNumberCount: count,
            subAccountNumberPerPage: perPage
        }
    },
    [SET_SUB_ACCOUNT_NUMBER_LIST_LOADING]: (state, {payload}) => ({...state, subAccountNumberListLoading: payload}),
    [SET_RESELLER_NUMBER_LIST]: (state, {payload: {list, page, count, perPage}}) => {
        return {
            ...state,
            numberList: list,
            numberPage: page,
            numberCount: count,
            numberPerPage: perPage
        }
    },
    [SET_RESELLER_NUMBER_LIST_LOADING]: (state, {payload}) => ({...state, numberListLoading: payload}),
    [SET_TRUNK_NUMBER_DIALER]: (state, {payload: {trunk_list}}) => ({...state, trunkList: trunk_list}),
    [SET_TRUNK_NUMBER_LOADING_DIALER]: (state, {payload}) => ({...state, trunkListLoading: payload}),
    [SET_SUB_ACCOUNT_PAYMENT_LIST]: (state, {payload: {list}}) => ({...state, subAccountPaymentList: list}),
    [SET_SUB_ACCOUNT_PAYMENT_LIST_LOADING]: (state, {payload}) => ({...state, subAccountPaymentListLoading: payload}),
    [SET_RESELLER_TRAFFIC_CDR_LIST]: (state, {payload: {cdrList, cdrReportList, page, count, perPage, summaries}}) => {
        return {
            ...state, 
            cdrList: cdrList || [],
            cdrReportList: cdrReportList || [],
            cdrPage: page,
            cdrPerPage: perPage,
            cdrCount: count,
            cdrSummaries: summaries || {}
        }
    },
    [SET_RESELLER_TRAFFIC_CDR_LIST_LOADING]: (state, {payload}) => ({...state, cdrLoading: payload}),
    
    [SET_RESELLER_ACCESS_LIST]: (state, {payload: {list, page, count, perPage}}) => {
        return {
            ...state,
            accessList: list,
            accessPage: page,
            accessCount: count,
            accessPerPage: perPage
        }
    },
    [SET_RESELLER_ACCESS_LIST_LOADING]: (state, {payload}) => ({...state, accessListLoading: payload}),
    [SET_RESELLER_HOST_LIST]: (state, {payload}) => ({...state, hostList: payload}),
    [SET_RESELLER_HOST_LIST_LOADING]: (state, {payload}) => ({...state, hostListLoading: payload}),
    [SET_RESELLER_TEMPLATE_LIST]: (state, {payload}) => ({...state, templateList: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);

}