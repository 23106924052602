import React, {useEffect, useState, useMemo, useRef, useContext, useCallback} from "react";
import {Spacer} from "../../../components/base/Spacer";
import BlockIvrSoundsFilter from "./BlockIvrSoundsFilter";
import {BaseButton, ButtonPrimary} from "../../../components/base/BaseButton";
import {Button, List, Icon, Loader, IconButton} from "rsuite";
import AudioPlayer, {RHAP_UI} from "react-h5-audio-player";
import styled from "styled-components";
import ModalAddSound from "./ModalAddSound";
import ModalCancel from "./ModalCancel";
import {PermissionContext} from "../../../App";
import {IVR_SOUND_CREATE_API, IVR_SOUND_MODIFY_API, IVR_SOUND_REMOVE_API} from "../../../const/apiMethods";
import {getFileResponse} from "../../../api/loginRoutes";
import {downloadDataAsFile} from "../../../utils";


export default (
    {
        data,
        loading,
        getIvrSoundList,
        activeIvr
    }
) => {

    const audioRefs = useRef(new Map());
    const playedSoundId = useRef(null);

    const permissions = useContext(PermissionContext);

    const [modalNewSoundShow, setModalNewSoundShow] = useState(false);
    const [modalCancelShow, setModalCancelShow] = useState(false);
    const [activeSoundId, setActiveSoundId] = useState(null);
    const [filter, setFilter] = useState({});

    const [loadingItems, setLoadingItems] = useState({});


    useEffect(() => {
        getIvrSoundList();
    }, []);

    useEffect(() => {
        getIvrSoundList(filter);
    }, [filter]);

    const addLoadingItems = useCallback((id) => {
        setLoadingItems(prev => ({...prev, [id]: true}));
    }, [loadingItems]);

    const removeLoadingItems = useCallback((id) => {
        setLoadingItems(prev => ({...prev, [id]: false}));
    }, [loadingItems]);

    const downloadSound = (id, filename) => {
        addLoadingItems(id);
        getFileResponse("ivr_sound:download", {target: {ivr_sound_id: id}}, "file")
            .then(r => {
                if (!r) {
                    return;
                }

                downloadDataAsFile(r, filename);
            })
            .finally(() => {
                removeLoadingItems(id);
            });
    };


    return (
        <>
            <Spacer size={30}/>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <BlockIvrSoundsFilter
                    style={{flexShrink: 0}}
                    activeIvr={activeIvr}
                    {...{
                        filter,
                        setFilter
                    }}
                />

                {permissions.includes(IVR_SOUND_CREATE_API) &&
                    <ButtonPrimary onClick={() => setModalNewSoundShow(true)}>Add New</ButtonPrimary>
                }
            </div>
            
            <Spacer size={30}/>

            <List>
                {data.map(item => {

                    return (
                        <List.Item key={item.id}>
                            <IvrAudio className="ivr-audio">
                                <div className="ivr-audio__title">{item.filename}</div>

                                <StyledAudioPlayer
                                    preload={"none"}
                                    ref={(ref) => audioRefs.current.set(item.id, ref)}
                                    className="ivr-audio__content"
                                    src={`${item.url_source}?${item.filename}`}
                                    autoPlayAfterSrcChange={false}
                                    onPlay={() => {
                                        if (playedSoundId.current && playedSoundId.current === item.id) {
                                            return;
                                        }

                                        if (playedSoundId.current) {
                                            const currentAudioRef = audioRefs.current.get(playedSoundId.current);
                                            if (currentAudioRef) {
                                                currentAudioRef.audio.current.pause();
                                            }
                                        }
                                        playedSoundId.current = item.id;
                                    }}
                                    autoPlay={false}
                                    layout="horizontal-reverse"
                                    showJumpControls={false}
                                    customProgressBarSection={[
                                        RHAP_UI.CURRENT_TIME,
                                        RHAP_UI.PROGRESS_BAR,
                                        RHAP_UI.DURATION,
                                    ]}
                                    customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                />

                                <div className="ivr-audio__actions">
                                    <IconButton
                                        as={BaseButton}
                                        loading={loadingItems[item.id]}
                                        buttonStyle="primary"
                                        onClick={() => downloadSound(item.id, item.filename)}
                                        icon={<Icon icon="download"/>}
                                    />

                                        {permissions.includes(IVR_SOUND_MODIFY_API) &&
                                        <BaseButton
                                            onClick={() => {
                                                setModalNewSoundShow(true);
                                                setActiveSoundId(item.id);
                                            }}
                                            buttonStyle="secondary"
                                        >
                                            Replace by sound
                                        </BaseButton>
                                    }

                                    {permissions.includes(IVR_SOUND_REMOVE_API) &&
                                        <Button
                                            onClick={() => {
                                                setModalCancelShow(true);
                                                setActiveSoundId(item.id);
                                            }}
                                            color="red"
                                        >
                                            <Icon icon="trash2"/>
                                        </Button>
                                    }
                                </div>
                            </IvrAudio>
                        </List.Item>
                    )
                })}
            </List>

            <ModalAddSound
                show={modalNewSoundShow}
                activeSoundId={activeSoundId}
                getIvrSoundList={getIvrSoundList}
                onClose={() => {
                    setModalNewSoundShow(false);
                    setActiveSoundId(null);
                }}
            />

            <ModalCancel
                show={modalCancelShow}
                activeSoundId={activeSoundId}
                getIvrSoundList={getIvrSoundList}
                onClose={() => {
                    setModalCancelShow(false);
                    setActiveSoundId(null);
                }}
            />

            {loading && <Loader backdrop />}
        </>
    )
};


const StyledAudioPlayer = styled(AudioPlayer)`
    .rhap_controls-section {
        flex-grow: 0;
    }
`;

const IvrAudio = styled.div`
    display: flex;
    align-items: center;
    
    .ivr-audio__content {
        padding: 2px 15px;
        max-width: 800px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background-color: #f3f3f3;
    }
    
    .rhap_time {
        font-size: 14px;
    }
    
    .ivr-audio__actions {
        flex-shrink: 0;
        margin-left: auto;
        
        .rs-btn {
            margin-left: 15px;
        }
    }
    
    .rhap_play-pause-button {
        font-size: 35px;
        width: 35px;
        height: 35px;
        margin-left: 0;
        
        svg {
            vertical-align: top;
        }
    }
    
    .ivr-audio__title {
        width: 270px;
        word-break: break-all
    }
`;