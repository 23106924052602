import React, {useEffect, useState, useMemo} from 'react';
import PageHeader from '../../components/base/PageHeader';
import {connect} from 'react-redux';
import PanelLayout from '../../components/base/PanelLayout';
import E164Table from './E164Table';
import E164Filter from './E164Filter';
import {getE164List} from 'actions/e164';
import {useWindowWidth} from 'hooks';
import {getReferencesForReports} from 'actions/references';
import E164DownloadButton from './E164DownloadButton';


const E164Page = ({
    page,
    per_page,
    count,
    loading,
    e164List,
    // dialerList,
    // supplierList,

    worldzone_list,
    destination_list,
    subdestination_list,

    getE164List,
    getReferencesForReports,
    ...props
}) => {

    const defaultFilter = {
        e_code: "",
        wz_key: null,
        de_key: null,
        sde_key: null,
        length_min_max: null
    };

    const [filter, onChangeFilter] = useState(defaultFilter);
    const defaultSort = {column: "e_code", type: "asc"};

    const [sort, setSort] = useState(defaultSort);

    useEffect(() => {
        getE164List(filter, 1, per_page, sort);
    }, []);
    
    const onChangeFilters = (filterValue) => {
        onChangeFilter({...filterValue});
        getE164List(filterValue, 1, per_page, sort);
    };

    const getItems = (page, perPageValue, sortValue) => {
        getE164List(filter, page, perPageValue, sortValue);
    };

    const windowWidth = useWindowWidth();

    return (
        <PanelLayout>
            <PageHeader title="E164"/>
            <E164Filter
                defaultFilter={defaultFilter}
                onChangeFilter={onChangeFilters}
                worldzone_list={worldzone_list}
                destination_list={destination_list}
                subdestination_list={subdestination_list}
            />
            <E164Table
                windowWidth={windowWidth}
                getList={getE164List}
                dataList={e164List}
                loading={loading}
                page={page}
                per_page={per_page}
                count={count}
                sort={sort}
                getItems={getItems}
            />
        </PanelLayout>
    );
};

const mapStateToProps = ({e164, references}) => ({
    // dialerList: references['sms.dialer_list'],
    // supplierList: references['sms.supplier_list'],
    worldzone_list: references.worldzone_list,
    destination_list: references.destination_list,
    subdestination_list: references.subdestination_list,

    per_page: e164.per_page,
    page: e164.page,
    count: e164.count,
    e164List: e164.list,
    loading: e164.loading
});

const mapDispatchToProps = {
    getE164List,
    getReferencesForReports
};

export default connect(mapStateToProps, mapDispatchToProps)(E164Page);

