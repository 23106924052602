import React, {useState} from 'react';
import {Alert, Checkbox, DatePicker} from 'rsuite';
import ModalSendApi from '../../../components/Modal/ModalSendApi'
import ApiRequest from '../../../components/ApiRequest'
import {getServiceSmsPrefix, toStartDayUTCTime} from 'utils';
import {useWindowWidth} from "../../../hooks";


export default ({service=true, initialValues, target, getCount, entity, update, ...props}) => {
    const windowWidth = useWindowWidth();

    const numbersCount = getCount();

    const revokeMethod = getServiceSmsPrefix(service, "trunk_number__filter:revoke");

    return (
        <ModalSendApi 
            {...props}
            title="Revoke Number"
            successText="Yes"
        >
            <ApiRequest
                method={revokeMethod}
                checkResultKey = 'trunk_numbers'
                target={target}
                update={ ({trunk_numbers}) => {
                    update();
                    Alert.success(`Revoked ${trunk_numbers} numbers`)
                }}
                data={{
                    ...initialValues
                }}
            >
                <div>
                    <p>Selected <strong>{numbersCount}</strong> {numbersCount === 1 ? "number" : "numbers"} from this trunk will be revoked. Do you really want to do it?</p>
                </div>
            </ApiRequest>
        </ModalSendApi>
    );
};