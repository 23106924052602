import React, {useState, useMemo, useRef} from "react";
import styled, {css} from "styled-components";

import {apiFormData} from "api/loginRoutes";
import {servicePick, toStartDayUTCTime} from "utils";
import {SCREEN_MEDIA, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY} from "const";
import {ACCOUNT_PRICE_PRICE_UPLOAD_CSV_API, SMS_SUPPLIER_PRICE_UPLOAD_CSV_METHOD} from "const/apiMethods";
import {
    ControlLabel, DatePicker, Schema, Form, Alert, 
    FormControl, InputNumber, SelectPicker,
} from "rsuite";
import {Spacer} from "components/base/Spacer";

import FormCheckbox from "components/Form/FormCheckbox";
import {CustomModal} from "components/base/BaseModal";
import CustomUploaderSingle from "components/Form/CustomUploaderSingle";

import CustomField from "components/Form/CustomField";


const fullPriceData = [
    {value: true, name: "Upload as full price"},
    {value: false, name: "Update existing and add new"},
];

const FILES_DEFAULT = [];
const {BooleanType, NumberType} = Schema.Types;


const formDefaultValue = {
    id_column: 1,
    name_column: 2,
    update_allocated_numbers_price: true,
}


export default (
    {
        sp_key = 1,
        cur_key,
        service,
        account_id,
        show,
        isSupplier,
        pickedSupplierPlan,
        accountServicePlanList,
        currency_list,
        paymentTermsList,
        transitSupplierPlanList,

        pickSupplierPlan,
        onSuccess,
        onClose,
        ...props
    }
) => {
    const defaultCurKey = servicePick(service, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY);

    const formRef = useRef();
    const [files, setFiles] = useState(FILES_DEFAULT);
    const [loading, setLoading] = useState(false);

    const formCommonModel = Schema.Model({
        sp_key: NumberType()
            .isRequired('Required'),
        cur_key: NumberType()
            .isRequired('Required'),
        ...(isSupplier ? {} 
        : {
            id_column: NumberType()
            .isRequired('Required')
            .min(1, 'Min value 1')
            .max(9, 'Max value 9')
        }),
        name_column: NumberType()
            .min(1, 'Min value 1')
            .max(9, 'Max value 9'),
        ...(isSupplier ? {} 
        : {
            rate_column: NumberType()
            .isRequired('Required')
            .min(1, 'Min value 1')
            .max(9, 'Max value 9')
        }),
        full_price: BooleanType()
            .isRequired('Required'),
        ...(isSupplier ? {
            update_transit_prefixes: BooleanType()
            // .isRequired('Required')
        }
        : {
            update_allocated_numbers_price: BooleanType()
            // .isRequired('Required')
        }),
    });

    // submit
    const handleSubmit = () => {
        if ( !formRef.current.check() ) {
            return;
        }

        if (!files || !files.length) {
            Alert.error('Please upload CSV file with prices');
            return;
        }

        const formValue = formRef.current.getFormValue();

        const startDate = formValue.start_date 
            ? toStartDayUTCTime(formValue.start_date).getTime() !== toStartDayUTCTime( new Date() ).getTime()
                ? toStartDayUTCTime(formValue.start_date)
                : null
            : null;

        const params = isSupplier 
        ? {
            ...formValue,

            upload_file_list: formValue.files,

            start_date: startDate,

            target: {
                'sms.supplier_plan_id': pickedSupplierPlan?.id
            }
        } 
        : {
            ...formValue,
            
            id_column: formValue.id_column 
                ? +formValue.id_column 
                : undefined,
            
            name_column: formValue.name_column 
                ? +formValue.name_column 
                : undefined,
        
            rate_column: formValue.rate_column 
                ? +formValue.rate_column 
                : undefined,
            
            start_date: startDate,

            target: {
                account_id
            }
        };

        const dt = new DataTransfer();
        dt.items.add(files[0].blobFile);
        params.files = dt.files;
        delete params.file;

        const method = isSupplier ? SMS_SUPPLIER_PRICE_UPLOAD_CSV_METHOD : ACCOUNT_PRICE_PRICE_UPLOAD_CSV_API;

        setLoading(true);
        apiFormData(method, params)
            .then(r => {
                if (!r) {
                    return;
                }

                if (r.validation_error) {
                    Alert.error(<>
                        <p><b>Error</b>: {r.validation_error}</p>
                        {r.line_number &&
                            <p>
                                {params.files && `${params.files[0].name}: `}
                                line <b>{r.line_number}</b><br/>
                                {r.line_str}
                            </p>
                        }
                    </>, 10000);
                    return;
                }

                onSuccess && onSuccess(r);
                handleClose();

            })
            .finally(() => {
                setLoading(false);
            });
    };


    const handleClose = () => {
        setFiles([]);
        onClose && onClose();
    }

    const columnsText = isSupplier ? "MCC, MNC, Rate, Date (option)" : "ID, Destination, Rate"; 

    
    return (
        <CustomModal
            showFooter
            show={show}
            title={'Upload Price'}
            loading={loading}
            onConfirm={handleSubmit}
            onClose={handleClose}
            {...props}
        >
            <StyledForm
                ref={formRef}
                model={formCommonModel}
                formDefaultValue={{
                    ...formDefaultValue,
                    sp_key,
                    cur_key: cur_key || defaultCurKey
                }}
            >
                {isSupplier ? <CustomField
                    accepter={SelectPicker}
                    data={transitSupplierPlanList}
                    cleanable={false}
                    searchable={false}
                    valueKey="id"
                    labelKey="name"
                    placeholder="Select trunk type"
                    label="Trank type"
                    value={pickedSupplierPlan?.id}
                    onChange={(id) => {
                        const supplierValue = transitSupplierPlanList.find(supplier => supplier?.id === id);

                        pickSupplierPlan(supplierValue)
                    }}
                    style={{
                        width: 230
                    }}
                /> : <></>}
                <StyledField>
                    <ControlLabel>Currency</ControlLabel>
                    <FormControl
                        disabled
                        errorPlacement={'topStart'}
                        data={currency_list}
                        accepter={SelectPicker}
                        name="cur_key"
                        labelKey="name"
                        valueKey="cur_key"
                        searchable={false}
                        cleanable={false}
                    />
                </StyledField>

                {!isSupplier && <StyledField>
                    <ControlLabel>Date</ControlLabel>
                    <FormControl
                        errorPlacement={'topStart'}
                        accepter={DatePicker}
                        name="start_date"
                        
                        ranges={[
                            {
                                label: 'Today',
                                value: new Date()
                            },
                            {
                                label: 'Tomorrow',
                                value: new Date().setDate(new Date().getDate() + 1)
                            }
                        ]}

                        disabledDate={(date) => {
                            const today = new Date();
                            today.setHours(0, 0, 0, 0);
                            return date < today;
                        }}

                    />
                </StyledField>}

                <StyledField>
                    <ControlLabel>File</ControlLabel>
                    <FormControl
                        errorPlacement={'topStart'}
                        name="file"
                        text="Upload file list"
                        files={files}
                        accepter={CustomUploaderSingle}
                        setFiles={setFiles}
                    />
                </StyledField>

                <Spacer size={15}/>

                <StyledControlLabel>
                    * The CSV file must consist of 3 columns: {columnsText}. <br/>
                </StyledControlLabel>

                {!isSupplier && <hr />}

                {!isSupplier && <FormRow>
                    
                    <FormCol>
                        <StyledField>
                            <ControlLabel>Column "ID" order</ControlLabel>
                            <FormControl
                                errorPlacement={'topStart'}
                                min={1}
                                max={9}
                                accepter={InputNumber}
                                name="id_column"
                            />
                        </StyledField>
                    </FormCol>

                    <FormCol>
                        <StyledField>
                            <ControlLabel>Column "Subdestination" order</ControlLabel>
                            <FormControl
                                errorPlacement={'topStart'}
                                min={1}
                                max={9}
                                accepter={InputNumber}
                                name="name_column"
                            />
                        </StyledField>
                    </FormCol>

                    <FormCol>
                        <StyledField>
                            <ControlLabel>Column "Rate" order</ControlLabel>
                            <FormControl
                                errorPlacement={'topStart'}
                                min={1}
                                max={9}
                                accepter={InputNumber}
                                name={`rate_column`}
                            />
                        </StyledField>
                    </FormCol>

                </FormRow>}

                <hr />

                <StyledField>
                    <ControlLabel>Upload action</ControlLabel>
                    <FormControl
                        errorPlacement={'topStart'}
                        data={fullPriceData}
                        accepter={SelectPicker}
                        name="full_price"
                        labelKey="name"
                        valueKey="value"
                        searchable={false}
                        cleanable={false}
                    />
                </StyledField>

                <FormRow>
                    <FormCol>
                        {isSupplier 
                        ? <StyledField isCheckbox>
                            <ControlLabel>Update allocated prefixes</ControlLabel>
                            <FormControl
                                errorPlacement={'topStart'}
                                accepter={FormCheckbox}
                                name="update_transit_prefixes"
                            />
                        </StyledField>
                        : <StyledField isCheckbox>
                            <ControlLabel>Update allocated numbers</ControlLabel>
                            <FormControl
                                errorPlacement={'topStart'}
                                accepter={FormCheckbox}
                                name="update_allocated_numbers_price"
                            />
                        </StyledField>}
                    </FormCol>
                </FormRow>
                
            </StyledForm>
        </CustomModal>
    )
}

const StyledForm = styled(Form)`

`;

const FormRow = styled.div`
    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const StyledControlLabel = styled(ControlLabel)`
    font-style: italic;
    opacity: 0.6;
`;

const FormCol = styled.div`

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        padding-left: 10px;
        padding-right: 10px;
        width: 50%;

        &:nth-child(2n - 1) {
            padding-left: 0
        }

        &:nth-child(2n) {
            padding-right: 0
        }
    }
`;

const StyledField = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
    }

    .rs-control-label {
        flex-shrink: 0;
        align-self: center;
        padding-right: 10px;
        width: 100%;

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            width: 150px;
        }
    }

    .rs-input-number {

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            max-width: 90px;
        }
    }

    ${props => props.isCheckbox && css`
        display: flex;
        
        @media (max-width: ${SCREEN_MEDIA.sm.max}px) {

            .rs-form-control-wrapper {
                width: auto;
            }

            .rs-control-label {
                width: auto;
                order: 1;
            }
        }
    `}
`;