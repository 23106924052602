import React, {useMemo, useState, useEffect} from "react";
import RatesTable from "./RatesTable";
import RatesFilters from "./RatesFilters";
import styled from "styled-components";
import ExportPrice from "./ExportPrice";
import {Form, Schema, DatePicker} from "rsuite";
import CustomField from "../../../components/Form/CustomField";
import Modal from "../../../components/Modal";
import {api} from "../../../api/loginRoutes";
import Checkbox from "../../../hoc/Checkbox";
import AddPrice from "./AddPrice";
import RateField from "../../../components/Form/RateField";
import {connect} from "react-redux";
import {servicePick, toStartDayUTCTime} from "utils";
import {
    DEFAULT_PER_PAGE_SMALL,
    SP_KEY__GLOBAL_ACCESS
} from "const";
import {
    ACCOUNT_PRICE_MODIFY_API,
    ACCOUNT_PRICE_REMOVE_API,
    SMS_SUPPLIER_PRICE_MODIFY_LIST_METHOD,
    SMS_SUPPLIER_PRICE_REMOVE_LIST_METHOD
} from "../../../const/apiMethods";
import DropdownAddPrices from "./DropdownAddPrices";
import ModalUploadPrice from "./ModalUploadPrice";
import ModalUploadResult from "./ModalUploadResult";


const {NumberType} = Schema.Types;


const TabRates = (
    {
        rates, 
        ratesLoading, 
        countryList,
        worldzone_list, 
        subdestination_list, 
        destination_list, 
        getItems, 
        account,
        service,
        account_id,
        supplier_id,

        isSupplier,

        e212List,
        defaultSPKey,
        currency_list,
        payment_terms_list,
        accountServicePlanList,

        servicePlanList,
        suppliersPlanList,
        getE212DropdownList,
        getReferencesForReports,
        getSupplierPlanList,
    }
) => {

    const hasTransitPlans = suppliersPlanList && suppliersPlanList.filter((supplierPlan) => supplierPlan.type === "transit").length;
    const hasServicePlans = servicePlanList && servicePlanList.length;
    
    const [items, setItems] = React.useState(rates || []);
    const [loading, setLoading] = React.useState(false);
    const [total, setTotal] = React.useState( (rates || []).length);
    const [per_page, onChangePerPage]  = React.useState(+localStorage.getItem("ratesPerPage") || DEFAULT_PER_PAGE_SMALL);
    const [activePage, onChangePage] = React.useState(1);
    const [modalAddPriceShow, setModalAddPriceShow] = useState(false);

    const [filter, setFilter] = React.useState({sp_key: defaultSPKey});
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [deleteID, setDeleteId] = React.useState(false);

    const transitSupplierPlanList = useMemo(() => {
        return suppliersPlanList && suppliersPlanList.length 
            ? suppliersPlanList.filter(plan => plan.type === "transit")
            : []
    }, [suppliersPlanList]);

    const filteredPriceList = useMemo(() => {
        return !service ? 
            hasTransitPlans 
                ? transitSupplierPlanList
                : hasServicePlans && servicePlanList.filter(servicePlan => servicePlan.sp_key !== SP_KEY__GLOBAL_ACCESS)
        : null;
    }, [service, isSupplier, hasServicePlans, transitSupplierPlanList, servicePlanList]);


    const [pickedSupplierPlan, pickSupplierPlan] = useState(transitSupplierPlanList.length && transitSupplierPlanList[0]);


    useEffect(() => {
        setItems(rates || []);
        setTotal((rates || []).length);
        onChangePage(1);
    }, [rates]);

    useEffect(() => {
        if (pickedSupplierPlan?.id) {
            getItems(filter, pickedSupplierPlan.id);
        }
    }, [pickedSupplierPlan])

    useEffect(() => {
        if (!isSupplier) {
            getItems(filter);
        } else {
            if (supplier_id)
                getSupplierPlanList(supplier_id);

            getE212DropdownList({}, true);
            getReferencesForReports();
        }
    }, []);
    
    const onChangeFilters = (filter) => {
        getItems(filter, pickedSupplierPlan?.id);
        setFilter(filter);
    };

    const onClickDelete = (id) => { 
        setDeleteId(id);
        setDeleteModal(true)
    };

    const deleteId = async () => {
        setDeleteModal(false);
        setLoading(true);
        const method = isSupplier ? SMS_SUPPLIER_PRICE_REMOVE_LIST_METHOD : ACCOUNT_PRICE_REMOVE_API;
        const params = isSupplier ? {
            target: {
                "sms.supplier_plan_id": pickedSupplierPlan?.id
            }, 
            "sms.supplier_price_key_list": [deleteID]
        } : {
            target: {
                account_id, 
                account_price_id: deleteID
            }
        };

        const result = await api(method, params);

        setLoading(false);

        if (result) {
            return getItems(filter, pickedSupplierPlan?.id);
        }
        
    };
    const onSavePerPage = (per_page) => {
        localStorage.setItem("ratesPerPage", per_page);
        onChangePerPage(per_page);
    };

    const [editModal, setEditModal] = React.useState(false);
    const [rateToEdit, setRateToEdit] = React.useState(null);
    const [editDisabled, setEditDisabled] = React.useState(false);
    const [formRef , setFormRef] = React.useState(null);

    const [modalUploadPriceShow, setModalUploadPriceShow] = useState(false);
    const [modalUploadPriceResultShow, setModalUploadPriceResultShow] = useState(false);
    const [modalUploadPriceResultData, setModalUploadPriceResultData] = useState(false);

    const onClickEdit = (rate) => {
        setRateToEdit(rate);
        setEditModal(true)   
    };

    const editId = async () => {
        if(formRef.check()){
            const data = {...formRef.getFormValue()};
            setEditDisabled(true);

            if (data.withDate) {
                data.start_date = data.start_date ? toStartDayUTCTime(new Date(data.start_date)) : null;
            } else {
                delete data.start_date;
            }

            delete data.withDate;
            if (data.hasOwnProperty("end_date"))
                delete data.end_date;

            const method = isSupplier ? SMS_SUPPLIER_PRICE_MODIFY_LIST_METHOD : ACCOUNT_PRICE_MODIFY_API;
            const params = isSupplier ? {
                target: {
                    "sms.supplier_plan_id": pickedSupplierPlan?.id
                }, 
                "sms.supplier_price_key_list": [rateToEdit?.key],
                ...data,
                rate: +formRef.getFormValue().rate
            } : {
                target:{
                    account_id, 
                    account_price_id: rateToEdit.id
                },
                ...data,
                rate: +formRef.getFormValue().rate
            };

            const result = await api(method, params);

            setEditDisabled(false);

            if (result) {
                setEditModal(false);
                getItems(filter, pickedSupplierPlan?.id);
            }

        }
    };

    const accountPaymentTermList = payment_terms_list.filter(item => item.pt_key === account.pt_key);


    return (
        <Container>
            <RatesFiltersContainer>
                <RatesFilters {...{
                    isSupplier,
                    countryList,
                    worldzone_list, 
                    subdestination_list, 
                    destination_list,
                    pickSupplierPlan,
                    pickedSupplierPlan,
                    transitSupplierPlanList
                }}
                filteredServicePlanList={filteredPriceList}
                onChange = {onChangeFilters}
                />
            </RatesFiltersContainer>

            <div style={{marginTop: 10, float: "right"}}>
                <ExportPrice 
                    filter={filter}
                    service={service}
                    account_id={account_id}
                    isSupplier={isSupplier}
                    pickedSupplierPlan={pickedSupplierPlan}
                    disabled={(isSupplier && !hasTransitPlans) || (items && !items.length)}
                />
                <DropdownAddPrices
                    filter={filter}
                    account_id={account_id}
                    setModalAddPriceShow={setModalAddPriceShow}
                    setModalUploadPriceShow={setModalUploadPriceShow}
                    disabled={(isSupplier && !hasTransitPlans)}
                />
            </div>

            <AddPrice 
                {...{
                    subdestination_list,
                    account_id,
                    isSupplier,
                    e212List,
                    pickSupplierPlan,
                    pickedSupplierPlan,
                    transitSupplierPlanList
                }}
                show={modalAddPriceShow}
                setShow={setModalAddPriceShow}
                updateItems={() => {
                    getItems(filter, pickedSupplierPlan?.id)
                }}
            />
            <RatesTable  
                data={items} 
                loading={ratesLoading || loading}
                onChangePerPage={onSavePerPage}
                {...{
                    total,
                    service,
                    per_page,
                    activePage, 
                    onChangePage,
                    onClickDelete,
                    onClickEdit,
                    isSupplier
                }}

            />
            <Modal 
                footer
                show={deleteModal} 
                title="Deleting Price" 
                onClose={() =>{setDeleteModal(false)}}
                onSuccess={deleteId}
            >
                <p>You are sure ?</p>
            </Modal>

            <Modal 
                show={editModal} 
                title="Change Rate" 
                footer
                onClose={() =>{
                    setEditModal(false)
                }}
                onSuccess={editId}
                successText="Update"
            >
                <ModalForm 
                    disabled={editDisabled}
                    updateFormRef={setFormRef}
                    rate={rateToEdit}
                    isSupplier={isSupplier}
                />
            </Modal>

            {modalUploadPriceShow &&
                <ModalUploadPrice
                    show={true}
                    service={service}
                    account_id={account_id}
                    isSupplier={isSupplier}
                    pickedSupplierPlan={pickedSupplierPlan}
                    title={`Upload Price for ${account.name}`}
                    sp_key={filter.sp_key || defaultSPKey}
                    cur_key={account.cur_key}
                    accountServicePlanList={accountServicePlanList}
                    currency_list={currency_list}
                    paymentTermsList={accountPaymentTermList}
                    transitSupplierPlanList={transitSupplierPlanList}

                    pickSupplierPlan={pickSupplierPlan}
                    onSuccess={(data) => {
                        setModalUploadPriceResultShow(true);
                        setModalUploadPriceResultData(data);

                        getItems(filter, pickedSupplierPlan?.id);
                    }}
                    onClose={() => setModalUploadPriceShow(false)}
                >

                </ModalUploadPrice>
            }


            <ModalUploadResult
                show={modalUploadPriceResultShow}
                data={modalUploadPriceResultData}
                isSupplier={isSupplier}
                onClose={() => setModalUploadPriceResultShow(false)}
            />
        </Container>
    )
};

const mapStateToProps = ({references}) => ({
    defaultSPKey: references.defaultSPKey,
    servicePlanList: references.service_plan_list
});

export default connect(mapStateToProps)(TabRates);

const Container = styled.div`
    margin-top: 30px;
`;

const RatesFiltersContainer = styled.div`
    display: inline-block;    
`;

const formModel = Schema.Model({
    rate: NumberType().isRequired("Required"),
});

const ModalForm = ({updateFormRef, rate, isSupplier}) => {
    const [withDate, setWithDate] = useState(false);

    return (
        <Form
            layout = "inline"
            model={formModel}
            formDefaultValue={rate}
            ref= { ref => updateFormRef(ref)}
        >
            <RateField name="rate" label="Rate"/>
            <br/>
                <Checkbox onChange={(checked) => {setWithDate(checked)}} name={"withDate"}>From date</Checkbox>
                <CustomField
                    accepter={DatePicker}
                    placeholder="Enter Date"
                    name="start_date"
                    disabled={!withDate}
                    ranges={[
                        {
                            label: "Today",
                            value: new Date()
                        },
                        {
                            label: "Tomorrow",
                            value: new Date().setDate(new Date().getDate() + 1)
                        }
                    ]}
                    disabledDate={(date) => {
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        return date < today;
                    }}

                />
            <br/>
            {!isSupplier 
                ? <Checkbox name="update_allocated_numbers">Update for all allocated numbers</Checkbox>
                : <Checkbox name="update_transit_prefixes">Update for all allocated prefixes</Checkbox>
            }

         </Form>
    );
};