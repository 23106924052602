import React, {useRef, useMemo, useState} from 'react';
import {Alert, Form, FormGroup, FormControl, Icon, List, Schema, InputNumber, Input, Button, FlexboxGrid} from 'rsuite';
import {useWindowWidth} from "../../../hooks";
import {createGlobalStyle} from 'styled-components';
import { compareObjects } from 'utils';
import Table from '../../../components/Table';
import styled, {css} from 'styled-components';
import {Spacer} from "../../../components/base/Spacer";
import {
    SMS_SUPPLIER_TRAFFICLIMIT_CREATE_METHOD, TRUNK_MODIFY_API
} from 'const/apiMethods';
import BaseTable from "../../../components/base/BaseTable";
import Modal from '../../../components/Modal';

import TableEditCellsNew from 'components/Table/TableEditCells/TableEditCellsNew';
import {TrafficLimitTable} from './TrunkModal/TrunkForm';

const {NumberType, StringType} = Schema.Types;


const dayModel = Schema.Model({
    day: NumberType()
        .min(1, 'Minimum 1')
        .max(10000000, 'Maximum 10000000')
        .isRequired('This field is required'),
});

const hourModel = Schema.Model({
    hour: NumberType()
        .min(1, 'Minimum 1')
        .max(10000000, 'Maximum 10000000')
        .isRequired('This field is required'),
});

const supplierIdModel = Schema.Model({
    senderid: StringType()
        .maxLength(40, 'Maximum 40'),
});


const trafficLimitModel = Schema.Model.combine(dayModel, hourModel, supplierIdModel);

export default ({
    show,
    onClose,
    target, 
    prefixList,
    prefixKeyList, 
    getCount, 
    update,
    trunkId,
    supplierId,

    isGanNumbers,

    createTrafficLimit,
    modifyTrafficLimit,
    removeTrafficLimit,
    updateCurrentPrefixes,
    ...props
}) => {
    // const selectedPrefixKeys = prefixKeyList ? Array.from(prefixKeyList.keys()) : [];
    
    const windowWidth = useWindowWidth();
    const prefixCount = getCount();

    const [currentKey, setCurrentKey] = useState(null);

    const dayModel = Schema.Model({
        day: NumberType()
            .min(1, 'Minimum 1')
            .max(10000000, 'Maximum 10000000')
            .isRequired('This field is required'),
    });

    const hourModel = Schema.Model({
        hour: NumberType()
            .min(1, 'Minimum 1')
            .max(10000000, 'Maximum 10000000')
            .isRequired('This field is required'),
    });

    const supplierIdModel = Schema.Model({
        senderid: StringType()
            .maxLength(40, 'Maximum 40'),
    });

    const selectedPrefixKeyList = Array.from(prefixKeyList.keys());
    const selectedPrefixValueList = Array.from(prefixKeyList.values());

    const trafficLimitList = selectedPrefixValueList.map(prefixValue => prefixValue.trafic_limit_list);

    const initTrafficLimitValue = useMemo(() => {
        if (!selectedPrefixKeyList.length) {
            const currentFirstValue = trafficLimitList[0];

            return currentFirstValue;
        }
        let currentPrefix;

        if (prefixList && prefixList.length) {
            currentPrefix = prefixList.find(prefix => selectedPrefixKeyList.includes(prefix.key));
        }

        const trafficLimitListValue = currentPrefix ? currentPrefix.trafic_limit_list : [];

        return trafficLimitListValue.map(trafficLimit => {
            return {
                ...trafficLimit,
                id: `${trafficLimit.key}_${trafficLimit.senderid}`
            };
        });
    }, [prefixList, trafficLimitList]);


    const [error, setError] = useState({});
    const [trafficLimitListState, setTrafficLimitListState] = useState(initTrafficLimitValue);

    const selectedPrefixList = useMemo(() => {
        return selectedPrefixValueList.map(value => value?.prefix);
    }, [selectedPrefixValueList]);

    const trafficLimitColumns = [
        {
            name: 'Sender ID',
            label: 'Sender ID',
            dataKey: 'senderid',
            width: 400,
            // flexGrow: 1,
            placeholder: 'Sender ID',

            disabledOnEdit: true,

            accepter: Input,
            model: supplierIdModel

        },
        {
            name: 'Limit day',
            label: 'Limit day',
            dataKey: 'day',
            width: 140,
            // flexGrow: 1,
            accepter: InputNumber,
            placeholder: 'Limit day',
            model: dayModel
        },
        {
            name: 'Limit hour',
            label: 'Limit hour',
            dataKey: 'hour',
            width: 140,
            // flexGrow: 1,
            accepter: InputNumber,
            placeholder: 'Limit hour',
            model: hourModel
        }
    ];

    const onSubmit = async () => {
        let isChanged = false;

        trafficLimitListState.forEach(async (trafficLimit) => {
            const currentTrafficLimit = {...trafficLimit};
            // delete currentTrafficLimit.id;
            const currentInitialValue = initTrafficLimitValue.find(initTrafficLimit => {
                return initTrafficLimit.senderid === currentTrafficLimit.senderid;
            });

            if (currentTrafficLimit.key && currentTrafficLimit.key.toString().startsWith("new")) {
                isChanged = true;
                await createTrafficLimit({
                    target: {
                        'sms.supplier_id': supplierId,
                        ...(isGanNumbers ? {'sms.supplier_trunk_id': trunkId} : {}),
                        ...(isGanNumbers ? 
                            {'sms.supplier_gan_key_list': selectedPrefixKeyList} :
                            {'sms.supplier_prefix_prefix_list': selectedPrefixList}
                        )      
                    },
                    senderid: currentTrafficLimit.senderid || "",
                    day: parseInt(currentTrafficLimit.day),
                    hour: parseInt(currentTrafficLimit.hour),
                    type: isGanNumbers ? 'gan' : 'prefix',
                }).then(() => {
                    Alert.success('Suppliers traffic limit was created');
                    update(true);
                });
            } else {
                if (!compareObjects(currentInitialValue, currentTrafficLimit)) {
                    isChanged = true;
                    await modifyTrafficLimit({
                        target: {
                            'sms.supplier_id': supplierId,
                            'sms.supplier_trunk_id': trunkId,
                            ...(isGanNumbers ? 
                                {'sms.supplier_gan_key_list': selectedPrefixKeyList} :
                                {'sms.supplier_prefix_prefix_list': selectedPrefixList}
                            ),
                            'senderid_list': [currentTrafficLimit.senderid]
                        },
                        hour: parseInt(currentTrafficLimit.hour),
                        day: parseInt(currentTrafficLimit.day),
                        type: isGanNumbers ? 'gan' : 'prefix',
                    }).then(() => {
                        Alert.success('Suppliers traffic limit was modified');
                        update(true);
                    });
                }
            }
        });

        initTrafficLimitValue.forEach(async (initTrafficLimit) => {
            const deletedInitTrafficLimit = !trafficLimitListState.find(trafficLimit => trafficLimit.key === initTrafficLimit.key);

            if (deletedInitTrafficLimit) {
                isChanged = true;
                await removeTrafficLimit({
                    target: {
                        'sms.supplier_id': supplierId,
                        'sms.traffic_limit_key_list': [initTrafficLimit.key]
                    },
                    type: isGanNumbers ? 'gan' : 'prefix',
                }).then(() => {
                    Alert.success('Suppliers traffic limit was deleted');
                    update(true);
                });
            }
        });

        onClose();
        return;
    };

    return (
        <>
            <Modal
                {...{onClose}}
                show={true}
                onSuccess={onSubmit}
                // disabled ={disabled}
                extraDisabled={Object.keys(error).filter((errorKey) => error[errorKey].length).length}
                title="Set Limit Modal"
                footer
                successText = "Save"
                width={820}
            >
                <div>
                    <p>Traffic limit for selected <strong>{prefixCount}</strong> {prefixCount === 1 ? "prefix" : "prefixes"} from this trunk will changed. Do you really want to do it?</p>
                    {/* <TableEditCellsNew
                        rowIdKey={'key'}
                        defaultRowIdKey={'senderid'}
                        virtualized
                        data={initTrafficLimitValue}
                        height={400}
                        columns={trafficLimitColumns}
                        headerHeight={40}
                        autoHeight={false}
                        shouldUpdateScroll={false}
                        formModel={trafficLimitModel}
                        addButtonWidth={100}
                        onDataUpdate={async (value) => {
                            await updateCurrentPrefixes();
                        }}
                        defaultDisableKey="trunk_lm_key"
                        onRowCreate={async (value) => {
                            const response = await createTrafficLimit({
                                target: {
                                    'sms.supplier_id': supplierId,
                                    ...(isGanNumbers ? {'sms.supplier_trunk_id': trunkId} : {}),
                                    ...(isGanNumbers ? 
                                        {'sms.supplier_gan_key_list': selectedPrefixKeyList} :
                                        {'sms.supplier_prefix_prefix_list': selectedPrefixList})
                                    
                                },
                                ...value,
                                senderid: value.senderid || "",
                                day: parseInt(value.day),
                                hour: parseInt(value.hour)
                            });
    
                            if (response['sms.traffic_limit cnt']) {
                                setCurrentKey(selectedPrefixKeyList[0]);
                                
                                Alert.success(`${selectedPrefixKeyList.length} traffic limits was added`);
                            }
                    
                            return response;
                        }}
                        onRowSubmit={async (value) => {
                            let response;
                            
                

                            if (isGanNumbers) {
                                const currentFirstValue = selectedPrefixValueList[0];

                                response = await createTrafficLimit({
                                    target: {
                                        'sms.supplier_id': supplierId,
                                        // 'sms.supplier_trunk_id': trunkId,
                                        'sms.supplier_gan_number_list': [currentFirstValue?.number]
                                    },
                                    ...value,
                                    senderid: value.senderid || "",
                                    day: parseInt(value.day),
                                    hour: parseInt(value.hour)
                                });
                            } else {
                                response =  await modifyTrafficLimit({
                                    target: {
                                        'sms.supplier_id': supplierId,
                                        'sms.supplier_trunk_id': trunkId,
                                        'sms.traffic_limit_key_list': [value.key]
                                    },
                                    ...value,
                                    type: isGanNumbers ? 'gan' : 'prefix',
                                    senderid: value.senderid || "",
                                    day: parseInt(value.day),
                                    hour: parseInt(value.hour)
                                });
                            }
                                
                            if (response['sms.traffic_limit_key_ar']) {
                                setCurrentKey(selectedPrefixKeyList[0]);

                                Alert.success(`Traffic limit "${value.senderid}" was changed`);
                            }
                    
                            return response;
                        }}
                        onRowRemove={async (value) => {
                            const response = await removeTrafficLimit({
                                target: {
                                    'sms.supplier_id': supplierId,
                                    // 'sms.supplier_trunk_id': trunkId,
                                    'sms.traffic_limit_key_list': [value]
                                },

                                type: isGanNumbers ? 'gan' : 'prefix',
                                // ...value
                            });
    
                            if (response['sms.traffic_limit_key']) {
                                setCurrentKey(selectedPrefixKeyList[0]);

                                Alert.success(`Traffic limit was removed`);
                            }
                    
                            return response;
                        }}
                    /> */}

                    <StyledTrafficLimitSection>
                        {trafficLimitListState && trafficLimitListState.length ? <StyledFlexboxGrid justify="start">
                                <FlexboxGrid.Item style={{width: 244}}>Sender ID</FlexboxGrid.Item>
                                <FlexboxGrid.Item style={{width: 140}}>day</FlexboxGrid.Item>
                                <FlexboxGrid.Item style={{width: 120}}>hour</FlexboxGrid.Item>
                            </StyledFlexboxGrid>
                        : <></>}

                        <StyledGrid
                            isMobile={windowWidth < 620}
                            // align={isMobile ? 'flex-start' : 'center'}
                            style={{margin: 0, marginLeft: 30}}
                        >
                            <StyledList>
                                {trafficLimitListState.map((trafficLimit, idx) => {
                                    return (<StyledListItem>
                                        <Form
                                            model={trafficLimitModel}
                                            onChange={(value) => {
                                                const currentFormValue = {...trafficLimit, ...value};
                                                const checkedFormValue = trafficLimitModel.check(currentFormValue);
                                                const hasErrorFormValue = Object.keys(checkedFormValue).filter(key => {
                                                    const checkedValue = checkedFormValue[key];

                                                    return checkedValue?.hasError;
                                                });

                                                setError(prev => {
                                                    const prevError = {...prev};
                                                    prevError[trafficLimit.id] = hasErrorFormValue;
                                                    return prevError;
                                                });
                                                
                                                setTrafficLimitListState(prev => {
                                                    const prevList = prev.map((x) => x);
                                                    prevList[idx] = {
                                                        ...prevList[idx],
                                                        ...value
                                                    };
                                                    return prevList;
                                                })
                                            }}
                                        >
                                            <StyledFormGroup>
                                                <span className="index">{idx + 1}</span>
                                                <FormControl
                                                    name="senderid"
                                                    placeholder="Sender ID"
                                                    style={{width: 220}}
                                                    value={trafficLimit.senderid}
                                                    disabled={!trafficLimit.id.startsWith("new")}
                                                />
                                                <FormControl
                                                    name="day"
                                                    placeholder="Day"
                                                    style={{width: 120}}
                                                    value={trafficLimit.day}
                                                />
                                                <FormControl
                                                    name="hour"
                                                    placeholder="Hour"
                                                    style={{width: 120}}
                                                    value={trafficLimit.hour}
                                                />
                                                <Button
                                                    appearance="ghost"
                                                    onClick={() => {
                                                        const newList = trafficLimitListState.map(x => x);
                                                        newList.splice(idx, 1);

                                                        setTrafficLimitListState(newList);
                                                    }}
                                                    disabled={trafficLimit.id.startsWith("null")}
                                                >
                                                    <Icon icon="trash"/>
                                                </Button>
                                            </StyledFormGroup>   
                                        </Form>
                                    </StyledListItem>);
                                })}
                                <AddNewButton
                                    appearance="ghost"
                                    onClick={() => {
                                        const newList = trafficLimitListState.map(x => x);
                                        newList.push({key: "new", id: "new", senderid: "", day: null, hour: null});
                                        setTrafficLimitListState(newList);
                                    }}
                                >
                                    <Icon icon="plus"/> Add new{trafficLimitListState && !trafficLimitListState.length ? ' limit' : ''}
                                </AddNewButton>
                            </StyledList>
                        </StyledGrid>
                    </StyledTrafficLimitSection>

                    {/* <FlexboxGrid justify="space-around">
                        <FlexboxGrid.Item colspan="18">
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <StyledButton
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Close
                            </StyledButton>
                        </FlexboxGrid.Item>
                    </FlexboxGrid> */}
                </div>
            </Modal>
        </>
    );
};


const StyledTrafficLimitSection = styled.div`
    margin-top: 30px;
`;


const StyledButton = styled(Button)`
    && {
        margin-top: 20px;
        height: 42px;
        width: 80px;
    }

`;

const StyledFlexboxGrid = styled(FlexboxGrid)`
    && {
        margin-top: 20px;
        margin-left: 60px;
        margin-bottom: 10px;
    }

    && .rs-flex-box-grid-item-2:not(:last-child) {
        margin-right: 107px;
    }
`;

const AddNewButton = styled(Button)`
    margin-left: 34px;
`;

const StyledList = styled(List)`
    && {
        box-shadow: none;
        margin-bottom: 10px;
        width: 700px;
        height: 350px;
    }

    && .rs-list-item {
        box-shadow: none;
    }

    && .rs-form-control-wrapper {
        margin-right: 20px;
    }

    &&& .rs-list-item .rs-form-group {
        margin-bottom: 0;
    }

    && .rs-list-item .separator {
        background: white;
        color: gray;
        // border: 1px solid #E5E5EA;
        // border-right: 0;
        // border-left: 0;
        height: 36px;
        width: 34px;
        position: relative;
        display: inline-block;
        padding-top: 6px;
        padding-left: 13px;
        top: -1px;
    }

    && .rs-list-item .index {
        background: white;
        color: gray;
        // border: 1px solid #E5E5EA;
        // border-right: 0;
        height: 36px;
        width: 34px;
        position: relative;
        display: inline-block;
        float: left;
        padding-top: 6px;
        padding-left: 13px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    && .rs-list-item .delete {
        /* display: inline-block; */
        /* position: absolute; */
        /* right: -3px; */
        top: 4px;
        cursor: pointer;
        border: 1px black;
        background: white;
        border-radius: 1px;
        width: 15px;
        height: 15px;
    }

    && .rs-list-item .delete_traffic {
        display: inline-block;
        position: absolute;
        margin-left: 5px;
        top: 12px;
        cursor: pointer;
        border: 1px black;
        background: white;
        border-radius: 1px;
        width: 15px;
        height: 15px;
    }

    && .rs-list-item .delete::before {
        display: flex;
        justify-content: center;
        width: 12px;
        flex-direction: row;
        align-items: center;
        align-content: center;
    }
`;

const StyledFormGroup = styled(FormGroup)`
    && {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &&& .rs-form-control-wrapper {
        max-width: none;
        width: inherit;
    }
`;

const StyledListItem = styled(List.Item)`
    && {
        padding-top: 0px;
    }
`;

const StyledGrid = styled(FlexboxGrid)`
    ${props => props.isMobile && css`
        &&& {
            flex-direction: column;
        }
    `}
`;



const GlobalStyle = createGlobalStyle`
    .rs-picker-menu .ReactVirtualized__Grid__innerScrollContainer > div:nth-child(1) .rs-picker-select-menu-item {
        padding: 18px !important;
    }
`;

const StyledField = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;

    .rs-control-label {
        flex-shrink: 0;
        align-self: center;
        padding-right: 10px;
        width: 100%;

    }
`;