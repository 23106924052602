import React, {useState, useEffect} from "react";
import {Form, Schema, SelectPicker, CheckPicker} from "rsuite";
import FormHOC from "hoc/FilltersForm";
import ServicePlans from "components/Filters/ServicePlans";
import Text from "components/Form/Text";
import CheckboxFullControl from "components/Form/CheckboxFullControl";
import CustomField from "components/Form/CustomField";
import {OTP_SERVICE_PLAN_KEY} from "const";
import { BaseToggle } from "components/base/BaseToggle";
import {servicePlanNoTrunkFilter} from "utils/filters";

import styled from "styled-components";

const { StringType } = Schema.Types;

const filtersModel = Schema.Model({
    str: StringType().maxLength(40, "Max characters 40"),
    str2: StringType().maxLength(40, "Max characters 40")
    }
);

export default FormHOC( ({
    service,
    onChange,
    extended,

    supplierList,
    currency_list,
    defaultFormValue,

    setExtended
}) => {
    const [formValue, setFormValue] = useState(defaultFormValue);
    const [changedServicePlan, onChangeServicePlan] = useState(null);

    const servicePlanField = extended
        ? <ServicePlans
            filtered
            cleanable
            extraPlaceholder="Type"
            accepter={SelectPicker}
            name="sp_key"
            onChange={(value) => {
                onChangeServicePlan(value)
            }}
            filterFunc={servicePlanNoTrunkFilter}
        />
        : <ServicePlans
            filtered
            cleanable
            extraPlaceholder="Type"
            accepter={CheckPicker}
            name="sp_key_list"
            onChange={(value) => {
                onChangeServicePlan(value)
            }}
            filterFunc={servicePlanNoTrunkFilter}
        />;

    useEffect(() => {
        setFormValue(defaultFormValue);
    }, [extended]);

    return (
        <FilterForm
            model={filtersModel}
            onChange={(value) => {
                onChange(value);
                setFormValue(value);
            }}
            formValue={formValue}
            formDefaultValue={defaultFormValue}
            layout="inline"
            style = {{marginBottom: "-10px"}}
        >
            {!!service && <ToggleField>
                <BaseToggle
                    checkedChildren="Extended"
                    unCheckedChildren="Default"
                    defaultChecked={extended}
                    onChange={value => setExtended(value)}
                />
            </ToggleField>}

            <StyledField>
                {servicePlanField}
            </StyledField>

            {!service ? <StyledField>

                <CustomField
                    accepter={CheckPicker}
                    data={supplierList}
                    cleanable
                    searchable
                    labelKey="name"
                    valueKey="id"
                    name="supplier_id_list"
                    placeholder="Select supplier"
                    style={{
                        width: 220
                    }}
                />
            </StyledField> : <></>}


            <StyledField>
                <CustomField
                    accepter={SelectPicker}
                    style={{
                        maxWidth: "80px",
                        minWidth: "30px"
                    }}
                    data={currency_list}
                    cleanable={false}
                    searchable={false}
                    labelKey="name"
                    valueKey="cur_key"
                    name="cur_key"
                    classPrefix="allocated"
                />
            </StyledField>

            {extended && <StyledField>
                <Text
                    name="originator"
                    style={{
                        maxWidth: "320px",
                        minWidth: "300px"
                    }}
                    placeholder={`Filter per originator`}
                />
            </StyledField>}


            <StyledField>
                <Text
                    name={service && !extended ? "str" : "origin"}
                    style={{
                        maxWidth: "280px",
                        minWidth: "265px"
                    }}
                    placeholder={`Filter per origin${changedServicePlan === OTP_SERVICE_PLAN_KEY ? ", Service" : ""} (min 3 characters)`}
                />
            </StyledField>

            <StyledField>
                <Text
                    name={service && !extended ? "str2" : "destination"}
                    style={{
                        maxWidth: "320px",
                        minWidth: "300px"
                    }}
                    placeholder={`Filter per destination${changedServicePlan === OTP_SERVICE_PLAN_KEY ? ", Service" : ""} (min 3 characters)`}
                />
            </StyledField>

            {!extended && <StyledField>
                <CustomField
                    accepter={CheckboxFullControl}
                    name="no_number"
                >
                    Hide prefixes
                </CustomField>
            </StyledField>}
        </FilterForm>
    )
}, filtersModel, 500);

const FilterForm = styled(Form)`
    && {
        display: flex;
        flex-direction: row;
    }
`;

const StyledField = styled.div`
    display: inline-block;
`;

const ToggleField = styled.div`
    display: inline-block;
    margin-top: 5px;
    width: 100px;
`;