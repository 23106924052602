import {connect} from 'react-redux';

import {getCLIList, loadingData} from '../../actions/client/cli';

import CLI from './CLI';

const mapState = ({auth_dialer, client_cli}) => ({
    dialerInfo: auth_dialer.dialerInfo,
    accountJoinList: auth_dialer.accountJoinList,
    service: auth_dialer.serviceDialer,

    CLIList: client_cli.items,
    count: client_cli.count,
    CLILoading: client_cli.loading,
    page: client_cli.page,
    per_page: client_cli.per_page,
});

export default connect(mapState, {
    getCLIList,
    loadingData
})(CLI);