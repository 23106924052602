import {connect} from 'react-redux';
import DialerGroups from "./DialerGroups";
import {
    createDialerGroup, 
    modifyDialerGroup,
    removeDialerGroup,
    getDialerGroupsList,
    addAccountToGroup,
    removeAccountFromGroup,
    getDialerGroupsAccountsList
} from "../../actions/dialer_groups";


const mapState = ({dialer_groups}) => ({
    loading: dialer_groups.loading,
    data: dialer_groups.list,
    accountLoading: dialer_groups.accountLoading,
    accountsData: dialer_groups.accountList,
    otherAccountsData: dialer_groups.otherAccountList
});

export default connect(mapState, {
    createDialerGroup,
    modifyDialerGroup,
    removeDialerGroup,
    getDialerGroupsList,
    addAccountToGroup,
    removeAccountFromGroup,
    getDialerGroupsAccountsList
})(DialerGroups);