import {MANUAL_ACCESS_LIST as PREFIX, DEFAULT_PER_PAGE_MEDIUM, DESC_SORT} from '../const';
import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import { 
    MANUAL_ACCESS_LIST_GET_LIST_API, SMS_MANUAL_ACCESS_LIST_GET_LIST_API, 
    MANUAL_ACCESS_LIST_CREATE_API, SMS_MANUAL_ACCESS_LIST_CREATE_API,
    MANUAL_ACCESS_LIST_CANCEL_API, SMS_MANUAL_ACCESS_LIST_CANCEL_API,
    MANUAL_ACCESS_LIST_REMOVE_API, SMS_MANUAL_ACCESS_LIST_REMOVE_API
 } from "../const/apiMethods";
// cancellation
import axios from 'axios';
import { servicePick } from 'utils';

let cancelToken = axios.CancelToken.source();

const setManualAccessList = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);


export const getManualAccessList = (service) => (dispatch) => {
    dispatch(setLoading(true));

    // let sort1, sort1_desc;

    // if (sort.column) {
    //     sort1 = sort.column;
    //     sort1_desc = sort.type && sort.type === DESC_SORT;
    // }

    const params = {};

    const method = servicePick(service, MANUAL_ACCESS_LIST_GET_LIST_API, SMS_MANUAL_ACCESS_LIST_GET_LIST_API);

    if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();

    api(method, {}, false, cancelToken)
        .then((response) => {
            
            if (axios.isCancel(response)) {
                return;
            }

            if (response === undefined) {
                dispatch(setLoading(false));
                return;
            }

            const items = servicePick(service, response["access_list_manual_list"], response["sms.access_list_manual_list"]);
            // localStorage.setItem(PREFIX + '_per_page', per_page);

            dispatch(setManualAccessList({items}));

            dispatch(setLoading(false))
        });
};


export const onCreateManualAccessItem = (service, data, targetData={}) => async (dispatch) => {
    const method = servicePick(service, MANUAL_ACCESS_LIST_CREATE_API, SMS_MANUAL_ACCESS_LIST_CREATE_API);

    return await api(
        method,
        {
            target: targetData,
            ...data,
        }
    );
};
export const onCancelManualAccessItem = (service, alm_key) => async (dispatch) => {
    const method = servicePick(service, MANUAL_ACCESS_LIST_CANCEL_API, SMS_MANUAL_ACCESS_LIST_CANCEL_API);

    return await api(
        method, {alm_key}
    );
};
export const onRemoveManualAccessItem = (service, alm_key) => async (dispatch) => {
    const method = servicePick(service, MANUAL_ACCESS_LIST_REMOVE_API, SMS_MANUAL_ACCESS_LIST_REMOVE_API);
    
    return await api(
        method, {alm_key}
    );
};