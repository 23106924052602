import React from "react";
import { Form, FormControl, FormGroup } from "rsuite";
import { debounce } from "utils";
import styled, {css} from "styled-components";

import m from "definedMessages";

const AccessListFilter = ({
    filter,
    disabled,
    issueWidth,
    
    onChangeFilter,

    formatMessage
}) => {
    return (
        <StyledForm
            issueWidth={issueWidth}
            formDefaultValue={filter}
            onChange={debounce((value) => {
                onChangeFilter(value);
            }, 500)}
        >
            <StyledFormGroup>
                <StyledFormControl
                    disabled={disabled}
                    placeholder={formatMessage(m.originNameOrNumberMinDigits)}
                    name="filter_cli"
                    errorPlacement="topRight"
                />
                <StyledFormControl
                    disabled={disabled}
                    placeholder={formatMessage(m.destinationNameOrNumberMinDigits)}
                    name="filter_phone"
                    errorPlacement="topRight"
                />
            </StyledFormGroup>
        </StyledForm>
    )
};

export default AccessListFilter;

const StyledForm = styled(Form)`
    && {

        ${props => props.issueWidth ? css`
            .rs-form-group {
                width: 100%;
                display: block;
                margin-right: 20px;
            }
            .rs-form-control-wrapper {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 15px;
            }
            .rs-input {
                width: 100%;
                min-width: 0;
                max-width: none;
            }
            .rs-form-control-wrapper > .rs-input {
                /* width: none; */
            }
        ` : css`
            .rs-form-control-wrapper {
                width: auto;
                margin-right: 20px;
            }
        `}
        width: 100%;
        display: flex;
        flex-grow: 1;
    }


`;

const StyledFormControl = styled(FormControl)`
    && {
        width: 100%;
        min-width: 300px;
        max-width: 320px;
        overflow: hidden;
    }
`;

const StyledFormGroup = styled(FormGroup)`
    && {
        display: flex;
        align-items: center;

        .rs-control-label {
            margin-right: 10px;
            margin-bottom: 0px;
        }
    }
`;