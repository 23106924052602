import {connect} from 'react-redux';
import {
    getHotAccessList,
    setEmptyHotAccessList,
    setLoadingData,
} from '../../actions/client/hot_accesses';
import {getTrunkListDialer} from '../../actions/client/price';
import {setDialerService} from "../../actions/auth_dialer";

import HotAccesses from './HotAccesses';


const mapState = ({client_hot_accesses, auth, auth_dialer}) => {
    return {
        service: auth_dialer.serviceDialer,
        account: auth.authInfo,
        // permissions: auth.permissions,
        accountDialerState: auth_dialer.dialerInfo,
        accountJoinList: auth_dialer.accountJoinList,

        accessesList: client_hot_accesses.items,
        count: client_hot_accesses.count,
        accessesLoading: client_hot_accesses.loading,

        page: client_hot_accesses.page,
        per_page: client_hot_accesses.per_page,
    }
};


export default connect(mapState, {
    getTrunkList: getTrunkListDialer,

    getHotAccessList,
    setLoadingData,
    setDialerService,

    setEmptyHotAccessList
})(HotAccesses);