import React, {useState, useEffect} from 'react';
import {Divider, Button, FlexboxGrid} from 'rsuite';
import ApiPageKeys from '../../../pages/Account/ApiPage/ApiPageKeys';
import PageHeader from '../../../components/base/PageHeader';
import ApiKeyModal from '../../../pages/Account/ApiPage/ApiKeyModal';
import styled from 'styled-components';
import { transformed_service_reverse_data } from '../../../const';

const ApiPage = ({
    accountInfo = {},
    account_id,
    keyList,
    service,
    accountJoinList,
    accountSession,
    getKeyList,
    deleteApiKey,
    modifyApiKey,
    createApiKey
}) => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [addedNewApiKey, setAddedNewApiKey] = useState(false);
    
    const serviceTransformed = transformed_service_reverse_data[service];
    const accountByService = accountJoinList.find(account => account.service === serviceTransformed) || {};

    useEffect(() => {
        getKeyList(accountByService?.id)
    }, [accountJoinList]);

    return (
        <>
            <StyledPageHeader extraTitle={"API Keys"}/>
            <Divider/>
            <StyledFlexboxGrid align="top">
                <FlexboxGrid.Item>
                    <Button
                        appearance="primary"
                        onClick={() => {
                            setShowCreateModal(true);
                            setAddedNewApiKey(true);
                        }}
                    >
                        Create new
                    </Button>
                </FlexboxGrid.Item>
            </StyledFlexboxGrid>
            <FlexboxGrid align="top">
                <FlexboxGrid.Item>
                    <ApiPageKeys
                        keyList={keyList}
                        modifyApiKey={modifyApiKey}
                        deleteApiKey={deleteApiKey}
                        account_id={accountByService?.id}
                        addedNewApiKey={addedNewApiKey}
                        onAddedNewApiKey={setAddedNewApiKey}
                    />
                </FlexboxGrid.Item>
        </FlexboxGrid>

        {showCreateModal &&
            <ApiKeyModal
                title="Create new"
                show={showCreateModal}
                onClose={setShowCreateModal}
                onSuccessMethod={createApiKey}
                account_id={accountByService?.id}
            />
        }
    </>
    );
};

export default ApiPage;

const StyledPageHeader = styled(PageHeader)`
    margin-bottom: -20px;
    margin-left: 15px;
`;

const StyledFlexboxGrid = styled(PageHeader)`
    margin-left: 10px;
    padding-bottom: 0;
    padding-top: 0;
`;