import { BaseButton } from "../../components/base/BaseButton";
import React, {useMemo} from "react";
import {Button, Checkbox, Icon, Input} from 'rsuite';
import TableEditCellsNew from "../../components/Table/TableEditCells/TableEditCellsNew";
import {formatDateApi, removeTZFromDate, toUTCDateTime} from '../../utils';
import {CustomCheckbox} from '../../components/Form/CustomCheckbox';
import moment from 'moment';


export default (
    {
        data,
        loading,
        loadableGroups,
        onClickEdit,
        onClickUpload,
        ...props
    }
) => {

    const columns = useMemo(() => [
        {
            id: 'supplier_name',
            dataKey: 'supplier_name',
            name: 'Supplier name',
            flexGrow: 4
        },
        {
            id: 'comment',
            dataKey: 'comment',
            name: 'Comment',

            flexGrow: 4
        },
        {
            id: 'price_percent_payment_terms_list',
            value: ({price_percent_payment_terms_list}) => (
                price_percent_payment_terms_list.reduce((summ, item, index, arr) => {
                    const percentValue = (item.rate_percent * 10 * 10).toFixed(1) * 1;
                    return summ + `${item.payment_terms_name}: -${(percentValue)}%${index + 1 !== arr.length ? ', ' : ''}`
                }, '')
            ),
            name: 'Payout autogeneration',
            // width: 300,
            flexGrow: 3
        },
        {
            id: 'upload_numbers_count',
            value: ({upload_count}) => (upload_count.toString()),
            name: 'Upload numbers count',
            // width: 160,
            flexGrow: 2
        },
        {
            id: 'allocated_numbers_count',
            value: ({allocated_count}) => (allocated_count.toString()),
            name: 'Allocated numbers count',
            // width: 160,
            flexGrow: 2
        },
        {
            id: 'date_upload_access_list',
            value: ({upload_at}) => (upload_at ? moment(toUTCDateTime(new Date(upload_at))).format("YYYY/MM/DD hh:mm") : null),
            name: 'Access Upload Date',
            // width: 300,
            flexGrow: 2
        },
        {
            name: 'Timeless',
            dataKey: 'access_list_timeless',
            value: ({access_list_timeless}) => <Checkbox
                disabled
                checked={access_list_timeless }
                style={{marginTop: '-7px'}}
            />,
            width: 80,
            align: 'center',
            accepter: CustomCheckbox,
            defaultValue: ({access_list_timeless}) => access_list_timeless,
        },
        {
            name: '',
            dataKey: "actions",
            value: (rowData) => {
                return (
                    <div className="tableFilters_buttons">
                        <Button
                            size="sm"
                            color="lightblue"
                            onClick={() => onClickEdit(rowData)}
                        >
                            <Icon icon="edit2"/>
                        </Button>

                        <BaseButton
                            size="sm"
                            buttonStyle="primary"
                            tooltipText="Upload access lists"
                            onClick={() => onClickUpload(rowData)}
                        >
                            <Icon icon="upload"/>
                        </BaseButton>
                    </div>
                )
            },
            width: 100,
        }
    ], [loadableGroups]);


    return (
        <TableEditCellsNew
            rowIdKey={'supplier_key'}
            data={data}
            loading={loading}
            columns={columns}
            autoHeight={true}
            editable={false}
            showFormCreate={false}
            rowClassName={(rowData) => rowData?.access_list_expired ? 'expired' : ''}
            {...props}
        />
    )
};