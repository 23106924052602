import React from 'react';
import MyForm from './../../../../../components/MyForm';
import Checkbox from '../../../../../hoc/Checkbox';
import NumberField from '../../../../../components/Form/NumberField';
import CustomField from '../../../../../components/Form/CustomField';
import {FlexboxGrid, FormControl, Icon, Schema, Whisper, Tooltip, SelectPicker} from 'rsuite/es';
import {MIN_RATE_VALUE, ALLOCATION_NUMBER_LIMIT_PANEL} from '../../../../../const';
import {Input} from "rsuite";
import {getBlobContent, getTargetByService} from "../../../../../utils";
import {getFileResponse} from 'api/loginRoutes';
import styled from "styled-components";
import {methodByService} from '../../../../../const/apiMethods';
import TemplateWhisper from "../../../../../components/Template/TemplateRegexWhisper";

const {NumberType} = Schema.Types;
const NUMBER_LIMIT = 1000;


export default ({
    activeTab, onSelect, target, showResult, setHideBufferButtons, activeTrunk,
    spAuth = false, setSummaryState, setCsvContent, setTransactionId, service,
    setTotalNumbersForced, setNotAllocatedNumbers, isMobile, getNewNumbers, supplierList, ...props
}) => {

    let timerid = null;
    const [templateList, setTemplateList] = React.useState([]);
    const [valueForm, setValueForm] = React.useState({random_number: true, numbers: 1});
    const [showRateInput, onChangeShowRateInput] = React.useState(null);
    const [customRateValue, onChangeCustomRateValue] = React.useState(null);

    const handleFormChange = async (newFormValues) => {
        setValueForm(newFormValues);
    };

    const changeTemplateList = (str) => {
        clearTimeout(timerid);

        timerid = setTimeout((str) => {

            const list = str.trim()
                .replace( /\r?\n/g, ' ')
                .split(' ').filter( s => s !== "");

            setTemplateList(list);
        }, 300, str);
    };

    const model = {
        rate: NumberType()
            .min(MIN_RATE_VALUE, `The minimum of this field is ${MIN_RATE_VALUE}`)
            .max(10, 'The maximum of this field is 10'),
        numbers: NumberType()
            .isRequired('Required')
            .isInteger('Only whole numbers')
            .max(NUMBER_LIMIT, `The maximum of this field is ${NUMBER_LIMIT}`)
    };


    const handleUpdateNew = ({trunk_number_transaction, not_allocated_numbers}) => {
        if (trunk_number_transaction) {
            const transactionId = trunk_number_transaction.id;
            const summaryInfo = {...trunk_number_transaction};

            getNewNumbers(service, transactionId);

            setSummaryState(summaryInfo);
            setTransactionId(transactionId);

            setTotalNumbersForced(summaryInfo.numbers);
            setHideBufferButtons(false);
            setNotAllocatedNumbers(not_allocated_numbers);
            showResult();
        }
    };

    const rateField = (
        <FlexboxGrid justify="start" style={{margin: '0 0 20px 0'}}>
            {!showRateInput

                ? <FlexboxGrid.Item>
                    <div
                        style={{cursor: 'pointer', margin: '8px 20px 0 0', display: 'inline-block'}}
                        onClick={() => {onChangeShowRateInput(true)}}
                    >
                        <Icon icon="book"
                              inverse
                              style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d', marginRight: '5px'}}
                        />
                        <a style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d',}}>Put custom rate</a>
                    </div>
                </FlexboxGrid.Item>

                : <FlexboxGrid.Item>
                    <div style={{display: 'inline-block', marginTop: '8px', marginRight: '10px'}}>Rate</div>
                    <div style={{display: 'inline-block', marginRight: '10px'}}>
                        <FormControl
                            style={{width: 140}}
                            errorPlacement="bottomStart"
                            placeholder="0.0001"
                            name="rate"
                            value={customRateValue}
                            onChange={(value) => {
                                onChangeCustomRateValue(value);
                            }}
                        />
                    </div>
                    <div style={{display: 'inline-block', marginTop: '8px', marginRight: '10px'}}>
                        <Icon
                            icon="close"
                            inverse
                            style={{
                                color: '#5e2f9d',
                                marginRight: '5px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                onChangeShowRateInput(false);
                                onChangeCustomRateValue(null)
                            }}
                        />
                    </div>
                </FlexboxGrid.Item>
            }
        </FlexboxGrid>
    );

    const targetByService = getTargetByService(target, service);
    
    return (
        <>
      
            <MyForm
                activeTrunk={activeTrunk}
                target={{
                    ...targetByService,
                    ...(valueForm?.supplier_id ? {"sms.supplier_id": valueForm.supplier_id} : {})
                }}
                method={methodByService['template_list'][service]}
                checkResultKey='price_range_number_list'
                update={handleUpdateNew}
                {...props}
                clearFormValueByKeys={["supplier_id"]}
                className="numberForm"
                onChange={handleFormChange}
                formValue={valueForm}
                addData={{
                    template_list: templateList,
                    rate: customRateValue !== null ? +customRateValue : null,
                    ...(!service ? {check_supplier_prefix: valueForm?.supplier_id ? true : false} : {})
                }}
                model={model}

                deepCloned={true}
            >

                {/* Show Rate form */}
                {!isMobile && rateField}
                <TemplateContainer>
                    <TemplateWhisper/>
                    <FormControl
                        accepter={StyledTextarea}
                        errorPlacement="topLeft"
                        componentClass="textarea"
                        className="test123"
                        classPreffix="test234"
                        name='template_list'
                        onChange={changeTemplateList}
                        rows={3}
                        placeholder="Enter your numbers here"
                    />
                </TemplateContainer>

                {!service ? <StyledSuppliersField
                    accepter={SelectPicker}
                    data={supplierList}
                    name="supplier_id"
                    label="Supplier"
                    placeholder="Suppliers"
                    valueKey="supplier_id"
                    labelKey="name"
                    cleanable={true}
                    searchable={true}
                /> : <></>}

                <NumberField
                    name="numbers"
                    label="Numbers per template"
                    min={1}
                    max={NUMBER_LIMIT}
                />

                <br style={{display: isMobile ? 'none' : 'block'}}/>

                <Checkbox name="random_number" defaultChecked>Random order</Checkbox>

                <br style={{display: isMobile ? 'none' : 'block'}}/>

                {isMobile && rateField}

            </MyForm>
        </>
    );
};



const TemplateContainer = styled.div`
    && {
        margin: 0;
        position: relative;
        width: 300px;
        height: 0px;
    }

    && .rs-icon {
        position: absolute;
        z-index: 100;
        top: 10px;
        right: 10px;
    }
`;

const StyledSuppliersField = styled(CustomField)`
    && {
        width: 240px !important;
        display: block;
    }
`;

const StyledTextarea = styled(Input)`
    &&& {
        min-width: 1px;
        width: 100%;
        
        @media (min-width: 767px) {
            width: 40%;
            height: 500px;
            float: left;
            margin-right: 5%;
            min-width: 300px;
        }
    }
`;