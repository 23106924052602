import React, {useRef, useMemo, useState, useEffect} from 'react';
import {Panel} from 'rsuite';

import Notes from "./Notes/NotesContainer";
import TabRates from './Rates/TabRates';
import TabGeneral from './General/TabGeneral';
import AccountHeader from './AccountHeader';
import AccountChanges from "./AccoutChanges/AccountChangesContainer";
import ApiPageContainer from './ApiPage/ApiPageContainer';
import TabAccessListFilters from './TabAccessListFilters/TabAccessListFilters';
import TabAccessList from './TabAccessList/TabAccessList';
import TabPaymentManagement from "./TabPaymentManagement/TabPaymentManagementContainer";
import SelfAllocationRestrictions from "./SelfAllocationRestrictions/SelfAllocationRestrictionsContainer";
import TabTrunksContainer from './Trunks/TabTrunksContainer';
import TabGanTrunksContainer from './Trunks/TabGanTrunksContainer';
import TabTransitTrunksContainer from './Trunks/TabTransitTrunksContainer';
// import TrafficLimits from './TabTrafficLimits/TrafficLimits/TrafficLimitsContainer';
import TabTrafficLimits from './TabTrafficLimits/TabTrafficLimits';
import {compareTwoArrays, compareObjects} from '../../utils/helpers';
import TabPostbackLogs from './TabPostbackLogs/TabPostbackLogs';
import TabPostbackLogsContainer from './TabPostbackLogs/TabPostbackLogsContainer';
import { usePrevious, useWindowWidth } from 'hooks';
import { transformed_service_reverse_data } from 'const';

const tabsList = {
    general: 'general',
    trunks: 'trunks',
    gan_trunks: 'gan_trunks',
    transit_trunks: 'transit_trunks',
    rates: 'rates',
    transit_rates: 'transit_rates',
    access: 'access',
    supplier_access: 'supplier_access',
    traffic_limits: 'traffic-limits',
    postback_logs: 'postback-logs',
    api: 'api',
    self_allocation: 'self-allocation',
    payment_management: 'payment-management',
    notes: 'notes',
    changes: 'changes'
};

const Account = ({
    rates,
    match,
    account,
    service,
    history,
    authInfo,
    location,
    e212List,
    ganTrunks,
    role_list,
    accessList,
    setService,
    permissions,
    howFindList,
    redirectTab,
    countryList,
    joinLoading,
    transitRates,
    ratesLoading,
    account_list,
    accountUsers,
    currency_list,
    modifiedLimit,
    supplierTrunks,
    worldzone_list,
    accountLoading,
    accountJoinList,
    currentSupplier,
    admin_role_list,
    destination_list,
    client_role_list,
    onSetRedirectTab,
    loadingReferences,
    accessListLoading,
    billingServiceList,
    payment_terms_list,
    newCreatedAccountId,
    transitRatesLoading,
    accountUsersLoading,
    subdestination_list,
    account_manager_list,
    accountServicePlanList,
    account_user_modify_method,
    account_user_remove_method,
    payment_terms_supplier_list,
    
    getRates: getRateList,
    getKeyList,
    getAccount,
    getAccounts,
    getAccountJoin,
    getTransitRates,
    getAccountUsers,
    setResellerMode,
    suppliersPlanList,
    unsetTrunksInited,
    setModifiedLimitId,
    setCurrentSupplier,
    getAccessListFilter,
    getDropdownAccounts,
    getSupplierPlanList,
    getE212DropdownList,
    setEmptyAccountTrunk,
    getReferencesForReports,
    getAccountServicePlanList,
    setAllocationRestrictions,
    getAccountManagersDropdownList
}) => {
    const tabTrunks = useRef(null);

    const storagedActiveTab = localStorage.getItem("ACCOUNT_ACTIVE_TAB");
    const afterCreateService = localStorage.getItem("AFTER_CREATE_SERVICE");

    const ratemodRole = (role_list.includes('Ratemod') || role_list.includes('Ratemod (sms)'));
    const isRatemod = role_list && role_list.length ? ratemodRole: null;
    const isSupplier = history.location.pathname.includes("sms-suppliers");

    const serviceTransformed = transformed_service_reverse_data[service];
    const accountJoinSafeList = accountJoinList && accountJoinList.length ? accountJoinList : [];
    const accountByService = accountJoinSafeList.find(account => account.service === serviceTransformed) || {};
    const hasAccountService = !!Object.keys(accountJoinSafeList).length;

    const serviceCompared = serviceTransformed === account.service;

    const prevServiceCompared = usePrevious(serviceCompared);

    const calculateTab = (secondUpdate=false) => {
        const supplierDefaultTab = isSupplier ? tabsList.gan_trunks : tabsList.trunks;
        const comparedServiceDefaultTab = secondUpdate && (serviceCompared && hasAccountService) || serviceCompared && !afterCreateService ? supplierDefaultTab : tabsList.general;
        const ratemodDefaultTab = isRatemod ? tabsList.supplier_access : comparedServiceDefaultTab;
        return ratemodDefaultTab;
    };

    const defaultTab = useMemo(() => calculateTab(), []);

    const [activeTab, setActiveTab] = useState(storagedActiveTab || defaultTab);
    const accountId = match.params.id;

    const resizedWidth = useWindowWidth();

    const paymentTermsList = useMemo(() => {
        return isSupplier ? payment_terms_supplier_list : payment_terms_list;
    }, [isSupplier, payment_terms_list, payment_terms_supplier_list]);

    useEffect(() => {
        if (serviceCompared !== prevServiceCompared) {
            localStorage.removeItem("ACCOUNT_ACTIVE_TAB");
        }

        if (!afterCreateService && !storagedActiveTab) {
            const calculatedTab = calculateTab(true);
            setActiveTab(calculatedTab);
        }
    }, [defaultTab, serviceCompared])

    useEffect(() => {
        const savedSupplier = localStorage.getItem(`CURRENT_SUPPLIER_${accountId}`);

        if (savedSupplier) {
            const parsedSupplier = JSON.parse(savedSupplier);
            setCurrentSupplier(parsedSupplier);

            // localStorage.removeItem("CURRENT_SUPPLIER");
        }

        if (!redirectTab) {
            const tab = new URLSearchParams(location.search).get('tab');
            const isTabExists = tab && Object.keys(tabsList).find(item => tab === tabsList[item]);
            
            if (isTabExists) {
                setActiveTab(tab);
            }
        } else {
            setActiveTab(redirectTab);
        }
        if (!isRatemod) {
            getAccountUsers(accountId);
        }

        const setSupplierCallback = isSupplier ? setCurrentSupplier : null;
        getAccountJoin(accountId, setSupplierCallback);
    }, [accountId, newCreatedAccountId]);

    useEffect(() => {
        if (serviceTransformed === account?.service && !isRatemod) {
            getRateList(accountId, undefined, service);
            getAccountServicePlanList(accountId);

            const storagedActiveTab = localStorage.getItem("ACCOUNT_ACTIVE_TAB");

            if (storagedActiveTab) {
                localStorage.removeItem("ACCOUNT_ACTIVE_TAB");
            }
            
            if (afterCreateService) {
                localStorage.removeItem("AFTER_CREATE_SERVICE");
            }
        }

        // getAccountUsers(accountId);

        // const setSupplierCallback = isSupplier ? setCurrentSupplier : null;

        // getAccountJoin(accountId, setSupplierCallback);
    }, [account.id]);

    useEffect(() => {
        const savedSupplier = localStorage.getItem(`CURRENT_SUPPLIER_${accountId}`);

        if (savedSupplier && savedSupplier !== JSON.stringify(currentSupplier)) {
            const parsedSupplier = JSON.parse(savedSupplier);
            setCurrentSupplier(parsedSupplier);
            // localStorage.removeItem("CURRENT_SUPPLIER");
        }
    }, [currentSupplier]);

    useEffect(() => {
        const storagedActiveTab = localStorage.getItem("ACCOUNT_ACTIVE_TAB");

        if (storagedActiveTab && storagedActiveTab === tabsList.gan_trunks && ganTrunks.length === 0 && !isRatemod) {
            setActiveTab(tabsList.transit_trunks);
        }
    }, [ganTrunks]);

    const onSelectTab = (activeKey) => {
        setEmptyAccountTrunk();
        unsetTrunksInited();
        
        // localStorage.setItem('CURRENT_SUPPLIER', JSON.stringify({...currentSupplier}));

        const tab = new URLSearchParams(location.search);
        tab.delete("tab");

        history.replace({
            ...location,
            search: tab.toString()
        });

        setActiveTab(activeKey);
    };

    const getRates = (filter, supplier_plan_id) => {
        if (supplier_plan_id) {
            getTransitRates(filter, supplier_plan_id);
            return;
        }

        getRateList(accountId, filter, service);
    };

    const getAccess = (filter, page, per_page, sort) => {
        getAccessListFilter(accountId, filter, page, per_page, sort);
    };

    const getApiKeys = () => {
        getKeyList(accountId);
    };

    const isGeneralTabDependencyLoading = loadingReferences || accountLoading || accountUsersLoading;

    const isMobile = resizedWidth < 1200;

    return (
        <Panel style={{marginTop: 10}} classPrefix="white-panel">

            <AccountHeader
                service={service}
                activeTab={activeTab}
                onSelect={onSelectTab}
                account={account}
                isMobile={isMobile}
                role_list={role_list}
                isSupplier={isSupplier}
                accountJoinList={accountJoinSafeList}
                serviceCompared={serviceCompared}
                accountByService={accountByService}
                billingServiceList={billingServiceList}
            />

            {
                (() => {
                    switch (activeTab) {
                        case tabsList.general:
                            
                            return <TabGeneral
                                disabled={isGeneralTabDependencyLoading}

                                {...{
                                    account,
                                    authInfo,
                                    accountId,
                                    accountJoinList,
                                    joinLoading,
                                    service,
                                    setService,
                                    isSupplier: isSupplier,

                                    permissions,
                                    account_list,
                                    currency_list,
                                    payment_terms_list: paymentTermsList,
                                    account_manager_list,
                                    client_role_list,
                                    admin_role_list,
                                    accountUsers,
                                    account_user_modify_method,
                                    account_user_remove_method,
                                    getAccount,
                                    getAccounts,
                                    getAccountJoin,
                                    getDropdownAccounts,
                                    currentSupplier,
                                    onSetRedirectTab,
                                    loading: isGeneralTabDependencyLoading,
                                    getAccountManagersDropdownList,
                                    countryList,
                                    howFindList,
                                    supplierTrunks,
                                    setResellerMode
                                }}
                                
                                getAccountUsers={() => {
                                    getAccountUsers(accountId)
                                }}
                            />;

                        case tabsList.trunks:
                            return <TabTrunksContainer
                                account={account}
                                accountId={accountId}
                                isMobile={isMobile}
                                activeTab={activeTab}
                            />;

                        case tabsList.gan_trunks:
                            return <TabGanTrunksContainer
                                account={account}
                                accountId={accountId}
                                service={service}
                                isMobile={isMobile}
                                activeTab={activeTab}
                                currentSupplier={currentSupplier}
                            />;

                        case tabsList.transit_trunks:
                            return <TabTransitTrunksContainer
                                account={account}
                                accountId={accountId}
                                service={service}
                                isMobile={isMobile}
                                activeTab={activeTab}
                                currentSupplier={currentSupplier}
                            />;

                        case tabsList.rates:
                            return <TabRates
                                {...{
                                    rates,
                                    service,
                                    ratesLoading,
                                    currency_list,
                                    worldzone_list,
                                    destination_list,
                                    payment_terms_list: paymentTermsList,
                                    subdestination_list,
                                    accountServicePlanList  
                                }}
                                account={account}
                                getItems={getRates}
                                account_id={accountId}
                            />;
                        
                        case tabsList.transit_rates:
                            return <TabRates
                                {...{
                                    rates: transitRates,
                                    ratesLoading: transitRatesLoading,
                                    e212List,
                                    countryList,
                                    currency_list,
                                    worldzone_list,
                                    destination_list,
                                    payment_terms_list: paymentTermsList,
                                    subdestination_list,
                                    accountServicePlanList
                                }}
                                isSupplier={isSupplier}
                                account={account}
                                supplier_id={currentSupplier?.supplier_id}
                                getItems={getRates}
                                suppliersPlanList={suppliersPlanList}
                                getSupplierPlanList={getSupplierPlanList}
                                getE212DropdownList={getE212DropdownList}
                                getReferencesForReports={getReferencesForReports}
                            />;


                        case tabsList.access:
                            return <TabAccessListFilters {...{
                                    accessList,
                                    accessListLoading
                                }}
                                getItems={getAccess}
                                account_id={accountId}
                            />;
                        case tabsList.supplier_access:
                            return <TabAccessList
                                currentSupplier={currentSupplier}
                            />;

                        case tabsList.traffic_limits:
                            return <TabTrafficLimits
                                currentSupplier={currentSupplier}
                                isSupplier={isSupplier}
                            />;
                        
                        case tabsList.postback_logs:
                            return <TabPostbackLogsContainer
                                account_id={accountId}
                            />;

                        case tabsList.api:
                            return <ApiPageContainer
                                getItems={getApiKeys}
                                account_id={accountId}
                                account={account}
                            />;

                        case tabsList.self_allocation:
                            return <SelfAllocationRestrictions
                                {...{
                                    account,
                                    getAccount,
                                    accountServicePlanList,
                                    loading: isGeneralTabDependencyLoading,
                                    setAllocationRestrictions,
                                    setModifiedLimitId,
                                    modifiedLimit
                                }}
                            />;
                        
                        case tabsList.payment_management:
                            return <TabPaymentManagement
                                accountId={accountId}
                                service={service}
                                isSupplier={isSupplier}
                            />;

                        case tabsList.notes:
                            return <Notes
                                accountId={accountId}
                            />;

                        case tabsList.changes:
                            return <AccountChanges
                                accountId={accountId}
                            />

                        default:
                            return null;
                    }
                })()
            }

        </Panel>
    );
};

export default Account;