import {ROUTING_SET_LOADING, ROUTING_SET_LIST} from '../actions/actionTypes';

const initialState = {
    list: [],
    loading: true
};

const handlers = {
    [ROUTING_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [ROUTING_SET_LIST]: (state, {payload: {list}}) => ({
        ...state,
        list,
        loading: false,
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}