import React, {useRef, useMemo} from "react";
import {Checkbox, CheckboxGroup, CheckPicker, Form, FormControl, Schema} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../../components/base/FlexGrid";
import FieldDataRange from "../../../components/Form/FieldDataRange";
import {daysCount, debounce} from "../../../utils";

const {ArrayType} = Schema.Types;

export default ({filters, onChangeFilters, accounts, setStatus, checkedStatus, globalSettings}) => {

    const formRef = useRef(null);

    const formModel = useMemo(() => {
        const daysInterval = globalSettings.billing_interval_multy_account_days || 0;
        return Schema.Model({
            start_end_date: ArrayType()
                .addRule((value, data) => {
                    if ( daysCount(value[0], value[1]) > daysInterval ) {
                        if (!data.account_id_list || !data.account_id_list.length)
                            return false;

                        if (data.account_id_list.length > 1)
                            return false;
                    }
                    return true;
                }, `For multiple accounts selection difference is more than ${daysInterval} days`),
        })
    }, []);

    const filteredAccounts = accounts.filter( item => {
        return item.is_managed;
    } );

    const formattedAccounts = filteredAccounts.map((account) => {
        return {
            ...account,
            name: `${account.name} / ${account.service}`
        }
    });

    const onChangeStatusFilter = (value) => {
        setStatus(value.map(item => item));
    };

    return (
        <Form
            model={formModel}
            ref={formRef}
            formDefaultValue={filters}
            onChange={debounce((value) => {
                if (!formRef.current.check())
                    return;
                onChangeFilters(value);
            }, 500)}
        >
            <FlexGrid align="middle">
                <FlexGridItem>
                    <FormControl
                        accepter={CheckPicker}
                        sticky
                        name="account_id_list"
                        data={formattedAccounts}
                        valueKey="id"
                        labelKey="name"
                        style={{ width: 224 }}
                    />
                </FlexGridItem>
                <FlexGridItem>Date from</FlexGridItem>
                <FlexGridItem>
                    <FieldDataRange name="start_end_date"/>
                </FlexGridItem>
                <FlexGridItem>
                    <CheckboxGroup
                        inline={true}
                        name="status"
                        onChange={onChangeStatusFilter}
                        defaultValue={checkedStatus}
                    >
                        <Checkbox value={0}>Only unpaid</Checkbox>
                        <Checkbox value={1}>Only ready for payment</Checkbox>
                    </CheckboxGroup>
                </FlexGridItem>
            </FlexGrid>
        </Form>
    )
}