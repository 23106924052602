import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import {
    getAccessListFiltersItems,
    createNewAccessListFiltersItem,
    modifyAccessListFiltersItem,
    removeAccessListFiltersItem,

    getAccessListNotificationSettings,
    unsetAccessListNotificationSettings,
    modifyAccessListNotificationSettings
} from "actions/accounts";
import {
    getAuthNumberType
} from "actions/auth_numbers";
import AccessListFilters from "./AccessListFilters";

const AccessListFiltersContainer = ({
    items,
    service,
    itemsPage,
    itemsLoading,
    itemsPerPage,
    worldzoneList,
    destinationList,
    subdestinationList,
    numberTypeFamilyList, 
    accessListNotificationSettings,
    
    getAuthNumberType,
    getAccessListFiltersItems,
    removeAccessListFiltersItem,
    modifyAccessListFiltersItem,
    createNewAccessListFiltersItem,
    getAccessListNotificationSettings,
    unsetAccessListNotificationSettings,
    modifyAccessListNotificationSettings,
    ...props
}) => {
    const getAccessListFiltersItemsByAccountId = (accountId) => {
        getAccessListFiltersItems(service, accountId);
    };

    const modifyAccessListNotificationSettingsById = async (id, data) => {
        return await modifyAccessListNotificationSettings(id, data);
    };

    return (
        <>
            <AccessListFilters
                accountId={props.match.params.id}
                service={service}
                items={items}
                itemsLoading={itemsLoading}
                itemsPage={itemsPage}
                itemsPerPage={itemsPerPage}

                notificationSettings={accessListNotificationSettings}

                worldzoneList={worldzoneList}
                destinationList={destinationList}
                subdestinationList={subdestinationList}

                getNotificationSettings={getAccessListNotificationSettings}
                unsetNotificationSettings={unsetAccessListNotificationSettings}
                modifyNotificationSettings={modifyAccessListNotificationSettingsById}

                getItems={getAccessListFiltersItemsByAccountId}
                addItem={createNewAccessListFiltersItem}
                modifyItem={modifyAccessListFiltersItem}
                removeItem={removeAccessListFiltersItem}
                getAuthNumberType={getAuthNumberType}
                numberTypeFamilyList={numberTypeFamilyList}
            />
        </>
    );
};

// class AccessListFiltersContainer extends React.Component {

//     constructor(props) {
//         super(props);
//     }

//     getAccessListFiltersItems = (accountId) => {
//         const {service} = this.props;
//         this.props.getAccessListFiltersItems(service, accountId);
//     };

//     createNewAccessListFiltersItem = (service, id, data) => {
//         const promise = this.props.createNewAccessListFiltersItem(service, id, data);
//         return promise;
//     };

//     modifyAccessListFiltersItem = (accountId, alf_key, data) => {
//         let promise = this.props.modifyAccessListFiltersItem(accountId, alf_key, data);
//         return promise;
//     };

//     removeAccessListFiltersItem = (id) => {
//         this.props.removeAccessListFiltersItem(id);
//     };

//     getAccessListNotificationSettings = (id) => {
//         this.props.getAccessListNotificationSettings(id);
//     };

//     modifyAccessListNotificationSettings = async (id, data) => {
//         let reposone = await this.props.modifyAccessListNotificationSettings(id, data);
//         return reposone;
//     };

//     render() {
//         const {service, numberTypeFamilyList, getAuthNumberType} = this.props;
//         let {items, itemsLoading, itemsPage, itemsPerPage} = this.props;

//         return (
//             <>
//                 <AccessListFilters
//                     accountId={this.props.match.params.id}
//                     service={service}
//                     items={items}
//                     itemsLoading={itemsLoading}
//                     itemsPage={itemsPage}
//                     itemsPerPage={itemsPerPage}

//                     notificationSettings={this.props.accessListNotificationSettings}

//                     worldzoneList={this.props.worldzoneList}
//                     destinationList={this.props.destinationList}
//                     subdestinationList={this.props.subdestinationList}

//                     getNotificationSettings={this.getAccessListNotificationSettings}
//                     unsetNotificationSettings={this.props.unsetAccessListNotificationSettings}
//                     modifyNotificationSettings={this.modifyAccessListNotificationSettings}

//                     getItems={this.getAccessListFiltersItems}
//                     addItem={this.createNewAccessListFiltersItem}
//                     modifyItem={this.modifyAccessListFiltersItem}
//                     removeItem={this.removeAccessListFiltersItem}
//                     getAuthNumberType={getAuthNumberType}
//                     numberTypeFamilyList={numberTypeFamilyList}
//                 />
//             </>
//         )
//     }
// }

const mapStateToProps = ({auth, accounts, references, auth_numbers}) => {
    return {
        service: auth.service,

        items: accounts.accessListFiltersList,
        itemsLoading: accounts.accessListFiltersListLoading,
        accessListNotificationSettings: accounts.accessListNotificationSettings,

        worldzoneList: references.worldzone_list,
        destinationList: references.destination_list,
        subdestinationList: references.subdestination_list,

        numberTypeFamilyList: auth_numbers.auth_number_type_family_list,
    }
};

export default compose(
    connect(mapStateToProps, {
        getAccessListFiltersItems,
        removeAccessListFiltersItem,
        createNewAccessListFiltersItem,
        modifyAccessListFiltersItem,

        getAccessListNotificationSettings,
        unsetAccessListNotificationSettings,
        modifyAccessListNotificationSettings,

        getAuthNumberType
    }),
    withRouter
)(AccessListFiltersContainer);