import {
    BLOCKED_DESTINATIONS_SET_LIST,
    BLOCKED_DESTINATIONS_SET_LOADING
} from "../actions/actionTypes";

const initialState = {
    loading: false,
    list: [],
    count: 0
};


const handlers = {
    [BLOCKED_DESTINATIONS_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [BLOCKED_DESTINATIONS_SET_LIST]: (state, {payload}) => ({
        ...state,
        list: payload.allocation_block_list || [],
        count: payload.allocation_block_count || 0,
        loading: false
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};
