import {SEARCH as PREFIX} from '../const';
import {
    SMS_SEARCH_GET_LIST
} from '../const/apiMethods';


import {api} from '../api/loginRoutes';
import {createAction} from './defaults';

const setSearch = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_'+ PREFIX);


export const getSearch = (filter) => (dispatch) => {
    dispatch(setLoading(true));

    api(SMS_SEARCH_GET_LIST, {filter}, false).then((response) => {
        if (response !== undefined) {
            dispatch( setSearch({
                items: response.rules_search_list || [],
                count: response.rules_search_count || 0
            }) );
        }
    }).finally(() => {
        dispatch(setLoading(false));
    });
};