import {defineMessages} from "react-intl";

export default defineMessages({
    notificationSettings: {id: "accessListFilters.notificationSettings", defaultMessage: "Notification settings", description: 'accessListFilters: Notification settings'},
    originPrefixDestination: {id: "accessListFilters.originPrefixDestination", defaultMessage: "Origin/origin prefix/destination", description: 'accessListFilters: Origin/origin prefix/destination'},
    deleteAccessListFilter: {id: "common.deleteAccessListFilter", defaultMessage: "Delete access list filter", description: 'accessListFilters: Delete access list filter'},
    perAppearanceForFilteredDestinations: {
        id: "accessListFilters.perAppearanceForFilteredDestinations",
        defaultMessage: "Per appearance for filtered destinations",
        description: "accessListFilters: Per appearance for filtered destinations"
    },
    typeFullPrice: {
        id: "accessListFilters.typeFullPrice",
        defaultMessage: "Full price",
        description: "accessListFilters: Full price"
    },
    typeJustForAllocated: {
        id: "accessListFilters.typeJustForAllocated",
        defaultMessage: "Just for allocated",
        description: "accessListFilters: Just for allocated"
    },
    typeOnlyFilteredDestinations: {
        id: "accessListFilters.typeOnlyFilteredDestinations",
        defaultMessage: "Only filtered destinations",
        description: "accessListFilters: Only filtered destinations"
    }
})