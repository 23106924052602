import React from 'react';
import {Checkbox, Table} from 'rsuite';
import BaseTable from "../base/BaseTable";
import RateField from "../Form/RateField";

const {Column, HeaderCell, Cell} = Table;

const TablePayments = ({disabled, loading, onChangeRates, rates, paymentTermsList = [], currencyList = []}) => {

    return (
        <BaseTable
            height={140}
            data={currencyList}
            rowHeight={50}
            loading={loading}
        >
            <Column width={100}>
                <HeaderCell>Currency</HeaderCell>
                <Cell dataKey="name"/>
            </Column>

            {paymentTermsList.map(term => (
                <Column flexGrow={1}>
                    <HeaderCell>{term.name.replace("_", "-")}</HeaderCell>
                    <Cell>
                        {(row, i) => {                            
                            const currentRate = rates.find(item => item.cur_key === row.cur_key && item.pt_key === term.pt_key);

                            return <RateField
                                value={currentRate ? currentRate.rate : null}
                                disabled={disabled}
                                onChange={(value) => onChangeRates({cur_key: row.cur_key, pt_key: term.pt_key, rate: value})}
                                name={`${row.cur_key}-${term.pt_key}`}
                            />
                        }}

                    </Cell>
                </Column>
            ))}
            <Column key="table_scrollfix" width={0} fixed>
                <HeaderCell></HeaderCell>
                <Cell></Cell>
            </Column>
        </BaseTable>
    )
};

export default TablePayments;