import React from 'react';
import {api} from '../api/loginRoutes';


export const ApiRequest = (
    {
        data = {},
        style = {},
        method,
        target,
        noCheck,
        canSend = true,
        disabled,
        children,
        
        update,
        updateRef,
        onFaultResult,
        checkResultKey,
        ...props
    }
) => {


    const _children = Array.isArray(children)
        ? React.Children.map(children, formControl => React.cloneElement(formControl, {disabled}))
        : React.cloneElement(children, {disabled});

    
    const send = () => {
        return new Promise(async (resolve, reject) => {
            if (canSend) {
                const result = await api(method, {
                    target,
                    ...data,
                });

                if (result && (noCheck || ((checkResultKey && result[checkResultKey])))) {
                    update && update(result);
                    return resolve(true);
                }

                if (result && onFaultResult) {
                    onFaultResult(result);
                }

                return resolve(false);
            }

            return resolve(true);
        });
    };


    return (
        <div
            ref={(ref) => updateRef({
                ...ref,
                send,
                id: props.id
            })}
            style={style}
            {...props}
        >
            {_children}
        </div>
    );
    
};

export default ApiRequest;