import React, {useRef, useMemo, useState, useEffect} from "react";
import {FlexboxGrid, Whisper, Tooltip} from "rsuite";
import PanelLayout from "components/base/PanelLayout";
import TableServerSort from "components/Table/TableServerSort";
import Filters from "./RangesFilters";
// import Numbers from "./Numbers";
import AddNewRange from "../Prices/Ranges/AddNewRange";
import DeleteRanges from "./DeleteRanges";
import Loader from "components/Loader/Loader";
import {connect} from "react-redux";
import {setCurrentTrunkId} from "actions/accounts";
import {getRanges} from "actions/ranges";
import {getNumbers} from "actions/numbers";
import queryString from "query-string";
import {ASC_SORT} from "const";
import {setCurrentRangeNumber} from "actions";
import {showReasonHandler} from "actions/reason_handler";
import axios from "axios";
import { compareObjects, getAccountInfoFromSession } from "utils";
import Numbers from "./Numbers/Numbers";
import { useHistory } from "react-router";
import { usePrevious } from "hooks";


const Ranges = ({
    worldzoneList,
    subdestinationList,
    destinationList,
    defaultSPKey,

    ranges,
    rangesLoading,
    rangesPage,
    rangesCount,
    rangesPerPage,

    service,
    numbers = [],
    numbersLoading,
    numbersPage,
    numbersCount,
    allocatedNumbersCount,
    numbersPerPage,
    accountSession,
    permissions,

    getRanges,
    getNumbers,
    setCurrentTrunkId,
    setCurrentRangeNumber,
    showReasonHandler
}) => {
    const refNumbers = useRef(null);
    const refRangesTable = useRef(null)

    const history = useHistory();

    const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());
    const urlParams = queryString.parse(history.location.search);

    const [filters, setFilters] = useState({
        sde_key: +urlParams.sde_key || null,
        de_key: +urlParams.de_key || null,
        wz_key: +urlParams.wz_key || null,
        only_with_unallocated_numbers: true,
        only_with_incorporated_numbers: false
    });

    const [prKey, setPrKey] = useState(null);
    const [selected, setSelected] = useState({all: false, list: []});
    const [sort, setSort] = useState({column: "name", type: ASC_SORT});
    const [sortNumbers, setSortNumbers] = useState({});
    const [incorporatedOnly, setIncorporatedOnly] = useState(false);

    const prevPrKey = usePrevious(prKey);
    const prevFilters = usePrevious(filters);

    const [numberFilters, setNumberFilters] = useState({
        show_allocated_numbers: true,
        show_unallocated_numbers: true,
        show_test_numbers: true,
        show_block_allocation_numbers: true,
        show_only_block_allocation_numbers: false
    });

    const getSelectedList = () => ({...selected, countAll: rangesCount});

    useEffect(() => {
        getRangesCommon(filters, 1, rangesPerPage, sort);
        // getRanges(filters, service, 1, rangesPerPage, sort);
    }, []);

    useEffect(() => {
        if (ranges?.length && prKey) {

            const currentNumberFilters = {
                ...numberFilters,
                show_only_incorporated_numbers: filters?.only_with_incorporated_numbers
            };

            if (compareObjects(numberFilters, currentNumberFilters)) {
                setNumberFilters(currentNumberFilters);
            }

            if (prKey !== prevPrKey && compareObjects(filters, prevFilters) 
                || prKey === prevPrKey && !compareObjects(filters, prevFilters)
                || !!prKey && prevPrKey === null
            ) {
                getNumbers(prKey, currentNumberFilters, service, 1, numbersPerPage, sortNumbers);
            }
        } else if (!ranges?.length) {
            setPrKey(null);
            getNumbers(null, numberFilters, service, 1, numbersPerPage, sortNumbers);
        }
    }, [prKey, prevPrKey, ranges, filters]);

    useEffect(() => {
        if (ranges && ranges.length) {
            const currentPrkey = prKey || ranges[0].pr_key;

            if (!prKey) {
                setPrKey(currentPrkey);
            }
        }
    }, [prKey, ranges]);

    useEffect(() => {
        if (
            ranges &&
            ranges.length && 
            !ranges.find((range) => range.pr_key === prKey)
        ) {
            setPrKey(ranges[0].pr_key);
        }
    }, [ranges, prKey]);

    const pickedRange = useMemo(() => {
        return ranges && ranges.length 
            ? ranges.find(range => range.pr_key === prKey)
            : {}
    }, [ranges, prKey]);

    const accountInfo = useMemo(() => {
        return getAccountInfoFromSession(accountSession, service);
    }, [service, accountSession]);

    const clearNumberFilters = () => {
        setNumberFilters({
            show_allocated_numbers: true,
            show_unallocated_numbers: true,
            show_test_numbers: true,
            show_block_allocation_numbers: true,
            show_only_block_allocation_numbers: false
        });
    };

    const onChangeNumberFilters = (value) => {
        setNumberFilters(value);
    };

    const clearSelected = () => {
        refRangesTable.current.clear();
        refNumbers.current.clearSelected();
    };

    const onClickRange = (value) => {
        setPrKey(() => {
            // getNumbersList(numberFilters, 1, numbersPerPage, sortNumbers);
            refNumbers.current.clearSelected();

            return value;
        });
    };

    const onChangeFilters = (filters) => {
        setFilters(filters);
        setIncorporatedOnly(!!filters.only_with_incorporated_numbers || false);
        getRangesCommon(filters, 1, rangesPerPage, sort);
        clearSelected();
    };

    const getNumbersList = (filters, page, per_page, sort) => {
        if (rangesLoading) return;

        getNumbers(prKey, filters, service, page, per_page, sort);
    };

    const getRangesCommon = (_filters, page, per_page, sort) => {
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
        }

        setCancelToken(axios.CancelToken.source());
        getRanges(_filters, service, page, per_page, sort);
    };

    const getRangesList = (page, per_page, sort) => {
        getRangesCommon(filters, page, per_page, sort);
    };

    const setSortNumber = (sort) => {
        setSortNumbers(sort);
    };

    return (
        <PanelLayout>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={12} style={{paddingRight: 20, borderRight: " 3px solid #C4C4C4"}}>
                    <div style={{marginBottom: 25}}>
                        <div style={{display: "inline-block", marginRight: 15}}>
                            <AddNewRange
                                service={service}
                            />
                        </div>
                        <DeleteRanges
                            permissionMethods={permissions}
                            disabled={!selected.list.length}
                            getList={getSelectedList}
                            service={service}
                            update={() => {
                                setPrKey(null);
                                getRangesCommon(filters, 1, rangesPerPage, sort);
                                clearNumberFilters();
                                clearSelected();
                            }}
                        />
                    </div>
                    <Filters
                        loading={rangesLoading}
                        onChange={onChangeFilters}
                        defaultValues={filters}
                        {...{
                            worldzoneList,
                            destinationList,
                            subdestinationList,
                        }}
                    />

                    <TableServerSort
                        ref={refRangesTable}
                        data={ranges}
                        onRowClick={onClickRange}
                        loading={rangesLoading}
                        columns={columns}
                        originalColumns={columns}
                        count={rangesCount}
                        per_page={rangesPerPage}
                        page={rangesPage}
                        getItems={getRangesList}
                        active_id={prKey}
                        row_key="pr_key"
                        setSelected={setSelected}
                        ispagination
                        isselected
                        isSetObject
                        style={{width: "100%"}}
                        onSort={(column, type) => setSort({column, type})}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                    <Loader show={rangesLoading} isLocal/>
                    <Numbers
                        ref={refNumbers}
                        getNumbers={getNumbersList}
                        allocatedNumbersCount={allocatedNumbersCount}
                        updateRanges={() => getRangesCommon(filters, 1, rangesPerPage, sort)}
                        filters={numberFilters}
                        rangesFilter={filters}
                        onChangeNumberFilters={onChangeNumberFilters}
                        {...{
                            service,
                            numbers,
                            numbersLoading,
                            numbersPage,
                            numbersCount,
                            numbersPerPage,
                            account_id: accountInfo?.id,
                            setCurrentTrunkId,
                            setCurrentRangeNumber,
                            incorporatedOnly,
                            pr_key: prKey,
                            sde_key: pickedRange?.sde_key,
                            showReasonHandler,
                            onSort: setSortNumber,
                            sort: sortNumbers,
                            selectedRange: pickedRange
                        }}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </PanelLayout>
    );
};

const columns = [
    {
        label: "Range", 
        dataKey: "name", 
        title: true, 
        sortable: true, 
        width: 300
    },
    {
        label: "Test numbers", 
        // dataKey: "test_numbers", 
        value: (({test_trunk_number_list}) => {
            if (!test_trunk_number_list)
                return;

            const list = test_trunk_number_list.slice(0, 2);

            return (
                list.length
                    ? <>
                        <Whisper
                            placement="auto"
                            speaker={
                                <Tooltip>{test_trunk_number_list.join(", ")}</Tooltip>
                            }
                        >
                             <span>{list.join(", ")}{list.length && "..."}</span>
                        </Whisper>
                    </>
                    :
                    <span></span>
            );
        })
    },
    {
        label: "Numbers",
        dataKey: "allocated_numbers",
        // formatData: (value, key, row) => (
        //     (!row.blocked && row.allocated_numbers || "0") + "/" + (row.all_numbers || "0")
        // ),
        value: ({blocked, allocated_numbers, all_numbers, inc_all_numbers, inc_allocated_numbers}) => {
            return inc_all_numbers
                ? `${(!blocked && allocated_numbers || "0")}(${inc_allocated_numbers || 0})/${(all_numbers || "0")}(${inc_all_numbers || 0})`
                : `${(!blocked && allocated_numbers || "0")}/${(all_numbers || "0")}`
        },
        sortable: true
    }
];

const mapState = ({actions, references, ranges, numbers, auth}) => ({
    worldzoneList: references.worldzone_list,
    subdestinationList: references.subdestination_list,
    destinationList: references.destination_list,
    defaultSPKey: references.defaultSPKey,

    ranges: ranges.ranges,
    rangesLoading: ranges.loading,
    rangesPage: ranges.page,
    rangesCount: ranges.count,
    rangesPerPage: ranges.per_page,

    service: auth.service,
    numbers: numbers.numbers,
    numbersLoading: numbers.loading,
    numbersPage: numbers.page,
    numbersCount: numbers.count,
    allocatedNumbersCount: numbers.allocatedNumbersCount,
    numbersPerPage: numbers.per_page,
    accountSession: auth.authInfo.session,
    permissions: auth.permissions
});

export default connect(mapState, {
    getRanges,
    getNumbers,
    setCurrentTrunkId,
    setCurrentRangeNumber,
    showReasonHandler
})(Ranges);