import React, { useState } from 'react';
import {Icon, Loader, Dropdown} from "rsuite";
import {ButtonPrimary} from 'components/base/BaseButton';
import {getCsvResponse} from 'api/loginRoutes';
import {downloadDataAsFile} from 'utils';
import {
    E212_GET_LIST_API
} from 'const/apiMethods';
import styled from "styled-components";


export default () => {

    const [loading, setLoading] = useState(false);

    const downloadE212 = (no_code, landline=null) => {
        setLoading(true);
        getCsvResponse(E212_GET_LIST_API, {
            no_code, landline
        })
            .then((response) => {
                const fileName = !no_code && landline === null ? 'All E164_E212.csv' 
                    : no_code && landline === null ? 'Only MCC_MNC with operators.csv'
                    : no_code && landline ? 'Only Landline.csv' : 'E212.csv';

                downloadDataAsFile(response, fileName);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    return (
        <StyledDropdown
            appearance="default"
            disabled={loading}
            placement="bottomEnd"
            title={<>
                {loading 
                    ? <StyledLoader/> 
                    : <><Icon icon="upload2"/>Download</>
                }
            </>}
        >
            <Dropdown.Item
                onClick={() => {
                    downloadE212(false);
                }}
            >
                Download all E164/E212
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => {
                    downloadE212(true);
                }}
            >
                Download only MCC/MNC with operators
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => {
                    downloadE212(true, true);
                }}
            >
                Download only Landline
            </Dropdown.Item>
        </StyledDropdown>
    )
};

const StyledDropdown = styled(Dropdown)`
    height: 30px;
    & .rs-btn {
        padding-left: 10px !important;
    }
    & .rs-loader-spin {
        margin-top: 0 !important;
    }
    & > a {
        padding-top: 5px !important;
        top: 0;
        height: 30px;
        width: 100%;
    }
    & .rs-dropdown-toggle-caret {
        top: 5px;
        margin-top: 0 !important;
    }
    &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
        background-color: #d6d6db !important;
    }
`;

const StyledLoader = styled(Loader)`
    & span.rs-loader-spin {
        margin-top: 3px;
        margin-right: 5px;
    }
`;