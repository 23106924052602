import React, {useState} from "react";
import {api} from "../../api/loginRoutes";
import {NEWS_REMOVE_API} from "../../const/apiMethods";
import {Modal} from "../../components/Modal";
import {useWindowWidth} from "../../hooks";


export default ({
    show,
    onClose,
    userId,
    maxWidth = 600,
    onUpdate,
    ...props
}) => {

    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);

    const handleSuccess = () => {
        if (!userId) {
            return;
        }

        setLoading(true);
        api(NEWS_REMOVE_API, {target: {news_id: userId}})
            .then(() => {
                onUpdate && onUpdate();
                onClose();
            })
            .finally(() => {
                setLoading(false);
            })
    };

    return (
        <Modal
            {...{show, onClose}}
            onClose={() => onClose(false)}
            title={`Remove Post`}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            footer={true}
            disabled={loading}
            onSuccessClose={false}
            successText="Yes"
            focusTarget="close"
            onSuccess={handleSuccess}
            {...props}
        >
            Are you sure?
        </Modal>
    )
}