import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {Icon} from 'rsuite';
import {Link} from 'react-router-dom';

import {newSupplier} from '../../routes/routes';
import {useDebounce, useWindowWidth} from "../../hooks";
import {checkPermissionsFor} from "../../store/storeHelpers";
import {SMS_SUPPLIER_CREATE_METHOD} from "../../const/apiMethods";
import {toEndDayUTCTime, toStartDayUTCTime, debounce} from "../../utils";

import PanelLayout from '../../components/base/PanelLayout';
import {ButtonPrimary} from "../../components/base/BaseButton";

import * as S from "./styled";
import SuppliersTable from './SuppliersTable';
import SuppliersFilters from './SuppliersFilters';


export default (
    {
        data,
        loading,
        authInfo,
        paymentTermsList,
        account_manager_list,
        countryList,
        howFindList,
        getSuppliers,
        servicePlanList,
        onSetRedirectTab,
        setCurrentSupplier,
        getAccountManagersDropdownList,
    }
) => {

    const cancelToken = useRef(axios.CancelToken.source());
    const windowWidth = useWindowWidth();

    const [moreFiltersShow, setMoreFiltersShow] = useState(false);
    const [filters, setFilters] = useState({str: "", only_with_traffic: true});
    const [localLoading, setLocalLoading] = useState(false);

    const deferredFilter = useDebounce(filters, 500);

    // get managers list
    useEffect(() => {
        getAccountManagersDropdownList({}, true);
    }, []);

    useEffect(() => {

        const params = {
            ...deferredFilter,
            cntr_code: deferredFilter.cntr_code || undefined,
            hf_key: deferredFilter.hf_key || undefined,
            acc_manager_id_list: deferredFilter.acc_manager_id_list
                ? deferredFilter.acc_manager_id_list.length ? filters.acc_manager_id_list : null
                : undefined,
            sp_key_list: deferredFilter.sp_key_list
                ? deferredFilter.sp_key_list.length ? deferredFilter.sp_key_list : null
                : undefined
        };
        if (params.created_time) {
            if (params.created_time.length === 2) {
                params.created_start_time = toStartDayUTCTime(params.created_time[0]);
                params.created_stop_time = toEndDayUTCTime(params.created_time[1]);
            }
            delete params.created_time;
        }
        _getSuppliers(params);
    }, [deferredFilter]);


    const _getSuppliers = (filters) => {
        if (typeof cancelToken.current !== typeof undefined) {
            cancelToken.current.cancel("Operation canceled due to new request.");
        }
        cancelToken.current = axios.CancelToken.source();        
        getSuppliers(null, {...filters, cancelToken: cancelToken.current})    
    };


    const handleChangeFilters = (params) => {
        setFilters(prev => {
            const showWithoutTrunksValue = !prev.sp_key_list?.includes(0) && params?.sp_key_list?.includes(0) && !prev.show_without_trunks
                ? true
                : params.show_without_trunks;

            const showClosedSupplier = !prev.sp_key_list?.includes(0) && params?.sp_key_list?.includes(0) && !prev.show_closed_supplier
                ? true
                : params.show_closed_supplier;

            const onlyWithTraffic = !prev.sp_key_list?.includes(0) && params?.sp_key_list?.includes(0) && prev.only_with_traffic
                ? false
                : params.only_with_traffic;
              
            return {
                ...params,
                show_without_trunks: showWithoutTrunksValue,
                show_closed_supplier: showClosedSupplier,
                only_with_traffic: onlyWithTraffic,
            }
        })
    };

    const SuppliersSplitedData = (items) => {

        return items
        // .reduce((result, nextValue, nextIndex) => {
        //     const subSupplierList = nextValue?.supplier_list || [];

        //     const splitedSubSuppliers = subSupplierList.length ? {
        //         voiceSupplier: subSupplierList.find(acc => acc?.service === "voice") || {},
        //         smsSupplier: subSupplierList.find(acc => acc?.service === "sms") || {}
        //     } : {};

        //     result[nextIndex] = {
        //         ...splitedSubSuppliers,
        //         ...nextValue
        //     };
        //     return result;
        // }, []);
    };

    const SuppliersSplitedList = SuppliersSplitedData(data);

    const isMobile = windowWidth < 1200;

    return (
        <PanelLayout>
            <S.Grid
                noWrap={!isMobile}
                align={!isMobile ? "top" : "middle"}
            >

                <S.GridItem
                    isFullWidth={windowWidth < 420}
                    style={{
                        marginLeft: 'auto',
                        order: windowWidth >= 420 ? 1 : 0,
                        textAlign: isMobile ? 'right' : 'inherit'
                    }}
                >
                    <ButtonAddSupplier isMobile={isMobile}/>
                </S.GridItem>

                <S.GridItem style={{flexGrow: 1}}>
                    <SuppliersFilters
                        {...{
                            moreFiltersShow,
                            setMoreFiltersShow,
                            account_manager_list,
                            paymentTermsList,
                            servicePlanList,
                            countryList,
                            howFindList,
                            isMobile,
                        }}
                        filter={filters}
                        setFilter={handleChangeFilters}
                        setLoading={setLocalLoading}
                    />
                </S.GridItem>

            </S.Grid>

            <SuppliersTable
                // data={[]}
                data={SuppliersSplitedList}
                loading={loading || localLoading}
                defaultSortColumn="name"
                isMobile={isMobile}
                {...{
                    authInfo,
                    onSetRedirectTab,
                    setCurrentSupplier
                }}
            />
        </PanelLayout>
    )
}


const ButtonAddSupplier = ({children, isMobile = false, ...props}) => (
    checkPermissionsFor(SMS_SUPPLIER_CREATE_METHOD) &&
        <ButtonPrimary
            componentClass={Link}
            to={newSupplier}
            isMobile={isMobile}
            {...props}
        >
            {isMobile ?
                <Icon icon="plus"/>
                : "+Add supplier"
            }
        </ButtonPrimary>
);

