import React from 'react';
import {compose} from "redux";
import {useIntl} from "react-intl";
import {Icon, Tooltip, Whisper} from 'rsuite';
import FormHOC from '../../../hoc/FilltersForm'
import {useWindowWidth} from "../../../hooks";
import {ButtonPrimary} from "../../base/BaseButton";
import styled from "styled-components";
import m from "../../../definedMessages";


const ButtonDownload = (({
    onDownload,
    limitWidth = 640,
    iconName = "download",
    loading, 
    buttonText = '', 
    tooltipText = '', 
    ...props
}) => {
    
    const {formatMessage} = useIntl();
    const resizedWidth = useWindowWidth();

    tooltipText = tooltipText ? tooltipText : formatMessage(m.enterFiltersToActivateTheButton);
    buttonText = buttonText ? buttonText : formatMessage(m.download);

    const speakerPlacement = resizedWidth >= limitWidth ? 'auto' : 'bottomStart';

    return (
        <Whisper
            speaker={<Tooltip>{tooltipText}</Tooltip>}
            placement={speakerPlacement}
        >
            <ButtonPrimary
                onClick={onDownload}
                loading={loading}
                {...props}
            >
                <StyledIcon icon={iconName}/>{buttonText}
            </ButtonPrimary>
        </Whisper>
    );
});

export default compose(
    FormHOC
)(ButtonDownload)


const StyledIcon = styled(Icon)`
    margin-right: 5px;
    
    .app-client.rtl & {
        margin-left: 5px;
        margin-right: 0;
    }
`;