import React from 'react'
import {
    Container,
    Content,
    FlexboxGrid,
    Header,
    Icon,
    Nav,
    Navbar,
    Panel,
} from 'rsuite';
import styled, {keyframes} from "styled-components";

export default () => {
    return (
        <StyledContainer>

            <Header>
                <Navbar appearance="inverse">
                    <Navbar.Body style={{minHeight: 56}}>
                    </Navbar.Body>
                </Navbar>
            </Header>

            <Content>
                <FlexboxGrid justify="center" style={{width: '100%'}}>
                    <FlexboxGrid.Item
                        style={{
                            marginTop: '20vh',
                            width: '100%',
                            maxWidth: '500px',
                            minWidth: '340px',
                            opacity: 0.7,
                        }}
                    >
                        <Panel
                            className="panel__noShadow"
                            header={<h3>Customer switching...</h3>}
                            style={{
                                width: '100%',
                                textAlign: 'center',
                            }}
                        >
                            <StyledIcon
                                icon="refresh2"
                                size="5x"
                            />
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </StyledContainer>
    );
};


const StyledContainer = styled(Container)`
    min-height: 100vh;
`;


const rotate = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;

const StyledIcon = styled(Icon)`
    display: block;
    animation: ${rotate} 2s linear infinite;
`;