
import React from "react";
import {useIntl} from "react-intl";
import m from "definedMessages";

import {isBoolean} from "utils/helpers";

export const withTranslation = (Component) => {
    const ComponentWithTranslations = (props) => {
        const {formatMessage} = useIntl();

        const formatMessageWithService = (message, service, params) => {
            if (isBoolean(service)) {
                const serviceMessage = service ? m.voice : m.sms;

                return `${formatMessage(serviceMessage)} ${formatMessage(message, params)}`;
            }
            return formatMessage(message, params);
        }

        return (
            <Component
                {...props}
                {...{
                    m,
                    formatMessage: formatMessageWithService
                }}
            />
        );
    }

    return ComponentWithTranslations;
};