import React from 'react';
import {FormGroup, FormControl, ControlLabel, HelpBlock} from 'rsuite'
import styled from "styled-components";

export default class  extends React.PureComponent {
    
    render() {
        const { name, message, label, widthLabel, accepter, error, formGroupExtraStyle, ...props } = this.props;
        const formGroupStyle = formGroupExtraStyle ? formGroupExtraStyle : { marginBottom: 10, width: "100%" };

        return (
            <FormGroup className={error ? 'has-error' : ''} style={formGroupStyle}>
                
                {label && 
                    <ControlLabel style={{width:'100%', textAlign:'left', display:'block'}}>{label}</ControlLabel>
                }

                <StyledFieldWrapper>
                    <FormControl
                        name={name}
                        accepter={accepter}
                        errorMessage={error}
                        errorPlacement="topRight"
                        {...props}
                    />
                </StyledFieldWrapper>

            </FormGroup>
      );
    }
}

const StyledFieldWrapper = styled.div`
    max-width: 250px;
`;