import {connect} from 'react-redux';
import CDR from './CDR';
import {exportDialerCDR, setEmptyCDRList, getDialerCDRList} from "../../../actions/client/cdr";

const mapState = ({auth_dialer, client_cdr, references}) => ({
    service: auth_dialer.serviceDialer,
    loading: client_cdr.loading,
    page: client_cdr.page,
    per_page: client_cdr.per_page,
    count: client_cdr.count,
    more: client_cdr.more,
    summaries: client_cdr.summaries,
    uploadLoading: references.uploadLoadingDialer,
    globalSettings: references.global_setting,
});

export default connect(mapState, {
    getCDRList: getDialerCDRList,
    exportCDR: exportDialerCDR,
    setEmptyCDRList
})(CDR);
