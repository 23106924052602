import React, {useEffect} from "react";
// import Table from "../../components/Table";
import {renderColumn} from "../../utils/helpers";
import {Table as Table_, Icon, Button} from 'rsuite';
import styled from "styled-components";
import {render} from 'redux-logger/src/diff';
import Checkbox from 'rsuite/es/Checkbox';
import BaseTable from "../../components/base/BaseTable";

const {Column, HeaderCell, Cell} = Table_;

const PaymentDetailsTable = ({
    data, 
    loading, 
    loadingEditButtonsSet,
    intl, 
    handleRemovePaymentDetails,
    handleEditPaymentDetails,
    ...props
}) => {

    const statuses = ["Pending", "Confirmed", "Declined"];

    const columns = [
        {
            id: 'details',
            label: "Details",
            dataKey: "payment_detail_info",
            flexGrow: 5,
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'created',
            label: "Created",
            value: ({start_date, customer_name, customer_last_name}) => {
                const name = customer_name
                    ? customer_name + (customer_last_name !== null ? (' ' + customer_last_name) : '' )
                    : null;
                return `${start_date}${name ? ' ' + `by ${name}` : ''}`;
            },
            flexGrow: 2,
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'status',
            label: "Status",
            value: ({status}) => {
                return statuses[status]
            },
            flexGrow: 2,
            minWidth: 200,
            align: 'center'
        }
    ];

    const tableActions = (
        <Column key="table__actions">
            <HeaderCell> </HeaderCell>
            <Cell>

                {rowData => {
                    return (
                        <StyledTableActions>

                            <StyledTableButton
                                appearance="primary"
                                size="sm"
                                disabled={loadingEditButtonsSet.size && !loadingEditButtonsSet.has(rowData.id)}
                                loading={loadingEditButtonsSet.has(rowData.id)}
                                onClick={() => {
                                    handleEditPaymentDetails(rowData);
                                }}
                            >
                                <Icon icon="edit2"/>
                            </StyledTableButton>
                            
                            <StyledTableButton
                                appearance="primary"
                                size="sm"
                                onClick={() => {
                                    handleRemovePaymentDetails(rowData)
                                }}
                            >
                                <Icon icon="trash2"/>
                            </StyledTableButton>
                            
                        </StyledTableActions>
                    );
                }}

            </Cell>
        </Column>
    );

    const renderedColumns = columns.map(renderColumn);
    // renderedColumns.push(confirmedColumn);
    renderedColumns.push(tableActions);

    // return (
        {/*<Table*/}
            // height="300px"
            // data={data}
            // autoHeight={true}
            // loading={loading}
            // columns={columns}
            // rowHeight={46}
            // ActionCell={tableActions}
        // >
        // </Table>
    // )

    return (
        <BaseTable
            height={300}
            shouldUpdateScroll={true}
            data={data}
            loading={loading}
            rowHeight={46}
        >
            {renderedColumns}
        </BaseTable>
    )
};

export default PaymentDetailsTable;

const StyledTableActions = styled.div`
    margin-top: -3px;

    .rtl & {
        direction: rtl;
    }
`;

const StyledTableButton = styled(Button).attrs(props => ({
    appearance: "primary",
    size: "sm"
}))`
    && {
        height: 25px;
        margin-right: 10px;
        padding: 0;
        width: 25px;

        &:last-child {
            margin-right: 0;
        }

        .rtl & {
            margin-right: 0;
            margin-left: 10px;

            &:last-child {
                margin-left: 0;
            }
        }
    }
`;