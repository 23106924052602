import {Modal} from "rsuite";
import styled, {css} from "styled-components";
import {ButtonPrimary, ButtonSubtle} from "./BaseButton";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import m from "../../definedMessages";
import {APP_TYPE_CLIENT} from "../../const";


export const BaseModal = (
    {
        show,
        title,
        children,

        // footer
        loading = false,
        disabled = false,
        extraDisabled = false,
        showFooter = false,
        customFooter = null,
        loadingText = null,
        confirmButtonText,
        confirmButtonStyle,
        cancelButtonText,
        cancelButtonStyle,
        focusTarget = null,

        // callbacks
        onClose,
        onConfirm,

        // crutches
        extraDialogClassName = "",
        checkBefore,

        ...props
    }
) => {

    const {formatMessage} = useIntl();

    const _customFooter = useMemo(() => (
        !customFooter
            ? null
            : customFooter()
    ), [customFooter]);

    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName={extraDialogClassName}
            {...props}
        >
            <Modal.Header>
                {title &&
                <Modal.Title>{title}</Modal.Title>
                }
            </Modal.Header>

            <Modal.Body>
                {children}
            </Modal.Body>

            {showFooter &&

                <Modal.Footer>
                    {!_customFooter
                        ? <>
                            <ButtonPrimary
                                appearance={confirmButtonStyle}
                                loading={loading}
                                disabled={disabled || extraDisabled}
                                autoFocus={focusTarget === "success"}
                                minWidth={0}
                                className="button-confirm"
                                showTextOnLoading={loading && loadingText}
                                onClick={() => {
                                    if (checkBefore && !checkBefore())
                                        return false;
                                    onConfirm && onConfirm();
                                }}
                            >
                                {loading && loadingText ? loadingText : (confirmButtonText || "Ok")}
                            </ButtonPrimary>

                            <ButtonSubtle
                                appearance={cancelButtonStyle}
                                disabled={extraDisabled}
                                autoFocus={focusTarget === "close"}
                                minWidth={0}
                                className="button-cancel"
                                onClick={onClose}
                            >
                                {cancelButtonText || formatMessage(m.cancel)}
                            </ButtonSubtle>
                        </>

                        : _customFooter
                    }
                </Modal.Footer>

            }

        </Modal>
    );
};


export const CustomModal = styled(BaseModal)`
    && {
        margin: 7.5% auto 0 auto;
        max-width: 100%;
        width: ${props => props.width || '600'}px;
        
        .rs-modal-body {
            margin-top: 0;
            padding-top: 30px;

            /* overflow: hidden !important; */
        
        }
        
        ${props => !!props.loadingText && css`
            .button-confirm.rs-btn-loading {
                color: #ffffff !important;
                background-color: var(--color-secondary);
            }
        `}
        
        ${props => props.theme.appType === APP_TYPE_CLIENT 
            ? css`
                .rs-modal-header {
                    padding-right: 0;
                    padding-left: 0 !important;
                    display: flex;
                    flex-direction: row-reverse;
        
                    .rs-modal-header-close {
                        position: static;
                    }

                    .rs-modal-title {

                        @media (max-width: 767px) {
                            white-space: normal;
                        }
                    }
                }
            `
            : css`
                .rs-modal-header {
                    position: absolute;
                    width: 100%;
                    top: -25px;
                    
                    .rs-modal-title {
                        color: #ffffff;
                    }
                    
                    .rs-modal-header-close {
                        right: 24px;
                        top: 0;
                        color: #ffffff;
                    }
                    
                    @media (max-width: 800px) {
                        left: 0;
                        padding-left: 20px;
                        padding-right: 0;
                    }
                }
            `
        }        
    }
`;


export default BaseModal;