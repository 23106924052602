import {connect} from "react-redux";
import LiveCalls from "./LiveCalls";
import {withRouter} from "react-router-dom";
import {
    getLiveCalls
} from "actions/client/liveCalls";

// import {showReasonHandler} from "actions/reason_handler";


const mapState = ({auth_dialer, client_live_calls}) => {
    return {
        data: client_live_calls.live_calls_list,
        loading: client_live_calls.loading,
        accountJoinList: auth_dialer.accountJoinList
    }
};

const mapMethods = {
    getLiveCallsList: getLiveCalls
};

export default withRouter(connect(mapState, mapMethods)(LiveCalls));