import React, {useState, useEffect} from "react";
import {List, Panel} from "rsuite";
import styled from "styled-components";
import {useWindowWidth} from "hooks";

import {Spacer} from "../../components/base/Spacer";
import {CustomModal} from "../../components/base";
import {ButtonContent} from "../../components/base/BaseButton";


const reasons = {
    undefined_sde: 'subdestination is undefined',
    number_exists: 'already in another group',
    number_exists_same_group: 'already in same group',
    no_range: 'range is not created',
    duplicate_number: 'number is duplicated',
};


export default (
    {
        data,
        maxWidth = 600,
        onClose,
        ...props
    }
) => {

    const resizedWidth = useWindowWidth();

    return (
        <>
            <StyledModal
                showFooter={true}
                title={'Upload numbers result'}
                width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
                customFooter={() => <ButtonContent onClick={onClose}>Close</ButtonContent>}
                onClose={onClose}
                {...props}
            >

                <p>Prices in file - <b>{data?.default_prices?.in_file}</b></p>
                <p>Prices updated - <b>{data?.default_prices?.updated}</b></p>
                <p>Prices inserted - <b>{data?.default_prices?.inserted}</b></p>
                <p>Prices closed - <b>{data?.default_prices?.closed}</b></p>

                {data.account_price_update_sde_list && data.account_price_update_sde_list.length
                    ? <>
                        <hr/>
                        <p style={{color: 'var(--color-primary)'}}>Updated subdestinations list:</p>
                        <StyledList size='sm'>
                            {data.account_price_update_sde_list
                                .map(item => (
                                    <List.Item key={item.sde_key}>

                                        <StyledPanel 
                                            collapsible
                                            header={`${item.subdestination_name} (${item.account_list.length} accounts)`}
                                        >
                                            <StyledList size='sm' style={{paddingLeft: 20}}>
                                                {item.account_list.map(acc => (
                                                    <List.Item key={acc.account_id}>
                                                        <StyledAccountInfo>
                                                            <span className="account-name">{acc.account_name}</span> 
                                                            <StyledPrice><del>{acc.rate_old} {acc.currency_name}</del><ins>{acc.rate_new}</ins> {acc.currency_name}</StyledPrice>
                                                        </StyledAccountInfo>
                                                    </List.Item>
                                                ))}
                                            </StyledList>
                                        </StyledPanel>

                                    </List.Item>
                                ))
                            }
                        </StyledList>
                    </>
                    : null
                }
                <hr />
                <p>Numbers updated - <b>{data?.numbers_updated}</b></p>

            </StyledModal>
        </>
    )
};


const StyledModal = styled(CustomModal)`
    .rs-modal-body {
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const StyledList = styled(List)`
    margin-top: 10px;
    box-shadow: none !important;
    
    @media (max-width: 767px) {
        display: none !important;
    }
`;

const StyledAccountInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .account-name {
        color: var(--color-dark);
        display: block;
        padding-right: 10px;
    }
`;

const StyledPrice = styled.div`
    del {
        color: var(--color-disabled);
        text-decoration: line-through;
        margin-right: 10px;
    }

    ins {
        color: var(--color-primary);
        font-size: 16px;
        text-decoration: none;
    }
`;

const StyledPanel = styled(Panel)`
    && {

        .rs-panel-heading {
            font-size: 14px;

            &:before {
                top: 0;
            }
        }
    }

    .rs-panel-heading, 
    .rs-panel-body {
        padding: 0;
    }
    
`;