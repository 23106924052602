import {api} from '../api/loginRoutes'
import {createAction} from './defaults'
import {
    TRAFFIC_REPORTS_GET_LIST_SUCCESS,
    TRAFFIC_REPORTS_SET_LOADING,
    MDR_FAILED_SET_LOADING,
    MDR_FAILED_GET_LIST_SUCCESS
} from "./actionTypes";
import {
    TRAFFIC_REPORTS_METHOD,
    TRAFFIC_REPORTS_SMS_METHOD,
    FAILED_MDR_REPORTS_METHOD,
} from "../const/apiMethods";
import {toEndDayUTCTime, toStartDayUTCTime} from "utils";

const setLoading = createAction(TRAFFIC_REPORTS_SET_LOADING);
const setTrafficReportsList = createAction(TRAFFIC_REPORTS_GET_LIST_SUCCESS);

const setFailedMdrLoading = createAction(MDR_FAILED_SET_LOADING);
const setFailedMDRReportsList = createAction(MDR_FAILED_GET_LIST_SUCCESS);

export const getTrafficReports = (service = true, filter = {}, page = 1, per_page = 1000) => (dispatch) => {
    dispatch(setLoading(true));

    const currentFilter = {...filter};
    const groupCheckbox = currentFilter?.group_checkbox || [];

    if (groupCheckbox.length) {
        delete currentFilter.group_checkbox;
    }

    if (currentFilter.range && currentFilter.range.length) {
        currentFilter['start_date'] = toStartDayUTCTime(currentFilter.range[0]);
        currentFilter['end_date'] = toEndDayUTCTime(currentFilter.range[1]);

        delete currentFilter.range;
    }
    const method = service ? TRAFFIC_REPORTS_METHOD : TRAFFIC_REPORTS_SMS_METHOD;
    api(method, {filter: currentFilter, group_checkbox: groupCheckbox, page, per_page})
        .then(({group_count = 0, group_list = []}) => {
            dispatch(setTrafficReportsList({
                list: group_list,
                count: group_count,
                perPage: per_page,
                page: page
            }));
        })
        .catch((error) => {
            dispatch(setLoading(false));
        })
};


export const getFailedMDRReports = (filter = {}, page = 1, per_page = 1000) => (dispatch) => {
    dispatch(setFailedMdrLoading(true));

    const currentFilter = {...filter};
    const groupCheckbox = currentFilter?.group_checkbox || [];

    if (groupCheckbox.length) {
        delete currentFilter.group_checkbox;
    }

    if (currentFilter.range && currentFilter.range.length) {
        currentFilter['start_date'] = toStartDayUTCTime(currentFilter.range[0]);
        currentFilter['end_date'] = toEndDayUTCTime(currentFilter.range[1]);

        delete currentFilter.range;
    }
    const method = FAILED_MDR_REPORTS_METHOD;
    api(method, {filter: currentFilter, group_checkbox: groupCheckbox, page, per_page})
        .then(({group_count = 0, group_list = []}) => {
            dispatch(setFailedMDRReportsList({
                list: group_list,
                count: group_count,
                perPage: per_page,
                page: page
            }));
        })
        .catch((error) => {
            dispatch(setFailedMdrLoading(false));
        })
};