import {connect} from "react-redux";
import {closeReasonHandler} from "../../actions/reason_handler";
import ModalResponseHandler from "./ModalResponseHandler";


const mapState = ({reason_handler})=> ({
    params: reason_handler.params,
    show: reason_handler.show,
});

export default connect(mapState, {
    onClose: closeReasonHandler
})(ModalResponseHandler);