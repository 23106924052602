import React, {useMemo, useState, useEffect} from "react";

import Page from "components/base/Page";
import PageHeader from "components/base/PageHeader";
import PriceFilters from "./PriceFilters";
import PriceTable from "./PriceTable";

import ModalAllocationCommon from "components/client/Modal/ModalAllocationCommon";
import ModalAllocationResult from "components/client/Modal/ModalAllocationResult";
import ModalResponseHandler from "components/Modal/ModalResponseHandler";

import { ButtonToolbar, Dropdown, Loader, Icon } from "rsuite";

import { FlexGrid, FlexGridItem } from "components/base/FlexGrid";
import { Spacer } from "components/base/Spacer";

import {
    DESC_SORT,
    SCREEN_MEDIA,
    SP_KEY__STANDARD,
    SP_KEY__RESELLER,
    MIN_PER_PAGE_DIALER,
    MAX_PER_PAGE_DIALER,
    USD_DEFAULT_CURRENCY_KEY,

    LOCAL_STORAGE_PRICE_FILTERS_DIALER,
    LOCAL_STORAGE_PRICE_PER_PAGE_DIALER,

    transformed_service_data
} from "const";

import {
    RATECARD_LIST_ACCOUNT_DIALER,
    RATECARD_LIST_OTP_DEFAULT_DIALER,
    RATECARD_LIST_OTP_ACCOUNT_DIALER,
    RATECARD_LIST_DEFAULT_DIALER,
    RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER,
    RATECARD_LIST_INCORPORATED_DEFAULT_DIALER,

    SMS_RATECARD_LIST_ACCOUNT_DIALER,
    SMS_RATECARD_LIST_DEFAULT_DIALER,
    SMS_RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER,
    SMS_RATECARD_LIST_INCORPORATED_DEFAULT_DIALER
} from "const/apiMethods";

import { withTranslation } from "hoc/WithTranslation";
import m from "definedMessages";
import { useLocation } from "react-router";
import { getFileResponse } from "api/loginRoutes";
import {   
    getServiceByLocation,
    savePdfByTemplate,
    getBlobContent,
    compareObjects,
    servicePick,
    compareTwoArrays,
    checkShouldUpdateDiffers
} from "utils";

import { usePrevious, useWindowWidth } from "hooks";

import styled, {css} from "styled-components";

const {md} = SCREEN_MEDIA;
const widthDesktopMin = md.min;


const Price = ({
    account,
    service,
    initedService,
    accountDialerState,
    accountJoinList,

    priceList,
    count,
    priceLoading,
    page,
    per_page,
    trunkList,
    loadingAllocation,
    
    service_plan_list: servicePlanList,
    currency_list: currencyList,
    uploadLoading,

    uploadLoadingSetting,
    trunk_number_transaction: trunkNumberTransaction,

    getPriceList,
    exportPrice,
    getTrunkList,
    allocateByTemplate,
    setLoadingAllocatedNumbersDialer,
    setUploadingPriceLoading,
    allocateByAccess,
    setAccessLoadingAllocatedNumbers,
    setUploadingLoading,
    getTemplate,
    setTrunkNumberTransaction,
    downloadNumbers,
    showReasonHandler,

    formatMessage
}) => {

    const savedTableFilter = JSON.parse( localStorage.getItem(LOCAL_STORAGE_PRICE_FILTERS_DIALER) );
    const defaultFilter = {str: "", sp_key: 1, gan: false};
    const defaultSelectedData = {id: null, range_name: null, av_numbers: null, pr_key: null, sp_key: null, ing_key: null};

    const defaultSort =  {column: null, type: "asc"};
   
    const location = useLocation();

    const windowWidth = useWindowWidth();

    const [currentPerPage, setCurrentPerPage] = useState(null);
    const [sort, setSort] = useState(defaultSort);
    const [isTest, setIsTest] = useState(null);
    const [isSupport, setIsSupport] = useState(null);
    const [filter, setFilter] = useState({
        ...defaultFilter,
        ...savedTableFilter || {}
    });

    const [selectedData, setSelectedData] = useState(defaultSelectedData);
    const [accountInfo, setAccountInfo] = useState({});
    const [spAuth, setSpAuth] = useState(false);
    const [isIncorporated, setIsIncorporated] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [savedPerPage, setSavedPerPage] = useState(JSON.parse( localStorage.getItem(LOCAL_STORAGE_PRICE_PER_PAGE_DIALER) ));
    const [searchLoading, setSearchLoading] = useState(false);
    const [showGetNumbersModal, setShowGetNumbersModal] = useState(false);
    const [showAllocatedNumbersModal, setShowAllocatedNumbersModal] = useState(false);
    const [trunkId, setTrunkId] = useState(null);
    const [filteredTrunkList, setFilteredTrunkList] = useState([]);
    const [showModalResponseHandler, setShowModalResponseHandler] = useState(null);

    const [csvContent, setCsvContent] = useState(null);
    const [csvContentLoading, setCsvContentLoading] = useState(true);

    const [notAllocatedNumbers, setNotAllocatedNumbers] = useState(null);
    const [allocatedNumbersCount, setAllocatedNumbersCount] = useState(0);

    const prevServicePlanList = usePrevious(servicePlanList);

    const prevTrunkList = usePrevious(trunkList);
    const prevFilter = usePrevious(filter);
    const prevSelectedData = usePrevious(selectedData);

    useEffect(() => {
        if (service != null) {
            getTrunkList(service);
            localStorage.removeItem(LOCAL_STORAGE_PRICE_FILTERS_DIALER);
        }
    }, [service]);

    useEffect(() => {
        const currentAccountJoinList = accountJoinList || [];
        const currentAccountInfo = currentAccountJoinList.find(accountService => transformed_service_data[accountService?.service] === service);

        if (currentAccountInfo) {
            const currencyKey = currentAccountInfo.cur_key || ( (savedTableFilter && savedTableFilter.cur_key) || {} ).cur_key || USD_DEFAULT_CURRENCY_KEY;
        
            const isTest = "is_test" in currentAccountInfo ? currentAccountInfo.is_test : false;

            const filter = {
                ...defaultFilter,
                ...(savedTableFilter || {}),
                ...(isTest ? {cur_key: currencyKey || USD_DEFAULT_CURRENCY_KEY} : {})
            };
  
            if (!isTest && filter.cur_key) {
                delete filter.cur_key;
            }

            if (servicePlanList
                && servicePlanList.length
                && !servicePlanList.find(item => item.sp_key === filter.sp_key)
            ) {
                const spKey = servicePlanList[0].sp_key;
                const gan = servicePlanList[0]?.gan;

                filter.sp_key = spKey;
                filter.gan = gan;
            }
            setAccountInfo(currentAccountInfo);
            setFilter(filter);
            setIsTest(isTest);
        }

    }, [accountJoinList, servicePlanList]);

    useEffect(() => {
        if (servicePlanList && servicePlanList.length && compareTwoArrays(prevServicePlanList, servicePlanList) && filter.sp_key) {
            const currentServicePlan = servicePlanList.find( item => item.sp_key === filter.sp_key );

            if (currentServicePlan && service === initedService) {
                setSpAuth(currentServicePlan.auth);
                setIsIncorporated(currentServicePlan.gan);
                getPriceList(service, savedPerPage || per_page, filter, 1, currentServicePlan, defaultSort);
            }
        }

    }, [filter, servicePlanList]);

    useEffect(() => {
        if (account && Object.keys(account).length) {
            if (account.hasOwnProperty("session")) {
                const accountUser = account.session.account_user;
       
                if (Object.keys(accountUser).length && accountUser.role_list && accountUser.role_list.length) {
                    const isSupport = account.session.account_user.role_list.includes("Account Support");
                    setIsSupport(isSupport);
                }
            }
        }
    }, [account]);

    useEffect(() => {
        if (checkShouldUpdateDiffers(trunkList, prevTrunkList)
            || !compareObjects(filter, prevFilter)
            || !compareObjects(selectedData, prevSelectedData)
        ) {

            const dataSpKeyList = priceList.map((value) => value.sp_key);

            setFilteredTrunkList(
                trunkList.filter(value => {
                    return (!value || !value?.allocation_limit?.allocate)
                        ? false
                        : !value.closed
                            && value.active
                            && (filter?.sp_key_list 
                                ? filter.sp_key_list.includes(value.sp_key)
                                : dataSpKeyList.includes(value.sp_key)
                            )
                            && (!selectedData.sp_key || !!showGetNumbersModal && selectedData?.sp_key && value.sp_key === selectedData.sp_key)
                })
            );
        }
    }, [filter, trunkList, selectedData])



    const onShowGetNumbersModal = (data) => {
        setShowGetNumbersModal(true);
        setSelectedData(data);
    };


    const getSortByFilter = (sort, prevSP, currentSP, isGan) => {
        const currentSort = prevSP !== currentSP ? defaultSort : sort;
        const sortColumn = prevSP !== currentSP && isGan ? {column: "incorporated_group_name", type: sort.type} : currentSort;

        setSort(sortColumn);
        return sortColumn;
    };


    const onChangeFilters = (filtersObj) => {
        const newFilter = {...filter, ...filtersObj}
        setFilter(newFilter);
        setSort(defaultSort);

        localStorage.setItem(LOCAL_STORAGE_PRICE_FILTERS_DIALER, JSON.stringify(newFilter));
    };

    
    const getItems = (page, per_page, sortValue) => {
        const currentServicePlan = servicePlanList.find( item => item.sp_key === filter.sp_key );

        if (currentServicePlan) {
            let currentSort = sortValue;
     
            if (page !== currentPage) {
                currentSort = sort;
            }

            const sortColumn = getSortByFilter(currentSort, filter?.sp_key, currentServicePlan?.sp_key, currentServicePlan?.gan);
            getPriceList(service, per_page, filter, page, currentServicePlan, sortColumn);
        }

        setCurrentPage(page);
    };

    const onChangePerPage = (perPage) => {
        setSavedPerPage(perPage ? null : currentPerPage);

        const per_page = Number(perPage) || currentPerPage;

        const _currentPerPage = per_page <= 0 
            ? MIN_PER_PAGE_DIALER
            : per_page > MAX_PER_PAGE_DIALER ? MAX_PER_PAGE_DIALER : per_page;

        setCurrentPerPage(_currentPerPage);
        
        localStorage.setItem(LOCAL_STORAGE_PRICE_PER_PAGE_DIALER, JSON.stringify(_currentPerPage));

        const currentServicePlan = servicePlanList.find(item => item.sp_key === +filter.sp_key);

        if (currentServicePlan) {
            const sortColumn = getSortByFilter(sort, filter?.sp_key, currentServicePlan?.sp_key, currentServicePlan?.gan);
            getPriceList(service, _currentPerPage, filter, 1, currentServicePlan, sortColumn);
        }
    };


    const onChangePage = (pageNumber) => {
        const currentServicePlan = servicePlanList.find(item => item.sp_key === +filter.sp_key);

        if (currentServicePlan) {
            const sortColumn = getSortByFilter(sort, filter?.sp_key, currentServicePlan?.sp_key, currentServicePlan?.gan);
            getPriceList(service, savedPerPage || currentPerPage || per_page, filter, pageNumber, currentServicePlan, sortColumn);
        }
    };


    const exportPriceFunc = () => {
        const currentServicePlan = servicePlanList.find(item => item.sp_key === +filter.sp_key);
        exportPrice(service, {...filter}, currentServicePlan, sort);
    };


    const exportPricePdfFunc = () => {
        const currentServicePlan = servicePlanList.find( item => item.sp_key == filter.sp_key );

        let sort1, sort1_desc;

        if (sort.column) {
            sort1 = sort.column;
            sort1_desc = sort.type && sort.type === DESC_SORT;
        } else {
            sort1 = !currentServicePlan?.gan ? "subdestination_name" : "incorporated_group_name";
        }

        const requestMethod = currentServicePlan.auth 
            ? !isTest
                ? RATECARD_LIST_OTP_ACCOUNT_DIALER: RATECARD_LIST_OTP_DEFAULT_DIALER
            : !isTest
                ? !filter?.gan
                    ? servicePick(service, RATECARD_LIST_ACCOUNT_DIALER, SMS_RATECARD_LIST_ACCOUNT_DIALER) : servicePick(service, RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER, SMS_RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER)
                : !filter?.gan
                    ? servicePick(service, RATECARD_LIST_DEFAULT_DIALER, SMS_RATECARD_LIST_DEFAULT_DIALER) : servicePick(service, RATECARD_LIST_INCORPORATED_DEFAULT_DIALER, SMS_RATECARD_LIST_INCORPORATED_DEFAULT_DIALER)
        
        setUploadingPriceLoading(true);
        getTemplate(account.id, 0, "allocated_numbers").then(({template = {}}) => {
            getFileResponse(requestMethod, {filter: filter, sort1, sort1_desc})
                .then((response) => {
                    if (response) {
                        const csvText = getBlobContent(response);
                        csvText.then((csvContent) => {
                            const csvStringsArray = csvContent ? csvContent.split("\n") : [];
                            const csvDataArrayData = csvStringsArray.filter(value => !!value);
                            const csvHeaders = csvDataArrayData.length && csvDataArrayData[0].split(";");
                            const csvBody = csvDataArrayData.length && csvDataArrayData.slice(1, csvDataArrayData.length).map(line => line.split(";"));

                            savePdfByTemplate(
                                csvBody,
                                [csvHeaders],
                                "prices",
                                template,
                                {},
                                account
                            );
                            setUploadingPriceLoading(false);
                        });
                    }
                }).catch(() => {
                setUploadingPriceLoading(false)
            });
        })
    };


    const getNumbers = async (data) => {
        const currentTrunkId = data.trunk_id;
        const currentServicePlan = servicePlanList.find(value => value.sp_key === selectedData.sp_key);

        if (spAuth || currentServicePlan?.gan) {
            return await allocateByAccess(service, currentTrunkId, selectedData.sde_key, data.numbers, data.random_number, selectedData.ing_key);
        }

        return await allocateByTemplate(service, currentTrunkId, data.numbers, selectedData.template, data.random_number, selectedData.ing_key);
    };
    

    const onAllocationGetResult = (response) => {
        const notAllocatedNumbers = response.not_allocated_numbers;

        if (notAllocatedNumbers) {
            setNotAllocatedNumbers(notAllocatedNumbers);
        }
        
        const trunkNumberTransaction = response.trunk_number_transaction;

        setTrunkNumberTransaction(trunkNumberTransaction || {});

        if (trunkNumberTransaction) {
            const transactionId = trunkNumberTransaction.id;
            const allocatedNumbersCount = trunkNumberTransaction.numbers;

            setAllocatedNumbersCount(allocatedNumbersCount);

            const method = servicePick(service, "trunk_number:get_list", "sms.trunk_number:get_list");

            getFileResponse(method, {
                target: {
                    [servicePick(service, "trunk_number_transaction_id" , "sms.trunk_number_transaction_id")]: transactionId
                }
            })
                .then((response) => {
                    if (spAuth) {
                        setAccessLoadingAllocatedNumbers(false);
                    } else {
                        setLoadingAllocatedNumbersDialer(false);
                    }
                    if (response) {
                        const csvText = getBlobContent(response);

                        csvText.then((text) => {
                            setCsvContent(text);
                            setCsvContentLoading(false);
                            setShowAllocatedNumbersModal(true);
                            setShowGetNumbersModal(false);
                        });
                    }
                })
        }
    };

    const clearAllocatedData = () => {
        setCsvContent(null);
        setAllocatedNumbersCount(0);
    };

    const onDownloadNumbers = (trunk_id) => {
        downloadNumbers(trunk_id, service);
    };


    const downloadNumbersTxt = (csvNumberList, trunkId, setUploadingLoading) => {
        const encodedUri = encodeURI(csvNumberList);
        const link = document.createElement("a");

        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "allocated_numbers.txt");

        document.body.appendChild(link);
        link.click();
        setUploadingLoading(trunkId, false);
    };

    const setModalResponseHandler = (value) => {
        setShowModalResponseHandler(value);
    };

    
    const onResponseHandlerSuccess = (value) => {
        setShowGetNumbersModal(true);
    };


    const currentServicePlan = servicePlanList && servicePlanList.find( item => item.sp_key === filter.sp_key );

    const modalMaxNumber = selectedData.available_numbers;

    const modalGetNumbersTitle = useMemo(() => {
        return selectedData.range_name 
        ? `${formatMessage(m.getNumbersForRange)} ${selectedData.range_name}`
        : selectedData.incorporated_group_name 
            ? `${formatMessage(m.getNumbersForGlobalAccessNumbersGroup, null, {group: selectedData.incorporated_group_name})}`
            : selectedData.subdestination_name
                ? `${formatMessage(m.getNumbersForDestination, null, {
                    name: `${selectedData.subdestination_name} (${selectedData.template_dest})`
                })}`
                : formatMessage(m.getNumbers);
    }, [selectedData]);


    return <>
        <Page>
            <PageHeader title={formatMessage(m.ratecard)}/>

            <FlexGrid justify="space-between">
                <FlexGridItem>
                    <PriceFilters
                        onChangeFilter={onChangeFilters}
                        loading={priceLoading || searchLoading}
                        prices={servicePlanList}
                        savedTableFilter={filter}
                        defaultFilter={defaultFilter}
                        {...{
                            accountJoinList,
                            currencyList,
                            location,
                            service
                        }}
                    />
                </FlexGridItem>
                
                {windowWidth >= widthDesktopMin &&
                    <ExportPriceDropdown
                        mobile={windowWidth < widthDesktopMin}
                        loading={uploadLoading}
                        exportPrice={exportPriceFunc}
                        exportPricePdf={exportPricePdfFunc}
                        formatMessage={formatMessage}
                    />
                }
            </FlexGrid>

            <Spacer/>

            <PriceTable
                data={priceList}
                trunks={trunkList}
                loading={priceLoading || searchLoading}
                filters={filter}
                spAuth={spAuth}
                getItems={getItems}
                isIncorporated={isIncorporated}
                prices={servicePlanList}
                customChangePage
                customChangePerPage
                {...{
                    service,
                    location,
                    accountInfo,
                    isTest,
                    isSupport,
                    onChangePage,
                    onChangePerPage,
                    onShowGetNumbersModal,
                    sort,
                    page,
                    count,
                    per_page,
                    savedPerPage,
                    accountDialerState,
                    currentServicePlan
                }}
            />

            <ModalAllocationCommon
                show={showGetNumbersModal}
                trunks={filteredTrunkList}
                service={service}
                loading={loadingAllocation}
                minNumber={1}
                maxNumber={modalMaxNumber}
                selectedData={selectedData}
                showReasonHandler={showReasonHandler}
                onSend={getNumbers}
                rangeName={selectedData.range_name}
                title={modalGetNumbersTitle}
                onClose={() => {
                    setShowGetNumbersModal(false);
                    setSelectedData({});
                }}
                onSuccess={(r, formData) => {
                    onAllocationGetResult(r);
                    const sortColumn = getSortByFilter(sort, filter?.sp_key, currentServicePlan?.sp_key, currentServicePlan?.gan);
                    getPriceList(service, savedPerPage || per_page, filter, 1, currentServicePlan, sortColumn);

                    const currentTrunkList = trunkList.filter(value => (!value || !value?.allocation_limit?.allocate)
                        ? false
                        : value.sp_key === selectedData?.sp_key && !value.closed && value.active
                    );

                    let currentTrunkId = null;

                    if (formData.trunk_id) {
                        currentTrunkId = formData.trunk_id;
                    } else {
                        if (currentTrunkList.length) {
                            currentTrunkId = currentTrunkList[0].id;
                        }
                    }

                    setTrunkId(currentTrunkId);
                }}
                hideRandom={!service}
            />

            <ModalAllocationResult
                account={account}
                show={showAllocatedNumbersModal}
                maxWidth={850}
                onClose={() => {
                    clearAllocatedData();
                    setNotAllocatedNumbers(null);
                    setCsvContentLoading(true);
                    setSelectedData({});
                    setShowAllocatedNumbersModal(false);
                }}
                service={service}
                priceData={selectedData}
                downloadNumbers={downloadNumbers}
                downloadNumbersTxt={downloadNumbersTxt}
                onDownloadNumbers={onDownloadNumbers}
                trunkId={trunkId}
                trunks={trunkList}
                defaultTrunkId={null}
                methodName={"Ratecard"}
                csvContent={csvContent}
                notAllocatedNumbers={notAllocatedNumbers}
                trunkNumberTransaction={trunkNumberTransaction}
                allocatedNumbersCount={allocatedNumbersCount}

                loading={csvContentLoading}
                uploadLoading={uploadLoadingSetting}
                setUploadingLoading={setUploadingLoading}
            />

            <ModalResponseHandler
                show={showModalResponseHandler}
                params={{}}
                onClose={setModalResponseHandler}
                onSuccess={onResponseHandlerSuccess}
            />

        </Page>
    </>
};

const ExportPriceDropdown = ({mobile, loading, exportPrice, exportPricePdf, formatMessage}) => {
    return (
        <StyledButtonToolbar>
            <StyledDropdown
                color="violet"
                appearance="default"
                placement={mobile ? "topStart" : "bottomEnd"}
                title={<>{loading ? <StyledLoader/> : <><Icon icon="upload2"/>{formatMessage(m.download)}</>}</>}
                disabled={loading}
            >
                <Dropdown.Item onClick={() => exportPrice()}>{formatMessage(m.exportPriceList)}</Dropdown.Item>
                <Dropdown.Item onClick={() => exportPricePdf()}>{formatMessage(m.exportPriceListAs, null, {type: "pdf"})}</Dropdown.Item>
            </StyledDropdown>
        </StyledButtonToolbar>
    )
};

export default withTranslation(Price);



const StyledButtonToolbar = styled(ButtonToolbar)`
    width: 140px;
    display: inline-block; 
    margin-right: 10px;
    height: 36px;
    margin-bottom: 20px;
    ${props => props.width && css`
        width: ${props.width};
    `
    }
    .rtl & {
        margin-right: 0;
        margin-left: 10px;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 10px;
        text-align: center;
    }
`;


const StyledDropdown = styled(Dropdown)`
    width: 100%;
    height: 36px;
    
    &&&&& {
        .rs-btn {
            background-color: var(--color-brand2);
            width: 100%;
            color: white;
            height: 36px;
            padding: 8px 20px 8px 12px;
            
            &:hover {
                background-color: #5f2bb3;
                color: white;
            }
            
            .rtl & {
                padding: 8px 12px 8px 32px;
            }
        }
        
        .rs-dropdown-menu {
            background-color: #fdfdfd !important;
        }
        
        & > .rs-dropdown-item > .rs-dropdown-item-content:hover {
            background-color: #cfcfcf !important;
            color: #5c5c5c !important;
        }
        
        &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
            background-color: #D5D4D4 !important;
            color: white;
        }
        
        &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
            background-color: #D5D4D4 !important;
            color: white;
        }
        
        @media (max-width: 768px) {
            width: 140px;
        }
    }
`;


const StyledLoader = styled(Loader)`
  & span.rs-loader-spin {
    margin-top: 3px;
    margin-right: 5px;
  }
`;