import React, {useState, useEffect, useRef} from 'react';
import {Form, Schema, FormControl, Icon} from 'rsuite';
import styled from 'styled-components';
import {MIN_RATE_VALUE} from 'const';





const {NumberType} = Schema.Types;


const formModel = Schema.Model({
    custom_rate: NumberType()
        .min(MIN_RATE_VALUE, `The minimum of this field is ${MIN_RATE_VALUE}`)
        .max(10, 'The maximum of this field is 10')
});



export default ({
    trunksList,
    trunkId,
    updateFormRef,
    currentGroupData,
    formValue,
    setFormValue
}) => {

    const formRef = useRef(null);
    const [showRate, setShowRate] = useState(false);

    useEffect( () => {
        getDefaultPrice(trunkId);
    }, [trunkId]);


    const getDefaultPrice = (trunkId) => {
        formRef.current.cleanErrors();

        if (!currentGroupData?.rate_list || !currentGroupData.rate_list.length) {
            setShowRate(true);
            setFormValue({custom_rate: ""});
            return;
        }

        const trunk = trunksList.find(trunk => trunk.trunk_id === trunkId);
        const currentRateData = currentGroupData.rate_list.find(item => (
            item.pt_key === trunk?.pt_key && item.cur_key === trunk?.cur_key
        ));

        if (!currentRateData?.rate && currentRateData?.rate !== 0) {
            setShowRate(true);
            setFormValue({custom_rate: ""});
            return;
        }

        setShowRate(false);
        setFormValue({
            ...formValue,
            custom_rate: currentRateData.rate
        });
    };


    return (

        <Form
            layout="inline"
            model={formModel}
            ref={(ref) => {
                updateFormRef(ref);
                formRef.current = ref;
            }}
            formValue={formValue}
            onChange={setFormValue}
        >

            {!showRate

                ? <StyledRateShowLabel
                        onClick={() => setShowRate(true)}
                    >
                        <Icon icon="book" inverse />
                        <a>Put custom rate</a>
                    </StyledRateShowLabel>

                : <>
                    <StyledRateLabel>Rate</StyledRateLabel>

                    <StyledRateField>
                        <FormControl
                            name="custom_rate"
                            placeholder="0.0001"
                            errorPlacement="bottomStart"
                        />
                    </StyledRateField>

                    {formValue.custom_rate 
                        ? <StyledIconClose>
                            <Icon
                                icon="close"
                                inverse
                                onClick={() => {
                                    getDefaultPrice(trunkId);
                                }}
                            />
                        </StyledIconClose>
                        : null
                    }
                </>
            }

        </Form>
    );
};


const StyledRateShowLabel = styled.div`
    cursor: pointer; 
    margin: 8px 20px 0 0; 
    display: inline-block;
    
    .rs-icon {
        margin-right: 5px; 
    }
    
    a,
    .rs-icon {
        color: #5e2f9d !important;
    }
`;


const StyledRateLabel = styled.div`
    display: inline-block;
    // margin-left: 15px;
    margin-top: 8px;
`;

const StyledRateField = styled.div`
    display: inline-block; 
    margin-right: 10px;
    
    .rs-input {
        width: 140px !important;
    }
`;

const StyledIconClose = styled.div`
    display: inline-block;
    margin-top: 8px; 
    margin-right: 10px;
    
    .rs-icon {
        color: #5e2f9d; 
        cursor: pointer;
        margin-right: 5px; 
    }
`;