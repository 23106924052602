import React from "react";
import {useIntl} from "react-intl";
import {SelectPicker} from "rsuite";
import m from "../../definedMessages";
import {BaseSelectPicker} from "../base/BaseForm";
import styled from "styled-components";


export default ({
    data = [],
    onChange = () => {},
    rtl = false,
    intl,
    ...props
}) => {
    const {formatMessage} = useIntl();
    const renderTrunksListItem = (value, item) => <TrunksListItem item={item}/>;
    
    return (
        <StyledBaseSelectPicker
            data={data}
            onChange={onChange}
            name="tr_key"
            valueKey="id"
            labelKey="name"
            placement={rtl ? "bottomEnd" : "bottomStart"}
            placeholder={formatMessage(m.selectTrunk)}
            renderMenuItem={renderTrunksListItem}
            renderValue={renderTrunksListItem}
            style={{width: '100%'}}
            {...props}
        />
    )
}

const TrunksListItem = ({item}) => {
    const {formatMessage} = useIntl();

    if (!item) {
        return null;
    }

    return (
        <>
            <b>{formatMessage(m.trunk)}: </b> {item.name}&#44;&nbsp;
            <b>{formatMessage(m.price)}: </b> {item.service_plan_name}
        </>
    );
};

const StyledBaseSelectPicker = styled(BaseSelectPicker)`
    && {
        padding-left: 15px;
        padding-right: 15px;
    }
`;