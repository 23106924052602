import {connect} from "react-redux";
import AccessList from "./AccessList";

import {
    getSubAccAccessList,
    exportAccessList
} from "actions/client/subaccounts";

const mapState = ({auth_dialer, client_subaccounts}) => ({
    data: client_subaccounts.accessList,
    page: client_subaccounts.accessPage,
    count: client_subaccounts.accessCount,
    perPage: client_subaccounts.accessPerPage,
    loading: client_subaccounts.accessListLoading,
    accountJoinList: auth_dialer.accountJoinList
});

const mapMethods = {
    getList: getSubAccAccessList,
    exportAccessList: exportAccessList
};

export default connect(mapState, mapMethods)(AccessList);