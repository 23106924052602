import axios from 'axios';
import {Alert} from 'rsuite';
import store from '../store/index';
import history from '../config/history';
import {setAuth, setServiceAction, setMaintenanceMode} from '../actions/auth';
import {setInited} from "../actions/references";
import {setRequestData, onVisibleRecaptcha} from '../actions/settings';
import { APP_TYPE_CLIENT, APP_TYPE_DEFAULT, ERROR_CODE_TOO_MUCH_OPERATIONS } from 'const';
import { REFERENCES_ALL_METHOD } from 'const/apiMethods';


const baseUrl = process.env.REACT_APP_API_URL;
const codeError = {
    1: 'Authentication error',      // authentication
    2: 'No Permission',             // authorization
    3: 'Validation error',          // validation
    4: 'Data error',                // data
    DEFAULT: 'Server error'
};



// login instance

const _loginAxios = () => {

    const instanceAxios = axios.create({
        baseURL: APP_TYPE_DEFAULT === APP_TYPE_CLIENT ? `${baseUrl}/headers` : baseUrl,
        headers: {
            "Content-Type": 'application/json'
        }
    });

    instanceAxios.interceptors.response.use(function (response) {

        if (response.data.error) {
            return null;
        }

        return response;
        
    }, function (error) {
        
        if (error.response.status === 423) {
            Alert.error("Operation failed. Try again in few seconds");
            return null;
        }

        if (error.response.status === 503) {
            store.dispatch( setMaintenanceMode(true) );
            return null;
        }

        return Promise.reject(error);
    });

    return instanceAxios;
};



// version check instance

const _checkVersionAxios = () => {
    const instanceAxios = axios.create({
        headers: {
            "Content-Type": 'application/json'
        }
    });

    instanceAxios.interceptors.response.use(
        function (response) {
            if (response.data.error || response.status === 404) {
                return null;
            }
            return response;
        }, function (error) {
            return Promise.reject(error);
        }
    );

    return instanceAxios;
};



// common request instance

const _requestAxios = (headers = false) => {
    // const state = store.getState();

    const url = headers ? `${baseUrl}/headers` : baseUrl;
    let instanceAxios = axios.create({
        baseURL: url,
        headers: {
            "Content-Type": 'application/json'
        },
        timeout: 3600000
    });
    /* Request Interceptor */
    instanceAxios.interceptors.request.use(function (config) {
    
        const session_key = localStorage.getItem('api_key');

        if(session_key) {
            // const headerUrl = `${baseUrl}${headers ? '/headers' : ''}?method=${config.data.method}`;
            config.baseURL = `${url}?method=${config.data.method}`;
            // config.url = headerUrl;
            config.headers = {...config.headers, 'Session-Key': session_key}
        } else {
            // const isExpired = ![REFERENCES_ALL_METHOD].includes(config.data.method);
            unsetAuth(true);
        }

        return config;

    }, function (error) {
        return Promise.reject(error);
    });


    /* Response Interceptor */
    instanceAxios.interceptors.response.use(function (response) {
        // const responseError = _.get(response, 'data.error');
        const responseError = response?.data?.error;
        const isCaptchaError = response.status === 403 && responseError.data === "need captcha_token";

        if (responseError) {
            if (responseError.code === 1 || responseError.code === 2) {
                if (isCaptchaError) {
                    return null;
                }
                
                unsetAuth();
                // if (window.location.pathname !== "/login") {
                //     localStorage.setItem("TEST", window.location.pathname)
                // }
            } else {
                Alert.error(codeError[responseError.code] || codeError.DEFAULT);
            }
            return null;
        }
        return response;

    }, function (error) {
        // on cancelled request
        if ( axios.isCancel(error) ) {
            return error;
        }

        if (error && !error.response) {
            Alert.error("Something went wrong", 10000);
        }

        const {response} = error;
        
        const responseErrorBody = response?.data?.error;
        const isCaptchaError = response.status === 403 && responseErrorBody.data === "need captcha_token";

        if (response.status === 423) {
            if (responseErrorBody?.code === ERROR_CODE_TOO_MUCH_OPERATIONS) {
                Alert.error("Too much simultaneous operations")
                return null;
            } 
            Alert.error("Operation failed. Try again in few seconds");
            return null;
        }

        // on enabled maintenance
        if (response.status === 503) {
            store.dispatch( setMaintenanceMode(true) );
            return null;
        }

        // const responseError = _.get(response, 'data.error');
        const responseError = response?.data?.error;

        if (responseError) {
            if (isCaptchaError) {
                const {config} = response;
                const requestData = JSON.parse(config.data);

                const {method, params} = requestData;
                if (method !== "account_user_login") {
                    store.dispatch(setRequestData(method, params));
                    store.dispatch(onVisibleRecaptcha());
                }

                return null;
            }

            const notSavablePathnameList = ["/login", "/reset-password", "/accounts", "/hot-accesses", "/hot-accesses-oice", "/hot-accesses-sms"];

            if (!notSavablePathnameList.includes(window.location.pathname)) {
                localStorage.setItem("LAST_PAGE_BEFORE_SESSION_EXPIRED", window.location.pathname)
            }
            if (responseError.code === 1) {
                unsetAuth();
                return null;
            }

            if (responseError.code === 2) {
                Alert.error("No authorization permission");
                return null;
            }
        }

        return Promise.reject(error);

    });

    return instanceAxios;
};



// form data request instance

const _requestFormDataAxios = () => {

    const url = baseUrl;
    let instanceAxios = axios.create({
        baseURL: url,
        headers: {
            "Content-Type": 'multipart/form-data'
        },
        timeout: 3600000
    });


    /* Request Interceptor */
    instanceAxios.interceptors.request.use(function (config) {

        const configCopy = {...config};
        const session_key = localStorage.getItem('api_key');
        if(session_key) {
            configCopy.baseURL = `${url}?method=${configCopy.data.method}`;
            configCopy.headers = {...configCopy.headers, 'Session-Key': session_key}
        } else {
            unsetAuth(true);
            console.log("currentURL", window.location.pathname)
        }

        const bodyFormData = new FormData();
        bodyFormData.append('file', configCopy.data.params.files[0]);
        delete configCopy.data.params.files;
        bodyFormData.append('json', JSON.stringify(configCopy.data));
        configCopy.data = bodyFormData;

        return configCopy;

    }, function (error) {
        return Promise.reject(error);
    });

    return instanceAxios;
};


const unsetAuth = (session_expired=false) => {
    store.dispatch( setAuth(false) );
    // store.dispatch( setServiceAction(true) );
    // localStorage.removeItem('SMS_SERVICE');

    if (session_expired) {
        store.dispatch( setInited(false) );
        
        if (!localStorage.getItem("SESSION_EXPIRED")) {
            localStorage.setItem("SESSION_EXPIRED", true);
            history.go();
            history.replace("/login");
        }
    } else {
        localStorage.removeItem("LAST_PAGE_BEFORE_SESSION_EXPIRED")
        localStorage.removeItem("LAST_SERVICE_BEFORE_SESSION_EXPIRED");
    }

    if (localStorage.getItem('api_key')) {
        localStorage.removeItem('api_key');
        //             history.goForward();
    }

};



export const loginAxios = _loginAxios();
export const requestAxios = _requestAxios();
export const requestFormDataAxios = _requestFormDataAxios();
export const requestAxiosWithHeaders = _requestAxios(true);
export const checkVersionAxios = _checkVersionAxios();