import CustomField from '../client/Form/CustomField/CustomField';
import {BaseDateRangePicker} from '../base/BaseForm';
import m from '../../definedMessages';
import React, {useContext} from 'react';
import {useIntl} from 'react-intl';
import {useWindowWidth} from '../../hooks';
import {DateRangePicker} from 'rsuite';
import {RtlContext} from '../../App';

const {combine, before, after} = DateRangePicker;

const DatePickerCustom = ({
    isDisabledDates,
    changedFormValue,
    showRanges=true,
    showRangeForSevenDays=true,
    withTime=false
}) => {
    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);
    const resizedWidth = useWindowWidth();

    const calendarPlacement = resizedWidth >= 768 ? (rtl ? "bottomEnd" : "bottomStart") : 'auto';

    let allowStartDate = new Date(new Date().setMonth(new Date().getMonth() - 3));
    allowStartDate = new Date(allowStartDate.getFullYear(), allowStartDate.getMonth(), 1);

    let allowEndDate = new Date();
    allowEndDate = new Date(allowEndDate.getFullYear(), allowEndDate.getMonth() + 1, 0);

    const defaultValue = [
        new Date(new Date(changedFormValue.start_date).setHours(0, 0, 0, 0)),
        new Date(new Date(changedFormValue.end_date).setHours(23, 59, 59, 999))
    ];

    return <CustomField
        name="start_end_date"
        placement={calendarPlacement}
        showOneCalendar={resizedWidth <= 767}
        accepter={BaseDateRangePicker}
        defaultCalendarValue={
            [
                new Date(changedFormValue.start_date),
                new Date(changedFormValue.end_date)
            ]
        }
        // defaultValue={defaultValue}
        cleanable={false}
        ranges={showRanges ? [
            {
                label: formatMessage(m.yesterday),
                value: [
                    new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0)),
                    new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 999))
                ]
            }, {
                label: formatMessage(m.today),
                value: [
                    new Date(new Date().setHours(0, 0, 0, 0)),
                    new Date(new Date().setHours(23, 59, 59, 999))
                ]
            }, 
            ...(showRangeForSevenDays ? [
                {
                    label: formatMessage(m.last7Days),
                    value: [
                        new Date(new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0, 0)),
                        new Date(new Date().setHours(23, 59, 59, 999))
                    ]
                }
            ] : [])
        ] : []}
        {...{
            ...(isDisabledDates ? {disabledDate: combine(before(allowStartDate), after(allowEndDate))} : {})
        }}
        format={withTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"}
    />
};

export default DatePickerCustom;