import React from "react";
import * as S from "./styled";
import { Button, Icon } from "rsuite";
import { FormattedMessage } from "react-intl";

export default ({title = null, show = false, callback = null, ...props}) => {

    const handleClick = (e) => {
        if (callback) 
            callback(e);

        return null;
    };

    return (
        <S.FlexBlock align="middle">
            {title &&
                <S.SectionTitle>{title}</S.SectionTitle>
            }
            <Button size="sm" appearance="link" onClick={handleClick}>
                {show
                    ? <>
                        <Icon icon="angle-up"/>&nbsp;
                        <FormattedMessage id="common.hide" defaultMessage="Hide"/>
                    </>
                    : <>
                        <Icon icon="angle-down"/>&nbsp;
                        <FormattedMessage id="common.show" defaultMessage="Show"/>
                    </>
                }
            </Button>
        </S.FlexBlock>
    )
};