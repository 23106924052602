import Modal from '../../components/Modal';
import React, {useEffect, useState} from 'react';
import {FormControl, Schema, Input} from 'rsuite/es';
import SettingsJsonEditor from 'pages/Settings/SettingsJsonEditor';
import Whisper from 'rsuite/es/Whisper';
import Tooltip from 'rsuite/es/Tooltip';
import {Icon, IconButton} from "rsuite";
import {FieldTextArea} from "./styled";
import * as S from './styled';
import SettingsContactEditor from './SettingsContactEditor'


const {NumberType, StringType} = Schema.Types;

const SettingsEditModal = ({show, dataObj, valueKey, isJson, onEditItem, isMobile, windowWidth, onClose, getList}) => {
    let formRef;

    const [formState, onChangeFormState] = useState(null);
    const [description, setDescription] = useState("");
    const [showDescriptionEdit, setShowDescriptionEdit] = useState(!dataObj.description);
    const [showResult, onShowResult] = useState(isJson === null || isJson === undefined,);
    const [comparedData, onCompareData] = useState(true);
    const [errorMessageState, onChangeErrorMessage] = useState(null);

    const editSchemaNotJson = Schema.Model({
        s_as_integer: NumberType('This field requires only numbers').max(100000000, 'This is the maximum number for this field'),
        s_as_string: StringType().maxLength(1024, 'This is the maximum count of characters for this field'),
        description: StringType().maxLength(256, 'This is the maximum count of characters for this field')
    });

    useEffect(() => {
        onShowResult(isJson === null || isJson === undefined);
    }, [isJson]);

    useEffect(() => {
        onChangeFormState(dataObj[valueKey])
        setShowDescriptionEdit(!dataObj.description);
        setDescription(dataObj.description);
    }, [dataObj]);

    const onSubmit = () => {
        const keyList = ['s_as_string', 's_as_integer', 's_as_json', 'type', 'name', 'description'];
        const data = Object.keys(dataObj).filter(key => keyList.includes(key)).reduce((result, key) => {
            result[key] = dataObj[key];
            return result
        }, {});
        const finalData = {
            ...data,
            [valueKey]: formState,
            description: description
        };

        onEditItem(finalData).then(() => {
            getList()
        });
        onShowResult(false);
        onCompareData(true);
    };

    const formData = Object.keys(dataObj).length ? dataObj[valueKey] : {};

    const compareAndUpdateFormData = (data) => {
        let parsedData = null;
        let errorMessage = null;
        try {
            parsedData = JSON.parse(data);
            onChangeErrorMessage(null)
        } catch (e) {
            errorMessage = 'JSON is incorrect! Use the undo button';
            onChangeErrorMessage(errorMessage)
        }

        if (!errorMessage) {
            onChangeFormState(parsedData);
        }
        onCompareData(JSON.stringify(parsedData) === JSON.stringify(formData));
    };

    const comparePreviewAdaptive = (windowWidth <= 1250) && !isMobile;

    return (
        <Modal
            show={show}
            title={'Setting value edit'}
            onClose={() => {
                onShowResult(false);
                onCompareData(true);
                setShowDescriptionEdit(false);
                onClose();
            }}
            footer={showResult}
            successText="Save"
            width={windowWidth * 0.4}
            extraDialogClassName={isJson ? 'modal-height-limited' : ''}
            onSuccess={() => {
                onSubmit();
                setShowDescriptionEdit(false);
                onClose();
            }}
        >
            <S.StyledForm
                ref={ref => formRef = ref}
                isMobile={isMobile}
                model={!isJson ? editSchemaNotJson : null}
                formDefaultValue={{...dataObj}}
                onChange={(value) => {
                    const formData = valueKey === 's_as_integer'
                        ? Number.parseInt(value[valueKey])
                        : value[valueKey];
                    setDescription(value.description || '');
                    onChangeFormState(formData);
                }}
            >
                <S.StyledKeyValue><S.StyledLabel>Name:</S.StyledLabel><S.StyledValue>{dataObj['name']}</S.StyledValue></S.StyledKeyValue>
                <S.StyledKeyValue><S.StyledLabel>Type:</S.StyledLabel><S.StyledValue>{dataObj['typeStr']}</S.StyledValue></S.StyledKeyValue>
                <S.StyledKeyValue>
                    <S.StyledLabel>Description:</S.StyledLabel>
                    <S.StyledValue>
                        {!showDescriptionEdit
                            ? dataObj['description']
                            : <FormControl
                                accepter={Input}
                                componentClass="textarea"
                                name="description"
                            />
                        }
                    </S.StyledValue>
                    <IconButton
                        style={{marginTop: '-2px'}}
                        appearance="link"
                        size="xs"
                        color={!showDescriptionEdit ? "" : "red"}
                        onClick={() => {
                            if (showDescriptionEdit) {
                                setDescription(dataObj['description']);
                            }
                            setShowDescriptionEdit(!showDescriptionEdit);
                        }}
                        icon={<Icon
                            icon={!showDescriptionEdit ? "edit2" : "close"}
                        />}
                    />
                </S.StyledKeyValue>

                

                {isJson
                    ? <>
                        {!showResult
                            ? <>
                                <Whisper
                                    open={!!errorMessageState}
                                    preventOverflow
                                    placement="topStart"
                                    trigger="none"
                                    speaker={
                                        <Tooltip>{errorMessageState}</Tooltip>
                                    }
                                >
                                    <SettingsJsonEditor
                                        disabled={true}
                                        editable={true}
                                        json={formState}
                                        onChange={compareAndUpdateFormData}
                                    />
                                </Whisper>
                                <S.StyledButton
                                    color="green"
                                    disabled={comparedData || errorMessageState}
                                    onClick={() => {
                                        onShowResult(true);
                                    }}
                                >
                                    View final result
                                </S.StyledButton>
                                <S.StyledButton
                                    color="blue"
                                    onClick={() => {
                                        onChangeFormState(dataObj[valueKey]);
                                        onChangeErrorMessage(null);
                                        onCompareData(true)
                                    }}
                                >
                                    Reset to default
                                </S.StyledButton>
                            </>
                            : <>
                                <S.StyledButton
                                    className="continue"
                                    onClick={() => {
                                        onShowResult(false)
                                    }}
                                >
                                    Continue editing
                                </S.StyledButton>
                                <S.StyledCompare
                                    isMobile={comparePreviewAdaptive}
                                >
                                    <div>
                                        <S.StyledLabel>Old json</S.StyledLabel>
                                        <pre style={{marginTop: 24 }}>
                                            {JSON.stringify(formData, null, 2)}
                                        </pre>
                                    </div>
                                    <div>
                                        <S.StyledLabel>New json</S.StyledLabel>
                                        <pre style={{marginTop: 24 }}>
                                            {formState && JSON.stringify(formState, null, 2)}
                                        </pre>
                                    </div>
                                </S.StyledCompare>
                            </>
                        }
                    </>
                    : <>
                        { valueKey === 's_as_integer' && <FormControl
                            name={valueKey}
                            className={'settings_input_not_json'}
                        />}
                        {valueKey === 's_as_string' && dataObj.name !== "contacts_text" ?
                            <FieldTextArea
                                name={valueKey}
                                defaultValue={formData}
                                onChange={(value) => {
                                    onChangeFormState(value.target.value)
                                }}
                                width={windowWidth}
                            />
                            : <SettingsContactEditor
                                defaultValue={formData}
                                onChange={(value) => {
                                    onChangeFormState(value)
                                }}
                            />
                        }
                    </>
                }
            </S.StyledForm>
        </Modal>
    );
};



export default SettingsEditModal;
