import React, {useRef, useState, useEffect} from "react";
import {Button, Form, FormControl, ControlLabel, Icon} from "rsuite";
import styled, {css} from "styled-components";
import ModalSubmitRowApprove from "./ModalSubmitRowApprove";
import {SCREEN_MEDIA} from "const";
import {isObject} from "utils/helpers";


const {md} = SCREEN_MEDIA;

export default (
    {
        columns,
        formModel,
        onSubmit,
        onDataUpdate,
        mobile,
        addButtonWidth,
        formStyle,
        onChangeServiceName,
        extraOnChange = null,
        disabled = false
    }
) => {

    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const [modalApproveData, setModalApproveData] = useState(false);
    const [showModalApprove, setShowModalApprove] = useState(false);
    const [modalApproveLoading, setModalApproveLoading] = useState(null);

    const [formStateValue, setFormStateValue] = useState({});

    const handleSubmit = () => {
        if (!formRef.current.check())
            return;
     
        const formValue = formRef.current.getFormValue();

        columns.forEach(column => {
            if (!formValue.hasOwnProperty(column?.dataKey) && column.hasOwnProperty('defaultValue') && column.replaceWithDefault) {
                formValue[column.dataKey] = column.defaultValue
            }
        })

        setLoading(true);

        onSubmit(formValue)
            .then(res => {
                setLoading(false);
                if (res) {
                    onDataUpdate();
                }
            });
    };

    const onApprovedSubmit = async () => {
        setModalApproveLoading(true);
        await onSubmit(modalApproveData);
        onDataUpdate();
        setModalApproveLoading(false);
        setShowModalApprove(false);
    };

    const fieldsCount = columns.filter(item => item.accepter).length;

    useEffect(() => {
        const columnListFiltered = columns.filter((value) => {
            return !!value.fixTextareaValue;
        });
        const textareaKeyList = columnListFiltered.map((value) => {
            return value.dataKey;
        });

        Object.keys(formStateValue).forEach((key) => {
            if (textareaKeyList.includes(key)) {
                formRef.current.check();
            }
        });
    }, [formStateValue]);

    return (
        <>
            <StyledForm
                ref={formRef}
                model={formModel}
                onSubmit={handleSubmit}
                formValue={formStateValue}
                onChange={(formValue) => {
                    if (extraOnChange) {
                        extraOnChange(formValue);
                    }

                    const columnListFiltered = columns.filter((value) => {
                        return !!value.fixTextareaValue;
                    });

                    const textareaKeyList = columnListFiltered.map((value) => {
                        return value.dataKey;
                    });

                    const textareaFormValues = textareaKeyList.reduce((result, key) => {
                        if (!!formValue[key]) {
                            if (Object.keys(formValue).includes(key) 
                                && formValue[key] 
                                && isObject(formValue[key])
                                && formValue[key].hasOwnProperty("target")) {
                                result[key] = formValue[key].target.value;
                            } else {
                                result[key] = formValue[key];
                            }
                        }

                        return result;
                    }, {});

                    const formFormattedValue = {
                        ...formValue,
                        ...textareaFormValues
                    };

                    setFormStateValue(formFormattedValue);
                }}
                formStyle={formStyle}
                mobile={mobile}
            >

                {columns.map( ({
                    dataKey, fieldName, accepter, name = "", value, defaultValue, model,
                    flexGrow = null, minWidth = null, width = null, label, showLabelOnCreate, ...formControlProps
                }) => (
                    
                    accepter && 
                    <FlexBox
                        grow={flexGrow} 
                        minWidth={minWidth}
                        width={width}
                    >
                        {showLabelOnCreate && <FormLabel>{label}</FormLabel>}
                        <StyledFieldWrapper 
                            cols={fieldsCount} 
                            grow={flexGrow} 
                            minWidth={minWidth}
                            width={width}
                        >
                            <StyledField
                                accepter={accepter}
                                mobile={mobile}
                                name={fieldName || dataKey}
                                className="tableFilters_field"
                                errorPlacement="topEnd"
                                placeholder="Description"
                                type="text"
                                onChange={(value) => {
                                    if (dataKey === "antf_name") {
                                        onChangeServiceName(value)
                                    }
                                }}
                                {...formControlProps}
                            />
                        </StyledFieldWrapper>
                    </FlexBox>
                ))}

                <StyledSubmitWrapper
                    mobile={mobile}
                    addButtonWidth={addButtonWidth}
                >
                    <Button
                        className="tableFilters_submit"
                        color="green"
                        type="submit"
                        loading={loading}
                        disabled={disabled}
                    >
                        {mobile 
                            ? <b>Create</b>
                            : <Icon icon="plus"/>
                        }
                    </Button>
                </StyledSubmitWrapper>
            </StyledForm>

            <ModalSubmitRowApprove
                show={showModalApprove}
                onSubmit={onApprovedSubmit}
                onClose={() => setShowModalApprove(false)}
                disabled={modalApproveLoading}
            />
        </>
    )
};

const FlexBox = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    flex-grow: ${props => props.grow || '0'};
    flex-shrink: 1;
    ${props => {
        const width = props.minWidth || props.width;
        return width && css`
            width: ${width}px;
            flex-basis: ${width}px;
        `
    }}
`;

const FormLabel = styled(ControlLabel)`
    &&&& {
        float: none;
        margin: 0 0 10px 0;
        padding: 0;
        text-align: left;
        word-wrap: none;

        @media (min-width: ${md.min}px) {
            margin: 0 10px 0 0;
            max-width: 130px;
            width: auto;
        }
    }
`;


const StyledField = styled(FormControl).attrs(props => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    placement: "autoVerticalStart",
    type: "text",
    cleanable: true,
    searchable: true,
}))`
`;

const StyledFieldWrapper = styled.div`
    flex-grow: ${props => props.grow || '0'};
    flex-shrink: 1;
    padding: 5px 10px;
    ${props => {
        const width = props.minWidth || props.width;
        return width && css`
            width: ${width}px;
            flex-basis: ${width}px;
        `
    }}
`;

const StyledSubmitWrapper = styled.div`
    flex-shrink: 0;
    padding: 5px 10px;
    width: ${props => props.mobile ? '100%' : (props.addButtonWidth ? props.addButtonWidth: 174)};
`;

const StyledForm = styled(Form)`
    display: flex;

    ${props => props.formStyle && ({...props.formStyle})}
    
    ${props => props.mobile && css`
        flex-wrap: wrap;
    `}
`;