import React, {useRef, useMemo, useState} from "react";
import Modal from "components/Modal/Modal";
import {api} from "api/loginRoutes";
import {SCREEN_MEDIA} from "const";
import {FlexboxGrid, Schema, ControlLabel, Form, Input, FormControl, InputNumber, RadioGroup, Radio, SelectPicker} from "rsuite";
import {
    SMS_TRAFFIC_LIMITS_CREATE_API
} from "const/apiMethods";
import Table from "components/Table/Table";
import { useEffect } from "react";
import {debounce, hasErrorObject} from "utils";

import CustomField from "components/Form/CustomField";

import styled, {css} from "styled-components";

const {StringType, NumberType} = Schema.Types;


const defaultFormValue = {senderid: "", day: null, hour: null, mcc: null, mnc: null};

export default (
    {
        show,
        transitTrunkList,
        suppliersGroupList,
        supplierId,
        onSuccess,
        onClose,
        disabled=false,
        trafficTypeState,
        e212List,
        countryList,
        service,
        selectedDefaultData,
        transitSupplierPlanList,

        pickSupplierPlan,
        getE212DropdownList,
        setSelectedDefaultData,
        getReferencesForReports,
        getSupplierPrefixesDropdown
    }
) => {

    const isGanGroup = trafficTypeState === "gan_group";
    const isGanNumber = trafficTypeState === "gan";
    const isPrefix = trafficTypeState === "prefix";
    const isMcc = trafficTypeState === "mcc";
    const isDefault = trafficTypeState === "mcc_default";

    const ganGroupModel = Schema.Model({
        senderid: StringType()
            .maxLength(40, "The maximum is 40 characters"),
            // .isRequired("This field is required"),
        gan_group_id: StringType()
            .isRequired("This field is required"),
        day: NumberType()
            .min(1, "Minimum 1")
            .max(10000000, "Maximum 10000000")
            .isRequired("This field is required"),
        hour: NumberType()
            .min(1, "Minimum 1")
            .max(10000000, "Maximum 10000000")
            .isRequired("This field is required"),
    });

    const ganNumberModel = Schema.Model({
        senderid: StringType()
            .maxLength(40, "The maximum is 40 characters"),
            // .isRequired("This field is required"),
        number: StringType()
            .pattern(/^[0-9][\d]*$/, "The number must contain only digits")
            .minLength(3, "The minimum is 3 characters.")
            .maxLength(15, "The maximum is 15 characters")
            .isRequired("This field is required"),
        day: NumberType()
            .min(1, "Minimum 1")
            .max(10000000, "Maximum 10000000")
            .isRequired("This field is required"),
        hour: NumberType()
            .min(1, "Minimum 1")
            .max(10000000, "Maximum 10000000")
            .isRequired("This field is required"),
    });

    const prefixModel = Schema.Model({
        senderid: StringType()
            .maxLength(40, "The maximum is 40 characters"),
            // .isRequired("This field is required"),
        // trunk_id: StringType()
        //     .isRequired("This field is required"),
        prefix: StringType()
            .pattern(/^[0-9][\d]*$/, "The prefix must contain only digits")
            .minLength(3, "The minimum is 3 characters.")
            .maxLength(15, "The maximum is 15 characters")
            .isRequired("This field is required"),
        day: NumberType()
            .min(1, "Minimum 1")
            .max(10000000, "Maximum 10000000")
            .isRequired("This field is required"),
        hour: NumberType()
            .min(1, "Minimum 1")
            .max(10000000, "Maximum 10000000")
            .isRequired("This field is required"),
    });

    const mccModel = Schema.Model({
        mcc: StringType()
            .isRequired("This field is required"),
        mnc: StringType(),
        senderid: StringType()
            .maxLength(40, "The maximum is 40 characters"),
        day: NumberType()
            .min(1, "Minimum 1")
            .max(10000000, "Maximum 10000000")
            .isRequired("This field is required"),
        hour: NumberType()
            .min(1, "Minimum 1")
            .max(10000000, "Maximum 10000000")
            .isRequired("This field is required"),
        "sms.supplier_plan_id": StringType()
            .isRequired("This field is required")
    });


    const operatorFilterModel = Schema.Model({
        code: StringType()
            .pattern(/^\d*$/, "Only numbers")
            .maxLength(15, "The maximum is only 15 characters.")
    });
    
    const defaultOperatorFilter = {
        mcc: "",
        mnc: "",
        country: null,
        operator: ""
    };

    const formRef = useRef(null);
    const [_disabled, setDisabled] = useState(disabled);

    const [activeOperator, setActiveOperator] = useState(null);
    const [activeOperatorData, setActiveOperatorData] = useState({});

    const [formValue, setFormValue] = useState(defaultFormValue);
    const [defaultLimitType, setDefaultLimitType] = useState("supplier");

    const [operatorFilter, setOperatorFilter] = useState(defaultOperatorFilter);

    const method = SMS_TRAFFIC_LIMITS_CREATE_API;

    const [transitTrunkId, setTransitTrunkId] = useState(null);

    const defaultModel = useMemo(() => {
        return Schema.Model({
            senderid: StringType()
                .maxLength(40, "The maximum is 40 characters"),
                // .isRequired("This field is required"),
            day: NumberType()
                .min(1, "Minimum 1")
                .max(10000000, "Maximum 10000000")
                .isRequired("This field is required"),
            hour: NumberType()
                .min(1, "Minimum 1")
                .max(10000000, "Maximum 10000000")
                .isRequired("This field is required"),
            ...(defaultLimitType === "mcc_default" ? {
                "sms.supplier_plan_id": StringType()
                    .isRequired("This field is required")
            } : {})
        });
    }, [defaultLimitType]);

    const formCreateModels = {
        gan_group: ganGroupModel,
        gan: ganNumberModel,
        prefix: prefixModel,
        mcc: mccModel,
        mcc_default: defaultModel
    };

    const formCreateHasError = useMemo(() => {
        const fullFormValue = isMcc ? {...formValue, ...activeOperatorData} : formValue;
        const formCreateErrorCheck = trafficTypeState in formCreateModels && formCreateModels[trafficTypeState].check(fullFormValue || {});

        return Object.keys(formCreateErrorCheck).some(field => formCreateErrorCheck[field]?.hasError);
    }, [formValue, activeOperatorData, trafficTypeState, defaultLimitType]);

    useEffect(() => {
        getReferencesForReports();
    }, []);

    useEffect(() => {
        if (Object.keys(selectedDefaultData).some((key) => !!selectedDefaultData[key])) {
            setFormValue(selectedDefaultData);
        } else {
            setFormValue(defaultFormValue);
        }
    }, [selectedDefaultData]);

    useEffect(() => {
        if (!service) {
            getE212DropdownList(operatorFilter, true);
        }
    }, [operatorFilter]);

    useEffect(() => {
        if (transitTrunkId) {
            getSupplierPrefixesDropdown(transitTrunkId)
        }
    }, [transitTrunkId]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.check();
        }
    }, [defaultLimitType]);

    const clearOperatorFilter = () => {
        setOperatorFilter(defaultOperatorFilter);
    };

    const onSubmit = async () => {
        const {
            hour: hour, day: day, gan_group_id, number, prefix, mcc, mnc, senderid, 
            "sms.supplier_plan_id": supplierPlanId,
            ...formValueLast
        } = formValue;

        const formGanNumber = [number];
        const formPrefixNumber = [prefix];

        const keyParams = {
            "sms.supplier_id": supplierId
        };

        const params = {
            target: {
                ...(isGanGroup ? {"sms.supplier_gan_group_id": gan_group_id} : {}),
                ...(isGanNumber ? {"sms.supplier_gan_number_list": formGanNumber} : {}),
                ...(isPrefix ? {"sms.supplier_prefix_prefix_list": formPrefixNumber} : {}),
                ...(isMcc ? {"mcc": activeOperatorData?.mcc, ...(activeOperatorData?.mnc ? {"mnc": activeOperatorData.mnc} : {})} : {}),
                ...((isMcc || isDefault) && supplierPlanId ? {"sms.supplier_plan_id": supplierPlanId} : {}),
                ...(mcc ? {mcc, ...(mnc ? {mnc} : {})} : {}),
                ...keyParams,
            },
            senderid: senderid || "",
            hour: parseInt(hour),
            day: parseInt(day),
            type: isDefault ? defaultLimitType : trafficTypeState,
            ...formValueLast
        };

        setDisabled(true);
        
        const result = await api(method, params);

        if (!("error" in result)) {
            onSuccess();
            setSelectedDefaultData(defaultFormValue);
        }

        setDisabled(false);
    };

    const currentE212List = useMemo(() => {
        return e212List.map((item) => {
            return {
                ...item,
                countryOperatorName: `${item.country_name}, ${item.operator} (${item.mcc}${item.mnc})`,
                mccMnc: `${item.mcc}-${item.mnc}`
            }
        })
    }, [e212List]);

    const e212Columns = useMemo(() => [
        {
            id: "countryOperatorName",
            dataKey: "countryOperatorName",
            align: "left",
            label: "Operator",
            className: "countryOperatorName",
            // width: 140,
            flexGrow: 1
        }
    ], []);

    return (
        <>
            {show && <Modal
                show={show}
                title="Create traffic limit"
                onClose={() => {
                    onClose();
                    setActiveOperator(null);
                    setActiveOperatorData({});
                    clearOperatorFilter();
                    setFormValue(defaultFormValue);
                    setSelectedDefaultData(defaultFormValue);
                }}
                footer={true}
                width={680}
                successText="Create"
                disabled={_disabled}
                extraDisabled={formCreateHasError}
                onSuccess={() => {
                    onSubmit();
                    setActiveOperator(null);
                    setActiveOperatorData({});
                    clearOperatorFilter();
                    setFormValue(defaultFormValue);
                }}
            >
                {isMcc && !selectedDefaultData.mcc && <Form
                    model={operatorFilterModel}
                    formDefaultValue={defaultOperatorFilter}
                    onChange={debounce((filterFormValue) => {
                        const formCheckData = operatorFilterModel.check(filterFormValue);
                        if (!hasErrorObject(formCheckData)) {

                            const pickedCountryCode = filterFormValue?.country;
                            const pickedCountryName = countryList.find((item) => item?.cntr_code === pickedCountryCode);
                            const currentFormValue = {
                                ...filterFormValue,
                                country: pickedCountryName?.name
                            };

                            setOperatorFilter(currentFormValue);
                        }
                    }, 1000)}
                >
                    <FilterFlexboxGrid align="middle">
                        <FlexboxGrid.Item>
                            <FormControl
                                accepter={Input}
                                name="mcc"
                                placeholder="MCC"
                                style={{width: 80}}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item>
                            <FormControl
                                accepter={SelectPicker}
                                data={countryList}
                                labelKey="name"
                                valueKey="cntr_code"
                                placeholder="Country"
                                name="country"
                                style={{width: 200}}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item>
                            <FormControl
                                accepter={Input}
                                name="mnc"
                                placeholder="MNC"
                                style={{width: 80}}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item>
                            <FormControl
                                accepter={Input}
                                name="operator"
                                placeholder="Operator"
                                style={{width: 200}}
                            />
                        </FlexboxGrid.Item>
                    </FilterFlexboxGrid>
                </Form>}
                {isMcc && !selectedDefaultData.mcc && <Table
                    row_key="mccMnc"
                    active_id={activeOperator}
                    virtualized
                    shouldUpdateScroll={false}
                    data={currentE212List}
                    extraHeight={(currentE212List.length && currentE212List.length < 7) ? (currentE212List.length * 40) + 40 : 280}
                    columns={e212Columns}
                    rowHeight={40}

                    onRowClick={(value) => {
                        setActiveOperator(value);
                        const [mcc, mnc] = value.split("-");

                        setActiveOperatorData({
                            mcc,
                            mnc
                        })
                    }}
                />}
                <StyledForm
                    ref={formRef}
                    model={formCreateModels[trafficTypeState]}
                    formValue={formValue}
                    onChange={setFormValue}
                >
                    {!isMcc && <>
                        {isDefault && <RadioGroup 
                            inline
                            name="limit_type" 
                            defaultValue="supplier"
                            onChange={setDefaultLimitType}
                            value={defaultLimitType}
                        >
                            <Radio value="supplier">Whole account limit</Radio>
                            <Radio value="mcc_default">Default MCC limit</Radio>
                        </RadioGroup>}

                        {isGanGroup && <StyledField>
                            <ControlLabel>Gan Groups</ControlLabel>
                            <FormControl
                                name="gan_group_id"
                                accepter={SelectPicker}
                                data={suppliersGroupList}
                                valueKey={"id"}
                                labelKey={"name"}
                                placeholder="Seletect gan group"
                            />
                        </StyledField>}

                        {isPrefix && <StyledField>
                            <ControlLabel>Prefix</ControlLabel>
                            <FormControl
                                name="prefix"
                                accepter={Input}
                                placeholder="Input prefix"
                            />
                        </StyledField>}

                        {isGanNumber && <StyledField>
                            <ControlLabel>Number</ControlLabel>
                            <FormControl
                                name="number"
                                accepter={Input}
                                placeholder="Input number"
                            />
                        </StyledField>}

                    </>}

                    {(isMcc || (isDefault && defaultLimitType === "mcc_default")) && <StrechedContainer>
                        <CustomField
                            accepter={SelectPicker}
                            data={transitSupplierPlanList}
                            cleanable={false}
                            searchable={false}
                            valueKey="id"
                            labelKey="name"
                            placeholder="Select trunk type"
                            errorPlacement="bottomLeft"
                            label="Trunk type"
                            name="sms.supplier_plan_id"
                            // value={pickedSupplierPlan?.id}
                            onChange={(id) => {
                                const supplierValue = transitSupplierPlanList.find(supplier => supplier?.id === id);
            
                                pickSupplierPlan(supplierValue)
                            }}
                            style={{
                                width: 230
                            }}
                            styleLabelExternal={{
                                width: 160,
                                marginRight: 0,
                                flexShrink: 0
                            }}
                        />
                    </StrechedContainer>}

                    <StyledField>
                        <ControlLabel>Sender ID</ControlLabel>
                        <FormControl
                            name="senderid"
                            accepter={Input}
                            placeholder="Type senderid"
                        />
                    </StyledField>

                    <StyledField>
                        <ControlLabel>Day</ControlLabel>
                        <FormControl
                            name="day"
                            accepter={InputNumber}
                            placeholder="Day"
                        />
                    </StyledField>

                    <StyledField>
                        <ControlLabel>Hour</ControlLabel>
                        <FormControl
                            name="hour"
                            accepter={InputNumber}
                            placeholder="Hour"
                        />
                    </StyledField>


                </StyledForm>
            
            </Modal>
            }
        </>

    );
};

const FilterFlexboxGrid = styled(FlexboxGrid)`
    .rs-flex-box-grid-item {
        margin-right: 10px;
    }
`;

const StyledForm = styled(Form)`
    .rs-form-group {
        display: flex;
        align-items: center;
    }
    
    .rs-control-label {
        margin-right: 20px;
        margin-bottom: 0;
        width: 100px;
    }
    
    .rs-picker-select {
        width: 100%;
    }
`;

const StyledField = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
    }

    .rs-control-label {
        flex-shrink: 0;
        align-self: center;
        padding-right: 10px;
        width: 100%;
        margin-right: 0;

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            width: 160px;
        }
    }

    .rs-input-number {

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            max-width: 90px;
        }
    }

    ${props => props.isCheckbox && css`
        display: flex;
        
        @media (max-width: ${SCREEN_MEDIA.sm.max}px) {

            .rs-form-control-wrapper {
                width: auto;
            }

            .rs-control-label {
                width: auto;
                order: 1;
            }
        }
    `}
`;

const StrechedContainer = styled.div`
    && {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 10px;
    }
`;