import React, {useState} from 'react';
import {Checkbox} from 'rsuite';

export const CustomCheckbox = (
    {
        children,
        defaultValue = false,
        onChange,
        value,
        ...props
    }
) => {

    const [_value, _setValue] = useState(!!defaultValue);

    return <Checkbox
        value={_value}
        checked={_value}
        onChange={() => {
            _setValue(!_value);
            onChange(!_value);
        }}
        {...props}
    >
        {children}
    </Checkbox>
};