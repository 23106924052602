import {AUTH_NUMBERS as PREFIX, DEFAULT_PER_PAGE, SET_AUTH_NUMBER_TYPE_LIST} from '../const';
import {
    AUTH_NUMBERS_ADD,
    AUTH_NUMBERS_ADD_UPLOAD,
    AUTH_NUMBERS_DELETE,
    AUTH_NUMBERS_EDIT,
    AUTH_NUMBERS_GET_LIST,
    AUTH_AUTH_NUMBER_TYPE_CREATE_NEW,
    AUTH_AUTH_NUMBER_TYPE_GET_LIST,
    SMS_AUTH_AUTH_NUMBER_TYPE_GET_LIST,
    RULE_API_GET_LIST,
    RULE_API_CREATE,
    RULE_API_MODIFY,
    RULE_API_REMOVE,
    OTP_RULE_API_GET_LIST,
    OTP_RULE_API_CREATE,
    OTP_RULE_API_MODIFY,
    OTP_RULE_API_REMOVE
} from '../const/apiMethods';

import {servicePick} from '../utils';
import {api} from '../api/loginRoutes';
import {createAction} from './defaults';

const setAuthNumbers = createAction('SET_ITEMS_'+PREFIX);
const setOtpServiceList = createAction(SET_AUTH_NUMBER_TYPE_LIST);
const setLoading = createAction('SET_LOADING_'+PREFIX);
const setLoadingItem = createAction('SET_LOADING_ITEM_'+PREFIX);
const setRulesLoading = createAction('SET_LOADING_RULES')
const setRules = createAction('SET_ITEMS_RULES');

export const getAuthNumbers = (filters) => (dispatch) => {
    dispatch(setLoading(true));
    const method = AUTH_NUMBERS_GET_LIST;

    api(method, {filter: filters}).then( ({ auth_number_list = [] }) => {
        dispatch(setAuthNumbers(auth_number_list || []));
    })
};

export const getAuthNumberType = () => (dispatch) => {
    const method = AUTH_AUTH_NUMBER_TYPE_GET_LIST;

    api(method).then( ({ auth_number_type_list = [], auth_number_type_family_list = [] }) => {
        dispatch(setOtpServiceList({
            auth_number_type_list: auth_number_type_list || [], 
            auth_number_type_family_list: auth_number_type_family_list || []
        }));
    })
};

export const getRules = (page=1, per_page=DEFAULT_PER_PAGE) => (dispatch) => {
    dispatch(setRulesLoading(true));

    api(OTP_RULE_API_GET_LIST, {page, per_page}, false).then((response) => {
        if (response !== undefined) {
            dispatch( setRules({
                rule_list: response.otp_rule_list || []
            }) );
        }
    }).finally(() => {
        dispatch(setRulesLoading(false));
    });
};

export const createRule = (params) => (dispatch) => {
    dispatch(setRulesLoading(true));

    return api(OTP_RULE_API_CREATE, params).finally(() => {
        dispatch(setRulesLoading(false));
    });
};

export const modifyRule = (params) => (dispatch) => {
    dispatch(setRulesLoading(true));

    return api(OTP_RULE_API_MODIFY, params).finally(() => {
        dispatch(setRulesLoading(false));
    });
};

export const removeRule = (key) => (dispatch) => {
    dispatch(setRulesLoading(true));

    return api(OTP_RULE_API_REMOVE, {key}).finally(() => {
        dispatch(setRulesLoading(false));
    });
};


export const createNewAuthNumberType = (name) => async (dispatch) => {
    return await api(AUTH_AUTH_NUMBER_TYPE_CREATE_NEW, {name});
};

export const editAuthNumber = (numberId, formData) => async (dispatch) => {
    dispatch(setLoadingItem(true));
    return await api(AUTH_NUMBERS_EDIT, {an_key: numberId, number: formData.number, ant_key: formData.ant_key, spg_key: formData.spg_key, verified: formData.verified}).then( () => {
        setLoadingItem(false);
    })
};

export const deleteAuthNumber = (id) => async (dispatch) => {
    dispatch(setLoadingItem(true));
    return await api(AUTH_NUMBERS_DELETE, {an_key: id}).then( (r) => {
        setLoadingItem(false);
        return r;
    })
};

export const addAuthNumber = (params) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await api(AUTH_NUMBERS_ADD, params, true);
        if (response && response.auth_number)  {
            setLoading(false);
        }

        return response;
    } catch {
        dispatch(setLoading(false));
    }

};

export const addUploadAuthNumbers = (jsonData, onSuccess) => (dispatch) => {
    dispatch(setLoading(true));
    api(AUTH_NUMBERS_ADD_UPLOAD, {data: jsonData}).then( () => {
        onSuccess();
        setLoading(false);
    })
};

export const setAnyLoading = (payload) => (dispatch) => {
    dispatch(setLoading(payload))
};