import React, { useRef, useState, useEffect } from "react";
import { Form, InputGroup, FormGroup, Input, SelectPicker, CheckboxGroup, Schema } from "rsuite";
import styled, {css} from "styled-components";
import DateTimeRangePicker from "components/DateRangePicker/DateTimerRangePicker";
import CustomSelfClearedForm from "components/client/Form/CustomSelfClearedForm";
import { debounce } from "utils";
import CustomField from "components/Form/CustomField";
import { BaseCheckboxField } from "components/base/BaseForm";
import m from "definedMessages";

const {StringType} = Schema.Types;

const MAX_CHARACTERS = 40;


const TrafficFilter = ({
    isCdr,
    filter,
    disabled,
    issueWidth,
    isSmallMobile,
    defaultFilter,
    subAccountList,

    formatMessage,
    onChangeFilter
}) => {

    const [dateFilterOpened, setDateTimeOpened] = useState(false);

    const filtersModel = Schema.Model({
        filter_cli: StringType()
            .pattern(/^\d+$/, formatMessage(m.correctNumber))
            .maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
        filter_phone: StringType()
            .pattern(/^\d+$/, formatMessage(m.correctNumber))
            .maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS}))
    });

    const handleChangeDateTime = (value) => {
        const filterValue = {
            ...filter,
            range: [value.range[0], value.range[1]]
        }

        onChangeFilter(filterValue);
    };

    return <StyledForm
        clearOn={isCdr}
        model={filtersModel}
        onChange={debounce((value) => {
            const filterValue = {
                ...filter,
                ...value,

                range: filter?.range
            };

            onChangeFilter(filterValue);
        }, 500)}
        formDefaultValue={defaultFilter}
        fluid
        readOnly={disabled}
    >
        <StyledFormGroup issueWidth={issueWidth} isSmallMobile={isSmallMobile} dateOpen={dateFilterOpened}>
            <InputGroup inside>
                <DateTimeRangePicker
                    onChange={debounce((value) => {
                        handleChangeDateTime(value);
                    }, 500)}
                    defaultStartDate={defaultFilter?.range[0]}
                    defaultEndDate={defaultFilter?.range[1]}
                    startDate={filter.range[0]}
                    endDate={filter.range[1]}
                    width={270}
                    onOpen={() => {
                        setDateTimeOpened(true);
                    }}
                    onClose={() => {
                        setDateTimeOpened(false);
                    }}
                    // input={false}
                />

                <CustomField
                    accepter={Input}
                    name="filter_cli"
                    placeholder={formatMessage(m.aNumber)}
                    width={150}
                />

                <CustomField
                    accepter={Input}
                    name="filter_phone"
                    placeholder={formatMessage(m.bNumber)}
                    width={150}
                />

                <CustomField
                    disabled={disabled}
                    placeholder={formatMessage(m.subaccounts)}
                    accepter={SelectPicker}
                    data={subAccountList}
                    valueKey="id"
                    labelKey="name"
                    name="subacc_id"
                    errorPlacement="topRight"
                />

                {!isCdr && <CdrReportGroup
                    filter={filter}
                    disabled={disabled}
                    defaultFilter={defaultFilter}
                    formatMessage={formatMessage}
                    onChangeFilter={onChangeFilter}
                />}
            </InputGroup>
        </StyledFormGroup>
    </StyledForm>
};

export default TrafficFilter;


const CdrReportGroup = ({
    filter,
    disabled,
    defaultFilter,
    formatMessage,
    onChangeFilter
}) => {

    const [groupState, setGroupState] = useState(defaultFilter?.group_list);

    const groupItemList = [
        ["subacc", m.subaccount],
        ["cli", m.aNumber],
        ["phone", m.bNumber],
        ["name", m.ranges]
    ];

    const handleChangeGroupList = (value) => {
        const filterValue = {
            ...filter,
            group_list: value
        };

        onChangeFilter(filterValue);
    };

    return <CustomField
        name="group_list"
        accepter={CheckboxGroup}
        inline
        defaultValue={defaultFilter?.group_list}
        value={groupState}
        onChange={(value) => {
            setGroupState(value);
            handleChangeGroupList(value);
        }}
    >
        {groupItemList.map((item) => {
            const message = (typeof item[1]) === "string" ? item[1] : formatMessage(item[1]);

            return (<BaseCheckboxField
                key={item[0]}
                value={item[0]}
                defaultChecked
                disabled={disabled}
            >
                {message}
            </BaseCheckboxField>)
        })}
    </CustomField>
};

const StyledForm = styled(CustomSelfClearedForm)`
    && {
        /* margin-top: 20px; */

    }
`;


const StyledFormGroup = styled(FormGroup)`
    && {
        ${props => props.issueWidth ? css`
            display: block;
            padding-right: 0px;

            .rs-input {
                width: 100% !important;
                padding-right: 0px !important;
                margin-bottom: 15px;
            }

            .outsideContainer {
                width: 100%;
                margin-right: 0px;
            }

            .rs-input-group.rs-input-group-inside > *:not(:last-child) {
                margin-right: 0px;
                margin-bottom: 0px;
            }

            .rs-input-group { 
                width: auto !important;
                display: block;
                margin-right: 20px;
            }

            .dateTimeRangePickerContainer {
                width: props.isSmallMobile ? "100vw" : "500px";
            }

            .rdtPicker {
                display: props.dateOpen ? "inline-block" : "none";
                position: relative;
            }

        ` : css`
            display: flex;

            .rs-input-group.rs-input-group-inside .rs-input {
                padding-right: 11px;
            }

            .outsideContainer {
                margin-right: 20px;
            }

            .rs-input-group.rs-input-group-inside > *:not(:last-child) {
                margin-right: 20px;
                margin-bottom: 0;
            }

            .dateTimeRangePickerContainer {
                width: 100%;
            }

            .rdtPicker {
                display: props.dateOpen ? "block" : "none";
                position: absolute;
            }
        `}

        align-items: center;

        width: 100%;

        .rs-control-label {
            margin-right: 10px;
            margin-bottom: 0px;
        }

      
    }
`;