import {connect} from 'react-redux';
import Rules from './Rules';
import {createRule, getRules, modifyRule, removeRule} from '../../actions/auth_numbers';

const mapState = ({auth_numbers}) => ({
    data: auth_numbers.rule_list,
    count: auth_numbers.rule_count,
    loading: auth_numbers.loading
});

export default connect(mapState, {
    getRules,
    createRule,
    modifyRule,
    removeRule,
})(Rules);