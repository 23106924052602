import {connect} from 'react-redux';
import Search from './Search';
import {getSearch} from '../../actions/search';

const mapState = ({search}) => ({
    data: search.items,
    count: search.count,
    loading: search.loading
});

export default connect(mapState, {
    getSearch
})(Search);