import React, {useMemo} from "react";
import {ControlLabel, Form, FormGroup, Schema, Tooltip, Whisper} from "rsuite";
import * as S from "./styled";
import m from "../../../../definedMessages";
import {useIntl} from "react-intl";

const {StringType} = Schema.Types;
const MIN_CHARACTERS = 7;
const MAX_CHARACTERS = 15;


export default React.forwardRef(({
    formValue = {},
    allocationLimit,
    onChange,
    disabled = false,
    isMobile,
    rtl
}, ref) => {

    const {formatMessage} = useIntl();

    const formModel = useMemo(() => {

        const MAX_ROWS = allocationLimit 
            ? allocationLimit.once
            : null;

        return Schema.Model({
            number_list: StringType()
                .addRule((value) => {
                    const stringRowList = value.trim().split('\n');
                    for (const row of stringRowList) {
                        if (row.length > MAX_CHARACTERS || row.length < MIN_CHARACTERS) {
                            return false;
                        }
                    }
                    return true;
                }, formatMessage(m.eachRowShouldBeSize, {minNumber: MIN_CHARACTERS, maxNumber: MAX_CHARACTERS}))
                .addRule((value) => {
                    const stringRowList = value.trim().split('\n');
                    return !(stringRowList.length > MAX_ROWS);
                }, formatMessage(m.numberOfPhoneNumbersExceeds, {number: MAX_ROWS}))
                .pattern(/^(?: *\d+ *(?:\n|$))+$/, formatMessage(m.pleaseEnterValidNumbers))
                .isRequired(formatMessage(m.thisFieldIsRequired))
        });
    }, [allocationLimit]);

    return (
        <Form
            formValue={formValue}
            ref={ref}
            model={formModel}
            onChange={formValue => {
                onChange && onChange(formValue);
            }}
        >
            <FormGroup>
                <S.FormTitle as={ControlLabel} disabled={disabled}>
                    {formatMessage(m.putListOfOwnNumbers)}
                </S.FormTitle>

                <Whisper
                    placement={!isMobile ? (rtl ? "rightStart" : "leftStart") : 'auto'}
                    trigger="click"
                    speaker={
                        <Tooltip>
                            {formatMessage(m.eachPhoneNumberConditions, {
                                break: str => <br key="break"/>,
                                wrapt: str => <span key="wrapt">&lt;{str}&gt;</span>
                            })}
                        </Tooltip>
                    }
                >
                    <S.Field
                        name="number_list"
                        componentClass="textarea"
                        wrap="off"
                        errorPlacement={rtl ? "topStart" : "topEnd"}
                        disabled={disabled}
                    />
                </Whisper>
            </FormGroup>
        </Form>
    )
})