import React, {useState, useCallback, useMemo} from "react";
import {Icon} from "rsuite";

import {Spacer} from "../../components/base/Spacer";
import PageHeader from "../../components/base/PageHeader";
import PanelLayout from "../../components/base/PanelLayout";
import LangSwitcher from "../../components/LangSwitcher";
import {ButtonPrimary} from "../../components/base/BaseButton";

import NewsList from "./NewsList/NewsList";
import ModalCreatePost from "./ModalCreatePost";
import ModalRemovePost from "./ModalRemovePost";
import ModalDeactivatePost from "./ModalDeactivatePost";
import {getAccountManagersDropdownList} from "../../actions/account_managers";


const langs = [
    {lang_key: 'en', lang_name: 'English version'},
    {lang_key: 'ar', lang_name: 'Arabic version'}
];


export default (
    {
        data,
        count,
        loading,
        inited,
        clientRoleList,
        dialerGroupsList,
        accountManagerList,
        paymentTermsList,
        currencyList,
        servicePlanList,
        accounts,

        getAccountManagersDropdownList,
        getDropdownAccounts,
        getDialerGroupsList,
        getNews,
}
) => {

    const [activePostId, setActivePostId] = useState(null);
    const [activePostData, setActivePostData] = useState(null);
    const [modalPostFormShow, setModalPostFormShow] = useState(false);
    const [modalPostRemoveShow, setModalPostRemoveShow] = useState(false);
    const [modalPostDeactivateShow, setModalPostDeactivateShow] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('en');

    const accountManagerListFiltered = useMemo(() => accountManagerList.filter(item => item.active || item.has_accounts), [accountManagerList]);
    const accountsFiltered = useMemo(() => accounts.filter(item => item.is_managed), [accounts]);

    const handleClickPostEdit = useCallback( (value) => {
        const postData = data.find(item => item.id === value);
        if (!postData) {
            return;
        }

        const params = {
            news_id: postData.id,
            text: postData.text,
            text_arabic: postData.text_arabic || "",
            nwg_key_list: postData.nwg_key_list
                ? postData.nwg_key_list.map(item => item.nwg_key)
                : null,
            account_manager_id_list: postData.account_user_manager_list
                ? postData.account_user_manager_list.map(item => item.account_manager_id)
                : null,
            account_id_list: postData.account_list
                ? postData.account_list.map(item => item.account_id)
                : null,
            pt_key_list: postData.pt_key_list
                ? postData.pt_key_list.map(item => item.pt_key)
                : null,
            cur_key_list: postData.cur_key_list
                ? postData.cur_key_list.map(item => item.cur_key)
                : null,
            sp_key_list: postData.sp_key_list
                ? postData.sp_key_list.map(item => item.sp_key)
                : null,
            role_list: postData.role_list
                ? postData.role_list.length ? postData.role_list : null
                : null,
        };
        setActivePostData(params);
        setModalPostFormShow(true);
    }, [data]);

    const handleClickPostRemove = (value) => {
        setActivePostId(value);
        setModalPostRemoveShow(true);
    };

    const handleClickPostDeactivate = (value) => {
        setActivePostId(value);
        setModalPostDeactivateShow(true);
    };

    const roleList = clientRoleList.map((item) => ({name: item, value: item}));

    return (
        <PanelLayout>
            <PageHeader title="News management">
                <LangSwitcher
                    style={{marginRight: 35}}
                    value={selectedLanguage}
                    options={langs}
                    onChange={setSelectedLanguage}
                    renderValue={(_, item) => {
                        return <><Icon icon="globe" style={{marginRight: 7, color: 'var(--color-brand1)'}}/> {item.lang_name}</>
                    }}
                />
                <ButtonPrimary
                    onClick={() => setModalPostFormShow(true)}
                >
                    Create post
                </ButtonPrimary>
            </PageHeader>

            <ModalCreatePost
                activePostData={activePostData}
                show={modalPostFormShow}
                onUpdate={getNews}
                accountManagerList={accountManagerListFiltered}
                accounts={accountsFiltered}
                onClose={() => {
                    setActivePostData(null);
                    setModalPostFormShow(false);
                }}
                {...{
                    roleList,
                    paymentTermsList,
                    dialerGroupsList,
                    currencyList,
                    servicePlanList,

                    getAccountManagersDropdownList,
                    getDropdownAccounts,
                    getDialerGroupsList
                }}
            />

            <Spacer size={40} />

            <NewsList
                {...{
                    data,
                    count,
                    getNews,
                    loading,
                    inited,
                }}
                onClickEdit={handleClickPostEdit}
                onClickRemove={handleClickPostRemove}
                onClickDeactivate={handleClickPostDeactivate}
                lang={selectedLanguage}
            />

            <ModalRemovePost
                show={modalPostRemoveShow}
                userId={activePostId}
                onUpdate={getNews}
                onClose={() => {
                    setModalPostRemoveShow(false);
                    setActivePostId(null);
                }}
            />

            <ModalDeactivatePost
                show={modalPostDeactivateShow}
                userId={activePostId}
                onUpdate={getNews}
                onClose={() => {
                    setModalPostDeactivateShow(false);
                    setActivePostId(null);
                }}
            />
        </PanelLayout>
    )
};