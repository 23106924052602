const pythonExample = {
    options: {
        code: `
    import requests
    import json
    
    ADDRESS = '{your_url}'
    API_KEY = '{your_api_key}'
    URL = '{address}?api_key={api_key}'.format(address=ADDRESS, api_key=API_KEY)
    CSV_URL = '{address}/csv?api_key={api_key}'.format(address=ADDRESS, api_key=API_KEY)

    print(URL)
    print(CSV_URL)
        `,
        result: `
            {your_url}?api_key={your_api_key}\n
            {your_url}/csv?api_key={your_api_key}\n
        `
    },
    'live_call:get_list_by_account_user': {
        code: `
    #  Method - live_call:get_list_by_account_user
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
                'method': 'live_call:get_list_by_account_user',
                'params': {},
                'id': None
            })
        
    print(json.dumps(response.json(), intend=4))
        `,
        result: `
    {
        'jsonrpc': '2.0',
        'result': {
            'live_call_list': []
        },
        'id': null,
        'hash': '4NU0EP8W',
        'notices': []
    }
        `
    },
    'account:get': {
        code: `
    #  Method - account:get
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
                'method': 'account:get',
                'params': {},
                'id': None
            })
        
    print(json.dumps(response.json(), intend=4))
        `,
        result: `
    {
        'jsonrpc': '2.0', 
        'result': {
            'account': {
                'id': 'iusayG26RGmQ3XXXXXXXXX', 
                'NAME': 'NAME', 
                'name': 'NAME',
                'STATUS': 2, 
                'active': true,
                'BALANCE': 1000, 
                'cur_key': 1, 
                'deleted': false, 
                'is_test': false, 
                'currency': 'USD', 
                'is_dailer': true, 
                'created_at': '2020-01-14T11:18:49.903626', 
                'is_managed': true, 
                'BALANCE_INV': 2000, 
                'VOLUME_1_IN': 0.0, 
                'VOLUME_7_IN': 0.0, 
                'is_supplier': false, 
                'BALANCE_INV2': 3000, 
                'VOLUME_1_OUT': 20, 
                'VOLUME_30_IN': 0.0, 
                'VOLUME_7_OUT': 2000, 
                'allow_hawala': null, 
                'VOLUME_30_OUT': 10000,
                'currency_name': 'USD', 
                'payment_terms': '30_45', 
                'account_manager': {}
                'account_options': {
                    'pause_payouts': false, 
                    'auto_accept_our_stat': true
                }, 
                'allocation_limit': {}, 
                'account_user_list': [], 
                'allocated_numbers': 100000, 
                'call_duration_max': 3600, 
                'trunk_notificaton': 'nothing', 
                'account_manager_id': 'XF5UplyjSzSilXXXXXXXXX', 
                'not_verified_users': 0, 
                'payment_terms_name': '30_45', 
                'account_contact_list': [], 
                'account_manager_name': 'MANAGER', 
                'numbers_with_traffic': 1569, 
                'account_manager_email': 'manager.test@test.com', 
                'available_for_payment': 20000
            }
        }, 
        'id': null, 
        'hash': 'PC271PYQ', 
        'notices': []
    }
        `
    },
    'cdr_full:get_list': {
        code: `
    #  Method - cdr_full:get_list
    response = requests.post(
        url={url},
        json={
            'jsonrpc': '2.0',
            'method': 'cdr_full:get_list',
            'params': {
                'page': 1,
                'per_page': 1
            },
            'id': None
        })
    
    {output}
        `,
        result: `
    {
        'jsonrpc': '2.0',
        'result': {
            'cdr_full_list': [
                {
                    'ip': '167.86.92.33',
                    'port': 5060,
                    'range': 'United States - OffNet 16417939XXX',
                    'a_number': '6473912XXX',
                    'b_number': '16417939XXX',
                    'datetime': '2021-01-13 13:32:44.184',
                    'duration': '00:00:04',
                    'a_sde_key': 1387,
                    'b_sde_key': 3792,
                    'sip_cause': 16,
                    'cost_dialer': 0.0001,
                    'rate_dialer': 0.0015,
                    'tprefix_dialer': '',
                    'a_subdestination_name': 'New Zealand - Fixed',
                    'b_subdestination_name': 'United States - OffNet'
                }
            ],
            'cdr_full_count': 125022,
            'cdr_full_summaries': {
                'cost': 10335.44896,
                'duration': 275574.87
            }
        },
        'id': null,
        'hash': '8366ZECC',
        'notices': null
    }
        `,
        result_csv: `
        Datetime;A-number;Originated from;B-number;Destination;Range;Duration;Rate;Cost;Term.Point;SIP cause
        2020-10-01 02:07:26.49;197134XXXXX;United States - OnNet;332XXXXX;Guatemala - Mobile - Comcel (Tigo);Guatemala - Mobile - Comcel (Tigo) 50233;00:00:28;0.04000;0.01867;10.254.1.10:5060 00002754#;16
        `
    },
    'cdr_full:group_get_list': {
        code: `
    #  Method - cdr_full:group_get_list
    response = requests.post(
        url={url},
        json={
            'jsonrpc': '2.0',
            'method': 'cdr_full:group_get_list',
            'params': {
                'page': 1,
                'per_page': 10
            },
            'id': None
        })
    
    {output}
        `,
        result: `
       {
        'jsonrpc': '2.0',
        'result': {
            'group_list': [
                {
                    'group': null,
                    'range': null,
                    'a_number': null,
                    'b_number': null,
                    'sum_duration': 275574.87,
                    'sum_cost_dialer': 10335.44896,
                    'count_cost_dialer': 124030
                }
            ],
            'group_count': 1,
            'group_summaries': {
                'cost': 10335.44896,
                'calls': 124030,
                'duration': 275574.87
            }
        },
        'id': null,
        'hash': '8A16XUEE',
        'notices': []
    }
        `,
        result_csv: `
        Duration;Calls;Cost
        275574.87;124030;10335.44896
        `
    },
    'allocation:price_range': {
        code: `
    #  Method - allocation:price_range
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'allocation:price_range',
            'params': {
                'target': {
                    'trunk_id': 'z4n4MNcxQjeccXXXXXXXXX'
                },
                'pr_key': 208086,
                'numbers': 1
            },
            'id': None
        })
    
    print(json.dumps(response.json(), intend=4))
        `,
        result: `
    {
        'jsonrpc': '2.0',
        'result': {
        'price_range_numbers': 5,
            'price_range_number_list': [
            {
                'rate': 0.1,
                'number': '79402214012',
                'status': 0,
                'prn_key': 14413116,
                'trn_name': 'Abkhazia - Mobile 79402214XXX',
                'trunk_name': 'Hi Class (ATX)/STD_NEW 0.10000 USD',
                'account_name': 'Hi Class (ATX)',
                'currency_name': 'USD',
                'numbers_count': 1
            }
        ]
    },
        'id': null,
        'hash': '0LX9H8D9'
    }
        `
    },
    'allocation:number_list_by_account_user': {
        code: `
    #  Method - allocation:number_list_by_account_user
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'allocation:number_list_by_account_user',
            'params': {
                'target': {
                    'trunk_id': 'z4n4MNcxQjeccXXXXXXXXX'
                },
                'number_list': [
                    '63433XXXXX'
                ]
            },
            'id': None
        })
    
    print(json.dumps(response.json(), intend=4))
        `,
        result: `
    {
        'jsonrpc': '2.0',
        'result': {
            'reason_code': null,
            'reason_list': {
                'OK': 1
            },
            'not_allocated_numbers': [],
            'trunk_number_transaction': {
                'id': 'WRPudQvuQuOoUlLMgkWOrw',
                'date': '2021-07-20 09:12:14.81626',
                'type': 0,
                'delay': 0.484919,
                'method': 'allocation:number_list_by_account_user',
                'ranges': 1,
                'method2': 'number_list',
                'numbers': 1,
                'prefixes': 1,
                'trunk_id': 'z4n4MNcxQjeccjhTt1-mUA',
                'account_id': 'iusayG26RGmQ3WIwnvRcvQ',
                'trunk_name': 'test_trunk_45345345',
                'reason_code': null,
                'account_name': 'Hi Class (ATX)',
                'account_user_id': null,
                'account_user_name': null,
                'account_user_email': null
            }
        },
        'id': null,
        'hash': 'OL6XNFEA',
        'notices': []
    }
        `
    },
    'allocation:template_by_account_user': {
        code: `
    #  Method - allocation:template_by_account_user
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'allocation:template_by_account_user',
            'params': {
                'target': {
                    'trunk_id': 'z4n4MNcxQjeccXXXXXXXXX'
                },
                'template': '39453453X',
                'numbers': 1
            },
            'id': None
        })
    
    print(json.dumps(response.json(), intend=4))
        `,
        result: `
    {
        'jsonrpc': '2.0',
        'result': {
            'reason_code': null,
            'reason_list': {
                'OK': 1
            },
            'not_allocated_numbers': null,
            'trunk_number_transaction': {
                'id': 'M-sil_cxSie-J9dszFv38A',
                'date': '2021-07-20 09:47:16.487013',
                'type': 0,
                'delay': 0.337209,
                'method': 'allocation:template_by_account_user',
                'ranges': 1,
                'method2': 'template',
                'numbers': 1,
                'prefixes': 1,
                'trunk_id': 'z4n4MNcxQjeccXXXXXXXXX',
                'account_id': 'iusayG26RGmQ3XXXXXXXXX',
                'trunk_name': 'test_trunk_45345345',
                'reason_code': null,
                'account_name': 'Hi Class (ATX)',
                'account_user_id': null,
                'account_user_name': null,
                'account_user_email': null
            }
        },
        'id': null,
        'hash': 'FLYMR4WU',
        'notices': []
    }
        `
    },
    'allocation:subdestination_by_account_user': {
        code: `
    #  Method - allocation:subdestination_by_account_user
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'allocation:subdestination_by_account_user',
            'params': {
                'target': {
                    'trunk_id': 'z4n4MNcxQjeccXXXXXXXXX'
                },
                'sde_key': 2119,
                'numbers': 1,
            },
            'id': None
        })
    
    print(json.dumps(response.json(), intend=4))
        `,
        result: `
    {
        'jsonrpc': '2.0',
        'result': {
            'reason_code': null,
            'reason_list': {
                'OK': 1
            },
            'not_allocated_numbers': null,
            'trunk_number_transaction': {
                'id': 'xdhbgUPJR1yfMcA3JDcPNg',
                'date': '2021-07-20 09:50:58.961998',
                'type': 0,
                'delay': 0.276943,
                'method': 'allocation:subdestination_by_account_user',
                'ranges': 1,
                'method2': 'subdestination',
                'numbers': 1,
                'prefixes': 1,
                'trunk_id': 'z4n4MNcxQjeccXXXXXXXXX',
                'account_id': 'iusayG26RGmQ3XXXXXXXXX',
                'trunk_name': 'test_trunk_45345345',
                'reason_code': null,
                'account_name': 'Hi Class (ATX)',
                'account_user_id': null,
                'account_user_name': null,
                'account_user_email': null
            }
        },
        'id': null,
        'hash': 'B7P9G1XO',
        'notices': []
    }
        `
    },
    'allocation:google_otp_by_account_user': {
        code: `
    # Method - allocation:google_otp_by_account_user
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'allocation:google_otp_by_account_user',
            'params': {
                'target': {
                    'trunk_id': 'dMmSLTIsRByLNFEDZ-p0sg'
                },
                'template_list': ['4562234211XX'],
                'numbers': 100
            },
            'id': None
        })
    
    print(json.dumps(response.json(), intend=4))
        `,
        result: `
    {
        'jsonrpc': '2.0', 
        'result': {
            'reason_code': null, 
            'reason_list': {
                'OK': 1
            }, 
            'not_allocated_numbers': null, 
            'trunk_number_transaction': {
                'id': 'w31yfC3bSVauxbS1IjwOMA', 
                'date': '2021-07-20 10:01:13.461356', 
                'type': 0, 
                'delay': 0.27423, 
                'method': 'allocation:google_otp_by_account_user', 
                'ranges': 1, 
                'method2': 'google_otp', 
                'numbers': 100, 
                'prefixes': 1, 
                'trunk_id': 'dMmSLTIsRByLNXXXXXXXXX', 
                'account_id': 'iusayG26RGmQ3XXXXXXXXX', 
                'trunk_name': 'testGoogleOTP', 
                'reason_code': null, 
                'account_name': 'Hi Class (ATX)', 
                'account_user_id': null, 
                'account_user_name': null, 
                'account_user_email': null
            }
        }, 
        'id': null, 
        'hash': '3AC54RR1', 
        'notices': []
    }
        `
    },
    'allocation:incorporated': {
        code: `
    # Method - allocation:incorporated
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'allocation:incorporated',
            'params': {
                'target': {
                    'trunk_id': 'XhlNfPhBRLebgXXXXXXXXX',
                },
                'ing_key': 92,
                'numbers': 1,
                'random_number': False
            },
            'id': None
        })

    print(json.dumps(response.json(), intend=4))
    `,
        result: `
    {
        'jsonrpc': '2.0', 
        'result': {
            'reason_code': null, 
            'reason_list': [], 
            'not_allocated_numbers': [], 
            'trunk_number_transaction': {
                'id': 'iyqnYy4oREOKS8GP58bOeg', 
                'date': '2021-07-27 11:15:13.24853', 
                'type': 0, 
                'delay': null, 
                'method': 'allocation:incorporated', 
                'ranges': 1, 
                'method2': 'allocate', 
                'numbers': 1, 
                'prefixes': null, 
                'trunk_id': 'XhlNfPhBRLebgXXXXXXXXX', 
                'account_id': 'iusayG26RGmQ3XXXXXXXXX', 
                'trunk_name': 'INCORP', 
                'reason_code': null, 
                'account_name': 'Hi Class (ATX)', 
                'account_user_id': null, 
                'account_user_name': null, 
                'account_user_email': null
            }
        }, 
        'id': null, 
        'hash': 'FKU500ZV', 
        'notices': []
    }
    `
    },
    'access_list__get_list:account_price': {
        code: `
    # Method - access_list__get_list:account_price
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'access_list__get_list:account_price',
            'params': {
                'filter': {
                    'sp_key_list': [8],
                    'str': ''
                },
                'page': 1,
                'per_page': 1
            },
            'id': None
        })
    
    {output}
        `,
        result: `
    {
      "jsonrpc": "2.0", 
      "result": {
        "access_list_list": [
          {
            "sp_key": 8, 
            "comment": null, 
            "ing_key": 98, 
            "sde_key": 1309, 
            "a_number": "", 
            "b_number": "", 
            "a_description": "Global access", 
            "b_description": "000test0", 
            "service_plan_name": "Global Access", 
            "b_test_number_list": [
              "573835740008"
            ], 
            "subdestination_name": "Colombia - Mobile", 
            "payment_terms_rate_list": [
              {
                "rate": 0.5, 
                "pt_key": 27, 
                "cur_key": 1, 
                "currency_name": "USD", 
                "payment_terms_name": "30/45"
              }
            ]
          }
        ], 
        "access_list_count": 10000
      }, 
      "id": null, 
      "hash": "B4LDBSNT", 
      "notices": []
    }
        `,
        result_csv: `
    Price;Access origin;CLI;Access destination;Test number;Rate;Currency
    Global Access;Global access;;000test0;573835XXXXXX;0.50000;USD
    Global Access;Global access;;test_client_3;52623XXXXXX;2.50000;USD
        `
    },
    'ratecard_incorporated:get_account_price': {
        code: `
    # Method - ratecard_incorporated:get_account_price
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'ratecard_incorporated:get_account_price',
            'params': {
                'filter': {
                    'sp_key': 8,
                    'str': ''
                },
                'page': 1,
                'per_page': 15,
                'sort1': 'incorporated_group_name'
            },
            'id': None
        })
    
    print(json.dumps(response.json(), intend=4))
        `,
        result: `
    {
      "jsonrpc": "2.0", 
      "result": {
        "ratecard_incorporated_list": [
          {
            "sp_key": 8, 
            "ing_key": 98, 
            "sde_key": 1309, 
            "supplier_comment": null, 
            "available_numbers": 4, 
            "subdestination_name": "Colombia - Mobile", 
            "test_trunk_number_list": [
              "573835XXXXXX"
            ], 
            "incorporated_group_name": "000test0", 
            "payment_terms_rate_list": [
              {
                "rate": 0.5, 
                "pt_key": 27, 
                "currency_name": "USD", 
                "payment_terms_name": "30/45"
              }
            ]
          }, 
          {
            "sp_key": 8, 
            "ing_key": 93, 
            "sde_key": 3469, 
            "supplier_comment": "test comment 2", 
            "available_numbers": 3, 
            "subdestination_name": "Mexico - Fixed - Equal Access", 
            "test_trunk_number_list": [
              "52623XXXXXX"
            ], 
            "incorporated_group_name": "test_client_3", 
            "payment_terms_rate_list": [
              {
                "rate": 2.5, 
                "pt_key": 27, 
                "currency_name": "USD", 
                "payment_terms_name": "30/45"
              }
            ]
          }
        ], 
        "ratecard_incorporated_count": 2
      }, 
      "id": null, 
      "hash": "I5XYDFJH", 
      "notices": []
    }
        `,
        result_csv: `
    Destination name;Group name;Test number;Payout
    Colombia - Mobile;000test0;573835XXXXXX;0.50000
    Mexico - Fixed - Equal Access;test_client_3;52623XXXXXX;2.50000
        `
    }
};

const jsExample = {
    options: {
        code: `
    const address = '{your_url}';
    const apiKey = '{your_api_key}';
    const url = \`\${address}?api_key=\${apiKey}\`;
    const csvUrl = \`\${address}/csv?api_key=\${apiKey}\`;
    
    console.log(url);
    console.log(csvUrl);
        `,
        result: `
            {your_url}?api_key={your_api_key}\n
            {your_url}/csv?api_key={your_api_key}\n
        `
    },
    'live_call:get_list_by_account_user': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'live_call:get_list_by_account_user',
        params: {},
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        'jsonrpc': '2.0',
        'result': {
            'live_call_list': []
        },
        'id': null,
        'hash': '5H4QAG1V'
    }
        `
    },
    'account:get': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'account:get',
        params: {},
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        'jsonrpc': '2.0', 
        'result': {
            'account': {
                'id': 'iusayG26RGmQ3XXXXXXXXX', 
                'NAME': 'NAME', 
                'name': 'NAME',
                'STATUS': 2, 
                'active': true,
                'BALANCE': 1000, 
                'cur_key': 1, 
                'deleted': false, 
                'is_test': false, 
                'currency': 'USD', 
                'is_dailer': true, 
                'created_at': '2020-01-14T11:18:49.903626', 
                'is_managed': true, 
                'BALANCE_INV': 2000, 
                'VOLUME_1_IN': 0.0, 
                'VOLUME_7_IN': 0.0, 
                'is_supplier': false, 
                'BALANCE_INV2': 3000, 
                'VOLUME_1_OUT': 20, 
                'VOLUME_30_IN': 0.0, 
                'VOLUME_7_OUT': 2000, 
                'allow_hawala': null, 
                'VOLUME_30_OUT': 10000,
                'currency_name': 'USD', 
                'payment_terms': '30_45', 
                'account_manager': {}
                'account_options': {
                    'pause_payouts': false, 
                    'auto_accept_our_stat': true
                }, 
                'allocation_limit': {}, 
                'account_user_list': [], 
                'allocated_numbers': 100000, 
                'call_duration_max': 3600, 
                'trunk_notificaton': 'nothing', 
                'account_manager_id': 'XF5UplyjSzSilXXXXXXXXX', 
                'not_verified_users': 0, 
                'payment_terms_name': '30_45', 
                'account_contact_list': [], 
                'account_manager_name': 'MANAGER', 
                'numbers_with_traffic': 1569, 
                'account_manager_email': 'manager.test@test.com', 
                'available_for_payment': 20000
            }
        }, 
        'id': null, 
        'hash': 'PC271PYQ', 
        'notices': []
    }
        `
    },
    'cdr_full:get_list': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'cdr_full:get_list',
        params: {
            page: 1,
            per_page: 1
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        'jsonrpc': '2.0',
        'result': {
            'cdr_full_list': [
                {
                    'ip': '167.86.92.33',
                    'port': 5060,
                    'range': 'United States - OffNet 16417939XXX',
                    'a_number': '6473912XXX',
                    'b_number': '16417939XXX',
                    'datetime': '2021-01-13 13:32:44.184',
                    'duration': '00:00:04',
                    'a_sde_key': 1387,
                    'b_sde_key': 3792,
                    'sip_cause': 16,
                    'cost_dialer': 0.0001,
                    'rate_dialer': 0.0015,
                    'tprefix_dialer': '',
                    'a_subdestination_name': 'New Zealand - Fixed',
                    'b_subdestination_name': 'United States - OffNet'
                }
            ],
            'cdr_full_count': 125022,
            'cdr_full_summaries': {
                'cost': 10335.44896,
                'duration': 275574.87
            }
        },
        'id': null,
        'hash': '8366ZECC',
        'notices': null
    }
        `,
        result_csv: `
        Datetime;A-number;Originated from;B-number;Destination;Range;Duration;Rate;Cost;Term.Point;SIP cause
        2020-10-01 02:07:26.49;197134XXXXX;United States - OnNet;332XXXXX;Guatemala - Mobile - Comcel (Tigo);Guatemala - Mobile - Comcel (Tigo) 50233;00:00:28;0.04000;0.01867;10.254.1.10:5060 00002754#;16
        `
    },
    'cdr_full:group_get_list': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'cdr_full:group_get_list',
        params: {
            page: 1,
            per_page: 1
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
       {
        'jsonrpc': '2.0',
        'result': {
            'group_list': [
                {
                    'group': null,
                    'range': null,
                    'a_number': null,
                    'b_number': null,
                    'sum_duration': 275574.87,
                    'sum_cost_dialer': 10335.44896,
                    'count_cost_dialer': 124030
                }
            ],
            'group_count': 1,
            'group_summaries': {
                'cost': 10335.44896,
                'calls': 124030,
                'duration': 275574.87
            }
        },
        'id': null,
        'hash': '8A16XUEE',
        'notices': []
    }
        `,
        result_csv: `
        Duration;Calls;Cost
        275574.87;124030;10335.44896
        `
    },
    'access_list__get_list:account_price': {
    code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'access_list__get_list:account_price',
        params: {
            filter: {
                sp_key_list: [8],
                str: ''
            },
            pag: 1,
            per_page: 1
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
      "jsonrpc": "2.0", 
      "result": {
        "access_list_list": [
          {
            "sp_key": 8, 
            "comment": null, 
            "ing_key": 98, 
            "sde_key": 1309, 
            "a_number": "", 
            "b_number": "", 
            "a_description": "Global access", 
            "b_description": "000test0", 
            "service_plan_name": "Global Access", 
            "b_test_number_list": [
              "573835740008"
            ], 
            "subdestination_name": "Colombia - Mobile", 
            "payment_terms_rate_list": [
              {
                "rate": 0.5, 
                "pt_key": 27, 
                "cur_key": 1, 
                "currency_name": "USD", 
                "payment_terms_name": "30/45"
              }
            ]
          }
        ], 
        "access_list_count": 10000
      }, 
      "id": null, 
      "hash": "B4LDBSNT", 
      "notices": []
    }
        `,
        result_csv: `
    Price;Access origin;CLI;Access destination;Test number;Rate;Currency
    Global Access;Global access;;000test0;573835XXXXXX;0.50000;USD
    Global Access;Global access;;test_client_3;52623XXXXXX;2.50000;USD
        `
},
    'ratecard_incorporated:get_account_price': {
    code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'ratecard_incorporated:get_account_price',
        params: {
            filter: {
                sp_key: 8,
                str: ''
            },
            page: 1,
            per_page: 15,
            sort1: 'incorporated_group_name'
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
      "jsonrpc": "2.0", 
      "result": {
        "ratecard_incorporated_list": [
          {
            "sp_key": 8, 
            "ing_key": 98, 
            "sde_key": 1309, 
            "supplier_comment": null, 
            "available_numbers": 4, 
            "subdestination_name": "Colombia - Mobile", 
            "test_trunk_number_list": [
              "573835740008"
            ], 
            "incorporated_group_name": "000test0", 
            "payment_terms_rate_list": [
              {
                "rate": 0.5, 
                "pt_key": 27, 
                "currency_name": "USD", 
                "payment_terms_name": "30/45"
              }
            ]
          }, 
          {
            "sp_key": 8, 
            "ing_key": 93, 
            "sde_key": 3469, 
            "supplier_comment": "test comment 2", 
            "available_numbers": 3, 
            "subdestination_name": "Mexico - Fixed - Equal Access", 
            "test_trunk_number_list": [
              "52623421131"
            ], 
            "incorporated_group_name": "test_client_3", 
            "payment_terms_rate_list": [
              {
                "rate": 2.5, 
                "pt_key": 27, 
                "currency_name": "USD", 
                "payment_terms_name": "30/45"
              }
            ]
          }
        ], 
        "ratecard_incorporated_count": 2
      }, 
      "id": null, 
      "hash": "I5XYDFJH", 
      "notices": [
        "NOTICE:  bl_account_price_actual__generate>>(p_acc_key=>417, p_force=>false)"
      ]
    }
        `,
        result_csv:
    `
    Destination name;Group name;Test number;Payout
    Colombia - Mobile;000test0;573835740008;0.50000
    Mexico - Fixed - Equal Access;test_client_3;52623421131;2.50000
    `
    },
    'allocation:price_range': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'allocation:price_range',
        params: {
            page: 1,
            per_page: 1
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        'jsonrpc': '2.0',
        'result': {
        'price_range_numbers': 5,
            'price_range_number_list': [
            {
                'rate': 0.1,
                'number': '79402214012',
                'status': 0,
                'prn_key': 14413116,
                'trn_name': 'Abkhazia - Mobile 79402214XXX',
                'trunk_name': 'Hi Class (ATX)/STD_NEW 0.10000 USD',
                'account_name': 'Hi Class (ATX)',
                'currency_name': 'USD',
                'numbers_count': 1
            }
        ]
    },
        'id': null,
        'hash': '0LX9H8D9'
    }
        `
    },
    'allocation:number_list_by_account_user': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'allocation:number_list_by_account_user',
        params: {
            target: {
                trunk_id: 'z4n4MNcxQjeccjhTt1-mUA'
            },
            number_list: ['6343342221']
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        'jsonrpc': '2.0',
        'result': {
            'reason_code': null,
            'reason_list': {
                'OK': 1
            },
            'not_allocated_numbers': [],
            'trunk_number_transaction': {
                'id': 'WRPudQvuQuOoUlLMgkWOrw',
                'date': '2021-07-20 09:12:14.81626',
                'type': 0,
                'delay': 0.484919,
                'method': 'allocation:number_list_by_account_user',
                'ranges': 1,
                'method2': 'number_list',
                'numbers': 1,
                'prefixes': 1,
                'trunk_id': 'z4n4MNcxQjeccjhTt1-mUA',
                'account_id': 'iusayG26RGmQ3WIwnvRcvQ',
                'trunk_name': 'test_trunk_45345345',
                'reason_code': null,
                'account_name': 'Hi Class (ATX)',
                'account_user_id': null,
                'account_user_name': null,
                'account_user_email': null
            }
        },
        'id': null,
        'hash': 'OL6XNFEA',
        'notices': []
    }
        `
    },
    'allocation:template_by_account_user': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'allocation:template_by_account_user',
        params: {
            target: {
                trunk_id: 'z4n4MNcxQjeccjhTt1-mUA'
            },
            template: '39452433X',
            numbers: 1
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        'jsonrpc': '2.0',
        'result': {
            'reason_code': null,
            'reason_list': {
                'OK': 1
            },
            'not_allocated_numbers': null,
            'trunk_number_transaction': {
                'id': 'M-sil_cxSie-J9dszFv38A',
                'date': '2021-07-20 09:47:16.487013',
                'type': 0,
                'delay': 0.337209,
                'method': 'allocation:template_by_account_user',
                'ranges': 1,
                'method2': 'template',
                'numbers': 1,
                'prefixes': 1,
                'trunk_id': 'z4n4MNcxQjeccXXXXXXXXX',
                'account_id': 'iusayG26RGmQ3XXXXXXXXX',
                'trunk_name': 'test_trunk_45345345',
                'reason_code': null,
                'account_name': 'Hi Class (ATX)',
                'account_user_id': null,
                'account_user_name': null,
                'account_user_email': null
            }
        },
        'id': null,
        'hash': 'FLYMR4WU',
        'notices': []
    }
        `
    },
    'allocation:subdestination_by_account_user': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'allocation:subdestination_by_account_user',
        params: {
            target: {
                trunk_id: 'z4n4MNcxQjeccjhTt1-mUA'
            },
            sde_key: 2119,
            numbers': 1,
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        'jsonrpc': '2.0',
        'result': {
            'reason_code': null,
            'reason_list': {
                'OK': 1
            },
            'not_allocated_numbers': null,
            'trunk_number_transaction': {
                'id': 'xdhbgUPJR1yfMcA3JDcPNg',
                'date': '2021-07-20 09:50:58.961998',
                'type': 0,
                'delay': 0.276943,
                'method': 'allocation:subdestination_by_account_user',
                'ranges': 1,
                'method2': 'subdestination',
                'numbers': 1,
                'prefixes': 1,
                'trunk_id': 'z4n4MNcxQjeccXXXXXXXXX',
                'account_id': 'iusayG26RGmQ3XXXXXXXXX',
                'trunk_name': 'test_trunk_45345345',
                'reason_code': null,
                'account_name': 'Hi Class (ATX)',
                'account_user_id': null,
                'account_user_name': null,
                'account_user_email': null
            }
        },
        'id': null,
        'hash': 'B7P9G1XO',
        'notices': []
    }
        `
    },
    'allocation:google_otp_by_account_user': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'allocation:google_otp_by_account_user',
        params: {
            target: {
                trunk_id: 'dMmSLTIsRByLNFEDZ-p0sg'
            },
            template_list: ['4562234211XX'],
            numbers: 100
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        'jsonrpc': '2.0', 
        'result': {
            'reason_code': null, 
            'reason_list': {
                'OK': 1
            }, 
            'not_allocated_numbers': null, 
            'trunk_number_transaction': {
                'id': 'w31yfC3bSVauxbS1IjwOMA', 
                'date': '2021-07-20 10:01:13.461356', 
                'type': 0, 
                'delay': 0.27423, 
                'method': 'allocation:google_otp_by_account_user', 
                'ranges': 1, 
                'method2': 'google_otp', 
                'numbers': 100, 
                'prefixes': 1, 
                'trunk_id': 'dMmSLTIsRByLNXXXXXXXXX', 
                'account_id': 'iusayG26RGmQ3XXXXXXXXX', 
                'trunk_name': 'testGoogleOTP', 
                'reason_code': null, 
                'account_name': 'Hi Class (ATX)', 
                'account_user_id': null, 
                'account_user_name': null, 
                'account_user_email': null
            }
        }, 
        'id': null, 
        'hash': '3AC54RR1', 
        'notices': []
    }
        `
    },
    'allocation:incorporated': {
    code: `

    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'allocation:incorporated',
        params: {
            target: {
                trunk_id: 'XhlNfPhBRLebgXXXXXXXXX',
            },
            ing_key: 92,
            numbers: 1,
            random_number: False
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
    `,
        result: `
    {
        'jsonrpc': '2.0', 
        'result': {
            'reason_code': null, 
            'reason_list': [], 
            'not_allocated_numbers': [], 
            'trunk_number_transaction': {
                'id': 'iyqnYy4oREOKS8GP58bOeg', 
                'date': '2021-07-27 11:15:13.24853', 
                'type': 0, 
                'delay': null, 
                'method': 'allocation:incorporated', 
                'ranges': 1, 
                'method2': 'allocate', 
                'numbers': 1, 
                'prefixes': null, 
                'trunk_id': 'XhlNfPhBRLebgXXXXXXXXX', 
                'account_id': 'iusayG26RGmQ3XXXXXXXXX', 
                'trunk_name': 'INCORP', 
                'reason_code': null, 
                'account_name': 'Hi Class (ATX)', 
                'account_user_id': null, 
                'account_user_name': null, 
                'account_user_email': null
            }
        }, 
        'id': null, 
        'hash': 'FKU500ZV', 
        'notices': []
    }
    `
    }
};
export {pythonExample, jsExample};