import styled, {css} from 'styled-components';
import {Button} from 'rsuite';

export const StyledTableActions = styled.div`
    margin-top: -3px;

    .rtl & {
        direction: rtl;
    }

    ${props =>
    props.alignEnd && css`
            text-align: right;
        
            .rtl & {
                text-align: left;
            }
        `
}
`;

export const StyledTableButton = styled(Button).attrs(props => ({
    appearance: "primary",
    size: "sm"
}))`
    && {
        line-height: 25px;
        height: 25px;
        margin-right: 10px;
        padding: 0 7px;

        &:last-child {
            margin-right: 0;
        }

        .rtl & {
            margin-right: 0;
            margin-left: 10px;

            &:last-child {
                margin-left: 0;
            }
        }
    }

    ${props => props.color && props.color === 'green' && css`
        &&& {
            background-color: #20BA88 !important;
        }
    `}

    ${props => props.color && props.color === 'red' && css`
        &&& {
            background-color: #F8432F !important;
        }
    `}
`;