import {connect} from "react-redux";
import {
    getSupplierAccountTrunk, setCurrentTrunkId, setCurrentRangeNumber,
    setAllocationRestrictions, setEmptyAccountTrunk
} from "actions/accounts";
import {
    getSupplierPrefixes
} from "actions/suppliers";
import {getAccountServicePlanList} from "actions/self_allocation_restrictions";
import {getTrunkNumberCsvById} from "actions/numbers";
import TabTransitTrunks from "./TabTransitTrunks";
import {trunkDownloadNumbers, unsetTrunksInited} from "actions";
import {
    getSupplierPlanList, 
    createSupplierPlan,
    modifySupplierPlan,
    removeSupplierPlan,
    createTrafficLimit,
    modifyTrafficLimit,
    removeTrafficLimit,
    setEmptyPrefixes,
    getSupplierPrefixesDropdown
} from "actions/suppliers";



const mapState = ({ auth, accounts, suppliers, references, self_allocation_restrictions}) => ({
    authInfo: auth.authInfo,
    account: accounts.accountView,
    currentSupplier: suppliers.currentSupplier,
    trunks: accounts.accountSupplierTransitTrunks,
    trunksInited: accounts.trunksInited,
    trunksLoading: accounts.accountTrunkLoading,
    ipOptionList: references["sms.supplier_trunk_ip.option_list"],
    allocatedNumbersCount: suppliers.suppliersPrefixListCount,
    allocatedNumbersTotalCount: accounts.accountAllocatedNumbersTotalCount,
    allocatedNumbersPage: suppliers.suppliersPrefixListPage,
    allocatedNumbersPerPage: suppliers.suppliersPrefixListPerPage,
    allocatedNumbersLoading: accounts.accountAllocatedLoading,
    worldzone_list: references.worldzone_list,
    subdestination_list: references.subdestination_list,
    destination_list: references.destination_list,
    currentTrunkId: accounts.currentTrunkId,
    currentRangeNumber: accounts.currentRangeNumber,
    accountServicePlanList: self_allocation_restrictions.list,
    raterc_list: references.raterc_list,
    paymentTermsList: references.payment_terms_supplier_list,
    rerouteStatusList: references["sms.reroute_status_list"],
    globalSettings: references.global_setting,
    suppliersPlanList: suppliers.suppliersPlanList,
    suppliersPrefixList: suppliers.suppliersPrefixList,
    suppliersPrefixListСount: suppliers.suppliersPrefixListCount,
    suppliersPrefixListPage: suppliers.suppliersPrefixListPage,
    suppliersPrefixListPerPage: suppliers.suppliersPrefixListPerPage,
    suppliersPrefixLoading: suppliers.suppliersPrefixLoading,
    suppliersPrefixDropdownList: suppliers.suppliersPrefixDropdownList,
});


export default connect(mapState, {
    setCurrentTrunkId,
    unsetTrunksInited,
    setCurrentRangeNumber,
    getAccountTrunk: getSupplierAccountTrunk,
    setEmptyAccountTrunk,
    getSupplierPrefixes,
    
    getSupplierPlanList,
    createSupplierPlan,
    modifySupplierPlan,
    removeSupplierPlan,

    createTrafficLimit,
    modifyTrafficLimit,
    removeTrafficLimit,

    setEmptyPrefixes,

    getTrunkNumberCsvById,
    getAccountServicePlanList,
    getSupplierPrefixesDropdown,
    trunkDownloadNumbers,
})(TabTransitTrunks);
  
