import { connect } from "react-redux";
import {getSuppliers, createSupplier, setNewCreatedAccountId} from "../actions/suppliers";
import CreateSupplier from "../pages/NewAccount/NewSupplier";
import {getAccountManagersDropdownList} from "../actions/account_managers";
import {logoutUser} from "../actions/auth";


const mapState = ( {auth, accounts, references, account_managers} )=> ({
    account_manager_list: account_managers.dropdown_list,
    currency_list:references.currency_list,
    payment_terms_list: references.payment_terms_supplier_list,
    loadingReferences: references.loading,
    client_role_list: references.client_role_list,
    admin_role_list: references.admin_role_list,
    registeredAccount: accounts.registeredAccount,
    countryList: references.country_list,
    howFindList: references.how_find_list,
    authInfo: auth.authInfo,
    service: auth.service
});

export default connect( mapState, {
    logoutUser,
    getSuppliers,
    createSupplier,
    setNewCreatedAccountId,
    getAccountManagersDropdownList
})(CreateSupplier);
  