import React, {useEffect, useMemo, useState} from "react";
import PanelLayout from "../../components/base/PanelLayout";
import UsersTable from "./UsersTable";
import PageHeader from "../../components/base/PageHeader";
import {ButtonPrimary} from "../../components/base/BaseButton";
import ModalRemoveUser from "./ModalRemoveUser";
import ModalCreateModifyUser from "./ModalCreateModifyUser";

export default (
    {
        data,
        loading,
        getManagerAccountUser,
        adminRoleList
    }
) => {

    const [activeUserId, setActiveUserId] = useState(null);
    const [showModalRemove, setShowModalRemove] = useState(false);
    const [editableUserFormData, setEditableUserFormData] = useState(null);
    const [showModalCreate, setShowModalCreate] = useState(null);

    const activeUserName = useMemo(() => {
        if (!data || !activeUserId) {
            return null;
        }
        return data.find(item => item.id === activeUserId)
    }, [data, activeUserId]);

    useEffect(() => {
        getManagerAccountUser();
    }, []);

    useEffect(() => {
        const userData = activeUserId
            ? data.find(item => item.id === activeUserId)
            : null;


        const params = userData
            ? {
                name: userData.name,
                surname: userData.surname,
                phone: userData.phone,
                email: userData.email,
                login: userData.login,
                role_list: userData.role_list,
                active: userData.active,
            }
            : null;

        if (params) {
            for (const prop of Object.keys(params)) {
                if (params[prop] === null) {
                    delete params[prop];
                }
            }
        }

        setEditableUserFormData(params);
    }, [data, activeUserId]);

    return (
        <PanelLayout>
            <PageHeader extraTitle="Admin Panel Users">
                <ButtonPrimary
                    onClick={() => setShowModalCreate(true)}
                >
                    + Create User
                </ButtonPrimary>
            </PageHeader>

            <UsersTable
                data={data}
                loading={loading}
                adminRoleList={adminRoleList}
                getManagerAccountUser={getManagerAccountUser}
                {...{
                    setActiveUserId,
                    setShowModalRemove,
                    setShowModalCreate,
                }}
            />

            <ModalRemoveUser
                show={showModalRemove}
                userId={activeUserId}
                title={<>Remove user <b>{activeUserName && activeUserName.name}</b></>}
                onClose={() => {
                    setShowModalRemove(false);
                    setActiveUserId(null);
                }}
                onUpdate={getManagerAccountUser}
            />

            <ModalCreateModifyUser
                show={showModalCreate}
                userId={activeUserId}
                userFormValue={editableUserFormData}
                title={activeUserName ? `Modify user ${activeUserName.name}` : "Create new user"}
                onClose={() => {
                    setShowModalCreate(false);
                    setActiveUserId(null);
                }}
                onUpdate={getManagerAccountUser}
                adminRoleList={adminRoleList}
            />

        </PanelLayout>
    )
}