import React, {useEffect, useState, useMemo, useRef} from 'react';
import styled from "styled-components";
import {SP_CHANGE_RATE_METHOD} from 'const/apiMethods';
import {Alert, Checkbox, Form, FormControl, SelectPicker} from 'rsuite';

import {api} from '../../api/loginRoutes';

import {Info} from '../../components/alert';
import MyTable from '../../components/Table';
import RateField from '../../components/Form/RateField';
import FormCheckbox from '../../components/Form/FormCheckbox';
import {CustomModal} from '../../components/base/BaseModal';
import {NumberField} from '../../components/Form/NumberField';

import ModalSelectDialers from './ModalSelectDialers';


const labelWidth = 130;
const inputNumberWidth = 100;
const heightResize = 190;
const rowHeight = 35;
const rowHeaderHeight = 35;


export default ({
    setShow,
    p_key,
    sde_key,
    cur_key,
    update,
    updateRanges,
    ratesValue = [],
    currency_list,
    update_numbers_type = 0,

    dialers,
    managers,
    getDialers,
    getManagers,
}) => {

    const formRef = useRef(null);
    const [accounts, setAccounts] = useState({data: [], loading: true});
    const [optionUpdateNumbers, setOptionUpdateNumbers] = useState(0);
    const [modalSelectDialersShow, setModalSelectDialersShow] = useState(false);
    const [ptValue, setPtValue] = useState(() => (
        ratesValue.map(item => ({...item, checked: item.value || item.value == 0}))
    ));
    const [submitLoading, setSubmitLoading] = useState(false);


    // columns
    const columns = useMemo(() => ([
        {
            label: 'Dialer', 
            dataKey: 'acc_name', 
            minWidth: 160
        },
        {
            label: 'Price',
            value: ({service_plan_name}) => service_plan_name,
            width: 95
        },
        {
            label: 'Rate',
            value: ({account_id, rate, sp_key}) => (
                <Form>
                    <StyledNumberField
                        max={10}
                        min={0.00001}
                        key={account_id}
                        size="sm"
                        name="rate"
                        value={rate}
                        width={'100%'}
                        calculated={true}
                        placeholder="0"
                        onChange={value => handleChangeRate(account_id, value, sp_key)}
                    />
                </Form>
            ),
            width: 100
        },
        {
            label: <>Update allocated <br/>numbers</>,
            value: ({account_id, checked}) => {
                return (
                    <StyledFormCheckbox 
                        disabled={!checked}
                        onChange={(value) => setAccounts(prev =>({
                            ...prev,
                            data: prev.data.map((item) => {
                                if (item.account_id === account_id) {
                                    return ({
                                        ...item,
                                        update_numbers: value,
                                    })
                                }
                                return item
                            })
                        }))}
                    />
                )
            },
            width: 120
        },
    ]), []);


    // fetch data
    useEffect(() => {
        setAccounts(prev => ({
            ...prev,
            loading: true,
        }));

        api('service_plan_price:account_with_special_price', {p_key, sde_key, cur_key})
            .then(({account_with_special_price}) => {
                const accountWithExtraKey = account_with_special_price 
                    ? account_with_special_price.map(acc => (
                        {...acc, select_object: {account_id: acc.account_id, sp_key: acc.sp_key}}
                    ))
                    : null;
                setAccounts({
                    data: accountWithExtraKey || [],
                    loading: false
                });
            });
    }, []);


    const getUpdatedPtValue = (rate, newData) => {
        return ptValue.map(ptValueItem => {
            if (ptValueItem.pt_key !== rate.pt_key)
                return ptValueItem;

            return {...ptValueItem, ...newData};
        });
    };


    const handleChangeRate = (account_id, rate, sp_key) => {
        setAccounts(prev => ({
            ...prev,
            data: prev.data.map(row => row.account_id === account_id && row.sp_key === sp_key 
                ? {...row, checked: true, rate} 
                : row
            )
        }));
    };


    const handleChangeChecked = (selected) => {
        setAccounts(prev => ({
            ...prev,
            data: prev.data.map(row => {
                const isSelected = selected.list.some(item => (
                    row.select_object.sp_key === item.sp_key && row.select_object.account_id === item.account_id
                ));

                return isSelected 
                    ? {...row, checked: true} 
                    : {...row, checked: false}
            })
        }));
    };


    const handleSubmit = (dialers) => {

        if ( !formRef.current.check() ) {
            return;
        }

        const formData = formRef.current.getFormValue();

        const newRates = ptValue
            .filter( rate => rate.checked )
            .map(rate => ({
                pt_key: rate.pt_key,
                rate: +rate.value
            }));

        const params = {
            ...formData,
            default_rates: newRates,
            account_rates: accounts.data
                .filter(x => x.checked)
                .map(x => ({
                    account_id: x.account_id, 
                    sp_key: +x.sp_key, 
                    rate: +x.rate,
                    update_numbers: x.update_numbers,
                }))
        }

        if (dialers) {
            params.target = {account_id_list: dialers};
        }

        if (params[undefined] !== undefined) {
            delete params[undefined];
        }

        setSubmitLoading(true);
        api(SP_CHANGE_RATE_METHOD, params)
            .then(r => {
                if (!r) {
                    Alert.error('Changing rate for subdestination failed');
                    return;
                }
                setShow(false);
                update(r);
            })
            .finally(() => {
                setSubmitLoading(false);
            });
    };


    const calculatedHeight = accounts.data.length < 5 && accounts.data.length > 0
        ? (rowHeight * accounts.data.length) + rowHeaderHeight
        : heightResize;

    const currency = currency_list.find(item => item.cur_key === cur_key);


    return (
        <>
            <CustomModal
                show={true}
                showFooter
                loading={submitLoading}
                confirmButtonText="Change"
                title={`Change rate for subdestination ${currency ? ' (' + currency.name + ')' : ''}`}
                onClose={() => setShow(false)}
                width={1000}
                onConfirm={() => {
                    if (optionUpdateNumbers === 2) {
                        setModalSelectDialersShow(true);
                        return;
                    };
                    handleSubmit();
                    updateRanges();
                }}
            >

                <StyledForm
                    noCheck
                    layout="inline"
                    ref={formRef}
                    formDefaultValue={{
                        p_key, 
                        sde_key, 
                        cur_key,
                        update_numbers_type,
                    }}
                >

                    <div style={{marginRight: 0, marginBottom: 0}}>

                        {ptValue.map(rate => (
                            <div key={rate.pt_key}>
                                <RateField
                                    labelWidth={labelWidth}
                                    width={inputNumberWidth}
                                    mr={5}
                                    style={{padding: 0, margin: 0}}
                                    label={`New rate for ${rate.name.replace("_", "/")}`}
                                    value={rate.value}
                                    onChange={(v) => setPtValue( getUpdatedPtValue(rate, {
                                        value: v,
                                        checked: !!v
                                    }))}
                                />
                                <Checkbox
                                    inline={true}
                                    style={{padding: 0, margin: 0, marginTop: 0}}
                                    checked={rate.checked}
                                    onChange={(v, checked) => setPtValue( getUpdatedPtValue(rate, {
                                        checked: checked
                                    }))}

                                />
                            </div>
                        ))}
                    </div>

                    <StyledControlPicker>
                        <FormControl
                            name='update_numbers_type'
                            accepter={SelectPicker}
                            searchable={false}
                            cleanable={false}
                            valueKey={'value'}
                            labelKey={'name'}
                            onChange={setOptionUpdateNumbers}
                            data={[
                                {value: 0, name: 'Do not update rate for allocated numbers'},
                                {value: 1, name: 'Update rate for allocated numbers for all dialers'},
                                {value: 2, name: 'Update rate for allocated numbers for specified dialers'}
                            ]}
                        />
                    </StyledControlPicker>
                </StyledForm>


                <StyledTableWrapper>
                    <Info>These dialers have speial rates for this subdestination. Do you want to update them?</Info>
                    
                    <StyledTable
                        heightResize={calculatedHeight}
                        data={accounts.data}
                        loading={accounts.loading}
                        columns={columns}
                        row_key="select_object"
                        setSelected={handleChangeChecked}
                        rowHeight={rowHeight}
                        isselected
                        stateChecked
                    />
                </StyledTableWrapper>

            </CustomModal>

            <ModalSelectDialers
                show={modalSelectDialersShow}
                onClose={() => setModalSelectDialersShow(false)}
                onSubmit={handleSubmit}
                {...{
                    p_key,
                    sde_key,
                    dialers,
                    getDialers,
                    managers,
                    getManagers,
                }}
            />
        </>
    );
};


const StyledTable = styled(MyTable)`
    .rs-table-cell-header {

        .rs-table-cell-content {
            white-space: normal;
            line-height: 1.2em;
            display: flex;
            align-items: center;
        }

        .rs-checkbox {
            top: 0 !important;
        }
    }
`;


const StyledForm = styled(Form)`
    display: inline-block;
    width: 300px;
    border-right: 3px solid #C4C4C4;
    vertical-align: middle;
    margin-top: 50px;
    height: auto;
`;

const StyledTableWrapper = styled.div`
    display: inline-block;
    width: 610px;
    margin-left: 20px;
    vertical-align: top;
`;

const StyledNumberField = styled(NumberField)`
    position: relative;
    top: -3px;
    width: 80px;
`;

const StyledFormCheckbox = styled(FormCheckbox)`
    position: relative;
    top: -5px;
`;

const StyledControlPicker = styled.div`
    margin: 0 !important;
    padding-top: 20px;
    padding-right: 55px;
`;