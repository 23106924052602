import React, {useState, useEffect} from "react";
import PageHeader from "../../components/base/PageHeader";
import PanelLayout from "../../components/base/PanelLayout";
import AccessListExceptionTable from "./AccessListExceptionTable";
import AccessListExceptionFilter from "./AccessListExceptionFilter";
import AccessListExceptionAdd from "./AccessListExceptionAdd";
import {connect} from "react-redux";
import {
    getAccessExclusionList, 
    onAddAccessExclusionItem, 
    onEditAccessExclusionItem, 
    onRemoveAccessExclusionItem
} from "actions/access_list";
import {getReferencesForReports} from "actions/references";
import ModalExceptionRemove from "./ModalExceptionRemove";
import "./AccessListException.css";


const AccessListException = ({
    service,
    loading,
    clientList,
    dialerList,
    supplierList,
    worldzoneList,
    destinationList,
    subdestinationList,
    access_list_exclusion,

    getAccessExclusionList,
    getReferencesForReports,
    onAddAccessExclusionItem,
    onEditAccessExclusionItem,
    onRemoveAccessExclusionItem
}) => {

    const [filter, setFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showModalRemove, setShowModalRemove] = useState(false);
    const [modalRemoveData, setModalRemoveData] = useState(null);
    
    useEffect(() => {
        getAccessExclusionList(service);
        getReferencesForReports();
    }, [service]);

    const onChangeFilter = (filter) => {
        setFilter(filter);
        setIsLoading(false);
    };

    const handleShowModalRemove = (data) => {
        setShowModalRemove(true);
        setModalRemoveData(data);
    };

    const removeExceptionList = () => {
        onRemoveAccessExclusionItem(service, modalRemoveData)
            .then(() => {
                getAccessExclusionList(service);
                setShowModalRemove(false);
            });
    };

    return (
        <PanelLayout>
            <PageHeader title="Exceptions for access lists"/>

            <AccessListExceptionFilter
                onChange={onChangeFilter}
            />
            <AccessListExceptionTable
                data={access_list_exclusion}
                onRemoveItem={handleShowModalRemove}
                onEditItem={onEditAccessExclusionItem}
                update={getAccessExclusionList}
                {...{
                    filter,
                    loading,
                    service,
                    dialerList,
                    isLoading,
                    clientList,
                    supplierList,
                    worldzoneList,
                    destinationList,
                    subdestinationList,
                }}
            /> 

            <AccessListExceptionAdd
                addItem={onAddAccessExclusionItem}
                update={getAccessExclusionList}
                {...{
                    service,
                    clientList,
                    dialerList,
                    supplierList,
                    worldzoneList,
                    destinationList,
                    subdestinationList
                }}
            />
            <ModalExceptionRemove
                show={showModalRemove}
                handleClose={() => setShowModalRemove(false)}
                handleSuccess={removeExceptionList}
            />
        </PanelLayout>
    );
};


const mapState = ({auth, access_list, references}) => ({
    service: auth.service,
    access_list_exclusion: access_list.access_list_exclusion,
    worldzoneList: references.worldzone_list,
    destinationList: references.destination_list,
    subdestinationList: references.subdestination_list,
    clientList: references.client_list,
    dialerList: auth.service ? references.dialer_list : references["sms.dialer_list"],
    supplierList: auth.service ? references.supplier_list :references["sms.supplier_list"]
});

export default connect(mapState,
    {
        getAccessExclusionList,
        onAddAccessExclusionItem,
        onEditAccessExclusionItem,
        onRemoveAccessExclusionItem,
        getReferencesForReports
    })(AccessListException);