import React, {useRef, useMemo, useState, useEffect} from 'react';
import {Table as _Table, InputNumber, Form, Schema, SelectPicker, ControlLabel, FlexboxGrid, FormControl, CheckPicker} from "rsuite";
import styled from 'styled-components';
import {api} from '../../../../../api/loginRoutes';

import Checkbox from '../../../../../hoc/Checkbox';
import Table from '../../../../../components/Table';
import NumberField from '../../../../../components/Form/NumberField';

import {deleteAllDuplicates} from '../../../../../utils/helpers';

import {SMS_DEFAULT_SP_KEY} from '../../../../../const';
import { debounce } from 'lodash';

const {Column, Cell, HeaderCell} = _Table;
const {NumberType} = Schema.Types;


const columns = [
    // {
    //     label: 'Subdestination',
    //     dataKey: 'subdestination_name',
    //     minWidth: 280,
    //     flexGrow: 3
    // },
    {
        label: 'Operator',
        dataKey: 'mccmnc_name',
        minWidth: 280,
        flexGrow: 3
    }
];


export default ({
    defaultFormValue,
    onChangeForm,
    formValue,
    disabled,
    account_id,
    currentTrunk,
    operatorList,
    // subdestination_list
}) => {

    let formRef = useRef(null);

    const [data, setData] = useState({
        current: [],
        loading: true,
        state: []
    });

    const [filterValue, setFilterValue] = useState({
        operator_list: []
    });

    const numbersList = useMemo(() => {
        const prefixCount = 0;
        const difference = 6-prefixCount > 0 ? 6-prefixCount : 1;

        const numberArray = Array.from(Array(difference).keys());
        return numberArray.map(x => 10 ** x).map(x => ({label:x, value:+x}));
        
    }, [formValue]);

    // const currentSubdest = useMemo(() => 
    //     subdestination_list.find(subdest => subdest.sde_key === formValue.sde_key) || [null]
    // , [formValue]);


    const formModel = Schema.Model({
        mccMnc: NumberType()
            .isRequired('Required'),
        numbers: NumberType()
            .isRequired('Required'),
    });

    const onChangePrice = (key, price) => {
        const stateItems = data.state.map(
            row => row.key === key ? {...row, checked: true, rate: price} : row
        );
        const currentItems = data.current.map(
            row => row.key === key ? {...row, checked: true, rate: price} : row
        );

        setData({
            ...data,
            current: currentItems,
            state: stateItems
        });

        onChangeForm({
            ...formValue,
            mccmnc_list: stateItems.filter(value => value.checked).map(value => {
                return {mcc: value.mcc, mnc: value.mnc, rate: parseFloat(value.rate)}
            })
        });
    };

    const onChangeChecked = (selected) => {
        const stateItems = data.state.map(
            row => selected.list.includes(row.key) ? {...row, checked: true} : {...row, checked: false}
        );
        const currentItems = data.current.map(
            row => selected.list.includes(row.key) ? {...row, checked: true} : {...row, checked: false}
        );

        setData({
            ...data,
            current: currentItems,
            state: stateItems,
        });

        onChangeForm({
            ...formValue,
            mccmnc_list: stateItems.filter(value => value.checked).map(value => {
                return {mcc: value.mcc, mnc: value.mnc, rate: parseFloat(value.rate)}
            })
        });
    };

    useEffect(() => {
        setData({
            ...data,
            loading: true,
        });

        if (!currentTrunk) {
            setData({
                current:  [],
                loading: false,
                state: [],
            });
            return;
        }

        api('sms.supplier_price:get_list', {target: {"sms.supplier_plan_id": currentTrunk?.plan_id}})
            .then(
                (r) => {

                    const uniqueData = deleteAllDuplicates(r["sms.supplier_price_list"], "key");
                    setData({
                        current: uniqueData || [],
                        loading: false,
                        state: uniqueData || [],
                    });
                }
            );
    }, [currentTrunk]);


    const checkIfSomeChecked = () => {
        return data.state.some(value => value.checked);
    };

    return (<>
        <Form
            onChange={debounce((value) => {
                setFilterValue(value);

                setData((dataValue) => {
                    const filteredList = dataValue.current.filter(operator => {
                        const mccMnc = `${operator.mcc}-${operator.mnc}`;
                        return !value.operator_list.length || value.operator_list.includes(mccMnc);
                    });
                    return {
                        ...dataValue,
                        state: filteredList,
                        loading: false
                    }
                })
            })}
            formValue={filterValue}
        >
            <FormControl
                accepter={CheckPicker}
                data={operatorList}
                name="operator_list"
                labelKey="operatorMccMnc"
                valueKey="mccMnc"
                placeholder="Operator"
                placement="bottomStart"
                style={{width: 330}}
                searchable={true}
                cleanable={true}
            />                 
        </Form>
        <StyledTable
            virtualized
            shouldUpdateScroll={false}
            data={data.state}
            loading={data.loading}
            row_key="key"
            setSelected={onChangeChecked}
            isselected
            stateChecked
            ActionCell={ActionCell(onChangePrice)}
            extraHeight={220}

            width={'auto'}
            columns={columns}
        />
        <Form
            defaultFormValue={defaultFormValue}
            onChange={(value) => {
                onChangeForm({
                    ...formValue,
                    mccmnc_list: value?.mccmnc_list || formValue?.mccmnc_list,
                    active: value?.active || formValue?.active,
                    prefixes: value?.prefixes || formValue?.prefixes,
                    numbers: value?.numbers
                });
            }}
            formModel={formModel}
            formValue={formValue}
            ref={ref => formRef = ref}
        >
            
            <FlexboxGrid justify="start" style={{margin: '0 0 20px 0'}}>
                <FlexboxGrid.Item>
                    <ControlLabel>Numbers</ControlLabel>
                    <FormControl
                        disabled={disabled || !checkIfSomeChecked()}
                        accepter={SelectPicker}
                        data={numbersList}
                        name="numbers"
                        labelKey="label"
                        valueKey="value"
                        placeholder="Numbers"
                        placement="topEnd"
                        style={{width: 230}}
                        searchable={false}
                    />                   
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="ml-4">
                    <ControlLabel>Prefix count</ControlLabel>
                    <FormControl
                        disabled={disabled || !checkIfSomeChecked()}
                        accepter={InputNumber}
                        name="prefixes"
                        placeholder="Prefixes"
                        style={{width: 230}}
                        min={1}
                        // max={currentSubdest.max_prefix_digits-1}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Form> 
    </>)
}

const ActionCell = (onChange) => (
    <Column width={150} minWidth={140}>
        <HeaderCell>Price</HeaderCell>
        <Cell>
            {rowData => (
                <Form>
                    <StyledNumberField
                        key={"key"}
                        max={10}
                        min={0}
                        size="sm"
                        placeholder="0"
                        value={rowData.rate}
                        calculated={true}
                        onChange={value => onChange(rowData.key, value)}
                    />
                </Form>
            )}
        </Cell>
    </Column>
);

const StyledTable = styled(Table)`
    @media (max-width: 767px) {
        font-size: 12px;
    }
    .rs-table-cell-content {
        padding: 0 10px !important;
    }
    .rs-table-cell-content .rs-checkbox-checker {
        margin-top: -6px;
    }
    .rs-checkbox-checked .rs-checkbox-wrapper::before {
        -webkit-transform: none;
        transform: none;
        opacity: none;
        visibility: none;
    }
`;

const StyledNumberField = styled(NumberField)`
    position: relative;
    width: 120px;

    @media (min-width: 1200px) {
        //top: -9px;
        width: 100px;
    }

    &.rs-input-group {
        width: 100px !important;

        @media (min-width: 767px) {
            width: 135px !important;
        }
    }
`;