import {
    PRICE_DIALER as PREFIX,
    REFERENCES_SERVICE_PLAN_DIALER,
    SET_LOADING_PRICE_ALLOCATED_NUMBERS_DIALER,
    SET_TRUNK_NUMBER_DIALER,
    DEFAULT_PER_PAGE_DIALER
} from '../../const';
import {ALLOCATION_PRICE_RANGE_DIALER_API} from "../../const/apiMethods";

const initialState = {
    items: [],
    count: 0,
    trunk_list: [],
    allocated_numbers: [],
    service_plan_list: [],
    page: 1,
    per_page: DEFAULT_PER_PAGE_DIALER,
    loading: false,
    loadingAllocatedNumbers: false
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {ratecard_list, ratecard_count, page, per_page}}) => ({
        ...state,
        items: ratecard_list,
        count: ratecard_count,
        page,
        per_page,
        loading: false
    }),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    [SET_LOADING_PRICE_ALLOCATED_NUMBERS_DIALER]: (state, {payload}) => ({...state, loadingAllocatedNumbers: payload}),
    [SET_TRUNK_NUMBER_DIALER]: (state, {payload: {trunk_list}}) => ({...state, trunk_list}),
    [ALLOCATION_PRICE_RANGE_DIALER_API]: (state, {payload: {price_range_number_list}}) => ({
        ...state,
        allocated_numbers: price_range_number_list,
        loadingAllocatedNumbers: false
    }),
    [REFERENCES_SERVICE_PLAN_DIALER]: (state, {payload:  service_plan_list}) => ({...state,  service_plan_list}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);

}