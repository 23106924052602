import {LIVE_CALLS_DIALER as PREFIX} from '../../const'
import {api} from '../../api/loginRoutes'
import {_setValue} from './defaults'
import {LIVE_CALLS_LIST_DIALER_API} from "../../const/apiMethods";

const setItems = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);

export const getLiveCalls = (testCalls = false) => (dispatch) => {
    dispatch( setLoading(true) );
    api(LIVE_CALLS_LIST_DIALER_API, {test_calls: testCalls})
        .then((response) => {
            dispatch( setLoading(false) );
            if (response === undefined)
                return;

            dispatch(setItems({
                live_calls_list: response.live_call_list || []
            }))
        })
};