import React, {useMemo} from "react";
import AccountForm from "./General/AccountForm";


const TabGeneral = ({
    history,
    account,
    disabled,
    subAccId,
    accountId,
    customerList,
    customerListLoading,

    modifySubAccount,

    createCustomer,
    modifyCustomer,
    removeCustomer,
    
    update,
    updateCustomers,
    cancel,
    close,
    open
}) => {
    const accountDefaultData = useMemo(() => {
        return {...account};
    }, [account, subAccId]);
    
    return <AccountForm
        history={history}
        disabled={disabled}
        subAccId={subAccId}
        accountId={accountId}
        formDefaultValue={accountDefaultData}

        customerList={customerList}
        customerListLoading={customerListLoading}

        modifySubAccount={modifySubAccount}

        createCustomer={createCustomer}
        modifyCustomer={modifyCustomer}
        removeCustomer={removeCustomer}

        update={update}
        updateCustomers={updateCustomers}
        cancel={cancel}
        close={close}
        open={open}
    />
};


export default TabGeneral;