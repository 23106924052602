import React from "react";
import Table from "../../components/Table";
import {Button} from "rsuite";
import styled from "styled-components";
import { transformed_service_data } from "const";
import { servicePick } from "utils";


export default ({
    data = [],
    loading,
    perPage,
    page,
    count,
    service,
    getItems,
    toggleParamsModal,
    toggleErrorModal,
    ...props
}) => {

    const columns = [
        {
            id: "date",
            label: "Date / Time",
            dataKey: "date",
            width: 200,
            formatData: "datetime_en",
        },
        {
            id: "account_user_name",
            label: "User",
            value: ({account_user_name}) => `${account_user_name}`,
            flexGrow: 1,
        },
        {
            id: "action",
            label: "Action",
            dataKey: "action",
            value: ({action, account_name, account_manager_name}) => {
                const manager = account_manager_name ? ` (${account_manager_name})`: "";
                return `${action}${account_name ? " - " + account_name + manager: ""}`
            },
            flexGrow: 4,
            minWidth: 200,
        },
        {
            id: "params",
            label: "Params",
            value: (rowData) => (rowData.params && Object.keys(rowData.params).length
                ? <StyledButton
                    onClick={() => toggleParamsModal(true, rowData)}
                >
                    {`{...}`}
                </StyledButton>
                : ""
            ),
            width: 150,
        },
        {
            id: "result",
            label: "Result",
            value: (rowData) => {
                if (rowData.result.error) {
                    return <>
                        error:{` `}
                        <StyledButton
                            onClick={() => toggleErrorModal(true, rowData)}
                        >
                            {`{...}`}
                        </StyledButton>
                    </>
                }
                return ( Object.keys(rowData.result).map(item => `${item}: ${rowData.result[item]}`) )
            },
            flexGrow: 2,
            minWidth: 200,
        }
    ];

    const height = 40 + (30 * perPage);

    return (
        <StyledTable
            virtualized
            ispagination
            extraHeight={height > 700 ? 700 : height}
            getItems={getItems}
            per_page={perPage}
            rowClassName={(rowData) => {
                if ( !rowData || (!rowData.site && rowData.site !== 0) )
                    return;
                
                return rowData.site === 4 
                ? servicePick(
                    transformed_service_data[rowData.service],
                    "row-admin-action-voice",
                    "row-admin-action-sms"
                ) : "row-client-action"
            }}
            {...{
                data,
                loading,
                columns,
                page,
                count
            }}
            {...props}
        />
    )
};


const StyledButton = styled(Button).attrs(props => ({
    appearance: "link"
}))`
    &&& {
        color: var(--color-violet);
        padding: 0;
        text-decoration: none;
        
        &:hover {
            color: var(--color-violet-dark);
        }
    }
`;

const StyledTable = styled(Table)`
    .row-admin-action-voice .rs-table-cell {
        color: var(--color-violet);
    }
    .row-admin-action-sms .rs-table-cell {
        color: var(--color-info);
    }
    .row-client-action .rs-table-cell {
        color: var(--color-brand2);
    }
`;