import React, { useMemo, useState, useEffect } from "react";
import PaymentsFilter from "./Payments/PaymentsFilter";
import PaymentsTable from "./Payments/PaymentsTable";
import AddNewPaymentModal from "./Payments/AddNewPaymentModal";
import { ButtonPrimary } from "components/base/BaseButton";
import { toStartDayTime, toEndDayTime } from "utils";
import { FilterSectionFlexboxGrid, StyledItemFitWidth, StyledItemAutoMargin } from "components/base/FitContainers";

import m from "definedMessages";

const TabPayments = ({
    subAccId,

    data,
    currencyList,
    loading,

    billingServiceList,
    billingCurrencyList,

    getData,
    accountUpdate,

    addNewFunc,
    removeFunc,
    
    formatMessage
}) => {

    let startDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    let endDate = new Date();
    endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);

    const defaultFilterValue = {
        range: [toStartDayTime(startDate), toEndDayTime(endDate)]
    };


    const [filterState, setFilterState] = useState(defaultFilterValue);
    const [showAddNewModal, setShowAddNewModal] = useState(false);

    const voiceBillingService = billingServiceList?.length ? billingServiceList.find((item) => {
        return item.service === "voice";
    }) : null;
    const voiceCurrencyKey = voiceBillingService ? voiceBillingService?.cur_key : null;

    const availableCurrencyList = currencyList.filter((currency) => {
        return billingCurrencyList?.length 
            ? billingCurrencyList.map((accountCurrency) => accountCurrency.cur_key).includes(currency.cur_key)
            : true;
    });

    useEffect(() => {
        update();
    }, [subAccId, filterState]);

    const update = () => {
        getData(subAccId, filterState);
    };

    const onAddNew = (data, callback=null) => {
        addNewFunc(subAccId, data)
            .then((response) => {
                if (response) {
                    update();
                    accountUpdate();
                    
                    if (callback) {
                        callback();
                    }
                }
            })
            .finally(() => {
                setShowAddNewModal(false);
            });
    };

    const onRemove = (key) => {
        removeFunc(subAccId, key)
            .then((response) => {
                if (response) {
                    update();
                    accountUpdate();
                }
            });
    };

    return <>
        <FilterSectionFlexboxGrid>
            <StyledItemFitWidth>
                <PaymentsFilter
                    filter={filterState}
                    defaultFilter={defaultFilterValue}
                    onChangeFilter={setFilterState}
                />
            </StyledItemFitWidth>
            <StyledItemAutoMargin>
                <ButtonPrimary
                    disabled={loading}
                    onClick={() => {
                        setShowAddNewModal(true);
                    }}
                >
                    {formatMessage(m.addPayment)}
                </ButtonPrimary>
            </StyledItemAutoMargin>
        </FilterSectionFlexboxGrid>

        <PaymentsTable
            height={440}
            data={data}
            loading={loading}
            onRemove={onRemove}
            formatMessage={formatMessage}
        />

        <AddNewPaymentModal
            show={showAddNewModal}
            disabled={loading}

            voiceCurrencyKey={voiceCurrencyKey}

            currencyList={availableCurrencyList}

            addNewFunc={onAddNew}
            onCloseModal={() => {
                setShowAddNewModal(false);
            }}
            formatMessage={formatMessage}
        />
    </>
};


export default TabPayments;