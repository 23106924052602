import React, {useContext, useEffect, useState} from "react";
import {Icon, Table, Tooltip, Whisper} from "rsuite";
import FormHOC from "../../hoc/FilltersForm";
import {renderColumn} from "../../utils/helpers";
import {compose} from "redux";
import {useIntl} from "react-intl";
import {useWindowWidth} from "../../hooks";
import {SCREEN_MEDIA, SP_KEY__INCORPORATED, SP_KEY__GLOBAL_ACCESS} from "../../const";
import m from "../../definedMessages";
import BaseTable from "../../components/base/BaseTable";
import {ButtonRowClient, ButtonToolbar} from "../../components/base/BaseButton";
import styled, {css} from "styled-components";
import {RtlContext} from "../../App";
import {Link} from "react-router-dom";

const {sm} = SCREEN_MEDIA;
const {Column, HeaderCell, Cell} = Table;

const TrunkTable = (
    {
        data = [],
        service,
        loading,
        onClickTrunk,
        onDownloadNumbers,
        onShowNumbersModal,
        uploadLoading,
        accountDialerState,

        onChangeActiveTrunk,
        activeTrunk,
    }
) => {

    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);
    const resizedWidth = useWindowWidth();
    const [currentTop, setCurrentTop] = useState(0);


    useEffect(() => {
        if (filteredData.length > 0 && activeTrunk === null) {
            onChangeActiveTrunk(filteredData[0].id, filteredData[0].allocation_limit);
        }
    });


    const handleClickRow = (id, sp_key, sp_auth, allocation_limit) => {
        onClickTrunk(id, sp_key, sp_auth, allocation_limit);
        const top = isScrolled ? scrollTop : currentTop;
        setCurrentTop(top);
        if (isScrolled)
            isScrolled = false;
    };


    let scrollTop = 0;
    let isScrolled = false;
    let tableRef = null;


    useEffect(() => {
        const top = Math.abs(currentTop);
        tableRef.scrollTop(top);
    }, [activeTrunk, data, loading, uploadLoading]);


    const accountSpList = accountDialerState?.account_service_plan_list || [];


    const columns = [
        {
            id: "name",
            label: formatMessage(m.name),
            dataKey: "name",
            align: "center",
            minWidth: 170,
            flexGrow: 4
        },
        ...(service ? [{
            id: "range",
            label: formatMessage(m.type),
            value: (({tprefix, ip, port, is_default_term_point, ivr_name}) => {
                return !is_default_term_point
                    ? (ip && port ? `${ip}:${port} ${tprefix}` : "")
                    : ivr_name || formatMessage(m.defaultTerminationPoint);
            }),
            align: "center",
            minWidth: 120,
            flexGrow: 3
        }] : []),
        {
            id: "price",
            label: formatMessage(m.price),
            dataKey: "service_plan_name",
            // value: (({service_plan_name, sp_otp_type}) => sp_otp_type === SP_OTP_TYPE  ? `${service_plan_name} (Google special)` : service_plan_name),
            align: "center",
            minWidth: 120,
            flexGrow: 2
        },
        {
            id: "allocated_numbers",
            label: formatMessage(m.allocatedNumbersTrunkTable),
            value: (({allocated_numbers, numbers_with_traffic}) => `${allocated_numbers || 0}/${numbers_with_traffic || 0}`),
            align: "center",
            minWidth: 100,
            flexGrow: 2
        },

        ...(service ? [{
            id: "sp_auth",
            label: "For all traffic",
            value: ({sp_auth}) => {
                return !sp_auth 
                    ? formatMessage(m.yes) 
                    :  <SecondStagedCell className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">
                            {formatMessage(m.no)}
                        </div>
                        <div className="table-two-staged__cell-second">
                            <StyledLink
                                to={"/auth-numbers"}
                            >
                                {formatMessage(m.clickToSeeCLILimitationForTrunk)}Click to see CLI limitation for trunk
                            </StyledLink>
                        </div>
                    </SecondStagedCell>
                        
                  
            },
            // align: "center",
            width: 210
        }] : [])
    ];


    const tableActions = (
        <Column
            fixed={rtl ? "" : "right"}
            key="table__actions"
            width={resizedWidth >= 768 ? 120 : 75}
        >
            <HeaderCell> </HeaderCell>
            <Cell>

                {rowData => {

                    const currentSp = accountSpList.find(item => {
                        return item.sp_key === rowData.sp_key
                    });

                    if (!currentSp || currentSp && currentSp.allocation_limit.hasOwnProperty("allocate") && !currentSp.allocation_limit.allocate) {
                        return null
                    }

                    return (

                        <ButtonToolbar>
                            <StyledButtonRowClient
                                onClick={() => onDownloadNumbers(rowData.id)}
                                loading={uploadLoading[rowData.id]}
                            >
                                <Icon icon="cloud-download"/>
                            </StyledButtonRowClient>

                            {![SP_KEY__GLOBAL_ACCESS, SP_KEY__INCORPORATED].includes(rowData.sp_key) &&
                            <>
                                {!rowData.active

                                    ? <Whisper
                                        preventOverflow
                                        trigger="hover"
                                        speaker={<Tooltip>{formatMessage(m.trunkIsNotActive)}</Tooltip>}
                                        placement="auto"
                                    >
                                        <StyledButtonRowClient
                                            // use className instead of prop to avoid Tooltip issue on Chrome
                                            className="rs-btn-disabled"
                                        >
                                            <Icon icon="lock"/>
                                        </StyledButtonRowClient>
                                    </Whisper>

                                    : <StyledButtonRowClient
                                        onClick={() => onShowNumbersModal(rowData.id, rowData.allocation_limit)}>
                                        <b>A</b>
                                    </StyledButtonRowClient>
                                }
                            </>        
                            }
                        </ButtonToolbar>
                    )
                }}
            </Cell>
        </Column>
    );

    const columnMobileFix = (
        <Column key="table_scrollfix" width={0} fixed>
            <HeaderCell></HeaderCell>
            <Cell></Cell>
        </Column>
    );

    const renderedColumns = columns.map(renderColumn);
    renderedColumns.push(tableActions);
    renderedColumns.push(columnMobileFix);

    // reverse columns for RTL. Haven"t found good solutions for CSS yet
    if (rtl) 
        renderedColumns.reverse();

    const isMobile = resizedWidth <= sm.max;
    const filteredData = data.filter(value => !value.closed);
    

    return (
        <BaseTable
            data={filteredData}
            onRowClick={({id, sp_key, sp_auth, allocation_limit}) => handleClickRow(id, sp_key, sp_auth, allocation_limit)}
            ref={ref => tableRef = ref}
            loading={loading}
            className={"trunk-table"}
            height={120}
            autoHeight={isMobile}
            rowHeight={30}
            headerHeight={31}
            onScroll={(scrollX, scrollY) => {
                if (!isScrolled)
                    isScrolled = true;

                scrollTop = scrollY;
            }}
            rowClassName={(rowData) => {
                if (rowData !== undefined) {
                    if (rowData.id === activeTrunk) {
                        return "row-active";
                    }
                    if (!rowData.active) {
                        return "row-closed";
                    }
                }
                return "row-table";
            }}
        >
            {renderedColumns}
        </BaseTable>
    );
};

export default compose(
    FormHOC
)(TrunkTable);

const ColumnName = styled.div`
    display: flex;
    align-items: center;
`;
const ColumnNameValue = styled.div`
    width: 100%;
    text-align: center;
`;
const ColumnNameWhitelist = styled(Icon)`
    ${props => props.nameIsTooLong && css`
      left: 0;
    `};
    font-size: 12px;
    position: absolute;
    color: #4aaef4;
    margin-left: 4px;
    margin-top: 2px;
`;

const StyledButtonRowClient = styled(ButtonRowClient)`
    &&&& {
        height: 25px;
        line-height: 25px;
        padding: 0px;
        min-width: 25px;
    }
`;

const StyledLink = styled(Link)`
    && {
        color: var(--color-secondary);
        /* text-decoration: underline; */
    }
`;

const SecondStagedCell = styled.div`
   margin-top: -7px;
    
    .table-two-staged__cell-first {
        ${props => props.same && css`
            font-size: 11px;
        `}
    }
    .table-two-staged__cell-second {
        margin-top: -4px;
        font-size: 12px;
        ${props => !props.same ? css`
            color: var(--color-brand1);
        ` : css`
            font-size: 13px;
        `}
        opacity: 1;
    }
`;