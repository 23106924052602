import React, { useMemo } from "react";
import { connect } from "react-redux";
import { SelectPicker } from "rsuite";
import CustomField from "../Form/CustomField";
import { withTranslation } from "hoc/WithTranslation";
import m from "definedMessages";

const ServicePlansComponent = ({excluded = [], service_plan_list, service_plan_list_filtered, filtered, extraList, extraPlaceholder, filterFunc=null, formatMessage, ...props}) => {
    const filteredData = useMemo(() => {
        const data = !extraList ? filtered ? service_plan_list_filtered : service_plan_list : extraList;
        const filtered1 = data.filter(item => !excluded.includes(item.sp_key));
        const filtered2 = filterFunc ? filtered1.filter(filterFunc) : filtered1;
        return filtered2;
    }, [filtered, service_plan_list_filtered, service_plan_list, excluded]);    
    
    return <CustomField
        block
        data={filteredData}
        accepter={SelectPicker}
        labelKey = "name"
        valueKey = "sp_key"
        placeholder={extraPlaceholder || formatMessage(m.price)}
        name="sp_key"
        searchable={false}  
        cleanable={false}  
        classPrefix="allocated"
        {...props}
        virtualized={false}
    />
};

const mapState = ( {references} )=> ({
    service_plan_list: references.service_plan_list,
    service_plan_list_filtered: references.service_plan_list_filtered
});


const ServicePlans = withTranslation(connect( mapState, {})(ServicePlansComponent));

export default ServicePlans; 