import React from 'react';
import {Divider, Drawer, IconButton, FlexboxGrid} from 'rsuite';
import apiMethods from './apiMethods.json';
import ApiPageDocumentation from './ApiPageDocumentation';
import ApiPageKeys from './ApiPageKeys';
import ApiPageExamples from './ApiPageExamples';
import './ApiPage.css';
import {LOCAL_STORAGE_API_KEY_DIALER} from '../../const';
import Affix from 'rsuite/es/Affix';
import Icon from 'rsuite/es/Icon';
import {Spacer} from '../../components/base/Spacer';
import ApiPageSidebar from './ApiPageSidebar';
import styled from 'styled-components';
import Page from "../../components/base/Page";
import {checkPermissionsFor} from '../../store/storeHelpers'
import {API_PAGE_LIST_DIALER_API} from '../../const/apiMethods'
import {compareTwoArrays} from '../../utils'


export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            apiTurn: 'docs',
            pickedApiKey: localStorage.getItem(LOCAL_STORAGE_API_KEY_DIALER),
            resizedWidth: window.innerWidth,
            mobileSidebarShow: false,
            keysPermission: false
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);

        this.getKeyListWithPermission()
    }

    componentDidUpdate(prevProps) {
        const {permissions} = this.props;
        // get keys
        if (!compareTwoArrays(prevProps.permissions, permissions)) {
            this.getKeyListWithPermission()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {keyList, newItem} = this.props;
        const {apiTurn, pickedApiKey, resizedWidth, mobileSidebarShow} = this.state;

        const activeKeyList = nextProps.keyList.filter(key => key.active).map(key => key.api_key);

        if (activeKeyList.length && !activeKeyList.includes(pickedApiKey)) {
            this.setState({pickedApiKey: activeKeyList[0]});
            localStorage.setItem(LOCAL_STORAGE_API_KEY_DIALER, activeKeyList[0])
        }
        return apiTurn !== nextState.apiTurn
            || mobileSidebarShow !== nextState.mobileSidebarShow
            || resizedWidth !== nextState.resizedWidth
            || keyList !== nextProps.keyList
            || pickedApiKey !== nextState.pickedApiKey
            || newItem !== nextProps.newItem
            || pickedApiKey && !activeKeyList.includes(pickedApiKey);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({
            resizedWidth: window.innerWidth
        });
    };

    pickApiKey = (key) => {
        this.setState({pickedApiKey: key});

        if (key)
            localStorage.setItem(LOCAL_STORAGE_API_KEY_DIALER, key);
        else
            localStorage.removeItem(LOCAL_STORAGE_API_KEY_DIALER);
    };

    toggleMobileSidebarShow = (val = false) => {
        this.setState({
            mobileSidebarShow: val
        })
    };

    getKeyListWithPermission = () => {
        const {keysPermission} = this.state;
        const {permissions, getKeyList, account_id} = this.props;

        if (!permissions.length) {
            this.setState({
                keysPermission: false
            })
        } else {
            if (permissions.length && checkPermissionsFor(API_PAGE_LIST_DIALER_API)) {
                getKeyList(account_id);
                if (!keysPermission) {
                    this.setState({
                        keysPermission: true
                    })
                }
            }
        }
    }

    render() {

        const {keyList, generateNewApiKey, newItem, loading} = this.props;
        const {apiTurn, pickedApiKey, resizedWidth, mobileSidebarShow, keysPermission} = this.state;

        const isMobile = resizedWidth < 768;

        return (
            <Page>
                <div>
                    {!isMobile &&
                        <>
                            <Spacer/>
                            <Divider />
                        </>
                    }
                    {isMobile &&
                        <>
                            <SidebarMenuToggle>
                                <IconButton
                                    onClick={() => this.toggleMobileSidebarShow(true)}
                                    circle={true}
                                    appearance="primary"
                                    icon={<Icon icon="list" />}
                                />
                            </SidebarMenuToggle>
                            <SidebarMenuDrawer 
                                size="xs" 
                                placement={this.props.rtl ? "right" : "left"}
                                show={mobileSidebarShow}
                                onHide={() => this.toggleMobileSidebarShow(false)}
                            >
                                <Drawer.Header>
                                    <Drawer.Title>API Navigation</Drawer.Title>
                                </Drawer.Header>
                                <Drawer.Body>
                                <ApiPageSidebar 
                                    {...{keyList, loading}}
                                    onSelect={(value) => {
                                        this.setState({apiTurn: value})
                                    }}
                                />        
                                </Drawer.Body>
                            </SidebarMenuDrawer>
                        </>
                    }
                    <FlexboxGrid align="top">
                        <Column width="25%">
                            { !isMobile &&
                                <Affix top={20}>

                                    <ApiPageSidebar 
                                        {...{keyList, loading}}
                                        onSelect={(value) => {
                                            this.setState({apiTurn: value})
                                        }}
                                    />   
                                    
                                </Affix>
                            }
                        </Column>
                        <Column main width="75%">
                                {(() => {
                                    switch (apiTurn) {
                                        case 'docs':
                                            return <ApiPageDocumentation
                                                methods={apiMethods}
                                                {...{pickedApiKey, newItem}}
                                            />;
                                        case 'keys':
                                            return keysPermission && <ApiPageKeys
                                                pickApiKey={this.pickApiKey}
                                                {...{keyList, generateNewApiKey, pickedApiKey, newItem}}/>;
                                        case 'examples_python':
                                        case 'examples_js':
                                            return <ApiPageExamples
                                                newItem={newItem}
                                                apiKey={pickedApiKey}
                                                methods={apiMethods}
                                                selectedPage={apiTurn}
                                            />;
                                        default:
                                            return null;
                                    }
                                })()}
                        </Column>
                    </FlexboxGrid>
                </div>
            </Page>
        );

    }

}

const SidebarMenuToggle = styled.div`
    bottom: 35px;
    left: 25px;
    position: fixed;
    z-index: 99

    .rtl & {
        left: auto;
        right: 25px;
    }
`;

const SidebarMenuDrawer = styled(Drawer)`
    &&& {
        width: 240px !important;
        z-index: 999999;
    }
`;

const Column = styled(FlexboxGrid.Item)`
    
    position: relative;
    display: block;
    min-height: 1px;
    width: 100%;
    
    @media (min-width: 768px) {
        width: ${props => props.width || 'auto'};
        // padding-left: ${props => props.main ? '20px' : '0'}
    }
`;