import React, {useRef, useMemo, useState} from "react";
import {Form, Alert, FormControl, Schema} from "rsuite";
import {BaseInputField, BaseSelectPicker} from "../../../components/base/BaseForm";
import {FlexGrid, FlexGridItem} from '../../../components/base/FlexGrid';
import {debounce, formatPercentage} from "../../../utils/helpers";
import {DEFAULT_PAYMENT_MISC_STATUS_EXPORTED} from "../../../const";
import {CustomModal} from "../../../components/base";
import styled from "styled-components";

const {NumberType} = Schema.Types;


const MiscPaymentRequestExportModal = ({
    show,
    data,
    onSuccess,
    onClose,
    selectedList,
    currencyList,
    globalSetting,

    exportFunc,
    ...props
}) => {
    const filtersModel = Schema.Model({
        commis_percent: NumberType()
            .min(0, "Minimum is 0%")
            .max(100, "Maximum is 100%")
            .pattern(/^\d+(\.\d{1,3})?$/, "Only three numbers after the period")
            .isRequired("This field is requred!"),
        conv_cur_key: NumberType()
            .isRequired("This field is requred!"),
    });

    // commis_percent from references

    const exportedStatus = DEFAULT_PAYMENT_MISC_STATUS_EXPORTED;

    const selectedData = data.filter((item) => selectedList.includes(item.id));

    const defaultExportValue = useMemo(() => {
        const onlyExportedData = selectedData.filter((item) => item.status === exportedStatus);
        const first = onlyExportedData.length ? onlyExportedData[0] : {};
        const formattedCommisionValue = first?.commis_percent ? first?.commis_percent : 0;
        const existCommisionPersent = formatPercentage(formattedCommisionValue, 3);
        const existConvCurKey = first?.conv_cur_key;
        // DEFAULT_PAYMENT_MISC_EXPORT_COMMIS
        const defaultMiscCommisPercent = existCommisionPersent || globalSetting["payment_method_Misc_percent"] * 100;
        // USD_DEFAULT_CURRENCY_KEY
        const defaultMiscCurrency = existConvCurKey || globalSetting["payment_method_Misc_cur_key"];

        return {commis_percent: defaultMiscCommisPercent, conv_cur_key: defaultMiscCurrency};
    }, [selectedData]);

    const formRef = useRef(null);
    const [exportValueState, setExportValueState] = useState(defaultExportValue);
    const [showConfirmExportModal, setShowConfirmExportModal] = useState(false);

    const onConfirm = () => {
        setShowConfirmExportModal(true);
    };

    const onConfirmClose = () => {
        setShowConfirmExportModal(false);
    };

    const onSubmit = (data, checkedData, hasConflict=false) => {
        const checker = Object.keys(checkedData).map(i => checkedData[i].hasError).every(value => !value);
        if (checker) {
            const exportData = {
                ...data,
                commis_percent: data["commis_percent"] / 100.0
            };
            exportFunc(!hasConflict, selectedList, exportData).then((res) => {
                if (!hasConflict && (!res || res && !res.error)) {
                    onSuccess();
                }    
            });

            
            setExportValueState(defaultExportValue);
            onConfirmClose();
        }
    };

    return (<>
        <StyledModal
            show={show}
            title="Export payment"
            onConfirm={() => {
                if (!formRef.current.check())
                    return;
                onConfirm();
            }}
            onClose={() => {
                setExportValueState(defaultExportValue);
                onClose();
            }}
            showFooter
            width={500}
            
        >
            <Form
                ref={formRef}
                model={filtersModel}
                // formValue={exportValueState}
                formDefaultValue={defaultExportValue}
                onChange={debounce((data) => {
                    setExportValueState(data);
                }, 1000)}
                // fluid
            >
                <StyledText>Enter the commission percentage and select the currency for export</StyledText>
                <StyledFlexGrid align="top">
                    <FlexGridItem width="140px">
                        <FormControl
                            name="commis_percent"
                            accepter={BaseInputField}
                            errorPlacement="topStart"
                            placeholder={"Commision"}
                        />
                    </FlexGridItem>
                    <FlexGridItem width="140px">
                        <FormControl
                            name="conv_cur_key"
                            accepter={BaseSelectPicker}
                            data={currencyList}
                            errorPlacement="topEnd"
                            labelKey="name"
                            valueKey="cur_key"
                            searchable={false}
                            placeholder={"Currency"}
                        />
                    </FlexGridItem>
                </StyledFlexGrid>
            </Form>
        </StyledModal>
        {showConfirmExportModal && <StyledModal
            show={showConfirmExportModal}
            title="Confirmation"
            onConfirm={() => {
                const checkedData = filtersModel.check(exportValueState);
                const firstSelectedData = data.find((item) => item.id === selectedList[0]);
                const hasConflict = (formatPercentage(firstSelectedData.commis_percent, 3) !== exportValueState.commis_percent
                    || firstSelectedData.conv_cur_key !== exportValueState.conv_cur_key)
                    && firstSelectedData.status === exportedStatus;
                
                onSubmit(exportValueState, checkedData, hasConflict);
            }}
            onClose={() => {
                onConfirmClose();
            }}
            showFooter
            width={460}
        >
            After you confirm you can't change discount anymore
        </StyledModal>}
    </>)
};

export default MiscPaymentRequestExportModal;


const StyledText = styled.div`
    && {
        margin-bottom: 10px;
    }
`;

const StyledModal = styled(CustomModal)`
    && > .rs-modal-body {
        overflow: none;
    }
`;

const StyledFlexGrid = styled(FlexGrid)`
    && {
        width: 300px;
    }
`;