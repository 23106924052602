import React from "react";
import {CheckPicker, Form, FormControl} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../../components/base/FlexGrid";
import FieldDataRange from "../../../components/Form/FieldDataRange";
import {debounce} from "../../../utils";

export default ({filters, onChangeFilters, accounts}) => {

    const filteredAccounts = accounts.filter( item => {
        return item.is_managed;
    } );

    const formattedAccounts = filteredAccounts.map((account) => {
        return {
            ...account,
            name: `${account.name} / ${account.service}`
        }
    });

    return (
        <Form
            formDefaultValue={filters}
            onChange={debounce((value) => {
                onChangeFilters(value);
            }, 500)}
        >
            <FlexGrid align="middle">
                <FlexGridItem>
                    <FormControl
                        accepter={CheckPicker}
                        sticky
                        name="account_id_list"
                        data={formattedAccounts}
                        valueKey="id"
                        labelKey="name"
                        style={{ width: 224 }}
                    />
                </FlexGridItem>
                <FlexGridItem>Date from</FlexGridItem>
                <FlexGridItem>
                    <FieldDataRange name="start_end_date"/>
                </FlexGridItem>
            </FlexGrid>
        </Form>
    )
}