import React, {useState, useEffect, useRef, useMemo} from 'react';
import {api, getFileResponse} from 'api/loginRoutes';
import AccountsGetForm from 'components/Filters/AccountsGetForm';
import {ButtonPrimary} from "../../../../components/base/BaseButton";
import {CustomModal} from '../../../../components/base/BaseModal';
import styled from "styled-components";
import DialerTrunk from 'components/Filters/DialerTrunk';
import AllocateForm from './AllocateForm';
import {servicePick, getBlobContent, getServiceSmsPrefix} from 'utils';
import ModalAllocationResult from '../../../../components/Modal/ModalAllocationResult';
import { SMS_ACCOUNT_ID } from 'const';
import {
    TRUNK_NUMBER_GET_LIST_API,
    TRUNK_LIST_FOR_ACCOUNTS_METHOD,
    ALLOCATION_INCORPORATED_BY_MANAGER_API,
    SMS_ALLOCATION_GAN_BY_MANAGER_API
} from '../../../../const/apiMethods';


export default ({
    disabled,
    extraParams,
    currentGroupData,
    showReasonHandler,
    service,
    isSupplier,
    onSuccess,
    method
}) => {

    const defaultTrunkId = null;
    const currentMethod = method || servicePick(service, ALLOCATION_INCORPORATED_BY_MANAGER_API, SMS_ALLOCATION_GAN_BY_MANAGER_API);

    const formRef = useRef(null);

    const [show, setShow] = useState(false);
    const [trunkId, setTrunkId] = useState(defaultTrunkId);
    const [formValue, setFormValue] = useState({});
    const [modalLoading, setModalLoading] = useState(false);

    const [dialers, setDialers] = useState([]);
    const [accountFilter, setAccountFilter] = useState(null);
    const [accountFilterLoading, setAccountFilterLoading] = useState(false);

    // result state
    const [showResult, setShowResult] = useState(false);
    const [summaryState, setSummaryState] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [csvContent, setCsvContent] = useState(null);
    const [totalNumbersForced, setTotalNumbersForced] = useState(null);
    const [clearTrunk, setClearTrunk] = useState(false);

    const [hashCode, setHashCode] = useState(null);

    const trunkListMethod = getServiceSmsPrefix(service, TRUNK_LIST_FOR_ACCOUNTS_METHOD);

     useEffect(() => {
         setClearTrunk(false);
     }, []);
 
    const smsSupplier = isSupplier || !service;

    useEffect(() => {
        if (dialers.length) {
            const firstTrunk = dialers[0];
            const currentTrunkIdList = dialers.map((trunk) => trunk.trunk_id);

            if (!currentTrunkIdList.includes(trunkId)) {
                setTrunkId(firstTrunk.trunk_id);
            }
        }
    }, [dialers]);


    useEffect(() => {
        if (show) {
            api(trunkListMethod, {
                filter: {
                    gan: true,
                    str: accountFilter || ""
                }
            }).then(({trunk_list}) => {
                setDialers((trunk_list || [])
                    .map(x => ({
                        ...x,
                        trunk_id: x.trunk_id,
                        _name: `${x.acc_name}\\${x.tr_name}\\${x.sp_name}`
                    }))
                );
            });
        }
    }, [show, accountFilter]);

    const activeTrunk = useMemo(() => {
        return dialers.find(item => item.trunk_id === trunkId) || null;
    }, [dialers, trunkId]);


    const onSubmit = async () => {

        if ( !formRef.current.check() )
            return;

        const params = {
            ...extraParams,

            target: {
                ...(Object.keys(extraParams).includes("target") && extraParams?.target),
                [smsSupplier ? "sms.trunk_id" : "trunk_id"]: trunkId
            },

            rate: +formValue.custom_rate
        };

        setModalLoading(true);

        const result = await api(currentMethod, params);

        if (result) {

            if (result.hash) {
                setHashCode(result.hash);
            }

            const activeTrunk = dialers.find(item => item.trunk_id === trunkId);

            if (result.reason_code && (!result.trunk_number_transaction || !result.trunk_number_transaction.numbers) ) {
                showReasonHandler({
                    ...params,
                    reasonCode: result.reason_code,
                    reasonHash: result.hash,
                    trunk: activeTrunk || {},
                    notAllocatedNumbers: result.not_allocated_numbers
                });
                setModalLoading(false);
                return;
            }

            const {trunk_number_transaction} = result;
            const summaryInfo = {...trunk_number_transaction};
            const transactionId = summaryInfo.id;
  
            const method = getServiceSmsPrefix(service, TRUNK_NUMBER_GET_LIST_API);

            getFileResponse(method, {target: {[getServiceSmsPrefix(service, 'trunk_number_transaction_id')]: transactionId}})
                .then((response) => {
                    if (response) {
                        const csvText = getBlobContent(response);
                        csvText.then((text) => {
                            setCsvContent(text);
                        });
                    }
                });

            setSummaryState(summaryInfo);
            setTransactionId(transactionId);

            setTotalNumbersForced(summaryInfo.numbers);
            setShowResult(true);
            setClearTrunk(true);

            onSuccess();
            setAccountFilter(null);
            setShow(false);
        }

        setModalLoading(false);
    };

    return (
        <>
            <ButtonPrimary
                disabled={disabled} 
                onClick={() => setShow(true)}
            >
                Allocate
            </ButtonPrimary>

            <CustomModal
                show={show}
                title="Allocate numbers"
                onClose={() => {
                    setShow(false);
                    setClearTrunk(true);
                    setAccountFilter(null);
                    setFormValue({});
                }}
                onConfirm={onSubmit}
                showFooter={true}
                loading={modalLoading}
                disabled={!trunkId || !isSupplier && !formValue.custom_rate}
                extraDisabled={!dialers.length}
                confirmButtonText="Confirm"
            >
                Choose dialer and trunk.

                <AccountsGetFormWrapper>
                    <AccountsGetForm
                        setLoading={setAccountFilterLoading}
                        setAccountFilter={setAccountFilter}
                    />
                </AccountsGetFormWrapper>

                <DialerTrunk
                    trunkId={trunkId}
                    clearTrunk={clearTrunk}
                    disabledTrunk={disabled}
                    // accounts={accountFilter}
                    dialerTrunkList={dialers}
                    setTrunkId={setTrunkId}
                    
                    onClear={() => {
                        setTrunkId(null);
                    }}
                    onChange={(trunk) => setTrunkId(trunk.trunk_id)}
                />

                <AllocateForm
                    currentGroupData={currentGroupData}
                    trunkId={trunkId}
                    isSupplier={isSupplier}
                    trunksList={dialers}
                    updateFormRef={ref => formRef.current = ref}

                    disabled={disabled || accountFilterLoading}
                    formValue={formValue}
                    setFormValue={setFormValue}
                />
            </CustomModal>

            <ModalAllocationResult
                showFullList={true}
                hideBufferButtons={false}
                
                show={showResult}
                summaryState={summaryState}
                totalNumbersForced={totalNumbersForced}
                trunkId={trunkId}
                defaultTrunkId={defaultTrunkId}
                service={service}
                csvContent={csvContent}
                transactionId={transactionId}

                isSms={activeTrunk?.account_id === SMS_ACCOUNT_ID}
                hashCode={hashCode}

                onClose={() => {
                    setShowResult(false);

                    if (totalNumbersForced)
                        setTotalNumbersForced(null);

                    if (csvContent)
                        setCsvContent(null);
           
                    if (hashCode)
                        setHashCode(null);
                }}
            />
        </>

    );
};


const AccountsGetFormWrapper = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
`;