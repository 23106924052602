import React from "react";
import CommonTable from "../CommonTable";

export default ({
    height = null,
    data = [],
    loading = false,
    withFilters = false,
}) => {

    const columns = [
        {
            id: 'amount',
            label: "Amount",
            value: ({sum, currency_name}) => `${sum}${currency_name ? ' ' + currency_name : ''}`,
            flexGrow: !withFilters ? 2 : null,
            width: withFilters ? 200 : 150,
            align: 'center'
        },
        {
            id: 'date',
            label: "Date",
            dataKey: "date",
            width: 150,
            flexGrow: !withFilters ? 2 : null,
            width: withFilters ? 200 : 150,
            align: 'center'
        },
        {
            id: 'paymentDetails',
            label: "Payment details",
            dataKey: "bank_detail",
            flexGrow: !withFilters ? 2 : null,
            width: withFilters ? 200 : 150,
            align: 'center'
        },
    ];

    if (withFilters) {
        columns.unshift({
            id: 'account',
            label: "Account",
            value: ({account_name, account_manager_name}) => `${account_name} / ${account_manager_name || 'no manager'}`,
            flexGrow: 2,
            minWidth: 300,
            align: 'center'
        })
    }

    return (
        <CommonTable
            height={height}
            data={data}
            loading={loading}
            columns={columns}
        />
    )
}