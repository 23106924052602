import React from 'react';
import {api} from '../../../api/loginRoutes';
import {Alert} from 'rsuite';
import Icon from 'rsuite/es/Icon';
import {ButtonClose} from "../../../components/base/BaseButton";
import {CustomModal} from '../../../components/base/BaseModal';


export default (
    {
        extraParams,
        disabled,
        onSuccess,
        checkKey='incorporated_numbers',
        method='incorporated_number:set_block_allocation'
    }
) => {
    const [show, setShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);


    const onSubmit = async () => {

        setLoading(true);
        const result = await api(method, {...extraParams});

        if (result && result[checkKey]) {
            Alert.success(`${result[checkKey]} number were blocked`);
            setShow(false);
            onSuccess();
        }
        setLoading(false);

    };
    return (
        <>
            <ButtonClose
                disabled={disabled}
                onClick={() => setShow(true)}
            >
                <Icon icon="lock"/> Block
            </ButtonClose>
            
            <CustomModal
                show={show}
                title="Allocate numbers"
                onClose={() => setShow(false)}
                showFooter={true}
                confirmButtonText="Confirm"
                loading={loading}
                onConfirm={onSubmit}
            >
                Selected numbers will be set as blocked. It means you can't allocate these numbers to any dialer or set test
            </CustomModal>
        </>

    );
}
