import React from 'react';
import TrafficStatTable from './TrafficStatTable';
import TrafficStatFilters from './TrafficStatFilters';
import {removeTZFromDate} from '../../../utils/helpers';
import {ButtonPrimary} from '../../../components/base/BaseButton';
import {
    LOCAL_STORAGE_TRAFFIC_STAT_COLUMNS_DIALER,
    LOCAL_STORAGE_TRAFFIC_STAT_FILTERS_DIALER,
    LOCAL_STORAGE_TRAFFIC_STAT_PER_PAGE_DIALER,
    MAX_PER_PAGE_DIALER,
    MIN_PER_PAGE_DIALER
} from '../../../const';
import {injectIntl} from "react-intl";
import {FlexGrid, FlexGridItem} from '../../../components/base/FlexGrid';
import { Spacer } from '../../../components/base/Spacer';
import {Drawer, Schema, Icon, IconButton} from "rsuite";
import styled from "styled-components";
import m from "../../../definedMessages";
import ButtonDownload from "../../../components/client/ButtonDownload";
import {servicePick, servicePickWithNull, getServiceByLocation} from "../../../utils/helpers";
import { relativeTimeThreshold } from 'moment';
import {toUTCDateTime} from "utils";

const {ArrayType, StringType} = Schema.Types;
const MIN_CHARACTERS = 3;
const MAX_CHARACTERS = 40;

class TrafficStat extends React.Component {
    constructor(props) {
        super(props);

        const todayDateStart = new Date(new Date().setHours(0, 0, 0, 0));
        const todayDateEnd = new Date(new Date().setHours(23, 59, 59, 999));

        const savedTableFilter = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TRAFFIC_STAT_FILTERS_DIALER));
        localStorage.removeItem(LOCAL_STORAGE_TRAFFIC_STAT_FILTERS_DIALER);

        this.defaultFilter = savedTableFilter
            ? {...savedTableFilter}
            : {
                range: [todayDateStart, todayDateEnd],
                start_date: removeTZFromDate(new Date(new Date().setHours(0, 0, 0, 0))),
                end_date: removeTZFromDate(new Date(new Date().setHours(23, 59, 59, 999))),
                group: 'range/a_number/b_number',
                checkbox: ['range', 'a_number', 'b_number']
            };
        
        this.defaultFilterSms = savedTableFilter
            ? {...savedTableFilter}
            : {
                range: [todayDateStart, todayDateEnd],
                start_date: removeTZFromDate(new Date(new Date().setHours(0, 0, 0, 0))),
                end_date: removeTZFromDate(new Date(new Date().setHours(23, 59, 59, 999))),
                group: 'range/senderid/phone',
                checkbox: ['range', 'senderid', 'phone'] 
            };
        
        this.savedTableFilter = savedTableFilter;

        this.formatMessage = this.props.intl.formatMessage.bind(this.props.intl);

        this.transformedService = {
            'voice': true,
            'sms': false
        };

        this.curPerPage = null;
        this.columns = [
            {
                id: 'a_number',
                label: this.formatMessage(m.aNumber),
                dataKey: 'a_number',
                flexGrow: 5, 
                minWidth: 130,
                align: 'center'
            },
            {
                id: 'b_number',
                label: this.formatMessage(m.bNumber),
                dataKey: 'b_number',
                flexGrow: 5,
                minWidth: 130,
                align: 'center'
            },
            {
                id: 'range', 
                label: this.formatMessage(m.ranges), 
                dataKey: 'range',
                flexGrow: 5, 
                minWidth: 150, 
                align: 'center'
            },
            {
                id: 'duration', 
                label: this.formatMessage(m.duration), 
                dataKey: 'sum_duration',
                value: (item) => {
                    return item.id === "summaries"
                        ? <span title={item.sum_duration}>{item.sum_duration}</span>
                        : item.sum_duration
                },
                flexGrow: 3, 
                minWidth: 80,
                hideOnService: this.transformedService['sms'],
                align: 'right'
            },
            {
                id: 'call', 
                label: servicePickWithNull(props.service, this.formatMessage(m.calls), this.formatMessage(m.messages)), 
                dataKey: servicePickWithNull(props.service, 'count_cost_dialer', 'count_rate_dialer'), 
                value: ({count_cost_dialer, count_rate_dialer}) => {
                    return servicePickWithNull(props.service, count_cost_dialer, count_rate_dialer);
                },
                flexGrow: 3,
                minWidth: 80, 
                align: 'right'
            },
            {
                id: 'cost', 
                label: this.formatMessage(m.cost), 
                dataKey: servicePickWithNull(props.service, 'sum_cost_dialer', 'sum_rate_dialer'),
                value: ({sum_cost_dialer, sum_rate_dialer, cur_name}) => {
                    let value = servicePickWithNull(props.service, sum_cost_dialer, sum_rate_dialer);
                    
                    if (value && cur_name) {
                        return `${value} ${cur_name}`;
                    } else if (value && !cur_name) {
                        return value;
                    }
                    return '';
                },
                flexGrow: 3,
                minWidth: 80,
                align: 'right'
            },

        ];

        this.filtersModel = Schema.Model({
            // range: ArrayType()
            //     .addRule((value) => {
            //         if (value && value[0] && value[1]) {
            //             const startDate = new Date(value[0]);
            //             const endDate = new Date(value[1]);
            //             const startDay = startDate.getDate();
            //             const endDay = endDate.getDate();

            //             if ((endDay-startDay) > 1) {
            //                 return false
            //             }
            //         }
            //         return true;
            //     },  "The filter is only available for the selected two days"),
            a_number: StringType()
                .pattern(/^\d+$/, this.formatMessage(m.correctNumber))
                // .minLength(MIN_CHARACTERS, this.formatMessage(m.minLength, {count: MIN_CHARACTERS}))
                .maxLength(MAX_CHARACTERS, this.formatMessage(m.maxLength, {count: MAX_CHARACTERS}))
        });

        this.state = {
            searchLoading: false,
            filter: {...(props.service ? this.defaultFilter : this.defaultFilterSms)},
            savedPerPage: JSON.parse(localStorage.getItem(LOCAL_STORAGE_TRAFFIC_STAT_PER_PAGE_DIALER)),
            savedTableColumns: JSON.parse(localStorage.getItem(LOCAL_STORAGE_TRAFFIC_STAT_COLUMNS_DIALER)),
            resizedWidth: window.innerWidth,
            activeFiltersCount: 0,
            showFilters: false,
            showColumns: this.columns,
            currentType: props.prevType,
        };
    }

    filterReplaceGroupOption = (filter, from, to) => {
        const newCheckbox = [...(filter?.checkbox || [])];
        newCheckbox[newCheckbox.indexOf(from)] = to;
        const newGroup = (filter?.group || "").replace(from, to);
        const newFilter = {
            ...filter,
            checkbox: newCheckbox,
            group: newGroup
        };

        // const checker = this.filtersModel.check(filter);
        // const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        // if (!formHasError) {
        //     getTrafficStat(savedPerPage || per_page, filter, 1, filter.group, filter.checkbox);
        // }
        return newFilter;
    }

    componentDidUpdate = (prevProps) => {
        const {savedPerPage, currentType, filter} = this.state;
        const {getTrafficStat, per_page, service, prevType, type} = this.props;

        // if (prevProps.service !== service) {
        //     currentFilter = changeFilterByService(service, currentFilter);
        // }

        if (prevProps.service !== service || (prevProps.service === service && currentType !== type)) {
            if (currentType !== type) {
                this.setState({
                    currentType: type
                });
                // window.innerWidth = 300;
            }

            const columns = this.columns.filter(column => ('hideOnService' in column && column?.hideOnService !== service) || !('hideOnService' in column));
            const changedColumns = columns.reduce((result, column) => {
                if (column?.id === "a_number" && service === false) {
                    column.dataKey = "senderid";
                    column.label = this.formatMessage(m.senderId);
                }
                if (column?.id === "b_number" && service === false) {
                    column.dataKey = "phone";
                }
                result.push(column)
                return result;
            }, []);

            const columnsIdList = changedColumns.map(column => column.id);
            this.onSaveColumnsTable(columnsIdList);
            this.setState({
                showColumns: changedColumns,
                savedTableColumns: columnsIdList
            });
            // let newFilter = filter;
            // if (service === false) {
            //     newFilter = this.filterReplaceGroupOption("a_number", "senderid")
            // } else if (service === true) {
            //     newFilter = this.filterReplaceGroupOption("senderid", "a_number")
            // }

            this.curPerPage = savedPerPage;
            // const serviceByLocation = getServiceByLocation(location);
            // if (service !== null) {
            //     getTrafficStat(service, savedPerPage || per_page, currentFilter, 1, currentFilter.group, currentFilter.checkbox);
            // }
        }

        if (prevProps.service !== service) {
            const filterValues = {...filter};
            let currentFilter = this.changeFilterByService(service, filterValues);

            if (service !== null) {
                getTrafficStat(service, savedPerPage || per_page, currentFilter, 1, currentFilter.group, currentFilter.checkbox);
            }
        }

    } 

    componentDidMount() {
        const {savedPerPage, currentType, filter} = this.state;
        const {getTrafficStat, type, prevType, location, per_page, service} = this.props;

        let currentFilter = {...filter};

        this.setActiveFiltersCount(currentFilter);

        if (currentType === prevType) {
            this.setState({currentType: !type});
        }
        
        if (service !== null) {
            this.curPerPage = savedPerPage;
            getTrafficStat(service, savedPerPage || per_page, currentFilter, 1, currentFilter.group, currentFilter.checkbox);
        }
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };

    onChangeFilters = (filtersObj) => {
        const {savedPerPage} = this.state;
        const {getTrafficStat, setEmptyTrafficStat, per_page, service} = this.props;

        this.setActiveFiltersCount(filtersObj);

        this.setState({
            filter: filtersObj,
            preparedFilter: filtersObj
        });

        // const checker = this.filtersModel.check(filtersObj);
        // const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        // if (!formHasError) {
        //     getTrafficStat(service, savedPerPage || this.curPerPage || per_page, filtersObj, 1, filtersObj.group, filtersObj.checkbox);
        // } 
        // else {
        //     setEmptyTrafficStat();
        // }
    };

    setActiveFiltersCount = (value = null) => {
        if (!value)
            return;

        let activeFilters = 0;
        activeFilters = value.checkbox.length !== 3 ? activeFilters + 1 : activeFilters;
        activeFilters = value.a_number ? activeFilters + 1 : activeFilters;

        this.setState({
            activeFiltersCount: activeFilters + 1
        });
    };

    onChangePerPage = (perPage) => {
        const {filter} = this.state;
        const {getTrafficStat, service} = this.props;

        if (perPage)
            this.setState({savedPerPage: null});
        else
            this.setState({savedPerPage: this.curPerPage});

        const per_page = Number(perPage) || this.curPerPage;

        this.curPerPage = per_page <= 0
            ? MIN_PER_PAGE_DIALER
            : per_page > MAX_PER_PAGE_DIALER ? MAX_PER_PAGE_DIALER : per_page;

        localStorage.setItem(LOCAL_STORAGE_TRAFFIC_STAT_PER_PAGE_DIALER, JSON.stringify(this.curPerPage));

        const checker = this.filtersModel.check(filter);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError) {
            getTrafficStat(service, this.curPerPage, filter, 1, filter.group, filter.checkbox);
        }
    };

    onChangePage = (pageNumber) => {
        const {filter, savedPerPage} = this.state;
        const {getTrafficStat, per_page, service} = this.props;

        const checker = this.filtersModel.check(filter);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError) {
            getTrafficStat(service, savedPerPage || this.curPerPage || per_page, filter, pageNumber, filter.group, filter.checkbox);
        }
    };

    changeFilterByService = (service) => {
        const {filter} = this.state;

        const todayDateStart = new Date(new Date().setHours(0, 0, 0, 0));
        const todayDateEnd = new Date(new Date().setHours(23, 59, 59, 999));

        const groupsList = service ? ['range', 'a_number', 'b_number'] : ['range', 'senderid', 'phone'];
        const groupsString = service ? 'range/a_number/b_number' : 'range/senderid/phone';

        const defaultFilter = {
            range: [todayDateStart, todayDateEnd],
            start_date: new Date(),
            end_date: new Date(),
            group: groupsString,
            ...(service ? {a_number: ''} : {senderid: ''})
        };
    
        const defaultDataFilter = {
            ...defaultFilter,
            checkbox: groupsList,
            start_date: removeTZFromDate(todayDateStart),
            end_date: removeTZFromDate(todayDateEnd),
            ...this.savedTableFilter
        };


        let currentFilter = defaultDataFilter;

        if (service === false) {
            currentFilter = this.filterReplaceGroupOption(currentFilter, "a_number", "senderid");
            currentFilter = this.filterReplaceGroupOption(currentFilter, "b_number", "phone");
        } else if (service === true) {
            currentFilter = this.filterReplaceGroupOption(currentFilter, "senderid", "a_number");
            currentFilter = this.filterReplaceGroupOption(currentFilter, "phone", "b_number");
        }

        this.setState({
            filter: currentFilter
        });

        return currentFilter;
    }

    exportReport = () => {
        const {filter} = this.state;
        const {service} = this.props;

        this.props.exportReport(service, filter, filter.group, filter.checkbox);
    };

    onSaveColumnsTable = (idListToSave) => {
        localStorage.setItem(LOCAL_STORAGE_TRAFFIC_STAT_COLUMNS_DIALER, JSON.stringify(idListToSave));
    };

    render() {
        const {showColumns, searchLoading, filter, savedPerPage, savedTableColumns} = this.state;
        const {
            type, page, loading, uploadLoading, trafficStat, count, per_page, service, summaries, globalSettings,
            getTrafficStat
        } = this.props;
        const onChangePage = this.onChangePage;
        const onChangePerPage = this.onChangePerPage;
        const exportReport = this.exportReport;
        const onSaveColumnsTable = this.onSaveColumnsTable;

        // remake with reselector
        let dataWithSummaries = [];
        let summariesData = {};
        
        if (Object.keys(summaries).length && trafficStat.length) {
            summariesData = {...trafficStat[0]};

            Object.keys(summariesData).forEach(item => {

                switch (item) {
                    case "sum_cost_dialer":
                        summariesData[item] = summaries["cost"] !== undefined ? summaries["cost"] : null;
                        break;

                    case "count_cost_dialer":
                        summariesData[item] = summaries["calls"] || null;
                        break;

                    case "sum_duration":
                        summariesData[item] = summaries["duration"] || null;
                        break;
                    
                    case "id":
                        summariesData[item] = "summaries";
                        break;
                    
                    default:
                        summariesData[item] = summaries[item] || null;
                }
                
            });

            dataWithSummaries = [...trafficStat];
            dataWithSummaries.push(summariesData);
        }
        
        return (
            <>
                {this.state.resizedWidth < 768 &&
                    <>
                        <ButtonApplyFilterMobile
                             onClick={() => {
                                const checker = this.filtersModel.check(filter);
                                const formHasError = Object.keys(checker).some(key => checker[key].hasError);

                                const preparedFilterValues = {...filter};

                                if (preparedFilterValues.range && preparedFilterValues.range.length) {
                                    preparedFilterValues['start_date'] = toUTCDateTime(preparedFilterValues.range[0]);
                                    preparedFilterValues['end_date'] = toUTCDateTime(preparedFilterValues.range[1]);
                        
                                    delete preparedFilterValues.range;
                                }

                                if (!formHasError) {
                                    getTrafficStat(service, savedPerPage || this.curPerPage || per_page, preparedFilterValues, 1, preparedFilterValues.group, preparedFilterValues.checkbox);
                                }
                            }}
                        >
                            {this.formatMessage(m.applyFilter)}
                        </ButtonApplyFilterMobile>
                        <Toggler
                            icon={<Icon icon="filter"/>}
                            onClick={() => this.setState({showFilters: true})}
                        >
                            {this.state.activeFiltersCount}
                        </Toggler>
                        <Drawer
                            size="xs"
                            placement="bottom"
                            style={{height: '260px'}}
                            show={this.state.showFilters}
                            onHide={() => this.setState({showFilters: false})}
                        >
                            <Drawer.Header>
                            </Drawer.Header>
                            <DrawerBody>
                                <TrafficStatFilters
                                    onChangeFilters={this.onChangeFilters}
                                    loading={loading || searchLoading}
                                    filtersModel={this.filtersModel}
                                    {...{
                                        service,
                                        filter,
                                        globalSettings
                                    }}
                                    mobile
                                />
                            </DrawerBody>
                        </Drawer>
                    </>
                }

                <Spacer size={30}/>

                {this.state.resizedWidth >= 768 &&
                    <>
                        <FlexGrid justify="space-between">
                            <FlexGridItem>
                                <TrafficStatFilters
                                    onChangeFilters={this.onChangeFilters}
                                    loading={loading || searchLoading}
                                    filtersModel={this.filtersModel}
                                    {...{
                                        filter,
                                        service,
                                        globalSettings
                                    }}
                                />
                            </FlexGridItem>
                            <FlexGridItem>
                                <ButtonApplyFilter
                                    onClick={() => {
                                        const checker = this.filtersModel.check(filter);
                                        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

                                        const preparedFilterValues = {...filter};

                                        if (preparedFilterValues.range && preparedFilterValues.range.length) {
                                            preparedFilterValues['start_date'] = toUTCDateTime(preparedFilterValues.range[0]);
                                            preparedFilterValues['end_date'] = toUTCDateTime(preparedFilterValues.range[1]);
                                
                                            delete preparedFilterValues.range;
                                        }

                                        if (!formHasError) {
                                            getTrafficStat(service, savedPerPage || this.curPerPage || per_page, preparedFilterValues, 1, preparedFilterValues.group, preparedFilterValues.checkbox);
                                        }
                                    }}
                                >
                                    {this.formatMessage(m.applyFilter)}
                                </ButtonApplyFilter>
                                <ButtonDownload
                                    onDownload={exportReport}
                                    limitWidth={875}
                                    buttonText={this.formatMessage(m.exportReport)}
                                    loading={uploadLoading}
                                />
                            </FlexGridItem>
                        </FlexGrid>

                        <Spacer size={19}/>
                    </>
                }

                <Spacer size={1}/>

                <TrafficStatTable
                    data={Object.keys(summariesData).length ? dataWithSummaries : trafficStat}
                    extraRows={Object.keys(summariesData).length ? 1 : 0}
                    pickedColumns={showColumns}
                    loading={loading || searchLoading}
                    {...{
                        onChangePage,
                        onChangePerPage,
                        type,
                        page,
                        per_page,
                        count,
                        service,
                        savedPerPage,
                        savedTableColumns,
                        onSaveColumnsTable
                    }}
                />
                {this.state.resizedWidth < 768 &&
                    <StyledActionButtonWrapper>
                        <ButtonDownload
                            onDownload={exportReport}
                            limitWidth={875}
                            buttonText={this.formatMessage(m.exportReport)}
                            loading={uploadLoading}
                        />
                    </StyledActionButtonWrapper>
                }
            </>
        );
    }
}

export default injectIntl(TrafficStat)

const Toggler = styled(IconButton)`
    && {
        color: #363434;
        float: right;
        margin-top: 6px;
    }
`;

const ButtonApplyFilter = styled(ButtonPrimary)`
    && {
        margin-right: 20px;
    }
`;

const StyledActionButtonWrapper = styled.div`
    padding-top: 15px;
    text-align: center;
    
    @media (min-width: 768px) {
        text-align: right;
    }
`;

const DrawerBody = styled(Drawer.Body)`
    && {
        position: initial;
    }
    && .dateTimePicker {
        height: 40px;
    }

    && .dateTimePicker.dateFrom {
        margin-top: -485px;
        position: absolute;
    }
    && .dateTimePicker.dateTo {
        margin-top: -165px;
        position: absolute;
    }
    && .dateTimePicker .dateTimeName {
        color: var(--color-light);
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 10px;
    }
`;

const ButtonApplyFilterMobile = styled(ButtonPrimary)`
    && {
        position: absolute;
        right: 100px;
    }
`;
