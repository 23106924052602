import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Button, SelectPicker} from 'rsuite';

export default ({actions, selected, disabled, loading = false, disabledItemValues = []}) => {
    const ref = React.createRef();

    const [selectedAction, setSelectedAction] = useState(null);

    useEffect(() => {
        setSelectedAction(null);
    }, [selected?.list]);

    const onClick = () => actions.find((action) => action.label === selectedAction).handler();

    return (
        <>
            <Label>With selected:</Label>

            <SelectPicker
                data={actions}
                valueKey="label"
                appearance="default"
                cleanable={false}
                value={selectedAction}
                searchable={false}
                disabled={disabled}
                onChange={(actionIndex) => setSelectedAction(actionIndex)}
                style={{width: 224}}
                ref={ref}
                disabledItemValues={disabledItemValues}
                placement={'autoVerticalEnd'}
            />

            <ButtonCss>
                <Button onClick={onClick} loading={loading} disabled={!selectedAction || disabled}>Go</Button>
            </ButtonCss>
        </>
    );
};

const Label = styled.span`
    padding-right: 5px;
    display: inline-block;
`;

const ButtonCss = styled.div`
    display: inline-block;
    padding: 0 15px;
    button {
        background: #20BA88;
        color: #fff;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.16);
    }
`;