import React, {useEffect, useState, useMemo} from 'react';
import PageHeader from '../../components/base/PageHeader';
import {connect} from 'react-redux';
import PanelLayout from '../../components/base/PanelLayout';
import RoutingTable from './RoutingTable';
import RoutingCreateForm from './RoutingCreateForm';
import {getRoutingList, onCreateRoutingItem, onModifyRoutingItem, onRemoveRoutingItem} from 'actions/routing';
import './Routing.css'
import {useWindowWidth} from 'hooks';
import {getReferencesForReports} from 'actions/references';
import RoutingFilter from './RoutingFilter';


const RoutingPage = ({
    loading,
    routingList,
    dialerList,
    supplierList,
    getRoutingList,
    onCreateRoutingItem,
    onModifyRoutingItem,
    onRemoveRoutingItem,
    getReferencesForReports,
    ...props
}) => {
    const defaultFilter = {
        is_exception: null,
        supplier_id: null,
        senderid: "",
        mcc_mnc: "",
        mcc_mnc_match_like: null,
        dialer_id: null,
        min_rate: ""
    };

    const [filter, onChangeFilter] = useState(defaultFilter);
    const routingFilteredList = useMemo(() => {
        if (!routingList)
            return [];

        const filteredData = routingList.filter(item => {
            if (filter.is_exception !== null && filter.is_exception !== item.is_exception) {
                return false;
            }

            if (filter.min_rate !== null 
                && (filter.min_rate > item.min_rate)
                || item.min_rate === null
            ) {
                return  false;
            }

            if (filter.supplier_id && filter.supplier_id !== item.supplier_id) {
                return  false;
            }

            if (filter.dialer_id && filter.dialer_id !== item.dialer_id) {
                return  false;
            }

            if (filter.senderid && !item.senderid.startsWith(filter.senderid)) {
                return false;
            }

            if (filter.mcc_mnc && item.mcc_mnc && !item.mcc_mnc.startsWith(filter.mcc_mnc)) {
                return false;
            }

            if (filter.mcc_mnc_match_like !== null && filter.mcc_mnc_match_like !== item.mcc_mnc_match_like) {
                return false;
            }

            return true;
        });

        return filteredData;
    }, [routingList, filter]);

    useEffect(() => {
        getRoutingList();
        getReferencesForReports();
    }, []);

    const windowWidth = useWindowWidth();

    const mccMncMatchList = [
        {
            value: false,
            name: "Full match"
        },
        {
            value: true,
            name: "Not full match"
        }
    ];

    const exceptionList = [
        {
            value: true,
            name: "Allowed"
        },
        {
            value: false,
            name: "Blocked"
        }
    ];


    return (
        <PanelLayout>
            <PageHeader title="Routing"/>
            <RoutingFilter
                defaultFilter={defaultFilter}
                dialerList={dialerList}
                supplierList={supplierList}
                onChangeFilter={onChangeFilter}
                mccMncMatchList={mccMncMatchList}
                exceptionList={exceptionList}
            />
            <RoutingTable
                windowWidth={windowWidth}
                dialerList={dialerList}
                supplierList={supplierList}
                dataList={routingFilteredList}

                mccMncMatchList={mccMncMatchList}
                exceptionList={exceptionList}

                loading={loading}

                getList={getRoutingList}

                handleRowModify={onModifyRoutingItem}
                handleRowRemove={onRemoveRoutingItem}
            />
            <RoutingCreateForm
                onSubmit={onCreateRoutingItem}
                dialerList={dialerList}
                supplierList={supplierList}
                getRoutingList={getRoutingList}
                mccMncMatchList={mccMncMatchList}
                exceptionList={exceptionList}
            />
        </PanelLayout>
    );
};

const mapStateToProps = ({routing, references}) => ({
    dialerList: references['sms.dialer_list'],
    supplierList: references['sms.supplier_list'],
    routingList: routing.list,
    loading: routing.loading
});

const mapDispatchToProps = {
    getRoutingList,
    onCreateRoutingItem,
    onModifyRoutingItem,
    onRemoveRoutingItem,
    getReferencesForReports
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutingPage);

