import React, {Component, useContext} from 'react';
import ReactDOM from 'react-dom'
import {Notification, Table, Checkbox, CheckPicker} from 'rsuite';
import {injectIntl} from 'react-intl';
import Pagination from '../../../components/client/Pagination';
import {DEFAULT_PER_PAGE_DIALER, TWO_STAGE_DEFAULT_PER_PAGE_DIALER} from '../../../const';
import {MenuShowColumnsIcon} from '../../../components/base/MenuShowColumns';
import Speaker from '../Speaker';
import styled from 'styled-components';
import BaseTable from "../../base/BaseTable";
import m from "../../../definedMessages";
import {RtlContext} from "../../../App";
import { compareTwoArrays } from 'utils';

const { Column, HeaderCell, Cell } = Table;


class CustomizableTable extends Component {

    constructor(props) {
        super(props);
        
        this.selectedList = new Set();

        this.formatMessage = props.intl.formatMessage.bind(props.intl);

        this.state = {
            columnMenuShow: false,
            menuColumns: [],
            selectedRows: new Set(),
            selectAll: false
        }
    }
    componentDidUpdate = (prevProps) => {
        const {savedTableColumns} = this.props;
        
        if (savedTableColumns && prevProps.savedTableColumns) {
            if (!compareTwoArrays(savedTableColumns, prevProps.savedTableColumns)) {
                this.setState({menuColumns: this.getColumnsFromIds(savedTableColumns).map(({id: value, label})=>({value, label}))})
            }
        }
    }

    onChangeColumnsTable = (columns) => {
        this.props.onChangeColumns(columns)
    };

    onOpenColumnChangeMenu = (changed) => {
        this.setState({columnMenuShow: changed})
    };

    getColumnsFromIds = (id_list) => {
        return this.props.pickedColumns.filter(elem=>id_list.includes(elem.id));
    };

    onToggleItem = (id, isChecked) => {
        const {useSelectedData, data = [], row_key = 'id'} = this.props;

        if(isChecked) {
            this.selectedList.add(id);
        } else {
            this.selectedList.delete(id);
        }

        if (useSelectedData) {
            const checkedData = data.filter(item => item[row_key] === id);
            useSelectedData(checkedData, isChecked)
        }

        this.setSelectParent();
        this.forceUpdate();
    };

    onToggleAll = (isChecked) => {
        const {selectAll} = this.state;
        const {data, isPaginated, isSelectedAll, useSelectedData} = this.props;

        if (!isChecked) {
            Notification.close("allocatedNumberChooser");
        }

        if (isSelectedAll && isChecked && !selectAll && isPaginated) {
            const placement = this.props.rtl ? 'topStart' : 'topEnd';
            Notification.open({
                top: 67,
                duration: 0,
                description: [
                    <div key="description" className="mb-2">
                        {this.formatMessage(m.doYouWantToChooseRecordsOnAllPages)}
                    </div>,
                    <div key='speaker'>
                        <Speaker
                            successText={this.formatMessage(m.yes)}
                            cancelText={this.formatMessage(m.onlyOnThisPage)}
                            onSuccess={() => {
                                this.setState({selectAll: true}, this.setSelectParent);
                                Notification.closeAll();
                            }}
                            onCancel={() => {
                                Notification.closeAll();
                            }}
                        />
                    </div>
                ],
                key: 'allocatedNumberChooser',
                placement
            });
        } else if (!isChecked && selectAll) {
            this.setState({selectAll: false}, this.setSelectParent);
        }

        for (let row of data) {
            isChecked
                ? this.selectedList.add(this.gettingSelectedItem(row))
                : this.selectedList.delete(this.gettingSelectedItem(row))
        }

        if (useSelectedData) {
            useSelectedData(data, isChecked)
        }

        this.setSelectParent();
        this.forceUpdate();
    };

    setSelectParent = () => {
        const {setSelected} = this.props;
        const {selectAll} = this.state;

        if (!setSelected) return;

        setSelected({
            list: Array.from(this.selectedList.values()),
            all: selectAll
        })
    };

    gettingSelectedItem = (row) => {
        const { row_key = 'id' } = this.props;
        return row[row_key];
    };

    clear = () => {
        const { isSelect } = this.props;
        const {selectAll } = this.state;
        if(isSelect) {
            this.selectedList.clear();

            if(selectAll) {
                this.setState({selectAll: false}, this.setSelectParent)
            } else {
                this.setSelectParent()
            }

        }
    };

    render() {
        const {
            data,
            pickedColumns,
            renderedColumns,
            savedTableColumns,
            count,
            per_page,
            savedPerPage,
            page,
            loading,
            onChangePage,
            onChangePerPage,
            onSaveColumnsTable,
            isSelect,
            isChangedColumns,
            isPaginated,
            actionColumn,
            defaultPerPage,
            wordWrap = false,
            calculatedOuterHeight,
            rowHeight = 35,
            headerHeight = 35,
            twoStaged,
            isMobile = false,
            rowClassName = "",
            extraRows = 0,
            rtl,
            moreCount,
            ...props
        } = this.props;

        const {columnMenuShow, menuColumns, selectAll} = this.state;

        const getColumnsFromIds = this.getColumnsFromIds;
        const onChangeColumnsTable = this.onChangeColumnsTable;
        const onOpenColumnChangeMenu = this.onOpenColumnChangeMenu;

        const defaultColumns = pickedColumns && pickedColumns.map(({id: value, label})=>({value, label}));
        const savedDefaultColumns = savedTableColumns && savedTableColumns.length ? getColumnsFromIds(savedTableColumns).map(({id: value, label})=>({value, label})) : [];

        if (actionColumn) {
            const column = actionColumn();
            renderedColumns.push(column);
        }

        let everyRowsChecked = false;
        if (data.length && data.every(
            row => this.selectedList.has(this.gettingSelectedItem(row))
        )) {
            everyRowsChecked = true
        }

        const columnChecked = (
            <Column key="table_toggle" width={50}>
                <HeaderCell>
                    <Checkbox
                        onChange={(value, isChecked) => this.onToggleAll(isChecked)}
                        checked={selectAll || everyRowsChecked}
                        className="columnCheckbox"
                    />
                </HeaderCell>
                <Cell>
                    {(row) => <Checkbox
                        disabled={selectAll}
                        checked = { row.checked || this.selectedList.has(this.gettingSelectedItem(row)) || selectAll}
                        onChange={(value, isChecked) => this.onToggleItem(this.gettingSelectedItem(row), isChecked)}
                        className="columnCheckbox"
                    />}
                </Cell>
            </Column>
        );

        if (isSelect && data.length) {
            renderedColumns.push(columnChecked);
        }

        // add fixing column to fix horizontal scroll
        const columnMobileFix = (
            <Column key="table_scrollfix" width={0} fixed>
                <HeaderCell></HeaderCell>
                <Cell></Cell>
            </Column>
        );
        renderedColumns.push(columnMobileFix);

        // reverse columns for RTL. Haven't found good CSS solution to fix yet
        if (rtl) renderedColumns.reverse();

        
        // Calculate table height
        const calculatedTableHeight = window.innerHeight - (calculatedOuterHeight || 410);
        const defaultTableRowsHeight = (defaultPerPage || (twoStaged ? TWO_STAGE_DEFAULT_PER_PAGE_DIALER + extraRows : DEFAULT_PER_PAGE_DIALER + extraRows)) * (rowHeight || 35) + (headerHeight || 35);
        // const calculatedTableRows = Math.floor(calculatedTableHeight / (rowHeight || 35));

        return (
            <>
                {
                    !!columnMenuShow &&
                        ReactDOM.createPortal(
                            <CheckPickerPortal onClick={()=>onOpenColumnChangeMenu(false)}/>
                        , document.body)
                }

                {isChangedColumns &&
                    <MenuShowColumnsIcon
                        icon={!columnMenuShow ? 'align-justify' : 'indent'}
                        onClick={() => onOpenColumnChangeMenu(!columnMenuShow)}
                    />
                }
                <StyledCheckPicker className="checkPicker__container">
                    <CheckPicker
                        key={savedDefaultColumns}
                        data={menuColumns.length ? menuColumns : defaultColumns}
                        defaultValue={savedDefaultColumns && savedDefaultColumns.length ? savedDefaultColumns.map(({value})=>value) : defaultColumns && defaultColumns.map(({value})=>value)}
                        onChange={id => {
                            const idList = id.filter(item=>typeof item === 'string');
                            const comparedColumns = getColumnsFromIds(idList);
                            onChangeColumnsTable(comparedColumns, idList);
                            onSaveColumnsTable(idList);
                        }}
                        open={columnMenuShow}
                        searchable={false}
                        sticky={true}
                        className="checkPicker"
                    />
                </StyledCheckPicker>


                <StyledTable
                    virtualized={!isMobile}
                    height={calculatedTableHeight < defaultTableRowsHeight ? defaultTableRowsHeight : calculatedTableHeight}
                    autoHeight={isMobile || (calculatedTableHeight <= defaultTableRowsHeight && data.length === (twoStaged ? TWO_STAGE_DEFAULT_PER_PAGE_DIALER + extraRows : defaultPerPage || DEFAULT_PER_PAGE_DIALER + extraRows))}
                    headerHeight={isMobile ? undefined : headerHeight}
                    data={data.sort((a, b) => {
                        return a.datetime > b.datetime;
                    })}
                    loading={loading}
                    wordWrap={wordWrap || isMobile}
                    rowHeight={isMobile ? undefined : rowHeight}
                    rowClassName={rowClassName}
                    {...props}
                >
                    {renderedColumns}
                </StyledTable>
                {
                    isPaginated &&
                    <Pagination
                        total={count}
                        per_page={per_page}
                        savedPerPage={savedPerPage}
                        activePage={page}
                        onChangePage={onChangePage}
                        onChangePerPage={onChangePerPage}
                        moreCount={moreCount}
                    />
                }
            </>

        )}
};

export default injectIntl(CustomizableTable, {forwardRef: true})


const CheckPickerPortal = styled.div`
    width: 100%;
    height: ${() => document.body.offsetHeight - 56}px;
    top: 56px;
    left: 0;
    position: absolute;
    z-index: 5;
`;

const StyledCheckPicker = styled.div`
    width: 220px;
    position: absolute;
    
    .checkPicker {
        width: 100%;
        visibility: hidden;
    }
`;

export const StyledTable = styled(BaseTable)`
    .rs-table-cell-content {
        // line-height: 26px;
    }
`;