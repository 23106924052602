import React, {useEffect, useMemo, useState} from 'react';
import {FlexGrid, FlexGridItem} from 'components/base/FlexGrid';
import PageHeader from 'components/base/PageHeader';
import PanelLayout from 'components/base/PanelLayout';
import AuthNumbersFilter_ from './AuthNumbersFilter';
import AuthNumbersUploader from './AuthNumbersUploader';
import AuthNumbersDownloadButton from './AuthNumbersDownloadButton';
import AuthNumbersTable_ from './AuthNumbersTable';
import {api} from 'api/loginRoutes';
import { Alert } from 'rsuite';

import {AUTH_NUMBERS_EDIT} from '../../const/apiMethods';


export default ({
    // data: _data,
    data,
    loading,
    service,
    spGroupList,
    numberTypeList,
    numberTypeFamilyList,

    addAuthNumber,
    getAuthNumbers,
    deleteAuthNumber,
    getAuthNumberType
}) => {

    const [filter, setFilter] = useState({});

    useEffect(() => {
        if (service) {
            getAuthNumberType();
        }
        getAuthNumbers();
    }, [filter]);


    const onDataUpdate = () => {
        if (service) {
            getAuthNumberType();
        }
        getAuthNumbers();
    };


    const handleModifyAuthNumber = async (params, rowData) => {
        return await api(AUTH_NUMBERS_EDIT, params);
    };


    const handleDeleteAuthNumber = async (id) => {
        return await deleteAuthNumber(id);
    };

    const handleCreateAuthNumber = async (params) => {
        const r = await addAuthNumber(params);
        
        if (r?.auth_number) {
            Alert.success(`Number ${params.number} was added`);
        }
        
        if (r?.error?.data?.number === "dublicate") {
            Alert.error(`Number ${params.number} is already exists!`)
        }

        return r;
    };


    const filteredAccounts = useMemo(() => {
        const str = filter?.number || '';
        const spg_key = filter?.spg_key;
        
        const filtereData = data.filter(value => {
            if (!value) {
                return false;
            }

            if (spg_key && value?.spg_key && value.spg_key !== spg_key) {
                return false
            }

            if (value?.number && value.number.includes(str) ||
                value?.type_family_name && value.type_family_name.includes(str)) {
                return true;
            }

            return false;
        });

        return filtereData || [];
    }, [data, filter]);


    return <PanelLayout>
        <PageHeader title="List of whitelisted CLIDs"/>

        <FlexGrid>
        <FlexGridItem>
            <AuthNumbersFilter_
                filter={filter}
                setFilter={setFilter}
            />
        </FlexGridItem>
        <FlexGridItem style={{marginLeft: 'auto'}}>
            <FlexGrid>
                <FlexGridItem>

                    <AuthNumbersDownloadButton/>

                </FlexGridItem>
                <FlexGridItem>

                    <AuthNumbersUploader/>

                </FlexGridItem>
            </FlexGrid>
        </FlexGridItem>
        </FlexGrid>

        <AuthNumbersTable_
            data={filteredAccounts}
            loading={loading}
            spGroupList={spGroupList}
            numberTypeList={numberTypeList}
            numberTypeFamilyList={numberTypeFamilyList}
            onDataUpdate={onDataUpdate}
            onRowCreate={handleCreateAuthNumber}
            onRowSubmit={handleModifyAuthNumber}
            onRowRemove={handleDeleteAuthNumber}
        />

  </PanelLayout>
}