import {Form, Input, Checkbox, DateRangePicker, SelectPicker, InputNumber} from "rsuite";
import styled from "styled-components";
import React from "react";

export default Form;

export const BaseInputField = styled(Input)``;
export const BaseInputNumberField = styled(InputNumber)``;
export const BaseSelectPicker = styled(SelectPicker)``;
export const BaseDateRangePicker = styled(DateRangePicker)``;
export const BaseCheckboxField = styled(Checkbox)``;