import {MANUAL_ACCESS_LIST as PREFIX, DEFAULT_PER_PAGE_MEDIUM} from '../const'

const initialState = {
    items: [],
    count: 0,
    page: 1,
    per_page: +localStorage.getItem(PREFIX+'_per_page') || DEFAULT_PER_PAGE_MEDIUM,
    loading:false
};

const handlers = {
    ['SET_ITEMS_'+PREFIX]: (state, {payload: {items}}) => ( {
        ...state,
        items,
        loading: false
    }),
    ['SET_LOADING_'+PREFIX]: (state,{payload:loading}) => ({...state, loading}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action)
}