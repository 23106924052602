import React, {useRef, useState, useEffect} from "react";
import {useWindowWidth} from "hooks";
import {FlexGrid, FlexGridItem} from "components/base/FlexGrid";
import {ControlLabel, Form, FormControl, InputNumber, Schema, Table, Alert, SelectPicker} from "rsuite";
import CustomField from "components/client/Form/CustomField/CustomField";
import {CustomModal} from "components/base";
import styled, {createGlobalStyle} from "styled-components";
import {Spacer} from "components/base/Spacer";

import TablePayments from "components/Table/TablePayments";
import {
    ACCOUNT_MANAGERS_GET_INFORMATORS_API,
    SMS_SUPPLIER_GAN_GROUP_MODIFY_METHOD,
    INCORPORATED_GROUP_DEFAULT_RATE_LIST_API,
    INCORPORATED_GROUP_MODIFY_API,
    INCORPORATED_GROUP_PRICE_MODIFY_API,
    SMS_SUPPLIER_GAN_GROUP_PRICE_MODIFY_METHOD,
    SMS_SUPPLIER_GAN_GROUP_GET_DEFAULT_RATE_LIST_METHOD
} from "const/apiMethods";
import {SCREEN_MEDIA} from "const";
import {api} from "api/loginRoutes";
import {checkPermissionsFor} from "store/storeHelpers";
import BillingIncrement from "components/Filters/BillingIncrement";
import {servicePick} from "utils";

const { Column, HeaderCell, Cell } = Table;


const {StringType, NumberType} = Schema.Types;


export default (
    {
        show = false,
        service,
        ivrList = [],
        formValue = {},
        setFormValue,
        currentGroupData,
        maxWidth = 600,
        onSuccess,
        onClose,
        paymentTermsList,
        currencyList,
        getAccountManagersInformators,
        isRatemod = false,
        ...props
    }
) => {

    const rateEditTimer = useRef(null);
    const formRef = useRef(null);
    const resizedWidth = useWindowWidth();

    const [loading, setLoading] = useState(false);
    const [rates, setRates] = useState([]);
    const [ratesUpdateLoading, setRatesUpdateLoading] = useState(false);

    
    const emptyIvr = {
        id: null,
        name: " "
    };

    const sortedIvrlist = [...ivrList, emptyIvr].sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
    });

    useEffect(() => {
        if (!formValue?.rate_list || !formValue.rate_list.length) {
            setRates([]);
            return;
        }

        setRates(formValue.rate_list.map(item => ({cur_key: item.cur_key, pt_key: item.pt_key, rate: item.rate})));
    }, [formValue?.rate_list]);
    
    const formModel = Schema.Model({
        name: StringType()
            .isRequired("This field is required")
            .maxLength(256, "Maximum length 256"),
        rate: NumberType()
            .isRequired("This field is required")
            .min(0.0001, "Minimum 0.0001")
            .max(10, "Maximum 10"),
        ...(service ? {rrc_key: NumberType()
            .isRequired("This field is required"),
        } : {})
    });


    const handleChangeRate = (value) => {
        clearTimeout(rateEditTimer.current);
        rateEditTimer.current = setTimeout( () => {
            
            if (!value || formModel.check({rate: value}).rate.hasError) {
                return;
            }

            if (service) {
                setRatesUpdateLoading(true);
            }

            const method = servicePick(service, INCORPORATED_GROUP_DEFAULT_RATE_LIST_API, SMS_SUPPLIER_GAN_GROUP_GET_DEFAULT_RATE_LIST_METHOD);

            const params = service ? {
                ing_key: currentGroupData.ing_key,
                rate: +value
            } : {
                target: {
                    "sms.supplier_gan_group_id": currentGroupData.id
                },
                rate: +value
            };
            
            api(method, params)
                .then(r => {
                    if (!r || !r.default_rate_list) {
                        return;
                    }

                    setRates(r.default_rate_list.map(item => ({cur_key: item.cur_key, pt_key: item.pt_key, rate: item.rate})));

                })
                .finally(() => {
                    setRatesUpdateLoading(false);
                });
        }, 500 );
    }


    const handleChangeRates = (value) => {
        if (!rates.find(item => item.cur_key === value.cur_key && item.pt_key === value.pt_key)) {
            setRates(rates => [...rates, value]);
            return;
        }

        const ratesUpdated = rates.map(item => {
            return item.cur_key === value.cur_key && item.pt_key === value.pt_key 
                ? value
                : item;
        });
        setRates(ratesUpdated);
    };


    const handleSubmit = () => {
        if (!formRef.current.check()) {
            return;
        }

    
        setLoading(true);
     

        const method = service ? INCORPORATED_GROUP_MODIFY_API : SMS_SUPPLIER_GAN_GROUP_MODIFY_METHOD;

        const params = service ? {
            target: {
                ...(formValue?.ivr_id ? {ivr_id: formValue.ivr_id} : {})
            },

            ing_key: currentGroupData.ing_key,
            name: formValue.name,
            rate: +formValue.rate,
            rrc_key: formValue.rrc_key,
            comment: formValue.comment
        } : {
            target: {
                "sms.supplier_gan_group_id": currentGroupData.id
            },
            name: formValue.name,
            rate: +formValue.rate,
            comment: formValue.comment
            // rrc_key: formValue.rrc_key
        };

        api(method, params)
            .then(r => {
                const resultParamName = servicePick(service, "incorporated_group", "sms.supplier_gan_group_id");


                if (!r || !r[resultParamName]) {
                    setLoading(false);
                    return;
                }

                Alert.success("Group has been successfully modified");

                if (isRatemod) {
                    onSuccess();
                    onClose();
                    setLoading(false);
                    return;
                }

                // Modify default prices
                const method = servicePick(service, INCORPORATED_GROUP_PRICE_MODIFY_API, SMS_SUPPLIER_GAN_GROUP_PRICE_MODIFY_METHOD);
                const ratesParams = service ? {
                    ing_key: currentGroupData.ing_key,
                    rate_list: rates
                        .filter(item => item?.rate)
                        .map(item => ({...item, rate: +item.rate})),
                } : {
                    target: {"sms.supplier_gan_group_id": currentGroupData.id},
                    rate_list: rates
                        .filter(item => item?.rate)
                        .map(item => ({...item, rate: +item.rate})),
                };

                api(method, ratesParams)
                    .then(r => {
        
                        if (!r) {
                            return;
                        }
        
                        Alert.success("Group prices have been updated");
                        onSuccess();
                        onClose();
                    })
                    .finally(() => {
                        setLoading(false);
                    });


            })
            .finally(() => {
                checkPermissionsFor(ACCOUNT_MANAGERS_GET_INFORMATORS_API) && getAccountManagersInformators();
                setLoading(false);
            });
    };
    
    return (
        <>
            <GlobalStyle/>
            <StyledModal
                show={show}
                showFooter={true}
                title={"Edit group"}
                width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
                onClose={onClose}
                onConfirm={handleSubmit}
                loading={loading}
                {...props}
            >
                <Form
                    ref={formRef}
                    model={formModel}
                    formValue={formValue}
                    onChange={setFormValue}
                >

                    <FlexGrid align="middle">
                        <FlexGridItem>
                            <ControlLabel>Group name</ControlLabel>
                        </FlexGridItem>
                        <FlexGridItem>
                            <FormControl
                                // disabled={isRatemod}
                                name={"name"}
                                placeholder={"Group name"}
                                style={{width: 224}}
                            />
                        </FlexGridItem>
                    </FlexGrid>

                    <Spacer />

                    <FlexGrid align="middle">
                        <FlexGridItem>
                            <ControlLabel>Payout</ControlLabel>
                        </FlexGridItem>
                        <FlexGridItem>
                            <FormControl
                                // disabled={isRatemod}
                                accepter={InputNumber}
                                name={"rate"}
                                onChange={handleChangeRate}
                                placeholder={"Payout"}
                                style={{width: 90}}
                            />
                        </FlexGridItem>
                        <FlexGridItem>
                            <ControlLabel>{currentGroupData && servicePick(service, currentGroupData.cur_name, currentGroupData.supplier_cur_name)}</ControlLabel>
                        </FlexGridItem>
                        {service && <>
                            <FlexGridItem>
                                <ControlLabel>Billing Increment</ControlLabel>
                            </FlexGridItem>
                            <FlexGridItem>
                                <BillingIncrement
                                    classPrefix={"rs-form-control"}
                                    style={{verticalAlign: "none", marginLeft: 10, paddingRight: 10, height: 36}}
                                />
                            </FlexGridItem>
                        </>}
                    </FlexGrid>
                    
                    <Spacer />

                    {service ? <FlexGrid align="middle">
                        <FlexGridItem>
                            IVR
                        </FlexGridItem>
                        <FlexGridItem>
                            <CustomIvrField
                                // block
                                data={sortedIvrlist}
                                accepter={SelectPicker}
                                labelKey="name"
                                valueKey="id"
                                placeholder="Select IVR"
                                name="ivr_id"
                                searchable={false}
                                cleanable={false}
                                virtualized={false}
                                styled={{width: 220}}
                            />
                        </FlexGridItem>
                    </FlexGrid> : <></>}
            
                    <Spacer />

                    <TablePayments
                        {...{
                            paymentTermsList,
                            currencyList,
                            rates,
                        }}
                        loading={ratesUpdateLoading}
                        disabled={isRatemod}
                        onChangeRates={handleChangeRates}
                    />
        

                    <StyledField>
                        <ControlLabel>Comment</ControlLabel>
                        <FormControl
                            name="comment"
                            placeholder="Enter your comment here..."
                            componentClass="textarea"
                            style={{height: 140}}
                        />
                    </StyledField>
                </Form>
            </StyledModal>
        </>
    )
};

const StyledField = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
    }

    .rs-control-label {
        flex-shrink: 0;
        align-self: center;
        padding-right: 10px;
        width: 100%;
        margin-right: 0;

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            width: 100px;
        }
    }
`;

const StyledModal = styled(CustomModal)`
    .rs-modal-body {
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const CustomIvrField = styled(CustomField)`
    &&.rs-picker-select a.rs-picker-toggle > .rs-picker-toggle-value {
        min-width: 120px;
        min-height: 20px;
    }
`;

const GlobalStyle = createGlobalStyle`
    .rs-picker-menu .rs-picker-select-menu-items > div[data-key="0"] .rs-picker-select-menu-item {
        padding: 18px !important;
    }
`;