import React from 'react';
import CustomField from './CustomField';
import {Checkbox} from 'rsuite';
import {BaseCheckboxField} from "../base/BaseForm";

export const CheckboxControl = (props) => {
    return <CustomField
        accepter={CheckboxHOC}
        {...props}
    />;
}

const CheckboxHOC = (props) => {        
    const onChange = (value, checked) => {
        props.onChange( props.inverse ? !checked : checked);
    };
    return <BaseCheckboxField
        {...props}
        onChange={onChange}
    />;
};

export default CheckboxControl;