import {connect} from "react-redux";
import GanSuppliers from "./GanSuppliers";
import {getGanSuppliersList, clearGanSuppliersList} from "../../actions/gan_suppliers";


const mapStateToProps = ({gan_suppliers}) => ({
    data: gan_suppliers.list,
    loading: gan_suppliers.loading,
});

export default connect(mapStateToProps, {
    getGanSuppliersList,
    clearGanSuppliersList
})(GanSuppliers);