import React from 'react';
import TrafficLimitsContainer from './TrafficLimits/TrafficLimitsContainer';
import {Header} from './styled';


export default ({isSupplier, currentSupplier}) => {

    return (
        <>
            <Header>{isSupplier ? 'Supplier' : 'Account'} Traffic Limits</Header>
            <TrafficLimitsContainer
                {...{
                    isSupplier,
                    currentSupplier
                }}
            />
        </>
    );
};
