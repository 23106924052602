import {DEFAULT_PER_PAGE_MEDIUM, TRAFFIC_LIMITS as PREFIX} from '../const';

const initialState = {
    items: [],
    acc_items: [],
    call_duration_max: 0,
    count: 0,
    page: 1,
    allocation_ban_expired_at: null,
    per_page: +localStorage.getItem(PREFIX + '_per_page') || DEFAULT_PER_PAGE_MEDIUM,
    loading: false
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {count, page, per_page, list, call_duration_max, allocation_ban_expired_at}}) => ({
        ...state,
        count,
        page,
        per_page,
        allocation_ban_expired_at,
        call_duration_max,
        items: list,
        loading: false
    }),
    ['SET_LOADING_' + PREFIX]: (state, {payload: loading}) => ({...state, loading}),
    ['SET_ACC_ITEMS_' + PREFIX]: (state, {payload: {list}}) => ({
        ...state,
        acc_items: [
            ...state.acc_items,
            ...list
        ]
    }),
    ['SET_CLEAR_ACC_ITEMS_' + PREFIX]: (state) => ({...state, acc_items: []}), 
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}