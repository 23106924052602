import React from "react";
import Modal from "../../../components/Modal";
import {useWindowWidth} from "../../../hooks";

export default ({
    accountId,
    show,
    onClose,
    onSuccess,
    maxWidth = 700,
    loading = false,
    data,
    selectedItems,
    getKeysFromSelectedItems,
    getValueFromSelectedItems
}) => {

    const resizedWidth = useWindowWidth();
    const selectedItemsKeys = getKeysFromSelectedItems(selectedItems);
    const selectedAccounts = data
        ? data.filter(item => {
            return selectedItemsKeys.includes(item.id);
        }).map(item => {
            return {...item, ...getValueFromSelectedItems(item.id, selectedItems)}
        })
        : [];

    let amountUsd = 0;
    let amountEur = 0;

    for (const account of selectedAccounts) {
        if (!account.available_for_payment)
            continue;
            
        switch (account.currency) {
            case "EUR":
                amountEur += account.available_for_payment;
                continue;
            default:
                amountUsd += account.available_for_payment;
        }
    }

    return (
        <Modal
            {...{show, onClose, onSuccess}}
            onClose={() => {
                onClose(false);
            }}
            title="Request Hawala Payments"
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            footer={true}
            disabled={loading}
            successText="Confirm"
        >
            {`Please confirm making ${selectedItemsKeys.length} payments to Havala on amount ${amountUsd.toFixed(2)} USD and ${amountEur.toFixed(2)} EUR`}
        </Modal>
    )
}