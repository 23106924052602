import React, {useMemo} from 'react';
import {Table} from 'rsuite';
import RenderedColumns from '../../../components/Table/RenderColumns';
import Pagination from '../../../components/base/Pagination'
import BaseTable from "../../../components/base/BaseTable";
import {ButtonRowDelete, ButtonRowEdit} from "../../../components/base/BaseButton";
import styled from "styled-components";

const { Column, HeaderCell, Cell } = Table;

export default ({
    data = [],
    loading = false,
    total,
    service,
    per_page,
    activePage,
    onChangePage,
    onChangePerPage,
    onClickDelete,
    onClickEdit,
    
    isSupplier
}) => {

    const columns = useMemo(() => {
        return isSupplier ? [
            {
                label: 'Operator', 
                dataKey: 'operator',
                value: ({mccmnc_name}) => mccmnc_name
            },
            {
                label: 'MCC/MNC',
                dataKey: 'mcc',
                value: ({mcc, mnc}) => `${mcc}${mnc}`
            },
            {label: 'Rate', dataKey: 'rate', align: 'right'},
            {label: 'Start date', dataKey: 'start_date', formatData: 'date', align: 'right'},
            {label: 'End date', dataKey: 'end_date', formatData: 'date', align: 'right'}
        ] : [
            {
                label: 'Subdestination', 
                dataKey: 'subdestination_name',
                value: ({subdestination_name, mcc, mnc}) => {
                    if (!service && isSupplier) {
                        return `${subdestination_name} (${mcc}${mnc ? ' ' : ''}${mnc})`
                    }
    
                    return subdestination_name;
                }
            },
            {label: 'Rate', dataKey: 'rate', align: 'right'},
            {label: 'Default rate', dataKey: 'rate_default', align: 'right'},
            {label: 'Start date', dataKey: 'start_date', formatData: 'date', align: 'right'},
            {label: 'End date', dataKey: 'end_date', formatData: 'date', align: 'right'}
        ];
    }, [isSupplier]);
    const renderedColumns = RenderedColumns(columns, {end_date: true});

    const getData = () =>  {
        const start = per_page * (activePage - 1);
        const end = start + per_page;
        
        return data.filter((v, i) => i >= start && i < end);
    };

    const _data = getData().map(item => ({
        ...item,
        start_date: item?.start_date ? item.start_date.replace(" ", "T") : "",
        end_date: item?.end_date ? item.end_date.replace(" ", "T") : "",
    }));

    return (
        <>
            <StyledTable
                data={_data}
                loading={loading}
                rowHeight={30}
                rowClassName={(rowData) => {
                    if (rowData && rowData.rate_default !== null && !isSupplier) {
                        return rowData.rate_default > rowData.rate ? 'red' : 'green';
                    }
                }}
                // rowClassName={}
                height={ ((_data.length * 30) || 100) + 50}
            >
                {renderedColumns}
                <Column width={50}>
                    <HeaderCell/>
                    <Cell style={{overflow: 'unset'}}>
                        {(rate) => <ButtonRowEdit onClick = { () => onClickEdit(rate)} style={{top: '-2px'}}/> }
                    </Cell>
                </Column>
                <Column width={50}>
                    <HeaderCell/>
                    <Cell style={{overflow: 'unset'}}>
                        {(body) => <ButtonRowDelete onClick = { () => {
                            const deleteID = isSupplier ? body?.key : body?.id;

                            onClickDelete(deleteID);
                        }} style={{top: '-2px'}}/> }
                    </Cell>
                </Column>
            </StyledTable>
            <Pagination {...{total,per_page, activePage, onChangePage, onChangePerPage}} />
        </>
    );
};

const StyledTable = styled(BaseTable)`
    .rs-table-row.green .rs-table-cell {
        background: #caface;
    }
    .rs-table-row.red .rs-table-cell {
        background: #ebd1d3;
    }
`;