import React from 'react';
import {Drawer, IconButton, Icon} from 'rsuite';
import NumbersFiltersForm from './NumbersFiltersForm';
import styled from 'styled-components';

export default ({
    service,
    onChangeFilters, 
    onChangeGroup, 
    filterValue,
    defaultFilter,
    savedTableFilter, 
    allocatedNumbersLoading, 
    showMobileFilters, 
    isMobile = false, 
    activeFiltersCount = 0,
    currentDisplay, 
    ...props
}) => {

    const [showFiltersDrawer, setShowFiltersDrawer] = React.useState(false);

    const showFilters = () => {
        setShowFiltersDrawer(true);
    };

    const hideFilters = () => {
        setShowFiltersDrawer(false);
    };

    return (
        <>
            {isMobile
                ? <>
                    <Toggler
                        icon={<Icon icon="filter"/>}
                        onClick={showFilters}
                    >
                        {activeFiltersCount}
                    </Toggler>
                    <Drawer
                        size="xs"
                        placement="bottom"
                        style={{height: '240px'}}
                        show={showFiltersDrawer}
                        onHide={hideFilters}
                    >
                        <Drawer.Header>
                        </Drawer.Header>
                        <Drawer.Body>
                            <NumbersFiltersForm 
                                {...{
                                    service,
                                    onChangeFilters, 
                                    onChangeGroup,
                                    filterValue,
                                    defaultFilter,
                                    savedTableFilter, 
                                    allocatedNumbersLoading,
                                    currentDisplay
                                }}
                            />                                
                        </Drawer.Body>
                    </Drawer>
                    {/*<p>{props.testCount}</p>*/}
                </>

                : <NumbersFiltersForm 
                    {...{
                        service,
                        onChangeFilters, 
                        onChangeGroup,
                        filterValue,
                        defaultFilter,
                        savedTableFilter, 
                        allocatedNumbersLoading,
                        currentDisplay
                    }}
                />
            }
        </>    
    )
}

const Toggler = styled(IconButton)`
    && {
        color: #363434;
    }
`;