// Simple checkbox with true/false value

import React, {useState} from "react";
import {Checkbox} from "rsuite";

export default ({children, defaultValue = false, onChange, ...props}) => {
    const [_value, _setValue] = useState(defaultValue);

    return <Checkbox
        value={_value}
        checked={_value}
        onChange={() => {
            _setValue(!_value);
            onChange && onChange(!_value);
        }}
        {...props}
    >
        {children}
    </Checkbox>
};