import {POSTBACK_LOGS as PREFIX, SET_POSTBACK_LOGS_LIST, DEFAULT_PER_PAGE_MEDIUM} from '../const/';

const initialState = {
    loading: false,
    page: 1,
    count: 0,
    per_page: +localStorage.getItem(PREFIX+'_per_page') || DEFAULT_PER_PAGE_MEDIUM,
    items: []
};
const handlers = {
    [SET_POSTBACK_LOGS_LIST]: (state, {payload:{items, page, per_page, count}}) => ({...state, items, count, page, per_page, loading: false}),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};