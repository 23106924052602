import React, {useLayoutEffect, useState, useMemo, useEffect} from 'react';

import {Alert} from 'rsuite';
import {
    getE212DropdownList
} from '../../../../../actions/e212';

import AddPrefixHeader from './AddPrefixHeader';
import ModalSendApi from '../../../../../components/Modal/ModalSendApi';
import ModalPrefixResult from './ModalPrefixResult';
import SinglePrefixSection from './SinglePrefixSection';
import PrefixByOperator from './PrefixByOperator';
// import PrefixBySubdestSection from './PrefixBySubdestSection';
import PrefixByTemplateSection from './PrefixByTemplateSection';
import ApiRequest from '../../../../../components/ApiRequest';
import {connect} from 'react-redux';
import MassPrefixesSection from './MassPrefixesSection';
import PrefixBySubdestSection from './PrefixBySubdestSection';


const AddNewPrefixesModal = ({
    show,
    update,
    method,
    onClose,
    trunk_id,
    account_id,
    onFaultResult,
    currentTrunk,
    activeTab, 
    setActiveTab,
    target = {},
    initialValues = {},
    e212List,
    subdestination_list,
    getE212DropdownList,
    suppliersPrefixDropdownList,
    getSupplierPrefixesDropdown,
    ...props
}) => {

    const defaultFormValue = {active: true, rate: null, prefixes: 1};


    const [formValueState, setFormValueState] = useState(defaultFormValue);
    const [showResult, setShowResult] = useState(false);
    const [resultData, setResultData] = useState(suppliersPrefixDropdownList);

    useEffect(() => {
        getE212DropdownList({}, true);
    }, []);

    useEffect(() => {
        if (suppliersPrefixDropdownList && suppliersPrefixDropdownList.length) {
            setResultData(suppliersPrefixDropdownList);
        }
    }, [suppliersPrefixDropdownList]);

    const clearForm = () => {
        setFormValueState(defaultFormValue);
    };

    const handleSetActiveTab = (activeTab) => {
        // setBreadcrumbs(`${document.location.pathname}/${activeTab}`);
        setActiveTab(activeTab);
        clearForm();
    };


    const disabledChoise = {
        "single": !(formValueState?.prefix),
        "default": !(formValueState?.prefixes && formValueState?.numbers)
    };
    const disabled = Object.keys(disabledChoise).includes(activeTab) ? disabledChoise[activeTab] : disabledChoise["default"];

    const [mcc, mnc] = formValueState?.mccMnc ? formValueState.mccMnc.split("-") : [];

    const extraParams = formValueState?.template ? {
        ...initialValues,
        ...formValueState,

        template_list: [{
            template: formValueState?.template, 
            rate: formValueState?.rate === null ? null : parseFloat(formValueState?.rate)
        }],
        template: undefined,
        rate: undefined,
        numbers: formValueState?.numbers ? parseInt(formValueState.numbers || '0') : undefined,
        prefixes: formValueState?.prefixes ? parseInt(formValueState.prefixes || '0') : undefined,
        active: true
    } : formValueState?.sde_key ? {
        ...initialValues,
        ...formValueState,

        subdestination_list: [{
            sde_key: formValueState?.sde_key, 
            rate: formValueState?.rate === null ? null : parseFloat(formValueState?.rate)
        }],
        rate: undefined,
        numbers: formValueState?.numbers ? parseInt(formValueState.numbers || '0') : undefined,
        prefixes: formValueState?.prefixes ? parseInt(formValueState.prefixes || '0') : undefined,
        active: true
    } : formValueState?.mccMnc ? {
        ...initialValues,
        ...formValueState,

        mccmnc_list: [{
            mcc,
            mnc,
            rate: formValueState?.rate === null ? null : parseFloat(formValueState?.rate)
        }],
        // rate: formValueState?.rate === null ? null : parseFloat(formValueState?.rate),
        numbers: formValueState?.numbers ? parseInt(formValueState.numbers || '0') : undefined,
        prefixes: formValueState?.prefixes ? parseInt(formValueState.prefixes || '0') : undefined,
        active: true
    } : {
        ...initialValues,
        ...formValueState,

        rate: formValueState?.rate === null ? null : parseFloat(formValueState?.rate),
        numbers: formValueState?.numbers ? parseInt(formValueState.numbers || '0') : undefined,
        prefixes: formValueState?.prefixes ? parseInt(formValueState.prefixes || '0') : undefined,
        active: true
    };

    const operatorList = useMemo(() => {
        return e212List.map((value) => {
            return {
                ...value,
                operatorMccMnc: `${value.mccmnc_name} (${value.mcc}${value.mnc})`,
                mccMnc: `${value.mcc}-${value.mnc}`
            };
        });
    }, [e212List]);

    return (
        <>
            {show &&

            <ModalSendApi
                {...props}
                extraDisabled={disabled}
                onClose={() => {
                    onClose();
                    clearForm();
                }}
                successText="Yes"
                title="Add new prefixes"
                
            >
                <ApiRequest
                    method={method}
                    target = {{...target}}
                    onFaultResult={(result) => {
                        if (!result?.error) {
                            onFaultResult(result);
                            clearForm();
                            update();
                        }
                    }}
                    data={extraParams}
                    update={(result) => {
                        const list = result['supplier_prefix_update_list'];

                        if (list && list.length) {
                            getSupplierPrefixesDropdown(trunk_id, true, false);
                            setShowResult(true);
                            update();
                        } else {
                            Alert.error("Unable to create perfixes")
                            clearForm();
                        }
                    }}
                    checkResultKey="supplier_prefix_update_list"
                >
                    <AddPrefixHeader
                        activeTab={activeTab}
                        onSelect={handleSetActiveTab}
                    />

                    {( () => {

                        switch (activeTab) {

                            case 'single':
                                return (
                                    <SinglePrefixSection
                                        formValue={formValueState}
                                        onChangeForm={setFormValueState}
                                        defaultFormValue={defaultFormValue}
                                    />
                                );

                            case 'operator':
                                return (
                                    <PrefixByOperator
                                        operatorList={operatorList}
                                        formValue={formValueState}
                                        onChangeForm={setFormValueState}
                                        defaultFormValue={defaultFormValue}
                                    />
                                );
                            
                            // case 'subdestination':
                            //     return (
                            //         <PrefixBySubdestSection
                            //             operatorList={operatorList}
                            //             formValue={formValueState}
                            //             onChangeForm={setFormValueState}
                            //             defaultFormValue={defaultFormValue}
                            //         />
                            //     );
                                
                            
                            case 'template':
                                return (
                                    <PrefixByTemplateSection
                                        formValue={formValueState}
                                        onChangeForm={setFormValueState}
                                        defaultFormValue={defaultFormValue}
                                        {...{
                                            subdestination_list
                                        }}
                                    />
                                );
    

                            case 'mass':
                                return (
                                    <MassPrefixesSection
                                        currentTrunk={currentTrunk}
                                        formValue={formValueState}
                                        onChangeForm={setFormValueState}
                                        defaultFormValue={defaultFormValue}
                                        account_id={account_id}
                                        operatorList={operatorList}
                                    />
                                );
                                
                            default:
                                return null;
                        }

                    } )()}
                </ApiRequest>
            </ModalSendApi>
            }
            <ModalPrefixResult
                show={showResult}
                data={resultData}
                trunkId={trunk_id}
                onClose={() => {
                    setShowResult(false);
                }}
            />
        </>
    );
};



const mapState = ({e212, references}) => ({
    subdestination_list: references.subdestination_list,
    e212List: e212.list
});


export default connect(mapState, {
    getE212DropdownList
})(AddNewPrefixesModal);


