import React, {useEffect, useState} from 'react';

import PageHeader from '../../components/base/PageHeader';
import {FlexboxGrid} from 'rsuite';
import {connect} from 'react-redux';
import PanelLayout from '../../components/base/PanelLayout';
import {LOCAL_STORAGE_SIM_TRAFFIC_RANGES_FILTERS} from 'const/localStorageKeys';
import SIMTrafficRangesFilters from 'pages/SIMTrafficRanges/SIMTrafficRangesFilters';
import SIMTrafficRangesTable from 'pages/SIMTrafficRanges/SIMTrafficRangesTable';
import {addNewPriceRangePriority, getPriceRangesPriorityList} from 'actions/sim_traffic_ranges';
import SIMTrafficRangesAddNewModal from 'pages/SIMTrafficRanges/SIMTrafficRangesAddNewModal';


const SIMTrafficRangesPage = ({loading, priceRangesPriorityList, getPriceRangesPriorityList,
                                  addNewPriceRangePriority, perPage, page}) => {
    const [filters, setFilters] = useState({});

    const [subdestinationAddNewModal, setSubdestinationAddNewModal] = useState(false);

    useEffect(() => {
        getPriceRangesPriorityList(filters)
    }, []);

    const onChangeFilters = (filters) => {
        getPriceRangesPriorityList(filters, 1, perPage);
        setFilters(filters);
    };

    const prefixTransformation = (data) => {

        return data.reduce((result, nextValue) => {
            result.push(nextValue);
            if (nextValue.prefix_list.length) {
                nextValue.prefix_list.forEach((value) => {
                    result.push({sde_name: value.prefix, numbers: value.numbers, ...value});
                });
            }
            return result
        }, []);
    };
    const transformedList = prefixTransformation(priceRangesPriorityList);

    return (
        <PanelLayout>
            <PageHeader title="SIM Traffic Ranges"/>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24}>
                    <SIMTrafficRangesFilters onSubmit={onChangeFilters} defaultFilters={filters}/>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <SIMTrafficRangesTable
                setSubdestinationAddNewModal={setSubdestinationAddNewModal}
                getList={getPriceRangesPriorityList}
                dataList={transformedList}
                filters={filters}
                loading={loading}
            />
            <SIMTrafficRangesAddNewModal
                show={subdestinationAddNewModal}
                getList={getPriceRangesPriorityList}
                subdestination={subdestinationAddNewModal}
                addNewPriceRangePriority={addNewPriceRangePriority}
                onClose={() => setSubdestinationAddNewModal(null)}
                perPage={perPage}
                page={page}
                filters={filters}
                loading={loading}
            />
        </PanelLayout>
    );
};

const mapStateToProps = ({sim_traffic_ranges}) => ({
    priceRangesPriorityList: sim_traffic_ranges.list,
    perPage: sim_traffic_ranges.perPage,
    page: sim_traffic_ranges.page,
    loading: sim_traffic_ranges.loading
});

const mapDispatchToProps = {
    getPriceRangesPriorityList,
    addNewPriceRangePriority
};

export default connect(mapStateToProps, mapDispatchToProps)(SIMTrafficRangesPage);

