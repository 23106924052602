import {
    ACCOUNT_MANAGERS_SET_LOADING,
    ACCOUNT_MANAGERS_LIST,
    ACCOUNT_MANAGERS_REFERENCES,
    ACCOUNT_MANAGERS_BIL, ACCOUNT_MANAGERS_INFORMATORS_LIST, ACCOUNT_MANAGERS_DROPDOWN_LIST,
} from '../const'

import {
    ACCOUNT_MANAGERS_LIST_API,
    ACCOUNT_MANAGERS_REFERENCES_API,
    ACCOUNT_MANAGERS_MODIFY_API,
    ACCOUNT_MANAGERS_BIL_API,
    ACCOUNT_MANAGERS_REMOVE_API,
    ACCOUNT_MANAGERS_CREATE_API,
    ACCOUNT_MANAGERS_GET_INFORMATORS_API, ACCOUNT_MANAGERS_DROPDOWN_LIST_API, ACCOUNT_MANAGERS_DROPDOWN_LIST_ALL_API,
} from '../const/apiMethods'

import {createAction} from './defaults';
import {api} from "../api/loginRoutes";

const setAccountManagersList = createAction(ACCOUNT_MANAGERS_LIST);
const setAccountManagersDropdownList = createAction(ACCOUNT_MANAGERS_DROPDOWN_LIST);
const setAccountManagersLoading = createAction(ACCOUNT_MANAGERS_SET_LOADING);
const setAccountManagersReferences = createAction(ACCOUNT_MANAGERS_REFERENCES);
const setAccountManagersBillingList = createAction(ACCOUNT_MANAGERS_BIL);
const setAccountManagersInformators = createAction(ACCOUNT_MANAGERS_INFORMATORS_LIST);

// get list
export const getAccountManagersList = (filter = {}) => async (dispatch) => {
    dispatch( setAccountManagersLoading(true) );
    await api(ACCOUNT_MANAGERS_LIST_API, {filter})
        .then((response) => {
            if (response && response.account_manager_list) {

                dispatch( setAccountManagersList({
                    list: response.account_manager_list || [],
                }) );

                // update scroll after updating each row height
                dispatch( setAccountManagersList({
                    list: [...response.account_manager_list] || [],
                }) );
            }
        })
        .finally(() => {
            dispatch( setAccountManagersLoading(false) );
        })
};

// get dropdown list
export const getAccountManagersDropdownList = (filter = {}, all=false) => async (dispatch) => {
    dispatch( setAccountManagersLoading(true) );

    const method = all ? ACCOUNT_MANAGERS_DROPDOWN_LIST_ALL_API : ACCOUNT_MANAGERS_DROPDOWN_LIST_API;

    await api(method, {filter})
        .then((response) => {
            if (response && response.account_manager_list) {

                dispatch( setAccountManagersDropdownList({
                    list: response.account_manager_list || [],
                }) );
            }
        })
        .finally(() => {
            dispatch( setAccountManagersLoading(false) );
        })
};

export const getAccountManagersReferences = () => (dispatch) => {
    api(ACCOUNT_MANAGERS_REFERENCES_API)
        .then((response) => {
            if (response !== undefined) {

                dispatch( setAccountManagersReferences({
                    userList: response.account_user_manager_list || [],
                    teamLeadList: response.account_user_teamlead_list || [],
                }) );
            }
        })
};

export const getAccountManagersBillingList = () => (dispatch) => {
    api(ACCOUNT_MANAGERS_BIL_API, {})
        .then((response) => {
            if (response !== undefined) {

                dispatch( setAccountManagersBillingList({
                    list: response.billing_account_manager_list || [],
                }) );
            }
        })
};

export const modifyAccountManager = ({name, skype, email, permission_list, ...params}) => async (dispatch) => {
    const response = await api(ACCOUNT_MANAGERS_MODIFY_API, {
        name: name,
        skype: skype,
        email: email,
        permission_list: permission_list,
        target: {
            ...params,
            account_user_manager_id_list: params.account_user_manager_id_list || []
        }
    });
    return response;
};

export const removeAccountManager = (id) => async (dispatch) => {
    const response = await api(ACCOUNT_MANAGERS_REMOVE_API, {
        target: {
            account_manager_id: id
        }
    });
    return response;
};

export const createAccountManager = (data) => async (dispatch) => {
    const params = {target: {}, ...data};
    if (params.account_user_teamlead_id) {
        params.target.account_user_teamlead_id = params.account_user_teamlead_id;
        delete params.account_user_teamlead_id;
    }

    if (params.account_user_manager_id_list) {
        params.target.account_user_manager_id_list = params.account_user_manager_id_list;
        delete params.account_user_manager_id_list;
    }

    const response = await api(ACCOUNT_MANAGERS_CREATE_API, {...params});
    return response;
};

export const getAccountManagersInformators = () => (dispatch) => {
    api(ACCOUNT_MANAGERS_GET_INFORMATORS_API, {})
        .then((response) => {
            if (response !== undefined) {
                dispatch( setAccountManagersInformators({
                    informators: response || {},
                }) );
            }
        })
};

