import React from 'react';
import {Checkbox, FormControl} from 'rsuite';


export default (props) => {
    return  <FormControl
                name={props.name}
                accepter={CheckboxHOC}
                {...props}                  
            /> ;
}


const CheckboxHOC = (props) => {        
    const onChange = (value,checked) =>   props.onChange(checked);

    return <Checkbox {...props}   onChange={onChange}  /> ;   
}