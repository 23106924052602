import React, {useState, useRef, useMemo, useEffect} from "react";
import styled from "styled-components";
import {
    ControlLabel, Form, FormControl, InputNumber,
    SelectPicker, Uploader, Schema, Panel, Checkbox,
    PanelGroup, RadioGroup, Radio, Button, Alert, Icon
} from "rsuite";

import useWindowWidth from "../../../hooks/useWindowWidth";
import {api, apiFormData} from "../../../api/loginRoutes";
import {
    INCORPORATED_GROUP_UPLOAD_EXIST_API,
    INCORPORATED_GROUP_UPLOAD_NEW_API,
    INCORPORATED_GROUP_MULTI_UPLOAD_CSV_API,
    SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_EXISTS_METHOD,
    SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_MULTY_METHOD,
    SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_NEW_METHOD
} from "../../../const/apiMethods";

import {Spacer} from "../../../components/base/Spacer";
import CustomField from "../../../components/Form/CustomField";
import {CustomModal} from "../../../components/base";
import {FlexGrid, FlexGridItem} from "../../../components/base/FlexGrid";
import { number } from "prop-types";
import BillingIncrement from "components/Filters/BillingIncrement";
import { getSupplierPlanList } from "actions/suppliers";
import {servicePick} from "../../../utils";
import {CustomCheckbox} from "components/Form/CustomCheckbox";



const {StringType, NumberType} = Schema.Types;

const MIN_CHARACTERS = 7;
const MAX_CHARACTERS = 15;
const MAX_ROWS = 10000;
const FILES_DEFAULT = [];

const numberOptions = [
    {value: 'add', label: 'Add new numbers'},
    {value: 'replace', label: 'Replace group numbers'},
];

const testNumberOptions = [
    {value: 'none', label: 'Do not set test number'},
    {value: 'first', label: 'Set first number from list as test'},
    {value: 'random', label: 'Set random number from list as test'},
];


export default (
    {
        id = null,
        show,
        rowKey,
        service,
        trunks,
        groupList,
        supplierList,
        formValue,
        setFormValue,
        maxWidth = 600,
        onClose,
        onSuccess,
        suppliers,
        getSuppliers,
        showReasonHandler,
        currentGroupData,
        getIncorporatedNumbersSuppliers,
        ...props
    }
) => {

    const formRef = useRef(null);
    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);
    const [isMultipleUpload, setIsMultipleUpload] = useState(false);

    const [files, setFiles] = useState(FILES_DEFAULT);

    const supplierListData = useMemo(() => {
        return service ? supplierList || [] : suppliers || [] ;
    }, [service, suppliers, supplierList]);

    const trunksData = trunks.length ? trunks.map(trunk => {
        const supplierName = trunk.supplier_name;
        const trunkPlanName = trunk.plan.name;

        return {
            supplier_trunk_id: trunk.id, 
            name: `${supplierName} / ${trunkPlanName}`
        }
    }) : [];

    useEffect(() => {
        if (!show) {
            return;
        }

        if (service) {
            getIncorporatedNumbersSuppliers();
        } else {
            getSuppliers();
        }
    }, [service, show]);


    useEffect(() => {
        if (files && files.length) {
            formRef.current.cleanErrorForFiled("text");
            setFormValue(prev => ({...prev, text: ""}));
            return;
        }

    }, [files]);


    useEffect(() => {
        setFiles([]);
        if (service) {
            setFormValue(prev => ({
                test_number_option: prev.test_number_option,
                supplier_key: prev.supplier_key,
            }));
        } else {
            setFormValue(prev => ({
                test_number_option: prev.test_number_option,
                supplier_trunk_id: prev.supplier_trunk_id,
            }));
        }
    }, [isMultipleUpload]);


    const currencyName = useMemo(() => {
        // if (!supplierListData || !formValue.supplier_key) {
        //     return 'USD';
        // }

        // const currentSupplier = supplierListData.find(item => item.supplier_key === formValue.supplier_key);
        // return currentSupplier?.cur_name;
        return currentGroupData && servicePick(service, currentGroupData.cur_name, currentGroupData.supplier_cur_name);
    }, [supplierListData, formValue.supplier_key]);


    const formModel = useMemo(() => {

        if (isMultipleUpload) {
            return Schema.Model({

                ...(service ? {
                    supplier_key: NumberType()
                        .addRule((value, data) => value || !!data[rowKey], 'This field is required', true),
                } : {
                    supplier_trunk_id: StringType()
                        .addRule((value, data) => value || !!data[rowKey], 'This field is required', true),
                }),

                test_number_option: StringType()
                    .addRule((value, data) => {
                        if (!value && data[rowKey] && data.existing_group_action === "replace") {
                            return false;
                        }
                        return value || !!data[rowKey]
                    }, 'This field is required', true),
            });
        }

        return Schema.Model({

            ...(service ? {
                supplier_key: NumberType()
                    .addRule((value, data) => value || !!data[rowKey], 'This field is required', true),
            } : {
                supplier_trunk_id: StringType()
                    .addRule((value, data) => value || !!data[rowKey], 'This field is required', true),
            }),

            name: StringType()
                .addRule((value, data) => value || !!data[rowKey], 'This field is required', true),

            ...(service ? {
                rrc_key: NumberType()
                    .addRule((value, data) => value || !!data[rowKey], 'This field is required', true),
            } : {}),

            rate: NumberType()
                .addRule((value, data) => value || !!data[rowKey], 'This field is required', true)
                .min(0.0001, 'Minimum 0.0001')
                .max(10, 'Maximum 10'),
            
            existing_group_action: StringType()
                .addRule((value, data) => value || !data[rowKey], 'This field is required', true),
            
            test_number_option: StringType()
                .addRule((value, data) => {
                    if (!value && data[rowKey] && data.existing_group_action === "replace") {
                        return false;
                    }
                    return value || !!data[rowKey]
                }, 'This field is required', true),
            
            text: StringType()
                .addRule((value) => {
                    if (!value && (!files || !files.length)) {
                        return false;
                    }
                    return true;
                }, 'This field is required', true)

                .addRule((value) => {
                    const rows = value.trim().split('\n');
                    for (const row of rows) {
                        if (row.length && row.includes('-')) {
                            const range = row.split('-');
                            if (range.length <= 2) {
                                const rangeHasError = range.find(value => value.trim().length > MAX_CHARACTERS || value.trim().length < MIN_CHARACTERS);
                                if (rangeHasError) {
                                    return false
                                }
                            } else {
                                return false
                            }
                        } else {
                            if ( row.length && (row.trim().length > MAX_CHARACTERS || row.trim().length < MIN_CHARACTERS) ) {
                                return false;
                            }
                        }
                    }
                    return true;
                }, `Each row should be greater than ${MIN_CHARACTERS} and less than ${MAX_CHARACTERS} (range must contain 2 numbers)`)

                .addRule((value) => {
                    const rows = value.trim().split('\n');
                    for (const row of rows) {
                        const rowTrimmed = row.trim();
                        if (!rowTrimmed) {
                            continue;
                        }
                        if (rowTrimmed.includes('-')) {
                            const numbers = rowTrimmed.split('-').map(e => e.trim());
                            if (numbers.length > 2) {
                                return false;
                            }
                            for (const number of numbers) {
                                if (!number.match(/^\d{1,}$/)) {
                                    return false;
                                }
                            }
                            continue;
                        }
                        if (!rowTrimmed.match(/^\d{1,}$/)) {
                            return false;
                        }
                    }
                    // const match = row.match(/^\d{1,}$/);
                    return true;
                }, 'Please enter valid numbers')

                .addRule((value) => {
                    const rows = value.trim().split('\n');
                    return !(rows.length > MAX_ROWS);
                }, `The number of entered telephone numbers exceeds ${MAX_ROWS} rows`)
        });
    }, [isMultipleUpload, files]);


    const handleSubmit = () => {
        if (!formRef.current.check()) {
            return;
        }

        let params = {};

        const moveFromOtherGroupsObj = !service && formValue?.move_from_other_groups ? {
            move_from_other_groups: formValue.move_from_other_groups
        } : {};

        if (isMultipleUpload) {

            if (!files || !files.length) {
                Alert.error("Please upload the CSV file");
                return;
            }

            const dt = new DataTransfer();
            dt.items.add(files[0].blobFile);

            params = service ? {
                supplier_key: formValue.supplier_key,
                test_number_option: formValue.test_number_option,
                files: dt.files
            } : {
                supplier_id: formValue.supplier_id,
                test_number_option: formValue.test_number_option,
                files: dt.files,
                ...moveFromOtherGroupsObj
            };

        } else {
            params = {
                ...formValue,
                rate: formValue.rate
                    ? +formValue.rate
                    : formValue.rate,
                ...moveFromOtherGroupsObj
            };

            if (files && files.length) {
                const dt = new DataTransfer();
                dt.items.add(files[0].blobFile);
                params.files = dt.files;
                delete params.text;
            }
        }

        if (!service && formValue?.id) {
            params.target = {
                "sms.supplier_gan_group_id": formValue.id
            };
        }

        if (!service && !formValue?.id && formValue?.supplier_trunk_id) {
            params.target = {
                "sms.supplier_trunk_id": formValue.supplier_trunk_id
            };
        }

        const methodVoice = formValue.ing_key
            ? INCORPORATED_GROUP_UPLOAD_EXIST_API
            : isMultipleUpload
                ? INCORPORATED_GROUP_MULTI_UPLOAD_CSV_API
                : INCORPORATED_GROUP_UPLOAD_NEW_API;
        
        const methodSms = formValue.id
            ? SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_EXISTS_METHOD
            : isMultipleUpload
                ? SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_MULTY_METHOD
                : SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_NEW_METHOD;
        
        const method = service ? methodVoice : methodSms;

        const multiUploadApiMethod = service ? SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_MULTY_METHOD : INCORPORATED_GROUP_MULTI_UPLOAD_CSV_API;

        const methodFunc = params.files || method === multiUploadApiMethod
            ? apiFormData
            : api;


        setLoading(true);

        methodFunc(method, params)
            .then(r => {

                if (!r) {
                    return;
                }

                if (r.validation_error) {
                    Alert.error(<>
                        <p><b>Error</b>: {r.validation_error}</p>
                        {r.line_number &&
                            <p>
                                {params.files && `${params.files[0].name}: `}
                                line <b>{r.line_number}</b><br/>
                                {r.line_str}
                            </p>
                        }
                    </>, 10000);
                    return;
                }

                if (r.reason_code) {
                    switch (r.reason_code) {
                        case "different_group":
                            showReasonHandler({
                                status: 1,
                                reasonCode: r.reason_code,
                                reasonHash: r.hash,
                                content: (
                                    <PanelGroup accordion bordered style={{marginRight: 20}}>
                                        {r.incorporated_group_list.map(item => (
                                            <Panel header={item.name} collapsible bordered>
                                                <p>{item.number_list.join(", ")}</p>
                                            </Panel>
                                        ))}
                                    </PanelGroup>
                                )
                            });
                            break;
                        default:
                            showReasonHandler({
                                status: 1,
                                reasonCode: r.reason_code,
                                reasonHash: r.hash,
                            })
                    }
                    return;
                }

                if (r.error) {
                    return;
                }

                setFiles([]);
                onSuccess(r, method === apiFormData);
                onClose();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const supplierKey = servicePick(service, "supplier_key", "supplier_trunk_id");
    const supplierName = servicePick(service, "supplier_name", "name");

    return (
        <>
            <StyledModal
                show={show}
                showFooter={true}
                title={'Upload numbers'}
                width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
                onConfirm={handleSubmit}
                loading={loading}
                onClose={() => {
                    onClose();
                    setFiles([]);
                }}
                onExited={() => {
                    setIsMultipleUpload(false);
                }}
                {...props}
            >
                <Form
                    ref={formRef}
                    model={formModel}
                    formValue={formValue}
                    onChange={setFormValue}
                >
                    <VerticalContainer>
                        {service ? <FormControl
                            accepter={SelectPicker}
                            data={supplierListData}
                            name={supplierKey}
                            valueKey={supplierKey}
                            labelKey={supplierName}
                            placeholder={'Supplier'}
                            disabled={formValue[rowKey]}
                            style={{width: 224}}
                        /> : <FormControl
                            accepter={SelectPicker}
                            data={trunksData}
                            name={supplierKey}
                            valueKey={supplierKey}
                            labelKey={supplierName}
                            placeholder="Supplier Trunk"
                            disabled={formValue[rowKey]}
                            style={{width: 224}}
                        />}
                        {!service && <CustomCheckbox
                            name="move_from_other_groups"
                            defaultValue={formValue.move_from_other_groups}
                            onChange={(check) => {
                                setFormValue((value) => {
                                    return {
                                        ...value,
                                        move_from_other_groups: check
                                    }
                                });
                            }}
                        >
                            Move from other groups
                        </CustomCheckbox>}
                    </VerticalContainer>

                    <Spacer />

                    {!formValue[rowKey] &&
                    <>
                        <div>
                            <CustomField
                                value={isMultipleUpload}
                                accepter={RadioGroup}
                                inline={resizedWidth > 767}
                                onChange={setIsMultipleUpload}
                            >
                                <Radio value={false}>Single group uploading</Radio>
                                <Radio value={true}>Multiple group uploading</Radio>
                            </CustomField>
                        </div>
                        <hr style={{marginBottom: 30}}/>
                    </>
                    }

                    {(!isMultipleUpload || formValue[rowKey])
                        ? <>
                            <FlexGrid align="middle">

                                <FlexGridItem>
                                    <FormControl
                                        name={'name'}
                                        placeholder={'Group name'}
                                        disabled={formValue[rowKey]}
                                        style={{width: 224}}
                                    />
                                </FlexGridItem>

                                <FlexGridItem>
                                    <FlexGrid>
                                        <FlexGridItem>
                                            <FormControl
                                                accepter={InputNumber}
                                                name={'rate'}
                                                min={0.0001}
                                                placeholder={'Payout'}
                                                disabled={formValue[rowKey]}
                                                style={{width: 90}}
                                            />
                                        </FlexGridItem>
                                        <FlexGridItem style={{alignSelf: 'center'}}>
                                            {currencyName}
                                        </FlexGridItem>
                                    </FlexGrid>
                                </FlexGridItem>


                                {service && <FlexGridItem>
                                    {!formValue.ing_key &&
                                        <BillingIncrement
                                            errorPlacement="bottomStart"
                                            classPrefix={"rs-form-control"}
                                            style={{height: 36}}
                                        />
                                    }
                                </FlexGridItem>}


                            </FlexGrid>

                            <Spacer size={30}/>

                            <FlexGrid noWrap>
                                <FlexGridItem colspan={12}>
                                    <FormControl
                                        name={'text'}
                                        placeholder={'Number list'}
                                        componentClass="textarea"
                                        onChange={() => setFiles(FILES_DEFAULT)}
                                        style={{height: 200}}
                                    />
                                </FlexGridItem>
                                <FlexGridItem colspan={12}>
                                    <ControlLabel>or </ControlLabel>
                                    <FormControl
                                        disabled={files.length > 0}
                                        accepter={StyledUploader}
                                        fileList={files}
                                        autoUpload={false}
                                        multiple={false}
                                        removable={true}
                                        accept="text/plain"
                                        onChange={(files) => {
                                            setFiles(files);
                                        }}
                                    >
                                        <Button>Upload numbers</Button>
                                    </FormControl>
                                </FlexGridItem>
                            </FlexGrid>
                        </>
                        : <>
                            <FormControl
                                disabled={files.length > 0}
                                accepter={StyledUploader}
                                fileList={files}
                                autoUpload={false}
                                multiple={false}
                                removable={true}
                                accept="text/csv"
                                onChange={(files) => {
                                    setFiles(files);
                                }}
                            >
                                <Button>Upload via CSV</Button>
                            </FormControl>

                            <Spacer size={15}/>

                            <StyledControlLabel>
                                * The CSV file must consist of 3 columns: number, group name, and payout. <br/>
                                {service ? <>May contain 4th column 'billing increment' (optional)</> : <></>}
                            </StyledControlLabel>

                        </>
                    }

                    <Spacer size={30}/>

                    <FlexGrid align="middle">
                        {formValue[rowKey] &&
                            <FlexGridItem>
                                <FormControl
                                    cleanable={false}
                                    searchable={false}
                                    accepter={SelectPicker}
                                    name={'existing_group_action'}
                                    data={numberOptions}
                                    placeholder={'Existing group action'}
                                    style={{width: 224}}
                                />
                            </FlexGridItem>
                        }
                                                
                        <FlexGridItem>
                            <FormControl
                                cleanable={false}
                                searchable={false}
                                accepter={SelectPicker}
                                name={'test_number_option'}
                                data={testNumberOptions}
                                placeholder={'Test number option'}
                                // style={{width: 300}}
                            />
                        </FlexGridItem>
                    </FlexGrid>

                </Form>
            </StyledModal>
        </>
    )
};


const StyledUploader = styled(Uploader)`
    
    .rs-uploader-file-item.rs-uploader-file-item-text {
        display: block !important;
    }

    .rs-uploader-file-item {
        background-color: transparent !important;
    }
    
    .rs-uploader-file-items {
        margin-top: 5px !important;
        flex-grow: 1;
    }
    
    .rs-uploader-file-item-title {
        white-space: normal !important;
    }

    .rs-uploader-file-item-size {
        margin-left: auto;
    }
`;

const StyledContainer = styled.div`
  display: flex;
`;

const StyledModal = styled(CustomModal)`
    .rs-modal-body {
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const StyledControlLabel = styled(ControlLabel)`
    font-style: italic;
    opacity: 0.6;
`;

const StyledCloseIcon = styled(Icon)`
    order: 1;
    align-self: center;
    margin-left: 20px;
    cursor: pointer;
`;

const VerticalContainer = styled.div`
    && {
        display: flex;
    }

    && .rs-form-control-wrapper {
        width: 220px;
        margin-right: 15px;
    }
`;