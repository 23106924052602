import React, {useState} from "react";
import Modal from '../../../components/Modal';
import {useWindowWidth} from "../../../hooks";

export default ({
    show,
    onClose,
    maxWidth = 600,
    onSuccess,
    title = ""
}) => {

    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);

    const handleSuccess = async () => {
        setLoading(true);

        await onSuccess();

        setLoading(false);
        onClose();
    };

    return (
        <Modal
            {...{show, onClose}}
            onClose={() => onClose(false)}
            title={title}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            footer={true}
            loading={loading}
            onSuccessClose={false}
            successText="Yes"
            focusTarget="close"
            onSuccess={handleSuccess}
        >
            Are you sure?
        </Modal>
    )
}