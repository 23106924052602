import {connect} from "react-redux";
import Accounts from "./Accounts";

import {getSubAccountList, createSubAccount} from "actions/client/subaccounts";

const mapState = ({auth, auth_dialer, client_subaccounts, references}) => {
    return {
        accountId: auth.dialerInfo ? auth.dialerInfo.account_list.find(account => account.service === "voice")?.id : null,
        paymentTermsList: references.payment_terms_list,

        accountJoinList: auth_dialer.accountJoinList,

        accountList: client_subaccounts.accountList,
        accountListLoading: client_subaccounts.accountListLoading
    }
};

const mapMethods = {
    getSubAccountList,
    createSubAccount
};

export default connect(mapState, mapMethods)(Accounts);