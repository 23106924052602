import {connect} from "react-redux";
import Settings from "./Settings";
import {withRouter} from "react-router-dom";
import {
    getSubAccTemplateList,
    getSubAccHostList,
    createSubAccHost,
    modifySubAccHost
} from "actions/client/subaccounts";


const mapState = ({auth_dialer, client_subaccounts}) => {
    return {
        templateList: client_subaccounts.templateList,
        hostList: client_subaccounts.hostList,
        loading: client_subaccounts.hostListLoading,
        accountJoinList: auth_dialer.accountJoinList
    }
};

const mapMethods = {
    getSubAccTemplateList,
    getSubAccHostList,
    createSubAccHost,
    modifySubAccHost
};

export default withRouter(connect(mapState, mapMethods)(Settings));