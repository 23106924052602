
import React, {useState} from "react";
import Modal from "../../../components/Modal/Modal";
import {api} from "../../../api/loginRoutes";
import {
    SMS_SUPPLIER_PREFIX_SET_ACTIVE_METHOD
} from "const/apiMethods";
import {Alert} from "rsuite/es";


export default ({show, update, trunk_id, prefixKeyList, onClose, status, initialValues}) => {
    const statusText = status ? "activated" : "deactivated";

    const selectedPrefixKeys = prefixKeyList ? Array.from(prefixKeyList.keys()) : [];

    const [disabled, setDisabled] = useState(false);

    const onSubmit = async () => {
        setDisabled(true);

        const method = SMS_SUPPLIER_PREFIX_SET_ACTIVE_METHOD;
        const result = await api(method, {
            target:{
                "sms.supplier_trunk_id": trunk_id
            },
            ...initialValues,
            ...(!initialValues.filterAll ? {"sms.supplier_prefix_key_list": selectedPrefixKeys} : {}),
            active: status
        });

        if(result) {
            Alert.success(`Prefixes have been ${statusText}`);
            onClose();
            update();
        }

        setDisabled(false);
    }

    return (
        <Modal 
            show={show}
            onSuccess={onSubmit}
            onClose={onClose}
            footer
            successText = "Yes"
            disabled = {disabled}
        >
            All prefixes will be {statusText}. Are you sure?
        </Modal>
    )
};
