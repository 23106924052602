import {SPECIAL_RATES, SET_TRANSIT_RATES} from '../const/';
import {ACCOUNT_PRICE_GET_LIST_API} from '../const/apiMethods';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';


const setLoading = createAction('SET_LOADING_'+SPECIAL_RATES);
const setRates = createAction(SPECIAL_RATES);
const setTransitLoading = createAction('SET_LOADING_'+SET_TRANSIT_RATES);
const setTransitRates = createAction(SET_TRANSIT_RATES)

export const getRates = (account_id, filter, service, page = 1 ,per_page= 10000) => (dispatch, getStore) => {
    const store = getStore();

    filter = filter || {sp_key: store.references.defaultSPKey};

    dispatch(setLoading(true));

    api(ACCOUNT_PRICE_GET_LIST_API, {
        filter,
        page,
        per_page,
        service: service ? "voice" : "sms", 
        target: {account_id}
    })
        .then((r) => {
            if (r === undefined) {
                return;
            }
            dispatch(setRates(r.account_price_list || []))
        })
        .finally(() => dispatch(setLoading(false)));
};

export const getTransitRates = (filter, supplier_plan_id, page = 1 ,per_page= 10000) => (dispatch, getStore) => {
    const store = getStore();

    filter = filter || {sp_key: store.references.defaultSPKey};

    dispatch(setTransitLoading(true));

    api('sms.supplier_price:get_list', {
        filter,
        page,
        per_page,
        target: {"sms.supplier_plan_id": supplier_plan_id}
    })
        .then((r) => {
            if (r === undefined) {
                return;
            }
            dispatch(setTransitRates(r['sms.supplier_price_list'] || []))
        })
        .finally(() => dispatch(setTransitLoading(false)));
};