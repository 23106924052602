import {connect} from 'react-redux';
import News from "./News";
import {getNewsDialer, clearCountDialer} from "../../actions/news_management";

const mapState = ({auth, news_management}) => ({
    data: news_management.listDialer,
    count: news_management.listDialerCount,
    loading: news_management.loading,
    inited: news_management.inited,
    lang: auth.lang,
});

export default connect(mapState, {
    getNewsDialer,
    clearCountDialer
})(News);
