import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import {Button, ButtonToolbar, Icon, IconButton} from "rsuite";
import styled, {css} from "styled-components";
import {Spacer} from "../../../components/base/Spacer";
import {toUTCDateTime} from '../../../utils'

const LINE_HEIGHT = 20;
const MAX_LINES = 3;

const MAX_HEIGHT = LINE_HEIGHT * MAX_LINES;

export default (
    {
        lang,
        onClickEdit,
        onClickRemove,
        onClickDeactivate,
        item
    }
) => {

    const textRef = useRef(null);
    const [excerpted, setExcerpted] = useState(false);

    useEffect(() => {
        if (!textRef?.current) {
            return;
        }

        const height = textRef.current.offsetHeight;
        if (height > MAX_HEIGHT) {
            setExcerpted(true);
            return;
        }

        setExcerpted(false);
    }, [item]);

    const text = lang === "en" ? item.text : item.text_arabic || item.text;

    return (
        <div style={{position: 'relative'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>{`By `}
                    <b style={{fontWeight: '500'}}>
                        <span style={{color: 'var(--color-primary)'}}>{item.author}</span> ({item.authorRole})
                    </b>
                    {`, `}
                    <time
                        dateTime={moment(toUTCDateTime(new Date(item.date))).format("YYYY.MM.DD hh:mm")}
                        title={moment(toUTCDateTime(new Date(item.date))).format('LLL')}
                    >
                        {moment(toUTCDateTime(new Date(item.date))).startOf('minute').fromNow()}
                    </time>

                </div>
                <ButtonToolbar>
                    <IconButton
                        size="xs"
                        onClick={() => onClickEdit(item.id)}
                        icon={<Icon icon="edit2" />}
                    />
                    <IconButton
                        size="xs"
                        disabled={!item.active}
                        onClick={() => onClickDeactivate(item.id)}
                        icon={<Icon icon="eye" />}
                    />
                    <IconButton
                        size="xs"
                        color="red"
                        onClick={() => onClickRemove(item.id)}
                        icon={<Icon icon="trash" />}
                    />
                </ButtonToolbar>
            </div>

            <div>
                <StyledMeta 
                    data={item.conditions} 
                    authorRole={item.authorRole}
                />
            </div>

            <StyledText withReadMore={excerpted}>
                <div ref={textRef} dangerouslySetInnerHTML={{__html: text}}/>
            </StyledText>

            {excerpted &&
                <StyledReadMore>
                    <Button
                        onClick={() => setExcerpted(false)}
                        appearance="link"
                        size="sm"
                    >
                        Read More
                    </Button>
                </StyledReadMore>
            }
        </div>
    )
};


const Meta = (
    {
        data,
        authorRole,
        ...props
    }
) => {

    const metaParts = [
        {name: 'AM', value: data?.accountManagers},
        {name: 'currencies', value: data?.currencies},
        {name: 'service plans', value: data?.servicePlans},
        {name: 'payment terms', value: data?.paymentTerms},
        {name: 'role list', value: data?.roleList},
        {name: 'recipients', value: data?.dialers},
    ];

    const isEmpty = metaParts.reduce((summ, item) => {
        return summ ? !item.value : false;
    }, true);

    if (isEmpty) {
        return <dl {...props}>
            <dt>recipients:</dt>
            <dd>All{authorRole !== 'Administrator' && ` of this ${authorRole}`}</dd>
        </dl>
    }

    return (
        <dl {...props}>
            {metaParts.map(item => (
                item.value
                    ? <>
                        <dt>{item.name}:</dt>
                        <dd>{item.value}</dd>
                    </>
                    : null
            ))}
        </dl>
    )
};


const StyledMeta = styled(Meta)`
    display: flex !important;
    font-size: 12px;
    padding-top: 7px;
    color: #939191;

    dt {
        font-weight: 400;
        padding-right: 3px;
        text-transform: uppercase;
    }

    dd {
        padding-right: 10px;
        margin-right: 10px;
        position: relative;
        color: #575757;
        font-weight: 500;

        &:after {
            border-radius: 3px;
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            border-top: 3px solid;
            width: 3px;
            height: 3px;
            margin-top: -2px;
            margin-right: -2px;
        }

        &:last-child {
            padding-right: 0;
            margin-right: 0;

            &:after {
                display: none;
            }
        }
    }
`;


const StyledText = styled.div`
    ${props => props.withReadMore && css`
        max-height: ${MAX_HEIGHT}px; 
        overflow: hidden;
    `}
`;


const StyledReadMore = styled.div`
    position: relative;
    width: 100%;
    text-align: center;
    background: linear-gradient(to bottom, transparent, #ffffff 50%);
    padding-top: 20px;
    margin-bottom: -6px;
    margin-top: -17px;
    
    .rs-btn {
        color: var(--color-primary) !important;
    }
`;