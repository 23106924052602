import React, {useRef, useState, useEffect} from 'react';
import {CheckPicker, Form, Schema} from 'rsuite';
import FormHOC from '../../hoc/FilltersForm';
import CustomSelfClearedForm from '../../components/client/Form/CustomSelfClearedForm';
import CheckboxFullControl from '../../components/Form/CheckboxFullControl';
import CustomField from '../../components/client/Form/CustomField/CustomField';
import {debounce} from '../../utils';
import {useIntl} from "react-intl";
import {FlexGrid, FlexGridItem} from '../../components/base/FlexGrid';
import m from "../../definedMessages";
import {BaseInputField, BaseSelectPicker} from "../../components/base/BaseForm";
import {useWindowWidth} from "../../hooks";
import {clearFormRef} from "../../utils/helpers";
import styled from "styled-components";


export default  FormHOC(({
    loading,
    service,
    savedFilter,
    filterState,
    defaultFilter,
    filtersModel,
    setFilterChanged,
    onChangeFilter,
    prices = [],
    currency_list,
    accountInfo,
}) => {
    const formRef = useRef(null);

    const [filterValueState, setFilterValueState] = useState(filterState || defaultFilter);
    const [checkboxDebounce, setCheckboxDebounce] = useState(false);
    const [checkboxValue, setCheckboxValue] = useState(false);

    useEffect(() => {
        clearFormRef(formRef, true);
        setCheckboxValue(false);
        onChangeFilter(defaultFilter, true);
        setFilterValueState(defaultFilter);
        setFilterChanged(false);
    }, [service]);

    const {formatMessage} = useIntl();
    const windowWidth = useWindowWidth();

    const onSubmit = (data, checkedData) => {
        // const checker = Object.keys(checkedData)
        //     .map(i => checkedData[i].hasError)
        //     .every(value => value === false);

        // if (checker) {
        onChangeFilter({
            ...data, 
            sp_key_list: data.sp_key_list && data.sp_key_list.length 
                ? data.sp_key_list 
                : null,
        });
        setFilterChanged(true);
        // }
    };

    const priceList = prices ? prices.map(({sp_key: value, name: label}) => ({value, label})) : [];
    const curList = currency_list ? currency_list.map(({cur_key: value, name: label}) => ({value, label})) : [];

    return (
        <CustomSelfClearedForm
            ref={formRef}
            clearOn={service}
            model={filtersModel}
            formValue={filterValueState}
            formDefaultValue={defaultFilter}
            onChange={debounce((data) => {
                const checkedData = filtersModel.check(data);
                onSubmit(data, checkedData);
                setFilterValueState(data);
                setCheckboxDebounce(false);
            }, 1000)}
            fluid
        >
            <FlexGrid align="top">
                <FlexGridItem width={windowWidth > 767 ? "180px" : "100%"}>
                    <CustomField
                        data={priceList}
                        accepter={CheckPicker}
                        readOnly={loading}
                        value={setFilterValueState?.sp_key_list}
                        searchable={false}
                        name="sp_key_list"
                        cleanable={false}
                        virtualized={false}
                    />
                </FlexGridItem>
                {accountInfo?.is_test &&
                    <FlexGridItem>
                        <CustomField
                            data={curList}
                            accepter={BaseSelectPicker}
                            readOnly={loading}
                            name="cur_key"
                            width={windowWidth > 767 ?  service ? "360px" : "280px" : "100%"}
                            cleanable={false}
                            searchable={false}
                        />
                    </FlexGridItem>
                }
                <FlexGridItem width={windowWidth > 767 ? service ? "360px" : "320px": "100%"}>
                    <CustomField
                        name={service ? "str" : "origin"}
                        accepter={BaseInputField}
                        readOnly={loading}
                        errorPlacement="topEnd"
                        placeholder={formatMessage(m.destinationFilterMinCharacter)}
                    />
                </FlexGridItem>
                <FlexGridItem width={windowWidth > 767 ? service ? "360px" : "320px" : "100%"}>
                    <CustomField
                        name={service ? "str2" : "destination"}
                        accepter={BaseInputField}
                        readOnly={loading}
                        errorPlacement="topEnd"
                        placeholder={formatMessage(m.customDestinationOnlyFilterMinCharacter, {destination: 'destination'})}
                    />
                </FlexGridItem>
                {!service && <FlexGridItem width={windowWidth > 767 ? service ? "380px" : "320px" : "100%"}>
                    <CustomField
                        name="message"
                        accepter={BaseInputField}
                        readOnly={loading}
                        errorPlacement="topEnd"
                        placeholder={formatMessage(m.message)}
                    />
                </FlexGridItem>}

                <FlexGridItem className={checkboxDebounce ? "disabled" : ""}>
                    <CustomField
                        accepter={CheckboxFullControl}
                        onChange={(check) => {
                            setCheckboxDebounce(true);
                            setCheckboxValue(check);
                        }}
                        value={checkboxValue}
                        disabled={checkboxDebounce}
                        name="no_number"
                    >
                        {formatMessage(m.hidePrefixes)}
                    </CustomField>
                </FlexGridItem>
            </FlexGrid>
            <SecondFlexGrid align="top">
            
            </SecondFlexGrid>
        </CustomSelfClearedForm>
    );
});


const SecondFlexGrid = styled(FlexGrid)`
    margin-top: 20px;

    && .rs-flex-box-grid-item.disabled {
        opacity: 0.3;
        cursor: not-allowed;
        pointer-events: none;
    }
`;