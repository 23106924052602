import React, {useRef, useState} from "react";
import {Form, FormControl, Icon, Input, InputGroup} from "rsuite";
import styled from "styled-components";
import {debounce} from '../../utils';

export default ({
    filter,
    onChangeFilter
}) => {

    // const [filterState, setFilterState] = useState(filter);

    return (
        <StyledField>
            <Form 
                formValue={filter}
                onChange={(value) => {
                    // setFilterState(value);
                    onChangeFilter(value);
                }}
                // onChange={debounce((value) => onChangeFilter(value), 700)}
            >
                <InputGroup>
                    <FormControl
                        name="str"
                        accepter={Input}
                        placeholder="Dialers filter"
                    />
                    <InputGroup.Addon>
                        <Icon icon="search" />
                    </InputGroup.Addon>
                </InputGroup>
            </Form>
        </StyledField>
    )
}

const StyledField = styled.div`
    max-width: 300px;
    margin-bottom: 20px;
`;