import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
    Checkbox,
    CheckboxGroup,
    ControlLabel,
    DateRangePicker,
    FormControl,
    FormGroup,
    Schema,
    Input,
    Form
} from 'rsuite';
import FormHOC from '../../../hoc/FilltersForm';
import CustomSelfClearedForm from '../../../components/client/Form/CustomSelfClearedForm';
import {getAllowedRangesByIntervalMonth, removeTZFromDate, toUTCDateTime} from '../../../utils';
import {useIntl} from "react-intl";
import {compose} from "redux";
import {useWindowWidth} from "../../../hooks";
import { FlexGrid, FlexGridItem } from '../../../components/base/FlexGrid';
import m from "../../../definedMessages";
import {BaseCheckboxField, BaseDateRangePicker, BaseInputField} from "../../../components/base/BaseForm";
import {RtlContext} from "../../../App";
import DatePickerCustom from '../../../components/Filters/DatePickerCustom';
import DateTimeRangePicker from "../../../components/DateRangePicker/DateTimerRangePicker";
import styled from "styled-components";


const TrafficStatFilters = ({
    mobile,
    filter,
    service,
    loading,
    onChangeFilters,
    filtersModel,
    globalSettings = {},
}) => {
    const {formatMessage} = useIntl();
    const form = useRef(null);
    const resizedWidth = useWindowWidth();
    const rtl = useContext(RtlContext);

    const todayDateStart = new Date(new Date().setHours(0, 0, 0, 0));
    const todayDateEnd = new Date(new Date().setHours(23, 59, 59, 999));

    const groupsList = service ? ['range', 'a_number', 'b_number'] : ['range', 'senderid', 'phone'];
    const groupsString = service ? 'range/a_number/b_number' : 'range/senderid/phone';
    const defaultFilter = {
        range: [todayDateStart, todayDateEnd],
        start_date: new Date(new Date().setHours(0, 0, 0, 0)),
        end_date: new Date(new Date().setHours(23, 59, 59, 999)),
        group: groupsString,
        ...(service ? {a_number: ''} : {senderid: ''})
    };

    const [changedFormValue, changeFormValue] = useState(filter || defaultFilter);

    const [checkboxState, setCheckboxState] = useState(filter?.checkbox);

    useEffect(() => {
        changeFormValue(defaultFilter);
        
        const filtersData = {
            ...defaultFilter,
            group: groupsString,
            checkbox: groupsList,
            start_date: defaultFilter.start_date,
            end_date: defaultFilter.end_date,
        };
        onChangeFilters(filtersData);
    }, [service]);

    const defaultFilterValue = useMemo(() => {
        return {
            ...filter,
            ...defaultFilter
        };
    }, [filter, service]);

    const replaceGroupByService = (checkboxList, service) => {
        const checkboxGroup = [...checkboxList];

        if (service === false) {
            checkboxGroup[checkboxGroup.indexOf("a_number")] = "senderid";
            checkboxGroup[checkboxGroup.indexOf("b_number")] = "phone";
        } else if (service === true) {
            checkboxGroup[checkboxGroup.indexOf("senderid")] = "a_number";
            checkboxGroup[checkboxGroup.indexOf("phone")] = "b_number";
        }

        return checkboxGroup;
    }

    useEffect(() => {
        const checkboxGroup = replaceGroupByService(groupsList, service);
        
        setCheckboxState(checkboxGroup);
    }, [service])

    const onSubmit = (data) => {
        const startDate = new Date(data.start_date);
        const endDate = new Date(data.end_date);

        if ( !form.current || !form.current.check() )
            return;

        // changeFormValue(formData);

        const filtersDataGroup = groupsList.reduce((sum, current) => (
            data['checkbox'] && data['checkbox'].find(dataItem => dataItem === current)
                ? [...sum, current]
                : sum
        ), []);

        const filtersData = {
            ...data,

            group: (data['checkbox']
                ? filtersDataGroup.join('/')
                : groupsString),

            checkbox: data.checkbox
                ? data.checkbox
                : groupsList,

            start_date: startDate,
            end_date: endDate
        };

        onChangeFilters(filtersData);
    };

    const groupItemList = service 
        ? [['a_number', m.aNumber], ['b_number', m.bNumber], ['range', m.ranges]]
        : [['senderid', m.senderId], ['phone', m.bNumber], ['range', m.ranges]];

    const handleChangeDateTime = (value) => {
        const filter = {...value};
        const currentDatetimeFilter = {range: [filter.range[0], filter.range[1]]};

        const filtersToUpdate = {
            ...changedFormValue,
            ...filter,
            ...currentDatetimeFilter
        };

        changeFormValue(filtersToUpdate);

        const filterValues = {...filtersToUpdate};

        if (filterValues.range && filterValues.range.length) {
            // filterValues['start_date'] = toStartDayUTCTime(filterValues.range[0]);
            // filterValues['end_date'] = toEndDayUTCTime(filterValues.range[1]);
            filterValues['start_date'] = toUTCDateTime(filterValues.range[0]);
            filterValues['end_date'] = toUTCDateTime(filterValues.range[1]);

            // delete filterValues.range;
        }
        const checkedData = filtersModel.check(filterValues);
        onSubmit(filterValues, checkedData);
    };
    

    return (
        <CustomSelfClearedForm
            formRef={form}
            clearOn={service}
            model={filtersModel}
            onChange={(data) => {
                const formData = {
                    ...data,
                    range: changedFormValue?.range
                };
                changeFormValue(formData);

                const formDataValues = {
                    ...formData,
                    range: changedFormValue?.range
                };

                if (formDataValues.range && formDataValues.range.length) {
                    // filterValues['start_date'] = toStartDayUTCTime(filterValues.range[0]);
                    // filterValues['end_date'] = toEndDayUTCTime(filterValues.range[1]);
                    formDataValues['start_date'] = toUTCDateTime(formDataValues.range[0]);
                    formDataValues['end_date'] = toUTCDateTime(formDataValues.range[1]);
        
                    // delete formDataValues.range;
                }

                onSubmit(formDataValues)
            }}
            formDefaultValue={defaultFilter}
            fluid
            disabled={loading}
        >
            <FlexGrid align="middle">
                {(resizedWidth > 406) &&
                    <FlexGridItem>{formatMessage(m.dateFrom)}</FlexGridItem>
                }
                <FreeSpaceFlexGridItem>
                    {/* <DatePickerCustom
                        isDisabledDates
                        changedFormValue={changedFormValue}
                        showRangeForSevenDays={false}
                    /> */}
                    <DateTimeRangePicker
                        onChange={(value)=> {
                            handleChangeDateTime(value);
                        }}

                        defaultStartDate={defaultFilter.range[0]}
                        defaultEndDate={defaultFilter.range[1]}
                        startDate={changedFormValue?.range ? changedFormValue?.range[0] : defaultFilter.range[0]}
                        endDate={changedFormValue?.range ? changedFormValue?.range[1] : defaultFilter.range[1]}
                        mobile={mobile}
                        // input={false}
                    />
                </FreeSpaceFlexGridItem>

                <FlexGridItem>
                    <FormControl
                        name={service ? "a_number" : "senderid"}
                        accepter={BaseInputField}
                        placement={resizedWidth >= 768 ? (rtl ? "bottomEnd" : "bottomStart") : 'auto'}
                        placeholder={service ? formatMessage(m.aNumber) : formatMessage(m.senderId)}
                    />
                </FlexGridItem>

                <FlexGridItem>
                    <FlexGrid align="middle">

                        {resizedWidth > 406 &&
                            <FlexGridItem>
                                <ControlLabel>{formatMessage(m.groupBy)}</ControlLabel>
                            </FlexGridItem>
                        }

                        <FlexGridItem>
                            <FormGroup>
                                <FormControl
                                    name="checkbox"
                                    accepter={CheckboxGroup}
                                    inline
                                    defaultValue={defaultFilterValue?.checkbox}
                                    value={checkboxState}
                                    onChange={(value) => {
                                        setCheckboxState(value)
                                    }}
                                >
                                    {groupItemList.map(item => {
                                        const message = (typeof item[1]) === "string" ? item[1] : formatMessage(item[1]);

                                        return (<BaseCheckboxField
                                            key={item[0]}
                                            value={item[0]}
                                            defaultChecked
                                            disabled={loading}
                                        >
                                            {message}
                                        </BaseCheckboxField>)
                                    })}
                                </FormControl>
                            </FormGroup>
                        </FlexGridItem>

                    </FlexGrid>
                </FlexGridItem>
            </FlexGrid>
        </CustomSelfClearedForm>
    );
};

export default compose(
    FormHOC,
)(TrafficStatFilters);


const FreeSpaceFlexGridItem = styled(FlexGridItem)`
    && {
        width: auto;
        position: inherit;
    }
`;
