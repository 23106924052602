import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import './config/Alert';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './scss/index.css';
import './scss/rtl.css';
import store from "./store";
import packageJson from '../package.json';

import TagManager from 'react-gtm-module'
import {APP_TYPE_DEFAULT, APP_TYPE_CLIENT} from 'const';

const gtmKey = process.env.REACT_APP_GTM_KEY;


export const APP_VERSION = packageJson.version;

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (APP_TYPE_DEFAULT === APP_TYPE_CLIENT && gtmKey) {
    TagManager.initialize({gtmId: gtmKey});
}
