import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {ButtonToolbar, Dropdown, Loader} from 'rsuite';
import {
    downloadNumbersCommon, 
    downloadNumbersTxtCommon,
} from 'utils';
import styled, {css} from "styled-components";
import m from "../../definedMessages";


export default (
    {
        service,
        currentDisplay,
        activeTrunkId,
        uploadLoading,
        setUploadingLoading,
        activeFilter,
        ...props
    }
) => {

    const {formatMessage} = useIntl();

    const handleDownloadCompleteNumbersCsv = () => {
        downloadNumbersCommon(activeTrunkId, activeFilter, () => setUploadingLoading(activeTrunkId, false), service)
    };

    const handleDownloadCompleteNumbersTxt = () => {
        downloadNumbersTxtCommon(activeTrunkId, activeFilter, () => setUploadingLoading(activeTrunkId, false), service)
    };


    return (
        <StyledButtonToolbar>
            <StyledDropdown
                appearance="default"
                onSelect={() => setUploadingLoading(activeTrunkId, true)}
                title={<>{uploadLoading[activeTrunkId] ? <StyledLoader/> : formatMessage(m.download)}</>}
                disabled={currentDisplay !== "trunk_number" || !activeTrunkId || uploadLoading[activeTrunkId]}
                {...props}
            >
                
                <Dropdown.Item 
                    onClick={handleDownloadCompleteNumbersCsv}
                >
                    <FormattedMessage
                        id="common.downloadAllocatedNumbersAs"
                        defaultMessage="Download allocated numbers as {type}"
                        values={{type: 'csv'}}
                    />
                </Dropdown.Item>                

                <Dropdown.Item
                    onClick={handleDownloadCompleteNumbersTxt}
                >
                    <FormattedMessage
                        id="common.downloadAllocatedOnlyNumbersAs"
                        defaultMessage="Download only numbers"
                        values={{type: 'txt'}}
                    />
                </Dropdown.Item>

            </StyledDropdown>
        </StyledButtonToolbar>
    )
};


const StyledButtonToolbar = styled(ButtonToolbar)`
    width: 140px;
    display: inline-block; 
    margin-right: 10px;
    ${props =>
        props.width && css`
            width: ${props.width};
        `
    }
    .rtl & {
        margin-right: 0;
        margin-left: 10px;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 10px;
        text-align: center;
    }
`;

const StyledDropdown = styled(Dropdown)`
    width: 100%;
    height: 36px;
    
    &&&&& {
        .rs-btn {
            background-color: var(--color-brand2);
            width: 100%;
            color: white;
            height: 36px;
            padding: 8px 20px 8px 12px;
            
            &:hover {
                background-color: #5f2bb3;
                color: white;
            }
            
            .rtl & {
                padding: 8px 12px 8px 32px;
            }
        }
        
        .rs-dropdown-menu {
            background-color: #fdfdfd !important;
        }
        
        & > .rs-dropdown-item > .rs-dropdown-item-content:hover {
            background-color: #cfcfcf !important;
            color: #5c5c5c !important;
        }
        
        &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
            background-color: #D5D4D4 !important;
            color: white;
        }
        
        &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
            background-color: #D5D4D4 !important;
            color: white;
        }
        
        @media (max-width: 768px) {
            width: 140px;
        }
    }
`;
const StyledLoader = styled(Loader)`
  & span.rs-loader-spin {
    margin-top: 3px;
    margin-right: 5px;
  }
`;