import {connect} from "react-redux";
import Numbers from "./Numbers";
import {withRouter} from "react-router-dom";
import {
    getSubAccountList,
    trunkSubAccGetNumberList,
    trunkSubAccChangeRateNumbers,
    trunkSubAccRevokeNumbers,
    trunkSubAccAllocateNumbers,
    trunkSubAccDownloadNumbers,
    trunkSubAccChangeDefaultDiscount,
} from "actions/client/subaccounts";
import {
    downloadNumbers,
    getNumberTrunksList,
    setUploadingLoading,
    setTrunkNumberTransaction
} from "actions/client/numbers";
import {showReasonHandler} from "actions/reason_handler";


const mapState = ({auth, auth_dialer, client_numbers, client_subaccounts}) => {
    return {
        numberList: client_subaccounts.numberList,
        numberPage: client_subaccounts.numberPage,
        numberCount: client_subaccounts.numberCount,
        numberPerPage: client_subaccounts.numberPerPage,
        numberListLoading: client_subaccounts.numberListLoading,
        
        trunkList: client_numbers.numberTrunks.filter(trunk => trunk.is_reseller),
        trunkListLoading: client_numbers.numberTrunkLoading,

        trunkNumberTransaction: client_numbers.trunk_number_transaction,
        uploadLoading: client_numbers.uploadTrunkLoading,
        allocationLoading: client_numbers.loadingAllocatedNumbers,

        subAccountList: client_subaccounts.accountList,
        accountJoinList: auth_dialer.accountJoinList,

        accountId: auth.dialerInfo ? auth.dialerInfo.account_list.find(account => account.service === "voice")?.id : null,
    }
};

const mapMethods = {

    getNumberList: trunkSubAccGetNumberList,
    getSubAccountList,
    getNumberTrunksList,

    downloadNumbers,
    showReasonHandler,
    setUploadingLoading,
    setTrunkNumberTransaction,

    allocateNumbersFunc: trunkSubAccAllocateNumbers,
    downloadNumbersFunc: trunkSubAccDownloadNumbers,
    changeDefaultDiscountFunc: trunkSubAccChangeDefaultDiscount,

    changeRateNumbersFunc: trunkSubAccChangeRateNumbers,
    revokeNumbersFunc: trunkSubAccRevokeNumbers
};

export default withRouter(connect(mapState, mapMethods)(Numbers));