import React, {useState} from "react";
import Table from "components/Table";
import {formatDateApi} from "utils"
import { ButtonPrimary } from "components/base/BaseButton";

import styled from "styled-components";
import ModalConfirm from "components/Modal/ModalConfirm";

import m from "definedMessages";


const PaymentsTable = ({
    data = [],
    loading = false,
    onRemove,

    formatMessage,
    ...props 
}) => {

    const [paymentKeyOnDelete, setPaymentKeyOnDelete] = useState(null);

    const columns = [
        {
            label: formatMessage(m.date),
            dataKey: "date",
            value: (({date}) => {
                return <span className="table-centered__cell">
                    {formatDateApi(date || '')}
                </span>
            }),
            flexGrow: 1,
            minWidth: 200,
        },
        {
            label: formatMessage(m.amount),
            dataKey: "amount",
            value: (({amount, currency_name}) => {
                return <span className="table-centered__cell">
                    {amount} {currency_name}
                </span>
            }),
            flexGrow: 2,
            minWidth: 120

        },
        {
            label: formatMessage(m.comment),
            dataKey: "comment",
            value: (({comment}) => {
                return <span className="table-centered__cell">
                    {comment}
                </span>
            }),
            flexGrow: 1,
            minWidth: 300,
        },
        {
            label: formatMessage(m.action),
            value: (({payment_key}) => {
                return <RemoveButton
                    onClick={() => {
                        setPaymentKeyOnDelete(payment_key);
                    }}
                >
                    {formatMessage(m.remove)}
                </RemoveButton>
            }),
            width: 100,
            align: "center"
        }
    ];

    return <>
        <Table
            data={data}
            columns={columns}
            loading={loading}
            {...props}
        />
        <ModalConfirm
            show={!!paymentKeyOnDelete}
            title={formatMessage(m.areYouSure)}
            width={320}
            onClose={() => {
                setPaymentKeyOnDelete(null);
            }}
            onSuccess={() => {
                onRemove(paymentKeyOnDelete);
                setPaymentKeyOnDelete(null);
            }}
        >
            {formatMessage(m.paymentWillBeDeleted)}
        </ModalConfirm>
    </>
};

export default PaymentsTable;

const RemoveButton = styled(ButtonPrimary)`
    && {
        width: 80px;
        min-width: 80px;
        height: 24px;
        padding-top: 2px;
        top: -2px;
    }
`;