import {Form, FormControl, Icon, InputGroup} from "rsuite";
import React from "react";
import styled from "styled-components";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import {debounce} from "../../utils";


export default (
    {
        filter = {},
        onChangeFilter,
    }
) => {

    return (
        <Form
            formDefaultValue={filter}
            onChange={debounce((value) => {
                onChangeFilter(value);
            }, 1000)}
        >
            <FlexGrid align="middle">
                <FlexGridItem style={{flexGrow: 1, width: '100%', maxWidth: 284}}>
                    <StyledInputGroup>
                        <FormControl
                            name="name"
                            errorPlacement="topRight"
                            placeholder={"Name"}
                        />
                        <InputGroup.Addon>
                            <Icon icon="search" />
                        </InputGroup.Addon>
                    </StyledInputGroup>
                </FlexGridItem>
            </FlexGrid>
        </Form>
    )
};


const StyledInputGroup = styled(InputGroup)`
    && {
        overflow: visible;
        
        @media (min-width: 1200px) {
            margin-bottom: 0;
        }
    }

    &&&& {
        .rs-input {
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
        }
    }
`;