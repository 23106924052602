import React from "react";
import { EOL } from 'const';
import {Alert, Dropdown, Modal, List as RsuiteList} from "rsuite";
import {useWindowWidth} from "../../../hooks";
import styled from "styled-components";
import ListNotCreatedRanges from "./ListNotCreatedRanges";
import * as S from "../../../components/Modal/ModalAllocationResult/styled";
import {copyToClipboard, downloadDataAsFile} from "../../../utils";
import {CustomModal} from "../../../components/base/BaseModal";

export default ({
    show,
    onClose,
    width = 820,
    data = [],
    newNumbersCount,
    rejectedNumbers = [],
}) => {

    const windowWidth = useWindowWidth();

    const onDownloadList = () => {
        if (!data || !data.length)
            return;

        const listHeader = "Name;Test numbers";

        const listData = data.map(item => {
            const testNumbers = item.test_trunk_number_list && item.test_trunk_number_list.length
                ? `${item.test_trunk_number_list.join(', ')}`
                : "";
            return [item.name, testNumbers].join(";");
        });

        downloadDataAsFile([listHeader, ...listData].join('\n'), 'new_ranges.csv');
    };

    const onCopyList = () => {
        const listData = data.map(item => {
            const testNumbers = item.test_trunk_number_list && item.test_trunk_number_list.length
                ? `(${item.test_trunk_number_list.join(', ')})`
                : "";
            return [item.name, testNumbers].join(" ");
        });
        copyToClipboard( listData.join(EOL) );
        Alert.success(`Copied!`);
    };

    const numberCsvListItems = data
        .map( (item, index) => {
                return (
                    <RsuiteList.Item key={index}>
                        <span>{item.name}</span>
                        {item.test_trunk_number_list && item.test_trunk_number_list.length
                            ? ` (${item.test_trunk_number_list.join(', ')})`
                            : ""
                        }
                    </RsuiteList.Item>
                )
            }
        );

    return (
        <StyledModal
            show={show}
            onHide={onClose}
            as={Modal}
            // width={windowWidth < 820 ? windowWidth : 820}
            style={{width: windowWidth < width ? windowWidth : width}}
        >
            <Modal.Header>
                <Modal.Title>Add new ranges</Modal.Title>
            </Modal.Header>

            <Modal.Body className="modal-body">
                {rejectedNumbers && !!rejectedNumbers.length &&
                    <ListNotCreatedRanges
                        count={rejectedNumbers.length}
                        numbers={rejectedNumbers}
                    />
                }

                <div className="allocation_result_warning-message" style={{textAlign: 'center'}}>
                    <b>{newNumbersCount || 0}</b> number(s) were added in ranges
                </div>

                <List
                    size='sm'
                    style={{height: data.length * 36, maxHeight: 288}}
                >
                    {numberCsvListItems}
                </List>
            </Modal.Body>

            <Modal.Footer className="modal-footer">
                {data && data.length
                    ? <>

                        <S.ButtonToolbar width={windowWidth < 576 ? "100%" : "30%"}>
                            <S.Dropdown
                                title={"Copy List"}
                                placement={windowWidth < width ? 'topStart' : 'bottomStart'}
                            >
                                <Dropdown.Item onClick={onCopyList}>Copy list</Dropdown.Item>
                            </S.Dropdown>
                        </S.ButtonToolbar>

                        <S.ButtonToolbar width={windowWidth < 576 ? "100%" : "30%"}>

                            <S.Dropdown
                                title={"Download List"}
                                placement={windowWidth < width ? 'topStart' : 'bottomStart'}
                            >
                                <Dropdown.Item onClick={onDownloadList}>
                                    Download new ranges list
                                </Dropdown.Item>

                            </S.Dropdown>

                        </S.ButtonToolbar>

                        <S.ButtonToolbar width={windowWidth < 576 ? "100%" : "30%"}>

                            <S.CancelButton
                                width="100%"
                                onClick={onClose}
                            >
                                Close
                            </S.CancelButton>

                        </S.ButtonToolbar>
                    </>
                    : null
                }

            </Modal.Footer>
        </StyledModal>
    )
};


export const List = styled(RsuiteList)`
    margin-top: 10px;
    box-shadow: none !important;
    
    @media (max-width: 767px) {
        display: none !important;
    }
`;

export const StyledModal = styled(CustomModal)`
    && {
        .rs-modal-body {
            overflow: auto !important;
        }
    }
`;