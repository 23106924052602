import {connect} from "react-redux";
import Traffic from "./Traffic";
import {withRouter} from "react-router-dom";
import {
    getSubAccountList,

    getTrafficCdrList,
    exportTrafficCdrList
} from "actions/client/subaccounts";


const mapState = ({auth, auth_dialer, client_subaccounts}) => {
    return {
        cdrList: client_subaccounts.cdrList,
        cdrReportList: client_subaccounts.cdrReportList,
        cdrSummaries: client_subaccounts.cdrSummaries,
        
        loading: client_subaccounts.cdrLoading,

        page: client_subaccounts.cdrPage,
        count: client_subaccounts.cdrCount,
        perPage: client_subaccounts.cdrPerPage,

        subAccountList: client_subaccounts.accountList,
        accountJoinList: auth_dialer.accountJoinList,
    }
};

const mapMethods = {
    getSubAccountList,
    getTrafficCdrList,
    exportTrafficCdrList
};

export default withRouter(connect(mapState, mapMethods)(Traffic));