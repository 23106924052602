import {connect} from 'react-redux';
import PageIvrs from "./PageIvrs";
import {getIvrList, getIvrTypes} from "../../../actions/ivr";
import {getIvrSoundList} from "../../../actions/ivr_sounds";

const mapState = ({ivr, ivr_cli, ivr_sounds}) => ({
    data: ivr.list,
    loading: ivr.loading,
    ivrTypes: ivr.types,
    ivrCliList: ivr_cli.list,
    ivrSoundsList: ivr_sounds.list,
});

export default connect(mapState, {
    getIvrList,
    getIvrTypes,
    getIvrSoundList,
})(PageIvrs);