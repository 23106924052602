import {defineMessages} from "react-intl";

export default defineMessages({
    cdrHistoryPeriodIs: {
        id: "cdr.cdrHistoryPeriodIs", 
        defaultMessage: "CDR history period is {count} month", 
        description: 'Traffic, CDRFilters: CDR history period is {count} month'
    },
    senderId: {
        id: "cdr.senderId",
        defaultMessage: "Sender ID", 
        description: "TestCallsFilters, TestCallsTable, CDR, CDRFilters, TrafficStat, TrafficStatFilters: Sender ID"
    },
    filterAvailableForTwoDays: {
        id: "cdr.filterAvailableForTwoDays",
        defaultMessage: "The filter is only available for the selected two days",
        description: "TestCalls, CDR, Traffic: The filter is only available for the selected two days"
    }
})