import React, {useEffect, useState, useRef, useMemo} from "react";
import {CheckPicker, FormControl, Form, Alert, Button, ButtonGroup} from "rsuite";
import TextEditor from "../../components/base/TextEditor";
import styled from "styled-components";
import {Spacer} from "../../components/base/Spacer";
import Checkbox from "../../hoc/Checkbox";
import {CustomModal} from "../../components/base";
import {NEWS_CREATE_API, NEWS_MODIFY_API} from "../../const/apiMethods";
import {api} from "../../api/loginRoutes";


const footerStyles = {
    padding: '10px 2px',
    borderTop: '1px solid #e5e5e5'
};

const serviceListData = [
    {
        name: "Voice",
        id: "voice"
    },
    {
        name: "SMS",
        id: "sms"
    }
];

export default (
    {
        show,
        activePostData = {},
        accountManagerList,
        dialerGroupsList,
        paymentTermsList,
        currencyList,
        servicePlanList,
        roleList,
        accounts: _accounts,

        getAccountManagersDropdownList,
        getDropdownAccounts,
        getDialerGroupsList,
        onUpdate,
        onClose,
    }
) => {
    
    const formRef = useRef(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [_lang, _setLang] = useState("en");

    const [formValue, setFormValue] = useState({});
    const [selectedAm, setSelectedAm] = useState([]);
    const [accounts, setAccounts] = useState(_accounts);
    const [selectedDialerGroups, setSelectedDialerGroups] = useState([]);

    // WYSIWYG message text
    const [messageTextObject, setMessageTextObject] = useState({message: ""});

    useEffect(() => {
        setFormValue(activePostData ? {...activePostData} : {});
    }, [activePostData]);


    useEffect(() => {
        setMessageTextObject({message: activePostData?.text || ""});
        _setLang("en");
    }, [activePostData]);


    const allValueData = useMemo(() => {
        return {
            role_list: roleList.map(item => item.value),
            nwg_key_list: dialerGroupsList.map(item => item.nwg_key),
            account_manager_id_list: accountManagerList.map(item => item.id),
            pt_key_list: paymentTermsList.map(item => item.pt_key),
            cur_key_list: currencyList.map(item => item.cur_key),
            sp_key_list: servicePlanList.map(item => item.sp_key),
            account_id_list: accounts.map(item => item.id),
            service_list: serviceListData.map(item => item.id)
        }
    }, [accountManagerList, paymentTermsList, currencyList, servicePlanList, accounts, roleList]);


    useEffect(() => {
        getAccountManagersDropdownList();
        getDropdownAccounts();
        getDialerGroupsList();
    }, []);


    useEffect(() => {
        // set filtered accounts 
        if (!selectedAm || !selectedAm.length) {
            setAccounts(_accounts);
            return;
        }

        const fileterdAccounts = _accounts.filter(item => selectedAm.includes(item.account_manager_id));
        setAccounts(fileterdAccounts);

        // unset excluded form dialers
        if (formValue.account_id_list && formValue.account_id_list.length) {
            const filteredFormValueDialers = formValue.account_id_list.filter(id => {
                return fileterdAccounts.find(item => item.id === id);
            });

            if (filteredFormValueDialers.length !== formValue.account_id_list.length) {
                setFormValue(prev => ({
                    ...prev, 
                    account_id_list: [...filteredFormValueDialers]
                }));
            }
        }
    }, [_accounts, selectedAm]);



    const handleSelectAll = (name, checked) => {
        const allValue = allValueData[name];
        setFormValue(prev => ({
            ...prev, 
            [name]: !checked ? null : allValue
        }));
    };


    const handleSubmitForm = () => {

        if (!formValue.text) {
            Alert.error("You must fill in the text field for the English version", 10000);
            return;
        }

        const formData = {...formValue};

        const params = {
            text: formValue.text || "",
            text_arabic: formValue.text_arabic || "",
        };

        delete formData.text;
        delete formData.text_arabic;

        if (formData.news_id) {
            params.target = {news_id: formData.news_id};
            delete formData.news_id;
        }

        for (const prop of Object.keys(formData)) {

            const value = formData[prop] && formData[prop].length
                ? formData[prop]
                : null;

            let isAllChecked = false;
            if (value && value.length && allValueData[prop]) {
                if (prop === 'service_list') {
                    isAllChecked = false;
                } else if (prop !== 'account_id_list') {
                    isAllChecked = allValueData[prop].length === value.length;
                } else {
                    isAllChecked = _accounts.length === value.length;
                }
            }

            if ( ['account_manager_id_list', 'account_id_list'].includes(prop) ) {
                if (!params.target) {
                    params.target = {};
                }
                params.target[prop] = isAllChecked ? null : value;
                continue;
            }

            params[prop] = value;
        }

        setSubmitLoading(true);
        const method = formValue && formValue.news_id
            ? NEWS_MODIFY_API
            : NEWS_CREATE_API;

        api(method, params)
            .then(r => {
                if (r === undefined)
                    return;

                onClose();
                onUpdate && onUpdate();
                Alert.success(formValue && formValue.news_id
                    ? "Message was updated"
                    : "New message created successfully"
                );
            })
            .finally(() => {
                setSubmitLoading(false);
                setSelectedDialerGroups([]);
            });
    };


    const setTextValue = (value) => {
        const prop = _lang === "en" ? "text" : "text_arabic";
        setFormValue(prev => ({
            ...prev,
            [prop]: value
        }));
    };

    return (
        <CustomModal
            showFooter
            show={show}
            loading={submitLoading}
            title={formValue && formValue.news_id ? "Update post" : "Create new post"}
            confirmButtonText={formValue && formValue.news_id ? "Update" : "Create"}
            style={{width: 960}}
            onConfirm={handleSubmitForm}
            onClose={() => {
                setFormValue({text: "", text_arabic: ""});
                _setLang("en");
                setMessageTextObject({message: ""});
                setSelectedDialerGroups([]);
                onClose();
            }}
        >
            <Form
                ref={formRef}
                formValue={formValue}
                onChange={setFormValue}
            >
                <StyledFormContainer>

                    <div className="news-form-filters__wrapper">
                        <div className="news-form-filters">

                            <div className="news-form-filters__item">
                                <FormControl
                                    accepter={CustomPicker}
                                    name="nwg_key_list"
                                    valueKey="nwg_key"
                                    labelKey="name"
                                    disabled={formValue.all}
                                    onChange={setSelectedDialerGroups}
                                    data={dialerGroupsList}
                                    placeholder="Groups"
                                    onSelectAll={handleSelectAll}
                                />
                            </div>

                            <div className="news-form-filters__item">
                                <FormControl
                                    accepter={CustomPicker}
                                    name="account_manager_id_list"
                                    valueKey="id"
                                    labelKey="name"
                                    disabled={formValue.all}
                                    onChange={setSelectedAm}
                                    data={accountManagerList}
                                    placeholder="All AM"
                                    onSelectAll={handleSelectAll}
                                />
                            </div>

                            <div className="news-form-filters__item">
                                <FormControl
                                    accepter={CustomPicker}
                                    name="pt_key_list"
                                    valueKey="pt_key"
                                    labelKey="name"
                                    data={paymentTermsList}
                                    placeholder="All payment terms"
                                    disabled={formValue.all}
                                    onSelectAll={handleSelectAll}
                                />
                            </div>

                            <div className="news-form-filters__item">
                                <FormControl
                                    accepter={CustomPicker}
                                    name="cur_key_list"
                                    valueKey="cur_key"
                                    labelKey="name"
                                    data={currencyList}
                                    placeholder="All currencies"
                                    disabled={formValue.all}
                                    onSelectAll={handleSelectAll}
                                />
                            </div>

                            <div className="news-form-filters__item">
                                <FormControl
                                    accepter={CustomPicker}
                                    name="sp_key_list"
                                    valueKey="sp_key"
                                    labelKey="name"
                                    data={servicePlanList}
                                    placeholder="All prices"
                                    disabled={formValue.all}
                                    onSelectAll={handleSelectAll}
                                />
                            </div>

                            <div className="news-form-filters__item">
                                <FormControl
                                    accepter={CustomPicker}
                                    name="role_list"
                                    valueKey="value"
                                    labelKey="name"
                                    data={roleList}
                                    placeholder="Role list"
                                    disabled={formValue.all}
                                    onSelectAll={handleSelectAll}
                                />
                            </div>

                            <div className="news-form-filters__item">
                                <FormControl
                                    accepter={CustomPicker}
                                    name="account_id_list"
                                    valueKey="id"
                                    labelKey="name"
                                    data={accounts}
                                    placeholder="All dialers"
                                    groupBy="account_manager_name"
                                    disabled={formValue.all}
                                    onSelectAll={handleSelectAll}
                                />
                            </div>

                            <div className="news-form-filters__item">
                                <FormControl
                                    accepter={CheckPicker}
                                    name="service_list"
                                    valueKey="id"
                                    labelKey="name"
                                    searchable={false}
                                    data={serviceListData}
                                    placeholder="All services"
                                    disabled={formValue.all}
                                    
                                    // onSelectAll={handleSelectAll}
                                />
                            </div>
                        </div>
                    </div>

                    <Spacer />
                    
                    <div class="news-form-content">
                        <TextEditor
                            onChange={setTextValue}
                            defaultValue={messageTextObject}
                            language={_lang}
                            show={show}
                        />

                        <ButtonGroup>
                            <Button
                                appearance={_lang === "en" ? 'primary' : 'default'}
                                size="sm"
                                onClick={() => {
                                    _setLang("en");
                                    setMessageTextObject({message: formValue?.["text"] || ""});
                                }}
                            >
                                English version
                            </Button>
                            <Button
                                appearance={_lang === "ar" ? 'primary' : 'default'}
                                size="sm"
                                onClick={() => {
                                    _setLang("ar");
                                    setMessageTextObject({message: formValue?.["text_arabic"] || ""});
                                }}
                            >
                                Arabic version
                            </Button>
                        </ButtonGroup>

                    </div>

                </StyledFormContainer>
            </Form>
        </CustomModal>
    )
};


const CustomPicker = ({onSelectAll, data, name, value, ...props}) => {

    const isSelected = value && value.length
        ? (value.length === data.length)
        : null;

    return <CheckPicker
        data={data}
        value={value}
        name={name}
        renderValue={(value, _, selectedElement) => {
            return value && value.length === data.length
                ? props.placeholder
                : selectedElement;
        }}
        renderExtraFooter={() => (
            <div style={footerStyles}>
                <CustomCheckbox
                    value={isSelected}
                    onChange={(checked) => {
                        onSelectAll(name, checked)
                    }}
                >
                    Check all
                </CustomCheckbox>
            </div>
        )}
        {...props}
    />
};


const CustomCheckbox = ({children, value, ...props}) => {
    return <Checkbox
        virtualized
        value={value}
        checked={value}
        {...props}
    >
        {children}
    </Checkbox>
};



const StyledFormContainer = styled.div`
    width: 100%;

    .news-form-filters__wrapper {
        overflow: hidden;
    }
    
    .news-form-filters {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        margin-left: -7px;
        margin-right: -7px;
    }

    .news-form-filters__item {
        width: 33.333333%;
        padding-left: 7px;
        padding-right: 7px;
        padding-bottom: 10px;
    }
`