import React, {useContext} from 'react';
import {renderColumn} from '../../utils/helpers';
import {useIntl} from "react-intl";
import CustomizableTable from '../../components/client/CustomizableTable/CustomizableTable';
import {useWindowWidth} from "../../hooks";
import m from "../../definedMessages";
import {RtlContext} from "../../App";
import {servicePick} from "utils";


export default ({data = [], service, loading, more, page, count, per_page, savedPerPage, onChangePerPage, onChangePage}) => {

    const {formatMessage} = useIntl();
    const windowWidth = useWindowWidth();
    const rtl = useContext(RtlContext);

    const columns = [
        {
            id: 'datetime', 
            label: formatMessage(m.datetime),
            dataKey: 'datetime', 
            align: 'center',
            flexGrow: 2,
            minWidth: 120,
            value: ({datetime}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-single">{datetime}</div>
                    </div>
                )
            }
        },
        {
            id: 'a_number', 
            label: service ? formatMessage(m.aNumber) : formatMessage(m.senderId), 
            dataKey: service ? 'a_number' : 'senderid',
            flexGrow: 2,
            minWidth: 120, 
            align: 'center',
            value: ({senderid, a_number, a_subdestination_name}) => {
                return (
                    <>
                        {service 
                            ? <div className="table-two-staged__cell">
                                <div className="table-two-staged__cell-first">{a_number}</div>
                                <div className="table-two-staged__cell-second">{a_subdestination_name}</div>
                            </div>
                            : <div className="table-two-staged__cell">
                                <div className="table-two-staged__cell-single">
                                    {senderid}
                                </div>
                            </div>
                        }
                    </>
                )
            }
        },
        {
            id: 'b_number', 
            label: formatMessage(m.bNumber), 
            dataKey: service ? 'b_number' : 'phone',
            flexGrow: 2,
            minWidth: 120, 
            align: 'center',
            value: ({phone, b_number, b_subdestination_name}) => {
                return (
                    <>
                        {service
                            ? <div className="table-two-staged__cell">
                                <div className="table-two-staged__cell-first">{b_number}</div>
                                <div className="table-two-staged__cell-second">{b_subdestination_name}</div>
                            </div>
                            : <div className="table-two-staged__cell">
                                <div className="table-two-staged__cell-single">
                                    {phone}
                                </div>
                            </div>
                        }
                    </>
                )
            }
        },
        {
            id: 'range', 
            label: formatMessage(m.range), 
            dataKey: 'range', 
            flexGrow: 4,
            minWidth: 200, 
            align: 'center',
            value: ({range, range_name}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-single">{servicePick(service, range, range_name)}</div>
                    </div>
                )
            }
        },
        ...(service ? [{
            id: 'duration', 
            label: formatMessage(m.duration), 
            dataKey: 'duration', 
            flexGrow: 2,
            minWidth: 120, 
            align: 'center',
            value: ({duration}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-single">{duration}</div>
                    </div>
                )
            }
        }] : [])
    ];

    const renderedColumns = columns.map(renderColumn);

    return (
        <>
            <CustomizableTable
                {...{
                    rtl,
                    data,
                    count,
                    page,
                    per_page,
                    loading,
                    savedPerPage,
                    onChangePage,
                    onChangePerPage,
                    renderedColumns
                }}
                data={data}
                isMobile={windowWidth < 768}
                rowHeight={46}
                twoStaged={true}
                isChangedColumns={false}
                isPaginated={true}
                moreCount={more}
            />
        </>
    );
}