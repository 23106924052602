import {
    ACCOUNT_SERVICE_PLAN as PREFIX
} from '../const';
import {
    ACCOUNT_SERVICE_PLAN_LIST_API
} from '../const/apiMethods';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';

const setList = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);

export const getAccountServicePlanList = (account_id) => (dispatch) => {
    dispatch( setLoading(true) );
    api(ACCOUNT_SERVICE_PLAN_LIST_API, {
        target: {account_id}
    })
        .then(r => {
            if (!r || !r.account_service_plan_list) {
                return;
            }
            dispatch( setList(r.account_service_plan_list) );
        })
        .finally(() => {
            dispatch( setLoading(false) );
        })
};