import React from "react";
import PanelLayout from "./PanelLayout";
import NewsWidget from "../../components/NewsWidget";
import {connect} from "react-redux";

const Page = ({children, showNewsWidget = true, authInfo, ...props}) => {
    return (
        <PanelLayout {...props}>
            {showNewsWidget && !authInfo?.session?.is_test &&
                <NewsWidget />
            }
            
            {children}
        </PanelLayout>
    )
    };

const mapState = ({auth}) => ({
    authInfo: auth.authInfo,
});

export default connect(mapState)(Page);