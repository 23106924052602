import React, {useContext} from "react";
import {connect} from "react-redux";
import {Button, Icon, Whisper, Popover} from "rsuite";
import styled, {css} from "styled-components";
import {APP_TYPE_CLIENT} from "const";
import {RtlContext} from "App";
import {useWindowWidth} from "../hooks"


const SkypeWidget = ({
    supportContacts,
    appType
}) => {
    
    const rtl = useContext(RtlContext);

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 767

    if (!supportContacts || !supportContacts.arabic || appType !== APP_TYPE_CLIENT) {
        return null;
    }

    const {skype: voiceSkype, sms_skype: smsSkype} = supportContacts.arabic;

    return (<>
        <Whisper 
            placement={rtl ? "right" : "left"} 
            trigger="hover" 
            speaker={<Popover>Voice Premiumy Support</Popover>}
            enterable={true}
        >
            {voiceSkype ? <SkypeButton rtl={rtl} href={`skype:${voiceSkype}?chat`} service={true}>
                <Icon icon="skype"/>
                {!isMobile && <SkypeButtonText service={true}>
                    <span className="vc1">V</span>
                    <span className="vc2">O</span>
                    <span className="vc3">I</span>
                    <span className="vc4">C</span>
                    <span className="vc5">E</span>
                </SkypeButtonText>}
            </SkypeButton> : <></>}
        </Whisper>
        <Whisper 
            placement={rtl ? "right" : "left"} 
            trigger="hover" 
            speaker={<Popover>SMS Premiumy Support</Popover>}
            enterable={true}
        >
         {smsSkype ? <SkypeButton rtl={rtl} href={`skype:${smsSkype}?chat`} service={false}>
                <Icon icon="skype"/>
                {!isMobile && <SkypeButtonText service={false}>
                    <span className="sc1">S</span>
                    <span className="sc2">M</span>
                    <span className="sc3">S</span>
                </SkypeButtonText>}
            </SkypeButton> : <></>}
        </Whisper> 
    </>);
}

const SkypeButtonText = styled.div`
    && {
        /* font-size: 11px;
        position: absolute;
        bottom: 3px;
        left: 16px;
        color: white;
        font-weight: 600; */

        position: absolute;
        width: 60px;
        height: 60px;
        margin: 0px auto;
        top: 0px;
        font-size: ${props => props.service ? "10px" : "12px"};
        color: white;
        font-weight: 600;

        span {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 20px;
            height: 66px;
            margin-left: -10px;
            line-height: 108px; /* height*2 - font-size */
            text-align: center;
            transform-origin: top center;
        }

        span.sc1 {
            transform: rotate(13deg);
        }
        span.sc2 {
            transform: rotate(0deg);
        }
        span.sc3 {
            transform: rotate(-13deg);
        }

        span.vc1 {
            transform: rotate(15deg);
        }
        span.vc2 {
            transform: rotate(7deg);
        }
        span.vc3 {
            transform: rotate(0deg);
        }
        span.vc4 {
            transform: rotate(-7deg);
        }
        span.vc5 {
            transform: rotate(-15deg);
        }
    }
`;


const SkypeButton = styled(Button)`
    && {
        padding: 0;
        width: 60px;
        height: 60px;
        border-radius: 60px;
        background: ${props => props.service ? "linear-gradient(var(--color-main), var(--color-main-hover))" : "linear-gradient(#88d9f8, #00aff0)"};
        box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 4px;
        position: relative;

        &:before {
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            background: ${props => props.service ? "var(--color-main-hover)" : "#00aff0"};
            z-index: -1;
            opacity: 0;
            transition: opacity 0.2s ease;
        }

        &:hover {
            background: ${props => props.service ? "linear-gradient(var(--color-main), var(--color-main-hover))" : "linear-gradient(#88d9f8, #00aff0)"};
            
            &:before {
                opacity: 1;
            }
        }

        &:focus, 
        &:active, 
        &:active:hover, 
        &:active:focus, 
        &:active.focus {
            background: ${props => props.service ? "linear-gradient(var(--color-main), var(--color-main-hover))" : "linear-gradient(#88d9f8, #00aff0)"};
        }

        .rs-icon {
            color: #fff;
            font-size: 30px;
            line-height: 50px;
        }

        bottom: ${props => props.service ? "10px" : "75px"};
        position: fixed;
        right: 10px;
        z-index: 10;

        @media (max-width: 767px) {
            bottom: ${props => props.service ? "160px" : "100px"};
            right: 5px;
            width: 36px;
            height: 36px;

            .rs-icon {
                font-size: 16px;
                line-height: 36px;
            }
        }

        ${props => props.rtl && css`
            left: 10px;
            right: auto;

            @media (max-width: 767px) {
                left: 5px;
                right: auto;
            }
        `}
    }
`;


const mapState = ({references, auth})=> ({
    supportContacts: references.support_contacts,
    appType: auth.appType,
});

export default connect(mapState)(SkypeWidget);