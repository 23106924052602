import React, {useState} from "react";
import {Modal, List, Dropdown, ButtonToolbar, Loader} from "rsuite";
import {CustomModal} from "../../../../../components/base";
import {useWindowWidth} from "../../../../../hooks";
import styled, {css} from "styled-components";
import {downloadDataAsFile, updateCsvText, downloadPrefixList} from '../../../../../utils';


export default ({
    show,
    data,
    trunkId,
    onClose,
}) => {

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 820;

    const [loading, setLoading] = useState(false);

    const prefixListItems = data && data
        .map( (item, index) => {
            return <List.Item key={index}>
                {item.prefix}, {item.name}, {item.rate} {item.currency_name}
            </List.Item>
        }
    );

    const onDownloadNewPrefixes = () => {
        setLoading(true);
        downloadPrefixList(trunkId, () => setLoading(false), false, "prefixes.csv", "prefix-csv");
    };

    const onDownloadFullPrice = () => {
        setLoading(true);
        downloadPrefixList(trunkId, () => setLoading(false), true, "all_prefixes.csv", "prefix-csv");
    };

    return <CustomModal
        as={Modal}
        show={show}
        style={{
            width: windowWidth < 576 ? windowWidth : 576
        }}
        onHide={onClose}
    >
        <StyledModalHeader>New created prefixes</StyledModalHeader>
        <Modal.Body className="modal-body">
            <StyledList
                size='sm'
                style={{height: data.length * 36, maxHeight: 288}}
            >
                {prefixListItems}
            </StyledList>
        </Modal.Body>
        <Modal.Footer>
            <StyledButtonToolbar width={windowWidth < 576 ? "100%" : "30%"}>
                <StyledDropdown
                    placement={isMobile ? 'topStart' : 'bottomStart'}
                    title={<>{loading && <StyledLoader/>} Download list</>}
                    disabled={loading}
                >
                    <Dropdown.Item onClick={onDownloadNewPrefixes}>Download new prefixes</Dropdown.Item>
                    <Dropdown.Item onClick={onDownloadFullPrice}>Download full price</Dropdown.Item>
                </StyledDropdown>
            </StyledButtonToolbar>

        </Modal.Footer>
    </CustomModal>
};

const StyledModalHeader = styled(Modal.Header)`
    && {
        color: white;
        font-weight: 700;
    }
`;

const StyledList = styled(List)`
    margin-top: 10px;
    box-shadow: none !important;
    
    @media (max-width: 767px) {
        display: none !important;
    }
`;

const StyledButtonToolbar = styled(ButtonToolbar)`
    width: 100%;
    display: inline-block; 
    ${props => props.width && css`
        width: ${props.width};
    `}
`;

const StyledDropdown = styled(Dropdown).attrs(props => ({
    appearance: "default"
}))`
    & > a {
        width: 100%;
    }
    &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
        background-color: #d6d6db !important;

    }
    && > a.rs-dropdown-toggle.rs-btn {
        padding: 12px 50px 12px 24px !important;
    }
    width: 100%;
`;

const StyledLoader = styled(Loader)`
    & span.rs-loader-spin {
        margin-top: 3px;
        margin-right: 5px;
    }
`;