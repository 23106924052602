import {DEFAULT_PER_PAGE_SMALL, PRICE_RANGES as PREFIX, SET_RANGES} from '../const/';
const initialState = {
    loading: false,
    page: 1,
    count: 0,
    per_page: +localStorage.getItem(PREFIX+'_per_page') ||  DEFAULT_PER_PAGE_SMALL,
    ranges: []
};
const handlers = {
    [SET_RANGES]: (state, {payload: {items, page, count, per_page}}) =>{
        return {...state, ranges: items, count, page, per_page, loading: false}
    },
    ['SET_LOADING_'+PREFIX]: (state, {payload}) =>({...state, loading: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};