import {SEARCH as PREFIX} from '../const/';

const initialState = {
    loading: false,
    items: [],
    count: 0
};
// CHECK IT
const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload}) => ({...state, ...payload, loading: false}),
    ['SET_LOADING_'+ PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action)
};