import React from 'react';
import {getFileResponse} from '../api/loginRoutes';
import {downloadDataAsFile} from '../utils';
import {ButtonSideIcon} from "./base/BaseButton";


export default ({ method, params, title, fileName, ...props}) => {
    const [loading, setLoading] = React.useState(false)
    
    const exportFile = async () => {
        setLoading(true);
        const result = await getFileResponse(method, params)
        if(result)
            downloadDataAsFile(result,fileName)
        setLoading(false)

    }

    return (
        <ButtonSideIcon
            onClick={() => exportFile()} 
            loading = {loading} 
            icon = "upload2"
            {...props}     
        >
            {title}
        </ButtonSideIcon>
    )
};