import React, {useEffect, useState} from 'react';
import PageHeader from '../../components/base/PageHeader';
import {connect} from 'react-redux';
import PanelLayout from '../../components/base/PanelLayout';
import SettingTable from 'pages/Settings/SettingsTable';
import {getSettingList, onEditSettingItem} from 'actions/global_setting';
import './Settings.css'
import {useWindowWidth} from 'hooks';


const SIMTrafficRangesPage = ({ loading, settingList, getSettingList, onEditSettingItem}) => {
    useEffect(() => {
        getSettingList()
    }, []);
    const windowWidth = useWindowWidth();

    return (
        <PanelLayout>
            <PageHeader title="Settings"/>
            <SettingTable
                windowWidth={windowWidth}
                getList={getSettingList}
                dataList={settingList}
                onEditItem={onEditSettingItem}
                loading={loading}
            />
        </PanelLayout>
    );
};

const mapStateToProps = ({global_setting}) => ({
    settingList: global_setting.list,
    loading: global_setting.loading
});

const mapDispatchToProps = {
    getSettingList,
    onEditSettingItem
};

export default connect(mapStateToProps, mapDispatchToProps)(SIMTrafficRangesPage);

