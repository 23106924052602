import {
    PAYMENTS as PREFIX,
    PAYMENT_LIST,
    PAYMENT_REQUEST_LIST,
    PAYMENT_TRAFFIC_REPORTS_LIST,
    PAYMENT_HAWALA_LIST,
    PAYMENT_MISC_LIST
} from '../const';

const initialState = {
    paymentItems: [],
    paymentItemsLoading: false,
    paymentRequestItems: [],
    paymentRequestLoading: false,
    paymentTrafficReportsItems: [],
    paymentTrafficReportsLoading: false,
    hawalaItems: [],
    hawalaLoading: false,
    miscItems: [],
    miscDataInited: false,
    miscLoading: false
};

const handlers = {

    ['SET_LOADING_' + PAYMENT_REQUEST_LIST]: (state, {payload}) => ({...state, paymentRequestLoading: payload}),
    ['SET_ITEMS_' + PAYMENT_REQUEST_LIST]: (state, {payload: {items}}) => ({
        ...state,
        paymentRequestItems: items,
        paymentRequestLoading: false
    }),

    ['SET_LOADING_' + PAYMENT_LIST]: (state, {payload}) => ({...state, paymentItemsLoading: payload}),
    ['SET_ITEMS_' + PAYMENT_LIST]: (state, {payload: {items}}) => ({
        ...state,
        paymentItems: items,
        paymentItemsLoading: false
    }),

    ['SET_LOADING_' + PAYMENT_TRAFFIC_REPORTS_LIST]: (state, {payload}) => ({...state, paymentTrafficReportsLoading: payload}),
    ['SET_ITEMS_' + PAYMENT_TRAFFIC_REPORTS_LIST]: (state, {payload: {items}}) => ({
        ...state,
        paymentTrafficReportsItems: items,
        paymentTrafficReportsLoading: false
    }),

    ['SET_LOADING_' + PAYMENT_HAWALA_LIST]: (state, {payload}) => ({...state, hawalaLoading: payload}),
    ['SET_ITEMS_' + PAYMENT_HAWALA_LIST]: (state, {payload: {items}}) => ({
        ...state,
        hawalaItems: items,
        hawalaLoading: false
    }),

    ['SET_LOADING_' + PAYMENT_MISC_LIST]: (state, {payload}) => ({...state, hawalaLoading: payload}),
    ['SET_ITEMS_' + PAYMENT_MISC_LIST]: (state, {payload: {items, inited}}) => ({
        ...state,
        miscDataInited: inited,
        miscItems: items,
        miscLoading: false
    }),
    
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}