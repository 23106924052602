import React, {useEffect, useState} from 'react';
import {Button, FlexboxGrid, Form, FormControl, Icon, Schema, Message} from 'rsuite';
import ModalEdit from '../Modal/ModalEdit';
import ModalEditProfileConfirm from './ModalEditProfileConfirm';
import {LOCAL_STORAGE_USER_INFO} from '../../const/localStorageKeys';
import m from "../../definedMessages";
import {useIntl} from "react-intl";

const {StringType} = Schema.Types;

const MIN_PASSWORD_LENGTH = 8;
const MAX_CHARACTERS = 40;
const MAX_EMAIL_LENGTH = 256;
const MAX_PHONE_LENGTH = 15;

const styles = {
    marginRight: '20px'
};

export default ({maxWidth = 520, show, onClose, loading, authInfo, ...props}) => {
    const {modifyAccountRequest, error, errorMessage} = props;

    const user = localStorage.getItem(LOCAL_STORAGE_USER_INFO);
    const userInfo = authInfo || ( user && JSON.parse(user) );
    const {formatMessage} = useIntl();

    const defaultFormProfileValue = {
        ...(userInfo && userInfo.name ? {name: userInfo.name} : {}),
        ...(userInfo && userInfo.surname ? {surname: userInfo.surname} : {}),
        ...(userInfo && userInfo.email ? {email: userInfo.email} : {}),
        ...(userInfo && userInfo.phone ? {phone: userInfo.phone} : {})
    };

    let form;

    const [showChangePassword, onChangeShowChangePassword] = useState(false);
    const [preparedData, onChangePreparedData] = useState(null);
    const [showConfirmEditModal, onChangeConfirmEditModal] = useState(false);
    const [passwordEqual, onEqualPasswords] = useState(false);

    const [formProfileValue, onChangeProfileFormValue] = useState(defaultFormProfileValue);
    const [formPasswordValue, onChangePasswordFormValue] = useState({});
    const [formProfileError, onChangeProfileErrorValue] = useState({});
    const [formPasswordError, onChangePasswordErrorValue] = useState({});
    const [disabledButton, onDisabledButton] = useState({password: true, profile: true});


    const clearFormState = () => {
        onChangeProfileFormValue(defaultFormProfileValue);
        onChangePasswordFormValue({});
        onChangeProfileErrorValue({});
        onChangePasswordErrorValue({});

        onChangeShowChangePassword(false);
        onEqualPasswords(false);
        onDisabledButton({password: true, profile: true})
    };

    const formProfileModel = Schema.Model({
        name: StringType()
            .maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
        surname: StringType()
            .maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
        // email: StringType()
        //     .isEmail('Please enter an email address')
        //     .maxLength(MAX_EMAIL_LENGTH, `The maximum of this field is ${MAX_EMAIL_LENGTH} characters`),
        phone: StringType()
            .pattern(/^[1-9][\d]*$/, formatMessage(m.numberMust))
            .maxLength(MAX_PHONE_LENGTH, formatMessage(m.maxLength, {count: MAX_PHONE_LENGTH}))
    });

    const formPasswordModel = Schema.Model({
        password: StringType()
            .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])[A-Za-z'\d#?!_@$%^&*-]{8,}$/, formatMessage(m.passwordLetters))
            .minLength(MIN_PASSWORD_LENGTH, formatMessage(m.passwordMinLength, {count: MIN_PASSWORD_LENGTH}))
    });

    useEffect(() => {
        if (error && errorMessage) {
            clearFormState();
        }
    }, [error]);

    const onSubmit = (form) => {
        if (!form.check()) {
            return false;
        }

        const formValue = {...formProfileValue};
        delete formValue.email;

        onChangePreparedData({...formPasswordValue, ...formValue});
        onChangeConfirmEditModal(true);

        return false
    };

    const checkFormError = (errorObj) => {
        return Object.keys(errorObj).map(i => errorObj[i].hasError).every(value => value === false)
    };

    const clearPassword = () => {
        form.state.formError = null;
        form.state.formValue = null;

        formPasswordError && Object.keys(formPasswordError).length !== 0 && onChangePasswordErrorValue({
            ...Object.keys(formPasswordError)
                .filter(key => key !== 'password')
                .reduce((result, current) => {
                    result[current] = formPasswordError[current];
                    return result;
                }, {})
        });
        formPasswordValue && Object.keys(formPasswordValue).length !== 0 && onChangePasswordFormValue(
            Object.assign({},
                ...Object.entries(formPasswordValue)
                    .filter(([key]) => key !== 'password')
                    .map(([result, current]) => ({[result]: current}))
            ));

        onChangeShowChangePassword(false);
        onEqualPasswords(false);
    };

    const styled = getComputedStyle(document.documentElement);

    return (
        <ModalEdit {...{show, onClose}}
                   onSuccessClose={false}
                   onClose={() => {
                       onClose();
                       clearFormState();
                   }}
                   onSuccess={() => onSubmit(form, formProfileValue)}
                   successButton={formatMessage(m.send)}
                   successButtonProps={{
                       disabled:
                           !checkFormError(formProfileError) ||
                           !checkFormError(formPasswordError) ||
                           showChangePassword && !passwordEqual && (!disabledButton.password || !disabledButton.profile) ||
                           !showChangePassword && disabledButton.profile
                   }}
                   title={formatMessage(m.editProfile)}
                   width={maxWidth}
        >
            <Form
                ref={ref => form = ref}
                model={formProfileModel}
                onChange={(data) => {
                    const checkedData = formProfileModel.check(data);
                    const formData = {name: data.name, surname: data.surname, email: data.email, phone: data.phone};

                    onChangeProfileErrorValue(checkedData);
                    onChangeProfileFormValue(formData);

                    if ((!formData.name && !formData.surname && !formData.email && !formData.phone && !formData.password) ||
                        (userInfo &&
                            (formData.name === userInfo.name && formData.surname === userInfo.surname &&
                                formData.email === userInfo.email && formData.phone === userInfo.phone))) {
                        onDisabledButton({...disabledButton, profile: true});
                    } else {
                        onDisabledButton({...disabledButton, profile: false})
                    }
                }}
                value={formProfileValue}
                formDefaultValue={formProfileValue}
            >
                <FlexboxGrid align="middle" style={{width: '100%', marginBottom: '20px'}}>
                    {
                        <FlexboxGrid.Item style={{...styles, width: '100%', maxWidth: '100px'}}>
                            {formatMessage(m.name)}
                        </FlexboxGrid.Item>
                    }
                    <FlexboxGrid.Item className="edit-profile-input-wrapper">
                        <FormControl
                            name="name"
                            type="text"
                            placeholder={formatMessage(m.name)}
                            className="edit-profile-input"
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid align="middle" style={{width: '100%', marginBottom: '20px'}}>
                    {
                        <FlexboxGrid.Item style={{...styles, width: '100%', maxWidth: '100px'}}>
                            {formatMessage(m.surname)}
                        </FlexboxGrid.Item>
                    }
                    <FlexboxGrid.Item className="edit-profile-input-wrapper">
                        <FormControl
                            name="surname"
                            type="text"
                            placeholder={formatMessage(m.surname)}
                            className="edit-profile-input"
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid align="middle" style={{width: '100%', marginBottom: '20px'}}>
                    {
                        <FlexboxGrid.Item style={{...styles, width: '100%', maxWidth: '100px'}}>
                            {formatMessage(m.email)}
                        </FlexboxGrid.Item>
                    }
                    <FlexboxGrid.Item className="edit-profile-input-wrapper">
                        <FormControl
                            disabled
                            name="email"
                            type="email"
                            placeholder={formatMessage(m.email)}
                            className="edit-profile-input"
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid align="middle" style={{width: '100%', marginBottom: '20px'}}>
                    {
                        <FlexboxGrid.Item style={{...styles, width: '100%', maxWidth: '100px'}}>
                            {formatMessage(m.phone)}
                        </FlexboxGrid.Item>
                    }
                    <FlexboxGrid.Item className="edit-profile-input-wrapper">
                        <FormControl
                            name="phone"
                            type="text"
                            placeholder={formatMessage(m.phone)}
                            className="edit-profile-input"
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
            {
                showChangePassword && (!disabledButton.password || !disabledButton.profile) || (disabledButton.profile) &&
                <FlexboxGrid align="middle" style={{width: '100%', marginBottom: '20px'}}>
                    <Message
                        showIcon
                        type="warning"
                        description={formatMessage(m.nothingToUpdate)}
                        style={{
                            width: '100%',
                            border: `1px solid ${styled.getPropertyValue('--color-warning')}`
                        }}
                    />
                </FlexboxGrid>
            }
            <Form
                ref={ref => form = ref}
                model={formPasswordModel}
                onChange={(data) => {
                    const checkedData = formPasswordModel.check(data);

                    onChangePasswordErrorValue(checkedData);

                    if (data.password && data.confirm_password) {
                        onEqualPasswords(data.password === data.confirm_password);
                    }

                    onChangePasswordFormValue({password: data.password});

                    onDisabledButton({...disabledButton, password: !data.password})
                }}
                value={formPasswordValue}
                formDefaultValue={formPasswordValue}
            >
                {!showChangePassword && <FlexboxGrid style={{marginTop: '20px', width: '100%'}}>
                    <FlexboxGrid.Item style={{width: '100%'}}>
                        <Button
                            style={{width: '100%'}}
                            onClick={() => {
                                onChangeShowChangePassword(true)
                            }}
                            appearance="ghost"
                            color="violet"
                            disabled={loading}
                        >
                            <Icon icon="pencil"/> {formatMessage(m.changePassword)}
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>}
                {showChangePassword && <>
                    <FlexboxGrid align="middle" style={{width: '100%', marginBottom: '20px'}}>
                        {
                            <FlexboxGrid.Item style={{...styles, width: '100%', maxWidth: '100px'}}>
                                {formatMessage(m.password)}
                            </FlexboxGrid.Item>
                        }
                        <FlexboxGrid.Item
                            style={{
                                width: '100%',
                                maxWidth: '300px',
                                minWidth: '140px'
                            }}>
                            <FormControl
                                errorMessage={formPasswordError.password && formPasswordError.password.errorMessage || null}
                                name="password"
                                type="password"
                                placeholder={formatMessage(m.password)}
                                style={{
                                    maxWidth: '300px',
                                    minWidth: '140px'
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid align="middle" style={{width: '100%', marginBottom: '20px'}}>
                        {
                            <FlexboxGrid.Item style={{...styles, width: '100%', maxWidth: '100px'}}>
                                {formatMessage(m.confirm)}
                            </FlexboxGrid.Item>
                        }
                        <FlexboxGrid.Item
                            style={{
                                width: '100%',
                                maxWidth: '300px',
                                minWidth: '140px'
                            }}>
                            <FormControl
                                name="confirm_password"
                                type="password"
                                placeholder={formatMessage(m.confirmPassword)}
                                style={{
                                    maxWidth: '300px',
                                    minWidth: '140px'
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    {
                        !passwordEqual &&
                        <FlexboxGrid align="middle" style={{width: '100%', marginBottom: '20px'}}>
                            <span style={{color: 'red'}}>{formatMessage(m.equalPasswordsAlert)}</span>
                        </FlexboxGrid>
                    }
                    <FlexboxGrid style={{marginTop: '20px', width: '100%'}}>
                        <FlexboxGrid.Item style={{width: '100%'}}>
                            <Button
                                style={{width: '100%'}}
                                onClick={() => {
                                    clearPassword()
                                }}
                                appearance="ghost"
                                color="violet"
                                disabled={loading}
                            >
                                <Icon icon="reply"/> {formatMessage(m.dontChangePassword)}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </>}
            </Form>
            <ModalEditProfileConfirm
                show={showConfirmEditModal}
                onClose={()=> {
                    clearPassword();
                    onChangeConfirmEditModal(false);
                }}
                data={preparedData}
                {...{
                    modifyAccountRequest
                }}
            />
        </ModalEdit>
        );
};