import {connect} from "react-redux";
import {
    setUploadingPriceLoadingDialer,
    allocateByPriceRangeDialer,
    allocateByTemplateDialer,
    exportPriceDialer,
    getPriceListDialer,
    getTrunkListDialer,
    setLoadingDataDialer,
    setLoadingAllocatedNumbersDialer
} from "actions/client/price";
import {allocateByAccess, setAccessLoadingAllocatedNumbers} from "actions/client/accesses";
import {downloadNumbers, setTrunkNumberTransaction, getTemplate, setUploadingLoading} from "actions/client/numbers"

import history from "config/history";
// import Price from "./Price";
import Price from "./PriceRefactored";

import client_price from "reducers/client/price";
import {showReasonHandler} from "actions/reason_handler";
import {getServiceByLocation} from "utils/helpers";

const mapState = ({auth, client_price, client_numbers, client_accesses, auth_dialer, references}) => {
    const serviceByLocation = getServiceByLocation(history.location);

    return {
        account: auth.authInfo,
        service: auth_dialer.serviceDialer,
        initedService: references.initedService,
        accountDialerState: auth_dialer.dialerInfo,
        accountJoinList: auth_dialer.accountJoinList,

        priceList: client_price.items,
        count: client_price.count,
        priceLoading: client_price.loading,
        page: client_price.page,
        per_page: client_price.per_page,
        trunkList: client_price.trunk_list,
        allocated_numbers: client_price.allocated_numbers,
        loadingAllocation: client_price.loadingAllocatedNumbers,
        loadingAccessAllocation: client_accesses.loadingAllocatedNumbers,
        
        service_plan_list: references.service_plan_list,
        worldzone_list: references.worldzone_list,
        destination_list: references.destination_list,
        subdestination_list: references.subdestination_list,
        currency_list: references.currency_list,
        payment_terms_list: references.payment_terms_list,
        uploadLoading: references.uploadLoadingDialer,

        // ...(serviceByLocation 
        //     ? references.voice_not_deployed
        //     : references.sms_not_deployed
        // ),

        uploadLoadingSetting: client_numbers.uploadTrunkLoading,
        trunk_number_transaction: client_numbers.trunk_number_transaction,
    }
};

export default connect(mapState,
{
    getPriceList: getPriceListDialer,
    exportPrice: exportPriceDialer,
    setLoadingData: setLoadingDataDialer,
    getTrunkList: getTrunkListDialer,
    allocateByTemplate: allocateByTemplateDialer,
    allocateByPriceRange: allocateByPriceRangeDialer,
    setLoadingAllocatedNumbersDialer,
    setUploadingPriceLoading: setUploadingPriceLoadingDialer,
    allocateByAccess,
    setAccessLoadingAllocatedNumbers,
    setUploadingLoading,
    getTemplate,
    setTrunkNumberTransaction,
    downloadNumbers,
    showReasonHandler,
})(Price);