import React from "react";
import {connect} from "react-redux";
import styled, {css} from 'styled-components';
import {FlexGrid, FlexGridItem} from "./FlexGrid";
import {APP_TYPE_CLIENT} from "../../const";
import {useIntl} from "react-intl";
import m from "../../definedMessages";


const StyledPageHeader = styled.div`
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
    
    h1 {
        font-family: inherit;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: var(--color-brand1);
    }
    
    ${props => props.theme.appType === APP_TYPE_CLIENT && css`
        padding-top: 10px;
        padding-bottom: 25px;
        
        h1 {
            font-size: 1.3rem;
            font-weight: 600;
            line-height: 1.2em;
        }
    `}
`;

const PageHeader = ({title, children, appType, service, serviceDialer, showService = true, extraTitle = null, ...props}) => {
    const {formatMessage} = useIntl();
    const currentService = appType === APP_TYPE_CLIENT ? serviceDialer : service;

    return (<StyledPageHeader
        className="page-header"
        {...props}
    >
        <FlexGrid justify="space-between" align="middle">
            {title &&
                <FlexGridItem><h1>{showService ? <>{`${currentService ? formatMessage(m.voice) : formatMessage(m.sms)}`} </> : <></>}{title}</h1></FlexGridItem>
            }
            {extraTitle &&
                <FlexGridItem><h1>{extraTitle}</h1></FlexGridItem>
            }
            <FlexGridItem>{children}</FlexGridItem>
        </FlexGrid>
    </StyledPageHeader>)
};

const mapState = ({auth, auth_dialer}) => ({
    appType: auth.appType,
    service: auth.service,
    serviceDialer: auth_dialer.serviceDialer
});

export default connect(mapState, {})(PageHeader);