import React from 'react';
import css from './CheckboxBadge.module.css';
import {Checkbox, Icon} from 'rsuite';

const CheckboxBadge = ({children, wrapperClassName, ...props}) => {

    return (
        <div className={[css.CheckboxBadgeWrapper, wrapperClassName].join(' ')}>
            <Checkbox {...props}>
                <div className={css.CheckboxBadge}>
                    <Icon icon={'check'} className={'mr-1'}/>
                    <Icon icon={'plus'} className={'mr-1'}/>
                    {children}
                </div>
            </Checkbox>
        </div>
    );
};

CheckboxBadge.displayName = 'Checkbox';

export default CheckboxBadge;