import React, {useRef, useMemo, useState} from 'react';
import Checkbox from '../../../../../hoc/Checkbox';
import {useWindowWidth} from "../../../../../hooks";
import {SelectPicker, Form, Schema, InputNumber, ControlLabel, DatePicker, FlexboxGrid, FormControl} from "rsuite";
import HiddenRate from './HiddenRate';
import CustomField from '../../../../../components/Form/CustomField';

const {NumberType} = Schema.Types;

export default ({
    defaultFormValue,
    onChangeForm,
    formValue,
    disabled,
    subdestination_list
}) => {

    let formRef = useRef(null);
    const [prefixCount, setPrefixCount] = useState(1);

    const numbersList = useMemo(() => {
        const prefixCount = 0;
        const difference = 6-prefixCount > 0 ? 6-prefixCount : 1;

        const numberArray = Array.from(Array(difference).keys());
        return numberArray.map(x => 10 ** x).map(x => ({label:x, value:+x}));
        
    }, [formValue]);


    const currentSubdest = useMemo(() => 
        subdestination_list.find(subdest => subdest.sde_key === formValue.sde_key) || [null]
    , [formValue]);

    const formModel = Schema.Model({
        sde_key: NumberType()
            .isRequired('Required'),
        prefixes: NumberType()
            .min(1, 'Minimum is 1')
            .max(10**currentSubdest.max_prefix_digits, `Maximum is ${currentSubdest.length}`)
            .isRequired('Required'),
        numbers: NumberType()
            .isRequired('Required'),
    });

    return (
        <Form
            defaultFormValue={defaultFormValue}
            onChange={onChangeForm}
            formModel={formModel}
            formValue={formValue}
            ref={ref => formRef = ref}
        >
            <FlexboxGrid justify="start" style={{margin: '0 0 20px 0'}}>
                <FlexboxGrid.Item>
                    <ControlLabel>Subdestination</ControlLabel>
                    <CustomField
                        disabled={disabled}
                        accepter={SelectPicker}
                        data={subdestination_list}
                        labelKey="name"
                        valueKey="sde_key"
                        placeholder="Subdestination"
                        errorPlacement="topRight"
                        name="sde_key"
                        style={{width: 230}}
                        onChange={(key) => {
                            const subdest = subdestination_list.find(subdest => subdest.sde_key === key) || {};
                            const maxPrefixCount = 10**subdest.max_prefix_digits;

                            if (prefixCount > maxPrefixCount) {
                                setPrefixCount(maxPrefixCount);
                            }
                        }}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="ml-4">
                    <ControlLabel>Prefix count</ControlLabel>
                    <FormControl
                        disabled={disabled}
                        accepter={InputNumber}
                        name="prefixes"
                        placeholder="Prefixes"
                        style={{width: 230}}
                        value={prefixCount}
                        onChange={(value) => {
                            setPrefixCount(value);
                        }}
                        min={1}
                        max={10**currentSubdest.max_prefix_digits}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <FlexboxGrid justify="start" style={{margin: '0 0 20px 0'}}>
                <FlexboxGrid.Item>
                    <ControlLabel>Numbers</ControlLabel>
                    <FormControl
                        disabled={disabled || !formValue?.prefixes}
                        accepter={SelectPicker}
                        data={numbersList}
                        name="numbers"
                        labelKey="label"
                        valueKey="value"
                        placeholder="Numbers"
                        style={{width: 230}}
                        searchable={false}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <br/>
            <HiddenRate/>

        </Form>    
    )
}