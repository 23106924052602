import React from 'react';
import Form from 'rsuite/es/Form';
import {FormControl, Schema} from 'rsuite';
import {debounce} from '../../utils';
import ServicePlanGroups from '../../components/Filters/ServicePlanGroups';
import styled from 'styled-components';


const { StringType } = Schema.Types;

const model = Schema.Model({
    number: StringType()
        .maxLength(40, 'The maximum is only 40 characters.'),
});


export default ({
    setFilter,
    filter
}) => {


    return (
        <>
            <StyledForm
                model={model}
                onChange={debounce(setFilter, 400)}
                formDefaultValue={filter}
            >
                <FormControl
                    name="number"
                    placeholder="Number/Service name"
                    style={{width: 300}}
                />
                <ServicePlanGroups
                    placeholder="Number type"
                    className={"margin"}
                    cleanable
                />
            </StyledForm>
        </>
    );
};

const StyledForm = styled(Form)`
    && {
        display: flex
    }
    && .margin {
        margin-left: 20px;
    }
`;