import React from 'react';
import Modal from '../../../components/Modal';
import {api} from '../../../api/loginRoutes';
import {Alert} from 'rsuite';
import Icon from 'rsuite/es/Icon';
import {ButtonPrimary} from "../../../components/base/BaseButton";
import {servicePick, getServiceSmsPrefix} from "../../../utils/helpers";


export default (
    {
        service,
        getList, 
        disabled, 
        update, 
        pr_key,
        incorporatedOnly,
        selectedNumbersInfo,
    }
) => {
    const [show, setShow] = React.useState(false);
    const [_disabled, setDisabled] = React.useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps

    const onSubmit = async () => {
        setDisabled(true);
        const numbersKeysList = getList().list;

        let params = {};

        if (incorporatedOnly && !service) {
            params = {
                target: {
                    // ...(service ? {trunk_id: trunkId} : {'sms.trunk_id': trunkId}) // TODO 
                }
            };
        }
        
        if (incorporatedOnly) {
            if (service) {
                params.inn_key_list = selectedNumbersInfo.map(item => item.inn_key);
            } else {
                params['sms.supplier_gan_key_list'] = selectedNumbersInfo.map(item => item.slg_key);
            }
        } else {
            params[getServiceSmsPrefix(service, 'pr_key')] = pr_key;
            params.prn_key_list = numbersKeysList;
        }

        const method = incorporatedOnly
            ? servicePick(service, 'incorporated_number:unset_block_allocation', 'sms.supplier_gan:unset_block_allocation_list')
            : getServiceSmsPrefix(service, 'price_range_number:unset_block_allocation_list');

        const result = await api(method, params);

        const resultNumbers = incorporatedOnly 
            ? service ? result['incorporated_numbers'] : result['sms.supplier_gan']
            : result['price_range_numbers'];

        if (result && resultNumbers) {
            Alert.success(`${resultNumbers} number were unblocked`);
            setShow(false);
            update();
        }
        setDisabled(false);

    };
    return (
        <>
            <ButtonPrimary
                disabled={disabled}
                onClick={() => setShow(true)}
            >
                <Icon icon="unlock-alt"/> Unblock
            </ButtonPrimary>

            {show && <Modal
                show={show}
                title="Allocate numbers"
                onClose={() => setShow(false)}
                footer={true}
                successText="Confirm"
                disabled={_disabled}
                onSuccess={onSubmit}
            >
                Selected numbers will be set as free. You can allocate them or set test in future
            </Modal>
            }
        </>

    );
}
