import {connect} from 'react-redux';
import {getSupplierPlanList, createSupplierPlan, modifySupplierPlan, removeSupplierPlan} from '../../../../actions/suppliers';
import AccessList from "./AccessList";
// import FormHOC from '../../../../hoc/FilltersForm';
import {showReasonHandler} from "../../../../actions/reason_handler";

const mapStateToProps = ({suppliers}) => ({
    loading: suppliers.suppliersPlanLoading,
    suppliersPlanList: suppliers.suppliersPlanList,
});

const mapDispatchToProps = {
    getSupplierPlanList,
    createSupplierPlan,
    modifySupplierPlan,
    removeSupplierPlan,
    showReasonHandler
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessList);