import React, {useMemo, useState, useEffect} from 'react';
import {Input, Schema, FlexboxGrid, RadioGroup, Form, Radio} from 'rsuite';
import CustomField from '../../../components/client/Form/CustomField/CustomField';
import {debounce} from '../../../utils';
import CustomSelfClearedForm from '../../../components/client/Form/CustomSelfClearedForm';
import {MAX_RATE_DIALER, MIN_RATE_DIALER} from '../../../const';
import {CustomizableForm} from '../../../components/client/Form/CustomizableForm';
import {useIntl} from "react-intl";
import CustomNumber from "../../../components/client/Form/CustomNumber";
import styled from 'styled-components';
import m from "../../../definedMessages";
import {BaseInputField} from "../../../components/base/BaseForm";

const {StringType, NumberType} = Schema.Types;


const MAX_CHARACTERS = 40;


export default ({service, onChangeFilters, onChangeGroup, defaultFilter, savedTableFilter, allocatedNumbersLoading, currentDisplay}) => {
    const {formatMessage} = useIntl();
    const [formValue, setFormValue] = useState(defaultFilter);
    // let form;

    // useEffect(() => {
    //     setFormValue(defaultFilter);
    // }, [service]);

    const numbersFiltersModel = Schema.Model({
        str: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
        rate_min: NumberType( formatMessage(m.pleaseEnterValidNumber) )
            .min(0, formatMessage(m.minNumber, {number: 0}))
            .max(9.9999, formatMessage(m.maxNumber, {number: 9.9999})),
        rate_max: NumberType( formatMessage(m.pleaseEnterValidNumber) )
            .min(0, formatMessage(m.minNumber, {number: 0}))
            .max(9.9999, formatMessage(m.maxNumber, {number: 9.9999})),
    });


    const onSubmit = (data, checkedData) => {
        const checker = Object.keys(checkedData).map(i => checkedData[i].hasError).every(value => value === false);

        if (checker) {
            onChangeFilters({
                ...data,
                rate_min: parseFloat(data.rate_min) || MIN_RATE_DIALER,
                rate_max: parseFloat(data.rate_max) || MAX_RATE_DIALER
            });
        }

        return false;
    };

    let handleChange = (data) => {
        const checkedData = numbersFiltersModel.check(data);
        onSubmit(data, checkedData);
        setFormValue(data);
    };

    handleChange = debounce(handleChange, 1000);

    return (
        <StyledGrid align="middle">
            <StyledGridItem>
                <CustomSelfClearedForm
                    // ref={ref => (form = ref)}
                    clearOn={service}
                    model={numbersFiltersModel}
                    onChange={handleChange}
                    // formValue={formValue}
                    formDefaultValue={defaultFilter}
                    fluid
                    // readOnly={allocatedNumbersLoading}
                >
                    <StyledGrid align="middle">

                        <StyledGridItem width="300px">
                            <CustomField
                                accepter={BaseInputField}
                                name="str"
                                // defaultValue={formValue.str}
                                errorPlacement="topEnd"
                                placeholder={formatMessage(m.rangeNumberSearch)}
                            />
                        </StyledGridItem>

                        <StyledGridItem classPrefix="flex-item">

                            <StyledGrid align="middle">

                                <StyledGridItem>
                                    <span>{formatMessage(m.rateRange)}</span>
                                </StyledGridItem>


                                <StyledGridItem>
                                    <StyledGrid
                                        align="middle" 
                                        wrap="nowrap"
                                    >

                                        <StyledGridItem>
                                            <NumberFieldWrapper>
                                                <CustomField
                                                    accepter={CustomNumber}
                                                    errorPlacement="topStart"
                                                    inline
                                                    label={formatMessage(m.from)}
                                                    max={formValue.rate_max ? formValue.rate_max : MAX_RATE_DIALER}
                                                    min={MIN_RATE_DIALER}
                                                    name="rate_min"
                                                    step={0.0001}
                                                />
                                            </NumberFieldWrapper>
                                        </StyledGridItem>

                                        <StyledGridItem>
                                            <NumberFieldWrapper>
                                                <CustomField
                                                    accepter={CustomNumber}
                                                    errorPlacement="bottomEnd"
                                                    inline
                                                    label={formatMessage(m.to)}
                                                    max={MAX_RATE_DIALER}
                                                    min={formValue.rate_min ? formValue.rate_min : MIN_RATE_DIALER}
                                                    name="rate_max"
                                                    step={0.0001}
                                                />
                                            </NumberFieldWrapper>
                                        </StyledGridItem>

                                    </StyledGrid>
                                </StyledGridItem>

                            </StyledGrid>
                        </StyledGridItem>
                    </StyledGrid>
                </CustomSelfClearedForm>
            </StyledGridItem>

            <StyledGridItem>
                <RadioGroup 
                    onChange={onChangeGroup} 
                    defaultValue={currentDisplay} 
                    name="radioList" 
                    inline
                >
                    <Radio value="trunk_number" disabled={false}>{formatMessage(m.numbers)}</Radio>
                    <Radio value="price_range" disabled={false}>{formatMessage(m.ranges)}</Radio>
                    <Radio value="subdestination" disabled={false}>{formatMessage(m.subdestination)}</Radio>
                </RadioGroup>
            </StyledGridItem>

        </StyledGrid>
    );
};

const StyledGrid = styled(FlexboxGrid)`
    && {
        flex-wrap: ${props => props.wrap || 'wrap'};
        margin-bottom: ${props => props.vSpace || '-20px'};
    }
`;

const StyledGridItem = styled(FlexboxGrid.Item)`
    padding-bottom: ${props => props.vSpace || '20px'};
    padding-right: ${props => props.hSpace || '20px'};
    position: relative;

    &&:last-child {
        padding-right: 0;
    }
    
    && {
        flex-shrink: 1;
    }

    .rtl & {
        padding-left: ${props => props.hSpace || '20px'};
        padding-right: 0;

        &&:last-child {
            padding-left: 0;
        }
    }
`;

const NumberFieldWrapper = styled.div`
    &&& {
        .rs-input-number {
            width: 100%;
            border: 1px solid var(--color-grey);
        }
        .rs-form-control-wrapper {
            max-width: 112px;
        }
    }
`;