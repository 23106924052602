import {defineMessages} from 'react-intl'

export default defineMessages({
    rulesRegulationLink: {
        id: "rulesRegulationWidget.rulesRegulationLink",
        defaultMessage: "Rules & regulations",
        description: "rulesRegulationWidget: Rules & Regulation link, Modal title"
    },
    rulesRegulation: {
        id: "rulesRegulationWidget.rulesRegulation",
        defaultMessage: "Rules:\n" +
            "1.  We respect all our clients and expect our clients to be respectful with us. Bad words and bad attitude may lead to the temporarily block of your account. \n" +
            "2.  In Premiumy you can have weekly and monthly accounts: weekly accounts are paid each Monday for the previous week, monthly accounts are paid after 45 days after the end of the billing month. \n" +
            "3.  Minimal payment threshold in Premiumy is $100. Which means that the amounts are accumulated in client’s account until the total balance becomes minimum $100. Only after the balance became $100 the client is able to request his payment. \n" +
            "4.  Premiumy reserves the right to close the account of the client anytime. We allow maximum 1 month for a client to show his best performance and reserve the right to close the accounts which do not meet our requirements. In some specific cases, your account might be closed even earlier. For the clients from the waiting list, we allow 2 weeks to show their best and close the accounts which do not meet our goal without any exceptions. \n" +
            "5.  In case Premiumy decides to close client’s account or client wishes to close his account and his balance is below $100, Premiumy does not pay this amount. Client has to reach his minimal payment of $100 in order to get paid. \n" +
            "6.  We do not allow selling of our accounts and reserve the right to close the account sold immediately without payment whenever the amount is. \n" +
            "7.  Premiumy reserves the right to withhold the payment to the client in case the traffic was not cleared by the carrier. \n" +
            "8.  Premiumy reserves the right to put the payment on hold in case Premiumy suspects that the traffic won’t be paid and keep it on hold until the issue is resolved or cancel it if the traffic is disputed. \n" +
            "9.  Premiumy doesn’t pay for the traffic which is below 10 seconds duration.\n" +
            "10.  Premiumy doesn’t pay for spam and fraudulent traffic.\n" +
            "11.  1 person might have maximum 2 accounts in Premiumy: 1 weekly and 1 monthly. Creating duplicated accounts is strictly forbidden and will lead to the closure of these accounts without payment.\n" +
            "12. Some CLIs require specific trunks to work from them! Please confirm with Support!\n" +
            "13.  Premiumy reserves the right to change its rules anytime at its own will.\n" +
            "14. By creating your account in Premiumy, you automatically comply with these rules.\n" +
            "\n" +
            "We allow clients willing to close their accounts which were created before 30rd March 2021 to close their accounts within 2 weeks (till 12th April 2021) and take the amounts below minimal payment. After 12th April 2021 all the accounts left will be automatically compliant with new rules. All the new accounts are automatically compliant with new rules.\n",
        description: "rulesRegulationWidget: Rules & Regulation"
    }
})
