import {Badge} from "rsuite";
import styled, {css} from "styled-components";

const ColorBadge = styled(Badge)`
    &&& {
        width: 10px;
        height: 10px;
        margin-right: 4px;
        left: 0; 
        top: 0;
        border-radius: 50%;
        transform: none;
        

        ${props =>
            props.badgeColor &&
            css`
                background-color: ${props.badgeColor}
            `
        }
        
    }
`;

export default ColorBadge;