import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Collapse} from "react-collapse";
import {Form, FormGroup} from 'rsuite';
import {FormControl, Icon, InputGroup, Schema, SelectPicker, CheckPicker, Checkbox, DateRangePicker} from 'rsuite'
import * as S from "./styled";
import styled from "styled-components";
import CustomField from "../../components/Form/CustomField";
import {BaseButton} from "../../components/base/BaseButton";
import {Spacer} from "../../components/base/Spacer";

const MAX_LENGTH = 128;
const {ArrayType, StringType} = Schema.Types;
const accFiltersModel = Schema.Model({
    str: StringType().maxLength(MAX_LENGTH, `The maximum is only ${MAX_LENGTH} characters.`),
    supplier_manager: ArrayType()
});


export default (
    {
        filter,
        setFilter,
        account_manager_list,
        paymentTermsList,
        servicePlanList,
        isMobile,
        countryList,
        howFindList,
        moreFiltersShow,
        setMoreFiltersShow,
    }
) => {

    const servicePlanListFiltered = useMemo(() => {
        return [...(servicePlanList || []), {name: 'Show suppliers without trunks', sp_key: 0}]
    }, [servicePlanList]);

    const accountManagerList = account_manager_list.filter((value) => value.active || value.has_accounts);
    const accountManagerBelongToAccountList = accountManagerList.filter((accountManager) => accountManager.is_managed);

    useEffect(() => {
        if (accountManagerBelongToAccountList.length) {
            setFilter({
                ...filter,
                acc_manager_id_list: accountManagerBelongToAccountList.map((accountManager) => accountManager.id)
            });
        }
    }, [account_manager_list]);

    return (
        <Form
            fluid
            model={accFiltersModel}
            formValue={filter}
            onChange={setFilter}
        >
            <S.Grid>

                <S.GridItem style={{flexGrow: 1, width: '100%', maxWidth: 284}}>
                    <StyledInputGroup>
                        <FormControl
                            name="str"
                            errorPlacement="topRight"
                            placeholder={!isMobile
                                ? "Supplier name/Email address"
                                : "Supplier name / email"
                            }
                        />
                        <InputGroup.Addon>
                            <Icon icon="search" />
                        </InputGroup.Addon>
                    </StyledInputGroup>
                </S.GridItem>

                <S.GridItem>
                    <FormGroup>
                        <FormControl
                            accepter={CheckPicker}
                            name="acc_manager_id_list"
                            sticky
                            data={accountManagerList}
                            labelKey="name"
                            valueKey="id"
                            style={{width: 224}}
                            placeholder="All account managers"
                        />
                    </FormGroup>
                </S.GridItem>

                <S.GridItem>
                    <FormGroup>
                        <FormControl
                            accepter={SelectPicker}
                            name="pt_key"
                            data={paymentTermsList}
                            labelKey="name"
                            valueKey="pt_key"
                            style={{width: 224}}
                            placeholder="Payment term"
                            searchable={false}
                        />
                    </FormGroup>
                </S.GridItem>

                <S.GridItem>
                    <FormControl
                        accepter={CustomCheckbox}
                        name="only_with_traffic"
                    >
                        Only with traffic
                    </FormControl>
                </S.GridItem>

                <S.GridItem>
                    <FormControl
                        accepter={CustomCheckbox}
                        name="show_closed_account"
                    >
                        Show closed suppliers
                    </FormControl>
                </S.GridItem>
            </S.Grid>
        </Form>
    );
};



const StyledInputGroup = styled(InputGroup)`
    && {
        overflow: visible;
        
        @media (min-width: 1200px) {
            margin-bottom: 0;
        }
    }

    &&&& {
        .rs-input {
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
        }
    }
`;


const CustomCheckbox = ({children, defaultValue = false, value, onChange, ...props}) => {
    const [_value, _setValue] = useState(defaultValue);

    useEffect(() => {
        _setValue(value);
    }, [value]);

    return <Checkbox
        value={_value}
        checked={_value}
        onChange={() => {
            _setValue(!_value);
            onChange && onChange(!_value);
        }}
        {...props}
    >
        {children}
    </Checkbox>
};