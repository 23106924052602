import React from "react";
import { Table } from "rsuite";
import { renderColumn } from "utils";
import styled from "styled-components";

import m from "definedMessages";



const AccountsTable = ({
    data, 
    loading,
    isMobile,

    formatMessage
}) => {
    const columns = [
        {
            id: "account",
            label: formatMessage(m.accountName),
            dataKey: "name",
            align: "left",
            flexGrow: 2,
            minWidth: 180
        },
        {
            id: "payment_terms",
            label: formatMessage(m.paymentTerms),
            dataKey: "invoice_period",
            align: "left",
            flexGrow: 1,
            minWidth: 120
        },
        {
            id: "curr_period",
            label: formatMessage(m.currentPeriodTraffic),
            dataKey: "rate",
            value: ({balance_USD, balance_EUR}) => {
                const {current_balance: current_balance_USD} = balance_USD || {};
                const {current_balance: current_balance_EUR} = balance_EUR || {};

                const currentBalanceUSD = balance_USD !== null ? `${current_balance_USD || 0} USD` : "";
                const currentBalanceEUR = balance_EUR !== null ? `${current_balance_EUR || 0} EUR` : "";

                return currentBalanceUSD && currentBalanceEUR 
                    ? `${currentBalanceUSD}/${currentBalanceEUR}`
                    : currentBalanceUSD !== null 
                        ? currentBalanceUSD
                        : currentBalanceEUR;
            },
            align: "right",
            flexGrow: 1,
            minWidth: 160
        },
        {
            id: "prev_period",
            label: formatMessage(m.previousPeriodTraffic),
            dataKey: "rate",
            value: ({balance_USD, balance_EUR}) => {
                const {invoice_balance: invoice_balance_USD} = balance_USD || {};
                const {invoice_balance: invoice_balance_EUR} = balance_EUR || {};

                const invoiceBalanceUSD = balance_USD !== null ? `${invoice_balance_USD || 0} USD` : "";
                const invoiceBalanceEUR = balance_EUR !== null ? `${invoice_balance_EUR || 0} EUR` : "";

                return invoiceBalanceUSD && invoiceBalanceEUR 
                    ? `${invoiceBalanceUSD}/${invoiceBalanceEUR}`
                    : invoiceBalanceUSD !== null 
                        ? invoiceBalanceUSD
                        : invoiceBalanceEUR;
            },
            align: "right",
            flexGrow: 1,
            minWidth: 160
        },
        ,
        {
            id: "allocated_numbers",
            label: formatMessage(m.allocatedNumbers),
            dataKey: "allocated_numbers",
            value: ({numbers_with_traffic, allocated_numbers}) => {
                return `${numbers_with_traffic || 0}/${allocated_numbers || 0}`;
            },
            align: "right",
            flexGrow: 1,
            minWidth: 150
        }
    ];

    const renderedColumns = columns.map(renderColumn);

    return (
        <StyledTable
            data={data}
            height={400}
            rowHeight={40}
            loading={loading}
            onRowClick={ ({id, ...acc}) => {
                window.open(`/sub-accounts/view/${id}`);
            }}
            rowClassName={(rowData) => rowData && !rowData.active ? "row-disabled" : ""}
        >
            {renderedColumns}
        </StyledTable>
    );
};

export default AccountsTable;

const StyledTable = styled(Table)`
    && {
        .rs-table-row.row-disabled .rs-table-cell {
            background-color: #d6d6db;
        }
    }
`;