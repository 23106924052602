import React, {useMemo, useContext} from 'react';
import {Table} from 'rsuite';
import {compareTwoArrays, renderColumn, servicePick} from "../../utils/helpers";
import {DEFAULT_PER_PAGE_DIALER} from '../../const';
import { usePrevious } from '../../hooks';
import {useIntl} from "react-intl";
import m from "../../definedMessages";
import BaseTable from "../../components/base/BaseTable";
import {RtlContext} from "../../App";

export default ({
    data = [], 
    loading,
    service,
    filter, 
    spAuth = false,
}) => {
    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);
    const prevService = usePrevious(service);

    const currentData = useMemo(() => {
        if (!Object.keys(filter).length || prevService !== service && prevService !== null) {
            return [];
        }

        return data;
    }, [data, filter, service]);

    window.testData = data;

    const columns = useMemo(() => {
        return (
            service ? [
            {
                id: 'otp_service',
                label: formatMessage(m.service),
                value: ({a_service, a_number}) => (
                    a_service && a_number
                        ? `${a_service} (${a_number})`
                        : ""
                ),
                minWidth: 120,
                flexGrow: 4,
                align: 'center',
                spAuth: true
            },
            {
                id: 'otp_subdest',
                label: formatMessage(m.subdestination),
                value: ({b_subdestination_name, b_template}) => (
                    b_subdestination_name && b_template
                        ? `${b_subdestination_name} (${b_template})`
                        : ""
                ),
                minWidth: 120,
                flexGrow: 4,
                align: 'center',
                spAuth: true
            },
            {
                id: 'a_cntr_name',
                label: formatMessage(m.origin),
                dataKey: 'a_cntr_name',
                minWidth: 120,
                flexGrow: 4,
                align: 'center',
                spAuth: false
            },
            {
                id: 'a_number',
                label: formatMessage(m.cli) ,
                dataKey: 'a_number',
                minWidth: 120,
                flexGrow: 2,
                align: 'center',
                spAuth: false
            },
            {
                id: 'b_cntr_name',
                label: formatMessage(m.destination),
                dataKey: 'b_cntr_name',
                minWidth: 120,
                flexGrow: 4,
                align: 'center',
                spAuth: false
            },
            {
                id: 'b_number',
                label: formatMessage(m.phone),
                dataKey: 'b_number',
                value: ({b_number, b_template}) => !spAuth ? b_number : b_template,
                minWidth: 120,
                flexGrow: 2,
                align: 'center',
                spAuth: false
            },
            {
                id: 'date',
                label: formatMessage(m.date),
                dataKey: 'date',
                minWidth: 120,
                flexGrow: 2,
                align: 'center',
                spAuth: false
            },
        ] : [
            {
                id: 'a_number',
                label: formatMessage(m.senderId),
                dataKey: 'a_number',
                minWidth: 120,
                flexGrow: 2,
                align: 'center',
                spAuth: true
            },
            {
                id: 'otp_subdest',
                label: formatMessage(m.destination),
                value: ({b_subdestination_name, b_number}) => (
                    b_subdestination_name && b_number
                        ? `${b_subdestination_name} (${b_number})`
                        : ""
                ),
                minWidth: 120,
                flexGrow: 4,
                align: 'center',
                spAuth: true
            },
            {
                id: 'message',
                label: formatMessage(m.message),
                dataKey: 'message',
                value: ({message}) => message,
                minWidth: 160,
                flexGrow: 2,
                align: 'center',
                spAuth: true
            },
            {
                id: 'a_cntr_name',
                label: formatMessage(m.origin),
                dataKey: 'a_cntr_name',
                minWidth: 120,
                flexGrow: 4,
                align: 'center',
                spAuth: false
            },
            {
                id: 'a_number',
                label: formatMessage(m.senderId),
                dataKey: 'a_number',
                minWidth: 120,
                flexGrow: 2,
                align: 'center',
                spAuth: false
            },
            {
                id: 'b_cntr_name',
                label: formatMessage(m.destination),
                dataKey: 'b_cntr_name',
                minWidth: 120,
                flexGrow: 4,
                align: 'center',
                spAuth: false
            },
            {
                id: 'message',
                label: formatMessage(m.message),
                dataKey: 'message',
                value: ({message}) => message,
                minWidth: 160,
                flexGrow: 2,
                align: 'center',
                spAuth: false
            },
            {
                id: 'b_number',
                label: formatMessage(m.phone),
                dataKey: 'b_number',
                value: ({b_number, b_template}) => !spAuth ? b_number : b_template,
                minWidth: 120,
                flexGrow: 2,
                align: 'center',
                spAuth: false
            },
            {
                id: 'date',
                label: formatMessage(m.date),
                dataKey: 'date',
                minWidth: 120,
                flexGrow: 2,
                align: 'center',
                spAuth: false
            }, 
        ]).filter(value => value.spAuth === undefined || value.spAuth === spAuth)
    }, [spAuth, service]);

    const renderedColumns = columns.map(renderColumn);
    // reverse columns for RTL. Haven't found good solutions for CSS yet
    if (rtl) renderedColumns.reverse();

    const clearData = (dataList) => {
        return dataList.map(number => {

            const newNumber = {...number};

            Object.keys(newNumber).forEach(key => {
                if (newNumber[key] === null)
                    newNumber[key] = '—';
            });

            return newNumber;
        });
    };

    const clearedData = clearData(currentData);

    const calculatedTableHeight = window.innerHeight - 365;
    const calculatedTableRows = Math.round(calculatedTableHeight / 35);
    const defaultTableRowsHeight = DEFAULT_PER_PAGE_DIALER * 35 + 35;

    return (
        <BaseTable
            height={calculatedTableHeight < defaultTableRowsHeight ? defaultTableRowsHeight : calculatedTableHeight}
            autoHeight={data.length === calculatedTableRows}
            data={clearedData}
            loading={loading}
            rowHeight={35}
            headerHeight={35}
            style={{overflowY: 'hidden'}}

        >
            {renderedColumns}
        </BaseTable>
    );
}