import React, {useEffect, useState} from "react";
import {api, apiFormData} from "../../api/loginRoutes";
import PageHeader from "../../components/base/PageHeader";
import PanelLayout from "../../components/base/PanelLayout";
import {GAN_SUPPLIERS_ACCESS_LIST_UPLOAD_API, GAN_SUPPLIERS_MODIFY_API} from "../../const/apiMethods";
import GanSuppliersFilter from "./GanSuppliersFilter";
import GanSuppliersTable from "./GanSuppliersTable";
import ModalModifyGanSupplier from "./ModalModifyGanSupplier";
import ModalUploadAccessLists from "./ModalUploadAccessLists";
import {parse} from 'object-flaser';
import { FlexGrid, FlexGridItem } from "components/base/FlexGrid";
import {Alert} from "rsuite";


export default (
    {
        data = [],
        loading = false,
        getGanSuppliersList,
        clearGanSuppliersList,
    }
) => {

    const [filter, setFilter] = useState({});
    const [formValue, setFormValue] = useState(null);
    const [supplierData, setSupplierData] = useState(null);
    
    const [modalEditShow, setModalEditShow] = useState(null);
    const [modalEditLoading, setModalEditLoading] = useState(false);

    const [modalUploadShow, setModalUploadShow] = useState(null);
    const [modalUploadLoading, setModalUploadLoading] = useState(false);
    const [modalUploadData, setModalUploadData] = useState({});


    useEffect(() => {
        getGanSuppliersList(filter);
    }, [filter]);


    // fix table resize with wordWrap prop
    useEffect(() => {
        return () => clearGanSuppliersList();
    }, []);


    const handleClickEdit = (params) => {
        const formValue = params.price_percent_payment_terms_list.reduce((summ, item) => {
            const percentValue = (item.rate_percent * 10 * 10).toFixed(1) * 1;
            return {
                ...summ,
                [`rate_percent.${item.pt_key}`]: percentValue,
        }}, {
            comment: params?.comment,
            access_list_timeless: params?.access_list_timeless
        });
        
        setFormValue(formValue);
        setSupplierData(params);
        setModalEditShow(true);
    };


    const handleEdit = () => {
        setModalEditLoading(true);

        const formValueParsed = parse(formValue);

        api(GAN_SUPPLIERS_MODIFY_API, {
            supplier_key: supplierData?.supplier_key,
            comment: formValueParsed?.comment || "",
            access_list_timeless: formValueParsed?.access_list_timeless || false,
            price_percent_payment_terms_list: Object.keys(formValueParsed.rate_percent).map((item) => ({
                pt_key: +item,
                rate_percent: +formValueParsed.rate_percent[item] / 100
            }))
        })
            .then(r => {
                if (!r) {
                    return;
                }

                getGanSuppliersList(filter);
                handleCloseModalEdit();
            })
            .finally(() => {
                setModalEditLoading(false);
            });
    };


    const handleClickUpload = (params) => {
        setSupplierData(params);
        setModalUploadShow(true);
    };


    const handleUpload = () => {
        if (!modalUploadData?.files || !modalUploadData.files.length) {
            return;
        }

        const dt = new DataTransfer();
        dt.items.add(modalUploadData.files[0].blobFile);

        setModalUploadLoading(true);
        
        apiFormData(GAN_SUPPLIERS_ACCESS_LIST_UPLOAD_API, {
            supplier_key: supplierData?.supplier_key,
            files: dt.files,
        })
            .then((r) => {
                if (!r) {
                    return;
                }

                if (r.validation_error) {
                    Alert.error(<>
                        <p><b>Error</b>: {r.validation_error}</p>
                        {r.line_number &&
                            <p>
                                {modalUploadData.files && `${modalUploadData.files[0].name}: `}
                                line <b>{r.line_number}</b><br/>
                                {r.line_str}
                            </p>
                        }
                    </>, 10000);
                    return;
                }

                Alert.success('Access Lists succesfully uploaded');
                getGanSuppliersList(filter);
                setModalUploadShow(false);
                setModalUploadData({});
            })
            .finally(() => {
                setModalUploadLoading(false);
            });

    };


    const handleCloseModalEdit = () => {
        setFormValue({});
        setSupplierData(null);
        setModalEditShow(false);
    };


    return (
        <>
            <PanelLayout>
                <PageHeader title="Suppliers"/>

                <FlexGrid align="middle">

                    <FlexGridItem>
                        <GanSuppliersFilter
                            filter={filter}
                            onChangeFilter={setFilter}
                        />
                    </FlexGridItem>

                </FlexGrid>

                <GanSuppliersTable
                    {...{
                        data,
                        loading
                    }}
                    wordWrap
                    virtualized={false}
                    onClickEdit={handleClickEdit}
                    onClickUpload={handleClickUpload}
                />

                <ModalModifyGanSupplier
                    show={modalEditShow}
                    loading={modalEditLoading}
                    onConfirm={handleEdit}
                    onClose={handleCloseModalEdit}
                    {...{
                        formValue,
                        setFormValue,
                        supplierData,
                    }}
                />

                <ModalUploadAccessLists
                    show={modalUploadShow}
                    loading={modalUploadLoading}
                    onClose={() => {
                        setModalUploadShow(false);
                        setModalUploadData({});
                    }}
                    supplierData={supplierData}
                    setFormValue={setModalUploadData}
                    onConfirm={handleUpload}
                />

            </PanelLayout>
        </>
    )
};