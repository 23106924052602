import React from 'react';
import {Button, Modal, Tooltip, Whisper} from 'rsuite';
import {useIntl} from "react-intl";
import m from "../../definedMessages";

export default ({show, onClose, onSuccess, children, title, width, successButton, closeButton, successButtonProps, showCloseSuccessButtons = true, onSuccessClose = true, style, ...props}) => {
    const styles = {...style};
    width && (styles.width = width);
    const {formatMessage} = useIntl();

    const closeModal = (onClose, onSuccess) => {
        onSuccess();
        onClose();
    };
    return (
        <Modal {...props} show={show} onHide={onClose} style={styles}>
            <Modal.Header>
                {
                    props.whisper ?
                        <Whisper
                            placement="bottomStart"
                            trigger="hover"
                            speaker={
                                <Tooltip>
                                    {props.whisper}
                                </Tooltip>
                            }
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Whisper>
                        :
                        <Modal.Title>{title}</Modal.Title>
                }
            </Modal.Header>
            <Modal.Body style={{maxHeight: '100%'}}>
                {children}
            </Modal.Body>
            <Modal.Footer>
                {
                    (() => {
                        if (showCloseSuccessButtons) {
                            return (
                                <>
                                    <Button appearance="primary"
                                            onClick={() => {
                                                if (onSuccessClose) {
                                                    closeModal(onClose, onSuccess)
                                                } else {
                                                    onSuccess()
                                                }
                                            }} {...successButtonProps} >
                                        {successButton ? successButton : 'Ok'}
                                    </Button>
                                    <Button onClick={onClose} appearance="subtle">
                                        {closeButton ? closeButton : formatMessage(m.cancel)}
                                    </Button>
                                </>
                            );
                        }
                    })()
                }
            </Modal.Footer>
        </Modal>
    );
};