import {defineMessages} from "react-intl";

export default defineMessages({
    numbersFromTemplateCannotBeAllocated: {
        id: "validation.numbersFromTemplateCannotBeAllocated",
        defaultMessage: "Numbers from template \"{template}\" cannot be allocated",
        description: "validation: Numbers from template \"{template}\" cannot be allocated"
    },
    accountTrunkRangeMismatchTemplate: {
        id: "modalResponseHandler.accountTrunkRangeMismatchTemplate",
        defaultMessage: "Numbers from template \"{template}\" cannot be allocated to trunk \"{trunkName}\". Please try another trunk.",
        description: "ModalResponseHandler: Numbers from template {template} cannot be allocated to trunk \"{trunkName}\". Please try another trunk."
    },
    accountTrunkRangeMismatchRange: {
        id: "modalResponseHandler.accountTrunkRangeMismatchRange",
        defaultMessage: "Numbers from range \"{range}\" cannot be allocated to trunk \"{trunkName}\". Please try another trunk.",
        description: "ModalResponseHandler: Numbers from range {range} cannot be allocated to trunk \"{trunkName}\". Please try another trunk."
    },
    notFoundFreeNumbersTemplate: {
        id: "modalResponseHandler.notFoundFreeNumbersTemplate",
        defaultMessage: "We cannot allocate {numbersAmount} numbers from template \"{template}\" successively. Please decrease number amount or use random order",
        description: "ModalResponseHandler: We cannot allocate {numbersAmount} numbers from template \"{template}\" successively. Please decrease number amount or use random order"

    },
    notFoundFreeNumbersRange: {
        id: "modalResponseHandler.notFoundFreeNumbersRange",
        defaultMessage: "We cannot allocate {numbersAmount} numbers from range \"{range}\" successively. Please decrease number amount or use random order",
        description: "ModalResponseHandler: We cannot allocate {numbersAmount} numbers from template \"{range}\" successively. Please decrease number amount or use random order"
    },
    notFoundFreeNumbersDestination: {
        id: "modalResponseHandler.notFoundFreeNumbersDestination",
        defaultMessage: "We cannot allocate {numbersAmount} numbers for destination \"{subDest}\" successively. Please decrease number amount or use random order",
        description: "ModalResponseHandler: We cannot allocate {numbersAmount} numbers for destination \"{subDest}\" successively. Please decrease number amount or use random order"
    },
    destinationIsNotAvailable: {
        id: "modalResponseHandler.destinationIsNotAvailable",
        defaultMessage: "This destination is not available for allocation. Please contact to your account manager.",
        description: "ModalResponseHandler: This destination is not available for allocation. Please contact to your account manager."
    },
    numbersForSubdestCannotBeAllocated: {
        id: "modalResponseHandler.numbersForSubdestCannotBeAllocated",
        defaultMessage: "Numbers for \"{subDest}\" cannot be allocated",
        description: "ModalResponseHandler: Numbers for \"{subDest}\" cannot be allocated."
    },
    youHaveExceededTheLimit: {
        id: "modalResponseHandler.youHaveExceededTheLimit",
        defaultMessage: "You have exceeded the limit for self-allocation numbers",
        description: "ModalResponseHandler: You have exceeded the limit for self-allocation numbers."
    },
    tooMuchNumbersPerSubdestination: {
        id: "modalResponseHandler.tooMuchNumbersPerSubdestination",
        defaultMessage: "You're trying to allocate too much numbers per subdestination",
        description: "ModalResponseHandler: You're trying to allocate too much numbers per subdestination"
    },
    numbersCannotBeAllocated: {
        id: "modalResponseHandler.numbersCannotBeAllocated",
        defaultMessage: "Numbers cannot be allocated",
        description: "ModalResponseHandler: Numbers cannot be allocated"
    },



    actionIsProhibited: {
        id: "modalResponseHandler.actionIsProhibited",
        defaultMessage: "Action is prohibited",
        description: "ModalResponseHandler: Action is prohibited"
    },
    tooMuchSimultaneousOperations: {
        id: "modalResponseHandler.tooMuchSimultaneousOperations",
        defaultMessage: "Too much simultaneous operations",
        description: "ModalResponseHandler: Too much simultaneous operations"
    },
    templateIsTooShort:{
        id: "modalResponseHandler.templateIsTooShort",
        defaultMessage: "Template is too short",
        description: "ModalResponseHandler: Template is too short"
    },
    templateIsTooLong:{
        id: "modalResponseHandler.templateIsTooLong",
        defaultMessage: "Template is too long",
        description: "ModalResponseHandler: Template is too long"
    },
    numbersAreTemporarilyUnavailable: {
        id: "modalResponseHandler.numbersAreTemporarilyUnavailable",
        defaultMessage: "Numbers are temporarily unavailable",
        description: "ModalResponseHandler: Numbers are temporarily unavailable"
    },
    tooMuchNumbersPerRange: {
        id: "modalResponseHandler.tooMuchNumbersPerRange",
        defaultMessage: "Too much numbers per range",
        description: "ModalResponseHandler: Too much numbers per range"
    },
    invalidOperation: {
        id: "modalResponseHandler.invalidOperation",
        defaultMessage: "Invalid operation",
        description: "ModalResponseHandler: Invalid operation"
    },
    incorrectNumberAmount: {
        id: "modalResponseHandler.incorrectNumberAmount",
        defaultMessage: "Incorrect number amount",
        description: "ModalResponseHandler: Incorrect number amount"
    },
    wrongTemplate: {
        id: "modalResponseHandler.wrongTemplate",
        defaultMessage: "Wrong template",
        description: "ModalResponseHandler: Wrong template"
    },
    templateHasIncorrectNumberLength: {
        id: "modalResponseHandler.templateHasIncorrectNumberLength",
        defaultMessage: "Template has incorrect number length",
        description: "ModalResponseHandler: Template has incorrect number length"
    },
    operationCode: {
        id: "modalResponseHandler.operationCode",
        defaultMessage: "Operation code",
        description: "ModalResponseHandler: Operation code"
    },
    tooMuchNumbersPerOne: {
        id: "modalResponseHandler.tooMuchNumbersPerOne",
        defaultMessage: "You're trying to allocate to much numbers per one",
        description: "ModalResponseHandler: You're trying to allocate too much numbers per one"
    },
    numbersCannotBeAllocatedToTrunk: {
        id: "modalResponseHandler.numbersCannotBeAllocatedToTrunk",
        defaultMessage: "Numbers cannot be allocated to trunk \"{trunkName}\". Please try another trunk.",
        description: "ModalResponseHandler: Numbers cannot be allocated to trunk \"{trunkName}\". Please try another trunk."
    },
    numbersFromRangeCannotBeAllocated: {
        id: "modalResponseHandler.numbersFromRangeCannotBeAllocated",
        defaultMessage: "Numbers from range \"{range}\" cannot be allocated.",
        description: "ModalResponseHandler: Numbers from range \"{range}\" cannot be allocated."
    },
    sequenceCannotAllocate: {
        id: "modalResponseHandler.sequenceCannotAllocate",
        defaultMessage: "We cannot allocate {numbersAmount} numbers successively. Please decrease number amount or use random order.",
        description: "ModalResponseHandler: We cannot allocate {numbersAmount} numbers successively. Please decrease number amount or use random order."
    },


    fileContainsSomeInvalidNumbers: {
        id: "modalResponseHandler.fileContainsSomeInvalidNumbers",
        defaultMessage: "File contains some invalid numbers",
        description: "ModalResponseHandler: File contains some invalid numbers"
    },
    incorporatedGroupHasDifferentSubdest: {
        id: "modalResponseHandler.incorporatedGroupHasDifferentSubdest",
        defaultMessage: "The incorporated group has a different subdestination",
        description: "ModalResponseHandler: The incorporated group has a different subdestination"
    },
    incorporatedGroupHasNoFreeNumbers: {
        id: "modalResponseHandler.incorporatedGroupHasNoFreeNumbers",
        defaultMessage: "Incorporated group has no free numbers",
        description: "ModalResponseHandler: Incorporated group has no free numbers"
    },
    globalAccessNumbersGroupHasDifferentSubdest: {
        id: "modalResponseHandler.globalAccessNumbersGroupHasDifferentSubdest",
        defaultMessage: "The global access numbers group has a different subdestination",
        description: "ModalResponseHandler: The global access numbers group has a different subdestination"
    },
    cannotDetermineSubdestByNumber: {
        id: "modalResponseHandler.cannotDetermineSubdestByNumber",
        defaultMessage: "Cannot determine the subdestination by the number",
        description: "ModalResponseHandler: Cannot determine the subdestination by the number"
    },
    numbersAreFromDifferentSubdest: {
        id: "modalResponseHandler.numbersAreFromDifferentSubdest",
        defaultMessage: "Numbers are from different subdestinations",
        description: "ModalResponseHandler: Numbers are from different subdestinations"
    },
    numbersBelongToAnotherGroup: {
        id: "modalResponseHandler.numbersBelongToAnotherGroup",
        defaultMessage: "Numbers belong to another group",
        description: "ModalResponseHandler: Numbers belong to another group"
    },
    numbersHaveBeenAllocated: {
        id: "modalResponseHandler.numbersHaveBeenAllocated",
        defaultMessage: "Numbers have been allocated",
        description: "ModalResponseHandler: Numbers have been allocated"
    },
    tooManyNumbersForOneTimeAllocation: {
        id: "modalResponseHandler.tooManyNumbersForOneTimeAllocation",
        defaultMessage: "Too many numbers for one-time allocation",
        description: "ModalResponseHandler: Too many numbers for one-time allocation"
    },
    dailyAllocationLimitIsExceed: {
        id: "modalResponseHandler.dailyAllocationLimitIsExceed",
        defaultMessage: "Daily allocation limit is exceed",
        description: "ModalResponseHandler: Daily allocation limit is exceed"
    },
    dailyAllocationLimitForThisDestinationIsExceed: {
        id: "modalResponseHandler.dailyAllocationLimitForThisDestinationIsExceed",
        defaultMessage: "Daily allocation limit for this destination is exceed",
        description: "ModalResponseHandler: Daily allocation limit for this destination is exceed"
    },
    amountOfAllocatedNumbersExceedAllowedForThisTrunk: {
        id: "modalResponseHandler.amountOfAllocatedNumbersExceedAllowedForThisTrunk",
        defaultMessage: "Amount of allocated numbers exceed allowed for this trunk",
        description: "ModalResponseHandler: Amount of allocated numbers exceed allowed for this trunk"
    },
    trunkIsNotGooogleOtp: {
        id: "modalResponseHandler.trunkIsNotGooogleOtp",
        defaultMessage: "Trunk is not Gooogle OTP",
        description: "ModalResponseHandler: Trunk is not Gooogle OTP"
    },
    incorrectAllocationMethod: {
        id: "modalResponseHandler.incorrectAllocationMethod",
        defaultMessage: "Incorrect allocation method",
        description: "ModalResponseHandler: Incorrect allocation method"
    },
    noRateForDestination: {
        id: "modalResponseHandler.noRateForDestination",
        defaultMessage: "No rate for destination",
        description: "ModalResponseHandler: No rate for destination"
    },
    allocationIsTemporaryUnavailable: {
        id: "modalResponseHandler.allocationIsTemporaryUnavailable",
        defaultMessage: "Allocation is temporary unavailable. Try again in few minutes",
        description: "ModalResponseHandler: Allocation is temporary unavailable. Try again in few minutes"
    },
    allocationForIsTemporaryUnavailable: {
        id: "modalResponseHandler.allocationForIsTemporaryUnavailable",
        defaultMessage: "Allocation for \"{name}\" is temporary unavailable. Try again in few minutes",
        description: "ModalResponseHandler: Allocation for \"{name}\" is temporary unavailable. Try again in few minutes"
    },
})