import React, {useMemo} from "react";
import {useWindowWidth} from "../../hooks";
import {Form, Schema} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import FieldDataRange from "../../components/Form/FieldDataRange";

export default ({filters, onChangeFilters}) => {

    const resizedWidth = useWindowWidth();

    return (
        <Form
            formDefaultValue={filters}
            onChange={(value) => {
                onChangeFilters(value);
            }}
        >
            <FlexGrid align="middle">
                {(resizedWidth > 1005) && 
                    <FlexGridItem>Date from</FlexGridItem>
                }

                <FlexGridItem>
                    <FieldDataRange name="start_end_date"/>
                </FlexGridItem>
            </FlexGrid>
        </Form>
    )
}