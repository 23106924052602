import React, {useMemo, useState, useEffect} from "react";
import {CheckPicker, SelectPicker, Form, FormControl, Whisper, Tooltip} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import FieldDataRange from "../../components/Form/FieldDataRange";
import {debounce} from "../../utils";
import { APP_TYPE_CLIENT, APP_TYPE_ADMIN } from "const";

const siteList = [
    {id: APP_TYPE_CLIENT, name: "Client"},
    {id: APP_TYPE_ADMIN + true, name: "Voice panel"},
    {id: APP_TYPE_ADMIN + false, name: "SMS panel"},
];

export default ({
    service,
    userList,
    actionList,
    accounts,
    filterValue,
    defaultFilter,
    onChangeFilter
}) => {
    
    
    const unitedDefaultFilter = {
        ...defaultFilter,
        site_list: [APP_TYPE_ADMIN+service]
    };

    const [filterState, setFilterState] = useState(unitedDefaultFilter);

    const [searchedActionValue, setSearchedActionValue] = useState(null);

    const modifiedUserList = userList.map((user) => {
        const name = user.account_user_name;
        const surname = user.account_user_surname;

        return {
            ...user,
            nameAndUsername: `${name}${!!surname?" ":""}${!!surname?surname:""}`
        }
    });

    const actualActionList = useMemo(() => {
        if (!!searchedActionValue) {
            return [
                {action: searchedActionValue},
                ...actionList
            ]
        }
        return actionList;
    }, [actionList, searchedActionValue]);

    useEffect(() => {
        setFilterState(filterValue);
    }, [filterValue]);

    return (
        <>
            <Form
                formDefaultValue={unitedDefaultFilter}
                filterValue={filterState}
                onChange={(value) => {
                    onChangeFilter(value);
                    setFilterState(value);
                }}
            >
                <FlexGrid align="middle">
                    <FlexGridItem>
                        <FieldDataRange
                            name="range"
                            placement="bottomStart"
                            placeholder={"Date"}
                            style={{ width: 234 }}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <FormControl
                            accepter={CheckPicker}
                            sticky
                            name="account_id_list"
                            data={accounts}
                            valueKey="id"
                            labelKey="name"
                            style={{ width: 224 }}
                            placeholder={"Accounts"}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <FormControl
                            accepter={CheckPicker}
                            sticky
                            name="account_user_id_list"
                            data={modifiedUserList}
                            valueKey="account_user_id"
                            labelKey="nameAndUsername"
                            style={{ width: 224 }}
                            menuStyle={{minWidth: 400}}
                            placeholder={"Users"}
                            renderMenuItem={(label) => {
                                return <Whisper
                                    placement="right"
                                    trigger="hover" 
                                    speaker={<Tooltip>{label}</Tooltip>}
                                    enterable={true}
                                >
                                    <span>{label}</span>
                                </Whisper>
                            }}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <FormControl
                            accepter={CheckPicker}
                            sticky
                            name="action_list"
                            data={actualActionList}
                            value={filterState.action_list}
                            valueKey="action"
                            labelKey="action"
                            onSearch={debounce((value) => {
                                const formValue = {
                                    ...filterValue,
                                    action_list: !!value && (!filterValue.length || filterValue.length && value !== filterValue[0])
                                        ? [value]
                                        : null
                                };

                                setSearchedActionValue(value);
                                onChangeFilter(formValue); 
                            }, 1100)}
                            // onClose={}
                            style={{ minWidth: 224, maxWidth: 400 }}
                            placeholder={"Actions"}
                            menuStyle={{minWidth: 400}}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <FormControl
                            accepter={CheckPicker}
                            name="site_list"
                            data={siteList}
                            valueKey="id"
                            labelKey="name"
                            defaultValue={[APP_TYPE_ADMIN+service]}
                            style={{width: 224}}
                            placeholder={"Site list"}
                            searchable={false}
                        />
                    </FlexGridItem>

                </FlexGrid>
            </Form>
        </>
    )
};