import React from 'react';
import {Form} from 'rsuite';
import Checkbox from '../../components/Form/CheckboxControl';
import {FormattedMessage} from 'react-intl';

export default ({onChangeFilters}) => {

    const onSubmit = (filters) => {
        onChangeFilters(filters);
    }

    return (
        <Form onChange={onSubmit}>
            <Checkbox name="test_calls">
                <FormattedMessage id="liveCalls.showTestCallsToo" defaultMessage="Show test calls too" />
            </Checkbox>
        </Form>
    )

};