import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Collapse} from "react-collapse";
import {Form, FormGroup} from 'rsuite';
import {FormControl, Icon, InputGroup, Schema, SelectPicker, CheckPicker, Checkbox, DateRangePicker} from 'rsuite'
import * as S from "./styled";
import styled from "styled-components";
import CustomField from "../../components/Form/CustomField";
import {BaseButton} from "../../components/base/BaseButton";
import {Spacer} from "../../components/base/Spacer";
import { transformed_service_reverse_data } from 'const';
import { usePrevious } from 'hooks';
import {servicePlanNoTrunkFilter} from "../../utils/filters";

const MAX_LENGTH = 128;
const {ArrayType, StringType} = Schema.Types;
const accFiltersModel = Schema.Model({
    str: StringType().maxLength(MAX_LENGTH, `The maximum is only ${MAX_LENGTH} characters.`),
    account_manager: ArrayType()
});

const servicePickerData = [
    {
        label: 'SMS',
        value: 'sms'
    },
    {
        label: 'Voice',
        value: 'voice'
    },
    {
        label: 'Both',
        value: undefined
    }
];


export default (
    {
        service,
        filter,
        setFilter,
        account_manager_list,
        paymentTermsList,
        servicePlanList,
        isMobile,
        countryList,
        howFindList,
        moreFiltersShow,
        setMoreFiltersShow,
    }
) => {

    const prevService = usePrevious(service);
    const [serviceValue, setService] = useState(transformed_service_reverse_data[service]);

    useEffect(() => {
        if (service !== prevService) {
            setFilter({...filter, service: transformed_service_reverse_data[service]});
        }
        setService(transformed_service_reverse_data[service]);
    }, [service]);

    const servicePlanListFiltered = useMemo(() => {
        const servicePlanListWithOption = [...(servicePlanList || []), {name: 'Show accounts without trunks', sp_key: 0}];
        const filteredServicePlanList = !service ? servicePlanListWithOption.filter(servicePlanNoTrunkFilter) : servicePlanListWithOption;
        return filteredServicePlanList;
    }, [servicePlanList]);

    const accountManagersFiltered = account_manager_list.filter((value) => value.active || value.has_accounts);

    return (
        <Form
            fluid
            model={accFiltersModel}
            // formDefaultValue={{...filter, service: transformed_service_reverse_data[service]}}
            formValue={{...filter, service: serviceValue}}
            onChange={setFilter}
        >
            <S.Grid>

                <S.GridItem style={{flexGrow: 1, width: '100%', maxWidth: 284}}>
                    <StyledInputGroup>
                        <FormControl
                            name="str"
                            errorPlacement="topRight"
                            placeholder={!isMobile
                                ? "Account name/Email address"
                                : "Account name / email"
                            }
                        />
                        <InputGroup.Addon>
                            <Icon icon="search" />
                        </InputGroup.Addon>
                    </StyledInputGroup>
                </S.GridItem>

                <S.GridItem>
                    <FormGroup>
                        <FormControl
                            accepter={CheckPicker}
                            name="acc_manager_id_list"
                            sticky
                            data={accountManagersFiltered}
                            labelKey="name"
                            valueKey="id"
                            style={{width: 224}}
                            placeholder="All account manager"
                        />
                    </FormGroup>
                </S.GridItem>

                <S.GridItem>
                    <FormGroup>
                        <FormControl
                            accepter={SelectPicker}
                            name="pt_key"
                            data={paymentTermsList}
                            labelKey="name"
                            valueKey="pt_key"
                            style={{width: 224}}
                            placeholder="Payment term"
                            searchable={false}
                        />
                    </FormGroup>
                </S.GridItem>

                <S.GridItem>
                    <FormControl
                        accepter={CustomCheckbox}
                        name="only_with_traffic"
                    >
                        Only with traffic
                    </FormControl>
                </S.GridItem>

                {!isMobile &&
                <S.GridItem>
                    <BaseButton
                        buttonStyle={moreFiltersShow ? "secondary" : "default"}
                        onClick={() => setMoreFiltersShow(!moreFiltersShow)}
                    >
                        more options
                    </BaseButton>
                </S.GridItem>
                }
            </S.Grid>

            {isMobile &&
                <div>
                    <Spacer size={20}/>
                    <BaseButton
                        buttonStyle={moreFiltersShow ? "secondary" : "default"}
                        onClick={() => setMoreFiltersShow(!moreFiltersShow)}
                    >
                        more options
                    </BaseButton>
                </div>
            }

            <Collapse isOpened={moreFiltersShow}>
                <Spacer size={15}/>
                <S.Grid>
                    <S.GridItem style={{flexGrow: 1, width: '100%', maxWidth: 284}}>
                        <CustomField
                            name="created_time"
                            accepter={DateRangePicker}
                            cleanable={true}
                            placeholder="Created time"
                            width={300}
                        />
                    </S.GridItem>

                    <S.GridItem>
                        <FormGroup>
                            <FormControl
                                name="cntr_code"
                                accepter={SelectPicker}
                                data={countryList}
                                labelKey="name"
                                valueKey="cntr_code"
                                style={{width: 224}}
                                placeholder="Country"
                            />
                        </FormGroup>
                    </S.GridItem>

                    <S.GridItem>
                        <FormGroup>
                            <FormControl
                                name="sp_key_list"
                                accepter={CheckPicker}
                                data={servicePlanListFiltered}
                                labelKey="name"
                                valueKey="sp_key"
                                style={{width: 224}}
                                placeholder="Price"
                                menuStyle={{width: 260}}
                            />
                        </FormGroup>
                    </S.GridItem>

                    <S.GridItem>
                        <FormGroup>
                            <FormControl
                                name="hf_key"
                                accepter={SelectPicker}
                                data={howFindList}
                                labelKey="how_find_name"
                                valueKey="hf_key"
                                style={{width: 224}}
                                placeholder="How find us"
                            />
                        </FormGroup>
                    </S.GridItem>

                    <S.GridItem>
                        <FormGroup>
                            <FormControl
                                name="service"
                                accepter={SelectPicker}
                                data={servicePickerData}
                                labelKey="label"
                                valueKey="value"
                                value={serviceValue}
                                onChange={(value) => {
                                    setService(value)
                                }}
                                style={{width: 180}}
                                placeholder="Service"
                                searchable={false}
                                cleanable={false}
                            />
                        </FormGroup>
                    </S.GridItem>

                </S.Grid>
                <Spacer size={20}/>
                <S.Grid>
                    <S.GridItem>
                        <FormControl
                            accepter={CustomCheckbox}
                            name="only_with_allocated"
                        >
                            Only with allocated numbers
                        </FormControl>
                    </S.GridItem>

                    <S.GridItem>
                        <FormControl
                            accepter={CustomCheckbox}
                            name="show_closed_account"
                        >
                            Show closed accounts
                        </FormControl>
                    </S.GridItem>

                    <S.GridItem>
                        <FormControl
                            accepter={CustomCheckbox}
                            name="show_without_trunks"
                        >
                            Show without trunks too
                        </FormControl>
                    </S.GridItem>

                </S.Grid>
                <Spacer size={15}/>
            </Collapse>

        </Form>
    );
};



const StyledInputGroup = styled(InputGroup)`
    && {
        overflow: visible;
        
        @media (min-width: 1200px) {
            margin-bottom: 0;
        }
    }

    &&&& {
        .rs-input {
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
        }
    }
`;


const CustomCheckbox = ({children, defaultValue = false, value, onChange, ...props}) => {
    const [_value, _setValue] = useState(defaultValue);

    useEffect(() => {
        _setValue(value);
    }, [value]);

    return <Checkbox
        value={_value}
        checked={_value}
        onChange={() => {
            _setValue(!_value);
            onChange && onChange(!_value);
        }}
        {...props}
    >
        {children}
    </Checkbox>
};