import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import TableEditCellsNew from "../../components/Table/TableEditCells/TableEditCellsNew";
import {api} from "../../api/loginRoutes";
import {Form, FormControl, Icon, Input, Button, InputNumber, Schema, SelectPicker} from "rsuite";
import {Spacer} from "../../components/base/Spacer";
import {CustomModal} from "../../components/base";
import {useWindowWidth} from "../../hooks";
import { useIntl } from "react-intl";
import m from "../../definedMessages";
import FormCreateRowNew from "components/Table/TableEditCells/FormCreateRowNew";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import {debounce} from "../../utils";
import CustomField from "../../components/Form/CustomField";
import {RtlContext} from "../../App";
import {transformed_service_reverse_data} from "../../const";
import AccessListFiltersSettings from "./AccessListFiltersSettings";

// access_list_notification:get
// access_list_filter:get_list
// access_list_filter:create
// access_list_filter:modify
// access_list_filter:remove

const {StringType} = Schema.Types;

const PREFIX_MIN_LENGTH = 6;
const PREFIX_MAX_LENGTH = 15;


export default (
    {
        show,
        data,
        service,
        loading,
        accountId,
        accountInfo,

        settings,
        settingsIsFetched,

        getAccessListFiltersDialer,
        modifyNotification,
        onClose,

        worldzoneList,
        destinationList,
        subdestinationList,

        numberTypeFamilyList
    }
) => {

    const {formatMessage} = useIntl();

    
    const formModelPrefix = useMemo(() => {
        return Schema.Model({
            ...(service ? {
                a_prefix: StringType()
                    .pattern(/^[0-9][\d]*$/, formatMessage(m.numberMust))
                    .minLength(PREFIX_MIN_LENGTH, formatMessage(m.minLength, {count: PREFIX_MIN_LENGTH}))
                    .maxLength(PREFIX_MAX_LENGTH, formatMessage(m.maxLength, {count: PREFIX_MAX_LENGTH}))
            } : {
                a_prefix: StringType()
                    .maxLength(PREFIX_MAX_LENGTH, formatMessage(m.maxLength, {count: PREFIX_MAX_LENGTH}))
            })
        });
    });

    const defaultSettingsValue = {
        daily: false,
        price_list: [],
        datetime: new Date(),
        type: null,
        filtered_destinations: false
    };

    const [filterLoading, setFilterLoading] = useState(false);
    const [allowGetList, setAllowGetList] = useState(false);
    const [filter, setFilter] = useState({});
    const [settingsValue, setSettingsValue] = useState(defaultSettingsValue);
    const [showMobileCreateForm, setShowMobileCreateForm] = useState(false);
    const [serviceNameData, onChangeServiceName] = useState(null);

    const windowWidth = useWindowWidth();

    useEffect(() => {
        if (settings) {
            setSettingsValue(settings);
        }
    }, [settings]);

    useEffect(() => {
        onChangeServiceName(null);
    }, [service]);

    const filteredData = useMemo(() => {
        if (!data) {
            return [];
        }

        if (!filter) {
            return data;
        }
        return data.filter(item => {
            const strMatch = filter?.str && filter.str.toLowerCase();

            const filteredParams = [
                "a_worldzone_name",
                "a_destination_name",
                "a_subdestination_name",
                "a_prefix",
                "b_worldzone_name",
                "b_destination_name",
                "b_subdestination_name",
            ];

            const strMatchedList = filteredParams.map((param) => {
                if (!strMatch) {
                    return true;
                }

                return !!(item[param] && item[param].toLowerCase().indexOf(strMatch) !== -1);
            });

            const antfMatch = filter?.antf_key;
            const antfMatchedValue = !antfMatch || item.antf_key === antfMatch;

            return strMatchedList.some(dataMatch => !!dataMatch) && antfMatchedValue;
        })
            // !antfMatch || item.antf_key === antfMatch;
    }, [data, filter]);

    const columns = useMemo(() => {
        return [
            ...(service ? [{
                name: formatMessage(m.serviceName),
                dataKey: "antf_name",
                flexGrow: 2,
                title: true,

                accepter: SelectPicker,
                defaultValue: ({antf_key}) => antf_key,
                placeholder: formatMessage(m.serviceName),
                data: numberTypeFamilyList || [],
                valueKey: "antf_key",
                labelKey: "family_name",
                fieldName: "antf_key",
            }] : []),
            ...(service ? [{
                name: formatMessage(m.originZone),
                dataKey: "a_worldzone_name",
                flexGrow: 2,
                title: true,

                accepter: SelectPicker,
                defaultValue: ({a_wz_key}) => a_wz_key,
                placeholder: formatMessage(m.originZone),
                data: worldzoneList || [],
                valueKey: "wz_key",
                labelKey: "name",
                fieldName: "a_wz_key",
                disabled: !!serviceNameData
            },

            {
                name: formatMessage(m.originCountry),
                dataKey: "a_destination_name",
                flexGrow: 2,
                title: true,

                accepter: SelectPicker,
                defaultValue: ({a_de_key}) => a_de_key,
                placeholder: formatMessage(m.originCountry),
                data: destinationList || [],
                valueKey: "de_key",
                labelKey: "name",
                fieldName: "a_de_key",
                disabled: !!serviceNameData
            },

            {
                name: formatMessage(m.originOperator),
                dataKey: "a_subdestination_name",
                flexGrow: 2,
                title: true,

                accepter: SelectPicker,
                defaultValue: ({a_sde_key}) => a_sde_key,
                placeholder: formatMessage(m.originOperator),
                data: subdestinationList || [],
                valueKey: "sde_key",
                labelKey: "name",
                fieldName: "a_sde_key",
                disabled: !!serviceNameData
            }] : []),

            {
                name: service ? formatMessage(m.originPrefix) : formatMessage(m.senderId),
                dataKey: "a_prefix",
                flexGrow: 2,
                title: true,

                model: formModelPrefix,
                accepter: Input,
                placeholder:  service ? formatMessage(m.originPrefix) : formatMessage(m.senderId),
                disabled: !!serviceNameData
            },

            {
                name: formatMessage(m.destinationZone),
                dataKey: "b_worldzone_name",
                flexGrow: 2,
                title: true,

                accepter: SelectPicker,
                defaultValue: ({b_wz_key}) => b_wz_key,
                placeholder: formatMessage(m.destinationZone),
                data: worldzoneList || [],
                valueKey: "wz_key",
                labelKey: "name",
                fieldName: "b_wz_key",
            },

            {
                name: formatMessage(m.destinationCountry),
                dataKey: "b_destination_name",
                flexGrow: 2,
                title: true,

                accepter: SelectPicker,
                defaultValue: ({b_de_key}) => b_de_key,
                placeholder: formatMessage(m.destinationCountry),
                data: destinationList || [],
                valueKey: "de_key",
                labelKey: "name",
                fieldName: "b_de_key",
            },

            {
                name: formatMessage(m.destinationOperator),
                dataKey: "b_subdestination_name",
                flexGrow: 2,
                title: true,

                accepter: SelectPicker,
                defaultValue: ({b_sde_key}) => b_sde_key,
                placeholder: formatMessage(m.destinationOperator),
                data: subdestinationList || [],
                valueKey: "sde_key",
                labelKey: "name",
                fieldName: "b_sde_key",
            },

            {
                name: formatMessage(m.minimalPayout),
                dataKey: "rate_min",
                flexGrow: 2,

                accepter: InputNumber,
                placeholder: formatMessage(m.minimalPayout),
                step: 0.1,
                min: 0,
            },
        ]
    }, [service, serviceNameData, worldzoneList, destinationList, subdestinationList, numberTypeFamilyList]);

    useEffect(() => {
        if (show && allowGetList && accountInfo && accountInfo.id) {
            getAccessListFiltersDialer(accountInfo.id, service);
        }
    }, [show, allowGetList, accountInfo]);


    const createAccessFilter = async (params) => {
        return await api("access_list_filter:create", {
            target: {account_id: accountInfo.id},
            ...params,
            service: transformed_service_reverse_data[service],
            rate_min: params.rate_min ? +params.rate_min : 0,
            a_prefix: params.a_prefix || null
        });
    };


    const modifyAccessFilter = async (params, data) => {
        return await api("access_list_filter:modify", {
            target: {alf_key: data.alf_key},
            ...params,
            service: transformed_service_reverse_data[service],
            rate_min: params.rate_min ? +params.rate_min : 0,
            a_prefix: params.a_prefix || null
        });
    };


    const removeAccessFilter = async (id) => {
        return await api("access_list_filter:remove", {
            target: {alf_key: id},
            service: transformed_service_reverse_data[service]
        });
    };


    const isMobile = windowWidth < 1600;

    const rtl = useContext(RtlContext);

    return (
        <>
            <CustomModal
                show={show}
                title={formatMessage(m.notificationSettings)}
                onClose={() => {
                    setAllowGetList(false);
                    onClose();
                }}
                width={windowWidth >= 1600 ? 1600 : windowWidth}
                onEntered={() => setAllowGetList(true)}
            >
                <AccessListFiltersSettings
                    service={service}
                    loading={loading || filterLoading}
                    accountId={accountId}
                    settingsValue={settingsValue}
                    settingsIsFetched={settingsIsFetched}
                    setSettingsValue={setSettingsValue}
                    handleSubmit={modifyNotification}
            
                    formatMessage={formatMessage}
                />
                <FlexBlock>
                    <Filter
                        setLoading={setFilterLoading}
                        {...{
                            rtl,
                            service,
                            setFilter,
                            numberTypeFamilyList
                        }}
                    />
                    {isMobile &&
                        <Button
                            className="tableFilters_submit"
                            color="green"
                            type="submit"
                            onClick={() => setShowMobileCreateForm(!showMobileCreateForm)}
                        >
                            {!showMobileCreateForm ? <Icon icon="plus"/> : <Icon icon="close"/>}
                        </Button>
                    }
                </FlexBlock>

                {isMobile &&
                    <Collapse isOpened={showMobileCreateForm}>
                        <StyledFormWrapper>
                            <FormCreateRowNew
                                columns={columns}
                                formModel={formModelPrefix}
                                onDataUpdate={() => getAccessListFiltersDialer(accountInfo.id, service)}
                                onSubmit={createAccessFilter}
                                mobile={true}
                                onChangeServiceName={onChangeServiceName}
                            />
                            <Spacer/>
                        </StyledFormWrapper>
                    </Collapse>
                }

                <TableEditCellsNew
                    rowIdKey="alf_key"
                    data={filteredData}
                    height={360}
                    autoHeight={false}
                    columns={columns}
                    loading={loading || filterLoading}
                    mobile={isMobile}
                    showFormCreate={!isMobile}
                    formModel={formModelPrefix}
                    onRowSubmit={modifyAccessFilter}
                    onRowRemove={removeAccessFilter}
                    onRowCreate={createAccessFilter}
                    modalRemoveTitle={formatMessage(m.deleteAccessListFilter)}
                    onDataUpdate={() => getAccessListFiltersDialer(accountInfo.id, service)}
                    onChangeServiceName={onChangeServiceName}
                />
            </CustomModal>
        </>
    )
};


const Filter = (
    {
        rtl,
        service,
        setLoading,
        setFilter,
        numberTypeFamilyList
    }
) => {

    const {formatMessage} = useIntl();

    const handleChange = (value) => {
        setLoading(true);
        debounce((value) => {
            setFilter(value);
            setLoading(false);
        }, 100)(value);
    };

    const strPlaceholder = service ? formatMessage(m.originPrefixDestination) : `${formatMessage(m.senderId)}/${formatMessage(m.destination)}`;

    return <Form
        onChange={debounce(handleChange, 500)}
    >
        <FlexInline>
            {service ? <CustomField
                style={{width: 160, ...(rtl ? {marginLeft: 20} : {marginRight: 20})}}
                name="antf_key"
                className="tableFilters_field"
                errorPlacement="topEnd"
                accepter={SelectPicker}
                data={numberTypeFamilyList}
                placeholder={formatMessage(m.serviceName)}
                valueKey="antf_key"
                labelKey="family_name"
                type="text"
            /> : <></>}
            <FormControl
                style={{marginBottom: "24px"}}
                name="str"
                placeholder={strPlaceholder}
            />
        </FlexInline>
    </Form>
};

const FlexInline = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 500px;
`;


const FlexBlock = styled.div`
    display: flex;
    justify-content: space-between;
    .rs-form {
        flex-grow: 1;
    }
`;


const StyledFormWrapper = styled.div`
    margin-left: -10px;
    margin-right: -10px;
`;