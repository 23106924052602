import React from 'react';
import styled, {css} from 'styled-components';
import {ButtonToolbar, Loader, Dropdown, Icon} from 'rsuite';
import {getFileResponse} from '../../api/loginRoutes';
import {servicePick} from "../../utils";
import {USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY} from "../../const";
import {RATECARD_LIST_DEFAULT_DIALER, SMS_RATECARD_LIST_DEFAULT_DIALER} from "../../const/apiMethods";
import {getBlobContent, downloadDataAsFile, savePdfByTemplate} from '../../utils/helpers';


export default ({
    cur_key,
    service,
    str = '',
    sp_key,
    p_key,
    otpType,
    modal = false,
    ...props
}) => {
    const defaultCurKey = servicePick(service, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY);
    const curKey = cur_key || defaultCurKey;

    const [loading, setLoading] = React.useState(false);


    const exportFile = async () => {
        setLoading(true);

        const method = otpType === null
            ? servicePick(service, RATECARD_LIST_DEFAULT_DIALER, SMS_RATECARD_LIST_DEFAULT_DIALER)
            : 'ratecard_otp:get_default_price';

        const result = await getFileResponse(method, {
            filter:{ str, cur_key: curKey, sp_key },
        });

        if(result) {
            downloadDataAsFile(result,'prices.csv');
        }

        setLoading(false)
    };

    const exportActualSppFile = async (withPrice = false) => { // withPrice: true - with price, false - without price, null - all
        setLoading(true);
        const result = await getFileResponse('service_plan_price:get_list_actual', {
            filter: {},
            cur_key: curKey,
            p_key,
            with_price: withPrice
        });
        if(result) {
            downloadDataAsFile(result,'prices.csv');
        }
        setLoading(false)
    };

    const exportActualSppFileWithoutPrice = () => {
        exportActualSppFile(false);
    }
    const exportActualSppFileWithPrice = () => {
        exportActualSppFile(true);
    }
    const exportActualSppFileAll  = () => {
        exportActualSppFile(null);
    }

    
    const onDownloadPricesPdf = async () => {
        const {account, getTemplate} = props;

        const method = otpType === null
            ? servicePick(service, RATECARD_LIST_DEFAULT_DIALER, SMS_RATECARD_LIST_DEFAULT_DIALER)
            : 'ratecard_otp:get_default_price';

        setLoading(true);
        getTemplate(account.id, 0, 'allocated_numbers').then(({template = {}}) => {
            getFileResponse(method, {filter: {str, cur_key, sp_key},})
                .then((response) => {
                    if (response) {
                        const csvText = getBlobContent(response);
                        csvText.then((csvContent) => {
                            const csvStringsArray = csvContent ? csvContent.split('\n') : [];
                            const csvDataArrayData = csvStringsArray.filter(value => !!value);
                            const csvHeaders = csvDataArrayData.length && csvDataArrayData[0].split(';');
                            const csvBody = csvDataArrayData.length && csvDataArrayData.slice(1, csvDataArrayData.length).map(line => line.split(';'));

                            savePdfByTemplate(
                                csvBody,
                                [csvHeaders],
                                'prices',
                                template,
                                {},
                                account
                            );
                            setLoading(false)
                        });
                    }
                })
        })
    };


    return (
        <StyledButtonToolbar>
            <StyledDropdown
                size="sm"
                title={<>
                    {loading 
                        ? <StyledLoader/> 
                        : <><Icon icon="upload2"/>Export</>
                    }
                </>}
                appearance="default"
                disabled={loading}
                placement="bottomEnd"
            >
                
                <Dropdown.Item
                    onClick={exportFile}
                >
                    Export price list with ranges
                </Dropdown.Item>
                
                <Dropdown.Item
                    onClick={onDownloadPricesPdf}
                >
                    Export price list with ranges (PDF)
                </Dropdown.Item>

                {!modal &&
                <Dropdown.Item
                    onClick={exportActualSppFileAll}
                >
                    Export subdestination list (full)
                </Dropdown.Item>
                }

                {!modal &&
                <Dropdown.Item
                    onClick={exportActualSppFileWithPrice}
                >
                    Export subdestination list (with rates)
                </Dropdown.Item>
                }

                {!modal &&
                <Dropdown.Item
                    onClick={exportActualSppFileWithoutPrice}
                >
                    Export subdestination list (without rates)
                </Dropdown.Item>
                }
                
            </StyledDropdown>
        </StyledButtonToolbar>
    )
};



const StyledButtonToolbar = styled(ButtonToolbar)`
    display: inline-block; 
    ${props => props.width && css`
        width: ${props.width};
    `}
`;


const StyledDropdown = styled(Dropdown)`
    height: 30px;
    & .rs-btn {
        padding-left: 10px !important;
    }
    & .rs-loader-spin {
        margin-top: 0 !important;
    }
    & > a {
        padding-top: 5px !important;
        top: 0;
        height: 30px;
        width: 100%;
    }
    & .rs-dropdown-toggle-caret {
        top: 5px;
        margin-top: 0 !important;
    }
    &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
        background-color: #d6d6db !important;
    }
`;

const StyledLoader = styled(Loader)`
    & span.rs-loader-spin {
        margin-top: 3px;
        margin-right: 5px;
    }
`;