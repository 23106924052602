import React from 'react';
import Modal from "../../components/Modal";

export default ({
    show,
    data,
    onClose,
    onSubmit,
    disabled,
    title
}) => {

    return (
        <>
            <Modal
                show={show}
                footer
                data={data}
                disabled={disabled}
                onSuccess={onSubmit}
                onClose={onClose}
                successText={"Ok"}
                title={title}
            >
                <p>Be careful, this setting will replace the default values for quarantine</p>
            </Modal>
        </>
    )
};