import React, {useState} from "react";
import Modal from "components/Modal";
import {DatePicker, Form, Schema, SelectPicker, Tooltip, Whisper} from "rsuite";
import CustomField from "components/Form/CustomField";
import {api} from "api/loginRoutes";
import Checkbox from "hoc/Checkbox";
import RateField from "components/Form/RateField";
import ServicePlans from "components/Filters/ServicePlans";
import {toStartDayUTCTime} from "utils";
import {useWindowWidth} from "hooks";
import {ButtonPrimary} from "components/base/BaseButton";
import {SP_KEY__INCORPORATED, SP_KEY__GLOBAL_ACCESS} from "const";

const {NumberType, StringType} = Schema.Types;


const AddPrice = ({
    show,
    setShow,
    subdestination_list,
    account_id,
    e212List,
    updateItems,
    isSupplier,
    pickSupplierPlan,
    pickedSupplierPlan,
    transitSupplierPlanList
}) => {

    let formRef = null;
    const updateRandomFormRef = (ref) => formRef = ref;
    const [disabled, setDisabled] = React.useState(false);


    const onSubmit = async () => {

        if (!formRef.check())
            return;

        const data = {...formRef.getFormValue()};
        setDisabled(true);
        const [mcc, mnc] = data.mccMnc ? data.mccMnc.split("-") : [];
        
        if (data.mccMnc) {
            delete data.mccMnc;
        }

        if (data.withDate) {

            data.start_date = data.start_date
                ? toStartDayUTCTime(new Date(data.start_date))
                : null;

        } else {
            delete data.start_date;
        }

        delete data.withDate;
        delete data["sms.supplier_plan_id"];

        const method = isSupplier ? "sms.supplier_price:create" : "account_price:create";
        const params = isSupplier 
            ? {
                target: {
                    "sms.supplier_plan_id": pickedSupplierPlan?.id,
                },
                ...data,
                mcc, mnc,
                rate: +formRef.getFormValue().rate
            }
            : {
                target: {
                    account_id
                },
                ...data,
                rate: +formRef.getFormValue().rate
            };

        const result = await api(method, params);

        setDisabled(false);

        if (result) {
            setShow(false);
            updateItems();
        }

    };

    return (
        <Modal
            show={show}
            title="Add new special rate"
            onClose={() => setShow(false)}
            footer={true}
            successText="Create"
            onSuccess={onSubmit}
        >
            <ModalForm
                updateFormRef={updateRandomFormRef}
                {...{
                    e212List,
                    subdestination_list,
                    disabled,
                    isSupplier,
                    pickedSupplierPlan,
                    transitSupplierPlanList,

                    pickSupplierPlan
                }}
            />
        </Modal>
    );
};

export default AddPrice;





const ModalForm = ({
    disabled = false, 
    e212List,
    subdestination_list,
    updateFormRef,
    isSupplier,
    pickedSupplierPlan,
    transitSupplierPlanList,

    pickSupplierPlan
}) => {
    const [withDate, setWithDate] = useState(false);
    const windowWidth = useWindowWidth();
    const [selectedOperator, setSelectedOperator] = useState(null);

    const defaultFormValue = isSupplier ? {
        update_transit_prefixes: false,
        "sms.supplier_plan_id": pickedSupplierPlan?.id
    } : {
        update_allocated_numbers: false
    };

    const formModel = Schema.Model({
        ...(!isSupplier ? {sp_key: NumberType().isRequired("Required")} : {}),
        ...(!isSupplier ? {sde_key: NumberType().isRequired("Required")} : {mccMnc: StringType().isRequired("Required")}),
        rate: NumberType().isRequired("Required"),
    });

    const operatorList = e212List && e212List.length ? e212List.map(value => {
        return {
            ...value,
            operatorMccMnc: `${value.mccmnc_name} (${value.mcc}${value.mnc})`,
            mccMnc: `${value.mcc}-${value.mnc}`
        };
    }) : [];

    return (
        <Form
            layout="inline"
            model={formModel}
            formDefaultValue={{...defaultFormValue}}
            ref={ref => updateFormRef(ref)}
        >
            {isSupplier ? <CustomField
                accepter={SelectPicker}
                data={transitSupplierPlanList}
                cleanable={false}
                searchable={false}
                valueKey="id"
                labelKey="name"
                placeholder="Select trunk type"
                label="Trank type"
                name="sms.supplier_plan_id"
                // value={pickedSupplierPlan?.id}
                onChange={(id) => {
                    const supplierValue = transitSupplierPlanList.find(supplier => supplier?.id === id);

                    pickSupplierPlan(supplierValue)
                }}
                style={{
                    width: 230
                }}
            /> : <ServicePlans
                excluded={[SP_KEY__INCORPORATED, SP_KEY__GLOBAL_ACCESS]}
                disabled={disabled}
                classPrefix="minWidth"
            />}
            
            {isSupplier ? <Whisper
                trigger="hidden"
                speaker={<Tooltip visible={!!selectedOperator}>{selectedOperator}</Tooltip>}
            >
                <CustomField
                    disabled={disabled}
                    accepter={SelectPicker}
                    data={operatorList}
                    onChange={(value) => {
                        setSelectedOperator(value);
                    }}
                    labelKey="operatorMccMnc"
                    valueKey="mccMnc"
                    placeholder="Operator"
                    errorPlacement="topRight"
                    name="mccMnc"
                    // classPrefix="minWidth"
                    style={{width: 430}}
                    label="Operator"
                />
            </Whisper> : <CustomField
                disabled={disabled}
                accepter={SelectPicker}
                data={subdestination_list}
                labelKey="name"
                valueKey="sde_key"
                placeholder="Subdestination"
                errorPlacement="topRight"
                name="sde_key"
                classPrefix="minWidth"
            />}
            <br/>
            <RateField
                name="rate"
                label="Rate"
            />
            <br/>
            <Checkbox
                name={"withDate"}
                onChange={(checked) => {
                    setWithDate(checked);
                }}
            >
                From date
            </Checkbox>

            <CustomField
                accepter={DatePicker}
                placeholder="Enter Date"
                name="start_date"
                disabled={!withDate}
                ranges={[
                    {
                        label: "Today",
                        value: new Date()
                    },
                    {
                        label: "Tomorrow",
                        value: new Date().setDate(new Date().getDate() + 1)
                    }
                ]}

                disabledDate={(date) => {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    return date < today;
                }}

                // mobile
                oneTap={windowWidth < 480 && withDate}
                inline={windowWidth < 480 && withDate}
            />

            {!isSupplier
            ? <Checkbox name="update_allocated_numbers">
                Update for all allocated numbers
            </Checkbox>
            : <Checkbox name="update_transit_prefixes">
                Update for all allocated prefixes
            </Checkbox>}
        </Form>
    );
};