import React, { useState, useEffect } from "react";
import Page from "components/base/Page";
import PageHeader from "components/base/PageHeader";
import styled, {createGlobalStyle} from "styled-components";
import AuthNumbersFilter from "./AuthNumbersFilter";
import AuthNumbersTable from "./AuthNumbersTable";
import { FilterSectionFlexboxGrid, StyledItemFitWidth, StyledItemAutoMargin } from "components/base/FitContainers";
import { useIntl } from "react-intl";
import { withTranslation } from "hoc/WithTranslation";
import m from "definedMessages";


const AuthNumbersPage = ({
    data,
    count,
    loading,
    page,
    perPage,

    getAuthNumbersList,
    formatMessage
}) => {

    const defaultFilterValue = {str: ""};

    const [filter, setFilter] = useState(defaultFilterValue);

    const defaultSort = {
        column: "number", type: "asc"
    };

    const [sort, setSort] = useState(defaultSort);

    useEffect(() => {
        getAuthNumbersList(filter, 1, perPage);
    }, [filter]);

    const getItems = (_page, _perPage) => {
        getAuthNumbersList(filter, _page, _perPage);
    };

    return (<Page>
        <GlobalStyle/>
        <PageHeader title={ formatMessage(m.noteAllocatedNumbersLink) } showService={false}/>

        <FilterSectionFlexboxGrid>
            <StyledItemFitWidth>
                <AuthNumbersFilter
                    filter={filter}
                    setFilter={setFilter}

                    formatMessage={formatMessage}
                />
            </StyledItemFitWidth>
            <StyledItemAutoMargin>
            </StyledItemAutoMargin>
        </FilterSectionFlexboxGrid>


        <AuthNumbersTable
            data={data}
            count={count}
            page={page}
            perPage={perPage}
            loading={loading}

            setSort={setSort}
            getItems={getItems}

            formatMessage={formatMessage}
        />
    </Page>)
};

export default withTranslation(AuthNumbersPage);


const GlobalStyle = createGlobalStyle`
    .rs-picker-check-menu-items {
        overflow-y: hidden !important;
        max-height: none !important;
    }
`;